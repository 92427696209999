import useRegistrationEntryForm from "./UseRegistrationEntryForm";

import PrimaryButton from "../../../../../common/components/buttons/PrimaryButton";
import Input from "../../../../../common/components/inputs/Input";

const RegistrationEntryForm = ({ submitButtonText = 'Enter Registration', onSubmitFormCallback }) => {
  const {
    formState,
    errorState,
    handleSubmit,
    updateFormState
  } = useRegistrationEntryForm(onSubmitFormCallback);

  return (
    <form onSubmit={handleSubmit}>
      <div className="row">
        <div className="col-xs-12 col-sm-6 col-md-4">
          <Input
            label='Business Legal Name*'
            name="entityName"
            value={formState.entityName}
            error={errorState.entityName}
            message={errorState.entityName}
            onChange={(value) => { updateFormState('entityName', value) }} />
        </div>
        <div className="col-xs-12 col-sm-6 col-md-4">
          <Input
            label='Trade Name/DBA*'
            name="tradeName"
            value={formState.tradeName}
            error={errorState.tradeName}
            message={errorState.tradeName}
            onChange={(value) => { updateFormState('tradeName', value) }} />
        </div>
        <div className="col-xs-12 col-sm-6 col-md-4">
          <Input
            label='Tax Id*'
            name="taxId"
            value={formState.taxId}
            error={errorState.taxId}
            message={errorState.taxId}
            onChange={(value) => { updateFormState('taxId', value) }} />
        </div>
      </div>
      <div className="row pull-right">
        <div className="col-xs-12 usas-extra-top-margin usas-extra-bottom-margin">
          <PrimaryButton type="submit">{submitButtonText}</PrimaryButton>
        </div>
      </div>
    </form>
  );
};

export default RegistrationEntryForm;