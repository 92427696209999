import { Fragment } from "react";

import useClubRosterDashboard from "./UseClubRosterDashboard";

import ClubRosterWidget from "./ClubRosterWidget";

import LoadingModal from "../../../../common/components/dialogs/LoadingModal";

const ClubRosterDashboard = ({ dashboardOid }) => {
  const { isError, isLoading, dashboard } = useClubRosterDashboard(dashboardOid);

  if (isError) {
    return <p>Error</p>;
  }
  
  if (isLoading) {
    return <LoadingModal />;
  }

  if (dashboard) {
    return (
      <Fragment>
        {dashboard.widgets?.map((widget, i) => {
          if (i === 0) {
            return (
              <Fragment key={i}>
                <ClubRosterWidget dashboardOid={dashboardOid} widget={widget} />
              </Fragment>
            );
          }
        })}
      </Fragment>
    );
  }

  return <Fragment />;
};

export default ClubRosterDashboard;