import { createContext, useState } from 'react';

import VendorData from './VendorData';

export const VendorStateContext = createContext();

const VendorContextProvider = ({ children }) => {
  const stateHook = useState(VendorData.INITIAL_STATE);

  return (
    <VendorStateContext.Provider value={stateHook}>
      {children}
    </VendorStateContext.Provider>
  );
};

export default VendorContextProvider;