import { CommonHttpHelper, NO_DATA_MESSAGE } from '../../utils/HttpHelper';
import Constants from '../../../common/utils/Constants';

const GetVendorData = (isTeamManagement, state, setState) => {
  if (state.isArrayLoading !== true && !state.message) {
    let newState = {
      ...state,
      isArrayLoading: true,
      isArrayLoaded: false,
      message: 'Loading...'
    };
    setState(newState);
    const url = `/Vendor${isTeamManagement === true ? '?isTeamManagement=true' : isTeamManagement === false ? '?isTeamManagement=false' : ''}`;
    CommonHttpHelper(url, 'GET')
      .then((arrayData) => {
        if (!arrayData) {
          throw new Error(NO_DATA_MESSAGE);
        } else {
          let dataAsOptionsWithNames = arrayData.map((v) => { return { id: v.vendorId, name: v.vendorName } });
          dataAsOptionsWithNames.unshift({ id: Constants.DEFAULT_ID, name: '--' });

          newState = {
            ...newState,
            isArrayLoading: false,
            isArrayLoaded: true,
            arrayData,
            dataAsOptionsWithNames: dataAsOptionsWithNames,
            message: ''
          };


          setState(newState);
        }
      })
      .catch((e) => {
        const message = e && e.message ? e.message : NO_DATA_MESSAGE;
        setState({
          ...newState,
          isArrayLoading: false,
          isArrayLoaded: false,
          message: message
        });
      });
  }
};

export default GetVendorData;