import { Fragment } from "react";

import { SisenseContextProvider } from "@sisense/sdk-ui";

import useClubPerformanceAthleteTimesMeetSummary from "./UseClubPerformanceAthleteTimesMeetSummary";

import MeetSummaryDashboard from "./MeetSummaryDashboard";

import * as DM from '../../../../../../usas-times-data';

import Headings from "../../../../../../common/components/headings/Headings";

const ClubPerformanceAthleteTimesMeetSummary = () => {
  const { environmentVariableState, MEET_SUMMARY_OID } = useClubPerformanceAthleteTimesMeetSummary();

  return (
    <Fragment>
      {environmentVariableState.isLoaded === true &&
        <SisenseContextProvider
          defaultDataSource={DM.DataSource}
          url={import.meta.env.VITE_SISENSE_URL}
          token={import.meta.env.VITE_SISENSE_API_TOKEN}>
          <div className="row usas-extra-bottom-margin">
            <div className="col-xs-12">
              <Headings.H3>Athlete Times Detail - Meet Summary</Headings.H3>
              <MeetSummaryDashboard dashboardOid={MEET_SUMMARY_OID} />
            </div>
          </div>
        </SisenseContextProvider>}
    </Fragment>
  );
}

export default ClubPerformanceAthleteTimesMeetSummary;