import { useExecuteQuery, useGetWidgetModel } from "@sisense/sdk-ui";
import { useLocation } from "../../../../../../common/wrappers/ReactRouterDom";

import * as DM from '../../../../../../usas-times-data';

const useMeetSummaryWidget = (dashboardOid, widgetOid) => {
  const location = useLocation();
  const queryProps = {
    dataSource: DM.DataSource, dimensions: [DM.SwimEvent.SwimEventKey, DM.SwimEvent.EventCode]
  };
  const { data } = useExecuteQuery(queryProps);
  const { widget, isLoading, isError } = useGetWidgetModel({ dashboardOid: dashboardOid, widgetOid: widgetOid });

  return {
    isLoading, isError, widget, location, eventData: data
  };
};

export default useMeetSummaryWidget;