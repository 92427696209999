import { useEffect } from 'react';

import useUsasClubPreCompLinksData from '../../../state/clubPreCompLinks/UseUsasClubPreCompLinksData';

import useBaseUsasClubData from '../../../../common/state/clubBase/UseBaseUsasClubData';

const useClubPreCompetitiveProgramsRead = () => {
  const { baseUsasClubState } = useBaseUsasClubData();
  const { usasClubPreCompLinksState, getUsasClubPreCompLinks } = useUsasClubPreCompLinksData();

  useEffect(() => {
    if (baseUsasClubState.isObjLoaded === true
      && usasClubPreCompLinksState.isArrayLoaded === false
      && usasClubPreCompLinksState.isArrayLoading === false) {
      getUsasClubPreCompLinks(baseUsasClubState.objData.usasClubId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [baseUsasClubState, usasClubPreCompLinksState]);

  return {
    baseUsasClubState,
    usasClubPreCompLinksState
  };
};

export default useClubPreCompetitiveProgramsRead;