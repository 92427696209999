import { useEffect, useState } from 'react';
import { useNavigate, useLocation } from '../../../../common/wrappers/ReactRouterDom';

import NavLinks from '../NavLinks';

import validate from '../../preComp/components/forms/PreCompLocationStaffFormValidation';

import PreCompConstants from '../../preComp/utils/PreCompConstants';

import usePreCompLocationRegistrationNavData from '../../../state/preCompLocationRegistration/preCompLocationRegistrationLeftNavBar/UsePreCompLocationRegistrationNavData';
import usePreCompLocationRegistrationData from '../../../state/preCompLocationRegistration/UsePreCompLocationRegistrationData';

import useEnvironmentVariableData from '../../../../common/state/environmentVariable/UseEnvironmentVariableData';
import useOrgRoleStaffRoleHierarchyData from '../../../../common/state/orgRoleStaffRoleHierarchy/UseOrgRoleStaffRoleHierarchyData';

import { createShallowPerson } from '../../preComp/utils/PreCompUtils';

import usePersonData from '../../../../common/state/person/UsePersonData';

import useForm from '../../../../common/utils/UseForm';
import Constants from '../../../../common/utils/Constants';
import { formatDate } from '../../../../common/utils/DateFunctions';

const usePreCompRegistrationLocationStaffDetail = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { locationRegistrationNavState } = usePreCompLocationRegistrationNavData();
  const { preCompLocationRegistrationState, saveStaffData, confirmSave } = usePreCompLocationRegistrationData();
  const { personState, getPotentialPersonDupes } = usePersonData();
  const { orgRoleStaffRoleHierarchyState } = useOrgRoleStaffRoleHierarchyData();
  const { PRIMARY_CONTACT_ORG_ROLE_ID, INSTRUCTOR_ORG_ROLE_ID, CPR_CERT_ORG_ROLE_FIELD_ID,
    FIRST_AID_CERT_ORG_ROLE_FIELD_ID, LIFEGUARD_CERT_ORG_ROLE_FIELD_ID, PRE_EMPLOYMENT_ORG_ROLE_FIELD_ID, PRECOMP_STAFF_ROLE_GROUP_ID } = useEnvironmentVariableData();
  const { formState, errorState, isSubmitting, handleSubmit, updateFormState, setFormData, setIsDirty, setErrors
  } = useForm(getInitialFormState, submitFormCallback, validate);
  const [dupGridState, setDupGridState] = useState([]);
  const [newGridState, setNewGridState] = useState([]);
  const [isPrimaryModalState, setIsPrimaryModalState] = useState(getInitialIsPrimaryModalState);
  const [displayLocalDupPersonModalState, setDisplayLocalDupPersonModalState] = useState(false);

  const onIsPrimaryModalContinueClicked = () => {
    setIsPrimaryModalState({
      ...isPrimaryModalState,
      displayModal: false
    });

    if (formState.personId !== '') {
      saveStaffData(createShallowPerson(formState, createPersonOrgRole(formState.orgRolesId)));
    } else {
      getPotentialPersonDupes(formState.firstName, formState.lastName, formState.birthDate);
    }
  };

  const onIsPrimaryModalCancelClicked = () => {
    setIsDirty(true);
    setIsPrimaryModalState(getInitialIsPrimaryModalState);
  };

  const onLocalDupPersonModalCancelClicked = () => {
    setIsDirty(true);
    setDisplayLocalDupPersonModalState(false);
  };

  const onCancelClicked = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    navigate(NavLinks.PRE_COMP_REGISTRATION_LOCATION_STAFF);
  };

  const onModalCancelClicked = () => {
    setDupGridState([]);
    setNewGridState([]);
    setIsDirty(true);
  };

  const onModalContinueClicked = () => {
    let matchFound = false;

    for (const dup of dupGridState) {
      if (dup.isSelected === true) {
        saveStaffData(createShallowPerson(dup, createPersonOrgRole(formState.orgRolesId)));
        matchFound = true;
        break;
      }
    }

    if (matchFound === false) {
      saveStaffData(createShallowPerson(formState, createPersonOrgRole(formState.orgRolesId)));
    }

    setDupGridState([]);
    setNewGridState([]);
  };

  const onSelectGridPerson = (personId) => {
    let newDupGridState = [];
    for (const dup of dupGridState) {
      newDupGridState.push({
        ...dup,
        isSelected: personId === dup.personId ? true : false
      });
    }

    setDupGridState(newDupGridState);
    setNewGridState([{
      ...newGridState[0],
      isSelected: personId ? false : true
    }]);
  };

  useEffect(() => {
    if (preCompLocationRegistrationState.isObjLoaded === false) {
      navigate(NavLinks.PRE_COMP_REGISTRATION_ENTRY);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [preCompLocationRegistrationState.isObjLoaded]);

  useEffect(() => {
    if (personState.isArrayLoaded === true) {
      if (personState.arrayData.length > 0) {
        setDupGridState(personState.arrayData.map(x => {
          let orgDuration = { orgUnitName: '', effectiveDate: '', expirationDate: '' };
          if (Array.isArray(x.orgDurations) && x.orgDurations.length > 0) {
            orgDuration = {
              orgUnitName: x.orgDurations[0].orgUnitName,
              effectiveDate: x.orgDurations[0].effectiveDate,
              expirationDate: x.orgDurations[0].expirationDate
            };
          }

          return {
            personId: x.personId,
            firstName: x.firstName,
            lastName: x.lastName,
            birthDate: x.birthDate,
            orgDuration,
            isSelected: false
          };
        }));
        setNewGridState([{
          personId: '',
          firstName: formState.firstName,
          lastName: formState.lastName,
          birthDate: formState.birthDate,
          orgDurations: { organization: '', effectiveDate: '', expirationDate: '' },
          isSelected: true
        }]);
      } else {
        saveStaffData(createShallowPerson(formState, createPersonOrgRole(formState.orgRolesId)));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [personState.isArrayLoaded]);

  useEffect(() => {
    if (preCompLocationRegistrationState.isSaved === true && isSubmitting === false) {
      confirmSave();
      navigate(NavLinks.PRE_COMP_REGISTRATION_LOCATION_STAFF);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [preCompLocationRegistrationState.isSaved, isSubmitting]);

  useEffect(() => {
    if (location.state && location.state.staffObj && orgRoleStaffRoleHierarchyState.isArrayLoaded === true) {
      const instructorFields = {
        isCPRAEDCertified: false,
        isFirstAidCertified: false,
        isLifeguardCertified: false,
        isPreEmploymentScreening: false
      };
      const staffObj = location.state.staffObj;
      const instructorRole = staffObj.personOrgRole.find(x => x.orgRoleId === INSTRUCTOR_ORG_ROLE_ID);

      if (instructorRole && Array.isArray(instructorRole.personOrgRoleFields)) {
        for (const personOrgRole of instructorRole.personOrgRoleFields) {
          if (personOrgRole.orgRoleFieldId === CPR_CERT_ORG_ROLE_FIELD_ID) {
            instructorFields.isCPRAEDCertified = personOrgRole.fieldValue === '1' ? true : false;
          } else if (personOrgRole.orgRoleFieldId === FIRST_AID_CERT_ORG_ROLE_FIELD_ID) {
            instructorFields.isFirstAidCertified = personOrgRole.fieldValue === '1' ? true : false;
          } else if (personOrgRole.orgRoleFieldId === LIFEGUARD_CERT_ORG_ROLE_FIELD_ID) {
            instructorFields.isLifeguardCertified = personOrgRole.fieldValue === '1' ? true : false;
          } else if (personOrgRole.orgRoleFieldId === PRE_EMPLOYMENT_ORG_ROLE_FIELD_ID) {
            instructorFields.isPreEmploymentScreening = personOrgRole.fieldValue === '1' ? true : false;
          }
        }
      }

      setFormData({
        ...formState,
        ...instructorFields,
        personId: staffObj.personId || '',
        firstName: staffObj.firstName || '',
        lastName: staffObj.lastName || '',
        phoneNumber: staffObj.phoneNumber || '',
        email: staffObj.email || '',
        birthDate: staffObj.birthDate
          ? formatDate(staffObj.birthDate)
          : Constants.BLANK_DATE_STRING,
        orgRolesId: staffObj.personOrgRole
          ? staffObj.personOrgRole.map(r => { return { id: r.orgRoleId, name: r.orgRoleName }; })
          : []
      });
      setIsDirty(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orgRoleStaffRoleHierarchyState]);

  function submitFormCallback(formState) {
    const currentPrimaryContact = findCurrentPrimaryContact(preCompLocationRegistrationState.objData.preCompetitiveLocationStaff);

    if (formState.personId !== '') {
      if (currentPrimaryContact !== null) {
        const areEditingPrimaryContact = currentPrimaryContact.personId === formState.personId;
        const isPrimaryContactRoleSelected = formState.orgRolesId.findIndex(x => x.id === PRIMARY_CONTACT_ORG_ROLE_ID) >= 0;
        const isSecondaryContactRoleSelected = formState.orgRolesId.findIndex(x => x.name === PreCompConstants.ORG_ROLE_NAME_SECONDARY_CONTACT) >= 0;

        if (isPrimaryContactRoleSelected === true && isSecondaryContactRoleSelected === true) {
          setErrors({ ...errorState, orgRolesId: 'The Primary Contact cannot also be a Secondary Contact' });
        } else if (areEditingPrimaryContact === true && isPrimaryContactRoleSelected === false) {
          setIsPrimaryModalState({
            ...isPrimaryModalState,
            currentPrimaryContactId: currentPrimaryContact.personId,
            currentPrimaryContact,
            isEditingCurrentPrimaryContact: true,
            displayModal: true
          });
        } else if (areEditingPrimaryContact === false && isPrimaryContactRoleSelected === true) {
          setIsPrimaryModalState({
            ...isPrimaryModalState,
            currentPrimaryContactId: currentPrimaryContact.personId,
            currentPrimaryContact,
            isEditingCurrentPrimaryContact: false,
            isOnlyRole: currentPrimaryContact.personOrgRole.length === 1,
            displayModal: true
          });
        } else {
          saveStaffData(createShallowPerson(formState, createPersonOrgRole(formState.orgRolesId)));
        }
      } else {
        saveStaffData(createShallowPerson(formState, createPersonOrgRole(formState.orgRolesId)));
      }
    } else {
      const localDupPerson = findLocalDupPerson(preCompLocationRegistrationState.objData.preCompetitiveLocationStaff);

      if (localDupPerson) {
        setDisplayLocalDupPersonModalState(true);
      } else {
        if (currentPrimaryContact !== null && formState.orgRolesId.findIndex(x => x.id === PRIMARY_CONTACT_ORG_ROLE_ID) >= 0) {
          setIsPrimaryModalState({
            ...isPrimaryModalState,
            currentPrimaryContactId: currentPrimaryContact.personId,
            currentPrimaryContact,
            isEditingCurrentPrimaryContact: false,
            isOnlyRole: currentPrimaryContact.personOrgRole.length === 1,
            displayModal: true
          });
        } else {
          getPotentialPersonDupes(formState.firstName, formState.lastName, formState.birthDate);
        }
      }
    }
  };

  function createPersonOrgRole(orgRolesId) {
    const personOrgRole = [];

    for (const role of orgRolesId) {
      if (role.id === INSTRUCTOR_ORG_ROLE_ID) {
        personOrgRole.push({
          orgRoleId: role.id,
          orgRoleName: role.name,
          personOrgRoleFields: [
            { fieldName: "CPR/AED Certification", orgRoleFieldId: CPR_CERT_ORG_ROLE_FIELD_ID, fieldValue: formState.isCPRAEDCertified ? '1' : '0' },
            { fieldName: "First Aid Certification", orgRoleFieldId: FIRST_AID_CERT_ORG_ROLE_FIELD_ID, fieldValue: formState.isFirstAidCertified ? '1' : '0' },
            { fieldName: "Lifeguard Certification", orgRoleFieldId: LIFEGUARD_CERT_ORG_ROLE_FIELD_ID, fieldValue: formState.isLifeguardCertified ? '1' : '0' },
            { fieldName: "Pre-employment Screening", orgRoleFieldId: PRE_EMPLOYMENT_ORG_ROLE_FIELD_ID, fieldValue: formState.isPreEmploymentScreening ? '1' : '0' }
          ]
        });
      }
      else {
        personOrgRole.push({
          orgRoleId: role.id,
          orgRoleName: role.name,
          personOrgRoleFields: []
        });
      }
    }

    return personOrgRole;
  };

  function findCurrentPrimaryContact(staffArray) {
    if (Array.isArray(staffArray) && staffArray.length > 0) {
      for (const staff of staffArray) {
        if (Array.isArray(staff.personOrgRole)) {
          const primaryContactRoleIndex = staff.personOrgRole.findIndex(x => x.orgRoleId === PRIMARY_CONTACT_ORG_ROLE_ID);

          if (primaryContactRoleIndex >= 0) {
            return staff;
          }
        }
      }
    }

    return null;
  };

  function findLocalDupPerson(staffArray) {
    if (Array.isArray(staffArray) && staffArray.length > 0) {
      for (const staff of staffArray) {
        const birthDate = formatDate(staff.birthDate);
        const firstName = staff.firstName;
        const lastName = staff.lastName;

        if (formState.birthDate === birthDate && firstName === formState.firstName && lastName === formState.lastName) {
          return staff;
        }
      }
    }

    return null;
  };

  function getInitialFormState() {
    return {
      personId: '',
      firstName: '',
      lastName: '',
      phoneNumber: '',
      email: '',
      birthDate: Constants.BLANK_DATE_STRING,
      orgRolesId: [],
      isCPRAEDCertified: false,
      isFirstAidCertified: false,
      isLifeguardCertified: false,
      isPreEmploymentScreening: false
    };
  };

  function getInitialIsPrimaryModalState() {
    return {
      currentPrimaryContactId: Constants.DEFAULT_ID,
      currentPrimaryContact: {},
      isEditingCurrentPrimaryContact: false,
      isOnlyRole: false,
      displayModal: false
    };
  };

  return {
    isLoading: personState.isArrayLoading || orgRoleStaffRoleHierarchyState.isArrayLoading,
    isEdit: formState.personId !== '',
    formState,
    errorState,
    dupGridState,
    newGridState,
    isPrimaryModalState,
    locationRegistrationNavState,
    displayLocalDupPersonModalState,
    handleSubmit,
    onFormValueChanged: updateFormState,
    onCancelClicked,
    onModalCancelClicked,
    onModalContinueClicked,
    onLocalDupPersonModalCancelClicked,
    onSelectGridPerson,
    onIsPrimaryModalCancelClicked,
    onIsPrimaryModalContinueClicked,
    PRECOMP_STAFF_ROLE_GROUP_ID,
    INSTRUCTOR_ORG_ROLE_ID
  };
};

export default usePreCompRegistrationLocationStaffDetail;