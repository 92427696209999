import React, { createContext, useState } from 'react';

import RecognitionProgramData from './RecognitionProgramData';

export const RecognitionProgramStateContext = createContext();

const RecognitionProgramContextProvider = ({ children }) => {
  const stateHook = useState(RecognitionProgramData.INITIAL_RECOGNITION_PROGRAM_STATE);

  return (
    <RecognitionProgramStateContext.Provider value={stateHook}>
      {children}
    </RecognitionProgramStateContext.Provider>
  );
};

export default RecognitionProgramContextProvider;