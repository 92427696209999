import React from 'react';

import UnlinkIcon from '../../../../../common/components/icons/UnlinkIcon';

import global from '../../../../../common/components/GlobalStyle.module.css';

const GridRow = ({ club, onDelete }) => (
  <tr>
    <td>{club.clubInfo?.name || ''}</td>
    <td>{club.clubInfo?.code || ''}</td>
    <td>{club.clubInfo?.location || ''}</td>
    <td>{club.clubInfo?.lsc || ''}</td>
    <td>
      {onDelete !== undefined &&
        <button
          className={global.IconButtonMargin}
          type="button"
          onClick={() => onDelete(club.usasClubId)}>
          <UnlinkIcon />
        </button>
      }
    </td>
  </tr>
);

const PreCompLocationClubGridLarge = ({ data, isLoading, onDelete }) => (
  <table className={[global.UsasTable, 'visible-md visible-lg'].join(' ')}>
    <thead>
      <tr>
        <th>Name</th>
        <th>Code</th>
        <th>Location</th>
        <th>LSC</th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      {isLoading === true
        ? <tr><td colSpan="5">Loading...</td></tr>
        : data.length > 0
          ? data.map((club, i) => <GridRow key={i} club={club} onDelete={onDelete} />)
          : <tr><td colSpan="5">No Clubs</td></tr>
      }
    </tbody>
  </table>
);

export default PreCompLocationClubGridLarge;