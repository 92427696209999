import React, { createContext, useState } from 'react';

import MemberAdministrationLscSecurityRolesData from './MemberAdministrationLscSecurityRolesData';

export const MemberAdministrationLscSecurityRolesStateContext = createContext();

const MemberAdministrationLscSecurityRolesContextProvider = ({ children }) => {
  const stateHook = useState(MemberAdministrationLscSecurityRolesData.INITIAL_STATE);

  return (
    <MemberAdministrationLscSecurityRolesStateContext.Provider value={stateHook}>
      {children}
    </MemberAdministrationLscSecurityRolesStateContext.Provider>
  );
};

export default MemberAdministrationLscSecurityRolesContextProvider;