/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Fragment } from 'react';

import HideIcon from '../../icons/HideIcon';
import ShowIcon from '../../icons/ShowIcon';
import ActionIntraPageButton from '../../buttons/ActionIntraPageButton';

import Constants from '../../../utils/Constants';
import { formatDate } from '../../../utils/DateFunctions';

import global from '../../GlobalStyle.module.css';

const DetailTableRow = ({ programCategory, programLevel, onEdit }) => (
  <tr>
    <td><a onClick={(e) => onEdit(e, programLevel.programLevelInstanceId, programCategory.programCategoryId)}>
      {programCategory.categoryName}
    </a></td>
    <td>{programCategory.minimumValue >= 0 ? programCategory.minimumValue : ''}</td>
    <td>{programCategory.currentPointValue >= 0 && programCategory.currentPointValue !== null ? programCategory.currentPointValue : '0'}</td>
  </tr>
);

const DetailTable = ({ programCategory, programLevel, onEdit }) => (
  <table className={global.DetailTable}>
    <thead>
      <tr>
        <th>Program Category Name</th>
        <th>Minimum Points</th>
        <th>Current Points</th>
      </tr>
    </thead>
    <tbody>
      {programCategory.map((programCategory, i) =>
        <DetailTableRow key={i} programCategory={programCategory} programLevel={programLevel} onEdit={onEdit} />)}
    </tbody>
  </table>
);

const TableRow = ({ programLevel, previousProgramLevel, onSubmit, onEdit, showStatus, expandedId, onClick }) => (
  <Fragment>
    <tr>
      <td>{programLevel.levelName}</td>
      <td>{programLevel.effectiveDate ? formatDate(programLevel.effectiveDate) : ''}</td>
      <td>{programLevel.expirationDate ? formatDate(programLevel.expirationDate) : ''}</td>
      <td>{programLevel.minimumValue >= 0 ? programLevel.minimumValue : ''}</td>
      <td>{programLevel.currentPointValue >= 0 && programLevel.currentPointValue !== null ? programLevel.currentPointValue : '0'}</td>
      {showStatus === true ?
        <td>
          {programLevel.status}
          {(programLevel.status === Constants.WORKFLOW_STATUS_NOT_SUBMITTED || programLevel.status === Constants.WORKFLOW_STATUS_IN_PROGRESS)
            && (previousProgramLevel?.status === Constants.WORKFLOW_STATUS_ACHIEVED || previousProgramLevel?.status === Constants.WORKFLOW_STATUS_RENEWED) ?
            <div className={global.FloatRight}>
              <ActionIntraPageButton type="button" onClick={(e) => onSubmit(e, programLevel.programLevelInstanceId)}>
                Submit Level
              </ActionIntraPageButton>
            </div> : <Fragment />}
        </td> : <Fragment />}
      <td>
        <button
          className={global.IconButtonMargin}
          type="button"
          onClick={(e) => onClick(e, programLevel.programLevelInstanceId)}>
          {expandedId === programLevel.programLevelInstanceId ? <HideIcon /> : <ShowIcon />}
        </button>
      </td>
    </tr>
    {Array.isArray(programLevel.programCategory) && programLevel.programCategory.length > 0 && expandedId === programLevel.programLevelInstanceId &&
      <tr className={global.Expanded}>
        <td colSpan="7">
          <DetailTable programCategory={programLevel.programCategory} programLevel={programLevel} onEdit={onEdit} />
        </td>
      </tr>}
  </Fragment>
);

const ProgramLevelGridLarge = ({ data, isLoading, onSubmit, onEdit, showStatus, expandedId, onClick }) => (
  <Fragment>
    <table className={[global.UsasTable, 'visible-md visible-lg'].join(' ')}>
      <thead>
        <tr>
          <th>Program Level Name</th>
          <th>Effective Date</th>
          <th>Expiration Date</th>
          <th>Minimum Points</th>
          <th>Current Points</th>
          {showStatus === true ?
            <th>Status</th> : <Fragment />}
          <th></th>
        </tr>
      </thead>
      <tbody>
        {isLoading === true ? (
          <tr>
            {showStatus === true ? <td colSpan="7">Loading...</td> : <td colSpan="6">Loading...</td>}
          </tr>
        ) : Array.isArray(data) && data.length > 0
          ? data.map((programLevel, i) =>
            <TableRow key={i} programLevel={programLevel} previousProgramLevel={i > 0 ? data[i - 1].programLevel : { status: 'Achieved' }}
              onSubmit={onSubmit} onEdit={onEdit} showStatus={showStatus} expandedId={expandedId} onClick={onClick} />
          ) : (
            <tr>
              {showStatus === true ? <td colSpan="7">No Program Levels</td> : <td colSpan="6">No Program Levels</td>}
            </tr>
          )
        }
      </tbody>
    </table>
  </Fragment >
);

export default ProgramLevelGridLarge;