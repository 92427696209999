import { useContext } from 'react';

import { CategoryMeasureScoreStateContext } from './CategoryMeasureScoreContextProvider';

import CategoryMeasureScoreData from './CategoryMeasureScoreData';

import Constants from '../../../common/utils/Constants';

const useCategoryMeasureScoreData = () => {
  const [categoryMeasureScoreState, setCategoryMeasureScoreState] = useContext(CategoryMeasureScoreStateContext);

  const postCategoryMeasureScore = (obj) => {
    CategoryMeasureScoreData.postCategoryMeasureScoreData(obj, categoryMeasureScoreState, setCategoryMeasureScoreState);
  };

  const putCategoryMeasureScore = (id, obj) => {
    CategoryMeasureScoreData.putCategoryMeasureScoreData(id, obj, categoryMeasureScoreState, setCategoryMeasureScoreState);
  };

  const clearCategoryMeasureScoreObjData = () => {
    setCategoryMeasureScoreState({
      ...categoryMeasureScoreState,
      isObjLoading: false,
      isObjLoaded: false,
      isSaving: false,
      isSaved: false,
      objData: {}
    });
  };

  const clearCategoryMeasureScoreArrayData = () => {
    setCategoryMeasureScoreState({
      ...categoryMeasureScoreState,
      isArrayLoading: false,
      isArrayLoaded: false,
      isSaving: false,
      isSaved: false,
      arrayData: [],
      orgUnitId: '',
      programTypeId: Constants.DEFAULT_ID
    });
  };

  const confirmSave = () => {
    setCategoryMeasureScoreState({
      ...categoryMeasureScoreState,
      isSaved: false
    });
  };

  return {
    categoryMeasureScoreState,
    setCategoryMeasureScoreState,
    postCategoryMeasureScore,
    putCategoryMeasureScore,
    clearCategoryMeasureScoreObjData,
    clearCategoryMeasureScoreArrayData,
    confirmSave
  };
};

export default useCategoryMeasureScoreData;