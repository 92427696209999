import { Fragment } from "react";
import useLearnToSwimVoucher from "./UseLearnToSwimVoucher";
import Headings from "../../../../common/components/headings/Headings";

import LearnToSwimVoucherForm from "./LearnToSwimVoucherForm";
import PopUpModal from "../../../../common/components/dialogs/PopUpModal";
import Constants from "../../../../common/utils/Constants";
import SecondaryButton from "../../../../common/components/buttons/SecondaryButton";

const LearnToSwimVoucher = () => {
  const {
    routeCodeValueState,
    ltsParticipantState,
    ltsVoucherCityState,
    state,
    modalState,
    onSubmitFormCallback,
    onRegisterAnotherParticipant,
    onModalCancelClicked
  } = useLearnToSwimVoucher();

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>Learn To Swim Voucher</Headings.H3>
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H4>{ltsVoucherCityState.arrayData.cityName}</Headings.H4>
        </div>
      </div>
      {state.isExpired === true &&
        <div className="row">
          <div className="col-xs-12 usas-extra-top-margin usas-extra-bottom-margin">
            This voucher has expired or reached its participant cap.
          </div>
        </div>
      }
      {ltsParticipantState.isObjLoaded === false && state.isExpired === false ?
        <LearnToSwimVoucherForm
          onSubmitFormCallback={onSubmitFormCallback}
        /> : ltsParticipantState.isObjLoaded === true &&
        <div className="row">
          <div className="col-xs-12 usas-extra-top-margin usas-extra-bottom-margin">
            Congratulations on signing up for a free swim lesson voucher. If you want to sign up someone else, please click 'Register Another Participant'<br /><br />
            <SecondaryButton type="button" onClick={onRegisterAnotherParticipant}>Register Another Participant</SecondaryButton>
          </div>
        </div>
      }
      <PopUpModal
        widthPct={90}
        maxWidth={720}
        title="Duplicate Learn To Swim Voucher Participant Detected"
        displayPopUp={modalState.displayModal}
        onModalCanceled={onModalCancelClicked}>
        <div className="row">
          <div className="col-xs-12">
            <p>Our records indicate that a Learn To Swim Participant with the same name and birthdate has already been registered in our system. </p>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12 usas-extra-top-margin">
            <SecondaryButton type="button" onClick={onModalCancelClicked}>Cancel</SecondaryButton>
          </div>
        </div>
      </PopUpModal>
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.SAVING_MSG}
        displayPopUp={ltsParticipantState.isObjLoading} />
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={routeCodeValueState.isObjLoading || ltsVoucherCityState.isArrayLoading} />
    </Fragment>)
}

export default LearnToSwimVoucher;