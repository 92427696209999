import React, { createContext, useState } from 'react';

import LearnToSwimRegistrationLeftNavData from './LearnToSwimRegistrationLeftNavData';

export const LearnToSwimRegistrationLeftNavStateContext = createContext();

const INITIAL_STATE = {
  isLoaded: false,
  arrayData: LearnToSwimRegistrationLeftNavData.INITIAL_ARRAY_DATA,
  objData: LearnToSwimRegistrationLeftNavData.INITIAL_OBJ_DATA
};

const LearnToSwimRegistrationLeftNavContextProvider = ({ children }) => {
  const stateHook = useState(INITIAL_STATE);

  return (
    <LearnToSwimRegistrationLeftNavStateContext.Provider value={stateHook}>
      {children}
    </LearnToSwimRegistrationLeftNavStateContext.Provider>
  );
};

export default LearnToSwimRegistrationLeftNavContextProvider;