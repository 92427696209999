import { Fragment } from 'react';

import ClubThirdPartyInvoicesDetailPersonPotentialMatchGridLarge from './ClubThirdPartyInvoicesDetailPersonPotentialMatchGridLarge';
import ClubThirdPartyInvoicesDetailPersonPotentialMatchGridSmall from './ClubThirdPartyInvoicesDetailPersonPotentialMatchGridSmall';

const ClubThirdPartyInvoicesDetailPersonPotentialMatchGrid = ({ gridData, isLoading, onSelected }) => {
  return (
    <Fragment>
      {isLoading === false
        ? (
          <div className="row">
            <div className="col-xs-12">
              <p>{gridData.length === 1 ? `${gridData.length} Potential Match` : `${gridData.length} Potential Matches`}</p>
            </div>
          </div>
        ) : <Fragment><div className="row usas-extra-top-margin usas-extra-bottom-margin"></div></Fragment>
      }
      <ClubThirdPartyInvoicesDetailPersonPotentialMatchGridLarge
        gridData={gridData}
        isLoading={isLoading}
        onSelected={onSelected}
      />
      <ClubThirdPartyInvoicesDetailPersonPotentialMatchGridSmall
        gridData={gridData}
        isLoading={isLoading}
        onSelected={onSelected}
      />
    </Fragment>
  );
}

export default ClubThirdPartyInvoicesDetailPersonPotentialMatchGrid;