import React from 'react';
import { Dialog } from '@reach/dialog';

import useLoginPopup from './UseLoginPopup';

import Headings from '../../../../../common/components/headings/Headings';
import PrimaryButton from '../../../../../common/components/buttons/PrimaryButton';

import style from './LoginPopup.module.css';

const LoginPopup = () => {
  const {
    isLoggedIn,
    onLoginClicked
  } = useLoginPopup();

  return (
    <Dialog
      isOpen={isLoggedIn === false}
      allowPinchZoom={true}
      aria-label={'Please Login'}
      style={{
        width: '75%',
        maxWidth: '75%'
      }}>
      <div className='row'>
        <div className="col-xs-12">
          <Headings.H2 excludeBar={true} className={style.CenterAlign}>USA Swimming Learn To Swim Provider Registration</Headings.H2>
          <hr className="usas-bar-turmeric" />
          <Headings.H3 excludeBar={true} className={style.CenterAlign}>Welcome</Headings.H3>
          <div className={'row'}>
            <div className={style.CenterAlign}>
              You are currently not logged in to the USA Swimming site. Click the 'Login' button below
              to be redirected to a screen that will allow you to login or create a login. After you have logged in,
              you will be redirected back to this page and allowed to continue the Learn To Swim Registration process.
            </div>
          </div>
          <div className='row usas-extra-top-margin'>
            <div className={style.CenterAlign}>
              <PrimaryButton onClick={onLoginClicked}>Login</PrimaryButton>
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default LoginPopup;