import React, { Fragment } from 'react';

import useLSCBoardMembers from './UseLSCBoardMembers';

import LSCBoardMembersGrid from './LSCBoardMembersGrid';

import YesNoSwitch from '../../../../common/components/yesNoSwitch/YesNoSwitch';
import ActionIntraPageButton from '../../../../common/components/buttons/ActionIntraPageButton';
import Headings from '../../../../common/components/headings/Headings';
import PopUpModal from '../../../../common/components/dialogs/PopUpModal';
import Constants from '../../../../common/utils/Constants';

const LSCBoardMembers = () => {
  const {
    isSaving,
    isGridLoading,
    viewState,
    gridState,
    onFilterCurrentMembers,
    onAddBoardMember,
    onEditBoardMember,
    onDeleteBoardMember
  } = useLSCBoardMembers();

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>LSC Board of Directors</Headings.H3>
        </div>
      </div>
      <div className="row" >
        <div className="col-xs-12 col-sm-8 col-lg-9 usas-extra-top-margin">
          <ActionIntraPageButton type="button" onClick={onAddBoardMember}>Add Board Member</ActionIntraPageButton>
        </div>
        <div className="col-xs-12 col-sm-4 col-lg-3">
          <YesNoSwitch
            label="Show Only Current Members/Roles?"
            name="toggleCurrentStaffRoles"
            checked={viewState.toggleCurrentStaffRoles}
            onChange={onFilterCurrentMembers} />
        </div>
      </div>
      <div className="row usas-extra-bottom-margin">
        <div className="col-xs-12">
          <LSCBoardMembersGrid
            data={gridState}
            isLoading={isGridLoading}
            onEdit={onEditBoardMember}
            onDelete={onDeleteBoardMember} />
        </div>
      </div>
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={isGridLoading} />
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.SAVING_MSG}
        displayPopUp={isSaving} />
    </Fragment>
  );
};

export default LSCBoardMembers;