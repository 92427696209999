import { isValidUSOrCanadianPhoneNumber, isValidMexicanPhoneNumber, isValidEmail, isValidName } from '../../../../common/utils/validation';

export const localValidate = (formState) => {
  let errors = {};

  //Facility Contact Name
  if (formState.contactName.trim() === '') {
    errors.contactName = 'Facility Primary Contact Name is required'
  } else if (!isValidName(formState.contactName.trim())) {
    errors.contactName = 'Facility Primary Contact Name cannot contain numbers, special characters, or exceed 100 characters';
  }
  //Facility Contact Email Address
  if (formState.contactEmailAddress.trim() === '') {
    errors.contactEmailAddress = 'Facility Email is required'
  } else if (!isValidEmail(formState.contactEmailAddress.trim())) {
    errors.contactEmailAddress = 'Facility Email must be in a valid format';
  } else if (formState.contactEmailAddress.trim().length > 512) {
    errors.contactEmailAddress = 'Facility Email cannot exceed 512 characters'
  }
  //Facility Contact Phone Number
  if (formState.contactPhoneNumber.trim() === '') {
    errors.contactPhoneNumber = 'Facility Phone Number is required'
  } else if (!isValidUSOrCanadianPhoneNumber(formState.contactPhoneNumber) && !isValidMexicanPhoneNumber(formState.contactPhoneNumber)) {
    errors.contactPhoneNumber = 'Must enter a valid US, Canadian, or Mexican phone number in +1/+52 (XXX) XXX-XXXX format'
  }

  //Club Facility Contact Name
  if (formState.clubFacilityContactName.trim() === '') {
    errors.clubFacilityContactName = 'Name of Club Contact for Facility is required'
  } else if (!isValidName(formState.clubFacilityContactName.trim())) {
    errors.clubFacilityContactName = 'Name of Club Contact for Facility cannot contain numbers, special characters, or exceed 100 characters';
  }
  //Club Facility Contact Email Address
  if (formState.clubFacilityContactEmailAddress.trim() === '') {
    errors.clubFacilityContactEmailAddress = 'Email of Club Contact for Facility is required'
  } else if (!isValidEmail(formState.clubFacilityContactEmailAddress.trim())) {
    errors.clubFacilityContactEmailAddress = 'Email of Club Contact for Facility must be in a valid format';
  } else if (formState.clubFacilityContactEmailAddress.trim().length > 512) {
    errors.clubFacilityContactEmailAddress = 'Email of Club Contact for Facility cannot exceed 512 characters'
  }

  //Club Facility Contact Phone Number
  if (formState.clubFacilityContactPhoneNumber.trim() === '') {
    errors.clubFacilityContactPhoneNumber = 'Phone Number of Club Contact for Facility is required'
  } else if (!isValidUSOrCanadianPhoneNumber(formState.clubFacilityContactPhoneNumber) && !isValidMexicanPhoneNumber(formState.clubFacilityContactPhoneNumber)) {
    errors.clubFacilityContactPhoneNumber = 'Must enter a valid US, Canadian, or Mexican phone number in +1/+52 (XXX) XXX-XXXX format'
  }

  if (formState.trainingFacility !== true && formState.trainingFacility !== false) {
    errors.trainingFacility = 'Must select yes or no';
  }

  return errors;
};

const ClubRegistrationFacilityDetailsValidation = async (formState) => {
  let errors = localValidate(formState);

  return errors ? errors : {};
}

export default ClubRegistrationFacilityDetailsValidation;