import Constants from "../../utils/Constants";

const INITIAL_STATE = {
  showPage: false,
  poolId: Constants.DEFAULT_ID,
  poolName: '',
  poolDimensionsId: Constants.DEFAULT_ID,
  poolDimensions: '',
  poolConfiguration: [],
  poolConfigurationIndex: Constants.DEFAULT_ID,
  poolConfigurationId: Constants.DEFAULT_ID,
  course: '',
  numberOfLanes: '',
  updatePoolConfigGrid: false
};

const PoolConfigurationData = {
  INITIAL_STATE
}

export default PoolConfigurationData;