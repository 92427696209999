import { useEffect, useState } from 'react';
import { useNavigate } from '../../../../common/wrappers/ReactRouterDom';

import { navLinks } from '../UseMemberAdministration';

import useMemberAdministrationLscStaffData from '../../../../common/state/memberAdministration/lscStaff/UseMemberAdministrationLscStaffData';
import useEnvironmentVariableData from '../../../../common/state/environmentVariable/UseEnvironmentVariableData';
import useSelectOrgUnitData from '../../../../common/state/selectOrgUnit/UseSelectOrgUnitData';

import Constants from '../../../../common/utils/Constants';
import { formatDate } from '../../../../common/utils/DateFunctions';
import { formatPhoneNumber } from '../../../../common/utils/validation';

const useLSCStaffMembers = () => {
  const navigate = useNavigate();
  const { environmentVariableState } = useEnvironmentVariableData();
  const { memberAdministrationLscStaffState, getLscStaff, deleteLscStaff, confirmSave } = useMemberAdministrationLscStaffData();
  const { selectOrgUnitState } = useSelectOrgUnitData();
  const [viewState, setViewState] = useState(getInitialViewState);
  const [gridState, setGridState] = useState([]);
  const [deleteModalState, setDeleteModalState] = useState(getInitialDeleteModalState);

  const onAddStaffMember = () => {
    navigate(navLinks.LSC_STAFF_ADD, { state: { activeUserOrgUnitId: selectOrgUnitState.orgUnitId } });
  };

  const onEditStaffRole = (personId, orgRoleId, personOrgRoleDurationId) => {
    navigate(navLinks.LSC_STAFF_EDIT, { state: { personId, orgRoleId, personOrgRoleDurationId, activeUserOrgUnitId: selectOrgUnitState.orgUnitId } });
  };

  const onDeleteStaffRole = (personId, personOrgRoleDurationId) => {
    if (personId && personOrgRoleDurationId > 0) {
      const targetPerson = gridState.find(x => x.personId === personId);

      if (targetPerson) {
        const targetRole = targetPerson.roleDurations.find(x => x.personOrgRoleDurationId);

        if (targetRole) {
          setDeleteModalState({
            ...deleteModalState,
            personId,
            personOrgRoleDurationId,
            personName: `${targetPerson.firstName} ${targetPerson.lastName}`,
            orgRoleName: targetRole.orgRoleName,
            targetPerson,
            displayModal: true
          });
        }
      }
    }
  };

  const onModalDeleteClicked = () => {
    deleteLscStaff(deleteModalState.personOrgRoleDurationId);
    setDeleteModalState(getInitialDeleteModalState);
  };

  const onModalCancelClicked = () => {
    setDeleteModalState(getInitialDeleteModalState);
  };

  const onFilterCurrentStaff = () => {
    if (memberAdministrationLscStaffState.isArrayLoading === false) {
      setViewState({
        ...viewState,
        toggleCurrentStaffRoles: !viewState.toggleCurrentStaffRoles
      });
      setGridState(filterStaffMembersForGrid(memberAdministrationLscStaffState.arrayData, !viewState.toggleCurrentStaffRoles));
    }
  };

  useEffect(() => {
    if (selectOrgUnitState?.orgUnitId) {
      if (memberAdministrationLscStaffState.isSaved === true) {
        confirmSave();
      } else {
        getLscStaff(selectOrgUnitState.orgUnitId);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectOrgUnitState?.orgUnitId, memberAdministrationLscStaffState.isSaved]);

  useEffect(() => {
    if (selectOrgUnitState?.orgUnitId && memberAdministrationLscStaffState.isArrayLoading === false
      && memberAdministrationLscStaffState.isArrayLoaded === true) {
      setGridState(filterStaffMembersForGrid(memberAdministrationLscStaffState.arrayData, viewState.toggleCurrentStaffRoles));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectOrgUnitState?.orgUnitId, memberAdministrationLscStaffState]);

  function filterStaffMembersForGrid(arrayData, toggleCurrentStaffRoles = false) {
    const today = new Date();
    const gridData = [];

    for (const staff of arrayData) {
      const roleDurations = [];
      if (Array.isArray(staff.personOrgRole) && staff.personOrgRole.length > 0) {
        for (const role of staff.personOrgRole) {
          if (Array.isArray(role.personDurations) && role.personDurations.length > 0) {
            if (toggleCurrentStaffRoles === true) {
              for (const duration of role.personDurations) {
                if (duration.isCurrent === true) {
                  roleDurations.push({
                    personOrgRoleDurationId: duration.personOrgRoleDurationId || Constants.DEFAULT_ID,
                    paidOrVolunteer: duration.paidOrVolunteer || '',
                    effectiveDate: duration.effectiveDate ? formatDate(duration.effectiveDate) : '',
                    expirationDate: duration.expirationDate ? formatDate(duration.expirationDate) : '',
                    isCurrent: duration.isCurrent === true ? true : false,
                    canDelete: new Date(duration.expirationDate) > today ? true : false,
                    orgRoleId: role.orgRoleId || '',
                    orgRoleName: role.orgRoleName || '',
                    orgRoleEmail: role.orgRoleEmail || ''
                  });
                }
              }
            } else {
              for (const duration of role.personDurations) {
                roleDurations.push({
                  personOrgRoleDurationId: duration.personOrgRoleDurationId || Constants.DEFAULT_ID,
                  paidOrVolunteer: duration.paidOrVolunteer || '',
                  effectiveDate: duration.effectiveDate ? formatDate(duration.effectiveDate) : '',
                  expirationDate: duration.expirationDate ? formatDate(duration.expirationDate) : '',
                  isCurrent: duration.isCurrent === true ? true : false,
                  canDelete: new Date(duration.expirationDate) > today ? true : false,
                  orgRoleId: role.orgRoleId || '',
                  orgRoleName: role.orgRoleName || '',
                  orgRoleEmail: role.orgRoleEmail || ''
                });
              }
            }
          }
        }
      }

      if (roleDurations.length > 0) {
        roleDurations.sort((a, b) => new Date(b.expirationDate) - new Date(a.expirationDate));

        gridData.push({
          personId: staff.personId || '',
          firstName: staff.firstName || '',
          lastName: staff.lastName || '',
          preferredName: staff.preferredName || '',
          email: staff.email || '',
          phoneNumber: staff.phoneNumber ? formatPhoneNumber(staff.phoneNumber) : '',
          roleDurations
        });
      }
    }

    return gridData;
  };

  function getInitialViewState() {
    return {
      toggleCurrentStaffRoles: true
    };
  };

  function getInitialDeleteModalState() {
    return {
      personId: '',
      personOrgRoleDurationId: Constants.DEFAULT_ID,
      personName: '',
      orgRoleName: '',
      targetPerson: {},
      displayModal: false
    };
  };

  return {
    isLoading: environmentVariableState.isLoading,
    isSaving: memberAdministrationLscStaffState.isSaving,
    isGridLoading: memberAdministrationLscStaffState.isArrayLoading,
    gridState,
    viewState,
    deleteModalState,
    onAddStaffMember,
    onEditStaffRole,
    onDeleteStaffRole,
    onFilterCurrentStaff,
    onModalDeleteClicked,
    onModalCancelClicked
  };
};

export default useLSCStaffMembers;