import React from 'react';

import global from '../../GlobalStyle.module.css';

const GridRow = ({ coach }) => (
  <div className={global.SmallTableRow}>
    <div className={global.SmallTableRowHead}>
      <span className='hidden-xs'>{coach.firstName} {coach.lastName}</span>&nbsp;
    </div>
    <div className={global.SmallTableRowInfo}>
      <div className='row'>
        <div className={['col-xs-6 col-sm-3 visible-xs', global.SmallTableRowLabels].join(' ')}>Coach Name</div>
        <div className={['col-xs-6 col-sm-3 visible-xs', global.SmallTableRowData].join(' ')}>{coach.firstName} {coach.lastName}</div>
        <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>Role</div>
        <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>{coach.orgRoleName || <span>&nbsp;</span>}</div>
        <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>Years</div>
        <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>{coach.years || <span>&nbsp;</span>}</div>
      </div>
    </div>
  </div>
);

const HeadCoachReportGridSmall = ({ data, isLoading }) => (
  <div className={[global.SmallTable, 'hidden-md hidden-lg'].join(' ')}>
    {isLoading === true ? (
      <div className={global.SmallTableRow}>
        <div className={global.SmallTableRowHead}>&nbsp;</div>
        <div className={global.SmallTableRowInfo}>
          <div className={global.SmallTableRowLabels}>Loading...</div>
        </div>
      </div>
    ) : Array.isArray(data) && data.length > 0 ?
      data.map((coach, i) => <GridRow key={i} coach={coach} />)
      : (
        <div className={global.SmallTableRow}>
          <div className={global.SmallTableRowHead}>&nbsp;</div>
          <div className={global.SmallTableRowInfo}>
            <div className={global.SmallTableRowLabels}>No Head Coach</div>
          </div>
        </div>
      )
    }
  </div>
);

export default HeadCoachReportGridSmall;