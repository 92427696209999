export const localValidate = (formState) => {
  let errors = {};

  if (formState.registrationSignature === '') {
    errors.registrationSignature = "Registration Signature is required"
  }

  return errors;
};

const ClubRenewalRenewValidation = async (formState) => {
  let errors = localValidate(formState);

  return errors ? errors : {};
}

export default ClubRenewalRenewValidation;