import { useState } from "react";

import ClubCommunityImpactBulkImportUploadData from "./ClubCommunityImpactBulkImportUploadData";

const useClubCommunityImpactBulkImportUploadData = () => {
  const [clubCommunityImpactBulkImportUploadState, setClubCommunityImpactBulkImportUploadState] = useState(ClubCommunityImpactBulkImportUploadData.INITIAL_STATE);

  const postValidateClubCommunityImpactBulkImportIds = (orgUnitId, offeringInstanceId, offeringInstanceCouponId, memberIds) => ClubCommunityImpactBulkImportUploadData.postValidateClubCommunityImpactBulkImportIds(orgUnitId, offeringInstanceId, offeringInstanceCouponId, memberIds, clubCommunityImpactBulkImportUploadState, setClubCommunityImpactBulkImportUploadState);

  const postProcessClubCommunityImpactBulkImportIds = (orgUnitId, offeringInstanceId, offeringInstanceCouponId, memberIds) => ClubCommunityImpactBulkImportUploadData.postProcessClubCommunityImpactBulkImportIds(orgUnitId, offeringInstanceId, offeringInstanceCouponId, memberIds, clubCommunityImpactBulkImportUploadState, setClubCommunityImpactBulkImportUploadState);

  const resetClubCommunityImpactBulkImportUploadState = () => {
    setClubCommunityImpactBulkImportUploadState({ ...ClubCommunityImpactBulkImportUploadData.INITIAL_STATE });
  };

  const confirmSave = () => {
    setClubCommunityImpactBulkImportUploadState({
      ...clubCommunityImpactBulkImportUploadState,
      isSaved: false
    });
  };

  const resetState = () => {
    setClubCommunityImpactBulkImportUploadState(ClubCommunityImpactBulkImportUploadData.INITIAL_STATE);
  }

  return {
    clubCommunityImpactBulkImportUploadState,
    postValidateClubCommunityImpactBulkImportIds,
    postProcessClubCommunityImpactBulkImportIds,
    resetClubCommunityImpactBulkImportUploadState,
    confirmSave,
    resetState
  };
};

export default useClubCommunityImpactBulkImportUploadData;