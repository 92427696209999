import { useEffect } from 'react';

import useBPMeetClassificationData from '../../../state/meet/blockPartyMeetClassification/useBPMeetClassificationData';

const useBpMeetClassificationTreeView = (orgUnitId, filterId, filterMode) => {
  const { bpMeetClassificationState, getBPMeetClassificationsByOrgUnitId } = useBPMeetClassificationData();

  useEffect(() => {
    if (orgUnitId && (orgUnitId !== bpMeetClassificationState.orgUnitId ||
      (bpMeetClassificationState.treeData.length === 0 && bpMeetClassificationState.isArrayLoaded === false))) {
        getBPMeetClassificationsByOrgUnitId(orgUnitId, filterId, filterMode);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orgUnitId, bpMeetClassificationState]);

  return {
    bpMeetClassificationState
  };
};

export default useBpMeetClassificationTreeView;