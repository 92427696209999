import { Fragment } from "react";

import useIndividualTimesSearchProgressionDashboard from "./UseIndividualTimesSearchProgressionDashboard";

import IndividualTimesSearchProgressionWidget from "./IndividualTimesSearchProgressionWidget";

import LoadingModal from "../../../../../../common/components/dialogs/LoadingModal";

const IndividualTimesSearchProgressionDashboard = ({ dashboardOid }) => {
  const { isError, isLoading, dashboard } = useIndividualTimesSearchProgressionDashboard(dashboardOid);

  if (isError) {
    return <p>Error</p>;
  }

  if (isLoading) {
    return <LoadingModal />;
  }

  if (dashboard) {
    return (
      <Fragment>
        {dashboard.widgets?.map((widget, i) => {
          if (i === 0) {
            return (
              <Fragment key={i}>
                <IndividualTimesSearchProgressionWidget dashboardOid={dashboardOid} widgetOid={widget.oid} />
              </Fragment>
            );
          }
        })}
      </Fragment>
    );
  }

  return <Fragment />;
};

export default IndividualTimesSearchProgressionDashboard;