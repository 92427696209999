import React, { createContext, useState } from 'react';

import PreCompInstructorStudentRatioData from './PreCompInstructorStudentRatioData';

export const PreCompInstructorStudentRatioStateContext = createContext();

const PreCompInstructorStudentRatioContextProvider = ({ children }) => {
  const stateHook = useState(PreCompInstructorStudentRatioData.INITIAL_STATE);

  return (
    <PreCompInstructorStudentRatioStateContext.Provider value={stateHook}>
      {children}
    </PreCompInstructorStudentRatioStateContext.Provider>
  );
};

export default PreCompInstructorStudentRatioContextProvider;