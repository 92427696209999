import React, { Fragment } from 'react';

import useYesNoView from './UseYesNoView';

import Headings from '../../../../common/components/headings/Headings';
import PopUpModal from '../../../../common/components/dialogs/PopUpModal';
import YesNoSwitch from '../../../../common/components/yesNoSwitch/YesNoSwitch';
import PrimaryButton from '../../../../common/components/buttons/PrimaryButton';
import SecondaryButton from '../../../../common/components/buttons/SecondaryButton';
import ReadOnly from '../../../../common/components/readOnly/ReadOnly';

import Constants from '../../../../common/utils/Constants';

const Label = ({ question }) => {
  return (
    <div dangerouslySetInnerHTML={{ __html: question }} />
  );
};

const YesNoView = ({ programLevel, categoryMeasure, isReadonly = false, onRedirect }) => {
  const {
    categoryMeasureScoreState,
    formState,
    errorState,
    handleSubmit,
    onFormValueChanged,
    onSaveClicked
  } = useYesNoView(programLevel, categoryMeasure, onRedirect);
  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>{categoryMeasure?.measureName}</Headings.H3>
        </div>
      </div>
      <form onSubmit={handleSubmit} noValidate>
        <div className="row">
          <div className="col-xs-12">
            {isReadonly === true
              ? (
                <ReadOnly
                  label={<Label question={categoryMeasure?.question} />}
                  name="yesNo"
                  value={formState.yesNo === true ? 'Yes' : 'No'} />
              ) : (
                <YesNoSwitch
                  label={<Label question={categoryMeasure?.question} />}
                  name="yesNo"
                  checked={formState.yesNo}
                  error={errorState.yesNo}
                  message={errorState.yesNo}
                  onChange={(value) => { onFormValueChanged('yesNo', value); }} />
              )}
          </div>
        </div>
        <div className="row usas-extra-top-margin usas-extra-bottom-margin">
          {isReadonly === true
            ? (
              <div className="col-xs-12 usas-extra-top-margin">
                <SecondaryButton type="button" onClick={() => onRedirect(false)}>Back</SecondaryButton>
              </div>
            ) : (
              <div className="col-xs-12 usas-extra-top-margin">
                <PrimaryButton type="button" onClick={onSaveClicked}>Save</PrimaryButton>&nbsp;
                <SecondaryButton type="button" onClick={() => onRedirect(false)}>Back</SecondaryButton>
              </div>
            )
          }
        </div>
      </form>
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.SAVING_MSG}
        displayPopUp={categoryMeasureScoreState.isSaving} />
    </Fragment>
  );
};

export default YesNoView;