const NavLinks = {
  PRE_COMP_ROOT: '/precompetitive',
  PRE_COMP_SEARCH: '/precompetitive/search',
  PRE_COMP_SELECTION: '/precompetitive/myprecompetitive/selection',
  PRE_COMP_LOCATION_INFO: '/precompetitive/location/info',
  PRE_COMP_LOCATION_PROGRAMS: '/precompetitive/location/programs',
  PRE_COMP_LOCATION_PROGRAMS_DETAIL: '/precompetitive/location/programs/detail',
  PRE_COMP_LOCATION_CLUB: '/precompetitive/location/clubs',
  PRE_COMP_LOCATION_CLUB_SEARCH: '/precompetitive/location/clubs/search',
  PRE_COMP_LOCATION_STAFF: '/precompetitive/location/staff',
  PRE_COMP_LOCATION_STAFF_DETAIL: '/precompetitive/location/staff/detail',
  PRE_COMP_LOCATION_FACILITY: '/precompetitive/location/facilities',
  PRE_COMP_LOCATION_FACILITY_SEARCH: '/precompetitive/location/facilities/search',
  PRE_COMP_LOCATION_FACILITY_ADD: '/precompetitive/location/facilities/add',
  PRE_COMP_LOCATION_FACILITY_DETAIL: '/precompetitive/location/facilities/detail',
  PRE_COMP_LOCATION_FACILITY_POOL_DETAIL: '/precompetitive/location/facilities/pools/detail',
  PRE_COMP_LOCATION_INSTRUCTOR_TRAINING: '/precompetitive/location/instructortraining',
  PRE_COMP_ORGANIZATION_INFO: '/precompetitive/organization/info',
  PRE_COMP_ORGANIZATION_INSURANCE: '/precompetitive/organization/insurance',
  PRE_COMP_ORGANIZATION_CONTACT: '/precompetitive/organization/contacts',
  PRE_COMP_ORGANIZATION_CONTACT_DETAIL: '/precompetitive/organization/contacts/detail'
};

export default NavLinks;