import { useState, useEffect } from 'react';
import { useNavigate, useLocation } from '../../../../common/wrappers/ReactRouterDom';

import NavLinks from '../NavLinks';

import ClubRegistrationConstants from '../utils/ClubRegistrationConstants';
import ClubConstants from '../../club/utils/ClubConstants';

import useClubRegistrationNewData from '../../../state/clubRegistrationNew/UseClubRegistrationNewData';
import useClubRegistrationNavData from '../../../state/clubRegistration/leftNavigation/UseClubRegistrationNavData';
import useUsasClubStaffData from '../../../state/clubStaff/UseUsasClubStaffData';
import useRequiredRolesData from '../../../state/clubRegistration/requiredRoles/UseRequiredRolesData';

import useEnvironmentVariableData from '../../../../common/state/environmentVariable/UseEnvironmentVariableData';

const registrationRoles = [
  ClubConstants.COACH_ROLE_NAME,
  ClubConstants.JUNIOR_COACH_ROLE_NAME,
  ClubConstants.PROVISIONAL_COACH_ROLE_NAME
];

const useClubRegistrationStaff = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { clubRegistrationState } = useClubRegistrationNewData();
  const { clubRegistrationNavState, setClubRegistrationNavState } = useClubRegistrationNavData();
  const { usasClubStaffState, getUsasClubStaff, deleteUsasClubStaff } = useUsasClubStaffData();
  const { requiredRolesState, getRequiredRolesByOrgLevelTypeId } = useRequiredRolesData();
  const [state, setState] = useState(getInitialState());
  const [modalState, setModalState] = useState(getInitialModalState());
  const [requiredRolesFormattedState, setRequiredRolesFormattedState] = useState({ isArrayLoaded: false, arrayData: [] });
  const [submitPrequisitesState, setSubmitPrequisitesState] = useState({ areSubmitPrerequisitesDone: false });

  const { environmentVariableState, HEAD_COACH_ORG_ROLE_ID, CLUB_STAFF_ROLE_GROUP_ID } = useEnvironmentVariableData();

  const onEdit = (e, personId) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    };

    navigate(NavLinks.CLUB_REGISTRATION_STAFF_DETAIL, { state: { personId } });
  };

  const onDelete = (e, personId, memberId, orgRoleId, personOrgRoleDurationId) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    };

    const targetPerson = usasClubStaffState.arrayData.find(x => x.personId === personId);

    if (targetPerson && Array.isArray(targetPerson.personOrgRole)) {
      deleteUsasClubStaff(personOrgRoleDurationId);
      setState({ ...state, tryGetAfterDelete: true });
    }
  };

  const onAddOptionalStaff = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    let hasHeadCoach = false;
    for (let i = 0; i < usasClubStaffState.arrayData.length; i++) {
      for (let j = 0; j < usasClubStaffState.arrayData[i].personOrgRole?.length; j++) {
        if (usasClubStaffState.arrayData[i].personOrgRole[j].orgRoleId === HEAD_COACH_ORG_ROLE_ID) {
          hasHeadCoach = true;
          break;
        }
      }
    }

    navigate(NavLinks.CLUB_REGISTRATION_STAFF_DETAIL, { state: { hasHeadCoach } })
  }

  const onContinueClicked = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    if (clubRegistrationState.objData.status === ClubRegistrationConstants.IN_PROGRESS_STATUS ||
      clubRegistrationState.objData.status === ClubRegistrationConstants.RENEWING_STATUS) {
      setState({ ...state, tryRedirect: true, submitButton: ClubRegistrationConstants.CONTINUE });
    }
    else {
      setState({ ...state, tryRedirect: true, submitButton: ClubRegistrationConstants.CONTINUE });
    }
  }

  const onSaveAndExitClicked = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    if (clubRegistrationState.objData.status === ClubRegistrationConstants.IN_PROGRESS_STATUS ||
      clubRegistrationState.objData.status === ClubRegistrationConstants.RENEWING_STATUS) {
      setState({ ...state, tryRedirect: false, submitButton: ClubRegistrationConstants.EXIT });
    }
    else {
      setState({ ...state, tryRedirect: false, submitButton: ClubRegistrationConstants.EXIT });
    }
  }

  const onBackClicked = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    if (clubRegistrationState.objData.status === ClubRegistrationConstants.IN_PROGRESS_STATUS ||
      clubRegistrationState.objData.status === ClubRegistrationConstants.RENEWING_STATUS) {
      setState({ ...state, tryRedirect: true, submitButton: ClubRegistrationConstants.BACK });
    }
    else {
      setState({ ...state, tryRedirect: true, submitButton: ClubRegistrationConstants.BACK });
    }
  }

  const onModalContinue = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    setState({ ...state, tryRedirect: true });
    setModalState({ ...getInitialModalState() });
  }

  const onModalCanceled = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    setState({ ...state, submitButton: '' });
    setModalState({ ...getInitialModalState() });
  }

  const validateStaff = () => {
    let requiredRolesArray = requiredRolesFormattedState.arrayData;
    let roleMet = false;

    for (const roleIdx in requiredRolesArray) {
      roleMet = requiredRolesArray[roleIdx].met;
      if (roleMet === false) { break; }
    }

    return roleMet;
  }

  const onOpenModal = () => {
    setModalState({ ...modalState, modalTitle: ClubRegistrationConstants.EXIT_CONFIRMATION, displayPopUp: true });
  }

  const updateNavState = (isComplete) => {
    for (let i = 0; i < clubRegistrationNavState.length; i++) {
      clubRegistrationNavState[i].isCurrent = false
    }
    const navIndex = clubRegistrationNavState.findIndex(x => x.name === ClubRegistrationConstants.CLUB_STAFF)
    if (!isComplete) {
      clubRegistrationNavState[navIndex] =
      {
        ...clubRegistrationNavState[navIndex],
        isCurrent: true,
        isVisited: true,
        arePrerequisitesDone: true
      }
    }
    else if (isComplete === true) {
      clubRegistrationNavState[navIndex] =
      {
        ...clubRegistrationNavState[navIndex],
        isCurrent: true,
        isComplete: true,
        isVisited: true,
        arePrerequisitesDone: true
      }
    }
    else if (isComplete === false) {
      clubRegistrationNavState[navIndex] =
      {
        ...clubRegistrationNavState[navIndex],
        isCurrent: true,
        isComplete: false,
        isVisited: true,
        arePrerequisitesDone: true
      }
    }

    //Submit
    let areSubmitPrerequisitesDone = true;
    let isSubmitComplete = false;
    for (let i = 0; i < clubRegistrationNavState.length; i++) {
      if (clubRegistrationNavState[i].name !== ClubRegistrationConstants.SUBMIT
        && clubRegistrationNavState[i].isComplete === false) {
        areSubmitPrerequisitesDone = false;
      }
      if (clubRegistrationNavState[i].name === ClubRegistrationConstants.SUBMIT) {
        if (clubRegistrationState.objData.status === ClubRegistrationConstants.PENDING_STATUS ||
          clubRegistrationState.objData.status === ClubRegistrationConstants.APPROVED_STATUS) {
          isSubmitComplete = true
        }
      }
    }
    const submitNavIndex = clubRegistrationNavState.findIndex(x => x.name === ClubRegistrationConstants.SUBMIT)
    clubRegistrationNavState[submitNavIndex] =
    {
      ...clubRegistrationNavState[submitNavIndex],
      isCurrent: false,
      isComplete: isSubmitComplete,
      isVisited: areSubmitPrerequisitesDone === false ? false : clubRegistrationNavState[submitNavIndex].isVisited,
      arePrerequisitesDone: areSubmitPrerequisitesDone
    }
    setSubmitPrequisitesState({ ...state, areSubmitPrerequisitesDone: areSubmitPrerequisitesDone });
    setClubRegistrationNavState(clubRegistrationNavState);
  }

  useEffect(() => {
    const navIndex = clubRegistrationNavState.findIndex(x => x.name === ClubRegistrationConstants.CLUB_STAFF)
    if (clubRegistrationNavState[navIndex].isCurrent === false) {
      updateNavState();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clubRegistrationNavState])

  useEffect(() => {
    const submitNavIndex = clubRegistrationNavState.findIndex(x => x.name === ClubRegistrationConstants.SUBMIT)
    if (clubRegistrationNavState[submitNavIndex].arePrerequisitesDone === false && submitPrequisitesState.areSubmitPrerequisitesDone === true) {
      updateNavState();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clubRegistrationNavState, submitPrequisitesState.areSubmitPrerequisitesDone])


  useEffect(() => {
    //Handle inital page load
    if (Object.keys(clubRegistrationState.objData).length > 0 && state.isIntialPageLoadComplete === false && environmentVariableState.isLoaded === true) {
      //Get any staff associated with the club
      if ((usasClubStaffState.isArrayLoading === false) || (location.state && location.state.addedStaff)) {
        getUsasClubStaff(clubRegistrationState.objData.orgUnitId, CLUB_STAFF_ROLE_GROUP_ID);
      }
      if (usasClubStaffState.isArrayLoaded === true) {
        setState({ ...state, isIntialPageLoadComplete: true });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [environmentVariableState, clubRegistrationState.objData,
    usasClubStaffState.arrayData,
    state.isIntialPageLoadComplete]);

  useEffect(() => {
    if (Object.keys(clubRegistrationState.objData).length > 0 && environmentVariableState.isLoaded === true) {
      if ((usasClubStaffState.isArrayLoading === false && usasClubStaffState.isArrayLoaded === false)) {
        getUsasClubStaff(clubRegistrationState.objData.orgUnitId, CLUB_STAFF_ROLE_GROUP_ID);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [environmentVariableState, clubRegistrationState.objData, usasClubStaffState.arrayData]);

  useEffect(() => {
    //If allowed to redirect, then navigate to the correct page
    if (state.submitButton !== '' && clubRegistrationState.isObjLoaded === true && usasClubStaffState.isArrayLoaded === true) {
      if (state.tryRedirect === false) {
        onOpenModal();
      }
      if (state.tryRedirect === true) {
        if (validateStaff() === true) {
          const navIndex = clubRegistrationNavState.findIndex(x => x.name === ClubRegistrationConstants.CLUB_STAFF)
          clubRegistrationNavState[navIndex] =
          {
            ...clubRegistrationNavState[navIndex],
            isCurrent: false,
            isComplete: true,
            isVisited: true,
            arePrerequisitesDone: true
          }
          setClubRegistrationNavState(clubRegistrationNavState);
        }
        else {
          const navIndex = clubRegistrationNavState.findIndex(x => x.name === ClubRegistrationConstants.CLUB_STAFF)
          clubRegistrationNavState[navIndex] =
          {
            ...clubRegistrationNavState[navIndex],
            isCurrent: false,
            isComplete: false,
            isVisited: true,
            arePrerequisitesDone: true
          }
          setClubRegistrationNavState(clubRegistrationNavState);
        }

        if (state.submitButton === ClubRegistrationConstants.CONTINUE) {
          navigate(NavLinks.CLUB_REGISTRATION_TYPE);
        }
        else if (state.submitButton === ClubRegistrationConstants.EXIT) {
          navigate(NavLinks.CLUB_REGISTRATION_ENTRY_NEW);
        }
        else if (state.submitButton === ClubRegistrationConstants.BACK) {
          navigate(NavLinks.CLUB_REGISTRATION_INFO);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    state,
    clubRegistrationState.isObjLoaded])

  useEffect(() => {
    //Get club staff after a successful delete
    if ((usasClubStaffState.isSaved === true && usasClubStaffState.isSaving === false) && state.tryGetAfterDelete === true) {
      getUsasClubStaff(clubRegistrationState.objData.orgUnitId, CLUB_STAFF_ROLE_GROUP_ID);
      setState({ ...state, showGrid: true, tryGetAfterDelete: false });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [usasClubStaffState.isSaved, state.tryGetAfterDelete])

  useEffect(() => {
    if (usasClubStaffState.isArrayLoaded === true && requiredRolesState.isArrayLoaded === true) {
      const staff = usasClubStaffState.arrayData;
      let staffRolesSimple = [];
      let requiredRolesArray = [];

      for (const staffIdx in staff) {
        const staffRole = staff[staffIdx].personOrgRole;
        for (const roleIdx in staffRole) {

          staffRolesSimple.push(staffRole[roleIdx].orgRoleId);
        }
      }

      for (const roleIdx in requiredRolesState.arrayData) {
        //is a staff member assigned with a role in the required list, if so, set met to true
        let found = staffRolesSimple.some(roleId => roleId === requiredRolesState.arrayData[roleIdx].requiredRoleId);
        let foundChildrenRole = false;
        if (found === false && requiredRolesState.arrayData[roleIdx].childrenRole) {
          for (const childRoleIdx in requiredRolesState.arrayData[roleIdx].childrenRole) {
            foundChildrenRole = staffRolesSimple.some(roleId => roleId === requiredRolesState.arrayData[roleIdx].childrenRole[childRoleIdx].orgRoleId);

            if (foundChildrenRole) { break; }
          }
        }

        requiredRolesArray.push({
          orgRoleId: requiredRolesState.arrayData[roleIdx].requiredRoleId,
          orgRoleName: requiredRolesState.arrayData[roleIdx].requiredRoleName,
          met: found || foundChildrenRole
        });
      }

      setRequiredRolesFormattedState({
        ...requiredRolesFormattedState,
        isArrayLoading: false,
        isArrayLoaded: true,
        arrayData: requiredRolesArray
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [usasClubStaffState, requiredRolesState])

  useEffect(() => {
    if (
      clubRegistrationState.isObjLoaded === true &&
      requiredRolesState.isArrayLoaded === false &&
      requiredRolesState.isArrayLoading === false) {
      getRequiredRolesByOrgLevelTypeId(clubRegistrationState.objData.clubOwnerType.orgLevelTypeId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clubRegistrationState, requiredRolesState])

  const arrayChunk = (arr, n) => {
    const array = arr.slice();
    const chunks = [];
    while (array.length) chunks.push(array.splice(0, n));
    return chunks;
  };

  function getInitialState() {
    return {
      isIntialPageLoadComplete: false,
      submitButton: '',
      tryRedirect: false,
      showGrid: false,
      tryGetAfterAdd: false,
      tryGetAfterDelete: false
    }
  }

  function getInitialModalState() {
    return {
      displayPopUp: false,
      modalTitle: ClubRegistrationConstants.MISSING_REQUIRED_INFORMATION
    }
  };

  return {
    clubRegistrationNavState,
    clubRegistrationState,
    usasClubStaffState,
    state,
    modalState,
    requiredRolesState,
    requiredRolesFormattedState,
    registrationRoles,
    onAddOptionalStaff,
    onEdit,
    onDelete,
    onContinueClicked,
    onSaveAndExitClicked,
    onBackClicked,
    onModalContinue,
    onModalCanceled,
    arrayChunk
  }
}

export default useClubRegistrationStaff;