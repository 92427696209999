import React, { Fragment } from 'react';

import useMemberIdView from './UseMemberIdView';

import Input from '../../../../common/components/inputs/Input';
import Headings from '../../../../common/components/headings/Headings';
import PopUpModal from '../../../../common/components/dialogs/PopUpModal';
import PrimaryButton from '../../../../common/components/buttons/PrimaryButton';
import SecondaryButton from '../../../../common/components/buttons/SecondaryButton';
import ReadOnly from '../../../../common/components/readOnly/ReadOnly';
import ActionIntraPageButton from '../../../../common/components/buttons/ActionIntraPageButton';

import Constants from '../../../../common/utils/Constants';

import style from './MemberIdView.module.css';

const Question = ({ question }) => {
  return (
    <div dangerouslySetInnerHTML={{ __html: question }} />
  );
};

const MemberIdView = ({ programLevel, categoryMeasure, isReadonly = false, onRedirect }) => {
  const {
    isLoading,
    showClearEntryButton,
    personObj,
    categoryMeasureScoreState,
    formState,
    errorState,
    handleSubmit,
    onUpdateMemberId,
    onSaveClicked,
    onClearEntryClicked,
    onSearchMemberClicked
  } = useMemberIdView(programLevel, categoryMeasure, onRedirect);

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>{categoryMeasure?.measureName}</Headings.H3>
        </div>
      </div>
      <form onSubmit={handleSubmit} noValidate>
        <div className="row">
          <div className="col-xs-12">
            <Question question={categoryMeasure.question} />
          </div>
        </div>
        <div className="row usas-extra-top-margin usas-extra-bottom-margin">
          {isReadonly === true
            ? (
              <Fragment>
                <div className="col-xs-12 col-sm-6 col-md-4">
                  <ReadOnly
                    label={'Member Id'}
                    name="memberId"
                    value={formState.memberId} />
                </div>
                {personObj &&
                  <Fragment>
                    <div className="col-xs-12 col-sm-6 col-md-4">
                      <ReadOnly
                        label={'Member Name'}
                        name="memberName"
                        value={`${personObj.firstName} ${personObj.lastName}`} />
                    </div>
                    <div className="col-xs-12 col-sm-6 col-md-4">
                      <ReadOnly
                        label={'Is In Good Standing'}
                        name="isInGoodStanding"
                        value={personObj.isInGoodStanding === true ? 'Yes' : 'No'} />
                    </div>
                  </Fragment>
                }
              </Fragment>
            ) : (
              <Fragment>
                <div className="col-xs-12 col-sm-6 col-md-4">
                  <Input
                    label={'Member Id'}
                    name="memberId"
                    value={formState.memberId}
                    error={errorState.memberId}
                    message={errorState.memberId}
                    onChange={(value) => { onUpdateMemberId(value); }} />
                </div>
                {personObj
                  ? (
                    <Fragment>
                      <div className="col-xs-12 col-sm-6 col-md-4">
                        <ReadOnly
                          label={'Member Name'}
                          name="memberName"
                          value={`${personObj.firstName} ${personObj.lastName}`} />
                      </div>
                      <div className="col-xs-12 col-sm-6 col-md-4">
                        <ReadOnly
                          label={'Is In Good Standing'}
                          name="isInGoodStanding"
                          value={personObj.isInGoodStanding === true ? 'Yes' : 'No'} />
                      </div>
                    </Fragment>
                  ) : (
                    <div className='col-xs-12 col-sm-4'>
                      <ActionIntraPageButton className={style.SearchButton} type='button' onClick={onSearchMemberClicked}>Search For Member</ActionIntraPageButton>
                    </div>
                  )
                }
              </Fragment>
            )}
        </div>
        <div className="row usas-extra-top-margin usas-extra-bottom-margin">
          {isReadonly === true || personObj?.isInGoodStanding !== true
            ? (
              <div className="col-xs-12 usas-extra-top-margin">
                <SecondaryButton type="button" onClick={() => onRedirect(false)}>Back</SecondaryButton>
              </div>
            ) : (
              <div className="col-xs-12 usas-extra-top-margin">
                <PrimaryButton type="button" onClick={onSaveClicked}>Save</PrimaryButton>&nbsp;
                <SecondaryButton type="button" onClick={() => onRedirect(false)}>Back</SecondaryButton>&nbsp;
                {showClearEntryButton &&
                  <PrimaryButton type="button" onClick={onClearEntryClicked}>Clear Entry</PrimaryButton>
                }
              </div>
            )
          }
        </div>
      </form>
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={isLoading} />
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.SAVING_MSG}
        displayPopUp={categoryMeasureScoreState.isSaving} />
    </Fragment>
  );
};

export default MemberIdView;