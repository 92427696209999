import useFacilityData from '../../../../../state/facility/UseFacilityData';

const FacilityPoolsUtils = () => {
  const { facilityState } = useFacilityData();

  const getFacilityCopy = () => {
    if (facilityState.isObjLoaded === true) {
      const facilityCopy = JSON.parse(JSON.stringify(facilityState.objData));
      return facilityCopy;
    }
  }

  const createNewPoolObject = (formState) => {
    let poolConfigurationArray = JSON.parse(JSON.stringify(formState.poolConfiguration));
    for (let i = 0; i < poolConfigurationArray.length; i++) {
      if (Object.keys(poolConfigurationArray[i].course).length === 1) {
        poolConfigurationArray[i].course = undefined;
      }
    }
    const newPool = {
      facilityId: formState.facilityId,
      poolDimensionsId: formState.poolDimensionsId,
      facilityPoolName: formState.poolName.trim(),
      poolMaxDepth: formState.poolMaxDepth === '' ? null : formState.poolMaxDepth,
      averagePoolTemperature: formState.averagePoolTemperature === '' ? null : formState.averagePoolTemperature,
      startingBlocks: formState.hasStartingBlocks,
      poolDimensions: undefined,
      poolConfiguration: poolConfigurationArray
    };

    return newPool;
  };

  const updatePoolObject = (formState) => {
    let poolConfigurationArray = JSON.parse(JSON.stringify(formState.poolConfiguration));
    for (let i = 0; i < poolConfigurationArray.length; i++) {
      if (Object.keys(poolConfigurationArray[i].course).length === 1) {
        poolConfigurationArray[i].course = undefined;
      }
    }
    const updatedPool = {
      facilityId: formState.facilityId,
      facilityPoolId: formState.poolId,
      poolDimensionsId: formState.poolDimensionsId,
      facilityPoolName: formState.poolName.trim(),
      poolMaxDepth: formState.poolMaxDepth === '' ? null : formState.poolMaxDepth,
      averagePoolTemperature: formState.averagePoolTemperature === '' ? null : formState.averagePoolTemperature,
      startingBlocks: formState.hasStartingBlocks,
      poolDimensions: undefined,
      poolConfiguration: poolConfigurationArray
    };

    return updatedPool;
  };

  return {
    getFacilityCopy,
    createNewPoolObject,
    updatePoolObject
  };
};

export default FacilityPoolsUtils;