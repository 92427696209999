import React from 'react';

import DeleteIcon from '../../../../../common/components/icons/DeleteIcon';
import EditIcon from '../../../../../common/components/icons/EditIcon';

import { CURRENCY_FORMATTER } from '../../../../../common/utils/Constants';

import global from '../../../../../common/components/GlobalStyle.module.css';

const GridRow = ({ participant, onEdit, onDelete }) => (
  <tr>
    <td>{participant.participantName || ''}</td>
    <td>{participant.amountRaised ? CURRENCY_FORMATTER.format(participant.amountRaised) : ''}</td>
    <td>{participant.satPrize?.description || ''}</td>
    <td>
      {onEdit &&
        <button className={global.IconButtonMargin} type="button" onClick={(e) => onEdit(e, participant.satParticipantId)}>
          <EditIcon />
        </button>
      }
      {onDelete &&
        <button className={global.IconButtonMargin} type="button" onClick={(e) => onDelete(e, participant.satParticipantId)}>
          <DeleteIcon />
        </button>
      }
    </td>
  </tr>
);

const SATParticipantsGridLarge = ({ data, isLoading, onEdit, onDelete }) => (
  <table className={[global.UsasTable, 'visible-md visible-lg'].join(' ')}>
    <thead>
      <tr>
        <th>Participant Name</th>
        <th>Amount Raised</th>
        <th>Award Achieved</th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      {isLoading === true
        ? (
          <tr>
            <td colSpan="4">Loading...</td>
          </tr>
        ) : Array.isArray(data) && data.length > 0
          ? data.map((participant, i) => (
            <GridRow
              key={i}
              participant={participant}
              onEdit={onEdit}
              onDelete={onDelete} />
          )) : (
            <tr>
              <td colSpan="4">No Participants</td>
            </tr>
          )
      }
    </tbody>
  </table>
);

export default SATParticipantsGridLarge;