import { useContext } from 'react';

import RouteCodeValueData from './RouteCodeValueData';

import { RouteCodeValueStateContext } from './RouteCodeValueContextProvider';

const useRouteCodeValueData = () => {
  const [routeCodeValueState, setRouteCodeValueState] = useContext(RouteCodeValueStateContext);

  const getRouteCodeValue = (routeCodeValue) => RouteCodeValueData.getRouteCodeValue(routeCodeValue, routeCodeValueState, setRouteCodeValueState);

  return {
    routeCodeValueState,
    setRouteCodeValueState,
    getRouteCodeValue
  };
};

export default useRouteCodeValueData;