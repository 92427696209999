/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';

import { formatDate } from "../../../../../common/utils/DateFunctions";

import global from '../../../../../common/components/GlobalStyle.module.css';

const GridRow = ({ club, onEdit }) => (
  <tr>
    <td><a onClick={(e) => onEdit(e, club.orgUnitId, club.clubName)}>{club.clubName || ''}</a></td>
    <td>{club.lscCode || 'N/A'}</td>
    <td>{club.expirationDate ? formatDate(club.expirationDate) : ''}</td>
  </tr>
);

const SATSearchGridLarge = ({ data, isLoading, onEdit }) => (
  <table className={[global.UsasTable, 'visible-md visible-lg'].join(' ')}>
    <thead>
      <tr>
        <th>Club Name</th>
        <th>LSC Code</th>
        <th>Expiration Date</th>
      </tr>
    </thead>
    <tbody>
      {isLoading === true
        ? (
          <tr>
            <td colSpan="2">Loading...</td>
          </tr>
        ) : Array.isArray(data) && data.length > 0
          ? data.map((club, i) => (
            <GridRow
              key={i}
              club={club}
              onEdit={onEdit} />
          )) : (
            <tr>
              <td colSpan="2">No Clubs</td>
            </tr>
          )
      }
    </tbody>
  </table>
);

export default SATSearchGridLarge;