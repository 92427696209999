import { useEffect } from 'react';

import validate from '../components/forms/PreCompLocationInfoFormValidation';

import { createLocObjFromLocInfoForm } from '../utils/PreCompUtils';

import usePreCompLocationData from '../../../state/preCompLocation/UsePreCompLocationData';
import usePreCompCurriculumData from '../../../state/preCompCurriculum/UsePreCompCurriculumData';
import usePreCompInstructionHoursData from '../../../state/preCompInstructionHours/UsePreCompInstructionHoursData';
import usePreCompInstructorStudentRatioData from '../../../state/preCompInstructorStudentRatio/UsePreCompInstructorStudentRatioData';

import useForm from '../../../../common/utils/UseForm';
import Constants from '../../../../common/utils/Constants';
import ToBoolIfBool from '../../../../common/utils/ToBoolIfBool';

const usePreCompLocationInfo = () => {
  const { preCompCurriculumState } = usePreCompCurriculumData();
  const { preCompInstructionHoursState } = usePreCompInstructionHoursData();
  const { preCompInstructorStudentRatioState } = usePreCompInstructorStudentRatioData();
  const { preCompLocationState, putPreCompLocation, confirmSave } = usePreCompLocationData();
  const { formState, errorState, handleSubmit, resetForm, updateFormState, onValueTextPairChanged, setFormData
  } = useForm(getInitialFormState, submitFormCallback, validate);

  useEffect(() => {
    if (preCompLocationState.isObjLoaded === true && preCompCurriculumState.isArrayLoaded === true
      && preCompInstructionHoursState.isArrayLoaded === true && preCompInstructorStudentRatioState.isArrayLoaded === true) {

      setFormData({
        ...formState,
        locationName: preCompLocationState.objData.locationName || '',
        averageLessonCost: preCompLocationState.objData.averageLessonCost !== null ?
          preCompLocationState.objData.averageLessonCost : '',
        averageSessionCost: preCompLocationState.objData.averageSessionCost !== null ?
          preCompLocationState.objData.averageSessionCost : '',
        financialAidOffered: ToBoolIfBool(preCompLocationState.objData.financialAidOffered) ?? '',
        instructorStudentRatioId: preCompLocationState.objData.instructorStudentRatioId || Constants.DEFAULT_ID,
        instructorStudentRatioName: preCompLocationState.objData.instructorStudentRatio?.ratioDescription || '',
        preCompCurriculumId: preCompLocationState.objData.preCompCurriculumId || Constants.DEFAULT_ID,
        preCompCurriculumName: preCompLocationState.objData.preCompCurriculum?.curriculumName || '',
        instructionHoursPerSessionId: preCompLocationState.objData.instructionHoursPerSessionId || 0.0,
        instructionHoursPerSessionName: preCompLocationState.objData.instructionHoursPerSession?.instructionHoursDescription || '',
        website: preCompLocationState.objData.website || ''
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [preCompLocationState.isObjLoaded, preCompCurriculumState.isArrayLoaded, preCompInstructorStudentRatioState.isArrayLoaded, preCompInstructionHoursState.isArrayLoaded]);

  useEffect(() => {
    if (preCompLocationState.isSaving === false && preCompLocationState.isSaved === true) {
      confirmSave();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [preCompLocationState]);

  function submitFormCallback(formState) {
    if (preCompLocationState.isSaving === false) {
      putPreCompLocation(preCompLocationState.objData.preCompetitiveLocationId, createLocObjFromLocInfoForm(JSON.parse(JSON.stringify(preCompLocationState.objData)), formState));
    }
  };

  function getInitialFormState() {
    return {
      locationName: '',
      averageLessonCost: '',
      averageSessionCost: '',
      financialAidOffered: '',
      instructionHoursPerSessionId: Constants.DEFAULT_ID,
      instructionHoursPerSessionName: '',
      instructorStudentRatioId: Constants.DEFAULT_ID,
      instructorStudentRatioName: '',
      preCompCurriculumId: Constants.DEFAULT_ID,
      preCompCurriculumName: '',
      website: ''
    };
  };

  return {
    isSaving: preCompLocationState.isSaving,
    isLoading: preCompLocationState.isObjLoading || preCompCurriculumState.isArrayLoading || preCompInstructionHoursState.isArrayLoading || preCompInstructorStudentRatioState.isArrayLoading,
    formState,
    errorState,
    preCompLocationState,
    handleSubmit,
    resetForm,
    onFormValueChanged: updateFormState,
    onValueTextPairChanged
  };
};

export default usePreCompLocationInfo;