import { useEffect, useState } from 'react';

import useBaseUsasClubData from '../../../../../../common/state/clubBase/UseBaseUsasClubData';
import useGroupCoachesData from '../../../../../../common/state/groupCoaches/UseGroupCoachesData';
import useGroupFacilitiesData from '../../../../../../common/state/groupFacilities/UseGroupFacilitiesData';
import useGroupFieldNamesData from '../../../../../../common/state/groupFieldNames/UseGroupFieldNamesData';

const useClubGroupsAddRemoveMembersGrid = (clubGroupState) => {
  const { GROUP_CONSTANTS } = useGroupFieldNamesData();
  const { groupCoachesState, getGroupCoachesByUsasClubId } = useGroupCoachesData();
  const { groupFacilitiesState, getGroupFacilitiesByUsasClubId } = useGroupFacilitiesData();
  const { baseUsasClubState } = useBaseUsasClubData();
  const [state, setState] = useState(getInitialState);

  useEffect(() => {
    if (clubGroupState.isObjLoaded === true
      && groupCoachesState.isArrayLoaded === true && groupCoachesState.usasClubId === baseUsasClubState.objData.usasClubId
      && groupFacilitiesState.isArrayLoaded === true && groupFacilitiesState.usasClubId === baseUsasClubState.objData.usasClubId) {
      const groupObj = clubGroupState.objData;

      if (groupObj.groupType?.typeName) {
        if (groupObj.groupType.typeName === GROUP_CONSTANTS.GROUP_TYPE_NAME_LIST) {
          setState(getInitialState);
        } else if (groupObj.groupType.typeName === GROUP_CONSTANTS.GROUP_TYPE_NAME_TRAVEL_TRIP) {
          const startDate = groupObj.orgGroupFieldValue.find(x => x.groupTypeField?.fieldName === GROUP_CONSTANTS.ORG_GROUP_TYPE_FIELD_NAME_START_DATE)?.fieldValue;
          const endDate = groupObj.orgGroupFieldValue.find(x => x.groupTypeField?.fieldName === GROUP_CONSTANTS.ORG_GROUP_TYPE_FIELD_NAME_END_DATE)?.fieldValue;
          
          setState({
            ...state,
            coachName: groupObj.personOrgGroup.length > 0
              ? getGroupCoachesNames(groupObj.personOrgGroup.map(x => x.personId), groupCoachesState.options)
              : 'None',
            dateRange: `${startDate || ''} - ${endDate || ''}`
          });
        } else if (groupObj.groupType.typeName=== GROUP_CONSTANTS.GROUP_TYPE_NAME_PRACTICE_GROUP) {
          const startDate = groupObj.orgGroupFieldValue.find(x => x.groupTypeField?.fieldName === GROUP_CONSTANTS.ORG_GROUP_TYPE_FIELD_NAME_START_DATE)?.fieldValue;
          const endDate = groupObj.orgGroupFieldValue.find(x => x.groupTypeField?.fieldName === GROUP_CONSTANTS.ORG_GROUP_TYPE_FIELD_NAME_END_DATE)?.fieldValue;
          const facilityFieldValue = groupObj.orgGroupFieldValue.find(x => x.groupTypeField?.fieldName === GROUP_CONSTANTS.ORG_GROUP_TYPE_FIELD_NAME_FACILITY)?.fieldValue;

          setState({
            ...state,
            coachName: groupObj.personOrgGroup.length > 0
              ? getGroupCoachesNames(groupObj.personOrgGroup.map(x => x.personId), groupCoachesState.options)
              : 'None',
            dateRange: `${startDate || ''} - ${endDate || ''}`,
            facilityName: `${groupFacilitiesState.options.find(x => `${x.id}` === facilityFieldValue)?.name || 'None'}`
          });
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clubGroupState, groupCoachesState, groupFacilitiesState, baseUsasClubState]);

  useEffect(() => {
    if (baseUsasClubState.isObjLoaded === true) {
      if (groupFacilitiesState.usasClubId !== baseUsasClubState.objData.usasClubId) {
        getGroupFacilitiesByUsasClubId(baseUsasClubState.objData.usasClubId);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [baseUsasClubState, groupFacilitiesState]);

  useEffect(() => {
    if (baseUsasClubState.isObjLoaded === true) {
      if (groupCoachesState.usasClubId !== baseUsasClubState.objData.usasClubId) {
        getGroupCoachesByUsasClubId(baseUsasClubState.objData.usasClubId);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [baseUsasClubState, groupCoachesState]);

  function getGroupCoachesNames(coachIds, coachOptions) {
    let coachNames = '';

    for (const id of coachIds) {
      const coachName = coachOptions.find(x => x.id === id)?.name;

      if (coachName) {
        if (coachNames) {
          coachNames += `, ${coachName}`;
        } else {
          coachNames = coachName;
        }
      }
    };

    return coachNames;
  };

  function getInitialState() {
    return {
      coachName: '',
      facilityName: '',
      dateRange: ''
    };
  };

  return {
    state,
    GROUP_CONSTANTS
  };
};

export default useClubGroupsAddRemoveMembersGrid;