import { useEffect, useState } from 'react';
import { useNavigate, useLocation } from '../../../../common/wrappers/ReactRouterDom';

import validate from './ClubRegistrationLegalEntitiesDetailValidation';

import NavLinks from '../NavLinks';

import useClubRegistrationNavData from '../../../state/clubRegistration/leftNavigation/UseClubRegistrationNavData';
import useClubRegistrationNewData from '../../../state/clubRegistrationNew/UseClubRegistrationNewData';
import useLegalEntityTypeData from '../../../state/legalEntity/UseLegalEntityTypeData';


import useForm from '../../../../common/utils/UseForm';
import Constants from '../../../../common/utils/Constants';

const ALREADY_LINKED_ID_ERROR_MSG = 'This Legal Entity Tax Id is already linked to the club';
const ALREADY_LINKED_NAME_ERROR_MSG = 'This Legal Entity Name is already linked to the club';

const useClubRegistrationLegalEntitiesDetail = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [state, setState] = useState({ tryRedirect: false });
  const { clubRegistrationNavState } = useClubRegistrationNavData();
  const { clubRegistrationState, putUsasClub } = useClubRegistrationNewData();
  const { legalEntityTypeState, setLegalEntityTypeState, getLegalEntityTypes } = useLegalEntityTypeData();

  const { formState, errorState, setErrors, updateFormState, handleSubmit, isDirty, setIsDirty, setFormData, onValueTextPairChanged }
    = useForm(getFormInitialState(), submitFormCallback, validate);

  function submitFormCallback() {
    //Check for legal entity dupes
    let dupLegalEntity = false;
    let selectedLegalEntity = {};
    if (location.state && location.state.usasClubLegalEntityId > 0) {
      selectedLegalEntity = clubRegistrationState.objData.usasClubLegalEntities.find(
        e => e.usasClubLegalEntityId === location.state.usasClubLegalEntityId);
    }
    for (const legalEntity of clubRegistrationState.objData.usasClubLegalEntities) {
      //Don't check against entity being edited
      if ((Object.keys(selectedLegalEntity).length > 0 && selectedLegalEntity.usasClubLegalEntityId !== legalEntity.usasClubLegalEntityId) ||
        Object.keys(selectedLegalEntity).length === 0) {
        if (legalEntity.legalEntityName === formState.legalEntityName && legalEntity.legalEntityTaxId === formState.legalEntityTaxId) {
          dupLegalEntity = true;
          setErrors({
            ...errorState, legalEntityName: ALREADY_LINKED_NAME_ERROR_MSG,
            legalEntityTaxId: ALREADY_LINKED_ID_ERROR_MSG
          });
        }
        else if (legalEntity.legalEntityName !== formState.legalEntityName && legalEntity.legalEntityTaxId === formState.legalEntityTaxId) {
          dupLegalEntity = true;
          setErrors({ ...errorState, legalEntityTaxId: ALREADY_LINKED_ID_ERROR_MSG });
        }
        else if (legalEntity.legalEntityName === formState.legalEntityName && legalEntity.legalEntityTaxId !== formState.legalEntityTaxId) {
          dupLegalEntity = true;
          setErrors({ ...errorState, legalEntityName: ALREADY_LINKED_NAME_ERROR_MSG });
        }
      }
    }
    if (dupLegalEntity === false) {
      putUsasClub(clubRegistrationState.objData.usasClubId, editClubObj());
      setState({ ...state, tryRedirect: true });
    }
  };

  const editClubObj = () => {
    let clubCopy = JSON.parse(JSON.stringify(clubRegistrationState.objData));

    //Edit
    if (location.state && location.state.usasClubLegalEntityId > 0) {
      const selectedLegalEntityIndex = clubRegistrationState.objData.usasClubLegalEntities.findIndex(
        e => e.usasClubLegalEntityId === location.state.usasClubLegalEntityId);

      clubCopy.usasClubLegalEntities[selectedLegalEntityIndex] = {
        ...clubCopy.usasClubLegalEntities[selectedLegalEntityIndex],
        legalEntityName: formState.legalEntityName.trim(),
        legalEntityTaxId: formState.legalEntityTaxId.trim(),
        legalEntityTypeId: formState.legalEntityTypeId
      };
    }
    //Add
    else {
      clubCopy.usasClubLegalEntities.push({
        usasClubId: clubCopy.usasClubId,
        legalEntityName: formState.legalEntityName.trim(),
        legalEntityTaxId: formState.legalEntityTaxId.trim(),
        legalEntityTypeId: formState.legalEntityTypeId
      });
    }

    return clubCopy;
  }

  const onSaveClicked = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    if (isDirty === false) {
      setIsDirty(true);
    }
    handleSubmit();
  }

  const onCancelClicked = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    navigate(NavLinks.CLUB_REGISTRATION_LEGAL_ENTITY);
  }

  useEffect(() => {
    if (clubRegistrationState.isObjLoaded === false) {
      navigate(NavLinks.CLUB_REGISTRATION_ENTRY_NEW);
    }

    if (clubRegistrationState.isObjLoaded === true) {
      if (legalEntityTypeState.isArrayLoading !== true && legalEntityTypeState.isArrayLoaded !== true) {
        const getLegalEntityTypePromise = getLegalEntityTypes();

        if (getLegalEntityTypePromise !== null) {
          getLegalEntityTypePromise.then((newState) => {
            if (newState !== null) {
              const items = newState.arrayData.map(x => { return { id: x.legalEntityTypeId, name: x.typeName } });
              items.unshift({ id: Constants.DEFAULT_ID, name: '--' })

              setLegalEntityTypeState({
                ...newState,
                items,
                areItemsLoaded: true
              });
            }
          }).catch((e) => {
            //TODO context error
          });
        }
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    //Populate edit values
    if (location.state && location.state.usasClubLegalEntityId > 0 && legalEntityTypeState.isArrayLoaded === true && legalEntityTypeState.areItemsLoaded === true) {
      const selectedLegalEntity = clubRegistrationState.objData.usasClubLegalEntities.find(
        e => e.usasClubLegalEntityId === location.state.usasClubLegalEntityId);
      setFormData({
        ...formState,
        usasClubLegalEntityId: selectedLegalEntity.usasClubLegalEntityId,
        legalEntityName: selectedLegalEntity.legalEntityName,
        legalEntityTaxId: selectedLegalEntity.legalEntityTaxId,
        legalEntityTypeId: selectedLegalEntity.legalEntityTypeId
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.state, legalEntityTypeState]);

  useEffect(() => {
    //Wait for add to complete before redirecting
    if (clubRegistrationState.isSaved === true && state.tryRedirect === true) {
      navigate(NavLinks.CLUB_REGISTRATION_LEGAL_ENTITY);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clubRegistrationState, state.tryRedirect]);

  return {
    clubRegistrationState,
    clubRegistrationNavState,
    formState,
    errorState,
    legalEntityTypeState,
    onFormValueChanged: updateFormState,
    handleSubmit,
    onSaveClicked,
    onCancelClicked,
    onValueTextPairChanged
  }
};

export default useClubRegistrationLegalEntitiesDetail;

function getFormInitialState() {
  return {
    usasClubLegalEntityId: Constants.DEFAULT_ID,
    legalEntityName: '',
    legalEntityTaxId: '',
    legalEntityTypeId: Constants.DEFAULT_ID,
    typeName: ''
  }
};