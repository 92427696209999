import { Fragment } from "react";

import StatesCombobox from "../../comboboxes/statesCombobox/StatesCombobox";
import DatePicker from "../../datepickers/DatePicker";
import Input from "../../inputs/Input";
import GroupCoachesMultiselect from "../../multiSelect/groupCoachesMultiSelect/GroupCoachesMultiselect";

const TravelTripAttributesForm = ({ formState, errorState, onFormValueChanged, onValueTextPairChanged, orgUnitId, usasClubId }) => (
  <Fragment>
    <div className="row">
      <div className="col-xs-12 col-sm-6 col-md-4">
        <GroupCoachesMultiselect
          label="Coach Name(s)"
          name="groupCoaches"
          value={formState.groupCoaches}
          error={errorState.groupCoaches}
          message={errorState.groupCoaches}
          onChange={(value) => { onFormValueChanged('groupCoaches', value); }}
          orgUnitId={orgUnitId}
          usasClubId={usasClubId} />
      </div>
      <div className="col-xs-12 col-sm-6 col-md-4">
        <DatePicker
          label="Start Date*"
          name="startDate"
          value={formState.startDate}
          error={errorState.startDate}
          message={errorState.startDate}
          onChange={(value) => { onFormValueChanged('startDate', value); }}
          countOfYears={15}
          startYearOffset={-5} />
      </div>
      <div className="col-xs-12 col-sm-6 col-md-4">
        <DatePicker
          label="End Date*"
          name="endDate"
          value={formState.endDate}
          error={errorState.endDate}
          message={errorState.endDate}
          onChange={(value) => { onFormValueChanged('endDate', value); }}
          countOfYears={15}
          startYearOffset={-5} />
      </div>
      <div className="col-xs-12 col-sm-6 col-md-4">
        <Input
          label="City*"
          name="city"
          value={formState.city}
          error={errorState.city}
          message={errorState.city}
          onChange={(value) => { onFormValueChanged('city', value) }} />
      </div>
      <div className="col-xs-12 col-sm-6 col-md-4">
        <StatesCombobox
          label="State*"
          name="stateCode"
          valueToMatch={formState.stateName}
          error={errorState.stateCode}
          message={errorState.stateCode}
          isUSA={true}
          onChange={(newValue, newValueLabel, e) => {
            onValueTextPairChanged(newValue, "stateCode", newValueLabel, "stateName", e);
          }} />
      </div>
      <div className="col-xs-12 col-sm-6 col-md-4">
        <Input
          label="Zip Code*"
          name="zip"
          value={formState.zip}
          error={errorState.zip}
          message={errorState.zip}
          onChange={(value) => { onFormValueChanged('zip', value) }} />
      </div>
      <div className="col-xs-12 col-sm-6 col-md-4">
        <Input
          label="Trip Purpose*"
          name="tripPurpose"
          value={formState.tripPurpose}
          error={errorState.tripPurpose}
          message={errorState.tripPurpose}
          onChange={(value) => { onFormValueChanged('tripPurpose', value) }} />
      </div>
    </div>
  </Fragment>
);

export default TravelTripAttributesForm;