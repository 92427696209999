import { OmrHttpHelper, NO_DATA_MESSAGE } from '../../../common/utils/HttpHelper';

const GetOrgUnitBulkRenewalOptInData = (orgUnitId, state, setState) => {
  if (state.isObjLoading !== true && orgUnitId && !state.message) {
    let newState = {
      ...state,
      orgUnitId,
      isObjLoading: true,
      isObjLoaded: false,
      message: 'Loading...'
    };
    setState(newState);

    const orgUnitIdForUrl = orgUnitId ? encodeURIComponent(orgUnitId) : 'NaN';
    const url = `/OrgUnitBulkRenewalOptIn/OrgUnit/${orgUnitIdForUrl}`;
    OmrHttpHelper(url, 'GET')
      .then((objData) => {
        if (!objData) {
          throw new Error(NO_DATA_MESSAGE);
        } else {
          newState = {
            ...newState,
            orgUnitId,
            isObjLoading: false,
            isObjLoaded: true,
            objData: objData,
            message: '',
          };
          setState(newState);
        }

      })
      .catch((e) => {
        const message = e && e.message ? e.message : NO_DATA_MESSAGE;
        setState({
          ...newState,
          isObjLoading: false,
          isObjLoaded: false,
          message: message
        });
      });
  }
};

export default GetOrgUnitBulkRenewalOptInData;