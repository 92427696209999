import React, {createContext, useState} from 'react';

import GroupCoachesData from './GroupCoachesData';

export const GroupCoachesStateContext = createContext();

const GroupCoachesContextProvider = ({children}) => {
  const stateHook = useState(GroupCoachesData.INITIAL_STATE);

  return (
    <GroupCoachesStateContext.Provider value={stateHook}>
      {children}
    </GroupCoachesStateContext.Provider>
  );
};

export default GroupCoachesContextProvider;