/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Fragment } from 'react';

import EditIcon from '../../../../icons/EditIcon';
import DeleteIcon from '../../../../icons/DeleteIcon';

import global from '../../../../GlobalStyle.module.css';

const LargeGrid = ({ state, poolConfiguration, onEdit, onDelete }) => (
  <table className={[global.UsasTable, ' visible-md visible-lg'].join(' ')}>
    <thead>
      <tr>
        <th>Course</th>
        <th>Number of Lanes</th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      {state.isObjLoaded !== true
        ? (
          <tr>
            {state.isObjLoading === true ?
              <td colSpan="3">Loading...</td>
              : <td colSpan="3">No Pool Configurations</td>
            }
          </tr>
        ) : Array.isArray(poolConfiguration) && poolConfiguration.length > 0
          ? poolConfiguration.map((poolConfig, i) => (
            <tr key={i}>
              <td>{poolConfig.course?.courseCode}</td>
              <td>{poolConfig.numberOfLanes}</td>
              <td>
                <button className={global.IconButtonMargin} type="button" onClick={(e) => onEdit(e, poolConfig, i)}>
                  <EditIcon />
                </button>
                <button className={global.IconButtonMargin} type="button" onClick={(e) => onDelete(e, i)}>
                  <DeleteIcon />
                </button>
              </td>
            </tr>))
          : (
            <Fragment>
              <tr>
                <td colSpan="3">No Pool Configurations</td>
              </tr>
            </Fragment>
          )
      }
    </tbody>
  </table>
);

const SmallGrid = ({ state, poolConfiguration, onEdit, onDelete }) => (
  <div className={[global.SmallTable, 'hidden-md hidden-lg'].join(' ')}>
    {state.isObjLoaded !== true
      ? (
        <div className={global.SmallTableRow}>
          <div className={global.SmallTableRowHead}>
            &nbsp;
          </div>
          <div className={global.SmallTableRowInfo}>
            {state.isObjLoading === true
              ? <div className={global.SmallTableRowLabels}>Loading...</div>
              : <div className={global.SmallTableRowLabels}>No Pool Configurations</div>
            }
          </div>
        </div>
      ) : Array.isArray(poolConfiguration) && poolConfiguration.length > 0
        ? poolConfiguration.map((poolConfig, i) => (
          <div className={global.SmallTableRow} key={i}>
            <div className={global.SmallTableRowHead}><span className='hidden-xs'>{poolConfig.course?.courseCode}</span>&nbsp;
              <button className={global.IconButton} type="button" onClick={(e) => onDelete(e, i)}>
                <DeleteIcon />
              </button>
              <button className={global.IconButtonMobileMargin} type="button" onClick={(e) => onEdit(e, poolConfig, i)}>
                <EditIcon />
              </button>
            </div>
            <div className={global.SmallTableRowInfo}>
              <div className='row'>
                <div className={['col-xs-6 col-sm-3 visible-xs', global.SmallTableRowLabels].join(' ')}>Course</div>
                <div className={['col-xs-6 col-sm-3 visible-xs', global.SmallTableRowData].join(' ')}>{poolConfig.course?.courseCode}</div>
                <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>Number of Lanes</div>
                <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>{poolConfig.numberOfLanes}</div>
              </div>
            </div>
          </div>
        )) : (
          <div className={global.SmallTableRow}>
            <div className={global.SmallTableRowHead}>
              &nbsp;
            </div>
            <div className={global.SmallTableRowInfo}>
              <div className={global.SmallTableRowLabels}>No Pool Configurations</div>
            </div>
          </div>
        )
    }
  </div>
);

const FacilityPoolConfigurationGrid = ({ facilityState, poolConfiguration, onEdit, onDelete }) => (
  <Fragment>
    <LargeGrid state={facilityState} poolConfiguration={poolConfiguration} onEdit={onEdit} onDelete={onDelete} />
    <SmallGrid state={facilityState} poolConfiguration={poolConfiguration} onEdit={onEdit} onDelete={onDelete} />
  </Fragment>
);

export default FacilityPoolConfigurationGrid;