import { useEffect, useState } from 'react';

import { useExecuteQuery } from '@sisense/sdk-ui';

import * as DM from '../../../../usas-times-data';

import Constants from '../../../../common/utils/Constants';

const useSisenseTimeStandardDropdown = (showDefault, showForRelays) => {
    const queryProps = { dataSource: DM.DataSource, dimensions: [DM.StandardType.SortOrder, DM.StandardType.StandardType, DM.StandardType.ShowForRelays] };
    const { data, isLoading } = useExecuteQuery(queryProps);
    const [timeStandardOptions, setTimeStandardOptions] = useState({ options: [] });

    useEffect(() => {
        const defaultOptions = [{ id: Constants.DEFAULT_ID, name: '--' }];
        if (data) {
            const formattedOptions = [];
            if (showDefault === true) {
                formattedOptions.push(defaultOptions[0]);
            }
            data.rows.map((row) => {
                if (row[0].text !== '0') {
                    if (showForRelays === true) {
                        if (row[2].text === '1') {
                            return (formattedOptions.push({ id: row[0].data, name: row[1].text }));
                        }
                    }
                    else {
                        return (formattedOptions.push({ id: row[0].data, name: row[1].text }));
                    }
                }
            });
            setTimeStandardOptions({ ...timeStandardOptions, options: formattedOptions });
        }
        else {
            setTimeStandardOptions({ ...timeStandardOptions, options: showDefault === true ? defaultOptions : [] });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, showDefault, showForRelays])

    return {
        data,
        isLoading,
        timeStandardOptions
    };
};

export default useSisenseTimeStandardDropdown;