import { Fragment } from 'react';

import useLearnToSwimRegistrationLeftNav from './UseLearnToSwimRegistrationLeftNav';

import LeftNav from '../../../../../common/components/leftNav/LeftNav';
import Headings from '../../../../../common/components/headings/Headings';
import PopUpModal from '../../../../../common/components/dialogs/PopUpModal';

import Constants from '../../../../../common/utils/Constants';

import style from './LearnToSwimRegistrationLeftNav.module.css';

const LeftNavigationTitle = ({ objData }) => (
  <div className={style.RegFor}>
    <Headings.H5>Provider:</Headings.H5>
    <Headings.H6>{objData.entityName || ''}</Headings.H6>
    <Headings.H5>Tax Id:</Headings.H5>
    <Headings.H6>{objData.taxId || ''}</Headings.H6>
  </div>
);

const LearnToSwimRegistrationLeftNav = ({ viewName, viewTitle, children }) => {
  const {
    ltsRegistrationLeftNavState
  } = useLearnToSwimRegistrationLeftNav(viewName);

  return (
    <Fragment>
      <div className="row usas-extra-bottom-margin">
        <div className="col-xs-12">
          <Headings.H2 excludeBar={true} className={style.CenterAlign}>Learn To Swim Provider Registration</Headings.H2>
          <hr className="usas-bar-turmeric" />
        </div>
      </div>
      {ltsRegistrationLeftNavState.isLoaded === true ? (
        <LeftNav
          formattedTitle={<LeftNavigationTitle objData={ltsRegistrationLeftNavState.objData || {}} />}
          state={ltsRegistrationLeftNavState.arrayData || []}
          validateBeforeRedirect={false}>
          {viewTitle &&
            <div className='row usas-extra-top-margin'>
              <div className='col-xs-12'>
                <Headings.H3 excludeBar={true}>{viewTitle}</Headings.H3>
              </div>
            </div>
          }
          <div className='row usas-extra-top-margin'>
            <div className='col-xs-12'>
              {children}
            </div>
          </div>
        </LeftNav>
      ) : (
        <PopUpModal
          widthPct={50}
          maxWidth={250}
          title={Constants.LOADING_MSG}
          displayPopUp={true} />
      )}
    </Fragment>
  );
};

export default LearnToSwimRegistrationLeftNav;