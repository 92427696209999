import { useContext, useState } from 'react';

import LearnToSwimFacilitiesData from './LearnToSwimFacilitiesData';
import { LearnToSwimFacilitiesStateContext } from './LearnToSwimFacilitiesContextProvider';

import { BASIC_INITIAL_STATE } from '../../../common/utils/HttpHelper';

const INITIAL_LTS_FACILITY_STATE = {
  ...BASIC_INITIAL_STATE
};

const useLearnToSwimFacilitiesData = () => {
  const [ltsFacilitiesState, setLTSFacilitiesState] = useContext(LearnToSwimFacilitiesStateContext);
  const [ltsFacilityState, setLTSFacilityState] = useState(INITIAL_LTS_FACILITY_STATE);

  const postLTSFacility = (orgUnitId, orgUnitFacilityObj) => {
    return LearnToSwimFacilitiesData.postLTSFacilityData(orgUnitId, orgUnitFacilityObj, ltsFacilityState, setLTSFacilityState);
  };

  const getLTSFacilities = (orgUnitId) => {
    return LearnToSwimFacilitiesData.getLTSFacilitiesData(orgUnitId, ltsFacilitiesState, setLTSFacilitiesState);
  };

  const putLTSFacility = (orgUnitId, orgUnitFacilityId, orgUnitFacilityObj) => {
    return LearnToSwimFacilitiesData.putLTSFacilityData(orgUnitId, orgUnitFacilityId, orgUnitFacilityObj, ltsFacilityState, setLTSFacilityState);
  };

  const deleteLTSFacility = (orgUnitId, orgUnitFacilityId) => {
    return LearnToSwimFacilitiesData.deleteLTSFacilityData(orgUnitId, orgUnitFacilityId, ltsFacilityState, setLTSFacilityState);
  };

  return {
    ltsFacilitiesState,
    ltsFacilityState,
    postLTSFacility,
    getLTSFacilities,
    putLTSFacility,
    deleteLTSFacility
  };
};

export default useLearnToSwimFacilitiesData;