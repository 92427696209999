import { useState } from 'react';

import PreCompFilesEmergencyPlansData from './PreCompFilesEmergencyPlansData';

const usePreCompFilesEmergencyPlansData = () => {
  const [preCompFilesEmergencyPlansState, setPreCompFilesEmergencyPlansState] = useState(PreCompFilesEmergencyPlansData.INITIAL_STATE);

  const postPreCompFilesEmergencyPlans = (file) => {
    PreCompFilesEmergencyPlansData.postPreCompFilesEmergencyPlansData(file, preCompFilesEmergencyPlansState, setPreCompFilesEmergencyPlansState);
  };

  const resetPreCompFilesEmergencyPlansState = () => {
    setPreCompFilesEmergencyPlansState(PreCompFilesEmergencyPlansData.INITIAL_STATE);
  };

  const getFileNameFromSavedUploadUrl = () => {
    if (preCompFilesEmergencyPlansState.isObjLoaded === true) {
      const fileNameWithDateTime = preCompFilesEmergencyPlansState.objData.uploadUrl.replace(PreCompFilesEmergencyPlansData.BASE_UPLOAD_URL, '');
      const indexOfEndOfDateTime = fileNameWithDateTime.indexOf('.') + 1;
      const fileName = fileNameWithDateTime.substring(indexOfEndOfDateTime);
      return preCompFilesEmergencyPlansState.objData.uploadUrl ? decodeURI(fileName) : '';
    } else {
      return '';
    }
  };

  const getFileNameFromUrl = (url) => {
    const fileNameWithDateTime = url.replace(PreCompFilesEmergencyPlansData.BASE_UPLOAD_URL, '');
    const indexOfEndOfDateTime = fileNameWithDateTime.indexOf('.') + 1;
    const fileName = fileNameWithDateTime.substring(indexOfEndOfDateTime);
    return decodeURI(fileName) || '';
  };

  return {
    preCompFilesEmergencyPlansState,
    postPreCompFilesEmergencyPlans,
    getFileNameFromSavedUploadUrl,
    getFileNameFromUrl,
    resetPreCompFilesEmergencyPlansState,
    BASE_UPLOAD_URL: PreCompFilesEmergencyPlansData.BASE_UPLOAD_URL,
    GET_DOWNLOAD_TEMPLATE_PATH: PreCompFilesEmergencyPlansData.GET_DOWNLOAD_TEMPLATE_PATH,
    GET_PRE_COMP_FILES_EMERGENCY_PLANS_PATH: PreCompFilesEmergencyPlansData.GET_PRE_COMP_FILES_EMERGENCY_PLANS_PATH,
  };
};

export default usePreCompFilesEmergencyPlansData;