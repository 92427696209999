import React, { Fragment } from 'react';

import EditIcon from '../../../../../common/components/icons/EditIcon';

import { formatPhoneNumber } from '../../../../../common/utils/validation';

import global from '../../../../../common/components/GlobalStyle.module.css';

const GridRow = ({ ltsProvider, onEditClicked }) => (
  <div className={global.SmallTableRow}>
    <div className={global.SmallTableRowHead}>
      <span className='hidden-xs'>{ltsProvider.entityName || ''}</span>&nbsp;
      <button
        className={global.IconButton}
        type="button"
        onClick={() => onEditClicked(ltsProvider.learnToSwimProviderId)}>
        <EditIcon />
      </button>
    </div>
    <div className={global.SmallTableRowInfo}>
      <div className='row'>
        <div className={[global.SmallTableRowLabels, 'col-xs-6 visible-xs'].join(' ')}>Business Legal Name</div>
        <div className={[global.SmallTableRowData, 'col-xs-6 visible-xs'].join(' ')}>{ltsProvider.entityName || <span>&nbsp;</span>}</div>
        <div className={[global.SmallTableRowLabels, 'col-xs-6 visible-xs'].join(' ')}>Trade Name/DBA</div>
        <div className={[global.SmallTableRowData, 'col-xs-6 visible-xs'].join(' ')}>{ltsProvider.tradeName || <span>&nbsp;</span>}</div>
        <div className={[global.SmallTableRowLabels, 'col-xs-6 visible-xs'].join(' ')}>Contact</div>
        <div className={[global.SmallTableRowData, 'col-xs-6 visible-xs'].join(' ')}>{ltsProvider.contactFirstName || <span>&nbsp;</span>} {ltsProvider.contactLastName || <span>&nbsp;</span>}</div>
        <div className={[global.SmallTableRowLabels, 'col-xs-6 visible-xs'].join(' ')}>Contact Email</div>
        <div className={[global.SmallTableRowData, 'col-xs-6 visible-xs'].join(' ')}>{ltsProvider.contactEmail || <span>&nbsp;</span>}</div>
        <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3'].join(' ')}>Contact Phone</div>
        <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3'].join(' ')}>{ltsProvider.contactPhoneNumber ? formatPhoneNumber(ltsProvider.contactPhoneNumber) : <span>&nbsp;</span>}</div>
      </div>
    </div>
  </div>
);

const ProviderSearchGridSmall = ({ data, isLoading, onEditClicked }) => (
  <Fragment>
    <div className={[global.SmallTable, 'hidden-lg hidden-md'].join(' ')}>
      {isLoading === true
        ? (
          <div className={global.SmallTableRow}>
            <div className={global.SmallTableRowHead}>&nbsp;</div>
            <div className={global.SmallTableRowInfo}><div className={global.SmallTableRowLabels}>Loading...</div></div>
          </div>
        )
        : data.length > 0
          ? data.map((ltsProvider, i) => <GridRow key={i} ltsProvider={ltsProvider} onEditClicked={onEditClicked} />)
          : (
            <div className={global.SmallTableRow}>
              <div className={global.SmallTableRowHead}>&nbsp;</div>
              <div className={global.SmallTableRowInfo}><div className={global.SmallTableRowLabels}>No Learn To Swim Providers</div></div>
            </div>
          )
      }
    </div>
  </Fragment>
);

export default ProviderSearchGridSmall;