import React, { Fragment } from "react";

import PrizeDropdown from "../../../../components/dropdowns/prizeDropdown/PrizeDropdown";

import CurrencyInput from "../../../../../common/components/inputs/CurrencyInput";
import Input from "../../../../../common/components/inputs/Input";

const SATParticipantForm = ({ formState, errorState, onAmountRaisedChanged, onFormValueChanged, onValueTextPairChanged }) => (
  <Fragment>
    <div className="row usas-extra-top-margin">
      <div className="col-xs-12 col-sm-6 col-md-4">
        <CurrencyInput
          label="Amount Raised*"
          name="amountRaised"
          value={formState.amountRaised}
          error={errorState.amountRaised}
          message={errorState.amountRaised}
          onChange={(value) => { onAmountRaisedChanged(value); }} />
      </div>
      <div className="col-xs-12 col-sm-6 col-md-4">
        <PrizeDropdown
          label="Prize*"
          name="satPrizeId"
          value={formState.satPrizeId}
          error={errorState.satPrizeId}
          message={errorState.satPrizeId}
          onChange={(newValue, newValueLabel, e) => {
            e && e.target && e.target.value &&
              onValueTextPairChanged(newValue, 'satPrizeId', newValueLabel, 'satPrizeName');
          }}
          amountRaised={formState.amountRaised} />
      </div>
      <div className="col-xs-12 col-sm-6 col-md-4">
        <Input
          label="Participant Name*"
          name="participantName"
          value={formState.participantName}
          error={errorState.participantName}
          message={errorState.participantName}
          onChange={(value) => { onFormValueChanged('participantName', value); }} />
      </div>
    </div>
  </Fragment>
);

export default SATParticipantForm;