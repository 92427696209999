import usePreCompLocationData from '../../../state/preCompLocation/UsePreCompLocationData';
import useFacilityData from '../../../../common/state/facility/UseFacilityData';

const usePreCompLocationFacilitiesAdd = () => {
  const { facilityState } = useFacilityData();
  const { preCompLocationState } = usePreCompLocationData();

  return {
    preCompLocationState,
    facilityState
  };
};

export default usePreCompLocationFacilitiesAdd;