import { Fragment } from 'react';
import UseClubBlockPartyMeetAdd from './UseClubBlockPartyMeetAdd';
import ClubBlockPartyMeetAddForm from './ClubBlockPartyMeetAddForm';
import ClubBlockPartyMeetViewForm from './ClubBlockPartyMeetViewForm';

import Headings from '../../../../common/components/headings/Headings';
import PrimaryButton from '../../../../common/components/buttons/PrimaryButton';
import SecondaryButton from '../../../../common/components/buttons/SecondaryButton';
import PopUpModal from '../../../../common/components/dialogs/PopUpModal';
import Constants from '../../../../common/utils/Constants';

const ClubBlockPartyMeetAdd = () => {
  const {
    clubBlockPartyMeetsState,
    formState,
    errorState,
    facilityOptionsState,
    meetFilesState,
    removeFileModalState,
    modalState,
    state,
    environmentVariableState,
    facilityConfirmationState,
    handleSubmitProxy,
    orgUnitId,
    onBackClicked,
    onFormValueChanged,
    onValueTextPairChanged,
    onUploadTemplateClicked,
    onRemoveTemplateClicked,
    onOpenRemoveFileModalClicked,
    onRemoveFileModalCanceled,
    onModalCancelClicked,
    BLOCK_PARTY_MEET_CLASSIFICATION_PARENT_ID,
    mode,
    GET_MEET_FILES_PATH,
    setFormState,
    location
  } = UseClubBlockPartyMeetAdd();

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>Block Party Meet {mode}</Headings.H3>
        </div>
      </div>
      {location.state === null || location.state.hasAdminRole === true || location.state.meet.status !== 'Paid' ?
      <ClubBlockPartyMeetAddForm 
        handleSubmitProxy={handleSubmitProxy} 
        formState={formState} 
        errorState={errorState} 
        facilityOptionsState={facilityOptionsState}
        state={state}
        modalState={modalState}
        facilityConfirmationState={facilityConfirmationState}
        orgUnitId={orgUnitId}
        onBackClicked={onBackClicked}
        onUploadTemplateClicked={onUploadTemplateClicked}
        onOpenRemoveFileModalClicked={onOpenRemoveFileModalClicked}
        onFormValueChanged={onFormValueChanged}
        onValueTextPairChanged={onValueTextPairChanged}
        onModalCancelClicked={onModalCancelClicked}
        BLOCK_PARTY_MEET_CLASSIFICATION_PARENT_ID={BLOCK_PARTY_MEET_CLASSIFICATION_PARENT_ID}
        GET_MEET_FILES_PATH={GET_MEET_FILES_PATH}
        setFormState={setFormState}
        status={location.state === null ? 'Pending' : location.state.meet.status}
      />
      : 
      <ClubBlockPartyMeetViewForm 
        formState={formState}
        onBackClicked={onBackClicked}
        status={location.state.meet.status}
      />
      }
      <PopUpModal
        widthPct={90}
        maxWidth={720}
        title={removeFileModalState.modalTitle}
        displayPopUp={removeFileModalState.displayPopUp}
        onModalCanceled={onRemoveFileModalCanceled}>
        <div className="row">
          <div className="col-xs-12">
            <p><b>File Type:</b> Meet Announcement</p>
            <p><b>Uploaded File Name:</b> {removeFileModalState.fileName}</p>
          </div>
        </div>
        <div className="row usas-extra-top-margin">
          <div className="col-xs-12">
            <PrimaryButton type="button" onClick={onRemoveTemplateClicked}>Yes, Remove</PrimaryButton>&nbsp;
            <SecondaryButton type="button" onClick={onRemoveFileModalCanceled}>Cancel</SecondaryButton>
          </div>
        </div>
      </PopUpModal>
      
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.SAVING_MSG}
        displayPopUp={clubBlockPartyMeetsState.isSaving} />
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.SAVING_MSG}
        displayPopUp={meetFilesState.isSaving} />
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={environmentVariableState.isLoading} />
    </Fragment>
  );
};

export default ClubBlockPartyMeetAdd;