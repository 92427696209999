import { useEffect } from "react";

import validate from './MeetSanctionStaffFormValidation';

import UseForm from "../../../../../../common/utils/UseForm";

const UseMeetSanctionStaffForm = (savedFormState, onSubmitFormCallback, OFFICIAL_ORG_ROLE_ID, OFFICIALS_ROLES_ROLE_GROUP_ID) => {
  const { formState, errorState, isSubmitting, setIsDirty, handleSubmit, updateFormState, setFormState
  } = UseForm(getInitialFormState, onSubmitFormCallback, validate);

  useEffect(() => {
    setFormState({
      ...formState,
      ...savedFormState
    });
    setIsDirty(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function getInitialFormState() {
    return {
      meetDirectorMemberId: '',
      meetRefereeMemberId: '',
      meetContactMemberId: '',
      meetDirectorPersonId: '',
      meetRefereePersonId: '',
      meetContactPersonId: '',
      OFFICIAL_ORG_ROLE_ID: OFFICIAL_ORG_ROLE_ID,
      OFFICIALS_ROLES_ROLE_GROUP_ID: OFFICIALS_ROLES_ROLE_GROUP_ID
    };
  };

  return {
    formState,
    errorState,
    isSubmitting,
    handleSubmit,
    onFormValueChanged: updateFormState,
    setFormState
  };
};

export default UseMeetSanctionStaffForm;