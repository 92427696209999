import { BASIC_INITIAL_STATE } from '../../../common/utils/HttpHelper';

import getClubMeetsData from './GetClubMeetsData';

import postClubMeetSanctionRequestData from '../../../common/state/meet/PostMeetData';
import deleteMeetData from '../../../common/state/meet/DeleteMeetData';

const INITIAL_STATE = {
  ...BASIC_INITIAL_STATE
};

const ClubMeetsData = {
  INITIAL_STATE,
  getClubMeetsData,
  postClubMeetSanctionRequestData,
  deleteMeetData
};

export default ClubMeetsData;