import React, { Fragment } from 'react';

import useClubRegistrationFacilities from './UseClubRegistrationFacilities';

import ClubRegistrationFacilitiesGridWrite from './ClubRegistrationFacilitiesGridWrite';
import ClubRegistrationFacilitiesGridRead from './ClubRegistrationFacilitiesGridRead';

import ValidationPopUpModal from '../components/ValidationPopUpModal';

import ClubRegistrationConstants from '../utils/ClubRegistrationConstants';

import LeftNav from '../../../../common/components/leftNav/LeftNav';
import Headings from '../../../../common/components/headings/Headings';
import PrimaryButton from '../../../../common/components/buttons/PrimaryButton';
import SecondaryButton from '../../../../common/components/buttons/SecondaryButton';
import ActionIntraPageButton from '../../../../common/components/buttons/ActionIntraPageButton';
import PopUpModal from '../../../../common/components/dialogs/PopUpModal';
import Constants from '../../../../common/utils/Constants';

import global from '../../../../common/components/GlobalStyle.module.css';

const WriteComponent = ({ onAddFacility, onOpenDeleteModalClicked, state, onDeletePool }) => (
  <Fragment>
    <div className="row usas-extra-top-margin">
      <div className="col-xs-12">
        <ActionIntraPageButton type="button" onClick={onAddFacility}>Add Facility</ActionIntraPageButton>
      </div>
    </div>
    <div className="row usas-extra-top-margin">
      <div className="col-xs-12">
        <ClubRegistrationFacilitiesGridWrite
          onDelete={onOpenDeleteModalClicked}
          onDeletePool={onDeletePool} />
      </div>
    </div>
    {state.error &&
      <div className="row">
        <div className="col-xs-12">
          <p className={global.ErrorMessage}>{state.error ? state.error : ''}</p>
        </div>
      </div>}
  </Fragment>
);

const ReadComponent = () => (
  <Fragment>
    <div className="row usas-extra-top-margin">
      <div className="col-xs-12 usas-extra-bottom-margin">
        <ClubRegistrationFacilitiesGridRead />
      </div>
    </div>
  </Fragment>
);

const ClubRegistrationFacilities = () => {
  const { usasClubFacilityLinksState, clubRegistrationNavState, clubRegistrationState, state,
    modalState, onModalContinue, onModalCanceled, poolModalState, onPoolModalCanceled, onDeletePool,
    onClickDeletePool, deleteModalState, onAddFacility, onDeleteModalCanceled, onOpenDeleteModalClicked,
    onDeleteFacilityFromClub, onContinueClicked, onSaveAndExitClicked, onBackClicked
  } = useClubRegistrationFacilities();

  return (
    <LeftNav title={ClubRegistrationConstants.LEFT_NAV_TITLE} state={clubRegistrationNavState} validateBeforeRedirect={false}>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>Club Facilities</Headings.H3>
        </div>
      </div>
      {clubRegistrationState.objData.status === ClubRegistrationConstants.IN_PROGRESS_STATUS ||
        clubRegistrationState.objData.status === ClubRegistrationConstants.RENEWING_STATUS ?
        <WriteComponent
          onAddFacility={onAddFacility}
          onOpenDeleteModalClicked={onOpenDeleteModalClicked}
          state={state}
          onDeletePool={onDeletePool}
        /> :
        <ReadComponent />
      }
      <div className="row usas-extra-top-margin usas-extra-bottom-margin">
        <div className="col-xs-12 usas-extra-top-margin">
          <PrimaryButton type="button" onClick={() => onContinueClicked()}>Continue</PrimaryButton>&nbsp;
          <PrimaryButton type="button" onClick={() => onSaveAndExitClicked()}>
            {clubRegistrationState.objData.status === ClubRegistrationConstants.IN_PROGRESS_STATUS
              || clubRegistrationState.objData.status === ClubRegistrationConstants.RENEWING_STATUS ?
              ClubRegistrationConstants.SAVE_AND_EXIT : ClubRegistrationConstants.EXIT}</PrimaryButton>&nbsp;
          <SecondaryButton type="button" onClick={() => onBackClicked()}>Back</SecondaryButton>
        </div>
      </div>
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={usasClubFacilityLinksState.isArrayLoading} />
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.SAVING_MSG}
        displayPopUp={usasClubFacilityLinksState.isSaving} />
      <ValidationPopUpModal
        clubRegistrationState={clubRegistrationState}
        state={state}
        modalState={modalState}
        onModalContinue={onModalContinue}
        onModalCanceled={onModalCanceled}
      />
      <PopUpModal
        widthPct={90}
        maxWidth={720}
        title={deleteModalState.modalTitle}
        displayPopUp={deleteModalState.displayPopUp}
        onModalCanceled={onDeleteModalCanceled}>
        <div className="row">
          <div className="col-xs-12">
            <p><b>Facility Name:</b> {deleteModalState.facilityName}</p>
            <p><b>Facility Location:</b> {deleteModalState.facilityCity}, {deleteModalState.facilityStateCode} {deleteModalState.facilityPostalCode}</p>
          </div>
        </div>
        <div className="row usas-extra-top-margin">
          <div className="col-xs-12">
            <PrimaryButton type="button" onClick={onDeleteFacilityFromClub}>Unlink</PrimaryButton>&nbsp;
            <SecondaryButton type="button" onClick={onDeleteModalCanceled}>Cancel</SecondaryButton>
          </div>
        </div>
      </PopUpModal>
      <PopUpModal
        title={poolModalState.modalTitle}
        displayPopUp={poolModalState.displayPopup}
        widthPct={90}
        maxWidth={720}
        onModalCanceled={onPoolModalCanceled}>
        <div className="row">
          <div className="col-xs-12">
            <p><b>Pool Number / Name:</b> {poolModalState.poolName}</p>
            <p><b>Pool Dimensions:</b> {poolModalState.poolDimensions}</p>
          </div>
        </div>
        <div className="row usas-extra-top-margin">
          <div className="col-xs-12">
            <PrimaryButton type="button" onClick={(e) => { onClickDeletePool(e, poolModalState.poolId) }}>Yes, delete</PrimaryButton>&nbsp;
            <SecondaryButton type="button" onClick={onPoolModalCanceled}>Cancel</SecondaryButton>
          </div>
        </div>
      </PopUpModal>
    </LeftNav>
  )
}

export default ClubRegistrationFacilities;