import React, { Fragment } from 'react';

import useLearnToSwimStaff from './UseLearnToSwimStaff';

import ProviderStaffGrid from '../components/grids/ProviderStaffGrid';

import Headings from '../../../../common/components/headings/Headings';
import PrimaryButton from '../../../../common/components/buttons/PrimaryButton';
import PopUpModal from '../../../../common/components/dialogs/PopUpModal';
import SecondaryButton from '../../../../common/components/buttons/SecondaryButton';
import ActionIntraPageButton from '../../../../common/components/buttons/ActionIntraPageButton';

import Constants from '../../../../common/utils/Constants';

const LearnToSwimStaff = () => {
  const {
    isLoading,
    isSaving,
    deleteModalState,
    ltsStaffState,
    onAddStaffClicked,
    onEditStaffClicked,
    onDeleteStaffClicked,
    onDeleteStaffRoleClicked,
    onDeleteModalDeleteClicked,
    onDeleteModalCancelClicked
  } = useLearnToSwimStaff();

  return (
    <Fragment>
      <div className='row'>
        <div className='col-xs-12 usas-extra-top-margin'>
          <Headings.H3>Staff</Headings.H3>
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12 usas-extra-top-margin">
          <ActionIntraPageButton onClick={onAddStaffClicked}>Add Staff</ActionIntraPageButton>
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12 usas-extra-top-margin usas-extra-bottom-margin">
          <ProviderStaffGrid
            data={ltsStaffState.arrayData}
            isLoading={isLoading}
            onEditClicked={onEditStaffClicked}
            onDeleteClicked={onDeleteStaffClicked}
            onDeleteRoleClicked={onDeleteStaffRoleClicked} />
        </div>
      </div>
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.SAVING_MSG}
        displayPopUp={isSaving} />
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={isLoading} />
      <PopUpModal
        widthPct={90}
        maxWidth={720}
        title={"Remove Staff Member?"}
        displayPopUp={deleteModalState.displayPopup}
        onModalCanceled={onDeleteModalCancelClicked}>
        <div className="row">
          <div className="col-xs-12">
            {deleteModalState.personOrgRoleDurationId > 0
              ? <p>{`Removing ${deleteModalState.personName}'s last role will remove them from this Learn To Swim Provider's staff`}</p>
              : <p>{`Remove ${deleteModalState.personName} from this Learn To Swim Provider's staff`}</p>
            }
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12 usas-extra-top-margin">
            <PrimaryButton type="button" onClick={onDeleteModalDeleteClicked}>Remove Staff Member</PrimaryButton>&nbsp;
            <SecondaryButton type="button" onClick={onDeleteModalCancelClicked}>Cancel</SecondaryButton>
          </div>
        </div>
      </PopUpModal>
    </Fragment>
  );
};

export default LearnToSwimStaff;