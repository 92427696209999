import usePreCompInstructionHoursData from '../../../../state/preCompInstructionHours/UsePreCompInstructionHoursData';

const useInstructionHoursDropdown = () => {
  const { preCompInstructionHoursState } = usePreCompInstructionHoursData();

  return {
    preCompInstructionHoursState
  };
};

export default useInstructionHoursDropdown;