import { useEffect } from 'react';

import useBusinessEntityData from '../../state/businessEntity/UseBusinessEntityData';

const useBusinessEntityDropdown = (clubOwnerTypeId) => {
  const { businessEntityState, getBusinessEntityTypes } = useBusinessEntityData();

  useEffect(() => {
    if (businessEntityState.isArrayLoading !== true) {
      getBusinessEntityTypes(clubOwnerTypeId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clubOwnerTypeId]);

  return {
    businessEntityState
  };
};

export default useBusinessEntityDropdown;