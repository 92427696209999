import { useContext } from 'react';

import { GroupFacilitiesStateContext } from './GroupFacilitiesContextProvider';
import GroupFacilitiesData from './GroupFacilitiesData';

const useGroupFacilitiesData = () => {
  const [groupFacilitiesState, setGroupFacilitiesState] = useContext(GroupFacilitiesStateContext);

  const getGroupFacilitiesByUsasClubId = (usasClubId) => {
    if (usasClubId !== groupFacilitiesState.usasClubId) {
      GroupFacilitiesData.getGroupFacilitiesByUsasClubId(usasClubId, groupFacilitiesState, setGroupFacilitiesState);
    }
  };

  const getGroupFacilitiesByOrgUnitId = (orgUnitId) => {
    if (orgUnitId !== groupFacilitiesState.orgUnitId) {
      GroupFacilitiesData.getGroupFacilitiesOrgUnitId(orgUnitId, groupFacilitiesState, setGroupFacilitiesState);
    }
  };

  return {
    groupFacilitiesState,
    getGroupFacilitiesByUsasClubId,
    getGroupFacilitiesByOrgUnitId
  };
};

export default useGroupFacilitiesData;