import React, { Fragment } from 'react';

import useClubRegistrationEmergencyPlan from './UseClubRegistrationEmergencyPlan';
import ValidationPopUpModal from '../components/ValidationPopUpModal';
import ClubRegistrationConstants from '../utils/ClubRegistrationConstants';

import LeftNav from '../../../../common/components/leftNav/LeftNav';
import Headings from '../../../../common/components/headings/Headings';
import PrimaryButton from '../../../../common/components/buttons/PrimaryButton';
import SecondaryButton from '../../../../common/components/buttons/SecondaryButton';
import PopUpModal from '../../../../common/components/dialogs/PopUpModal';

import Constants from '../../../../common/utils/Constants';
import global from '../../../../common/components/GlobalStyle.module.css';

const SAFETY = 'safety';

const ClubRegistrationEmergencyPlan = () => {
  const { GET_DOWNLOAD_TEMPLATE_PATH, GET_USAS_CLUB_FILES_PATH, clubRegistrationNavState, clubRegistrationState, modalState, removeFileModalState,
    state, fileState, usasClubFilesState, onUploadTemplateClicked, onRemoveTemplateClicked, onOpenRemoveFileModalClicked,
    onRemoveFileModalCanceled, onModalContinue, onModalCanceled, onContinueClicked, onSaveAndExitClicked, onBackClicked
  } = useClubRegistrationEmergencyPlan();

  return (
    <LeftNav title={ClubRegistrationConstants.LEFT_NAV_TITLE} state={clubRegistrationNavState} validateBeforeRedirect={false}>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>Emergency Plan</Headings.H3>
        </div>
      </div>

      {clubRegistrationState.objData.status === ClubRegistrationConstants.IN_PROGRESS_STATUS ||
        clubRegistrationState.objData.status === ClubRegistrationConstants.RENEWING_STATUS ?
        <div className="row usas-extra-top-margin">
          <div className="col-xs-12">
            <PrimaryButton type="button" onClick={(event) => {
              event.preventDefault();
              window.location.href = import.meta.env.VITE_CLUBAPI_URL + GET_DOWNLOAD_TEMPLATE_PATH + SAFETY;
            }}>Download Template</PrimaryButton>&nbsp;
            {fileState.templateFileUrl === '' ?
              <Fragment>
                <label htmlFor="emergency-plan-file-upload" className={global.FileUpload}>
                  Upload File
                </label>
                <input hidden id="emergency-plan-file-upload" type="file" onChange={onUploadTemplateClicked} />
                &nbsp;(*.pdf), (*.doc), or (*.docx)
              </Fragment>
              :
              <Fragment>
                <SecondaryButton onClick={onOpenRemoveFileModalClicked}>
                  {fileState.templateFileName.includes("Bad Request") ? "Clear Invalid" : "Remove Uploaded"} File
                </SecondaryButton>&nbsp;
                {fileState.templateFileName.includes("Bad Request") === false &&
                  <PrimaryButton type="button" onClick={(event) => {
                    event.preventDefault();
                    const fileUrl = fileState.templateFileUrl;
                    window.location.href = import.meta.env.VITE_CLUBAPI_URL + GET_USAS_CLUB_FILES_PATH + fileUrl;
                  }}>Download Uploaded File</PrimaryButton>
                }
              </Fragment>}
          </div>
        </div> :
        <Fragment >
          <div className="row usas-extra-bottom-margin" hidden={fileState.templateFileUrl !== ''}>
            <div className="col-xs-12">
              <p><b>No Uploaded File</b> </p>
            </div>
          </div>
        </Fragment>
      }
      <div className="row usas-extra-bottom-margin" hidden={fileState.templateFileUrl === ''}>
        <div className="col-xs-12">
          {fileState.templateFileName.includes("Bad Request")
            ? <p className={global.ErrorMessage} style={{ fontSize: 16, fontWeight: 'bold' }}>Invalid File Type</p>
            : <p><b>Uploaded File Name:</b> {fileState.templateFileName}</p>
          }
        </div>
      </div>
      {
        ((clubRegistrationState.objData.status === ClubRegistrationConstants.IN_PROGRESS_STATUS ||
          clubRegistrationState.objData.status === ClubRegistrationConstants.RENEWING_STATUS) && state.error) ?
          <div className="row">
            <div className="col-xs-12">
              <p className={global.ErrorMessage}>{state.error ? state.error : ''}</p>
            </div>
          </div> : <Fragment />
      }
      <div className="row usas-extra-top-margin usas-extra-bottom-margin">
        <div className="col-xs-12 usas-extra-top-margin">
          <PrimaryButton type="button" onClick={() => onContinueClicked()}>Continue</PrimaryButton>&nbsp;
          <PrimaryButton type="button" onClick={() => onSaveAndExitClicked()}>
            {clubRegistrationState.objData.status === ClubRegistrationConstants.IN_PROGRESS_STATUS ||
              clubRegistrationState.objData.status === ClubRegistrationConstants.RENEWING_STATUS ?
              ClubRegistrationConstants.SAVE_AND_EXIT : ClubRegistrationConstants.EXIT}</PrimaryButton>&nbsp;
          <SecondaryButton type="button" onClick={() => onBackClicked()}>Back</SecondaryButton>
        </div>
      </div>
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={clubRegistrationState.isObjLoading} />
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.SAVING_MSG}
        displayPopUp={clubRegistrationState.isSaving || usasClubFilesState.isSaving} />
      <ValidationPopUpModal
        clubRegistrationState={clubRegistrationState}
        state={state}
        modalState={modalState}
        onModalContinue={onModalContinue}
        onModalCanceled={onModalCanceled}
      />
      <PopUpModal
        widthPct={90}
        maxWidth={720}
        title={removeFileModalState.modalTitle}
        displayPopUp={removeFileModalState.displayPopUp}
        onModalCanceled={onRemoveFileModalCanceled}>
        <div className="row">
          <div className="col-xs-12">
            <p><b>File Type:</b> Emergency Plan</p>
            {removeFileModalState.fileName.includes("Bad Request")
              ? <p className={global.ErrorMessage} style={{ fontSize: 16, fontWeight: 'bold' }}>Invalid File Type</p>
              : <p><b>Uploaded File Name:</b> {removeFileModalState.fileName}</p>
            }
          </div>
        </div>
        <div className="row usas-extra-top-margin">
          <div className="col-xs-12">
            <PrimaryButton type="button" onClick={onRemoveTemplateClicked}>Yes, Remove</PrimaryButton>&nbsp;
            <SecondaryButton type="button" onClick={onRemoveFileModalCanceled}>Cancel</SecondaryButton>
          </div>
        </div>
      </PopUpModal>
    </LeftNav >
  )
}

export default ClubRegistrationEmergencyPlan;