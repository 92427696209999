import { Fragment } from 'react';

import Checkbox from '../../../../common/components/checkboxes/Checkbox';
import SGColumnHeader from '../../../../common/components/grids/SGColumnHeader';

import { formatDate } from '../../../../common/utils/DateFunctions';
import useSortableGrid from '../../../../common/utils/UseSortableGrid';
import SGConstants from '../../../../common/utils/SGConstants';

import global from '../../../../common/components/GlobalStyle.module.css';

const GridRow = ({ person, onSelected }) => {
  return (
    <Fragment>
      <tr>
        <td>
          {(person?.firstName || person?.middleName || person?.lastName) ? `${person?.firstName || ''}  ${person?.preferredName !== '' && person?.preferredName !== person?.firstName ? '"' + person?.preferredName + '"' : ''} ${person?.middleName || ''} ${person?.lastName || ''}` : ''}
        </td>
        <td>{person?.memberId || ''}</td>
        <td>{person?.competitionGenderTypeName || ''}</td>
        <td>{person?.birthDate ? formatDate(person?.birthDate) : ''}</td>
        <td>{person?.lscCode}/{person?.clubCode}</td>
        <td>
          <Checkbox
            label=""
            name="isSelected"
            onChange={() => onSelected(person.memberId, person.isSelected)}
            checked={person.isSelected ?? false} />
        </td>
      </tr>
    </Fragment>
  );
};

const ClubThirdPartyInvoicesDetailPersonPotentialMatchGridLarge = ({ gridData, isLoading, onSelected }) => {
  const {
    sortableGridState,
    onColumnClicked
  } = useSortableGrid(gridData);

  return (
    <table className={[global.UsasTable, 'visible-md visible-lg'].join(' ')}>
      <thead>
        <tr>
          <SGColumnHeader sortableGridState={sortableGridState} columnLabel={'Name'} columnField={'lastName'}
            sortType={SGConstants.SORT_TYPE_ALPHABETICAL} onColumnClicked={onColumnClicked} />
          <SGColumnHeader style={{ minWidth: '100px' }} sortableGridState={sortableGridState} columnLabel={'Member ID'} columnField={'memberId'}
            sortType={SGConstants.SORT_TYPE_ALPHABETICAL} onColumnClicked={onColumnClicked} />
          <SGColumnHeader style={{ minWidth: '170px' }} sortableGridState={sortableGridState} columnLabel={'Competition Category'} columnField={'competitionGenderTypeName'}
            sortType={SGConstants.SORT_TYPE_ALPHABETICAL} onColumnClicked={onColumnClicked} />
          <SGColumnHeader style={{ minWidth: '100px' }} sortableGridState={sortableGridState} columnLabel={'Birth Date'} columnField={'birthDate'}
            sortType={SGConstants.SORT_TYPE_DATE} onColumnClicked={onColumnClicked} />
          <SGColumnHeader style={{ minWidth: '100px' }} sortableGridState={sortableGridState} columnLabel={'LSC / Club Code'} columnField={'lscCode'}
            sortType={SGConstants.SORT_TYPE_ALPHABETICAL} onColumnClicked={onColumnClicked} />
          <th>Select</th>
        </tr>
      </thead>
      <tbody>
        {isLoading === true
          ? <tr><td colSpan="6">Loading...</td></tr>
          : Array.isArray(sortableGridState.sortedGridData) && sortableGridState.sortedGridData.length > 0
            ? sortableGridState.sortedGridData.map((person, i) =>
              <GridRow key={i} person={person} onSelected={onSelected} />)
            : <tr><td colSpan="6">No Potential Matches</td></tr>
        }
      </tbody>
    </table>
  )
};

export default ClubThirdPartyInvoicesDetailPersonPotentialMatchGridLarge;