import React, { Fragment } from 'react';

import ClubBulkRenewSelectedReadOnlyLargeGrid from './ClubBulkRenewSelectedReadOnlyLargeGrid';
import ClubBulkRenewSelectedReadOnlySmallGrid from './ClubBulkRenewSelectedReadOnlySmallGrid';

const ClubBulkRenewSelectedReadOnlyGrid = ({ gridData, isLoading }) => {
  return (
    <Fragment>
      {isLoading === false ?
        <div className="row">
          <div className="col-xs-12">
              <p>{gridData.length === 1 ? `${gridData.length} Selected Membership` : `${gridData.length} Selected Memberships`}</p>
          </div>
        </div>
        : <Fragment><div className="row usas-extra-top-margin usas-extra-bottom-margin"></div></Fragment>}
      <ClubBulkRenewSelectedReadOnlyLargeGrid
        gridData={gridData}
        isLoading={isLoading}
      />
      <ClubBulkRenewSelectedReadOnlySmallGrid
        gridData={gridData}
        isLoading={isLoading}
      />
    </Fragment>
  )
};

export default ClubBulkRenewSelectedReadOnlyGrid;