import React, { Fragment } from 'react';

import useLearnToSwimRegistrationFacilities from './UseLearnToSwimRegistrationFacilities';

import LearnToSwimRegistrationLeftNav from '../components/leftNav/LearnToSwimRegistrationLeftNav';
import RegistrationProviderFacilitiesGrid from '../components/grids/RegistrationProviderFacilitiesGrid';
import { FACILITIES_NAME } from '../components/leftNav/LearnToSwimRegistrationLeftNavConstants';

import PopUpModal from '../../../../common/components/dialogs/PopUpModal';
import SecondaryButton from '../../../../common/components/buttons/SecondaryButton';
import PrimaryButton from '../../../../common/components/buttons/PrimaryButton';
import ActionIntraPageButton from '../../../../common/components/buttons/ActionIntraPageButton';

import Constants from '../../../../common/utils/Constants';

import global from '../../../../common/components/GlobalStyle.module.css';

const LearnToSwimRegistrationFacilities = () => {
  const {
    isLoading,
    state,
    ltsProviderRegistrationState,
    onContinueClicked,
    onBackClicked,
    onAddFacilityClicked,
    onEditFacilityClicked,
    onDeleteFacilityClicked
  } = useLearnToSwimRegistrationFacilities();

  return (
    <Fragment>
      <LearnToSwimRegistrationLeftNav viewName={FACILITIES_NAME} viewTitle={'Facilities'}>
        <div className="row">
          <div className="col-xs-12 usas-extra-top-margin usas-extra-bottom-margin">
            <ActionIntraPageButton type="button" onClick={onAddFacilityClicked}>Add Facility</ActionIntraPageButton>
          </div>
        </div>
        <RegistrationProviderFacilitiesGrid
          data={ltsProviderRegistrationState.objData?.facilities || []}
          isLoading={isLoading}
          onEditClicked={onEditFacilityClicked}
          onDeleteClicked={onDeleteFacilityClicked} />
        {state.displayError === true &&
          <div className="row">
            <div className="col-xs-12">
              <p className={global.ErrorMessage}>At least one facility is required</p>
            </div>
          </div>
        }
        <div className="row pull-right">
          <div className="col-xs-12 usas-extra-top-margin usas-extra-bottom-margin">
            <SecondaryButton type="button" onClick={onBackClicked}>Back</SecondaryButton>&nbsp;
            <PrimaryButton type="button" onClick={onContinueClicked}>Save & Continue</PrimaryButton>
          </div>
        </div>
        <PopUpModal
          widthPct={50}
          maxWidth={250}
          title={Constants.LOADING_MSG}
          displayPopUp={isLoading} />
      </LearnToSwimRegistrationLeftNav>
    </Fragment>
  );
};

export default LearnToSwimRegistrationFacilities;