import { PersonHttpHelper, NO_DATA_MESSAGE } from '../../../common/utils/HttpHelper';

const GetPersonPotentialMatchesData = (firstName, lastName, birthDate, state, setState) => {
  if (state.isArrayLoading !== true && !state.message) {
    const newState = {
      ...state,
      isArrayLoading: true,
      isArrayLoaded: false,
      message: 'Loading...'
    };
    setState(newState);
    firstName, lastName, birthDate
    const firstNameForUrl = firstName ? encodeURIComponent(firstName) : '';
    const lastNameForUrl = lastName ? encodeURIComponent(lastName) : '';
    const birthDateForUrl = birthDate ? birthDate : '';
    const url = `/person/GetPotentialMatchedForPerson?firstName=${firstNameForUrl}&lastName=${lastNameForUrl}&birthDate=${birthDateForUrl}`;
    PersonHttpHelper(url, 'GET')
      .then((arrayData) => {
        if (!arrayData) {
          throw new Error(NO_DATA_MESSAGE);
        } else {
          setState({
            ...newState,
            isArrayLoading: false,
            isArrayLoaded: true,
            arrayData,
            message: ''
          })
        }
      })
      .catch((e) => {
        const message = e && e.message ? e.message : NO_DATA_MESSAGE;
        setState({
          ...newState,
          isArrayLoading: false,
          isArrayLoaded: false,
          message: message
        });
      });
  }
};

export default GetPersonPotentialMatchesData;