import { useState, useEffect } from 'react';
import { useNavigate } from '../../../../common/wrappers/ReactRouterDom';

import NavLinks from '../NavLinks';

import validate from '../../../../common/components/searches/searchFacility/addFacility/FacilityValidation';

import useCountryCombobox from '../../../../common/components/comboboxes/countryCombobox/UseCountryCombobox';

import useFacilityData from '../../../../common/state/facility/UseFacilityData';
import useClubAffiliationTypeData from '../../../../common/state/clubAffiliationType/UseClubAffiliationTypeData';

import useForm from '../../../../common/utils/UseForm';
import Constants from '../../../../common/utils/Constants';

const US_COUNTRY_CODE = 'US';

const INITIAL_VALIDATE_STATE = {
  isValidating: false,
  validatedAddress: null
};

const useAddFacility = () => {
  const navigate = useNavigate();
  const { facilityState, getPotentialFacilityDupes, postFacility, clearObjData } = useFacilityData();
  const { clubAffiliationTypeState } = useClubAffiliationTypeData();
  const { getCountryNameById } = useCountryCombobox();
  const { formState, errorState, onValueTextPairChanged, handleSubmit, updateFormState, setIsDirty, setFormData
  } = useForm(getInitialFormState, submitFormCallback, validate);
  const [validateState, setValidateState] = useState(INITIAL_VALIDATE_STATE);
  const [state, setState] = useState({ submitClicked: false, tryRedirect: false });
  const [dupsState, setDupsState] = useState(getInitialDupsState);

  const handleSubmitProxy = async (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    setValidateState({
      isValidating: true
    });
    await handleSubmit();
    setValidateState({
      isValidating: false,
      validatedAddress: formState.validatedAddress
    });
    updateFormState('showedAddressSuggestion', true);
  };

  const onAddDupFacilityClicked = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    setDupsState(getInitialDupsState());
    postFacility(createPostFacilityObject(formState));

    setState({ ...state, tryRedirect: true });
  };

  const onCancelDupFacilityClicked = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    setState({
      ...state,
      submitClicked: false
    });
    setDupsState(getInitialDupsState());
    setIsDirty(true);
  };

  const onCancelClicked = () => {
    if (facilityState.isSaving === false && dupsState.isArrayLoading === false) {
      navigate(NavLinks.PRE_COMP_REGISTRATION_LOCATION_FACILITY_SEARCH);
    }
  };

  useEffect(() => {
    clearObjData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (formState.isInternational === false) {
      setFormData({
        ...formState,
        country: US_COUNTRY_CODE,
        countryName: getCountryNameById(US_COUNTRY_CODE)
      }, true);
    } else {
      setFormData({
        ...formState,
        country: '',
        countryName: ''
      }, true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formState.isInternational]);

  useEffect(() => {
    if (dupsState.isArrayLoading === false && dupsState.isArrayLoaded === true
      && dupsState.arrayData.length === 0 && state.submitClicked === true) {
      postFacility(createPostFacilityObject(formState));

      setState({ ...state, tryRedirect: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dupsState]);

  useEffect(() => {
    if (state.submitClicked === true && state.tryRedirect === true
      && facilityState.isSaved === true && facilityState.isSaving === false) {
      navigate(NavLinks.PRE_COMP_REGISTRATION_LOCATION_FACILITY_DETAIL, { state: { facilityId: facilityState.objData.facilityId } });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state, facilityState]);

  function submitFormCallback(formState) {
    getPotentialFacilityDupes(formState.address1?.trim(), formState.city?.trim(), formState.stateCode, formState.postalCode?.trim(),
      dupsState, setDupsState);

    setState({
      ...state,
      submitClicked: true
    });
  }

  function createPostFacilityObject(formState) {
    return {
      facilityName: formState.facilityName.trim(),
      address1: formState.address1.trim(),
      address2: formState.address2.trim(),
      city: formState.city.trim(),
      stateCode: formState.stateCode,
      postalCode: formState.postalCode.trim(),
      contactName: formState.contactName.trim(),
      contactEmailAddress: formState.contactEmailAddress.trim(),
      contactPhoneNumber: formState.contactPhoneNumber.trim() !== '' && !Constants.PHONE_AREA_CODES.find(x => x === formState.contactPhoneNumber.trim()) ? formState.contactPhoneNumber.trim() : '',
      clubAffiliationId: formState.clubAffiliationId,
      altitude: formState.altitude.toString().replace(',', ''),
      isInternational: formState.isInternational,
      country: formState.country.trim()
    };
  }

  return {
    formState,
    errorState,
    dupsState,
    clubAffiliationTypeState,
    facilityState,
    onFormValueChanged: updateFormState,
    onCancelClicked,
    onValueTextPairChanged,
    onAddDupFacilityClicked,
    onCancelDupFacilityClicked,
    handleSubmitProxy,
    setFormData,
    validateState
  };
};

export default useAddFacility;

function getInitialFormState() {
  return {
    facilityName: '',
    address1: '',
    address2: '',
    city: '',
    stateCode: '',
    country: '',
    countryName: '',
    stateName: '',
    postalCode: '',
    contactName: '',
    contactEmailAddress: '',
    contactPhoneNumber: '',
    clubAffiliationId: Constants.DEFAULT_POSITIVE_ID,
    altitude: Constants.DEFAULT_ALTITUDE,
    isInternational: false,
    validatedAddress: null,
    showedAddressSuggestion: false
  };
}

function getInitialDupsState() {
  return {
    arrayData: [],
    isArrayLoaded: false,
    isArrayLoading: false
  };
}