import { useContext, useState, useEffect } from 'react';

import { FacilityStateContext } from './FacilityContextProvider';
import { FacilityFiltersStateContext } from './FacilityFiltersContextProvider';

import FacilityData from './FacilityData';

import { BASIC_INITIAL_STATE } from '../../utils/HttpHelper';

const CLUB_AFFILIATION_TYPES = {
  nonApplicable: 1
}

const useFacilityData = () => {
  const [facilityState, setFacilityState] = useContext(FacilityStateContext);
  const [facilityFiltersState, setFacilityFiltersState] = useContext(FacilityFiltersStateContext);
  const [orgUnitState, setOrgUnitState] = useState(BASIC_INITIAL_STATE);

  const searchFacilities = (filterObject, sortBy) => {
    setFacilityFiltersState({ ...facilityFiltersState, filterObject, sortBy });
    FacilityData.searchFacilities(JSON.parse(JSON.stringify(filterObject)), sortBy, facilityState, setFacilityState);
  }

  const getFacility = (facilityId) => {
    FacilityData.getFacility(facilityId, facilityState, setFacilityState)
  };

  const getPotentialFacilityDupes = (address1, city, stateCode, postalCode, state, setState) => {
    if (address1 && city && stateCode && postalCode) {
      FacilityData.getPotentialFacilityDupes(address1, city, stateCode, postalCode, state, setState)
    }
  }

  const putFacility = (facilityId, facilityObj) => {
    FacilityData.putFacility(facilityId, facilityObj, facilityState, setFacilityState)
  }

  const postFacility = (facilityObj) => {
    FacilityData.postFacility(facilityObj, facilityState, setFacilityState)
  }

  const postFacilityClubLink = (facilityObj) => {
    FacilityData.postFacilityClubLink(facilityObj, facilityState, setFacilityState)
  }

  const deleteFacility = (facilityId) => {
    FacilityData.deleteFacility(facilityId, facilityState, setFacilityState)
  }

  const deleteFacilityPool = (facilityPoolId) => {
    FacilityData.deleteFacilityPool(facilityPoolId, facilityState, setFacilityState)
  }

  const resetFacilityState = () => {
    setFacilityState(FacilityData.INITIAL_STATE);
  }

  const clearObjData = () => {
    setFacilityState({
      ...facilityState,
      isObjLoading: false,
      isObjLoaded: false,
      isSaving: false,
      isSaved: false,
      objData: {},
      message: ''
    });
  }

  const clearFacilityArrayData = () => {
    setFacilityState({
      ...facilityState,
      isArrayLoading: false,
      isArrayLoaded: false,
      arrayData: [],
      message: ''
    });
  }

  const clearFilterState = () => {
    setFacilityFiltersState({
      filterObject: {},
      sortBy: ''
    });
  }

  const confirmSave = () => {
    setFacilityState({
      ...facilityState,
      isSaved: false
    });
  };

  useEffect(() => {
    if (facilityState.isObjLoading !== true
      && facilityState.isObjLoaded === true
      && facilityState.objData.facilityName
      && facilityState.objData.clubAffiliationId === null) {
      setFacilityState({
        ...facilityState,
        objData: {
          ...facilityState.objData,
          clubAffiliationId: CLUB_AFFILIATION_TYPES.nonApplicable
        }
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [facilityState.objData])

  return {
    facilityState,
    facilityFiltersState,
    orgUnitState,
    setFacilityState,
    setOrgUnitState,
    searchFacilities,
    getFacility,
    getPotentialFacilityDupes,
    putFacility,
    postFacility,
    postFacilityClubLink,
    deleteFacility,
    deleteFacilityPool,
    resetFacilityState,
    clearObjData,
    clearFacilityArrayData,
    clearFilterState,
    confirmSave
  };
};

export default useFacilityData;