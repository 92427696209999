import useCourseMultiSelect from './UseCourseMultiSelect';

import MultiSelect from '../MultiSelect';

import global from '../../GlobalStyle.module.css';

const CourseMultiSelect = ({ label, name, value, error, message, onChange, isVisible=true }) => {
  const { courseState, options } = useCourseMultiSelect();

  return courseState.message
    ? <span className={global.LoadingMsg}>{courseState.message}</span>
    : 
    (
      <>
      {isVisible === true &&
      <MultiSelect
        label={label}
        name={name}
        value={value}
        options={options}
        error={error}
        message={message}
        isLoading={courseState.isLoading}
        onChange={onChange} />
      }
      </>
    );
};

export default CourseMultiSelect;