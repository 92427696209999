import { useEffect } from "react";

import useOfferingTypeData from "../../../common/state/offeringTypes/UseOfferingTypeData";

import Constants from "../../../common/utils/Constants";

const useOfferingTypeRegistrationRadioButtonList = (orgUnitId, category) => {
  const isDisabled = false;
  const { offeringTypeState, getOfferingTypesForClubRegistration } = useOfferingTypeData();

  useEffect(() => {
    if (offeringTypeState.isArrayLoading !== true && orgUnitId !== Constants.DEFAULT_ID) {
      getOfferingTypesForClubRegistration(orgUnitId, '', category);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orgUnitId, category]);

  return { offeringTypeState, isDisabled }
};

export default useOfferingTypeRegistrationRadioButtonList;