import { Fragment } from "react";

import useClubSwimAThonParticipant from './UseClubSwimAThonParticipant';

import SATParticipantsGrid from "../components/grids/SATParticipantsGrid";

import ActionIntraPageButton from "../../../../common/components/buttons/ActionIntraPageButton";
import SecondaryButton from "../../../../common/components/buttons/SecondaryButton";
import PopUpModal from "../../../../common/components/dialogs/PopUpModal";
import Headings from "../../../../common/components/headings/Headings";
import PrimaryButton from "../../../../common/components/buttons/PrimaryButton";

import Constants from "../../../../common/utils/Constants";

const ClubSwimAThonParticipant = () => {
  const {
    isLoading,
    isSaving,
    swimAThonResultState,
    modalState,
    onSaveClicked,
    onSubmitClicked,
    onBackClicked,
    onAddParticipant,
    onEditParticipant,
    onDeleteParticipant,
    onModalSubmitClicked,
    onModalCancelClicked
  } = useClubSwimAThonParticipant();

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>Swim-a-Thon Prize Winners</Headings.H3>
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12 usas-extra-top-margin">
          <ActionIntraPageButton onClick={onAddParticipant}>Add Prize Winner</ActionIntraPageButton>
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12 usas-extra-top-margin usas-extra-bottom-margin">
          <SATParticipantsGrid
            data={swimAThonResultState.objData?.satParticipant || []}
            isLoading={false}
            onEdit={onEditParticipant}
            onDelete={onDeleteParticipant} />
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12 usas-extra-top-margin usas-extra-bottom-margin">
          <PrimaryButton type="button" onClick={onSaveClicked}>Save</PrimaryButton>&nbsp;
          <PrimaryButton type="button" onClick={onSubmitClicked}>Submit</PrimaryButton>&nbsp;
          <SecondaryButton onClick={onBackClicked}>Back</SecondaryButton>
        </div>
      </div>
      <PopUpModal
        title={modalState.modalTitle}
        displayPopUp={modalState.displayPopup}
        widthPct={90}
        maxWidth={720}
        onModalCanceled={onModalCancelClicked}>
        <div className="row">
          <div className="col-xs-12">
            <p><b>Contract Date:</b> {modalState.contractDate}</p>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12" style={{ paddingLeft: 50 }}>
            <ul>
              <li>Please make sure that your Swim-a-Thon results information is accurate.</li>
              <li>Once you submit your Swim-a-Thon results you will not be able to modify your contract or results.</li>
              <li>If you have any questions please contact Swim-a-Thon at <a href="mailto: swimathon@usaswimming.org ">swimathon@usaswimming.org </a></li>
            </ul>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12 usas-extra-top-margin">
            <PrimaryButton type="button" onClick={onModalSubmitClicked}>Yes, Submit Results</PrimaryButton>&nbsp;
            <SecondaryButton type="button" onClick={onModalCancelClicked}>Cancel</SecondaryButton>
          </div>
        </div>
      </PopUpModal>
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={isLoading} />
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.SAVING_MSG}
        displayPopUp={isSaving} />
    </Fragment>
  );
};

export default ClubSwimAThonParticipant;
