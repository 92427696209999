import { useContext, useState } from 'react';

import LearnToSwimStaffData from './LearnToSwimStaffData';
import { LearnToSwimStaffStateContext } from './LearnToSwimStaffContextProvider';

import { BASIC_INITIAL_STATE } from '../../../common/utils/HttpHelper';

const INITIAL_LTS_STAFF_MEMBER_STATE = {
  ...BASIC_INITIAL_STATE
};

const useLearnToSwimStaffData = () => {
  const [ltsStaffState, setLTSStaffState] = useContext(LearnToSwimStaffStateContext);
  const [ltsStaffMemberState, setLTSStaffMemberState] = useState(INITIAL_LTS_STAFF_MEMBER_STATE);

  const postLTSStaffMember = (orgUnitId, roleGroupId, staffObj) => {
    return LearnToSwimStaffData.postLTSStaffMemberData(orgUnitId, roleGroupId, staffObj, ltsStaffMemberState, setLTSStaffMemberState);
  };

  const getLTSStaff = (orgUnitId, roleGroupId, isCurrentOnly = true) => {
    return LearnToSwimStaffData.getLTSStaffData(orgUnitId, roleGroupId, isCurrentOnly, ltsStaffState, setLTSStaffState);
  };

  const putLTSStaffMember = (orgUnitId, personId, roleGroupId, staffObj) => {
    return LearnToSwimStaffData.putLTSStaffMemberData(orgUnitId, personId, roleGroupId, staffObj, ltsStaffMemberState, setLTSStaffMemberState);
  };

  const deleteLTSStaffMemberRole = (personOrgRoleDurationId) => {
    return LearnToSwimStaffData.deleteLTSStaffMemberRoleData(personOrgRoleDurationId, ltsStaffMemberState, setLTSStaffMemberState);
  };

  return {
    ltsStaffState,
    ltsStaffMemberState,
    postLTSStaffMember,
    getLTSStaff,
    putLTSStaffMember,
    deleteLTSStaffMemberRole
  };
};

export default useLearnToSwimStaffData;