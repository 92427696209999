import { BASIC_INITIAL_STATE } from '../../../common/utils/HttpHelper';
import { API_NAMES, initApi } from "../../../common/utils/HttpApiHelper";

const getLTSVoucherCityData = (learnToSwimVoucherCityVoucherId, state, setState) => {
  if (learnToSwimVoucherCityVoucherId) {
    const api = initApi(API_NAMES.COMMON, state, setState);
    const url = `/LearnToSwimParticipant/info/${learnToSwimVoucherCityVoucherId}`;

    return api?.executeArray ? api.executeArray(url, 'GET') : null;
  }
};

const LearnToSwimVoucherData = { 
  INITIAL_STATE: BASIC_INITIAL_STATE, 
  getLTSVoucherCityData };

export default LearnToSwimVoucherData;
