/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';

import Checkbox from '../../../../../common/components/checkboxes/Checkbox';

import { formatDate } from '../../../../../common/utils/DateFunctions';

import global from '../../../../../common/components/GlobalStyle.module.css';

const GridRow = ({ person, onSelected }) => (
  <tr>
    <td>{person.firstName}</td>
    <td>{person.lastName}</td>
    <td>{formatDate(person.birthDate)}</td>
    <td>{person.orgDuration?.orgUnitName || ''}</td>
    <td>{person.orgDuration?.effectiveDate ? formatDate(person.orgDuration.effectiveDate) : ''}</td>
    <td>{person.orgDuration?.expirationDate ? formatDate(person.orgDuration.expirationDate) : ''}</td>
    <td>
      <Checkbox
        label=""
        name="isSelected"
        onChange={() => onSelected(person.personId)}
        checked={person.isSelected} />
    </td>
  </tr>
);

const ProviderStaffDupGridLarge = ({ actionLabel, persons, onSelected }) => (
  <table className={[global.UsasTable, 'visible-md visible-lg'].join(' ')}>
    <thead>
      <tr>
        <th>First Name</th>
        <th>Last Name</th>
        <th>Birth Date</th>
        <th>Organization</th>
        <th>Effective Date</th>
        <th>Expiration Date</th>
        <th>{actionLabel}</th>
      </tr>
    </thead>
    <tbody>
      {persons.map((person, i) => <GridRow key={i} person={person} onSelected={onSelected} />)}
    </tbody>
  </table>
);

export default ProviderStaffDupGridLarge;