import { useContext } from 'react';

import { PreCompLocationStateContext } from './PreCompLocationContextProvider';

import PreCompLocationData from './PreCompLocationData';

const usePreCompLocationData = () => {
  const [preCompLocationState, setPreCompLocationState] = useContext(PreCompLocationStateContext);

  const getPreCompLocation = (locationId) => {
    PreCompLocationData.getPreCompLocationData(locationId, preCompLocationState, setPreCompLocationState);
  };

  const getPreCompLocationByOrgUnitId = (orgUnitId) => {
    PreCompLocationData.getPreCompLocationByOrgUnitIdData(orgUnitId, preCompLocationState, setPreCompLocationState);
  };

  const putPreCompLocation = (locationId, locationObj) => {
    PreCompLocationData.putPreCompLocationData(locationId, locationObj, preCompLocationState, setPreCompLocationState);
  };

  const clearObjData = () => {
    setPreCompLocationState({
      ...preCompLocationState,
      isObjLoading: false,
      isObjLoaded: false,
      isSaving: false,
      isSaved: false,
      objData: {}
    });
  };

  const clearArrayData = () => {
    setPreCompLocationState({
      ...preCompLocationState,
      isArrayLoading: false,
      isArrayLoaded: false,
      isSaving: false,
      isSaved: false,
      arrayData: []
    });
  };

  const confirmSave = () => {
    setPreCompLocationState({
      ...preCompLocationState,
      isSaved: false
    });
  };

  return {
    preCompLocationState,
    confirmSave,
    clearObjData,
    clearArrayData,
    getPreCompLocation,
    getPreCompLocationByOrgUnitId,
    putPreCompLocation
  };
};

export default usePreCompLocationData;