const PreCompLocationProgramOfferingFormValidation = (formState) => {
  let errors = {};

  const enrollmentValue = parseInt(formState.enrollmentCount);
  const scholarshipValue = parseInt(formState.scholarshipCount);

  // Only validate on offering add
  if (!formState.preCompLocationOfferingId) {
    if (formState.programOffering.length <= 0) {
      errors.programOffering = 'Must select a Program Offering';
    }
  }
  
  if (isNaN(enrollmentValue)) {
    errors.enrollmentCount = 'Must be a valid number';
  } else if (enrollmentValue < 1) {
    errors.enrollmentCount = 'Must be a positive number';
  }

  if (isNaN(scholarshipValue)) {
    errors.scholarshipCount = 'Must be a valid number';
  } else if (scholarshipValue < 0) {
    errors.scholarshipCount = 'Cannot be a negative number';
  }

  return errors;
};

export default PreCompLocationProgramOfferingFormValidation;