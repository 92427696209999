import NavLinks from '../utils/NavLinks';

import useLearnToSwimData from '../../../state/learnToSwim/UseLearnToSwimData';

import useEnvironmentVariableData from '../../../../common/state/environmentVariable/UseEnvironmentVariableData';

const ROUTE = NavLinks.LEARN_TO_SWIM_PROVIDER_INFO;
const TAXONOMIES = ['LearnToSwimDetail'];
const SCOPE = 'LearnToSwim';

const useLearnToSwimOrgUnitSelection = () => {
  const { LTS_STAFF_ROLE_GROUP_ID } = useEnvironmentVariableData();
  const { getLTSProviderByOrgUnitId } = useLearnToSwimData();

  return {
    ROUTE,
    TAXONOMIES,
    SCOPE,
    LTS_STAFF_ROLE_GROUP_ID,
    getLTSProviderByOrgUnitId
  };
};

export default useLearnToSwimOrgUnitSelection;