import { useState, useEffect } from 'react';

import useClubRegistrationLinkOrgUnitIdData from '../../../state/clubGenerateRegistrationLink/UseClubRegistrationLinkOrgUnitIdData';

import useBaseUsasClubData from '../../../../common/state/clubBase/UseBaseUsasClubData';

import Constants from '../../../../common/utils/Constants';

const REQUIRED_PHRASE = 'delete';

const INITIAL_REQUIRED_PHRASE_STATE = {
  phraseValue: '',
  isMatch: false
};

const useClubGenerateRegistrationLink = () => {
  const { baseUsasClubState } = useBaseUsasClubData();
  const { getClubRegistrationLinkByOrgUnitId, clubRegistrationLinkState, putClubRegistrationLinkOrgUnitId } = useClubRegistrationLinkOrgUnitIdData();
  const [requiredPhraseState, setRequiredPhraseState] = useState(INITIAL_REQUIRED_PHRASE_STATE);
  const [state, setState] = useState(getInitialState);

  const onModalCanceled = (e) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }

    setState({
      ...state,
      modalState: {
        ...state.modalState,
        displayPopUp: false,
      }
    });
    setRequiredPhraseState(INITIAL_REQUIRED_PHRASE_STATE);
  };

  const onCopyToClipboardModalCanceled = (e) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }

    setState({
      ...state,
      copyToClipboardState: {
        ...state.copyToClipboardState,
        displayPopUp: false,
      }
    });
  };

  const onOpenNewLinkModalClicked = (e, link) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }

    setState({
      ...state,
      modalState: {
        ...state.modalState,
        displayPopUp: true,
        routeCodeName: link.routeCodeName,
        // expirationDate: link.expirationDate,
        // effectiveDate: link.effectiveDate,
        registrationLink: link.routeCodeValue,
        routeCodeId: link.routeCodeId
      }
    });
  };

  const onClickCopyToClipboard = (e, link) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }

    navigator.clipboard.writeText(link);

    setState({
      ...state,
      copyToClipboardState: {
        ...state.copyToClipboardState,
        displayPopUp: true,
      }
    })
  };

  const onGenerateNewLink = (e, id) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }

    putClubRegistrationLinkOrgUnitId(id);
    setRequiredPhraseState(INITIAL_REQUIRED_PHRASE_STATE);
  };

  const onPhraseUpdated = (value) => {
    setRequiredPhraseState({
      ...requiredPhraseState,
      phraseValue: value,
      isMatch: value.toLowerCase() === REQUIRED_PHRASE ? true : false
    });
  };

  useEffect(() => {
    if (clubRegistrationLinkState.isSaved === true) {

      setState({
        ...state,
        tryGet: true,
        modalState: {
          ...state.modalState,
          displayPopUp: false
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clubRegistrationLinkState.isSaved]);

  useEffect(() => {
    if (baseUsasClubState?.objData?.orgUnitId !== undefined || state.tryGet === true) {
      const orgUnitId = baseUsasClubState.objData.orgUnitId;

      getClubRegistrationLinkByOrgUnitId(orgUnitId);
      setState({ ...state, tryGet: false });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [baseUsasClubState, state.tryGet]);

  function getInitialState() {
    return {
      modalState: {
        modalTitle: 'Generate New Registration Link',
        displayPopUp: false,
        // expirationDate: Constants.BLANK_DATE_STRING,
        // effectiveDate: Constants.BLANK_DATE_STRING,
        routeCodeName: '',
        registrationLink: '',
        routeCodeId: Constants.DEFAULT_ID
      },
      copyToClipboardState: {
        modalTitle: 'Success! Link Copied',
        displayPopUp: false
      },
      tryGet: false
    };
  }

  return {
    clubRegistrationLinkState,
    requiredPhraseState,
    modalState: state.modalState,
    copyToClipboardState: state.copyToClipboardState,
    onModalCanceled,
    onOpenNewLinkModalClicked,
    onGenerateNewLink,
    onClickCopyToClipboard,
    onCopyToClipboardModalCanceled,
    onPhraseUpdated,
    REQUIRED_PHRASE
  };
};

export default useClubGenerateRegistrationLink;