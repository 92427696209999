export const localValidate = (formState) => {
  let errors = {};

  if (formState.textEntry.trim() === '') {
    errors.textEntry = 'Text is required';
  } else if (formState.textEntry.trim().length > 8000) {
    errors.textEntry = 'Text cannot exceed 8000 characters';
  }

  return errors;
};

const TextEntryViewValidation = async (formState) => {
  let errors = localValidate(formState);

  return errors ? errors : {};
};

export default TextEntryViewValidation;