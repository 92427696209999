import { useEffect } from 'react';

import validate from '../components/forms/PreCompLocationInstructorTrainingFormValidation';

import { createLocObjFromLocInstructorTrainingForm } from '../utils/PreCompUtils';

import usePreCompLocationData from '../../../state/preCompLocation/UsePreCompLocationData';
import usePreCompInstructionHoursData from '../../../state/preCompInstructionHours/UsePreCompInstructionHoursData';
import usePreCompCurriculumData from '../../../state/preCompCurriculum/UsePreCompCurriculumData';

import useForm from '../../../../common/utils/UseForm';
import Constants from '../../../../common/utils/Constants';
import ToBoolIfBool from '../../../../common/utils/ToBoolIfBool';

const usePreCompLocationInstructorTraining = () => {
  const { preCompLocationState, putPreCompLocation, confirmSave } = usePreCompLocationData();
  const { preCompInstructionHoursState } = usePreCompInstructionHoursData();
  const { preCompCurriculumState } = usePreCompCurriculumData();
  const { formState, errorState, handleSubmit, resetForm, updateFormState, onValueTextPairChanged, setFormData
  } = useForm(getInitialFormState, submitFormCallback, validate);

  useEffect(() => {
    if (preCompLocationState.isSaving === false && preCompLocationState.isSaved === true) {
      confirmSave();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [preCompLocationState]);

  useEffect(() => {
    if (preCompLocationState.isObjLoaded === true && preCompInstructionHoursState.isArrayLoaded === true
      && preCompCurriculumState.isArrayLoaded === true) {

      setFormData({
        ...formState,
        preEmploymentScreening: ToBoolIfBool(preCompLocationState.objData.preEmploymentScreening) ?? '',
        backgroundCheckRequired: ToBoolIfBool(preCompLocationState.objData.backgroundCheckRequired) ?? '',
        protectionPolicy: ToBoolIfBool(preCompLocationState.objData.protectionPolicy) ?? '',
        instructorTrainingAgencyId: preCompLocationState.objData.instructorTrainingAgencyId || Constants.DEFAULT_ID,
        instructorTrainingAgencyName: preCompLocationState.objData.instructorTrainingAgency?.trainingAgencyName || '',
        inServiceInstructionHoursId: preCompLocationState.objData.inServiceInstructionHoursId || Constants.DEFAULT_ID,
        inServiceInstructionHoursName: preCompLocationState.objData.inServiceInstructionHours?.instructionHoursDescription || '',
        inClassroomInstructionHoursId: preCompLocationState.objData.inClassroomInstructionHoursId || Constants.DEFAULT_ID,
        inClassroomInstructionHoursName: preCompLocationState.objData.inClassroomInstructionHours?.instructionHoursDescription || '',
        inWaterInstructionHoursId: preCompLocationState.objData.inWaterInstructionHoursId || Constants.DEFAULT_ID,
        inWaterInstructionHoursName: preCompLocationState.objData.inWaterInstructionHours?.instructionHoursDescription || '',
        inShadowInstructionHoursId: preCompLocationState.objData.inShadowInstructionHoursId || Constants.DEFAULT_ID,
        inShadowInstructionHoursName: preCompLocationState.objData.inShadowInstructionHours?.instructionHoursDescription || '',
        inPracticeInstructionHoursId: preCompLocationState.objData.inPracticeInstructionHoursId || Constants.DEFAULT_ID,
        inPracticeInstructionHoursName: preCompLocationState.objData.inPracticeInstructionHours?.instructionHoursDescription || ''
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [preCompLocationState.isObjLoaded, preCompCurriculumState.isArrayLoaded, preCompInstructionHoursState.isArrayLoaded]);

  function submitFormCallback(formState) {
    if (preCompLocationState.isSaving === false) {
      putPreCompLocation(preCompLocationState.objData.preCompetitiveLocationId, createLocObjFromLocInstructorTrainingForm(JSON.parse(JSON.stringify(preCompLocationState.objData)), formState));
    }
  };

  function getInitialFormState() {
    return {
      preEmploymentScreening: '',
      backgroundCheckRequired: '',
      protectionPolicy: '',
      instructorTrainingAgencyId: Constants.DEFAULT_ID,
      instructorTrainingAgencyName: '',
      inServiceInstructionHoursId: Constants.DEFAULT_ID,
      inServiceInstructionHoursName: '',
      inClassroomInstructionHoursId: Constants.DEFAULT_ID,
      inClassroomInstructionHoursName: '',
      inWaterInstructionHoursId: Constants.DEFAULT_ID,
      inWaterInstructionHoursName: '',
      inShadowInstructionHoursId: Constants.DEFAULT_ID,
      inShadowInstructionHoursName: '',
      inPracticeInstructionHoursId: Constants.DEFAULT_ID,
      inPracticeInstructionHoursName: ''
    };
  };

  return {
    isSaving: preCompLocationState.isSaving,
    isLoading: preCompLocationState.isObjLoading || preCompCurriculumState.isArrayLoading || preCompInstructionHoursState.isArrayLoading,
    preCompLocationState,
    formState,
    errorState,
    onFormValueChanged: updateFormState,
    onValueTextPairChanged,
    handleSubmit,
    resetForm
  };
};

export default usePreCompLocationInstructorTraining;