import React, { Fragment } from 'react';

import PreCompConstants from '../../utils/PreCompConstants';

import DatePicker from '../../../../../common/components/datepickers/DatePicker';
import Input from '../../../../../common/components/inputs/Input';
import ReadOnly from '../../../../../common/components/readOnly/ReadOnly';
import SecondaryButton from '../../../../../common/components/buttons/SecondaryButton';
import CurrencyInput from '../../../../../common/components/inputs/CurrencyInput';
import PrimaryButton from '../../../../../common/components/buttons/PrimaryButton';
import Headings from '../../../../../common/components/headings/Headings';
import YesNoSwitch from '../../../../../common/components/yesNoSwitch/YesNoSwitch';

import { CURRENCY_FORMATTER } from '../../../../../common/utils/Constants';

import global from '../../../../../common/components/GlobalStyle.module.css';

const PreCompOrganizationInsuranceForm = ({ formState, errorState, onFormValueChanged,
  onUploadVerification, onRemoveVerification, onDownloadUploadedFile }) => (
  <Fragment>
    {onUploadVerification === undefined ?
      <Fragment />
      :
      <Fragment>
        <div className="row usas-extra-top-margin">
          <div className="col-xs-12 usas-extra-top-margin">
            <Headings.H6>Insurance Verification</Headings.H6>
          </div>
        </div>
        <div className="row usas-extra-top-margin usas-extra-bottom-margin">
          <div className="col-xs-12">
            {(formState.verificationFile.size > 0 || formState.insuranceValidationUrl !== '') ?
              <Fragment>
                <SecondaryButton onClick={(e) => onRemoveVerification(e)}>Remove Uploaded File</SecondaryButton>&nbsp;
                {formState.insuranceValidationName !== '' && <PrimaryButton type="button" onClick={(event) => onDownloadUploadedFile(event)}>Download Uploaded File</PrimaryButton>}
                <p><b>Uploaded File Name:</b> {formState.verificationFile.name ? formState.verificationFile.name : formState.insuranceValidationName}</p>
              </Fragment> :
              <Fragment>
                <label htmlFor="insurance-verification-file-upload" className={global.FileUpload}>
                  Upload File
                </label>
                <input hidden id="insurance-verification-file-upload" type="file" onChange={(e) => onUploadVerification(e)} />
                &nbsp;(*.pdf), (*.doc), or (*.docx)
                {errorState.insuranceValidationUrl && <p className={global.ErrorMessage}>{errorState.insuranceValidationUrl}</p>}
              </Fragment>}
          </div>
        </div>
      </Fragment>
    }
    <div className="row usas-extra-top-margin">
      <div className="col-xs-12 col-sm-6 col-md-4">
        {onFormValueChanged === undefined
          ? <ReadOnly
            label="Insurance Provider Name"
            name="insuranceProviderName"
            value={formState.insuranceProviderName} />
          : (
            <Input
              label="Insurance Provider Name*"
              name="insuranceProviderName"
              value={formState.insuranceProviderName}
              error={errorState.insuranceProviderName}
              message={errorState.insuranceProviderName}
              onChange={(value) => { onFormValueChanged("insuranceProviderName", value); }} />
          )
        }
      </div>
      <div className="col-xs-12 col-sm-6 col-md-4">
        {onFormValueChanged === undefined
          ? <ReadOnly
            label="Insurance Policy Number"
            name="policyNumber"
            value={formState.policyNumber} />
          : (
            <Input
              label="Insurance Policy Number*"
              name="policyNumber"
              value={formState.policyNumber}
              error={errorState.policyNumber}
              message={errorState.policyNumber}
              onChange={(value) => { onFormValueChanged("policyNumber", value); }} />
          )
        }
      </div>
      <div className="col-xs-12 col-sm-6 col-md-4">
        <ReadOnly
          label="Insurance Policy Type"
          name="policyTypeId"
          value={PreCompConstants.POLICY_TYPE_NAME_GENERAL_LIABILITY} />
      </div>
      <div className="col-xs-12 col-sm-6 col-md-4">
        {onFormValueChanged === undefined
          ? <ReadOnly
            label="Effective Date"
            name="effectiveDate"
            value={formState.effectiveDate} />
          : (
            <DatePicker
              label="Effective Date*"
              name="effectiveDate"
              value={formState.effectiveDate}
              error={errorState.effectiveDate}
              message={errorState.effectiveDate}
              onChange={(value) => { onFormValueChanged("effectiveDate", value); }}
              countOfYears={150}
              startYearOffset={-146} />
          )
        }
      </div>
      <div className="col-xs-12 col-sm-6 col-md-4">
        {onFormValueChanged === undefined
          ? <ReadOnly
            label="Expiration Date"
            name="expirationDate"
            value={formState.effectiveDate} />
          : (
            <DatePicker
              label="Expiration Date*"
              name="expirationDate"
              value={formState.expirationDate}
              error={errorState.expirationDate}
              message={errorState.expirationDate}
              onChange={(value) => { onFormValueChanged("expirationDate", value); }}
              countOfYears={150}
              startYearOffset={-145} />
          )
        }
      </div>
      <div className="col-xs-12 col-sm-6 col-md-4">
        {onFormValueChanged === undefined
          ? <ReadOnly
            label='Are Self Insured?'
            name="isSelfInsured"
            value={formState.isSelfInsured === true ? 'Yes' : 'No'} />
          : (
            <YesNoSwitch
              label="Are Self Insured?*"
              name="isSelfInsured"
              checked={formState.isSelfInsured}
              error={errorState.isSelfInsured}
              message={errorState.isSelfInsured}
              onChange={(value) => { onFormValueChanged("isSelfInsured", value); }} />
          )
        }
      </div>
      <div className="col-xs-12 col-sm-6 col-md-4">
        {onFormValueChanged === undefined
          ? <ReadOnly
            label="Each Occurrence Limit"
            name="occurrenceLimit"
            value={CURRENCY_FORMATTER.format(formState.occurrenceLimit)} />
          : (
            <CurrencyInput
              label="Each Occurrence Limit*"
              name="occurrenceLimit"
              value={formState.occurrenceLimit}
              error={errorState.occurrenceLimit}
              message={errorState.occurrenceLimit}
              onChange={(value) => { onFormValueChanged("occurrenceLimit", value); }} />
          )
        }
      </div>
      <div className="col-xs-12 col-sm-6 col-md-4">
        {onFormValueChanged === undefined
          ? <ReadOnly
            label="Aggregate Limit"
            name="aggregateLimit"
            value={CURRENCY_FORMATTER.format(formState.aggregateLimit)} />
          : (
            <CurrencyInput
              label="Aggregate Limit*"
              name="aggregateLimit"
              value={formState.aggregateLimit}
              error={errorState.aggregateLimit}
              message={errorState.aggregateLimit}
              onChange={(value) => { onFormValueChanged("aggregateLimit", value); }} />
          )
        }
      </div>
    </div>
  </Fragment>
);

export default PreCompOrganizationInsuranceForm;