import React, { Fragment } from 'react';

import useClubSwimAThonContractDetail from './UseClubSwimAThonContractDetail';

import SATContractForm from '../components/forms/SATContractForm';

import Headings from '../../../../common/components/headings/Headings';
import PrimaryButton from '../../../../common/components/buttons/PrimaryButton';
import SecondaryButton from '../../../../common/components/buttons/SecondaryButton';
import PopUpModal from '../../../../common/components/dialogs/PopUpModal';
import AddressValidation from '../../../../common/components/addressValidation/AddressValidation';

import Constants from '../../../../common/utils/Constants';

const ClubSwimAThonContractDetail = ({ canEditSubmittedContract = false, canEditFulfillmentDate = false, canEditSignatureDate = false }) => {
  const {
    isSubmitted,
    isEdit,
    isSaving,
    isLoading,
    formState,
    errorState,
    validateState,
    contractModalState,
    cancelContractModalState,
    onFormValueChanged,
    onCancelClicked,
    onValueTextPairChanged,
    onContractModalCanceled,
    onContinueSaveClicked,
    onContinueCancelContractClicked,
    onCancelContractModelCanceled,
    onCancelContract,
    handleSubmitProxy,
    setFormData,
    submmitedStatusId,
    paidStatusId
  } = useClubSwimAThonContractDetail();

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>{isEdit ? "Edit" : "Add"} Swim-a-Thon Contract</Headings.H3>
        </div>
      </div>
      {canEditSubmittedContract || !isSubmitted ? (
        <form onSubmit={handleSubmitProxy} noValidate>
          <SATContractForm
            formState={formState}
            errorState={errorState}
            onFormValueChanged={onFormValueChanged}
            onValueTextPairChanged={onValueTextPairChanged}
            canEditFulfillmentDate={canEditFulfillmentDate}
            canEditSignatureDate={canEditSignatureDate} />
          <AddressValidation formState={formState} setFormData={setFormData} />
          <div className="row usas-extra-top-margin ">
            <div className="col-xs-12 usas-extra-top-margin usas-extra-bottom-margin">
              <PrimaryButton type="submit">Submit</PrimaryButton>&nbsp;&nbsp;
              <SecondaryButton type="button" onClick={onCancelClicked}>Back</SecondaryButton>&nbsp;&nbsp;
              {formState.contractStatusId !== Constants.DEFAULT_ID && formState.resultStatusId !== submmitedStatusId && formState.resultStatusId !== paidStatusId &&
              <SecondaryButton type="button" onClick={onCancelContract}>Cancel Contract</SecondaryButton>
              }
            </div>
          </div>
        </form>
      ) : (
        <Fragment>
          <SATContractForm
            formState={formState} />
          <div className="row usas-extra-top-margin">
            <div className="col-xs-12 usas-extra-top-margin usas-extra-bottom-margin">
              <SecondaryButton type="button" onClick={onCancelClicked}>Back</SecondaryButton>&nbsp;&nbsp;
              {formState.resultStatusId !== submmitedStatusId && formState.resultStatusId !== paidStatusId &&
              <SecondaryButton type="button" onClick={onCancelContract}>Cancel Contract</SecondaryButton>
              }
            </div>
          </div>
        </Fragment>
      )}
      <PopUpModal
        title={cancelContractModalState.modalTitle}
        displayPopUp={cancelContractModalState.displayPopup}
        widthPct={90}
        maxWidth={720}
        onModalCanceled={onCancelContractModelCanceled}>
        <div className="row">
          <div className="col-xs-12">
            <p><b>Contract Date:</b> {cancelContractModalState.contractDate}</p>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12 usas-extra-top-margin">
            <PrimaryButton type="button" onClick={onContinueCancelContractClicked}>Yes, Cancel Contract</PrimaryButton>&nbsp;
            <SecondaryButton type="button" onClick={onCancelContractModelCanceled}>Cancel</SecondaryButton>
          </div>
        </div>
      </PopUpModal>
      <PopUpModal
        title={contractModalState.modalTitle}
        displayPopUp={contractModalState.displayPopup}
        widthPct={90}
        maxWidth={720}
        onModalCanceled={onContractModalCanceled}>
        <div className="row">
          <div className="col-xs-12">
            <p><b>Contract Date:</b> {contractModalState.contractDate}</p>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12" style={{ paddingLeft: 50 }}>
            <ul>
              <li>Please make sure all the information on your Swim-a-Thon contract is accurate.</li>
              <li>Once you submit your Swim-a-Thon results you will not be able to modify your contract.</li>
              <li>If you have any questions please contact Swim-a-Thon at <a href="mailto: swimathon@usaswimming.org ">swimathon@usaswimming.org</a></li>
            </ul>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12 usas-extra-top-margin">
            <PrimaryButton type="button" onClick={onContinueSaveClicked}>Yes, Submit Contract</PrimaryButton>&nbsp;
            <SecondaryButton type="button" onClick={onContractModalCanceled}>Cancel</SecondaryButton>
          </div>
        </div>
      </PopUpModal>
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title="Validating..."
        displayPopUp={validateState.isValidating} />
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={isLoading} />
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.SAVING_MSG}
        displayPopUp={isSaving} />
    </Fragment>
  );
};

export default ClubSwimAThonContractDetail;