import React, { Fragment } from 'react';

import useClubStaff from './UseClubStaff';

import ClubStaffWriteGrid from './ClubStaffWriteGrid';
import Headings from '../../../../common/components/headings/Headings';
import ActionIntraPageButton from '../../../../common/components/buttons/ActionIntraPageButton';
import PrimaryButton from '../../../../common/components/buttons/PrimaryButton';
import PopUpModal from '../../../../common/components/dialogs/PopUpModal';
import SecondaryButton from '../../../../common/components/buttons/SecondaryButton';
import Constants from '../../../../common/utils/Constants';

const ClubStaffWrite = () => {
  const {
    usasClubStaffState,
    deleteModalState,
    environmentVariableState,
    onDelete,
    onAdd,
    onEdit,
    onModalCancelClicked,
    onModalDeleteClicked,
    registrationRoles
  } = useClubStaff();

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>Club Staff</Headings.H3>
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12 usas-extra-top-margin">
          <ActionIntraPageButton onClick={onAdd}>Add Staff</ActionIntraPageButton>
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12 usas-extra-top-margin usas-extra-bottom-margin">
          <ClubStaffWriteGrid state={usasClubStaffState} onDelete={onDelete} onEdit={onEdit} registrationRoles={registrationRoles} />
        </div>
      </div>
      <PopUpModal
        widthPct={90}
        maxWidth={720}
        title={deleteModalState.isLastRole === true ? 'Remove Staff Member?' : 'Remove Staff Role?'}
        displayPopUp={deleteModalState.displayModal}
        onModalCanceled={onModalCancelClicked}>
        <div className="row">
          <div className="col-xs-12">
            {deleteModalState.isLastRole === true
              ? <p>{`Removing ${deleteModalState.personName}'s last role will remove them from this club's staff`}</p>
              : <p>{`Remove staff role from ${deleteModalState.personName}?`}</p>
            }
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12 usas-extra-top-margin">
            <PrimaryButton type="button" onClick={onModalDeleteClicked}>{deleteModalState.isLastRole === true ? 'Remove Staff Member' : 'Remove Staff Role'}</PrimaryButton>&nbsp;
            <SecondaryButton type="button" onClick={onModalCancelClicked}>Cancel</SecondaryButton>
          </div>
        </div>
      </PopUpModal>
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.SAVING_MSG}
        displayPopUp={usasClubStaffState.isSaving} />
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={usasClubStaffState.isArrayLoading || environmentVariableState.isLoading} />
    </Fragment>
  );
};

export default ClubStaffWrite;