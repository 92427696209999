import { useEffect, useState } from 'react';

import validate from './YesNoViewValidation';

import { createCategoryMeasureScoreObj } from '../../utils/RecognitionProgramUtils';

import useCategoryMeasureScoreData from '../../../state/categoryMeasureScore/UseCategoryMeasureScoreData';

import useForm from "../../../../common/utils/UseForm";

const useYesNoView = (programLevel, categoryMeasure, onRedirect) => {
  const { categoryMeasureScoreState, postCategoryMeasureScore, putCategoryMeasureScore
  } = useCategoryMeasureScoreData();
  const { handleSubmit, updateFormState, formState, errorState, setFormData, isDirty
  } = useForm(getInitialFormState(), submitFormCallback, validate);
  const [state, setState] = useState({ tryRedirect: false });

  const onSaveClicked = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    if (isDirty === false && (formState.yesNo === true || formState.yesNo === false)) {
      onRedirect(false);
    } else {
      handleSubmit();
    }
  };

  function submitFormCallback() {
    if (categoryMeasure.categoryMeasureScoreId > 0) {
      putCategoryMeasureScore(categoryMeasure.categoryMeasureScoreId,
        createCategoryMeasureScoreObj(categoryMeasure, programLevel, getSubmittedValue(formState.yesNo), getScoreValue(formState.yesNo)));
    } else {
      postCategoryMeasureScore(createCategoryMeasureScoreObj(categoryMeasure, programLevel, getSubmittedValue(formState.yesNo), getScoreValue(formState.yesNo)));
    }
    setState({ ...state, tryRedirect: true });
  };

  useEffect(() => {
    if (Object.keys(categoryMeasure).length > 0) {
      setFormData({
        ...formState,
        yesNo: categoryMeasure.submittedValue === "Yes" ?
          true : categoryMeasure.submittedValue === "No" ? false : ''
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categoryMeasure]);

  useEffect(() => {
    if (state.tryRedirect === true && categoryMeasureScoreState.isSaved === true) {
      onRedirect(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state, categoryMeasureScoreState]);

  function getSubmittedValue(yesNo) {
    if (yesNo === true) {
      return 'Yes';
    } else if (yesNo === false) {
      return 'No';
    } else {
      return null;
    }
  };

  function getScoreValue(yesNo) {
    if (yesNo === true) {
      return categoryMeasure?.maxValue;
    } else {
      return 0;
    }
  };

  function getInitialFormState() {
    return {
      yesNo: ''
    };
  };

  return {
    categoryMeasureScoreState,
    handleSubmit,
    formState,
    errorState,
    onFormValueChanged: updateFormState,
    onSaveClicked
  };
};

export default useYesNoView;