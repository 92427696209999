import { useState } from 'react';

import ClubTouchpointData from './ClubTouchpointData';

const useClubTouchpointData = () => {
  const [clubTouchpointState, setClubTouchpointState] = useState(ClubTouchpointData.INITIAL_STATE);

  const getClubTouchpoints = (usasClubId) => {
    ClubTouchpointData.getClubTouchpointData(usasClubId, clubTouchpointState, setClubTouchpointState);
  };

  const postClubTouchpoint = (usasClubId, touchpointObj) => {
    ClubTouchpointData.postClubTouchpointData(usasClubId, touchpointObj, clubTouchpointState, setClubTouchpointState);
  };

  const putClubTouchpoint = (usasClubTouchpointId, touchpointObj) => {
    ClubTouchpointData.putClubTouchpointData(usasClubTouchpointId, touchpointObj, clubTouchpointState, setClubTouchpointState);
  };

  const deleteClubTouchpoint = (usasClubTouchpointId) => {
    ClubTouchpointData.deleteClubTouchpointData(usasClubTouchpointId, clubTouchpointState, setClubTouchpointState);
  };

  return {
    clubTouchpointState,
    getClubTouchpoints,
    postClubTouchpoint,
    putClubTouchpoint,
    deleteClubTouchpoint
  };
};

export default useClubTouchpointData;