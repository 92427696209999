import usePreCompOfferingTypeData from '../../../../state/preCompOfferingType/UsePreCompOfferingTypeData';

const usePreCompOfferingTypeTreeView = () => {
  const { preCompOfferingTypeState } = usePreCompOfferingTypeData();

  return {
    preCompOfferingTypeState
  };
};

export default usePreCompOfferingTypeTreeView;