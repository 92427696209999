import useVendorData from '../../../state/vendor/UseVendorData';

const useVendorDropdown = (isTeamManagement) => {
  const { vendorState } = useVendorData(isTeamManagement);

  return {
    vendorState
  };
};

export default useVendorDropdown;