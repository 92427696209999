import { useState } from 'react';
import LearnToSwimVoucherData from './LearnToSwimVoucherData';

const useLearnToSwimVoucherData = () => {
  const [ ltsVoucherCityState, setLTSVoucherCityState ] = useState(LearnToSwimVoucherData.INITIAL_STATE);

  const getLTSVoucherCity = (learnToSwimVoucherCityVoucherId) => {
    return LearnToSwimVoucherData.getLTSVoucherCityData(learnToSwimVoucherCityVoucherId, ltsVoucherCityState, setLTSVoucherCityState);
  }

  return {
    ltsVoucherCityState,
    setLTSVoucherCityState,
    getLTSVoucherCity
  }
}

export default useLearnToSwimVoucherData;
