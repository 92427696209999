/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Fragment } from 'react';

import SATSearchGridLarge from './SATSearchGridLarge';
import SATSearchGridSmall from './SATSearchGridSmall';

const SATSearchGrid = ({ data, isLoading, onEdit }) => (
  <Fragment>
    <SATSearchGridLarge data={data} isLoading={isLoading} onEdit={onEdit} />
    <SATSearchGridSmall data={data} isLoading={isLoading} onEdit={onEdit} />
  </Fragment>
);

export default SATSearchGrid;