import React from "react";

import useClubSwimAThonPayments from './UseClubSwimAThonPayments';

import ShoppingCart from '../../../../common/components/payments/ShoppingCart';

const ClubSwimAThonPayments = () => {
  const {
    instructionText,
    onPaymentCompletion,
    onPaymentCancel,
    orgUnitId
  } = useClubSwimAThonPayments();

  return (
    <ShoppingCart title="Swim-a-Thon Payments" isBarOnBottom={false} instructionText={instructionText} orgUnitId={orgUnitId} itemSubsystemId={6} onPaymentCompletion={onPaymentCompletion} onPaymentCancel={onPaymentCancel}>
    </ShoppingCart>
  );
};

export default ClubSwimAThonPayments;