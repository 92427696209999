import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "../../../../../common/wrappers/ReactRouterDom";
import NavLinks from "../../NavLinks";

import { formatDate } from "../../../../../common/utils/DateFunctions";

const UseClubMeetUpload = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [meetHeaderState, setMeetHeaderState] = useState(getInitialMeetHeaderState);

  const onBackClicked = () => {
    navigate(NavLinks.CLUB_BLOCKPARTY);
  };

  useEffect(() => {
    if (location.state?.meet) {
      setMeetHeaderState({
        ...meetHeaderState,
        meetName: location.state.meet.meetName,
        meetDates: formatDate(location.state.meet.startDate) + ' - ' + formatDate(location.state.meet.endDate)
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function getInitialMeetHeaderState() {
    return {
      meetName: '',
      meetDates: ''
    };
  }

  return {
    meetId: location.state?.meet?.meetId || -1,
    meetHeaderState,
    onBackClicked
  }
}

export default UseClubMeetUpload;