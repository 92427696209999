import { useState } from 'react';

import RecFilesData from './RecFilesData';

const useRecFilesData = () => {
  const [recFilesState, setRecFilesState] = useState(RecFilesData.INITIAL_STATE);

  const postRecFiles = (file) => {
    RecFilesData.postRecFilesData(file, recFilesState, setRecFilesState);
  };

  const resetRecFilesState = () => {
    setRecFilesState(RecFilesData.INITIAL_STATE);
  }

  const getFileNameFromSavedUploadUrl = () => {
    if (recFilesState.isObjLoaded === true) {
      const fileNameWithDateTime = recFilesState.objData.uploadUrl.replace(RecFilesData.BASE_UPLOAD_URL, '');
      const indexOfEndOfDateTime = fileNameWithDateTime.indexOf('.') + 1;
      const fileName = fileNameWithDateTime.substring(indexOfEndOfDateTime);
      return recFilesState.objData.uploadUrl ? decodeURI(fileName) : '';
    } else {
      return '';
    }
  };

  const getFileNameFromUrl = (url) => {
    const fileNameWithDateTime = url.replace(RecFilesData.BASE_UPLOAD_URL, '');
    const indexOfEndOfDateTime = fileNameWithDateTime.indexOf('.') + 1;
    const fileName = fileNameWithDateTime.substring(indexOfEndOfDateTime);
    return decodeURI(fileName) || '';
  };

  return {
    recFilesState,
    postRecFiles,
    resetRecFilesState,
    getFileNameFromSavedUploadUrl,
    getFileNameFromUrl,
    BASE_UPLOAD_URL: RecFilesData.BASE_UPLOAD_URL,
    GET_REC_FILES_PATH: RecFilesData.GET_REC_FILES_PATH
  };
};

export default useRecFilesData;