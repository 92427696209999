import { useEffect, useMemo, useState } from "react";
import { useExecuteQueryByWidgetId } from "@sisense/sdk-ui";
import { filterFactory } from "@sisense/sdk-data";
import { useLocation, useNavigate } from "../../../../../../common/wrappers/ReactRouterDom";

import * as DM from '../../../../../../usas-times-data';

import useSisenseEventNoCourseDropdown from "../../../../../components/usas-times-data/sisenseEventNoCourseDropdown/UseSisenseEventNoCourseDropdown";
import useSisenseScyEventNoCourseDropdown from '../../../../../components/usas-times-data/sisenseScyEventNoCourseDropdown/UseSisenseScyEventNoCourseDropdown';
import useSisenseScmEventNoCourseDropdown from '../../../../../components/usas-times-data/sisenseScmEventNoCourseDropdown/UseSisenseScmEventNoCourseDropdown';
import useSisenseLcmEventNoCourseDropdown from '../../../../../components/usas-times-data/sisenseLcmEventNoCourseDropdown/UseSisenseLcmEventNoCourseDropdown';
import useSisenseScmLcmEventNoCourseDropdown from '../../../../../components/usas-times-data/sisenseScmLcmEventNoCourseDropdown/UseSisenseScmLcmEventNoCourseDropdown';
import useSisenseScyLcmEventNoCourseDropdown from "../../../../../components/usas-times-data/sisenseScyLcmEventNoCourseDropdown/UseSisenseScyLcmEventNoCourseDropdown";
import useSisenseScyScmEventNoCourseDropdown from "../../../../../components/usas-times-data/sisenseScyScmEventNoCourseDropdown/UseSisenseScyScmEventNoCourseDropdown";
import useSisenseCompetitionCategoryNoDefaultDropdown from "../../../../../components/usas-times-data/sisenseCompetitionCategoryNoDefaultDropdown/UseSisenseCompetitionCategoryNoDefaultDropdown";

import { getSisenseFormattedData } from "../../../../../utils/SisenseSortableGridUtils";

import useNavRoutes from "../../../../../../common/state/security/UseNavRoutes";

import UseForm from "../../../../../../common/utils/UseForm";
import Constants from "../../../../../../common/utils/Constants";
import ToIntIfInt from '../../../../../../common/utils/ToIntIfInt';

export const MAX_DATA_COUNT = 500;

export const COLUMN_NUMBERS = {
  PLACE: 1,
  EVENT: 2,
  SESSION: 3,
  NAME: 4,
  GENDER: 5,
  AGE_GROUP: 6,
  SWIM_TIME: 7,
  TIME_STANDARD: 8
};

const COLUMN_INDEXES = {
  PLACE: 0,
  EVENT: 1,
  SESSION: 2,
  NAME: 3,
  GENDER: 4,
  AGE_GROUP: 5,
  SWIM_TIME: 6,
  TIME_STANDARD: 7
};

const INITIAL_FORM_STATE = {
  eventId: Constants.DEFAULT_ID,
  eventName: '--',
  sessionId: Constants.DEFAULT_ID,
  sessionName: '--',
  competitionCategoryId: Constants.DEFAULT_ID,
  competitionCategoryName: '--',
  ageGroupId: Constants.DEFAULT_ID,
  ageGroupName: '--',
};

const useMeetResultsWidget = (dashboardOid, widget, meetData, showScy, showScm, showLcm) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { navRoutes } = useNavRoutes();
  const [state, setState] = useState({ offset: 0, showFilters: true, initialLoadComplete: false });
  const [typeNameFilter, setTypeNameFilter] = useState(null);
  const [ageGroupFilter, setAgeGroupFilter] = useState(null);
  const [eventCodeFilter, setEventCodeFilter] = useState(null);
  const [sessionFilter, setSessionFilter] = useState(null);
  const [sessionOptions, setSessionOptions] = useState({ options: [] });
  const [formattedData, setFormattedData] = useState({ rows: [], columns: [] });
  const { formState, errorState, onValueTextPairChanged, setFormData } = UseForm(INITIAL_FORM_STATE, () => { }, () => { });
  const { competitionCategoryNoDefaultOptions } = useSisenseCompetitionCategoryNoDefaultDropdown();
  const { eventNoCourseOptions } = useSisenseEventNoCourseDropdown();
  const { scmLcmEventNoCourseOptions } = useSisenseScmLcmEventNoCourseDropdown();
  const { scyLcmEventNoCourseOptions } = useSisenseScyLcmEventNoCourseDropdown();
  const { scyScmEventNoCourseOptions } = useSisenseScyScmEventNoCourseDropdown();
  const { scyEventNoCourseOptions } = useSisenseScyEventNoCourseDropdown();
  const { scmEventNoCourseOptions } = useSisenseScmEventNoCourseDropdown();
  const { lcmEventNoCourseOptions } = useSisenseLcmEventNoCourseDropdown();

  const filters = useMemo(() => {
    const meetFilter = filterFactory.equals(DM.Meet.MeetKey, ToIntIfInt(location.state?.meetKey))
    return [meetFilter, typeNameFilter, ageGroupFilter, eventCodeFilter, sessionFilter].filter((f) => !!f);
  }, [location.state?.meetKey, typeNameFilter, ageGroupFilter, eventCodeFilter, sessionFilter]);

  const { data, isLoading, isError } = useExecuteQueryByWidgetId({
    dashboardOid,
    widgetOid: widget?.oid,
    enabled: location.state?.meetKey && filterFactory.equals(DM.Meet.MeetKey, ToIntIfInt(location.state?.meetKey))
      && eventCodeFilter !== null && sessionFilter !== null && typeNameFilter !== null,
    filters: filters,
    count: MAX_DATA_COUNT,
    offset: state.offset
  });

  const onFiltersToggle = (e) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }

    setState({ ...state, showFilters: !state.showFilters });
  };

  const goToNextPage = (e) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }

    const newOffset = state.offset + MAX_DATA_COUNT;
    setState({ ...state, offset: newOffset });
  };

  const goToPreviousPage = (e) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }

    const newOffset = state.offset - MAX_DATA_COUNT;
    setState({ ...state, offset: newOffset });
  };

  const onBackToMeetSummaryClicked = (e) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }

    navigate(navRoutes.CLUB_PERFORMANCE_ATHLETE_TIMES_DETAIL_MEET_SUMMARY?.route, {
      state: {
        meetKey: location.state?.meetKey,
        meetName: location.state?.meetName,
        personKey: location.state?.personKey,
        personName: location.state?.personName,
        clubRosterFilters: location.state?.clubRosterFilters,
        individualTimesSearchFilters: location.state?.individualTimesSearchFilters
      }
    })
  };

  useEffect(() => {
    if (meetData) {
      const formattedOptions = [];
      const meetDataColumns = meetData.columns;
      const meetDataRow = meetData.rows[0];
      //Final filter
      if (meetDataRow[3]?.text === '1') {
        formattedOptions.push({ id: 1, name: meetDataColumns[3].name });
      }
      //Semi filter
      if (meetDataRow[4]?.text === '1') {
        formattedOptions.push({ id: 2, name: meetDataColumns[4].name });
      }
      //Prelim filter
      if (meetDataRow[5]?.text === '1') {
        formattedOptions.push({ id: 3, name: meetDataColumns[5].name });
      }

      setSessionOptions({ ...sessionOptions, options: formattedOptions });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [meetData])

  useEffect(() => {
    if (sessionOptions.options?.length > 0 && (formState.sessionId === Constants.DEFAULT_ID || formState.sessionName === '--')) {
      setFormData({
        ...formState,
        sessionId: sessionOptions.options[0]?.id,
        sessionName: sessionOptions.options[0]?.name
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessionOptions, formState.sessionId, formState.sessionName])

  useEffect(() => {
    if (formState.sessionName) {
      if (formState.sessionName !== '--') {
        const session = filterFactory.members(DM.Session.SessionName, [formState.sessionName]);
        setSessionFilter(session);
      }
      else {
        setSessionFilter(null);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formState.sessionName])

  useEffect(() => {
    if (showScy === true && showScm === true && showLcm === true &&
      eventNoCourseOptions.options?.length > 0 && (formState.eventId === Constants.DEFAULT_ID || formState.eventName === '--')) {
      setFormData({
        ...formState,
        eventId: eventNoCourseOptions.options[0]?.id,
        eventName: eventNoCourseOptions.options[0]?.name
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventNoCourseOptions, formState.eventId, formState.eventName, showScy, showScm, showLcm])

  useEffect(() => {
    if (showScy === false && showScm === true && showLcm === true &&
      scmLcmEventNoCourseOptions.options?.length > 0 && (formState.eventId === Constants.DEFAULT_ID || formState.eventName === '--')) {
      setFormData({
        ...formState,
        eventId: scmLcmEventNoCourseOptions.options[0]?.id,
        eventName: scmLcmEventNoCourseOptions.options[0]?.name
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scmLcmEventNoCourseOptions, formState.eventId, formState.eventName, showScy, showScm, showLcm])

  useEffect(() => {
    if (showScy === true && showScm === false && showLcm === true &&
      scyLcmEventNoCourseOptions.options?.length > 0 && (formState.eventId === Constants.DEFAULT_ID || formState.eventName === '--')) {
      setFormData({
        ...formState,
        eventId: scyLcmEventNoCourseOptions.options[0]?.id,
        eventName: scyLcmEventNoCourseOptions.options[0]?.name
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scyLcmEventNoCourseOptions, formState.eventId, formState.eventName, showScy, showScm, showLcm])

  useEffect(() => {
    if (showScy === true && showScm === true && showLcm === false &&
      scyScmEventNoCourseOptions.options?.length > 0 && (formState.eventId === Constants.DEFAULT_ID || formState.eventName === '--')) {
      setFormData({
        ...formState,
        eventId: scyScmEventNoCourseOptions.options[0]?.id,
        eventName: scyScmEventNoCourseOptions.options[0]?.name
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scyScmEventNoCourseOptions, formState.eventId, formState.eventName, showScy, showScm, showLcm])

  useEffect(() => {
    if (showScy === true && showScm === false && showLcm === false &&
      scyEventNoCourseOptions.options?.length > 0 && (formState.eventId === Constants.DEFAULT_ID || formState.eventName === '--')) {
      setFormData({
        ...formState,
        eventId: scyEventNoCourseOptions.options[0]?.id,
        eventName: scyEventNoCourseOptions.options[0]?.name
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scyEventNoCourseOptions, formState.eventId, formState.eventName, showScy, showScm, showLcm])

  useEffect(() => {
    if (showScy === false && showScm === true && showLcm === false &&
      scmEventNoCourseOptions.options?.length > 0 && (formState.eventId === Constants.DEFAULT_ID || formState.eventName === '--')) {
      setFormData({
        ...formState,
        eventId: scmEventNoCourseOptions.options[0]?.id,
        eventName: scmEventNoCourseOptions.options[0]?.name
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scmEventNoCourseOptions, formState.eventId, formState.eventName, showScy, showScm, showLcm])

  useEffect(() => {
    if (showScy === false && showScm === false && showLcm === true &&
      lcmEventNoCourseOptions.options?.length > 0 && (formState.eventId === Constants.DEFAULT_ID || formState.eventName === '--')) {
      setFormData({
        ...formState,
        eventId: lcmEventNoCourseOptions.options[0]?.id,
        eventName: lcmEventNoCourseOptions.options[0]?.name
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lcmEventNoCourseOptions, formState.eventId, formState.eventName, showScy, showScm, showLcm])

  useEffect(() => {
    if (formState.eventName) {
      if (formState.eventName !== '--') {
        const event = filterFactory.members(DM.SwimEvent.EventCode, [formState.eventName]);
        setEventCodeFilter(event);
      }
      else {
        setEventCodeFilter(null);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formState.eventName])

  useEffect(() => {
    if (competitionCategoryNoDefaultOptions.options?.length > 0 && (formState.competitionCategoryId === Constants.DEFAULT_ID || formState.competitionCategoryName === '--')) {
      setFormData({
        ...formState,
        competitionCategoryId: competitionCategoryNoDefaultOptions.options[0]?.id,
        competitionCategoryName: competitionCategoryNoDefaultOptions.options[0]?.name
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [competitionCategoryNoDefaultOptions, formState.competitionCategoryId, formState.competitionCategoryName])

  useEffect(() => {
    if (formState.competitionCategoryName) {
      if (formState.competitionCategoryName !== '--') {
        const typeName = filterFactory.members(DM.EventCompetitionCategory.TypeName, [formState.competitionCategoryName]);
        setTypeNameFilter(typeName);
      }
      else {
        setTypeNameFilter(null);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formState.competitionCategoryName])

  useEffect(() => {
    if (formState.ageGroupName) {
      if (formState.ageGroupName !== '--') {
        const ageGroup = filterFactory.members(DM.Age.AgeGroup1, [formState.ageGroupName]);
        setAgeGroupFilter(ageGroup);
      }
      else {
        setAgeGroupFilter(null);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formState.ageGroupName])

  useEffect(() => {
    //if any of the filters change, set the offset back to 0
    setState({ ...state, offset: 0 });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [typeNameFilter, ageGroupFilter, eventCodeFilter, sessionFilter])

  useEffect(() => {
    if (data) {
      const newFormattedData = getSisenseFormattedData(data, COLUMN_INDEXES, COLUMN_NUMBERS);

      setFormattedData({
        ...formattedData,
        ...newFormattedData
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return {
    isError,
    isLoading,
    state,
    formattedData,
    location,
    formState,
    errorState,
    onValueTextPairChanged,
    onFiltersToggle,
    goToNextPage,
    goToPreviousPage,
    onBackToMeetSummaryClicked,
    sessionOptions
  };
};

export default useMeetResultsWidget;