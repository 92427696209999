import { Fragment } from "react";

import useIndividualTimesSearchWidget, { COLUMN_NUMBERS, MAX_DATA_COUNT } from "./UseIndividualTimesSearchWidget";

import SisenseSGColumnHeader from "../../../../components/grids/SisenseSGColumnHeader";
import SisenseCourseDropdown from '../../../../components/usas-times-data/sisenseCourseDropdown/SisenseCourseDropdown';
import SisenseEventMultiselect from '../../../../components/usas-times-data/sisenseEventMultiselect/SisenseEventMultiselect';
import SisenseSeasonYearDropdown from "../../../../components/usas-times-data/sisenseSeasonYearDropdown/SisenseSeasonYearDropdown";
import SisenseTimeStandardDropdown from "../../../../components/usas-times-data/sisenseTimeStandardDropdown/SisenseTimeStandardDropdown";
import SisenseShowAllTimesDropdown from "../../../../components/usas-times-data/sisenseShowAllTimesDropdown/SisenseShowAllTimesDropdown";

import useSisenseSortableGrid from "../../../../utils/UseSisenseSortableGrid";

import Input from "../../../../../common/components/inputs/Input";
import Spinner from "../../../../../common/components/spinners/Spinner";
import Headings from "../../../../../common/components/headings/Headings";
import LeftArrowIcon from "../../../../../common/components/icons/LeftArrowIcon";
import RightArrowIcon from "../../../../../common/components/icons/RightArrowIcon";
import SecondaryButton from '../../../../../common/components/buttons/SecondaryButton';
import ActionIntraPageButton from "../../../../../common/components/buttons/ActionIntraPageButton";

import SGConstants from '../../../../../common/utils/SGConstants';
import ToIntIfInt from "../../../../../common/utils/ToIntIfInt";
import Constants from "../../../../../common/utils/Constants";

import global from '../../../../../common/components/GlobalStyle.module.css';
import style from '../../ClubPerformance.module.css';

const IndividualTimesSearchGridRowSmall = ({ columns, row, onGoToMeetSummaryClicked, onSeeProgressionClicked }) => (
  <div className={global.SmallTableRow}>
    <div className={global.SmallTableRowHead}>
      <div style={{ maxWidth: '365px' }}><ActionIntraPageButton onClick={(e) => onGoToMeetSummaryClicked(e, row)}>Meet Summary</ActionIntraPageButton>&nbsp;<ActionIntraPageButton onClick={(e) => onSeeProgressionClicked(e, row)}>Progression</ActionIntraPageButton></div>
    </div>
    <div className={global.SmallTableRowInfo}>
      <div className="row">
        {columns.map((column, i) => (
          <Fragment key={i}>
            {column.columnNumber > 0 &&
              <Fragment>
                <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3'].join(' ')}>
                  {column.columnLabel}
                </div>
                <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3'].join(' ')}>
                  {row[i].cellText || <span>&nbsp;</span>}
                </div>
              </Fragment>
            }
          </Fragment>
        ))}
      </div>
    </div>
  </div>
);

const IndividualTimesSearchGridSmall = ({ data, onGoToMeetSummaryClicked, onSeeProgressionClicked }) => (
  <div className={[global.SmallTable, 'hidden-md hidden-lg'].join(' ')}>
    {Array.isArray(data.rows) && data.rows.length > 0
      ? data.rows.map((row, i) => (
        <IndividualTimesSearchGridRowSmall key={i} columns={data.columns} row={row} onGoToMeetSummaryClicked={onGoToMeetSummaryClicked} onSeeProgressionClicked={onSeeProgressionClicked} />
      )) : (
        <div className={global.SmallTableRow}>
          <div className={global.SmallTableRowHead}>
            &nbsp;
          </div>
          <div className={global.SmallTableRowInfo}>
            <div className={global.SmallTableRowLabels}>No Results</div>
          </div>
        </div>
      )}
  </div>
);

const IndividualTimesSearchGridRowLarge = ({ row, onGoToMeetSummaryClicked, onSeeProgressionClicked }) => (
  <tr>
    {row.map((cell, i) => {
      if (cell.columnNumber === COLUMN_NUMBERS.MEET) {
        return <td className={style.MeetName} key={i}><a onClick={(e) => onGoToMeetSummaryClicked(e, row)}>{cell.cellText || ''}</a></td>;
      } else if (cell.columnNumber > 0) {
        return <td key={i}> {cell.cellText || ''} </td>;
      }
    })}
    <td><div style={{ paddingTop: '5px' }} className="pull-right"><ActionIntraPageButton onClick={(e) => onSeeProgressionClicked(e, row)}>Progression</ActionIntraPageButton></div></td>
  </tr>
);

const IndividualTimesSearchGridLarge = ({ data, onGoToMeetSummaryClicked, onSeeProgressionClicked }) => {
  const {
    sortableGridState,
    onColumnClicked
  } = useSisenseSortableGrid(data.rows);

  return (
    <table className={global.UsasTable}>
      <thead>
        <tr>
          {data.columns.map((column, i) =>
            <Fragment key={i}>
              {column.columnNumber === COLUMN_NUMBERS.SWIM_EVENT_NAME ?
                <SisenseSGColumnHeader
                  key={i} arrayIndex={data.swimEventKeyIndex} style={{ minWidth: '100px' }}
                  sortableGridState={sortableGridState} columnLabel={column.columnLabel}
                  sortType={SGConstants.SORT_TYPE_NUMERICAL} onColumnClicked={onColumnClicked} /> :
                column.columnNumber === COLUMN_NUMBERS.SWIM_TIME ?
                  <SisenseSGColumnHeader
                    key={i} arrayIndex={i} style={{ minWidth: '90px' }}
                    sortableGridState={sortableGridState} columnLabel={column.columnLabel}
                    sortType={SGConstants.SORT_TYPE_TIME} onColumnClicked={onColumnClicked} /> :
                  column.columnNumber === COLUMN_NUMBERS.AGE ?
                    <SisenseSGColumnHeader
                      key={i} arrayIndex={i} style={{ minWidth: '50px' }}
                      sortableGridState={sortableGridState} columnLabel={column.columnLabel}
                      sortType={SGConstants.SORT_TYPE_NUMERICAL} onColumnClicked={onColumnClicked} /> :
                    column.columnNumber === COLUMN_NUMBERS.POINTS ?
                      <SisenseSGColumnHeader
                        key={i} arrayIndex={i} style={{ minWidth: '70px' }}
                        sortableGridState={sortableGridState} columnLabel={column.columnLabel}
                        sortType={SGConstants.SORT_TYPE_NUMERICAL} onColumnClicked={onColumnClicked} /> :
                      column.columnNumber === COLUMN_NUMBERS.TIME_STANDARD || column.columnNumber === COLUMN_NUMBERS.MEET ?
                        <SisenseSGColumnHeader
                          key={i} arrayIndex={i} style={{ minWidth: '120px' }}
                          sortableGridState={sortableGridState} columnLabel={column.columnLabel}
                          sortType={SGConstants.SORT_TYPE_ALPHABETICAL} onColumnClicked={onColumnClicked} /> :
                        column.columnNumber === COLUMN_NUMBERS.LSC || column.columnNumber === COLUMN_NUMBERS.TEAM ?
                          <SisenseSGColumnHeader
                            key={i} arrayIndex={i} style={{ minWidth: '60px' }}
                            sortableGridState={sortableGridState} columnLabel={column.columnLabel}
                            sortType={SGConstants.SORT_TYPE_ALPHABETICAL} onColumnClicked={onColumnClicked} /> :
                          column.columnNumber === COLUMN_NUMBERS.SWIM_DATE ?
                            <SisenseSGColumnHeader
                              key={i} arrayIndex={i} style={{ minWidth: '100px' }}
                              sortableGridState={sortableGridState} columnLabel={column.columnLabel}
                              sortType={SGConstants.SORT_TYPE_DATE} onColumnClicked={onColumnClicked} /> :
                            column.columnNumber > 0 ?
                              <SisenseSGColumnHeader
                                key={i} arrayIndex={i}
                                sortableGridState={sortableGridState} columnLabel={column.columnLabel}
                                sortType={SGConstants.SORT_TYPE_ALPHABETICAL} onColumnClicked={onColumnClicked} /> :
                              <Fragment />}
            </Fragment>)}
          <th style={{ minWidth: '155px' }}></th>
        </tr>
      </thead>
      <tbody>
        {sortableGridState.sortedGridData?.length > 0
          ? sortableGridState.sortedGridData?.map((row, i) =>
            <IndividualTimesSearchGridRowLarge key={i} row={row}
              onGoToMeetSummaryClicked={onGoToMeetSummaryClicked}
              onSeeProgressionClicked={onSeeProgressionClicked} />)
          : <tr><td colSpan={data.columns.length}>No Results</td></tr>
        }
      </tbody>
    </table>
  );
}

const IndividualTimesSearchGrid = ({ data, onGoToMeetSummaryClicked, onSeeProgressionClicked }) => (
  <Fragment>
    <div className={'visible-md visible-lg'}>
      <IndividualTimesSearchGridLarge data={data} onGoToMeetSummaryClicked={onGoToMeetSummaryClicked} onSeeProgressionClicked={onSeeProgressionClicked} />
    </div>
    <div className={'hidden-md hidden-lg'}>
      <IndividualTimesSearchGridSmall data={data} onGoToMeetSummaryClicked={onGoToMeetSummaryClicked} onSeeProgressionClicked={onSeeProgressionClicked} />
    </div>
  </Fragment>
);

const IndividualTimesSearchWidget = ({ dashboardOid, widget }) => {
  const {
    isError,
    isLoading,
    state,
    formattedData,
    location,
    onFiltersToggle,
    goToNextPage,
    goToPreviousPage,
    onBackToClubRosterClicked,
    formState,
    errorState,
    onFormValueChanged,
    onValueTextPairChanged,
    onGoToMeetSummaryClicked,
    onSeeProgressionClicked
  } = useIndividualTimesSearchWidget(dashboardOid, widget);

  return (
    <Fragment>
      <div className="row usas-extra-top-margin usas-extra-bottom-margin">
        <div className="col-xs-12 col-sm-7 usas-extra-top-margin">
          <Headings.H5>Individual Times for {location.state?.personName}</Headings.H5>
        </div>
        <div className="col-xs-12 col-sm-5 usas-extra-top-margin">
          <div className="pull-right">
            <button className={global.BackToSearchButton}
              type="button"
              onClick={onBackToClubRosterClicked}>
              <LeftArrowIcon />  Back To Athlete Times Roster
            </button>
          </div>
        </div>
      </div>
      <div className={state.showFilters === true ? global.DisplayComponent : global.HideComponent}>
        <div className="row usas-extra-top-margin">
          <div className="col-xs-12 col-sm-6 col-md-4">
            <SisenseSeasonYearDropdown
              label="Competition Year"
              name="competitionYearId"
              value={formState.competitionYearId}
              error={errorState.competitionYearId}
              message={errorState.competitionYearId}
              onChange={(value, newValueLabel, e) => {
                e && e.target && e.target.value &&
                  onValueTextPairChanged(value, 'competitionYearId', newValueLabel, 'competitionYearName');
              }}
            />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4">
            <Input
              label={"Age From"}
              type="number"
              name="ageFrom"
              min='0'
              max='99'
              value={formState.ageFrom}
              error={formState.ageFrom && formState.ageTo && (ToIntIfInt(formState.ageFrom) > ToIntIfInt(formState.ageTo))}
              message={'Age From cannot be greater than Age To'}
              onChange={(value) => { onFormValueChanged('ageFrom', value) }} />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4">
            <Input
              label={"Age To"}
              type="number"
              name="ageTo"
              min='0'
              max='99'
              value={formState.ageTo}
              error={formState.ageFrom && formState.ageTo && (ToIntIfInt(formState.ageFrom) > ToIntIfInt(formState.ageTo))}
              message={'Age To cannot be less than Age From'}
              onChange={(value) => { onFormValueChanged('ageTo', value) }} />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4">
            <SisenseTimeStandardDropdown
              label="Time Standard"
              name="timeStandardId"
              value={formState.timeStandardId}
              error={errorState.timeStandardId}
              message={errorState.timeStandardId}
              onChange={(value, newValueLabel, e) => {
                e && e.target && e.target.value &&
                  onValueTextPairChanged(value, 'timeStandardId', newValueLabel, 'timeStandardName');
              }}
              showDefault={true}
            />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4">
            <SisenseShowAllTimesDropdown
              label="Display Options*"
              name="displayId"
              value={formState.displayId}
              error={formState.displayId === Constants.DEFAULT_ID ||
                formState.displayName === '--'}
              message={'Display Option is required'}
              onChange={(value, newValueLabel, e) => {
                e && e.target && e.target.value &&
                  onValueTextPairChanged(value, 'displayId', newValueLabel, 'displayName');
              }}
            />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4">
            <SisenseCourseDropdown
              label="Course"
              name="courseId"
              value={formState.courseId}
              error={errorState.courseId}
              message={errorState.courseId}
              onChange={(value, newValueLabel, e) => {
                e && e.target && e.target.value &&
                  onValueTextPairChanged(value, 'courseId', newValueLabel, 'courseName');
              }}
            />
          </div>
          {state.initialLoadComplete === true && <div className="col-xs-12">
            <SisenseEventMultiselect
              label="Event"
              name="events"
              value={formState.events}
              error={errorState.events}
              message={errorState.events}
              onChange={(value) => { onFormValueChanged('events', value); }}
              courseName={formState.courseName !== '--' ? formState.courseName : undefined}
            />
          </div>}
        </div>
      </div>
      <div className={style.Widget}>
        <div className="col-xs-12 usas-extra-top-margin">
          <button className={style.ToggleButton} type="button" onClick={(e) => onFiltersToggle(e)}>{state.showFilters === true ? 'Hide Filters' : 'Show Filters'}</button>
        </div>
      </div>
      {!isLoading && !isError &&
        <div className={style.Widget}>
          <div className="row usas-extra-top-margin">
            <div className="col-xs-12 col-sm-3">
              <p>{formattedData.rows?.length === 1 ? `${formattedData.rows?.length} Result` : `${formattedData.rows?.length} Results`} - Page {(state.offset / MAX_DATA_COUNT) + 1}</p>
            </div>
            <div className="col-xs-12 col-sm-9">
              {formattedData.rows?.length === MAX_DATA_COUNT &&
                <Fragment>
                  <SecondaryButton className={'pull-right usas-extra-top-margin'} onClick={(e) => goToNextPage(e)} >View Next Results Page <RightArrowIcon /></SecondaryButton>
                  <span className="pull-right">&nbsp;</span>
                </Fragment>}
              {state.offset > 0 &&
                <Fragment>
                  <SecondaryButton className={'pull-right usas-extra-top-margin'} onClick={(e) => goToPreviousPage(e)} ><LeftArrowIcon /> View Previous Results Page</SecondaryButton>
                  <span className="pull-right">&nbsp;</span>
                </Fragment>}
            </div>
          </div>
        </div>}
      <div className="row usas-extra-top-margin">
        <div className="col-xs-12">
          {isError
            ? <p className={global.ErrorMessage}>Error...</p>
            : isLoading
              ? <Spinner />
              : formattedData
                ?
                <IndividualTimesSearchGrid data={formattedData || {}}
                  onGoToMeetSummaryClicked={onGoToMeetSummaryClicked}
                  onSeeProgressionClicked={onSeeProgressionClicked} />
                : <Fragment />
          }
        </div>
      </div>
      {!isLoading && !isError && formattedData.rows?.length > 5 &&
        <div className={style.Widget}>
          <div className="row usas-extra-top-margin">
            <div className="col-xs-12 col-sm-3">
              <p>{formattedData.rows?.length === 1 ? `${formattedData.rows?.length} Result` : `${formattedData.rows?.length} Results`} - Page {(state.offset / MAX_DATA_COUNT) + 1}</p>
            </div>
            <div className="col-xs-12 col-sm-9">
              {formattedData.rows?.length === MAX_DATA_COUNT &&
                <Fragment>
                  <SecondaryButton className={'pull-right usas-extra-top-margin'} onClick={(e) => goToNextPage(e)} >View Next Results Page <RightArrowIcon /></SecondaryButton>
                  <span className="pull-right">&nbsp;</span>
                </Fragment>}
              {state.offset > 0 &&
                <Fragment>
                  <SecondaryButton className={'pull-right usas-extra-top-margin'} onClick={(e) => goToPreviousPage(e)} ><LeftArrowIcon /> View Previous Results Page</SecondaryButton>
                  <span className="pull-right">&nbsp;</span>
                </Fragment>}
            </div>
          </div>
        </div>}
    </Fragment>
  );
};

export default IndividualTimesSearchWidget;