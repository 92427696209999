import { useEffect, useState } from 'react';
import { useNavigate } from '../../../../common/wrappers/ReactRouterDom';

import { navLinks } from '../UseMemberAdministration';

import useMemberAdministrationLscBoardData from '../../../../common/state/memberAdministration/lscBoard/UseMemberAdministrationLscBoardData';
import useSelectOrgUnitData from '../../../../common/state/selectOrgUnit/UseSelectOrgUnitData';

import Constants from '../../../../common/utils/Constants';
import { formatDate } from '../../../../common/utils/DateFunctions';

const useLSCBoardMembers = () => {
  const navigate = useNavigate();
  const { memberAdministrationLscBoardState, getLscBoardMembers, deleteLscBoardMember, confirmSave
  } = useMemberAdministrationLscBoardData();
  const { selectOrgUnitState } = useSelectOrgUnitData();
  const [viewState, setViewState] = useState(getInitialViewState);
  const [gridState, setGridState] = useState([]);

  const onAddBoardMember = () => {
    navigate(navLinks.LSC_BOARD_ADD, { state: { activeUserOrgUnitId: selectOrgUnitState.orgUnitId } });
  };

  const onEditBoardMember = (personId, personOrgRoleDurationId) => {
    navigate(navLinks.LSC_BOARD_EDIT, { state: { personId, personOrgRoleDurationId, activeUserOrgUnitId: selectOrgUnitState.orgUnitId } });
  };

  const onDeleteBoardMember = (e, personOrgRoleDurationId) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    deleteLscBoardMember(personOrgRoleDurationId);
  };

  const onFilterCurrentMembers = () => {
    if (memberAdministrationLscBoardState.isArrayLoading === false) {
      setViewState({
        ...viewState,
        toggleCurrentStaffRoles: !viewState.toggleCurrentStaffRoles
      });
      setGridState(filterBoardMembersForGrid(memberAdministrationLscBoardState.arrayData, !viewState.toggleCurrentStaffRoles));
    }
  };

  useEffect(() => {
    if (selectOrgUnitState.orgUnitId) {
      if (memberAdministrationLscBoardState.isSaved === true) {
        confirmSave();
      } else {
        getLscBoardMembers(selectOrgUnitState.orgUnitId);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectOrgUnitState.orgUnitId, memberAdministrationLscBoardState.isSaved]);

  useEffect(() => {
    if (selectOrgUnitState.orgUnitId && memberAdministrationLscBoardState.isArrayLoading === false
      && memberAdministrationLscBoardState.isArrayLoaded === true) {
      setGridState(filterBoardMembersForGrid(memberAdministrationLscBoardState.arrayData, viewState.toggleCurrentStaffRoles));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectOrgUnitState.orgUnitId, memberAdministrationLscBoardState]);

  function filterBoardMembersForGrid(arrayData, toggleCurrentStaffRoles = false) {
    const today = new Date();
    const gridData = [];

    for (const staff of arrayData) {
      const roleDurations = [];

      if (Array.isArray(staff.orgRoles) && staff.orgRoles.length > 0) {
        if (toggleCurrentStaffRoles === true) {
          for (const duration of staff.orgRoles) {
            if (duration.isCurrent === true) {
              roleDurations.push({
                personOrgRoleDurationId: duration.personOrgRoleDurationId || Constants.DEFAULT_ID,
                personOrgRoleId: duration.personOrgRoleId || '',
                effectiveDate: duration.effectiveDate ? formatDate(duration.effectiveDate) : '',
                expirationDate: duration.expirationDate ? formatDate(duration.expirationDate) : '',
                isCurrent: duration.isCurrent === true ? true : false,
                canDelete: new Date(duration.expirationDate) > today ? true : false,
                orgRoleId: duration.orgRoleId || '',
                orgRoleName: duration.orgRoleName || ''
              });
            }
          }
        } else {
          for (const duration of staff.orgRoles) {
            roleDurations.push({
              personOrgRoleDurationId: duration.personOrgRoleDurationId || Constants.DEFAULT_ID,
              personOrgRoleId: duration.personOrgRoleId || Constants.DEFAULT_ID,
              effectiveDate: duration.effectiveDate ? formatDate(duration.effectiveDate) : '',
              expirationDate: duration.expirationDate ? formatDate(duration.expirationDate) : '',
              isCurrent: duration.isCurrent === true ? true : false,
              canDelete: new Date(duration.expirationDate) > today ? true : false,
              orgRoleId: duration.orgRoleId || '',
              orgRoleName: duration.orgRoleName || ''
            });
          }
        }
      }

      if (roleDurations.length > 0) {
        roleDurations.sort((a, b) => new Date(b.expirationDate) - new Date(a.expirationDate));

        gridData.push({
          personId: staff.personId || '',
          firstName: staff.firstName || '',
          lastName: staff.lastName || '',
          email: staff.email || '',
          roleDurations
        });
      }
    }

    return gridData;
  };

  function getInitialViewState() {
    return {
      toggleCurrentStaffRoles: true
    };
  };

  return {
    isSaving: memberAdministrationLscBoardState.isSaving,
    isGridLoading: memberAdministrationLscBoardState.isArrayLoading,
    viewState,
    gridState,
    memberAdministrationLscBoardState,
    onFilterCurrentMembers,
    onEditBoardMember,
    onAddBoardMember,
    onDeleteBoardMember
  };
};

export default useLSCBoardMembers;