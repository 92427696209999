import { useState } from 'react';

import PreCompFilesInsuranceVerificationData from './PreCompFilesInsuranceVerificationData';

const usePreCompFilesInsuranceVerificationData = () => {
  const [preCompFilesInsuranceVerificationState, setPreCompFilesInsuranceVerificationState] = useState(PreCompFilesInsuranceVerificationData.INITIAL_STATE);

  const postPreCompFilesInsuranceVerification = (file) => {
    PreCompFilesInsuranceVerificationData.postPreCompFilesInsuranceVerificationData(file, preCompFilesInsuranceVerificationState, setPreCompFilesInsuranceVerificationState);
  };

  const resetPreCompFilesInsuranceVerificationState = () => {
    setPreCompFilesInsuranceVerificationState(PreCompFilesInsuranceVerificationData.INITIAL_STATE);
  };

  const getFileNameFromSavedUploadUrl = () => {
    if (preCompFilesInsuranceVerificationState.isObjLoaded === true) {
      const fileNameWithDateTime = PreCompFilesInsuranceVerificationData.objData.uploadUrl.replace(PreCompFilesInsuranceVerificationData.BASE_UPLOAD_URL, '');
      const indexOfEndOfDateTime = fileNameWithDateTime.indexOf('.') + 1;
      const fileName = fileNameWithDateTime.substring(indexOfEndOfDateTime);
      return PreCompFilesInsuranceVerificationData.objData.uploadUrl ? decodeURI(fileName) : '';
    } else {
      return '';
    }
  };

  const getFileNameFromUrl = (url) => {
    const fileNameWithDateTime = url.replace(PreCompFilesInsuranceVerificationData.BASE_UPLOAD_URL, '');
    const indexOfEndOfDateTime = fileNameWithDateTime.indexOf('.') + 1;
    const fileName = fileNameWithDateTime.substring(indexOfEndOfDateTime);
    return decodeURI(fileName) || '';
  };

  return {
    preCompFilesInsuranceVerificationState,
    postPreCompFilesInsuranceVerification,
    getFileNameFromSavedUploadUrl,
    getFileNameFromUrl,
    resetPreCompFilesInsuranceVerificationState,
    BASE_UPLOAD_URL: PreCompFilesInsuranceVerificationData.BASE_UPLOAD_URL,
    GET_DOWNLOAD_TEMPLATE_PATH: PreCompFilesInsuranceVerificationData.GET_DOWNLOAD_TEMPLATE_PATH,
    GET_PRE_COMP_FILES_INSURANCE_VERIFICATION_PATH: PreCompFilesInsuranceVerificationData.GET_PRE_COMP_FILES_INSURANCE_VERIFICATION_PATH
  };
};

export default usePreCompFilesInsuranceVerificationData;