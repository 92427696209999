import React from 'react';

import useBusinessEntityDropdown from './UseBusinessEntityDropdown';

import Dropdown from '../../../common/components/dropdowns/Dropdown';

import global from '../../../common/components/GlobalStyle.module.css';

const BusinessEntityDropdown = ({ label, name, value, error, message, onChange, clubOwnerTypeId }) => {
  const { businessEntityState } = useBusinessEntityDropdown(clubOwnerTypeId);

  return businessEntityState.message
    ? <span className={global.LoadingMsg}>{businessEntityState.message}</span>
    : (
      <Dropdown
        error={error}
        label={label}
        message={message}
        options={businessEntityState.dataAsOptionsWithNames}
        name={name}
        value={value}
        onChange={onChange}
        isLoading={businessEntityState.isArrayLoading}
      />
    );
};

export default BusinessEntityDropdown;