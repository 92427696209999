import { useEffect, useState } from 'react';
import { useNavigate } from '../../../../common/wrappers/ReactRouterDom';

import NavLinks from '../NavLinks';

import PreCompRegistrationConstants from '../utils/PreCompRegistrationConstants';

import usePreCompLocationRegistrationNavData from '../../../state/preCompLocationRegistration/preCompLocationRegistrationLeftNavBar/UsePreCompLocationRegistrationNavData';
import usePreCompLocationRegistrationData from '../../../state/preCompLocationRegistration/UsePreCompLocationRegistrationData';

const REQUIRED_ERROR = 'Must link at least one Facility';

const usePreCompRegistrationLocationFacilities = () => {
  const navigate = useNavigate();
  const { preCompLocationRegistrationState, saveObjData, confirmSave } = usePreCompLocationRegistrationData();
  const { locationRegistrationNavState, updateCurrentNavState } = usePreCompLocationRegistrationNavData();
  const [gridState, setGridState] = useState([]);
  const [modalState, setModalState] = useState(getInitialModalState);
  const [pageErrorMessageState, setPageErrorMessageState] = useState('');

  const onContinueClicked = () => {
    if (preCompLocationRegistrationState.objData.orgUnit.orgUnitFacility.length > 0) {
      navigate(NavLinks.PRE_COMP_REGISTRATION_LOCATION_EMERGENCY_PLAN);
    } else {
      setPageErrorMessageState(REQUIRED_ERROR);
    }
  };

  const onBackClicked = () => {
    if (preCompLocationRegistrationState.objData.orgUnit.orgUnitFacility.length > 0) {
      navigate(NavLinks.PRE_COMP_REGISTRATION_LOCATION_CLUB);
    } else {
      setPageErrorMessageState(REQUIRED_ERROR);
    }
  };

  const onAddFacility = () => {
    navigate(NavLinks.PRE_COMP_REGISTRATION_LOCATION_FACILITY_SEARCH);
  };

  const onEditFacility = (facilityId) => {
    navigate(NavLinks.PRE_COMP_REGISTRATION_LOCATION_FACILITY_DETAIL, { state: { facilityId } });
  };

  const onDeleteFacility = (facilityId) => {
    if (preCompLocationRegistrationState.isSaving === false) {
      const targetIndex = preCompLocationRegistrationState.objData.orgUnit.orgUnitFacility.findIndex(x => x.facilityId === facilityId);

      if (targetIndex >= 0) {
        setModalState({
          ...modalState,
          displayPopUp: true,
          facilityName: preCompLocationRegistrationState.objData.orgUnit.orgUnitFacility[targetIndex].facility.facilityName,
          facilityCity: preCompLocationRegistrationState.objData.orgUnit.orgUnitFacility[targetIndex].facility.city,
          facilityStateCode: preCompLocationRegistrationState.objData.orgUnit.orgUnitFacility[targetIndex].facility.stateCode,
          facilityPostalCode: preCompLocationRegistrationState.objData.orgUnit.orgUnitFacility[targetIndex].facility.postalCode,
          targetIndex
        });
      }
    }
  };

  const onModalCancelClicked = () => {
    setModalState(getInitialModalState);
  };

  const onModalDeleteClicked = (targetIndex) => {
    if (targetIndex >= 0) {
      const locObj = preCompLocationRegistrationState.objData;

      locObj.orgUnit.orgUnitFacility.splice(targetIndex, 1);

      saveObjData(locObj);

      setModalState(getInitialModalState);
    }
  };

  useEffect(() => {
    if (preCompLocationRegistrationState.objData?.orgUnit?.orgUnitFacility?.length > 0) {
      updateCurrentNavState(PreCompRegistrationConstants.LOC_FACILITY, true);
    } else {
      updateCurrentNavState(PreCompRegistrationConstants.LOC_FACILITY, false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (preCompLocationRegistrationState.isObjLoaded === true && preCompLocationRegistrationState.isSaved === false) {
      setGridState(preCompLocationRegistrationState.objData.orgUnit?.orgUnitFacility || []);
    } else if (preCompLocationRegistrationState.isObjLoaded === false) {
      navigate(NavLinks.PRE_COMP_REGISTRATION_ENTRY);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [preCompLocationRegistrationState.isObjLoaded, preCompLocationRegistrationState.isSaved]);

  useEffect(() => {
    if (preCompLocationRegistrationState.isSaved === true) {
      confirmSave();

      setPageErrorMessageState('');
      if (preCompLocationRegistrationState.objData.orgUnit.orgUnitFacility.length > 0) {
        updateCurrentNavState(PreCompRegistrationConstants.LOC_FACILITY, true);
      } else {
        updateCurrentNavState(PreCompRegistrationConstants.LOC_FACILITY, false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [preCompLocationRegistrationState.isSaved]);

  function getInitialModalState() {
    return {
      displayPopUp: false,
      facilityName: '',
      facilityCity: '',
      facilityStateCode: '',
      facilityPostalCode: '',
      targetIndex: undefined
    };
  };

  return {
    locationRegistrationNavState,
    gridState,
    modalState,
    pageErrorMessageState,
    onAddFacility,
    onEditFacility,
    onDeleteFacility,
    onModalCancelClicked,
    onModalDeleteClicked,
    onContinueClicked,
    onBackClicked
  };
};

export default usePreCompRegistrationLocationFacilities;