import { useEffect, useState } from 'react';
import { useNavigate, useLocation } from '../../../../common/wrappers/ReactRouterDom';

import NavLinks from '../NavLinks';

import usePreCompLocationData from '../../../state/preCompLocation/UsePreCompLocationData';
import useFacilityData from '../../../../common/state/facility/UseFacilityData';
import Constants from '../../../../common/utils/Constants';
import useTeamFacilityLinksData from '../../../../common/state/teamFacilityLinks/UseTeamsFacilityLinksData';

const usePreCompLocationFacilities = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { preCompLocationState, confirmSave } = usePreCompLocationData();
  const { facilityState, deleteFacilityPool } = useFacilityData();
  const [state, setState] = useState({ tryGet: false });
  const [modalState, setModalState] = useState(getInitialModalState);
  const [poolModalState, setPoolModalState] = useState(getInitialPoolModalState());
  const { teamFacilityLinksState, getTeamFacilityLinks, deleteTeamFacilityLinks } = useTeamFacilityLinksData();

  const onAddFacility = () => {
    navigate(NavLinks.PRE_COMP_LOCATION_FACILITY_SEARCH);
  };

  const onEditFacility = (facilityId) => {
    navigate(NavLinks.PRE_COMP_LOCATION_FACILITY_DETAIL, { state: { facilityId } });
  };

  const onAddPool = (e, facilityId) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    navigate(NavLinks.PRE_COMP_LOCATION_FACILITY_POOL_DETAIL, { state: { facilityId } });
  }

  const onEditPool = (e, facilityId, poolId) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    navigate(NavLinks.PRE_COMP_LOCATION_FACILITY_POOL_DETAIL, { state: { facilityId, poolId } });
  }

  const onDeletePool = (e, pool) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    setPoolModalState({
      ...poolModalState,
      displayPopup: true,
      poolId: pool.facilityPoolId,
      poolName: pool.facilityPoolName,
      poolDimensions: pool.poolDimensions.dimensionName
    });
  };

  const onClickDeletePool = (e, poolId) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    setPoolModalState(getInitialPoolModalState());
    deleteFacilityPool(poolId);
    setState({ ...state, tryGet: true });
  };

  const onPoolModalCanceled = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    setPoolModalState(getInitialPoolModalState());
  };

  const onDeleteFacility = (facilityId, facilityName) => {
    const targetIndex = teamFacilityLinksState.arrayData.findIndex((orgUnitFacility) => orgUnitFacility.facilityId === facilityId);

    if (targetIndex >= 0) {
      setModalState({
        ...modalState,
        orgUnitFacilityId: teamFacilityLinksState.arrayData[targetIndex].orgUnitFacilityId,
        displayPopUp: true,
        facilityId: facilityId,
        facilityName: facilityName,
        facilityCity: teamFacilityLinksState.arrayData[targetIndex].facility.city,
        facilityStateCode: teamFacilityLinksState.arrayData[targetIndex].facility.stateCode,
        facilityPostalCode: teamFacilityLinksState.arrayData[targetIndex].facility.postalCode,
        targetIndex
      });
    }
    
  };

  const onModalCancelClicked = () => {
    setModalState(getInitialModalState);
  }

  const onModalDeleteClicked = (targetIndex) => {
    deleteTeamFacilityLinks(preCompLocationState.objData.orgUnitId, modalState.orgUnitFacilityId);
    setModalState({
      ...getInitialModalState()
    });
    setState({ ...state, tryGet: true });
  }

  useEffect(() => {
    if (location.state && (location.state.addedPool === true || location.state.hasNewFacilityDetails === true)) {
      setState({ ...state, tryGet: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (state.tryGet === true) {
      if (preCompLocationState.isObjLoaded === true && teamFacilityLinksState.isArrayLoading === false
        && teamFacilityLinksState.isSaving === false && facilityState.isSaving === false) {
        getTeamFacilityLinks(preCompLocationState.objData.orgUnitId);
        setState({ ...state, tryGet: false });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state, preCompLocationState, teamFacilityLinksState, facilityState]);

  useEffect(() => {
    if (teamFacilityLinksState.isArrayLoaded === false) {
      if (preCompLocationState.isObjLoaded === true && teamFacilityLinksState.isArrayLoading === false && teamFacilityLinksState.isSaving === false) {
        getTeamFacilityLinks(preCompLocationState.objData.orgUnitId);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teamFacilityLinksState, preCompLocationState]);

  useEffect(() => {
    if (preCompLocationState.isSaving === false && preCompLocationState.isSaved === true) {
      confirmSave();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [preCompLocationState]);

  function getInitialModalState() {
    return {
      displayPopUp: false,
      facilityName: '',
      facilityCity: '',
      facilityStateCode: '',
      facilityPostalCode: '',
      targetIndex: undefined
    }
  };

  function getInitialPoolModalState() {
    return {
      modalTitle: 'Delete this pool from the facility?',
      displayPopup: false,
      poolId: Constants.DEFAULT_ID,
      poolName: '',
      poolDimensions: ''
    };
  };

  return {
    teamFacilityLinksState,
    preCompLocationState,
    modalState,
    poolModalState,
    onAddFacility,
    onEditFacility,
    onAddPool,
    onEditPool,
    onDeletePool,
    onClickDeletePool,
    onPoolModalCanceled,
    onDeleteFacility,
    onModalCancelClicked,
    onModalDeleteClicked
  };
};

export default usePreCompLocationFacilities;