import { useEffect, useState } from 'react';
import { useNavigate, useLocation } from '../../../../../common/wrappers/ReactRouterDom';

import validate from './EditLSCStaffMemberValidation';

import { navLinks } from '../../UseMemberAdministration';

import useEnvironmentVariableData from '../../../../../common/state/environmentVariable/UseEnvironmentVariableData';
import useMemberAdministrationLscStaffData from '../../../../../common/state/memberAdministration/lscStaff/UseMemberAdministrationLscStaffData';

import useForm from '../../../../../common/utils/UseForm';
import Constants from '../../../../../common/utils/Constants';
import { formatDate } from '../../../../../common/utils/DateFunctions';

const useEditLSCStaffMember = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { LSC_STAFF_ROLE_GROUP_ID, ORG_ROLE_FIELD_PAID_OR_VOLUNTEER_FIELD_NAME, environmentVariableState } = useEnvironmentVariableData();
  const { memberAdministrationLscStaffState, putLscStaff } = useMemberAdministrationLscStaffData();
  const { formState, errorState, handleSubmit, setFormData, onValueTextPairChanged, setIsDirty, isSubmitting
  } = useForm(getInitialFormState, submitFormCallback, validate);
  const [viewState, setViewState] = useState(getInitialViewState);

  const onSaveClicked = () => {
    setIsDirty(true);
    handleSubmit();
  }

  const onCancelClicked = () => {
    navigate(navLinks.LSC_STAFF);
  };

  useEffect(() => {
    if (location.state?.personId && location.state?.orgRoleId && location.state?.personOrgRoleDurationId > 0
      && location.state?.activeUserOrgUnitId) {
      const personId = location.state.personId;
      const orgRoleId = location.state.orgRoleId;
      const personOrgRoleDurationId = location.state.personOrgRoleDurationId;
      const activeUserOrgUnitId = location.state.activeUserOrgUnitId;

      const targetPerson = memberAdministrationLscStaffState.arrayData.find(x => x.personId === personId);

      if (targetPerson && Array.isArray(targetPerson.personOrgRole)) {
        const targetRole = targetPerson.personOrgRole.find(x => x.orgRoleId === orgRoleId);

        if (targetRole && Array.isArray(targetRole.personDurations)) {
          const targetDuration = targetRole.personDurations.find(x => x.personOrgRoleDurationId === personOrgRoleDurationId);

          if (targetDuration) {
            setFormData({
              ...formState,
              paidOrVolunteerId: (targetDuration.paidOrVolunteer === "Paid") ? 1 : (targetDuration.paidOrVolunteer === "Volunteer") ? 2 : Constants.DEFAULT_ID,
              paidOrVolunteerName: targetDuration.paidOrVolunteer || ''
            });
            setViewState({
              ...viewState,
              orgRoleName: targetRole.orgRoleName,
              orgRoleId,
              memberName: `${targetPerson.firstName} ${targetPerson.lastName}`,
              memberId: targetPerson.memberId || '',
              personId,
              personOrgRoleDurationId,
              orgRoleFieldId: targetDuration.orgRoleFieldId,
              effectiveDate: targetDuration.effectiveDate ? formatDate(targetDuration.effectiveDate) : '',
              expirationDate: targetDuration.expirationDate ? formatDate(targetDuration.expirationDate) : '',
              activeUserOrgUnitId,
              targetPerson
            });
            setIsDirty(true);
          }
        }
      }
    }
    else {
      onCancelClicked();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (memberAdministrationLscStaffState.isSaved === true) {
      navigate(navLinks.LSC_STAFF);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [memberAdministrationLscStaffState.isSaved]);

  function submitFormCallback(formState) {
    putLscStaff(viewState.activeUserOrgUnitId, viewState.personId, LSC_STAFF_ROLE_GROUP_ID, createStaffObj(viewState.targetPerson, formState));
  };

  function createStaffObj(staff, formState) {
    const personOrgRole = [];

    for (const role of staff.personOrgRole) {
      if (Array.isArray(role.personDurations) && role.personDurations.find(x => x.isCurrent === true)) {
        if (role.orgRoleId === viewState.orgRoleId) {
          personOrgRole.push({
            personOrgRoleId: role.personOrgRoleId,
            personOrgRoleDurationId: viewState.personOrgRoleDurationId,
            orgRoleId: role.orgRoleId,
            orgRoleName: role.orgRoleName,
            personOrgRoleFields: [
              { 
                fieldName: ORG_ROLE_FIELD_PAID_OR_VOLUNTEER_FIELD_NAME,
                orgRoleFieldId: viewState.orgRoleFieldId,
                fieldValue: formState.paidOrVolunteerName
              }
            ],
          });
        } else {
          personOrgRole.push({
            orgRoleId: role.orgRoleId,
            orgRoleName: role.orgRoleName,
            personOrgRoleId: role.personOrgRoleId,
            personOrgRoleDurationId: role.personDurations.find(x => x.isCurrent === true).personOrgRoleDurationId
          });
        }
      }
    }

    return {
      personId: staff.personId,
      personOrgRole
    };
  };

  function getInitialFormState() {
    return {
      paidOrVolunteerId: Constants.DEFAULT_ID,
      paidOrVolunteerName: ''
    };
  };

  function getInitialViewState() {
    return {
      orgRoleName: '',
      orgRoleId: '',
      memberName: '',
      memberId: '',
      personId: '',
      personOrgRoleDurationId: Constants.DEFAULT_ID,
      orgRoleFieldId: Constants.DEFAULT_ID,
      effectiveDate: '',
      expirationDate: '',
      activeUserOrgUnitId: '',
      targetPerson: {}
    };
  };

  return {
    isLoading: memberAdministrationLscStaffState.isArrayLoading || environmentVariableState.isLoading || isSubmitting,
    isSaving: memberAdministrationLscStaffState.isSaving,
    formState,
    errorState,
    viewState,
    onValueTextPairChanged,
    handleSubmit,
    onCancelClicked,
    onSaveClicked,
    paidOrVolunteerOptions: [
      { id: Constants.DEFAULT_ID, name: "--" },
      { id: 1, name: "Paid" },
      { id: 2, name: "Volunteer" }
    ]
  };
};

export default useEditLSCStaffMember;