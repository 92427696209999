
import validate from "./LearnToSwimVoucherFormValidation";
import useForm from "../../../../common/utils/UseForm";
import Constants from "../../../../common/utils/Constants";

const INITIAL_FORM_STATE = {
  firstName: '',
  lastName: '',
  postalCode: '',
  birthDate: Constants.BLANK_DATE_STRING,
  competitionGenderTypeId: Constants.DEFAULT_ID,
  email: ''
};

const competitionGenderOptions = [
  { id: Constants.DEFAULT_ID, name: "--", value: '' },
  { id: 1, name: "Male", value: 1 },
  { id: 2, name: "Female", value: 2 }
];

const useLearnToSwimVoucherForm = (onSubmitFormCallback) => {
  const { formState, errorState, updateFormState, onValueTextPairChanged, handleSubmit
  } = useForm(INITIAL_FORM_STATE, onSubmitFormCallback, validate);

  return {
    formState,
    errorState,
    onFormValueChanged: updateFormState,
    onValueTextPairChanged,
    handleSubmit,
    competitionGenderOptions
  };
}

export default useLearnToSwimVoucherForm;