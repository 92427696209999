import { useContext, useEffect } from 'react';

import { PreCompInstructorStudentRatioStateContext } from './PreCompInstructorStudentRatioContextProvider';
import PreCompInstructorStudentRatioData from './PreCompInstructorStudentRatioData';

const usePreCompInstructorStudentRatioData = () => {
  const [preCompInstructorStudentRatioState, setPreCompInstructorStudentRatioState] = useContext(PreCompInstructorStudentRatioStateContext);

  const getInstructorStudentRatios = () => { PreCompInstructorStudentRatioData.getPreCompInstructorStudentRatios(preCompInstructorStudentRatioState, setPreCompInstructorStudentRatioState); };

  useEffect(() => {
    if (preCompInstructorStudentRatioState.isArrayLoading !== true && preCompInstructorStudentRatioState.isArrayLoaded !== true) {
      getInstructorStudentRatios(preCompInstructorStudentRatioState, setPreCompInstructorStudentRatioState);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    preCompInstructorStudentRatioState,
    getInstructorStudentRatios
  };
};

export default usePreCompInstructorStudentRatioData;