import { Fragment } from 'react';
import SGColumnHeader from '../../../../common/components/grids/SGColumnHeader';

import EditIcon from '../../../../common/components/icons/EditIcon';
import HideIcon from '../../../../common/components/icons/HideIcon';
import ShowIcon from '../../../../common/components/icons/ShowIcon';
import Checkbox from '../../../../common/components/checkboxes/Checkbox';

import { formatDate } from '../../../../common/utils/DateFunctions';
import useSortableGrid from '../../../../common/utils/UseSortableGrid';
import SGConstants from '../../../../common/utils/SGConstants';

import global from '../../../../common/components/GlobalStyle.module.css';

const DetailTableRow = ({ role }) => {
  return (
    <tr>
      <td>{role.orgRoleName}</td>
      <td>{role.registrationExpirationDate ? formatDate(role.registrationExpirationDate) : ''}</td>
      <td>{role.isInGoodStandingExpirationDate ? formatDate(role.isInGoodStandingExpirationDate) : ''}</td>
    </tr>
  )
};

const DetailTable = ({ roles }) => (
  <table className={global.DetailTable}>
    <thead>
      <tr>
        <th>Member Type</th>
        <th>Registration Expiration Date</th>
        <th>Good Standing Expiration Date</th>
      </tr>
    </thead>
    <tbody>
      {roles.map((role, i) =>
        <DetailTableRow role={role} key={i} />)}
    </tbody>
  </table>
);

const GridRow = ({ nonAthlete, onEditNonAthlete, expandedId, onClick, onVendorSyncCheckbox }) => {
  return (
    <Fragment>
      <tr>
        <td>
          {(nonAthlete.firstName || nonAthlete.preferredName || nonAthlete.lastName)
            ? `${nonAthlete.preferredName ? nonAthlete.preferredName : nonAthlete.firstName || ''} ${nonAthlete.lastName || ''}`
            : ''
          }
        </td>
        <td>{nonAthlete.birthDate ? formatDate(nonAthlete.birthDate) : ''}</td>
        <td>{nonAthlete.orgRoleName || ''}</td>
        <td>
          <Checkbox
            label=""
            name="syncMember"
            onChange={(e) => { onVendorSyncCheckbox(e, nonAthlete.memberId) }}
            checked={nonAthlete.syncMember}
          />
        </td>
        <td>
          {onEditNonAthlete &&
            <button className={global.IconButtonMargin}
              type="button"
              onClick={() => onEditNonAthlete(nonAthlete.personId)}>
              <EditIcon />
            </button>
          }
          <button
            className={global.IconButtonMargin}
            type="button"
            onClick={(e) => onClick(e, nonAthlete.personId)}>
            {expandedId === nonAthlete.personId ? <HideIcon /> : <ShowIcon />}
          </button>
        </td>
      </tr>
      {Array.isArray(nonAthlete.goodStandingOrgRole) && nonAthlete.goodStandingOrgRole.length > 0 &&
        expandedId === nonAthlete.personId &&
        <tr className={global.Expanded}>
          <td colSpan="7">
            <DetailTable roles={nonAthlete.goodStandingOrgRole} />
          </td>
        </tr>}
    </Fragment>
  );
};

const ClubNonAthleteRosterGridLarge = ({ gridData, isLoading, onEditNonAthlete, expandedId, onClick, onVendorSyncCheckbox }) => {
  const {
    sortableGridState,
    onColumnClicked
  } = useSortableGrid(gridData);

  return (
    <table className={[global.UsasTable, 'visible-md visible-lg'].join(' ')}>
      <thead>
        <tr>
          <SGColumnHeader sortableGridState={sortableGridState} columnLabel={'Name'} columnField={'lastName'}
            sortType={SGConstants.SORT_TYPE_ALPHABETICAL} onColumnClicked={onColumnClicked} />
          <SGColumnHeader sortableGridState={sortableGridState} columnLabel={'Birth Date'} columnField={'birthDate'}
            sortType={SGConstants.SORT_TYPE_DATE} onColumnClicked={onColumnClicked} />
          <SGColumnHeader sortableGridState={sortableGridState} columnLabel={'Member Type(s)'} columnField={'orgRoleName'}
            sortType={SGConstants.SORT_TYPE_ALPHABETICAL} onColumnClicked={onColumnClicked} />
          <th>Sync</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {isLoading === true
          ? <tr><td colSpan="4">Loading...</td></tr>
          : Array.isArray(sortableGridState.sortedGridData) && sortableGridState.sortedGridData.length > 0
            ? sortableGridState.sortedGridData.map((nonAthlete, i) => 
            <GridRow key={i} 
              nonAthlete={nonAthlete} 
              onEditNonAthlete={onEditNonAthlete} 
              expandedId={expandedId} 
              onClick={onClick} 
              onVendorSyncCheckbox={onVendorSyncCheckbox} />)
            : <tr><td colSpan="4">No Non Athletes</td></tr>
        }
      </tbody>
    </table>
  )
};

export default ClubNonAthleteRosterGridLarge;