import useClubOwnerTypeData from '../../state/clubOwnerType/UseClubOwnerTypeData';

const useClubOwnerTypeDropdown = () => {
  const { clubOwnerTypeState } = useClubOwnerTypeData();

  return {
    clubOwnerTypeState
  };
};

export default useClubOwnerTypeDropdown;