import useSisenseEventMultiselect from "./UseSisenseEventMultiselect";

import MultiSelect from "../../../../common/components/multiSelect/MultiSelect";

import Constants from "../../../../common/utils/Constants";

import global from '../../../../common/components/GlobalStyle.module.css';

const SisenseEventMultiselect = ({ label, name, value, error, message, onChange, courseName, excludeRelays, excludeIndividualEvents }) => {
  const { eventOptions, isLoading } = useSisenseEventMultiselect(courseName, excludeRelays, excludeIndividualEvents);

  return isLoading === true
    ? <span className={global.LoadingMsg}>{Constants.LOADING_MSG}</span>
    : (
      <MultiSelect
        label={label}
        name={name}
        value={value}
        options={eventOptions.options}
        error={error}
        message={message}
        isLoading={isLoading === true}
        onChange={onChange} />
    );
};

export default SisenseEventMultiselect;