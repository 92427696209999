import { useEffect, useState } from 'react';

import validate from './SearchFacilityValidation';

import useForm from '../../../utils/UseForm'
import useFacilityData from '../../../state/facility/UseFacilityData';
import useSearchFacilityData from '../../../state/searchFacility/UseSearchFacilityData';
import useStatesCombobox from '../../comboboxes/statesCombobox/UseStatesCombobox';
import useCountryCombobox from '../../comboboxes/countryCombobox/UseCountryCombobox';

const sortByOptions = [
  { id: 1, name: "Facility Name (A-Z)", value: 'facilityName' },
];
const MODAL_TITLE = 'No Results - Add a New Facility?';
const ALTERNATE_MODAL_TITLE = `Can't Find Facility - Add a New Facility?`;

const useSearchFacility = () => {
  const { facilityState, facilityFiltersState, searchFacilities, getFacility } = useFacilityData();
  const { searchFacilityState, setSearchFacilityState } = useSearchFacilityData();
  const { getStateNameById } = useStatesCombobox(false, true, false, false);
  const { getCountryNameById } = useCountryCombobox();
  const { formState, errorState, handleSubmit, updateFormState, setFormState, setErrors, onValueTextPairChanged,
    setIsDirty, isDirty } = useForm(getInitialFormState, submitFormCallback, validate);
  const [modalState, setModalState] = useState(getInitialModalState());

  const clearForm = () => {
    setFormState(getInitialFormState());
    setErrors({});
  };

  const onFiltersToggle = () => {
    setSearchFacilityState({ ...searchFacilityState, showFilters: !searchFacilityState.showFilters });
    setIsDirty(true);
  }

  const onOpenModal = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    setModalState({ ...modalState, modalTitle: ALTERNATE_MODAL_TITLE, displayPopUp: true, noResults: false });
  };

  const onModalCanceled = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    setIsDirty(true);
    setModalState(getInitialModalState());
  };

  useEffect(() => {
    if (Object.keys(facilityFiltersState.filterObject).length > 0) {
      const oldSearchValues = JSON.parse(JSON.stringify(facilityFiltersState.filterObject));
      let sortByIndex = undefined;
      for (let i = 0; i < sortByOptions.length; i++) {
        if (facilityFiltersState.sortBy === sortByOptions[i].value) {
          sortByIndex = i;
          break;
        }
      }

      setFormState({
        ...formState,
        ...oldSearchValues,
        stateName: oldSearchValues.stateCode ? getStateNameById(oldSearchValues.stateCode) : '',
        countryName: oldSearchValues.country ? getCountryNameById(oldSearchValues.country) : '',
        sortById: sortByIndex !== undefined ? sortByOptions[sortByIndex].id : sortByOptions[0].id,
        sortByName: sortByIndex !== undefined ? sortByOptions[sortByIndex].name : sortByOptions[0].name
      });
      setIsDirty(true);

      if (facilityState.isArrayLoading === false) {
        searchFacilities(facilityFiltersState.filterObject, facilityFiltersState.sortBy || '');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (facilityState.isArrayLoading === false && facilityState.isArrayLoaded === true
      && facilityState.arrayData.length === 0) {
      setModalState({ ...modalState, displayPopUp: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [facilityState]);

  useEffect(() => {
    if (searchFacilityState.showFilters === true && isDirty === false) {
      setIsDirty(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchFacilityState.showFilters, isDirty]);

  function submitFormCallback(formState) {
    setSearchFacilityState({ ...searchFacilityState, tryRedirect: false });

    let sortBy = undefined;
    for (const sortByOption of sortByOptions) {
      if (formState.sortBy === sortByOption.id) {
        sortBy = sortByOption.value;
        break;
      }

      if (facilityState.isArrayLoading === false) {
        const filterObject = createSearchFilterObj(formState);
        searchFacilities(filterObject, sortBy || '', {});
      }
    };
  }

  function createSearchFilterObj(formState) {
    return {
      facilityName: formState.facilityName.trim() || undefined,
      city: formState.city.trim() || undefined,
      stateCode: formState.stateName !== '' ? formState.stateCode : undefined,
      postalCode: formState.postalCode.trim() || undefined,
      country: formState.country.trim() || undefined
    };
  };

  function getInitialFormState() {
    return {
      facilityName: '',
      city: '',
      stateCode: '',
      stateName: '',
      postalCode: '',
      sortById: sortByOptions[0].id,
      sortByName: sortByOptions[0].name,
      country: '',
      countryName: ''
    };
  };

  function getInitialModalState() {
    return {
      modalTitle: MODAL_TITLE,
      displayPopUp: false,
      noResults: true
    }
  };

  return {
    formState,
    errorState,
    facilityState,
    searchFacilityState,
    setSearchFacilityState,
    modalState,
    onOpenModal,
    onModalCanceled,
    clearForm,
    onFormValueChanged: updateFormState,
    onValueTextPairChanged,
    handleSubmit,
    setIsDirty,
    sortByOptions,
    onFiltersToggle,
    getFacility
  };
};

export default useSearchFacility;