import { BASIC_INITIAL_STATE } from '../../../common/utils/HttpHelper';

import getClubRegistrationLinkByOrgUnitId from './GetClubRegistrationLinkOrgUnitIdData';
import putClubRegistrationLinkOrgUnitId from './PutClubRegistrationLinkOrgUnitIdData';

const INITIAL_STATE = {
  ...BASIC_INITIAL_STATE
};

const ClubRegistrationLinkOrgUnitIdData = {
  INITIAL_STATE,
  getClubRegistrationLinkByOrgUnitId,
  putClubRegistrationLinkOrgUnitId
};

export default ClubRegistrationLinkOrgUnitIdData;