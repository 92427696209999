import type { Dimension, DateDimension, Attribute, DataSourceInfo } from '@sisense/sdk-data';

import { createAttribute, createDateDimension, createDimension } from '@sisense/sdk-data';

export const DataSource: DataSourceInfo = {
    title: 'USA Swimming Times Elasticube',
    type: 'elasticube',
};

interface AgeDimension extends Dimension {
    AgeGroup: Attribute;
    AgeGroup1: Attribute;
    AgeGroup2: Attribute;
    AgeKey: Attribute;
    AgeRange1: Attribute;
    AgeRange2: Attribute;
    IMXAgeRange: Attribute;
    VccAgeRange: Attribute;
    ModifiedDatetime: DateDimension;
}
export const Age = createDimension({
    name: 'Age',
    AgeGroup: createAttribute({
        name: 'AgeGroup',
        type: 'text-attribute',
        expression: '[Age.AgeGroup]',
    }),
    AgeGroup1: createAttribute({
        name: 'AgeGroup1',
        type: 'text-attribute',
        expression: '[Age.AgeGroup1]',
    }),
    AgeGroup2: createAttribute({
        name: 'AgeGroup2',
        type: 'text-attribute',
        expression: '[Age.AgeGroup2]',
    }),
    AgeKey: createAttribute({
        name: 'AgeKey',
        type: 'numeric-attribute',
        expression: '[Age.AgeKey]',
    }),
    AgeRange1: createAttribute({
        name: 'AgeRange1',
        type: 'text-attribute',
        expression: '[Age.AgeRange1]',
    }),
    AgeRange2: createAttribute({
        name: 'AgeRange2',
        type: 'text-attribute',
        expression: '[Age.AgeRange2]',
    }),
    IMXAgeRange: createAttribute({
        name: 'IMXAgeRange',
        type: 'text-attribute',
        expression: '[Age.IMXAgeRange]',
    }),
    VccAgeRange: createAttribute({
        name: 'VccAgeRange',
        type: 'text-attribute',
        expression: '[Age.VccAgeRange]',
    }),
    ModifiedDatetime: createDateDimension({
        name: 'ModifiedDatetime',
        expression: '[Age.ModifiedDatetime (Calendar)]',
    }),
}) as AgeDimension;

interface AgeGroupDimension extends Dimension {
    AgeGroupDesc: Attribute;
    AgeGroupKey: Attribute;
    MaxAge: Attribute;
    MinAge: Attribute;
    RecordAgeGroupId: Attribute;
    ModifiedDatetime: DateDimension;
}
export const AgeGroup = createDimension({
    name: 'AgeGroup',
    AgeGroupDesc: createAttribute({
        name: 'AgeGroupDesc',
        type: 'text-attribute',
        expression: '[AgeGroup.AgeGroupDesc]',
    }),
    AgeGroupKey: createAttribute({
        name: 'AgeGroupKey',
        type: 'numeric-attribute',
        expression: '[AgeGroup.AgeGroupKey]',
    }),
    MaxAge: createAttribute({
        name: 'MaxAge',
        type: 'numeric-attribute',
        expression: '[AgeGroup.MaxAge]',
    }),
    MinAge: createAttribute({
        name: 'MinAge',
        type: 'numeric-attribute',
        expression: '[AgeGroup.MinAge]',
    }),
    RecordAgeGroupId: createAttribute({
        name: 'RecordAgeGroupId',
        type: 'numeric-attribute',
        expression: '[AgeGroup.RecordAgeGroupId]',
    }),
    ModifiedDatetime: createDateDimension({
        name: 'ModifiedDatetime',
        expression: '[AgeGroup.ModifiedDatetime (Calendar)]',
    }),
}) as AgeGroupDimension;

interface EventCompetitionCategoryDimension extends Dimension {
    EventCompetitionCategoryKey: Attribute;
    TypeCode: Attribute;
    TypeName: Attribute;
}
export const EventCompetitionCategory = createDimension({
    name: 'EventCompetitionCategory',
    EventCompetitionCategoryKey: createAttribute({
        name: 'EventCompetitionCategoryKey',
        type: 'numeric-attribute',
        expression: '[EventCompetitionCategory.EventCompetitionCategoryKey]',
    }),
    TypeCode: createAttribute({
        name: 'TypeCode',
        type: 'text-attribute',
        expression: '[EventCompetitionCategory.TypeCode]',
    }),
    TypeName: createAttribute({
        name: 'TypeName',
        type: 'text-attribute',
        expression: '[EventCompetitionCategory.TypeName]',
    }),
}) as EventCompetitionCategoryDimension;

interface MeetDimension extends Dimension {
    Final: Attribute;
    LCM: Attribute;
    MeetId: Attribute;
    MeetKey: Attribute;
    MeetName: Attribute;
    Prelim: Attribute;
    SCM: Attribute;
    SCY: Attribute;
    Semi: Attribute;
}
export const Meet = createDimension({
    name: 'Meet',
    Final: createAttribute({
        name: 'Final',
        type: 'numeric-attribute',
        expression: '[Meet.Final]',
    }),
    LCM: createAttribute({
        name: 'LCM',
        type: 'text-attribute',
        expression: '[Meet.LCM]',
    }),
    MeetId: createAttribute({
        name: 'MeetId',
        type: 'numeric-attribute',
        expression: '[Meet.MeetId]',
    }),
    MeetKey: createAttribute({
        name: 'MeetKey',
        type: 'numeric-attribute',
        expression: '[Meet.MeetKey]',
    }),
    MeetName: createAttribute({
        name: 'MeetName',
        type: 'text-attribute',
        expression: '[Meet.MeetName]',
    }),
    Prelim: createAttribute({
        name: 'Prelim',
        type: 'numeric-attribute',
        expression: '[Meet.Prelim]',
    }),
    SCM: createAttribute({
        name: 'SCM',
        type: 'text-attribute',
        expression: '[Meet.SCM]',
    }),
    SCY: createAttribute({
        name: 'SCY',
        type: 'text-attribute',
        expression: '[Meet.SCY]',
    }),
    Semi: createAttribute({
        name: 'Semi',
        type: 'numeric-attribute',
        expression: '[Meet.Semi]',
    }),
}) as MeetDimension;

interface OrgUnitDimension extends Dimension {
    Level1Code: Attribute;
    Level1Name: Attribute;
    Level2Code: Attribute;
    Level2Name: Attribute;
    Level3Code: Attribute;
    Level3Name: Attribute;
    Level4Code: Attribute;
    Level4Name: Attribute;
    LscClub: Attribute;
    OrganizationCode: Attribute;
    OrganizationName: Attribute;
    OrgLevel: Attribute;
    OrgUnitKey: Attribute;
}
export const OrgUnit = createDimension({
    name: 'OrgUnit',
    Level1Code: createAttribute({
        name: 'Level1Code',
        type: 'text-attribute',
        expression: '[OrgUnit.Level1Code]',
    }),
    Level1Name: createAttribute({
        name: 'Level1Name',
        type: 'text-attribute',
        expression: '[OrgUnit.Level1Name]',
    }),
    Level2Code: createAttribute({
        name: 'Level2Code',
        type: 'text-attribute',
        expression: '[OrgUnit.Level2Code]',
    }),
    Level2Name: createAttribute({
        name: 'Level2Name',
        type: 'text-attribute',
        expression: '[OrgUnit.Level2Name]',
    }),
    Level3Code: createAttribute({
        name: 'Level3Code',
        type: 'text-attribute',
        expression: '[OrgUnit.Level3Code]',
    }),
    Level3Name: createAttribute({
        name: 'Level3Name',
        type: 'text-attribute',
        expression: '[OrgUnit.Level3Name]',
    }),
    Level4Code: createAttribute({
        name: 'Level4Code',
        type: 'text-attribute',
        expression: '[OrgUnit.Level4Code]',
    }),
    Level4Name: createAttribute({
        name: 'Level4Name',
        type: 'text-attribute',
        expression: '[OrgUnit.Level4Name]',
    }),
    LscClub: createAttribute({
        name: 'LscClub',
        type: 'text-attribute',
        expression: '[OrgUnit.LscClub]',
    }),
    OrganizationCode: createAttribute({
        name: 'OrganizationCode',
        type: 'text-attribute',
        expression: '[OrgUnit.OrganizationCode]',
    }),
    OrganizationName: createAttribute({
        name: 'OrganizationName',
        type: 'text-attribute',
        expression: '[OrgUnit.OrganizationName]',
    }),
    OrgLevel: createAttribute({
        name: 'OrgLevel',
        type: 'text-attribute',
        expression: '[OrgUnit.OrgLevel]',
    }),
    OrgUnitKey: createAttribute({
        name: 'OrgUnitKey',
        type: 'numeric-attribute',
        expression: '[OrgUnit.OrgUnitKey]',
    }),
}) as OrgUnitDimension;

interface PersonDimension extends Dimension {
    CompetitionCategoryKey: Attribute;
    EligibleToReprTeamUSA: Attribute;
    FullName: Attribute;
    IsForeign: Attribute;
    MemberId: Attribute;
    PersonId: Attribute;
    PersonKey: Attribute;
    USCitizen: Attribute;
    BirthDate: DateDimension;
}
export const Person = createDimension({
    name: 'Person',
    CompetitionCategoryKey: createAttribute({
        name: 'CompetitionCategoryKey',
        type: 'numeric-attribute',
        expression: '[Person.CompetitionCategoryKey]',
    }),
    EligibleToReprTeamUSA: createAttribute({
        name: 'EligibleToReprTeamUSA',
        type: 'text-attribute',
        expression: '[Person.EligibleToReprTeamUSA]',
    }),
    FullName: createAttribute({
        name: 'FullName',
        type: 'text-attribute',
        expression: '[Person.FullName]',
    }),
    IsForeign: createAttribute({
        name: 'IsForeign',
        type: 'numeric-attribute',
        expression: '[Person.IsForeign]',
    }),
    MemberId: createAttribute({
        name: 'MemberId',
        type: 'text-attribute',
        expression: '[Person.MemberId]',
    }),
    PersonId: createAttribute({
        name: 'PersonId',
        type: 'numeric-attribute',
        expression: '[Person.PersonId]',
    }),
    PersonKey: createAttribute({
        name: 'PersonKey',
        type: 'numeric-attribute',
        expression: '[Person.PersonKey]',
    }),
    USCitizen: createAttribute({
        name: 'USCitizen',
        type: 'text-attribute',
        expression: '[Person.USCitizen]',
    }),
    BirthDate: createDateDimension({
        name: 'BirthDate',
        expression: '[Person.BirthDate (Calendar)]',
    }),
}) as PersonDimension;

interface RecordListDimension extends Dimension {
    OrgUnitKey: Attribute;
    RecordGroupName: Attribute;
    RecordListKey: Attribute;
    RecordListName: Attribute;
    SortOrder: Attribute;
}
export const RecordList = createDimension({
    name: 'RecordList',
    OrgUnitKey: createAttribute({
        name: 'OrgUnitKey',
        type: 'numeric-attribute',
        expression: '[RecordList.OrgUnitKey]',
    }),
    RecordGroupName: createAttribute({
        name: 'RecordGroupName',
        type: 'text-attribute',
        expression: '[RecordList.RecordGroupName]',
    }),
    RecordListKey: createAttribute({
        name: 'RecordListKey',
        type: 'numeric-attribute',
        expression: '[RecordList.RecordListKey]',
    }),
    RecordListName: createAttribute({
        name: 'RecordListName',
        type: 'text-attribute',
        expression: '[RecordList.RecordListName]',
    }),
    SortOrder: createAttribute({
        name: 'SortOrder',
        type: 'numeric-attribute',
        expression: '[RecordList.SortOrder]',
    }),
}) as RecordListDimension;

interface RecordsCustomDimension extends Dimension {
    CountAsRecord: Attribute;
    EventCompetitionCategoryKey: Attribute;
    FullName: Attribute;
    MeetCity: Attribute;
    MeetKey: Attribute;
    OrgUnitKey: Attribute;
    PersonKey: Attribute;
    RecordAgeGroupKey: Attribute;
    RecordListKey: Attribute;
    RecordsKey: Attribute;
    SessionKey: Attribute;
    SwimEventKey: Attribute;
    SwimmerCountry: Attribute;
    SwimTime: Attribute;
    SwimTimeSeconds: Attribute;
    SwimDate: DateDimension;
}
export const RecordsCustom = createDimension({
    name: 'RecordsCustom',
    CountAsRecord: createAttribute({
        name: 'CountAsRecord',
        type: 'numeric-attribute',
        expression: '[RecordsCustom.CountAsRecord]',
    }),
    EventCompetitionCategoryKey: createAttribute({
        name: 'EventCompetitionCategoryKey',
        type: 'numeric-attribute',
        expression: '[RecordsCustom.EventCompetitionCategoryKey]',
    }),
    FullName: createAttribute({
        name: 'FullName',
        type: 'text-attribute',
        expression: '[RecordsCustom.FullName]',
    }),
    MeetCity: createAttribute({
        name: 'MeetCity',
        type: 'text-attribute',
        expression: '[RecordsCustom.MeetCity]',
    }),
    MeetKey: createAttribute({
        name: 'MeetKey',
        type: 'numeric-attribute',
        expression: '[RecordsCustom.MeetKey]',
    }),
    OrgUnitKey: createAttribute({
        name: 'OrgUnitKey',
        type: 'numeric-attribute',
        expression: '[RecordsCustom.OrgUnitKey]',
    }),
    PersonKey: createAttribute({
        name: 'PersonKey',
        type: 'numeric-attribute',
        expression: '[RecordsCustom.PersonKey]',
    }),
    RecordAgeGroupKey: createAttribute({
        name: 'RecordAgeGroupKey',
        type: 'numeric-attribute',
        expression: '[RecordsCustom.RecordAgeGroupKey]',
    }),
    RecordListKey: createAttribute({
        name: 'RecordListKey',
        type: 'numeric-attribute',
        expression: '[RecordsCustom.RecordListKey]',
    }),
    RecordsKey: createAttribute({
        name: 'RecordsKey',
        type: 'numeric-attribute',
        expression: '[RecordsCustom.RecordsKey]',
    }),
    SessionKey: createAttribute({
        name: 'SessionKey',
        type: 'numeric-attribute',
        expression: '[RecordsCustom.SessionKey]',
    }),
    SwimEventKey: createAttribute({
        name: 'SwimEventKey',
        type: 'numeric-attribute',
        expression: '[RecordsCustom.SwimEventKey]',
    }),
    SwimmerCountry: createAttribute({
        name: 'SwimmerCountry',
        type: 'text-attribute',
        expression: '[RecordsCustom.SwimmerCountry]',
    }),
    SwimTime: createAttribute({
        name: 'SwimTime',
        type: 'text-attribute',
        expression: '[RecordsCustom.SwimTime]',
    }),
    SwimTimeSeconds: createAttribute({
        name: 'SwimTimeSeconds',
        type: 'numeric-attribute',
        expression: '[RecordsCustom.SwimTimeSeconds]',
    }),
    SwimDate: createDateDimension({
        name: 'SwimDate',
        expression: '[RecordsCustom.SwimDate (Calendar)]',
    }),
}) as RecordsCustomDimension;

interface RelayTimesDimension extends Dimension {
    EventCompetitionCategoryKey: Attribute;
    FinishPosition: Attribute;
    FullName: Attribute;
    MeetKey: Attribute;
    OrgUnitKey: Attribute;
    RelayAgeGroupKey: Attribute;
    SeasonCalendarKey: Attribute;
    SessionKey: Attribute;
    SingleSeasonBest: Attribute;
    SortKey: Attribute;
    SwimEventKey: Attribute;
    SwimTimeFormatted: Attribute;
    SwimTimeSeconds: Attribute;
    TimeStandardKey: Attribute;
    UsasSwimTimeKey: Attribute;
}
export const RelayTimes = createDimension({
    name: 'RelayTimes',
    EventCompetitionCategoryKey: createAttribute({
        name: 'EventCompetitionCategoryKey',
        type: 'numeric-attribute',
        expression: '[RelayTimes.EventCompetitionCategoryKey]',
    }),
    FinishPosition: createAttribute({
        name: 'FinishPosition',
        type: 'numeric-attribute',
        expression: '[RelayTimes.FinishPosition]',
    }),
    FullName: createAttribute({
        name: 'FullName',
        type: 'text-attribute',
        expression: '[RelayTimes.FullName]',
    }),
    MeetKey: createAttribute({
        name: 'MeetKey',
        type: 'numeric-attribute',
        expression: '[RelayTimes.MeetKey]',
    }),
    OrgUnitKey: createAttribute({
        name: 'OrgUnitKey',
        type: 'numeric-attribute',
        expression: '[RelayTimes.OrgUnitKey]',
    }),
    RelayAgeGroupKey: createAttribute({
        name: 'RelayAgeGroupKey',
        type: 'numeric-attribute',
        expression: '[RelayTimes.RelayAgeGroupKey]',
    }),
    SeasonCalendarKey: createAttribute({
        name: 'SeasonCalendarKey',
        type: 'numeric-attribute',
        expression: '[RelayTimes.SeasonCalendarKey]',
    }),
    SessionKey: createAttribute({
        name: 'SessionKey',
        type: 'numeric-attribute',
        expression: '[RelayTimes.SessionKey]',
    }),
    SingleSeasonBest: createAttribute({
        name: 'SingleSeasonBest',
        type: 'numeric-attribute',
        expression: '[RelayTimes.SingleSeasonBest]',
    }),
    SortKey: createAttribute({
        name: 'SortKey',
        type: 'text-attribute',
        expression: '[RelayTimes.SortKey]',
    }),
    SwimEventKey: createAttribute({
        name: 'SwimEventKey',
        type: 'numeric-attribute',
        expression: '[RelayTimes.SwimEventKey]',
    }),
    SwimTimeFormatted: createAttribute({
        name: 'SwimTimeFormatted',
        type: 'text-attribute',
        expression: '[RelayTimes.SwimTimeFormatted]',
    }),
    SwimTimeSeconds: createAttribute({
        name: 'SwimTimeSeconds',
        type: 'numeric-attribute',
        expression: '[RelayTimes.SwimTimeSeconds]',
    }),
    TimeStandardKey: createAttribute({
        name: 'TimeStandardKey',
        type: 'numeric-attribute',
        expression: '[RelayTimes.TimeStandardKey]',
    }),
    UsasSwimTimeKey: createAttribute({
        name: 'UsasSwimTimeKey',
        type: 'numeric-attribute',
        expression: '[RelayTimes.UsasSwimTimeKey]',
    }),
}) as RelayTimesDimension;

interface SeasonCalendarDimension extends Dimension {
    SeasonCalendarKey: Attribute;
    SeasonYear: Attribute;
    SeasonYearDesc: Attribute;
    CalendarDate: DateDimension;
}
export const SeasonCalendar = createDimension({
    name: 'SeasonCalendar',
    SeasonCalendarKey: createAttribute({
        name: 'SeasonCalendarKey',
        type: 'numeric-attribute',
        expression: '[SeasonCalendar.SeasonCalendarKey]',
    }),
    SeasonYear: createAttribute({
        name: 'SeasonYear',
        type: 'numeric-attribute',
        expression: '[SeasonCalendar.SeasonYear]',
    }),
    SeasonYearDesc: createAttribute({
        name: 'SeasonYearDesc',
        type: 'text-attribute',
        expression: '[SeasonCalendar.SeasonYearDesc]',
    }),
    CalendarDate: createDateDimension({
        name: 'CalendarDate',
        expression: '[SeasonCalendar.CalendarDate (Calendar)]',
    }),
}) as SeasonCalendarDimension;

interface SessionDimension extends Dimension {
    SessionKey: Attribute;
    SessionName: Attribute;
    SessionOrder: Attribute;
}
export const Session = createDimension({
    name: 'Session',
    SessionKey: createAttribute({
        name: 'SessionKey',
        type: 'numeric-attribute',
        expression: '[Session.SessionKey]',
    }),
    SessionName: createAttribute({
        name: 'SessionName',
        type: 'text-attribute',
        expression: '[Session.SessionName]',
    }),
    SessionOrder: createAttribute({
        name: 'SessionOrder',
        type: 'numeric-attribute',
        expression: '[Session.SessionOrder]',
    }),
}) as SessionDimension;

interface StandardTypeDimension extends Dimension {
    ShowForRelays: Attribute;
    SortOrder: Attribute;
    StandardType: Attribute;
    TimeStandardKey: Attribute;
}
export const StandardType = createDimension({
    name: 'StandardType',
    ShowForRelays: createAttribute({
        name: 'ShowForRelays',
        type: 'numeric-attribute',
        expression: '[StandardType.ShowForRelays]',
    }),
    SortOrder: createAttribute({
        name: 'SortOrder',
        type: 'numeric-attribute',
        expression: '[StandardType.SortOrder]',
    }),
    StandardType: createAttribute({
        name: 'StandardType',
        type: 'text-attribute',
        expression: '[StandardType.StandardType]',
    }),
    TimeStandardKey: createAttribute({
        name: 'TimeStandardKey',
        type: 'numeric-attribute',
        expression: '[StandardType.TimeStandardKey]',
    }),
}) as StandardTypeDimension;

interface SwimEventDimension extends Dimension {
    CourseCode: Attribute;
    EventCode: Attribute;
    OrderedEventCode: Attribute;
    RaceType: Attribute;
    SwimEventKey: Attribute;
}
export const SwimEvent = createDimension({
    name: 'SwimEvent',
    CourseCode: createAttribute({
        name: 'CourseCode',
        type: 'text-attribute',
        expression: '[SwimEvent.CourseCode]',
    }),
    EventCode: createAttribute({
        name: 'EventCode',
        type: 'text-attribute',
        expression: '[SwimEvent.EventCode]',
    }),
    OrderedEventCode: createAttribute({
        name: 'OrderedEventCode',
        type: 'text-attribute',
        expression: '[SwimEvent.OrderedEventCode]',
    }),
    RaceType: createAttribute({
        name: 'RaceType',
        type: 'text-attribute',
        expression: '[SwimEvent.RaceType]',
    }),
    SwimEventKey: createAttribute({
        name: 'SwimEventKey',
        type: 'numeric-attribute',
        expression: '[SwimEvent.SwimEventKey]',
    }),
}) as SwimEventDimension;

interface TimeStandardDimension extends Dimension {
    StandardType: Attribute;
    TimeStandardKey: Attribute;
    TimeStandardName: Attribute;
}
export const TimeStandard = createDimension({
    name: 'TimeStandard',
    StandardType: createAttribute({
        name: 'StandardType',
        type: 'text-attribute',
        expression: '[TimeStandard.StandardType]',
    }),
    TimeStandardKey: createAttribute({
        name: 'TimeStandardKey',
        type: 'numeric-attribute',
        expression: '[TimeStandard.TimeStandardKey]',
    }),
    TimeStandardName: createAttribute({
        name: 'TimeStandardName',
        type: 'text-attribute',
        expression: '[TimeStandard.TimeStandardName]',
    }),
}) as TimeStandardDimension;

interface UsasSwimTimeDimension extends Dimension {
    AgeAtMeetKey: Attribute;
    CompetitionCategoryKey: Attribute;
    EventCompetitionCategoryKey: Attribute;
    FinishPosition: Attribute;
    FullName: Attribute;
    LifeTimeBest: Attribute;
    MeetKey: Attribute;
    OrgUnitKey: Attribute;
    PersonKey: Attribute;
    PowerPoints: Attribute;
    RelayAgeGroupKey: Attribute;
    SeasonBest: Attribute;
    SeasonCalendarKey: Attribute;
    SessionKey: Attribute;
    SingleLifetimeBest: Attribute;
    SingleSeasonBest: Attribute;
    SortKey: Attribute;
    SwimEventKey: Attribute;
    SwimTimeFormatted: Attribute;
    SwimTimeSeconds: Attribute;
    TimeStandardKey: Attribute;
    UsasSwimTimeKey: Attribute;
}
export const UsasSwimTime = createDimension({
    name: 'UsasSwimTime',
    AgeAtMeetKey: createAttribute({
        name: 'AgeAtMeetKey',
        type: 'numeric-attribute',
        expression: '[UsasSwimTime.AgeAtMeetKey]',
    }),
    CompetitionCategoryKey: createAttribute({
        name: 'CompetitionCategoryKey',
        type: 'numeric-attribute',
        expression: '[UsasSwimTime.CompetitionCategoryKey]',
    }),
    EventCompetitionCategoryKey: createAttribute({
        name: 'EventCompetitionCategoryKey',
        type: 'numeric-attribute',
        expression: '[UsasSwimTime.EventCompetitionCategoryKey]',
    }),
    FinishPosition: createAttribute({
        name: 'FinishPosition',
        type: 'numeric-attribute',
        expression: '[UsasSwimTime.FinishPosition]',
    }),
    FullName: createAttribute({
        name: 'FullName',
        type: 'text-attribute',
        expression: '[UsasSwimTime.FullName]',
    }),
    LifeTimeBest: createAttribute({
        name: 'LifeTimeBest',
        type: 'numeric-attribute',
        expression: '[UsasSwimTime.LifeTimeBest]',
    }),
    MeetKey: createAttribute({
        name: 'MeetKey',
        type: 'numeric-attribute',
        expression: '[UsasSwimTime.MeetKey]',
    }),
    OrgUnitKey: createAttribute({
        name: 'OrgUnitKey',
        type: 'numeric-attribute',
        expression: '[UsasSwimTime.OrgUnitKey]',
    }),
    PersonKey: createAttribute({
        name: 'PersonKey',
        type: 'numeric-attribute',
        expression: '[UsasSwimTime.PersonKey]',
    }),
    PowerPoints: createAttribute({
        name: 'PowerPoints',
        type: 'numeric-attribute',
        expression: '[UsasSwimTime.PowerPoints]',
    }),
    RelayAgeGroupKey: createAttribute({
        name: 'RelayAgeGroupKey',
        type: 'numeric-attribute',
        expression: '[UsasSwimTime.RelayAgeGroupKey]',
    }),
    SeasonBest: createAttribute({
        name: 'SeasonBest',
        type: 'numeric-attribute',
        expression: '[UsasSwimTime.SeasonBest]',
    }),
    SeasonCalendarKey: createAttribute({
        name: 'SeasonCalendarKey',
        type: 'numeric-attribute',
        expression: '[UsasSwimTime.SeasonCalendarKey]',
    }),
    SessionKey: createAttribute({
        name: 'SessionKey',
        type: 'numeric-attribute',
        expression: '[UsasSwimTime.SessionKey]',
    }),
    SingleLifetimeBest: createAttribute({
        name: 'SingleLifetimeBest',
        type: 'numeric-attribute',
        expression: '[UsasSwimTime.SingleLifetimeBest]',
    }),
    SingleSeasonBest: createAttribute({
        name: 'SingleSeasonBest',
        type: 'numeric-attribute',
        expression: '[UsasSwimTime.SingleSeasonBest]',
    }),
    SortKey: createAttribute({
        name: 'SortKey',
        type: 'text-attribute',
        expression: '[UsasSwimTime.SortKey]',
    }),
    SwimEventKey: createAttribute({
        name: 'SwimEventKey',
        type: 'numeric-attribute',
        expression: '[UsasSwimTime.SwimEventKey]',
    }),
    SwimTimeFormatted: createAttribute({
        name: 'SwimTimeFormatted',
        type: 'text-attribute',
        expression: '[UsasSwimTime.SwimTimeFormatted]',
    }),
    SwimTimeSeconds: createAttribute({
        name: 'SwimTimeSeconds',
        type: 'numeric-attribute',
        expression: '[UsasSwimTime.SwimTimeSeconds]',
    }),
    TimeStandardKey: createAttribute({
        name: 'TimeStandardKey',
        type: 'numeric-attribute',
        expression: '[UsasSwimTime.TimeStandardKey]',
    }),
    UsasSwimTimeKey: createAttribute({
        name: 'UsasSwimTimeKey',
        type: 'numeric-attribute',
        expression: '[UsasSwimTime.UsasSwimTimeKey]',
    }),
}) as UsasSwimTimeDimension;
