import { useState } from "react";

import ClubRosterVendorSyncData from "./ClubRosterVendorSyncData";

const useClubRosterVendorSyncData = () => {
  const [clubRosterVendorSyncState, setClubRosterVendorSyncState] = useState(ClubRosterVendorSyncData.INITIAL_STATE);
  const postClubRosterVendorSync = (memberSyncArray) => ClubRosterVendorSyncData.postClubRosterVendorSyncData(memberSyncArray, clubRosterVendorSyncState, setClubRosterVendorSyncState);

  return {
    clubRosterVendorSyncState,
    setClubRosterVendorSyncState,
    postClubRosterVendorSync
  };
};

export default useClubRosterVendorSyncData;