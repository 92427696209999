import { useState, useEffect } from "react";
import { useNavigate } from "../../../../common/wrappers/ReactRouterDom";

import NavLinks from "../utils/NavLinks";

import useLearnToSwimFacilitiesData from "../../../state/learnToSwimFacilities/UseLearnToSwimFacilitiesData";
import useLearnToSwimData from "../../../state/learnToSwim/UseLearnToSwimData";

const INITIAL_MODAL_STATE = {
  displayModal: false,
  facilityName: '',
};

const useLearnToSwimFacilitiesSearch = () => {
  const navigate = useNavigate();
  const { ltsProviderState } = useLearnToSwimData();
  const { ltsFacilitiesState, getLTSFacilities } = useLearnToSwimFacilitiesData();
  const [modalState, setModalState] = useState(INITIAL_MODAL_STATE);

  const onLinkFacilityClicked = (e, facilityId) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    const facilities = ltsFacilitiesState.arrayData;
    let dupFacility = null;

    for (const facility of facilities) {
      if (facility.facilityId === facilityId) {
        dupFacility = facility;
        break;
      }
    }

    if (dupFacility === null) {
      navigate(NavLinks.LEARN_TO_SWIM_FACILITIES_DETAIL, { state: { facilityId } });
    } else {
      setModalState({
        ...modalState,
        displayModal: true,
        facilityName: dupFacility.facility?.facilityName || ''
      });
    }
  };

  const onCancelClicked = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    navigate(NavLinks.LEARN_TO_SWIM_FACILITIES);
  };

  const onModalCancelClicked = () => {
    setModalState({ ...INITIAL_MODAL_STATE });
  };

  const onAddANewFacilityClicked = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    navigate(NavLinks.LEARN_TO_SWIM_FACILITIES_ADD);
  };

  useEffect(() => {
    if (ltsFacilitiesState.isArrayLoaded !== true) {
      const orgUnitId = ltsProviderState.objData?.orgUnitId;

      if (orgUnitId) {
        const getLTSFacilitiesPromise = getLTSFacilities(orgUnitId);

        if (getLTSFacilitiesPromise !== null) {
          getLTSFacilitiesPromise.catch((e) => {
            //TODO context error
          });
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ltsFacilitiesState.isArrayLoaded]);

  return {
    isLoading: ltsFacilitiesState.isArrayLoading,
    modalState,
    onLinkFacilityClicked,
    onCancelClicked,
    onModalCancelClicked,
    onAddANewFacilityClicked
  };
};

export default useLearnToSwimFacilitiesSearch;