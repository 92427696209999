import { useState } from 'react';

import LearnToSwimCurriculumData from './LearnToSwimCurriculumData';

import { BASIC_INITIAL_STATE } from '../../../common/utils/HttpHelper';

const INITIAL_LTS_CURRICULUM_STATE = {
  ...BASIC_INITIAL_STATE
};

const useLearnToSwimCurriculumFileData = () => {
  const [ltsCurriculumFileState, setLTSCurriculumFileState] = useState(INITIAL_LTS_CURRICULUM_STATE);

  const postLTSCurriculum = (curriculumFile) => {
    return LearnToSwimCurriculumData.postLTSCurriculumData(curriculumFile, ltsCurriculumFileState, setLTSCurriculumFileState);
  };

  return {
    ltsCurriculumFileState,
    setLTSCurriculumFileState,
    postLTSCurriculum
  };
};

export default useLearnToSwimCurriculumFileData;