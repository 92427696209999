import { useEffect } from 'react';
import { useNavigate } from '../../../../common/wrappers/ReactRouterDom';

import { navLinks } from '../UseMemberAdministration';

import useMemberAdministrationLscEmailData from '../../../../common/state/memberAdministration/lscEmail/UseMemberAdministrationLscEmailData';
import useSelectOrgUnitData from '../../../../common/state/selectOrgUnit/UseSelectOrgUnitData';

const useLSCRoleEmails = () => {
  const navigate = useNavigate();
  const { memberAdministrationLscRoleEmailsState, getLscRoleEmails, confirmSave } = useMemberAdministrationLscEmailData();
  const { selectOrgUnitState } = useSelectOrgUnitData();

  const onAddRoleEmail = () => {
    navigate(navLinks.LSC_EMAILS_ADD, { state: { activeUserOrgUnitId: selectOrgUnitState.orgUnitId } });
  };

  const onEditRoleEmail = (orgUnitOrgRoleFieldId) => {
    navigate(navLinks.LSC_EMAILS_EDIT, { state: { orgUnitOrgRoleFieldId, activeUserOrgUnitId: selectOrgUnitState.orgUnitId } });
  };

  useEffect(() => {
    if (selectOrgUnitState.orgUnitId) {
      if (memberAdministrationLscRoleEmailsState.isSaved === true) {
        confirmSave();
      } else {
        getLscRoleEmails(selectOrgUnitState.orgUnitId);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectOrgUnitState.orgUnitId, memberAdministrationLscRoleEmailsState.isSaved]);

  return {
    isLoading: memberAdministrationLscRoleEmailsState.isArrayLoading,
    memberAdministrationLscRoleEmailsState,
    onAddRoleEmail,
    onEditRoleEmail
  };
};

export default useLSCRoleEmails;