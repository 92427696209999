import React, { Fragment } from 'react';

import useLearnToSwimFacilitiesAdd from './UseLearnToSwimFacilitiesAdd';

import LearnToSwimFacilityAddForm from '../../learnToSwim/components/forms/LearnToSwimFacilityAddForm';

import FacilityDupCheckContent from '../../../../common/components/searches/searchFacility/addFacility/components/FacilityDupCheckContent';
import PopUpModal from '../../../../common/components/dialogs/PopUpModal';

import Constants from '../../../../common/utils/Constants';
import Headings from '../../../../common/components/headings/Headings';

const LearnToSwimFacilitiesAdd = () => {
  const {
    isLoading,
    state,
    dupsState,
    facilityState,
    onAddDupFacilityClicked,
    onCancelDupFacilityClicked,
    onSubmitFormCallback,
    onBackClicked
  } = useLearnToSwimFacilitiesAdd();

  return (
    <Fragment>
      <div className='row'>
        <div className='col-xs-12 usas-extra-top-margin'>
          <Headings.H3>Add Facility</Headings.H3>
        </div>
      </div>
      <LearnToSwimFacilityAddForm
        onSubmitFormCallback={onSubmitFormCallback}
        onSecondaryButtonClicked={onBackClicked} />
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={isLoading} />
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.SAVING_MSG}
        displayPopUp={facilityState.isSaving} />
      <PopUpModal
        title="Facility Duplicate Check"
        displayPopUp={dupsState.arrayData?.length > 0}>
        <FacilityDupCheckContent
          formState={state.facilityObj}
          dupsState={dupsState}
          onAddDupFacilityClicked={onAddDupFacilityClicked}
          onCancelDupFacilityClicked={onCancelDupFacilityClicked} />
      </PopUpModal>
    </Fragment>
  );
};

export default LearnToSwimFacilitiesAdd;