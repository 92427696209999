const NavLinks = {
  CLUB_REGISTRATION_ROOT: '/clubregistration',
  CLUB_REGISTRATION_SELECTION: '/clubregistration/selection',
  CLUB_REGISTRATION_ENTRY: '/clubregistration/entry',
  CLUB_REGISTRATION_ENTRY_NEW: '/clubregistration/entry/new',
  CLUB_REGISTRATION_DISCLAIMER: '/clubregistration/disclaimer',
  //CLUB_REGISTRATION_NEW_CLUB_SEARCH: '/clubregistration/newclubsearch',
  //CLUB_REGISTRATION_EXISTING_CLUB_RENEWAL: '/clubregistration/existingclubrenewal',
  //CLUB_REGISTRATION_EXISTING_CLUB_SEARCH: '/clubregistration/existingclubsearch',
  CLUB_REGISTRATION_INFO: '/clubregistration/info',
  CLUB_REGISTRATION_STAFF: '/clubregistration/staff',
  CLUB_REGISTRATION_STAFF_DETAIL: '/clubregistration/staff/detail',
  CLUB_REGISTRATION_TYPE: '/clubregistration/type',
  CLUB_REGISTRATION_LEGAL_ENTITY: '/clubregistration/legalentity',
  CLUB_REGISTRATION_LEGAL_ENTITY_DETAIL: '/clubregistration/legalentity/detail',
  CLUB_REGISTRATION_AFFILIATION: '/clubregistration/affiliation',
  CLUB_REGISTRATION_AFFILIATION_DETAIL: '/clubregistration/affiliation/detail',
  CLUB_REGISTRATION_SCREENING_POLICIES: '/clubregistration/screeningpolicies',
  //CLUB_REGISTRATION_PRECOMPETITIVE_PROGRAMS: '/clubregistration/precompetitiveprograms',
  //CLUB_REGISTRATION_PRECOMPETITIVE_SEARCH: '/clubregistration/precompetitiveprograms/search',
  CLUB_REGISTRATION_FACILITY: '/clubregistration/facility', 
  CLUB_REGISTRATION_FACILITY_DETAILS: '/clubregistration/facility/details',
  CLUB_REGISTRATION_FACILITY_SEARCH: '/clubregistration/facility/search',
  CLUB_REGISTRATION_FACILITY_ADD: '/clubregistration/facility/add',
  CLUB_REGISTRATION_FACILITY_POOL_DETAIL: '/clubregistration/facility/pool/detail',
  CLUB_REGISTRATION_MISSION_STATEMENT_BUDGET: '/clubregistration/missionstatementbudget',
  CLUB_REGISTRATION_EMERGENCY_PLAN: '/clubregistration/emergencyplan',
  CLUB_REGISTRATION_SUBMIT: '/clubregistration/submit'
};

export default NavLinks;