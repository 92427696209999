import { Fragment } from 'react';

import useSafeSportSearch from './UseSafeSportSearch';

import LscDropdown from '../../../../common/components/dropdowns/lscDropdown/LscDropdown';
import OrgUnitCombobox from '../../../../common/components/comboboxes/orgUnitCombobox/OrgUnitCombobox';
import PrimaryButton from '../../../../common/components/buttons/PrimaryButton';
import SecondaryButton from '../../../../common/components/buttons/SecondaryButton';
import Headings from '../../../../common/components/headings/Headings';

const SSRPSearchForm = ({ formState, errorState, clearForm, handleSubmit, onValueTextPairChanged }) => (
  <form onSubmit={handleSubmit} noValidate>
    <div className="row">
      <div className="col-xs-12 col-sm-6 col-md-4">
        <LscDropdown
          label="LSC"
          name="lscOrgUnitId"
          value={formState.lscOrgUnitId}
          error={errorState.lscOrgUnitId}
          message={errorState.lscOrgUnitId}
          onChange={(newValue, newValueLabel, e) => {
            onValueTextPairChanged(newValue, 'lscOrgUnitId', newValueLabel, 'lscName', e);
            onValueTextPairChanged('', 'orgUnitId', '', 'orgUnitName', e);
          }} />
      </div>
      <div className="col-xs-12 col-sm-6 col-md-4">
        <OrgUnitCombobox
          label="Club Name*"
          name="orgUnitName"
          valueToMatch={formState.orgUnitName}
          error={errorState.orgUnitId}
          message={errorState.orgUnitId}
          onChange={(newValue, newValueLabel, e) => { onValueTextPairChanged(newValue, 'orgUnitId', newValueLabel, 'orgUnitName', e); }}
          organizationId={formState.organizationId}
          lscId={formState.lscOrgUnitId} />
      </div>
    </div>
    <div className="row usas-extra-top-margin usas-extra-bottom-margin">
      <div className="col-xs-12 usas-extra-top-margin">
        <PrimaryButton type="submit">Go To SSRP</PrimaryButton>&nbsp;
        <SecondaryButton type="button" onClick={() => clearForm()}>Clear Form</SecondaryButton>&nbsp;
      </div>
    </div>
  </form >
);

const SafeSportSearch = () => {
  const {
    formState,
    errorState,
    environmentVariableState,
    clearForm,
    handleSubmit,
    onValueTextPairChanged,
  } = useSafeSportSearch();

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>SSRP Search</Headings.H3>
        </div>
      </div>
      <SSRPSearchForm
        formState={formState}
        errorState={errorState}
        environmentVariableState={environmentVariableState}
        clearForm={clearForm}
        handleSubmit={handleSubmit}
        onValueTextPairChanged={onValueTextPairChanged} />
    </Fragment>
  );
};

export default SafeSportSearch;