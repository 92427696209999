import React, { Fragment } from 'react';

import usePreCompLocationInstructorTraining from './UsePreCompLocationInstructorTraining';

import PreCompLocationInstructorTrainingForm from '../components/forms/PreCompLocationInstructorTrainingForm';

import PopUpModal from '../../../../common/components/dialogs/PopUpModal';
import PrimaryButton from '../../../../common/components/buttons/PrimaryButton';
import SecondaryButton from '../../../../common/components/buttons/SecondaryButton';
import Headings from '../../../../common/components/headings/Headings';
import Constants from '../../../../common/utils/Constants';

const PreCompLocationInstructorTraining = () => {
  const {
    isSaving,
    isLoading,
    formState,
    errorState,
    onFormValueChanged,
    onValueTextPairChanged,
    handleSubmit,
    resetForm
  } = usePreCompLocationInstructorTraining();

  return (
    <Fragment>
      <Headings.H3>Location Instructor Training</Headings.H3>
      <form onSubmit={handleSubmit} noValidate>
        <PreCompLocationInstructorTrainingForm
          formState={formState}
          errorState={errorState}
          onFormValueChanged={onFormValueChanged}
          onValueTextPairChanged={onValueTextPairChanged} />
        {errorState.errorOnSubmitAction &&
          <div className="row">
            <div className="col-xs-12">
              <p className={global.ErrorMessage}>{errorState.errorOnSubmitAction || ''}</p>
            </div>
          </div>}
        <div className="row usas-extra-top-margin usas-extra-bottom-margin">
          <div className="col-xs-12 usas-extra-top-margin">
            <PrimaryButton type="submit">Save</PrimaryButton>&nbsp;
            <SecondaryButton type="button" onClick={resetForm}>Cancel</SecondaryButton>
          </div>
        </div>
      </form>
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.SAVING_MSG}
        displayPopUp={isSaving} />
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={isLoading} />
    </Fragment>
  );
};

export default PreCompLocationInstructorTraining;