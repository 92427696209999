import { Fragment } from 'react';

import useLearnToSwimFacilityAddForm from './UseLearnToSwimFacilityAddForm';

import FacilityForm from '../../../../../common/components/searches/searchFacility/addFacility/components/FacilityForm';
import AddressValidation from '../../../../../common/components/addressValidation/AddressValidation';

import PrimaryButton from '../../../../../common/components/buttons/PrimaryButton';
import SecondaryButton from '../../../../../common/components/buttons/SecondaryButton';
import PopUpModal from '../../../../../common/components/dialogs/PopUpModal';

const LearnToSwimFacilityAddForm = ({ submitButtonText = 'Save', secondaryButtonText = 'Back',
  onSubmitFormCallback, onSecondaryButtonClicked }) => {
  const {
    formState,
    errorState,
    validateState,
    onFormValueChanged,
    onValueTextPairChanged,
    handleSubmitProxy,
    setFormData,
  } = useLearnToSwimFacilityAddForm(onSubmitFormCallback);

  return (
    <Fragment>
      <form onSubmit={handleSubmitProxy} noValidate>
        <FacilityForm
          learnToSwim={true}
          formState={formState}
          errorState={errorState}
          onFormValueChanged={onFormValueChanged}
          onValueTextPairChanged={onValueTextPairChanged} />
        {formState.isInternational === false
          ? <AddressValidation formState={formState} setFormData={setFormData} />
          : <Fragment />}
        <div className="row">
          <div className="col-xs-12 usas-extra-top-margin usas-extra-bottom-margin">
            <PrimaryButton type="submit">{submitButtonText}</PrimaryButton>&nbsp;
            <SecondaryButton type="button" onClick={onSecondaryButtonClicked}>{secondaryButtonText}</SecondaryButton>
          </div>
        </div>
      </form>
      <PopUpModal
        displayPopUp={validateState.isValidating === true}
        maxWidth={250}
        title="Validating..."
        widthPct={50} />
    </Fragment>
  );
};

export default LearnToSwimFacilityAddForm;