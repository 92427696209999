import { useState } from "react";

import ClubNationalPracticeDataData from "./ClubNationalPracticeDataData";

const useClubNationalPracticeDataData = () => {
  const [clubNationalPracticeDataState, setClubNationalPracticeDataState] = useState(ClubNationalPracticeDataData.INITIAL_STATE);
  const postNationalPracticeData = (nationalPracticeData) => ClubNationalPracticeDataData.postClubNationalPracticeData(nationalPracticeData, clubNationalPracticeDataState, setClubNationalPracticeDataState);

  return {
    clubNationalPracticeDataState,
    postNationalPracticeData
  };
};

export default useClubNationalPracticeDataData;