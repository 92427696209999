import { useEffect } from 'react';

import useCategoryMeasureMultiselectData from '../../../state/categoryMeasureMultiselect/UseCategoryMeasureMultiselectData';

const useCategoryMeasureMultiselect = (categoryMeasureId) => {
  const { categoryMeasureMultiselectState, getCategoryMeasureMultiselect } = useCategoryMeasureMultiselectData();

  useEffect(() => {
    if (categoryMeasureMultiselectState.isArrayLoading !== true && categoryMeasureId > 0 && categoryMeasureId !== categoryMeasureMultiselectState.categoryMeasureId) {
      getCategoryMeasureMultiselect(categoryMeasureId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categoryMeasureMultiselectState, categoryMeasureId]);

  return {
    categoryMeasureMultiselectState
  };
};

export default useCategoryMeasureMultiselect;