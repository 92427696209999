import { useEffect, useState } from 'react';
import { useNavigate, useLocation } from '../../../../common/wrappers/ReactRouterDom';

import validate from './ClubTouchpointsDetailValidation';

import NavLinks from '../NavLinks';

import useBaseUsasClubData from '../../../../common/state/clubBase/UseBaseUsasClubData';
import useForm from '../../../../common/utils/UseForm';
import Constants from '../../../../common/utils/Constants';
import { formatDate } from '../../../../common/utils/DateFunctions';

import useTouchpointTypeData from '../../../state/touchpointType/UseTouchpointTypeData';
import useClubTouchpointData from '../../../state/clubTouchpoints/UseClubTouchpointData';

const useClubTouchpointsDetail = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { baseUsasClubState } = useBaseUsasClubData();
  const { touchpointTypeState } = useTouchpointTypeData();
  const { clubTouchpointState, postClubTouchpoint, putClubTouchpoint } = useClubTouchpointData();

  const [state, setState] = useState({ tryRedirect: false });

  const {
    formState,
    errorState,
    updateFormState,
    handleSubmit,
    isDirty,
    setFormData,
    onValueTextPairChanged }
    = useForm(getFormInitialState, submitFormCallback, validate);

  useEffect(() => {
    if (clubTouchpointState.isObjLoaded === true) {
      navigate(NavLinks.CLUB_TOUCHPOINTS);
    }
  }, [clubTouchpointState]);

  useEffect(() => {
    if (location.state && location.state.usasClubTouchpointId !== Constants.DEFAULT_ID && touchpointTypeState.isLoaded === true) {
      setFormData({
        ...FormData,
        usasClubTouchpointId: location.state.usasClubTouchpointId || Constants.DEFAULT_ID,
        touchpointNote: location.state.touchpointNote || "",
        touchpointDate: formatDate(location.state.touchpointDate) || Constants.BLANK_DATE_STRING,
        touchpointTypeId: location.state.touchpointTypeId || Constants.DEFAULT_ID
      })
    }
  }, [location.state, touchpointTypeState]);

  function submitFormCallback() {
    const touchpointObj = {
      touchpointTypeId: formState.touchpointTypeId,
      touchpointNote: formState.touchpointNote,
      touchpointDate: formState.touchpointDate
    };

    if (formState.usasClubTouchpointId !== Constants.DEFAULT_ID) {
      putClubTouchpoint(formState.usasClubTouchpointId, touchpointObj);
    } else {
      postClubTouchpoint(baseUsasClubState.objData.usasClubId, touchpointObj);
    }
  }

  const onSaveClicked = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    handleSubmit();
    if (isDirty === false) {
      setState({ ...state, tryRedirect: true });
    }
  };

  const onCancelClicked = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    navigate(NavLinks.CLUB_TOUCHPOINTS);
  };

  function getFormInitialState() {
    return {
      usasClubTouchpointId: Constants.DEFAULT_ID,
      touchpointTypeId: Constants.DEFAULT_ID,
      touchpointTypeName: '',
      touchpointNote: '',
      touchpointDate: ''
    };
  };

  return {
    formState,
    errorState,
    touchpointTypeState,
    onFormValueChanged: updateFormState,
    handleSubmit,
    onSaveClicked,
    onCancelClicked,
    onValueTextPairChanged
  }
}

export default useClubTouchpointsDetail;