import { useState } from 'react';

import LSCRegistrationManagementData from './LSCRegistrationManagementData';

const useLSCRegistrationManagementData = () => {
  const [lscRegistrationManagementState, setLscRegistrationManagementState] = useState(LSCRegistrationManagementData.INITIAL_STATE);

  const getLSCRegManagement = (orgUnitId, reportPeriodId, registrationStartDate, registrationEndDate, clubOrgUnitId = undefined) => {
    const filterObject = {
      reportPeriodId,
      registrationStartDate,
      registrationEndDate,
      clubOrgUnitId
    };

    LSCRegistrationManagementData.getLSCRegistrationManagementData(orgUnitId, filterObject, lscRegistrationManagementState, setLscRegistrationManagementState);
  };

  const putLSCRegManagement = (orgUnitId, registrationData) => {
    LSCRegistrationManagementData.putLSCRegistrationManagementData(orgUnitId, registrationData, lscRegistrationManagementState, setLscRegistrationManagementState);
  };

  const clearLSCRegManagementArrayData = () => {
    setLscRegistrationManagementState({
      ...lscRegistrationManagementState,
      isArrayLoading: false,
      isArrayLoaded: false,
      isSaving: false,
      isSaved: false,
      arrayData: []
    });
  }

  return {
    lscRegistrationManagementState,
    getLSCRegManagement,
    putLSCRegManagement,
    clearLSCRegManagementArrayData
  };
};

export default useLSCRegistrationManagementData;