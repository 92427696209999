import { Fragment } from 'react';
import { Routes, Route } from 'react-router-dom';

import useClubContextRoutes from './UseClubContextRoutes';

import ClubContextRoutesContextProviders from './ClubContextRoutesContextProviders';

import ClubInfoWrite from './clubInfo/ClubInfoWrite';
import ClubInfoRead from './clubInfo/ClubInfoRead';
import ClubAdminChanges from './clubAdminChanges/ClubAdminChanges';
import ClubAthleteRoster from './clubAthleteRoster/ClubAthleteRoster';
import ClubNonAthleteRoster from './clubNonAthleteRoster/ClubNonAthleteRoster';
import ClubStaffWrite from './clubStaff/ClubStaffWrite';
import ClubStaffRead from './clubStaff/ClubStaffRead';
import ClubStaffDetail from './clubStaff/ClubStaffDetail';
import ClubStaffRequirements from './clubStaffRequirements/ClubStaffRequirements';
import ClubReporting from './clubReporting/ClubReporting';
import ClubNationalPracticeData from './clubNationalPracticeData/ClubNationalPracticeData';
import ClubAffiliationWrite from './clubAffiliation/ClubAffiliationWrite';
import ClubAffiliationRead from './clubAffiliation/ClubAffiliationRead';
import ClubAffiliationDetail from './clubAffiliation/ClubAffiliationDetail';
import ClubActivateVendor from './clubActivateVendor/ClubActivateVendor';
import ClubAddVendor from './clubActivateVendor/clubAddVendor/ClubAddVendor';
import ClubGenerateRegistrationLink from './clubGenerateRegistrationLink/ClubGenerateRegistrationLink';
import ClubPoliciesWrite from './clubPolicies/ClubPoliciesWrite';
import ClubPoliciesRead from './clubPolicies/ClubPoliciesRead';
import ClubPreCompetitiveProgramsWrite from './clubPreCompetitivePrograms/ClubPreCompetitiveProgramsWrite';
import ClubPreCompetitiveProgramsRead from './clubPreCompetitivePrograms/ClubPreCompetitiveProgramsRead';
import ClubPreCompetitiveProgramsSearch from './clubPreCompetitivePrograms/ClubPreCompetitiveProgramsSearch';
import ClubGroups from './clubGroups/ClubGroups';
import ClubGroupsSelectGroupType from './clubGroups/clubGroupsSelectGroupType/ClubGroupsSelectGroupType';
import ClubGroupTypeListAdd from './clubGroups/clubGroupTypeList/ClubGroupTypeListAdd';
import ClubGroupTypeListEdit from './clubGroups/clubGroupTypeList/ClubGroupTypeListEdit';
import ClubGroupTypePracticeAdd from './clubGroups/clubGroupTypePractice/ClubGroupTypePracticeAdd';
import ClubGroupTypePracticeEdit from './clubGroups/clubGroupTypePractice/ClubGroupTypePracticeEdit';
import ClubGroupTypeTravelAdd from './clubGroups/clubGroupTypeTravel/ClubGroupTypeTravelAdd';
import ClubGroupTypeTravelEdit from './clubGroups/clubGroupTypeTravel/ClubGroupTypeTravelEdit';
import ClubGroupTypeSiteAdd from './clubGroups/clubGroupTypeSite/ClubGroupTypeSiteAdd';
import ClubGroupTypeSiteEdit from './clubGroups/clubGroupTypeSite/ClubGroupTypeSiteEdit';
import ClubGroupsAddRemoveMembersGrid from './clubGroups/clubGroupsAddRemoveMembers/ClubGroupsAddRemoveMembersGrid';
import ClubHistory from './clubHistory/ClubHistory';
import ClubFacilitiesWrite from './clubFacilities/ClubFacilitiesWrite';
import ClubFacilitiesRead from './clubFacilities/ClubFacilitiesRead';
import ClubFacilitiesSearch from './clubFacilities/ClubFacilitiesSearch';
import ClubFacilitiesAdd from './clubFacilities/ClubFacilitiesAdd';
import ClubFacilityDetails from './clubFacilities/ClubFacilityDetails';
import ClubFacilitiesPoolsDetail from './clubFacilities/ClubFacilitiesPoolsDetail';
import ClubLegalEntitiesWrite from './clubLegalEntities/ClubLegalEntitiesWrite';
import ClubLegalEntitiesRead from './clubLegalEntities/ClubLegalEntitiesRead';
import ClubLegalEntitiesDetails from './clubLegalEntities/ClubLegalEntitiesDetails';
import ClubMeets from './clubMeets/ClubMeets';
import ClubBlockPartyMeets from './clubBlockPartyMeets/ClubBlockPartyMeets';
import ClubBlockPartyMeetAddHQ from './clubBlockPartyMeets/ClubBlockPartyMeetAddHQ';
import ClubBlockPartyMeetAddClub from './clubBlockPartyMeets/ClubBlockPartyMeetAddClub';
import ClubBlockPartyMeetAdd from './clubBlockPartyMeets/ClubBlockPartyMeetAdd';
import ClubMeetSanctionRequest from './clubMeets/clubMeetSanctionRequest/ClubMeetSanctionRequest';
import ClubMeetUpload from './clubMeets/clubMeetUpload/ClubMeetUpload';
import ClubMeetRecon from './clubMeets/clubMeetRecon/ClubMeetRecon';
import ClubManageOfferings from './clubManageOfferings/ClubManageOfferings';
import ClubBulkRenewAthletes from './clubBulkRenew/ClubBulkRenewAthletes';
import ClubBulkRenewNonAthletes from './clubBulkRenew/ClubBulkRenewNonAthletes';
import ClubTouchpoints from './clubTouchpoints/ClubTouchpoints';
import ClubTouchpointsDetails from './clubTouchpoints/ClubTouchpointsDetails';
import ClubCommunityImpactBulkImport from './clubCommunityImpactBulkImport/ClubCommunityImpactBulkImport';
import ClubAudit from './clubAudit/ClubAudit';
import ClubThirdPartyInvoices from './clubThirdPartyInvoices/ClubThirdPartyInvoices';

import ClubHeader from '../../components/header/ClubHeader';

import LoadingModal from '../../../common/components/dialogs/LoadingModal';
import Headings from '../../../common/components/headings/Headings';
import SecuredRouteElement from '../../../common/components/security/SecuredRouteElement';
import PageNotFound from '../../../common/components/PageNotFound';

import global from '../../../common/components/GlobalStyle.module.css';

const ContextRoutes = () => {
  const {
    isLoading,
    isError,
    navRoutes
  } = useClubContextRoutes();

  return (
    <Fragment>
      {isError === false &&
        <Fragment>
          <div className="row">
            <div className="col-xs-12">
              <button className={global.HeaderButton}>
                <Headings.H3
                  className={[global.NoBottomMargin, 'col-xs-12'].join(' ')}
                  excludeBar={true}>
                  View/Edit Club
                </Headings.H3>
              </button>
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12">
              <hr className="usas-bar-turmeric" />
            </div>
          </div>
        </Fragment>
      }
      {isError === true
        ? <PageNotFound />
        : isLoading === true ?
          <LoadingModal /> : (
            <Fragment>
              <ClubHeader />
              <Routes>
                <Route path={navRoutes.CLUB_INFO?.path}
                  element={
                    <SecuredRouteElement
                      navRoute={navRoutes.CLUB_INFO}
                      editElement={<ClubInfoWrite />}
                      readOnlyElement={<ClubInfoRead />} />
                  } />
                <Route path={navRoutes.CLUB_ADMINCHANGES?.path}
                  element={
                    <SecuredRouteElement
                      navRoute={navRoutes.CLUB_ADMINCHANGES}
                      editElement={<ClubAdminChanges />} />
                  } />
                <Route path={navRoutes.CLUB_ATHLETE_ROSTER?.path}
                  element={
                    <SecuredRouteElement
                      navRoute={navRoutes.CLUB_ATHLETE_ROSTER}
                      editElement={<ClubAthleteRoster />} />
                  } />
                <Route path={navRoutes.CLUB_NONATHLETE_ROSTER?.path}
                  element={
                    <SecuredRouteElement
                      navRoute={navRoutes.CLUB_NONATHLETE_ROSTER}
                      editElement={<ClubNonAthleteRoster />} />
                  } />
                <Route path={navRoutes.CLUB_STAFF?.path}
                  element={
                    <SecuredRouteElement
                      navRoute={navRoutes.CLUB_STAFF}
                      editElement={<ClubStaffWrite />}
                      readOnlyElement={<ClubStaffRead />} />
                  } />
                <Route path={navRoutes.CLUB_STAFF_DETAIL?.path}
                  element={
                    <SecuredRouteElement
                      navRoute={navRoutes.CLUB_STAFF_DETAIL}
                      editElement={<ClubStaffDetail />} />
                  } />
                <Route path={navRoutes.CLUB_STAFF_REQUIREMENTS?.path}
                  element={
                    <SecuredRouteElement
                      navRoute={navRoutes.CLUB_STAFF_REQUIREMENTS}
                      editElement={<ClubStaffRequirements />} />
                  } />
                <Route path={navRoutes.CLUB_REPORTING?.path}
                  element={
                    <SecuredRouteElement
                      navRoute={navRoutes.CLUB_REPORTING}
                      editElement={<ClubReporting />} />
                  } />
                <Route path={navRoutes.CLUB_NATIONAL_PRACTICE_DATA?.path}
                  element={
                    <SecuredRouteElement
                      navRoute={navRoutes.CLUB_NATIONAL_PRACTICE_DATA}
                      editElement={<ClubNationalPracticeData />} />
                  } />
                <Route path={navRoutes.CLUB_AFFILIATION?.path}
                  element={
                    <SecuredRouteElement
                      navRoute={navRoutes.CLUB_AFFILIATION}
                      editElement={<ClubAffiliationWrite />}
                      readOnlyElement={<ClubAffiliationRead />} />
                  } />
                <Route path={navRoutes.CLUB_AFFILIATION_DETAIL?.path}
                  element={
                    <SecuredRouteElement
                      navRoute={navRoutes.CLUB_AFFILIATION_DETAIL}
                      editElement={<ClubAffiliationDetail />} />
                  } />
                <Route path={navRoutes.CLUB_ACTIVATE_VENDOR?.path}
                  element={
                    <SecuredRouteElement
                      navRoute={navRoutes.CLUB_ACTIVATE_VENDOR}
                      editElement={<ClubActivateVendor />} />
                  } />
                <Route path={navRoutes.CLUB_ACTIVATE_VENDOR_ADD_VENDOR?.path}
                  element={
                    <SecuredRouteElement
                      navRoute={navRoutes.CLUB_ACTIVATE_VENDOR_ADD_VENDOR}
                      editElement={<ClubAddVendor />} />
                  } />
                <Route path={navRoutes.CLUB_GENERATE_REGISTRATION_LINK?.path}
                  element={
                    <SecuredRouteElement
                      navRoute={navRoutes.CLUB_GENERATE_REGISTRATION_LINK}
                      editElement={<ClubGenerateRegistrationLink />} />
                  } />
                <Route path={navRoutes.CLUB_POLICIES?.path}
                  element={
                    <SecuredRouteElement
                      navRoute={navRoutes.CLUB_POLICIES}
                      editElement={<ClubPoliciesWrite />}
                      readOnlyElement={<ClubPoliciesRead />} />
                  } />
                <Route path={navRoutes.CLUB_PRECOMPETITIVE?.path}
                  element={
                    <SecuredRouteElement
                      navRoute={navRoutes.CLUB_PRECOMPETITIVE}
                      editElement={<ClubPreCompetitiveProgramsWrite />}
                      readOnlyElement={<ClubPreCompetitiveProgramsRead />} />
                  } />
                <Route path={navRoutes.CLUB_PRECOMPETITIVE_SEARCH?.path}
                  element={
                    <SecuredRouteElement
                      navRoute={navRoutes.CLUB_PRECOMPETITIVE_SEARCH}
                      editElement={<ClubPreCompetitiveProgramsSearch />} />
                  } />
                <Route path={navRoutes.CLUB_GROUPS?.path}
                  element={
                    <SecuredRouteElement
                      navRoute={navRoutes.CLUB_GROUPS}
                      editElement={<ClubGroups />} />
                  } />
                <Route path={navRoutes.CLUB_GROUPS_GROUP_TYPE?.path}
                  element={
                    <SecuredRouteElement
                      navRoute={navRoutes.CLUB_GROUPS_GROUP_TYPE}
                      editElement={<ClubGroupsSelectGroupType />} />
                  } />
                <Route path={navRoutes.CLUB_GROUPS_LIST_ADD?.path}
                  element={
                    <SecuredRouteElement
                      navRoute={navRoutes.CLUB_GROUPS_LIST_ADD}
                      editElement={<ClubGroupTypeListAdd />} />
                  } />
                <Route path={navRoutes.CLUB_GROUPS_LIST_EDIT?.path}
                  element={
                    <SecuredRouteElement
                      navRoute={navRoutes.CLUB_GROUPS_LIST_EDIT}
                      editElement={<ClubGroupTypeListEdit />} />
                  } />
                <Route path={navRoutes.CLUB_GROUPS_PRACTICE_ADD?.path}
                  element={
                    <SecuredRouteElement
                      navRoute={navRoutes.CLUB_GROUPS_PRACTICE_ADD}
                      editElement={<ClubGroupTypePracticeAdd />} />
                  } />
                <Route path={navRoutes.CLUB_GROUPS_PRACTICE_EDIT?.path}
                  element={
                    <SecuredRouteElement
                      navRoute={navRoutes.CLUB_GROUPS_PRACTICE_EDIT}
                      editElement={<ClubGroupTypePracticeEdit />} />
                  } />
                <Route path={navRoutes.CLUB_GROUPS_TRAVEL_ADD?.path}
                  element={
                    <SecuredRouteElement
                      navRoute={navRoutes.CLUB_GROUPS_TRAVEL_ADD}
                      editElement={<ClubGroupTypeTravelAdd />} />
                  } />
                <Route path={navRoutes.CLUB_GROUPS_TRAVEL_EDIT?.path}
                  element={
                    <SecuredRouteElement
                      navRoute={navRoutes.CLUB_GROUPS_TRAVEL_EDIT}
                      editElement={<ClubGroupTypeTravelEdit />} />
                  } />
                <Route path={navRoutes.CLUB_GROUPS_CLUB_SITE_ADD?.path}
                  element={
                    <SecuredRouteElement
                      navRoute={navRoutes.CLUB_GROUPS_CLUB_SITE_ADD}
                      editElement={<ClubGroupTypeSiteAdd />} />
                  } />
                <Route path={navRoutes.CLUB_GROUPS_CLUB_SITE_EDIT?.path}
                  element={
                    <SecuredRouteElement
                      navRoute={navRoutes.CLUB_GROUPS_CLUB_SITE_EDIT}
                      editElement={<ClubGroupTypeSiteEdit />} />
                  } />
                <Route path={navRoutes.CLUB_GROUPS_MEMBER_DETAIL?.path}
                  element={
                    <SecuredRouteElement
                      navRoute={navRoutes.CLUB_GROUPS_MEMBER_DETAIL}
                      editElement={<ClubGroupsAddRemoveMembersGrid />} />
                  } />
                <Route path={navRoutes.CLUB_HISTORY?.path}
                  element={
                    <SecuredRouteElement
                      navRoute={navRoutes.CLUB_HISTORY}
                      editElement={<ClubHistory />} />
                  } />
                <Route path={navRoutes.CLUB_FACILITIES?.path}
                  element={
                    <SecuredRouteElement
                      navRoute={navRoutes.CLUB_FACILITIES}
                      editElement={<ClubFacilitiesWrite />}
                      readOnlyElement={<ClubFacilitiesRead />} />
                  } />
                <Route path={navRoutes.CLUB_FACILITIES_SEARCH?.path}
                  element={
                    <SecuredRouteElement
                      navRoute={navRoutes.CLUB_FACILITIES_SEARCH}
                      editElement={<ClubFacilitiesSearch />} />
                  } />
                <Route path={navRoutes.CLUB_FACILITIES_ADD?.path}
                  element={
                    <SecuredRouteElement
                      navRoute={navRoutes.CLUB_FACILITIES_ADD}
                      editElement={<ClubFacilitiesAdd />} />
                  } />
                <Route path={navRoutes.CLUB_FACILITIES_DETAILS?.path}
                  element={
                    <SecuredRouteElement
                      navRoute={navRoutes.CLUB_FACILITIES_DETAILS}
                      editElement={<ClubFacilityDetails />} />
                  } />
                <Route path={navRoutes.CLUB_FACILITIES_POOLS_DETAIL?.path}
                  element={
                    <SecuredRouteElement
                      navRoute={navRoutes.CLUB_FACILITIES_POOLS_DETAIL}
                      editElement={<ClubFacilitiesPoolsDetail />} />
                  } />
                <Route path={navRoutes.CLUB_LEGAL_ENTITIES?.path}
                  element={
                    <SecuredRouteElement
                      navRoute={navRoutes.CLUB_LEGAL_ENTITIES}
                      editElement={<ClubLegalEntitiesWrite />}
                      readOnlyElement={<ClubLegalEntitiesRead />} />
                  } />
                <Route path={navRoutes.CLUB_LEGAL_ENTITIES_DETAILS?.path}
                  element={
                    <SecuredRouteElement
                      navRoute={navRoutes.CLUB_LEGAL_ENTITIES_DETAILS}
                      editElement={<ClubLegalEntitiesDetails />} />
                  } />
                <Route path={navRoutes.CLUB_MEETS?.path}
                  element={
                    <SecuredRouteElement
                      navRoute={navRoutes.CLUB_MEETS}
                      editElement={<ClubMeets />} />
                  } />
                <Route path={navRoutes.CLUB_BLOCKPARTY?.path}
                  element={
                    <SecuredRouteElement
                      navRoute={navRoutes.CLUB_BLOCKPARTY}
                      editElement={<ClubBlockPartyMeets />} />
                  } />
                <Route path={navRoutes.CLUB_BLOCKPARTY_ADD_ROLE?.path}
                  element={
                    <SecuredRouteElement
                      navRoute={navRoutes.CLUB_BLOCKPARTY_ADD_ROLE}
                      editElement={<ClubBlockPartyMeetAddHQ />}
                      readOnlyElement={<ClubBlockPartyMeetAddClub />} />
                  } />
                <Route path={navRoutes.CLUB_BLOCKPARTY_ADD?.path}
                  element={
                    <SecuredRouteElement
                      navRoute={navRoutes.CLUB_BLOCKPARTY_ADD}
                      editElement={<ClubBlockPartyMeetAdd />}
                      readOnlyElement={<ClubBlockPartyMeetAdd />}
                    />
                  } />
                <Route path={navRoutes.CLUB_MEETS_SANCTION?.path}
                  element={
                    <SecuredRouteElement
                      navRoute={navRoutes.CLUB_MEETS_SANCTION}
                      editElement={<ClubMeetSanctionRequest />} />
                  } />
                <Route path={navRoutes.CLUB_MEETS_UPLOAD?.path}
                  element={
                    <SecuredRouteElement
                      navRoute={navRoutes.CLUB_MEETS_UPLOAD}
                      editElement={<ClubMeetUpload />} />
                  } />
                <Route path={navRoutes.CLUB_MEETS_RECON?.path}
                  element={
                    <SecuredRouteElement
                      navRoute={navRoutes.CLUB_MEETS_RECON}
                      editElement={<ClubMeetRecon />} />
                  } />
                <Route path={navRoutes.CLUB_MANAGE_OFFERINGS?.path}
                  element={
                    <SecuredRouteElement
                      navRoute={navRoutes.CLUB_MANAGE_OFFERINGS}
                      editElement={<ClubManageOfferings />} />
                  } />
                <Route path={navRoutes.CLUB_BULK_RENEW_ATHLETES?.path}
                  element={
                    <SecuredRouteElement
                      navRoute={navRoutes.CLUB_BULK_RENEW_ATHLETES}
                      editElement={<ClubBulkRenewAthletes />} />
                  } />
                <Route path={navRoutes.CLUB_BULK_RENEW_NONATHLETES?.path}
                  element={
                    <SecuredRouteElement
                      navRoute={navRoutes.CLUB_BULK_RENEW_NONATHLETES}
                      editElement={<ClubBulkRenewNonAthletes />} />
                  } />
                <Route path={navRoutes.CLUB_COMMUNITY_IMPACT_BULK_IMPORT?.path}
                  element={
                    <SecuredRouteElement
                      navRoute={navRoutes.CLUB_COMMUNITY_IMPACT_BULK_IMPORT}
                      editElement={<ClubCommunityImpactBulkImport />} />
                  } />
                <Route path={navRoutes.CLUB_TOUCHPOINTS?.path}
                  element={
                    <SecuredRouteElement
                      navRoute={navRoutes.CLUB_TOUCHPOINTS}
                      editElement={<ClubTouchpoints />} />
                  } />
                <Route path={navRoutes.CLUB_TOUCHPOINTS_DETAILS?.path}
                  element={
                    <SecuredRouteElement
                      navRoute={navRoutes.CLUB_TOUCHPOINTS_DETAILS}
                      editElement={<ClubTouchpointsDetails />} />
                  } />
                <Route path={navRoutes.CLUB_AUDIT?.path}
                  element={
                    <SecuredRouteElement
                      navRoute={navRoutes.CLUB_AUDIT}
                      editElement={<ClubAudit />} />
                  }
                />
                <Route path={navRoutes.CLUB_THIRD_PARTY_INVOICES?.path}
                  element={
                    <SecuredRouteElement
                      navRoute={navRoutes.CLUB_THIRD_PARTY_INVOICES}
                      editElement={<ClubThirdPartyInvoices />} />
                  }
                />
              </Routes>
            </Fragment>
          )
      }
    </Fragment>
  );
};

const ClubContextRoutes = () => {
  return (
    <ClubContextRoutesContextProviders>
      <ContextRoutes />
    </ClubContextRoutesContextProviders>
  );
};

export default ClubContextRoutes;