import { BASIC_INITIAL_STATE } from '../../../common/utils/HttpHelper';

import postProgramLevelInstanceSubmitLevelData from './PostProgramLevelInstanceSubmitLevelData';

const INITIAL_STATE = {
  ...BASIC_INITIAL_STATE
};

const ProgramLevelInstanceData = {
  INITIAL_STATE,
  postProgramLevelInstanceSubmitLevelData
};

export default ProgramLevelInstanceData;