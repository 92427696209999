export const localValidate = (formState) => {
  let errors = {};

  if (formState.paidOrVolunteerId < 0 || formState.paidOrVolunteerName === '--' || formState.paidOrVolunteerName === '') {
    errors.paidOrVolunteerId = 'Selecting Paid or Volunteer is required';
  }

  return errors;
};

const EditLSCStaffMemberValidation = async (formState) => {
  let errors = localValidate(formState);

  return errors ? errors : {};
};

export default EditLSCStaffMemberValidation;