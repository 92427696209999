//import { useEffect, useState } from 'react';
import { useState } from 'react';
import { useNavigate } from '../../../../common/wrappers/ReactRouterDom';

import NavLinks from '../NavLinks';

//import useSwimAThonPurchaseItemData from '../../../state/swimAThon/swimAThonPurchase/UseSwimAThonPurchaseItemData';
import useSwimAThonData from '../../../state/swimAThon/UseSwimAThonData';

const INITIAL_VIEW_STATE = {
  //items: [],
  instructionText: 'Pay for Swim-A-Thon Items',
  //isLoading: true
};

const useClubSwimAThonPayments = () => {
  const navigate = useNavigate();
  const { satState } = useSwimAThonData();
  //const { satPurchaseItemsPromise } = useSwimAThonPurchaseItemData();
  const [state, setState] = useState(INITIAL_VIEW_STATE);

  const onPaymentCompletion = () => {
    navigate(NavLinks.CLUB_SWIMATHON_RESULTS, { state: { performGet: true } });
  };

  const onPaymentCancel = () => {
    navigate(NavLinks.CLUB_SWIMATHON_RESULTS, { state: { performGet: false } });
  };

  // useEffect(() => {
  //   const purchaseItemsPromise = satPurchaseItemsPromise(satState.orgUnitId);

  //   if (purchaseItemsPromise) {
  //     purchaseItemsPromise.then((purchaseItemData) => {
  //       const items = Array.isArray(purchaseItemData?.arrayData) && purchaseItemData.arrayData.length > 0
  //         ? [...purchaseItemData.arrayData] : [];

  //       setState({
  //         ...state,
  //         items,
  //         isLoading: false
  //       });
  //     }).catch((e) => {
  //       setState({
  //         ...state,
  //         isLoading: false
  //       });
  //     });
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  return {
    //isLoading: state.isLoading || satState.isObjLoading,
    //items: state.items,
    instructionText: state.instructionText,
    onPaymentCompletion,
    onPaymentCancel,
    orgUnitId: satState.orgUnitId
  };
};

export default useClubSwimAThonPayments;