import { BASIC_INITIAL_STATE } from '../../../../common/utils/HttpHelper';

import getRequiredRolesByOrgLevelTypeId from './GetRequiredRolesByOrgLevelTypeIdData';

const INITIAL_STATE = {
  ...BASIC_INITIAL_STATE
};

const RequiredRolesData = {
  INITIAL_STATE,
  getRequiredRolesByOrgLevelTypeId
};

export default RequiredRolesData;