import Constants from '../../../../common/utils/Constants';
import { ClubHttpHelper, NO_DATA_MESSAGE } from '../../../../common/utils/HttpHelper';

export const localValidate = async (formState) => {
  let errors = {};

  //Club Name
  if (formState.clubName.trim().length === 0) {
    errors.clubName = 'Club Name is required'
  }
  else if (formState.clubName.trim().length > 100) {
    errors.clubName = 'Club Name cannot exceed 100 characters'
  }

  //Club Code
  if (formState.clubCode.trim().length === 0) {
    errors.clubCode = 'Club Code is required'
  }
  else if (formState.clubCode.trim().length > 15) {
    errors.clubCode = 'Club Code cannot exceed 15 characters'
  }

  //Is Club Active?
  if (formState.isActive === false) {
    if (formState.clubInactiveReasonId === Constants.DEFAULT_ID) {
      errors.clubInactiveReasonId = 'Club Inactive Reason is required';
    }
  }

  return errors;
};

const serverValidate = async (formState) => {
  let errors = {};

  const checkClubNameurl = `/UsasClub/query/dupcheck?lscOrgUnitId=${formState.lscId}&clubName=${formState.clubName}`;

  await ClubHttpHelper(checkClubNameurl, 'GET')
    .then((arrayData) => {
      if (!arrayData) {
        throw new Error(NO_DATA_MESSAGE);
      }
      else {
        if (arrayData.length > 0) {
          for (let i = 0; i < arrayData.length; i++) {
            if (arrayData[i].usasClubId !== formState.usasClubId) {
              errors.clubName = "This Club Name is already in use for the selected LSC. Please provide a different Club Name."
              break;
            }
          }
        }
      }
    })
    .catch((e) => {
    });

  const checkClubCodeurl = `/UsasClub/query/dupcheck?lscOrgUnitId=${formState.lscId}&clubCode=${formState.clubCode}`;

  await ClubHttpHelper(checkClubCodeurl, 'GET')
    .then((arrayData) => {
      if (!arrayData) {
        throw new Error(NO_DATA_MESSAGE);
      }
      else {
        if (arrayData.length > 0) {
          for (let i = 0; i < arrayData.length; i++) {
            if (arrayData[i].usasClubId !== formState.usasClubId) {
              errors.clubCode = "This Club Code is already in use for the selected LSC. Please provide a different Club Code."
              break;
            }
          }
        }
      }
    })
    .catch((e) => {
    });

  return errors;
};

const ClubAdminChangesValidation = async (formState) => {
  let errors = await localValidate(formState);

  if (!errors || Object.keys(errors).length === 0) {
    errors = await serverValidate(formState);
  }

  return errors ? errors : {};
};

export default ClubAdminChangesValidation;