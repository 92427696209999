import { useContext } from 'react';

import { UsasClubThirdPartyInvoicesStateContext } from './UsasClubThirdPartyInvoicesContextProvider';
import UsasClubThirdPartyInvoicesData from './UsasClubThirdPartyInvoicesData';

const useUsasClubThirdPartyInvoicesData = () => {
  const [clubThirdPartyInvoiceState, setClubThirdPartyInvoiceState] = useContext(UsasClubThirdPartyInvoicesStateContext);

  const getThirdPartyInvoices = (orgUnitId) => UsasClubThirdPartyInvoicesData.getThirdPartyInvoiceData(orgUnitId, clubThirdPartyInvoiceState, setClubThirdPartyInvoiceState);
  const getThirdPartyInvoiceByThirdPartyClubRegistrationIdData = (orgUnitId, thirdPartyClubRegistrationPersonOfferingId) => UsasClubThirdPartyInvoicesData.getThirdPartyInvoiceByThirdPartyClubRegistrationIdData(orgUnitId, thirdPartyClubRegistrationPersonOfferingId, clubThirdPartyInvoiceState, setClubThirdPartyInvoiceState);
  const postThirdPartyInvoiceByThirdPartyClubRegistrationIdProcessData = (orgUnitId, thirdPartyClubRegistrationPersonOfferingId) => UsasClubThirdPartyInvoicesData.postThirdPartyInvoiceByThirdPartyClubRegistrationIdProcessData(orgUnitId, thirdPartyClubRegistrationPersonOfferingId, clubThirdPartyInvoiceState, setClubThirdPartyInvoiceState);
  const putThirdPartyInvoiceByThirdPartyClubRegistrationIdData = (orgUnitId, thirdPartyClubRegistrationPersonOfferingId, thirdPartyInvoiceArray) => UsasClubThirdPartyInvoicesData.putThirdPartyInvoiceByThirdPartyClubRegistrationIdData(orgUnitId, thirdPartyClubRegistrationPersonOfferingId, thirdPartyInvoiceArray, clubThirdPartyInvoiceState, setClubThirdPartyInvoiceState);
  const deleteThirdPartyInvoices = (thirdPartyClubRegistrationPersonOfferingId) => UsasClubThirdPartyInvoicesData.deleteThirdPartyInvoiceData(thirdPartyClubRegistrationPersonOfferingId, clubThirdPartyInvoiceState, setClubThirdPartyInvoiceState);

  const clearThirdPartyInvoicesObjData = () => {
    setClubThirdPartyInvoiceState({
      ...clubThirdPartyInvoiceState,
      isObjLoading: false,
      isObjLoaded: false,
      isSaving: false,
      isSaved: false,
      objData: {},
      message: ''
    });
  };

  const clearThirdPartyInvoicesArrayData = () => {
    setClubThirdPartyInvoiceState({
      ...clubThirdPartyInvoiceState,
      isArrayLoading: false,
      isArrayLoaded: false,
      isSaving: false,
      isSaved: false,
      arrayData: [],
      message: ''
    });
  };

  const confirmThirdPartyInvoicesSave = () => {
    setClubThirdPartyInvoiceState({
      ...clubThirdPartyInvoiceState,
      isSaved: false
    });
  };

  return {
    clubThirdPartyInvoiceState,
    setClubThirdPartyInvoiceState,
    clearThirdPartyInvoicesObjData,
    clearThirdPartyInvoicesArrayData,
    confirmThirdPartyInvoicesSave,
    getThirdPartyInvoices,
    getThirdPartyInvoiceByThirdPartyClubRegistrationIdData,
    postThirdPartyInvoiceByThirdPartyClubRegistrationIdProcessData,
    putThirdPartyInvoiceByThirdPartyClubRegistrationIdData,
    deleteThirdPartyInvoices
  };
};

export default useUsasClubThirdPartyInvoicesData;