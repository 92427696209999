import React, { Fragment } from 'react';

import ClubBulkRenewSelectionLargeGrid from './ClubBulkRenewSelectionLargeGrid';
import ClubBulkRenewSelectionSmallGrid from './ClubBulkRenewSelectionSmallGrid';

import PrimaryButton from '../../../../../common/components/buttons/PrimaryButton';
import SecondaryButton from '../../../../../common/components/buttons/SecondaryButton';

import global from '../../../../../common/components/GlobalStyle.module.css';

const ClubBulkRenewSelectionGrid = ({ gridData, isLoading, onCheckboxChange, selectionView, onOpenMemberTypeSelectionPopUpClicked, onSelectAll, onUnselectAll,
  onSaveClicked, memberRegistrationRenewalSelectionState, confirmationGridState, errorState }) => {
  return (
    <Fragment>
      {isLoading === false ?
        <div className="row">
          <div className="col-xs-12 usas-extra-bottom-margin">
            <p>{gridData.length === 1 ? `${gridData.length} Search Result` : `${gridData.length} Search Results`}</p>
          </div>
          {gridData.length > 5 && memberRegistrationRenewalSelectionState?.isArrayLoading === false && selectionView === true &&
            < div className='visible-md visible-lg' >
              <div className='col-xs-12 col-md-4 usas-extra-bottom-margin'>
                <PrimaryButton type="button" onClick={onOpenMemberTypeSelectionPopUpClicked}>Add Selected to Renew</PrimaryButton></div><span className={"visible-xs visible-sm"}>&nbsp;&nbsp;</span>
              <div className="col-xs-12 col-md-8 usas-extra-bottom-margin">
                <SecondaryButton className={"pull-right hidden-xs hidden-sm"} type="button" onClick={onUnselectAll}>Unselect All Results</SecondaryButton>
                <span className={"pull-right hidden-xs hidden-sm"}>&nbsp;</span>
                <PrimaryButton className={"pull-right hidden-xs hidden-sm"} type="button" onClick={onSelectAll}>Select All Results</PrimaryButton>
                <PrimaryButton className={"visible-xs visible-sm"} type="button" onClick={onSelectAll}>Select All Results</PrimaryButton>
                <span className={"visible-xs visible-sm"}>&nbsp;</span>
                <SecondaryButton className={"visible-xs visible-sm"} type="button" onClick={onUnselectAll}>Unselect All Results</SecondaryButton>
                <span className={"visible-xs visible-sm"}>&nbsp;</span>
              </div>
              {errorState.general &&
                <div className="col-xs-12">
                  <p className={[global.ErrorMessage, global.Multiline].join(' ')}>{errorState.general}</p>
                </div>
              }
            </div>}
          {gridData.length > 0 && memberRegistrationRenewalSelectionState?.isArrayLoading === false && selectionView === true &&
            < div className='hidden-md hidden-lg' >
              <div className='col-xs-12 col-md-4 usas-extra-bottom-margin'>
                <PrimaryButton type="button" onClick={onOpenMemberTypeSelectionPopUpClicked}>Add Selected to Renew</PrimaryButton></div><span className={"visible-xs visible-sm"}>&nbsp;&nbsp;</span>
              <div className="col-xs-12 col-md-8 usas-extra-bottom-margin">
                <SecondaryButton className={"pull-right hidden-xs hidden-sm"} type="button" onClick={onUnselectAll}>Unselect All Results</SecondaryButton>
                <span className={"pull-right hidden-xs hidden-sm"}>&nbsp;</span>
                <PrimaryButton className={"pull-right hidden-xs hidden-sm"} type="button" onClick={onSelectAll}>Select All Results</PrimaryButton>
                <PrimaryButton className={"visible-xs visible-sm"} type="button" onClick={onSelectAll}>Select All Results</PrimaryButton>
                <span className={"visible-xs visible-sm"}>&nbsp;</span>
                <SecondaryButton className={"visible-xs visible-sm"} type="button" onClick={onUnselectAll}>Unselect All Results</SecondaryButton>
                <span className={"visible-xs visible-sm"}>&nbsp;</span>
              </div>
              {errorState.general &&
                <div className="col-xs-12">
                  <p className={[global.ErrorMessage, global.Multiline].join(' ')}>{errorState.general}</p>
                </div>
              }
            </div>}
          {confirmationGridState?.gridData?.filter(x => x.isSelected === true)?.length > 5 && memberRegistrationRenewalSelectionState?.isArrayLoading === false && selectionView === false &&
            < div className='visible-md visible-lg' >
              <div className='col-xs-12 col-md-9 usas-extra-bottom-margin'>
                <PrimaryButton type="button" onClick={onSaveClicked}>Renew Selected Memberships</PrimaryButton>
              </div>
              <span className={"visible-xs visible-sm"}>&nbsp;&nbsp;</span>
              <div className="col-xs-12 col-md-3 usas-extra-bottom-margin">
                <SecondaryButton type="button" className={"pull-right hidden-xs hidden-sm"} onClick={onUnselectAll}>Unselect All Results</SecondaryButton>
                <SecondaryButton type="button" className={"visible-xs visible-sm"} onClick={onUnselectAll}>Unselect All Results</SecondaryButton>
              </div>
              {errorState.general &&
                <div className="col-xs-12">
                  <p className={[global.ErrorMessage, global.Multiline].join(' ')}>{errorState.general}</p>
                </div>
              }
            </div>}
          {confirmationGridState?.gridData?.filter(x => x.isSelected === true)?.length > 0 && memberRegistrationRenewalSelectionState?.isArrayLoading === false && selectionView === false &&
            < div className='hidden-md hidden-lg' >
              <div className='col-xs-12 col-md-9 usas-extra-bottom-margin'>
                <PrimaryButton type="button" onClick={onSaveClicked}>Renew Selected Memberships</PrimaryButton>
              </div>
              <span className={"visible-xs visible-sm"}>&nbsp;&nbsp;</span>
              <div className="col-xs-12 col-md-3 usas-extra-bottom-margin">
                <SecondaryButton type="button" className={"pull-right hidden-xs hidden-sm"} onClick={onUnselectAll}>Unselect All Results</SecondaryButton>
                <SecondaryButton type="button" className={"visible-xs visible-sm"} onClick={onUnselectAll}>Unselect All Results</SecondaryButton>
              </div>
              {errorState.general &&
                <div className="col-xs-12">
                  <p className={[global.ErrorMessage, global.Multiline].join(' ')}>{errorState.general}</p>
                </div>
              }
            </div>}
        </div>
        : <Fragment><div className="row usas-extra-top-margin usas-extra-bottom-margin"></div></Fragment>}
      <ClubBulkRenewSelectionLargeGrid
        gridData={gridData}
        isLoading={isLoading}
        onCheckboxChange={onCheckboxChange}
        selectionView={selectionView}
      />
      <ClubBulkRenewSelectionSmallGrid
        gridData={gridData}
        isLoading={isLoading}
        onCheckboxChange={onCheckboxChange}
        selectionView={selectionView}
      />
      {selectionView === true &&
        <div className="row usas-extra-top-margin usas-extra-bottom-margin">
          {errorState.general &&
            <div className="col-xs-12">
              <p className={[global.ErrorMessage, global.Multiline].join(' ')}>{errorState.general}</p>
            </div>
          }
          <div className="col-xs-12 col-md-4 usas-extra-top-margin">
            <PrimaryButton type="button" onClick={onOpenMemberTypeSelectionPopUpClicked}>Add Selected to Renew</PrimaryButton><span className={"visible-xs visible-sm"}>&nbsp;&nbsp;</span>
          </div>
          <div className="col-xs-12 col-md-8 usas-extra-top-margin">
            <SecondaryButton className={"pull-right hidden-xs hidden-sm"} type="button" onClick={onUnselectAll}>Unselect All Results</SecondaryButton>
            <span className={"pull-right hidden-xs hidden-sm"}>&nbsp;</span>
            <PrimaryButton className={"pull-right hidden-xs hidden-sm"} type="button" onClick={onSelectAll}>Select All Results</PrimaryButton>
            <PrimaryButton className={"visible-xs visible-sm"} type="button" onClick={onSelectAll}>Select All Results</PrimaryButton>
            <span className={"visible-xs visible-sm"}>&nbsp;</span>
            <SecondaryButton className={"visible-xs visible-sm"} type="button" onClick={onUnselectAll}>Unselect All Results</SecondaryButton>
            <span className={"visible-xs visible-sm"}>&nbsp;</span>
          </div>
        </div>}
      {selectionView === false &&
        <div className="row usas-extra-top-margin usas-extra-bottom-margin">
          {errorState.general &&
            <div className="col-xs-12">
              <p className={[global.ErrorMessage, global.Multiline].join(' ')}>{errorState.general}</p>
            </div>
          }
          <div className='col-xs-12 col-md-9 usas-extra-top-margin'>
            <PrimaryButton type="button" onClick={onSaveClicked}>Renew Selected Memberships</PrimaryButton>
          </div>
          <span className={"visible-xs visible-sm"}>&nbsp;&nbsp;</span>
          <div className="col-xs-12 col-md-3 usas-extra-top-margin">
            <SecondaryButton type="button" className={"pull-right hidden-xs hidden-sm"} onClick={onUnselectAll}>Unselect All Results</SecondaryButton>
            <SecondaryButton type="button" className={"visible-xs visible-sm"} onClick={onUnselectAll}>Unselect All Results</SecondaryButton>
          </div>
        </div>}
    </Fragment>
  )
};

export default ClubBulkRenewSelectionGrid;