import React, { Fragment } from "react";

import SATParticipantsGridLarge from "./SATParticipantsGridLarge";
import SATParticipantsGridSmall from "./SATParticipantsGridSmall";

const SATParticipantsGrid = ({ data, isLoading, onEdit = undefined, onDelete = undefined }) => (
  <Fragment>
    <SATParticipantsGridLarge data={data} isLoading={isLoading} onEdit={onEdit} onDelete={onDelete} />
    <SATParticipantsGridSmall data={data} isLoading={isLoading} onEdit={onEdit} onDelete={onDelete} />
  </Fragment>
);

export default SATParticipantsGrid;