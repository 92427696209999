import React, { createContext, useState } from 'react';

import MemberAdministrationLscEmailData from './MemberAdministrationLscEmailData';

export const MemberAdministrationLscEmailStateContext = createContext();

const MemberAdministrationLscEmailContextProvider = ({ children }) => {
  const stateHook = useState(MemberAdministrationLscEmailData.INITIAL_STATE);

  return (
    <MemberAdministrationLscEmailStateContext.Provider value={stateHook}>
      {children}
    </MemberAdministrationLscEmailStateContext.Provider>
  );
};

export default MemberAdministrationLscEmailContextProvider;