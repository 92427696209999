//TODO move to common?
import { faCaretUp, faCaretDown, faSort } from '@fortawesome/free-solid-svg-icons';

import Icon from "../../../common/components/icons/Icon";

import SGConstants from "../../../common/utils/SGConstants";

const SisenseSGColumnIcon = ({ sortableGridState, arrayIndex, columnLabel, sortType }) => {
  if (sortableGridState.targetArrayIndex === arrayIndex) {
    if (sortableGridState.sortDirection === SGConstants.SORT_DIRECTION_ASCENDING) {
      return <Icon tooltip={`Sort ${columnLabel} By ${sortType}`} iconObj={faCaretUp} />;
    } else if (sortableGridState.sortDirection === SGConstants.SORT_DIRECTION_DESCENDING) {
      return <Icon tooltip={`Sort ${columnLabel} By ${sortType}`} iconObj={faCaretDown} />;
    }
  }

  return <Icon tooltip={`Sort ${columnLabel} By ${sortType}`} iconObj={faSort} />;
};

const SisenseSGColumnHeader = ({ sortableGridState, columnLabel, arrayIndex, sortType, onColumnClicked,
  className = undefined, style = undefined, colSpan = undefined }) => (
  <th
    className={className ? className : undefined}
    style={style ? style : undefined}
    colSpan={colSpan ? colSpan : '1'}
    onClick={() => onColumnClicked(arrayIndex, sortType)}>
    {columnLabel} <SisenseSGColumnIcon sortableGridState={sortableGridState} arrayIndex={arrayIndex} columnLabel={columnLabel} sortType={sortType} />
  </th>
);

export default SisenseSGColumnHeader;