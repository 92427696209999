import Constants from "../../../../common/utils/Constants";
import { isValidName } from "../../../../common/utils/validation";

export const localValidate = (formState) => {
  let errors = {};

  if (formState.firstOrPreferredName.trim() !== '' && !isValidName(formState.firstOrPreferredName.trim())) {
    errors.firstOrPreferredName = 'First or Preferred Name cannot contain numbers, special characters, or exceed 100 characters';
  }

  if (formState.lastName.trim() !== '' && !isValidName(formState.lastName.trim())) {
    errors.lastName = 'Last Name cannot contain numbers, special characters, or exceed 100 characters';
  }

  if (formState.practiceGroupName.trim() !== '') {
    if (formState.practiceGroupId === Constants.DEFAULT_ID || formState.practiceGroupId === '') {
      errors.practiceGroupId = 'Must be a valid Practice Group';
    }
  }

  if (formState.siteGroupName.trim() !== '') {
    if (formState.siteGroupId === Constants.DEFAULT_ID || formState.siteGroupId === '') {
      errors.siteGroupId = 'Must be a valid Site Group';
    }
  }

  return errors;
};

const ClubNonAthleteRosterFormValidation = async (formState) => {
  let errors = localValidate(formState);

  return errors ? errors : {};
};

export default ClubNonAthleteRosterFormValidation;