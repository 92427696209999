import React, { Fragment } from 'react';

import useClubBulkRenewNonAthletes from './UseClubBulkRenewNonAthletes';

import ClubBulkRenewSelectionNonAthletes from './ClubBulkRenewSelectionNonAthletes';
import ClubBulkRenewRenewalConfirmation from './ClubBulkRenewRenewalConfirmation';

import ClubBulkRenewSelectedReadOnlyGrid from './grids/ClubBulkRenewSelectedReadOnlyGrid';
import ClubBulkRenewMembersReadOnlyGrid from './grids/ClubBulkRenewMembersReadOnlyGrid';

import CurrentSeasonOfferingDropdown from '../../../components/dropdowns/currentSeasonOfferingDropdown/CurrentSeasonOfferingDropdown';

import Headings from '../../../../common/components/headings/Headings';
import PopUpModal from '../../../../common/components/dialogs/PopUpModal';
import PrimaryButton from '../../../../common/components/buttons/PrimaryButton';
import SecondaryButton from '../../../../common/components/buttons/SecondaryButton';
import YesNoSwitch from '../../../../common/components/yesNoSwitch/YesNoSwitch';
import ReadOnly from '../../../../common/components/readOnly/ReadOnly';
import ActionIntraPageButton from '../../../../common/components/buttons/ActionIntraPageButton';

import style from './ClubBulkRenew.module.css';

import Constants from '../../../../common/utils/Constants';
import { formatDate } from '../../../../common/utils/DateFunctions';

const ClubBulkRenewNonAthletes = () => {
  const { baseUsasClubState, memberRegistrationRenewalSelectionState, previousSeasonOfferingState, currentSeasonOfferingState, gridState, selectionChangesState,
    renewalChangesState, onSelectAll, onUnselectAll, onSaveClicked, onCheckboxChange, onFilterClicked,
    onClearFilterClicked, onFormValueChanged, onValueTextPairChanged, formState, errorState,
    competitionGenderOptions, onOpenRenewPopUpClicked, modalState, onModalCanceled, onSwitchViewClicked,
    confirmationGridState, onOpenMemberTypeSelectionPopUpClicked, memberTypeSelectionModalState,
    memberTypeSelectionFormState, setMemberTypeSelectionFormState, memberTypeSelectionErrorState,
    onMemberTypeSelectionContinueClicked, onMemberTypeSelectionModalCanceled, renewalConfirmationModalState,
    onRenewalConfirmationContinueClicked, onRenewalConfirmationModalCanceled, orgUnitBulkRenewalOptInState, optInFormState,
    onOptInFormValueChanged, onOrgUnitBulkRenewalSaveClicked, onNextOptInFormValueChanged } = useClubBulkRenewNonAthletes();

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>Bulk Renew Non-Athlete Memberships</Headings.H3>
        </div>
        <div className="col-xs-12 usas-extra-top-margin"></div>
        <div className="col-xs-12 usas-extra-top-margin"></div>
      </div >
      {orgUnitBulkRenewalOptInState.objData && orgUnitBulkRenewalOptInState.objData.bulkRenewalPeriod?.length > 1 &&
        <Fragment>
          <div className={style.SectionBorder}>
            <div className='row'>
              <div className="col-xs-12 usas-extra-top-margin">
                <Headings.H5>Upcoming Registration Year: {formatDate(orgUnitBulkRenewalOptInState.objData.bulkRenewalPeriod?.find(x => x.orgUnitBulkRenewalOptInAvailable === true)?.startDate)} - {formatDate(orgUnitBulkRenewalOptInState.objData.bulkRenewalPeriod?.find(x => x.orgUnitBulkRenewalOptInAvailable === true)?.endDate)}</Headings.H5></div>
              <div className="col-xs-12 usas-extra-top-margin usas-extra-bottom-margin">
                <ul className="col-xs-12">
                  <li>If you select <b>'Yes'</b>, this club agrees to register all non-athletes associated with the club from {formatDate(orgUnitBulkRenewalOptInState.objData.bulkRenewalPeriod?.find(x => x.orgUnitBulkRenewalOptInAvailable === true)?.priorRegStartDate).slice(-4)} - {formatDate(orgUnitBulkRenewalOptInState.objData.bulkRenewalPeriod?.find(x => x.orgUnitBulkRenewalOptInAvailable === true)?.priorRegEndDate)?.slice(-4)} for the upcoming registration year.</li>
                  <li>If you select <b>'No'</b>, this club will require all non-athletes associated with the club from {formatDate(orgUnitBulkRenewalOptInState.objData.bulkRenewalPeriod?.find(x => x.orgUnitBulkRenewalOptInAvailable === true)?.priorRegStartDate).slice(-4)} - {formatDate(orgUnitBulkRenewalOptInState.objData.bulkRenewalPeriod?.find(x => x.orgUnitBulkRenewalOptInAvailable === true)?.priorRegEndDate)?.slice(-4)} to complete their own non-athlete membership renewals for the upcoming registration year.</li>
                  <li><b>Your selection will lock in on {formatDate(orgUnitBulkRenewalOptInState.objData.bulkRenewalPeriod?.find(x => x.orgUnitBulkRenewalOptInAvailable === true)?.optInEndDate)}. Once it is locked, you will not be able to change your selection until the following registration year.</b></li>
                </ul>
              </div>
              <form>
                <div className="col-xs-12 col-md-8 usas-extra-top-margin usas-extra-bottom-margin">
                  <YesNoSwitch
                    label={'Opt In to Bulk Renewing Non-Athletes for this Club for the Upcoming Registration Year?*'}
                    name="nextNonAthleteOfferingsOptIn"
                    checked={optInFormState.nextNonAthleteOfferingsOptIn}
                    error={optInFormState.errors?.nextNonAthleteOfferingsOptIn}
                    message={optInFormState.errors?.nextNonAthleteOfferingsOptIn}
                    onChange={(value) => { onNextOptInFormValueChanged(value); }}
                  />
                </div>
                {optInFormState.nextNonAthleteOfferingsOptInLastModifiedBy &&
                  <div className="col-xs-12 col-md-4 usas-extra-top-margin usas-extra-bottom-margin">
                    <ReadOnly
                      label={'Opt In Selection Last Modified By'}
                      name="nextNonAthleteOfferingsOptInLastModifiedBy"
                      value={optInFormState.nextNonAthleteOfferingsOptInLastModifiedBy || ''}
                    />
                  </div>}
                <div className="col-xs-12 usas-extra-top-margin usas-extra-bottom-margin">
                  <PrimaryButton type="button" onClick={onOrgUnitBulkRenewalSaveClicked}>Save</PrimaryButton>
                </div>
              </form>
            </div>
          </div>
        </Fragment>}
      <Fragment>
        <div className={style.SectionBorder}>
          <div className='row'>
            <div className="col-xs-12 usas-extra-top-margin">
              <Headings.H5>Current Registration Year: {formatDate(orgUnitBulkRenewalOptInState.objData.bulkRenewalPeriod?.find(x => x.orgUnitBulkRenewalOptInAvailable === false)?.startDate)} - {formatDate(orgUnitBulkRenewalOptInState.objData.bulkRenewalPeriod?.find(x => x.orgUnitBulkRenewalOptInAvailable === false)?.endDate)}</Headings.H5>
            </div>
            {orgUnitBulkRenewalOptInState.objData && orgUnitBulkRenewalOptInState.objData?.orgUnitBulkRenewalOptInAvailable === false ?
              <Fragment>
                <div className="col-xs-12 usas-extra-top-margin usas-extra-bottom-margin">
                  <ul className="col-xs-12">
                    <li>If you selected <b>'Yes'</b>, this club agreed to register all non-athletes associated with the club from {formatDate(orgUnitBulkRenewalOptInState.objData.bulkRenewalPeriod?.find(x => x.orgUnitBulkRenewalOptInAvailable === false)?.priorRegStartDate).slice(-4)} - {formatDate(orgUnitBulkRenewalOptInState.objData.bulkRenewalPeriod?.find(x => x.orgUnitBulkRenewalOptInAvailable === false)?.priorRegEndDate)?.slice(-4)} for the current registration year.</li>
                    <li>If you selected <b>'No'</b>, this club agreed to require all non-athletes associated with the club from {formatDate(orgUnitBulkRenewalOptInState.objData.bulkRenewalPeriod?.find(x => x.orgUnitBulkRenewalOptInAvailable === false)?.priorRegStartDate).slice(-4)} - {formatDate(orgUnitBulkRenewalOptInState.objData.bulkRenewalPeriod?.find(x => x.orgUnitBulkRenewalOptInAvailable === false)?.priorRegEndDate)?.slice(-4)} to complete their own non-athlete membership renewals for the current registration year.</li>
                    <li><b>Your selection is locked in for the current registration year.</b></li>
                  </ul>
                </div>
                <form>
                  <div className="col-xs-12 col-md-8 usas-extra-top-margin usas-extra-bottom-margin">
                    <YesNoSwitch
                      label={'Opt In to Bulk Renewing Non-Athletes for this Club?*'}
                      name="nonAthleteOfferingsOptIn"
                      checked={optInFormState.nonAthleteOfferingsOptIn}
                      error={optInFormState.errors?.nonAthleteOfferingsOptIn}
                      message={optInFormState.errors?.nonAthleteOfferingsOptIn}
                      onChange={(value) => { onOptInFormValueChanged(value); }}
                      disabled={true} />
                  </div>
                  {optInFormState.nonAthleteOfferingsOptInLastModifiedBy &&
                    <div className="col-xs-12 col-md-4 usas-extra-top-margin usas-extra-bottom-margin">
                      <ReadOnly
                        label={'Opt In Selection Last Modified By'}
                        name="nonAthleteOfferingsOptInLastModifiedBy"
                        value={optInFormState.nonAthleteOfferingsOptInLastModifiedBy || ''}
                      />
                    </div>}
                </form>
                {orgUnitBulkRenewalOptInState.objData && orgUnitBulkRenewalOptInState.objData?.nonAthleteOfferingsOptIn === true &&
                  <Fragment>
                    <div className="col-xs-12 usas-extra-top-margin"></div>
                    <div className="col-xs-12 usas-extra-top-margin">
                      <Headings.H6>This Club Has Opted In to Bulk Renewing Non-Athletes</Headings.H6>
                    </div>
                    <div className="col-xs-12">
                      <p>Please select a Renewal Membership and click 'Renew' to begin the Non-Athlete Bulk Renewal process.</p>
                    </div>
                    <form>
                      <div className="col-xs-12 col-sm-6 col-md-4 usas-extra-top-margin usas-extra-bottom-margin">
                        <CurrentSeasonOfferingDropdown
                          label={"Renewal Membership*"}
                          name={"index"}
                          value={memberTypeSelectionFormState.index}
                          error={memberTypeSelectionErrorState.index}
                          message={memberTypeSelectionErrorState.index}
                          onChange={(newValue, newValueLabel, e) =>
                            e && e.target && e.target.value && setMemberTypeSelectionFormState({ ...memberTypeSelectionFormState, index: newValue, renewalOfferingName: newValueLabel })
                          }
                          orgUnitId={baseUsasClubState.objData?.orgUnitId}
                          isAthleteRole={false}
                        />
                      </div>
                    </form>
                    <div className="col-xs-12 usas-extra-top-margin">
                      <PrimaryButton type="button" onClick={onOpenRenewPopUpClicked}>Renew</PrimaryButton>
                    </div>
                  </Fragment>}
              </Fragment> :
              <Fragment>
                <div className="col-xs-12 usas-extra-top-margin usas-extra-bottom-margin">
                  <ul className="col-xs-12">
                    <li>If you select <b>'Yes'</b>, this club is agreeing to register all non-athletes associated with the club from {formatDate(orgUnitBulkRenewalOptInState.objData.bulkRenewalPeriod?.find(x => x.orgUnitBulkRenewalOptInAvailable === false)?.priorRegStartDate).slice(-4)} - {formatDate(orgUnitBulkRenewalOptInState.objData.bulkRenewalPeriod?.find(x => x.orgUnitBulkRenewalOptInAvailable === false)?.priorRegEndDate)?.slice(-4)} for the registration year.</li>
                    <li>If you select <b>'No'</b>, this club is allowing all non-athletes associated with the club from {formatDate(orgUnitBulkRenewalOptInState.objData.bulkRenewalPeriod?.find(x => x.orgUnitBulkRenewalOptInAvailable === false)?.priorRegStartDate).slice(-4)} - {formatDate(orgUnitBulkRenewalOptInState.objData.bulkRenewalPeriod?.find(x => x.orgUnitBulkRenewalOptInAvailable === false)?.priorRegEndDate)?.slice(-4)} to complete their own non-athlete membership renewals for the registration year.</li>
                    <li><b>Your selection will lock in on {formatDate(orgUnitBulkRenewalOptInState.objData.bulkRenewalPeriod?.find(x => x.orgUnitBulkRenewalOptInAvailable === false)?.optInEndDate)}. Once it is locked, you will not be able to change your selection until the following registration year.</b></li>
                  </ul>
                </div>
                <form>
                  <div className="col-xs-12 col-md-8 usas-extra-top-margin usas-extra-bottom-margin">
                    <YesNoSwitch
                      label={'Opt In to Bulk Renewing Non-Athletes for this Club?*'}
                      name="nonAthleteOfferingsOptIn"
                      checked={optInFormState.nonAthleteOfferingsOptIn}
                      error={optInFormState.errors?.nonAthleteOfferingsOptIn}
                      message={optInFormState.errors?.nonAthleteOfferingsOptIn}
                      onChange={(value) => { onOptInFormValueChanged(value); }} />
                  </div>
                  {optInFormState.nonAthleteOfferingsOptInLastModifiedBy &&
                    <div className="col-xs-12 col-md-4 usas-extra-top-margin usas-extra-bottom-margin">
                      <ReadOnly
                        label={'Opt In Selection Last Modified By'}
                        name="nonAthleteOfferingsOptInLastModifiedBy"
                        value={optInFormState.nonAthleteOfferingsOptInLastModifiedBy || ''}
                      />
                    </div>}
                  <div className="col-xs-12 usas-extra-top-margin usas-extra-bottom-margin">
                    <PrimaryButton type="button" onClick={onOrgUnitBulkRenewalSaveClicked}>Save</PrimaryButton>
                  </div>
                </form>
                {orgUnitBulkRenewalOptInState.objData && orgUnitBulkRenewalOptInState.objData?.nonAthleteOfferingsOptIn === true &&
                  <Fragment>
                    <div className="col-xs-12 usas-extra-top-margin"></div>
                    <div className="col-xs-12 usas-extra-top-margin">
                      <Headings.H6>This Club Has Opted In to Bulk Renewing Non-Athletes</Headings.H6>
                    </div>
                    <div className="col-xs-12">
                      <p>Please select a Renewal Membership and click 'Renew' to begin the Non-Athlete Bulk Renewal process.</p>
                    </div>
                    <form>
                      <div className="col-xs-12 col-sm-6 col-md-4 usas-extra-top-margin usas-extra-bottom-margin">
                        <CurrentSeasonOfferingDropdown
                          label={"Renewal Membership*"}
                          name={"index"}
                          value={memberTypeSelectionFormState.index}
                          error={memberTypeSelectionErrorState.index}
                          message={memberTypeSelectionErrorState.index}
                          onChange={(newValue, newValueLabel, e) =>
                            e && e.target && e.target.value && setMemberTypeSelectionFormState({ ...memberTypeSelectionFormState, index: newValue, renewalOfferingName: newValueLabel })
                          }
                          orgUnitId={baseUsasClubState.objData?.orgUnitId}
                          isAthleteRole={false}
                        />
                      </div>
                    </form>
                    <div className="col-xs-12 usas-extra-top-margin">
                      <PrimaryButton type="button" onClick={onOpenRenewPopUpClicked}>Renew</PrimaryButton>
                    </div>
                  </Fragment>}
              </Fragment>}
          </div>
        </div >
      </Fragment >
      <PopUpModal
        id={"modalId"}
        className={style.PopUpModalMargin}
        widthPct={98}
        maxWidth={2560}
        title={modalState.modalTitle}
        displayPopUp={modalState.displayPopUp}
        onModalCanceled={onModalCanceled}>
        <div className='row'>
          <div className='col-xs-12 col-sm-6 col-md-9' >
            <div className={modalState.view === 'Selection' ? style.Border : 'hidden'}>
              <ClubBulkRenewSelectionNonAthletes
                competitionGenderOptions={competitionGenderOptions}
                formState={formState}
                errorState={errorState}
                onValueTextPairChanged={onValueTextPairChanged}
                onFormValueChanged={onFormValueChanged}
                onFilterClicked={onFilterClicked}
                onClearFilterClicked={onClearFilterClicked}
                gridState={gridState}
                memberRegistrationRenewalSelectionState={memberRegistrationRenewalSelectionState}
                onSelectAll={onSelectAll}
                onUnselectAll={onUnselectAll}
                onSwitchViewClicked={onSwitchViewClicked}
                onCheckboxChange={onCheckboxChange}
                onOpenMemberTypeSelectionPopUpClicked={onOpenMemberTypeSelectionPopUpClicked}
                memberTypeSelectionModalState={memberTypeSelectionModalState}
                memberTypeSelectionFormState={memberTypeSelectionFormState}
                onMemberTypeSelectionContinueClicked={onMemberTypeSelectionContinueClicked}
                onMemberTypeSelectionModalCanceled={onMemberTypeSelectionModalCanceled}
                onModalCanceled={onModalCanceled}
                renewalChangesState={renewalChangesState}
              />
            </div>
          </div>
          <div className='col-xs-12 col-sm-6 col-md-3' >
            <div className={modalState.view === 'Selection' ? style.Border : 'hidden'}>
              <div className='row usas-extra-top-margin usas-extra-bottom-margin'>
                <div className="col-xs-12 usas-extra-bottom-margin">
                  <Headings.H6>Memberships to Renew</Headings.H6>
                </div>
                {renewalChangesState.arrayData?.filter(x => x.isSelected === true)?.length > 0 &&
                  <div className="col-xs-12 usas-extra-top-margin">
                    <ActionIntraPageButton type="button" onClick={onSwitchViewClicked}>Continue to Renew</ActionIntraPageButton>
                  </div>}
                <div className="col-xs-12 usas-extra-bottom-margin">
                  <ClubBulkRenewSelectedReadOnlyGrid
                    gridData={renewalChangesState.arrayData?.filter(x => x.isSelected === true)}
                    isLoading={memberRegistrationRenewalSelectionState.isArrayLoading}
                  />
                </div>
                {renewalChangesState.arrayData?.filter(x => x.isSelected === true)?.length > 0 &&
                  <div className="col-xs-12 usas-extra-top-margin">
                    <ActionIntraPageButton type="button" onClick={onSwitchViewClicked}>Continue to Renew</ActionIntraPageButton>
                  </div>}
              </div>
            </div>
          </div>
          <div className='col-xs-12 col-sm-6 col-md-3' >
            <div className={modalState.view === 'Renew' ? style.Border : 'hidden'}>
              <div className='row usas-extra-top-margin usas-extra-bottom-margin'>
                <div className="col-xs-12 usas-extra-bottom-margin">
                  <Headings.H6>Unselected Memberships</Headings.H6>
                </div>
                <div className="col-xs-12 usas-extra-top-margin">
                  <SecondaryButton type="button" onClick={onSwitchViewClicked}>Back to Selection</SecondaryButton>
                </div>
                <div className="col-xs-12 usas-extra-bottom-margin">
                  <ClubBulkRenewMembersReadOnlyGrid
                    gridData={memberRegistrationRenewalSelectionState.arrayData?.filter(x => renewalChangesState.arrayData?.findIndex(rc => (rc.personId === x.personId && rc.offeringInstanceId === x.offeringInstanceId)) === -1)}
                    isLoading={memberRegistrationRenewalSelectionState.isArrayLoading}
                  />
                </div>
                <div className="col-xs-12 usas-extra-top-margin">
                  <SecondaryButton type="button" onClick={onSwitchViewClicked}>Back to Selection</SecondaryButton>
                </div>
              </div>
            </div>
          </div>
          <div className='col-xs-12 col-sm-6 col-md-9' >
            <div className={modalState.view === 'Renew' ? style.Border : 'hidden'}>
              <ClubBulkRenewRenewalConfirmation
                competitionGenderOptions={competitionGenderOptions}
                formState={formState}
                errorState={errorState}
                onValueTextPairChanged={onValueTextPairChanged}
                onFormValueChanged={onFormValueChanged}
                onFilterClicked={onFilterClicked}
                onClearFilterClicked={onClearFilterClicked}
                confirmationGridState={confirmationGridState}
                memberRegistrationRenewalSelectionState={memberRegistrationRenewalSelectionState}
                onSaveClicked={onSaveClicked}
                onUnselectAll={onUnselectAll}
                onSwitchViewClicked={onSwitchViewClicked}
                onCheckboxChange={onCheckboxChange}
                selectionChangesState={selectionChangesState}
                onModalCanceled={onModalCanceled}
                isAthleteRole={false}
                renewalConfirmationModalState={renewalConfirmationModalState}
                onRenewalConfirmationContinueClicked={onRenewalConfirmationContinueClicked}
                onRenewalConfirmationModalCanceled={onRenewalConfirmationModalCanceled}
                memberTypeSelectionFormState={memberTypeSelectionFormState}
              />
            </div>
          </div>
        </div>
      </PopUpModal>
      <PopUpModal
        widthPct={30}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={memberRegistrationRenewalSelectionState.isArrayLoading || previousSeasonOfferingState.isArrayLoading ||
          currentSeasonOfferingState.isArrayLoading || orgUnitBulkRenewalOptInState.isObjLoading} />
      <PopUpModal
        widthPct={30}
        maxWidth={250}
        title={Constants.SAVING_MSG}
        displayPopUp={memberRegistrationRenewalSelectionState.isSaving || orgUnitBulkRenewalOptInState.isSaving} />
    </Fragment >
  );
};

export default ClubBulkRenewNonAthletes;