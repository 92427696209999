import { useContext } from 'react';

import { LearnToSwimCurriculumStateContext } from './LearnToSwimCurriculumContextProvider';

import LearnToSwimCurriculumData from './LearnToSwimCurriculumData';

const useLearnToSwimCurriculumData = () => {
  const [ltsCurriculumState, setLTSCurriculumState] = useContext(LearnToSwimCurriculumStateContext);

  const getLTSCurriculum = () => {
    return LearnToSwimCurriculumData.getLTSCurriculumData(ltsCurriculumState, setLTSCurriculumState);
  };

  return {
    ltsCurriculumState,
    setLTSCurriculumState,
    getLTSCurriculum
  };
};

export default useLearnToSwimCurriculumData;