import { BASIC_INITIAL_STATE } from '../../../common/utils/HttpHelper';
import Constants from '../../../common/utils/Constants';

const PRE_COMP_LOCATION_OBJ = {
  locationName: '',
  website: '',
  registrationSignature: Constants.BLANK_DATE_STRING,
  registrationDate: '',
  emergencyPlanUrl: '',
  averageLessonCost: '',
  averageSessionCost: '',
  financialAidOffered: null,
  protectionPolicy: null,
  backgroundCheckRequired: null,
  preEmploymentScreening: null,
  isUsasMemberTeam: null,
  referParticipantsToUsas: null,
  instructionHoursPerSessionId: Constants.DEFAULT_ID,
  instructorStudentRatioId: Constants.DEFAULT_ID,
  preCompCurriculumId: Constants.DEFAULT_ID,
  instructorTrainingAgencyId: Constants.DEFAULT_ID,
  inServiceInstructionHoursId: Constants.DEFAULT_ID,
  inClassroomInstructionHoursId: Constants.DEFAULT_ID,
  inWaterInstructionHoursId: Constants.DEFAULT_ID,
  inShadowInstructionHoursId: Constants.DEFAULT_ID,
  inPracticeInstructionHoursId: Constants.DEFAULT_ID,
  orgUnit: {
    organizationId: Constants.DEFAULT_ID,
    orgLevelId: Constants.DEFAULT_ID,
    orgUnitName: '',
    orgUnitFacility: []
  },
  preCompCurriculum: undefined,
  inClassroomInstructionHours: undefined,
  inPracticeInstructionHours: undefined,
  inServiceInstructionHours: undefined,
  inShadowInstructionHours: undefined,
  inWaterInstructionHours: undefined,
  instructionHoursPerSession: undefined,
  instructorStudentRatio: undefined,
  instructorTrainingAgency: undefined,
  preCompLocationClub: [],
  preCompLocationOffering: [],
  preCompetitiveLocationStaff: []
};

const INITIAL_STATE = {
  ...BASIC_INITIAL_STATE
};

const PreCompLocationRegistrationData = {
  PRE_COMP_LOCATION_OBJ,
  INITIAL_STATE
};

export default PreCompLocationRegistrationData;
