import React, { Fragment } from 'react';

import ClubPreCompSearchGridLarge from './ClubPreCompSearchGridLarge';
import ClubPreCompSearchGridSmall from './ClubPreCompSearchGridSmall';

import global from '../../../../common/components/GlobalStyle.module.css';

const ClubPreCompSearchGrid = ({ data, searchPreCompState, isLoading, onAdd, usasClubPreCompLinksState, onFiltersToggle }) => (
  <Fragment>
    {isLoading === false ?
      <div className="row">
        <div className="col-xs-12 col-sm-10">
          {data.length > 499
            ? <p className={global.ErrorMessage}>More than 500 Search Results returned. Please add additional filters to narrow your search results.</p>
            : <p>{data.length === 1 ? `${data.length} Search Result` : `${data.length} Search Results`}</p>}
        </div>
        {onFiltersToggle ?
          <div className="col-xs-12 col-sm-2">
            <button className={global.ToggleButton} type="button" onClick={() => onFiltersToggle()}>{searchPreCompState.showFilters === true ? 'Hide Filters' : 'Show Filters'}</button>
          </div> : <Fragment />}
      </div> :
      <Fragment>
        <div className="row usas-extra-top-margin usas-extra-bottom-margin">
        </div>
      </Fragment >}
    <ClubPreCompSearchGridLarge data={data} isLoading={isLoading} onAdd={onAdd} usasClubPreCompLinksState={usasClubPreCompLinksState} />
    <ClubPreCompSearchGridSmall data={data} isLoading={isLoading} onAdd={onAdd} usasClubPreCompLinksState={usasClubPreCompLinksState} />
  </Fragment>
);

export default ClubPreCompSearchGrid;