import React, { Fragment } from 'react';

import { isCertExpired } from '../utils/ClubUtils'

import ShowIcon from '../../../../common/components/icons/ShowIcon';
import HideIcon from '../../../../common/components/icons/HideIcon';
import SGColumnHeader from '../../../../common/components/grids/SGColumnHeader';

import { formatPhoneNumber } from '../../../../common/utils/validation';
import { formatDate } from '../../../../common/utils/DateFunctions';
import Constants from '../../../../common/utils/Constants';
import useSortableGrid from '../../../../common/utils/UseSortableGrid';
import SGConstants from '../../../../common/utils/SGConstants';

import global from '../../../../common/components/GlobalStyle.module.css';

const DetailTableRow = ({ cert }) => (
  <tr>
    {isCertExpired(cert.courseExpirationDate) || (cert.courseCompletionDate === null && cert.courseRequired === true) ?
      <Fragment>
        <td className={global.ExpiredCert}>{cert.courseName} {cert.courseCode ? `(${cert.courseCode})` : ''}</td>
        <td className={global.ExpiredCert}>{cert.courseCompletionDate !== null && cert.coursePassed === true
          ? formatDate(cert.courseCompletionDate) === Constants.DEFAULT_MAX_DATE
            ? ' '
            : formatDate(cert.courseCompletionDate)
          : cert.courseCompletionDate === null && cert.courseRequired === false ? 'Not Required Yet' : ' '}</td>
        <td className={global.ExpiredCert}>
          {cert.courseExpirationDate !== null && cert.coursePassed === true
            ? formatDate(cert.courseExpirationDate) === Constants.DEFAULT_MAX_DATE
              ? `Doesn't Expire`
              : formatDate(cert.courseExpirationDate)
            : ' '
          }
        </td>
      </Fragment>
      :
      <Fragment>
        <td>{cert.courseName} {cert.courseCode ? `(${cert.courseCode})` : ''}</td>
        <td >{cert.courseCompletionDate !== null && cert.coursePassed === true
          ? formatDate(cert.courseCompletionDate) === Constants.DEFAULT_MAX_DATE
            ? ' '
            : formatDate(cert.courseCompletionDate)
          : cert.courseCompletionDate === null && cert.courseRequired === false ? 'Not Required Yet' : ' '}</td>
        <td>
          {cert.courseExpirationDate !== null && cert.coursePassed === true
            ? formatDate(cert.courseExpirationDate) === Constants.DEFAULT_MAX_DATE
              ? `Doesn't Expire`
              : formatDate(cert.courseExpirationDate)
            : ' '
          }
        </td>
      </Fragment>
    }
  </tr>
);

const GridRowDetailTable = ({ staff }) => (
  <table className={global.DetailTable}>
    <thead>
      <tr>
        <th>Requirement</th>
        <th>Date Completed</th>
        <th>Expiration Date</th>
      </tr>
    </thead>
    <tbody>
      {Array.isArray(staff.personRequirement) && staff.personRequirement.map((cert, i) =>
        <DetailTableRow key={i} cert={cert} />)
      }
    </tbody>
  </table>
);

const GridRow = ({ staff, expandedId, onClick }) => (
  <Fragment>
    <tr>
      <td>{staff.preferredName || staff.firstName} {staff.lastName}</td>
      <td>{formatPhoneNumber(staff.phoneNumber)}</td>
      <td>{staff.email}</td>
      <td>{staff.isInGoodStanding === true ? 'Yes' : 'No'}</td>
      <td>
        <button
          className={global.IconButtonMargin}
          type="button"
          onClick={(e) => onClick(e, staff.personId)}>
          {expandedId === staff.personId ? <HideIcon /> : <ShowIcon />}
        </button>
      </td>
    </tr>
    {Array.isArray(staff.personRequirement) && staff.personRequirement.length > 0 && staff.personId === expandedId &&
      <tr className={global.Expanded}>
        <td colSpan="5">
          <GridRowDetailTable staff={staff} />
        </td>
      </tr>}
  </Fragment>
);

const ClubStaffRequirementsLargeGrid = ({ state, expandedId, onClick }) => {
  const {
    sortableGridState,
    onColumnClicked
  } = useSortableGrid(state.arrayData);

  return (
    <table className={[global.UsasTable, 'visible-md visible-lg'].join(' ')}>
      <thead>
        <tr>
          <SGColumnHeader sortableGridState={sortableGridState} columnLabel={'Club Staff Name'} columnField={'lastName'}
            sortType={SGConstants.SORT_TYPE_ALPHABETICAL} onColumnClicked={onColumnClicked} />
          <SGColumnHeader sortableGridState={sortableGridState} columnLabel={'Phone Number'} columnField={'phoneNumber'}
            sortType={SGConstants.SORT_TYPE_NUMERICAL} onColumnClicked={onColumnClicked} />
          <SGColumnHeader sortableGridState={sortableGridState} columnLabel={'Email'} columnField={'email'}
            sortType={SGConstants.SORT_TYPE_ALPHABETICAL} onColumnClicked={onColumnClicked} />
          <SGColumnHeader sortableGridState={sortableGridState} columnLabel={'Is In Good Standing?'} columnField={'isInGoodStanding'}
            sortType={SGConstants.SORT_TYPE_NUMERICAL} onColumnClicked={onColumnClicked} />
          <th></th>
        </tr>
      </thead>
      <tbody>
        {state.isArrayLoading === true
          ? <tr><td colSpan="5">Loading...</td></tr>
          : Array.isArray(sortableGridState.sortedGridData) && sortableGridState.sortedGridData.length > 0
            ? sortableGridState.sortedGridData.map((staff, i) => <GridRow key={i} staff={staff} expandedId={expandedId} onClick={onClick} />)
            : <tr>
              <td colSpan="5">No Staff Requirements</td>
            </tr>}
      </tbody>
    </table>
  );
};

export default ClubStaffRequirementsLargeGrid;