export const localValidate = (formState) => {
  let errors = {};

  if (formState.orgUnitId === '') {
    errors.orgUnitId = 'Club Name is Required'
  }

  return errors;
};

const SafeSportSearchValidation = async (formState) => {
  let errors = localValidate(formState);

  return errors ? errors : {};
};

export default SafeSportSearchValidation;