import React, { Fragment } from 'react';

import Headings from '../../../../common/components/headings/Headings';
import SecondaryButton from '../../../../common/components/buttons/SecondaryButton';

const Question = ({ question }) => {
  return (
    <div dangerouslySetInnerHTML={{ __html: question }} />
  );
};

const ElsewhereInSwimsView = ({ programLevel, categoryMeasure, onRedirect }) => {

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>{categoryMeasure.measureName}</Headings.H3>
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12">
          {categoryMeasure.question ?
            <Question question={categoryMeasure.question} /> : <Fragment />}
        </div>
      </div>
      <div className="row usas-extra-top-margin">
        <div className="col-xs-12">
          Collected Elsewhere in SWIMS
        </div>
      </div>
      <div className="row usas-extra-top-margin usas-extra-bottom-margin">
        <div className="col-xs-12">
          <SecondaryButton type="button" onClick={() => onRedirect(false)}>Back</SecondaryButton>
        </div>
      </div>
    </Fragment>
  );
};

export default ElsewhereInSwimsView;