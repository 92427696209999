import { BASIC_INITIAL_STATE } from '../../../utils/HttpHelper';

import getLscStaff from './GetLscStaffData';
import postLscStaff from '../../orgUnitStaff/PostOrgUnitStaffData';
import putLscStaff from '../../orgUnitStaff/PutOrgUnitStaffData';
import deleteLscStaff from '../../orgUnitStaff/DeletePersonOrgRoleDurationData';

const INITIAL_STATE = {
  ...BASIC_INITIAL_STATE
};

const MemberAdministrationLscStaffData = {
  INITIAL_STATE,
  getLscStaff,
  postLscStaff,
  putLscStaff,
  deleteLscStaff
};

export default MemberAdministrationLscStaffData;