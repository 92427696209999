import { useEffect } from "react";

import useClubPerformanceData from "../../state/clubPerformance/UseClubPerformanceData";

import useNavRoutes from "../../../common/state/security/UseNavRoutes";
import useSelectOrgUnitData from "../../../common/state/selectOrgUnit/UseSelectOrgUnitData";

import { useNavigate } from "../../../common/wrappers/ReactRouterDom";

const useClubPerformanceContextView = () => {
  const navigate = useNavigate();
  const { navRoutes } = useNavRoutes();
  const { getDecryptedOrgUnitId } = useClubPerformanceData();
  const { selectOrgUnitState, resetSelectOrgUnitState } = useSelectOrgUnitData();

  const onHomeClicked = () => {
    if (navRoutes.CLUB_PERFORMANCE_ATHLETE_TIMES?.route) {
      navigate(navRoutes.CLUB_PERFORMANCE_ATHLETE_TIMES.route);
    }
  };

  const onBackToSelectionClicked = () => {
    resetSelectOrgUnitState();

    if (navRoutes.CLUB_PERFORMANCE_SELECTION?.route) {
      navigate(navRoutes.CLUB_PERFORMANCE_SELECTION.route);
    }
  };

  useEffect(() => {
    if (selectOrgUnitState.orgUnitId) {
      const getDecryptedOrgUnitIdPromise = getDecryptedOrgUnitId(selectOrgUnitState.orgUnitId);

      if (getDecryptedOrgUnitIdPromise ?? false) {
        getDecryptedOrgUnitIdPromise.then((newState) => {
          console.log(newState.objData);
        }).catch((e) => {
          console.log(e);
        })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    HOME_ROUTE: navRoutes.CLUB_PERFORMANCE_ATHLETE_TIMES?.route,
    isError: !selectOrgUnitState.orgUnitId,
    userClub: selectOrgUnitState.orgUnitName,
    showBackToSelection: selectOrgUnitState.showBackToSelection,
    onHomeClicked,
    onBackToSelectionClicked
  };
};

export default useClubPerformanceContextView;