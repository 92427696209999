import { Fragment } from "react";

import ClubBulkRenewFiltersForm from "./forms/ClubBulkRenewFiltersForm";

import ClubBulkRenewSelectionGrid from "./grids/ClubBulkRenewSelectionGrid";

import PrimaryButton from "../../../../common/components/buttons/PrimaryButton";
import SecondaryButton from "../../../../common/components/buttons/SecondaryButton";
import PopUpModal from "../../../../common/components/dialogs/PopUpModal";
import Headings from "../../../../common/components/headings/Headings";

import Constants from "../../../../common/utils/Constants";

import global from '../../../../common/components/GlobalStyle.module.css';

const ClubBulkRenewSelectionNonAthletes = ({ competitionGenderOptions, formState, errorState, onValueTextPairChanged, onFormValueChanged, onFilterClicked,
    onClearFilterClicked, gridState, memberRegistrationRenewalSelectionState, onSelectAll, onUnselectAll, onSaveClicked, onSwitchViewClicked, onCheckboxChange,
    onOpenMemberTypeSelectionPopUpClicked, memberTypeSelectionModalState, memberTypeSelectionFormState, onMemberTypeSelectionContinueClicked, onMemberTypeSelectionModalCanceled, onModalCanceled, renewalChangesState }) => {

    return (
        <Fragment>
            <ClubBulkRenewFiltersForm
                title={'Select Memberships'}
                competitionGenderOptions={competitionGenderOptions}
                formState={formState}
                errorState={errorState}
                onValueTextPairChanged={onValueTextPairChanged}
                onFormValueChanged={onFormValueChanged}
                onFilterClicked={onFilterClicked}
                onClearFilterClicked={onClearFilterClicked}
                isAthleteRole={false}
            />
            {gridState.gridData.length > 5 && memberRegistrationRenewalSelectionState.isArrayLoading === false &&
                < div className='visible-md visible-lg' >
                    <div className="row usas-extra-top-margin usas-extra-bottom-margin">
                        <div className="col-xs-12 usas-extra-top-margin">
                            <SecondaryButton type="button" onClick={onModalCanceled}>Exit Bulk Renew</SecondaryButton>
                        </div>
                    </div>
                </div >}
            {gridState.gridData.length > 0 && memberRegistrationRenewalSelectionState.isArrayLoading === false &&
                < div className='hidden-md hidden-lg' >
                    <div className="row usas-extra-top-margin usas-extra-bottom-margin">
                        <div className="col-xs-12 usas-extra-top-margin">
                            <SecondaryButton type="button" onClick={onModalCanceled}>Exit Bulk Renew</SecondaryButton>
                        </div>
                    </div>
                </div >}
            <div className="row usas-extra-top-margin">
                <div className="col-xs-12 usas-extra-top-margin">
                    <Headings.H4>Currently Bulk Renewing: {memberTypeSelectionFormState.renewalOfferingName || ''} Memberships</Headings.H4>
                </div>
            </div>
            <div className='row usas-extra-top-margin'>
                <div className="col-xs-12">
                    <ClubBulkRenewSelectionGrid
                        gridData={gridState.gridData}
                        isLoading={memberRegistrationRenewalSelectionState.isArrayLoading}
                        onCheckboxChange={onCheckboxChange}
                        selectionView={true}
                        onOpenMemberTypeSelectionPopUpClicked={onOpenMemberTypeSelectionPopUpClicked}
                        onSelectAll={onSelectAll}
                        onUnselectAll={onUnselectAll}
                        onSaveClicked={onSaveClicked}
                        memberRegistrationRenewalSelectionState={memberRegistrationRenewalSelectionState}
                        errorState={errorState}
                    />
                </div>
            </div>
            <div className="row usas-extra-top-margin usas-extra-bottom-margin">
                <div className="col-xs-12 usas-extra-top-margin">
                    <SecondaryButton type="button" onClick={onModalCanceled}>Exit Bulk Renew</SecondaryButton>
                </div>
            </div>
            <div className="row usas-extra-top-margin">
                <div className="col-xs-12 usas-extra-top-margin">
                    <Headings.H4>Currently Bulk Renewing: {memberTypeSelectionFormState.renewalOfferingName || ''} Memberships</Headings.H4>
                </div>
            </div>
            <PopUpModal
                widthPct={90}
                maxWidth={720}
                title={memberTypeSelectionModalState.modalTitle}
                displayPopUp={memberTypeSelectionModalState.displayPopUp}
                onModalCanceled={onMemberTypeSelectionModalCanceled}>
                <form>
                    <div className="row">
                        <div className="col-xs-12 usas-extra-bottom-margin">
                            <label className={global.UsasLabel} htmlFor={"renewalOfferingName"}>{"Renewal Membership"}</label>
                            <Headings.H4><span style={{ margin: '4px' }}>{memberTypeSelectionFormState.renewalOfferingName}</span></Headings.H4>
                        </div>
                        <div className="col-xs-12 usas-extra-top-margin">
                            <PrimaryButton type="button" onClick={onMemberTypeSelectionContinueClicked}>Add to Renew with Selected Membership</PrimaryButton>&nbsp;
                            <SecondaryButton type="button" onClick={onMemberTypeSelectionModalCanceled}>Cancel</SecondaryButton>
                        </div>
                    </div>
                </form>
            </PopUpModal>
            <PopUpModal
                widthPct={30}
                maxWidth={250}
                title={Constants.LOADING_MSG}
                displayPopUp={memberRegistrationRenewalSelectionState.isArrayLoading} />
        </Fragment>)
};

export default ClubBulkRenewSelectionNonAthletes;