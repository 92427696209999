import { isValid5DigitZip, isValid9DigitZip, isValidAddressLine, isValidDate } from '../../../utils/validation';
import Constants from '../../../utils/Constants';

export const TravelTripAttributesFormValidation = (formState) => {
  let errors = {};

  const startDate = new Date(formState.startDate);
  const endDate = new Date(formState.endDate);

  if (formState.startDate === Constants.BLANK_DATE_STRING || formState.startDate === '') {
    errors.startDate = 'Start Date is required';
  } else if (!isValidDate(formState.startDate)) {
    errors.startDate = 'Start Date must be a valid date';
  } else if (endDate < startDate || formState.startDate === formState.endDate) {
    errors.startDate = 'Start Date must be before the End Date';
  }

  if (formState.endDate === Constants.BLANK_DATE_STRING || formState.endDate === '') {
    errors.endDate = 'End Date is required';
  } else if (!isValidDate(formState.endDate)) {
    errors.endDate = 'End Date must be a valid date';
  } else if (endDate < startDate || formState.startDate === formState.endDate) {
    errors.endDate = 'End Date must be after the Start Date';
  }

  if (formState.city.trim().length === 0) {
    errors.city = 'City is required';
  } else if (!isValidAddressLine(formState.city.trim())) {
    errors.city = 'City cannot exceed 100 characters. Letters, numbers, number signs, spaces, periods, dashes, slashes, and apostrophes are allowed.';
  }

  if (formState.stateName === '') {
    errors.stateCode = 'State is required';
  } else if (formState.stateCode === Constants.DEFAULT_ID || formState.stateCode === '') {
    errors.stateCode = 'State must be valid';
  }

  if (formState.zip.trim() === '') {
    errors.zip = 'Zip Code is required';
  } else if (!isValid5DigitZip(formState.zip.trim()) && !isValid9DigitZip(formState.zip.trim())) {
    errors.zip = 'Zip Code must be 5 digits, or 9 digits in XXXXX-XXXX format';
  }

  if (formState.tripPurpose.trim() === '') {
    errors.tripPurpose = 'Trip Purpose is required';
  }

  return errors;
};

export default TravelTripAttributesFormValidation;