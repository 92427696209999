import { BASIC_INITIAL_STATE } from '../../utils/HttpHelper';

import getOfferingTypesForRegistration from './GetOfferingTypesForRegistrationData';
import getOfferingTypesForClubRegistration from './GetOfferingTypesForClubRegistrationData';
import postMemberRegistrationUpgrade from './PostMemberRegistrationUpgradeData';

const INITIAL_STATE = {
  ...BASIC_INITIAL_STATE,
  orgUnitId: null,
  age: null,
  category: null,
  personId: null
};


const OfferingTypeData = {
  INITIAL_STATE,
  getOfferingTypesForRegistration,
  getOfferingTypesForClubRegistration,
  postMemberRegistrationUpgrade
};

export default OfferingTypeData;