import { Fragment } from "react";

import { PieChart } from "@sisense/sdk-ui";
import { filterFactory } from "@sisense/sdk-data";

import useMeetSummaryWidget from "./UseMeetSummaryWidget";

import * as DM from '../../../../../../usas-times-data';

import Spinner from "../../../../../../common/components/spinners/Spinner";
import Headings from "../../../../../../common/components/headings/Headings";

import ToIntIfInt from "../../../../../../common/utils/ToIntIfInt";

import global from '../../../../../../common/components/GlobalStyle.module.css';

const MeetSummaryLargePieChartWidget = ({ dashboardOid, widgetOid }) => {
  const { location, isError, isLoading, widget } = useMeetSummaryWidget(dashboardOid, widgetOid);

  return (
    <Fragment>
      <div className="col-xs-12 col-lg-6">
        {isError
          ? <p className={global.ErrorMessage}>Error...</p>
          : isLoading
            ? <Spinner />
            : widget
              ?
              <Fragment>
                <span style={{ textAlign: 'center' }}>
                  <Headings.H6>{widget.title || ''}</Headings.H6>
                </span>
                <PieChart {...widget.getChartProps()
                }
                  filters={[filterFactory.equals(DM.Meet.MeetKey, ToIntIfInt(location.state?.meetKey))]}
                />
              </Fragment>
              : <Fragment />
        }
      </div>
    </Fragment>
  );
};

export default MeetSummaryLargePieChartWidget;