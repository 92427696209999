import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { Navigate } from '../../../common/wrappers/ReactRouterDom';

import ClubSwimAThonContextView from './ClubSwimAThonContextView';

import ClubSwimAThonContract from './clubSwimAThonContract/ClubSwimAThonContract';
import ClubSwimAThonContractDetail from './clubSwimAThonContract/ClubSwimAThonContractDetail';
import ClubSwimAThonResult from './clubSwimAThonResult/ClubSwimAThonResult';
import ClubSwimAThonLeaderboard from './clubSwimAThonLeaderboard/ClubSwimAThonLeaderboard';
import ClubSwimAThonResultDetail from './clubSwimAThonResult/ClubSwimAThonResultDetail';
import ClubSwimAThonParticipant from './clubSwimAThonResult/ClubSwimAThonParticipant';
import ClubSwimAThonParticipantDetail from './clubSwimAThonResult/ClubSwimAThonParticipantDetail';
import ClubSwimAThonSearch from './clubSwimAThonSearch/ClubSwimAThonSearch';
import ClubSwimAThonPayments from './clubSwimAThonPayments/ClubSwimAThonPayments';
import ClubSwimAThonPaymentsHQ from './clubSwimAThonPayments/ClubSwimAThonPaymentsHQ';

import OrgUnitSelection from './orgUnitSelection/OrgUnitSelection';

import PrizeContextProvider from '../../state/prize/PrizeContextProvider';
import SwimAThonContextProvider from '../../state/swimAThon/SwimAThonContextProvider';
import SwimAThonFiltersContextProvider from '../../state/swimAThon/SwimAThonFiltersContextProvider';
import SwimAThonResultContextProvider from '../../state/swimAThon/swimAThonResult/SwimAThonResultContextProvider';

import LoadingModal from '../../../common/components/dialogs/LoadingModal';
import SecuredRouteElement from '../../../common/components/security/SecuredRouteElement';

import useNavRoutes from '../../../common/state/security/UseNavRoutes';
import SelectOrgUnitContextProvider from '../../../common/state/selectOrgUnit/SelectOrgUnitContextProvider';
import PersonOrgRoleContextProvider from '../../../common/state/personOrgRole/PersonOrgRoleContextProvider';

const ContextRoutes = ({ navRoutes }) => {
  return (
    <ClubSwimAThonContextView>
      <Routes>
        <Route path={navRoutes.CLUB_SWIMATHON_CONTRACTS?.path}
          element={
            <SecuredRouteElement
              navRoute={navRoutes.CLUB_SWIMATHON_CONTRACTS}
              editElement={<ClubSwimAThonContract />} />
          } />
        <Route path={navRoutes.CLUB_SWIMATHON_CONTRACTS_DETAIL_ADMIN?.path}
          element={
            <SecuredRouteElement
              navRoute={navRoutes.CLUB_SWIMATHON_CONTRACTS_DETAIL_ADMIN}
              editElement={<ClubSwimAThonContractDetail canEditSubmittedContract={true} canEditFulfillmentDate={true} canEditSignatureDate={true} />} />
          } />
        <Route path={navRoutes.CLUB_SWIMATHON_CONTRACTS_DETAIL?.path}
          element={
            <SecuredRouteElement
              navRoute={navRoutes.CLUB_SWIMATHON_CONTRACTS_DETAIL}
              editElement={<ClubSwimAThonContractDetail canEditSubmittedContract={false} canEditFulfillmentDate={false} canEditSignatureDate={false} />} />
          } />
        <Route path={navRoutes.CLUB_SWIMATHON_RESULTS?.path}
          element={
            <SecuredRouteElement
              navRoute={navRoutes.CLUB_SWIMATHON_RESULTS}
              editElement={<ClubSwimAThonResult />} />
          } />
        <Route path={navRoutes.CLUB_SWIMATHON_RESULTS_DETAIL_ADMIN?.path}
          element={
            <SecuredRouteElement
              navRoute={navRoutes.CLUB_SWIMATHON_RESULTS_DETAIL_ADMIN}
              editElement={<ClubSwimAThonResultDetail canEditSubmittedResult={true} />} />
          } />
        <Route path={navRoutes.CLUB_SWIMATHON_RESULTS_DETAIL?.path}
          element={
            <SecuredRouteElement
              navRoute={navRoutes.CLUB_SWIMATHON_RESULTS_DETAIL}
              editElement={<ClubSwimAThonResultDetail canEditSubmittedResult={false} />} />
          } />
        <Route path={navRoutes.CLUB_SWIMATHON_RESULTS_DETAIL_PRIZEWINNERS?.path}
          element={
            <SecuredRouteElement
              navRoute={navRoutes.CLUB_SWIMATHON_RESULTS_DETAIL_PRIZEWINNERS}
              editElement={<ClubSwimAThonParticipant />} />
          } />
        <Route path={navRoutes.CLUB_SWIMATHON_RESULTS_DETAIL_PRIZEWINNERS_DETAIL?.path}
          element={
            <SecuredRouteElement
              navRoute={navRoutes.CLUB_SWIMATHON_RESULTS_DETAIL_PRIZEWINNERS_DETAIL}
              editElement={<ClubSwimAThonParticipantDetail />} />
          } />
        <Route path={navRoutes.CLUB_SWIMATHON_RESULTS_PAYMENTS?.path}
          element={
            <SecuredRouteElement
              navRoute={navRoutes.CLUB_SWIMATHON_RESULTS_PAYMENTS}
              editElement={<ClubSwimAThonPaymentsHQ />}
              readOnlyElement={<ClubSwimAThonPayments />} />
          } />
        <Route path={navRoutes.CLUB_SWIMATHON_LEADERBOARD?.path}
          element={
            <SecuredRouteElement
              navRoute={navRoutes.CLUB_SWIMATHON_LEADERBOARD}
              editElement={<ClubSwimAThonLeaderboard />} />
          } />
      </Routes>
    </ClubSwimAThonContextView>
  );
};

const BasicRoutesContextProviders = ({ children }) => (
  <SelectOrgUnitContextProvider>
    <PersonOrgRoleContextProvider>
      <SwimAThonContextProvider>
        <SwimAThonFiltersContextProvider>
          <SwimAThonResultContextProvider>
            <PrizeContextProvider>
              {children}
            </PrizeContextProvider>
          </SwimAThonResultContextProvider>
        </SwimAThonFiltersContextProvider>
      </SwimAThonContextProvider>
    </PersonOrgRoleContextProvider>
  </SelectOrgUnitContextProvider>
);

const BasicRoutes = () => {
  const { navRoutes, isReadyToRoute } = useNavRoutes();

  if (isReadyToRoute !== true) {
    return <LoadingModal />;
  }

  return (
    <Routes>
      <Route path={navRoutes.CLUB_SWIMATHON_SEARCH?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes.CLUB_SWIMATHON_SEARCH}
            editElement={<ClubSwimAThonSearch />} />
        } />
      <Route path={navRoutes.CLUB_SWIMATHON_SELECTION?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes.CLUB_SWIMATHON_SELECTION}
            editElement={<OrgUnitSelection />} />
        } />
      <Route path={'/*'} element={<ContextRoutes navRoutes={navRoutes} />} />
      <Route path={'/'} element={<Navigate to={navRoutes.CLUB_SWIMATHON_SEARCH?.path} replace />} />
    </Routes>
  );
};

const ClubSwimAThon = () => (
  <BasicRoutesContextProviders>
    <BasicRoutes />
  </BasicRoutesContextProviders>
);

export default ClubSwimAThon;