import { Fragment } from 'react';
import useClubTouchpoint from './UseClubTouchpoints';
import Headings from '../../../../common/components/headings/Headings';
import PopUpModal from '../../../../common/components/dialogs/PopUpModal';
import Constants from '../../../../common/utils/Constants';

import ClubTouchpointsGrid from './ClubTouchpointsGrid';
import ActionIntraPageButton from '../../../../common/components/buttons/ActionIntraPageButton';

const ClubTouchpoints = () => {
  const {
    baseUsasClubState,
    clubTouchpointState,
    onAdd,
    onEdit,
    onDelete
  } = useClubTouchpoint();

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>Club Touchpoints</Headings.H3>
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12 usas-extra-top-margin">
          <ActionIntraPageButton onClick={onAdd}>Add Touchpoint</ActionIntraPageButton>
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12 usas-extra-top-margin usas-extra-bottom-margin">
          <ClubTouchpointsGrid state={clubTouchpointState} onEdit={onEdit} onDelete={onDelete} />
        </div>
      </div>
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={baseUsasClubState.isObjLoading || clubTouchpointState.isArrayLoading || clubTouchpointState.isSaving} />
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.SAVING_MSG}
        displayPopUp={clubTouchpointState.isSaving} />
    </Fragment>);
}

export default ClubTouchpoints;