import React, { Fragment } from 'react';

import useLearnToSwimRegistrationProviderInfo from './UseLearnToSwimRegistrationProviderInfo';

import LearnToSwimRegistrationLeftNav from '../components/leftNav/LearnToSwimRegistrationLeftNav';
import RegistrationProviderInfoForm from '../components/forms/RegistrationProviderInfoForm';
import { PROVIDER_INFO_NAME } from '../components/leftNav/LearnToSwimRegistrationLeftNavConstants';

import PopUpModal from '../../../../common/components/dialogs/PopUpModal';
import PrimaryButton from '../../../../common/components/buttons/PrimaryButton';
import SecondaryButton from '../../../../common/components/buttons/SecondaryButton';

import Constants from '../../../../common/utils/Constants';

const LearnToSwimRegistrationProviderInfo = () => {
  const {
    isLoading,
    ltsProviderRegistrationState,
    modalState,
    onSubmitFormCallback,
    onExitClicked,
    onModalConfirmClicked,
    onModalCancelClicked
  } = useLearnToSwimRegistrationProviderInfo();

  return (
    <Fragment>
      <LearnToSwimRegistrationLeftNav viewName={PROVIDER_INFO_NAME} viewTitle={'Provider Info'}>
        <RegistrationProviderInfoForm
          secondaryButtonText={'Exit'}
          providerObj={ltsProviderRegistrationState.objData}
          onSubmitFormCallback={onSubmitFormCallback}
          onSecondaryButtonClicked={onExitClicked} />
        <PopUpModal
          widthPct={90}
          maxWidth={720}
          title="Exit Learn To Swim Provider Registration"
          displayPopUp={modalState.displayModal}
          onModalCanceled={onModalCancelClicked}>
          <div className="row">
            <div className="col-xs-12">
              <p>Are you sure you want exit Learn To Swim Provider Registration?</p>
              <p>All progress will be lost.</p>
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12 usas-extra-top-margin">
              <PrimaryButton type="button" onClick={onModalConfirmClicked}>Yes, Exit Registration</PrimaryButton>&nbsp;
              <SecondaryButton type="button" onClick={onModalCancelClicked}>Cancel</SecondaryButton>
            </div>
          </div>
        </PopUpModal>
        <PopUpModal
          widthPct={50}
          maxWidth={250}
          title={Constants.LOADING_MSG}
          displayPopUp={isLoading} />
      </LearnToSwimRegistrationLeftNav>
    </Fragment>
  );
};

export default LearnToSwimRegistrationProviderInfo;