import React, { createContext, useState } from 'react';

import CategoryMeasureScoreData from './CategoryMeasureScoreData';

export const CategoryMeasureScoreStateContext = createContext();

const CategoryMeasureScoreContextProvider = ({ children }) => {
  const stateHook = useState(CategoryMeasureScoreData.INITIAL_STATE);

  return (
    <CategoryMeasureScoreStateContext.Provider value={stateHook}>
      {children}
    </CategoryMeasureScoreStateContext.Provider>
  );
};

export default CategoryMeasureScoreContextProvider;