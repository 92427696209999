import React, { createContext, useState } from 'react';

import ClubRegistrationNewData from './ClubRegistrationNewData';

export const ClubRegistrationNewStateContext = createContext();

const ClubRegistrationNewContextProvider = ({ children }) => {
  const stateHook = useState(ClubRegistrationNewData.INITIAL_STATE);

  return (
    <ClubRegistrationNewStateContext.Provider value={stateHook}>
        {children}
    </ClubRegistrationNewStateContext.Provider>
  );
};

export default ClubRegistrationNewContextProvider;