/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';

import SGColumnHeader from '../../../../../common/components/grids/SGColumnHeader';

import useSortableGrid from '../../../../../common/utils/UseSortableGrid';
import SGConstants from '../../../../../common/utils/SGConstants';
import { formatPhoneNumber } from '../../../../../common/utils/validation';

import global from '../../../../../common/components/GlobalStyle.module.css';

const GridRow = ({ ltsProvider, onEditClicked }) => (
  <tr>
    <td><a onClick={() => onEditClicked(ltsProvider.learnToSwimProviderId)}>{ltsProvider.entityName || ''}</a></td>
    <td>{ltsProvider.tradeName || ''}</td>
    <td>{ltsProvider.contactFirstName || ''} {ltsProvider.contactLastName || ''}</td>
    <td>{ltsProvider.contactEmail || ''}</td>
    <td>{ltsProvider.contactPhoneNumber ? formatPhoneNumber(ltsProvider.contactPhoneNumber) : ''}</td>
  </tr>
);

const ProviderSearchGridLarge = ({ data, isLoading, onEditClicked }) => {
  const {
    sortableGridState,
    onColumnClicked
  } = useSortableGrid(data);

  return (
    <table className={[global.UsasTable, 'visible-lg visible-md'].join(' ')}>
      <thead>
        <tr>
          <SGColumnHeader sortableGridState={sortableGridState} columnLabel={'Business Legal Name'} columnField={'entityName'}
            sortType={SGConstants.SORT_TYPE_ALPHABETICAL} onColumnClicked={onColumnClicked} />
          <SGColumnHeader sortableGridState={sortableGridState} columnLabel={'Trade Name/DBA'} columnField={'tradeName'}
            sortType={SGConstants.SORT_TYPE_ALPHABETICAL} onColumnClicked={onColumnClicked} />
          <SGColumnHeader sortableGridState={sortableGridState} columnLabel={'Contact'} columnField={'contactLastName'}
            sortType={SGConstants.SORT_TYPE_ALPHABETICAL} onColumnClicked={onColumnClicked} />
          <SGColumnHeader sortableGridState={sortableGridState} columnLabel={'Contact Email'} columnField={'contactEmail'}
            sortType={SGConstants.SORT_TYPE_ALPHABETICAL} onColumnClicked={onColumnClicked} />
          <SGColumnHeader sortableGridState={sortableGridState} columnLabel={'Contact Phone'} columnField={'contactPhoneNumber'}
            sortType={SGConstants.SORT_TYPE_NUMERICAL} onColumnClicked={onColumnClicked} />
        </tr>
      </thead>
      <tbody>
        {isLoading === true
          ? <tr><td colSpan="5">Loading...</td></tr>
          : sortableGridState.sortedGridData.length > 0
            ? sortableGridState.sortedGridData.map((ltsProvider, i) => <GridRow key={i} ltsProvider={ltsProvider} onEditClicked={onEditClicked} />)
            : <tr><td colSpan="5">No Learn To Swim Providers</td></tr>
        }
      </tbody>
    </table>
  );
};

export default ProviderSearchGridLarge;