import { Fragment } from "react";

import useMeetResultsWidget, { MAX_DATA_COUNT, COLUMN_NUMBERS } from "./UseMeetResultsWidget";

import SisenseAgeGroup1Dropdown from "../../../../../components/usas-times-data/sisenseAgeGroup1Dropdown/SisenseAgeGroup1Dropdown";
import SisenseEventNoCourseDropdown from "../../../../../components/usas-times-data/sisenseEventNoCourseDropdown/SisenseEventNoCourseDropdown";
import SisenseScyEventNoCourseDropdown from "../../../../../components/usas-times-data/sisenseScyEventNoCourseDropdown/SisenseScyEventNoCourseDropdown";
import SisenseScmEventNoCourseDropdown from "../../../../../components/usas-times-data/sisenseScmEventNoCourseDropdown/SisenseScmEventNoCourseDropdown";
import SisenseLcmEventNoCourseDropdown from "../../../../../components/usas-times-data/sisenseLcmEventNoCourseDropdown/SisenseLcmEventNoCourseDropdown";
import SisenseScmLcmEventNoCourseDropdown from "../../../../../components/usas-times-data/sisenseScmLcmEventNoCourseDropdown/SisenseScmLcmEventNoCourseDropdown";
import SisenseScyLcmEventNoCourseDropdown from "../../../../../components/usas-times-data/sisenseScyLcmEventNoCourseDropdown/SisenseScyLcmEventNoCourseDropdown";
import SisenseScyScmEventNoCourseDropdown from "../../../../../components/usas-times-data/sisenseScyScmEventNoCourseDropdown/SisenseScyScmEventNoCourseDropdown";
import SisenseCompetitionCategoryNoDefaultDropdown from "../../../../../components/usas-times-data/sisenseCompetitionCategoryNoDefaultDropdown/SisenseCompetitionCategoryNoDefaultDropdown";

import Spinner from "../../../../../../common/components/spinners/Spinner";
import Headings from "../../../../../../common/components/headings/Headings";
import Dropdown from "../../../../../../common/components/dropdowns/Dropdown";
import LeftArrowIcon from "../../../../../../common/components/icons/LeftArrowIcon";
import RightArrowIcon from "../../../../../../common/components/icons/RightArrowIcon";
import SecondaryButton from '../../../../../../common/components/buttons/SecondaryButton';

import Constants from "../../../../../../common/utils/Constants";

import global from '../../../../../../common/components/GlobalStyle.module.css';
import style from '../../../ClubPerformance.module.css';

const MeetResultsGridRowSmall = ({ columns, row }) => (
  <div className={global.SmallTableRow}>
    <div className={global.SmallTableRowHead}>
      &nbsp;</div>
    <div className={global.SmallTableRowInfo}>
      <div className="row">
        {columns.map((column, i) => (
          <Fragment key={i}>
            {column.columnNumber > 0 &&
              <Fragment>
                <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3'].join(' ')}>
                  {column.columnLabel || <span>&nbsp;</span>}
                </div>
                <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3'].join(' ')}>
                  {row[i]?.cellText || <span>&nbsp;</span>}
                </div>
              </Fragment>
            }
          </Fragment>
        ))}
      </div>
    </div>
  </div>
);

const MeetResultsGridSmall = ({ data }) => (
  <div className={[global.SmallTable, 'hidden-md hidden-lg'].join(' ')}>
    {Array.isArray(data.rows) && data.rows.length > 0
      ? data.rows.map((row, i) => (
        <MeetResultsGridRowSmall key={i} columns={data.columns} row={row} />
      )) : (
        <div className={global.SmallTableRow}>
          <div className={global.SmallTableRowHead}>
            &nbsp;
          </div>
          <div className={global.SmallTableRowInfo}>
            <div className={global.SmallTableRowLabels}>No Results</div>
          </div>
        </div>
      )}
  </div>
);

const MeetResultsGridRowLarge = ({ row }) => (
  <tr>
    {row.map((cell, i) => {
      if (cell.columnNumber > 0) {
        return <td key={i}>{cell?.cellText || ''}</td>;
      }
    })}
  </tr>
);

const MeetResultsGridLarge = ({ data }) => {
  return (
    <table className={global.UsasTable}>
      <thead>
        <tr>
          {data.columns.map((column, i) => (
            <Fragment key={i}>
              {column.columnNumber === COLUMN_NUMBERS.AGE_GROUP || column.columnNumber === COLUMN_NUMBERS.SWIM_TIME
                ? <th key={i} style={{ minWidth: '80px' }}>{column.columnLabel || ''}</th>
                : column.columnNumber === COLUMN_NUMBERS.TIME_STANDARD
                  ? <th key={i} style={{ minWidth: '120px' }}>{column.columnLabel || ''}</th>
                  : column.columnNumber > 0 ?
                    <th key={i}>{column.columnLabel || ''}</th>
                    : <Fragment />
              }
            </Fragment>
          ))}
        </tr>
      </thead>
      <tbody>
        {data.rows?.length > 0
          ? data.rows?.map((row, i) =>
            <MeetResultsGridRowLarge key={i} row={row} />)
          : <tr><td colSpan={data.columns.length}>No Results</td></tr>
        }
      </tbody>
    </table>
  );
}

const MeetResultsGrid = ({ data }) => (
  <Fragment>
    <div className={'visible-md visible-lg'}>
      <MeetResultsGridLarge data={data} />
    </div>
    <div className={'hidden-md hidden-lg'}>
      <MeetResultsGridSmall data={data} />
    </div>
  </Fragment>
);

const MeetResultsWidget = ({ dashboardOid, widget, meetData, showScy, showScm, showLcm }) => {
  const {
    isError,
    isLoading,
    state,
    formattedData,
    location,
    formState,
    errorState,
    onValueTextPairChanged,
    onFiltersToggle,
    goToNextPage,
    goToPreviousPage,
    onBackToMeetSummaryClicked,
    sessionOptions,
  } = useMeetResultsWidget(dashboardOid, widget, meetData, showScy, showScm, showLcm);

  return (
    <Fragment>
      <div className="row usas-extra-top-margin usas-extra-bottom-margin">
        <div className="col-xs-12 col-sm-7 usas-extra-top-margin">
          <Headings.H5>{location.state?.meetName}</Headings.H5>
        </div>
        <div className="col-xs-12 col-sm-5 usas-extra-top-margin">
          <div className="pull-right">
            <button className={global.BackToSearchButton}
              type="button"
              onClick={onBackToMeetSummaryClicked}>
              <LeftArrowIcon />  {'Back to Meet Summary'}
            </button>
          </div>
        </div>
      </div>
      <div className={state.showFilters === true ? global.DisplayComponent : global.HideComponent}>
        <div className="row usas-extra-top-margin">
          <div className="col-xs-12 col-sm-6 col-md-3">
            {showScy === true && showScm === true && showLcm === true ?
              <SisenseEventNoCourseDropdown
                label="Event*"
                name="eventId"
                value={formState.eventId}
                error={formState.eventId === Constants.DEFAULT_ID ||
                  formState.eventName === '--'}
                message={'Event is required'}
                onChange={(newValue, newValueLabel, e) => {
                  e && e.target && e.target.value &&
                    onValueTextPairChanged(newValue, 'eventId', newValueLabel, 'eventName');
                }}
              /> : showScy === false && showScm === true && showLcm === true ?
                <SisenseScmLcmEventNoCourseDropdown
                  label="Event*"
                  name="eventId"
                  value={formState.eventId}
                  error={formState.eventId === Constants.DEFAULT_ID ||
                    formState.eventName === '--'}
                  message={'Event is required'}
                  onChange={(newValue, newValueLabel, e) => {
                    e && e.target && e.target.value &&
                      onValueTextPairChanged(newValue, 'eventId', newValueLabel, 'eventName');
                  }}
                /> : showScy === true && showScm === false && showLcm === true ?
                  <SisenseScyLcmEventNoCourseDropdown
                    label="Event*"
                    name="eventId"
                    value={formState.eventId}
                    error={formState.eventId === Constants.DEFAULT_ID ||
                      formState.eventName === '--'}
                    message={'Event is required'}
                    onChange={(newValue, newValueLabel, e) => {
                      e && e.target && e.target.value &&
                        onValueTextPairChanged(newValue, 'eventId', newValueLabel, 'eventName');
                    }}
                  /> : showScy === true && showScm === true && showLcm === false ?
                    <SisenseScyScmEventNoCourseDropdown
                      label="Event*"
                      name="eventId"
                      value={formState.eventId}
                      error={formState.eventId === Constants.DEFAULT_ID ||
                        formState.eventName === '--'}
                      message={'Event is required'}
                      onChange={(newValue, newValueLabel, e) => {
                        e && e.target && e.target.value &&
                          onValueTextPairChanged(newValue, 'eventId', newValueLabel, 'eventName');
                      }}
                    /> : showScy === true && showScm === false && showLcm === false ?
                      <SisenseScyEventNoCourseDropdown
                        label="Event*"
                        name="eventId"
                        value={formState.eventId}
                        error={formState.eventId === Constants.DEFAULT_ID ||
                          formState.eventName === '--'}
                        message={'Event is required'}
                        onChange={(newValue, newValueLabel, e) => {
                          e && e.target && e.target.value &&
                            onValueTextPairChanged(newValue, 'eventId', newValueLabel, 'eventName');
                        }}
                      /> : showScy === false && showScm === true && showLcm === false ?
                        <SisenseScmEventNoCourseDropdown
                          label="Event*"
                          name="eventId"
                          value={formState.eventId}
                          error={formState.eventId === Constants.DEFAULT_ID ||
                            formState.eventName === '--'}
                          message={'Event is required'}
                          onChange={(newValue, newValueLabel, e) => {
                            e && e.target && e.target.value &&
                              onValueTextPairChanged(newValue, 'eventId', newValueLabel, 'eventName');
                          }}
                        /> : showScy === false && showScm === false && showLcm === true ?
                          <SisenseLcmEventNoCourseDropdown
                            label="Event*"
                            name="eventId"
                            value={formState.eventId}
                            error={formState.eventId === Constants.DEFAULT_ID ||
                              formState.eventName === '--'}
                            message={'Event is required'}
                            onChange={(newValue, newValueLabel, e) => {
                              e && e.target && e.target.value &&
                                onValueTextPairChanged(newValue, 'eventId', newValueLabel, 'eventName');
                            }}
                          /> : <Fragment />}
          </div>
          <div className="col-xs-12 col-sm-6 col-md-3">
            <Dropdown
              label="Session*"
              name="sessionId"
              options={sessionOptions.options}
              value={formState.sessionId}
              error={formState.sessionId === Constants.DEFAULT_ID ||
                formState.sessionName === '--'}
              message={'Session is required'}
              isLoading={false}
              onChange={(newValue, newValueLabel, e) => {
                e && e.target && e.target.value &&
                  onValueTextPairChanged(newValue, 'sessionId', newValueLabel, 'sessionName');
              }} />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-3">
            <SisenseCompetitionCategoryNoDefaultDropdown
              label="Gender*"
              name="competitionCategoryId"
              value={formState.competitionCategoryId}
              error={formState.competitionCategoryId === Constants.DEFAULT_ID ||
                formState.competitionCategoryName === '--'}
              message={'Gender is required'}
              onChange={(value, newValueLabel, e) => {
                e && e.target && e.target.value &&
                  onValueTextPairChanged(value, 'competitionCategoryId', newValueLabel, 'competitionCategoryName');
              }}
            />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-3">
            <SisenseAgeGroup1Dropdown
              label="Age Group"
              name="ageGroupId"
              value={formState.ageGroupId}
              error={errorState.ageGroupId}
              message={errorState.ageGroupId}
              onChange={(value, newValueLabel, e) => {
                e && e.target && e.target.value &&
                  onValueTextPairChanged(value, 'ageGroupId', newValueLabel, 'ageGroupName');
              }}
            />
          </div>
        </div>
      </div>
      <div className={style.Widget}>
        <div className="col-xs-12 usas-extra-top-margin">
          <button className={style.ToggleButton} type="button" onClick={(e) => onFiltersToggle(e)}>{state.showFilters === true ? 'Hide Filters' : 'Show Filters'}</button>
        </div>
      </div>
      {!isLoading && !isError &&
        <div className={style.Widget}>
          <div className="row usas-extra-top-margin">
            <div className="col-xs-12 col-sm-3">
              <p>{formattedData.rows?.length === 1 ? `${formattedData.rows?.length} Result` : `${formattedData.rows?.length} Results`} - Page {(state.offset / MAX_DATA_COUNT) + 1}</p>
            </div>
            <div className="col-xs-12 col-sm-9">
              {formattedData.rows?.length === MAX_DATA_COUNT &&
                <Fragment>
                  <SecondaryButton className={'pull-right usas-extra-top-margin'} onClick={(e) => goToNextPage(e)} >View Next Results Page <RightArrowIcon /></SecondaryButton>
                  <span className="pull-right">&nbsp;</span>
                </Fragment>}
              {state.offset > 0 &&
                <Fragment>
                  <SecondaryButton className={'pull-right usas-extra-top-margin'} onClick={(e) => goToPreviousPage(e)} ><LeftArrowIcon /> View Previous Results Page</SecondaryButton>
                  <span className="pull-right">&nbsp;</span>
                </Fragment>}
            </div>
          </div>
        </div>}
      <div className="row usas-extra-top-margin">
        <div className="col-xs-12">
          {isError
            ? <p className={global.ErrorMessage}>Error...</p>
            : isLoading
              ? <Spinner />
              : formattedData
                ? <MeetResultsGrid data={formattedData || {}} />
                : <Fragment />
          }
        </div>
      </div>
      {!isLoading && !isError && formattedData.rows?.length > 5 &&
        <div className={style.Widget}>
          <div className="row usas-extra-top-margin">
            <div className="col-xs-12 col-sm-3">
              <p>{formattedData.rows?.length === 1 ? `${formattedData.rows?.length} Result` : `${formattedData.rows?.length} Results`} - Page {(state.offset / MAX_DATA_COUNT) + 1}</p>
            </div>
            <div className="col-xs-12 col-sm-9">
              {formattedData.rows?.length === MAX_DATA_COUNT &&
                <Fragment>
                  <SecondaryButton className={'pull-right usas-extra-top-margin'} onClick={(e) => goToNextPage(e)} >View Next Results Page <RightArrowIcon /></SecondaryButton>
                  <span className="pull-right">&nbsp;</span>
                </Fragment>}
              {state.offset > 0 &&
                <Fragment>
                  <SecondaryButton className={'pull-right usas-extra-top-margin'} onClick={(e) => goToPreviousPage(e)} ><LeftArrowIcon /> View Previous Results Page</SecondaryButton>
                  <span className="pull-right">&nbsp;</span>
                </Fragment>}
            </div>
          </div>
        </div>}
    </Fragment>
  );
};

export default MeetResultsWidget;