import { useNavigate } from '../../../../common/wrappers/ReactRouterDom';

import NavLinks from '../NavLinks';

import useBaseUsasClubData from '../../../../common/state/clubBase/UseBaseUsasClubData';

const useClubAffiliation = () => {
  const navigate = useNavigate();
  const { baseUsasClubState, setBaseUsasClubState, deleteUsasClubAffiliation } = useBaseUsasClubData();

  const onAdd = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    setBaseUsasClubState({
      ...baseUsasClubState,
      isSaved: false
    });

    navigate(NavLinks.CLUB_AFFILIATION_DETAIL);
  };

  const onDelete = (e, usasClubAffiliationId) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    deleteUsasClubAffiliation(usasClubAffiliationId);
  };

  return { baseUsasClubState, onDelete, onAdd };
};

export default useClubAffiliation;

