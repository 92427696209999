import React, { Fragment } from 'react';

import Input from '../../../../../common/components/inputs/Input';
import InternationalPhoneInput from '../../../../../common/components/inputs/InternationalPhoneInput';
import ReadOnly from '../../../../../common/components/readOnly/ReadOnly';
import { formatPhoneNumber } from '../../../../../common/utils/validation';

const PreCompLocationFacilityContactForm = ({ formState, errorState, onFormValueChanged }) => (
  <Fragment>
    <div className="row">
      <div className="col-xs-12 col-sm-6 col-md-4">
        {onFormValueChanged === undefined
          ? <ReadOnly
            label="Facility Primary Contact Name"
            name="contactName"
            value={formState.contactName} />
          : (
            <Input
              label="Facility Primary Contact Name*"
              name="contactName"
              value={formState.contactName}
              error={errorState.contactName}
              message={errorState.contactName}
              onChange={(value) => { onFormValueChanged('contactName', value) }} />)}
      </div>
      <div className="col-xs-12 col-sm-6 col-md-4">
        {onFormValueChanged === undefined
          ? <ReadOnly
            label="Facility Email"
            name="contactEmailAddress"
            value={formState.contactEmailAddress} />
          : (
            <Input
              label="Facility Email*"
              name="contactEmailAddress"
              value={formState?.contactEmailAddress}
              error={errorState.contactEmailAddress}
              message={errorState.contactEmailAddress}
              onChange={(value) => { onFormValueChanged('contactEmailAddress', value) }} />)}
      </div>
      <div className="col-xs-12 col-sm-6 col-md-4">
        {onFormValueChanged === undefined
          ? <ReadOnly
            label="Facility Phone Number"
            name="contactPhoneNumber"
            value={formatPhoneNumber(formState.contactPhoneNumber)} />
          : (
            <InternationalPhoneInput
              label="Facility Phone Number*"
              name="contactPhoneNumber"
              value={formState.contactPhoneNumber}
              error={errorState.contactPhoneNumber}
              message={errorState.contactPhoneNumber}
              onChange={(value) => { onFormValueChanged('contactPhoneNumber', value) }} />)}
      </div>
      <div className="col-xs-12 col-sm-6 col-md-4">
        {onFormValueChanged === undefined
          ? <ReadOnly
            label="Pre-Comp Primary Contact Name for Facility"
            name="clubFacilityContactName"
            value={formState.clubFacilityContactName} />
          : (
            <Input
              label="Pre-Comp Primary Contact Name for Facility*"
              name="clubFacilityContactName"
              value={formState.clubFacilityContactName}
              error={errorState.clubFacilityContactName}
              message={errorState.clubFacilityContactName}
              onChange={(value) => { onFormValueChanged("clubFacilityContactName", value); }} />
          )
        }
      </div>
      <div className="col-xs-12 col-sm-6 col-md-4">
        {onFormValueChanged === undefined
          ? <ReadOnly
            label="Pre-Comp Primary Contact Email"
            name="clubFacilityContactEmailAddress"
            value={formState.clubFacilityContactEmailAddress} />
          : (
            <Input
              label="Pre-Comp Primary Contact Email*"
              name="clubFacilityContactEmailAddress"
              value={formState.clubFacilityContactEmailAddress}
              error={errorState.clubFacilityContactEmailAddress}
              message={errorState.clubFacilityContactEmailAddress}
              onChange={(value) => { onFormValueChanged("clubFacilityContactEmailAddress", value); }} />
          )
        }
      </div>
      <div className="col-xs-12 col-sm-6 col-md-4">
        {onFormValueChanged === undefined
          ? <ReadOnly
            label="Pre-Comp Primary Contact Phone Number"
            name="clubFacilityContactPhoneNumber"
            value={formatPhoneNumber(formState.clubFacilityContactPhoneNumber)} />
          : (
            <InternationalPhoneInput
              label="Pre-Comp Primary Contact Phone Number*"
              name="clubFacilityContactPhoneNumber"
              value={formState.clubFacilityContactPhoneNumber}
              error={errorState.clubFacilityContactPhoneNumber}
              message={errorState.clubFacilityContactPhoneNumber}
              onChange={(value) => { onFormValueChanged("clubFacilityContactPhoneNumber", value); }} />
          )
        }
      </div>
    </div>
  </Fragment>
);

export default PreCompLocationFacilityContactForm;