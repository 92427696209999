import NavLinks from '../NavLinks';

import usePreCompLocationData from '../../../state/preCompLocation/UsePreCompLocationData';

import useEnvironmentVariableData from '../../../../common/state/environmentVariable/UseEnvironmentVariableData';
import usePreCompOrganizationData from '../../../../common/state/preCompOrganization/UsePreCompOrganizationData';

const useOrgUnitSelection = () => {
    const TAXONOMIES = ['PreComp'];
    const SCOPE = 'PreComp';
    const { MY_PRECOMP_ROLE_GROUP_ID } = useEnvironmentVariableData();
    const { getPreCompOrganizationByLocationOrgUnitId } = usePreCompOrganizationData();
    const { getPreCompLocationByOrgUnitId } = usePreCompLocationData();

    const getPreCompOrganizationAndLocationFunction = (orgUnitId) => {
        getPreCompOrganizationByLocationOrgUnitId(orgUnitId);
        getPreCompLocationByOrgUnitId(orgUnitId);
    }

    return {
        NavLinks,
        getPreCompOrganizationAndLocationFunction,
        TAXONOMIES,
        SCOPE,
        MY_PRECOMP_ROLE_GROUP_ID
    };
};

export default useOrgUnitSelection;