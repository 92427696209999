import { useContext, } from 'react';

import OfferingTypeData from './OfferingTypeData';
import OfferingTypeWithRouteCodeData from './OfferingTypeWithRouteCodeData';

import { OfferingTypesStateContext } from './OfferingTypesContextProvider';

const useOfferingTypeData = () => {
  const [offeringTypeState, setOfferingTypeState] = useContext(OfferingTypesStateContext);

  const getOfferingTypesForRegistration = (orgUnitId, age, category, personId) =>
    OfferingTypeData.getOfferingTypesForClubRegistration(orgUnitId, age, category, personId, offeringTypeState, setOfferingTypeState);

  const getOfferingTypesForRegistrationWithRouteCode = (orgUnitId, age, category, offeringInstanceCouponId, personId) =>
    OfferingTypeWithRouteCodeData.getOfferingTypesForRegistrationWithRouteCode(orgUnitId, age, category, offeringInstanceCouponId, personId, offeringTypeState, setOfferingTypeState);

  const postMemberRegistrationUpgrade = (memberRegistrationObj) => {
    OfferingTypeData.postMemberRegistrationUpgrade(memberRegistrationObj, offeringTypeState, setOfferingTypeState)
  }
  
  const getOfferingTypesForClubRegistration = (orgUnitId, age, category, personId) =>
    OfferingTypeData.getOfferingTypesForClubRegistration(orgUnitId, age, category, personId, offeringTypeState, setOfferingTypeState);

  const resetOfferingTypeState = () => {
    setOfferingTypeState(OfferingTypeData.INITIAL_STATE);
  }
  return {
    offeringTypeState,
    setOfferingTypeState,
    getOfferingTypesForRegistration,
    getOfferingTypesForRegistrationWithRouteCode,
    postMemberRegistrationUpgrade,
    getOfferingTypesForClubRegistration,
    resetOfferingTypeState
  };
};

export default useOfferingTypeData;