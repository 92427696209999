import React, { Fragment } from 'react';

import YesNoSwitch from '../../../../common/components/yesNoSwitch/YesNoSwitch';
import ReadOnly from '../../../../common/components/readOnly/ReadOnly';

const ClubRegistrationScreeningPoliciesRead = ({ formState, clubRegistrationState }) => (
  <Fragment >
    <div className="row usas-extra-top-margin">
      <div className="col-xs-12">
        <b>Pre-Employment Screening</b>
      </div>
    </div>
    <div className="row">
      <div className="col-xs-12">
        <YesNoSwitch
          label="By selecting yes and signing below, I formally acknowledge that this club 
              is conducting pre-employment screening on all new employees who are required to be 
              members of USA Swimming as required in the USA Swimming Rules &amp; Regulations, Article 502.6.8"
          name="preEmploymentScreeningRequired"
          checked={formState.preEmploymentScreeningRequired}
          disabled={true}
        />
      </div>
    </div>
    <div className="row">
      <div className="col-xs-12 col-sm-6 col-md-4">
        <ReadOnly
          label="First and Last Name"
          name="preEmploymentScreeningRequiredSignature"
          value={formState.preEmploymentScreeningRequiredSignature} />
      </div>
      <div className="col-xs-12 col-sm-6 col-md-4">
        <ReadOnly
          label="Signature Date"
          name="preEmploymentScreeningRequiredSignatureDate"
          value={formState.preEmploymentScreeningRequiredSignatureDate} />
      </div>
    </div>
    <div className="row usas-extra-top-margin">
      <div className="col-xs-12">
        <b>Racing Start Certification</b>
      </div>
    </div>
    <div className="row">
      <div className="col-xs-12">
        <YesNoSwitch
          label="By selecting yes and signing below, I formally acknowledge that this club complies 
              with all Racing Start Certification requirements as stated in the USA Swimming Rules &amp; 
              Regulations, Article 103.2.2 and maintains records for its athlete members."
          checked={formState.racingStartCertified}
          disabled={true} />
      </div>
    </div>
    <div className="row">
      <div className="col-xs-12 col-sm-6 col-md-4">
        <ReadOnly
          label="First and Last Name"
          name="racingStartCertifiedSignature"
          value={formState.racingStartCertifiedSignature} />
      </div>
      <div className="col-xs-12 col-sm-6 col-md-4">
        <ReadOnly
          label="Signature Date"
          name="racingStartCertifiedSignatureDate"
          value={formState.racingStartCertifiedSignatureDate} />
      </div>
    </div>
    <div className="row usas-extra-top-margin">
      <div className="col-xs-12">
        <b>State Concussion Laws</b>
      </div>
    </div>
    <div className="row">
      <div className="col-xs-12">
        <YesNoSwitch
          label="By selecting yes and signing below, I formally acknowledge that this club is following 
              the state concussion laws regarding training coaches and providing educational information to 
              athletes, parents, and guardians as required."
          checked={formState.stateConcussionsLaws}
          disabled={true} />
      </div>
    </div>
    <div className="row">
      <div className="col-xs-12 col-sm-6 col-md-4">
        <ReadOnly
          label="First and Last Name"
          name="stateConcussionsLawsSignature"
          value={formState.stateConcussionsLawsSignature} />
      </div>
      <div className="col-xs-12 col-sm-6 col-md-4">
        <ReadOnly
          label="Signature Date"
          name="stateConcussionsLawsSignatureDate"
          value={formState.stateConcussionsLawsSignatureDate} />
      </div>
    </div>
    <div className="row usas-extra-top-margin">
      <div className="col-xs-12">
        <b>Minor Athlete Abuse Prevention Policy</b>
      </div>
    </div>
    <div className="row">
      <div className="col-xs-12">
        <YesNoSwitch
          label="By selecting yes and signing below, I formally acknowledge that this club 
              has implemented USA Swimming Minor Athlete Abuse Prevention Policy, and require all 
              athletes, parents, coaches, and other non-athlete members of the club to review and 
              agree to the Policy on an annual basis with such written agreement to be retained by the club."
          checked={formState.minorAthleteAbusePreventionPolicy}
          disabled={true} />
      </div>
    </div>
    <div className="row">
      <div className="col-xs-12 col-sm-6 col-md-4">
        <ReadOnly
          label="First and Last Name"
          name="minorAthleteAbusePreventionPolicySignature"
          value={formState.minorAthleteAbusePreventionPolicySignature} />
      </div>
      <div className="col-xs-12 col-sm-6 col-md-4">
        <ReadOnly
          label="Signature Date"
          name="minorAthleteAbusePreventionPolicySignatureDate"
          value={formState.minorAthleteAbusePreventionPolicySignatureDate} />
      </div>
    </div>
    {/* <div className="row usas-extra-top-margin">
      <div className="col-xs-12">
        <b>CLBMS 101</b>
      </div>
    </div>
    <div className="row">
      <div className="col-xs-12">
        <YesNoSwitch
          label="By selecting yes and signing below, I formally acknowledge that this club head coach 
              has taken their CLMBS 101 course and has completed all modules and requirements."
          checked={formState.clbmS101}
          disabled={true} />
      </div>
    </div> */}
    {/* <div className="row usas-extra-bottom-margin">
      <div className="col-xs-12 col-sm-6 col-md-4">
        <ReadOnly
          label="First and Last Name"
          name="clbmS101signature"
          value={formState.clbmS101signature} />
      </div>
      <div className="col-xs-12 col-sm-6 col-md-4">
        <ReadOnly
          label="Signature Date"
          name="clbmS101signatureDate"
          value={formState.clbmS101signatureDate} />
      </div>
      <div className="col-xs-12 col-sm-6 col-md-4">
        <ReadOnly
          label="Completion Date"
          name="clbmS101CompletionDate"
          value={formState.clbmS101CompletionDate} />
      </div>
    </div> */}

    {/* {clubRegistrationState.objData?.clubRegistrations?.length > 1 ?
      <Fragment>
        <div className="row">
          <div className="col-xs-12">
            <b>CLBMS 201</b>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12">
            <YesNoSwitch
              label="By selecting yes and signing below, I formally acknowledge that this club head coach 
              has taken their CLMBS 201 course and has completed all modules and requirements."
              checked={formState.clbmS201}
              disabled={true} />
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12 col-sm-6 col-md-4">
            <ReadOnly
              label="First and Last Name"
              name="clbmS201signature"
              value={formState.clbmS201signature} />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4">
            <ReadOnly
              label="Signature Date"
              name="clbmS201signatureDate"
              value={formState.clbmS201signatureDate} />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4">
            <ReadOnly
              label="Completion Date"
              name="clbmS201CompletionDate"
              value={formState.clbmS201CompletionDate} />
          </div>
        </div>
      </Fragment>
      : <Fragment />} */}

  </Fragment>
);

export default ClubRegistrationScreeningPoliciesRead;