import { useEffect, useState } from 'react';

import { useExecuteQuery } from '@sisense/sdk-ui';
import { Sort } from '@sisense/sdk-data';

import * as DM from '../../../../usas-times-data';

import Constants from '../../../../common/utils/Constants';

import useEnvironmentVariableData from '../../../../common/state/environmentVariable/UseEnvironmentVariableData';

const useSisenseCourseDropdown = () => {
    const queryProps = { dataSource: DM.DataSource, dimensions: [DM.SwimEvent.CourseCode.sort(Sort.Descending)] };
    const { data } = useExecuteQuery(queryProps);
    const [courseOptions, setCourseOptions] = useState({ options: [] });
    const { environmentVariableState, SISENSE_UNKNOWN_TEXT, OPEN_WATER_COURSE_CODE } = useEnvironmentVariableData();

    useEffect(() => {
        const defaultOptions = [{ id: Constants.DEFAULT_ID, name: '--' }];
        if (data && environmentVariableState.isLoaded === true) {
            const formattedOptions = [];
            formattedOptions.push(defaultOptions[0]);
            data.rows.map((row) => {
                if (row[0].text !== SISENSE_UNKNOWN_TEXT && row[0].text !== OPEN_WATER_COURSE_CODE) {
                    return (formattedOptions.push({ id: row[0].data, name: row[0].text }))
                }
            });
            setCourseOptions({ ...courseOptions, options: formattedOptions });
        }
        else {
            setCourseOptions({ ...courseOptions, options: defaultOptions });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, environmentVariableState])

    return {
        data,
        courseOptions
    };
};

export default useSisenseCourseDropdown;