import { Fragment } from "react";

import useLearnToSwimRegistrationEntry from "./UseLearnToSwimRegistrationEntry";

import RegistrationEntryForm from "../components/forms/RegistrationEntryForm";
import LoginPopup from "../components/dialogs/LoginPopup";

import Headings from "../../../../common/components/headings/Headings";
import PopUpModal from "../../../../common/components/dialogs/PopUpModal";
import SecondaryButton from "../../../../common/components/buttons/SecondaryButton";

import Constants from "../../../../common/utils/Constants";

const LearnToSwimRegistrationEntry = () => {
  const {
    isLoading,
    modalState,
    onSubmitFormCallback,
    onModalCancelClicked
  } = useLearnToSwimRegistrationEntry();

  return (
    <Fragment>
      <Headings.H3>Learn To Swim Provider Registration Entry</Headings.H3>
      <RegistrationEntryForm onSubmitFormCallback={onSubmitFormCallback} />
      <LoginPopup />
      <PopUpModal
        widthPct={90}
        maxWidth={720}
        title="Duplicate Learn To Swim Provider Detected"
        displayPopUp={modalState.displayModal}
        onModalCanceled={onModalCancelClicked}>
        <div className="row">
          <div className="col-xs-12">
            <p>Our records indicate that a Learn To Swim Provider with the provided Tax Id has already been registered in our system. Please enter a different Tax Id to continue to registration.</p>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12 usas-extra-top-margin">
            <SecondaryButton type="button" onClick={onModalCancelClicked}>Cancel</SecondaryButton>
          </div>
        </div>
      </PopUpModal>
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={isLoading} />
    </Fragment>
  );
};

export default LearnToSwimRegistrationEntry;