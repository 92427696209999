import React from 'react';

import usePreCompRegistrationLocationProgramsDetail from './UsePreCompRegistrationLocationProgramsDetail';

import PreCompRegistrationConstants from '../utils/PreCompRegistrationConstants';

import PreCompLocationProgramOfferingForm from '../../preComp/components/forms/PreCompLocationProgramOfferingForm';

import Headings from '../../../../common/components/headings/Headings';
import PrimaryButton from '../../../../common/components/buttons/PrimaryButton';
import SecondaryButton from '../../../../common/components/buttons/SecondaryButton';
import PopUpModal from '../../../../common/components/dialogs/PopUpModal';
import LeftNav from '../../../../common/components/leftNav/LeftNav';

import global from '../../../../common/components/GlobalStyle.module.css';

const PRIMARY_PROGRAM_MESSAGE = '*Select primary Learn To Swim offering first, you will be able to add additional offerings afterwards';

const PreCompRegistrationLocationProgramsDetail = () => {
  const {
    formState,
    errorState,
    displayDupModalState,
    locationRegistrationNavState,
    isFirstProgram,
    onFormValueChanged,
    handleSubmit,
    onBackClicked,
    onDupModalCancelClicked
  } = usePreCompRegistrationLocationProgramsDetail();

  return (
    <LeftNav title={PreCompRegistrationConstants.LEFT_NAV_TITLE} state={locationRegistrationNavState} validateBeforeRedirect={false}>
      <Headings.H3>{formState.preCompLocationOfferingId ? 'Edit' : 'Add'} Program Offering</Headings.H3>
      {isFirstProgram === true &&
        <div className="row">
          <div className="col-xs-12">
            <p className={global.Bold}>{PRIMARY_PROGRAM_MESSAGE}</p>
          </div>
        </div>
      }
      <form onSubmit={handleSubmit} noValidate>
        <PreCompLocationProgramOfferingForm
          formState={formState}
          errorState={errorState}
          onFormValueChanged={onFormValueChanged}
          onOfferingTypeChanged={formState.preCompLocationOfferingId ? undefined : onFormValueChanged} />
        {errorState.errorOnSubmitAction &&
          <div className="row">
            <div className="col-xs-12">
              <p className={global.ErrorMessage}>{errorState.errorOnSubmitAction || ''}</p>
            </div>
          </div>}
        <div className="row usas-extra-top-margin  usas-extra-bottom-margin">
          <div className="col-xs-12 usas-extra-top-margin">
            <PrimaryButton type="submit">Save</PrimaryButton>&nbsp;
            <SecondaryButton type="button" onClick={onBackClicked}>Back</SecondaryButton>
          </div>
        </div>
      </form>
      <PopUpModal
        widthPct={90}
        maxWidth={1020}
        title={'Duplicate Program Offering'}
        displayPopUp={displayDupModalState === true}
        onModalCanceled={onDupModalCancelClicked}>
        <div className="row">
          <div className="col-xs-12">
            <p>Location already has a {formState.programOffering[0]?.name} program offering.</p>
          </div>
        </div>
        <div className="row usas-extra-top-margin">
          <div className="col-xs-12">
            <SecondaryButton type="button" onClick={onDupModalCancelClicked}>Cancel</SecondaryButton>
          </div>
        </div>
      </PopUpModal>
    </LeftNav>
  );
};

export default PreCompRegistrationLocationProgramsDetail;