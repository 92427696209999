import React, { Fragment } from "react";

import LSCSecurityRolesGridLarge from "./LSCSecurityRolesGridLarge";
import LSCSecurityRolesGridSmall from "./LSCSecurityRolesGridSmall";

import useExpandedRow from "../../../../common/components/grids/UseExpandedRow";

const LSCSecurityRolesGrid = ({ data, isLoading, onEdit, onDelete }) => {
  const { expandedId, onClick } = useExpandedRow();
  return (
    <Fragment>
      <LSCSecurityRolesGridLarge data={data} isLoading={isLoading} onEdit={onEdit} onDelete={onDelete} expandedId={expandedId} onClick={onClick} />
      <LSCSecurityRolesGridSmall data={data} isLoading={isLoading} onEdit={onEdit} onDelete={onDelete} expandedId={expandedId} onClick={onClick} />
    </Fragment>
  );
};

export default LSCSecurityRolesGrid;