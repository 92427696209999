import { useState } from 'react';

import LearnToSwimProofOfInsuranceData from './LearnToSwimProofOfInsuranceData';

import { BASIC_INITIAL_STATE } from '../../../common/utils/HttpHelper';

const INITIAL_LTS_PROOF_OF_INSURANCE_STATE = {
  ...BASIC_INITIAL_STATE
};

const useLearnToSwimProofOfInsuranceData = () => {
  const [ltsProofOfInsuranceState, setLTSProofOfInsuranceState] = useState(INITIAL_LTS_PROOF_OF_INSURANCE_STATE);

  const postLTSProofOfInsurance = (proofOfInsuranceFile) => {
    return LearnToSwimProofOfInsuranceData.postLTSProofOfInsuranceData(proofOfInsuranceFile, ltsProofOfInsuranceState, setLTSProofOfInsuranceState);
  };

  return {
    ltsProofOfInsuranceState,
    postLTSProofOfInsurance
  };
};

export default useLearnToSwimProofOfInsuranceData;