import { Fragment } from "react";

import useClubSwimAThonLeaderboard from './UseClubSwimAThonLeaderboard';

import SATLeaderboardGrid from "../components/grids/SATLeaderboardGrid";

import Headings from "../../../../common/components/headings/Headings";
import PopUpModal from "../../../../common/components/dialogs/PopUpModal";

import Constants from "../../../../common/utils/Constants";

const ClubSwimAThonLeaderboard = () => {
  const {
    satState,
    clubSwimAThonLeaderboardState
  } = useClubSwimAThonLeaderboard();

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>Swim-a-Thon Leaderboard</Headings.H3>
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12 usas-extra-top-margin usas-extra-bottom-margin">
          <SATLeaderboardGrid
            data={clubSwimAThonLeaderboardState.objData?.leaderboard || []}
            isLoading={clubSwimAThonLeaderboardState.isObjLoading} />
        </div>
      </div>
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={clubSwimAThonLeaderboardState.isObjLoading || satState.isObjLoading} />
    </Fragment>
  );
};

export default ClubSwimAThonLeaderboard;
