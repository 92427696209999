import React, { Fragment } from 'react';

import usePreCompOrganizationInfo from './UsePreCompOrganizationInfo';

import PreCompOrganizationBasicInfoForm from '../components/forms/PreCompOrganizationBasicInfoForm';
import PreCompOrganizationSpecialInfoForm from '../components/forms/PreCompOrganizationSpecialInfoForm';

import PrimaryButton from '../../../../common/components/buttons/PrimaryButton';
import SecondaryButton from '../../../../common/components/buttons/SecondaryButton';
import Headings from '../../../../common/components/headings/Headings';
import PopUpModal from '../../../../common/components/dialogs/PopUpModal';
import Constants from '../../../../common/utils/Constants';
import AddressValidation from '../../../../common/components/addressValidation/AddressValidation';

const PreCompOrganizationInfo = () => {
  const {
    isSaving,
    isLoading,
    formState,
    errorState,
    onFormValueChanged,
    onValueTextPairChanged,
    resetForm,
    handleSubmitProxy,
    setFormData,
    validateState
  } = usePreCompOrganizationInfo();

  return (
    <Fragment>
      <Headings.H3>Organization Info</Headings.H3>
      <form onSubmit={handleSubmitProxy} noValidate>
        <PreCompOrganizationSpecialInfoForm
          formState={formState}
          errorState={errorState}
          onFormValueChanged={onFormValueChanged} />
        <PreCompOrganizationBasicInfoForm
          formState={formState}
          errorState={errorState}
          onFormValueChanged={onFormValueChanged}
          onValueTextPairChanged={onValueTextPairChanged} />
        {errorState.errorOnSubmitAction &&
          <div className="row">
            <div className="col-xs-12">
              <p className={global.ErrorMessage}>{errorState.errorOnSubmitAction || ''}</p>
            </div>
          </div>}
        <AddressValidation formState={formState} setFormData={setFormData} />
        <div className="row usas-extra-top-margin usas-extra-bottom-margin">
          <div className="col-xs-12 usas-extra-top-margin">
            <PrimaryButton type="submit">Save</PrimaryButton>&nbsp;
            <SecondaryButton type="button" onClick={resetForm}>Cancel</SecondaryButton>
          </div>
        </div>
      </form>
      {validateState.isValidating === true && (
        <PopUpModal
          displayPopUp={true}
          maxWidth={250}
          title="Validating..."
          widthPct={50} />
      )}
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.SAVING_MSG}
        displayPopUp={isSaving} />
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={isLoading} />
    </Fragment>
  );
};

export default PreCompOrganizationInfo;