import { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "../../../../../common/wrappers/ReactRouterDom";

import { useExecuteQueryByWidgetId } from "@sisense/sdk-ui";
import { filterFactory } from "@sisense/sdk-data";

import * as DM from '../../../../../usas-times-data';

import useSisenseEventMultiselect from "../../../../components/usas-times-data/sisenseEventMultiselect/UseSisenseEventMultiselect";
import useSisenseSeasonYearDropdown from "../../../../components/usas-times-data/sisenseSeasonYearDropdown/UseSisenseSeasonYearDropdown";
import useSisenseShowAllTimesDropdown from "../../../../components/usas-times-data/sisenseShowAllTimesDropdown/UseSisenseShowAllTimesDropdown";

import { getSisenseFormattedData } from "../../../../utils/SisenseSortableGridUtils";

import useNavRoutes from "../../../../../common/state/security/UseNavRoutes";
import useEnvironmentVariableData from "../../../../../common/state/environmentVariable/UseEnvironmentVariableData";

import UseForm from "../../../../../common/utils/UseForm";
import Constants from "../../../../../common/utils/Constants";
import ToIntIfInt from '../../../../../common/utils/ToIntIfInt';

export const MAX_DATA_COUNT = 500;

export const COLUMN_NUMBERS = {
  SWIM_EVENT_NAME: 1,
  SWIM_TIME: 2,
  AGE: 3,
  POINTS: 4,
  TIME_STANDARD: 5,
  MEET: 6,
  LSC: 7,
  TEAM: 8,
  SWIM_DATE: 9,
  PERSON_KEY: -10, // columnNumber 0 and below are hidden
  SWIM_EVENT_KEY: -11,
  MEET_KEY: -12,
  SORT_KEY: -13
};

const COLUMN_INDEXES = {
  SWIM_EVENT_NAME: 0,
  SWIM_TIME: 1,
  AGE: 2,
  POINTS: 3,
  TIME_STANDARD: 4,
  MEET: 5,
  LSC: 6,
  TEAM: 7,
  SWIM_DATE: 8,
  PERSON_KEY: 9,
  SWIM_EVENT_KEY: 10,
  MEET_KEY: 11,
  SORT_KEY: 12
};

const INITIAL_FORM_STATE = {
  courseId: Constants.DEFAULT_ID,
  courseName: '--',
  events: [],
  competitionYearId: Constants.DEFAULT_ID,
  competitionYearName: '--',
  ageFrom: '',
  ageTo: '',
  timeStandardId: Constants.DEFAULT_ID,
  timeStandardName: '--',
  displayId: Constants.DEFAULT_ID,
  displayName: '--'
};

const useIndividualTimesSearchWidget = (dashboardOid, widget) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { navRoutes } = useNavRoutes();
  const [state, setState] = useState({ offset: 0, showFilters: true, initialLoadComplete: false, isDefaultSeasonYearSet: false });
  const [courseFilter, setCourseFilter] = useState(null);
  const [eventCodeFilter, setEventCodeFilter] = useState(null);
  const [seasonYearFilter, setSeasonYearFilter] = useState(null);
  const [ageFilter, setAgeFilter] = useState(null);
  const [timeStandardFilter, setTimeStandardFilter] = useState(null);
  const [displayFilter, setDisplayFilter] = useState(null);
  const [formattedData, setFormattedData] = useState({ rows: [], columns: [], swimEventKeyIndex: -1 });
  const { formState, errorState, onFormValueChanged, onValueTextPairChanged, setFormData } = UseForm(INITIAL_FORM_STATE, () => { }, () => { });
  const { eventOptions } = useSisenseEventMultiselect();
  const { seasonYearOptions } = useSisenseSeasonYearDropdown();
  const { showAllTimesOptions } = useSisenseShowAllTimesDropdown();
  const { environmentVariableState, SISENSE_SHOW_BEST_TIMES_ONLY_TEXT, SISENSE_SHOW_ALL_TIMES_TEXT } = useEnvironmentVariableData();

  const filters = useMemo(() => {
    const personFilter = filterFactory.equals(DM.UsasSwimTime.PersonKey, ToIntIfInt(location.state?.personKey))
    return [personFilter, courseFilter, eventCodeFilter, seasonYearFilter, ageFilter, timeStandardFilter, displayFilter].filter((f) => !!f);
  }, [location.state?.personKey, courseFilter, eventCodeFilter, seasonYearFilter, ageFilter, timeStandardFilter, displayFilter]);

  const { data, isLoading, isError } = useExecuteQueryByWidgetId({
    dashboardOid,
    widgetOid: widget?.oid,
    enabled: location.state?.personKey && filterFactory.equals(DM.UsasSwimTime.PersonKey, ToIntIfInt(location.state?.personKey)),
    filters: filters,
    count: MAX_DATA_COUNT,
    offset: state.offset
  });

  const onFiltersToggle = (e) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }

    setState({ ...state, showFilters: !state.showFilters });
  };

  const goToNextPage = (e) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }

    const newOffset = state.offset + MAX_DATA_COUNT;
    setState({ ...state, offset: newOffset });
  };

  const goToPreviousPage = (e) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }

    const newOffset = state.offset - MAX_DATA_COUNT;
    setState({ ...state, offset: newOffset });
  };

  const onBackToClubRosterClicked = (e) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }

    navigate(navRoutes.CLUB_PERFORMANCE_ATHLETE_TIMES?.route, {
      state: {
        clubRosterFilters: location.state?.clubRosterFilters
      }
    });
  };

  const onGoToMeetSummaryClicked = (e, row) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }

    if (row) {
      const meetKey = row.find(x => x.columnNumber === COLUMN_NUMBERS.MEET_KEY)?.text;
      const meetName = row.find(x => x.columnNumber === COLUMN_NUMBERS.MEET)?.text;

      navigate(navRoutes.CLUB_PERFORMANCE_ATHLETE_TIMES_DETAIL_MEET_SUMMARY?.route, {
        state: {
          meetKey,
          meetName,
          personKey: location.state?.personKey,
          personName: location.state?.personName,
          clubRosterFilters: location.state?.clubRosterFilters,
          individualTimesSearchFilters: {
            competitionYearId: formState.competitionYearId,
            competitionYearName: formState.competitionYearName,
            seasonYearFilter: seasonYearFilter,
            ageFrom: formState.ageFrom,
            ageTo: formState.ageTo,
            ageFilter: ageFilter,
            courseId: formState.courseId,
            courseName: formState.courseName,
            courseFilter: courseFilter,
            events: formState.events,
            eventCodeFilter: eventCodeFilter,
            timeStandardId: formState.timeStandardId,
            timeStandardName: formState.timeStandardName,
            timeStandardFilter: timeStandardFilter,
            displayId: formState.displayId,
            displayName: formState.displayName,
            displayFilter: displayFilter
          }
        }
      });
    }
  };

  const onSeeProgressionClicked = (e, row) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }

    if (row) {
      const swimEventKey = row.find(x => x.columnNumber === COLUMN_NUMBERS.SWIM_EVENT_KEY)?.text;
      const swimEventName = row.find(x => x.columnNumber === COLUMN_NUMBERS.SWIM_EVENT_NAME)?.text;

      navigate(navRoutes.CLUB_PERFORMANCE_ATHLETE_TIMES_DETAIL_PROGRESSION?.route, {
        state: {
          swimEventKey,
          swimEventName,
          personKey: location.state?.personKey,
          personName: location.state?.personName,
          clubRosterFilters: location.state?.clubRosterFilters,
          individualTimesSearchFilters: {
            competitionYearId: formState.competitionYearId,
            competitionYearName: formState.competitionYearName,
            seasonYearFilter: seasonYearFilter,
            ageFrom: formState.ageFrom,
            ageTo: formState.ageTo,
            ageFilter: ageFilter,
            courseId: formState.courseId,
            courseName: formState.courseName,
            courseFilter: courseFilter,
            events: formState.events,
            eventCodeFilter: eventCodeFilter,
            timeStandardId: formState.timeStandardId,
            timeStandardName: formState.timeStandardName,
            timeStandardFilter: timeStandardFilter,
            displayId: formState.displayId,
            displayName: formState.displayName,
            displayFilter: displayFilter
          }
        }
      });
    }
  };

  useEffect(() => {
    if (formState.courseName) {
      if (formState.courseName !== '--') {
        const course = filterFactory.members(DM.SwimEvent.CourseCode, [formState.courseName]);
        setCourseFilter(course);
      } else {
        setCourseFilter(null);
      }
      //If any previous filters have been set, then clear out the event selections when the course changes
      if (state.initialLoadComplete === true) {
        onFormValueChanged('events', []);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formState.courseName])

  useEffect(() => {
    if (formState.events) {
      if (formState.events?.length > 0) {
        const eventsFormatted = formState.events.map(event => { return (event.name) });
        const events = filterFactory.members(DM.SwimEvent.EventCode, eventsFormatted);
        setEventCodeFilter(events);
      }
      else {
        setEventCodeFilter(null);
      }
    }
  }, [formState.events])

  useEffect(() => {
    if (!location.state?.individualTimesSearchFilters?.competitionYearId && !location.state?.individualTimesSearchFilters?.competitionYearName &&
      state.isDefaultSeasonYearSet === false && seasonYearOptions.options?.length > 1 &&
      (formState.competitionYearId === Constants.DEFAULT_ID || formState.competitionYearName === '--')) {
      setFormData({
        ...formState,
        competitionYearId: seasonYearOptions.options[1]?.id,
        competitionYearName: seasonYearOptions.options[1]?.name
      });
      setState({ ...state, isDefaultSeasonYearSet: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [seasonYearOptions, formState.competitionYearId, formState.competitionYearName, formState])

  useEffect(() => {
    if (formState.competitionYearName) {
      if (formState.competitionYearName !== '--') {
        const seasonYear = filterFactory.members(DM.SeasonCalendar.SeasonYearDesc, [formState.competitionYearName]);
        setSeasonYearFilter(seasonYear);
      } else {
        setSeasonYearFilter(null);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formState.competitionYearName, data, isLoading])

  useEffect(() => {
    if (formState.ageFrom || formState.ageTo) {
      const age = filterFactory.between(DM.Age.AgeKey, formState.ageFrom ? ToIntIfInt(formState.ageFrom) : 0, formState.ageTo ? ToIntIfInt(formState.ageTo) : 150);
      setAgeFilter(age);
    } else {
      setAgeFilter(null);
    }
  }, [formState.ageFrom, formState.ageTo, data, isLoading])

  useEffect(() => {
    if (formState.timeStandardName) {
      if (formState.timeStandardName !== '--') {
        const timeStandard = filterFactory.members(DM.StandardType.StandardType, [formState.timeStandardName]);
        setTimeStandardFilter(timeStandard);
      } else {
        setTimeStandardFilter(null);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formState.timeStandardName])

  useEffect(() => {
    if (showAllTimesOptions.options?.length > 0 && (formState.displayId === Constants.DEFAULT_ID || formState.displayName === '--')) {
      setFormData({
        ...formState,
        displayId: showAllTimesOptions.options[0]?.id,
        displayName: showAllTimesOptions.options[0]?.name
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showAllTimesOptions, formState.displayId, formState.displayName, formState])

  useEffect(() => {
    if (formState.displayName && environmentVariableState.isLoaded === true) {
      if (formState.displayName !== '--') {
        const display = formState.displayName === SISENSE_SHOW_ALL_TIMES_TEXT ? null :
          formState.displayName === SISENSE_SHOW_BEST_TIMES_ONLY_TEXT && formState.competitionYearName !== '--' ?
            filterFactory.equals(DM.UsasSwimTime.SingleSeasonBest, 1)
            : formState.displayName === SISENSE_SHOW_BEST_TIMES_ONLY_TEXT && formState.competitionYearName === '--' ?
              filterFactory.equals(DM.UsasSwimTime.SingleLifetimeBest, 1) : null;
        setDisplayFilter(display);
      } else {
        setDisplayFilter(null);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formState.displayName, formState.competitionYearName, environmentVariableState])

  useEffect(() => {
    //if any of the selections change, set the offset back to 0
    setState({ ...state, offset: 0 });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formState])

  useEffect(() => {
    //Reset previous filters on navigating back to page
    if (state.initialLoadComplete === false) {
      if (location.state?.individualTimesSearchFilters) {
        const searchFilters = location.state.individualTimesSearchFilters;

        if (searchFilters.courseFilter) {
          const course = filterFactory.members(DM.SwimEvent.CourseCode, searchFilters.courseFilter.members);
          setCourseFilter(course);
        }
        if (searchFilters.eventCodeFilter) {
          const eventCode = filterFactory.members(DM.SwimEvent.EventCode, searchFilters.eventCodeFilter.members);
          setEventCodeFilter(eventCode);
        }
        if (searchFilters.seasonYearFilter) {
          const seasonYear = filterFactory.members(DM.SeasonCalendar.SeasonYearDesc, searchFilters.seasonYearFilter.members);
          setSeasonYearFilter(seasonYear);
        }
        if (searchFilters.ageFilter) {
          const age = filterFactory.between(DM.Age.AgeKey, searchFilters.ageFilter.valueA, searchFilters.ageFilter.valueB);
          setAgeFilter(age);
        }
        if (searchFilters.timeStandardFilter) {
          const timeStandard = filterFactory.members(DM.StandardType.StandardType, searchFilters.timeStandardFilter.members);
          setTimeStandardFilter(timeStandard);
        }
        if (searchFilters.displayFilter) {
          const display = searchFilters.displayName === SISENSE_SHOW_ALL_TIMES_TEXT ? null :
            searchFilters.displayName === SISENSE_SHOW_BEST_TIMES_ONLY_TEXT && searchFilters.competitionYearName !== '--' ?
              filterFactory.equals(DM.UsasSwimTime.SingleSeasonBest, searchFilters.displayFilter.valueA)
              : searchFilters.displayName === SISENSE_SHOW_BEST_TIMES_ONLY_TEXT && searchFilters.competitionYearName === '--' ?
                filterFactory.equals(DM.UsasSwimTime.SingleLifetimeBest, searchFilters.displayFilter.valueA) : null;
          setDisplayFilter(display);
        }
        //handle reseting the multiselects
        setFormData({
          ...formState,
          courseId: searchFilters.courseId || Constants.DEFAULT_ID,
          courseName: searchFilters.courseName || '--',
          events: searchFilters.events || [],
          competitionYearId: searchFilters.competitionYearId || Constants.DEFAULT_ID,
          competitionYearName: searchFilters.competitionYearName || '--',
          ageFrom: searchFilters.ageFrom || '',
          ageTo: searchFilters.ageTo || '',
          timeStandardId: searchFilters.timeStandardId || Constants.DEFAULT_ID,
          timeStandardName: searchFilters.timeStandardName || '--',
          displayId: searchFilters.displayId || showAllTimesOptions.options[0]?.id,
          displayName: searchFilters.displayName || showAllTimesOptions.options[0]?.name,
        });

        //if there are no searchFilters.events and the event dropdown is loaded, consider the page loaded
        if (!searchFilters.events && eventOptions.options?.length > 0) {
          if (searchFilters.competitionYearId && searchFilters.competitionYearId !== Constants.DEFAULT_ID &&
            searchFilters.competitionYearName && searchFilters.competitionYearName !== '--') {
            setState({ ...state, isDefaultSeasonYearSet: true, initialLoadComplete: true });
          } else {
            setState({ ...state, initialLoadComplete: true });
          }
        }
        //if there are searchFilters.events and the event dropdown is loaded and the formState.events length
        //is the same as the searchFilters.events, consider the page loaded
        if ((searchFilters.events?.length === formState.events?.length) && eventOptions.options?.length > 0) {
          if (searchFilters.competitionYearId && searchFilters.competitionYearId !== Constants.DEFAULT_ID &&
            searchFilters.competitionYearName && searchFilters.competitionYearName !== '--') {
            setState({ ...state, isDefaultSeasonYearSet: true, initialLoadComplete: true });
          }
          else {
            setState({ ...state, initialLoadComplete: true });
          }
        }
      } else { //if there is not location.state.individualTimesSearchFilters, consider the page loaded
        setState({ ...state, initialLoadComplete: true });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.initialLoadComplete, eventOptions, formState.events])

  useEffect(() => {
    if (data) {
      const newFormattedData = getSisenseFormattedData(data, COLUMN_INDEXES, COLUMN_NUMBERS);
      const swimEventKeyIndex = newFormattedData.columns.findIndex((x) => x.columnNumber === COLUMN_NUMBERS.SWIM_EVENT_KEY);

      setFormattedData({
        ...formattedData,
        ...newFormattedData,
        swimEventKeyIndex
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return {
    isLoading,
    isError,
    location,
    state,
    formState,
    errorState,
    formattedData,
    onFiltersToggle,
    goToNextPage,
    goToPreviousPage,
    onBackToClubRosterClicked,
    onGoToMeetSummaryClicked,
    onSeeProgressionClicked,
    onFormValueChanged,
    onValueTextPairChanged
  };
};

export default useIndividualTimesSearchWidget;