const NavLinks = {
  CLUB_RECOGNITION_ROOT: '/clubrecognition',
  CLUB_RECOGNITION_SELECTION: '/clubrecognition/selection',
  CLUB_RECOGNITION_PROGRAM_LEVEL: '/clubrecognition/programlevel',
  CLUB_RECOGNITION_PROGRAM_CATEGORY: '/clubrecognition/programcategory',
  CLUB_RECOGNITION_PROGRAM_CATEGORY_YES_NO: '/clubrecognition/programcategory/yesno',
  CLUB_RECOGNITION_PROGRAM_CATEGORY_FILE_UPLOAD: '/clubrecognition/programcategory/fileupload',
  CLUB_RECOGNITION_PROGRAM_CATEGORY_TEXT_ENTRY: '/clubrecognition/programcategory/textentry',
  CLUB_RECOGNITION_PROGRAM_CATEGORY_DROPDOWN: '/clubrecognition/programcategory/dropdown',
  CLUB_RECOGNITION_PROGRAM_CATEGORY_KPI: '/clubrecognition/programcategory/kpi',
  CLUB_RECOGNITION_PROGRAM_CATEGORY_ELSEWHERE: '/clubrecognition/programcategory/swims'
};

export default NavLinks;