import { useContext } from 'react';

import { ClubRegistrationPaymentStateContext } from './ClubRegistrationPaymentContextProvider';

const paymentTypes = [{ name: 'Credit Card Payment', id: 'C' }, { name: 'Paypal', id: 'P' }];

const useClubRegistrationPaymentData = () => {
  const [clubRegistrationPaymentState, setClubRegistrationPaymentState] = useContext(ClubRegistrationPaymentStateContext);

  const clearClubRegistrationPaymentState = () => {
    setClubRegistrationPaymentState({
      ...clubRegistrationPaymentState,
      paymentType: 'C',
      status: 'unpaid',
      isProcessing: false,
      opaqueData: {},
      firstName: '',
      lastName: '',
      usePrimaryAddress: true,
      address: {},
      lineItems: [],
      amount: 0.00,
      message: ''
    });
  }

  return {
    clubRegistrationPaymentState,
    setClubRegistrationPaymentState,
    clearClubRegistrationPaymentState,
    paymentTypes
  };
};

export default useClubRegistrationPaymentData;