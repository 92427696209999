import { useContext, useEffect } from 'react';

import { PreCompInstructionHoursStateContext } from './PreCompInstructionHoursContextProvider';
import PreCompInstructionHoursData from './PreCompInstructionHoursData';

const usePreCompInstructionHoursData = () => {
  const [preCompInstructionHoursState, setPreCompInstructionHoursState] = useContext(PreCompInstructionHoursStateContext);

  const getInstructionHours = () => { PreCompInstructionHoursData.getPreCompInstructionHours(preCompInstructionHoursState, setPreCompInstructionHoursState); };

  useEffect(() => {
    if (preCompInstructionHoursState.isArrayLoading !== true && preCompInstructionHoursState.isArrayLoaded !== true) {
      getInstructionHours(preCompInstructionHoursState, setPreCompInstructionHoursState);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    preCompInstructionHoursState,
    getInstructionHours
  };
};

export default usePreCompInstructionHoursData;