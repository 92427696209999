import React, { Fragment } from 'react';

import useClubPolicies from './UseClubPolicies';

import Headings from '../../../../common/components/headings/Headings';
import PrimaryButton from '../../../../common/components/buttons/PrimaryButton';
import SecondaryButton from '../../../../common/components/buttons/SecondaryButton';
import YesNoSwitch from '../../../../common/components/yesNoSwitch/YesNoSwitch';
import DatePicker from '../../../../common/components/datepickers/DatePicker';
import PopUpModal from '../../../../common/components/dialogs/PopUpModal';
import Constants from '../../../../common/utils/Constants';

const ClubPoliciesWrite = () => {
  const {
    baseUsasClubState,
    formState,
    errorState,
    onFormValueChanged,
    handleSubmit,
    onCancelClicked
  } = useClubPolicies();

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>Club Policies</Headings.H3>
        </div>
      </div>
      <form onSubmit={handleSubmit} noValidate>
        <div className="row">
          <div className="col-xs-12 col-sm-6 col-md-4">
            <YesNoSwitch
              label="Pre-Employment screening required?"
              name="preEmploymentScreeningRequired"
              checked={formState.preEmploymentScreeningRequired}
              error={errorState.preEmploymentScreeningRequired}
              message={errorState.preEmploymentScreeningRequired}
              onChange={(value) => { onFormValueChanged('preEmploymentScreeningRequired', value); }} />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4">
            <YesNoSwitch
              label="Include in Find-A-Club Search?"
              name="includeInFindAclubSearch"
              checked={formState.includeInFindAclubSearch}
              error={errorState.includeInFindAclubSearch}
              message={errorState.includeInFindAclubSearch}
              onChange={(value) => { onFormValueChanged('includeInFindAclubSearch', value); }} />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4">
            <YesNoSwitch
              label="Racing start certified?"
              name="racingStartCertified"
              checked={formState.racingStartCertified}
              error={errorState.racingStartCertified}
              message={errorState.racingStartCertified}
              onChange={(value) => { onFormValueChanged('racingStartCertified', value); }} />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4">
            <YesNoSwitch
              label="State concussion laws?"
              name="stateConcussionsLaws"
              checked={formState.stateConcussionsLaws}
              error={errorState.stateConcussionsLaws}
              message={errorState.stateConcussionsLaws}
              onChange={(value) => { onFormValueChanged('stateConcussionsLaws', value); }} />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4">
            <YesNoSwitch
              label="Minor athlete abuse prevention policy?"
              name="minorAthleteAbusePreventionPolicy"
              checked={formState.minorAthleteAbusePreventionPolicy}
              error={errorState.minorAthleteAbusePreventionPolicy}
              message={errorState.minorAthleteAbusePreventionPolicy}
              onChange={(value) => { onFormValueChanged('minorAthleteAbusePreventionPolicy', value); }} />
          </div>
          <div className="row visible-sm visible-md visible-lg">
            <div className="col-xs-12">
            </div>
          </div>
          <div className="col-xs-12 col-sm-6 col-md-2">
            <YesNoSwitch
              label="CLBMS 101?"
              name="clbmS101"
              checked={formState.clbmS101}
              error={errorState.clbmS101}
              message={errorState.clbmS101}
              onChange={(value) => { onFormValueChanged('clbmS101', value); }} />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4">
            <DatePicker
              label="CLBMS 101 Completion Date"
              name="clbmS101CompletionDate"
              value={formState.clbmS101CompletionDate}
              error={errorState.clbmS101CompletionDate}
              message={errorState.clbmS101CompletionDate}
              onChange={(value) => { onFormValueChanged('clbmS101CompletionDate', value); }}
            />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-2">
            <YesNoSwitch
              label="CLBMS 201?"
              name="clbmS201"
              checked={formState.clbmS201}
              error={errorState.clbmS201}
              message={errorState.clbmS201}
              onChange={(value) => { onFormValueChanged('clbmS201', value); }} />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4">
            <DatePicker
              label="CLBMS 201 Completion Date"
              name="clbmS201CompletionDate"
              value={formState.clbmS201CompletionDate}
              error={errorState.clbmS201CompletionDate}
              message={errorState.clbmS201CompletionDate}
              onChange={(value) => { onFormValueChanged('clbmS201CompletionDate', value); }}
            />
          </div>
        </div>
        <div className="row  usas-extra-top-margin">
          <div className="col-xs-12 usas-extra-top-margin usas-extra-bottom-margin">
            <PrimaryButton type="submit">Save</PrimaryButton>&nbsp;
            <SecondaryButton type="button" onClick={onCancelClicked}>Cancel</SecondaryButton>
          </div>
        </div>
      </form>
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={baseUsasClubState.isObjLoading} />
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.SAVING_MSG}
        displayPopUp={baseUsasClubState.isSaving} />
    </Fragment>
  );
};

export default ClubPoliciesWrite;