import React, { Fragment } from 'react';

import global from '../../../../common/components/GlobalStyle.module.css';

const ClubRegistrationInfoRead = ({ formState, clubRegistrationState }) => (
  <Fragment>
    {formState.clubName !== '' || formState.clubCode !== '' ?
      <Fragment>
        <div className={[global.HeaderText, "row usas-extra-top-margin"].join(' ')}>
          <div className="col-xs-6 col-md-3">
            <p><b>Club Name:</b></p>
          </div>
          <div className="col-xs-6 col-md-9">
            <p>{formState.clubName !== '' ? formState.clubName : 'No Response'}</p>
          </div>
          <div className="col-xs-6 col-md-3">
            <p><b>Club Code:</b></p>
          </div>
          <div className="col-xs-6 col-md-3">
            <p>{formState.clubCode !== '' ? formState.clubCode : 'No Response'}</p>
          </div>
          <div className="col-xs-6 col-md-3">
            <p><b>LSC:</b></p>
          </div>
          <div className="col-xs-6 col-md-3">
            <p>{formState.lscname !== '' ? formState.lscname : 'No Response'}</p>
          </div>
          <div className="col-xs-6 col-md-3">
            <p><b>Year Founded:</b></p>
          </div>
          <div className="col-xs-6 col-md-3">
            <p>{formState.yearFounded !== '' ? formState.yearFounded : 'No Response'}</p>
          </div>
          <div className="col-xs-6 col-md-3">
            <p><b>Owner Type:</b></p>
          </div>
          <div className="col-xs-6 col-md-3">
            <p>{formState.ownerTypeName ? formState.ownerTypeName : 'No Response'}</p>
          </div>
          <div className="col-xs-6 col-md-3">
            <p><b>Tax Listing:</b></p>
          </div>
          <div className="col-xs-6 col-md-3">
            <p>{formState.businessEntityTypeName ? formState.businessEntityTypeName : 'No Response'}</p>
          </div>
          <div className="col-xs-6 col-md-3">
            <p><b>Setting:</b></p>
          </div>
          <div className="col-xs-6 col-md-3">
            <p>{formState.clubSetting !== '' ? formState.clubSetting : "No Response"}</p>
          </div>
          <div className="row visible-md visible-lg">
            <div className='col-xs-12'></div>
          </div>
          <div className="col-xs-6 col-md-3">
            <p><b>Website Address:</b></p>
          </div>
          <div className="col-xs-6 col-md-9">
            <p>{formState.websiteAddress ? formState.websiteAddress : "No Response"}</p>
          </div>
          <div className="col-xs-6 col-md-3">
            <p><b>Mailing Address:</b></p>
          </div>
          <div className="col-xs-6 col-md-9">
            {clubRegistrationState.objData?.usasClubAddress?.length > 0 ?
              <p>{formState.address1}{formState.address2 !== '' ? '  ' + formState.address2 : ''},  {formState.city},  {formState.stateCode} {formState.postalCode}</p> :
              <p>No Response</p>}
          </div>
        </div>
      </Fragment >
      : <Fragment />}
  </Fragment>
)

export default ClubRegistrationInfoRead;