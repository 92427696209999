import usePoolDimensionsDropdown from './UsePoolDimensionsDropdown';

import Dropdown from '../Dropdown';

import global from '../../GlobalStyle.module.css';

const PoolDimensionsDropdown = ({ label, name, value, error, message, onChange }) => {
  const { poolDimensionsState } = usePoolDimensionsDropdown();

  return poolDimensionsState.message
    ? <span className={global.LoadingMsg}>{poolDimensionsState.message}</span> :
    (
      <Dropdown
        error={error}
        label={label}
        message={message}
        options={poolDimensionsState.dataAsOptionsWithNames}
        name={name}
        value={value}
        onChange={onChange}
        isLoading={poolDimensionsState.isLoading} />
    );
};

export default PoolDimensionsDropdown;