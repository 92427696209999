import React, { Fragment } from 'react';

import HeadCoachReportGridLarge from './HeadCoachReportGridLarge';
import HeadCoachReportGridSmall from './HeadCoachReportGridSmall';

const HeadCoachReportGrid = ({ data, isLoading }) => (
  <Fragment>
    <HeadCoachReportGridLarge data={data} isLoading={isLoading} />
    <HeadCoachReportGridSmall data={data} isLoading={isLoading} />
  </Fragment>
);

export default HeadCoachReportGrid;