import { useContext } from 'react';

import { LearnToSwimRegistrationLeftNavStateContext } from './LearnToSwimRegistrationLeftNavContextProvider';

const useLearnToSwimRegistrationLeftNavData = () => {
  const [ltsRegistrationLeftNavState, setLTSRegistrationLeftNavState] = useContext(LearnToSwimRegistrationLeftNavStateContext);

  // refreshes all left nav objects and left nav title fields
  const refreshLeftNavState = (currentViewName, providerObj) => {
    const newArrayData = JSON.parse(JSON.stringify(ltsRegistrationLeftNavState.arrayData));

    // check isCurrent & isVisited
    for (const leftNavObj of newArrayData) {
      if (leftNavObj.name === currentViewName) {
        leftNavObj.isCurrent = true;
        leftNavObj.isVisited = true;
      } else {
        leftNavObj.isCurrent = false;
      }
    }

    // check arePrerequisitesDone
    for (const leftNavObj of newArrayData) {
      if (Array.isArray(leftNavObj.prerequisites) && leftNavObj.prerequisites.length > 0) {
        let arePrerequisitesDone = true;

        for (const prerequisiteName of leftNavObj.prerequisites) {
          const prerequisiteObj = newArrayData.find(x => x.name === prerequisiteName);

          if (prerequisiteObj?.isComplete !== true) {
            arePrerequisitesDone = false;
            break;
          }
        }

        leftNavObj.arePrerequisitesDone = arePrerequisitesDone;
      } else {
        leftNavObj.arePrerequisitesDone = true;
      }
    }

    const newObjData = {
      entityName: providerObj.entityName || '',
      taxId: providerObj.taxId || ''
    };

    setLTSRegistrationLeftNavState({
      ...ltsRegistrationLeftNavState,
      arrayData: newArrayData,
      objData: newObjData,
      isLoaded: true
    });
  };

  const updateCurrentLeftNavData = (currentViewName, isComplete = undefined) => {
    if (ltsRegistrationLeftNavState.isLoaded === true) {
      const newArrayData = JSON.parse(JSON.stringify(ltsRegistrationLeftNavState.arrayData));

      // check isCurrent & isVisited
      for (const leftNavObj of newArrayData) {
        if (leftNavObj.name === currentViewName) {
          leftNavObj.isCurrent = true;
          leftNavObj.isVisited = true;

          if (isComplete !== undefined) {
            leftNavObj.isComplete = isComplete;
          }
        } else {
          leftNavObj.isCurrent = false;
        }
      }

      // check arePrerequisitesDone if isComplete was passed
      if (isComplete !== undefined) {
        for (const leftNavObj of newArrayData) {
          if (Array.isArray(leftNavObj.prerequisites) && leftNavObj.prerequisites.length > 0) {
            let arePrerequisitesDone = true;

            for (const prerequisiteName of leftNavObj.prerequisites) {
              const prerequisiteObj = newArrayData.find(x => x.name === prerequisiteName);

              if (prerequisiteObj?.isComplete !== true) {
                arePrerequisitesDone = false;
                break;
              }
            }

            leftNavObj.arePrerequisitesDone = arePrerequisitesDone;
          } else {
            leftNavObj.arePrerequisitesDone = true;
          }
        }
      }

      setLTSRegistrationLeftNavState({
        ...ltsRegistrationLeftNavState,
        arrayData: newArrayData
      });
    }
  };

  return {
    ltsRegistrationLeftNavState,
    refreshLeftNavState,
    updateCurrentLeftNavData
  };
};

export default useLearnToSwimRegistrationLeftNavData;