import EditIcon from '../../../../common/components/icons/EditIcon';

import { formatDate } from '../../../../common/utils/DateFunctions';

import global from '../../../../common/components/GlobalStyle.module.css';

const SmallGridRow = ({ athlete, onEditAthlete }) => (
  <div className={global.SmallTableRow}>
    <div className={global.SmallTableRowHead}><span className='hidden-xs'>
      {(athlete.firstName || athlete.middleName || athlete.lastName)
        ? `${athlete.preferredName ? athlete.preferredName : athlete.firstName || ''} ${athlete.lastName || ''}`
        : ''
      }</span>&nbsp;
      {onEditAthlete &&
        <button
          className={global.IconButton}
          type="button"
          onClick={() => onEditAthlete(athlete.personId)}>
          <EditIcon />
        </button>
      }
    </div>
    <div className={global.SmallTableRowInfo}>
      <div className='row'>
        <div className={[global.SmallTableRowLabels, 'col-xs-6 visible-xs'].join(' ')}>Name</div>
        <div className={[global.SmallTableRowData, 'col-xs-6 visible-xs'].join(' ')}>
          {(athlete.firstName || athlete.middleName || athlete.lastName)
            ? `${athlete.preferredName ? athlete.preferredName : athlete.firstName || ''} ${athlete.lastName || ''}`
            : <span>&nbsp;</span>
          }</div>
        <div className={['col-xs-6 col-sm-4 hidden-xs', global.SmallTableRowLabels].join(' ')}>Competition Category</div>
        <div className={['col-xs-6 col-sm-4 visible-xs', global.SmallTableRowLabels].join(' ')}>Comp. Category</div>
        <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-2'].join(' ')}>{athlete.competitionGenderTypeName || <span>&nbsp;</span>}</div>
        <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3'].join(' ')}>Birth Date</div>
        <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3'].join(' ')}>{athlete.birthDate ? formatDate(athlete.birthDate) : <span>&nbsp;</span>}</div>
        <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-4'].join(' ')}>Age</div>
        <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-2'].join(' ')}>{athlete.age >= 0 ? athlete.age : <span>&nbsp;</span>}</div>
        <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3'].join(' ')}>Member Type</div>
        <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3'].join(' ')}>{athlete.orgRoleName || <span>&nbsp;</span>}</div>
        <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-4'].join(' ')}>Good Standing Exp. Date</div>
        <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-2'].join(' ')}>{athlete.isInGoodStandingExpirationDate ? formatDate(athlete.isInGoodStandingExpirationDate) : <span>&nbsp;</span>}</div>
        <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3'].join(' ')}>Attach Date</div>
        <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3'].join(' ')}>{athlete.attachDate ? formatDate(athlete.attachDate) : <span>&nbsp;</span>}</div>
      </div>
    </div>
  </div>
);

const ClubAthleteRosterGridSmall = ({ gridData, isLoading, onEditAthlete }) => (
  <div className={[global.SmallTable, 'hidden-md hidden-lg'].join(' ')}>
    {isLoading === true
      ? (
        <div className={global.SmallTableRow}>
          <div className={global.SmallTableRowHead}>&nbsp;</div>
          <div className={global.SmallTableRowInfo}>
            <div className={global.SmallTableRowLabels}>Loading...</div>
          </div>
        </div>
      )
      : Array.isArray(gridData) && gridData.length > 0
        ? gridData.map((athlete, i) => <SmallGridRow key={i} athlete={athlete} onEditAthlete={onEditAthlete} />)
        : (
          <div className={global.SmallTableRow}>
            <div className={global.SmallTableRowHead}>&nbsp;</div>
            <div className={global.SmallTableRowInfo}>
              <div className={global.SmallTableRowLabels}>No Athletes</div>
            </div>
          </div>
        )
    }
  </div>
);

export default ClubAthleteRosterGridSmall;