import { useContext, useEffect } from 'react';

import { PreCompPolicyTypeStateContext } from './PreCompPolicyTypeContextProvider';
import PreCompPolicyTypeData from './PreCompPolicyTypeData';

const usePreCompPolicyTypeData = () => {
  const [preCompPolicyTypeState, setPreCompPolicyTypeState] = useContext(PreCompPolicyTypeStateContext);

  const getPolicyType = () => { PreCompPolicyTypeData.getPreCompInsurancePolicyTypes(preCompPolicyTypeState, setPreCompPolicyTypeState); };

  useEffect(() => {
    if (preCompPolicyTypeState.isArrayLoading !== true && preCompPolicyTypeState.isArrayLoaded !== true) {
      getPolicyType(preCompPolicyTypeState, setPreCompPolicyTypeState);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    preCompPolicyTypeState,
    getPolicyType
  };
};

export default usePreCompPolicyTypeData;