import { Fragment } from "react";

import useMeetSummaryDashboard from "./UseMeetSummaryDashboard";

import MeetSummaryIndicatorWidget from "./MeetSummaryIndicatorWidget";
import MeetSummaryColumnChartWidget from "./MeetSummaryColumnChartWidget";
import MeetSummaryPieChartWidget from "./MeetSummaryPieChartWidget";
import MeetSummaryLargePieChartWidget from "./MeetSummaryLargePieChartWidget";

import Headings from "../../../../../../common/components/headings/Headings";
import LoadingModal from "../../../../../../common/components/dialogs/LoadingModal";
import LeftArrowIcon from "../../../../../../common/components/icons/LeftArrowIcon";
import ActionIntraPageButton from '../../../../../../common/components/buttons/ActionIntraPageButton';

import global from '../../../../../../common/components/GlobalStyle.module.css';

const MeetSummaryDashboard = ({ dashboardOid }) => {
  const {
    location,
    navRoutes,
    isError,
    isLoading,
    dashboard,
    MS_NUM_OF_SWIMS_WIDGET_OID,
    MS_NUM_OF_SWIMMERS_WIDGET_OID,
    MS_NUM_OF_LSCS_WIDGET_OID,
    MS_NUM_OF_TEAMS_WIDGET_OID,
    MS_PCT_LIFETIME_BEST_WIDGET_OID,
    MS_PCT_SEASON_BEST_WIDGET_OID,
    MS_NUM_OF_RECORDS_BROKEN_WIDGET_OID,
    MS_NUM_SWIMMERS_TO_BREAK_RECORD_WIDGET_OID,
    MS_NUM_OF_SWIMMERS_PER_EVENT_WIDGET_OID,
    MS_INDIVIDUAL_COMPETITION_CATEGORIES_WIDGET_OID,
    MS_NUMBER_OF_RELAYS_PER_EVENT_WIDGET_OID,
    MS_RELAY_COMPETITION_CATEGORIES_WIDGET_OID,
    MS_TIME_STANDARDS_ACHIEVED_BY_SWIM_WIDGET_OID,
    MS_TIME_STANDARDS_ACHIEVED_BY_PERSON_WIDGET_OID,
    onBackToMeetSummarySearch,
    onBackToPersonTimesSearch,
    onBackToRelayTimesSearch,
    onGoToMeetResults
  } = useMeetSummaryDashboard(dashboardOid);

  if (isError) {
    return <p>Error</p>;
  }

  if (isLoading) {
    return <LoadingModal />;
  }

  if (dashboard) {
    return (
      <Fragment>
        <div className="row usas-extra-top-margin">
          <div className="col-xs-12 col-sm-6 usas-extra-top-margin">
            <Headings.H5>{location.state?.meetName}</Headings.H5>
          </div>
          <div className="col-xs-12 col-sm-6 usas-extra-top-margin">
            <div className="pull-right">
              <button className={global.BackToSearchButton}
                type="button"
                onClick={location.state?.navigateBackTo === navRoutes.INDIVIDUAL_SEARCH?.route || location.state?.navigateBackTo === navRoutes.TIMES_EVENT_RANK?.route ?
                  onBackToPersonTimesSearch : location.state?.navigateBackTo === navRoutes.TIMES_RELAY_EVENT_RANK?.route ? onBackToRelayTimesSearch : onBackToMeetSummarySearch}>
                <LeftArrowIcon /> {location.state?.navigateBackTo === navRoutes.INDIVIDUAL_SEARCH?.route || location.state?.navigateBackTo === navRoutes.TIMES_EVENT_RANK?.route ? `Back to Individual Times for ${location.state.personName || ''}` :
                  location.state?.navigateBackTo === navRoutes.TIMES_RELAY_EVENT_RANK?.route ? 'Back to Relay Event Rank Search' : 'Back to Meet Search'}
              </button>
            </div>
          </div>
          <div className="col-xs-12 usas-extra-top-margin">
            <ActionIntraPageButton onClick={onGoToMeetResults}>View Meet Results</ActionIntraPageButton>
          </div>
        </div>
        <MeetSummaryIndicatorWidget dashboardOid={dashboardOid} widgetOid={MS_NUM_OF_SWIMS_WIDGET_OID} filterRecordsBroken={false} />
        <MeetSummaryIndicatorWidget dashboardOid={dashboardOid} widgetOid={MS_NUM_OF_SWIMMERS_WIDGET_OID} filterRecordsBroken={false} />
        <MeetSummaryIndicatorWidget dashboardOid={dashboardOid} widgetOid={MS_NUM_OF_LSCS_WIDGET_OID} filterRecordsBroken={false} />
        <MeetSummaryIndicatorWidget dashboardOid={dashboardOid} widgetOid={MS_NUM_OF_TEAMS_WIDGET_OID} filterRecordsBroken={false} />
        <MeetSummaryIndicatorWidget dashboardOid={dashboardOid} widgetOid={MS_PCT_LIFETIME_BEST_WIDGET_OID} filterRecordsBroken={false} />
        <div className="hidden-lg hidden-md hidden-sm"><div className="row usas-extra-top-margin usas-extra-bottom-margin"><div className="col-xs-12 usas-extra-top-margin usas-extra-bottom-margin"></div></div></div>
        <MeetSummaryIndicatorWidget dashboardOid={dashboardOid} widgetOid={MS_PCT_SEASON_BEST_WIDGET_OID} filterRecordsBroken={false} />
        <MeetSummaryIndicatorWidget dashboardOid={dashboardOid} widgetOid={MS_NUM_OF_RECORDS_BROKEN_WIDGET_OID} filterRecordsBroken={true} />
        <MeetSummaryIndicatorWidget dashboardOid={dashboardOid} widgetOid={MS_NUM_SWIMMERS_TO_BREAK_RECORD_WIDGET_OID} filterRecordsBroken={true} />
        <div className="row usas-extra-top-margin usas-extra-bottom-margin"><div className="col-xs-12 usas-extra-top-margin usas-extra-bottom-margin"></div></div>
        <MeetSummaryColumnChartWidget dashboardOid={dashboardOid} widgetOid={MS_NUM_OF_SWIMMERS_PER_EVENT_WIDGET_OID} />
        <div className="hidden-lg hidden-md"><div className="row usas-extra-top-margin usas-extra-bottom-margin"><div className="col-xs-12 usas-extra-top-margin usas-extra-bottom-margin"></div></div></div>
        <MeetSummaryPieChartWidget dashboardOid={dashboardOid} widgetOid={MS_INDIVIDUAL_COMPETITION_CATEGORIES_WIDGET_OID} />
        <div className="row usas-extra-top-margin usas-extra-bottom-margin"><div className="col-xs-12 usas-extra-top-margin usas-extra-bottom-margin"></div></div>
        <MeetSummaryColumnChartWidget dashboardOid={dashboardOid} widgetOid={MS_NUMBER_OF_RELAYS_PER_EVENT_WIDGET_OID} />
        <div className="hidden-lg hidden-md"><div className="row usas-extra-top-margin usas-extra-bottom-margin"><div className="col-xs-12 usas-extra-top-margin usas-extra-bottom-margin"></div></div></div>
        <MeetSummaryPieChartWidget dashboardOid={dashboardOid} widgetOid={MS_RELAY_COMPETITION_CATEGORIES_WIDGET_OID} />
        <div className="row usas-extra-top-margin usas-extra-bottom-margin"><div className="col-xs-12 usas-extra-top-margin usas-extra-bottom-margin"></div></div>
        <MeetSummaryLargePieChartWidget dashboardOid={dashboardOid} widgetOid={MS_TIME_STANDARDS_ACHIEVED_BY_SWIM_WIDGET_OID} />
        <div className="hidden-lg hidden-md"><div className="row usas-extra-top-margin usas-extra-bottom-margin"><div className="col-xs-12 usas-extra-top-margin usas-extra-bottom-margin"></div></div></div>
        <MeetSummaryLargePieChartWidget dashboardOid={dashboardOid} widgetOid={MS_TIME_STANDARDS_ACHIEVED_BY_PERSON_WIDGET_OID} />
      </Fragment>
    );
  }

  return <Fragment></Fragment>;
};

export default MeetSummaryDashboard;