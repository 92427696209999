import React, { createContext, useState } from 'react';

import ClubRegistrationNavData from './ClubRegistrationNavData';

export const ClubRegistrationNavStateContext = createContext();

const ClubRegistrationNavContextProvider = ({ children }) => {
  const stateHook = useState(ClubRegistrationNavData.INITIAL_STATE);

  return (
    <ClubRegistrationNavStateContext.Provider value={stateHook}>
      {children}
    </ClubRegistrationNavStateContext.Provider>
  );
};

export default ClubRegistrationNavContextProvider;