import { isValidLink } from '../../utils/PreCompUtils';

const PreCompLocationInfoFormValidation = (formState) => {
  let errors = {};

  const lessonCostValue = parseFloat(formState.averageLessonCost);
  const sessionCostValue = parseFloat(formState.averageSessionCost);

  if (formState.locationName.trim() === '') {
    errors.locationName = 'Location Name is required';
  }

  if (isNaN(lessonCostValue)) {
    errors.averageLessonCost = 'Must be a valid number';
  } else if (lessonCostValue < 0) {
    errors.averageLessonCost = 'Cannot be a negative number';
  } else if (lessonCostValue > sessionCostValue) {
    errors.averageLessonCost = 'Average Lesson Cost cannot be greater than the Average Session Cost';
  }

  if (isNaN(sessionCostValue)) {
    errors.averageSessionCost = 'Must be a valid number';
  } else if (sessionCostValue < 0) {
    errors.averageSessionCost = 'Cannot be a negative number';
  } else if (lessonCostValue > sessionCostValue) {
    errors.averageSessionCost = 'Average Session Cost cannot be less than the Average Lesson Cost';
  }

  if (formState.financialAidOffered !== true && formState.financialAidOffered !== false) {
    errors.financialAidOffered = 'Must select yes or no for this field';
  }

  if (!(formState.instructionHoursPerSessionId > 0)) {
    errors.instructionHoursPerSessionId = 'Total In-Water Instructional Time/Child is required';
  }

  if (!(formState.instructorStudentRatioId > 0)) {
    errors.instructorStudentRatioId = 'Instructor to Student Ratio is required';
  }

  if (!(formState.preCompCurriculumId > 0)) {
    errors.preCompCurriculumId = 'Learn to Swim Curriculum is required';
  }

  if (formState.website.trim() === '') {
    errors.website = 'Website is required';
  } else if (!isValidLink(formState.website)) {
    errors.website = `Website must begin with 'http://' or 'https://'`;
  }

  return errors;
};

export default PreCompLocationInfoFormValidation;