import { useEffect } from 'react';
import { useNavigate, useLocation } from '../../../../common/wrappers/ReactRouterDom';

import NavLinks from '../NavLinks';

import useEnvironmentVariableData from '../../../../common/state/environmentVariable/UseEnvironmentVariableData';
import useRecognitionProgramData from '../../../../common/state/recognitionProgram/UseRecognitionProgramData';

const useClubRecognitionCategoryMeasure = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { environmentVariableState, KPI_BASIC_MEASURE_TYPE_ID, KPI_COACH_ROSTER_MEASURE_TYPE_ID, KPI_COACH_YEARS_MEASURE_TYPE_ID
  } = useEnvironmentVariableData();
  const { programFocusState, updateProgramFocus } = useRecognitionProgramData();

  const onRedirectToCategory = (performGet) => {
    navigate(NavLinks.CLUB_RECOGNITION_PROGRAM_CATEGORY, { state: { performGet } });
  };

  useEffect(() => {
    if (location.state?.categoryMeasureId > 0) {
      updateProgramFocus(programFocusState?.programLevelInstanceId, programFocusState?.programCategoryId, location.state?.categoryMeasureId);
    } else if (!(programFocusState?.categoryMeasureId > 0)) {
      navigate(NavLinks.CLUB_RECOGNITION_ROOT);
    } else {
      updateProgramFocus(programFocusState?.programLevelInstanceId, programFocusState?.programCategoryId, programFocusState?.categoryMeasureId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    isLoading: environmentVariableState.isLoading,
    programFocusState,
    KPI_BASIC_MEASURE_TYPE_ID,
    KPI_COACH_ROSTER_MEASURE_TYPE_ID,
    KPI_COACH_YEARS_MEASURE_TYPE_ID,
    onRedirectToCategory
  };
};

export default useClubRecognitionCategoryMeasure;