/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Fragment } from 'react';

import HideIcon from '../../../../../common/components/icons/HideIcon';
import ShowIcon from '../../../../../common/components/icons/ShowIcon';
import EditIcon from '../../../../../common/components/icons/EditIcon';
import DeleteIcon from '../../../../../common/components/icons/DeleteIcon';
import UnlinkIcon from '../../../../../common/components/icons/UnlinkIcon';
import AddPoolIcon from '../../../../../common/components/icons/AddPoolIcon';

import global from '../../../../../common/components/GlobalStyle.module.css';

const DetailTableRow = ({ pool, onEditPool, onDeletePool }) => (
  <div className={global.SmallTableRow}>
    <div className={global.SmallTableDetailRowHead}>
      <span className='hidden-xs'>{pool.facilityPoolName && pool.facilityPoolName.trim() !== '' ?
        pool.facilityPoolName : <span>&nbsp;</span>}</span>&nbsp;
      {onDeletePool !== undefined &&
        <button
          type="button"
          className={global.IconButton}
          onClick={(e) => onDeletePool(e, pool)}>
          <DeleteIcon />
        </button>}
      {onEditPool !== undefined &&
        <button
          type="button"
          className={global.IconButtonMobileMargin}
          onClick={(e) => onEditPool(e, pool.facilityId, pool.facilityPoolId)}>
          <EditIcon />
        </button>}
    </div>
    <div className={global.SmallTableRowInfo}>
      <div className='row'>
        <div className={[global.SmallTableRowLabels, 'col-xs-6 visible-xs'].join(' ')}>Pool Name</div>
        <div className={[global.SmallTableRowData, 'col-xs-6 visible-xs'].join(' ')}>{pool.facilityPoolName &&
          pool.facilityPoolName.trim() !== '' ? pool.facilityPoolName : <span>&nbsp;</span>}</div>
        <div className={[global.SmallTableRowLabels, 'col-xs-6'].join(' ')}>Pool Dimensions</div>
        <div className={[global.SmallTableRowData, 'col-xs-6'].join(' ')}>{pool.poolDimensions ?
          pool.poolDimensions.dimensionName : <span>&nbsp;</span>}</div>
      </div>
    </div>
  </div>
);

const DetailTable = ({ facility, onEditPool, onDeletePool }) => (
  <div className={global.SmallTableDetailTable}>
    {Array.isArray(facility.facilityPool) && facility.facilityPool.map((pool, i) =>
      <DetailTableRow key={i} pool={pool} onEditPool={onEditPool} onDeletePool={onDeletePool} />)}
  </div>
);

const GridRow = ({ facility, onEdit, onDelete, onAddPool, onEditPool, onDeletePool, expandedId, onClick }) => {
  return (
    <Fragment>
      <div className={global.SmallTableRow} key={facility.facilityId}>
        <div className={global.SmallTableRowHead}><span className='hidden-xs'>{facility.facility?.facilityName}</span>&nbsp;
          <button className={global.IconButton}
            type="button"
            onClick={(e) => onClick(e, facility.facilityId)}>
            {expandedId === facility.facilityId ? <HideIcon /> : <ShowIcon />}
          </button>
          {onAddPool !== undefined ?
            <button className={global.IconButtonMobileMargin}
              type="button"
              onClick={(e) => onAddPool(e, facility.facilityId)}>
              <AddPoolIcon />
            </button> : <Fragment />}
          {onDelete !== undefined &&
            <button
              className={global.IconButtonMobileMargin}
              type="button"
              onClick={() => onDelete(facility.orgUnitFacilityId || facility.facilityId)}>
              <UnlinkIcon />
            </button>
          }
          {onEdit !== undefined &&
            <button
              className={global.IconButtonMobileMargin}
              type="button"
              onClick={() => onEdit(facility.facilityId)}>
              <EditIcon />
            </button>
          }
        </div>
        <div className={global.SmallTableRowInfo}>
          <div className='row'>
            <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3 visible-xs'].join(' ')}>Facility Name</div>
            <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3 visible-xs'].join(' ')}>{facility.facility?.facilityName || <span>&nbsp;</span>}</div>
            <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3'].join(' ')}>City</div>
            <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3'].join(' ')}>{facility.facility?.city || <span>&nbsp;</span>}</div>
            <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3'].join(' ')}>State</div>
            <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3'].join(' ')}>{facility.facility?.stateCode || <span>&nbsp;</span>}</div>
            <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3'].join(' ')}>Zip Code</div>
            <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3'].join(' ')}>{facility.facility?.postalCode || <span>&nbsp;</span>}</div>
            <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3'].join(' ')}>Address</div>
            <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3'].join(' ')}>{facility.facility?.address1 || <span>&nbsp;</span>}</div>
          </div>
        </div>
      </div>
      {Array.isArray(facility.facility.facilityPool) && facility.facility.facilityPool.length > 0 &&
        expandedId === facility.facilityId &&
        <DetailTable facility={facility.facility} onEditPool={onEditPool} onDeletePool={onDeletePool} />}
    </Fragment>
  );
};

const PreCompLocationFacilityGridSmall = ({ data, isLoading, onEdit, onDelete, onAddPool, onEditPool, onDeletePool,
  expandedId, onClick }) => (
  <div className={[global.SmallTable, 'hidden-md hidden-lg'].join(' ')}>
    {isLoading === true
      ? (
        <div className={global.SmallTableRow}>
          <div className={global.SmallTableRowHead}>&nbsp;</div>
          <div className={global.SmallTableRowInfo}>
            <div className={global.SmallTableRowLabels}>Loading...</div>
          </div>
        </div>
      )
      : data.length > 0
        ? data.map((facility, i) =>
          <GridRow key={i} facility={facility} onEdit={onEdit}
            onDelete={data.length > 1 ? onDelete : undefined}
            onAddPool={onAddPool} onEditPool={onEditPool} onDeletePool={onDeletePool}
            expandedId={expandedId} onClick={onClick} />)
        : (
          <div className={global.SmallTableRow}>
            <div className={global.SmallTableRowHead}>&nbsp;</div>
            <div className={global.SmallTableRowInfo}>
              <div className={global.SmallTableRowLabels}>No Facilities</div>
            </div>
          </div>
        )}
  </div>
);

export default PreCompLocationFacilityGridSmall;