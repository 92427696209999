import { useEffect, useState } from 'react';
import { useNavigate, useLocation } from '../../../../common/wrappers/ReactRouterDom';

import NavLinks from '../NavLinks';

import validate from '../components/forms/PreCompLocationStaffFormValidation';

import PreCompConstants from '../utils/PreCompConstants';

import usePreCompLocationData from '../../../state/preCompLocation/UsePreCompLocationData';

import usePreCompLocationStaffData from '../../../state/preCompLocationStaff/UsePreCompLocationStaffData';

import usePersonData from '../../../../common/state/person/UsePersonData';
import usePersonGeneralData from '../../../../common/state/personGeneral/UsePersonGeneralData';
import useEnvironmentVariableData from '../../../../common/state/environmentVariable/UseEnvironmentVariableData';
import useOrgRoleStaffRoleHierarchyData from '../../../../common/state/orgRoleStaffRoleHierarchy/UseOrgRoleStaffRoleHierarchyData';

import useForm from '../../../../common/utils/UseForm';
import Constants from '../../../../common/utils/Constants';
import { formatDate } from '../../../../common/utils/DateFunctions';

const usePreCompLocationStaffDetail = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { preCompLocationState } = usePreCompLocationData();
  const { preCompLocationStaffState, savePreCompLocationStaff, resetPreCompLocationStaffState } = usePreCompLocationStaffData();
  const { personState, getPotentialPersonDupes, postBasePerson } = usePersonData();
  const { personGeneralState, getPersonGeneral, putPersonGeneralContact, clearObjData } = usePersonGeneralData();
  const { orgRoleStaffRoleHierarchyState } = useOrgRoleStaffRoleHierarchyData();
  const { environmentVariableState, UNKNOWN_COMPETITION_GENDER_ID, OTHER_PHONE_TYPE_ID, PRECOMP_STAFF_ROLE_GROUP_ID,
    PRIMARY_CONTACT_ORG_ROLE_ID, INSTRUCTOR_ORG_ROLE_ID, CPR_CERT_ORG_ROLE_FIELD_ID, FIRST_AID_CERT_ORG_ROLE_FIELD_ID,
    LIFEGUARD_CERT_ORG_ROLE_FIELD_ID, PRE_EMPLOYMENT_ORG_ROLE_FIELD_ID, SELF_RELATIONSHIP_ID } = useEnvironmentVariableData();
  const { formState, errorState, setIsDirty, handleSubmit, updateFormState, setFormData, setErrors
  } = useForm(getInitialFormState, submitFormCallback, validate);
  const [dupGridState, setDupGridState] = useState([]);
  const [newGridState, setNewGridState] = useState([]);
  const [isPrimaryModalState, setIsPrimaryModalState] = useState(getInitialIsPrimaryModalState);
  const [displayLocalDupPersonModalState, setDisplayLocalDupPersonModalState] = useState(false);

  const onIsPrimaryModalContinueClicked = () => {
    setIsPrimaryModalState({
      ...isPrimaryModalState,
      displayModal: false
    });

    if (formState.personId) {
      savePreCompLocationStaff(preCompLocationState.objData.orgUnitId, formState.personId, PRECOMP_STAFF_ROLE_GROUP_ID, createPersonOrgRole(formState.orgRolesId));
    } else {
      getPotentialPersonDupes(formState.firstName, formState.lastName, formState.birthDate);
    }
  };

  const onIsPrimaryModalCancelClicked = () => {
    setIsDirty(true);
    setIsPrimaryModalState(getInitialIsPrimaryModalState);
  };

  const onLocalDupPersonModalCancelClicked = () => {
    setIsDirty(true);
    setDisplayLocalDupPersonModalState(false);
  };

  const onCancelClicked = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    navigate(NavLinks.PRE_COMP_LOCATION_STAFF);
  };

  const onModalCancelClicked = () => {
    setDupGridState([]);
    setNewGridState([]);
    setIsDirty(true);
  };

  const onModalContinueClicked = () => {
    let matchFound = false;

    for (const dup of dupGridState) {
      if (dup.isSelected === true) {
        savePreCompLocationStaff(preCompLocationState.objData.orgUnitId, dup.personId, PRECOMP_STAFF_ROLE_GROUP_ID, createPersonOrgRole(formState.orgRolesId));

        matchFound = true;
        break;
      }
    }

    if (matchFound === false) {
      postBasePerson({
        firstName: formState.firstName.trim(),
        lastName: formState.lastName.trim(),
        birthDate: formState.birthDate,
        email: formState.email.trim(),
        competitionGenderTypeId: UNKNOWN_COMPETITION_GENDER_ID,
        isDeleted: false
      });
    }

    setDupGridState([]);
    setNewGridState([]);
  };

  const onSelectGridPerson = (personId) => {
    let newDupGridState = [];
    for (const dup of dupGridState) {
      newDupGridState.push({
        ...dup,
        isSelected: personId === dup.personId ? true : false
      });
    }

    setDupGridState(newDupGridState);
    setNewGridState([{
      ...newGridState[0],
      isSelected: personId ? false : true
    }]);
  };

  useEffect(() => {
    if (personState.isArrayLoaded === true && environmentVariableState.isLoaded === true) {
      if (personState.arrayData.length > 0) {
        setDupGridState(personState.arrayData.map(x => {
          let orgDuration = { orgUnitName: '', effectiveDate: '', expirationDate: '' };
          if (Array.isArray(x.orgDurations) && x.orgDurations.length > 0) {
            orgDuration = {
              orgUnitName: x.orgDurations[0].orgUnitName,
              effectiveDate: x.orgDurations[0].effectiveDate,
              expirationDate: x.orgDurations[0].expirationDate
            };
          }

          return {
            personId: x.personId,
            firstName: x.firstName,
            lastName: x.lastName,
            birthDate: x.birthDate,
            orgDuration,
            isSelected: false
          };
        }));
        setNewGridState([{
          personId: '',
          firstName: formState.firstName,
          lastName: formState.lastName,
          birthDate: formState.birthDate,
          orgDurations: { organization: '', effectiveDate: '', expirationDate: '' },
          isSelected: true
        }]);
      } else {
        postBasePerson({
          firstName: formState.firstName.trim(),
          lastName: formState.lastName.trim(),
          birthDate: formState.birthDate,
          email: formState.email.trim(),
          competitionGenderTypeId: UNKNOWN_COMPETITION_GENDER_ID,
          isDeleted: false
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [personState.isArrayLoaded, environmentVariableState]);

  useEffect(() => {
    if (personState.isSaving === false && personState.isSaved === true) {
      getPersonGeneral(personState.objData.personId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [personState]);

  useEffect(() => {
    if (personGeneralState.isObjLoaded === true && personGeneralState.isObjLoading === false &&
      personGeneralState.isSaving === false && personGeneralState.isSaved === false) {
      putPersonGeneralContact(personGeneralState.objData.personId, createPutPersonGeneralObj(JSON.parse(JSON.stringify(personGeneralState.objData)), formState));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [personGeneralState]);

  useEffect(() => {
    if ((personGeneralState.isSaving === false && personGeneralState.isSaved === true) ||
      (personState.isObjLoading === false && personState.isObjLoaded === true)) {
      if (preCompLocationStaffState.isSaving === false && preCompLocationStaffState.isSaved === false) {
        savePreCompLocationStaff(preCompLocationState.objData.orgUnitId, personState.objData.personId, PRECOMP_STAFF_ROLE_GROUP_ID, createPersonOrgRole(formState.orgRolesId));
        if (personGeneralState.isSaved === true) {
          clearObjData();
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [personGeneralState, personState]);

  useEffect(() => {
    if (preCompLocationStaffState.isSaving === false && preCompLocationStaffState.isSaved === true) {
      if (isPrimaryModalState.currentPrimaryContactId !== Constants.DEFAULT_ID && preCompLocationStaffState.objData?.personId !== isPrimaryModalState.currentPrimaryContactId) {
        savePreCompLocationStaff(preCompLocationState.objData.orgUnitId, isPrimaryModalState.currentPrimaryContactId, PRECOMP_STAFF_ROLE_GROUP_ID, createPersonOrgRole(isPrimaryModalState.currentPrimaryContact.personOrgRole.map(x => { return { id: x.orgRoleId }; }).filter(x => x.id !== PRIMARY_CONTACT_ORG_ROLE_ID)));
        setIsPrimaryModalState(getInitialIsPrimaryModalState);
      } else {
        resetPreCompLocationStaffState();
        navigate(NavLinks.PRE_COMP_LOCATION_STAFF);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [environmentVariableState, preCompLocationStaffState]);

  useEffect(() => {
    if (location.state && location.state && orgRoleStaffRoleHierarchyState.isArrayLoaded) {
      const instructorFields = {
        personOrgRoleId: Constants.DEFAULT_ID,
        personOrgRoleDurationId: Constants.DEFAULT_ID,
        isCPRAEDCertified: false,
        isFirstAidCertified: false,
        isLifeguardCertified: false,
        isPreEmploymentScreening: false
      };
      const staffObj = location.state.staffObj;
      const instructorRole = staffObj.personOrgRole.find(x => x.orgRoleId === INSTRUCTOR_ORG_ROLE_ID);
      if (instructorRole && Array.isArray(instructorRole.personOrgRoleFields)) {
        instructorFields.personOrgRoleId = instructorRole.personOrgRoleId;
        instructorFields.personOrgRoleDurationId = instructorRole.personOrgRoleDurationId;
        for (const personOrgRole of instructorRole.personOrgRoleFields) {
          if (personOrgRole.orgRoleFieldId === CPR_CERT_ORG_ROLE_FIELD_ID) {
            instructorFields.isCPRAEDCertified = personOrgRole.fieldValue === '1' ? true : false;
          } else if (personOrgRole.orgRoleFieldId === FIRST_AID_CERT_ORG_ROLE_FIELD_ID) {
            instructorFields.isFirstAidCertified = personOrgRole.fieldValue === '1' ? true : false;
          } else if (personOrgRole.orgRoleFieldId === LIFEGUARD_CERT_ORG_ROLE_FIELD_ID) {
            instructorFields.isLifeguardCertified = personOrgRole.fieldValue === '1' ? true : false;
          } else if (personOrgRole.orgRoleFieldId === PRE_EMPLOYMENT_ORG_ROLE_FIELD_ID) {
            instructorFields.isPreEmploymentScreening = personOrgRole.fieldValue === '1' ? true : false;
          }
        }
      }

      setFormData({
        ...formState,
        ...instructorFields,
        personId: staffObj.personId || '',
        firstName: staffObj.firstName || '',
        lastName: staffObj.lastName || '',
        phoneNumber: staffObj.phoneNumber || '',
        email: staffObj.email || '',
        birthDate: staffObj.birthDate
          ? formatDate(staffObj.birthDate)
          : Constants.BLANK_DATE_STRING,
        orgRolesId: staffObj.personOrgRole
          ? staffObj.personOrgRole.map(r => { return { id: r.orgRoleId, name: r.orgRoleName }; })
          : []
      });
      setIsDirty(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orgRoleStaffRoleHierarchyState]);

  function submitFormCallback(formState) {
    const currentPrimaryContact = findCurrentPrimaryContact(preCompLocationStaffState.arrayData);

    // edit
    if (formState.personId) {
      if (currentPrimaryContact !== null) {
        const areEditingPrimaryContact = currentPrimaryContact.personId === formState.personId;
        const isPrimaryContactRoleSelected = formState.orgRolesId.findIndex(x => x.id === PRIMARY_CONTACT_ORG_ROLE_ID) >= 0;
        const isSecondaryContactRoleSelected = formState.orgRolesId.findIndex(x => x.name === PreCompConstants.ORG_ROLE_NAME_SECONDARY_CONTACT) >= 0;

        if (isPrimaryContactRoleSelected === true && isSecondaryContactRoleSelected === true) {
          setErrors({ ...errorState, orgRolesId: 'The Primary Contact cannot also be a Secondary Contact' });
        } else if (areEditingPrimaryContact === true && isPrimaryContactRoleSelected === false) {
          setIsPrimaryModalState({
            ...isPrimaryModalState,
            currentPrimaryContactId: currentPrimaryContact.personId,
            currentPrimaryContact,
            isEditingCurrentPrimaryContact: true,
            displayModal: true
          });
        } else if (areEditingPrimaryContact === false && isPrimaryContactRoleSelected === true) {
          setIsPrimaryModalState({
            ...isPrimaryModalState,
            currentPrimaryContactId: currentPrimaryContact.personId,
            currentPrimaryContact,
            isEditingCurrentPrimaryContact: false,
            isOnlyRole: currentPrimaryContact.personOrgRole.length === 1,
            displayModal: true
          });
        } else {
          savePreCompLocationStaff(preCompLocationState.objData.orgUnitId, formState.personId, PRECOMP_STAFF_ROLE_GROUP_ID, createPersonOrgRole(formState.orgRolesId));
        }
      } else {
        savePreCompLocationStaff(preCompLocationState.objData.orgUnitId, formState.personId, PRECOMP_STAFF_ROLE_GROUP_ID, createPersonOrgRole(formState.orgRolesId));
      }
    }
    // add
    else {
      const localDupPerson = findLocalDupPerson(preCompLocationStaffState.arrayData);

      if (localDupPerson) {
        setDisplayLocalDupPersonModalState(true);
      } else {
        if (currentPrimaryContact !== null && formState.orgRolesId.findIndex(x => x.id === PRIMARY_CONTACT_ORG_ROLE_ID) >= 0) {
          setIsPrimaryModalState({
            ...isPrimaryModalState,
            currentPrimaryContactId: currentPrimaryContact.personId,
            currentPrimaryContact,
            isEditingCurrentPrimaryContact: false,
            isOnlyRole: currentPrimaryContact.personOrgRole.length === 1,
            displayModal: true
          });
        } else {
          getPotentialPersonDupes(formState.firstName, formState.lastName, formState.birthDate);
        }
      }
    }
  };

  function createPersonOrgRole(orgRolesId) {
    const personOrgRole = [];

    for (const role of orgRolesId) {
      if (role.id === INSTRUCTOR_ORG_ROLE_ID) {
        personOrgRole.push({
          personOrgRoleId: formState.personOrgRoleId ? formState.personOrgRoleId : undefined,
          personOrgRoleDurationId: formState.personOrgRoleDurationId > 0 ? formState.personOrgRoleDurationId : undefined,
          orgRoleId: role.id,
          orgRoleName: role.name,
          personOrgRoleFields: [
            { fieldName: "CPR/AED Certification", orgRoleFieldId: CPR_CERT_ORG_ROLE_FIELD_ID, fieldValue: formState.isCPRAEDCertified ? '1' : '0' },
            { fieldName: "First Aid Certification", orgRoleFieldId: FIRST_AID_CERT_ORG_ROLE_FIELD_ID, fieldValue: formState.isFirstAidCertified ? '1' : '0' },
            { fieldName: "Lifeguard Certification", orgRoleFieldId: LIFEGUARD_CERT_ORG_ROLE_FIELD_ID, fieldValue: formState.isLifeguardCertified ? '1' : '0' },
            { fieldName: "Pre-employment Screening", orgRoleFieldId: PRE_EMPLOYMENT_ORG_ROLE_FIELD_ID, fieldValue: formState.isPreEmploymentScreening ? '1' : '0' }
          ]
        });
      }
      else {
        personOrgRole.push({
          orgRoleId: role.id,
          orgRoleName: role.name,
          personOrgRoleFields: []
        });
      }
    }

    return personOrgRole;
  };

  function findLocalDupPerson(staffArray) {
    if (Array.isArray(staffArray) && staffArray.length > 0) {
      for (const staff of staffArray) {
        const birthDate = formatDate(staff.birthDate);
        const firstName = staff.firstName;
        const lastName = staff.lastName;

        if (formState.birthDate === birthDate && firstName === formState.firstName && lastName === formState.lastName) {
          return staff;
        }
      }
    }

    return null;
  };

  function findCurrentPrimaryContact(staffArray) {
    if (Array.isArray(staffArray) && staffArray.length > 0) {
      for (const staff of staffArray) {
        if (Array.isArray(staff.personOrgRole)) {
          const primaryContactRoleIndex = staff.personOrgRole.findIndex(x => x.orgRoleId === PRIMARY_CONTACT_ORG_ROLE_ID);

          if (primaryContactRoleIndex >= 0) {
            return staff;
          }
        }
      }
    }

    return null;
  };

  function createPutPersonGeneralObj(personGeneralObjData, formState) {
    if (personGeneralObjData.personContact) {
      const selfIndex = personGeneralObjData.personContact.findIndex(x => x.contactRelationshipTypeId === SELF_RELATIONSHIP_ID)
      if (selfIndex > -1) {
        if (personGeneralObjData.personContact[selfIndex].personContactPhone)
          personGeneralObjData.personContact[selfIndex].personContactPhone.push({
            personContactId: personGeneralObjData.personContact[selfIndex].personContactId,
            phoneTypeId: OTHER_PHONE_TYPE_ID,
            phoneNumber: formState.phoneNumber,
            isPrimary: true,
            phoneType: undefined
          });
      }
    }

    return personGeneralObjData;
  };


  function getInitialFormState() {
    return {
      personId: '',
      firstName: '',
      lastName: '',
      phoneNumber: '',
      email: '',
      birthDate: Constants.BLANK_DATE_STRING,
      orgRolesId: [],
      personOrgRoleId: Constants.DEFAULT_ID,
      personOrgRoleDurationId: Constants.DEFAULT_ID,
      isCPRAEDCertified: false,
      isFirstAidCertified: false,
      isLifeguardCertified: false,
      isPreEmploymentScreening: false
    };
  };

  function getInitialIsPrimaryModalState() {
    return {
      currentPrimaryContactId: Constants.DEFAULT_ID,
      currentPrimaryContact: {},
      isEditingCurrentPrimaryContact: false,
      isOnlyRole: false,
      displayModal: false
    };
  };

  return {
    isSaving: preCompLocationState.isSaving || preCompLocationStaffState.isSaving || personState.isSaving || personGeneralState.isSaving,
    isLoading: preCompLocationState.isObjLoading || personState.isArrayLoading || personGeneralState.isObjLoading || orgRoleStaffRoleHierarchyState.isArrayLoading,
    isEdit: formState.personId,
    formState,
    errorState,
    preCompLocationState,
    dupGridState,
    newGridState,
    isPrimaryModalState,
    displayLocalDupPersonModalState,
    handleSubmit,
    onFormValueChanged: updateFormState,
    onCancelClicked,
    onModalCancelClicked,
    onModalContinueClicked,
    onLocalDupPersonModalCancelClicked,
    onSelectGridPerson,
    onIsPrimaryModalCancelClicked,
    onIsPrimaryModalContinueClicked,
    PRECOMP_STAFF_ROLE_GROUP_ID,
    INSTRUCTOR_ORG_ROLE_ID
  };
};

export default usePreCompLocationStaffDetail;