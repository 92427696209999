import React, { Fragment } from 'react';

import useClubStaffRequirements from './UseClubStaffRequirements';

import ClubStaffRequirementsGrid from './ClubStaffRequirementsGrid';

import Headings from '../../../../common/components/headings/Headings';
import PopUpModal from '../../../../common/components/dialogs/PopUpModal';
import ActionIntraPageButton from '../../../../common/components/buttons/ActionIntraPageButton';
import ViewReport from '../../../../common/components/embeddedReports/ViewReport';

import Constants from '../../../../common/utils/Constants';

const ClubStaffRequirements = () => {
  const {
    usasClubStaffState,
    viewState,
    setViewState,
    INITIAL_VIEW_STATE,
    onRunClubStaffRequirementsReport
  } = useClubStaffRequirements();

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>Club Staff Requirements</Headings.H3>
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12 usas-extra-top-margin">
          <ActionIntraPageButton type="button" onClick={() => onRunClubStaffRequirementsReport()}>Print Report</ActionIntraPageButton>
        </div>
      </div>
      <div className='row'>
        <div className="col-xs-12 usas-extra-top-margin usas-extra-bottom-margin">
          <ClubStaffRequirementsGrid state={usasClubStaffState} />
        </div>
      </div>
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={usasClubStaffState.isArrayLoading} />
      {viewState.showReport === true &&
        <ViewReport reportInfoId={viewState.reportInfoId} reportParameters={viewState.reportParameters} onBackToGrid={() => setViewState({ INITIAL_VIEW_STATE })} />}
    </Fragment>
  );
};

export default ClubStaffRequirements;