import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { Navigate } from '../../../common/wrappers/ReactRouterDom';

import CategoryMeasureScoreContextProvider from '../../state/categoryMeasureScore/CategoryMeasureScoreContextProvider';
import CategoryMeasureDropdownContextProvider from '../../state/categoryMeasureDropdown/CategoryMeasureDropdownContextProvider';
import CategoryMeasureMultiselectContextProvider from '../../state/categoryMeasureMultiselect/CategoryMeasureMultiselectContextProvider';

import RecognitionProgramContextProvider from '../../../common/state/recognitionProgram/RecognitionProgramContextProvider';
import ProgramFocusContextProvider from '../../../common/state/recognitionProgram/ProgramFocusContextProvider';
import SelectOrgUnitContextProvider from '../../../common/state/selectOrgUnit/SelectOrgUnitContextProvider';
import PersonOrgRoleContextProvider from '../../../common/state/personOrgRole/PersonOrgRoleContextProvider';
import useNavRoutes from '../../../common/state/security/UseNavRoutes';

import SafeSportContextView from './SafeSportContextView';

import SafeSportProgramLevel from './safeSportProgramLevel/SafeSportProgramLevel';
import SafeSportProgramCategory from './safeSportProgramCategory/SafeSportProgramCategory';
import SafeSportYesNo from './safeSportCategoryMeasure/SafeSportYesNo';
import SafeSportFileUpload from './safeSportCategoryMeasure/SafeSportFileUpload';
import SafeSportTextEntry from './safeSportCategoryMeasure/SafeSportTextEntry';
import SafeSportDropdown from './safeSportCategoryMeasure/SafeSportDropdown';
import SafeSportKpi from './safeSportCategoryMeasure/SafeSportKpi';
import SafeSportMultiselect from './safeSportCategoryMeasure/SafeSportMultiselect';
import SafeSportElsewhereInSwims from './safeSportCategoryMeasure/SafeSportElsewhereInSwims';
import OrgUnitSelection from './orgUnitSelection/OrgUnitSelection';
import SafeSportSearch from './safeSportSearch/SafeSportSearch';
import SafeSportMemberId from './safeSportCategoryMeasure/SafeSportMemberId';

import LoadingModal from '../../../common/components/dialogs/LoadingModal';
import SecuredRouteElement from '../../../common/components/security/SecuredRouteElement';

const ContextRoutes = ({ navRoutes }) => {
  return (
    <SafeSportContextView>
      <Routes>
        <Route path={navRoutes['SAFE_SPORT_PROGRAM_LEVEL_ADMIN']?.path}
          element={
            <SecuredRouteElement
              navRoute={navRoutes['SAFE_SPORT_PROGRAM_LEVEL_ADMIN']}
              editElement={<SafeSportProgramLevel canRevokeSSRP={true} />} />
          } />
        <Route path={navRoutes['SAFE_SPORT_PROGRAM_LEVEL']?.path}
          element={
            <SecuredRouteElement
              navRoute={navRoutes['SAFE_SPORT_PROGRAM_LEVEL']}
              editElement={<SafeSportProgramLevel canRevokeSSRP={false} />} />
          } />
        <Route path={navRoutes['SAFE_SPORT_PROGRAM_CATEGORY']?.path}
          element={
            <SecuredRouteElement
              navRoute={navRoutes['SAFE_SPORT_PROGRAM_CATEGORY']}
              editElement={<SafeSportProgramCategory />} />
          } />
        <Route path={navRoutes['SAFE_SPORT_PROGRAM_CATEGORY_YES_NO']?.path}
          element={
            <SecuredRouteElement
              navRoute={navRoutes['SAFE_SPORT_PROGRAM_CATEGORY_YES_NO']}
              editElement={<SafeSportYesNo />} />
          } />
        <Route path={navRoutes['SAFE_SPORT_PROGRAM_CATEGORY_FILE_UPLOAD']?.path}
          element={
            <SecuredRouteElement
              navRoute={navRoutes['SAFE_SPORT_PROGRAM_CATEGORY_FILE_UPLOAD']}
              editElement={<SafeSportFileUpload />} />
          } />
        <Route path={navRoutes['SAFE_SPORT_PROGRAM_CATEGORY_TEXT_ENTRY']?.path}
          element={
            <SecuredRouteElement
              navRoute={navRoutes['SAFE_SPORT_PROGRAM_CATEGORY_TEXT_ENTRY']}
              editElement={<SafeSportTextEntry />} />
          } />
        <Route path={navRoutes['SAFE_SPORT_PROGRAM_CATEGORY_DROPDOWN']?.path}
          element={
            <SecuredRouteElement
              navRoute={navRoutes['SAFE_SPORT_PROGRAM_CATEGORY_DROPDOWN']}
              editElement={<SafeSportDropdown />} />
          } />
        <Route path={navRoutes['SAFE_SPORT_PROGRAM_CATEGORY_KPI_ADMIN']?.path}
          element={
            <SecuredRouteElement
              navRoute={navRoutes['SAFE_SPORT_PROGRAM_CATEGORY_KPI_ADMIN']}
              editElement={<SafeSportKpi canOverrideScore={true} />} />
          } />
        <Route path={navRoutes['SAFE_SPORT_PROGRAM_CATEGORY_KPI']?.path}
          element={
            <SecuredRouteElement
              navRoute={navRoutes['SAFE_SPORT_PROGRAM_CATEGORY_KPI']}
              editElement={<SafeSportKpi canOverrideScore={false} />} />
          } />
        <Route path={navRoutes['SAFE_SPORT_PROGRAM_CATEGORY_MULTISELECT']?.path}
          element={
            <SecuredRouteElement
              navRoute={navRoutes['SAFE_SPORT_PROGRAM_CATEGORY_MULTISELECT']}
              editElement={<SafeSportMultiselect />} />
          } />
        <Route path={navRoutes['SAFE_SPORT_PROGRAM_CATEGORY_MEMBER_ID']?.path}
          element={
            <SecuredRouteElement
              navRoute={navRoutes['SAFE_SPORT_PROGRAM_CATEGORY_MEMBER_ID']}
              editElement={<SafeSportMemberId />} />
          } />
        <Route path={navRoutes['SAFE_SPORT_PROGRAM_CATEGORY_ELSEWHERE']?.path}
          element={
            <SecuredRouteElement
              navRoute={navRoutes['SAFE_SPORT_PROGRAM_CATEGORY_ELSEWHERE']}
              editElement={<SafeSportElsewhereInSwims />} />
          } />
        <Route path={'/'} element={<Navigate to={navRoutes['SAFE_SPORT_PROGRAM_LEVEL']?.path} replace />} />
      </Routes>
    </SafeSportContextView>
  );
};

const SafeSportRoutes = () => {
  const { navRoutes, isReadyToRoute } = useNavRoutes();

  if (isReadyToRoute !== true) {
    return <LoadingModal />;
  }

  return (
    <Routes>
      <Route path={navRoutes['SAFE_SPORT_SELECTION']?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes['SAFE_SPORT_SELECTION']}
            editElement={<OrgUnitSelection />} />
        } />
      <Route path={navRoutes['SAFE_SPORT_SEARCH']?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes['SAFE_SPORT_SEARCH']}
            editElement={<SafeSportSearch />} />
        } />
      <Route path={'/*'} element={<ContextRoutes navRoutes={navRoutes} />} />
      <Route path={'/'} element={<Navigate to={'selection'} replace />} />
    </Routes>
  );
};

const SafeSport = () => (
  <SelectOrgUnitContextProvider>
    <PersonOrgRoleContextProvider>
      <CategoryMeasureDropdownContextProvider>
        <CategoryMeasureMultiselectContextProvider>
          <RecognitionProgramContextProvider>
            <ProgramFocusContextProvider>
              <CategoryMeasureScoreContextProvider>
                <SafeSportRoutes />
              </CategoryMeasureScoreContextProvider>
            </ProgramFocusContextProvider>
          </RecognitionProgramContextProvider>
        </CategoryMeasureMultiselectContextProvider>
      </CategoryMeasureDropdownContextProvider>
    </PersonOrgRoleContextProvider>
  </SelectOrgUnitContextProvider>
);

export default SafeSport;