import React, { Fragment } from "react";

import DeleteIcon from "../../../../common/components/icons/DeleteIcon";
import EditIcon from "../../../../common/components/icons/EditIcon";
import HideIcon from "../../../../common/components/icons/HideIcon";
import ShowIcon from "../../../../common/components/icons/ShowIcon";

import global from '../../../../common/components/GlobalStyle.module.css';

const DetailTableRow = ({ personId, role, onEdit, onDelete }) => (
  <tr>
    <td>{role.orgRoleName || ''}</td>
    <td>{role.effectiveDate || ''}</td>
    <td>{role.expirationDate || ''}</td>
    <td>
      {onEdit !== undefined && role.isCurrent === true &&
        <button
          className={global.IconButtonMargin}
          type="button"
          onClick={() => onEdit(personId, role.personOrgRoleDurationId)}>
          <EditIcon />
        </button>
      }
      {onDelete !== undefined && role.canDelete === true &&
        <button
          className={global.IconButtonMargin}
          type="button"
          onClick={() => onDelete(personId, role.personOrgRoleDurationId)}>
          <DeleteIcon />
        </button>
      }
    </td>
  </tr>
);

const DetailTable = ({ personId, roleDurations, onEdit, onDelete }) => (
  <table className={global.DetailTable}>
    <thead>
      <tr>
        <th>Role</th>
        <th>Effective Date</th>
        <th>Expiration Date</th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      {Array.isArray(roleDurations) && roleDurations.map((role, i) =>
        <DetailTableRow key={i} personId={personId} role={role} onEdit={onEdit} onDelete={onDelete} />)}
    </tbody>
  </table>
);

const GridRow = ({ staff, onEdit, onDelete, expandedId, onClick }) => {
  return (
    <Fragment>
      <tr>
        <td colSpan="2">{staff.name || ''}</td>
        <td>{staff.email || ''}</td>
        <td>
          <button
            className={global.IconButtonMargin}
            type="button"
            onClick={(e) => onClick(e, staff.personId)}>
            {expandedId === staff.personId ? <HideIcon /> : <ShowIcon />}
          </button>
        </td>
      </tr>
      {Array.isArray(staff.roleDurations) && staff.roleDurations.length > 0 && expandedId === staff.personId &&
        <tr className={global.Expanded}>
          <td colSpan="4">
            <DetailTable personId={staff.personId} roleDurations={staff.roleDurations} onEdit={onEdit} onDelete={onDelete} />
          </td>
        </tr>}
    </Fragment>
  );
};

const LSCSecurityRolesGridLarge = ({ data, isLoading, onEdit, onDelete, expandedId, onClick }) => (
  <table className={[global.UsasTable, 'visible-md visible-lg'].join(' ')}>
    <thead>
      <tr>
        <th colSpan="2">LSC Security Member Name</th>
        <th colSpan="2">Email</th>
      </tr>
    </thead>
    <tbody>
      {isLoading === true
        ? <tr><td colSpan="4">Loading...</td></tr>
        : data.length > 0
          ? data.map((staff, i) => <GridRow key={i} staff={staff} onEdit={onEdit} onDelete={onDelete}
            expandedId={expandedId} onClick={onClick} />)
          : <tr><td colSpan="4">No LSC Security Members</td></tr>
      }
    </tbody>
  </table>
);

export default LSCSecurityRolesGridLarge;