import { useState } from 'react';

import LearnToSwimLogoData from './LearnToSwimLogoData';

import { BASIC_INITIAL_STATE } from '../../../common/utils/HttpHelper';

const INITIAL_LTS_LOGO_STATE = {
  ...BASIC_INITIAL_STATE
};

const useLearnToSwimLogoData = () => {
  const [ltsLogoState, setLTSLogoState] = useState(INITIAL_LTS_LOGO_STATE);

  const postLTSLogo = (logoFile) => {
    return LearnToSwimLogoData.postLTSLogoData(logoFile, ltsLogoState, setLTSLogoState);
  };

  return {
    ltsLogoState,
    postLTSLogo
  };
};

export default useLearnToSwimLogoData;