import { useEffect, useState } from 'react';
import { useNavigate, useLocation } from '../../../../common/wrappers/ReactRouterDom';

import { FACILITIES_NAME } from '../components/leftNav/LearnToSwimRegistrationLeftNavConstants';

import NavLinks from '../utils/NavLinks';
import { createProviderObjFromFacilityContactAddForm } from '../utils/LearnToSwimRegistrationUtils';

import useLearnToSwimRegistrationData from '../../../state/learnToSwimRegistration/UseLearnToSwimRegistrationData';
import useLearnToSwimRegistrationLeftNavData from '../../../state/learnToSwimRegistrationLeftNav/UseLearnToSwimRegistrationLeftNavData';

import useFacilityData from '../../../../common/state/facility/UseFacilityData';

const INITIAL_STATE = {
  orgUnitFacilityObj: {},
  targetIndex: -1
};

const useLearnToSwimRegistrationFacilitiesDetail = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { ltsProviderRegistrationState, putLTSProviderRegistration } = useLearnToSwimRegistrationData();
  const { updateCurrentLeftNavData } = useLearnToSwimRegistrationLeftNavData();
  const { facilityState, getFacility, clearObjData } = useFacilityData();
  const [state, setState] = useState(INITIAL_STATE);

  const onCancelClicked = () => {
    clearObjData(); // facility

    navigate(NavLinks.LEARN_TO_SWIM_REGISTRATION_FACILITIES);
  };

  useEffect(() => {
    if (location.state?.facilityId > 0 && facilityState.isObjLoading !== true) {
      const targetIndex = ltsProviderRegistrationState.objData.facilities.findIndex(x => x.facilityId === location.state.facilityId);

      if (targetIndex >= 0) {
        const targetOrgUnitFacility = ltsProviderRegistrationState.objData.facilities[targetIndex];

        setState({
          ...state,
          orgUnitFacilityObj: targetOrgUnitFacility,
          targetIndex
        });
      } else if (facilityState.isObjLoaded !== true || facilityState.objData?.facilityId !== location.state.facilityId) {
        getFacility(location.state.facilityId);
      } else {
        setState({
          ...state,
          orgUnitFacilityObj: {
            facilityId: facilityState.objData?.facilityId,
            facility: facilityState.objData
          }
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [facilityState]);

  function onSubmitFormCallback(formState) {
    const providerObj = createProviderObjFromFacilityContactAddForm(formState, ltsProviderRegistrationState.objData, state.orgUnitFacilityObj, state.targetIndex);
    const putLTSProviderRegistrationPromise = putLTSProviderRegistration(providerObj);

    updateCurrentLeftNavData(FACILITIES_NAME, true);

    if (putLTSProviderRegistrationPromise !== null) {
      putLTSProviderRegistrationPromise.then((newState) => {
        if (newState !== null) {
          clearObjData(); // facility

          navigate(NavLinks.LEARN_TO_SWIM_REGISTRATION_FACILITIES);
        }
      }).catch((e) => {
        //TODO context error
        updateCurrentLeftNavData(FACILITIES_NAME, false);
      });
    }
  };

  return {
    isLoading: facilityState.isObjLoading,
    orgUnitFacilityObj: state.orgUnitFacilityObj,
    onSubmitFormCallback,
    onCancelClicked
  };
};

export default useLearnToSwimRegistrationFacilitiesDetail;