import { API_NAMES, initApi } from "../../../common/utils/HttpApiHelper";

const searchLTSProvidersData = (filterObject, state, setState) => {
  if (filterObject) {
    const api = initApi(API_NAMES.COMMON, state, setState);
    const url = `/LearnToSwimProvider/search?filters=${encodeURIComponent(filterObject)}`;

    return api?.executeArray ? api.executeArray(url, 'GET') : null;
  }
};

const getLTSProviderData = (learnToSwimProviderId, state, setState) => {
  if (learnToSwimProviderId > 0) {
    const api = initApi(API_NAMES.COMMON, state, setState);
    const learnToSwimProviderIdForUrl = encodeURIComponent(learnToSwimProviderId);
    const url = `/LearnToSwimProvider/${learnToSwimProviderIdForUrl}/ProviderInfo`;

    return api?.executeObject ? api.executeObject(url, 'GET') : null;
  }
};

const getLTSProviderOrgUnitIdData = (orgUnitId, state, setState) => {
  if (orgUnitId) {
    const api = initApi(API_NAMES.COMMON, state, setState);
    const orgUnitIdForUrl = encodeURIComponent(orgUnitId);
    const url = `/LearnToSwimProvider/orgUnit/${orgUnitIdForUrl}/ProviderInfo`;

    return api?.executeObject ? api.executeObject(url, 'GET') : null;
  }
};

const putLTSProviderData = (learnToSwimProviderId, providerObj, state, setState) => {
  if (learnToSwimProviderId > 0) {
    const newState = {
      ...state,
      isSaving: true
    };

    const api = initApi(API_NAMES.COMMON, newState, setState);
    const learnToSwimProviderIdForUrl = encodeURIComponent(learnToSwimProviderId);
    const url = `/LearnToSwimProvider/${learnToSwimProviderIdForUrl}/ProviderInfo`;

    return api?.executeObject ? api.executeObject(url, 'PUT', providerObj) : null;
  }
};

const LearnToSwimData = {
  searchLTSProvidersData,
  getLTSProviderData,
  getLTSProviderOrgUnitIdData,
  putLTSProviderData
};

export default LearnToSwimData;