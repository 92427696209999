import { OmrHttpHelper, NO_DATA_MESSAGE, BASIC_INITIAL_STATE } from '../../../common/utils/HttpHelper';

const postValidateClubCommunityImpactBulkImportIds = (orgUnitId, offeringInstanceId, offeringInstanceCouponId, memberIds, state, setState) => {
  if (state.isObjLoading !== true && orgUnitId && offeringInstanceId && Array.isArray(memberIds) && !state.message) {
    let newState = {
      ...state,
      isObjLoading: true,
      isObjLoaded: false,
      message: 'Loading...'
    };
    setState(newState);

    const orgUnitIdForUrl = orgUnitId ? encodeURIComponent(orgUnitId) : '';
    const offeringInstanceIdForUrl = offeringInstanceId ? encodeURIComponent(offeringInstanceId) : '';
    const offeringInstanceCouponIdForUrl = offeringInstanceCouponId ? encodeURIComponent(offeringInstanceCouponId) : '';
    const url = `/MemberRegistration/orgUnit/${orgUnitIdForUrl}/offeringInstance/${offeringInstanceIdForUrl}/communityImpact?offeringInstanceCouponId=${offeringInstanceCouponIdForUrl}`;
    OmrHttpHelper(url, 'POST', memberIds)
      .then((objData) => {
        if (!objData) {
          throw new Error(NO_DATA_MESSAGE);
        } else {
          newState = {
            ...newState,
            isObjLoading: false,
            isObjLoaded: true,
            objData,
            message: ''
          };
          setState(newState);
        }

      })
      .catch((e) => {
        const message = e && e.message ? e.message : NO_DATA_MESSAGE;
        setState({
          ...newState,
          isSaving: false,
          isSaved: false,
          message: message
        });
      });
  }
};

const postProcessClubCommunityImpactBulkImportIds = (orgUnitId, offeringInstanceId, offeringInstanceCouponId, memberIds, state, setState) => {
  if (state.isSaving !== true && orgUnitId && offeringInstanceId && Array.isArray(memberIds) && !state.message) {
    let newState = {
      ...state,
      isSaving: true,
      isSaved: false,
      message: 'Saving...'
    };
    setState(newState);

    const orgUnitIdForUrl = orgUnitId ? encodeURIComponent(orgUnitId) : '';
    const offeringInstanceIdForUrl = offeringInstanceId ? encodeURIComponent(offeringInstanceId) : '';
    const offeringInstanceCouponIdForUrl = offeringInstanceCouponId ? encodeURIComponent(offeringInstanceCouponId) : '';
    const url = `/MemberRegistration/orgUnit/${orgUnitIdForUrl}/offeringInstance/${offeringInstanceIdForUrl}/communityImpact/Process?offeringInstanceCouponId=${offeringInstanceCouponIdForUrl}`;
    OmrHttpHelper(url, 'POST', memberIds)
      .then((objData) => {
        if (!objData) {
          throw new Error(NO_DATA_MESSAGE);
        } else {
          newState = {
            ...newState,
            isSaving: false,
            isSaved: true,
            objData,
            message: ''
          };
          setState(newState);
        }

      })
      .catch((e) => {
        const message = e && e.message ? e.message : NO_DATA_MESSAGE;
        setState({
          ...newState,
          isSaving: false,
          isSaved: false,
          message: message
        });
      });
  }
};

const ClubCommunityImpactBulkImportUploadData = { INITIAL_STATE: BASIC_INITIAL_STATE, postValidateClubCommunityImpactBulkImportIds, postProcessClubCommunityImpactBulkImportIds };

export default ClubCommunityImpactBulkImportUploadData;

