import { BASIC_INITIAL_STATE } from "../../../common/utils/HttpHelper";

import getSwimAThonContractsData from "./GetSwimAThonContractsData";
import searchSwimAThonOrgUnitData from "./SearchSwimAThonOrgUnitData"; 

const SwimAThonData = {
  INITIAL_STATE: {
    ...BASIC_INITIAL_STATE,
    orgUnitId: '',
    orgUnitName: ''
  },
  getSwimAThonContractsData,
  searchSwimAThonOrgUnitData
};

export default SwimAThonData;