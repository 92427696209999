import React, { Fragment } from 'react';

import useLSCRoleEmails from './UseLSCRoleEmails';

import LSCRoleEmailsGrid from './LSCRoleEmailsGrid';

import ActionIntraPageButton from '../../../../common/components/buttons/ActionIntraPageButton';
import Headings from '../../../../common/components/headings/Headings';
import PopUpModal from '../../../../common/components/dialogs/PopUpModal';

import Constants from '../../../../common/utils/Constants';

const LSCRoleEmails = () => {
  const {
    isLoading,
    memberAdministrationLscRoleEmailsState,
    onAddRoleEmail,
    onEditRoleEmail
  } = useLSCRoleEmails();

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>LSC Role Emails</Headings.H3>
        </div>
      </div>
      <div className="row usas-extra-top-margin">
        <div className="col-xs-12">
          <ActionIntraPageButton type="button" onClick={onAddRoleEmail}>Add Role Email</ActionIntraPageButton>
        </div>
      </div>
      <div className="row usas-extra-top-margin usas-extra-bottom-margin">
        <div className="col-xs-12">
          <LSCRoleEmailsGrid
            data={memberAdministrationLscRoleEmailsState.arrayData}
            isLoading={memberAdministrationLscRoleEmailsState.isArrayLoading}
            onEdit={onEditRoleEmail} />
        </div>
      </div>
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={isLoading} />
    </Fragment>
  );
};

export default LSCRoleEmails;