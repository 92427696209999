import Constants from '../../../../../common/utils/Constants';

export const localValidate = async (formState) => {
  let errors = {};

  if (formState.vendorId === Constants.DEFAULT_ID) {
    errors.vendorId = "A vendor must be selected";
  }

  return errors;
};

const ClubAddVendorValidation = async (formState) => {
  let errors = await localValidate(formState);

  return errors ? errors : {};
};

export default ClubAddVendorValidation;