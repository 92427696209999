import { isValidDate } from '../../../../common/utils/validation';

export const localValidate = (formState) => {
  let errors = {};

  //Signature
  if (formState.registrationSignature.trim() === '') {
    errors.registrationSignature = 'Signature is required'
  }

  //Date
  if (!isValidDate(formState.registrationDate)) {
    errors.registrationDate = 'Signature Date is required'
  }

  return errors;
};

const ClubRegistrationSubmitValidation = async (formState) => {
  let errors = localValidate(formState);

  return errors ? errors : {};
}

export default ClubRegistrationSubmitValidation;