import { BASIC_INITIAL_STATE } from '../../../common/utils/HttpHelper';

import getClubManageOfferingsData from './GetClubManageOfferingsData';
import postOfferingInstanceExclusionData from './PostOfferingInstanceExclusionData';
import deleteOfferingInstanceExclusionData from './DeleteOfferingInstanceExclusionData';

const INITIAL_STATE = {
  ...BASIC_INITIAL_STATE
};

const ClubManageOfferingsData = {
  INITIAL_STATE,
  getClubManageOfferingsData,
  postOfferingInstanceExclusionData,
  deleteOfferingInstanceExclusionData
};

export default ClubManageOfferingsData;