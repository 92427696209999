import usePreCompCurriculumData from '../../../../state/preCompCurriculum/UsePreCompCurriculumData';

const useCurriculumDropdown = () => {
  const { preCompCurriculumState } = usePreCompCurriculumData();

  return {
    preCompCurriculumState
  };
};

export default useCurriculumDropdown;