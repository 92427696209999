import { useEffect, useState } from 'react';
import { useNavigate } from '../../../../common/wrappers/ReactRouterDom';

import NavLinks from '../NavLinks';

import PreCompRegistrationConstants from '../utils/PreCompRegistrationConstants';

import validate from '../../preComp/components/forms/PreCompLocationInfoFormValidation';

import usePreCompLocationRegistrationData from '../../../state/preCompLocationRegistration/UsePreCompLocationRegistrationData';
import usePreCompCurriculumData from '../../../state/preCompCurriculum/UsePreCompCurriculumData';
import usePreCompInstructionHoursData from '../../../state/preCompInstructionHours/UsePreCompInstructionHoursData';
import usePreCompInstructorStudentRatioData from '../../../state/preCompInstructorStudentRatio/UsePreCompInstructorStudentRatioData';
import usePreCompLocationRegistrationNavData from '../../../state/preCompLocationRegistration/preCompLocationRegistrationLeftNavBar/UsePreCompLocationRegistrationNavData';
import usePreCompOrganizationRegistrationData from '../../../state/preCompOrganizationRegistration/UsePreCompOrganizationRegistrationData';
import { createLocObjFromLocInfoForm } from '../../preComp/utils/PreCompUtils';

import useForm from '../../../../common/utils/UseForm';
import Constants from '../../../../common/utils/Constants';

const usePreCompRegistrationLocationInfo = () => {
  const navigate = useNavigate();
  const { preCompCurriculumState } = usePreCompCurriculumData();
  const { preCompInstructionHoursState } = usePreCompInstructionHoursData();
  const { preCompInstructorStudentRatioState } = usePreCompInstructorStudentRatioData();
  const { preCompOrganizationRegistrationState } = usePreCompOrganizationRegistrationData();
  const { preCompLocationRegistrationState, confirmSave, saveObjData } = usePreCompLocationRegistrationData();
  const { locationRegistrationNavState, updateCurrentNavState } = usePreCompLocationRegistrationNavData();
  const { formState, errorState, isSubmitting, handleSubmit, updateFormState, onValueTextPairChanged, setFormData, setIsDirty
  } = useForm(getInitialFormState, submitFormCallback, validate);
  const [displayExitModalState, setDisplayExitModalState] = useState(false);
  const [continueState, setContinueState] = useState(getInitialContinueState);

  const onModalExitClicked = () => {
    navigate(NavLinks.PRE_COMP_REGISTRATION_ENTRY);
  };

  const onModalCancelClicked = () => {
    setDisplayExitModalState(false);
  };

  const onContinueClicked = () => {
    setContinueState({
      ...continueState,
      destination: NavLinks.PRE_COMP_REGISTRATION_LOCATION_PROGRAMS
    });
    handleSubmit();
  };

  const onBackClicked = () => {
    if (preCompOrganizationRegistrationState.objData?.preCompetitiveOrganizationId > 0) {
      setDisplayExitModalState(true);
    } else {
      setContinueState({
        ...continueState,
        destination: NavLinks.PRE_COMP_REGISTRATION_ORGANIZATION_INSURANCE
      });
      handleSubmit();
    }
  };

  useEffect(() => {
    updateCurrentNavState(PreCompRegistrationConstants.LOC_INFO);
    setIsDirty(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (preCompLocationRegistrationState.isSaved === true && isSubmitting === false) {
      confirmSave();
      updateCurrentNavState(PreCompRegistrationConstants.LOC_INFO, true);

      setContinueState({
        ...continueState,
        tryRedirect: true
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [preCompLocationRegistrationState.isSaved, isSubmitting]);

  useEffect(() => {
    if (preCompLocationRegistrationState.isObjLoaded === true && preCompCurriculumState.isArrayLoaded === true
      && preCompInstructionHoursState.isArrayLoaded === true && preCompInstructorStudentRatioState.isArrayLoaded === true) {

      setFormData({
        ...formState,
        locationName: preCompLocationRegistrationState.objData.locationName || '',
        averageLessonCost: preCompLocationRegistrationState.objData.averageLessonCost !== null ?
          preCompLocationRegistrationState.objData.averageLessonCost : '',
        averageSessionCost: preCompLocationRegistrationState.objData.averageSessionCost !== null ?
          preCompLocationRegistrationState.objData.averageSessionCost : '',
        financialAidOffered: preCompLocationRegistrationState.objData.financialAidOffered ?? '',
        instructorStudentRatioId: preCompLocationRegistrationState.objData.instructorStudentRatioId || Constants.DEFAULT_ID,
        instructorStudentRatioName: preCompLocationRegistrationState.objData.instructorStudentRatio?.ratioDescription || '',
        preCompCurriculumId: preCompLocationRegistrationState.objData.preCompCurriculumId || Constants.DEFAULT_ID,
        preCompCurriculumName: preCompLocationRegistrationState.objData.preCompCurriculum?.curriculumName || '',
        instructionHoursPerSessionId: preCompLocationRegistrationState.objData.instructionHoursPerSessionId || Constants.DEFAULT_ID,
        instructionHoursPerSessionName: preCompLocationRegistrationState.objData.instructionHoursPerSession?.instructionHoursDescription || '',
        website: preCompLocationRegistrationState.objData.website || ''
      });
      setIsDirty(true);
    } else if (preCompLocationRegistrationState.isObjLoaded === false) {
      navigate(NavLinks.PRE_COMP_REGISTRATION_ENTRY);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [preCompLocationRegistrationState.isObjLoaded, preCompCurriculumState.isArrayLoaded, preCompInstructorStudentRatioState.isArrayLoaded, preCompInstructionHoursState.isArrayLoaded]);

  useEffect(() => {
    if (continueState.tryRedirect === true) {
      if (continueState.destination !== '') {
        navigate(continueState.destination);
      } else {
        setContinueState(getInitialContinueState);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [continueState.tryRedirect]);

  function submitFormCallback(formState) {
    saveObjData(createLocObjFromLocInfoForm(preCompLocationRegistrationState.objData, formState));
  };

  function getInitialFormState() {
    return {
      locationName: '',
      averageLessonCost: '',
      averageSessionCost: '',
      financialAidOffered: '',
      instructionHoursPerSessionId: Constants.DEFAULT_ID,
      instructionHoursPerSessionName: '',
      instructorStudentRatioId: Constants.DEFAULT_ID,
      instructorStudentRatioName: '',
      preCompCurriculumId: Constants.DEFAULT_ID,
      preCompCurriculumName: '',
      website: ''
    };
  };

  function getInitialContinueState() {
    return {
      tryRedirect: false,
      destination: ''
    };
  };

  return {
    isLoading: preCompCurriculumState.isArrayLoading || preCompInstructionHoursState.isArrayLoading || preCompInstructorStudentRatioState.isArrayLoading,
    formState,
    errorState,
    locationRegistrationNavState,
    displayExitModalState,
    onFormValueChanged: updateFormState,
    onValueTextPairChanged,
    onContinueClicked,
    onBackClicked,
    onModalExitClicked,
    onModalCancelClicked
  };
};

export default usePreCompRegistrationLocationInfo;