import { Fragment } from "react";

import { SisenseContextProvider } from "@sisense/sdk-ui";

import useClubPerformanceAthleteTimesProgression from "./UseClubPerformanceAthleteTimesProgression";

import IndividualTimesSearchProgressionDashboard from "./IndividualTimesSearchProgressionDashboard";

import * as DM from '../../../../../../season-time-progression-data';

import Headings from "../../../../../../common/components/headings/Headings";

const ClubPerformanceAthleteTimesProgression = () => {
  const { environmentVariableState, INDIVIDUAL_TIMES_SEARCH_PROGRESSION_OID } = useClubPerformanceAthleteTimesProgression();

  return (
    <Fragment>
      {environmentVariableState.isLoaded === true &&
        <SisenseContextProvider
          defaultDataSource={DM.DataSource}
          url={import.meta.env.VITE_SISENSE_URL}
          token={import.meta.env.VITE_SISENSE_API_TOKEN}>
          <div className="row usas-extra-bottom-margin">
            <div className="col-xs-12">
              <Headings.H3>Athlete Times Detail - Season Progression</Headings.H3>
              <IndividualTimesSearchProgressionDashboard dashboardOid={INDIVIDUAL_TIMES_SEARCH_PROGRESSION_OID} />
            </div>
          </div>
        </SisenseContextProvider>}
    </Fragment>
  );
}

export default ClubPerformanceAthleteTimesProgression;