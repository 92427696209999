import { useEffect, useState } from "react";

import validate from './ClubPoliciesValidation';


import useForm from "../../../../common/utils/UseForm";
import Constants from "../../../../common/utils/Constants";
import useBaseUsasClubData from "../../../../common/state/clubBase/UseBaseUsasClubData";
import { formatDate } from "../../../../common/utils/DateFunctions";

const useClubPolicies = () => {
  const { baseUsasClubState, putBaseUsasClub } = useBaseUsasClubData();
  const [state, setState] = useState(getInitialReadOnlyState);

  const {
    handleSubmit,
    updateFormState,
    formState,
    setFormState,
    setFormData,
    errorState,
    onValueTextPairChanged,
    resetForm
  } = useForm(getFormInitialState(), submitFormCallback, validate);

  function submitFormCallback() {
    const putObj = updateClubStateFromFormState();
    putBaseUsasClub(putObj.usasClubId, putObj);
  };

  const onCancelClicked = () => {
    resetForm();
  };

  const updateClubStateFromFormState = () => {
    //format club object to send to the putClub API call
    let clubToPut = JSON.parse(JSON.stringify(baseUsasClubState.objData));


    clubToPut.includeOnFindAteam = formState.includeInFindAclubSearch;

    clubToPut.clbmS101 = formState.clbmS101;
    clubToPut.clbmS101CompletionDate = formState?.clbmS101 === true ? formState.clbmS101CompletionDate : null;
    clubToPut.clbmS101signature = formState.clbmS101signature;
    clubToPut.clbmS101signatureDate = formState.clbmS101signatureDate;

    clubToPut.clbmS201 = formState.clbmS201;
    clubToPut.clbmS201CompletionDate = formState?.clbmS201 === true ? formState.clbmS201CompletionDate : null;
    clubToPut.clbmS201signature = formState.clbmS201signature;
    clubToPut.clbmS201signatureDate = formState.clbmS201signatureDate;

    if (clubToPut.usasClubAcknowledgement.length === 0) {
      clubToPut.usasClubAcknowledgement = [{
        preEmploymentScreeningRequired: formState.preEmploymentScreeningRequired,
        racingStartCertified: formState.racingStartCertified,
        stateConcussionsLaws: formState.stateConcussionsLaws,
        minorAthleteAbusePreventionPolicy: formState.minorAthleteAbusePreventionPolicy,
        preEmploymentScreeningRequiredSignature: formState.preEmploymentScreeningRequired
      }];
    } else if (clubToPut.usasClubAcknowledgement.length > 0) {
      clubToPut.usasClubAcknowledgement[clubToPut.usasClubAcknowledgement.length - 1] = {
        ...clubToPut.usasClubAcknowledgement[clubToPut.usasClubAcknowledgement.length - 1],
        preEmploymentScreeningRequired: formState.preEmploymentScreeningRequired,
        racingStartCertified: formState.racingStartCertified,
        stateConcussionsLaws: formState.stateConcussionsLaws,
        minorAthleteAbusePreventionPolicy: formState.minorAthleteAbusePreventionPolicy,
        preEmploymentScreeningRequiredSignature: formState.preEmploymentScreeningRequired
      };
    }

    return clubToPut;
  };


  useEffect(() => {
    if (formState?.clbmS101 === false && formState?.clbmS101CompletionDate !== Constants?.BLANK_DATE_STRING) {

      setFormState({ ...formState, clbmS101CompletionDate: Constants?.BLANK_DATE_STRING });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formState?.clbmS101]);

  useEffect(() => {
    if (formState?.clbmS201 === false && formState?.clbmS201CompletionDate !== Constants?.BLANK_DATE_STRING) {

      setFormState({ ...formState, clbmS201CompletionDate: Constants?.BLANK_DATE_STRING });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formState?.clbmS201]);


  useEffect(() => {
    if (Object.keys(baseUsasClubState.objData).length > 0 && baseUsasClubState.isObjLoaded === true) {

      setFormData({
        ...formState,
        orgUnitId: baseUsasClubState.objData.orgUnit.orgUnitId || '',
        orgUnitName: baseUsasClubState.objData.orgUnit.orgUnitName || '',
        orgUnitCode: baseUsasClubState.objData.orgUnit.orgUnitCode || '',
        includeInFindAclubSearch: baseUsasClubState.objData.includeOnFindAteam ? baseUsasClubState.objData.includeOnFindAteam : false,
        clbmS101: baseUsasClubState.objData.clbms101 ? baseUsasClubState.objData.clbms101 : false,
        clbmS101CompletionDate: baseUsasClubState.objData.clbms101completionDate ? formatDate(baseUsasClubState.objData.clbms101completionDate) : Constants.BLANK_DATE_STRING,
        clbmS201: baseUsasClubState.objData.clbms201 ? baseUsasClubState.objData.clbms201 : false,
        clbmS201CompletionDate: baseUsasClubState.objData.clbms201completionDate ? formatDate(baseUsasClubState.objData.clbms201completionDate) : Constants.BLANK_DATE_STRING,
        preEmploymentScreeningRequired: baseUsasClubState.objData.usasClubAcknowledgement[0].preEmploymentScreeningRequired || false,
        racingStartCertified: baseUsasClubState.objData.usasClubAcknowledgement[0].racingStartCertified || false,
        stateConcussionsLaws: baseUsasClubState.objData.usasClubAcknowledgement[0].stateConcussionsLaws || false,
        minorAthleteAbusePreventionPolicy: baseUsasClubState.objData.usasClubAcknowledgement[0].minorAthleteAbusePreventionPolicy || false
      });
      setState({
        ...state,
        preEmploymentScreeningRequired: baseUsasClubState.objData.usasClubAcknowledgement[0].preEmploymentScreeningRequired || false,
        racingStartCertified: baseUsasClubState.objData.usasClubAcknowledgement[0].racingStartCertified || false,
        stateConcussionsLaws: baseUsasClubState.objData.usasClubAcknowledgement[0].stateConcussionsLaws || false,
        minorAthleteAbusePreventionPolicy: baseUsasClubState.objData.usasClubAcknowledgement[0].minorAthleteAbusePreventionPolicy || false,
        clbmS101: baseUsasClubState.objData.clbms101 ? baseUsasClubState.objData.clbms101 : false,
        clbmS101CompletionDate: baseUsasClubState.objData.clbms101completionDate ? formatDate(baseUsasClubState.objData.clbms101completionDate) : Constants.BLANK_DATE_STRING,
        clbmS201: baseUsasClubState.objData.clbms201 ? baseUsasClubState.objData.clbms201 : false,
        clbmS201CompletionDate: baseUsasClubState.objData.clbms201completionDate ? formatDate(baseUsasClubState.objData.clbms201completionDate) : Constants.BLANK_DATE_STRING,
        includeInFindAclubSearch: baseUsasClubState.objData.includeOnFindAteam ? baseUsasClubState.objData.includeOnFindAteam : false
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [baseUsasClubState]);

  return {
    baseUsasClubState,
    state,
    formState,
    errorState,
    onFormValueChanged: updateFormState,
    handleSubmit,
    onCancelClicked,
    onValueTextPairChanged
  };
};

export default useClubPolicies;

function getFormInitialState() {
  return {
    preEmploymentScreeningRequired: false,
    includeInFindAclubSearch: false,
    racingStartCertified: false,
    stateConcussionsLaws: false,
    minorAthleteAbusePreventionPolicy: false,
    clbmS101: false,
    clbmS101CompletionDate: Constants.BLANK_DATE_STRING,
    clbmS201: false,
    clbmS201CompletionDate: Constants.BLANK_DATE_STRING
  };
};

function getInitialReadOnlyState() {
  return {
    preEmploymentScreeningRequired: ' ',
    includeInFindAclubSearch: ' ',
    racingStartCertified: ' ',
    stateConcussionsLaws: ' ',
    minorAthleteAbusePreventionPolicy: ' ',
    clbmS101: ' ',
    clbmS101CompletionDate: Constants.BLANK_DATE_STRING,
    clbmS201: ' ',
    clbmS201CompletionDate: Constants.BLANK_DATE_STRING
  };
};