import {ClubHttpHelper, NO_DATA_MESSAGE} from '../../../common/utils/HttpHelper';

const DeleteUsasClubPreCompLinksData = (usasClubId, preCompLocationId, state, setState) => {
  if (state.isSaving !== true && !state.message) {
    const newState = {
      ...state,
      isSaving: true,
      isSaved: false,
      message: 'Deleting...'
    };
    setState(newState);

    const usasClubIdForUrl = usasClubId ? encodeURIComponent(usasClubId) : 'NaN';
    const preCompLocationIdForUrl = preCompLocationId ? encodeURIComponent(preCompLocationId) : 'NaN';
    const url = `/UsasClub/${usasClubIdForUrl}/preCompLinks/${preCompLocationIdForUrl}`;
    ClubHttpHelper(url, 'DELETE')
      .then((arrayData) => {
        if (!arrayData) {
          throw new Error(NO_DATA_MESSAGE);
        } else {
          setState({
            ...newState,
            isSaving: false,
            isSaved: true,
            isArrayLoaded: true,
            arrayData,
            message: ''
          });
        }
      })
      .catch((e) => {
        const message = e && e.message ? e.message : NO_DATA_MESSAGE;
        setState({
          ...newState,
          isSaving: false,
          isSaved: false,
          isArrayLoaded: false,
          message: message
        });
      });
  }
};

export default DeleteUsasClubPreCompLinksData;