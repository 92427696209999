import useEnvironmentVariableData from "../../../../../common/state/environmentVariable/UseEnvironmentVariableData";

const useClubPerformanceAthleteTimesDetail = () => {
  const { environmentVariableState, INDIVIDUAL_TIMES_SEARCH_OID } = useEnvironmentVariableData();

  return {
    environmentVariableState,
    INDIVIDUAL_TIMES_SEARCH_OID
  };
};

export default useClubPerformanceAthleteTimesDetail;