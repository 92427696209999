import React, { Fragment } from 'react';

import PreCompInstructorTrainingAgencyDropdown from '../instructorTrainingAgencyDropdown/PreCompInstructorTrainingAgencyDropdown';
import PreCompInstructionHoursDropdown from '../instructionHoursDropdown/PreCompInstructionHoursDropdown';

import ReadOnly from '../../../../../common/components/readOnly/ReadOnly';
import YesNoSwitch from '../../../../../common/components/yesNoSwitch/YesNoSwitch';

const PreCompLocationInstructorTrainingForm = ({ formState, errorState, onFormValueChanged, onValueTextPairChanged }) => (
  <Fragment>
    <div className="row">
      <div className="col-xs-12 col-sm-6 col-md-4">
        {onValueTextPairChanged === undefined
          ? <ReadOnly
            label="Instructor Training Agency"
            name="instructorTrainingAgencyId"
            value={formState.instructorTrainingAgencyName} />
          : (
            <PreCompInstructorTrainingAgencyDropdown
              label="Instructor Training Agency*"
              name="instructorTrainingAgencyId"
              value={formState.instructorTrainingAgencyId}
              error={errorState.instructorTrainingAgencyId}
              message={errorState.instructorTrainingAgencyId}
              onChange={(newValue, newValueLabel, e) => {
                e && e.target && e.target.value &&
                  onValueTextPairChanged(newValue, "instructorTrainingAgencyId", newValueLabel, "instructorTrainingAgencyName");
              }} />
          )
        }
      </div>
      <div className="col-xs-12 col-sm-6 col-md-4">
        {onValueTextPairChanged === undefined
          ? <ReadOnly
            label="In-Water Training Requirement"
            name="inWaterInstructionHoursId"
            value={formState.inWaterInstructionHoursName} />
          : (
            <PreCompInstructionHoursDropdown
              label="In-Water Training Requirement*"
              name="inWaterInstructionHoursId"
              value={formState.inWaterInstructionHoursId}
              error={errorState.inWaterInstructionHoursId}
              message={errorState.inWaterInstructionHoursId}
              onChange={(newValue, newValueLabel, e) => {
                e && e.target && e.target.value &&
                  onValueTextPairChanged(newValue, "inWaterInstructionHoursId", newValueLabel, "inWaterInstructionHoursName");
              }} />
          )
        }
      </div>
      <div className="col-xs-12 col-sm-6 col-md-4">
        {onValueTextPairChanged === undefined
          ? <ReadOnly
            label="Instructor Shadowing Requirement"
            name="inShadowInstructionHoursId"
            value={formState.inShadowInstructionHoursName} />
          : (
            <PreCompInstructionHoursDropdown
              label="Instructor Shadowing Requirement*"
              name="inShadowInstructionHoursId"
              value={formState.inShadowInstructionHoursId}
              error={errorState.inShadowInstructionHoursId}
              message={errorState.inShadowInstructionHoursId}
              onChange={(newValue, newValueLabel, e) => {
                e && e.target && e.target.value &&
                  onValueTextPairChanged(newValue, "inShadowInstructionHoursId", newValueLabel, "inShadowInstructionHoursName");
              }} />
          )
        }
      </div>
      <div className="col-xs-12 col-sm-6 col-md-4">
        {onValueTextPairChanged === undefined
          ? <ReadOnly
            label="Practice Teaching Requirement"
            name="inPracticeInstructionHoursId"
            value={formState.inPracticeInstructionHoursName} />
          : (
            <PreCompInstructionHoursDropdown
              label="Practice Teaching Requirement*"
              name="inPracticeInstructionHoursId"
              value={formState.inPracticeInstructionHoursId}
              error={errorState.inPracticeInstructionHoursId}
              message={errorState.inPracticeInstructionHoursId}
              onChange={(newValue, newValueLabel, e) => {
                e && e.target && e.target.value &&
                  onValueTextPairChanged(newValue, "inPracticeInstructionHoursId", newValueLabel, "inPracticeInstructionHoursName");
              }} />
          )
        }
      </div>
      <div className="col-xs-12 col-sm-6 col-md-4">
        {onValueTextPairChanged === undefined
          ? <ReadOnly
            label="Classroom Based Training Requirement"
            name="inClassroomInstructionHoursId"
            value={formState.inClassroomInstructionHoursName} />
          : (
            <PreCompInstructionHoursDropdown
              label="Classroom Based Training Requirement*"
              name="inClassroomInstructionHoursId"
              value={formState.inClassroomInstructionHoursId}
              error={errorState.inClassroomInstructionHoursId}
              message={errorState.inClassroomInstructionHoursId}
              onChange={(newValue, newValueLabel, e) => {
                e && e.target && e.target.value &&
                  onValueTextPairChanged(newValue, "inClassroomInstructionHoursId", newValueLabel, "inClassroomInstructionHoursName");
              }} />
          )
        }
      </div>
      <div className="col-xs-12 col-sm-6 col-md-4">
        {onValueTextPairChanged === undefined
          ? <ReadOnly
            label="Monthly In-Service Training Requirement"
            name="inServiceInstructionHoursId"
            value={formState.inServiceInstructionHoursName} />
          : (
            <PreCompInstructionHoursDropdown
              label="Monthly In-Service Training Requirement*"
              name="inServiceInstructionHoursId"
              value={formState.inServiceInstructionHoursId}
              error={errorState.inServiceInstructionHoursId}
              message={errorState.inServiceInstructionHoursId}
              onChange={(newValue, newValueLabel, e) => {
                e && e.target && e.target.value &&
                  onValueTextPairChanged(newValue, "inServiceInstructionHoursId", newValueLabel, "inServiceInstructionHoursName");
              }} />
          )
        }
      </div>
      <div className="col-xs-12 col-sm-6 col-md-4">
        {onFormValueChanged === undefined
          ? <ReadOnly
            label="Pre-employment screenings and reference checks conducted on all employees?"
            name="preEmploymentScreening"
            value={formState.preEmploymentScreening} />
          : (
            <YesNoSwitch
              label="Pre-employment screenings and reference checks conducted on all employees?*"
              name="preEmploymentScreening"
              checked={formState.preEmploymentScreening}
              error={errorState.preEmploymentScreening}
              message={errorState.preEmploymentScreening}
              onChange={(value) => { onFormValueChanged("preEmploymentScreening", value); }} />
          )
        }
      </div>
      <div className="col-xs-12 col-sm-6 col-md-4">
        {onFormValueChanged === undefined
          ? <ReadOnly
            label="Background checks required for all staff ages 18 years and older?"
            name="backgroundCheckRequired"
            value={formState.backgroundCheckRequired} />
          : (
            <YesNoSwitch
              label="Background checks required for all staff ages 18 years and older?*"
              name="backgroundCheckRequired"
              checked={formState.backgroundCheckRequired}
              error={errorState.backgroundCheckRequired}
              message={errorState.backgroundCheckRequired}
              onChange={(value) => { onFormValueChanged("backgroundCheckRequired", value); }} />
          )
        }
      </div>
      <div className="col-xs-12 col-sm-6 col-md-4">
        {onFormValueChanged === undefined
          ? <ReadOnly
            label="Child Safety and Protection training required of all staff?"
            name="protectionPolicy"
            value={formState.protectionPolicy} />
          : (
            <YesNoSwitch
              label="Child Safety and Protection training required of all staff?*"
              name="protectionPolicy"
              checked={formState.protectionPolicy}
              error={errorState.protectionPolicy}
              message={errorState.protectionPolicy}
              onChange={(value) => { onFormValueChanged("protectionPolicy", value); }} />
          )
        }
      </div>
    </div>
  </Fragment>
);

export default PreCompLocationInstructorTrainingForm;