//TODO need to replace with nav routes
const NavLinks = {
  CLUB_ROOT: '/club',
  CLUB_BY_ID: '/club/byId',
  CLUB_MY_CLUB: '/club/myclub/selection',
  CLUB_SEARCH: '/club/search',
  CLUB_INFO: '/club/info',
  CLUB_STAFF: '/club/staff',
  CLUB_STAFF_DETAIL: '/club/staff/detail',
  CLUB_STAFF_CERTIFICATIONS: '/club/staffcertifications',
  CLUB_AFFILIATION: '/club/affiliation',
  CLUB_AFFILIATION_DETAIL: '/club/affiliation/detail',
  CLUB_POLICIES: '/club/policies',
  CLUB_PRECOMPETITIVE: '/club/precompetitiveprograms',
  CLUB_PRECOMPETITIVE_SEARCH: '/club/precompetitiveprograms/search',
  CLUB_GROUPS: '/club/groups',
  CLUB_GROUPS_GROUP_TYPE: '/club/groups/grouptype',
  CLUB_GROUPS_LIST_ADD: '/club/groups/add/list',
  CLUB_GROUPS_LIST_EDIT: '/club/groups/edit/list',
  CLUB_GROUPS_PRACTICE_ADD: '/club/groups/add/practice',
  CLUB_GROUPS_PRACTICE_EDIT: '/club/groups/edit/practice',
  CLUB_GROUPS_TRAVEL_ADD: '/club/groups/add/travel',
  CLUB_GROUPS_TRAVEL_EDIT: '/club/groups/edit/travel',
  CLUB_GROUPS_CLUB_SITE_ADD: '/club/groups/add/clubsite',
  CLUB_GROUPS_CLUB_SITE_EDIT: '/club/groups/edit/clubsite',
  CLUB_GROUPS_MEMBER_DETAIL: '/club/groups/member/detail',
  CLUB_HISTORY: '/club/history',
  CLUB_FACILITIES: '/club/facilities',
  CLUB_FACILITIES_SEARCH: '/club/facilities/search',
  CLUB_FACILITIES_ADD: '/club/facilities/add',
  CLUB_FACILITIES_POOLS_DETAIL: '/club/facilities/pools/detail',
  CLUB_FACILITIES_DETAILS: '/club/facilities/details',
  CLUB_LEGAL_ENTITIES: '/club/legalentities',
  CLUB_LEGAL_ENTITIES_DETAILS: '/club/legalentities/details',
  CLUB_MEETS: '/club/meets',
  CLUB_BLOCKPARTY: '/club/blockparty',
  CLUB_BLOCKPARTY_ADD: '/club/blockparty/add',
  CLUB_BLOCKPARTY_ADD_ROLE: '/club/blockparty/addrole',
  CLUB_MEETS_SANCTION: '/club/meets/sanctionrequest',
  CLUB_MEETS_UPLOAD: '/club/meets/upload',
  CLUB_MEETS_RECON: '/club/meets/recon',
  CLUB_GENERATE_REGISTRATION_LINK: '/club/generateregistrationlink',
  CLUB_ACTIVATE_VENDOR: '/club/activatevendor',
  CLUB_ACTIVATE_VENDOR_ADD_VENDOR: '/club/activatevendor/addvendor',
  CLUB_ATHLETE_ROSTER: '/club/athleteroster',
  CLUB_MANAGE_OFFERINGS: '/club/manageofferings',
  CLUB_TOUCHPOINTS: '/club/touchpoints',
  CLUB_TOUCHPOINTS_DETAILS: '/club/touchpoints/details',
  CLUB_REPORTS: '/club/reporting',
  CLUB_NATIONAL_PRACTICE_DATA: '/club/nationalpracticedata'
};

export default NavLinks;