import { useEffect } from 'react';
import { useLocation, useNavigate } from '../../../../../common/wrappers/ReactRouterDom';

import validate from './ClubGroupTypeTravelValidation';

import { addClubGroupCoaches, createGroupObjFromGroupInfoForm, createOrgGroupFieldValues, getBaseGroupData } from '../utils/ClubGroupsUtils';
import ClubConstants from '../../utils/ClubConstants';

import NavLinks from '../../NavLinks';

import useClubGroupsData from '../../../../state/clubGroups/UseClubGroupsData';

import useOrgGroupPropertiesData from '../../../../../common/state/orgGroupProperties/UseOrgGroupPropertiesData';
import useBaseUsasClubData from '../../../../../common/state/clubBase/UseBaseUsasClubData';
import useGroupCategoryData from '../../../../../common/state/groupCategory/UseGroupCategoryData';
import useGroupFieldNamesData from '../../../../../common/state/groupFieldNames/UseGroupFieldNamesData';

import useForm from '../../../../../common/utils/UseForm';
import Constants from '../../../../../common/utils/Constants';

const useClubGroupTypeTravelAdd = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { GROUP_CONSTANTS } = useGroupFieldNamesData();
  const { baseUsasClubState } = useBaseUsasClubData();
  const { clubGroupState, postClubGroup } = useClubGroupsData();
  const { groupCategoryState } = useGroupCategoryData();
  const { orgGroupPropertiesState, getOrgGroupProperties } = useOrgGroupPropertiesData();
  const { formState, errorState, handleSubmit, onFormValueChanged, setFormData, onValueTextPairChanged
  } = useForm(getInitialFormState, submitFormCallback, validate);

  const onBackClicked = () => {
    navigate(NavLinks.CLUB_GROUPS_GROUP_TYPE);
  };

  useEffect(() => {
    if (groupCategoryState.isArrayLoaded === true && baseUsasClubState.isObjLoaded === true
      && orgGroupPropertiesState.isArrayLoaded === true && orgGroupPropertiesState.groupTypeId === location.state?.groupTypeId) {
      if (location.state?.groupTypeId && location.state?.groupTypeName && baseUsasClubState.objData.orgUnitId) {
        setFormData({
          ...formState,
          groupTypeId: location.state.groupTypeId,
          groupTypeName: location.state.groupTypeName,
          orgUnitId: baseUsasClubState.objData.orgUnitId
        });
      } else {
        navigate(NavLinks.CLUB_GROUPS_GROUP_TYPE);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [baseUsasClubState, groupCategoryState, orgGroupPropertiesState]);

  useEffect(() => {
    if (clubGroupState.isSaved === true) {
      navigate(NavLinks.CLUB_GROUPS);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clubGroupState]);

  useEffect(() => {
    if (location.state?.groupTypeId !== orgGroupPropertiesState.groupTypeId) {
      getOrgGroupProperties(location.state.groupTypeId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function submitFormCallback(formState) {
    const newOrgGroupObj = createGroupObjFromGroupInfoForm(getBaseGroupData, formState);

    const groupTypeFieldId = orgGroupPropertiesState.arrayData.find(x => x.fieldName === GROUP_CONSTANTS.PERSON_GROUP_TYPE_FIELD_NAME_ATTENDEE_TYPE)?.groupTypeFieldId;

    newOrgGroupObj.personOrgGroup = addClubGroupCoaches(formState.groupCoaches, groupTypeFieldId, ClubConstants.COACH_ROLE_NAME);

    newOrgGroupObj.orgGroupFieldValue = createOrgGroupFieldValues([
      { fieldName: GROUP_CONSTANTS.ORG_GROUP_TYPE_FIELD_NAME_START_DATE, fieldValue: formState.startDate },
      { fieldName: GROUP_CONSTANTS.ORG_GROUP_TYPE_FIELD_NAME_END_DATE, fieldValue: formState.endDate },
      { fieldName: GROUP_CONSTANTS.ORG_GROUP_TYPE_FIELD_NAME_CITY, fieldValue: formState.city },
      { fieldName: GROUP_CONSTANTS.ORG_GROUP_TYPE_FIELD_NAME_STATE, fieldValue: formState.stateCode },
      { fieldName: GROUP_CONSTANTS.ORG_GROUP_TYPE_FIELD_NAME_ZIP, fieldValue: formState.zip },
      { fieldName: GROUP_CONSTANTS.ORG_GROUP_TYPE_FIELD_NAME_TRIP_PURPOSE, fieldValue: formState.tripPurpose }
    ], orgGroupPropertiesState.arrayData);

    if (newOrgGroupObj.orgGroupFieldValue.length > 0) {
      for (const orgGroupField of newOrgGroupObj.orgGroupFieldValue) {
        orgGroupField.groupTypeField = undefined;
      }
    }

    postClubGroup(newOrgGroupObj);
  };

  function getInitialFormState() {
    return {
      orgUnitId: '',
      groupTypeId: Constants.DEFAULT_ID,
      groupTypeName: '',
      groupCategories: [],
      groupName: '',
      groupCode: '',
      groupDescription: '',
      groupCoaches: [],
      startDate: Constants.BLANK_DATE_STRING,
      endDate: Constants.BLANK_DATE_STRING,
      city: '',
      stateCode: '',
      stateName: '',
      zip: '',
      tripPurpose: ''
    };
  };

  return {
    isLoading: orgGroupPropertiesState.isArrayLoading || groupCategoryState.isArrayLoading,
    isSaving: clubGroupState.isSaving,
    formState,
    errorState,
    handleSubmit,
    onFormValueChanged,
    onValueTextPairChanged,
    onBackClicked,
    usasClubId: baseUsasClubState.objData.usasClubId || Constants.DEFAULT_ID,
    orgUnitId: baseUsasClubState.objData.orgUnitId || ''
  };
};

export default useClubGroupTypeTravelAdd;