import { ClubHttpHelper, NO_DATA_MESSAGE, BASIC_INITIAL_STATE } from '../../../common/utils/HttpHelper';
import Constants from '../../../common/utils/Constants';

const GetClubInactiveReasonsData = (state, setState) => {
  if (state.isArrayLoading !== true && !state.message) {
    const newState = {
      ...state,
      isArrayLoading: true,
      isArrayLoaded: false,
      message: 'Loading...'
    };
    setState(newState);

    const url = `/ClubInactiveReason`;
    ClubHttpHelper(url, 'GET')
      .then((arrayData) => {
        if (!arrayData) {
          throw new Error(NO_DATA_MESSAGE);
        } else {
          arrayData.unshift({ clubInactiveReasonId: Constants.DEFAULT_ID, inactiveReasonDescription: '--' });
          setState({
            ...newState,
            isArrayLoading: false,
            isArrayLoaded: true,
            message: '',
            arrayData
          });
        }
      })
      .catch((e) => {
        const message = e && e.message ? e.message : NO_DATA_MESSAGE;
        setState({
          ...newState,
          isArrayLoading: false,
          isArrayLoaded: false,
          message: message
        });
      });
  }
};

const INITIAL_STATE = {
  ...BASIC_INITIAL_STATE,
  options: [],
  isOptionsLoaded: false
};

const ClubInactiveReasonData = { INITIAL_STATE, GetClubInactiveReasonsData };

export default ClubInactiveReasonData;