
import React from "react";

import useKpiSubmittedValueForm from "./UseKpiSubmittedValueForm";

import PrimaryButton from "../../../../common/components/buttons/PrimaryButton";
import SecondaryButton from "../../../../common/components/buttons/SecondaryButton";
import Input from "../../../../common/components/inputs/Input";

const KpiSubmittedValueForm = ({ programLevel, categoryMeasure, inputLabel, onRedirect, submitFormCallback }) => {
  const {
    formState,
    errorState,
    onFormValueChanged,
    onSaveClicked
  } = useKpiSubmittedValueForm(programLevel, categoryMeasure, onRedirect, submitFormCallback);

  return (
    <form noValidate>
      <div className="row usas-extra-top-margin">
        <div className="col-xs-12 col-sm-6 col-md-4">
          <Input
            label={inputLabel}
            name="submittedValue"
            value={formState.submittedValue}
            error={errorState.submittedValue}
            message={errorState.submittedValue}
            onChange={(value) => { onFormValueChanged('submittedValue', value); }} />
        </div>
      </div>
      <div className="row usas-extra-top-margin usas-extra-bottom-margin">
        <div className="col-xs-12 usas-extra-top-margin">
          <PrimaryButton type="button" onClick={onSaveClicked}>Save</PrimaryButton>&nbsp;
          <SecondaryButton type="button" onClick={() => onRedirect(false)}>Back</SecondaryButton>
        </div>
      </div>
    </form>
  );
};

export default KpiSubmittedValueForm;