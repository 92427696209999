import { useEffect, useState } from 'react';

import { useExecuteQuery } from '@sisense/sdk-ui';
import { Sort } from '@sisense/sdk-data';

import * as DM from '../../../../usas-times-data';

import Constants from '../../../../common/utils/Constants';

const useSisenseSeasonYearDropdown = () => {
    const queryProps = { dataSource: DM.DataSource, dimensions: [DM.SeasonCalendar.SeasonYear.sort(Sort.Descending), DM.SeasonCalendar.SeasonYearDesc] };
    const { data } = useExecuteQuery(queryProps);
    const [seasonYearOptions, setSeasonYearOptions] = useState({ options: [] });

    useEffect(() => {
        const defaultOptions = [{ id: Constants.DEFAULT_ID, name: '--' }];
        if (data) {
            const formattedOptions = [];
            formattedOptions.push(defaultOptions[0]);
            data.rows.map((row) => {
                if (row[0].text !== '0') {
                    return (formattedOptions.push({ id: row[0].data, name: row[1].text }))
                }
            });
            setSeasonYearOptions({ ...seasonYearOptions, options: formattedOptions });
        }
        else {
            setSeasonYearOptions({ ...seasonYearOptions, options: defaultOptions });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data])

    return {
        data,
        seasonYearOptions
    };
};

export default useSisenseSeasonYearDropdown;