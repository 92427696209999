import React, { createContext, useState } from 'react';

import PrizeData from './PrizeData';

export const PrizeStateContext = createContext();

const PrizeContextProvider = ({ children }) => {
  const stateHook = useState(PrizeData.INITIAL_STATE);

  return (
    <PrizeStateContext.Provider value={stateHook}>
      {children}
    </PrizeStateContext.Provider>
  );
};

export default PrizeContextProvider;