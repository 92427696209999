import { useEffect, useState } from "react";

const useLSCRegistrationManagementGrid = (registrationData, isGridDirty, setIsGridDirty) => {
  const [gridState, setGridState] = useState([]);

  const onToggleLSCApprovedOffering = (memberRegistrationId) => {
    const gridData = JSON.parse(JSON.stringify(gridState));

    for (const club of gridData) {
      if (club && Array.isArray(club.members)) {
        const targetOffering = club.members.find(x => x.memberRegistrationId === memberRegistrationId);

        if (targetOffering) {
          targetOffering.isLscApproved = !targetOffering.isLscApproved;

          if (targetOffering.isLscApproved === false && club.isLscApproved === true) {
            club.isLscApproved = false;
          }

          break;
        }
      }
    }

    const isNewGridDirty = isGridDataDirty(gridData);
    if (isNewGridDirty !== isGridDirty) {
      setIsGridDirty(isNewGridDirty);
    }

    setGridState(gridData);
  };

  const onToggleLSCApprovedClub = (orgUnitId) => {
    const gridData = JSON.parse(JSON.stringify(gridState));

    for (const club of gridData) {
      if (club && club.orgUnitId === orgUnitId && Array.isArray(club.members)) {
        club.isLscApproved = !club.isLscApproved;

        for (const offering of club.members) {
          offering.isLscApproved = club.isLscApproved;
        }
        break;
      }
    }

    const isNewGridDirty = isGridDataDirty(gridData);
    if (isNewGridDirty !== isGridDirty) {
      setIsGridDirty(isNewGridDirty);
    }

    setGridState(gridData);
  };

  const onCancelChanges = () => {
    if (Array.isArray(registrationData)) {
      setGridState(JSON.parse(JSON.stringify(registrationData)));
    }

    setIsGridDirty(false);
  };

  useEffect(() => {
    if (Array.isArray(registrationData)) {
      setGridState(JSON.parse(JSON.stringify(registrationData)));
    }
  }, [registrationData]);

  // if any offering is lsc approved, data is dirty
  function isGridDataDirty(gridData) {
    for (const club of gridData) {
      if (club && Array.isArray(club.members)) {
        if (club.members.find(x => x.isLscApproved === true)) {
          return true;
        }
      }
    }

    return false;
  };

  return {
    gridState,
    onToggleLSCApprovedOffering,
    onToggleLSCApprovedClub,
    onCancelChanges
  };
};

export default useLSCRegistrationManagementGrid;