
import React, { Fragment } from 'react';

import SelectOrgUnit from '../../../../common/components/selectOrgUnit/SelectOrgUnit';

import useOrgUnitSelection from './UseOrgUnitSelection';

const OrgUnitSelection = () => {
  const { NavLinks, TAXONOMIES, SCOPE, getBaseUsasClubByOrgUnitId, MY_CLUB_ROLE_GROUP_ID } = useOrgUnitSelection();

  return (
    <Fragment>
      <SelectOrgUnit
        pageTitle={'My Clubs'}
        navLink={NavLinks.CLUB_INFO}
        roleGroupId={MY_CLUB_ROLE_GROUP_ID}
        getFunction={getBaseUsasClubByOrgUnitId}
        TAXONOMIES={TAXONOMIES}
        SCOPE={SCOPE} />
    </Fragment>
  );
};

export default OrgUnitSelection;