import { createContext, useState } from 'react';

import ZoneReportingData from './ZoneReportingData';

export const ZoneReportingStateContext = createContext();

const ZoneReportingContextProvider = ({ children }) => {
  const stateHook = useState(ZoneReportingData.INITIAL_STATE);

  return (
    <ZoneReportingStateContext.Provider value={stateHook}>
      {children}
    </ZoneReportingStateContext.Provider>
  );
};

export default ZoneReportingContextProvider;