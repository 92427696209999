import { useEffect, useState } from 'react';
import { useNavigate } from '../../../../common/wrappers/ReactRouterDom';

import NavLinks from '../NavLinks';

import usePreCompLocationRegistrationData from '../../../state/preCompLocationRegistration/UsePreCompLocationRegistrationData';
import usePreCompLocationRegistrationNavData from '../../../state/preCompLocationRegistration/preCompLocationRegistrationLeftNavBar/UsePreCompLocationRegistrationNavData';

const usePreCompLocationFacilitiesLinkSearch = () => {
  const navigate = useNavigate();
  const { preCompLocationRegistrationState } = usePreCompLocationRegistrationData();
  const { locationRegistrationNavState } = usePreCompLocationRegistrationNavData();
  const [modalState, setModalState] = useState(getInitialModalState);

  const onLinkFacility = (e, facilityId) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    let dupFacility = null;
    for (const facility of preCompLocationRegistrationState.objData.orgUnit.orgUnitFacility) {
      if (facility.facilityId === facilityId) {
        dupFacility = facility;
        break;
      }
    }

    if (dupFacility === null) {
      navigate(NavLinks.PRE_COMP_REGISTRATION_LOCATION_FACILITY_DETAIL, { state: { facilityId } });
    } else {
      setModalState({
        ...modalState,
        displayModal: true,
        facilityName: dupFacility.facility?.facilityName || ''
      });
    }
  };

  const onCancelClicked = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    navigate(NavLinks.PRE_COMP_REGISTRATION_LOCATION_FACILITY);
  };

  const onModalCancel = () => {
    setModalState(getInitialModalState);
  };

  const onAddANewFacility = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    navigate(NavLinks.PRE_COMP_REGISTRATION_LOCATION_FACILITY_ADD);
  };

  useEffect(() => {
    if (preCompLocationRegistrationState.isObjLoaded === false) {
      navigate(NavLinks.PRE_COMP_REGISTRATION_ENTRY);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function getInitialModalState() {
    return {
      displayModal: false,
      facilityName: '',
    };
  };

  return {
    locationRegistrationNavState,
    modalState,
    onLinkFacility,
    onCancelClicked,
    onModalCancel,
    onAddANewFacility
  };
};

export default usePreCompLocationFacilitiesLinkSearch;