import { BASIC_INITIAL_STATE } from '../../../common/utils/HttpHelper';

import getUsasClub from './GetUsasClubData';
import getPotentialUsasClubDupes from './GetPotentialUsasClubDupesData';
import postUsasClub from './PostUsasClubData';
import putUsasClub from './PutUsasClubData';
import putUsasClubAndHelperObj from './PutUsasClubAndHelperObjData';

const INITIAL_STATE = {
  ...BASIC_INITIAL_STATE,
  helperObj: {}
};

const ClubRegistrationData = {
  INITIAL_STATE,
  getUsasClub,
  getPotentialUsasClubDupes,
  postUsasClub,
  putUsasClub,
  putUsasClubAndHelperObj
};

export default ClubRegistrationData;