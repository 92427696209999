import React, { Fragment } from 'react';

import PreCompOrganizationContactsGridLarge from './PreCompOrganizationContactsGridLarge';
import PreCompOrganizationContactsGridSmall from './PreCompOrganizationContactsGridSmall';

const PreCompOrganizationContactsGrid = ({ data, isLoading, onDelete }) => (
  <Fragment>
    <PreCompOrganizationContactsGridLarge data={data} isLoading={isLoading} onDelete={onDelete} />
    <PreCompOrganizationContactsGridSmall data={data} isLoading={isLoading} onDelete={onDelete} />
  </Fragment>
);

export default PreCompOrganizationContactsGrid;