import React, { Fragment } from 'react';

import ClubRegistrationConstants from '../utils/ClubRegistrationConstants';

import PrimaryButton from '../../../../common/components/buttons/PrimaryButton';
import PopUpModal from '../../../../common/components/dialogs/PopUpModal';

const ValidationPopUpModal = ({ clubRegistrationState, state, modalState, onModalContinue, onModalCanceled }) => {

  return (
    <PopUpModal
      widthPct={90}
      maxWidth={720}
      title={modalState.modalTitle}
      displayPopUp={modalState.displayPopUp}
      onModalCanceled={onModalCanceled}>
      <div className="row">
        <div className="col-xs-12">
          {state.submitButton === ClubRegistrationConstants.CONTINUE ?
            <p>Do you wish to continue? 
              {modalState.modalTitle === ClubRegistrationConstants.MISSING_REQUIRED_INFORMATION && 
                clubRegistrationState.isObjLoaded === true && clubRegistrationState.objData.clubRegistrations.length === 1 ? ClubRegistrationConstants.UNSAVED_CHANGES : ''}</p> : <Fragment />}
          {state.submitButton === ClubRegistrationConstants.EXIT ?
            <span>
              {clubRegistrationState.isObjLoaded === true && clubRegistrationState.objData.status === ClubRegistrationConstants.PENDING_STATUS ?
                <Fragment>
                  <p><b>Your registration has been submitted and is awaiting approval.</b></p>
                </Fragment>
                : <Fragment />}
              {clubRegistrationState.isObjLoaded === true && clubRegistrationState.objData.status === ClubRegistrationConstants.APPROVED_STATUS ?
                <p><b>Your registration is approved.</b></p>
                : <Fragment />}
              <p>Do you wish to exit? {modalState.modalTitle === ClubRegistrationConstants.MISSING_REQUIRED_INFORMATION ? ClubRegistrationConstants.UNSAVED_CHANGES : ''}</p>
            </span>
            : <Fragment />}
          {state.submitButton === ClubRegistrationConstants.BACK ?
            <p>Do you wish to go back? {modalState.modalTitle === ClubRegistrationConstants.MISSING_REQUIRED_INFORMATION ? ClubRegistrationConstants.UNSAVED_CHANGES : ''}</p>
            : <Fragment />}
        </div>
      </div>
      <div className="row usas-extra-top-margin">
        <div className="col-xs-12">
          <PrimaryButton type="button" onClick={onModalContinue}>{state.submitButton}</PrimaryButton>&nbsp;
          {/* <SecondaryButton type="button" onClick={onModalCanceled}>Cancel</SecondaryButton> */}
        </div>
      </div>
    </PopUpModal >
  )
}

export default ValidationPopUpModal;