import { isValidAddressLine, isValid5DigitZip, isValid9DigitZip, isValidITIN, isValidEIN } from '../../../utils/validation';
import Constants from '../../../utils/Constants';

const REQUIRED_FILTER_COUNT = 1;

export const localValidate = (formState) => {
  let errors = {};
  let filterCount = 0;

  if (formState.name.trim() !== '') {
    filterCount += 1;

    if (formState.name.trim().length > 200) {
      errors.name = 'Organization/Location Name cannot exceed 200 characters'
    }
  }

  if (formState.federalTaxId.trim() !== '') {
    filterCount += 1;
    if (!isValidITIN(formState.federalTaxId.trim()) && !isValidEIN(formState.federalTaxId.trim())) {
      errors.federalTaxId = 'Must be valid Federal Tax Id format XXX-XX-XXXX or XX-XXXXXXX';
    }
  }

  if (formState.city.trim() !== '') {
    filterCount += 1;

    if (!isValidAddressLine(formState.city.trim())) {
      errors.city = 'City cannot exceed 100 characters. Letters, numbers, number signs, spaces, periods, dashes, slashes, and apostrophes are allowed.';
    }
  }

  if (formState.stateName !== '') {
    filterCount += 1;

    if (formState.stateCode === Constants.DEFAULT_ID || formState.stateCode === '') {
      errors.stateCode = 'Must be a valid State';
    }
  }

  if (formState.zipCode.trim() !== '') {
    filterCount += 1;

    if (!isValid5DigitZip(formState.zipCode.trim()) && !isValid9DigitZip(formState.zipCode.trim())) {
      errors.zipCode = 'Zip Code must be 5 digits, or 9 digits in XXXXX-XXXX format';
    }
  }

  if (filterCount < REQUIRED_FILTER_COUNT) {
    errors.filter = 'Please enter information in at least one search filter';
  }

  return errors;
};

const SearchPreCompValidation = async (formState) => {
  let errors = localValidate(formState);

  return errors ? errors : {};
};

export default SearchPreCompValidation;