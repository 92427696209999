import { isValidEIN, isValidITIN } from "../../../../../common/utils/validation";

const localValidate = (formState) => {
  let errors = {};
  let filterCount = 0;

  if (formState.entityName.trim() !== '') {
    filterCount += 1;
  }

  if (formState.tradeName.trim() !== '') {
    filterCount += 1;
  }

  if (formState.taxId.trim() !== '') {
    filterCount += 1;

    if (!isValidITIN(formState.taxId.trim()) && !isValidEIN(formState.taxId.trim())) {
      errors.taxId = 'Must be valid Tax Id format XXX-XX-XXXX or XX-XXXXXXX';
    }
  }

  if (filterCount === 0) {
    errors.filterCount = 'Must enter information in at least one search filter';
  }

  return errors;
};

const ProviderSearchFormValidation = async (formState) => {
  let errors = localValidate(formState);

  return errors ? errors : {};
};

export default ProviderSearchFormValidation;