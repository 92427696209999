import React, { Fragment } from 'react';

import useClubGroups from './UseClubGroups';

import ClubGroupsGrid from './clubGroupsGrid/ClubGroupsGrid';

import Headings from '../../../../common/components/headings/Headings';
import ActionIntraPageButton from '../../../../common/components/buttons/ActionIntraPageButton';
import PopUpModal from '../../../../common/components/dialogs/PopUpModal';
import PrimaryButton from '../../../../common/components/buttons/PrimaryButton';
import SecondaryButton from '../../../../common/components/buttons/SecondaryButton';

import Constants from '../../../../common/utils/Constants';

const ClubGroups = () => {
  const {
    modalState,
    clubGroupState,
    onModalCanceled,
    onClickRemoveGroup,
    onEditClubGroup,
    onDeleteClubGroup,
    onAddClubGroup,
    onAddRemoveMember
  } = useClubGroups();

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>Club Groups</Headings.H3>
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12 usas-extra-top-margin">
          <ActionIntraPageButton type="button" onClick={(e) => onAddClubGroup(e)}>Add Group</ActionIntraPageButton>
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12 usas-extra-top-margin"></div>
      </div>
      <ClubGroupsGrid
        clubGroupState={clubGroupState}
        onEditClubGroup={onEditClubGroup}
        onDeleteClubGroup={onDeleteClubGroup}
        onAddRemoveMember={onAddRemoveMember} />
      <div className="row">
        <div className="col-xs-12 usas-extra-bottom-margin"></div>
      </div>
      <PopUpModal
        title={'Delete this group?'}
        displayPopUp={modalState.displayPopUp}
        widthPct={90}
        maxWidth={720}
        onModalCanceled={onModalCanceled}>
        <div className='row'>
          <div className='col-xs-12'>
            <p><b>Group Name:</b> {modalState.groupName}</p>
          </div>
        </div>
        <div className='row usas-extra-top-margin'>
          <div className='col-xs-12'>
            <PrimaryButton type="button" onClick={(e) => { onClickRemoveGroup(e, modalState.groupId); }}>Delete</PrimaryButton>&nbsp;
            <SecondaryButton type="button" onClick={onModalCanceled}>Cancel</SecondaryButton>
          </div>
        </div>
      </PopUpModal>
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={clubGroupState.isArrayLoading} />
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.SAVING_MSG}
        displayPopUp={clubGroupState.isSaving} />
    </Fragment>
  );
};

export default ClubGroups;