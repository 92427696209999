import React, { Fragment } from 'react';

import LargeGrid from './LargeGrid';
import SmallGrid from './SmallGrid';
import ClubGroupInfoHeader from './clubGroupMemberHeader/ClubGroupInfoHeader';
import AddRemoveMembersConfirmation from './addRemoveMembersConfirmation/AddRemoveMembersConfirmation';

import useClubGroupsAddRemoveMembersGrid from './UseClubGroupsAddRemoveMembersGrid';

import Headings from '../../../../../common/components/headings/Headings';
import PrimaryButton from '../../../../../common/components/buttons/PrimaryButton';
import SecondaryButton from '../../../../../common/components/buttons/SecondaryButton';
import Input from '../../../../../common/components/inputs/Input';
import Dropdown from '../../../../../common/components/dropdowns/Dropdown';
import PopUpModal from '../../../../../common/components/dialogs/PopUpModal';
import Constants from '../../../../../common/utils/Constants';

import global from '../../../../../common/components/GlobalStyle.module.css';
import style from './ClubGroupsAddRemoveMembersGrid.module.css';

const ClubGroupsAddRemoveMembersGrid = () => {
  const {
    displayPopupState,
    formState,
    errorState,
    clubGroupState,
    clubMembersState,
    gridState,
    onSave,
    onShowPopup,
    onClosePopup,
    onSelectAllClicked,
    handleSubmit,
    onCancelGoToClubGroups,
    onFormValueChanged,
    onCheckBoxChange,
    competitionGenderOptions
  } = useClubGroupsAddRemoveMembersGrid();

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>Add/Edit Group Athletes</Headings.H3>
        </div>
      </div>
      <ClubGroupInfoHeader clubGroupState={clubGroupState} />
      <form onSubmit={handleSubmit}>
        <div className="row usas-extra-top-margin usas-extra-bottom-margin">
          <div className="col-xs-12">
            <label className={style.FilterAgeHeading}>Filter By Age</label>
          </div>
          <div className="col-xs-12 col-sm-1">
            <Input
              label="Start"
              name="startAge"
              value={formState.startAge}
              error={errorState.startAge}
              message={errorState.startAge}
              onChange={(value) => { onFormValueChanged('startAge', value); }} />
          </div>
          <div className="col-xs-12 col-sm-1">
            <Input
              label="End"
              name="endAge"
              value={formState.endAge}
              error={errorState.endAge}
              message={errorState.endAge}
              onChange={(value) => { onFormValueChanged('endAge', value); }} />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4">
            <Dropdown
              error={errorState.filterCompetitionGender}
              label="Filter Athlete By Competition Category:"
              message={errorState.message}
              options={competitionGenderOptions}
              name="filterCompetitionGender"
              value={formState.filterCompetitionGender}
              onChange={(value) => { onFormValueChanged('filterCompetitionGender', value); }}
            />
          </div>
          <div className="col-xs-12 col-sm-2">
            <button type="submit" className={global.PlainButton}>Filter</button>
          </div>
        </div>
      </form>
      <div className="row usas-extra-top-margin usas-extra-bottom-margin">
        <div className="col-xs-12 usas-extra-top-margin">
          <PrimaryButton type="button" onClick={onSelectAllClicked}>Select All</PrimaryButton>
        </div>
      </div>
      <LargeGrid
        data={gridState.membersInClub}
        onCheckBoxChange={onCheckBoxChange} />
      <SmallGrid
        data={gridState.membersInClub}
        onCheckBoxChange={onCheckBoxChange} />
      <div className="row usas-extra-top-margin usas-extra-bottom-margin">
        <div className="col-xs-12 usas-extra-top-margin">
          <PrimaryButton type="button" onClick={onShowPopup}>Save</PrimaryButton>&nbsp;
          <SecondaryButton type="button" onClick={onCancelGoToClubGroups}>Back</SecondaryButton>
        </div>
      </div>
      <AddRemoveMembersConfirmation
        displayPopup={displayPopupState}
        gridState={gridState}
        clubGroupState={clubGroupState}
        onClosePopup={onClosePopup}
        onCheckBoxChange={onCheckBoxChange}
        onSave={onSave} />
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={clubGroupState.isArrayLoading || clubMembersState.isArrayLoading} />
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.SAVING_MSG}
        displayPopUp={clubGroupState.isSaving} />
    </Fragment>
  );
};

export default ClubGroupsAddRemoveMembersGrid;

