import React, { Fragment } from 'react';

import usePreCompLocationFacilitiesDetail from './UsePreCompLocationFacilitiesDetail';

import PreCompLocationFacilityReadOnlyInfo from '../components/forms/PreCompLocationFacilityReadOnlyInfo';
import PreCompLocationFacilityContactForm from '../components/forms/PreCompLocationFacilityContactForm';

import Headings from '../../../../common/components/headings/Headings';
import PrimaryButton from '../../../../common/components/buttons/PrimaryButton';
import SecondaryButton from '../../../../common/components/buttons/SecondaryButton';
import PopUpModal from '../../../../common/components/dialogs/PopUpModal';
import Constants from '../../../../common/utils/Constants';

import global from '../../../../common/components/GlobalStyle.module.css';

const PreCompLocationFacilitiesDetail = () => {
  const {
    teamFacilityLinksState,
    formState,
    errorState,
    onFormValueChanged,
    handleSubmit,
    onCancelClicked
  } = usePreCompLocationFacilitiesDetail();

  return (
    <Fragment>
      <Headings.H3>Facility Contact Info</Headings.H3>
      <PreCompLocationFacilityReadOnlyInfo
        facilityObj={formState} />
      <form onSubmit={handleSubmit} noValidate>
        <PreCompLocationFacilityContactForm
          formState={formState}
          errorState={errorState}
          onFormValueChanged={onFormValueChanged} />
        {errorState.errorOnSubmitAction &&
          <div className="row">
            <div className="col-xs-12">
              <p className={global.ErrorMessage}>{errorState.errorOnSubmitAction || ''}</p>
            </div>
          </div>}
        <div className="row usas-extra-top-margin usas-extra-bottom-margin">
          <div className="col-xs-12 usas-extra-top-margin">
            <PrimaryButton type="submit">Save</PrimaryButton>&nbsp;
            <SecondaryButton type="button" onClick={onCancelClicked}>Back</SecondaryButton>
          </div>
        </div>
      </form>
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.SAVING_MSG}
        displayPopUp={teamFacilityLinksState.isSaving} />
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={teamFacilityLinksState.isArrayLoading} />
    </Fragment >
  );
};

export default PreCompLocationFacilitiesDetail;