import React, { Fragment } from 'react';

import SelectOrgUnit from '../../../../common/components/selectOrgUnit/SelectOrgUnit';

import useOrgUnitSelection from './UseOrgUnitSelection';

const OrgUnitSelection = () => {
  const { 
    NavLinks, 
    getBaseUsasClubByOrgUnitId, 
    CLUB_RENEWAL_SELECTOR_ROLE_GROUP_ID, TAXONOMIES, SCOPE } = useOrgUnitSelection();

  return (
    <Fragment>
      <SelectOrgUnit
        pageTitle={'Club Registration'}
        navLink={NavLinks.CLUB_REGISTRATION_ENTRY_NEW}
        roleGroupId={CLUB_RENEWAL_SELECTOR_ROLE_GROUP_ID}
        getFunction={getBaseUsasClubByOrgUnitId}
        TAXONOMIES={TAXONOMIES}
        SCOPE={SCOPE} />
    </Fragment>
  );
};

export default OrgUnitSelection;