import { BASIC_INITIAL_STATE } from '../../../common/utils/HttpHelper';

import postUsasClubFilesData from './PostUsasClubFilesData';

const BASE_UPLOAD_URL = 'https://clubdocs.blob.core.windows.net/upload-container/';
const GET_DOWNLOAD_TEMPLATE_PATH = '/UsasClubFiles/downloadTemplate/';
const GET_USAS_CLUB_FILES_PATH = '/UsasClubFiles?fileUrl=';

const INITIAL_STATE = {
  ...BASIC_INITIAL_STATE
};

const UsasClubFilesData = {
  BASE_UPLOAD_URL,
  GET_DOWNLOAD_TEMPLATE_PATH,
  GET_USAS_CLUB_FILES_PATH,
  INITIAL_STATE,
  postUsasClubFilesData
};

export default UsasClubFilesData;