import { useEffect } from 'react';

import validate from './ClubAdminChangesValidation';

import useClubInactiveReasonData from '../../../state/clubInactiveReason/UseClubInactiveReasonData';
import useClubRegistrationNewData from '../../../state/clubRegistrationNew/UseClubRegistrationNewData';

import useBaseUsasClubData from '../../../../common/state/clubBase/UseBaseUsasClubData';

import useForm from '../../../../common/utils/UseForm';
import Constants from '../../../../common/utils/Constants';
import { formatDate } from '../../../../common/utils/DateFunctions';

const useClubAdminChanges = () => {
  const { baseUsasClubState, putBaseUsasClub } = useBaseUsasClubData();
  const { clubInactiveReasonState } = useClubInactiveReasonData();
  const { clubRegistrationState, getUsasClub, clearObjData } = useClubRegistrationNewData();
  const { formState, errorState, isSubmitting, handleSubmit, updateFormState, resetForm, setFormData
  } = useForm(getInitialFormState(), submitFormCallback, validate);

  const onCancelClicked = () => {
    resetForm();
  };

  const updateClubStateFromFormState = () => {
    //format club object to send to the PUT API call
    let clubToPut = JSON.parse(JSON.stringify(baseUsasClubState.objData));

    const inactiveReasonArray = [{
      ...clubToPut.usasClubInactiveReason,
      usasClubId: clubToPut.usasClubId,
      clubInactiveReasonId: formState.isActive === false && formState.clubInactiveReasonId > 0 ? formState.clubInactiveReasonId : null
    }];

    clubToPut = {
      ...clubToPut,
      orgUnit: {
        ...clubToPut.orgUnit,
        orgUnitName: formState.clubName.trim(),
        orgUnitCode: formState.clubCode.trim()
      },
      isActive: formState.isActive,
      usasClubInactiveReason: formState.isActive === false ? inactiveReasonArray : []
    };

    return clubToPut;
  };

  useEffect(() => {
    clearObjData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (baseUsasClubState.isObjLoaded === true && 
        clubRegistrationState.isObjLoaded === false && 
        clubRegistrationState.isObjLoading === false && 
        clubRegistrationState.isSaving === false ) {
          getUsasClub(baseUsasClubState.objData.usasClubId);
    }
  }, [baseUsasClubState, clubRegistrationState, getUsasClub]);

  useEffect(() => {
    if (baseUsasClubState.isObjLoading === false && baseUsasClubState.isSaving === false && 
      baseUsasClubState.isObjLoaded === true && clubInactiveReasonState.isArrayLoaded === true &&
      clubRegistrationState.isObjLoaded === true && clubRegistrationState.isObjLoading === false) {
      const currentOrgUnitParentChildIndex = baseUsasClubState.objData.orgUnit?.orgUnitParentChild.findIndex(x => formatDate(x.expirationDate) === Constants.DEFAULT_MAX_DATE);

      const arrayIndex = clubRegistrationState.objData?.clubRegistrations?.length || -1;
      const expirationDate = clubRegistrationState.objData.clubRegistrations[arrayIndex - 1].expirationDate || Constants.DEFAULT_MIN_DATE;

      setFormData({
        ...formState,
        usasClubId: baseUsasClubState.objData.usasClubId || '',
        clubName: baseUsasClubState.objData.orgUnit?.orgUnitName || '',
        clubCode: baseUsasClubState.objData.orgUnit?.orgUnitCode || '',
        isActive: baseUsasClubState.objData.isActive === true || baseUsasClubState.objData.isActive === false ? baseUsasClubState.objData.isActive : true,
        clubInactiveReasonId: baseUsasClubState.objData.usasClubInactiveReason[0]?.clubInactiveReasonId || Constants.DEFAULT_ID,
        clubInactiveReason: baseUsasClubState.objData.clubInactiveReasonId > 0 ? clubInactiveReasonState.arrayData.find(x => x.clubInactiveReasonId ===
          baseUsasClubState.objData.clubInactiveReasonId)?.inactiveReasonDescription || '' : '',
        lscId: currentOrgUnitParentChildIndex > -1
          ? baseUsasClubState.objData.orgUnit.orgUnitParentChild[currentOrgUnitParentChildIndex].parentOrgUnitId
          : baseUsasClubState.objData.orgUnit.parentOrgUnitId,
        isClubCurrent: new Date(expirationDate) > new Date()
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [baseUsasClubState, clubInactiveReasonState, clubRegistrationState]);

  function submitFormCallback() {
    const putObj = updateClubStateFromFormState();

    putBaseUsasClub(putObj.usasClubId, putObj);
  };

  return {
    formState,
    errorState,
    baseUsasClubState,
    clubInactiveReasonState,
    isSubmitting,
    onFormValueChanged: updateFormState,
    onCancelClicked,
    handleSubmit
  };
};

export default useClubAdminChanges;

function getInitialFormState() {
  return {
    usasClubId: '',
    clubName: '',
    clubCode: '',
    isActive: true,
    clubInactiveReasonId: Constants.DEFAULT_ID,
    clubInactiveReason: '',
    lscId: '',
    isClubCurrent: false
  };
};