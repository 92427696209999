import React, { createContext, useState } from 'react';

import MemberRegistrationRenewalSelectionData from './MemberRegistrationRenewalSelectionData';

export const MemberRegistrationRenewalSelectionStateContext = createContext();

const MemberRegistrationRenewalSelectionProvider = ({ children }) => {
  const stateHook = useState(MemberRegistrationRenewalSelectionData.INITIAL_STATE);

  return (
    <MemberRegistrationRenewalSelectionStateContext.Provider value={stateHook}>
      {children}
    </MemberRegistrationRenewalSelectionStateContext.Provider>
  );
};

export default MemberRegistrationRenewalSelectionProvider;