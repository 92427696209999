import { Fragment } from 'react';

import useLearnToSwimRegistrationFacilitiesAdd from './UseLearnToSwimRegistrationFacilitiesAdd';

import LearnToSwimRegistrationLeftNav from '../components/leftNav/LearnToSwimRegistrationLeftNav';
import { FACILITIES_NAME } from '../components/leftNav/LearnToSwimRegistrationLeftNavConstants';

import LearnToSwimFacilityAddForm from '../../learnToSwim/components/forms/LearnToSwimFacilityAddForm';

import FacilityDupCheckContent from '../../../../common/components/searches/searchFacility/addFacility/components/FacilityDupCheckContent';
import PopUpModal from '../../../../common/components/dialogs/PopUpModal';

import Constants from '../../../../common/utils/Constants';

const LearnToSwimRegistrationFacilitiesAdd = () => {
  const {
    isLoading,
    state,
    dupsState,
    facilityState,
    onAddDupFacilityClicked,
    onCancelDupFacilityClicked,
    onSubmitFormCallback,
    onBackClicked
  } = useLearnToSwimRegistrationFacilitiesAdd();

  return (
    <Fragment>
      <LearnToSwimRegistrationLeftNav viewName={FACILITIES_NAME} viewTitle={'Add Facility'}>
        <LearnToSwimFacilityAddForm
          onSubmitFormCallback={onSubmitFormCallback}
          onSecondaryButtonClicked={onBackClicked} />
        <PopUpModal
          widthPct={50}
          maxWidth={250}
          title={Constants.LOADING_MSG}
          displayPopUp={isLoading} />
        <PopUpModal
          widthPct={50}
          maxWidth={250}
          title={Constants.SAVING_MSG}
          displayPopUp={facilityState.isSaving} />
        <PopUpModal
          title="Facility Duplicate Check"
          displayPopUp={dupsState.arrayData?.length > 0}>
          <FacilityDupCheckContent
            formState={state.facilityObj}
            dupsState={dupsState}
            onAddDupFacilityClicked={onAddDupFacilityClicked}
            onCancelDupFacilityClicked={onCancelDupFacilityClicked} />
        </PopUpModal>
      </LearnToSwimRegistrationLeftNav>
    </Fragment>
  );
};

export default LearnToSwimRegistrationFacilitiesAdd;