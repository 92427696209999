import React, { Fragment } from 'react';

import HideIcon from '../../icons/HideIcon';
import ShowIcon from '../../icons/ShowIcon';
import EditIcon from '../../icons/EditIcon';
import SubmitIcon from '../../icons/SubmitIcon';

import Constants from '../../../utils/Constants';
import { formatDate } from '../../../utils/DateFunctions';

import global from '../../GlobalStyle.module.css';

const DetailTableRow = ({ programCategory, programLevel, onEdit }) => (
  <div className={global.SmallTableRow}>
    <div className={global.SmallTableDetailRowHead}>
      <span className='hidden-xs'>{programCategory.categoryName && programCategory.categoryName.trim() !== '' ?
        programCategory.categoryName : <span>&nbsp;</span>}</span>&nbsp;
      <div className={global.FloatRight}>
        <button
          type="button"
          className={global.IconButton}
          onClick={(e) => onEdit(e, programLevel.programLevelInstanceId, programCategory.programCategoryId)}>
          <EditIcon />
        </button>
      </div>
    </div>
    <div className={global.SmallTableRowInfo}>
      <div className='row'>
        <div className={['col-xs-6 col-sm-3 visible-xs', global.SmallTableRowLabels].join(' ')}>
          Category Name
        </div>
        <div className={['col-xs-6 col-sm-3 visible-xs', global.SmallTableRowData].join(' ')}>
          {programCategory.categoryName && programCategory.categoryName.trim() !== '' ?
            programCategory.categoryName : <span>&nbsp;</span>}
        </div>
        <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>Minimum Points</div>
        <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>{programCategory.minimumValue >= 0 ?
          programCategory.minimumValue : <span>&nbsp;</span>}</div>
        <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>Current Points</div>
        <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>{programCategory.currentPointValue >= 0 &&
          programCategory.currentPointValue !== null ? programCategory.currentPointValue : '0'}</div>
      </div>
    </div>
  </div >
);

const DetailTable = ({ programCategory, programLevel, onEdit }) => (
  <div className={global.SmallTableDetailTable}>
    {Array.isArray(programCategory) && programCategory.map((programCategory, i) =>
      <DetailTableRow key={i} programCategory={programCategory}
        programLevel={programLevel} onEdit={onEdit} />)}
  </div>
);

const GridRowContent = ({ programLevel, showStatus }) => (
  <div className={global.SmallTableRowInfo}>
    <div className='row'>
      <div className={['col-xs-6 col-sm-3 visible-xs', global.SmallTableRowLabels].join(' ')}>
        Level Name
      </div>
      <div className={['col-xs-6 col-sm-3 visible-xs', global.SmallTableRowData].join(' ')}>
        {programLevel.levelName || <span>&nbsp;</span>}
      </div>
      <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>Effective Date</div>
      <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>
        {programLevel.effectiveDate ? formatDate(programLevel.effectiveDate) : <span>&nbsp;</span>}
      </div>
      <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>Expiration Date</div>
      <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>
        {programLevel.expirationDate ? formatDate(programLevel.expirationDate) : <span>&nbsp;</span>}
      </div>
      <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>Minimum Points</div>
      <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>
        {programLevel.minimumValue >= 0 ? programLevel.minimumValue : <span>&nbsp;</span>}
      </div>
      <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>Current Points</div>
      <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>
        {programLevel.currentPointValue >= 0 && programLevel.currentPointValue !== null ? programLevel.currentPointValue : '0'}
      </div>
      {showStatus === true ?
        <Fragment>
          <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>Status</div>
          <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>{programLevel.status || <span>&nbsp;</span>}</div>
        </Fragment>
        : <Fragment />}
    </div>
  </div>
)

const GridRow = ({ programLevel, previousProgramLevel, onSubmit, onEdit, showStatus, expandedId, onClick }) => (
  <Fragment>
    <div className={global.SmallTableRow}>
      <div className={global.SmallTableRowHead}>
        <span className='hidden-xs'>{programLevel.levelName || <span>&nbsp;</span>}</span>&nbsp;
        <button className={global.IconButton}
          type="button"
          onClick={(e) => onClick(e, programLevel.programLevelInstanceId)}>
          {expandedId === programLevel.programLevelInstanceId ? <HideIcon /> : <ShowIcon />}
        </button>
        {showStatus === true && (programLevel.status === Constants.WORKFLOW_STATUS_NOT_SUBMITTED || programLevel.status === Constants.WORKFLOW_STATUS_IN_PROGRESS)
          && (previousProgramLevel?.status === Constants.WORKFLOW_STATUS_ACHIEVED || previousProgramLevel?.status === Constants.WORKFLOW_STATUS_RENEWED) ?
          <button
            type="button"
            className={global.IconButtonMobileMargin}
            onClick={(e) => onSubmit(e, programLevel.programLevelInstanceId)}>
            <SubmitIcon />
          </button>
          : <Fragment />}
      </div>
      <GridRowContent programLevel={programLevel} showStatus={showStatus} />
    </div>
    {Array.isArray(programLevel.programCategory) && programLevel.programCategory.length > 0 && expandedId === programLevel.programLevelInstanceId &&
      <DetailTable programCategory={programLevel.programCategory}
        programLevel={programLevel} onEdit={onEdit} />}
  </Fragment>
);

const ProgramLevelGridSmall = ({ data, isLoading, onSubmit, onEdit, showStatus, expandedId, onClick }) => (
  <Fragment>
    <div className={[global.SmallTable, 'hidden-md hidden-lg'].join(' ')}>
      {isLoading === true
        ? (
          <div className={global.SmallTableRow}>
            <div className={global.SmallTableRowHead}>&nbsp;</div>
            <div className={global.SmallTableRowInfo}>
              <div className={global.SmallTableRowLabels}>Loading...</div>
            </div>
          </div>
        ) : Array.isArray(data) && data.length > 0
          ? data.map((programLevel, i) =>
            <GridRow key={i} programLevel={programLevel} previousProgramLevel={i > 0 ? data[i - 1].programLevel : { status: 'Achieved' }}
              onSubmit={onSubmit} onEdit={onEdit} showStatus={showStatus} expandedId={expandedId} onClick={onClick} />)
          : (
            <div className={global.SmallTableRow}>
              <div className={global.SmallTableRowHead}>&nbsp;</div>
              <div className={global.SmallTableRowInfo}>
                <div className={global.SmallTableRowLabels}>No Program Levels</div>
              </div>
            </div>
          )
      }
    </div>
  </Fragment >
);

export default ProgramLevelGridSmall;