import { useContext } from 'react';

import SearchPreCompData from './SearchPreCompData';

import { SearchPreCompStateContext } from './SearchPreCompContextProvider';

const useSearchPreCompData = () => {
  const [searchPreCompState, setSearchPreCompState] = useContext(SearchPreCompStateContext);

  const resetSearchPreCompState = () => {
    setSearchPreCompState(SearchPreCompData.INITIAL_STATE);
  }

  return {
    searchPreCompState,
    setSearchPreCompState,
    resetSearchPreCompState
  };
};

export default useSearchPreCompData;