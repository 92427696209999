import React from 'react';

import EditIcon from "../../../../../common/components/icons/EditIcon";

import { CURRENCY_FORMATTER } from '../../../../../common/utils/Constants';
import { formatDate } from "../../../../../common/utils/DateFunctions";

import Constants from '../../../../../common/utils/Constants';
import global from '../../../../../common/components/GlobalStyle.module.css';

const GridRow = ({ contract, onEdit }) => (
  <tr>
    <td>{contract.satDate ? formatDate(contract.satDate) : ''}</td>
    <td>{contract.contractStatus?.statusDescription || ''}</td>
    <td>{contract.fundraisingGoal ? CURRENCY_FORMATTER.format(contract.fundraisingGoal) : ''}</td>
    <td>{contract.signatureDate ? formatDate(contract.signatureDate) : ''}</td>
    <td>{contract.fulfillmentDate ? formatDate(contract.fulfillmentDate) : ''}</td>
    <td>
      {onEdit && contract.contractStatus.statusDescription !== "Canceled" &&
        <button className={global.IconButtonMargin} type="button" onClick={(e) => onEdit(e, contract)}>
          <EditIcon />
        </button>
      }
    </td>
  </tr>
);

const SATContractsGridLarge = ({ data, isLoading, onEdit }) => (
  <table className={[global.UsasTable, 'visible-md visible-lg'].join(' ')}>
    <thead>
      <tr>
        <th>Swim-a-Thon Date</th>
        <th>Contract Status</th>
        <th>Fundraising Goal</th>
        <th>Signature Date</th>
        <th>Fulfillment Date</th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      {isLoading === true
        ? (
          <tr>
            <td colSpan="6">Loading...</td>
          </tr>
        ) : Array.isArray(data) && data.length > 0
          ? data.map((contract, i) => (
            <GridRow
              key={i}
              contract={contract}
              onEdit={onEdit} />
          )) : (
            <tr>
              <td colSpan="6">No Contracts</td>
            </tr>
          )
      }
    </tbody>
  </table>
);

export default SATContractsGridLarge;