import { useContext } from "react";

import { ClubPerformanceStateContext } from "./ClubPerformanceContextProvider";
import ClubPerformanceData from "./ClubPerformanceData";

const useClubPerformanceData = () => {
  const [clubPerformanceState, setClubPerformanceState] = useContext(ClubPerformanceStateContext);

  const getDecryptedOrgUnitId = (orgUnitId) => {
    return ClubPerformanceData.getDecryptedIdsData({ orgUnitId }, clubPerformanceState, setClubPerformanceState);
  };

  return {
    clubPerformanceState,
    getDecryptedOrgUnitId
  };
};

export default useClubPerformanceData;