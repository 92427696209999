import { useEffect, useState } from 'react';
import { useNavigate } from '../../../../common/wrappers/ReactRouterDom';

import NavLinks from '../NavLinks';
import PreCompRegistrationConstants from '../utils/PreCompRegistrationConstants';

import usePreCompLocationRegistrationNavData from '../../../state/preCompLocationRegistration/preCompLocationRegistrationLeftNavBar/UsePreCompLocationRegistrationNavData';
import usePreCompLocationRegistrationData from '../../../state/preCompLocationRegistration/UsePreCompLocationRegistrationData';

import useForm from '../../../../common/utils/UseForm';

const usePreCompRegistrationLocationClubs = () => {
  const navigate = useNavigate();
  const { preCompLocationRegistrationState, confirmSave, saveObjData } = usePreCompLocationRegistrationData();
  const { locationRegistrationNavState, updateCurrentNavState } = usePreCompLocationRegistrationNavData();
  const { formState, errorState, isDirty, isSubmitting, updateFormState, setFormData, handleSubmit,
  } = useForm(getInitialFormState, submitFormCallback, () => { });
  const [gridState, setGridState] = useState([]);
  const [modalState, setModalState] = useState(getInitialModalState);
  const [formModalState, setFormModalState] = useState(getInitialFormModalState);
  const [pageErrorMessageState, setPageErrorMessageState] = useState('');

  const onContinueClicked = () => {
    const isValidResult = isValidClubsData(preCompLocationRegistrationState.objData, preCompLocationRegistrationState.objData.preCompLocationClub || []);

    if (isValidResult === '') {
      navigate(NavLinks.PRE_COMP_REGISTRATION_LOCATION_FACILITY);
    } else {
      setPageErrorMessageState(isValidResult);
    }
  };

  const onBackClicked = () => {
    const isValidResult = isValidClubsData(preCompLocationRegistrationState.objData, preCompLocationRegistrationState.objData.preCompLocationClub || []);

    if (isValidResult === '') {
      navigate(NavLinks.PRE_COMP_REGISTRATION_LOCATION_INSTRUCTOR_TRAINING);
    } else {
      setPageErrorMessageState(isValidResult);
    }
  };

  const onLinkClub = () => {
    navigate(NavLinks.PRE_COMP_REGISTRATION_LOCATION_CLUB_SEARCH);
  };

  const onClubDelete = (usasClubId) => {
    if (preCompLocationRegistrationState.isSaving === false) {
      const targetIndex = preCompLocationRegistrationState.objData.preCompLocationClub.findIndex(x => x.usasClubId === usasClubId);

      if (targetIndex >= 0) {
        setModalState({
          ...modalState,
          displayPopUp: true,
          clubName: preCompLocationRegistrationState.objData.preCompLocationClub[targetIndex].clubInfo.name,
          clubLocation: preCompLocationRegistrationState.objData.preCompLocationClub[targetIndex].clubInfo.location,
          targetIndex,
          showLastClubWarning: preCompLocationRegistrationState.objData.preCompLocationClub.length === 1
        });
      }
    }
  };

  const onModalCancelClicked = () => {
    setModalState(getInitialModalState);
  };

  const onModalDeleteClicked = (targetIndex) => {
    if (targetIndex >= 0) {
      const locObj = preCompLocationRegistrationState.objData;

      locObj.preCompLocationClub.splice(targetIndex, 1);

      if (locObj.preCompLocationClub.length === 0) {
        locObj.isUsasMemberTeam = false;
        locObj.referParticipantsToUsas = false;
      }

      saveObjData(locObj);

      setModalState(getInitialModalState);
    }
  };

  const onFormModalCancelClicked = () => {
    setFormData({
      ...formState,
      isUsasMemberTeam: preCompLocationRegistrationState.objData.isUsasMemberTeam,
      referParticipantsToUsas: preCompLocationRegistrationState.objData.referParticipantsToUsas
    });
    setFormModalState(getInitialFormModalState);
  };

  const onFormModalConfirmClicked = () => {
    const locObj = preCompLocationRegistrationState.objData;

    locObj.isUsasMemberTeam = formState.isUsasMemberTeam;
    locObj.referParticipantsToUsas = formState.referParticipantsToUsas;
    locObj.preCompLocationClub = [];

    saveObjData(locObj);
    setFormModalState(getInitialFormModalState);
  };

  useEffect(() => {
    if (preCompLocationRegistrationState.objData) {
      updateCurrentNavState(PreCompRegistrationConstants.LOC_CLUB, isValidClubsData(preCompLocationRegistrationState.objData, preCompLocationRegistrationState.objData.preCompLocationClub || []) === '');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (preCompLocationRegistrationState.isObjLoaded === true && preCompLocationRegistrationState.isSaved === false) {
      setFormData({
        ...formState,
        isUsasMemberTeam: preCompLocationRegistrationState.objData.isUsasMemberTeam ?? '',
        referParticipantsToUsas: preCompLocationRegistrationState.objData.referParticipantsToUsas ?? ''
      });
      setGridState(preCompLocationRegistrationState.objData.preCompLocationClub || []);
    } else if (preCompLocationRegistrationState.isObjLoaded === false) {
      navigate(NavLinks.PRE_COMP_REGISTRATION_ENTRY);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [preCompLocationRegistrationState.isObjLoaded, preCompLocationRegistrationState.isSaved]);

  useEffect(() => {
    if (preCompLocationRegistrationState.isSaved === true && isSubmitting === false) {
      confirmSave();
      updateCurrentNavState(PreCompRegistrationConstants.LOC_CLUB, true);
      setPageErrorMessageState('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [preCompLocationRegistrationState, isSubmitting]);

  useEffect(() => {
    if (isDirty === true) {
      handleSubmit();

      if (preCompLocationRegistrationState.objData) {
        updateCurrentNavState(PreCompRegistrationConstants.LOC_CLUB, isValidClubsData(preCompLocationRegistrationState.objData, preCompLocationRegistrationState.objData.preCompLocationClub || []) === '');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formState]);

  function submitFormCallback(formState) {
    if (formState.isUsasMemberTeam !== preCompLocationRegistrationState.objData.isUsasMemberTeam
      || formState.referParticipantsToUsas !== preCompLocationRegistrationState.objData.referParticipantsToUsas) {
      if (formState.isUsasMemberTeam === false && formState.referParticipantsToUsas === false
        && preCompLocationRegistrationState.objData.preCompLocationClub.length > 0) {
        setFormModalState({
          ...formModalState,
          displayPopUp: true
        });
      }
      else {
        const locObj = preCompLocationRegistrationState.objData;

        locObj.isUsasMemberTeam = formState.isUsasMemberTeam;
        locObj.referParticipantsToUsas = formState.referParticipantsToUsas;

        saveObjData(locObj);
      }
    }
  };

  function isValidClubsData(locObj, clubsData) {
    if (!(locObj.isUsasMemberTeam === true || locObj.isUsasMemberTeam === false)
      || !(locObj.referParticipantsToUsas === true || locObj.referParticipantsToUsas === false)) {
      return 'Must select yes or no for both questions';
    } else if ((locObj.isUsasMemberTeam === true || locObj.referParticipantsToUsas === true) && clubsData.length === 0) {
      return 'Must link at least one club';
    } else {
      return '';
    }
  };

  function getInitialModalState() {
    return {
      displayPopUp: false,
      clubName: '',
      clubLocation: '',
      targetIndex: undefined,
      showLastClubWarning: false
    };
  };

  function getInitialFormModalState() {
    return {
      displayPopUp: false
    };
  };

  function getInitialFormState() {
    return {
      isUsasMemberTeam: '',
      referParticipantsToUsas: ''
    };
  };

  return {
    gridState,
    modalState,
    formState,
    errorState,
    formModalState,
    locationRegistrationNavState,
    pageErrorMessageState,
    onFormValueChanged: updateFormState,
    onLinkClub,
    onClubDelete,
    onModalCancelClicked,
    onModalDeleteClicked,
    onFormModalCancelClicked,
    onFormModalConfirmClicked,
    onContinueClicked,
    onBackClicked
  };
};

export default usePreCompRegistrationLocationClubs;