import { isValidAddressLine, isValid5DigitZip, isValid9DigitZip, validateAddress } from '../../../../common/utils/validation';
import { isValidLink } from '../../preComp/utils/PreCompUtils';
import { ClubHttpHelper, NO_DATA_MESSAGE } from '../../../../common/utils/HttpHelper';
import Constants from '../../../../common/utils/Constants';

export const localValidate = async (formState) => {
  let errors = {};

  const today = new Date();
  const currentYear = today.getFullYear();

  let addressError = false;

  //Club Name
  if (formState.clubName.trim().length === 0) {
    errors.clubName = 'Club Name is required'
  }
  else if (formState.clubName.trim().length > 100) {
    errors.clubName = 'Club Name cannot exceed 100 characters'
  }

  //Club Code
  if (formState.clubCode.trim().length === 0) {
    errors.clubCode = 'Club Code is required'
  }
  else if (formState.clubCode.trim().length > 15) {
    errors.clubCode = 'Club Code cannot exceed 15 characters'
  }

  //Lsc
  if (formState.lscId === Constants.DEFAULT_ID) {
    errors.lscId = 'LSC is required'
  }

  //Primary Mailing Club Address
  if (formState.address1.trim().length === 0) {
    addressError = true;
    errors.address1 = 'Primary Mailing Club Address is required'
  }
  else if (!isValidAddressLine(formState.address1.trim())) {
    addressError = true;
    errors.address1 = 'Primary Mailing Club Address cannot exceed 100 characters. Letters, numbers, number signs, spaces, periods, dashes, slashes, and apostrophes are allowed.';
  }

  //Address Line 2
  if (formState.address2.trim() !== '') {
    if (!isValidAddressLine(formState.address2.trim())) {
      addressError = true;
      errors.address2 = 'Address Line 2 cannot exceed 100 characters. Letters, numbers, number signs, spaces, periods, dashes, slashes, and apostrophes are allowed.';
    }
  }

  //City
  if (formState.city.trim().length === 0) {
    addressError = true;
    errors.city = 'City is required'
  }
  else if (!isValidAddressLine(formState.city.trim())) {
    addressError = true;
    errors.city = 'City cannot exceed 100 characters. Letters, numbers, number signs, spaces, periods, dashes, slashes, and apostrophes are allowed.';
  }
  
  //State
  if (formState.stateCode === Constants.DEFAULT_ID || formState.stateCode.trim() === '') {
    addressError = true;
    errors.stateCode = 'State is required and must be valid';
  }

  //Zip
  if (formState.postalCode.trim().length === 0) {
    addressError = true;
    errors.postalCode = 'Zip Code is required'
  } else if (!isValid5DigitZip(formState.postalCode.trim()) && !isValid9DigitZip(formState.postalCode.trim())) {
    addressError = true;
    errors.postalCode = 'Zip Code must be 5 digits, or 9 digits in XXXXX-XXXX format';
  }

  if (addressError === false
    && formState.showedAddressSuggestion === false) {
    formState.validatedAddress = await validateAddress(formState.address1?.trim(), formState.address2?.trim(), formState.city?.trim(), formState.stateCode, formState.postalCode?.trim());
    if (formState.validatedAddress.isValid === true
      && (
        formState.address1?.trim() !== formState.validatedAddress.address1?.trim()
        || formState.address2?.trim() !== formState.validatedAddress.address2?.trim()
        || formState.city?.trim() !== formState.validatedAddress.city?.trim()
        || formState.stateCode !== formState.validatedAddress.stateCode
        || formState.postalCode?.trim() !== formState.validatedAddress.postalCode?.trim()
      )
    ) {
      if (formState.address1?.trim() !== formState.validatedAddress.address1?.trim()) {
        errors.address1 = 'See Address Suggestion';
      }
      if (formState.address2?.trim() !== formState.validatedAddress.address2?.trim()) {
        errors.address2 = 'See Address Suggestion';
      }
      if (formState.city?.trim() !== formState.validatedAddress.city?.trim()) {
        errors.city = 'See Address Suggestion';
      }
      if (formState.stateCode !== formState.validatedAddress.stateCode) {
        errors.stateCode = 'See Address Suggestion';
      }
      if (formState.postalCode?.trim() !== formState.validatedAddress.postalCode?.trim()) {
        errors.postalCode = 'See Address Suggestion';
      }
    }
  }


  //Year Founded
  if (formState.yearFounded && formState.yearFounded.length > 0) {
    if (!Number.isInteger(parseInt(formState.yearFounded))) {
      errors.yearFounded = 'Not a valid value for Year Founded';
    }else if (parseInt(formState.yearFounded) > currentYear) {
      errors.yearFounded = 'Year Founded cannot be in the future'
    } 
    else if (parseInt(formState.yearFounded) < 1900 || parseInt(formState.yearFounded) > 2100) {
      errors.yearFounded = 'Not a valid value for Year Founded';
    }
  }

  //Website Address
  if (formState.websiteAddress.trim() !== '') {
    if (!isValidLink(formState.websiteAddress)) {
      errors.websiteAddress = `Website Address must begin with 'http://' or 'https://'`;
    }
    if (formState.websiteAddress.trim().length > 200) {
      errors.websiteAddress = 'Website Address cannot exceed 200 characters';
    }
  }

  return errors;
};

const serverValidate = async (formState) => {
  let errors = {};

  const checkClubNameurl = `/UsasClub/query/dupcheck?lscOrgUnitId=${formState.lscId}&clubName=${formState.clubName}`;

  await ClubHttpHelper(checkClubNameurl, 'GET')
    .then((arrayData) => {
      if (!arrayData) {
        throw new Error(NO_DATA_MESSAGE);
      }
      else {
        if (arrayData.length > 0) {
          for (let i = 0; i < arrayData.length; i++) {
            if (arrayData[i].usasClubId !== formState.usasClubId) {
              errors.clubName = "This Club Name is already in use for the selected LSC. Please provide a different Club Name."
              break;
            }
          }
        }
      }
    })
    .catch((e) => {
    });

  const checkClubCodeurl = `/UsasClub/query/dupcheck?lscOrgUnitId=${formState.lscId}&clubCode=${formState.clubCode}`;

  await ClubHttpHelper(checkClubCodeurl, 'GET')
    .then((arrayData) => {
      if (!arrayData) {
        throw new Error(NO_DATA_MESSAGE);
      }
      else {
        if (arrayData.length > 0) {
          for (let i = 0; i < arrayData.length; i++) {
            if (arrayData[i].usasClubId !== formState.usasClubId) {
              errors.clubCode = "This Club Code is already in use for the selected LSC. Please provide a different Club Code."
              break;
            }
          }
        }
      }
    })
    .catch((e) => {
    });

  return errors;
};

const ClubRegistrationInfoValidation = async (formState) => {
  let errors = await localValidate(formState);
  if (!errors || Object.keys(errors).length === 0) {
    errors = await serverValidate(formState);
  }

  return errors ? errors : {};
}

export default ClubRegistrationInfoValidation;