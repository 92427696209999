/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Fragment } from 'react';

import HideIcon from '../../../../../common/components/icons/HideIcon';
import ShowIcon from '../../../../../common/components/icons/ShowIcon';
import EditIcon from '../../../../../common/components/icons/EditIcon';
import DeleteIcon from '../../../../../common/components/icons/DeleteIcon';

import { formatPhoneNumber } from '../../../../../common/utils/validation';

import global from '../../../../../common/components/GlobalStyle.module.css';
import { PRIMARY_CONTACT_ORG_ROLE_NAME } from '../../utils/LearnToSwimConstants';

const DetailTableRow = ({ role, personId, onDeleteRoleClicked }) => (
  <div className={global.SmallTableRow}>
    <div className={global.SmallTableDetailRowHead}><span className='hidden-xs'>{role.orgRoleName || ''}</span>&nbsp;
      {role.orgRoleName !== PRIMARY_CONTACT_ORG_ROLE_NAME &&
        <button
          className={global.IconButton}
          type="button"
          onClick={() => onDeleteRoleClicked(personId, role.personOrgRoleDurationId)}>
          <DeleteIcon />
        </button>
      }
    </div>
    <div className={global.SmallTableRowInfo}>
      <div className='row'>
        <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3 visible-xs'].join(' ')}>Role</div>
        <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3 visible-xs'].join(' ')}>{role.orgRoleName || <span>&nbsp;</span>}</div>
      </div>
    </div>
  </div>
);

const DetailTable = ({ personId, personOrgRole, onDeleteRoleClicked }) => (
  <div className={global.SmallTableDetailTable}>
    {Array.isArray(personOrgRole) && personOrgRole.map((role, i) =>
      <DetailTableRow key={i} personId={personId} role={role} onDeleteRoleClicked={onDeleteRoleClicked} />)
    }
  </div>
);

const TableRow = ({ staff, expandedId, onEditClicked, onDeleteClicked, onDeleteRoleClicked, onExpand }) => {
  return (
    <Fragment>
      <div className={global.SmallTableRow}>
        <div className={global.SmallTableRowHead}><span className='hidden-xs'>{staff.firstName} {staff.lastName}</span>&nbsp;
          <button className={global.IconButton}
            type="button"
            onClick={(e) => onExpand(e, staff.personId)}>
            {expandedId === staff.personId ? <HideIcon /> : <ShowIcon />}
          </button>
          {!(staff.personOrgRole.some((x) => x.orgRoleName === PRIMARY_CONTACT_ORG_ROLE_NAME)) &&
            <button
              className={global.IconButtonMargin}
              type="button"
              onClick={() => onDeleteClicked(staff.personId)}>
              <DeleteIcon />
            </button>
          }
          <button
            className={global.IconButtonMobileMargin}
            type="button"
            onClick={() => onEditClicked(staff)}>
            <EditIcon />
          </button>
        </div>
        <div className={global.SmallTableRowInfo}>
          <div className='row'>
            <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3 visible-xs'].join(' ')}>Staff Name</div>
            <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3 visible-xs'].join(' ')}>{`${staff.firstName || ''} ${staff.lastName || ''}`}</div>
            <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3'].join(' ')}>Member Id</div>
            <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3'].join(' ')}>{staff.memberId || <span>&nbsp;</span>}</div>
            <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3'].join(' ')}>Email</div>
            <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3'].join(' ')}>{staff.email || <span>&nbsp;</span>}</div>
            <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3'].join(' ')}>Phone Number</div>
            <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3'].join(' ')}>{staff.phoneNumber ? formatPhoneNumber(staff.phoneNumber) : <span>&nbsp;</span>}</div>
          </div>
        </div>
      </div>
      {Array.isArray(staff.personOrgRole) && staff.personOrgRole.length > 0 && expandedId === staff.personId &&
        <DetailTable personId={staff.personId} personOrgRole={staff.personOrgRole} onDeleteRoleClicked={onDeleteRoleClicked} />
      }
    </Fragment>
  );
};

const ProviderStaffGridSmall = ({ data, isLoading, expandedId, onEditClicked, onDeleteClicked, onDeleteRoleClicked, onExpand }) => (
  <div className={[global.SmallTable, 'hidden-md hidden-lg'].join(' ')}>
    {isLoading === true
      ? (
        <div className={global.SmallTableRow}>
          <div className={global.SmallTableRowHead}>&nbsp;</div>
          <div className={global.SmallTableRowInfo}>
            <div className={global.SmallTableRowLabels}>Loading...</div>
          </div>
        </div>
      )
      : data.length > 0
        ? data.map((staff, i) => <TableRow key={i} staff={staff} expandedId={expandedId} onEditClicked={onEditClicked}
          onDeleteClicked={onDeleteClicked} onDeleteRoleClicked={onDeleteRoleClicked} onExpand={onExpand} />)
        : (
          <div className={global.SmallTableRow}>
            <div className={global.SmallTableRowHead}>&nbsp;</div>
            <div className={global.SmallTableRowInfo}>
              <div className={global.SmallTableRowLabels}>No Staff</div>
            </div>
          </div>
        )}
  </div>
);

export default ProviderStaffGridSmall;