import React, { Fragment } from 'react';

import useClubRegistrationStaffDetail from './UseClubRegistrationStaffDetail';

import ClubRegistrationConstants from '../utils/ClubRegistrationConstants';

import ClubStaffTreeView from '../../../components/treeView/ClubStaffTreeView';

import LeftNav from '../../../../common/components/leftNav/LeftNav';
import Headings from '../../../../common/components/headings/Headings';
import PrimaryButton from '../../../../common/components/buttons/PrimaryButton';
import SecondaryButton from '../../../../common/components/buttons/SecondaryButton';
import PopUpModal from '../../../../common/components/dialogs/PopUpModal';
import Dropdown from '../../../../common/components/dropdowns/Dropdown';
import SearchMemberWithLscPopup from '../../../../common/components/searches/searchMember/SearchMemberWithLscPopup';

import { isValidMemberId } from '../../../../common/utils/validation';
import Constants from '../../../../common/utils/Constants';

import global from '../../../../common/components/GlobalStyle.module.css';

const ClubRegistrationStaffDetail = () => {
  const {
    usasClubStaffState,
    orgRoleStaffRoleHierarchyState,
    isSubmitting,
    isEdit,
    viewState,
    clubRegistrationNavState,
    formState,
    errorState,
    onFormValueChanged,
    onValueTextPairChanged,
    handleSubmit,
    onAddClicked,
    onCancelClicked,
    coachTypeCodeOptions,
    HEAD_COACH_ORG_ROLE_ID,
    ASSISTANT_COACH_ORG_ROLE_ID,
    AGE_GROUP_COACH_ORG_ROLE_ID,
    SENIOR_COACH_ORG_ROLE_ID,
    setFormData
  } = useClubRegistrationStaffDetail();

  return (
    <LeftNav title={ClubRegistrationConstants.LEFT_NAV_TITLE} state={clubRegistrationNavState} validateBeforeRedirect={false}>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>Club Staff</Headings.H3>
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12 usas-extra-top-margin">
          <Headings.H6>{isEdit === false ? 'Add Optional' : 'Edit'} Staff Member</Headings.H6>
        </div>
      </div>
      <form onSubmit={handleSubmit} noValidate>
        <div className="row usas-extra-top-margin usas-extra-bottom-margin">
          {isEdit === false ? (
            <div className="col-xs-12 col-sm-6 col-md-4">
              <SearchMemberWithLscPopup
                formState={formState}
                errorState={errorState}
                setFormData={setFormData}
                onFormValueChanged={onFormValueChanged} />
            </div>
          ) : (
            <div className={["col-xs-12", global.HeaderText].join(' ')}>
              <p><b>Member Name:</b>&nbsp;{viewState.firstName} {viewState.lastName}</p>
              <p><b>Member Id:</b>&nbsp;{viewState.memberId}</p>
              {formState.registrationRoles.length > 0 &&
                <p><b>Registration Role(s):</b>&nbsp;{viewState.registrationRoleString}</p>
              }
            </div>
          )}
          <div className="col-xs-12 col-sm-6 col-md-8">
            <ClubStaffTreeView
              label={formState.registrationRoles.length > 0 ? "Staff Role(s)" : "Staff Role(s)*"}
              name="personOrgRole"
              value={formState.personOrgRole}
              error={errorState.personOrgRole}
              message={errorState.personOrgRole}
              singleSelect={false}
              onChange={(value) => { onFormValueChanged('personOrgRole', value) }}
            />
          </div>
          {formState.personOrgRole.find(x => (x.id === HEAD_COACH_ORG_ROLE_ID || x.id === ASSISTANT_COACH_ORG_ROLE_ID || x.id === AGE_GROUP_COACH_ORG_ROLE_ID || x.id === SENIOR_COACH_ORG_ROLE_ID)) !== undefined ?
            <div className="col-xs-12 col-sm-6 col-md-4">
              <Dropdown
                label="Full Time / Part Time Coach*"
                name="coachTypeCode"
                options={coachTypeCodeOptions}
                value={formState.coachTypeCode}
                error={errorState.coachTypeCode}
                message={errorState.coachTypeCode}
                showEmptySelection={false}
                isLoading={false}
                onChange={(newValue, newValueLabel, e) => {
                  e && e.target && e.target.value &&
                    onValueTextPairChanged(newValue, 'coachTypeCode', newValueLabel, 'coachTypeCodeName');
                }} />
            </div>
            : <Fragment />
          }
        </div>
        {errorState.apiError &&
          <div className="row">
            <div className="col-xs-12 usas-extra-top-margin">
              <p className={global.ErrorMessage}>{errorState.apiError}</p>
            </div>
          </div>
        }
        {errorState.uniqueRoleError &&
          <div className="row">
            <div className="col-xs-12 usas-extra-top-margin">
              <p className={global.ErrorMessage}>{errorState.uniqueRoleError}</p>
            </div>
          </div>
        }
        {errorState.conflictingRolesError &&
          <div className="row">
            <div className="col-xs-12 usas-extra-top-margin">
              <p className={global.ErrorMessage}>{errorState.conflictingRolesError}</p>
            </div>
          </div>
        }
        <div className="row usas-extra-top-margin usas-extra-bottom-margin">
          <div className="col-xs-12 usas-extra-top-margin">
            <PrimaryButton type="button" onClick={onAddClicked}>Add Staff</PrimaryButton>&nbsp;
            <SecondaryButton type="button" onClick={onCancelClicked}>Back</SecondaryButton>
          </div>
        </div>
      </form>
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={orgRoleStaffRoleHierarchyState.isArrayLoading || (isSubmitting && formState.memberId.trim().length > 0 && isValidMemberId(formState.memberId.trim()))} />
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.SAVING_MSG}
        displayPopUp={usasClubStaffState.isSaving} />
    </LeftNav >
  );
};

export default ClubRegistrationStaffDetail;