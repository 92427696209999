import React from 'react';

import usePreCompRegistrationLocationFacilities from './UsePreCompRegistrationLocationFacilities';

import PreCompRegistrationConstants from '../utils/PreCompRegistrationConstants';

import PreCompLocationFacilityGrid from '../../preComp/components/grids/PreCompLocationFacilityGrid';

import Headings from '../../../../common/components/headings/Headings';
import PrimaryButton from '../../../../common/components/buttons/PrimaryButton';
import PopUpModal from '../../../../common/components/dialogs/PopUpModal';
import SecondaryButton from '../../../../common/components/buttons/SecondaryButton';
import LeftNav from '../../../../common/components/leftNav/LeftNav';
import ActionIntraPageButton from '../../../../common/components/buttons/ActionIntraPageButton';

import global from '../../../../common/components/GlobalStyle.module.css';

const PreCompRegistrationLocationFacilities = () => {
  const {
    locationRegistrationNavState,
    gridState,
    modalState,
    pageErrorMessageState,
    onAddFacility,
    onEditFacility,
    onDeleteFacility,
    onModalCancelClicked,
    onModalDeleteClicked,
    onContinueClicked,
    onBackClicked
  } = usePreCompRegistrationLocationFacilities();

  return (
    <LeftNav title={PreCompRegistrationConstants.LEFT_NAV_TITLE} state={locationRegistrationNavState} validateBeforeRedirect={false}>
      <Headings.H3>Location Facilities</Headings.H3>
      <div className="row">
        <div className="col-xs-12">
          <p><b>*At least one linked Facility is required</b></p>
        </div>
      </div>
      <div className="row usas-extra-top-margin">
        <div className="col-xs-12">
          <ActionIntraPageButton onClick={onAddFacility}>Add Facility</ActionIntraPageButton>
        </div>
      </div>
      <div className="row usas-extra-top-margin">
        <div className="col-xs-12">
          <PreCompLocationFacilityGrid
            data={gridState}
            isLoading={false}
            onEdit={onEditFacility}
            onDelete={onDeleteFacility}
            onEditPool={undefined}
            onDeletePool={undefined} />
        </div>
      </div>
      {pageErrorMessageState &&
        <div className="row">
          <div className="col-xs-12">
            <p className={global.ErrorMessage}>{pageErrorMessageState}</p>
          </div>
        </div>}
      <div className="row usas-extra-top-margin usas-extra-bottom-margin">
        <div className="col-xs-12 usas-extra-top-margin">
          <PrimaryButton type="button" onClick={onContinueClicked}>Continue</PrimaryButton>&nbsp;
          <SecondaryButton type="button" onClick={onBackClicked}>Back</SecondaryButton>
        </div>
      </div>
      <PopUpModal
        widthPct={90}
        maxWidth={720}
        title={"Unlink Facility?"}
        displayPopUp={modalState.displayPopUp}
        onModalCanceled={onModalCancelClicked}>
        <div className="row">
          <div className="col-xs-12">
            <p><b>Facility Name:</b> {modalState.facilityName}</p>
            <p><b>Facility Location:</b> {modalState.facilityCity}, {modalState.facilityStateCode} {modalState.facilityPostalCode}</p>
          </div>
        </div>
        <div className="row usas-extra-top-margin">
          <div className="col-xs-12">
            <PrimaryButton type="button" onClick={() => onModalDeleteClicked(modalState.targetIndex)}>
              Unlink
            </PrimaryButton>&nbsp;
            <SecondaryButton type="button" onClick={onModalCancelClicked}>Cancel</SecondaryButton>
          </div>
        </div>
      </PopUpModal>
    </LeftNav>
  );
};

export default PreCompRegistrationLocationFacilities;