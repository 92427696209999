import { BASIC_INITIAL_STATE } from '../../../utils/HttpHelper';

import getBPMeetClassificationsByOrgUnitId from './GetBPMeetClassificationsByOrgUnitIdData';
//import getBPMeetClassifications from './GetBPMeetClassificationsData';

const INITIAL_STATE = {
  ...BASIC_INITIAL_STATE,
  orgUnitId: '',
  treeData: []
};

const BPMeetClassificationData = {
  INITIAL_STATE, getBPMeetClassificationsByOrgUnitId //, getMeetClassifications
};

export default BPMeetClassificationData;