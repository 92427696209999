import { useContext } from 'react';

import SearchFacilityData from './SearchFacilityData';

import { SearchFacilityStateContext } from './SearchFacilityContextProvider';

const useSearchFacilityData = () => {
  const [searchFacilityState, setSearchFacilityState] = useContext(SearchFacilityStateContext);

  const resetSearchFacilityState = () => {
    setSearchFacilityState(SearchFacilityData.INITIAL_STATE);
  }

  return {
    searchFacilityState,
    setSearchFacilityState,
    resetSearchFacilityState
  };
};

export default useSearchFacilityData;