import React from 'react';

import usePreCompRegistrationLocationClubsLinkSearch from './UsePreCompRegistrationLocationClubsLinkSearch';

import PreCompRegistrationConstants from '../utils/PreCompRegistrationConstants';

import useSearchClub from '../../../../common/components/searches/searchClub/UseSearchClub';
import SearchClub from '../../../../common/components/searches/searchClub/SearchClub';
import PopUpModal from '../../../../common/components/dialogs/PopUpModal';
import LeftNav from '../../../../common/components/leftNav/LeftNav';

const PreCompRegistrationLocationClubsLinkSearch = () => {
  const {
    locationRegistrationNavState,
    modalState,
    onLinkClub,
    onCancelClicked,
    onModalCancel
  } = usePreCompRegistrationLocationClubsLinkSearch();
  const { searchClubState } = useSearchClub();

  return (
    <LeftNav title={PreCompRegistrationConstants.LEFT_NAV_TITLE} state={locationRegistrationNavState} validateBeforeRedirect={false}>
      <SearchClub
        pageTitle={"Club Search"}
        onEdit={onLinkClub}
        searchClubState={searchClubState}
        onCancelClicked={onCancelClicked}
        sendObjOnEdit={true} />
      <PopUpModal
        widthPct={90}
        maxWidth={720}
        title="Duplicate Club Error"
        displayPopUp={modalState.displayModal}
        onModalCanceled={onModalCancel}>
        <div className="row">
          <div className="col-xs-12">
            <p>{modalState.clubName} ({modalState.clubLocation}) is already linked to this location</p>
          </div>
        </div>
      </PopUpModal>
    </LeftNav>
  );
};

export default PreCompRegistrationLocationClubsLinkSearch;