import React, { Fragment } from 'react';

import useAddEditFacilityPoolConfiguration from './UseAddEditFacilityPoolConfiguration';

import FacilityPoolConfigurationForm from './components/FacilityPoolConfigurationForm';

import PrimaryButton from '../../../buttons/PrimaryButton';
import SecondaryButton from '../../../buttons/SecondaryButton';
import PopUpModal from '../../../dialogs/PopUpModal';
import Headings from '../../../headings/Headings';
import Constants from '../../../../utils/Constants';

const AddEditFacilityPoolConfiguration = () => {
  const {
    facilityState,
    courseState,
    formState,
    errorState,
    handleSubmit,
    onFormValueChanged,
    onValueTextPairChanged,
    onSaveClicked,
    onCancelClicked
  } = useAddEditFacilityPoolConfiguration();

  return (
    <Fragment>
      <form onSubmit={handleSubmit} noValidate>
        <div className="row">
          <div className="col-xs-12">
            <Headings.H3>{formState.poolConfigurationIndex >= 0 ? 'Edit' : 'Add'} Pool Configuration</Headings.H3>
          </div>
        </div>
        <FacilityPoolConfigurationForm
          facilityState={facilityState}
          formState={formState}
          errorState={errorState}
          onFormValueChanged={onFormValueChanged}
          onValueTextPairChanged={onValueTextPairChanged} />
        <div className="row usas-extra-top-margin usas-extra-bottom-margin">
          <div className="col-xs-12 usas-extra-top-margin">
            <PrimaryButton type="button" onClick={onSaveClicked}>Save</PrimaryButton>&nbsp;
            <SecondaryButton type="button" onClick={onCancelClicked}>Back</SecondaryButton>
          </div>
        </div>
      </form>
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={courseState.isLoading} />
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.SAVING_MSG}
        displayPopUp={facilityState.isSaving} />
    </Fragment>
  );
};

export default AddEditFacilityPoolConfiguration;