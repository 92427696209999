import React, { Fragment } from 'react';

import usePreCompRegistrationWelcome from './UsePreCompRegistrationWelcome';

import Headings from '../../../../common/components/headings/Headings';

import style from './PreCompRegistrationWelcome.module.css';
import PrimaryButton from '../../../../common/components/buttons/PrimaryButton';

const PreCompRegistrationWelcome = () => {
  const {
    isLoggedIn,
    onLoginClicked
  } = usePreCompRegistrationWelcome();

  return (
    <div className='row'>
      <div className="col-xs-12">
        <Headings.H2 excludeBar={true} className={style.CenterAlign}>USA Swimming Pre Competitive Registration</Headings.H2>
        <hr className="usas-bar-turmeric" />
        <Headings.H3 excludeBar={true} className={style.CenterAlign}>Welcome</Headings.H3>
        {isLoggedIn === false &&
          < Fragment >
            <div className={['row', style.BottomMargin].join('')}>
              <div className={style.CenterAlign}>
                You are currently not logged in to the USA Swimming site. Click the 'Login' button below
                to be redirected to a screen that will allow you to login or create a login. After you have logged in,
                you will be redirected back to this page and allowed to continue the Pre Competitive Registration process.
              </div>
            </div>
            <div className='row usas-extra-top-margin usas-extra-bottom-margin'></div>
            <div className='row usas-extra-top-margin'>
              <div className={style.CenterAlign}>
                <PrimaryButton onClick={onLoginClicked}>Login</PrimaryButton>
              </div>
            </div>
          </Fragment>
        }
      </div>
    </div>
  );
};

export default PreCompRegistrationWelcome;