import useLearnToSwimFacilityContactForm from './UseLearnToSwimFacilityContactForm';

import Input from '../../../../../common/components/inputs/Input';
import ReadOnly from '../../../../../common/components/readOnly/ReadOnly';
import PrimaryButton from '../../../../../common/components/buttons/PrimaryButton';
import SecondaryButton from '../../../../../common/components/buttons/SecondaryButton';
import InternationalPhoneInput from '../../../../../common/components/inputs/InternationalPhoneInput';

import global from '../../../../../common/components/GlobalStyle.module.css';

const LearnToSwimFacilityContactForm = ({ submitButtonText = 'Save', secondaryButtonText = 'Back', orgUnitFacilityObj,
  onSubmitFormCallback, onSecondaryButtonClicked }) => {
  const {
    formState,
    errorState,
    onFormValueChanged,
    handleSubmit,
    getStateNameById
  } = useLearnToSwimFacilityContactForm(onSubmitFormCallback, orgUnitFacilityObj);

  return (
    <form onSubmit={handleSubmit} noValidate>
      <div className="row">
        <div className="col-xs-12 col-sm-6 col-md-4">
          <ReadOnly
            label="Facility Name"
            name="facilityName"
            value={orgUnitFacilityObj.facility?.facilityName || ''} />
        </div>
        <div className="col-xs-12 col-sm-6 col-md-4">
          <ReadOnly
            label="Address Line 1"
            name="address1"
            value={orgUnitFacilityObj.facility?.address1 || ''} />
        </div>
        <div className="col-xs-12 col-sm-6 col-md-4">
          <ReadOnly
            label="Address Line 2"
            name="address2"
            value={orgUnitFacilityObj.facility?.address2 || ''} />
        </div>
        <div className="col-xs-12 col-sm-6 col-md-4">
          <ReadOnly
            label="City"
            name="city"
            value={orgUnitFacilityObj.facility?.city || ''} />
        </div>
        <div className="col-xs-12 col-sm-6 col-md-4">
          <ReadOnly
            label="State"
            name="stateCode"
            value={orgUnitFacilityObj.facility?.stateCode ? getStateNameById(orgUnitFacilityObj.facility.stateCode) : ''} />
        </div>
        <div className="col-xs-12 col-sm-6 col-md-4">
          <ReadOnly
            label="Zip Code"
            name="postalCode"
            value={orgUnitFacilityObj.facility?.postalCode || ''} />
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12 col-sm-6 col-md-4">
          <Input
            label="Facility Primary Contact Name"
            name="contactName"
            value={formState.contactName}
            error={errorState.contactName}
            message={errorState.contactName}
            onChange={(value) => { onFormValueChanged('contactName', value) }} />
        </div>
        <div className="col-xs-12 col-sm-6 col-md-4">
          <Input
            label="Facility Email"
            name="contactEmailAddress"
            value={formState?.contactEmailAddress}
            error={errorState.contactEmailAddress}
            message={errorState.contactEmailAddress}
            onChange={(value) => { onFormValueChanged('contactEmailAddress', value) }} />
        </div>
        <div className="col-xs-12 col-sm-6 col-md-4">
          <InternationalPhoneInput
            label="Facility Phone Number"
            name="contactPhoneNumber"
            value={formState.contactPhoneNumber}
            error={errorState.contactPhoneNumber}
            message={errorState.contactPhoneNumber}
            onChange={(value) => { onFormValueChanged('contactPhoneNumber', value) }} />
        </div>
        <div className="col-xs-12 col-sm-6 col-md-4">
          <Input
            label="Learn To Swim Primary Contact Name for Facility"
            name="clubFacilityContactName"
            value={formState.clubFacilityContactName}
            error={errorState.clubFacilityContactName}
            message={errorState.clubFacilityContactName}
            onChange={(value) => { onFormValueChanged("clubFacilityContactName", value); }} />
        </div>
        <div className="col-xs-12 col-sm-6 col-md-4">
          <Input
            label="Learn To Swim Primary Contact Email"
            name="clubFacilityContactEmailAddress"
            value={formState.clubFacilityContactEmailAddress}
            error={errorState.clubFacilityContactEmailAddress}
            message={errorState.clubFacilityContactEmailAddress}
            onChange={(value) => { onFormValueChanged("clubFacilityContactEmailAddress", value); }} />
        </div>
        <div className="col-xs-12 col-sm-6 col-md-4">
          <InternationalPhoneInput
            label="Learn To Swim Primary Contact Phone Number"
            name="clubFacilityContactPhoneNumber"
            value={formState.clubFacilityContactPhoneNumber}
            error={errorState.clubFacilityContactPhoneNumber}
            message={errorState.clubFacilityContactPhoneNumber}
            onChange={(value) => { onFormValueChanged("clubFacilityContactPhoneNumber", value); }} />
        </div>
      </div>
      {errorState.errorOnSubmitAction &&
        <div className="row">
          <div className="col-xs-12">
            <p className={global.ErrorMessage}>{errorState.errorOnSubmitAction || ''}</p>
          </div>
        </div>
      }
      <div className="row">
        <div className="col-xs-12 usas-extra-top-margin usas-extra-bottom-margin">
          <PrimaryButton type="submit">{submitButtonText}</PrimaryButton>&nbsp;
          <SecondaryButton type="button" onClick={onSecondaryButtonClicked}>{secondaryButtonText}</SecondaryButton>
        </div>
      </div>
    </form>
  );
};

export default LearnToSwimFacilityContactForm;