import { useEffect, useState } from 'react';
import { useNavigate } from '../../../../common/wrappers/ReactRouterDom';

import NavLinks from '../NavLinks';

import ClubRegistrationConstants from '../utils/ClubRegistrationConstants';

import useClubRegistrationNavData from '../../../state/clubRegistration/leftNavigation/UseClubRegistrationNavData';
import useClubRegistrationNewData from '../../../state/clubRegistrationNew/UseClubRegistrationNewData';
import useUsasClubFilesData from '../../../state/clubFiles/UseUsasClubFilesData';

import Constants from '../../../../common/utils/Constants';

const SAVING_ERROR_MSG = 'The last action is still saving. Please try again.';
const FILE_TYPE_ERROR_MSG = 'File type not permitted. Please provide a (*.pdf), (*.doc), or (*.docx) file.';
const REQUIRED_ERROR_MSG = 'An uploaded emergency plan is required';
const UNABLE_TO_CONTINUE_ERROR_MSG = 'Unable to continue to Submit. Please complete all other steps in order to continue.';
const FILE_MODAL_TITLE = 'Remove Uploaded File?';

const useClubRegistrationEmergencyPlan = () => {
  const navigate = useNavigate();
  const [state, setState] = useState({ submitButton: '', tryRedirect: false, error: '' });
  const [submitPrequisitesState, setSubmitPrequisitesState] = useState({ areSubmitPrerequisitesDone: false });
  const [fileState, setFileState] = useState({ templateFileUrl: '', templateFileName: '' });
  const [modalState, setModalState] = useState(getInitialModalState());
  const [removeFileModalState, setRemoveFileModalState] = useState(getInitialRemoveFileModalState());
  const { clubRegistrationNavState, setClubRegistrationNavState } = useClubRegistrationNavData();
  const { clubRegistrationState, putUsasClub } = useClubRegistrationNewData();
  const { usasClubFilesState, postUsasClubFiles, getFileNameFromSavedUploadUrl, getFileNameFromUrl, GET_DOWNLOAD_TEMPLATE_PATH,
    GET_USAS_CLUB_FILES_PATH } = useUsasClubFilesData();

  const editClubObj = (name) => {
    let clubCopy = JSON.parse(JSON.stringify(clubRegistrationState.objData));

    if (clubCopy.usasClubAcknowledgement.length === 1) {
      clubCopy.usasClubAcknowledgement = [{
        ...clubCopy.usasClubAcknowledgement[0],
        emergencyPlanUrl: name
      }]
    }
    else if (clubCopy.usasClubAcknowledgement.length === 0) {
      clubCopy.usasClubAcknowledgement = [{
        usasClubId: clubCopy.usasClubId,
        emergencyPlanUrl: name,
      }]
    }

    return clubCopy;
  }

  const onUploadTemplateClicked = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    };
    if (clubRegistrationState.isSaving === true || usasClubFilesState.isSaving === true) {
      setState({ ...state, submitButton: '', tryRedirect: false, error: SAVING_ERROR_MSG });
    }
    else {
      setState({ ...state, submitButton: '', tryRedirect: false, error: '' });
      if (e.target.files[0]) {
        const file = e.target.files[0];
        const fileType = e.target.files[0].type;
        if (fileType === Constants.FILE_TYPE_PDF || fileType === Constants.FILE_TYPE_DOC ||
          fileType === Constants.FILE_TYPE_DOCX) {
          postUsasClubFiles(file);
          setState({ ...state, submitButton: '', tryRedirect: false, error: '' });
        }
        else {
          setState({ ...state, submitButton: '', tryRedirect: false, error: FILE_TYPE_ERROR_MSG });
        }
      }
    }
  }

  const onRemoveTemplateClicked = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    };
    setRemoveFileModalState({ ...getInitialRemoveFileModalState() });
    if (clubRegistrationState.isSaving === true || usasClubFilesState.isSaving === true) {
      setState({ ...state, submitButton: '', tryRedirect: false, error: SAVING_ERROR_MSG });
    }
    else {
      const navIndex = clubRegistrationNavState.findIndex(x => x.name === ClubRegistrationConstants.EMERGENCY_PLAN)
      clubRegistrationNavState[navIndex] =
      {
        ...clubRegistrationNavState[navIndex],
        isCurrent: true,
        isComplete: false,
        isVisited: true,
        arePrerequisitesDone: true
      }
      const submitNavIndex = clubRegistrationNavState.findIndex(x => x.name === ClubRegistrationConstants.SUBMIT)
      clubRegistrationNavState[submitNavIndex] =
      {
        ...clubRegistrationNavState[submitNavIndex],
        isCurrent: false,
        isComplete: false,
        isVisited: false,
        arePrerequisitesDone: false
      }
      setSubmitPrequisitesState({ ...submitPrequisitesState, areSubmitPrerequisitesDone: false });
      setClubRegistrationNavState(clubRegistrationNavState);
      setFileState({ ...fileState, templateFileUrl: '', templateFileName: '' });
      setState({ ...state, submitButton: '', tryRedirect: false, error: '' })
      const name = null;
      putUsasClub(clubRegistrationState.objData.usasClubId, editClubObj(name));
    }
  }

  const onContinueClicked = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    };
    if (clubRegistrationState.objData?.usasClubAcknowledgement?.length > 0) {
      if (clubRegistrationState.objData.usasClubAcknowledgement.length === 1) {
        if (clubRegistrationState.objData?.usasClubAcknowledgement[0].emergencyPlanUrl !== null &&
          clubRegistrationState.objData?.usasClubAcknowledgement[0].emergencyPlanUrl.includes("Bad Request") === false
        ) {
          setState({ ...state, tryRedirect: true, submitButton: ClubRegistrationConstants.CONTINUE, error: '' });
        }
        else {
          setState({ ...state, submitButton: ClubRegistrationConstants.CONTINUE, error: REQUIRED_ERROR_MSG });
        }
      }
    }
    else {
      setState({ ...state, submitButton: ClubRegistrationConstants.CONTINUE, error: REQUIRED_ERROR_MSG });
    }
  }

  const onSaveAndExitClicked = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    if (clubRegistrationState.objData?.usasClubAcknowledgement?.length > 0) {
      if (clubRegistrationState.objData.usasClubAcknowledgement.length === 1) {
        if (clubRegistrationState.objData?.usasClubAcknowledgement[0].emergencyPlanUrl !== null &&
          clubRegistrationState.objData?.usasClubAcknowledgement[0].emergencyPlanUrl.includes("Bad Request") === false
        ) {
          setState({ ...state, submitButton: ClubRegistrationConstants.EXIT, error: '' });
        }
        else {
          setState({ ...state, submitButton: ClubRegistrationConstants.EXIT, error: REQUIRED_ERROR_MSG });
        }
      }
    }
    else {
      setState({ ...state, submitButton: ClubRegistrationConstants.EXIT, error: REQUIRED_ERROR_MSG });
    }
  }

  const onBackClicked = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    if (clubRegistrationState.objData?.usasClubAcknowledgement?.length > 0) {
      if (clubRegistrationState.objData.usasClubAcknowledgement.length === 1) {
        if (clubRegistrationState.objData?.usasClubAcknowledgement[0].emergencyPlanUrl !== null && 
          clubRegistrationState.objData?.usasClubAcknowledgement[0].emergencyPlanUrl.includes("Bad Request") === false
        ) {
          setState({ ...state, tryRedirect: true, submitButton: ClubRegistrationConstants.BACK, error: '' });
        }
        else {
          setState({ ...state, submitButton: ClubRegistrationConstants.BACK, error: REQUIRED_ERROR_MSG });
        }
      }
    }
    else {
      setState({ ...state, submitButton: ClubRegistrationConstants.BACK, error: REQUIRED_ERROR_MSG });
    }
  }

  const onOpenModal = () => {
    if (state.error !== '') {
      setModalState({ ...modalState, displayPopUp: true });
    }
    else {
      setModalState({ ...modalState, modalTitle: ClubRegistrationConstants.EXIT_CONFIRMATION, displayPopUp: true });
    }
  }

  const onModalContinue = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    setState({ ...state, tryRedirect: true });
    setModalState({ ...getInitialModalState() });
  }

  const onModalCanceled = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    setModalState({ ...getInitialModalState() });
  }

  const onOpenRemoveFileModalClicked = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    setRemoveFileModalState({ ...removeFileModalState, fileName: fileState.templateFileName, displayPopUp: true });
  }

  const onRemoveFileModalCanceled = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    setRemoveFileModalState({ ...getInitialRemoveFileModalState() });
  }

  const updateNavState = () => {
    for (let i = 0; i < clubRegistrationNavState.length; i++) {
      clubRegistrationNavState[i].isCurrent = false
    }
    const navIndex = clubRegistrationNavState.findIndex(x => x.name === ClubRegistrationConstants.EMERGENCY_PLAN)
    if (clubRegistrationState.objData?.usasClubAcknowledgement?.length > 0) {
      if (clubRegistrationState.objData.usasClubAcknowledgement.length === 1) {
        if (clubRegistrationState.objData.usasClubAcknowledgement[0].emergencyPlanUrl !== null &&
          clubRegistrationState.objData?.usasClubAcknowledgement[0].emergencyPlanUrl.includes("Bad Request") === false
        ) {
          clubRegistrationNavState[navIndex] =
          {
            ...clubRegistrationNavState[navIndex],
            isCurrent: true,
            isComplete: true,
            isVisited: true,
            arePrerequisitesDone: true
          }
        }
        else {
          clubRegistrationNavState[navIndex] =
          {
            ...clubRegistrationNavState[navIndex],
            isCurrent: true,
            isComplete: false,
            isVisited: true,
            arePrerequisitesDone: true
          }
        }
      }
    }
    else {
      clubRegistrationNavState[navIndex] =
      {
        ...clubRegistrationNavState[navIndex],
        isCurrent: true,
        isComplete: false,
        isVisited: true,
        arePrerequisitesDone: true
      }
    }
    //Submit
    let areSubmitPrerequisitesDone = true;
    let isSubmitComplete = false;
    for (let i = 0; i < clubRegistrationNavState.length; i++) {
      if (clubRegistrationNavState[i].name !== ClubRegistrationConstants.SUBMIT
        && clubRegistrationNavState[i].isComplete === false) {
        areSubmitPrerequisitesDone = false;
      }
      if (clubRegistrationNavState[i].name === ClubRegistrationConstants.SUBMIT) {
        if (clubRegistrationState.objData.status === ClubRegistrationConstants.PENDING_STATUS ||
          clubRegistrationState.objData.status === ClubRegistrationConstants.APPROVED_STATUS) {
          isSubmitComplete = true
        }
      }
    }
    const submitNavIndex = clubRegistrationNavState.findIndex(x => x.name === ClubRegistrationConstants.SUBMIT)
    clubRegistrationNavState[submitNavIndex] =
    {
      ...clubRegistrationNavState[submitNavIndex],
      isCurrent: false,
      isComplete: isSubmitComplete,
      isVisited: areSubmitPrerequisitesDone === false ? false : clubRegistrationNavState[submitNavIndex].isVisited,
      arePrerequisitesDone: areSubmitPrerequisitesDone
    }
    setSubmitPrequisitesState({ ...state, areSubmitPrerequisitesDone: areSubmitPrerequisitesDone });
    setClubRegistrationNavState(clubRegistrationNavState);
  }

  useEffect(() => {
    if (clubRegistrationState.isObjLoaded === false) {
      navigate(NavLinks.CLUB_REGISTRATION_ENTRY_NEW);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const navIndex = clubRegistrationNavState.findIndex(x => x.name === ClubRegistrationConstants.EMERGENCY_PLAN)
    if (clubRegistrationNavState[navIndex].isCurrent === false) {
      updateNavState();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clubRegistrationNavState])

  useEffect(() => {
    const submitNavIndex = clubRegistrationNavState.findIndex(x => x.name === ClubRegistrationConstants.SUBMIT)
    if (clubRegistrationNavState[submitNavIndex].arePrerequisitesDone === false &&
      submitPrequisitesState.areSubmitPrerequisitesDone === true) {
      updateNavState();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clubRegistrationNavState, submitPrequisitesState.areSubmitPrerequisitesDone])

  useEffect(() => {
    if (Object.keys(clubRegistrationState.objData).length > 0) {
      updateNavState();
      if (clubRegistrationState.objData?.usasClubAcknowledgement?.length === 1) {

        setFileState({
          ...fileState,
          templateFileUrl: clubRegistrationState.objData.usasClubAcknowledgement[0].emergencyPlanUrl !== null ?
            clubRegistrationState.objData.usasClubAcknowledgement[0].emergencyPlanUrl : '',
          templateFileName: clubRegistrationState.objData.usasClubAcknowledgement[0].emergencyPlanUrl !== null ?
            getFileNameFromUrl(clubRegistrationState.objData.usasClubAcknowledgement[0].emergencyPlanUrl) : ''
        })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clubRegistrationState.objData])

  useEffect(() => {
    if (usasClubFilesState.isSaved === true && Object.keys(usasClubFilesState.objData).length > 0) {
      setFileState({ ...fileState, templateFileUrl: usasClubFilesState.objData.uploadUrl, templateFileName: getFileNameFromSavedUploadUrl() });
      putUsasClub(clubRegistrationState.objData.usasClubId, editClubObj(usasClubFilesState.objData.uploadUrl));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [usasClubFilesState])

  useEffect(() => {
    //Handle validation modal and navigation
    if (state.submitButton !== '' && clubRegistrationState.isObjLoaded === true) {
      if (clubRegistrationState.isSaving === true || usasClubFilesState.isSaving === true) {
        setState({ ...state, submitButton: '', tryRedirect: false, error: SAVING_ERROR_MSG });
      }
      else if (state.tryRedirect === false) {
        if (state.error !== '' || state.submitButton === ClubRegistrationConstants.EXIT) {
          onOpenModal();
        }
      }
      else if (state.tryRedirect === true) {
        //If no error (docs not uploaded) or this is a renew (length > 1) set isComplete to true
        if (state.error === '' 
        //|| clubRegistrationState.objData?.clubRegistrations?.length > 1
        ) {
          const navIndex = clubRegistrationNavState.findIndex(x => x.name === ClubRegistrationConstants.EMERGENCY_PLAN)
          clubRegistrationNavState[navIndex] =
          {
            ...clubRegistrationNavState[navIndex],
            isCurrent: false,
            isComplete: true,
            isVisited: true,
            arePrerequisitesDone: true
          }
          //Submit
          let areSubmitPrerequisitesDone = true;
          let isSubmitComplete = false;
          for (let i = 0; i < clubRegistrationNavState.length; i++) {
            if (clubRegistrationNavState[i].name !== ClubRegistrationConstants.SUBMIT
              && clubRegistrationNavState[i].isComplete === false) {
              areSubmitPrerequisitesDone = false;
            }
            if (clubRegistrationNavState[i].name === ClubRegistrationConstants.SUBMIT) {
              if (clubRegistrationState.objData.status === ClubRegistrationConstants.PENDING_STATUS ||
                clubRegistrationState.objData.status === ClubRegistrationConstants.APPROVED_STATUS) {
                isSubmitComplete = true
              }
            }
          }
          const submitNavIndex = clubRegistrationNavState.findIndex(x => x.name === ClubRegistrationConstants.SUBMIT)
          clubRegistrationNavState[submitNavIndex] =
          {
            ...clubRegistrationNavState[submitNavIndex],
            isCurrent: false,
            isComplete: isSubmitComplete,
            isVisited: areSubmitPrerequisitesDone === false ? false : clubRegistrationNavState[submitNavIndex].isVisited,
            arePrerequisitesDone: areSubmitPrerequisitesDone
          }
          setClubRegistrationNavState(clubRegistrationNavState);
        }


        if (state.submitButton === ClubRegistrationConstants.CONTINUE) {
          let areSubmitPrerequisitesDone = true;
          for (let i = 0; i < clubRegistrationNavState.length; i++) {
            if (clubRegistrationNavState[i].name !== ClubRegistrationConstants.SUBMIT
              && clubRegistrationNavState[i].isComplete === false) {
              areSubmitPrerequisitesDone = false;
            }
          }
          if (areSubmitPrerequisitesDone === true) {
            navigate(NavLinks.CLUB_REGISTRATION_SUBMIT);
          }
          else {
            setState({ ...state, submitButton: '', tryRedirect: false, error: UNABLE_TO_CONTINUE_ERROR_MSG });
          }
        }
        else if (state.submitButton === ClubRegistrationConstants.EXIT) {
          navigate(NavLinks.CLUB_REGISTRATION_ENTRY_NEW);
        }
        else if (state.submitButton === ClubRegistrationConstants.BACK) {
          navigate(NavLinks.CLUB_REGISTRATION_MISSION_STATEMENT_BUDGET);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state, clubRegistrationState.isObjLoaded])

  return {
    clubRegistrationNavState,
    clubRegistrationState,
    state,
    modalState,
    removeFileModalState,
    fileState,
    usasClubFilesState,
    onUploadTemplateClicked,
    onRemoveTemplateClicked,
    onOpenRemoveFileModalClicked,
    onRemoveFileModalCanceled,
    onModalContinue,
    onModalCanceled,
    onContinueClicked,
    onSaveAndExitClicked,
    onBackClicked,
    GET_DOWNLOAD_TEMPLATE_PATH,
    GET_USAS_CLUB_FILES_PATH
  }
}

export default useClubRegistrationEmergencyPlan;

function getInitialModalState() {
  return {
    displayPopUp: false,
    modalTitle: ClubRegistrationConstants.MISSING_REQUIRED_INFORMATION
  }
};

function getInitialRemoveFileModalState() {
  return {
    displayPopUp: false,
    fileName: '',
    modalTitle: FILE_MODAL_TITLE
  }
};