import { useEffect, useState } from 'react';
import { useNavigate, useLocation } from '../../../../../common/wrappers/ReactRouterDom';

import validate from './ClubGroupsAddRemoveMembersGridValidation';

import { editClubGroupAthletes } from '../utils/ClubGroupsUtils';
import ClubConstants from '../../utils/ClubConstants';

import NavLinks from '../../NavLinks';

import useClubGroupsData from '../../../../state/clubGroups/UseClubGroupsData';

import useGroupFieldNamesData from '../../../../../common/state/groupFieldNames/UseGroupFieldNamesData';
import useOrgGroupPropertiesData from '../../../../../common/state/orgGroupProperties/UseOrgGroupPropertiesData';

import useForm from '../../../../../common/utils/UseForm';
import ToIntIfInt from '../../../../../common/utils/ToIntIfInt';
import Constants from '../../../../../common/utils/Constants';

const competitionGenderOptions = [
  { id: Constants.DEFAULT_ID, name: "--", value: Constants.DEFAULT_ID },
  { name: "Male", id: "Male" },
  { name: "Female", id: "Female" }
];

const useClubGroupsAddRemoveMembersGrid = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { GROUP_CONSTANTS } = useGroupFieldNamesData();
  const { clubGroupState, getClubGroupDetails, getClubGroupMembersInfo, putClubGroup } = useClubGroupsData();
  const { orgGroupPropertiesState, getOrgGroupProperties } = useOrgGroupPropertiesData();
  const { formState, errorState, onFormValueChanged, handleSubmit
  } = useForm(getInitialFormState, submitFormCallback, validate);
  const [clubMembersState, setClubMembersState] = useState(getInitialClubMembersState);
  const [gridState, setGridState] = useState(getInitialGridState);
  const [displayPopupState, setDisplayPopupState] = useState(false);

  const onShowPopup = () => {
    if (gridState.membersAddedToGroup.length > 0 || gridState.membersRemovedFromGroup.length > 0) {
      setDisplayPopupState(true);
    }
    else {
      onCancelGoToClubGroups();
    }
  };

  const onClosePopup = () => {
    setDisplayPopupState(false);
  };

  const onCancelGoToClubGroups = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    navigate(NavLinks.CLUB_GROUPS);
  };

  const onSave = () => {
    if (clubGroupState.isSaving === false) {
      const newOrgGroupObj = JSON.parse(JSON.stringify(clubGroupState.objData));

      const groupTypeFieldId = orgGroupPropertiesState.arrayData.find(x => x.fieldName === GROUP_CONSTANTS.PERSON_GROUP_TYPE_FIELD_NAME_ATTENDEE_TYPE)?.groupTypeFieldId;

      const membersToGoIntoGroup = gridState.membersInClub.filter((athlete) => athlete.isInGroup === true);

      newOrgGroupObj.personOrgGroup = editClubGroupAthletes(gridState.membersInClub, membersToGoIntoGroup,
        newOrgGroupObj.personOrgGroup, newOrgGroupObj.orgGroupId, groupTypeFieldId, ClubConstants.ATHLETE_ROLE_NAME);

      putClubGroup(newOrgGroupObj.orgGroupId, newOrgGroupObj);
    }
  };

  const onCheckBoxChange = (membersId) => {
    const memberId = ToIntIfInt(membersId);
    const membersInClub = gridState.membersInClub;
    let newMembersAddedToGroup = [];
    let newMembersRemovedFromGroup = [];

    membersInClub.map((member) => {
      if (member.personId === memberId) {
        const newMember = member;
        newMember.isInGroup = !member.isInGroup;
        const isMemberInAddGroup = isMemberInGroup(newMember, gridState.membersAddedToGroup);
        const isMemberInRemoveGroup = isMemberInGroup(newMember, gridState.membersRemovedFromGroup);

        if (newMember.isInGroup === true) {
          const temp = addMemberIntoGroup(newMember, isMemberInAddGroup);
          newMembersAddedToGroup = temp.membersAddedToGroup;
          newMembersRemovedFromGroup = temp.membersRemovedFromGroup;
        } else {
          const temp = removeMemberFromGroup(newMember, isMemberInRemoveGroup);
          newMembersAddedToGroup = temp.membersAddedToGroup;
          newMembersRemovedFromGroup = temp.membersRemovedFromGroup;
        }

        return newMember;
      } else {
        return member;
      }
    });

    setGridState({
      ...gridState,
      membersInClub,
      membersAddedToGroup: newMembersAddedToGroup,
      membersRemovedFromGroup: newMembersRemovedFromGroup
    });
  };

  const onSelectAllClicked = () => {
    const newMembersAddedToGroup = gridState.membersAddedToGroup;

    for (const member of gridState.membersInClub) {
      if (member.isVisible === true && member.isInGroup === false) {
        member.isInGroup = true;

        newMembersAddedToGroup.push(member);
      }
    }

    setGridState({
      ...gridState,
      membersAddedToGroup: newMembersAddedToGroup
    });
  };

  useEffect(() => {
    if (clubGroupState.isSaved === true) {
      navigate(NavLinks.CLUB_GROUPS);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clubGroupState.isSaved]);

  useEffect(() => {
    if (clubMembersState.isArrayLoaded === true) {
      const membersInClub = JSON.parse(JSON.stringify(clubMembersState.arrayData));
      for (const member of membersInClub) {
        member.isVisible = true;
      }

      setGridState({
        ...gridState,
        membersInClub
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clubMembersState.isArrayLoaded]);

  useEffect(() => {
    const clubGroupId = location.state?.clubGroupId;

    if (clubGroupId !== null && clubGroupId !== Constants.DEFAULT_ID) {
      getClubGroupDetails(clubGroupId);
      getClubGroupMembersInfo(clubGroupId, clubMembersState, setClubMembersState);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.state]);

  useEffect(() => {
    if (clubGroupState.isObjLoaded === true && clubGroupState.objData?.groupTypeId !== orgGroupPropertiesState.groupTypeId) {
      getOrgGroupProperties(clubGroupState.objData.groupTypeId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clubGroupState, orgGroupPropertiesState]);

  function submitFormCallback(formState) {
    const filterObject = createFilteredMembersArrayObject(formState);
    let filteredMembersInClub = JSON.parse(JSON.stringify(clubMembersState.arrayData));
    if (filterObject.startAge !== undefined) {
      filteredMembersInClub = filteredMembersInClub.filter((member) => member.age >= filterObject.startAge);
    }
    if (filterObject.endAge !== undefined) {
      filteredMembersInClub = filteredMembersInClub.filter((member) => member.age <= filterObject.endAge);
    }
    if (filterObject.filterCompetitionGender !== undefined) {
      filteredMembersInClub = filteredMembersInClub.filter((member) => member.typeName === filterObject.filterCompetitionGender);
    }

    const membersInClub = gridState.membersInClub;
    for (const member of membersInClub) {
      if (filteredMembersInClub.find(x => x.personId === member.personId)) {
        member.isVisible = true;
      } else {
        member.isVisible = false;
      }
    }

    setGridState({
      ...gridState,
      membersInClub
    });
  };

  function addMemberIntoGroup(memberObj, isMemberInAddGroup) {
    if (isMemberInAddGroup === false) {
      const membersRemovedFromGroup = [...gridState.membersRemovedFromGroup];
      const newMembersRemovedFromGroup = membersRemovedFromGroup.filter((member) => memberObj.personId !== member.personId);
      const membersAddedToGroup = [...gridState.membersAddedToGroup];
      membersAddedToGroup.push(memberObj);

      return {
        membersRemovedFromGroup: newMembersRemovedFromGroup,
        membersAddedToGroup: membersAddedToGroup
      };
    }
  };

  function removeMemberFromGroup(memberObj, isMemberInRemoveGroup) {
    if (isMemberInRemoveGroup === false) {
      const membersAddedToGroup = [...gridState.membersAddedToGroup];
      const newMembersAddedToGroup = membersAddedToGroup.filter((member) => memberObj.personId !== member.personId);
      const membersRemovedFromGroup = [...gridState.membersRemovedFromGroup];
      membersRemovedFromGroup.push(memberObj);

      return {
        membersAddedToGroup: newMembersAddedToGroup,
        membersRemovedFromGroup: membersRemovedFromGroup
      };
    }
  };

  function isMemberInGroup(memberObj, memberGroupArray) {
    if ([memberGroupArray].some((member) => member.personId === memberObj.personId)) {
      return true;
    } else {
      return false;
    }
  };

  function createFilteredMembersArrayObject(formState) {
    return {
      startAge: (ToIntIfInt(formState.startAge) === '' ? undefined : formState.startAge),
      endAge: (ToIntIfInt(formState.endAge) === '' ? undefined : formState.endAge),
      filterCompetitionGender: (ToIntIfInt(formState.filterCompetitionGender) === Constants.DEFAULT_ID ? undefined : formState.filterCompetitionGender)
    };
  };

  return {
    displayPopupState,
    formState,
    clubGroupState,
    clubMembersState,
    gridState,
    errorState,
    onSave,
    onFormValueChanged,
    onShowPopup,
    onClosePopup,
    onSelectAllClicked,
    handleSubmit,
    onCheckBoxChange,
    onCancelGoToClubGroups,
    competitionGenderOptions
  };
};

export default useClubGroupsAddRemoveMembersGrid;

function getInitialFormState() {
  return {
    filterCompetitionGender: Constants.DEFAULT_ID,
    startAge: '',
    endAge: ''
  };
};

function getInitialGridState() {
  return {
    membersInClub: [],
    membersAddedToGroup: [],
    membersRemovedFromGroup: []
  };
}

function getInitialClubMembersState() {
  return {
    arrayData: [],
    isArrayLoaded: false,
    isArrayLoading: false
  };
};