import React, { createContext, useState } from 'react';
import { BASIC_INITIAL_STATE } from '../../../../common/utils/HttpHelper';

export const ClubRegistrationPaymentStateContext = createContext();

const ClubRegistrationPaymentContextProvider = ({ children }) => {
  const stateHook = useState({...BASIC_INITIAL_STATE});

  return (
    <ClubRegistrationPaymentStateContext.Provider value={stateHook}>
      {children}
    </ClubRegistrationPaymentStateContext.Provider>
  );
};

export default ClubRegistrationPaymentContextProvider;