import React from 'react';

import global from '../../../../../common/components/GlobalStyle.module.css';

const GridRow = ({ club }) => (
  <div className={global.SmallTableRow}>
    <div className={global.SmallTableRowHead}><span className='hidden-xs'>{club.leaderboardPosition || ''}</span>&nbsp;
    </div>
    <div className={global.SmallTableRowInfo}>
      <div className='row'>
        <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3 visible-xs'].join(' ')}>Position</div>
        <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3 visible-xs'].join(' ')}>{club.leaderboardPosition || <span>&nbsp;</span>}</div>
        <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3'].join(' ')}>Club Name</div>
        <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3'].join(' ')}>{club.clubName || <span>&nbsp;</span>}</div>
        <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3'].join(' ')}>5 x 2</div>
        <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3'].join(' ')}>{club.pointsForDollarsToUSAS || <span>&nbsp;</span>}</div>
        <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3'].join(' ')}>Average Gross</div>
        <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3'].join(' ')}>{club.averageGross || <span>&nbsp;</span>}</div>
        <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3'].join(' ')}>Grand Total</div>
        <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3'].join(' ')}>{club.totalPoints || <span>&nbsp;</span>}</div>
      </div>
    </div>
  </div>
);

const SATLeaderboardGridSmall = ({ data, isLoading }) => (
  <div className={[global.SmallTable, 'hidden-md hidden-lg'].join(' ')}>
    {isLoading === true
      ? (
        <div className={global.SmallTableRow}>
          <div className={global.SmallTableRowHead}>
            &nbsp;
          </div>
          <div className={global.SmallTableRowInfo}>
            <div className={global.SmallTableRowLabels}>Loading...</div>
          </div>
        </div>
      ) : Array.isArray(data) && data.length > 0
        ? data.map((club, i) => (
          <GridRow
            key={i}
            club={club} />
        )) : (
          <div className={global.SmallTableRow}>
            <div className={global.SmallTableRowHead}>
              &nbsp;
            </div>
            <div className={global.SmallTableRowInfo}>
              <div className={global.SmallTableRowLabels}>No Clubs</div>
            </div>
          </div>
        )
    }
  </div>
);

export default SATLeaderboardGridSmall;