import useClubAthleteRosterForm from "./UseClubAthleteRosterForm";

import ClubGroupCombobox from "../../../components/comboboxes/clubGroupCombobox/ClubGroupCombobox";

import Dropdown from "../../../../common/components/dropdowns/Dropdown";
import Input from "../../../../common/components/inputs/Input";

import global from '../../../../common/components/GlobalStyle.module.css';
import ClubRosterExport from "../../../../common/components/fileExports/ClubRosterExport";

const ClubAthleteRosterForm = ({ 
  onSubmitFormCallback, 
  onSyncSelectedMembers,
  orgUnitId, 
  PRACTICE_GROUP_TYPE_ID, 
  SITE_GROUP_TYPE_ID, 
  clubRosterArray,
  orgUnitCode,
  orgUnitName,
  parentOrgUnitCode,
  clubCity,
  clubStateCode,
  clubAddress1,
  clubPostalCode,
  clearVendorSync,
  vendorSyncState }) => {
    
  const {
    formState,
    errorState,
    onFormValueChanged,
    onValueTextPairChanged,
    handleSubmit,
    onClearFilterClicked,
    competitionGenderOptions
  } = useClubAthleteRosterForm(onSubmitFormCallback, clearVendorSync);

  return (
    <form onSubmit={handleSubmit} >
      <div className='row'>
        <div className='col-xs-12 col-sm-6 col-md-4'>
          <Dropdown
            label="Competition Category"
            name="competitionGenderTypeId"
            options={competitionGenderOptions}
            value={formState.competitionGenderTypeId}
            error={errorState.competitionGenderTypeId}
            message={errorState.competitionGenderTypeId}
            isLoading={false}
            onChange={(newValue, newValueLabel, e) => {
              e && e.target && e.target.value &&
                onValueTextPairChanged(newValue, 'competitionGenderTypeId', newValueLabel, 'competitionGenderTypeName');
            }} />
        </div>
        <div className="col-xs-6 col-sm-3 col-md-2">
          <Input
            label="Min Age"
            name="minAge"
            type="number"
            min="1"
            max="99"
            value={formState.minAge}
            error={errorState.minAge}
            message={errorState.minAge}
            onChange={(value) => { onFormValueChanged('minAge', value) }} />
        </div>
        <div className="col-xs-6 col-sm-3 col-md-2">
          <Input
            label="Max Age"
            name="maxAge"
            type="number"
            min="1"
            max="99"
            value={formState.maxAge}
            error={errorState.maxAge}
            message={errorState.maxAge}
            onChange={(value) => { onFormValueChanged('maxAge', value) }} />
        </div>
        {PRACTICE_GROUP_TYPE_ID > 0 && orgUnitId &&
          <div className="col-xs-12 col-sm-6 col-md-4">
            <ClubGroupCombobox
              label={"Practice Group"}
              name={"practiceGroupId"}
              valueToMatch={formState.practiceGroupName}
              error={errorState.practiceGroupId}
              message={errorState.practiceGroupId}
              onChange={(newValue, newValueLabel) => { onValueTextPairChanged(newValue, 'practiceGroupId', newValueLabel, 'practiceGroupName'); }}
              orgUnitId={orgUnitId}
              groupTypeId={PRACTICE_GROUP_TYPE_ID} />
          </div>
        }
        {SITE_GROUP_TYPE_ID > 0 && orgUnitId &&
          <div className="col-xs-12 col-sm-6 col-md-4">
            <ClubGroupCombobox
              label={"Site Group"}
              name={"siteGroupId"}
              valueToMatch={formState.siteGroupName}
              error={errorState.siteGroupId}
              message={errorState.siteGroupId}
              onChange={(newValue, newValueLabel, e) => { onValueTextPairChanged(newValue, 'siteGroupId', newValueLabel, 'siteGroupName', e); }}
              orgUnitId={orgUnitId}
              groupTypeId={SITE_GROUP_TYPE_ID}
            />
          </div>
        }
        <div className="col-xs-12 col-sm-6 col-md-4">
          <Input
            label="First or Preferred Name"
            name="firstOrPreferredName"
            value={formState.firstOrPreferredName}
            error={errorState.firstOrPreferredName}
            message={errorState.firstOrPreferredName}
            onChange={(value) => { onFormValueChanged('firstOrPreferredName', value) }} />
        </div>
        <div className="col-xs-12 col-sm-6 col-md-4">
          <Input
            label="Last Name"
            name="lastName"
            value={formState.lastName}
            error={errorState.lastName}
            message={errorState.lastName}
            onChange={(value) => { onFormValueChanged('lastName', value) }} />
        </div>
        <div className='col-xs-6 col-sm-2 col-md-1'>
          <button className={global.PlainButton} type='submit'>Filter</button>
        </div>
        <div className='col-xs-6 col-sm-4 col-md-2'>
          <button className={global.PlainButton} type='button' onClick={onClearFilterClicked}>Clear Filter</button>
        </div>
        <div className='col-xs-12 col-sm-6 col-md-6 col-lg-3'>
          <ClubRosterExport 
            clubRosterArray={clubRosterArray} 
            orgUnitCode={orgUnitCode} 
            orgUnitName={orgUnitName} 
            parentOrgUnitCode={parentOrgUnitCode}
            clubCity={clubCity}
            clubStateCode={clubStateCode}
            clubAddress1={clubAddress1}
            clubPostalCode={clubPostalCode}
            clubEmail={""}
            rosterType={"USAS"}
            />
        </div>
        {vendorSyncState.syncPersons.length > 0 &&
        <div className="col-xs-12 col-sm-6 col-md-6 col-lg-3">
          <button className={global.PlainButton} type="button" onClick={onSyncSelectedMembers}>Sync Selected Members</button>
        </div>
        }
      </div>
    </form >
  );
};

export default ClubAthleteRosterForm;