import React, { createContext, useState } from 'react';

import ClubBlockPartyMeetsData from './ClubBlockPartyMeetsData';

export const ClubBlockPartyMeetsStateContext = createContext();

const ClubBlockPartyMeetsContextProvider = ({ children }) => {
  const stateHook = useState(ClubBlockPartyMeetsData.INITIAL_STATE);

  return (
    <ClubBlockPartyMeetsStateContext.Provider value={stateHook}>
      {children}
    </ClubBlockPartyMeetsStateContext.Provider>
  );
};

export default ClubBlockPartyMeetsContextProvider;