import React, { Fragment } from 'react';

import RegistrationProviderFacilitiesGridLarge from './RegistrationProviderFacilitiesGridLarge';
import RegistrationProviderFacilitiesGridSmall from './RegistrationProviderFacilitiesGridSmall';

const RegistrationProviderFacilitiesGrid = ({ data, isLoading, onEditClicked, onDeleteClicked }) => (
  <Fragment>
    <RegistrationProviderFacilitiesGridLarge data={data} isLoading={isLoading} onEditClicked={onEditClicked}
      onDeleteClicked={onDeleteClicked} />
    <RegistrationProviderFacilitiesGridSmall data={data} isLoading={isLoading} onEditClicked={onEditClicked}
      onDeleteClicked={onDeleteClicked} />
  </Fragment>
);

export default RegistrationProviderFacilitiesGrid;