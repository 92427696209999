import { useState } from "react";
import { useNavigate } from "../../../../common/wrappers/ReactRouterDom";

import NavLinks from "../utils/NavLinks";

import useLearnToSwimRegistrationData from "../../../state/learnToSwimRegistration/UseLearnToSwimRegistrationData";

const INITIAL_MODAL_STATE = {
  displayModal: false,
  facilityName: '',
};

const useLearnToSwimRegistrationFacilitiesSearch = () => {
  const navigate = useNavigate();
  const { ltsProviderRegistrationState } = useLearnToSwimRegistrationData();
  const [modalState, setModalState] = useState(INITIAL_MODAL_STATE);

  const onLinkFacilityClicked = (e, facilityId) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    const facilities = ltsProviderRegistrationState.objData?.facilities;
    let dupFacility = null;

    for (const facility of facilities) {
      if (facility.facilityId === facilityId) {
        dupFacility = facility;
        break;
      }
    }

    if (dupFacility === null) {
      navigate(NavLinks.LEARN_TO_SWIM_REGISTRATION_FACILITIES_DETAIL, { state: { facilityId } });
    } else {
      setModalState({
        ...modalState,
        displayModal: true,
        facilityName: dupFacility.facility?.facilityName || ''
      });
    }
  };

  const onCancelClicked = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    navigate(NavLinks.LEARN_TO_SWIM_REGISTRATION_FACILITIES);
  };

  const onModalCancelClicked = () => {
    setModalState({ ...INITIAL_MODAL_STATE });
  };

  const onAddANewFacilityClicked = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    navigate(NavLinks.LEARN_TO_SWIM_REGISTRATION_FACILITIES_ADD);
  };

  return {
    modalState,
    onLinkFacilityClicked,
    onCancelClicked,
    onModalCancelClicked,
    onAddANewFacilityClicked
  };
};

export default useLearnToSwimRegistrationFacilitiesSearch;