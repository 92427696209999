import React, { Fragment } from 'react';

import usePreCompRegistrationLocationPrograms from './UsePreCompRegistrationLocationPrograms';

import PreCompRegistrationConstants from '../utils/PreCompRegistrationConstants';

import PreCompLocationProgramsGrid from '../../preComp/components/grids/PreCompLocationProgramsGrid';

import Headings from '../../../../common/components/headings/Headings';
import ActionIntraPageButton from '../../../../common/components/buttons/ActionIntraPageButton';
import LeftNav from '../../../../common/components/leftNav/LeftNav';
import PrimaryButton from '../../../../common/components/buttons/PrimaryButton';
import SecondaryButton from '../../../../common/components/buttons/SecondaryButton';

import global from '../../../../common/components/GlobalStyle.module.css';

const PreCompRegistrationLocationPrograms = () => {
  const {
    gridState,
    locationRegistrationNavState,
    pageErrorMessageState,
    onAddOffering,
    onEditOffering,
    onDeleteOffering,
    onContinueClicked,
    onBackClicked
  } = usePreCompRegistrationLocationPrograms();

  return (
    <LeftNav title={PreCompRegistrationConstants.LEFT_NAV_TITLE} state={locationRegistrationNavState} validateBeforeRedirect={false}>
      <Headings.H3>Program Offerings</Headings.H3>
      <div className="row">
        <div className="col-xs-12 col-sm-12">
          <p><b>*At least one Program Offering is required</b></p>
        </div>
      </div>
      <Fragment>
        <div className="row usas-extra-top-margin">
          <div className="col-xs-12">
            <ActionIntraPageButton onClick={onAddOffering}>Add Program Offering</ActionIntraPageButton>
          </div>
        </div>
        <div className="row usas-extra-top-margin usas-extra-bottom-margin">
          <div className="col-xs-12">
            <PreCompLocationProgramsGrid
              data={gridState}
              isLoading={false}
              onEdit={onEditOffering}
              onDelete={onDeleteOffering} />
          </div>
        </div>
        {pageErrorMessageState &&
          <div className="row usas-extra-top-margin">
            <div className="col-xs-12">
              <p className={global.ErrorMessage}>{pageErrorMessageState}</p>
            </div>
          </div>}
        <div className="row usas-extra-top-margin usas-extra-bottom-margin">
          <div className="col-xs-12 usas-extra-top-margin">
            <PrimaryButton type="button" onClick={onContinueClicked}>Continue</PrimaryButton>&nbsp;
            <SecondaryButton type="button" onClick={onBackClicked}>Back</SecondaryButton>
          </div>
        </div>
      </Fragment>
    </LeftNav>
  );
};

export default PreCompRegistrationLocationPrograms;