import { isValidITIN, isValidEIN } from '../../../../../common/utils/validation';

const RADIO_BUTTON_KEY = 'isSwimLessonInsured';

const PreCompOrganizationSpecialInfoFormValidation = (formState) => {
  let errors = {};

  if (formState.orgUnitName.trim() === '') {
    errors.orgUnitName = 'Federal Organization Name is required';
  }

  if (formState.federalTaxId.trim() !== '') {
    if (!isValidITIN(formState.federalTaxId.trim()) && !isValidEIN(formState.federalTaxId.trim())) {
      errors.federalTaxId = 'Must be valid Federal Tax Id format XXX-XX-XXXX or XX-XXXXXXX';
    }
  } else {
    errors.federalTaxId = 'Federal Tax Id is required';
  }

  if (RADIO_BUTTON_KEY in formState) {
    if (formState.isSwimLessonInsured !== false && formState.isSwimLessonInsured !== true) {
      errors.isSwimLessonInsured = 'Must answer this question to continue';
    } else if (formState.isSwimLessonInsured === false) {
      errors.modalError = 'Cannot create a Pre-Competitive Organization without insurance to provide swim lessons. Please call (719)-866-3520 for assistance.';
    }
  }

  return errors;
};

export default PreCompOrganizationSpecialInfoFormValidation;