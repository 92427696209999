import { useContext, useEffect } from 'react';

import { PreCompCurriculumStateContext } from './PreCompCurriculumContextProvider';
import PreCompCurriculumData from './PreCompCurriculumData';

const usePreCompCurriculumData = () => {
  const [preCompCurriculumState, setPreCompCurriculumState] = useContext(PreCompCurriculumStateContext);

  const getCurriculums = () => { PreCompCurriculumData.getPreCompCurriculums(preCompCurriculumState, setPreCompCurriculumState); };

  useEffect(() => {
    if (preCompCurriculumState.isArrayLoading !== true && preCompCurriculumState.isArrayLoaded !== true) {
      getCurriculums(preCompCurriculumState, setPreCompCurriculumState);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    preCompCurriculumState,
    getCurriculums
  };
};

export default usePreCompCurriculumData;