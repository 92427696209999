import React, { Fragment } from 'react';

import useClubRegistrationInfo from './UseClubRegistrationInfo';

import ClubRegistrationInfoRead from './ClubRegistrationInfoRead';
import ClubRegistrationInfoWrite from './ClubRegistrationInfoWrite';

import ClubRegistrationConstants from '../utils/ClubRegistrationConstants';

import ValidationPopUpModal from '../components/ValidationPopUpModal';

import LeftNav from '../../../../common/components/leftNav/LeftNav';
import Headings from '../../../../common/components/headings/Headings';
import PrimaryButton from '../../../../common/components/buttons/PrimaryButton';
import SecondaryButton from '../../../../common/components/buttons/SecondaryButton';
import PopUpModal from '../../../../common/components/dialogs/PopUpModal';

import Constants from '../../../../common/utils/Constants';

const ClubRegistrationInfo = () => {
  const { clubRegistrationState, clubRegistrationNavState, usasClubStaffState,
    usasClubFacilityLinksState, lscState, state, modalState, onModalContinue, onModalCanceled,
    formState, errorState, onFormValueChanged, onValueTextPairChanged, onContinueClicked,
    onSaveAndExitClicked, clearForm, onBackClicked, settingOptions, handleSubmitProxy,
    setFormData, validateState
  } = useClubRegistrationInfo();

  return (
    <LeftNav title={ClubRegistrationConstants.LEFT_NAV_TITLE} state={clubRegistrationNavState} validateBeforeRedirect={false}>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>Club Info</Headings.H3>
        </div>
      </div>
      {clubRegistrationState.objData.status === undefined
        || clubRegistrationState.objData.status === ClubRegistrationConstants.IN_PROGRESS_STATUS
        || (clubRegistrationState.objData.status === ClubRegistrationConstants.RENEWING_STATUS &&
          Object.keys(formState.selectedRegistration).length === 0 && clubRegistrationState.objData?.clubRegistrations[0] &&
          clubRegistrationState.objData?.clubRegistrations[0]?.paymentProcessed === false)
        || (clubRegistrationState.objData.status === ClubRegistrationConstants.RENEWING_STATUS &&
          Object.keys(formState.selectedRegistration).length > 0 &&
          (formState.selectedRegistration?.paymentProcessed === false || formState.selectedRegistration?.paymentProcessed === null)) ?
        <ClubRegistrationInfoWrite
          state={state}
          handleSubmit={handleSubmitProxy}
          formState={formState}
          errorState={errorState}
          onValueTextPairChanged={onValueTextPairChanged}
          onFormValueChanged={onFormValueChanged}
          settingOptions={settingOptions}
          setFormData={setFormData}
          validateState={validateState}
        />
        : <ClubRegistrationInfoRead
          formState={formState}
          clubRegistrationState={clubRegistrationState}
        />}
      {state.showPage === true ?
        <Fragment>
          <div className="row usas-extra-top-margin usas-extra-bottom-margin">
            <div className="col-xs-12 usas-extra-top-margin">
              <PrimaryButton type="button" onClick={() => onContinueClicked()}>Continue</PrimaryButton>&nbsp;
              <PrimaryButton type="button" onClick={() => onSaveAndExitClicked()}>
                {clubRegistrationState.objData.status === undefined || clubRegistrationState.objData.status === ClubRegistrationConstants.IN_PROGRESS_STATUS
                  || clubRegistrationState.objData.status === ClubRegistrationConstants.RENEWING_STATUS ?
                  ClubRegistrationConstants.SAVE_AND_EXIT : ClubRegistrationConstants.EXIT}</PrimaryButton>&nbsp;
              {clubRegistrationState.objData.status === undefined || clubRegistrationState.objData.status === ClubRegistrationConstants.IN_PROGRESS_STATUS
                || clubRegistrationState.objData.status === ClubRegistrationConstants.RENEWING_STATUS ?
                <span><SecondaryButton type="button" onClick={() => clearForm()}>Cancel</SecondaryButton>&nbsp;</span>
                : <Fragment />}
              {clubRegistrationState.objData.status === undefined || clubRegistrationState.objData.status === ClubRegistrationConstants.IN_PROGRESS_STATUS ?
                <SecondaryButton type="button" onClick={() => onBackClicked()}>Back</SecondaryButton>
                : <Fragment />}
            </div>
          </div>
        </Fragment>
        : <Fragment />}
      <PopUpModal
        displayPopUp={validateState.isValidating === true}
        maxWidth={250}
        title="Validating..."
        widthPct={50} />
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={clubRegistrationState.isObjLoading || usasClubStaffState.isArrayLoading
          || lscState.isLoading || usasClubFacilityLinksState.isArrayLoading} />
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.SAVING_MSG}
        displayPopUp={clubRegistrationState.isSaving} />
      <ValidationPopUpModal
        clubRegistrationState={clubRegistrationState}
        state={state}
        modalState={modalState}
        onModalContinue={onModalContinue}
        onModalCanceled={onModalCanceled}
      />
    </LeftNav >
  )
}

export default ClubRegistrationInfo;