import { BASIC_INITIAL_STATE } from '../../../common/utils/HttpHelper';

import getClubBlockPartyMeetsData from './GetClubBlockPartyMeetsData';
import postClubBlockPartMeetData from './PostClubBlockPartyMeetData';
import putClubBlockPartyMeetData from './PutClubBlockPartyMeetData';
import deleteClubBlockPartyMeetData from './DeleteClubBlockPartyMeetData';

const INITIAL_STATE = {
  ...BASIC_INITIAL_STATE
};

const ClubBlockPartyMeetsData = {
  INITIAL_STATE,
  getClubBlockPartyMeetsData,
  postClubBlockPartMeetData,
  putClubBlockPartyMeetData,
  deleteClubBlockPartyMeetData
};

export default ClubBlockPartyMeetsData;