import { useEffect, useState } from 'react';

import { useExecuteQuery } from '@sisense/sdk-ui';
import { filterFactory } from '@sisense/sdk-data';

import * as DM from '../../../../usas-times-data';

import useEnvironmentVariableData from '../../../../common/state/environmentVariable/UseEnvironmentVariableData';

const useSisenseEventMultiselect = (courseName, excludeRelays, excludeIndividualEvents) => {
    const queryProps = {
        dataSource: DM.DataSource, dimensions: [DM.SwimEvent.SwimEventKey, DM.SwimEvent.EventCode],
        filters: courseName ? [filterFactory.contains(DM.SwimEvent.EventCode, courseName)] : null
    };
    const { data, isLoading } = useExecuteQuery(queryProps);
    const [eventOptions, setEventOptions] = useState({ options: [] });
    const { environmentVariableState, SISENSE_RELAY_FILTER_TEXT } = useEnvironmentVariableData();

    useEffect(() => {
        const defaultOptions = [];
        if (data && environmentVariableState.isLoaded === true) {
            const formattedOptions = [];
            data.rows.map((row) => {
                if (row[0].data !== 0) {
                    if (excludeRelays === true) {
                        if (row[1].text?.includes(SISENSE_RELAY_FILTER_TEXT) === false) {
                            return (formattedOptions.push({ id: row[0].data, name: row[1].text, children: [] }))
                        }
                    }
                    else if (excludeIndividualEvents === true) {
                        if (row[1].text?.includes(SISENSE_RELAY_FILTER_TEXT) === true) {
                            return (formattedOptions.push({ id: row[0].data, name: row[1].text, children: [] }))
                        }
                    }
                    else {
                        return (formattedOptions.push({ id: row[0].data, name: row[1].text, children: [] }))
                    }
                }
            });
            setEventOptions({ ...eventOptions, options: formattedOptions });
        }
        else {
            setEventOptions({ ...eventOptions, options: defaultOptions });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, environmentVariableState, courseName, excludeRelays, excludeIndividualEvents])

    return {
        data,
        isLoading,
        eventOptions
    };
};

export default useSisenseEventMultiselect;