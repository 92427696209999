import { PersonHttpHelper, NO_DATA_MESSAGE } from '../../../../common/utils/HttpHelper';
import { isValidMemberId } from '../../../../common/utils/validation';

export const localValidate = (formState) => {
  let errors = {};

  // add validation only
  if (!(formState.personId)) {
    if (formState.memberId.trim().length === 0) {
      errors.memberId = 'Staff Member Id is required'
    } else if (isValidMemberId(formState.memberId.trim()) === false) {
      errors.memberId = 'Staff Member Id must be 14 alphanumeric characters'
    }
  }

  if (formState.personOrgRole.length === 0 && formState.registrationRoles.length === 0) {
    errors.personOrgRole = 'Must select at least one staff role'
  } else if (formState.personOrgRole.length > 0 && formState.personOrgRole.find(x =>
    (x.id === formState.headCoachOrgRoleId || 
     x.id === formState.assistantCoachOrgRoleId || 
     x.id === formState.ageGroupCoachOrgRoleId || 
     x.id === formState.seniorCoachOrgRoleId)) !== undefined && formState.coachTypeCode === '') {
    errors.coachTypeCode = 'Must make a selection';
  }

  return errors;
};

const serverValidate = async (formState) => {
  let errors = {};

  const memberIdForUrl = formState.memberId ? encodeURIComponent(formState.memberId) : 'NaN';
  const url = `/Person/flat/memberId/${memberIdForUrl}`;

  await PersonHttpHelper(url, 'GET')
    .then((objData) => {
      if (!objData) {
        throw new Error(NO_DATA_MESSAGE);
      }
      else {
        if (objData.isOrgRoleDurationActive === false || !(objData.personId)) {
          errors.apiError = "This member is inactive. Only active members can be added as club staff";
        } else {
          formState.postPersonId = objData.personId;
        }
      }
    })
    .catch((e) => {
      errors.apiError = 'Staff Member Id is not valid'
    });

  return errors;
};


const ClubRegistrationStaffDetailValidation = async (formState) => {
  let errors = localValidate(formState);
  if (!errors || Object.keys(errors).length === 0) {
    errors = await serverValidate(formState);
  }

  return errors ? errors : {};
}

export default ClubRegistrationStaffDetailValidation;