import React, { Fragment } from 'react';

import useClubTouchpointsDetails from './UseClubTouchpointsDetails';

// import Input from '../../../../common/components/inputs/Input';
import Headings from '../../../../common/components/headings/Headings';
import PrimaryButton from '../../../../common/components/buttons/PrimaryButton';
import SecondaryButton from '../../../../common/components/buttons/SecondaryButton';
//import PopUpModal from '../../../../common/components/dialogs/PopUpModal';
//import Constants from '../../../../common/utils/Constants';
import DatePicker from '../../../../common/components/datepickers/DatePicker';
import Dropdown from '../../../../common/components/dropdowns/Dropdown';
import Textarea from '../../../../common/components/inputs/Textarea';
import global from '../../../../common/components/GlobalStyle.module.css';

const ClubTouchpointsDetails = () => {
  const {
    // baseUsasClubState,
    formState,
    errorState,
    touchpointTypeState,
    onSaveClicked,
    onFormValueChanged,
    handleSubmit,
    onCancelClicked,
    onValueTextPairChanged
  } = useClubTouchpointsDetails();

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>{formState.usasClubTouchpointId > 0 ? "Edit" : "Add"} Touchpoint</Headings.H3>
        </div>
      </div>
      <form onSubmit={handleSubmit} noValidate>
        <div className="row usas-extra-top-margin usas-extra-bottom-margin">
          <div className="col-xs-12 col-sm-6 col-md-4">
            <Textarea
              className={global.Textarea}
              label="Touchpoint Note*"
              name="touchpointNote"
              value={formState.touchpointNote}
              error={errorState.touchpointNote}
              message={errorState.touchpointNote}
              onChange={(value) => { onFormValueChanged('touchpointNote', value) }} />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4">
            <DatePicker label="Touchpoint Date*"
              name="touchpointDate"
              value={formState.touchpointDate}
              error={errorState.touchpointDate}
              message={errorState.touchpointDate}
              onChange={(value) => { onFormValueChanged('touchpointDate', value); }}
              countOfYears={5}
              startYearOffset={0} />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4">
            <Dropdown
              label="Touchpoint Types*"
              name="touchpointTypeId"
              value={formState.touchpointTypeId}
              error={errorState.touchpointTypeId}
              message={errorState.touchpointTypeId}
              areValuesIntegers={true}
              isLoading={touchpointTypeState.isLoading === true}
              options={touchpointTypeState.dataAsOptionsWithNames}
              onChange={(newValue, newValueLabel, e) => { onValueTextPairChanged(newValue, 'touchpointTypeId', newValueLabel, 'touchpointTypeName', e); }} />
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12 usas-extra-top-margin usas-extra-bottom-margin">
            <PrimaryButton type="button" onClick={onSaveClicked}>Save</PrimaryButton>&nbsp;
            <SecondaryButton type="button" onClick={onCancelClicked}>Back</SecondaryButton>
          </div>
        </div>
      </form>
      {/* <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={baseUsasClubState.isObjLoading} />
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.SAVING_MSG}
        displayPopUp={baseUsasClubState.isSaving} /> */}
    </Fragment>
  );
};

export default ClubTouchpointsDetails;