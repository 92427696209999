import React, { Fragment } from 'react';

import useClubRegistrationDisclaimer from './UseClubRegistrationDisclaimer';

import ClubRegistrationConstants from '../utils/ClubRegistrationConstants';

import ClubRegistrationDisclaimerWrite from './ClubRegistrationDisclaimerWrite';
import ClubRegistrationDisclaimerRead from './ClubRegistrationDisclaimerRead';

import Headings from '../../../../common/components/headings/Headings';
import PopUpModal from '../../../../common/components/dialogs/PopUpModal';
import Constants from '../../../../common/utils/Constants';

const ClubRegistrationDisclaimer = () => {
  const { state, clubRegistrationState, clubOwnerTypeState, businessEntityState, formState, errorState,
    onFormValueChanged, onValueTextPairChanged, handleSubmit, onContinueClicked, onBackClicked }
    = useClubRegistrationDisclaimer();

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>Club Registration</Headings.H3>
        </div>
      </div>
      {Object.keys(clubRegistrationState.objData).length === 0 ||
        (clubRegistrationState.objData?.status &&
          clubRegistrationState.objData?.status === ClubRegistrationConstants.IN_PROGRESS_STATUS) ?
        <ClubRegistrationDisclaimerWrite
          clubRegistrationState={clubRegistrationState}
          handleSubmit={handleSubmit}
          formState={formState}
          errorState={errorState}
          onValueTextPairChanged={onValueTextPairChanged}
          onFormValueChanged={onFormValueChanged}
          clubOwnerTypeState={clubOwnerTypeState}
          state={state}
          onBackClicked={onBackClicked}
        /> :
        <ClubRegistrationDisclaimerRead
          clubRegistrationState={clubRegistrationState}
          onContinueClicked={onContinueClicked}
          onBackClicked={onBackClicked}
        />
      }
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={clubRegistrationState.isObjLoading || clubOwnerTypeState.isLoading
          || businessEntityState.isArrayLoading} />
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.SAVING_MSG}
        displayPopUp={clubRegistrationState.isSaving} />
    </Fragment>
  )
}

export default ClubRegistrationDisclaimer;