import { useEffect } from 'react';

import validate from './ProviderStaffFormValidation';

import useOrgRoleStaffRoleHierarchyData from '../../../../../common/state/orgRoleStaffRoleHierarchy/UseOrgRoleStaffRoleHierarchyData';

import Constants from "../../../../../common/utils/Constants";
import UseForm from "../../../../../common/utils/UseForm";
import { formatDate } from '../../../../../common/utils/DateFunctions';

const INITIAL_FORM_STATE = {
  firstName: '',
  lastName: '',
  phoneNumber: '',
  email: '',
  birthDate: Constants.BLANK_DATE_STRING,
  orgRoles: []
};

const useProviderStaffForm = (onSubmitFormCallback, staffObj) => {
  const { orgRoleStaffRoleHierarchyState } = useOrgRoleStaffRoleHierarchyData();
  const { formState, errorState, updateFormState, handleSubmit, setFormData
  } = UseForm(INITIAL_FORM_STATE, onSubmitFormCallback, validate);

  useEffect(() => {
    if (staffObj && orgRoleStaffRoleHierarchyState.isArrayLoaded === true) {
      setFormData({
        ...formState,
        firstName: staffObj.firstName || '',
        lastName: staffObj.lastName || '',
        phoneNumber: staffObj.phoneNumber || '',
        email: staffObj.email || '',
        birthDate: staffObj.birthDate
          ? formatDate(staffObj.birthDate)
          : Constants.BLANK_DATE_STRING,
        orgRoles: staffObj.personOrgRole
          ? staffObj.personOrgRole.map((x) => { return { id: x.orgRoleId, name: x.orgRoleName }; })
          : []
      }, true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orgRoleStaffRoleHierarchyState.isArrayLoaded, staffObj]);

  return {
    isLoading: orgRoleStaffRoleHierarchyState.isArrayLoading,
    formState,
    errorState,
    handleSubmit,
    updateFormState
  };
};

export default useProviderStaffForm;