import ZoneReportingNavLinks from '../ZoneReportingNavLinks';

import useEnvironmentVariableData from '../../../../common/state/environmentVariable/UseEnvironmentVariableData';

const useZoneReportingSelection = () => {
  const { environmentVariableState, ZONE_REPORTING_ROLE_GROUP_ID } = useEnvironmentVariableData();

  return {
    isLoaded: environmentVariableState.isLoaded === true,
    NavLink: ZoneReportingNavLinks.ZONE_REPORTING_REPORTS,
    roleGroupId: ZONE_REPORTING_ROLE_GROUP_ID
  };
};

export default useZoneReportingSelection;