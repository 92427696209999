import React, { Fragment } from 'react';

import AddGrid from './AddGrid';
import RemoveGrid from './RemoveGrid';

import PrimaryButton from '../../../../../../common/components/buttons/PrimaryButton';
import SecondaryButton from '../../../../../../common/components/buttons/SecondaryButton';
import PopupModal from '../../../../../../common/components/dialogs/PopUpModal';

const AddRemoveMembersConfirmation = ({ displayPopup, gridState, clubGroupState, onClosePopup, onCheckBoxChange, onSave }) => {
  return (
    <Fragment>
      <PopupModal
        title={`Confirm updates to group: ${clubGroupState.objData?.groupName}`}
        displayPopUp={displayPopup}
        widthPct={90}
        onModalCanceled={onClosePopup}>
        <p><b>Athletes To Be Added To {clubGroupState.objData?.groupName}</b></p>
        <AddGrid
          gridState={gridState}
          onCheckBoxChange={onCheckBoxChange} />&nbsp;
        <p><b>Athletes To Be Removed From {clubGroupState.objData?.groupName}</b></p>
        <RemoveGrid
          gridState={gridState}
          onCheckBoxChange={onCheckBoxChange} />&nbsp;
        <div className="row">
          <div className="col-xs-12 usas-extra-top-margin">
            <PrimaryButton type="button" onClick={onSave}>Save</PrimaryButton>&nbsp;
            <SecondaryButton type="button" onClick={onClosePopup}>Cancel</SecondaryButton>
          </div>
        </div>
      </PopupModal>
    </Fragment>
  );
};

export default AddRemoveMembersConfirmation;