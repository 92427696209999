import { useEffect, useState } from 'react';
import { useNavigate, useLocation } from '../../../../common/wrappers/ReactRouterDom';

import NavLinks from '../NavLinks';

import validate from '../../preComp/components/forms/PreCompLocationFacilityContactFormValidation';

import usePreCompLocationRegistrationData from '../../../state/preCompLocationRegistration/UsePreCompLocationRegistrationData';
import usePreCompLocationRegistrationNavData from '../../../state/preCompLocationRegistration/preCompLocationRegistrationLeftNavBar/UsePreCompLocationRegistrationNavData';
import { createLocObjFromLocFacilityForm } from '../../preComp/utils/PreCompUtils';

import useFacilityData from '../../../../common/state/facility/UseFacilityData';
import useForm from '../../../../common/utils/UseForm';

const usePreCompRegistrationFacilitiesDetail = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { facilityState, getFacility, clearObjData } = useFacilityData();
  const { preCompLocationRegistrationState, confirmSave, saveObjData } = usePreCompLocationRegistrationData();
  const { locationRegistrationNavState } = usePreCompLocationRegistrationNavData();
  const { formState, errorState, isSubmitting, handleSubmit, updateFormState, setFormData, setIsDirty
  } = useForm(getInitialFormState, submitFormCallback, validate);
  const [facilityObjState, setFacilityObjState] = useState({});
  const [targetIndexState, setTargetIndexState] = useState(-1);

  const onCancelClicked = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    clearObjData(); // facility

    navigate(NavLinks.PRE_COMP_REGISTRATION_LOCATION_FACILITY);
  };

  useEffect(() => {
    if (location.state && location.state.facilityId > 0) {
      const targetIndex = preCompLocationRegistrationState.objData.orgUnit?.orgUnitFacility?.findIndex(
        x => x.facilityId === location.state.facilityId
      );
      // Edit
      if (targetIndex >= 0) {
        const targetOrgUnitFacility = preCompLocationRegistrationState.objData.orgUnit.orgUnitFacility[targetIndex];

        setFormData({
          ...formState,
          contactName: targetOrgUnitFacility.facility.contactName || '',
          contactEmailAddress: targetOrgUnitFacility.facility.contactEmailAddress || '',
          contactPhoneNumber: targetOrgUnitFacility.facility.contactPhoneNumber || '',
          clubFacilityContactName: targetOrgUnitFacility.clubFacilityContactName || '',
          clubFacilityContactEmailAddress: targetOrgUnitFacility.clubFacilityContactEmailAddress || '',
          clubFacilityContactPhoneNumber: targetOrgUnitFacility.clubFacilityContactPhoneNumber || ''
        });
        setFacilityObjState(targetOrgUnitFacility.facility || {});
        setTargetIndexState(targetIndex);
        setIsDirty(true);
      }
      // Add
      else {
        getFacility(location.state.facilityId);
      }
      // Invalid
    } else {
      navigate(NavLinks.PRE_COMP_REGISTRATION_LOCATION_FACILITY);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (facilityState.isObjLoaded && targetIndexState < 0) {
      setFormData({
        ...formState,
        contactName: facilityState.objData.contactName || '',
        contactEmailAddress: facilityState.objData.contactEmailAddress || '',
        contactPhoneNumber: facilityState.objData.contactPhoneNumber || '',
      })
      setFacilityObjState(facilityState.objData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [facilityState]);

  useEffect(() => {
    if (preCompLocationRegistrationState.isSaved === true && isSubmitting === false) {
      confirmSave();
      clearObjData();

      navigate(NavLinks.PRE_COMP_REGISTRATION_LOCATION_FACILITY);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [preCompLocationRegistrationState.isSaved, isSubmitting]);

  function submitFormCallback(formState) {
    saveObjData(createLocObjFromLocFacilityForm(preCompLocationRegistrationState.objData, formState, targetIndexState, facilityObjState.facilityId, facilityObjState));
  };

  function getInitialFormState() {
    return {
      contactName: '',
      contactEmailAddress: '',
      contactPhoneNumber: '',
      clubFacilityContactName: '',
      clubFacilityContactEmailAddress: '',
      clubFacilityContactPhoneNumber: ''
    };
  };

  return {
    locationRegistrationNavState,
    isLoading: facilityState.isObjLoading,
    formState,
    errorState,
    facilityObjState,
    handleSubmit,
    onFormValueChanged: updateFormState,
    onCancelClicked
  };
};

export default usePreCompRegistrationFacilitiesDetail;