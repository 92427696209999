import { Fragment } from "react";

import useMeetResultsDashboard from "./UseMeetResultsDashboard";

import MeetResultsWidget from "./MeetResultsWidget";

import LoadingModal from "../../../../../../common/components/dialogs/LoadingModal";

const MeetResultsDashboard = ({ dashboardOid }) => {
  const { isError, isLoading, dashboard, meetData } = useMeetResultsDashboard(dashboardOid);
  const meetDataRow = meetData?.rows[0];

  if (isError) {
    return <p>Error</p>;
  }

  if (isLoading) {
    return <LoadingModal />;
  }

  if (dashboard) {
    return (
      <Fragment>
        {dashboard.widgets?.map((widget, i) => {
          if (i === 0) {
            return (
              <Fragment key={i}>
                <MeetResultsWidget dashboardOid={dashboardOid} widget={widget} meetData={meetData}
                  showScy={meetDataRow && meetDataRow[0] && meetDataRow[0]?.text === 'true' ? true : false}
                  showScm={meetDataRow && meetDataRow[1] && meetDataRow[1]?.text === 'true' ? true : false}
                  showLcm={meetDataRow && meetDataRow[2] && meetDataRow[2]?.text === 'true' ? true : false}
                />
              </Fragment>)
          }
        })
        }
      </Fragment>
    );
  }
  return <Fragment></Fragment>;
};

export default MeetResultsDashboard;