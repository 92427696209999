import { useEffect, useState } from 'react';
import { useNavigate } from '../../../../common/wrappers/ReactRouterDom';

import NavLinks from '../NavLinks';

import useClubRegistrationNavData from '../../../state/clubRegistration/leftNavigation/UseClubRegistrationNavData';
import useClubRegistrationPaymentData from '../../../state/clubRegistration/payment/UseClubRegistrationPaymentData';
import useClubRegistrationNewData from '../../../state/clubRegistrationNew/UseClubRegistrationNewData';
import useUsasClubFacilityLinksData from '../../../state/clubFacilityLinks/UseUsasClubFacilityLinksData';
import useUsasClubPreCompLinksData from '../../../state/clubPreCompLinks/UseUsasClubPreCompLinksData';
import useNewClubRegistrationStatusData from '../../../state/clubRegistration/newClubRegistrationStatus/UseNewClubRegistrationStatusData';

import usePreCompOrganizationData from '../../../../common/state/preCompOrganization/UsePreCompOrganizationData';
import useSearchPreCompData from '../../../../common/state/searchPreComp/UseSearchPreCompData';
import useFacilityData from '../../../../common/state/facility/UseFacilityData';
import useSearchFacilityData from '../../../../common/state/searchFacility/UseSearchFacilityData';
import useUsasClubStaffData from '../../../state/clubStaff/UseUsasClubStaffData';
import useOfferingTypeData from '../../../../common/state/offeringTypes/UseOfferingTypeData';

const useClubRegistrationEntryNew = () => {
  const navigate = useNavigate();
  const [uiState, setUiState] = useState({ message: '' });
  const { resetClubRegistrationNavState } = useClubRegistrationNavData();
  const { clubRegistrationState, getUsasClub, clearObjData } = useClubRegistrationNewData();
  const { clearUsasClubStaffObjData, clearUsasClubStaffArrayData } = useUsasClubStaffData();
  const { clearFilterState, clearFacilityArrayData } = useFacilityData();
  const { clearUsasClubFacilityLinksArrayData } = useUsasClubFacilityLinksData();
  const { clearUsasClubPreCompLinksArrayData } = useUsasClubPreCompLinksData();
  const { resetSearchFacilityState } = useSearchFacilityData();
  const { clearPreCompSearchFilterState, clearArrayData } = usePreCompOrganizationData();
  const { resetSearchPreCompState } = useSearchPreCompData();
  const { clearClubRegistrationPaymentState } = useClubRegistrationPaymentData();
  const { resetOfferingTypeState } = useOfferingTypeData();
  const { newClubRegistrationStatusState, getNewClubRegistrationStatus, clearNewClubRegistrationStatusData } = useNewClubRegistrationStatusData();

  useEffect(() => {
    clearPreCompSearchFilterState();
    clearArrayData();
    resetSearchPreCompState();

    clearUsasClubStaffObjData();
    clearUsasClubStaffArrayData();

    clearNewClubRegistrationStatusData();

    clearFilterState();
    clearFacilityArrayData();
    resetSearchFacilityState();
    clearUsasClubFacilityLinksArrayData();
    clearUsasClubPreCompLinksArrayData();

    clearClubRegistrationPaymentState();
    resetOfferingTypeState();

    resetClubRegistrationNavState();
    clearObjData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (clubRegistrationState.isObjLoaded === false && clubRegistrationState.isObjLoading === false &&
      newClubRegistrationStatusState.isObjLoaded === false &&
      newClubRegistrationStatusState.isObjLoading === false) {
      //uses the personId of the logged in user if 0 is passed in for the personId
      getNewClubRegistrationStatus(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clubRegistrationState])

  useEffect(() => {
    if (clubRegistrationState.isObjLoaded === false &&
      clubRegistrationState.isObjLoading === false &&
      newClubRegistrationStatusState.isObjLoaded === true &&
      (newClubRegistrationStatusState.objData.usasClubId !== undefined && newClubRegistrationStatusState.objData.status === "In Progress")) {
      getUsasClub(newClubRegistrationStatusState.objData.usasClubId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newClubRegistrationStatusState])

  useEffect(() => {
    if (newClubRegistrationStatusState.isObjLoaded === true && 
      (Object.keys(newClubRegistrationStatusState.objData).length === 0 || newClubRegistrationStatusState.objData.status === "Approved")) {
      navigate(NavLinks.CLUB_REGISTRATION_DISCLAIMER);
    } else if (newClubRegistrationStatusState.objData.status === "Pending Payment") {
      setUiState({...uiState, message: 'You have a new club registration in your cart waiting for payment.'});
    } else if (newClubRegistrationStatusState.objData.status === "Pending") {
      setUiState({...uiState, message: 'You have a new club registration waiting for approval.'});
    } else if (clubRegistrationState.isObjLoaded && newClubRegistrationStatusState.isObjLoaded) {
      if (Object.keys(clubRegistrationState.objData).length > 0 && newClubRegistrationStatusState.objData.status === "In Progress" ) {
        navigate(NavLinks.CLUB_REGISTRATION_INFO);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clubRegistrationState.isObjLoaded, newClubRegistrationStatusState.isObjLoaded])

  return {
    clubRegistrationState,
    newClubRegistrationStatusState,
    uiState
  }
}

export default useClubRegistrationEntryNew;