import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { Navigate } from '../../../common/wrappers/ReactRouterDom';

import SelectOrgUnitContextProvider from '../../../common/state/selectOrgUnit/SelectOrgUnitContextProvider';

import ClubRenewalContextView from './ClubRenewalContextView';

import OrgUnitSelection from '../clubRenewal/orgUnitSelection/OrgUnitSelection';
import ClubRenewalRenew from '../clubRenewal/clubRenewalRenew/ClubRenewalRenew';

import useNavRoutes from '../../../common/state/security/UseNavRoutes';
import LoadingModal from '../../../common/components/dialogs/LoadingModal';
import SecuredRouteElement from '../../../common/components/security/SecuredRouteElement';

import BaseUsasClubContextProvider from '../../../common/state/clubBase/BaseUsasClubContextProvider';
import BaseUsasClubFiltersContextProvider from '../../../common/state/clubBase/BaseUsasClubFiltersContextProvider';
import PersonOrgRoleContextProvider from '../../../common/state/personOrgRole/PersonOrgRoleContextProvider';
import OfferingTypesContextProvider from '../../../common/state/offeringTypes/OfferingTypesContextProvider';
import ClubRegistrationContextProvider from '../../state/clubRegistration/ClubRegistrationContextProvider';

const ContextRoutes = ({ navRoutes }) => {
  return (
    <ClubRenewalContextView>
      <Routes>
        <Route path={navRoutes['CLUB_RENEWAL_RENEW']?.path}
          element={
            <SecuredRouteElement
              navRoute={navRoutes['CLUB_RENEWAL_RENEW']}
              editElement={<ClubRenewalRenew />} />
          } />
        <Route path={'/'} element={<Navigate to={navRoutes['CLUB_RENEWAL_RENEW']?.path} replace />} />
      </Routes>
    </ClubRenewalContextView>
  );
};

const ClubRenewalRoutes = () => {
  const { navRoutes, isReadyToRoute } = useNavRoutes();

  if (isReadyToRoute !== true) {
    return <LoadingModal />;
  }

  return (
    <Routes>
      <Route path={navRoutes['CLUB_RENEWAL_SELECTION']?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes['CLUB_RENEWAL_SELECTION']}
            editElement={<OrgUnitSelection />} />
        } />
      <Route path={'/*'} element={<ContextRoutes navRoutes={navRoutes} />} />
      <Route path={'/'} element={<Navigate to={'selection'} replace />} />
    </Routes>
  );
};

const ClubRenewal = () => (
  <BaseUsasClubContextProvider>
    <BaseUsasClubFiltersContextProvider>
      <SelectOrgUnitContextProvider>
        <PersonOrgRoleContextProvider>
          <ClubRegistrationContextProvider>
            <OfferingTypesContextProvider>
              <ClubRenewalRoutes />
            </OfferingTypesContextProvider>
          </ClubRegistrationContextProvider>
        </PersonOrgRoleContextProvider>
      </SelectOrgUnitContextProvider>
    </BaseUsasClubFiltersContextProvider>
  </BaseUsasClubContextProvider>
);

export default ClubRenewal;