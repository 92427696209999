import { createContext, useState } from 'react';

import LscOfferingByOrgUnitIdAndReportPeriodIdDropdownData from './LscOfferingByOrgUnitIdAndReportPeriodIdDropdownData';

export const LscOfferingByOrgUnitIdAndReportPeriodIdDropdownStateContext = createContext();

const LscOfferingByOrgUnitIdAndReportPeriodIdDropdownContextProvider = ({ children }) => {
  const stateHook = useState(LscOfferingByOrgUnitIdAndReportPeriodIdDropdownData.INITIAL_STATE);

  return (
    <LscOfferingByOrgUnitIdAndReportPeriodIdDropdownStateContext.Provider value={stateHook}>
      {children}
    </LscOfferingByOrgUnitIdAndReportPeriodIdDropdownStateContext.Provider>
  );
};

export default LscOfferingByOrgUnitIdAndReportPeriodIdDropdownContextProvider;