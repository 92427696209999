import useSisenseSeasonYearDropdown from "./UseSisenseSeasonYearDropdown";

import Dropdown from '../../../../common/components/dropdowns/Dropdown';

import Constants from "../../../../common/utils/Constants";

import global from '../../../../common/components/GlobalStyle.module.css';

const SisenseSeasonYearDropdown = ({ label, name, value, error, message, onChange }) => {
  const { seasonYearOptions } = useSisenseSeasonYearDropdown();

  return !seasonYearOptions.options
    ? <span className={global.LoadingMsg}>{Constants.LOADING_MSG}</span>
    : (
      <Dropdown
        error={error}
        label={label}
        message={message}
        options={seasonYearOptions.options}
        name={name}
        value={value}
        onChange={onChange}
        isLoading={!seasonYearOptions.options}
      />
    );
};

export default SisenseSeasonYearDropdown;