import React, { Fragment } from 'react';

const ClubGroupAttributesListType = ({ clubGroupState }) => {
  return (
    <Fragment>
      <div className="col-xs-6 col-md-3"><p><b>Number of Members:</b></p></div>
      <div className="col-xs-6 col-md-3"><p>{clubGroupState.objData?.personOrgGroup?.length}</p></div>
    </Fragment>
  );
};

export default ClubGroupAttributesListType;