
import React, { Fragment } from 'react';

import useLearnToSwimOrgUnitSelection from './UseLearnToSwimOrgUnitSelection';

import SelectOrgUnit from '../../../../common/components/selectOrgUnit/SelectOrgUnit';

const LearnToSwimOrgUnitSelection = () => {
  const {
    ROUTE,
    TAXONOMIES,
    SCOPE,
    LTS_STAFF_ROLE_GROUP_ID,
    getLTSProviderByOrgUnitId
  } = useLearnToSwimOrgUnitSelection();

  return (
    <Fragment>
      <SelectOrgUnit
        pageTitle={'My Learn To Swim Providers'}
        navLink={ROUTE}
        TAXONOMIES={TAXONOMIES}
        SCOPE={SCOPE}
        roleGroupId={LTS_STAFF_ROLE_GROUP_ID}
        getFunction={getLTSProviderByOrgUnitId} />
    </Fragment>
  );
};

export default LearnToSwimOrgUnitSelection;