import React, { createContext, useState } from 'react';

import UsasClubPreCompLinksData from './UsasClubPreCompLinksData';

export const UsasClubPreCompLinksStateContext = createContext();

const UsasClubPreCompLinksContextProvider = ({ children }) => {
  const stateHook = useState(UsasClubPreCompLinksData.INITIAL_STATE);

  return (
    <UsasClubPreCompLinksStateContext.Provider value={stateHook}>
      {children}
    </UsasClubPreCompLinksStateContext.Provider>
  );
};

export default UsasClubPreCompLinksContextProvider;