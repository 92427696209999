import React from 'react';

import usePreCompRegistrationLocationFacilitiesDetail from './UsePreCompRegistrationLocationFacilitiesDetail';

import PreCompRegistrationConstants from '../utils/PreCompRegistrationConstants';

import PreCompLocationFacilityReadOnlyInfo from '../../preComp/components/forms/PreCompLocationFacilityReadOnlyInfo';
import PreCompLocationFacilityContactForm from '../../preComp/components/forms/PreCompLocationFacilityContactForm';

import LeftNav from '../../../../common/components/leftNav/LeftNav';
import Headings from '../../../../common/components/headings/Headings';
import PrimaryButton from '../../../../common/components/buttons/PrimaryButton';
import SecondaryButton from '../../../../common/components/buttons/SecondaryButton';
import PopUpModal from '../../../../common/components/dialogs/PopUpModal';

import Constants from '../../../../common/utils/Constants';

const PreCompRegistrationLocationFacilitiesDetail = () => {
  const {
    locationRegistrationNavState,
    isLoading,
    formState,
    errorState,
    facilityObjState,
    onFormValueChanged,
    handleSubmit,
    onCancelClicked
  } = usePreCompRegistrationLocationFacilitiesDetail();

  return (
    <LeftNav title={PreCompRegistrationConstants.LEFT_NAV_TITLE} state={locationRegistrationNavState} validateBeforeRedirect={false}>
      <Headings.H3>Facility Contact Info</Headings.H3>
      {Object.keys(facilityObjState).length > 0 &&
        <PreCompLocationFacilityReadOnlyInfo
          facilityObj={facilityObjState} />}
      <form onSubmit={handleSubmit} noValidate>
        <PreCompLocationFacilityContactForm
          formState={formState}
          errorState={errorState}
          onFormValueChanged={onFormValueChanged} />
        {errorState.errorOnSubmitAction &&
          <div className="row">
            <div className="col-xs-12">
              <p className={global.ErrorMessage}>{errorState.errorOnSubmitAction || ''}</p>
            </div>
          </div>}
        <div className="row usas-extra-top-margin usas-extra-bottom-margin">
          <div className="col-xs-12 usas-extra-top-margin">
            <PrimaryButton type="submit">Save</PrimaryButton>&nbsp;
            <SecondaryButton type="button" onClick={onCancelClicked}>Cancel</SecondaryButton>
          </div>
        </div>
      </form>
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={isLoading} />
    </LeftNav>
  );
};

export default PreCompRegistrationLocationFacilitiesDetail;