import { useState } from 'react';
import { useNavigate } from '../../../../common/wrappers/ReactRouterDom';

import NavLinks from '../NavLinks';

import useBaseUsasClubData from '../../../../common/state/clubBase/UseBaseUsasClubData';

import useForm from '../../../../common/utils/UseForm';

const useClubLegalEntities = () => {
  const navigate = useNavigate();
  const { baseUsasClubState, setBaseUsasClubState, putBaseUsasClub } = useBaseUsasClubData();
  const [modalState, setModalState] = useState(getInitialModalState());
  const { setIsDirty } = useForm();

  const onAddLegalEntity = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    setBaseUsasClubState({
      ...baseUsasClubState,
      isSaved: false
    });

    navigate(NavLinks.CLUB_LEGAL_ENTITIES_DETAILS);
  };

  const onEditLegalEntity = (e, usasClubLegalEntityId) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    navigate(NavLinks.CLUB_LEGAL_ENTITIES_DETAILS, { state: { usasClubLegalEntityId } });
  };

  const onDeleteLegalEntityFromClub = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    const baseUsasClubObj = JSON.parse(JSON.stringify(baseUsasClubState.objData));
    const grabSpecificLegalEntity = baseUsasClubObj.usasClubLegalEntities.filter(e => e.usasClubLegalEntityId !== modalState.usasClubLegalEntityId);
    baseUsasClubObj.usasClubLegalEntities = grabSpecificLegalEntity;
    putBaseUsasClub(baseUsasClubState.objData.usasClubId, baseUsasClubObj);
    setModalState({
      ...getInitialModalState()
    });
  };

  const onModalCanceled = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    setIsDirty(true);
    setModalState(getInitialModalState());
  };

  const onOpenModalClicked = (e, usasClubLegalEntityId) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    const baseUsasClubLegalEntitiesObj = baseUsasClubState.objData.usasClubLegalEntities.find(x => x.usasClubLegalEntityId === usasClubLegalEntityId);
    setModalState({
      ...modalState,
      usasClubLegalEntityId: usasClubLegalEntityId,
      legalEntityName: baseUsasClubLegalEntitiesObj.legalEntityName,
      legalEntityTaxId: baseUsasClubLegalEntitiesObj.legalEntityTaxId,
      displayPopUp: true
    });
  };

  return {
    baseUsasClubState,
    modalState,
    onDeleteLegalEntityFromClub,
    onAddLegalEntity,
    onEditLegalEntity,
    onModalCanceled,
    onOpenModalClicked
  };
};

export default useClubLegalEntities;

function getInitialModalState() {
  return {
    displayPopUp: false,
    currentLegalEntityInfo: '',
    modalTitle: 'Delete Legal Entity From Club?'
  };
};

