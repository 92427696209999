import { useContext } from 'react';

import { GroupCategoryStateContext } from './GroupCategoryContextProvider';
import GroupCategoryData from './GroupCategoryData';

const useGroupCategoryData = () => {
  const [groupCategoryState, setGroupCategoryState] = useContext(GroupCategoryStateContext);

  const getGroupCategoriesByOrgUnitId = (orgUnitId) => {
    GroupCategoryData.getGroupCategoriesByOrgUnitId(orgUnitId, groupCategoryState, setGroupCategoryState);
  };

  return {
    groupCategoryState,
    getGroupCategoriesByOrgUnitId
  };
};

export default useGroupCategoryData;