import { BASIC_INITIAL_STATE } from '../../../common/utils/HttpHelper';

import getTeamFacilityLinksData from './GetTeamFacilityLinksData';
import postTeamFacilityLinksData from './PostTeamFacilityLinksData';
import putTeamFacilityLinksData from './PutTeamFacilityLinksData';
import deleteTeamFacilityLinksData from './DeleteTeamFacilityLinksData';

const INITIAL_STATE = {
  ...BASIC_INITIAL_STATE
};

const TeamFacilityLinksData = {
  INITIAL_STATE, getTeamFacilityLinksData, postTeamFacilityLinksData,
  putTeamFacilityLinksData, deleteTeamFacilityLinksData
};

export default TeamFacilityLinksData;