import { useEffect } from 'react';
import { useNavigate, useLocation } from '../../../../../common/wrappers/ReactRouterDom';

import { createGroupObjFromGroupInfoForm } from '../utils/ClubGroupsUtils';

import NavLinks from '../../NavLinks';

import useClubGroupsData from '../../../../state/clubGroups/UseClubGroupsData';

import validate from '../../../../../common/components/forms/groups/GroupInfoFormValidation';

import useGroupCategoryData from '../../../../../common/state/groupCategory/UseGroupCategoryData';

import useForm from '../../../../../common/utils/UseForm';
import Constants from '../../../../../common/utils/Constants';
import HierarchicalDataUtils from '../../../../../common/utils/HierarchicalDataUtils';

const useClubGroupTypeListEdit = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { groupCategoryState } = useGroupCategoryData();
  const { clubGroupState, getClubGroupDetails, putClubGroup } = useClubGroupsData();
  const { formState, setFormState, handleSubmit, errorState, onFormValueChanged, setIsDirty
  } = useForm(getInitialFormState, submitFormCallback, validate);

  const onBackClicked = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    navigate(NavLinks.CLUB_GROUPS);
  };

  useEffect(() => {
    if (clubGroupState.isObjLoaded === true && groupCategoryState.isArrayLoaded === true
      && groupCategoryState.id === clubGroupState.objData.orgUnitId) {
      const groupObj = clubGroupState.objData;

      setFormState({
        ...formState,
        orgUnitId: groupObj.orgUnitId,
        groupTypeId: groupObj.groupTypeId,
        groupTypeName: groupObj.groupType?.typeName || '',
        groupCategories: HierarchicalDataUtils.GetNameIdPairs(groupCategoryState.treeData, [groupObj.groupCategoryId]),
        groupName: groupObj.groupName,
        groupCode: groupObj.groupCode,
        groupDescription: groupObj.groupDescription
      });

      setIsDirty(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clubGroupState, groupCategoryState]);

  useEffect(() => {
    if (clubGroupState.isSaved === true) {
      navigate(NavLinks.CLUB_GROUPS);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clubGroupState.isSaved]);

  useEffect(() => {
    if (location.state.clubGroupId) {
      getClubGroupDetails(location.state.clubGroupId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.state]);

  function submitFormCallback() {
    if (clubGroupState.isSaving === false) {
      putClubGroup(clubGroupState.objData.orgGroupId, createGroupObjFromGroupInfoForm(clubGroupState.objData, formState));
    }
  };

  function getInitialFormState() {
    return {
      orgUnitId: '',
      groupTypeId: Constants.DEFAULT_ID,
      groupTypeName: '',
      groupCategories: [],
      groupName: '',
      groupCode: '',
      groupDescription: ''
    };
  };

  return {
    clubGroupState,
    groupCategoryState,
    formState,
    errorState,
    handleSubmit,
    onFormValueChanged,
    onBackClicked,
    orgUnitId: clubGroupState.objData.orgUnitId || ''
  };
};

export default useClubGroupTypeListEdit;