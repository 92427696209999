import React, {createContext, useState} from 'react';

import ClubAffiliationTypeData from './ClubAffiliationTypeData';

export const ClubAffiliationTypeStateContext = createContext();

const ClubAffiliationTypeContextProvider = ({children}) => {
  const stateHook = useState(ClubAffiliationTypeData.INITIAL_STATE);

  return (
    <ClubAffiliationTypeStateContext.Provider value={stateHook}>
      {children}
    </ClubAffiliationTypeStateContext.Provider>
  );
};

export default ClubAffiliationTypeContextProvider;