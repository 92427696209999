import { useContext, useEffect } from 'react';

import { PreCompOfferingTypeStateContext } from './PreCompOfferingTypeContextProvider';
import PreCompOfferingTypeData from './PreCompOfferingTypeData';

const usePreCompOfferingTypeData = () => {
  const [preCompOfferingTypeState, setPreCompOfferingTypeState] = useContext(PreCompOfferingTypeStateContext);

  const getOfferingTypes = () => { PreCompOfferingTypeData.getPreCompOfferingTypes(preCompOfferingTypeState, setPreCompOfferingTypeState); };

  useEffect(() => {
    if (preCompOfferingTypeState.isArrayLoading !== true && preCompOfferingTypeState.isArrayLoaded !== true) {
      getOfferingTypes(preCompOfferingTypeState, setPreCompOfferingTypeState);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    preCompOfferingTypeState,
    getOfferingTypes
  };
};

export default usePreCompOfferingTypeData;