import React, { Fragment } from 'react';

import ClubGroupsGridLarge from './ClubGroupsGridLarge';
import ClubGroupsGridSmall from './ClubGroupsGridSmall';

const ClubGroupsGrid = ({ clubGroupState, onEditClubGroup, onDeleteClubGroup, onAddRemoveMember }) => (
  <Fragment>
    <ClubGroupsGridLarge
      state={clubGroupState}
      onEditClubGroup={onEditClubGroup}
      onDeleteClubGroup={onDeleteClubGroup}
      onAddRemoveMember={onAddRemoveMember} />
    <ClubGroupsGridSmall
      state={clubGroupState}
      onEditClubGroup={onEditClubGroup}
      onDeleteClubGroup={onDeleteClubGroup}
      onAddRemoveMember={onAddRemoveMember} />
  </Fragment>
);

export default ClubGroupsGrid;