import React, { createContext, useState } from 'react';

import PreCompCurriculumData from './PreCompCurriculumData';

export const PreCompCurriculumStateContext = createContext();

const PreCompCurriculumContextProvider = ({ children }) => {
  const stateHook = useState(PreCompCurriculumData.INITIAL_STATE);

  return (
    <PreCompCurriculumStateContext.Provider value={stateHook}>
      {children}
    </PreCompCurriculumStateContext.Provider>
  );
};

export default PreCompCurriculumContextProvider;