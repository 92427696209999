import React, { Fragment } from 'react';

import EditIcon from '../../../../../common/components/icons/EditIcon';
import UnlinkIcon from '../../../../../common/components/icons/UnlinkIcon';
import AddPoolIcon from '../../../../../common/components/icons/AddPoolIcon';
import HideIcon from '../../../../../common/components/icons/HideIcon';
import ShowIcon from '../../../../../common/components/icons/ShowIcon';
import DeleteIcon from '../../../../../common/components/icons/DeleteIcon';

import global from '../../../../../common/components/GlobalStyle.module.css';

const DetailTableRow = ({ pool, onEditPool, onDeletePool }) => (
  <tr>
    <td>{pool.facilityPoolName || ''}</td>
    <td>{pool.poolDimensions?.dimensionName || ''}</td>
    <td>
      <button className={global.IconButtonMargin} type="button" onClick={() => onEditPool(pool.facilityId, pool.facilityPoolId)}>
        <EditIcon />
      </button>
      <button className={global.IconButtonMargin} type="button" onClick={() => onDeletePool(pool)}>
        <DeleteIcon />
      </button>
    </td>
  </tr>
);

const DetailTable = ({ facility, onEditPool, onDeletePool }) => (
  <table className={global.DetailTable}>
    <thead>
      <tr>
        <th>Pool Number / Name</th>
        <th>Pool Dimensions</th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      {Array.isArray(facility.facilityPool) && facility.facilityPool.length > 0
        ? facility.facilityPool.map((pool, i) => <DetailTableRow key={i} pool={pool} onEditPool={onEditPool} onDeletePool={onDeletePool} />)
        : <tr><td colSpan="6">No Pools</td></tr>
      }
    </tbody>
  </table>
);

const TableRow = ({ expandedId, facility, onExpand, onEdit, onDelete, onAddPool, onEditPool, onDeletePool }) => (
  <Fragment>
    <tr>
      <td>{facility.facility?.facilityName || ''}</td>
      <td>{facility.facility?.city || ''}</td>
      <td>{facility.facility?.stateCode || ''}</td>
      <td>{facility.facility?.postalCode || ''}</td>
      <td>{facility.facility?.address1 || ''}</td>
      <td>
        <button
          className={global.IconButtonMargin}
          type="button"
          onClick={() => onEdit(facility.facilityId)}>
          <EditIcon />
        </button>
        {onDelete !== undefined &&
          <button
            className={global.IconButtonMargin}
            type="button"
            onClick={() => onDelete(facility.facilityId)}>
            <UnlinkIcon />
          </button>
        }
        <button className={global.IconButtonMargin} type="button" onClick={() => onAddPool(facility.facilityId)}>
          <AddPoolIcon />
        </button>
        <button
          className={global.IconButtonMargin}
          type="button"
          onClick={(e) => onExpand(e, facility.facilityId)}>
          {expandedId === facility.facilityId ? <HideIcon /> : <ShowIcon />}
        </button>
      </td>
    </tr>
    {Array.isArray(facility.facility?.facilityPool) && expandedId === facility.facilityId &&
      <tr className={global.Expanded}>
        <td colSpan="6">
          <DetailTable facility={facility.facility} onEditPool={onEditPool} onDeletePool={onDeletePool} />
        </td>
      </tr>
    }
  </Fragment>
);

const ProviderFacilitiesGridLarge = ({ isLoading, expandedId, data, onExpand, onEdit, onDelete, onAddPool, onEditPool, onDeletePool }) => (
  <table className={[global.UsasTable, 'visible-md visible-lg'].join(' ')}>
    <thead>
      <tr>
        <th>Facility Name</th>
        <th>City</th>
        <th>State</th>
        <th>Zip Code</th>
        <th>Address</th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      {isLoading === true
        ? <tr><td colSpan="6">Loading...</td></tr>
        : data.length > 0
          ? data.map((facility, i) => <TableRow key={i} expandedId={expandedId} facility={facility} onExpand={onExpand} onEdit={onEdit} onDelete={data.length > 1 ? onDelete : undefined} onAddPool={onAddPool} onEditPool={onEditPool} onDeletePool={onDeletePool} />)
          : <tr><td colSpan="6">No Facilities</td></tr>
      }
    </tbody>
  </table>
);

export default ProviderFacilitiesGridLarge;