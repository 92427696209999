import { Fragment } from 'react';

import ClubThirdPartyInvoicesDetailGridLarge from './ClubThirdPartyInvoicesDetailGridLarge';
import ClubThirdPartyInvoicesDetailGridSmall from './ClubThirdPartyInvoicesDetailGridSmall';

import global from '../../../../common/components/GlobalStyle.module.css';

const ClubThirdPartyInvoicesDetailGrid = ({ lscOfferingByOrgUnitIdAndReportPeriodIdDropdownState, orgUnitId, reportPeriodId, gridData, isLoading, onDeleteClicked,
  onUpdateSelectedOfferingNameValueChange, showError, onPersonPotentialMatchModalOpenClicked }) => {
  return (
    <Fragment>
      {isLoading === false
        ? (
          <div className="row">
            <div className="col-xs-12">
              {gridData.length > 499
                ? <p className={global.ErrorMessage}>More than 500 Member Registrations returned. Please add additional filters to narrow your results.</p>
                : <p>{gridData.length === 1 ? `${gridData.length} Member Registration` : `${gridData.length} Member Registrations`}</p>
              }
            </div>
          </div>
        ) : <Fragment><div className="row usas-extra-top-margin usas-extra-bottom-margin"></div></Fragment>
      }
      <ClubThirdPartyInvoicesDetailGridLarge
        lscOfferingByOrgUnitIdAndReportPeriodIdDropdownState={lscOfferingByOrgUnitIdAndReportPeriodIdDropdownState}
        orgUnitId={orgUnitId}
        reportPeriodId={reportPeriodId}
        gridData={gridData}
        isLoading={isLoading}
        onDeleteClicked={onDeleteClicked}
        onUpdateSelectedOfferingNameValueChange={onUpdateSelectedOfferingNameValueChange}
        showError={showError}
        onPersonPotentialMatchModalOpenClicked={onPersonPotentialMatchModalOpenClicked}
      />
      <ClubThirdPartyInvoicesDetailGridSmall
        lscOfferingByOrgUnitIdAndReportPeriodIdDropdownState={lscOfferingByOrgUnitIdAndReportPeriodIdDropdownState}
        orgUnitId={orgUnitId}
        reportPeriodId={reportPeriodId}
        gridData={gridData}
        isLoading={isLoading}
        onDeleteClicked={onDeleteClicked}
        onUpdateSelectedOfferingNameValueChange={onUpdateSelectedOfferingNameValueChange}
        showError={showError}
        onPersonPotentialMatchModalOpenClicked={onPersonPotentialMatchModalOpenClicked}
      />
    </Fragment>
  );
}

export default ClubThirdPartyInvoicesDetailGrid;