import React from 'react';

import usePreCompAffiliationMultiselect from './UsePreCompAffiliationMultiselect';

import MultiSelect from '../../../../../common/components/multiSelect/MultiSelect';

import global from '../../../../../common/components/GlobalStyle.module.css';

const PreCompAffiliationMultiselect = ({ label, name, value, error, message, onChange }) => {
  const { preCompAffiliationTypeState } = usePreCompAffiliationMultiselect();

  return preCompAffiliationTypeState.message
    ? <span className={global.LoadingMsg}>{preCompAffiliationTypeState.message}</span>
    : (
      <MultiSelect
        label={label}
        name={name}
        value={value}
        options={preCompAffiliationTypeState.options}
        error={error}
        message={message}
        isLoading={preCompAffiliationTypeState.isArrayLoading}
        onChange={onChange} />
    );
};

export default PreCompAffiliationMultiselect;