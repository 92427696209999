import { BASIC_INITIAL_STATE } from '../../utils/HttpHelper';

import searchUsasClubsData from './SearchUsasClubsData';
import getBaseUsasClubData from './GetBaseUsasClubData';
import getBaseUsasClubByOrgUnitIdData from './GetBaseUsasClubByOrgUnitIdData';
import putBaseUsasClubData from './PutBaseUsasClubData';
import deleteUsasClubAffiliationData from './DeleteUsasClubAffiliationData';

const INITIAL_STATE = {
  ...BASIC_INITIAL_STATE
};

const BaseUsasClubData = {
  INITIAL_STATE, searchUsasClubsData, getBaseUsasClubData, getBaseUsasClubByOrgUnitIdData,
   putBaseUsasClubData, deleteUsasClubAffiliationData
};

export default BaseUsasClubData;