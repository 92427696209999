import useClubAffiliationTypeData from '../../../state/clubAffiliationType/UseClubAffiliationTypeData';

const useClubAffiliationTypeMultiselect = () => {
  const { clubAffiliationTypeState } = useClubAffiliationTypeData();

  return {
    clubAffiliationTypeState
  };
};

export default useClubAffiliationTypeMultiselect;