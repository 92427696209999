import React, { Fragment } from "react";

import EditIcon from "../../../../common/components/icons/EditIcon";

import global from '../../../../common/components/GlobalStyle.module.css';

const GridRow = ({ roleField, onEdit }) => (
  <Fragment>
    <tr>
      <td>{roleField.orgRoleName || ''}</td>
      <td>{roleField.email || ''}</td>
      <td>
        {onEdit !== undefined &&
          <button
            className={global.IconButtonMargin}
            type="button"
            onClick={() => onEdit(roleField.orgUnitOrgRoleFieldId)}>
            <EditIcon />
          </button>
        }
      </td>
    </tr>
  </Fragment>
);

const LSCRoleEmailsGridLarge = ({ data, isLoading, onEdit }) => (
  <table className={[global.UsasTable, 'visible-md visible-lg'].join(' ')}>
    <thead>
      <tr>
        <th>Role</th>
        <th>Email</th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      {isLoading === true
        ? <tr><td colSpan="3">Loading...</td></tr>
        : data.length > 0
          ? data.map((roleField, i) => <GridRow key={i} roleField={roleField} onEdit={onEdit} />)
          : <tr><td colSpan="3">No LSC Role Emails</td></tr>
      }
    </tbody>
  </table>
);

export default LSCRoleEmailsGridLarge;