import React, { Fragment } from "react";

import useClubSwimAThonParticipantDetail from './UseClubSwimAThonParticipantDetail';

import SATParticipantForm from "../components/forms/SATParticipantForm";

import Headings from "../../../../common/components/headings/Headings";
import PrimaryButton from "../../../../common/components/buttons/PrimaryButton";
import SecondaryButton from "../../../../common/components/buttons/SecondaryButton";

const ClubSwimAThonParticipantDetail = () => {
  const {
    isEdit,
    formState,
    errorState,
    onCancelClicked,
    onAmountRaisedChanged,
    onFormValueChanged,
    onValueTextPairChanged,
    handleSubmit
  } = useClubSwimAThonParticipantDetail();

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>{isEdit ? 'Edit' : 'Add'} Swim-a-Thon Prize Winner</Headings.H3>
        </div>
      </div>
      <form onSubmit={handleSubmit} noValidate>
        <SATParticipantForm
          formState={formState}
          errorState={errorState}
          onAmountRaisedChanged={onAmountRaisedChanged}
          onFormValueChanged={onFormValueChanged}
          onValueTextPairChanged={onValueTextPairChanged} />
        <div className="row usas-extra-top-margin">
          <div className="col-xs-12 usas-extra-top-margin usas-extra-bottom-margin">
            <PrimaryButton type="submit">Save</PrimaryButton>&nbsp;
            <SecondaryButton type="button" onClick={onCancelClicked}>Back</SecondaryButton>
          </div>
        </div>
      </form>
    </Fragment>
  );
};

export default ClubSwimAThonParticipantDetail;