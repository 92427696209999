import React, { Fragment } from 'react';

import useClubSwimAThonResultDetail from './UseClubSwimAThonResultDetail';

import SATResultForm from '../components/forms/SATResultForm';
import SATParticipantsGrid from '../components/grids/SATParticipantsGrid';

import Headings from '../../../../common/components/headings/Headings';
import PrimaryButton from '../../../../common/components/buttons/PrimaryButton';
import SecondaryButton from '../../../../common/components/buttons/SecondaryButton';
import PopUpModal from '../../../../common/components/dialogs/PopUpModal';

import Constants from '../../../../common/utils/Constants';

const ClubSwimAThonResultDetail = ({ canEditSubmittedResult = false }) => {
  const {
    isSubmitted,
    isLoading,
    formState,
    errorState,
    swimAThonResultState,
    onFormValueChanged,
    handleSubmit,
    onCancelClicked
  } = useClubSwimAThonResultDetail();

  if (swimAThonResultState.isObjLoaded !== true) {
    return (
      <Fragment>
        <div className="row">
          <div className="col-xs-12">
            <Headings.H3>Edit Swim-a-Thon Result</Headings.H3>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12 usas-extra-top-margin usas-extra-bottom-margin">
            <SecondaryButton type="button" onClick={onCancelClicked}>Back</SecondaryButton>
          </div>
        </div>
      </Fragment>
    );
  }

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>Edit Swim-a-Thon Result</Headings.H3>
        </div>
      </div>
      {canEditSubmittedResult || !isSubmitted ? (
        <form onSubmit={handleSubmit} noValidate>
          <SATResultForm
            formState={formState}
            errorState={errorState}
            onFormValueChanged={onFormValueChanged} />
          <div className="row usas-extra-top-margin usas-extra-bottom-margin">
            <div className="col-xs-12">
              <PrimaryButton type="submit">Continue</PrimaryButton>&nbsp;
              <SecondaryButton type="button" onClick={onCancelClicked}>Back</SecondaryButton>
            </div>
          </div>
        </form>
      ) : (
        <Fragment>
          <SATResultForm
            formState={formState} />
          <div className="row">
            <div className="col-xs-12 usas-extra-top-margin usas-extra-bottom-margin">
              <SATParticipantsGrid
                data={swimAThonResultState.objData?.satParticipant || []}
                isLoading={false} />
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12 usas-extra-top-margin usas-extra-bottom-margin">
              <SecondaryButton type="button" onClick={onCancelClicked}>Back</SecondaryButton>
            </div>
          </div>
        </Fragment>
      )}
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={isLoading} />
    </Fragment>
  );
};

export default ClubSwimAThonResultDetail;