import { useContext } from 'react';

import PoolConfigurationData from './PoolConfigurationData';

import { PoolConfigurationStateContext } from './PoolConfigurationContextProvider';

import Constants from '../../utils/Constants';

const usePoolConfigurationData = () => {
  const [poolConfigurationState, setPoolConfigurationState] = useContext(PoolConfigurationStateContext);

  const resetPoolConfigurationStateUpdateGrid = () => {
    setPoolConfigurationState({
      ...poolConfigurationState,
      showPage: false,
      poolId: Constants.DEFAULT_ID,
      poolName: '',
      poolDimensionsId: Constants.DEFAULT_ID,
      poolDimensions: '',
      poolConfiguration: [],
      poolConfigurationIndex: Constants.DEFAULT_ID,
      poolConfigurationId: Constants.DEFAULT_ID,
      course: '',
      numberOfLanes: '',
      updatePoolConfigGrid: true
    });
  }

  const resetPoolConfigurationState = () => {
    setPoolConfigurationState(PoolConfigurationData.INITIAL_STATE);
  }

  return {
    poolConfigurationState,
    setPoolConfigurationState,
    resetPoolConfigurationStateUpdateGrid,
    resetPoolConfigurationState
  };
};

export default usePoolConfigurationData;