import React, { Fragment } from 'react';

import ReadOnly from '../../../../../common/components/readOnly/ReadOnly';
import YesNoSwitch from '../../../../../common/components/yesNoSwitch/YesNoSwitch';

const PreCompLocationInstructorStaffForm = ({ formState, errorState, onFormValueChanged }) => (
  <Fragment>
    <div className="row">
      <div className="col-xs-12 col-sm-6 col-md-4">
        {onFormValueChanged === undefined
          ? <ReadOnly
            label="CPR/AED Certified?"
            name="isCPRAEDCertified"
            value={formState.isCPRAEDCertified} />
          : (
            <YesNoSwitch
              label="CPR/AED Certified?*"
              name="isCPRAEDCertified"
              checked={formState.isCPRAEDCertified === true}
              error={errorState.isCPRAEDCertified}
              message={errorState.isCPRAEDCertified}
              onChange={(value) => { onFormValueChanged('isCPRAEDCertified', value) }} />
          )
        }
      </div>
      <div className="col-xs-12 col-sm-6 col-md-4">
        {onFormValueChanged === undefined
          ? <ReadOnly
            label="First Aid Certified?"
            name="isFirstAidCertified"
            value={formState.isFirstAidCertified} />
          : (
            <YesNoSwitch
              label="First Aid Certified?*"
              name="isFirstAidCertified"
              checked={formState.isFirstAidCertified === true}
              error={errorState.isFirstAidCertified}
              message={errorState.isFirstAidCertified}
              onChange={(value) => { onFormValueChanged('isFirstAidCertified', value) }} />
          )
        }
      </div>
      <div className="col-xs-12 col-sm-6 col-md-4">
        {onFormValueChanged === undefined
          ? <ReadOnly
            label="Lifeguard Certified?"
            name="isLifeguardCertified"
            value={formState.isLifeguardCertified} />
          : (
            <YesNoSwitch
              label="Lifeguard Certified?*"
              name="isLifeguardCertified"
              checked={formState.isLifeguardCertified === true}
              error={errorState.isLifeguardCertified}
              message={errorState.isLifeguardCertified}
              onChange={(value) => { onFormValueChanged('isLifeguardCertified', value) }} />
          )
        }
      </div>
      <div className="col-xs-12 col-sm-6 col-md-4">
        {onFormValueChanged === undefined
          ? <ReadOnly
            label="Pre-Employment Screening?"
            name="isPreEmploymentScreening"
            value={formState.isPreEmploymentScreening} />
          : (
            <YesNoSwitch
              label="Pre-Employment Screening?*"
              name="isPreEmploymentScreening"
              checked={formState.isPreEmploymentScreening === true}
              error={errorState.isPreEmploymentScreening}
              message={errorState.isPreEmploymentScreening}
              onChange={(value) => { onFormValueChanged('isPreEmploymentScreening', value) }} />
          )
        }
      </div>
    </div>
  </Fragment>
);

export default PreCompLocationInstructorStaffForm;