import React, { createContext, useState } from 'react';

import PreCompLocationStaffData from './PreCompLocationStaffData';

export const PreCompLocationStaffStateContext = createContext();

const PreCompLocationStaffContextProvider = ({ children }) => {
  const stateHook = useState(PreCompLocationStaffData.INITIAL_STATE);

  return (
    <PreCompLocationStaffStateContext.Provider value={stateHook}>
      {children}
    </PreCompLocationStaffStateContext.Provider>
  );
};

export default PreCompLocationStaffContextProvider;