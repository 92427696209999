import React, { Fragment } from 'react';

import useClubSearch from './UseClubSearch';

import SearchClub from '../../../../common/components/searches/searchClub/SearchClub';

const ClubSearch = () => {
  const { 
    searchClubState,
    onEditClub
  } = useClubSearch();

  return (
    <Fragment>
      <SearchClub
        pageTitle={"Club Search"}
        onEdit={onEditClub}
        sendObjOnEdit={true}
        searchClubState={searchClubState}
      />
    </Fragment>
  );
};

export default ClubSearch;