const createCategoryMeasureScoreObj = (categoryMeasure, programLevel, submittedValue, score,
  scoreEntryOverride = false, submittedValueArray = undefined) => {
  // check for submittedValueArray, if yes we are making a change so start with empty array, if no keep old value
  const categoryMeasureScoreDetail = submittedValueArray ? [] : categoryMeasure?.multiSelectSubmittedValue ?? [];

  if (Array.isArray(submittedValueArray) && submittedValueArray.length > 0) {
    for (const value of submittedValueArray) {
      categoryMeasureScoreDetail.push({
        categoryMeasureScoreId: categoryMeasure?.categoryMeasureScoreId > 0
          ? categoryMeasure.categoryMeasureScoreId
          : undefined,
        submittedValue: value.name
      });
    }
  }

  return {
    categoryMeasureScoreId: categoryMeasure?.categoryMeasureScoreId > 0
      ? categoryMeasure.categoryMeasureScoreId
      : undefined,
    programLevelInstanceId: programLevel?.programLevelInstanceId,
    categoryMeasureId: categoryMeasure?.categoryMeasureId,
    submittedValue,
    submittedById: null, //handled by backend
    score,
    scoreEntryOverride,
    categoryMeasureScoreDetail,
    categoryMeasure: undefined
  };
};

export {
  createCategoryMeasureScoreObj
};