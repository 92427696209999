/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Fragment } from 'react';

import EditIcon from '../../../../../common/components/icons/EditIcon';
import UnlinkIcon from '../../../../../common/components/icons/UnlinkIcon';

import global from '../../../../../common/components/GlobalStyle.module.css';

const GridRow = ({ facility, onEditClicked, onDeleteClicked }) => (
  <Fragment>
    <div className={global.SmallTableRow}>
      <div className={global.SmallTableRowHead}><span className='hidden-xs'>{facility.facility?.facilityName || ''}</span>&nbsp;
        <button
          className={global.IconButtonMobileMargin}
          type="button"
          onClick={() => onDeleteClicked(facility.facilityId)}>
          <UnlinkIcon />
        </button>
        <button
          className={global.IconButtonMobileMargin}
          type="button"
          onClick={() => onEditClicked(facility.facilityId)}>
          <EditIcon />
        </button>
      </div>
      <div className={global.SmallTableRowInfo}>
        <div className='row'>
          <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3 visible-xs'].join(' ')}>Facility Name</div>
          <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3 visible-xs'].join(' ')}>{facility.facility?.facilityName || <span>&nbsp;</span>}</div>
          <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3'].join(' ')}>City</div>
          <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3'].join(' ')}>{facility.facility?.city || <span>&nbsp;</span>}</div>
          <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3'].join(' ')}>State</div>
          <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3'].join(' ')}>{facility.facility?.stateCode || <span>&nbsp;</span>}</div>
          <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3'].join(' ')}>Zip Code</div>
          <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3'].join(' ')}>{facility.facility?.postalCode || <span>&nbsp;</span>}</div>
          <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3'].join(' ')}>Address</div>
          <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3'].join(' ')}>{facility.facility?.address1 || <span>&nbsp;</span>}</div>
        </div>
      </div>
    </div>
  </Fragment>
);

const RegistrationProviderFacilitiesGridSmall = ({ data, isLoading, onEditClicked, onDeleteClicked }) => (
  <div className={[global.SmallTable, 'hidden-md hidden-lg'].join(' ')}>
    {isLoading === true
      ? (
        <div className={global.SmallTableRow}>
          <div className={global.SmallTableRowHead}>&nbsp;</div>
          <div className={global.SmallTableRowInfo}>
            <div className={global.SmallTableRowLabels}>Loading...</div>
          </div>
        </div>
      )
      : data.length > 0
        ? data.map((facility, i) => <GridRow key={i} facility={facility} onEditClicked={onEditClicked} onDeleteClicked={onDeleteClicked} />)
        : (
          <div className={global.SmallTableRow}>
            <div className={global.SmallTableRowHead}>&nbsp;</div>
            <div className={global.SmallTableRowInfo}>
              <div className={global.SmallTableRowLabels}>No Facilities</div>
            </div>
          </div>
        )}
  </div>
);

export default RegistrationProviderFacilitiesGridSmall;