import React, { Fragment } from 'react';

import SATLeaderboardGridLarge from './SATLeaderboardGridLarge';
import SATLeaderboardGridSmall from './SATLeaderboardGridSmall';

const SATLeaderboardGrid = ({ data, isLoading }) => (
  <Fragment>
    <SATLeaderboardGridLarge data={data} isLoading={isLoading} />
    <SATLeaderboardGridSmall data={data} isLoading={isLoading} />
  </Fragment>
);

export default SATLeaderboardGrid;