import React, { Fragment } from 'react';

import useClubRegistrationSubmit from './UseClubRegistrationSubmit';

import ClubRegistrationConstants from '../utils/ClubRegistrationConstants';

import ValidationPopUpModal from '../components/ValidationPopUpModal';

import LeftNav from '../../../../common/components/leftNav/LeftNav';
import Headings from '../../../../common/components/headings/Headings';
import Input from '../../../../common/components/inputs/Input';
import ReadOnly from '../../../../common/components/readOnly/ReadOnly';
import PrimaryButton from '../../../../common/components/buttons/PrimaryButton';
import SecondaryButton from '../../../../common/components/buttons/SecondaryButton';
import PopUpModal from '../../../../common/components/dialogs/PopUpModal';

import Constants from '../../../../common/utils/Constants';
import { FormatMoneyWithSymbol } from '../../../../common/utils/FormatMoney';

const ClubRegistrationSubmit = () => {
  const { 
    clubRegistrationNavState, 
    clubRegistrationState, 
    clubRegistrationPaymentState, 
    modalState, 
    state,
    offeringTypeState, 
    handleSubmit, 
    formState, 
    errorState, 
    uiState,
    onFormValueChanged, 
    onModalContinue, 
    onModalCanceled,
    onSaveAndExitClicked, 
    onBackClicked
  } = useClubRegistrationSubmit();

  return (
    <LeftNav title={ClubRegistrationConstants.LEFT_NAV_TITLE} state={clubRegistrationNavState} validateBeforeRedirect={false}>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>Submit</Headings.H3>
        </div>
      </div>
      <Fragment>
        <div className="row">
          <div className="col-xs-12">
            <p><b>Club Name:</b> {clubRegistrationState.objData.orgUnit.orgUnitName}</p>
            <p><b>Club Type:</b> {formState.offeringName}</p>
            <p><b>USA Swimming Fee:</b> {formState.usasFeeAmount !== '' ? FormatMoneyWithSymbol(formState.usasFeeAmount) : '0'}</p>
            <p><b>LSC Fee:</b> {formState.lscFeeAmount !== '' ? FormatMoneyWithSymbol(formState.lscFeeAmount) : '0'}</p>
            <p><b>Fee Paid:</b> {formState.selectedRegistration.paymentProcessed === true ? 'Yes' : 'No'}</p>
            {/* <p><b>Fee Disclaimer:</b> The fees mentioned here refer solely to USA Swimming fees. LSC fees are handled in a separate process.</p> */}
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12">
            <p>{uiState.message}</p>
          </div>
        </div>
      </Fragment>
      <form onSubmit={handleSubmit} noValidate>
        {uiState.message.length === 0 &&
        <>
        <div className="row usas-extra-top-margin">
          <div className="col-xs-12">
            <p>By signing below, I certify that the information given on this form is truthful, accurate, and complete.</p>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12 col-sm-6 col-md-4">
            <Input
              label="First and Last Name*"
              name="registrationSignature"
              value={formState.registrationSignature}
              error={errorState.registrationSignature}
              message={errorState.registrationSignature}
              onChange={(value) => {
                onFormValueChanged('registrationSignature', value);
                onFormValueChanged('registrationDate', formState.today);
              }} />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4">
            <ReadOnly
              label="Signature Date"
              name="registrationDate"
              value={formState.registrationDate} />
          </div>
        </div>
        </>
        } 
        <Fragment>
          <div className="row usas-extra-top-margin usas-extra-bottom-margin">
            <div className="col-xs-12 usas-extra-top-margin">
              {clubRegistrationState.objData.status === ClubRegistrationConstants.IN_PROGRESS_STATUS || clubRegistrationState.objData.status === ClubRegistrationConstants.RENEWING_STATUS ?
                <PrimaryButton type="button" onClick={() => onSaveAndExitClicked()}>Submit</PrimaryButton> : <Fragment />}&nbsp;
              <SecondaryButton type="button" onClick={() => onBackClicked()}>Back</SecondaryButton>
            </div>
          </div>
        </Fragment>
      </form>
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={clubRegistrationState.isObjLoading || offeringTypeState.isArrayLoading} />
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.SAVING_MSG}
        displayPopUp={clubRegistrationState.isSaving} />
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.PROCESSING_PAYMENT_MSG}
        displayPopUp={clubRegistrationPaymentState.isProcessing} />
      <ValidationPopUpModal
        clubRegistrationState={clubRegistrationState}
        state={state}
        modalState={modalState}
        onModalContinue={onModalContinue}
        onModalCanceled={onModalCanceled}
      />
    </LeftNav>
  );
};

export default ClubRegistrationSubmit;