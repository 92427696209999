import { PersonHttpHelper, NO_DATA_MESSAGE, BASIC_INITIAL_STATE } from '../../../common/utils/HttpHelper';

const postClubRosterVendorSyncData = (memberSyncArray, state, setState) => {
  if (state.isSaving !== true && !state.message) {
    let newState = {
      ...state,
      isSaving: true,
      isSaved: false,
      message: 'Saving...'
    };
    setState(newState);

    const url = `/person/MemberDetailsUpdateEvent`;
    PersonHttpHelper(url, 'POST', memberSyncArray)
      .then((objData) => {
        if (!objData) {
          throw new Error(NO_DATA_MESSAGE);
        } else {
          newState = {
            ...newState,
            isSaving: false,
            isSaved: true,
            objData,
            message: ''
          };
          setState(newState);
        }

      })
      .catch((e) => {
        const message = e && e.message ? e.message : NO_DATA_MESSAGE;
        setState({
          ...newState,
          isSaving: false,
          isSaved: false,
          message: message
        });
      });
  }
};

const ClubRosterVendorSyncData = { INITIAL_STATE: BASIC_INITIAL_STATE, postClubRosterVendorSyncData };

export default ClubRosterVendorSyncData;

