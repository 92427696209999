import React, { Fragment } from "react";

import LSCBoardMembersGridLarge from "./LSCBoardMembersGridLarge";
import LSCBoardMembersGridSmall from "./LSCBoardMembersGridSmall";

import useExpandedRow from "../../../../common/components/grids/UseExpandedRow";

const LSCBoardMembersGrid = ({ data, isLoading, onEdit, onDelete }) => {
  const { expandedId, onClick } = useExpandedRow();
  return (
    <Fragment>
      <LSCBoardMembersGridLarge data={data} isLoading={isLoading} onEdit={onEdit} onDelete={onDelete} expandedId={expandedId} onClick={onClick} />
      <LSCBoardMembersGridSmall data={data} isLoading={isLoading} onEdit={onEdit} onDelete={onDelete} expandedId={expandedId} onClick={onClick} />
    </Fragment>
  );
};

export default LSCBoardMembersGrid;