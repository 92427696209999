import { OmrHttpHelper, NO_DATA_MESSAGE } from '../../utils/HttpHelper';

const GetOfferingTypesForClubRegistrationData = (orgUnitId, age, category, personId, state, setState) => {
  if (state.isArrayLoading !== true
    && !state.message && (
      state.isArrayLoaded !== true
      || age !== state.age
      || orgUnitId !== state.orgUnitId
      || category !== state.category
      || personId !== state.personId)) {
    const newState = {
      ...state,
      isArrayLoading: true,
      isArrayLoaded: false,
      message: 'Loading...',
      orgUnitId,
      age,
      category,
      personId
    };
    setState(newState);

    const orgUnitIdForUrl = orgUnitId ? encodeURIComponent(orgUnitId) : 'NaN';
    const personIdForUrl = personId ? encodeURIComponent(personId) : '';
    const url = `/OfferingType/ClubRegistration?orgUnitId=${orgUnitIdForUrl}&age=${age}&category=${category}&personId=${personIdForUrl}`;
    OmrHttpHelper(url, 'GET')
      .then((arrayData) => {
        if (!arrayData) {
          throw new Error(NO_DATA_MESSAGE);
        } else {
          setState({
            ...newState,
            isArrayLoading: false,
            isArrayLoaded: true,
            arrayData,
            message: '',
          });
        }
      })
      .catch((e) => {
        const message = e && e.message ? e.message : NO_DATA_MESSAGE;
        setState({
          ...newState,
          isArrayLoading: false,
          isArrayLoaded: false,
          message: message
        });
      });
  }
};

export default GetOfferingTypesForClubRegistrationData;