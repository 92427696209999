import { BLANK_DATE_STRING } from "../../../common/utils/Constants";
import { isValidDate } from "../../../common/utils/validation";

export const localValidate = (formState) => {
  let errors = {};
  const today = new Date();
  const effectiveDate = new Date(formState.effectiveDate);
  const expirationDate = new Date(formState.expirationDate);

  if (formState.expirationDate === BLANK_DATE_STRING
    || !isValidDate(formState.expirationDate)) {
    errors.expirationDate = 'Expiration Date must be a valid date';
  } else if (expirationDate < effectiveDate) {
    errors.expirationDate = `Expiration Date must be after the Effective Date(${formState.effectiveDate})`;
  } else if (expirationDate > today) {
    errors.expirationDate = 'Expiration Date cannot be in the future';
  }

  return errors;
};

const RevokeSSRPFormValidation = async (formState) => {
  let errors = localValidate(formState);

  return errors ? errors : {};
};

export default RevokeSSRPFormValidation;