import { Fragment } from 'react';

import useClubThirdPartyInvoices from './UseClubThirdPartyInvoices';

import ClubThirdPartyInvoicesGrid from './ClubThirdPartyInvoicesGrid';
import ClubThirdPartyInvoicesDetail from './ClubThirdPartyInvoicesDetail';

import Headings from '../../../../common/components/headings/Headings';
import PopUpModal from '../../../../common/components/dialogs/PopUpModal';

import Constants from '../../../../common/utils/Constants';

import style from './ClubThirdPartyInvoices.module.css';

const ClubThirdPartyInvoices = () => {
  const {
    modalState,
    onModalCanceled,
    isLoading,
    clubThirdPartyInvoiceState,
    onEditClicked
  } = useClubThirdPartyInvoices();

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>Third Party Invoices</Headings.H3>
        </div>
      </div>
      <div className='row usas-extra-top-margin usas-extra-bottom-margin'>
        <div className="col-xs-12">
          <ClubThirdPartyInvoicesGrid
            gridData={clubThirdPartyInvoiceState.arrayData}
            isLoading={clubThirdPartyInvoiceState.isArrayLoading}
            onEditClicked={onEditClicked} />
        </div>
      </div>
      <PopUpModal
        id={"modalId"}
        className={style.PopUpModalMargin}
        widthPct={98}
        maxWidth={2560}
        title={modalState.modalTitle}
        displayPopUp={modalState.displayPopUp}
        onModalCanceled={onModalCanceled}>
        <div className='row'>
          <div className='col-xs-12' >
            <ClubThirdPartyInvoicesDetail
              modalState={modalState}
              onModalCanceled={onModalCanceled}
            />
          </div>
        </div>
      </PopUpModal>
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={isLoading} />
    </Fragment>
  );
};

export default ClubThirdPartyInvoices;