import { PRIMARY_CONTACT_ORG_ROLE_NAME } from "./LearnToSwimConstants";

import Constants from "../../../../common/utils/Constants";
import { formatDate } from "../../../../common/utils/DateFunctions";

const isValidLink = (link) => {
  try {
    const trimmedLink = link.trim();
    const http = trimmedLink.substring(0, 7);

    if (http === 'http://' && link.length > 7) {
      return true;
    } else {
      const https = trimmedLink.substring(0, 8);

      if (https === 'https://' && link.length > 8) {
        return true;
      } else {
        return false;
      }
    }
  } catch {
    return false;
  }
};

const createProviderObjFromProviderInfoForm = (formState, providerObj) => {
  return {
    ...providerObj,
    entityName: formState.entityName,
    tradeName: formState.tradeName,
    taxId: formState.taxId,
    learnToSwimCurriculumId: formState.learnToSwimCurriculumId,
    website: formState.website,
    offersFinancialAssistance: formState.offersFinancialAssistance,
    currentLTSGrantRecipient: formState.currentLTSGrantRecipient,
    affiliatedWithUsasClub: formState.affiliatedWithUsasClub,
    childProtectionTraining: formState.childProtectionTraining,
    offerAdultLearnToSwim: formState.offerAdultLearnToSwim,
    offerSpecialNeeds: formState.offerSpecialNeeds,
    backgroundCheckAcknowledgement: formState.backgroundCheckAcknowledgement,
    backgroundCheckNotUsedExplanation: formState.backgroundCheckNotUsedExplanation,
    indemnificationAgreement: formState.indemnificationAgreement,
    indemnificationAgreementSignature: formState.indemnificationAgreementSignature,
    indemnificationAgreementSignatureDate: formState.indemnificationAgreementSignatureDate,
    usasClubId: formState.affiliatedWithUsasClub === true ? formState.usasClubId : '',
    logo: formState.logoUrl,
    curriculumUrl: formState.curriculumUrl,
    proofOfInsuranceUrl: formState.proofOfInsuranceUrl,
    formW9Url: formState.formW9Url
  };
};

const createOrgUnitFacilityObjFromFacilityContactAddForm = (formState, orgUnitFacilityObj) => {
  const today = new Date();
  const todayFormatted = new Date(formatDate(today));

  return {
    ...orgUnitFacilityObj,
    clubFacilityContactName: formState.clubFacilityContactName,
    clubFacilityContactEmailAddress: formState.clubFacilityContactEmailAddress,
    clubFacilityContactPhoneNumber: formState.clubFacilityContactPhoneNumber,
    facilityContactName: formState.contactName,
    facilityContactEmailAddress: formState.contactEmailAddress,
    facilityContactPhoneNumber: formState.contactPhoneNumber,
    effectiveDate: orgUnitFacilityObj.effectiveDate || todayFormatted,
    expirationDate: orgUnitFacilityObj.expirationDate || Constants.DEFAULT_MAX_DATE,
    trainingFacility: true,
    facility: {
      ...orgUnitFacilityObj.facility,
      contactName: formState.contactName,
      contactEmailAddress: formState.contactEmailAddress,
      contactPhoneNumber: formState.contactPhoneNumber
    }
  };
};

const findLocalDupPerson = (staffArray, newFirstName, newLastName, newBirthDate) => {
  if (Array.isArray(staffArray) && staffArray.length > 0) {
    for (const staff of staffArray) {
      const birthDate = formatDate(staff.birthDate);
      const firstName = staff.firstName;
      const lastName = staff.lastName;

      if (birthDate === newBirthDate && firstName === newFirstName && lastName === newLastName) {
        return staff;
      }
    }
  }

  return null;
};

const findCurrentPrimaryContact = (staffArray) => {
  if (Array.isArray(staffArray)) {
    for (const staff of staffArray) {
      if (Array.isArray(staff.personOrgRole)) {
        const isPrimaryContact = staff.personOrgRole.some((x) => x.orgRoleName === PRIMARY_CONTACT_ORG_ROLE_NAME);

        if (isPrimaryContact === true) {
          return staff;
        }
      }
    }
  }

  return null;
};

const createStaffMember = (personObj, formState) => {
  return {
    personId: personObj.personId,
    email: formState.email || undefined,
    phoneNumber: formState.phoneNumber || undefined,
    personOrgRole: formState.orgRoles.map((x) => {
      return {
        orgRoleId: x.id || x.orgRoleId,
        orgRoleName: x.name || x.orgRoleName,
        personOrgRoleFields: []
      };
    })
  }
};

const getUploadedFileName = (fileUrl) => {
  try {
    const splitStringArray = fileUrl.split('/');
    const indexOfEndOfDateTime = splitStringArray[splitStringArray.length - 1].indexOf('.') + 1;
    const fileName = splitStringArray[splitStringArray.length - 1].substring(indexOfEndOfDateTime);
    return decodeURI(fileName);
  } catch {
    return fileUrl;
  }
};

export {
  isValidLink, createProviderObjFromProviderInfoForm, createOrgUnitFacilityObjFromFacilityContactAddForm,
  findLocalDupPerson, findCurrentPrimaryContact, createStaffMember, getUploadedFileName
};