import React from 'react';

import ClubRegistrationStaffGridWrite from './ClubRegistrationStaffGridWrite';

import PrimaryButton from '../../../../common/components/buttons/PrimaryButton';
import SecondaryButton from '../../../../common/components/buttons/SecondaryButton';
import ActionIntraPageButton from '../../../../common/components/buttons/ActionIntraPageButton'

const StaffGridComponentWrite = ({ 
  onAddOptionalStaff, usasClubStaffState, onEdit, onDelete, 
  onContinueClicked, onSaveAndExitClicked, onBackClicked, registrationRoles }) => (
  <div>
    <div className="row">
      <div className="col-xs-12 col-sm-4 usas-extra-top-margin">
        <ActionIntraPageButton type="button" onClick={() => onAddOptionalStaff()}>Add Staff</ActionIntraPageButton>
      </div>
    </div>
    <div className="row usas-extra-top-margin">
      <div className="col-xs-12 usas-extra-bottom-margin">
        <ClubRegistrationStaffGridWrite state={usasClubStaffState} onEdit={onEdit} onDelete={onDelete} registrationRoles={registrationRoles} />
      </div>
    </div>
    <div className="row usas-extra-top-margin usas-extra-bottom-margin">
      <div className="col-xs-12">
        <PrimaryButton type="button" onClick={() => onContinueClicked()}>Continue</PrimaryButton>&nbsp;
        <PrimaryButton type="button" onClick={() => onSaveAndExitClicked()}>Save &amp; Exit</PrimaryButton>&nbsp;
        <SecondaryButton type="button" onClick={() => onBackClicked()}>Back</SecondaryButton>
      </div>
    </div>
  </div>
)

export default StaffGridComponentWrite;