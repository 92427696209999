import { useState } from 'react';

import KpiMeasureData from './KpiMeasureData';

const useKpiMeasureData = () => {
  const [kpiMeasureState, setKpiMeasureState] = useState(KpiMeasureData.INITIAL_STATE);

  const getKpiMeasure = (programLevelInstanceId, categoryMeasureId, kpiUrl) => {
    KpiMeasureData.getKpiMeasureData(programLevelInstanceId, categoryMeasureId, kpiUrl, kpiMeasureState, setKpiMeasureState);
  };

  const postKpiMeasure = (programLevelInstanceId, categoryMeasureId, kpiUrl, submittedValue = undefined) => {
    KpiMeasureData.postKpiMeasureData(programLevelInstanceId, categoryMeasureId, submittedValue, kpiUrl, kpiMeasureState, setKpiMeasureState);
  };

  const putKpiMeasure = (programLevelInstanceId, categoryMeasureId, categoryMeasureScoreId, kpiUrl, submittedValue = undefined) => {
    KpiMeasureData.putKpiMeasureData(programLevelInstanceId, categoryMeasureId, categoryMeasureScoreId, submittedValue, kpiUrl, kpiMeasureState, setKpiMeasureState);
  };

  return {
    kpiMeasureState,
    getKpiMeasure,
    postKpiMeasure,
    putKpiMeasure
  };
};

export default useKpiMeasureData;