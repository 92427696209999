import { isValidName } from '../../../../common/utils/validation';

const PreCompRegistrationSubmitValidation = (formState) => {
  let errors = {};

  if (formState.signature.trim() === '') {
    errors.signature = 'Signature is required';
  } else if (!isValidName(formState.signature.trim())) {
    errors.signature = 'Signature cannot contain numbers, special characters, or exceed 100 characters';
  }

  return errors;
};

export default PreCompRegistrationSubmitValidation;