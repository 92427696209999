import { useState } from 'react';

import LearnToSwimFormW9Data from './LearnToSwimFormW9Data';

import { BASIC_INITIAL_STATE } from '../../../common/utils/HttpHelper';

const INITIAL_LTS_FORM_W9_STATE = {
  ...BASIC_INITIAL_STATE
};

const useLearnToSwimFormW9Data = () => {
  const [ltsFormW9State, setLTSFormW9State] = useState(INITIAL_LTS_FORM_W9_STATE);

  const postLTSFormW9 = (formW9File) => {
    return LearnToSwimFormW9Data.postLTSFormW9Data(formW9File, ltsFormW9State, setLTSFormW9State);
  };

  return {
    ltsFormW9State,
    postLTSFormW9
  };
};

export default useLearnToSwimFormW9Data;