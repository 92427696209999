import { PreCompHttpHelper, BASIC_INITIAL_STATE, NO_DATA_MESSAGE } from '../../../common/utils/HttpHelper';
import HierarchicalDataUtils from '../../../common/utils/HierarchicalDataUtils';

const INITIAL_STATE = {
  ...BASIC_INITIAL_STATE,
  treeData: []
};

const getPreCompOfferingTypes = (state, setState) => {
  if (state.isArrayLoading !== true && !state.message) {
    let newState = {
      ...state,
      isArrayLoading: true,
      isArrayLoaded: false,
      message: 'Loading...'
    };
    setState(newState);

    const url = `/PreCompetitiveOfferingType`;
    PreCompHttpHelper(url, 'GET')
      .then((arrayData) => {
        if (!arrayData) {
          throw new Error(NO_DATA_MESSAGE);
        } else {
          newState = {
            ...newState,
            isArrayLoading: false,
            isArrayLoaded: true,
            arrayData,
            treeData: HierarchicalDataUtils.MapArrayToTreeData(arrayData, 'id', 'name'),
            message: ''
          };
          setState(newState);
        }
      })
      .catch((e) => {
        const message = e && e.message ? e.message : NO_DATA_MESSAGE;
        setState({
          ...newState,
          isArrayLoading: false,
          isArrayLoaded: false,
          message: message
        });
      });
  }
};

const PreCompOfferingTypeData = {
  INITIAL_STATE,
  getPreCompOfferingTypes
};

export default PreCompOfferingTypeData;