import { ClubHttpHelper, NO_DATA_MESSAGE } from '../../../../common/utils/HttpHelper';

const GetClubRegistrationStatusData = (clubId, state, setState) => {
  if (state.isObjLoading !== true && !state.message) {
    let newState = {
      ...state,
      isObjLoading: true,
      isObjLoaded: false,
      message: 'Loading...'
    };
    setState(newState);

    const url = `/UsasClub/${clubId}/status`;
    ClubHttpHelper(url, 'GET')
      .then((objData) => {
        if (!objData) {
          throw new Error(NO_DATA_MESSAGE);
        } else {
          newState = {
            ...newState,
            isObjLoading: false,
            isObjLoaded: true,
            objData,
            message: ''
          };

          setState(newState);
        }
      })
      .catch((e) => {
        const message = e && e.message ? e.message : NO_DATA_MESSAGE;
        setState({
          ...newState,
          isObjLoading: false,
          isObjLoaded: message === NO_DATA_MESSAGE,
          message: message
        });
      });
  }
};

export default GetClubRegistrationStatusData;