import { BASIC_INITIAL_STATE } from '../../../common/utils/HttpHelper';

import postRecFilesData from './PostRecFilesData';

const BASE_UPLOAD_URL = 'https://recdocs.blob.core.windows.net/upload-files/';
const GET_REC_FILES_PATH = '/RecFiles?fileUrl=';

const INITIAL_STATE = {
  ...BASIC_INITIAL_STATE
};

const PostRecFilesData = {
  BASE_UPLOAD_URL,
  GET_REC_FILES_PATH,
  INITIAL_STATE,
  postRecFilesData
};

export default PostRecFilesData;