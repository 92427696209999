import { Fragment } from 'react';

import CopyIcon from '../../../../common/components/icons/CopyIcon';

// import Constants from '../../../../common/utils/Constants';
// import { formatDate } from '../../../../common/utils/DateFunctions';

import global from '../../../../common/components/GlobalStyle.module.css';

const LargeClubGenerateRegistrationLinkGrid = ({ state, onOpenNewLinkModalClicked, onClickCopyToClipboard }) => (
	<Fragment>
		<table className={[global.UsasTable, 'visible-md visible-lg'].join(' ')}>
			<thead>
				<tr>
					<th>Membership Group</th>
					{/* <th>Effective Date</th>
					<th>Expiration Date</th> */}
					<th>Custom Link</th>
					<th></th>
				</tr>
			</thead>
			<tbody>
				{state.isArrayLoaded !== true
					? (
						<tr>
							{state.isArrayLoading === true
								? <td colSpan="3">Loading...</td>
								: <td colSpan="3">No Results</td>}
						</tr>)
					: Array.isArray(state.arrayData) === true && state.arrayData.length > 0
						? state.arrayData.map((link, i) => (
							<tr key={i}>
								<td>{link?.routeCodeName}</td>
								{/* <td>{formatDate(link?.effectiveDate) || Constants.BLANK_DATE_STRING}</td>
								<td>{formatDate(link?.expirationDate) || Constants.BLANK_DATE_STRING}</td> */}
								<td><button
									className={global.IconButtonMargin}
									type="button"
									onClick={(e) => onClickCopyToClipboard(e, link.resolvedUrl)}> <CopyIcon />
								</button></td>
								<td><button className={global.AnchorButton} onClick={(e) => onOpenNewLinkModalClicked(e, link)}><span>Generate New Link</span></button></td>
							</tr>))
						: (
							<tr>
								<td colSpan="3">No Results</td>
							</tr>
						)
				}
			</tbody>
		</table>
	</Fragment>
);

export default LargeClubGenerateRegistrationLinkGrid;