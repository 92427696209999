import { BASIC_INITIAL_STATE } from "../../../../common/utils/HttpHelper";

import postSwimAThonContractData from "./PostSwimAThonContractData";
import putSwimAThonContractData from "./PutSwimAThonContractData";

const SwimAThonContractData = {
  INITIAL_STATE: { ...BASIC_INITIAL_STATE },
  postSwimAThonContractData,
  putSwimAThonContractData
};

export default SwimAThonContractData;