import { useState } from 'react';

import ProgramLevelInstanceData from './ProgramLevelInstanceData';

const useProgramLevelInstanceData = () => {
  const [submitLevelState, setSubmitLevelState] = useState(ProgramLevelInstanceData.INITIAL_STATE);

  const postProgramLevelInstanceSubmitLevel = (programLevelInstanceId) => {
    ProgramLevelInstanceData.postProgramLevelInstanceSubmitLevelData(programLevelInstanceId, submitLevelState, setSubmitLevelState);
  };

  return {
    submitLevelState,
    postProgramLevelInstanceSubmitLevel
  };
};

export default useProgramLevelInstanceData;