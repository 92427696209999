import React, { Fragment } from "react";

import HideIcon from "../../../../../common/components/icons/HideIcon";
import ShowIcon from "../../../../../common/components/icons/ShowIcon";

import Checkbox from "../../../../../common/components/checkboxes/Checkbox";

import { CURRENCY_FORMATTER } from "../../../../../common/utils/Constants";
import { formatDate } from "../../../../../common/utils/DateFunctions";

import global from '../../../../../common/components/GlobalStyle.module.css';

const DetailTableRow = ({ offering, onToggleOfferingApproved }) => (
  <div className={global.SmallTableRow}>
    <div className={global.SmallTableDetailRowHead}>
      <span className="hidden-xs">{offering.firstName || <span>&nbsp;</span>} {offering.lastName || <span>&nbsp;</span>}</span>&nbsp;
    </div>
    <div className={global.SmallTableRowInfo}>
      <div className="row">
        <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3 visible-xs'].join(' ')}>Member Name</div>
        <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3 visible-xs'].join(' ')}>{offering.firstName || <span>&nbsp;</span>} {offering.lastName || <span>&nbsp;</span>}</div>
        <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3'].join(' ')}>Registration Date</div>
        <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3'].join(' ')}>{offering.registrationDateTime ? formatDate(offering.registrationDateTime) : <span>&nbsp;</span>}</div>
        <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3'].join(' ')}>New/Renew</div>
        <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3'].join(' ')}>{offering.registrationType}</div>
        <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3'].join(' ')}>Offering</div>
        <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3'].join(' ')}>{offering.offeringName || <span>&nbsp;</span>}</div>
        <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3'].join(' ')}>Fee Amount</div>
        <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3'].join(' ')}>{offering.feeAmount > 0 ? CURRENCY_FORMATTER.format(offering.feeAmount) : offering.feeAmount === 0 ?
          '$0.00 - BOGO' : <span>&nbsp;</span>}</div>
        <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3'].join(' ')}>LSC Approved?</div>
        <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3'].join(' ')}>
          <Checkbox
            label=""
            name="isLscApproved"
            checked={offering.isLscApproved}
            onChange={() => { onToggleOfferingApproved(offering.memberRegistrationId); }} />
        </div>
      </div>
    </div>
  </div>
);

const DetailTable = ({ offerings, onToggleOfferingApproved }) => (
  <div className={global.SmallTableDetailTable}>
    {Array.isArray(offerings) && offerings.map((offering, i) =>
      <DetailTableRow key={i} offering={offering} onToggleOfferingApproved={onToggleOfferingApproved} />)}
  </div>
);

const GridRow = ({ club, onToggleClubApproved, onToggleOfferingApproved, expandedId, onClick }) => {
  return (
    <Fragment>
      <div className={global.SmallTableRow}>
        <div className={global.SmallTableRowHead}>
          <span className="hidden-xs">{club.orgUnitName || <span>&nbsp;</span>}</span>
          <button
            className={global.IconButton}
            type="button"
            onClick={(e) => onClick(e, club.orgUnitId)}>
            {expandedId === club.orgUnitId ? <HideIcon /> : <ShowIcon />}
          </button>&nbsp;
        </div>
        <div className={global.SmallTableRowInfo}>
          <div className="row">
            <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3 visible-xs'].join(' ')}>Club Name</div>
            <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3 visible-xs'].join(' ')}>{club.orgUnitName || <span>&nbsp;</span>}</div>
            <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3'].join(' ')}>Registration Year</div>
            <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-9'].join(' ')}>{club.periodName || <span>&nbsp;</span>}</div>
            <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3'].join(' ')}>Total Fee Amount</div>
            <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3'].join(' ')}>{club.totalFeeAmount > 0 ?
              CURRENCY_FORMATTER.format(club.totalFeeAmount) : club.totalFeeAmount === 0 ? '$0.00 - BOGO' : <span>&nbsp;</span>}</div>
            <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3'].join(' ')}>LSC Approved?</div>
            <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3'].join(' ')}>
              <Checkbox
                label=""
                name="isLscApproved"
                checked={club.isLscApproved}
                onChange={() => { onToggleClubApproved(club.orgUnitId); }} />
            </div>
          </div>
        </div>
      </div>
      {Array.isArray(club.members) && club.members.length > 0 && expandedId === club.orgUnitId &&
        <DetailTable offerings={club.members} onToggleOfferingApproved={onToggleOfferingApproved} />}
    </Fragment >
  );
};

const LSCRegistrationManagementGridSmall = ({ data, isLoading, onToggleClubApproved, onToggleOfferingApproved, expandedId, onClick }) => (
  <div className={[global.SmallTable, 'hidden-md hidden-lg'].join(' ')}>
    {isLoading === true
      ? (
        <div className={global.SmallTableRow}>
          <div className={global.SmallTableRowHead}>&nbsp;</div>
          <div className={global.SmallTableRowInfo}>
            <div className={global.SmallTableRowLabels}>Loading...</div>
          </div>
        </div>
      )
      : data.length > 0
        ? data.map((club, i) => <GridRow key={i} club={club} onToggleClubApproved={onToggleClubApproved}
          onToggleOfferingApproved={onToggleOfferingApproved} expandedId={expandedId} onClick={onClick} />)
        : (
          <div className={global.SmallTableRow}>
            <div className={global.SmallTableRowHead}>&nbsp;</div>
            <div className={global.SmallTableRowInfo}>
              <div className={global.SmallTableRowLabels}>No Offerings</div>
            </div>
          </div>
        )
    }
  </div>
);

export default LSCRegistrationManagementGridSmall;