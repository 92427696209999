import { useEffect, useState } from 'react';
import { useNavigate } from '../../../../common/wrappers/ReactRouterDom';

import NavLinks from '../NavLinks';

import validate from '../../preComp/components/forms/PreCompOrganizationSpecialInfoFormValidation';

import { createOrgObjFromOrgInfoForm } from '../../preComp/utils/PreCompUtils';
import usePreCompLocationRegistrationData from '../../../state/preCompLocationRegistration/UsePreCompLocationRegistrationData';
import usePreCompOrganizationRegistrationData from '../../../state/preCompOrganizationRegistration/UsePreCompOrganizationRegistrationData';
import usePreCompLocationRegistrationNavData from '../../../state/preCompLocationRegistration/preCompLocationRegistrationLeftNavBar/UsePreCompLocationRegistrationNavData';

import useSearchFacilityData from '../../../../common/state/searchFacility/UseSearchFacilityData';
import useFacilityData from '../../../../common/state/facility/UseFacilityData';
import useSearchClubData from '../../../../common/state/searchClub/UseSearchClubData';
import useBaseUsasClubData from '../../../../common/state/clubBase/UseBaseUsasClubData';

import useForm from '../../../../common/utils/UseForm';

const usePreCompRegistrationEntry = () => {
  const navigate = useNavigate();
  const { preCompOrganizationRegistrationState, saveObjData, resetPreCompOrganizationRegistrationState,
    confirmSave, getPreCompOrganizationDupCheck } = usePreCompOrganizationRegistrationData();
  const { resetPreCompLocationRegistrationState, setupPreCompLocationRegistrationState } = usePreCompLocationRegistrationData();
  const { resetNavState, setupNavState } = usePreCompLocationRegistrationNavData();
  const { resetSearchFacilityState } = useSearchFacilityData();
  const { clearFacilityArrayData, clearFilterState } = useFacilityData();
  const { resetSearchClubState } = useSearchClubData();
  const { clearBaseUsasClubFilterState, clearBaseUsasClubArrayData } = useBaseUsasClubData();
  const { formState, errorState, handleSubmit, resetForm, setIsDirty, updateFormState, setErrors
  } = useForm(getInitialFormState, submitFormCallback, validate);
  const [dupState, setDupState] = useState(getInitialDupState);
  const [redirectState, setRedirectState] = useState(getInitialRedirectState);

  const onErrorModalCancelClicked = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    setErrors({});
  };

  const onDupModalCancelClicked = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    resetForm();
    setIsDirty(true);
    setDupState(getInitialDupState);
  };

  const onDupModalContinueClicked = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    const orgObj = preCompOrganizationRegistrationState.objData;
    orgObj.preCompetitiveOrganizationId = dupState.objData.preCompetitiveOrganizationId;

    saveObjData(orgObj);
  };

  useEffect(() => {
    if (preCompOrganizationRegistrationState.isSaved === true) {
      confirmSave();
      setupPreCompLocationRegistrationState();

      if (preCompOrganizationRegistrationState.objData.preCompetitiveOrganizationId > 0) {
        setupNavState(false);
        setRedirectState({
          ...redirectState,
          tryRedirect: true,
          targetUrl: NavLinks.PRE_COMP_REGISTRATION_LOCATION_INFO
        });
      } else {
        setupNavState(true);
        setRedirectState({
          ...redirectState,
          tryRedirect: true,
          targetUrl: NavLinks.PRE_COMP_REGISTRATION_ORGANIZATION_INFO
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [preCompOrganizationRegistrationState]);

  useEffect(() => {
    if (dupState.isObjLoaded && !(dupState.objData?.preCompetitiveOrganizationId > 0)) {
      saveObjData(createOrgObjFromOrgInfoForm(preCompOrganizationRegistrationState.objData, formState, false, true));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dupState]);

  useEffect(() => {
    if (redirectState.tryRedirect === true) {
      navigate(redirectState.targetUrl);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [redirectState]);

  useEffect(() => {
    resetPreCompOrganizationRegistrationState();
    resetPreCompLocationRegistrationState();
    resetNavState();

    //Clear out any club or facility search data
    clearBaseUsasClubFilterState();
    clearBaseUsasClubArrayData();
    resetSearchClubState();

    clearFilterState();
    clearFacilityArrayData();
    resetSearchFacilityState();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function submitFormCallback(formState) {
    getPreCompOrganizationDupCheck(formState.federalTaxId, dupState, setDupState);
  };

  function getInitialFormState() {
    return {
      orgUnitName: '',
      federalTaxId: '',
      isSwimLessonInsured: ''
    };
  };

  function getInitialDupState() {
    return {
      objData: {},
      isObjLoaded: false,
      isObjLoading: false
    };
  };

  function getInitialRedirectState() {
    return {
      tryRedirect: false,
      targetUrl: ''
    };
  };

  return {
    isLoading: dupState.isObjLoading,
    displayDupModal: dupState.isObjLoaded && dupState.objData?.preCompetitiveOrganizationId > 0,
    formState,
    errorState,
    onFormValueChanged: updateFormState,
    handleSubmit,
    resetForm,
    onErrorModalCancelClicked,
    onDupModalContinueClicked,
    onDupModalCancelClicked
  };
};

export default usePreCompRegistrationEntry;