import { MeetHttpHelper, NO_DATA_MESSAGE } from '../../../utils/HttpHelper';
import HierarchicalDataUtils from '../../../utils/HierarchicalDataUtils'

const GetBPMeetClassificationsByOrgUnitIdData = (orgUnitId, filterId, filterMode, state, setState) => {
  if (state.isArrayLoading !== true && !state.message) {
    let newState = {
      ...state,
      orgUnitId,
      isArrayLoading: true,
      isArrayLoaded: false,
      message: 'Loading...'
    };
    setState(newState);

    const orgUnitIdForUrl = orgUnitId ? encodeURIComponent(orgUnitId) : 'NaN';
    const url = `/MeetClassification/OrgUnit/${orgUnitIdForUrl}?filterId=${filterId}&filterMode=${filterMode}`;
    MeetHttpHelper(url, 'GET')
      .then((arrayData) => {
        if (!arrayData) {
          throw new Error(NO_DATA_MESSAGE);
        } else {
          newState = {
            ...newState,
            orgUnitId,
            isArrayLoading: false,
            isArrayLoaded: true,
            arrayData,
            treeData: HierarchicalDataUtils.MapArrayToTreeData(arrayData, 'id', 'name'),
            message: ''
          };
          setState(newState);
        }
      })
      .catch((e) => {
        const message = e && e.message ? e.message : NO_DATA_MESSAGE;
        setState({
          ...newState,
          isArrayLoading: false,
          isArrayLoaded: false,
          message: message
        });
      });
  }
};

export default GetBPMeetClassificationsByOrgUnitIdData;