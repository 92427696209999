import React, { Fragment } from 'react';

import NavLinks from '../utils/NavLinks';

import AddEditFacilityPool from '../../../../common/components/searches/searchFacility/addEditPool/AddEditFacilityPool';

const LearnToSwimFacilitiesPoolsDetail = () => {
  return (
    <Fragment>
      <AddEditFacilityPool
        saveUrl={NavLinks.LEARN_TO_SWIM_FACILITIES}
        cancelUrl={NavLinks.LEARN_TO_SWIM_FACILITIES}
        showFacilityInfo={true} />
    </Fragment>
  );
};

export default LearnToSwimFacilitiesPoolsDetail;