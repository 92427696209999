import React, { Fragment } from 'react';

import usePreCompRegistrationLocationStaffDetail from './UsePreCompRegistrationLocationStaffDetail';

import PreCompLocationStaffForm from '../../preComp/components/forms/PreCompLocationStaffForm';
import PreCompLocationStaffDupGrid from '../../preComp/components/grids/PreCompLocationStaffDupGrid';
import PreCompLocationInstructorStaffForm from '../../preComp/components/forms/PreCompLocationInstructorStaffForm';

import Headings from '../../../../common/components/headings/Headings';
import PrimaryButton from '../../../../common/components/buttons/PrimaryButton';
import SecondaryButton from '../../../../common/components/buttons/SecondaryButton';
import PopUpModal from '../../../../common/components/dialogs/PopUpModal';
import LeftNav from '../../../../common/components/leftNav/LeftNav';
import PreCompRegistrationConstants from '../utils/PreCompRegistrationConstants';

import Constants from '../../../../common/utils/Constants';

const PreCompRegistrationLocationStaffDetail = () => {
  const {
    isLoading,
    isEdit,
    formState,
    errorState,
    dupGridState,
    newGridState,
    isPrimaryModalState,
    locationRegistrationNavState,
    displayLocalDupPersonModalState,
    onFormValueChanged,
    handleSubmit,
    onCancelClicked,
    onModalCancelClicked,
    onModalContinueClicked,
    onLocalDupPersonModalCancelClicked,
    onSelectGridPerson,
    onIsPrimaryModalCancelClicked,
    onIsPrimaryModalContinueClicked,
    PRECOMP_STAFF_ROLE_GROUP_ID,
    INSTRUCTOR_ORG_ROLE_ID
  } = usePreCompRegistrationLocationStaffDetail();

  return (
    <LeftNav title={PreCompRegistrationConstants.LEFT_NAV_TITLE} state={locationRegistrationNavState} validateBeforeRedirect={false}>
      <Headings.H3>{isEdit ? 'Edit' : 'Add'} Staff</Headings.H3>
      <form onSubmit={handleSubmit} noValidate>
        <PreCompLocationStaffForm
          formState={formState}
          errorState={errorState}
          onFormValueChanged={isEdit ? undefined : onFormValueChanged}
          onStaffRoleChanged={onFormValueChanged}
          PRECOMP_STAFF_ROLE_GROUP_ID={PRECOMP_STAFF_ROLE_GROUP_ID} />
        {formState.orgRolesId.find(x => x.id === INSTRUCTOR_ORG_ROLE_ID) !== undefined ?
          <div className="row usas-extra-top-margin">
            <div className="col-xs-12">
              <PreCompLocationInstructorStaffForm
                formState={formState}
                errorState={errorState}
                onFormValueChanged={onFormValueChanged} />
            </div>
          </div>
          : <Fragment />
        }
        {errorState.errorOnSubmitAction &&
          <div className="row">
            <div className="col-xs-12">
              <p className={global.ErrorMessage}>{errorState.errorOnSubmitAction || ''}</p>
            </div>
          </div>}
        <div className="row usas-extra-top-margin usas-extra-bottom-margin">
          <div className="col-xs-12 usas-extra-top-margin">
            <PrimaryButton type="submit">Save</PrimaryButton>&nbsp;
            <SecondaryButton type="button" onClick={onCancelClicked}>Back</SecondaryButton>
          </div>
        </div>
      </form>
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={isLoading} />
      <PopUpModal
        widthPct={90}
        maxWidth={1020}
        title={"Location Staff Dupe Check"}
        displayPopUp={dupGridState.length > 0}
        onModalCanceled={onModalCancelClicked}>
        <p><b>Database Matches Found</b></p>
        <div className="row usas-extra-bottom-margin">
          <div className="col-xs-12">
            <PreCompLocationStaffDupGrid
              actionLabel={'Use Existing Person'}
              persons={dupGridState}
              onSelected={onSelectGridPerson} />
          </div>
        </div>
        <p><b>Info Provided By User</b></p>
        <div className="row usas-extra-bottom-margin">
          <div className="col-xs-12">
            <PreCompLocationStaffDupGrid
              actionLabel={'Create New Person'}
              persons={newGridState}
              onSelected={onSelectGridPerson} />
          </div>
        </div>
        <div className="row usas-extra-top-margin">
          <div className="col-xs-12">
            <PrimaryButton type="button" onClick={onModalContinueClicked}>Continue</PrimaryButton>&nbsp;
            <SecondaryButton type="button" onClick={onModalCancelClicked}>Cancel</SecondaryButton>
          </div>
        </div>
      </PopUpModal>
      <PopUpModal
        widthPct={90}
        maxWidth={1020}
        title={isPrimaryModalState.isEditingCurrentPrimaryContact === false
          ? 'Replace Current Primary Contact?'
          : 'Error, Primary Contact Role Is Required'}
        displayPopUp={isPrimaryModalState.displayModal}
        onModalCanceled={onIsPrimaryModalCancelClicked}>
        <div className="row">
          <div className="col-xs-12">
            {isPrimaryModalState.isEditingCurrentPrimaryContact === false
              ? (
                <Fragment>
                  <p>Assigning {formState.firstName} {formState.lastName} the Primary Contact role will remove it from {isPrimaryModalState.currentPrimaryContact?.firstName} {isPrimaryModalState.currentPrimaryContact?.lastName}.</p>
                  {isPrimaryModalState.isOnlyRole === true && <p><b>{isPrimaryModalState.currentPrimaryContact?.firstName} {isPrimaryModalState.currentPrimaryContact?.lastName}'s only role is Primary Contact. Replacing them as Primary Contact will remove them from this location's staff.</b></p>}
                </Fragment>
              )
              : (
                <p>Cannot directly remove Primary Contact role from {isPrimaryModalState.currentPrimaryContact?.firstName} {isPrimaryModalState.currentPrimaryContact?.lastName}. To remove the Primary Contact role, assign the role to a different staff member.</p>
              )}
          </div>
        </div>
        <div className="row usas-extra-top-margin">
          <div className="col-xs-12">
            {isPrimaryModalState.isEditingCurrentPrimaryContact === false
              && (
                <Fragment>
                  <PrimaryButton type="button" onClick={onIsPrimaryModalContinueClicked}>Continue</PrimaryButton>&nbsp;
                </Fragment>
              )}
            <SecondaryButton type="button" onClick={onIsPrimaryModalCancelClicked}>Cancel</SecondaryButton>
          </div>
        </div>
      </PopUpModal>
      <PopUpModal
        widthPct={90}
        maxWidth={1020}
        title={'Duplicate Staff Detected'}
        displayPopUp={displayLocalDupPersonModalState === true}
        onModalCanceled={onLocalDupPersonModalCancelClicked}>
        <div className="row">
          <div className="col-xs-12">
            <p>{formState.firstName} {formState.lastName} ({formState.birthDate}) is already a staff member.</p>
          </div>
        </div>
        <div className="row usas-extra-top-margin">
          <div className="col-xs-12">
            <SecondaryButton type="button" onClick={onLocalDupPersonModalCancelClicked}>Cancel</SecondaryButton>
          </div>
        </div>
      </PopUpModal>
    </LeftNav >
  );
};

export default PreCompRegistrationLocationStaffDetail;