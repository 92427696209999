import React, { createContext, useState } from 'react';

import RouteCodeValueData from './RouteCodeValueData';

export const RouteCodeValueStateContext = createContext();

const RouteCodeValueContextProvider = ({ children }) => {
  const stateHook = useState(RouteCodeValueData.INITIAL_STATE);

  return (
    <RouteCodeValueStateContext.Provider value={stateHook}>
      {children}
    </RouteCodeValueStateContext.Provider>
  );
};

export default RouteCodeValueContextProvider;