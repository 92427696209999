import PreCompOrganizationBasicInfoFormValidation from '../components/forms/PreCompOrganizationBasicInfoFormValidation';
import PreCompOrganizationSpecialInfoFormValidation from '../components/forms/PreCompOrganizationSpecialInfoFormValidation';

const PreCompOrganizationInfoValidation = async (formState) => {
  let errors = {};

  const basicInfoFormValidation = await PreCompOrganizationBasicInfoFormValidation(formState);
  const specialInfoFormValidation = PreCompOrganizationSpecialInfoFormValidation(formState);

  errors = {    
    ...basicInfoFormValidation,
    ...specialInfoFormValidation
  }

  return errors ? errors : {};
};

export default PreCompOrganizationInfoValidation;