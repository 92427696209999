import React, { Fragment } from 'react';

import useClubGroupTypeTravelAdd from './UseClubGroupTypeTravelAdd';

import Headings from '../../../../../common/components/headings/Headings';
import PrimaryButton from '../../../../../common/components/buttons/PrimaryButton';
import SecondaryButton from '../../../../../common/components/buttons/SecondaryButton';
import PopUpModal from '../../../../../common/components/dialogs/PopUpModal';
import Constants from '../../../../../common/utils/Constants';
import GroupInfoForm from '../../../../../common/components/forms/groups/GroupInfoForm';
import TravelTripAttributesForm from '../../../../../common/components/forms/groups/TravelTripAttributesForm';

const ClubGroupTypeTravelAdd = () => {
  const {
    isLoading,
    isSaving,
    formState,
    errorState,
    handleSubmit,
    onFormValueChanged,
    onValueTextPairChanged,
    onBackClicked,
    usasClubId,
    orgUnitId
  } = useClubGroupTypeTravelAdd();

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>Add Travel Trip Group</Headings.H3>
        </div>
      </div>
      <form onSubmit={handleSubmit} noValidate>
        <GroupInfoForm
          formState={formState}
          errorState={errorState}
          onFormValueChanged={onFormValueChanged}
          orgUnitId={orgUnitId}
          attributeForm={<TravelTripAttributesForm
            formState={formState}
            errorState={errorState}
            onFormValueChanged={onFormValueChanged}
            onValueTextPairChanged={onValueTextPairChanged}
            usasClubId={usasClubId} />
          } />
        <div className="row">
          <div className="col-xs-12 usas-extra-top-margin usas-extra-bottom-margin">
            <PrimaryButton type="submit">Create Group</PrimaryButton>&nbsp;
            <SecondaryButton type="button" onClick={onBackClicked}>Back</SecondaryButton>
          </div>
        </div>
      </form>
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={isLoading} />
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.SAVING_MSG}
        displayPopUp={isSaving} />
    </Fragment>
  );
};

export default ClubGroupTypeTravelAdd;