import React, { Fragment } from 'react';

import useDropdownView from './UseDropdownView';

import CategoryMeasureDropdown from '../../dropdowns/categoryMeasureDropdown/CategoryMeasureDropdown';

import Headings from '../../../../common/components/headings/Headings';
import PopUpModal from '../../../../common/components/dialogs/PopUpModal';
import PrimaryButton from '../../../../common/components/buttons/PrimaryButton';
import SecondaryButton from '../../../../common/components/buttons/SecondaryButton';
import ReadOnly from '../../../../common/components/readOnly/ReadOnly';

import Constants from '../../../../common/utils/Constants';

const Label = ({ question }) => (
  <div dangerouslySetInnerHTML={{ __html: question }} />
);

const DropdownView = ({ programLevel, categoryMeasure, isReadonly = false, onRedirect }) => {
  const {
    categoryMeasureScoreState,
    categoryMeasureDropdownState,
    formState,
    errorState,
    handleSubmit,
    onValueTextPairChanged,
    onSaveClicked
  } = useDropdownView(programLevel, categoryMeasure, isReadonly, onRedirect);

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>{categoryMeasure.measureName}</Headings.H3>
        </div>
      </div>
      <form onSubmit={handleSubmit} noValidate>
        <div className="row">
          <div className="col-xs-12 col-sm-6">
            {isReadonly === true
              ? (
                <ReadOnly
                  label={<Label question={categoryMeasure?.question} />}
                  name="dropdownName"
                  value={formState.dropdownName} />
              ) : (
                <CategoryMeasureDropdown
                  label={<Label question={categoryMeasure.question} />}
                  name="dropdownId"
                  value={formState.dropdownId}
                  error={errorState.dropdownId}
                  message={errorState.dropdownId}
                  onChange={(newValue, newValueLabel) => {
                    onValueTextPairChanged(newValue, 'dropdownId', newValueLabel, 'dropdownName');
                  }}
                  categoryMeasureId={categoryMeasure.categoryMeasureId} />
              )}
          </div>
        </div>
        <div className="row usas-extra-top-margin usas-extra-bottom-margin">
          {isReadonly === true
            ? (
              <div className="col-xs-12 usas-extra-top-margin">
                <SecondaryButton type="button" onClick={() => onRedirect(false)}>Back</SecondaryButton>
              </div>
            ) : (
              <div className="col-xs-12 usas-extra-top-margin">
                <PrimaryButton type="button" onClick={onSaveClicked}>Save</PrimaryButton>&nbsp;
                <SecondaryButton type="button" onClick={() => onRedirect(false)}>Back</SecondaryButton>
              </div>
            )
          }
        </div>
      </form>
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={categoryMeasureDropdownState.isArrayLoading} />
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.SAVING_MSG}
        displayPopUp={categoryMeasureScoreState.isSaving} />
    </Fragment>
  );
};

export default DropdownView;