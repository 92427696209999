import { useEffect } from 'react';
import { useNavigate } from '../../../../common/wrappers/ReactRouterDom';

import NavLinks from '../NavLinks';

import usePreCompOrganizationContactsData from '../../../state/preCompOrganizationContacts/UsePreCompOrganizationContactsData';
import useEnvironmentVariableData from '../../../../common/state/environmentVariable/UseEnvironmentVariableData';
import usePreCompOrganizationData from '../../../../common/state/preCompOrganization/UsePreCompOrganizationData';

const usePreCompOrganizationContacts = () => {
  const navigate = useNavigate();
  const { environmentVariableState, PRECOMP_STAFF_ROLE_GROUP_ID } = useEnvironmentVariableData();
  const { preCompOrganizationState } = usePreCompOrganizationData();
  const { preCompOrganizationContactsState, getPreCompOrganizationContacts } = usePreCompOrganizationContactsData();

  const onAddStaff = () => {
    navigate(NavLinks.PRE_COMP_ORGANIZATION_CONTACT_DETAIL);
  };

  const onDeleteStaff = (orgRoleId) => {
    navigate(NavLinks.PRE_COMP_ORGANIZATION_CONTACT_DETAIL, { state: { orgRoleId } });
  };

  useEffect(() => {
    if (preCompOrganizationContactsState.isArrayLoading === false && preCompOrganizationContactsState.isArrayLoaded === false
      && environmentVariableState.isLoaded === true) {
      getPreCompOrganizationContacts(preCompOrganizationState.objData.orgUnitId, PRECOMP_STAFF_ROLE_GROUP_ID);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [preCompOrganizationContactsState, environmentVariableState]);

  return {
    isSaving: preCompOrganizationState.isSaving,
    isLoading: preCompOrganizationState.isObjLoading || preCompOrganizationContactsState.isArrayLoading,
    preCompOrganizationState,
    preCompOrganizationContactsState,
    onAddStaff,
    onDeleteStaff
  };
};

export default usePreCompOrganizationContacts;