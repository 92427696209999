import { BASIC_INITIAL_STATE } from '../../utils/HttpHelper';

import postMeetFilesData from './PostMeetFilesData';

const BASE_UPLOAD_URL = 'https://meetdocs.blob.core.windows.net/announcements/';
const GET_MEET_FILES_PATH = '/MeetFiles/Announcements?fileUrl=';

const INITIAL_STATE = {
  ...BASIC_INITIAL_STATE
};

const MeetFilesData = {
  BASE_UPLOAD_URL,
  GET_MEET_FILES_PATH,
  INITIAL_STATE,
  postMeetFilesData
};

export default MeetFilesData;