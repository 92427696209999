import { useContext, useEffect } from 'react';

import { ClubOwnerTypeStateContext } from './ClubOwnerTypeContextProvider';

import ClubOwnerTypeData from './ClubOwnerTypeData';

const useClubOwnerTypeData = () => {
  const [clubOwnerTypeState, setClubOwnerTypeState] = useContext(ClubOwnerTypeStateContext);

  useEffect(() => {
    if (clubOwnerTypeState.isLoading !== true && clubOwnerTypeState.isLoaded !== true) {
      ClubOwnerTypeData.getClubOwnerTypes(clubOwnerTypeState, setClubOwnerTypeState);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    clubOwnerTypeState
  };
};

export default useClubOwnerTypeData;