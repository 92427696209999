import { CommonHttpHelper, NO_DATA_MESSAGE, BASIC_INITIAL_STATE } from '../../utils/HttpHelper';
import HierarchicalDataUtils from '../../utils/HierarchicalDataUtils';

const INITIAL_STATE = {
  ...BASIC_INITIAL_STATE,
  treeData: [],
  id: ''
};

const getGroupCategoriesByOrgUnitId = (orgUnitId, state, setState) => {
  if (state.isArrayLoading !== true && orgUnitId && orgUnitId !== state.id && !state.message) {
    let newState = {
      ...state,
      isArrayLoading: true,
      isArrayLoaded: false,
      message: 'Loading...',
      id: orgUnitId
    };
    setState(newState);

    const url = `/GroupCategory/orgUnit/${orgUnitId}`;
    CommonHttpHelper(url, 'GET')
      .then((arrayData) => {
        if (!arrayData) {
          throw new Error(NO_DATA_MESSAGE);
        } else {
          newState = {
            ...newState,
            isArrayLoading: false,
            isArrayLoaded: true,
            arrayData,
            treeData: HierarchicalDataUtils.MapArrayToTreeData(arrayData, 'groupCategoryId', 'categoryName'),
            message: ''
          };
          setState(newState);
        }
      })
      .catch((e) => {
        const message = e && e.message ? e.message : NO_DATA_MESSAGE;
        setState({
          ...newState,
          isArrayLoading: false,
          isArrayLoaded: false,
          message: message
        });
      });
  }
};

const GroupCategoryData = { INITIAL_STATE, getGroupCategoriesByOrgUnitId };

export default GroupCategoryData;