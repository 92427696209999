import { useEffect, useState } from 'react';
import { useNavigate } from '../../../../common/wrappers/ReactRouterDom';
import { navigateToCrossUiRoute } from '../../../../common/utils/FormatCrossUiRoute';

import validate from './forms/ClubBulkRenewFiltersFormValidation';
import memberTypeSelectionValidate from './forms/ClubBulkRenewRenewalMembershipTypeFormValidation';

import usePreviousSeasonOfferingData from '../../../state/previousSeasonOffering/UsePreviousSeasonOfferingData';
import useCurrentSeasonOfferingData from '../../../state/currentSeasonOffering/UseCurrentSeasonOfferingData';
import useMemberRegistrationRenewalSelectionData from '../../../state/memberRegistrationRenewalSelection/UseMemberRegistrationRenewalSelectionData';
import useOrgUnitBulkRenewalOptInData from '../../../state/orgUnitBulkRenewalOptIn/UseOrgUnitBulkRenewalOptInData';

import useBaseUsasClubData from '../../../../common/state/clubBase/UseBaseUsasClubData';
import useGlobalRoutesData from '../../../../common/state/security/UseGlobalRoutesData';

import Constants from '../../../../common/utils/Constants';
import UseForm from '../../../../common/utils/UseForm';
import ToIntIfInt from '../../../../common/utils/ToIntIfInt';

const INITIAL_OPT_IN_FORM_STATE = {
  nonAthleteOfferingsOptIn: '',
  nonAthleteOfferingsOptInLastModifiedBy: '',
  nextNonAthleteOfferingsOptIn: '',
  nextNonAthleteOfferingsOptInLastModifiedBy: '',
  errors: {}
};

const competitionGenderOptions = [
  { id: Constants.DEFAULT_ID, name: "--", value: '' },
  { id: 1, name: "Male", value: 1 },
  { id: 2, name: "Female", value: 2 }];

const INITIAL_FORM_STATE = {
  competitionGenderTypeId: Constants.DEFAULT_ID,
  competitionGenderTypeName: '',
  minAge: '',
  maxAge: '',
  index: Constants.DEFAULT_ID,
  offeringName: '--',
  firstOrPreferredName: '',
  lastName: '',
};

const INITIAL_MODAL_STATE = {
  displayPopUp: false,
  modalTitle: 'Bulk Renew Memberships - Selection',
  view: 'Selection'
};

const INITIAL_MEMBER_TYPE_SELECTION_MODAL_STATE = {
  displayPopUp: false,
  modalTitle: 'Selected Renewal Membership'
};

const INITIAL_MEMBER_TYPE_SELECTION_FORM_STATE = {
  index: Constants.DEFAULT_ID,
  renewalOfferingName: '',
};

const INITIAL_RENEWAL_CONFIRMATION_MODAL_STATE = {
  displayPopUp: false,
  modalTitle: 'The Selected Renewal Memberships Have Been Added to the Shopping Cart'
};

const useClubBulkRenewNonAthletes = () => {
  const navigate = useNavigate();
  const { baseUsasClubState } = useBaseUsasClubData();
  const { previousSeasonOfferingState } = usePreviousSeasonOfferingData();
  const { currentSeasonOfferingState } = useCurrentSeasonOfferingData();
  const { memberRegistrationRenewalSelectionState, getMemberRegistrationRenewalSelection, postMemberRegistrationRenewalSelection } = useMemberRegistrationRenewalSelectionData();
  const { orgUnitBulkRenewalOptInState, getOrgUnitBulkRenewalOptIn, putOrgUnitBulkRenewalOptIn } = useOrgUnitBulkRenewalOptInData();
  const [optInFormState, setOptInFormState] = useState(INITIAL_OPT_IN_FORM_STATE);
  const [modalState, setModalState] = useState(INITIAL_MODAL_STATE);
  const [gridState, setGridState] = useState({ gridData: [] });
  const [selectionChangesState, setSelectionChangesState] = useState({ arrayData: [] });
  const [memberTypeSelectionModalState, setMemberTypeSelectionModalState] = useState(INITIAL_MEMBER_TYPE_SELECTION_MODAL_STATE);
  const [memberTypeSelectionFormState, setMemberTypeSelectionFormState] = useState(INITIAL_MEMBER_TYPE_SELECTION_FORM_STATE);
  const [memberTypeSelectionErrorState, setMemberTypeSelectionErrorState] = useState({});
  const [renewalChangesState, setRenewalChangesState] = useState({ arrayData: [] });
  const [confirmationGridState, setConfirmationGridState] = useState({ gridData: [] });
  const [renewalConfirmationModalState, setRenewalConfirmationModalState] = useState(INITIAL_RENEWAL_CONFIRMATION_MODAL_STATE);
  const [state, setState] = useState({ tryPopulatingLastMembershipFilter: false });
  const { handleSubmit, formState, errorState, setErrors, setFormState, onFormValueChanged, onValueTextPairChanged
  } = UseForm(INITIAL_FORM_STATE, submitFormCallback, validate);
  const { getGlobalRoute } = useGlobalRoutesData();

  const onOptInFormValueChanged = (nonAthleteOfferingsOptIn) => {
    setOptInFormState({ ...optInFormState, nonAthleteOfferingsOptIn: nonAthleteOfferingsOptIn });
  };

  const onNextOptInFormValueChanged = (nextNonAthleteOfferingsOptIn) => {
    setOptInFormState({ ...optInFormState, nextNonAthleteOfferingsOptIn: nextNonAthleteOfferingsOptIn });
  };

  const onOrgUnitBulkRenewalSaveClicked = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    if (orgUnitBulkRenewalOptInState.objData.bulkRenewalPeriod?.length > 1) {
      if (optInFormState.nextNonAthleteOfferingsOptIn !== true && optInFormState.nextNonAthleteOfferingsOptIn !== false) {
        let errors = {};
        errors.nextNonAthleteOfferingsOptIn = 'Must select yes or no';
        setOptInFormState({ ...optInFormState, errors: errors });
      }
      else {
        setOptInFormState({ ...optInFormState, errors: {} });
        const selectedReportPeriodObj = orgUnitBulkRenewalOptInState.objData.bulkRenewalPeriod.find(x => x.orgUnitBulkRenewalOptInAvailable === true);
        const optInObj = {
          reportPeriodId: selectedReportPeriodObj?.reportPeriodId, orgUnitBulkRenewalOptInAvailable: selectedReportPeriodObj?.orgUnitBulkRenewalOptInAvailable,
          nonAthleteOfferingsOptIn: optInFormState.nextNonAthleteOfferingsOptIn, athleteOfferingsOptIn: selectedReportPeriodObj?.athleteOfferingsOptIn
        }
        putOrgUnitBulkRenewalOptIn(baseUsasClubState.objData?.orgUnitId, optInObj);
      }
    }
    else {
      if (optInFormState.nonAthleteOfferingsOptIn !== true && optInFormState.nonAthleteOfferingsOptIn !== false) {
        let errors = {};
        errors.nonAthleteOfferingsOptIn = 'Must select yes or no';
        setOptInFormState({ ...optInFormState, errors: errors });
      }
      else {
        setOptInFormState({ ...optInFormState, errors: {} });
        const selectedReportPeriodObj = orgUnitBulkRenewalOptInState.objData.bulkRenewalPeriod?.find(x => x.orgUnitBulkRenewalOptInAvailable === true);
        const optInObj = {
          reportPeriodId: selectedReportPeriodObj?.reportPeriodId, orgUnitBulkRenewalOptInAvailable: selectedReportPeriodObj?.orgUnitBulkRenewalOptInAvailable,
          nonAthleteOfferingsOptIn: optInFormState.nonAthleteOfferingsOptIn, athleteOfferingsOptIn: selectedReportPeriodObj?.athleteOfferingsOptIn
        }
        putOrgUnitBulkRenewalOptIn(baseUsasClubState.objData?.orgUnitId, optInObj);
      }
    }
  };

  const onFilterClicked = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    handleSubmit();
  };

  const onClearFilterClicked = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    setFormState(INITIAL_FORM_STATE);
    const filteredGridData = filter(INITIAL_FORM_STATE);
    setGridState({ ...gridState, gridData: filteredGridData });
    setConfirmationGridState({ ...confirmationGridState, gridData: filteredGridData });
    setErrors({});
  };

  const onSaveClicked = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    if (renewalChangesState.arrayData.length > 0) {
      const renewalChangesArrayDataFormatted = renewalChangesState.arrayData?.map(x => { return ({ personId: x.personId, offeringInstanceId: x.renewalOfferingInstanceId, offeringInstanceCouponId: x.renewalOfferingInstanceCouponId }) });
      postMemberRegistrationRenewalSelection(baseUsasClubState.objData?.orgUnitId, renewalChangesArrayDataFormatted);
      setRenewalConfirmationModalState({ ...renewalConfirmationModalState, displayPopUp: true });
    }
    else {
      setErrors({ ...errorState, general: 'No renewal memberships are selected. Please go back to selection, then select and add to renew at least one membership.' });
    }
  };

  const onSelectAll = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    const updatedArrayData = JSON.parse(JSON.stringify(selectionChangesState.arrayData));
    const updatedGridData = JSON.parse(JSON.stringify(gridState.gridData));
    for (let i = 0; i < updatedGridData.length; i++) {
      updatedGridData[i].isSelected = true;
      const selectedMembershipIndexArrayData = updatedArrayData.findIndex(x => x.personId === updatedGridData[i].personId && x.offeringName === updatedGridData[i].offeringName);
      //Edit
      if (selectedMembershipIndexArrayData > -1) {
        updatedArrayData[selectedMembershipIndexArrayData].isSelected = true;
      }
      else {
        updatedArrayData.push(updatedGridData[i]);
      }
    }

    setSelectionChangesState({
      ...selectionChangesState,
      arrayData: updatedArrayData
    });
    setGridState({ ...gridState, gridData: updatedGridData });
  };

  const onUnselectAll = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    const updatedArrayData = JSON.parse(JSON.stringify(selectionChangesState.arrayData));
    const updatedGridData = modalState.view === 'Selection' ? JSON.parse(JSON.stringify(gridState.gridData)) : modalState.view === 'Renew' ? JSON.parse(JSON.stringify(confirmationGridState.gridData)) : [];
    for (let i = 0; i < updatedGridData.length; i++) {
      updatedGridData[i].isSelected = false;
      const selectedMembershipIndexArrayData = updatedArrayData.findIndex(x => x.personId === updatedGridData[i].personId && x.offeringName === updatedGridData[i].offeringName);
      //Edit
      if (selectedMembershipIndexArrayData > -1) {
        updatedArrayData[selectedMembershipIndexArrayData].isSelected = false;
      }
      else {
        updatedArrayData.push(updatedGridData[i]);
      }
    }

    setSelectionChangesState({
      ...selectionChangesState,
      arrayData: updatedArrayData
    });

    if (modalState.view === 'Selection') {
      setGridState({ ...gridState, gridData: updatedGridData });
    }
    else if (modalState.view === 'Renew') {
      setConfirmationGridState({ ...confirmationGridState, gridData: updatedGridData });
      //remove unselected membership(s) from renewalChangesState
      const renewalChangesArrayCopy = JSON.parse(JSON.stringify(renewalChangesState.arrayData));
      let updatedRenewalChangesArrayCopy = [];
      for (let i = 0; i < renewalChangesArrayCopy.length; i++) {
        const selectedGridDataIndex = updatedGridData?.findIndex(x => x.personId === renewalChangesArrayCopy[i].personId && x.offeringName === renewalChangesArrayCopy[i].offeringName);
        //if there's a match in the selection changes state, only add if isSelected === true
        if (selectedGridDataIndex > -1) {
          if (updatedGridData[selectedGridDataIndex].isSelected === true) {
            updatedRenewalChangesArrayCopy.push(renewalChangesArrayCopy[i]);
          }
        }
        //if there isn't a match in the selection changes state, keep the previous info
        else {
          updatedRenewalChangesArrayCopy.push(renewalChangesArrayCopy[i]);
        }
      }

      setRenewalChangesState({
        ...renewalChangesState,
        arrayData: updatedRenewalChangesArrayCopy
      });
    }
  };

  const onCheckboxChange = (e, membership) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    const updatedArrayData = JSON.parse(JSON.stringify(selectionChangesState.arrayData));
    const selectedMembershipIndexArrayData = updatedArrayData.findIndex(x => x.personId === membership.personId && x.offeringName === membership.offeringName);

    const updatedGridData = modalState.view === 'Selection' ? JSON.parse(JSON.stringify(gridState.gridData)) : modalState.view === 'Renew' ? JSON.parse(JSON.stringify(confirmationGridState.gridData)) : [];
    const selectedMembershipIndexGridData = modalState.view === 'Selection' ? gridState.gridData?.findIndex(x => x.personId === membership.personId && x.offeringName === membership.offeringName) : modalState.view === 'Renew' ? confirmationGridState.gridData?.findIndex(x => x.personId === membership.personId && x.offeringName === membership.offeringName) : Constants.DEFAULT_ID;

    if (selectedMembershipIndexGridData > -1) {
      updatedGridData[selectedMembershipIndexGridData].isSelected = !updatedGridData[selectedMembershipIndexGridData].isSelected;
      if (modalState.view === 'Selection') {
        setGridState({
          ...gridState,
          gridData: updatedGridData
        });
      } else if (modalState.view === 'Renew') {
        setConfirmationGridState({
          ...confirmationGridState,
          gridData: updatedGridData
        });
        //remove unselected membership from renewalChangesState
        if (updatedGridData[selectedMembershipIndexGridData].isSelected === false) {
          const renewalChangesArrayCopy = JSON.parse(JSON.stringify(renewalChangesState.arrayData));
          const renewalChangesArrayIndex = renewalChangesArrayCopy?.findIndex(x => x.personId === updatedGridData[selectedMembershipIndexGridData].personId && x.offeringName === updatedGridData[selectedMembershipIndexGridData].offeringName && x.isSelected === true);
          renewalChangesArrayCopy.splice(renewalChangesArrayIndex, 1);
          setRenewalChangesState({
            ...renewalChangesState,
            arrayData: renewalChangesArrayCopy
          });
        }
      }
    }

    if (selectedMembershipIndexArrayData > -1) {
      updatedArrayData[selectedMembershipIndexArrayData].isSelected = !updatedArrayData[selectedMembershipIndexArrayData].isSelected;
      setSelectionChangesState({
        ...selectionChangesState,
        arrayData: updatedArrayData
      });
    }
    else if (selectedMembershipIndexArrayData === -1) {
      const newAthlete = JSON.parse(JSON.stringify(updatedGridData[selectedMembershipIndexGridData]));
      updatedArrayData.push(newAthlete);
      setSelectionChangesState({
        ...selectionChangesState,
        arrayData: updatedArrayData
      });
    }
  };

  const onOpenRenewPopUpClicked = async (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    const memberTypeSelectionErrors = await memberTypeSelectionValidate(memberTypeSelectionFormState) || {};
    if (Object.keys(memberTypeSelectionErrors).length === 0) {
      //only get athletes
      const renewalOfferingInstanceId = currentSeasonOfferingState?.arrayData?.find(x => x.offeringName === memberTypeSelectionFormState.renewalOfferingName)?.offeringInstanceId;
      getMemberRegistrationRenewalSelection(baseUsasClubState.objData?.orgUnitId, { isAthleteRole: false, offeringInstanceToId: renewalOfferingInstanceId });
      setModalState({ ...modalState, displayPopUp: true, modalTitle: `Bulk Renew ${memberTypeSelectionFormState.renewalOfferingName || ''} Memberships - Selection` });
      setState({ tryPopulatingLastMembershipFilter: true });
    }
    else {
      setMemberTypeSelectionErrorState(memberTypeSelectionErrors);
    }
  };

  const onSwitchViewClicked = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    document.getElementById('modalId')?.scrollIntoView();
    if (modalState.view === 'Selection') {
      if (renewalChangesState.arrayData?.length > 0) {
        setErrors({});
        onClearFilterClicked();
        setSelectionChangesState({ ...selectionChangesState, arrayData: [] });
        setModalState({
          ...modalState,
          modalTitle: `Bulk Renew ${memberTypeSelectionFormState.renewalOfferingName || ''} Memberships - Renew`,
          view: 'Renew'
        });
      }
      else {
        setErrors({ ...errorState, general: 'No renewal memberships have been added. Please select and add to renew at least one membership to continue.' });
      }
    }
    else if (modalState.view === 'Renew') {
      setState({ tryPopulatingLastMembershipFilter: true });
      setErrors({});
      onClearFilterClicked();
      setSelectionChangesState({ ...selectionChangesState, arrayData: [] });
      setModalState({
        ...modalState,
        modalTitle: `Bulk Renew ${memberTypeSelectionFormState.renewalOfferingName || ''} Memberships - Selection`,
        view: 'Selection'
      });
    }
  };

  const onModalCanceled = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    setModalState(INITIAL_MODAL_STATE);
    setSelectionChangesState({ arrayData: [] });
    setRenewalChangesState({ arrayData: [] });
    setMemberTypeSelectionFormState(INITIAL_MEMBER_TYPE_SELECTION_FORM_STATE);
    setMemberTypeSelectionErrorState({});
    onClearFilterClicked();
  };

  const onOpenMemberTypeSelectionPopUpClicked = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    setErrors({});
    if (selectionChangesState.arrayData?.filter(x => x.isSelected)?.length > 0) {
      setMemberTypeSelectionModalState({ ...memberTypeSelectionModalState, displayPopUp: true });
    }
    else {
      setErrors({ ...errorState, general: 'No memberships are selected. Please select at least one membership to add to renew.' })
    }
  };

  const onMemberTypeSelectionContinueClicked = async (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    const memberTypeSelectionErrors = await memberTypeSelectionValidate(memberTypeSelectionFormState) || {};
    if (Object.keys(memberTypeSelectionErrors).length === 0) {
      //add selected membership to selected selections
      const renewalOfferingInstanceObj = currentSeasonOfferingState?.arrayData?.find(x => x.offeringName === memberTypeSelectionFormState.renewalOfferingName);
      const selectionChangesWithRenewalMembershipTypesArray = selectionChangesState.arrayData?.filter(x => x.isSelected === true)?.map(x => {
        return ({
          ...x,
          renewalOfferingInstanceId: renewalOfferingInstanceObj?.offeringInstanceId,
          renewalOfferingName: memberTypeSelectionFormState.renewalOfferingName,
          renewalOfferingInstanceCouponId: renewalOfferingInstanceObj?.offeringInstanceCouponId
        });
      });

      //keep any prior renewal selections
      const renewalChangesArrayCopy = JSON.parse(JSON.stringify(renewalChangesState.arrayData));
      const renewalChangesArray = renewalChangesArrayCopy?.concat(selectionChangesWithRenewalMembershipTypesArray);

      //set renewal changes state, update grid data - remove memberships that have been added to renew,
      //clear out selection changes state, and close dropdown popup
      setRenewalChangesState({ ...renewalChangesState, arrayData: renewalChangesArray });

      const filteredGridData = JSON.parse(JSON.stringify(gridState.gridData));
      const updatedFilteredGridData = filteredGridData?.filter(x => renewalChangesArray?.findIndex(rc => (rc.personId === x.personId && rc.offeringName === x.offeringName && rc.isSelected === true)) === -1);

      setGridState({ ...gridState, gridData: updatedFilteredGridData });
      setSelectionChangesState({ ...selectionChangesState, arrayData: [] });

      onMemberTypeSelectionModalCanceled();
    }
    else {
      setMemberTypeSelectionErrorState(memberTypeSelectionErrors);
    }
  };

  const onMemberTypeSelectionModalCanceled = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    setMemberTypeSelectionModalState(INITIAL_MEMBER_TYPE_SELECTION_MODAL_STATE);
  };

  const onRenewalConfirmationContinueClicked = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    setRenewalConfirmationModalState(INITIAL_RENEWAL_CONFIRMATION_MODAL_STATE);
    onModalCanceled();
    //Navigate to cart
    const paymentsRoute = getGlobalRoute('PAYMENTS_SHOPPING_CART');
    if (paymentsRoute && paymentsRoute.route) {
      navigateToCrossUiRoute(paymentsRoute.uIProjectName, paymentsRoute.route, navigate);
    }
  };

  const onRenewalConfirmationModalCanceled = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    setRenewalConfirmationModalState(INITIAL_RENEWAL_CONFIRMATION_MODAL_STATE);
    onModalCanceled();
  };

  function submitFormCallback(formState) {
    const filteredGridData = filter(formState);

    if (modalState.view === 'Selection') {
      setGridState({
        ...gridState,
        gridData: filteredGridData
      });
    } else if (modalState.view === 'Renew') {
      setConfirmationGridState({
        ...confirmationGridState,
        gridData: filteredGridData
      });
    }
  };

  function filter(formState) {
    let arrayDataCopy = [];
    if (modalState.view === 'Selection') {
      arrayDataCopy = JSON.parse(JSON.stringify(memberRegistrationRenewalSelectionState.arrayData));
      //remove any memberships that have already been added to renew 
      arrayDataCopy = arrayDataCopy?.filter(x => renewalChangesState.arrayData?.findIndex(rc => (rc.personId === x.personId && rc.offeringName === x.offeringName && rc.isSelected === true)) === -1);
    }
    else if (modalState.view === 'Renew') {
      arrayDataCopy = JSON.parse(JSON.stringify(renewalChangesState.arrayData));
    }

    //keep unsaved selections / unselections from previous searches
    if (selectionChangesState.arrayData.length > 0) {
      for (let i = 0; i < selectionChangesState.arrayData.length; i++) {
        const matchingIndex = arrayDataCopy.findIndex(x => x.personId === selectionChangesState.arrayData[i].personId && x.offeringName === selectionChangesState.arrayData[i].offeringName);
        if (matchingIndex > -1) {
          arrayDataCopy[matchingIndex].isSelected = selectionChangesState.arrayData[i].isSelected;
        }
      }
    };

    let results = [];
    let competitionGenderFilter = formState.competitionGenderTypeId > 0 ? true : false;
    let firstOrPreferredNameFilter = formState.firstOrPreferredName?.trim() !== '' ? true : false;
    let lastNameFilter = formState.lastName?.trim() !== '' ? true : false;
    let ageFilter = formState.minAge?.trim() !== '' && formState.maxAge?.trim() !== '' ? true : false;
    let lastRegTypeFilter = formState.offeringName !== '--' ? true : false;

    //No filters used
    if (competitionGenderFilter === false && firstOrPreferredNameFilter === false && lastNameFilter === false && ageFilter === false && lastRegTypeFilter === false) {
      results = arrayDataCopy;
    }
    else {
      //Filter data
      const minAgeInt = ToIntIfInt(formState.minAge?.trim());
      const maxAgeInt = ToIntIfInt(formState.maxAge?.trim());
      for (let i = 0; i < arrayDataCopy.length; i++) {
        let competitionGenderMatch = false;
        let firstOrPreferredNameMatch = false;
        let lastNameMatch = false;
        let ageMatch = false;
        let lastRegTypeMatch = false;
        if (competitionGenderFilter === true) {
          if (ToIntIfInt(arrayDataCopy[i].competitionGenderTypeId) === ToIntIfInt(formState.competitionGenderTypeId)) {
            competitionGenderMatch = true;
          }
        }
        if (firstOrPreferredNameFilter === true) {
          if (match('*' + formState.firstOrPreferredName?.trim() + '*', arrayDataCopy[i].firstName) === true ||
            match('*' + formState.firstOrPreferredName?.trim() + '*', arrayDataCopy[i].preferredName) === true) {
            firstOrPreferredNameMatch = true;
          }
        }
        if (lastNameFilter === true) {
          if (match('*' + formState.lastName?.trim() + '*', arrayDataCopy[i].lastName) === true) {
            lastNameMatch = true;
          }
        }
        if (ageFilter === true) {
          if (arrayDataCopy[i].age >= minAgeInt && arrayDataCopy[i].age <= maxAgeInt) {
            ageMatch = true;
          }
        }
        if (lastRegTypeFilter === true) {
          if (ToIntIfInt(arrayDataCopy[i].offeringName) === ToIntIfInt(formState.offeringName)) {
            lastRegTypeMatch = true;
          }
        }

        //Does the membership meet all the filter requirements?
        if ((competitionGenderFilter === false || (competitionGenderFilter === true && competitionGenderMatch === true)) &&
          (firstOrPreferredNameFilter === false || (firstOrPreferredNameFilter === true && firstOrPreferredNameMatch === true)) &&
          (lastNameFilter === false || (lastNameFilter === true && lastNameMatch === true)) &&
          (ageFilter === false || (ageFilter === true && ageMatch === true)) &&
          (lastRegTypeFilter === false || (lastRegTypeFilter === true && lastRegTypeMatch === true))) {
          results.push(arrayDataCopy[i]);
        }
      }
    }

    return results;
  };

  function match(providedFilter, personName) {
    let providedFilterLowerCase = providedFilter.toLowerCase();
    let personNameLowerCase = personName.toLowerCase();
    if (providedFilterLowerCase.length === 0 && personNameLowerCase.length === 0)
      return true;

    if (providedFilterLowerCase.length > 1 && providedFilterLowerCase[0] === '*' &&
      personNameLowerCase.length === 0)
      return false;

    if ((providedFilterLowerCase.length > 1 && providedFilterLowerCase[0] === '?') ||
      (providedFilterLowerCase.length !== 0 && personNameLowerCase.length !== 0 &&
        providedFilterLowerCase[0] === personNameLowerCase[0]))
      return match(providedFilterLowerCase.substring(1),
        personName.substring(1));

    if (providedFilterLowerCase.length > 0 && providedFilterLowerCase[0] === '*')
      return match(providedFilterLowerCase.substring(1), personNameLowerCase) ||
        match(providedFilterLowerCase, personNameLowerCase.substring(1));

    return false;
  };

  useEffect(() => {
    if ((orgUnitBulkRenewalOptInState.isObjLoaded === false ||
      (orgUnitBulkRenewalOptInState.isObjLoaded === true && orgUnitBulkRenewalOptInState?.orgUnitId !== baseUsasClubState.objData?.orgUnitId))
      && orgUnitBulkRenewalOptInState.isObjLoading === false) {
      getOrgUnitBulkRenewalOptIn(baseUsasClubState.objData?.orgUnitId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orgUnitBulkRenewalOptInState, baseUsasClubState.objData?.orgUnitId])

  useEffect(() => {
    if (orgUnitBulkRenewalOptInState.isObjLoaded === true && orgUnitBulkRenewalOptInState?.orgUnitId === baseUsasClubState.objData?.orgUnitId) {
      //handle month overlap of current reg year and next reg year
      const bulkRenewalPeriodLastModifiedBy = `${orgUnitBulkRenewalOptInState.objData?.nonAthleteOfferingsOptInFirstName || ''} ${orgUnitBulkRenewalOptInState.objData?.nonAthleteOfferingsOptInLastName || ''}`;
      if (orgUnitBulkRenewalOptInState.objData.bulkRenewalPeriod?.length > 1) {
        const nextBulkRenewalPeriod = orgUnitBulkRenewalOptInState.objData.bulkRenewalPeriod?.find(x => x.orgUnitBulkRenewalOptInAvailable === true);
        const nextBulkRenewalPeriodLastModifiedBy = `${nextBulkRenewalPeriod.nonAthleteOfferingsOptInFirstName || ''} ${nextBulkRenewalPeriod.nonAthleteOfferingsOptInLastName || ''}`;
        setOptInFormState({
          ...optInFormState, nextNonAthleteOfferingsOptIn: nextBulkRenewalPeriod?.nonAthleteOfferingsOptIn ?? '', nextNonAthleteOfferingsOptInLastModifiedBy: nextBulkRenewalPeriodLastModifiedBy || '',
          nonAthleteOfferingsOptIn: orgUnitBulkRenewalOptInState.objData?.nonAthleteOfferingsOptIn ?? false, nonAthleteOfferingsOptInLastModifiedBy: bulkRenewalPeriodLastModifiedBy || ''
        });
      }
      else {
        setOptInFormState({ ...optInFormState, nonAthleteOfferingsOptIn: orgUnitBulkRenewalOptInState.objData?.nonAthleteOfferingsOptIn ?? false, nonAthleteOfferingsOptInLastModifiedBy: bulkRenewalPeriodLastModifiedBy || '' });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orgUnitBulkRenewalOptInState])

  useEffect(() => {
    if (memberRegistrationRenewalSelectionState.isArrayLoaded === true && memberRegistrationRenewalSelectionState.isArrayLoading === false
      && currentSeasonOfferingState.isArrayLoaded === true && previousSeasonOfferingState.isArrayLoaded === true) {
      if (state.tryPopulatingLastMembershipFilter === true) {
        const renewalOfferingName = currentSeasonOfferingState.arrayData.find(x => x.offeringName === memberTypeSelectionFormState.renewalOfferingName)?.offeringName;
        if (renewalOfferingName !== 'Junior Coach') {
          const offeringNameMatch = previousSeasonOfferingState.arrayData.find(x => x.offeringName === renewalOfferingName);
          if (offeringNameMatch) {
            onValueTextPairChanged(offeringNameMatch.index, 'index', offeringNameMatch.offeringName, 'offeringName');
            const filteredGridData = filter({ ...formState, index: offeringNameMatch.index, offeringName: offeringNameMatch.offeringName });
            setGridState({ ...gridState, gridData: filteredGridData });
          }
        }
        setState({ tryPopulatingLastMembershipFilter: false });
      }
      else {
        const filteredGridData = filter(formState);
        setGridState({ ...gridState, gridData: filteredGridData });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [memberRegistrationRenewalSelectionState.isArrayLoaded, memberRegistrationRenewalSelectionState.isArrayLoading, state.tryPopulatingLastMembershipFilter,
    currentSeasonOfferingState, previousSeasonOfferingState])

  useEffect(() => {
    if (memberRegistrationRenewalSelectionState.isArrayLoaded === true && memberRegistrationRenewalSelectionState.isArrayLoading === false) {
      if (modalState.view === 'Selection') {
        let arrayDataCopy = JSON.parse(JSON.stringify(memberRegistrationRenewalSelectionState.arrayData || []));
        //remove any memberships that have already been added to renew 
        arrayDataCopy = arrayDataCopy?.filter(x => renewalChangesState.arrayData?.findIndex(rc => (rc.personId === x.personId && rc.offeringName === x.offeringName && rc.isSelected === true)) === -1);
        //keep unsaved selections / unselections from previous searches
        if (selectionChangesState.arrayData.length > 0) {
          for (let i = 0; i < selectionChangesState.arrayData.length; i++) {
            const matchingIndex = arrayDataCopy.findIndex(x => x.personId === selectionChangesState.arrayData[i].personId && x.offeringName === selectionChangesState.arrayData[i].offeringName);
            if (matchingIndex > -1) {
              arrayDataCopy[matchingIndex].isSelected = selectionChangesState.arrayData[i].isSelected;
            }
          }
        }
        setGridState({ ...gridState, gridData: arrayDataCopy });
      }
      else if (modalState.view === 'Renew') {
        const arrayDataCopy = JSON.parse(JSON.stringify(renewalChangesState.arrayData || []));
        //keep unsaved selections / unselections from previous searches
        if (selectionChangesState.arrayData.length > 0) {
          for (let i = 0; i < selectionChangesState.arrayData.length; i++) {
            const matchingIndex = arrayDataCopy.findIndex(x => x.personId === selectionChangesState.arrayData[i].personId && x.offeringName === selectionChangesState.arrayData[i].offeringName);
            if (matchingIndex > -1) {
              arrayDataCopy[matchingIndex].isSelected = selectionChangesState.arrayData[i].isSelected;
            }
          }
        }
        setConfirmationGridState({ ...confirmationGridState, gridData: arrayDataCopy });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [memberRegistrationRenewalSelectionState.isArrayLoaded, memberRegistrationRenewalSelectionState.isArrayLoading, modalState.view])

  return {
    orgUnitBulkRenewalOptInState,
    optInFormState,
    onOptInFormValueChanged,
    onOrgUnitBulkRenewalSaveClicked,
    baseUsasClubState,
    memberRegistrationRenewalSelectionState,
    previousSeasonOfferingState,
    currentSeasonOfferingState,
    gridState,
    selectionChangesState,
    renewalChangesState,
    confirmationGridState,
    onSelectAll,
    onUnselectAll,
    onSaveClicked,
    onCheckboxChange,
    onFilterClicked,
    onClearFilterClicked,
    onFormValueChanged,
    onValueTextPairChanged,
    formState,
    errorState,
    competitionGenderOptions,
    onOpenRenewPopUpClicked,
    modalState,
    onModalCanceled,
    onSwitchViewClicked,
    onOpenMemberTypeSelectionPopUpClicked,
    memberTypeSelectionModalState,
    memberTypeSelectionFormState,
    setMemberTypeSelectionFormState,
    memberTypeSelectionErrorState,
    onMemberTypeSelectionContinueClicked,
    onMemberTypeSelectionModalCanceled,
    renewalConfirmationModalState,
    onRenewalConfirmationContinueClicked,
    onRenewalConfirmationModalCanceled,
    onNextOptInFormValueChanged
  };
}

export default useClubBulkRenewNonAthletes;