import Constants from '../../utils/Constants';
import { API_NAMES, initApi } from '../../utils/HttpApiHelper';
import { BASIC_INITIAL_STATE } from '../../utils/HttpHelper';

import getCurrentProgramByProgramTypeData from './GetCurrentProgramByProgramTypeData';
import getAllProgramsByProgramTypeData from './GetAllProgramsByProgramTypeData';
import postProgramLevelInitiationData from './PostProgramLevelInitiationData';
import postProgramLevelRenewalData from './PostProgramLevelRenewalData';

const putProgramLevelRevokeData = (programLevelInstanceId, revokeObj, state, setState) => {
  if (programLevelInstanceId > 0 && revokeObj) {
    const api = initApi(API_NAMES.REC, state, setState);
    const programLevelInstanceIdForUrl = encodeURIComponent(programLevelInstanceId);
    const url = `/ProgramLevelInstance/${programLevelInstanceIdForUrl}/Revoke`;

    return api?.executeArray ? api.executeArray(url, 'PUT', revokeObj) : null;
  }
};

const INITIAL_RECOGNITION_PROGRAM_STATE = {
  ...BASIC_INITIAL_STATE,
  orgUnitId: '',
  programTypeId: Constants.DEFAULT_ID
};

const INITIAL_PROGRAM_FOCUS_STATE = {
  programLevelObj: {},
  programLevelInstanceId: Constants.DEFAULT_ID,
  levelCategoryObj: {},
  programCategoryId: Constants.DEFAULT_ID,
  categoryMeasureObj: {},
  categoryMeasureId: Constants.DEFAULT_ID
};

const RecognitionProgramData = {
  INITIAL_RECOGNITION_PROGRAM_STATE,
  INITIAL_PROGRAM_FOCUS_STATE,
  getCurrentProgramByProgramTypeData,
  getAllProgramsByProgramTypeData,
  postProgramLevelInitiationData,
  postProgramLevelRenewalData,
  putProgramLevelRevokeData
};

export default RecognitionProgramData;