import { useEffect, useState } from 'react';
import { useNavigate } from '../../../../common/wrappers/ReactRouterDom';

import NavLinks from '../NavLinks';

import useClubRegistrationNavData from "../../../state/clubRegistration/leftNavigation/UseClubRegistrationNavData";
import useClubRegistrationNewData from "../../../state/clubRegistrationNew/UseClubRegistrationNewData";
import useUsasClubFacilityLinksData from '../../../state/clubFacilityLinks/UseUsasClubFacilityLinksData';

import useSearchFacility from '../../../../common/components/searches/searchFacility/UseSearchFacility';

import Constants from '../../../../common/utils/Constants';

const useClubRegistrationFacilitySearch = () => {
  const navigate = useNavigate();
  const { facilityState, searchFacilityState, setSearchFacilityState, getFacility } = useSearchFacility();
  const { usasClubFacilityLinksState, getUsasClubFacilityLinks, postUsasClubFacilityLinks,
    clearUsasClubFacilityLinksObjData } = useUsasClubFacilityLinksData();
  const { clubRegistrationState } = useClubRegistrationNewData();
  const { clubRegistrationNavState } = useClubRegistrationNavData();
  const [modalState, setModalState] = useState(getInitialModalState);
  const [state, setState] = useState({ facilityId: Constants.DEFAULT_ID });

  function createObjectForPost(facilityObjData) {
    return {
      orgUnitId: clubRegistrationState.objData.orgUnitId,
      facilityId: facilityObjData.facilityId,
      effectiveDate: null,
      expirationDate: null,
      facility: undefined,
      clubFacilityContactName: null,
      clubFacilityContactEmailAddress: null,
      clubFacilityContactPhoneNumber: null,
      facilityContactName: facilityObjData.contactName,
      facilityContactEmailAddress: facilityObjData.contactEmailAddress,
      facilityContactPhoneNumber: facilityObjData.contactPhoneNumber
    }
  }

  const onLinkFacility = (e, facilityId) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    let dupFacility = null;
    for (const usasClubFacility of usasClubFacilityLinksState.arrayData) {
      if (usasClubFacility.facility.facilityId === facilityId) {
        dupFacility = usasClubFacility;
        break;
      }
    }

    if (dupFacility === null) {
      setState({ ...state, facilityId: facilityId });
      getFacility(facilityId);
      setModalState(getInitialModalState);
    }
    else {
      setModalState({
        ...modalState,
        displayModal: true,
        facilityName: dupFacility.facility?.facilityName || ''
      });
    }
  }

  const onCancelClicked = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    navigate(NavLinks.CLUB_REGISTRATION_FACILITY);
  }

  const onModalCancel = () => {
    setModalState(getInitialModalState);
  }

  const onAddANewFacility = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    navigate(NavLinks.CLUB_REGISTRATION_FACILITY_ADD);
  };

  useEffect(() => {
    if (clubRegistrationState.isObjLoaded === false) {
      navigate(NavLinks.CLUB_REGISTRATION_ENTRY_NEW);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    clearUsasClubFacilityLinksObjData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (facilityState.isObjLoaded === true && state.facilityId === facilityState.objData.facilityId) {
      postUsasClubFacilityLinks(clubRegistrationState.objData.usasClubId, createObjectForPost(facilityState.objData));
      setSearchFacilityState({ ...searchFacilityState, tryRedirect: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [facilityState])

  useEffect(() => {
    //Wait for add to complete before getting updated club & redirecting
    if (usasClubFacilityLinksState.isSaving === false && searchFacilityState.tryRedirect === true && state.facilityId > 0) {
      getUsasClubFacilityLinks(clubRegistrationState.objData.usasClubId);
      setSearchFacilityState({ ...searchFacilityState, tryRedirect: false });
      navigate(NavLinks.CLUB_REGISTRATION_FACILITY_DETAILS, { state: { facilityId: state.facilityId } });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [usasClubFacilityLinksState, searchFacilityState, state]);

  function getInitialModalState() {
    return {
      displayModal: false,
      facilityName: '',
    }
  }

  return {
    usasClubFacilityLinksState,
    clubRegistrationNavState,
    clubRegistrationState,
    facilityState,
    modalState,
    onLinkFacility,
    onCancelClicked,
    onModalCancel,
    onAddANewFacility
  }
}

export default useClubRegistrationFacilitySearch;