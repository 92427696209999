import React, { createContext, useState } from 'react';

import { BASIC_INITIAL_STATE } from '../../../common/utils/HttpHelper';

export const LearnToSwimCurriculumStateContext = createContext();

export const INITIAL_STATE = {
  ...BASIC_INITIAL_STATE,
  items: [],
  areItemsLoaded: false
};

const LearnToSwimCurriculumContextProvider = ({ children }) => {
  const stateHook = useState(INITIAL_STATE);

  return (
    <LearnToSwimCurriculumStateContext.Provider value={stateHook}>
      {children}
    </LearnToSwimCurriculumStateContext.Provider>
  );
};

export default LearnToSwimCurriculumContextProvider;