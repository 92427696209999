import { useEffect, useState } from 'react';

import validate from './FacilityPoolConfigurationValidation';

import FacilityPoolsUtils from './utils/FacilityPoolsUtils';

import useForm from '../../../../utils/UseForm';
import useFacilityData from '../../../../state/facility/UseFacilityData';
import useCourseData from '../../../../state/course/UseCourseData';
import usePoolConfigurationData from '../../../../state/poolConfiguration/UsePoolConfigurationData';

import Constants from '../../../../utils/Constants';

const useAddEditFacilityPoolConfiguration = () => {
  const { facilityState, putFacility } = useFacilityData();
  const { courseState } = useCourseData();
  const { poolConfigurationState, setPoolConfigurationState, resetPoolConfigurationState, resetPoolConfigurationStateUpdateGrid } = usePoolConfigurationData();
  const { getFacilityCopy } = FacilityPoolsUtils();
  const { formState, errorState, setFormState, updateFormState, handleSubmit, onValueTextPairChanged,
    setIsDirty, setErrors, isDirty } = useForm(getInitialFormState, submitFormCallback, validate);
  const [state, setState] = useState({ tryRedirect: false });

  const onSaveClicked = () => {
    //Hide detail view without saving if no changes are made to edit pool config
    if (formState.poolConfigurationIndex >= 0 && isDirty === false) {
      resetPoolConfigurationStateUpdateGrid();
    }
    else {
      handleSubmit();
    }
  };

  const onCancelClicked = () => {
    setErrors({});
    resetPoolConfigurationState();
  };

  useEffect(() => {
    if (facilityState.isObjLoaded === true && poolConfigurationState && poolConfigurationState.showPage === true) {
      if (poolConfigurationState.poolId > 0) {
        const selectedPool = [...facilityState.objData.facilityPool]
          .find((pool) => pool.facilityPoolId === poolConfigurationState.poolId);
        if (poolConfigurationState.poolConfigurationIndex >= 0) {
          //Edit Pool Config
          const selectedPoolConfiguration = selectedPool.poolConfiguration[poolConfigurationState.poolConfigurationIndex];
          setFormState({
            ...formState,
            poolId: selectedPool.facilityPoolId || Constants.DEFAULT_ID,
            poolName: selectedPool.facilityPoolName || '',
            poolDimensionsId: selectedPool.poolDimensionsId || Constants.DEFAULT_ID,
            poolDimensions: selectedPool.poolDimensions?.dimensionName || '',
            poolConfiguration: selectedPool.poolConfiguration || [],
            poolConfigurationIndex: poolConfigurationState.poolConfigurationIndex >= 0 ? poolConfigurationState.poolConfigurationIndex : Constants.DEFAULT_ID,
            poolConfigurationId: selectedPoolConfiguration.poolConfigurationId || Constants.DEFAULT_ID,
            courseId: selectedPoolConfiguration.courseId || Constants.DEFAULT_ID,
            courseName: selectedPoolConfiguration.course?.courseCode || '',
            numberOfLanes: selectedPoolConfiguration.numberOfLanes || ''
          });
        }
        else {
          //Add Pool Config
          setFormState({
            ...formState,
            poolId: selectedPool.facilityPoolId || Constants.DEFAULT_ID,
            poolName: selectedPool.facilityPoolName || '',
            poolDimensionsId: selectedPool.poolDimensionsId || Constants.DEFAULT_ID,
            poolDimensions: selectedPool.poolDimensions?.dimensionName || '',
            poolConfiguration: selectedPool.poolConfiguration || [],
            poolConfigurationIndex: Constants.DEFAULT_ID,
            poolConfigurationId: Constants.DEFAULT_ID,
            courseId: Constants.DEFAULT_ID,
            courseName: '',
            numberOfLanes: ''
          });
          setIsDirty(true);
        }
      }
      else {
        if (poolConfigurationState.poolConfigurationIndex >= 0) {
          //Edit Pool Config
          setFormState({
            ...formState,
            poolId: poolConfigurationState.poolId || Constants.DEFAULT_ID,
            poolName: poolConfigurationState.poolName || '',
            poolDimensionsId: poolConfigurationState.poolDimensionsId || Constants.DEFAULT_ID,
            poolDimensions: poolConfigurationState.poolDimensions || '',
            poolConfiguration: poolConfigurationState.poolConfiguration || [],
            poolConfigurationIndex: poolConfigurationState.poolConfigurationIndex >= 0 ? poolConfigurationState.poolConfigurationIndex : Constants.DEFAULT_ID,
            poolConfigurationId: poolConfigurationState.poolConfigurationId || Constants.DEFAULT_ID,
            courseId: poolConfigurationState.courseId || Constants.DEFAULT_ID,
            courseName: poolConfigurationState.course || '',
            numberOfLanes: poolConfigurationState.numberOfLanes || ''
          });
        }
        else {
          //Add Pool Config
          setFormState({
            ...formState,
            poolId: poolConfigurationState.poolId || Constants.DEFAULT_ID,
            poolName: poolConfigurationState.poolName || '',
            poolDimensionsId: poolConfigurationState.poolDimensionsId || Constants.DEFAULT_ID,
            poolDimensions: poolConfigurationState.poolDimensions || '',
            poolConfiguration: poolConfigurationState.poolConfiguration || [],
            poolConfigurationIndex: Constants.DEFAULT_ID,
            poolConfigurationId: Constants.DEFAULT_ID,
            courseId: Constants.DEFAULT_ID,
            courseName: '',
            numberOfLanes: ''
          });
          setIsDirty(true);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [facilityState.isObjLoaded, poolConfigurationState]);

  useEffect(() => {
    if (state.tryRedirect === true && facilityState.isSaving === false) {
      resetPoolConfigurationStateUpdateGrid();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state, facilityState]);

  function submitFormCallback() {
    //if pool exists, save changes with API call, otherwise hide detail view
    const updatedFacility = createFacilityObjForSubmit();
    if (formState.poolId > 0) {
      if (facilityState.isSaving === false) {
        putFacility(updatedFacility.facilityId, updatedFacility);
        setState({ tryRedirect: true });
      }
    }
  };

  function createFacilityObjForSubmit() {
    const facilityCopy = getFacilityCopy();
    const selectedPoolIndex = facilityCopy.facilityPool
      .findIndex((pool) => pool.facilityPoolId === formState.poolId);

    if (formState.poolConfigurationIndex >= 0) {
      //Edit
      let updatedPoolConfiguration = {};
      if (formState.poolConfigurationId > 0) {
        updatedPoolConfiguration = {
          poolConfigurationId: formState.poolConfigurationId,
          facilityPoolId: formState.poolId > 0 ? formState.poolId : undefined,
          courseId: formState.courseId,
          course: formState.poolId > 0 ? undefined : { courseCode: courseState.dataAsOptionsWithNames.find((option) => option.id === formState.courseId).name },
          numberOfLanes: formState.numberOfLanes
        }
      }
      else {
        updatedPoolConfiguration = {
          facilityPoolId: formState.poolId > 0 ? formState.poolId : undefined,
          courseId: formState.courseId,
          course: formState.poolId > 0 ? undefined : { courseCode: courseState.dataAsOptionsWithNames.find((option) => option.id === formState.courseId).name },
          numberOfLanes: formState.numberOfLanes
        }
      }
      let poolConfigurationArray = JSON.parse(JSON.stringify(formState.poolConfiguration));
      poolConfigurationArray[formState.poolConfigurationIndex] = updatedPoolConfiguration;
      updateFormState('poolConfiguration', poolConfigurationArray);
      if (selectedPoolIndex >= 0) {
        facilityCopy.facilityPool[selectedPoolIndex].poolConfiguration = poolConfigurationArray;
      }
      else {
        setPoolConfigurationState({
          ...poolConfigurationState,
          showPage: false,
          poolId: Constants.DEFAULT_ID,
          poolName: '',
          poolDimensionsId: Constants.DEFAULT_ID,
          poolDimensions: '',
          poolConfiguration: poolConfigurationArray,
          poolConfigurationIndex: Constants.DEFAULT_ID,
          poolConfigurationId: Constants.DEFAULT_ID,
          course: '',
          numberOfLanes: '',
          updatePoolConfigGrid: true
        });
      }
    }
    else {
      //Add
      const newPoolConfiguration = {
        facilityPoolId: formState.poolId > 0 ? formState.poolId : undefined,
        courseId: formState.courseId,
        course: formState.poolId > 0 ? undefined : { courseCode: courseState.dataAsOptionsWithNames.find((option) => option.id === formState.courseId).name },
        numberOfLanes: formState.numberOfLanes
      }
      let poolConfigurationArray = JSON.parse(JSON.stringify(formState.poolConfiguration));
      poolConfigurationArray.push(newPoolConfiguration);
      updateFormState('poolConfiguration', poolConfigurationArray);
      if (selectedPoolIndex >= 0) {
        facilityCopy.facilityPool[selectedPoolIndex].poolConfiguration = poolConfigurationArray;
      }
      else {
        setPoolConfigurationState({
          ...poolConfigurationState,
          showPage: false,
          poolId: Constants.DEFAULT_ID,
          poolName: '',
          poolDimensionsId: Constants.DEFAULT_ID,
          poolDimensions: '',
          poolConfiguration: poolConfigurationArray,
          poolConfigurationIndex: Constants.DEFAULT_ID,
          poolConfigurationId: Constants.DEFAULT_ID,
          course: '',
          numberOfLanes: '',
          updatePoolConfigGrid: true
        });
      }
    }

    return facilityCopy;
  };

  return {
    facilityState,
    courseState,
    formState,
    errorState,
    handleSubmit,
    onFormValueChanged: updateFormState,
    onValueTextPairChanged,
    onSaveClicked,
    onCancelClicked
  };
};

export default useAddEditFacilityPoolConfiguration;

function getInitialFormState() {
  return {
    poolId: Constants.DEFAULT_ID,
    poolName: '',
    poolDimensionsId: Constants.DEFAULT_ID,
    poolDimensions: '',
    poolConfiguration: [],
    poolConfigurationIndex: Constants.DEFAULT_ID,
    poolConfigurationId: Constants.DEFAULT_ID,
    courseId: Constants.DEFAULT_ID,
    courseName: '',
    numberOfLanes: ''
  };
};