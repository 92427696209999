import React, { createContext, useState } from 'react';

import PreCompOrganizationData from './PreCompOrganizationData';

export const PreCompOrganizationStateContext = createContext();

const PreCompOrganizationContextProvider = ({ children }) => {
  const stateHook = useState(PreCompOrganizationData.INITIAL_STATE);

  return (
    <PreCompOrganizationStateContext.Provider value={stateHook}>
      {children}
    </PreCompOrganizationStateContext.Provider>
  );
};

export default PreCompOrganizationContextProvider;