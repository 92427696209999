import { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "../../../../common/wrappers/ReactRouterDom";

import { useExecuteQueryByWidgetId } from "@sisense/sdk-ui";
import { filterFactory } from "@sisense/sdk-data";

import useClubPerformanceData from "../../../state/clubPerformance/UseClubPerformanceData";

import { getSisenseFormattedData } from "../../../utils/SisenseSortableGridUtils";

import * as DM from '../../../../club-roster-data';

import useNavRoutes from "../../../../common/state/security/UseNavRoutes";

import UseForm from "../../../../common/utils/UseForm";
import ToIntIfInt from "../../../../common/utils/ToIntIfInt";
import { isValidName, isValidNumber } from "../../../../common/utils/validation";

export const MAX_DATA_COUNT = 500;

export const COLUMN_NUMBERS = {
  FULL_NAME: 1,
  MEMBER_ID: 2,
  AGE: 3,
  PERSON_KEY: 0 // columnNumber 0 and below are hidden
};

const COLUMN_INDEXES = {
  FULL_NAME: 0,
  MEMBER_ID: 1,
  AGE: 2,
  PERSON_KEY: 3
};

const INITIAL_FORM_STATE = {
  firstName: '',
  lastName: '',
  age: ''
};

const INITIAL_FILTER_STATE = {
  firstName: '',
  lastName: '',
  age: ''
};

const useClubRosterWidget = (dashboardOid, widget) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { navRoutes } = useNavRoutes();
  const { clubPerformanceState } = useClubPerformanceData();
  const { formState, errorState, handleSubmit, setErrors, onFormValueChanged, setIsDirty, setFormState
  } = UseForm(INITIAL_FORM_STATE, onSubmitFormCallback, () => { });
  const [formattedData, setFormattedData] = useState({ rows: [], columns: [] });
  const [filterState, setFilterState] = useState(INITIAL_FILTER_STATE);
  const [state, setState] = useState({ offset: 0, enabled: false });

  const filters = useMemo(() => {
    const orgUnitFilter = filterFactory.equals(DM.Roster.OrgUnitId, clubPerformanceState.objData?.orgUnitNumber);
    const firstNameFilter = filterState.firstName ? filterFactory.contains(DM.Roster.FirstAndPreferredName, filterState.firstName) : null;
    const lastNameFilter = filterState.lastName ? filterFactory.contains(DM.Roster.LastName, filterState.lastName) : null;
    const ageFilter = filterState.age ? filterFactory.equals(DM.Roster.Age, filterState.age) : null;

    return [orgUnitFilter, firstNameFilter, lastNameFilter, ageFilter].filter((f) => !!f);
  }, [clubPerformanceState, filterState.firstName, filterState.lastName, filterState.age]);

  const { data, isLoading, isError } = useExecuteQueryByWidgetId({
    dashboardOid,
    widgetOid: widget?.oid,
    enabled: state.enabled === true && clubPerformanceState.objData?.orgUnitNumber && filterFactory.equals(DM.Roster.OrgUnitId, clubPerformanceState.objData?.orgUnitNumber),
    filters: filters,
    count: MAX_DATA_COUNT,
    offset: state.offset
  });

  const goToNextPage = (e) => {
    e?.preventDefault();

    const newOffset = state.offset + MAX_DATA_COUNT;
    setState({ ...state, offset: newOffset });
  };

  const goToPreviousPage = (e) => {
    e?.preventDefault();

    const newOffset = state.offset - MAX_DATA_COUNT;
    setState({ ...state, offset: newOffset });
  };

  const onViewTimesClicked = (e, row) => {
    e?.preventDefault();

    if (row) {
      const personKey = row.find(x => x.columnNumber === COLUMN_NUMBERS.PERSON_KEY)?.text;
      const personName = row.find(x => x.columnNumber === COLUMN_NUMBERS.FULL_NAME)?.text;

      navigate(navRoutes.CLUB_PERFORMANCE_ATHLETE_TIMES_DETAIL?.route, {
        state: {
          clubRosterFilters: filterState,
          personKey,
          personName
        }
      });
    }
  };

  useEffect(() => {
    if (location.state?.clubRosterFilters) {
      const clubRosterFilters = location.state.clubRosterFilters;

      setFilterState({
        ...filterState,
        firstName: clubRosterFilters.firstName || '',
        lastName: clubRosterFilters.lastName || '',
        age: clubRosterFilters.age || ''
      });
      setFormState({
        ...formState,
        firstName: clubRosterFilters.firstName || '',
        lastName: clubRosterFilters.lastName || '',
        age: clubRosterFilters.age || ''
      });
      setIsDirty(true);
    }

    setState({ ...state, enabled: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (data) {
      const newFormattedData = getSisenseFormattedData(data, COLUMN_INDEXES, COLUMN_NUMBERS);

      setFormattedData({
        ...formattedData,
        ...newFormattedData
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  function onCheckForErrors(formState) {
    let errors = {};

    if (formState.firstName.trim() !== '') {
      if (!isValidName(formState.firstName.trim())) {
        errors.firstName = 'First Name cannot contain numbers, special characters, or exceed 100 characters';
      }
    }

    if (formState.lastName.trim() !== '') {
      if (!isValidName(formState.lastName.trim())) {
        errors.lastName = 'Last Name cannot contain numbers, special characters, or exceed 100 characters';
      }
    }

    if (formState.age.trim() !== '') {
      const ageAsInt = ToIntIfInt(formState.age);

      if (!isValidNumber(ageAsInt)) {
        errors.age = 'Age must be a valid number';
      } else if (ageAsInt <= 0) {
        errors.age = 'Age must be greater than zero';
      }
    }

    return errors;
  }

  function onSubmitFormCallback(formState) {
    const errors = onCheckForErrors(formState);

    if (Object.keys(errors).length === 0) {
      setErrors({});
      setState({
        ...state,
        enabled: true,
        offset: 0
      });
      setFilterState({
        ...filterState,
        firstName: formState.firstName.trim(),
        lastName: formState.lastName.trim(),
        age: formState.age.trim()
      });
    } else {
      setErrors(errors);
    }
  }

  return {
    isLoading,
    isError,
    formattedData,
    state,
    formState,
    errorState,
    onFormValueChanged,
    handleSubmit,
    goToNextPage,
    goToPreviousPage,
    onViewTimesClicked
  };
};

export default useClubRosterWidget;