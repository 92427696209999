import Constants from "../../../../common/utils/Constants";

export const PROVIDER_OBJ = {
  entityName: '',
  tradeName: '',
  taxId: '',
  website: '',
  logo: '',
  curriculumUrl: '',
  proofOfInsuranceUrl: '',
  formW9Url: '',
  offersFinancialAssistance: '', //bool
  currentLTSGrantRecipient: '', //bool
  affiliatedWithUsasClub: '', //bool
  preEmploymentScreening: '', //bool
  childProtectionTraining: '', //bool
  offerAdultLearnToSwim: '', //bool
  offerSpecialNeeds: '', //bool
  backgroundCheckAcknowledgement: '', //bool
  backgroundCheckNotUsedExplanation: '',
  indemnificationAgreement: '', //bool
  indemnificationAgreementSignature: '',
  indemnificationAgreementSignatureDate: Constants.BLANK_DATE_STRING,
  learnToSwimCurriculumId: Constants.DEFAULT_ID,
  usasClubId: '',
  facilities: []
};

const LearnToSwimRegistrationConstants = {
  PROVIDER_OBJ
};

export default LearnToSwimRegistrationConstants;