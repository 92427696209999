import { API_NAMES, initApi } from "../../../common/utils/HttpApiHelper";

const postLTSFacilityData = (orgUnitId, orgUnitFacilityObj, state, setState) => {
  if (orgUnitId && orgUnitFacilityObj) {  
    const api = initApi(API_NAMES.COMMON, state, setState);
    const orgUnitIdForUrl = orgUnitId ? encodeURIComponent(orgUnitId) : 'NaN';
    const url = `/OrgUnit/${orgUnitIdForUrl}/facilityLinks`;

    return api?.executeObject ? api.executeObject(url, 'POST', orgUnitFacilityObj) : null;
  }
};

const getLTSFacilitiesData = (orgUnitId, state, setState) => {
  if (orgUnitId) {
    const api = initApi(API_NAMES.COMMON, state, setState);
    const orgUnitIdForUrl = orgUnitId ? encodeURIComponent(orgUnitId) : 'NaN';
    const url = `/OrgUnit/${orgUnitIdForUrl}/facilityLinks`;

    return api?.executeArray ? api.executeArray(url, 'GET') : null;
  }
};

const putLTSFacilityData = (orgUnitId, orgUnitFacilityId, orgUnitFacilityObj, state, setState) => {
  if (orgUnitId && orgUnitFacilityId > 0 && orgUnitFacilityObj) {
    const api = initApi(API_NAMES.COMMON, state, setState);
    const orgUnitIdForUrl = orgUnitId ? encodeURIComponent(orgUnitId) : 'NaN';
    const orgUnitFacilityIdForUrl = orgUnitFacilityId ? encodeURIComponent(orgUnitFacilityId) : 'NaN';
    const url = `/OrgUnit/${orgUnitIdForUrl}/facilityLinks/${orgUnitFacilityIdForUrl}`;

    return api?.executeObject ? api.executeObject(url, 'PUT', orgUnitFacilityObj) : null;
  }
};

const deleteLTSFacilityData = (orgUnitId, orgUnitFacilityId, state, setState) => {
  if (orgUnitId && orgUnitFacilityId > 0) {
    const api = initApi(API_NAMES.COMMON, state, setState);
    const orgUnitIdForUrl = orgUnitId ? encodeURIComponent(orgUnitId) : 'NaN';
    const orgUnitFacilityIdForUrl = orgUnitFacilityId ? encodeURIComponent(orgUnitFacilityId) : 'NaN';
    const url = `/OrgUnit/${orgUnitIdForUrl}/facilityLinks/${orgUnitFacilityIdForUrl}`;

    return api?.executeObject ? api.executeObject(url, 'DELETE') : null;
  }
};

const LearnToSwimFacilitiesData = {
  postLTSFacilityData,
  getLTSFacilitiesData,
  putLTSFacilityData,
  deleteLTSFacilityData
};

export default LearnToSwimFacilitiesData;