export const localValidate = (formState) => {
  let errors = {};

  if (formState.clubName.trim().length === 0) {
    errors.clubName = 'Club Name is required';
  }

  return errors;
};

const SATSearchFormValidation = async (formState) => {
  let errors = localValidate(formState);

  return errors ? errors : {};
};

export default SATSearchFormValidation;