import React, { createContext, useState } from 'react';

import SearchFacilityData from './SearchFacilityData';

export const SearchFacilityStateContext = createContext();

const SearchFacilityContextProvider = ({ children }) => {
  const stateHook = useState(SearchFacilityData.INITIAL_STATE);

  return (
    <SearchFacilityStateContext.Provider value={stateHook}>
      {children}
    </SearchFacilityStateContext.Provider>
  );
};

export default SearchFacilityContextProvider;