import React from 'react';

import useCategoryMeasureMultiselect from './UseCategoryMeasureMultiselect';

import MultiSelect from '../../../../common/components/multiSelect/MultiSelect';

import global from '../../../../common/components/GlobalStyle.module.css';

const CategoryMeasureMultiselect = ({ label, name, value, error, message, onChange, categoryMeasureId }) => {
  const { categoryMeasureMultiselectState } = useCategoryMeasureMultiselect(categoryMeasureId);

  return categoryMeasureMultiselectState.message
    ? <span className={global.LoadingMsg}>{categoryMeasureMultiselectState.message}</span>
    : (
      <MultiSelect
        label={label}
        name={name}
        value={value}
        options={categoryMeasureMultiselectState.options}
        error={error}
        message={message}
        isLoading={categoryMeasureMultiselectState.isArrayLoading}
        onChange={onChange} />
    );
};

export default CategoryMeasureMultiselect;