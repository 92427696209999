import { useState } from 'react';
import NewClubRegistrationStatusData from './NewClubRegistrationStatusData';

const useNewClubRegistrationStatusData = () => {
  const [newClubRegistrationStatusState, setNewClubRegistrationStatusState] = useState(NewClubRegistrationStatusData.INITIAL_STATE);

  const getNewClubRegistrationStatus = (personId) => {
    NewClubRegistrationStatusData.getNewClubRegistrationStatus(personId, newClubRegistrationStatusState, setNewClubRegistrationStatusState);
  };

  const clearNewClubRegistrationStatusData = () => {
    setNewClubRegistrationStatusState({
      ...newClubRegistrationStatusState,
      isObjLoading: false,
      isObjLoaded: false,
      isSaving: false,
      isSaved: false,
      objData: {},
      message: ''
    });
  };

  return {
    newClubRegistrationStatusState,
    setNewClubRegistrationStatusState,
    getNewClubRegistrationStatus,
    clearNewClubRegistrationStatusData
  };
};

export default useNewClubRegistrationStatusData;