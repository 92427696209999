import { Fragment } from "react";
import { Chart } from "@sisense/sdk-ui";
import { filterFactory } from "@sisense/sdk-data";

import useIndividualTimesSearchProgressionWidget from "./UseIndividualTimesSearchProgressionWidget";

import * as DM from '../../../../../../season-time-progression-data';

import Spinner from "../../../../../../common/components/spinners/Spinner";
import Headings from "../../../../../../common/components/headings/Headings";
import LeftArrowIcon from "../../../../../../common/components/icons/LeftArrowIcon";

import ToIntIfInt from "../../../../../../common/utils/ToIntIfInt";

import global from '../../../../../../common/components/GlobalStyle.module.css';

const IndividualTimesSearchProgressionWidget = ({ dashboardOid, widgetOid }) => {
  const {
    chartProps,
    isError,
    isLoading,
    widget,
    location,
    greaterThanYear,
    onBackToPersonTimesSearchClicked,
    convertRawTime
  } = useIndividualTimesSearchProgressionWidget(dashboardOid, widgetOid);

  return (
    <Fragment>
      <div className="row usas-extra-top-margin usas-extra-bottom-margin">
        <div className="col-xs-12 col-sm-6 usas-extra-top-margin">
          <Headings.H5>{location.state?.swimEventName} Progression for {location.state?.personName}</Headings.H5>
        </div>
        <div className="col-xs-12 col-sm-6 usas-extra-top-margin">
          <div className="pull-right">
            <button className={global.BackToSearchButton}
              type="button"
              onClick={onBackToPersonTimesSearchClicked}>
              <LeftArrowIcon />  Back to Individual Times for {location.state.personName || ''}
            </button>
          </div>
        </div>
      </div>
      <div className="row usas-extra-top-margin">
        <div className="col-xs-12">
          {isError
            ? <p className={global.ErrorMessage}>Error...</p>
            : isLoading
              ? <Spinner />
              : widget && chartProps
                ?
                <Chart
                  chartType={chartProps.chartType}
                  dataSet={chartProps.dataSet}
                  dataOptions={chartProps.dataOptions}
                  highlights={chartProps.highlights}
                  styleOptions={chartProps.styleOptions}
                  onBeforeRender={(options) => {
                    options.tooltip = {
                      ...options.tooltip,
                      formatter: function () {
                        // Assuming 'y' holds the time in seconds with milliseconds
                        return '<div style="min-width: 100px; color: rgb(91, 99, 114); font-size: 13px; line-height: 18px; margin: 4px 6px;">' + this.series.name + '<br/>' + `<span style="font-size: 15px; line-height: 18px; color: ${this.point.color}">` + convertRawTime(this.y) + '</span>' + '<hr class="csdk-border-t" style="margin: 7px 0px;">' + this.x + '</div>';
                      }
                    }
                    options.series.forEach(function (series) {
                      series.dataLabels = {
                        enabled: true,
                        formatter: function () {
                          // Assuming 'y' holds the time in seconds with milliseconds
                          return convertRawTime(this.y);
                        }
                      };
                    })
                    return options;
                  }}
                  filters={[filterFactory.greaterThan(DM.ReportingYear.SeasonYear, ToIntIfInt(greaterThanYear)),
                  filterFactory.equals(DM.SeasonTimeProgression.PersonKey, ToIntIfInt(location.state?.personKey)),
                  filterFactory.equals(DM.SeasonTimeProgression.SwimEventKey, ToIntIfInt(location.state?.swimEventKey))]}
                />
                : <Fragment />
          }
        </div>
      </div>
    </Fragment>
  );
};

export default IndividualTimesSearchProgressionWidget;