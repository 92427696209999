import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { Navigate } from '../../../common/wrappers/ReactRouterDom';

import PreCompAffiliationTypeContextProvider from '../../state/preCompAffiliationType/PreCompAffiliationTypeContextProvider';
import PreCompBusinessEntityTypeContextProvider from '../../state/preCompBusinessEntityType/PreCompBusinessEntityTypeContextProvider';
import PreCompCurriculumContextProvider from '../../state/preCompCurriculum/PreCompCurriculumContextProvider';
import PreCompInstructorStudentRatioContextProvider from '../../state/preCompInstructorStudentRatio/PreCompInstructorStudentRatioContextProvider';
import PreCompInstructionHoursContextProvider from '../../state/preCompInstructionHours/PreCompInstructionHoursContextProvider';
import PreCompOfferingTypeContextProvider from '../../state/preCompOfferingType/PreCompOfferingTypeContextProvider';
import PreCompPolicyTypeContextProvider from '../../state/preCompPolicyType/PreCompPolicyTypeContextProvider';
import PreCompOrganizationRegistrationContextProvider from '../../state/preCompOrganizationRegistration/PreCompOrganizationRegistrationContextProvider';
import PreCompLocationRegistrationContextProvider from '../../state/preCompLocationRegistration/PreCompLocationRegistrationContextProvider';
import PreCompLocationRegistrationNavContextProvider from '../../state/preCompLocationRegistration/preCompLocationRegistrationLeftNavBar/PreCompLocationRegistrationNavContextProvider';
import OrgRoleStaffRoleHierarchyContextProvider from '../../../common/state/orgRoleStaffRoleHierarchy/OrgRoleStaffRoleHierarchyContextProvider';

import FacilityContextProvider from '../../../common/state/facility/FacilityContextProvider';
import FacilityFiltersContextProvider from '../../../common/state/facility/FacilityFiltersContextProvider';
import SearchFacilityContextProvider from '../../../common/state/searchFacility/SearchFacilityContextProvider';
import BaseUsasClubContextProvider from '../../../common/state/clubBase/BaseUsasClubContextProvider';
import BaseUsasClubFiltersContextProvider from '../../../common/state/clubBase/BaseUsasClubFiltersContextProvider';
import SearchClubContextProvider from '../../../common/state/searchClub/SearchClubContextProvider';
import OrgRoleHierarchyContextProvider from '../../../common/state/orgRoleHierarchy/OrgRoleHierarchyContextProvider';
import PoolConfigurationContextProvider from '../../../common/state/poolConfiguration/PoolConfigurationContextProvider';
import LeftNavModalContextProvider from '../../../common/state/leftNavModal/LeftNavModalContextProvider';

import PreCompRegistrationEntry from './preCompRegistrationEntry/PreCompRegistrationEntry';
import PreCompRegistrationOrganizationInfo from './preCompRegistrationOrganizationInfo/PreCompRegistrationOrganizationInfo';
import PreCompRegistrationOrganizationContacts from './preCompRegistrationOrganizationContacts/PreCompRegistrationOrganizationContacts';
import PreCompRegistrationOrganizationContactsDetail from './preCompRegistrationOrganizationContacts/PreCompRegistrationOrganizationContactsDetail';
import PreCompRegistrationOrganizationInsurance from './preCompRegistrationOrganizationInsurance/PreCompRegistrationOrganizationInsurance';
import PreCompRegistrationLocationInfo from './preCompRegistrationLocationInfo/PreCompRegistrationLocationInfo';
import PreCompRegistrationLocationStaff from './preCompRegistrationLocationStaff/PreCompRegistrationLocationStaff';
import PreCompRegistrationLocationStaffDetail from './preCompRegistrationLocationStaff/PreCompRegistrationLocationStaffDetail';
import PreCompRegistrationLocationInstructorTraining from './preCompRegistrationLocationInstructorTraining/PreCompRegistrationLocationInstructorTraining';
import PreCompRegistrationLocationClubs from './preCompRegistrationLocationClubs/PreCompRegistrationLocationClubs';
import PreCompRegistrationLocationClubsLinkSearch from './preCompRegistrationLocationClubs/PreCompRegistrationLocationClubsLinkSearch';
import PreCompRegistrationLocationFacilities from './preCompRegistrationLocationFacilities/PreCompRegistrationLocationFacilities';
import PreCompRegistrationLocationFacilitiesLinkSearch from './preCompRegistrationLocationFacilities/PreCompRegistrationLocationFacilitiesLinkSearch';
import PreCompRegistrationLocationFacilitiesDetail from './preCompRegistrationLocationFacilities/PreCompRegistrationLocationFacilitiesDetail';
import PreCompRegistrationLocationEmergencyPlan from './preCompRegistrationLocationEmergencyPlan/PreCompRegistrationLocationEmergencyPlan';
import PreCompRegistrationSubmit from './preCompRegistrationSubmit/PreCompRegistrationSubmit';
import PreCompRegistrationLocationPrograms from './preCompRegistrationLocationPrograms/PreCompRegistrationLocationPrograms';
import PreCompRegistrationLocationProgramsDetail from './preCompRegistrationLocationPrograms/PreCompRegistrationLocationProgramsDetail';
import PreCompRegistrationLocationFacilitiesAdd from './preCompRegistrationLocationFacilities/PreCompRegistrationLocationFacilitiesAdd';
import PreCompRegistrationWelcome from './preCompRegistrationWelcome/PreCompRegistrationWelcome';

import LoadingModal from '../../../common/components/dialogs/LoadingModal';
import SecuredRouteElement from '../../../common/components/security/SecuredRouteElement';

import useNavRoutes from '../../../common/state/security/UseNavRoutes';

const PreCompetitiveRegistration = () => {
  const { navRoutes, isReadyToRoute } = useNavRoutes();

  if (isReadyToRoute !== true) {
    return <LoadingModal />;
  }

  return (
    <Routes>
      <Route path={navRoutes['PRE_COMP_REGISTRATION_WELCOME']?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes['PRE_COMP_REGISTRATION_WELCOME']}
            editElement={<PreCompRegistrationWelcome />} />
        } />
      <Route path={navRoutes['PRE_COMP_REGISTRATION_ENTRY']?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes['PRE_COMP_REGISTRATION_ENTRY']}
            editElement={<PreCompRegistrationEntry />} />
        } />
      <Route path={navRoutes['PRE_COMP_REGISTRATION_ORGANIZATION_INFO']?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes['PRE_COMP_REGISTRATION_ORGANIZATION_INFO']}
            editElement={<PreCompRegistrationOrganizationInfo />} />
        } />
      <Route path={navRoutes['PRE_COMP_REGISTRATION_ORGANIZATION_INSURANCE']?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes['PRE_COMP_REGISTRATION_ORGANIZATION_INSURANCE']}
            editElement={<PreCompRegistrationOrganizationInsurance />} />
        } />
      <Route path={navRoutes['PRE_COMP_REGISTRATION_ORGANIZATION_CONTACT']?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes['PRE_COMP_REGISTRATION_ORGANIZATION_CONTACT']}
            editElement={<PreCompRegistrationOrganizationContacts />} />
        } />
      <Route path={navRoutes['PRE_COMP_REGISTRATION_ORGANIZATION_CONTACT_DETAIL']?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes['PRE_COMP_REGISTRATION_ORGANIZATION_CONTACT_DETAIL']}
            editElement={<PreCompRegistrationOrganizationContactsDetail />} />
        } />
      <Route path={navRoutes['PRE_COMP_REGISTRATION_LOCATION_INFO']?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes['PRE_COMP_REGISTRATION_LOCATION_INFO']}
            editElement={<PreCompRegistrationLocationInfo />} />
        } />
      <Route path={navRoutes['PRE_COMP_REGISTRATION_LOCATION_PROGRAMS']?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes['PRE_COMP_REGISTRATION_LOCATION_PROGRAMS']}
            editElement={<PreCompRegistrationLocationPrograms />} />
        } />
      <Route path={navRoutes['PRE_COMP_REGISTRATION_LOCATION_PROGRAMS_DETAIL']?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes['PRE_COMP_REGISTRATION_LOCATION_PROGRAMS_DETAIL']}
            editElement={<PreCompRegistrationLocationProgramsDetail />} />
        } />
      <Route path={navRoutes['PRE_COMP_REGISTRATION_LOCATION_CLUB']?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes['PRE_COMP_REGISTRATION_LOCATION_CLUB']}
            editElement={<PreCompRegistrationLocationClubs />} />
        } />
      <Route path={navRoutes['PRE_COMP_REGISTRATION_LOCATION_CLUB_SEARCH']?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes['PRE_COMP_REGISTRATION_LOCATION_CLUB_SEARCH']}
            editElement={<PreCompRegistrationLocationClubsLinkSearch />} />
        } />
      <Route path={navRoutes['PRE_COMP_REGISTRATION_LOCATION_STAFF']?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes['PRE_COMP_REGISTRATION_LOCATION_STAFF']}
            editElement={<PreCompRegistrationLocationStaff />} />
        } />
      <Route path={navRoutes['PRE_COMP_REGISTRATION_LOCATION_STAFF_DETAIL']?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes['PRE_COMP_REGISTRATION_LOCATION_STAFF_DETAIL']}
            editElement={<PreCompRegistrationLocationStaffDetail />} />
        } />
      <Route path={navRoutes['PRE_COMP_REGISTRATION_LOCATION_FACILITY']?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes['PRE_COMP_REGISTRATION_LOCATION_FACILITY']}
            editElement={<PreCompRegistrationLocationFacilities />} />
        } />
      <Route path={navRoutes['PRE_COMP_REGISTRATION_LOCATION_FACILITY_ADD']?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes['PRE_COMP_REGISTRATION_LOCATION_FACILITY_ADD']}
            editElement={<PreCompRegistrationLocationFacilitiesAdd />} />
        } />
      <Route path={navRoutes['PRE_COMP_REGISTRATION_LOCATION_FACILITY_SEARCH']?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes['PRE_COMP_REGISTRATION_LOCATION_FACILITY_SEARCH']}
            editElement={<PreCompRegistrationLocationFacilitiesLinkSearch />} />
        } />
      <Route path={navRoutes['PRE_COMP_REGISTRATION_LOCATION_FACILITY_DETAIL']?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes['PRE_COMP_REGISTRATION_LOCATION_FACILITY_DETAIL']}
            editElement={<PreCompRegistrationLocationFacilitiesDetail />} />
        } />
      <Route path={navRoutes['PRE_COMP_REGISTRATION_LOCATION_INSTRUCTOR_TRAINING']?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes['PRE_COMP_REGISTRATION_LOCATION_INSTRUCTOR_TRAINING']}
            editElement={<PreCompRegistrationLocationInstructorTraining />} />
        } />
      <Route path={navRoutes['PRE_COMP_REGISTRATION_LOCATION_EMERGENCY_PLAN']?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes['PRE_COMP_REGISTRATION_LOCATION_EMERGENCY_PLAN']}
            editElement={<PreCompRegistrationLocationEmergencyPlan />} />
        } />
      <Route path={navRoutes['PRE_COMP_REGISTRATION_SUBMIT']?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes['PRE_COMP_REGISTRATION_SUBMIT']}
            editElement={<PreCompRegistrationSubmit />} />
        } />
      <Route path={'/'} element={<Navigate to={navRoutes['PRE_COMP_REGISTRATION_ENTRY']?.path} replace />} />
    </Routes>
  );
};

const PreCompRegistration = () => (
  <LeftNavModalContextProvider>
    <PreCompAffiliationTypeContextProvider>
      <PreCompBusinessEntityTypeContextProvider>
        <PreCompCurriculumContextProvider>
          <PreCompInstructorStudentRatioContextProvider>
            <PreCompInstructionHoursContextProvider>
              <PreCompOfferingTypeContextProvider>
                <PreCompPolicyTypeContextProvider>
                  <PreCompOrganizationRegistrationContextProvider>
                    <PreCompLocationRegistrationContextProvider>
                      <PreCompLocationRegistrationNavContextProvider>
                        <OrgRoleHierarchyContextProvider>
                          <OrgRoleStaffRoleHierarchyContextProvider>
                            <BaseUsasClubContextProvider>
                              <BaseUsasClubFiltersContextProvider>
                                <SearchClubContextProvider>
                                  <FacilityContextProvider>
                                    <FacilityFiltersContextProvider>
                                      <SearchFacilityContextProvider>
                                        <PoolConfigurationContextProvider>
                                          <PreCompetitiveRegistration />
                                        </PoolConfigurationContextProvider>
                                      </SearchFacilityContextProvider>
                                    </FacilityFiltersContextProvider>
                                  </FacilityContextProvider>
                                </SearchClubContextProvider>
                              </BaseUsasClubFiltersContextProvider>
                            </BaseUsasClubContextProvider>
                          </OrgRoleStaffRoleHierarchyContextProvider>
                        </OrgRoleHierarchyContextProvider>
                      </PreCompLocationRegistrationNavContextProvider>
                    </PreCompLocationRegistrationContextProvider>
                  </PreCompOrganizationRegistrationContextProvider>
                </PreCompPolicyTypeContextProvider>
              </PreCompOfferingTypeContextProvider>
            </PreCompInstructionHoursContextProvider>
          </PreCompInstructorStudentRatioContextProvider>
        </PreCompCurriculumContextProvider>
      </PreCompBusinessEntityTypeContextProvider >
    </PreCompAffiliationTypeContextProvider >
  </LeftNavModalContextProvider >
);

export default PreCompRegistration;