import { BASIC_INITIAL_STATE } from '../../../common/utils/HttpHelper';

import getClubVendorsByOrgUnit from './GetClubVendorByOrgUnitIdData';
import deleteClubVendorFromOrgUnit from './DeleteClubVendorData';
import postClubVendorForOrgUnit from './PostClubVendorForOrgUnitIdData';

const INITIAL_STATE = {
  ...BASIC_INITIAL_STATE
};

const ClubVendorData = {
  INITIAL_STATE,
  getClubVendorsByOrgUnit,
  deleteClubVendorFromOrgUnit,
  postClubVendorForOrgUnit,

};

export default ClubVendorData;