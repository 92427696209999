import React from 'react';

import useClubReporting from './UseClubReporting';

import ReportList from '../../../../common/components/embeddedReports/ReportList';

const ClubReporting = () => {
  const { reportParameters, contextId, routeName } = useClubReporting();

  return (
    <ReportList listTitle="Club Reports"
      routeName={routeName}
      contextId={contextId}
      reportParameters={reportParameters} />
  );
};

export default ClubReporting;