import type { Dimension, DateDimension, Attribute, DataSourceInfo } from '@sisense/sdk-data';

import { createAttribute, createDateDimension, createDimension } from '@sisense/sdk-data';

export const DataSource: DataSourceInfo = { title: 'Season Time Progression', type: 'elasticube' };

interface ReportingYearDimension extends Dimension {
    EndDateInt: Attribute;
    OrganizationCode: Attribute;
    OrganizationName: Attribute;
    Quad: Attribute;
    ReportingYearKey: Attribute;
    ReportPeriodId: Attribute;
    Season: Attribute;
    SeasonYear: Attribute;
    StartDateInt: Attribute;
    EndDate: DateDimension;
    ModifiedDatetime: DateDimension;
    StartDate: DateDimension;
}
export const ReportingYear = createDimension({
    name: 'ReportingYear',
    EndDateInt: createAttribute({
        name: 'EndDateInt',
        type: 'numeric-attribute',
        expression: '[ReportingYear.EndDateInt]',
    }),
    OrganizationCode: createAttribute({
        name: 'OrganizationCode',
        type: 'text-attribute',
        expression: '[ReportingYear.OrganizationCode]',
    }),
    OrganizationName: createAttribute({
        name: 'OrganizationName',
        type: 'text-attribute',
        expression: '[ReportingYear.OrganizationName]',
    }),
    Quad: createAttribute({
        name: 'Quad',
        type: 'text-attribute',
        expression: '[ReportingYear.Quad]',
    }),
    ReportingYearKey: createAttribute({
        name: 'ReportingYearKey',
        type: 'numeric-attribute',
        expression: '[ReportingYear.ReportingYearKey]',
    }),
    ReportPeriodId: createAttribute({
        name: 'ReportPeriodId',
        type: 'numeric-attribute',
        expression: '[ReportingYear.ReportPeriodId]',
    }),
    Season: createAttribute({
        name: 'Season',
        type: 'text-attribute',
        expression: '[ReportingYear.Season]',
    }),
    SeasonYear: createAttribute({
        name: 'SeasonYear',
        type: 'numeric-attribute',
        expression: '[ReportingYear.SeasonYear]',
    }),
    StartDateInt: createAttribute({
        name: 'StartDateInt',
        type: 'numeric-attribute',
        expression: '[ReportingYear.StartDateInt]',
    }),
    EndDate: createDateDimension({
        name: 'EndDate',
        expression: '[ReportingYear.EndDate (Calendar)]',
    }),
    ModifiedDatetime: createDateDimension({
        name: 'ModifiedDatetime',
        expression: '[ReportingYear.ModifiedDatetime (Calendar)]',
    }),
    StartDate: createDateDimension({
        name: 'StartDate',
        expression: '[ReportingYear.StartDate (Calendar)]',
    }),
}) as ReportingYearDimension;

interface SeasonTimeProgressionDimension extends Dimension {
    PersonKey: Attribute;
    ReportingYearKey: Attribute;
    SwimEventKey: Attribute;
    SwimTime: Attribute;
    SwimTimeSeconds: Attribute;
}
export const SeasonTimeProgression = createDimension({
    name: 'SeasonTimeProgression',
    PersonKey: createAttribute({
        name: 'PersonKey',
        type: 'numeric-attribute',
        expression: '[SeasonTimeProgression.PersonKey]',
    }),
    ReportingYearKey: createAttribute({
        name: 'ReportingYearKey',
        type: 'numeric-attribute',
        expression: '[SeasonTimeProgression.ReportingYearKey]',
    }),
    SwimEventKey: createAttribute({
        name: 'SwimEventKey',
        type: 'numeric-attribute',
        expression: '[SeasonTimeProgression.SwimEventKey]',
    }),
    SwimTime: createAttribute({
        name: 'SwimTime',
        type: 'text-attribute',
        expression: '[SeasonTimeProgression.SwimTime]',
    }),
    SwimTimeSeconds: createAttribute({
        name: 'SwimTimeSeconds',
        type: 'numeric-attribute',
        expression: '[SeasonTimeProgression.SwimTimeSeconds]',
    }),
}) as SeasonTimeProgressionDimension;

interface SwimEventDimension extends Dimension {
    CourseCode: Attribute;
    CourseName: Attribute;
    Distance: Attribute;
    EventCode: Attribute;
    EventId: Attribute;
    EventName: Attribute;
    IsOlympicEvent: Attribute;
    RaceType: Attribute;
    Stroke: Attribute;
    StrokeDetail: Attribute;
    StrokeShortCode: Attribute;
    StrokeTypeId: Attribute;
    SwimEventKey: Attribute;
    ModifiedDatetime: DateDimension;
}
export const SwimEvent = createDimension({
    name: 'SwimEvent',
    CourseCode: createAttribute({
        name: 'CourseCode',
        type: 'text-attribute',
        expression: '[SwimEvent.CourseCode]',
    }),
    CourseName: createAttribute({
        name: 'CourseName',
        type: 'text-attribute',
        expression: '[SwimEvent.CourseName]',
    }),
    Distance: createAttribute({
        name: 'Distance',
        type: 'numeric-attribute',
        expression: '[SwimEvent.Distance]',
    }),
    EventCode: createAttribute({
        name: 'EventCode',
        type: 'text-attribute',
        expression: '[SwimEvent.EventCode]',
    }),
    EventId: createAttribute({
        name: 'EventId',
        type: 'numeric-attribute',
        expression: '[SwimEvent.EventId]',
    }),
    EventName: createAttribute({
        name: 'EventName',
        type: 'text-attribute',
        expression: '[SwimEvent.EventName]',
    }),
    IsOlympicEvent: createAttribute({
        name: 'IsOlympicEvent',
        type: 'text-attribute',
        expression: '[SwimEvent.IsOlympicEvent]',
    }),
    RaceType: createAttribute({
        name: 'RaceType',
        type: 'text-attribute',
        expression: '[SwimEvent.RaceType]',
    }),
    Stroke: createAttribute({
        name: 'Stroke',
        type: 'text-attribute',
        expression: '[SwimEvent.Stroke]',
    }),
    StrokeDetail: createAttribute({
        name: 'StrokeDetail',
        type: 'text-attribute',
        expression: '[SwimEvent.StrokeDetail]',
    }),
    StrokeShortCode: createAttribute({
        name: 'StrokeShortCode',
        type: 'text-attribute',
        expression: '[SwimEvent.StrokeShortCode]',
    }),
    StrokeTypeId: createAttribute({
        name: 'StrokeTypeId',
        type: 'numeric-attribute',
        expression: '[SwimEvent.StrokeTypeId]',
    }),
    SwimEventKey: createAttribute({
        name: 'SwimEventKey',
        type: 'numeric-attribute',
        expression: '[SwimEvent.SwimEventKey]',
    }),
    ModifiedDatetime: createDateDimension({
        name: 'ModifiedDatetime',
        expression: '[SwimEvent.ModifiedDatetime (Calendar)]',
    }),
}) as SwimEventDimension;
