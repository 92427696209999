import React, { Fragment } from 'react';

import usePreCompLocationPrograms from './UsePreCompLocationPrograms';

import PreCompLocationProgramsGrid from '../components/grids/PreCompLocationProgramsGrid';

import Headings from '../../../../common/components/headings/Headings';
import PopUpModal from '../../../../common/components/dialogs/PopUpModal';
import ActionIntraPageButton from '../../../../common/components/buttons/ActionIntraPageButton';

import Constants from '../../../../common/utils/Constants';

const PreCompLocationPrograms = () => {
  const {
    isSaving,
    isLoading,
    gridState,
    onAddOffering,
    onEditOffering,
    onDeleteOffering
  } = usePreCompLocationPrograms();

  return (
    <Fragment>
      <Headings.H3>Program Offerings</Headings.H3>
      <Fragment>
        <div className="row usas-extra-top-margin">
          <div className="col-xs-12">
            <ActionIntraPageButton onClick={onAddOffering}>Add Program Offering</ActionIntraPageButton>
          </div>
        </div>
        <div className="row usas-extra-top-margin usas-extra-bottom-margin">
          <div className="col-xs-12">
            <PreCompLocationProgramsGrid
              data={gridState}
              isLoading={isLoading}
              onEdit={onEditOffering}
              onDelete={onDeleteOffering} />
          </div>
        </div>
      </Fragment>
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={isLoading} />
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.SAVING_MSG}
        displayPopUp={isSaving} />
    </Fragment>
  );
};

export default PreCompLocationPrograms;