import React, { Fragment } from 'react';

import useClubSwimAThonContextView from './UseClubSwimAThonContextView';

import NavLinks from './NavLinks';

import Headings from '../../../common/components/headings/Headings';
import MobileContextBasedNav from '../../../common/components/contextBasedNav/MobileContextBasedNav';
import LargeContextBasedNav from '../../../common/components/contextBasedNav/LargeContextBasedNav';
import LeftArrowIcon from '../../../common/components/icons/LeftArrowIcon';
import PageNotFound from '../../../common/components/PageNotFound';
import PopUpModal from '../../../common/components/dialogs/PopUpModal';

import Constants from '../../../common/utils/Constants';
import global from '../../../common/components/GlobalStyle.module.css';

const ClubSwimAThonContextView = ({ children }) => {
  const {
    satState,
    backToLabel,
    onHomeClicked,
    onBackClicked,
    selectOrgUnitState,
    backToSelectionText
  } = useClubSwimAThonContextView();

  if (!satState || satState.isObjLoaded === false) {
    return satState.isObjLoading === false
      ? (
        <Fragment>
          <div className="row usas-extra-bottom-margin">
            <div className="col-xs-12">
              <button className={global.BackToSearchButton}
                type="button"
                onClick={onBackClicked}>
                <LeftArrowIcon />  {backToLabel}
              </button>
            </div>
          </div>
          <div className="row usas-extra-bottom-margin">
            <div className="col-xs-12">
              <PageNotFound />
            </div>
          </div>
        </Fragment>
      ) : (
        <div className="row usas-extra-bottom-margin">
          <div className="col-xs-12">
            <PopUpModal
              widthPct={50}
              maxWidth={250}
              title={Constants.LOADING_MSG}
              displayPopUp={true} />
          </div>
        </div>
      );
  }

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <button className={global.HeaderButton} onClick={onHomeClicked}>
            <Headings.H3
              className={[global.NoBottomMargin, 'col-xs-12'].join(' ')}
              excludeBar={true}>
              Club Swim-a-Thon
            </Headings.H3>
          </button>
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12">
          <hr className="usas-bar-turmeric" />
        </div>
      </div>
      {selectOrgUnitState.showBackToSelection === true && backToLabel === backToSelectionText &&
        <div className="row">
          <div className="col-xs-12">
            <button className={global.BackToSearchButton}
              type="button"
              onClick={onBackClicked}>
              <LeftArrowIcon />  {backToLabel}
            </button>
          </div>
        </div>}
      {backToLabel !== backToSelectionText &&
        <div className="row">
          <div className="col-xs-12">
            <button className={global.BackToSearchButton}
              type="button"
              onClick={onBackClicked}>
              <LeftArrowIcon />  {backToLabel}
            </button>
          </div>
        </div>}
      <div className="row">
        <div className={["col-xs-12", global.HeaderText].join(' ')}>
          <p className={global.HeaderText}><strong>Editing:</strong> {satState.orgUnitName}</p>
        </div>
      </div>
      <div className="row">
        <div className="visible-xs col-xs-12">
          <MobileContextBasedNav taxonomyName="SwimAThonDetail" />
        </div>
      </div>
      <div className="row hidden-xs">
        <div className="col-xs-12">
          <LargeContextBasedNav taxonomyName="SwimAThonDetail" homeRoute={NavLinks.CLUB_SWIMATHON_CONTRACTS} />
        </div>
      </div>
      {children}
    </Fragment>
  );
};

export default ClubSwimAThonContextView;