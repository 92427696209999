import React, { createContext, useState } from 'react';

import SearchPreCompData from './SearchPreCompData';

export const SearchPreCompStateContext = createContext();

const SearchPreCompContextProvider = ({ children }) => {
  const stateHook = useState(SearchPreCompData.INITIAL_STATE);

  return (
    <SearchPreCompStateContext.Provider value={stateHook}>
      {children}
    </SearchPreCompStateContext.Provider>
  );
};

export default SearchPreCompContextProvider;