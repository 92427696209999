import React, { createContext, useState } from 'react';

import { PROVIDER_OBJ } from '../../views/learnToSwimRegistration/utils/LearnToSwimRegistrationConstants';

import { BASIC_INITIAL_STATE } from '../../../common/utils/HttpHelper';

export const LearnToSwimRegistrationStateContext = createContext();

const INITIAL_STATE = {
  ...BASIC_INITIAL_STATE,
  objData: { ...PROVIDER_OBJ }
};

const LearnToSwimRegistrationContextProvider = ({ children }) => {
  const stateHook = useState(INITIAL_STATE);

  return (
    <LearnToSwimRegistrationStateContext.Provider value={stateHook}>
      {children}
    </LearnToSwimRegistrationStateContext.Provider>
  );
};

export default LearnToSwimRegistrationContextProvider;