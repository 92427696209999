import { Routes, Route } from 'react-router-dom';
import { Navigate } from '../../../common/wrappers/ReactRouterDom';

import MemberAdministrationHeaderContextProvider from '../../state/memberAdministrationHeader/MemberAdministrationHeaderContextProvider';

import ReportPeriodSeasonContextProvider from '../../../common/state/reportPeriodSeason/ReportPeriodSeasonContextProvider';
import SelectOrgUnitContextProvider from '../../../common/state/selectOrgUnit/SelectOrgUnitContextProvider';
import BasePersonContextProvider from '../../../common/state/personBase/BasePersonContextProvider';
import PersonOrgRoleContextProvider from '../../../common/state/personOrgRole/PersonOrgRoleContextProvider';
import MemberAdministrationLscStaffContextProvider from '../../../common/state/memberAdministration/lscStaff/MemberAdministrationLscStaffContextProvider';
import MemberAdministrationLscBoardContextProvider from '../../../common/state/memberAdministration/lscBoard/MemberAdministrationLscBoardContextProvider';
import MemberAdministrationLscEmailContextProvider from '../../../common/state/memberAdministration/lscEmail/MemberAdministrationLscEmailContextProvider';
import MemberAdministrationLscSecurityRolesContextProvider from '../../../common/state/memberAdministration/lscSecurityRoles/MemberAdministrationLscSecurityRolesContextProvider';

import MemberAdministrationContextView from './MemberAdministrationContextView';

import OrgUnitSelection from './orgUnitSelection/OrgUnitSelection';
import LSCHQSelection from './lscHqSelection/LSCHQSelection';
import MemberAdministrator from './memberAdministrator/MemberAdministrator';
import LSCBoardMembers from './lscBoardMembers/LSCBoardMembers';
import AddLSCBoardMember from './lscBoardMembers/boardAdd/AddLSCBoardMember';
import EditLSCBoardMember from './lscBoardMembers/boardEdit/EditLSCBoardMember';
import LSCStaffMembers from './lscStaffMembers/LSCStaffMembers';
import AddLSCStaffMember from './lscStaffMembers/staffAdd/AddLSCStaffMember';
import EditLSCStaffMember from './lscStaffMembers/staffEdit/EditLSCStaffMember';
import LSCRoleEmails from './lscRoleEmails/LSCRoleEmails';
import EditLSCRoleEmail from './lscRoleEmails/emailEdit/EditLSCRoleEmail';
import AddLSCRoleEmail from './lscRoleEmails/emailAdd/AddLSCRoleEmail';
import LSCRegistrationManagement from './lscRegistrationManagement/LSCRegistrationManagement';
import LSCSecurityRoles from './lscSecurityRoles/LSCSecurityRoles';
import AddLSCSecurityRole from './lscSecurityRoles/securityRolesAdd/AddLSCSecurityRole';
import EditLSCSecurityRole from './lscSecurityRoles/securityRolesEdit/EditLSCSecurityRole';

import LoadingModal from '../../../common/components/dialogs/LoadingModal';
import SecuredRouteElement from '../../../common/components/security/SecuredRouteElement';

import useNavRoutes from '../../../common/state/security/UseNavRoutes';

const ContextRoutes = ({ navRoutes }) => {
  return (
    <MemberAdministrationContextView>
      <Routes>
        <Route path={navRoutes['CLUB_MEMBER_ADMIN_HOME']?.path}
          element={
            <SecuredRouteElement
              navRoute={navRoutes['CLUB_MEMBER_ADMIN_HOME']}
              editElement={<MemberAdministrator />} />
          } />
        <Route path={navRoutes['CLUB_MEMBER_ADMIN_LSC_BOARD']?.path}
          element={
            <SecuredRouteElement
              navRoute={navRoutes['CLUB_MEMBER_ADMIN_LSC_BOARD']}
              editElement={<LSCBoardMembers />} />
          } />
        <Route path={navRoutes['CLUB_MEMBER_ADMIN_LSC_BOARD_ADD']?.path}
          element={
            <SecuredRouteElement
              navRoute={navRoutes['CLUB_MEMBER_ADMIN_LSC_BOARD_ADD']}
              editElement={<AddLSCBoardMember />} />
          } />
        <Route path={navRoutes['CLUB_MEMBER_ADMIN_LSC_BOARD_EDIT']?.path}
          element={
            <SecuredRouteElement
              navRoute={navRoutes['CLUB_MEMBER_ADMIN_LSC_BOARD_EDIT']}
              editElement={<EditLSCBoardMember />} />
          } />
        <Route path={navRoutes['CLUB_MEMBER_ADMIN_LSC_STAFF']?.path}
          element={
            <SecuredRouteElement
              navRoute={navRoutes['CLUB_MEMBER_ADMIN_LSC_STAFF']}
              editElement={<LSCStaffMembers />} />
          } />
        <Route path={navRoutes['CLUB_MEMBER_ADMIN_LSC_STAFF_ADD']?.path}
          element={
            <SecuredRouteElement
              navRoute={navRoutes['CLUB_MEMBER_ADMIN_LSC_STAFF_ADD']}
              editElement={<AddLSCStaffMember />} />
          } />
        <Route path={navRoutes['CLUB_MEMBER_ADMIN_LSC_STAFF_EDIT']?.path}
          element={
            <SecuredRouteElement
              navRoute={navRoutes['CLUB_MEMBER_ADMIN_LSC_STAFF_EDIT']}
              editElement={<EditLSCStaffMember />} />
          } />
        <Route path={navRoutes['CLUB_MEMBER_ADMIN_LSC_EMAILS']?.path}
          element={
            <SecuredRouteElement
              navRoute={navRoutes['CLUB_MEMBER_ADMIN_LSC_EMAILS']}
              editElement={<LSCRoleEmails />} />
          } />
        <Route path={navRoutes['CLUB_MEMBER_ADMIN_LSC_EMAILS_ADD']?.path}
          element={
            <SecuredRouteElement
              navRoute={navRoutes['CLUB_MEMBER_ADMIN_LSC_EMAILS_ADD']}
              editElement={<AddLSCRoleEmail />} />
          } />
        <Route path={navRoutes['CLUB_MEMBER_ADMIN_LSC_EMAILS_EDIT']?.path}
          element={
            <SecuredRouteElement
              navRoute={navRoutes['CLUB_MEMBER_ADMIN_LSC_EMAILS_EDIT']}
              editElement={<EditLSCRoleEmail />} />
          } />
        <Route path={navRoutes['CLUB_MEMBER_ADMIN_LSC_REG_MANAGEMENT']?.path}
          element={
            <SecuredRouteElement
              navRoute={navRoutes['CLUB_MEMBER_ADMIN_LSC_REG_MANAGEMENT']}
              editElement={<LSCRegistrationManagement />} />
          } />
        <Route path={navRoutes['CLUB_MEMBER_ADMIN_LSC_SECURITY_ROLES']?.path}
          element={
            <SecuredRouteElement
              navRoute={navRoutes['CLUB_MEMBER_ADMIN_LSC_SECURITY_ROLES']}
              editElement={<LSCSecurityRoles />} />
          } />
        <Route path={navRoutes['CLUB_MEMBER_ADMIN_LSC_SECURITY_ROLES_ADD']?.path}
          element={
            <SecuredRouteElement
              navRoute={navRoutes['CLUB_MEMBER_ADMIN_LSC_SECURITY_ROLES_ADD']}
              editElement={<AddLSCSecurityRole />} />
          } />
        <Route path={navRoutes['CLUB_MEMBER_ADMIN_LSC_SECURITY_ROLES_EDIT']?.path}
          element={
            <SecuredRouteElement
              navRoute={navRoutes['CLUB_MEMBER_ADMIN_LSC_SECURITY_ROLES_EDIT']}
              editElement={<EditLSCSecurityRole />} />
          } />
        <Route path={'/'} element={<Navigate to={navRoutes['CLUB_MEMBER_ADMIN_HOME']?.path} replace />} />
      </Routes>
    </MemberAdministrationContextView>
  );
};

const MemberAdministrationRoutes = () => {
  const { navRoutes, isReadyToRoute } = useNavRoutes();

  if (isReadyToRoute !== true) {
    return <LoadingModal />;
  }

  return (
    <Routes>
      <Route path={navRoutes['CLUB_MEMBER_ADMIN_HQ_SELECTION']?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes['CLUB_MEMBER_ADMIN_HQ_SELECTION']}
            editElement={<LSCHQSelection />} />
        } />
      <Route path={navRoutes['CLUB_MEMBER_ADMIN_SELECTION']?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes['CLUB_MEMBER_ADMIN_SELECTION']}
            editElement={<OrgUnitSelection />} />
        } />
      <Route path={'/*'} element={<ContextRoutes navRoutes={navRoutes} />} />
      <Route path={'/'} element={navRoutes['CLUB_MEMBER_ADMIN_HQ_SELECTION']?.route ?
        <Navigate to={navRoutes['CLUB_MEMBER_ADMIN_HQ_SELECTION']?.route} replace /> :
        <Navigate to={navRoutes['CLUB_MEMBER_ADMIN_SELECTION']?.route} replace />} />
    </Routes>
  );
};

const MemberAdministration = () => (
  <SelectOrgUnitContextProvider>
    <MemberAdministrationHeaderContextProvider>
      <BasePersonContextProvider>
        <PersonOrgRoleContextProvider>
          <ReportPeriodSeasonContextProvider>
            <MemberAdministrationLscStaffContextProvider>
              <MemberAdministrationLscBoardContextProvider>
                <MemberAdministrationLscEmailContextProvider>
                  <MemberAdministrationLscSecurityRolesContextProvider>
                    <MemberAdministrationRoutes />
                  </MemberAdministrationLscSecurityRolesContextProvider>
                </MemberAdministrationLscEmailContextProvider>
              </MemberAdministrationLscBoardContextProvider>
            </MemberAdministrationLscStaffContextProvider>
          </ReportPeriodSeasonContextProvider>
        </PersonOrgRoleContextProvider>
      </BasePersonContextProvider>
    </MemberAdministrationHeaderContextProvider>
  </SelectOrgUnitContextProvider>
);

export default MemberAdministration;