import {
  isValid5DigitZip, isValid9DigitZip, 
  isValidCanadianZip ,isValidAddressLine
} from '../../../utils/validation';

export const localValidate = (formState) => {
  let errors = {};
  let filterCount = 0;

  if (formState.facilityName.trim() !== '') {
    filterCount += 1;

    if (formState.facilityName.trim().length > 100) {
      errors.facilityName = 'Facility Name cannot exceed 100 characters';
    }
  }

  if (formState.city.trim() !== '') {
    filterCount += 1;

    if (!isValidAddressLine(formState.city.trim())) {
      errors.city = 'City cannot exceed 100 characters. Letters, numbers, number signs, spaces, periods, dashes, slashes, and apostrophes are allowed.';
    }
  }

  if (formState.stateName !== '') {
    filterCount += 1;

  }

  if (formState.country !== '') {
    filterCount += 1;
  }

  if (formState.postalCode.trim() !== '') {
    filterCount += 1;

    if (!isValid5DigitZip(formState.postalCode.trim()) && !isValid9DigitZip(formState.postalCode.trim()) && !isValidCanadianZip(formState.postalCode.trim())) {
      errors.postalCode = 'Zip Code must be 5 digits, or 9 digits in XXXXX-XXXX format';
    }
  }

  if (filterCount < 1) {
    errors.filter = 'Please enter information in at least one search filter';
  }

  return errors;
};

const SearchFacilityValidation = async (formState) => {
  let errors = localValidate(formState);

  return errors ? errors : {};
};

export default SearchFacilityValidation;