import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from '../../../../common/wrappers/ReactRouterDom';

import useLearnToSwimData from '../../../state/learnToSwim/UseLearnToSwimData';
import useLearnToSwimStaffData from '../../../state/learnToSwimStaff/UseLearnToSwimStaffData';

import NavLinks from '../utils/NavLinks';
import { PRIMARY_CONTACT_ORG_ROLE_NAME } from '../utils/LearnToSwimConstants';
import { createStaffMember, findCurrentPrimaryContact } from '../utils/LearnToSwimUtils';

import useEnvironmentVariableData from '../../../../common/state/environmentVariable/UseEnvironmentVariableData';

const INITIAL_PRIMARY_CONTACT_MODAL_STATE = {
  currentPrimaryContactPersonId: '',
  currentPrimaryContact: {},
  isOnlyRole: false,
  displayModal: false
};

const useLearnToSwimStaffEdit = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { environmentVariableState, LTS_STAFF_ROLE_GROUP_ID } = useEnvironmentVariableData();
  const { ltsProviderState } = useLearnToSwimData();
  const { ltsStaffState, ltsStaffMemberState, putLTSStaffMember } = useLearnToSwimStaffData();
  const [primaryContactModalState, setPrimaryContactModalState] = useState(INITIAL_PRIMARY_CONTACT_MODAL_STATE);
  const [existingPersonState, setExistingPersonState] = useState({});
  const [updatedPersonState, setUpdatedPersonState] = useState({});
  const [displayPrimaryContactErrorModalState, setDisplayPrimaryContactErrorModalState] = useState(false);

  const onCancelClicked = () => {
    navigate(NavLinks.LEARN_TO_SWIM_STAFF);
  };

  const onPrimaryContactModalContinueClicked = () => {
    setPrimaryContactModalState({
      ...primaryContactModalState,
      displayModal: false
    });

    updateStaffMember(updatedPersonState, true);
  };

  const onPrimaryContactModalCancelClicked = () => {
    setPrimaryContactModalState({ ...INITIAL_PRIMARY_CONTACT_MODAL_STATE });
  };

  const onPrimaryContactErrorModalCancelClicked = () => {
    setDisplayPrimaryContactErrorModalState(false);
  };

  const onSubmitFormCallback = (formState) => {
    const currentPrimaryContact = findCurrentPrimaryContact(ltsStaffState.arrayData);
    const isPrimaryContactSelected = formState.orgRoles.some((x) => x.name === PRIMARY_CONTACT_ORG_ROLE_NAME);

    if (isPrimaryContactSelected === true) {
      if (currentPrimaryContact?.personId === existingPersonState.personId) { // editing current primary contact
        updateStaffMember(formState, false);
      } else if (currentPrimaryContact) { // replacing existing primary contact
        setPrimaryContactModalState({
          ...primaryContactModalState,
          currentPrimaryContactPersonId: currentPrimaryContact.personId,
          currentPrimaryContact,
          isOnlyRole: currentPrimaryContact.personOrgRole.length === 1,
          displayModal: true
        });
        setUpdatedPersonState({ ...formState });
      } else { // there is no existing primary contact
        updateStaffMember(formState, false);
      }
    } else {
      if (currentPrimaryContact?.personId === existingPersonState.personId) { // removing current primary contact
        setDisplayPrimaryContactErrorModalState(true);
      } else { // is not primary contact
        updateStaffMember(formState, false);
      }
    }
  };

  useEffect(() => {
    if (location.state?.staffObj) {
      setExistingPersonState(location.state.staffObj);
    } else {
      navigate(NavLinks.LEARN_TO_SWIM_STAFF);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function updateStaffMember(formState, updateCurrentPrimaryContact) {
    const orgUnitId = ltsProviderState.objData?.orgUnitId;
    const staffObj = createStaffMember(existingPersonState, formState);

    if (orgUnitId) {
      if (updateCurrentPrimaryContact === true) {
        const primaryContactStaffObj = createStaffMember(primaryContactModalState.currentPrimaryContact, { orgRoles: primaryContactModalState.currentPrimaryContact.personOrgRole.filter((x) => x.orgRoleName !== PRIMARY_CONTACT_ORG_ROLE_NAME) });
        const putLTSPrimaryContactStaffMemberPromise = putLTSStaffMember(orgUnitId, primaryContactModalState.currentPrimaryContactPersonId, LTS_STAFF_ROLE_GROUP_ID, primaryContactStaffObj);

        if (putLTSPrimaryContactStaffMemberPromise !== null) {
          putLTSPrimaryContactStaffMemberPromise.then((newState) => {
            if (newState !== null) {
              const putLTSStaffMemberPromise = putLTSStaffMember(orgUnitId, staffObj.personId, LTS_STAFF_ROLE_GROUP_ID, staffObj);

              if (putLTSStaffMemberPromise !== null) {
                putLTSStaffMemberPromise.then((newState) => {
                  if (newState !== null) {
                    navigate(NavLinks.LEARN_TO_SWIM_STAFF, { state: { refreshState: true } })
                  }
                }).catch((e) => {
                  //TODO state error
                });
              }
            }
          }).catch((e) => {
            //TODO state error
          });
        }
      } else {
        const putLTSStaffMemberPromise = putLTSStaffMember(orgUnitId, staffObj.personId, LTS_STAFF_ROLE_GROUP_ID, staffObj);

        if (putLTSStaffMemberPromise !== null) {
          putLTSStaffMemberPromise.then((newState) => {
            if (newState !== null) {
              navigate(NavLinks.LEARN_TO_SWIM_STAFF, { state: { refreshState: true } })
            }
          }).catch((e) => {
            //TODO state error
          });
        }
      }
    }
  };

  return {
    isSaving: ltsStaffMemberState.isObjLoading,
    isLoading: environmentVariableState.isLoading,
    existingPersonState,
    primaryContactModalState,
    displayPrimaryContactErrorModalState,
    onSubmitFormCallback,
    onCancelClicked,
    onPrimaryContactModalContinueClicked,
    onPrimaryContactModalCancelClicked,
    onPrimaryContactErrorModalCancelClicked,
    LTS_STAFF_ROLE_GROUP_ID
  };
};

export default useLearnToSwimStaffEdit;