import { Fragment } from "react";

import ClubBulkRenewFiltersForm from "./forms/ClubBulkRenewFiltersForm";

import ClubBulkRenewSelectionGrid from "./grids/ClubBulkRenewSelectionGrid";

import CurrentSeasonOfferingDropdown from "../../../components/dropdowns/currentSeasonOfferingDropdown/CurrentSeasonOfferingDropdown";

import PrimaryButton from "../../../../common/components/buttons/PrimaryButton";
import SecondaryButton from "../../../../common/components/buttons/SecondaryButton";
import PopUpModal from "../../../../common/components/dialogs/PopUpModal";
import Headings from "../../../../common/components/headings/Headings";

import Constants from "../../../../common/utils/Constants";

const ClubBulkRenewSelectionAthletes = ({ baseUsasClubState, competitionGenderOptions, formState, errorState, onValueTextPairChanged, onFormValueChanged, onFilterClicked,
    onClearFilterClicked, gridState, memberRegistrationRenewalSelectionState, onSelectAll, onUnselectAll, onCheckboxChange,
    onOpenMemberTypeSelectionPopUpClicked, memberTypeSelectionModalState, memberTypeSelectionModalFormState, setMemberTypeSelectionModalFormState,
    memberTypeSelectionModalErrorState, onMemberTypeSelectionContinueClicked, onMemberTypeSelectionModalCanceled, onModalCanceled,
    memberTypeValidationModalState, onMemberTypeValidationRenewAsPremiumClicked, onMemberTypeValidationDoNotRenewClicked, onMemberTypeValidationModalCanceled, onSaveClicked }) => {

    return (
        <Fragment>
            <ClubBulkRenewFiltersForm
                title={'Select Memberships'}
                competitionGenderOptions={competitionGenderOptions}
                formState={formState}
                errorState={errorState}
                onValueTextPairChanged={onValueTextPairChanged}
                onFormValueChanged={onFormValueChanged}
                onFilterClicked={onFilterClicked}
                onClearFilterClicked={onClearFilterClicked}
                isAthleteRole={true}
            />
            {gridState.gridData.length > 5 && memberRegistrationRenewalSelectionState.isArrayLoading === false &&
                < div className='visible-md visible-lg' >
                    <div className="row usas-extra-top-margin usas-extra-bottom-margin">
                        <div className="col-xs-12 usas-extra-top-margin">
                            <SecondaryButton type="button" onClick={onModalCanceled}>Exit Bulk Renew</SecondaryButton>
                        </div>
                    </div>
                </div >}
            {gridState.gridData.length > 0 && memberRegistrationRenewalSelectionState.isArrayLoading === false &&
                < div className='hidden-md hidden-lg' >
                    <div className="row usas-extra-top-margin usas-extra-bottom-margin">
                        <div className="col-xs-12 usas-extra-top-margin">
                            <SecondaryButton type="button" onClick={onModalCanceled}>Exit Bulk Renew</SecondaryButton>
                        </div>
                    </div>
                </div >}
            <div className="row usas-extra-top-margin">
                <div className="col-xs-12 usas-extra-top-margin">
                    <Headings.H4>Currently Bulk Renewing: Athlete Memberships</Headings.H4>
                </div>
            </div>
            <div className='row usas-extra-top-margin'>
                <div className="col-xs-12">
                    <ClubBulkRenewSelectionGrid
                        gridData={gridState.gridData}
                        isLoading={memberRegistrationRenewalSelectionState.isArrayLoading}
                        onCheckboxChange={onCheckboxChange}
                        selectionView={true}
                        onOpenMemberTypeSelectionPopUpClicked={onOpenMemberTypeSelectionPopUpClicked}
                        onSelectAll={onSelectAll}
                        onUnselectAll={onUnselectAll}
                        onSaveClicked={onSaveClicked}
                        memberRegistrationRenewalSelectionState={memberRegistrationRenewalSelectionState}
                        errorState={errorState}
                    />
                </div>
            </div>
            <div className="row usas-extra-top-margin usas-extra-bottom-margin">
                <div className="col-xs-12 usas-extra-top-margin">
                    <SecondaryButton type="button" onClick={onModalCanceled}>Exit Bulk Renew</SecondaryButton>
                </div>
            </div>
            <div className="row usas-extra-top-margin">
                <div className="col-xs-12 usas-extra-top-margin">
                    <Headings.H4>Currently Bulk Renewing: Athlete Memberships</Headings.H4>
                </div>
            </div>
            <PopUpModal
                widthPct={90}
                maxWidth={720}
                title={memberTypeSelectionModalState.modalTitle}
                displayPopUp={memberTypeSelectionModalState.displayPopUp}
                onModalCanceled={onMemberTypeSelectionModalCanceled}>
                <form>
                    <div className="row">
                        <div className="col-xs-12 usas-extra-bottom-margin">
                            <CurrentSeasonOfferingDropdown
                                label={"Renewal Membership*"}
                                name={"index"}
                                value={memberTypeSelectionModalFormState.index}
                                error={memberTypeSelectionModalErrorState.index}
                                message={memberTypeSelectionModalErrorState.index}
                                onChange={(newValue, newValueLabel, e) => {
                                    e && e.target && e.target.value && setMemberTypeSelectionModalFormState({ ...memberTypeSelectionModalFormState, index: newValue, renewalOfferingName: newValueLabel })
                                }}
                                orgUnitId={baseUsasClubState.objData?.orgUnitId}
                                isAthleteRole={true}
                                isActive={true}
                            />
                        </div>
                        <div className="col-xs-12 usas-extra-top-margin">
                            <PrimaryButton type="button" onClick={onMemberTypeSelectionContinueClicked}>Add to Renew with Selected Membership</PrimaryButton>&nbsp;
                            <SecondaryButton type="button" onClick={onMemberTypeSelectionModalCanceled}>Cancel</SecondaryButton>
                        </div>
                    </div>
                </form>
            </PopUpModal>
            <PopUpModal
                widthPct={90}
                maxWidth={720}
                title={memberTypeValidationModalState.modalTitle}
                displayPopUp={memberTypeValidationModalState.displayPopUp}
                onModalCanceled={onMemberTypeValidationModalCanceled}>
                <form>
                    <div className="row">
                        <div className="col-xs-12">
                            <b>At least one selected athlete is not eligible for the selected Flex Athlete renewal membership because they have aged out.</b>
                        </div>
                        <div className="col-xs-12 usas-extra-top-margin usas-extra-bottom-margin">
                            <span>Please choose between:</span>
                            <ul>
                                <li style={{ marginLeft: "15px" }}>Renewing eligible athletes as Flex Athletes & renewing all aged out athletes as Premium Athletes</li>
                                <li style={{ marginLeft: "15px" }}>Renewing eligible athletes as Flex Athletes & not renewing any aged out athletes</li>
                                <li style={{ marginLeft: "15px" }}>Canceling, in order to select a different renewal membership</li>
                            </ul>
                        </div>
                        <div className="col-xs-12 usas-extra-top-margin">
                            <PrimaryButton type="button" onClick={onMemberTypeValidationRenewAsPremiumClicked}>Renew Aged Out Athletes as Premium</PrimaryButton>&nbsp;
                            <PrimaryButton type="button" onClick={onMemberTypeValidationDoNotRenewClicked}>Do Not Renew Aged Out Athletes</PrimaryButton>&nbsp;
                            <SecondaryButton type="button" onClick={onMemberTypeValidationModalCanceled}>Cancel</SecondaryButton>
                        </div>
                    </div>
                </form>
            </PopUpModal>
            <PopUpModal
                widthPct={30}
                maxWidth={250}
                title={Constants.LOADING_MSG}
                displayPopUp={memberRegistrationRenewalSelectionState.isArrayLoading} />
        </Fragment>)
};

export default ClubBulkRenewSelectionAthletes;