import React, { createContext, useState } from 'react';

import OrgUnitBulkRenewalOptInData from './OrgUnitBulkRenewalOptInData';

export const OrgUnitBulkRenewalOptInStateContext = createContext();

const OrgUnitBulkRenewalOptInContextProvider = ({ children }) => {
  const stateHook = useState(OrgUnitBulkRenewalOptInData.INITIAL_STATE);

  return (
    <OrgUnitBulkRenewalOptInStateContext.Provider value={stateHook}>
      {children}
    </OrgUnitBulkRenewalOptInStateContext.Provider>
  );
};

export default OrgUnitBulkRenewalOptInContextProvider;