import useEnvironmentVariableData from '../../../../common/state/environmentVariable/UseEnvironmentVariableData';
import useBaseUsasClubData from '../../../../common/state/clubBase/UseBaseUsasClubData';

import NavLinks from '../NavLinks';

const TAXONOMIES = [''];
const SCOPE = 'ClubRenewal';

const useOrgUnitSelection = () => {
  const { CLUB_RENEWAL_SELECTOR_ROLE_GROUP_ID } = useEnvironmentVariableData();
  const { getBaseUsasClubByOrgUnitId } = useBaseUsasClubData();

  return {
    NavLinks,
    getBaseUsasClubByOrgUnitId,
    CLUB_RENEWAL_SELECTOR_ROLE_GROUP_ID,
    TAXONOMIES,
    SCOPE
  };
};

export default useOrgUnitSelection;