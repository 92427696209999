import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { Navigate } from '../../../common/wrappers/ReactRouterDom';

import CategoryMeasureScoreContextProvider from '../../state/categoryMeasureScore/CategoryMeasureScoreContextProvider';
import CategoryMeasureDropdownContextProvider from '../../state/categoryMeasureDropdown/CategoryMeasureDropdownContextProvider';

import SelectOrgUnitContextProvider from '../../../common/state/selectOrgUnit/SelectOrgUnitContextProvider';
import PersonOrgRoleContextProvider from '../../../common/state/personOrgRole/PersonOrgRoleContextProvider';
import RecognitionProgramContextProvider from '../../../common/state/recognitionProgram/RecognitionProgramContextProvider';
import ProgramFocusContextProvider from '../../../common/state/recognitionProgram/ProgramFocusContextProvider';

import ClubRecognitionContextView from './ClubRecognitionContextView';

import OrgUnitSelection from '../clubRecognition/orgUnitSelection/OrgUnitSelection';
import ClubRecognitionProgramLevel from './clubRecognitionProgramLevel/ClubRecognitionProgramLevel';
import ClubRecognitionProgramCategory from './clubRecognitionProgramCategory/ClubRecognitionProgramCategory';
import ClubRecognitionYesNo from './clubRecognitionCategoryMeasure/ClubRecognitionYesNo';
import ClubRecognitionFileUpload from './clubRecognitionCategoryMeasure/ClubRecognitionFileUpload';
import ClubRecognitionTextEntry from './clubRecognitionCategoryMeasure/ClubRecognitionTextEntry';
import ClubRecognitionDropdown from './clubRecognitionCategoryMeasure/ClubRecognitionDropdown';
import ClubRecognitionKpi from './clubRecognitionCategoryMeasure/ClubRecognitionKpi';
import ClubRecognitionElsewhereInSwims from './clubRecognitionCategoryMeasure/ClubRecognitionElsewhereInSwims';
import ClubRecognitionSearch from './clubRecognitionSearch/ClubRecognitionSearch';

import useNavRoutes from '../../../common/state/security/UseNavRoutes';
import LoadingModal from '../../../common/components/dialogs/LoadingModal';
import SecuredRouteElement from '../../../common/components/security/SecuredRouteElement';

const ContextRoutes = ({ navRoutes }) => {
  return (
    <ClubRecognitionContextView>
      <Routes>
        <Route path={navRoutes['CLUB_RECOGNITION_PROGRAM_LEVEL']?.path}
          element={
            <SecuredRouteElement
              navRoute={navRoutes['CLUB_RECOGNITION_PROGRAM_LEVEL']}
              editElement={<ClubRecognitionProgramLevel />} />
          } />
        <Route path={navRoutes['CLUB_RECOGNITION_PROGRAM_CATEGORY']?.path}
          element={
            <SecuredRouteElement
              navRoute={navRoutes['CLUB_RECOGNITION_PROGRAM_CATEGORY']}
              editElement={<ClubRecognitionProgramCategory />} />
          } />
        <Route path={navRoutes['CLUB_RECOGNITION_PROGRAM_CATEGORY_YES_NO']?.path}
          element={
            <SecuredRouteElement
              navRoute={navRoutes['CLUB_RECOGNITION_PROGRAM_CATEGORY_YES_NO']}
              editElement={<ClubRecognitionYesNo />} />
          } />
        <Route path={navRoutes['CLUB_RECOGNITION_PROGRAM_CATEGORY_FILE_UPLOA']?.path}
          element={
            <SecuredRouteElement
              navRoute={navRoutes['CLUB_RECOGNITION_PROGRAM_CATEGORY_FILE_UPLOA']}
              editElement={<ClubRecognitionFileUpload />} />
          } />
        <Route path={navRoutes['CLUB_RECOGNITION_PROGRAM_CATEGORY_TEXT_ENTRY']?.path}
          element={
            <SecuredRouteElement
              navRoute={navRoutes['CLUB_RECOGNITION_PROGRAM_CATEGORY_TEXT_ENTRY']}
              editElement={<ClubRecognitionTextEntry />} />
          } />
        <Route path={navRoutes['CLUB_RECOGNITION_PROGRAM_CATEGORY_DROPDOWN']?.path}
          element={
            <SecuredRouteElement
              navRoute={navRoutes['CLUB_RECOGNITION_PROGRAM_CATEGORY_DROPDOWN']}
              editElement={<ClubRecognitionDropdown />} />
          } />
        <Route path={navRoutes['CLUB_RECOGNITION_PROGRAM_CATEGORY_KPI_ADMIN']?.path}
          element={
            <SecuredRouteElement
              navRoute={navRoutes['CLUB_RECOGNITION_PROGRAM_CATEGORY_KPI_ADMIN']}
              editElement={<ClubRecognitionKpi canOverrideScore={true} />} />
          } />
        <Route path={navRoutes['CLUB_RECOGNITION_PROGRAM_CATEGORY_KPI']?.path}
          element={
            <SecuredRouteElement
              navRoute={navRoutes['CLUB_RECOGNITION_PROGRAM_CATEGORY_KPI']}
              editElement={<ClubRecognitionKpi canOverrideScore={false} />} />
          } />
        <Route path={navRoutes['CLUB_RECOGNITION_PROGRAM_CATEGORY_ELSEWHERE']?.path}
          element={
            <SecuredRouteElement
              navRoute={navRoutes['CLUB_RECOGNITION_PROGRAM_CATEGORY_ELSEWHERE']}
              editElement={<ClubRecognitionElsewhereInSwims />} />
          } />
        <Route path={'/'} element={<Navigate to={navRoutes['CLUB_RECOGNITION_PROGRAM_LEVEL']?.path} replace />} />
      </Routes>
    </ClubRecognitionContextView>
  );
};

const ClubRecognitionRoutes = () => {
  const { navRoutes, isReadyToRoute } = useNavRoutes();

  if (isReadyToRoute !== true) {
    return <LoadingModal />;
  }

  return (
    <Routes>
      <Route path={navRoutes['CLUB_RECOGNITION_SELECTION']?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes['CLUB_RECOGNITION_SELECTION']}
            editElement={<OrgUnitSelection />} />
        } />
      <Route path={navRoutes['CLUB_RECOGNITION_SEARCH']?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes['CLUB_RECOGNITION_SEARCH']}
            editElement={<ClubRecognitionSearch />} />
        } />
      <Route path={'/*'} element={<ContextRoutes navRoutes={navRoutes} />} />
      <Route path={'/'} element={<Navigate to={'selection'} replace />} />
    </Routes>
  );
};

const ClubRecognition = () => (
  <SelectOrgUnitContextProvider>
    <PersonOrgRoleContextProvider>
      <CategoryMeasureDropdownContextProvider>
        <RecognitionProgramContextProvider>
          <ProgramFocusContextProvider>
            <CategoryMeasureScoreContextProvider>
              <ClubRecognitionRoutes />
            </CategoryMeasureScoreContextProvider>
          </ProgramFocusContextProvider>
        </RecognitionProgramContextProvider>
      </CategoryMeasureDropdownContextProvider>
    </PersonOrgRoleContextProvider>
  </SelectOrgUnitContextProvider>
);

export default ClubRecognition;