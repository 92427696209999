import { useContext } from 'react';

import { PreCompOrganizationContactsStateContext } from './PreCompOrganizationContactsContextProvider';

import PreCompOrganizationContactsData from './PreCompOrganizationContactsData';

const usePreCompOrganizationContactsData = () => {
  const [preCompOrganizationContactsState, setPreCompOrganizationContactsState] = useContext(PreCompOrganizationContactsStateContext);

  const getPreCompOrganizationContacts = (orgUnitId, roleGroupId, isCurrentOnly) => { PreCompOrganizationContactsData.getPreCompOrganizationContactsData(orgUnitId, roleGroupId, isCurrentOnly, preCompOrganizationContactsState, setPreCompOrganizationContactsState); };
  const postPreCompOrganizationContacts = (orgUnitId, roleGroupId, staffObj) => { PreCompOrganizationContactsData.postPreCompOrganizationContactsData(orgUnitId, roleGroupId, staffObj, preCompOrganizationContactsState, setPreCompOrganizationContactsState); };
  const putPreCompOrganizationContacts = (orgUnitId, personId, roleGroupId, staffObj) => { PreCompOrganizationContactsData.putPreCompOrganizationContactsData(orgUnitId, personId, roleGroupId, staffObj, preCompOrganizationContactsState, setPreCompOrganizationContactsState); };
  const deletePreCompOrganizationContacts = (personOrgRoleDurationId) => { PreCompOrganizationContactsData.deletePreCompOrganizationContactsData(personOrgRoleDurationId, preCompOrganizationContactsState, setPreCompOrganizationContactsState); };

  const savePreCompOrganizationContacts = (orgUnitId, personId, roleGroupId, personOrgRole) => {
    if (personId && orgUnitId && roleGroupId > 0) {
      let matchFound = false;
      // Edit
      for (const staff of preCompOrganizationContactsState.arrayData) {
        if (staff.personId === personId) {
          putPreCompOrganizationContacts(orgUnitId, personId, roleGroupId, { personId: personId, personOrgRole: personOrgRole });

          matchFound = true;
          break;
        }
      }

      // Add
      if (matchFound === false) {
        postPreCompOrganizationContacts(orgUnitId, roleGroupId, { personId: personId, personOrgRole: personOrgRole });
      }
    }
  };

  const resetPreCompOrganizationContactsState = () => {
    setPreCompOrganizationContactsState(PreCompOrganizationContactsData.INITIAL_STATE);
  };

  const confirmPreCompOrganizationContactsStateSaved = () => {
    setPreCompOrganizationContactsState({
      ...preCompOrganizationContactsState,
      isSaved: false
    });
  };

  return {
    preCompOrganizationContactsState,
    getPreCompOrganizationContacts,
    postPreCompOrganizationContacts,
    putPreCompOrganizationContacts,
    deletePreCompOrganizationContacts,
    savePreCompOrganizationContacts,
    resetPreCompOrganizationContactsState,
    confirmPreCompOrganizationContactsStateSaved
  };
};

export default usePreCompOrganizationContactsData;