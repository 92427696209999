import React, { Fragment } from 'react';

import SGColumnHeader from '../SGColumnHeader';

import HideIcon from '../../icons/HideIcon';
import ShowIcon from '../../icons/ShowIcon';

import SGConstants from '../../../utils/SGConstants';
import useSortableGrid from '../../../utils/UseSortableGrid';

import global from '../../GlobalStyle.module.css';

const DetailTableRow = ({ role }) => (
  <tr>
    <td>{role.orgRoleName || ''}</td>
  </tr>
);

const DetailTable = ({ staff }) => (
  <table className={global.DetailTable}>
    <thead>
      <tr>
        <th>Role</th>
      </tr>
    </thead>
    <tbody>
      {staff && staff.personOrgRole && staff.personOrgRole.map((role, i) =>
        <DetailTableRow role={role} key={i} />)}
    </tbody>
  </table>
);

const TableRow = ({ staff, expandedId, onClick }) => (
  <Fragment>
    <tr>
      <td>{staff.firstName || ''} {staff.lastName || ''}</td>
      <td>{staff.memberId || ''}</td>
      <td>{staff.isInGoodStanding === true ? 'Yes' : 'No'}</td>
      <td>
        <button
          className={global.IconButton}
          type="button"
          onClick={(e) => onClick(e, staff.personId)}>
          {expandedId === staff.personId ? <HideIcon /> : <ShowIcon />}
        </button>
      </td>
    </tr>
    {staff && staff.personOrgRole && staff.personOrgRole.length > 0 && expandedId === staff.personId &&
      <tr className={global.Expanded}>
        <td colSpan="4">
          <DetailTable staff={staff} />
        </td>
      </tr>
    }
  </Fragment>
);

const ClubStaffReportGridLarge = ({ state, expandedId, onClick }) => {
  const {
    sortableGridState,
    onColumnClicked
  } = useSortableGrid(state.arrayData);

  return (
    <table className={[global.UsasTable, 'visible-md visible-lg'].join(' ')}>
      <thead>
        <tr>
          <SGColumnHeader sortableGridState={sortableGridState} columnLabel={'Club Staff Name'} columnField={'lastName'}
            sortType={SGConstants.SORT_TYPE_ALPHABETICAL} onColumnClicked={onColumnClicked} />
          <SGColumnHeader sortableGridState={sortableGridState} columnLabel={'Member Id'} columnField={'memberId'}
            sortType={SGConstants.SORT_TYPE_ALPHABETICAL} onColumnClicked={onColumnClicked} />
          <SGColumnHeader sortableGridState={sortableGridState} columnLabel={'Is in good standing?'} columnField={'isInGoodStanding'}
            sortType={SGConstants.SORT_TYPE_NUMERICAL} onColumnClicked={onColumnClicked} />
          <th></th>
        </tr>
      </thead>
      <tbody>
        {state.isArrayLoaded !== true
          ? (
            <tr>
              {state.isArrayLoading === true
                ? <td colSpan="4">Loading...</td>
                : <td colSpan="4">No Staff</td>
              }
            </tr>)
          : Array.isArray(sortableGridState.sortedGridData) && sortableGridState.sortedGridData.length > 0
            ? sortableGridState.sortedGridData.map((staff, i) => <TableRow key={i} staff={staff} expandedId={expandedId} onClick={onClick} />)
            : (
              <tr>
                <td colSpan="4">No Staff</td>
              </tr>
            )}
      </tbody>
    </table>
  );
};

export default ClubStaffReportGridLarge;