import React, { Fragment } from 'react';

import ProgramLevelGridLarge from './ProgramLevelGridLarge';
import ProgramLevelGridSmall from './ProgramLevelGridSmall';

import useExpandedRow from '../UseExpandedRow';

const ProgramLevelGrid = ({ data, isLoading, onSubmit, onEdit, showStatus }) => {
  const { onClick, expandedId } = useExpandedRow();

  return (
    <Fragment>
      <ProgramLevelGridLarge
        data={data}
        isLoading={isLoading}
        onSubmit={onSubmit}
        onEdit={onEdit}
        showStatus={showStatus}
        expandedId={expandedId}
        onClick={onClick} />
      <ProgramLevelGridSmall
        data={data}
        isLoading={isLoading}
        onSubmit={onSubmit}
        onEdit={onEdit}
        showStatus={showStatus}
        expandedId={expandedId}
        onClick={onClick} />
    </Fragment>
  );
};

export default ProgramLevelGrid;