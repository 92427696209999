/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';

import EditIcon from '../../../../../common/components/icons/EditIcon';
import DeleteIcon from '../../../../../common/components/icons/DeleteIcon';

import global from '../../../../../common/components/GlobalStyle.module.css';

const GridRow = ({ offering, onEdit, onDelete }) => (
  <div className={global.SmallTableRow}>
    <div className={global.SmallTableRowHead}><span className='hidden-xs'>{offering.preCompOfferingType?.typeName || <span>&nbsp;</span>}</span>&nbsp;
      <div className={global.FloatRight}>
        {onEdit !== undefined &&
          <button
            className={global.IconButtonMargin}
            type="button"
            onClick={() => onEdit(offering.preCompLocationOfferingId)}>
            <EditIcon />
          </button>
        }
        {onDelete !== undefined &&
          <button
            className={global.IconButtonMargin}
            type="button"
            onClick={() => onDelete(offering.preCompLocationOfferingId)}>
            <DeleteIcon />
          </button>
        }
      </div>
    </div>
    <div className={global.SmallTableRowInfo}>
      <div className='row'>
        <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3 visible-xs'].join(' ')}>Offering Type</div>
        <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3 visible-xs'].join(' ')}>{offering.preCompOfferingType?.typeName || <span>&nbsp;</span>}</div>
        <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3'].join(' ')}>Avg. Number of Participants</div>
        <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3'].join(' ')}>{offering.enrollmentCount ?? <span>&nbsp;</span>}</div>
        <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3'].join(' ')}>Avg. Number of Scholarships</div>
        <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3'].join(' ')}>{offering.scholarshipCount ?? <span>&nbsp;</span>}</div>
      </div>
    </div>
  </div>
);

const PreCompLocationProgramsGridSmall = ({ data, isLoading, onEdit, onDelete }) => (
  <div className={[global.SmallTable, 'hidden-md hidden-lg'].join(' ')}>
    {isLoading === true
      ? (
        <div className={global.SmallTableRow}>
          <div className={global.SmallTableRowHead}>&nbsp;</div>
          <div className={global.SmallTableRowInfo}>
            <div className={global.SmallTableRowLabels}>Loading...</div>
          </div>
        </div>
      )
      : data.length > 0
        ? data.map((offering, i) => <GridRow key={i} offering={offering} onEdit={onEdit} onDelete={onDelete} />)
        : (
          <div className={global.SmallTableRow}>
            <div className={global.SmallTableRowHead}>&nbsp;</div>
            <div className={global.SmallTableRowInfo}>
              <div className={global.SmallTableRowLabels}>No Offerings</div>
            </div>
          </div>
        )}
  </div>
);

export default PreCompLocationProgramsGridSmall;