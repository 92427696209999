import { useEffect, useState } from 'react';

import { createCategoryMeasureScoreObj } from '../../utils/RecognitionProgramUtils';

import useCategoryMeasureScoreData from '../../../state/categoryMeasureScore/UseCategoryMeasureScoreData';
import useCategoryMeasureMultiselectData from '../../../state/categoryMeasureMultiselect/UseCategoryMeasureMultiselectData';

import useForm from "../../../../common/utils/UseForm";

const useMultiselectView = (programLevel, categoryMeasure, isReadonly, onRedirect) => {
  const { categoryMeasureScoreState, postCategoryMeasureScore, putCategoryMeasureScore
  } = useCategoryMeasureScoreData();
  const { categoryMeasureMultiselectState, getCategoryMeasureMultiselect } = useCategoryMeasureMultiselectData();
  const { handleSubmit, updateFormState, formState, errorState, setFormData, isDirty
  } = useForm(getInitialFormState(), submitFormCallback, () => { });
  const [state, setState] = useState({ tryRedirect: false });

  const onSaveClicked = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    if (isDirty === false) {
      onRedirect(false);
    } else {
      handleSubmit();
    }
  };

  const getMultiValueString = (multiSelectValues) => {
    let valueString = '';

    if (Array.isArray(multiSelectValues)) {
      for (let i = 0; i < multiSelectValues.length; i++) {
        if (valueString === '') {
          valueString = `${multiSelectValues[i].name}`;
        } else if (i === multiSelectValues.length - 1) {
          valueString += `, & ${multiSelectValues[i].name}`;
        } else {
          valueString += `, ${multiSelectValues[i].name}`;
        }
      }
    }

    return valueString;
  };

  useEffect(() => {
    if (isReadonly === true) {
      const categoryMeasureId = categoryMeasure.categoryMeasureId;

      if (categoryMeasureMultiselectState.isArrayLoading !== true && categoryMeasureId > 0 && categoryMeasureId !== categoryMeasureMultiselectState.categoryMeasureId) {
        getCategoryMeasureMultiselect(categoryMeasureId);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categoryMeasureMultiselectState, categoryMeasure.categoryMeasureId, isReadonly]);

  useEffect(() => {
    if (Object.keys(categoryMeasure).length > 0 &&
      categoryMeasureMultiselectState.categoryMeasureId === categoryMeasure?.categoryMeasureId) {

      setFormData({
        ...formState,
        multiSelectValues: getMultiSelectValues(categoryMeasureMultiselectState.options, categoryMeasure.multiSelectSubmittedValue || [])
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categoryMeasureMultiselectState, categoryMeasure]);

  useEffect(() => {
    if (state.tryRedirect === true && categoryMeasureScoreState.isSaved === true) {
      onRedirect(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state, categoryMeasureScoreState]);

  function submitFormCallback() {
    if (categoryMeasure.categoryMeasureScoreId > 0) {
      putCategoryMeasureScore(categoryMeasure.categoryMeasureScoreId,
        createCategoryMeasureScoreObj(categoryMeasure, programLevel, 'Submitted', getScore(categoryMeasureMultiselectState.arrayData, formState.multiSelectValues), false, formState.multiSelectValues));
    } else {
      postCategoryMeasureScore(createCategoryMeasureScoreObj(categoryMeasure, programLevel, 'Submitted', getScore(categoryMeasureMultiselectState.arrayData, formState.multiSelectValues), false, formState.multiSelectValues));
    }
    setState({ ...state, tryRedirect: true });
  };

  function getMultiSelectValues(multiSelectOptions, multiSelectSubmittedValue) {
    const multiSelectValues = [];

    for (const scoreDetail of multiSelectSubmittedValue) {
      const multiSelectValue = multiSelectOptions.find(x => x.name === scoreDetail.submittedValue);

      if (multiSelectValue) {
        multiSelectValues.push(multiSelectValue);
      }
    }

    return multiSelectValues;
  };

  function getScore(multiSelectArrayData, multiSelectValues) {
    let totalScore = 0;

    for (const multiSelectValue of multiSelectValues) {
      const score = multiSelectArrayData.find(x => x.dropdownValue === multiSelectValue.name)?.score;

      if (score) {
        totalScore += score;
      }
    }

    return totalScore;
  };

  function getInitialFormState() {
    return {
      multiSelectValues: [],
    };
  };

  return {
    categoryMeasureScoreState,
    categoryMeasureMultiselectState,
    formState,
    errorState,
    handleSubmit,
    onFormValueChanged: updateFormState,
    onSaveClicked,
    getMultiValueString
  };
};

export default useMultiselectView;