import { useContext } from 'react';

import SearchClubData from './SearchClubData';

import { SearchClubStateContext } from './SearchClubContextProvider';

const useSearchClubData = () => {
  const [searchClubState, setSearchClubState] = useContext(SearchClubStateContext);

  const resetSearchClubState = () => {
    setSearchClubState(SearchClubData.INITIAL_STATE);
  }

  return {
    searchClubState,
    setSearchClubState,
    resetSearchClubState
  };
};

export default useSearchClubData;