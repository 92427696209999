import React, { createContext, useState } from 'react';

import ClubRegistrationData from './ClubRegistrationData';

export const ClubRegistrationStateContext = createContext();

const ClubRegistrationContextProvider = ({ children }) => {
  const stateHook = useState(ClubRegistrationData.INITIAL_STATE);

  return (
    <ClubRegistrationStateContext.Provider value={stateHook}>
        {children}
    </ClubRegistrationStateContext.Provider>
  );
};

export default ClubRegistrationContextProvider;