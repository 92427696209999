import React, { Fragment } from 'react';

import CategoryMeasureGridLarge from './CategoryMeasureGridLarge';
import CategoryMeasureGridSmall from './CategoryMeasureGridSmall';

const CategoryMeasureGrid = ({ programLevel, programCategory, isLoading, onEdit }) => (
  <Fragment>
    <CategoryMeasureGridLarge
      programLevel={programLevel}
      programCategory={programCategory}
      isLoading={isLoading}
      onEdit={onEdit} />
    <CategoryMeasureGridSmall
      programLevel={programLevel}
      programCategory={programCategory}
      isLoading={isLoading}
      onEdit={onEdit} />
  </Fragment>
);

export default CategoryMeasureGrid;