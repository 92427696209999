import React, { Fragment } from 'react';

import useAddLSCRoleEmail from './UseAddLSCRoleEmail';

import Headings from '../../../../../common/components/headings/Headings';
import PrimaryButton from '../../../../../common/components/buttons/PrimaryButton';
import SecondaryButton from '../../../../../common/components/buttons/SecondaryButton';
import PopUpModal from '../../../../../common/components/dialogs/PopUpModal';
import Input from '../../../../../common/components/inputs/Input';

import Constants from '../../../../../common/utils/Constants';
import OrgRoleStaffRoleTreeView from '../../../../../common/components/tree/orgRoleTree/OrgRoleStaffRoleTreeView';

const AddLSCRoleEmail = () => {
  const {
    isLoading,
    isSaving,
    formState,
    errorState,
    modalState,
    onFormValueChanged,
    handleSubmit,
    onCancelClicked,
    onModalConfirm,
    onModalCancel,
    LSC_EMAIL_ROLE_GROUP_ID
  } = useAddLSCRoleEmail();

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>Add Role Email</Headings.H3>
        </div>
      </div>
      <form onSubmit={handleSubmit} noValidate>
        <div className="row">
          <div className="col-xs-12 col-sm-6 col-md-4">
            {
              LSC_EMAIL_ROLE_GROUP_ID && (
                <OrgRoleStaffRoleTreeView
                  label={'LSC Role*'}
                  name={'orgRole'}
                  value={formState.orgRole}
                  error={errorState.orgRole}
                  message={errorState.orgRole}
                  singleSelect={true}
                  onChange={(value) => onFormValueChanged('orgRole', value)}
                  roleGroupId={LSC_EMAIL_ROLE_GROUP_ID} />
              )
            }
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4">
            <Input
              label={"Email*"}
              name={'email'}
              value={formState.email}
              error={errorState.email}
              message={errorState.email}
              onChange={(value) => onFormValueChanged('email', value)} />
          </div>
        </div>
        <div className="row usas-extra-top-margin">
          <div className="col-xs-12 usas-extra-top-margin usas-extra-bottom-margin">
            <PrimaryButton type="submit">Save</PrimaryButton>&nbsp;
            <SecondaryButton type="button" onClick={onCancelClicked}>Back</SecondaryButton>
          </div>
        </div>
      </form>
      <PopUpModal
        widthPct={90}
        maxWidth={720}
        title={'Update Role Email?'}
        displayPopUp={modalState.displayModal}
        onModalCanceled={onModalCancel}>
        <div className="row">
          <div className="col-xs-12">
            <p>The {modalState.orgRoleName} role already has an email</p>
            <p><b>Current Email:</b> {modalState.currentEmail}</p>
            <p><b>New Email:</b> {modalState.newEmail}</p>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12 usas-extra-top-margin">
            <PrimaryButton type="button" onClick={onModalConfirm}>Update Email</PrimaryButton>&nbsp;
            <SecondaryButton type="button" onClick={onModalCancel}>Cancel</SecondaryButton>
          </div>
        </div>
      </PopUpModal>
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={isLoading} />
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.SAVING_MSG}
        displayPopUp={isSaving} />
    </Fragment>
  );
};

export default AddLSCRoleEmail;