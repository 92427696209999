import React, { Fragment } from 'react';

import SATContractsGridLarge from './SATContractsGridLarge';
import SATContractsGridSmall from './SATContractsGridSmall';

const SATContractsGrid = ({ data, isLoading, onEdit = undefined }) => (
  <Fragment>
    <SATContractsGridLarge data={data} isLoading={isLoading} onEdit={onEdit} />
    <SATContractsGridSmall data={data} isLoading={isLoading} onEdit={onEdit} />
  </Fragment>
);

export default SATContractsGrid;