import React, { useState, useEffect } from 'react';

import useBaseUsasClubData from '../../../../common/state/clubBase/UseBaseUsasClubData';
import Constants from '../../../../common/utils/Constants';

import useClubNationalPracticeDataData from '../../../state/clubNationalPracticeData/UseClubNationalPracticeDataData';
import PrimaryButton from '../../../../common/components/buttons/PrimaryButton';
import useEmbeddedReportData from '../../../../common/state/embeddedReport/UseEmbeddedReportData';

const INITIAL_VIEW_STATE = {
  reportParameters: { meetId: '' },
  orgUnitId: '',
  routeName: '',
  isLoading: false
};

const useClubNationalPracticeData = () => {
  const { baseUsasClubState } = useBaseUsasClubData();
  const { clubNationalPracticeDataState, postNationalPracticeData } = useClubNationalPracticeDataData();
  const { reportListState, getEmbeddedReportListForRoute } = useEmbeddedReportData();

  const [jsonFileState, setJsonFileState] = useState(getInitialJSONFileState);
  const [viewState, setViewState] = useState(INITIAL_VIEW_STATE);

  const FileUpload = () => {
    const hiddenFileInput = React.useRef(null);
    let fileReader;
    let fileType;

    const handleSelectFileClicked = (e) => {
      if (e && e.preventDefault) {
        e.preventDefault();
      }

      hiddenFileInput.current.click();
    };

    const handleFileRead = (event) => {
      const fileContent = fileReader.result;
      processFile(fileContent, fileType);
    };

    const handleFileChosen = (file) => {
      fileType = file.name.substring(file.name.length - 3);

      fileReader = new FileReader();
      fileReader.onloadend = handleFileRead;
      fileReader.readAsText(file);
    };

    return (
      <div className="row">
        <div className="col-xs-12 usas-extra-top-margin">
          <PrimaryButton onClick={handleSelectFileClicked}>Select a File (*.csv)</PrimaryButton>
        </div>
        <input
          type='file'
          id='file'
          ref={hiddenFileInput}
          style={{ display: 'none' }}
          onChange={e => handleFileChosen(e.target.files[0])} />
      </div>
    );
  };

  const onUploadFile = () => {
    if (clubNationalPracticeDataState.isSaving === false) {
      postNationalPracticeData(jsonFileState.practiceDataContent);
    }
  }

  const onRunNPDReport = () => {
    ;
    setViewState({
      ...viewState,
      reportParameters: { orgUnitId: baseUsasClubState.objData.orgUnitId },
      routeName: 'CLUB_NATIONAL_PRACTICE_DATA',
      isLoading: true,
      reportViewerType: 'Sisense'
    });
  };

  const onRunClubRosterReport = () => {
    setViewState({
      ...viewState,
      reportParameters: { orgUnitId: baseUsasClubState.objData.orgUnitId },
      routeName: 'CLUB_NATIONAL_PRACTICE_DATA',
      isLoading: true,
      reportViewerType: 'ActiveReports'
    });
  };

  const processFile = (fileContent, fileType) => {
    const isValidFileType = fileType.toUpperCase() === "CSV";
    const fileArray = fileContent.toString().replace(/\r\n/g, '\n').split('\n');
    let arrayIdx = 0;
    let practiceDataArray = [];

    if (isValidFileType === true) {

      for (const fileLine of fileArray) {
        if (fileLine.trim().length === 0) {
          continue;
        }

        if (arrayIdx > 0) {
          const lineArray = fileLine.split(',');

          //skip empty lines and missing data
          if (
            lineArray[0].length === 14 && //memberId
            lineArray[1].length > 0 && //name
            lineArray[2].length > 0 && //repeat1
            lineArray[3].length > 0 && //repeat2
            lineArray[4].length > 0 && //repeat3
            lineArray[5].length > 0 && //repeat4
            lineArray[6].length > 0 && //repeat5
            lineArray[7].length > 0 && //repeat6
            lineArray[14].length > 0 && //strokeCode
            lineArray[15].length > 0 && //interval
            lineArray[16].length > 0 && //wave
            lineArray[17].length > 0) { //set
            let practiceDataObj = {
              memberId: lineArray[0],
              name: lineArray[1],
              repeat1Time: lineArray[2],
              repeat2Time: lineArray[3],
              repeat3Time: lineArray[4],
              repeat4Time: lineArray[5],
              repeat5Time: lineArray[6],
              repeat6Time: lineArray[7],
              repeat7Time: lineArray[8],
              repeat8Time: lineArray[9],
              repeat9Time: lineArray[10],
              repeat10Time: lineArray[11],
              repeat11Time: lineArray[12],
              repeat12Time: lineArray[13],
              strokeCode: lineArray[14],
              interval: lineArray[15],
              wave: lineArray[16],
              set: lineArray[17]
            }

            practiceDataArray.push(practiceDataObj);
          } else {
            console.log('skipped', fileLine);
          }
        }
        arrayIdx++;
      }

      let clubPracticeDataObj = {
        orgUnitId: baseUsasClubState.objData.orgUnitId || Constants.DEFAULT_ID,
        nationalPracticeData: practiceDataArray
      }

      setJsonFileState({
        ...jsonFileState,
        practiceDataContent: clubPracticeDataObj,
        isValidFileType: isValidFileType
      });
    } else {
      setJsonFileState({
        ...jsonFileState,
        practiceDataContent: '',
        isValidFileType: isValidFileType
      });
    }

  };

  useEffect(() => {
    if (viewState.routeName) {
      const reportListPromise = getEmbeddedReportListForRoute(viewState.routeName);

      if (reportListPromise) {
        reportListPromise.then((stateObj) => {
          if (Array.isArray(stateObj.arrayData)) {

            const reportIdx = stateObj.arrayData.findIndex(x => x.reportViewerType === viewState.reportViewerType);

            setViewState({
              ...viewState,
              isLoading: false,
              showReport: stateObj.arrayData.length > 0,
              reportInfoId: stateObj.arrayData.length === 2 ? stateObj.arrayData[reportIdx].reportInfoId : -1,
            });
          } else {
            setViewState({
              ...viewState,
              isLoading: false,
              showReport: false,
              reportInfoId: -1
            });
          }
        }).catch((err) => {
          setViewState({
            ...viewState,
            isLoading: false,
            showReport: false,
            reportInfoId: -1
          });
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewState.routeName, viewState.reportViewerType]);

  function getInitialJSONFileState() {
    return {
      practiceDataContent: '',
      isValidFileType: true
    };
  };

  return {
    baseUsasClubState,
    jsonFileState,
    clubNationalPracticeDataState,
    viewState,
    setViewState,
    reportListState,
    INITIAL_VIEW_STATE,
    processFile,
    onUploadFile,
    FileUpload,
    onRunNPDReport,
    onRunClubRosterReport
  }
}

export default useClubNationalPracticeData;