import React, { Fragment } from 'react';

import usePreCompLocationFacilitiesLinkSearch from './UsePreCompLocationFacilitiesLinkSearch';

import SearchFacility from '../../../../common/components/searches/searchFacility/SearchFacility';
import PopUpModal from '../../../../common/components/dialogs/PopUpModal';
import Constants from '../../../../common/utils/Constants';

const PreCompLocationFacilitiesLinkSearch = () => {
  const {
    isSaving,
    isLoading,
    modalState,
    onLinkFacility,
    onCancelClicked,
    onModalCancel,
    onAddANewFacility
  } = usePreCompLocationFacilitiesLinkSearch();

  return (
    <Fragment>
      <SearchFacility
        pageTitle={"Facility Search"}
        onEdit={onLinkFacility}
        onCancelClicked={onCancelClicked}
        onAddANewFacility={onAddANewFacility}
        contactLabel={'Pre-Comp'} />
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.SAVING_MSG}
        displayPopUp={isSaving} />
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={isLoading} />
      <PopUpModal
        widthPct={90}
        maxWidth={720}
        title="Duplicate Facility Error"
        displayPopUp={modalState.displayModal}
        onModalCanceled={onModalCancel}>
        <div className="row">
          <div className="col-xs-12">
            <p>{modalState.facilityName} is already linked to this location</p>
          </div>
        </div>
      </PopUpModal>
    </Fragment>
  );
};

export default PreCompLocationFacilitiesLinkSearch;