import React, { Fragment } from 'react';

import ClubThirdPartyInvoicesGridLarge from './ClubThirdPartyInvoicesGridLarge';
import ClubThirdPartyInvoicesGridSmall from './ClubThirdPartyInvoicesGridSmall';

import global from '../../../../common/components/GlobalStyle.module.css';

const ClubThirdPartyInvoicesGrid = ({ gridData, isLoading, onEditClicked }) => {
  return (
    <Fragment>
      {isLoading === false
        ? (
          <div className="row">
            <div className="col-xs-12">
              {gridData.length > 499
                ? <p className={global.ErrorMessage}>More than 500 Invoices returned. Please add additional filters to narrow your results.</p>
                : <p>{gridData.length === 1 ? `${gridData.length} Invoice` : `${gridData.length} Invoices`}</p>
              }
            </div>
          </div>
        ) : <Fragment><div className="row usas-extra-top-margin usas-extra-bottom-margin"></div></Fragment>
      }
      <ClubThirdPartyInvoicesGridLarge
        gridData={gridData}
        isLoading={isLoading}
        onEditClicked={onEditClicked}
      />
      <ClubThirdPartyInvoicesGridSmall
        gridData={gridData}
        isLoading={isLoading}
        onEditClicked={onEditClicked}
      />
    </Fragment>
  );
}

export default ClubThirdPartyInvoicesGrid;