import React from 'react';

import usePreCompRegistrationLocationFacilitiesLinkSearch from './UsePreCompRegistrationLocationFacilitiesLinkSearch';

import PreCompRegistrationConstants from '../utils/PreCompRegistrationConstants';

import SearchFacility from '../../../../common/components/searches/searchFacility/SearchFacility';

import LeftNav from '../../../../common/components/leftNav/LeftNav';
import PopUpModal from '../../../../common/components/dialogs/PopUpModal';

const PreCompRegistrationLocationFacilitiesLinkSearch = () => {
  const {
    locationRegistrationNavState,
    modalState,
    onLinkFacility,
    onCancelClicked,
    onModalCancel,
    onAddANewFacility
  } = usePreCompRegistrationLocationFacilitiesLinkSearch();

  return (
    <LeftNav title={PreCompRegistrationConstants.LEFT_NAV_TITLE} state={locationRegistrationNavState} validateBeforeRedirect={false}>
      <SearchFacility
        pageTitle={"Facility Search"}
        onEdit={onLinkFacility}
        onCancelClicked={onCancelClicked}
        onAddANewFacility={onAddANewFacility}
        contactLabel={'Pre-Comp'} />
      <PopUpModal
        widthPct={90}
        maxWidth={720}
        title="Duplicate Facility Error"
        displayPopUp={modalState.displayModal}
        onModalCanceled={onModalCancel}>
        <div className="row">
          <div className="col-xs-12">
            <p>{modalState.facilityName} is already linked to this location</p>
          </div>
        </div>
      </PopUpModal>
    </LeftNav>
  );
};

export default PreCompRegistrationLocationFacilitiesLinkSearch;