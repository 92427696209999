import React from 'react';

import useClubRegistrationFacilitySearch from './UseClubRegistrationFacilitySearch';

import ClubRegistrationConstants from '../utils/ClubRegistrationConstants';

import LeftNav from '../../../../common/components/leftNav/LeftNav';
import SearchFacility from '../../../../common/components/searches/searchFacility/SearchFacility';
import PopUpModal from '../../../../common/components/dialogs/PopUpModal';
import Constants from '../../../../common/utils/Constants';

const ClubRegistrationFacilitySearch = () => {
  const { usasClubFacilityLinksState, clubRegistrationState, clubRegistrationNavState, facilityState, modalState, onLinkFacility, onCancelClicked,
    onModalCancel, onAddANewFacility } = useClubRegistrationFacilitySearch();

  return (
    <LeftNav title={ClubRegistrationConstants.LEFT_NAV_TITLE} state={clubRegistrationNavState} validateBeforeRedirect={false}>
      <SearchFacility
        pageTitle={"Facility Search"}
        onEdit={onLinkFacility}
        onCancelClicked={onCancelClicked}
        onAddANewFacility={onAddANewFacility}
        contactLabel={'Club'}
      />
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.SAVING_MSG}
        displayPopUp={clubRegistrationState.isSaving || usasClubFacilityLinksState.isSaving || facilityState.isSaving} />
      <PopUpModal
        widthPct={90}
        maxWidth={720}
        title="Duplicate Facility Error"
        displayPopUp={modalState.displayModal}
        onModalCanceled={onModalCancel}>
        <div className="row">
          <div className="col-xs-12">
            <p>{modalState.facilityName} is already linked to this club</p>
          </div>
        </div>
      </PopUpModal>
    </LeftNav>
  )
}

export default ClubRegistrationFacilitySearch;