import { useContext } from 'react';

import { LscOfferingByOrgUnitIdAndReportPeriodIdDropdownStateContext } from './LscOfferingByOrgUnitIdAndReportPeriodIdDropdownContextProvider';

import LscOfferingByOrgUnitIdAndReportPeriodIdDropdownData from './LscOfferingByOrgUnitIdAndReportPeriodIdDropdownData';

const useLscOfferingByOrgUnitIdAndReportPeriodIdDropdownData = () => {
  const [lscOfferingByOrgUnitIdAndReportPeriodIdDropdownState, setLscOfferingByOrgUnitIdAndReportPeriodIdDropdownState] = useContext(LscOfferingByOrgUnitIdAndReportPeriodIdDropdownStateContext);

  const getLscOfferingByOrgUnitIdAndReportPeriodIdDropdown = (orgUnitId, reportPeriodId) => {
    LscOfferingByOrgUnitIdAndReportPeriodIdDropdownData.getLscOfferingByOrgUnitIdAndReportPeriodId(orgUnitId, reportPeriodId, lscOfferingByOrgUnitIdAndReportPeriodIdDropdownState, setLscOfferingByOrgUnitIdAndReportPeriodIdDropdownState);
  };

  return {
    lscOfferingByOrgUnitIdAndReportPeriodIdDropdownState,
    getLscOfferingByOrgUnitIdAndReportPeriodIdDropdown
  };
};

export default useLscOfferingByOrgUnitIdAndReportPeriodIdDropdownData;