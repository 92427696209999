import React from 'react';

import usePreCompInstructionHoursDropdown from './UsePreCompInstructionHoursDropdown';

import Dropdown from '../../../../../common/components/dropdowns/Dropdown';

import global from '../../../../../common/components/GlobalStyle.module.css';

const PreCompInstructionHoursDropdown = ({ label, name, value, error, message, onChange }) => {
  const { preCompInstructionHoursState } = usePreCompInstructionHoursDropdown();

  return preCompInstructionHoursState.message
    ? <span className={global.LoadingMsg}>{preCompInstructionHoursState.message}</span>
    : (
      <Dropdown
        error={error}
        label={label}
        message={message}
        options={preCompInstructionHoursState.dataAsOptionsWithNames}
        name={name}
        value={value}
        onChange={onChange}
        isLoading={preCompInstructionHoursState.isLoading}
      />
    );
};

export default PreCompInstructionHoursDropdown;