import React, { Fragment } from 'react';

import EditIcon from '../../../../common/components/icons/EditIcon';
import ShowIcon from '../../../../common/components/icons/ShowIcon';
import HideIcon from '../../../../common/components/icons/HideIcon';
import DeleteIcon from '../../../../common/components/icons/DeleteIcon';
import useExpandedRow from '../../../../common/components/grids/UseExpandedRow';
import SGColumnHeader from '../../../../common/components/grids/SGColumnHeader';

import { formatPhoneNumber } from '../../../../common/utils/validation';
import SGConstants from '../../../../common/utils/SGConstants';
import useSortableGrid from '../../../../common/utils/UseSortableGrid';

import global from '../../../../common/components/GlobalStyle.module.css';

const DetailTableRow = ({ personId, role, onDelete, registrationRoles }) => (
  <tr>
    <td>{role.orgRoleName}</td>
    <td>
      {!registrationRoles.find(x => x === role.orgRoleName) &&
        <button
          className={global.IconButtonMargin}
          type="button"
          onClick={(e) => onDelete(e, personId, role.personOrgRoleDurationId)}>
          <DeleteIcon />
        </button>
      }
    </td>
  </tr>
);

const DetailTable = ({ staff, onDelete, registrationRoles }) => (
  <table className={global.DetailTable}>
    <thead>
      <tr>
        <th>Role</th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      {staff && staff.personOrgRole && staff.personOrgRole.map((role, i) =>
        <DetailTableRow personId={staff.personId} role={role} key={i} onDelete={onDelete} registrationRoles={registrationRoles} />)}
    </tbody>
  </table>
);

const TableRow = ({ staff, onEdit, onDelete, expandedId, onClick, registrationRoles }) => {
  return (
    <Fragment>
      <tr>
        <td>
          {staff.preferredName || staff.firstName} {staff.lastName}
        </td>
        <td>
          {formatPhoneNumber(staff.phoneNumber)}
        </td>
        <td>
          {staff.email}
        </td>
        <td>
          {staff.isInGoodStanding === true ? 'Yes' : 'No'}
        </td>
        <td>
          <button className={global.IconButtonMargin} type="button" onClick={(e) => onEdit(e, staff.personId)}>
            <EditIcon />
          </button>
          <button
            className={global.IconButtonMargin}
            type="button"
            onClick={(e) => onClick(e, staff.personId)}>
            {expandedId === staff.personId ? <HideIcon /> : <ShowIcon />}
          </button>
        </td>
      </tr>
      {staff && staff.personOrgRole && staff.personOrgRole.length > 0 && expandedId === staff.personId &&
        <tr className={global.Expanded}>
          <td colSpan="6">
            <DetailTable staff={staff} onDelete={onDelete} registrationRoles={registrationRoles} />
          </td>
        </tr>}
    </Fragment>
  );
};

const LargeGrid = ({ state, onEdit, onDelete, expandedId, onClick, registrationRoles }) => {
  const {
    sortableGridState,
    onColumnClicked
  } = useSortableGrid(state.arrayData);

  return (
    <table className={[global.UsasTable, 'visible-md visible-lg'].join(' ')}>
      <thead>
        <tr>
          <SGColumnHeader sortableGridState={sortableGridState} columnLabel={'Club Staff Name'} columnField={'lastName'}
            sortType={SGConstants.SORT_TYPE_ALPHABETICAL} onColumnClicked={onColumnClicked} />
          <SGColumnHeader sortableGridState={sortableGridState} columnLabel={'Phone Number'} columnField={'phoneNumber'}
            sortType={SGConstants.SORT_TYPE_NUMERICAL} onColumnClicked={onColumnClicked} />
          <SGColumnHeader sortableGridState={sortableGridState} columnLabel={'Email'} columnField={'email'}
            sortType={SGConstants.SORT_TYPE_ALPHABETICAL} onColumnClicked={onColumnClicked} />
          <SGColumnHeader sortableGridState={sortableGridState} columnLabel={'Is in good standing?'} columnField={'isInGoodStanding'}
            sortType={SGConstants.SORT_TYPE_NUMERICAL} onColumnClicked={onColumnClicked} />
          <th></th>
        </tr>
      </thead>
      <tbody>
        {state.isArrayLoaded !== true
          ? (
            <tr>
              {state.isArrayLoading === true ?
                <td colSpan="5">Loading...</td>
                : <td colSpan="5">No Staff</td>
              }
            </tr>
          ) : Array.isArray(sortableGridState.sortedGridData) && sortableGridState.sortedGridData.length > 0
            ? sortableGridState.sortedGridData.map((staff, i) => <TableRow staff={staff} key={i} onEdit={onEdit} onDelete={onDelete}
              expandedId={expandedId} onClick={onClick} registrationRoles={registrationRoles} />)
            : (
              <tr>
                <td colSpan="5">No Staff</td>
              </tr>
            )
        }
      </tbody>
    </table>
  );
};

const GridRowDetailTableRow = ({ personId, role, onDelete, registrationRoles }) => (
  <div className={global.SmallTableRow}>
    <div className={global.SmallTableDetailRowHead}><span className='hidden-xs'>{role.orgRoleName}</span>&nbsp;
      {!registrationRoles.find(x => x === role.orgRoleName) &&
        <button
          className={global.IconButton}
          type="button"
          onClick={(e) => onDelete(e, personId, role.personOrgRoleDurationId)}>
          <DeleteIcon />
        </button>
      }
    </div>
    <div className={global.SmallTableRowInfo}>
      <div className='row'>
        <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3 visible-xs'].join(' ')}>Role</div>
        <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3 visible-xs'].join(' ')}>{role.orgRoleName}</div>
      </div>
    </div>
  </div >
);

const GridRowDetailTable = ({ staff, onDelete, registrationRoles }) => (
  <div className={global.SmallTableDetailTable}>
    {staff && Array.isArray(staff.personOrgRole) && staff.personOrgRole.map((role, i) =>
      <GridRowDetailTableRow key={i} personId={staff.personId} role={role} onDelete={onDelete} registrationRoles={registrationRoles} />)}
  </div>
);

const GridRow = ({ staff, onEdit, onDelete, expandedId, onClick, registrationRoles }) => {
  return (
    <Fragment>
      <div className={global.SmallTableRow}>
        <div className={global.SmallTableRowHead}>
          <span className='hidden-xs'>{staff.preferredName || staff.firstName} {staff.lastName}</span>&nbsp;
          <button className={global.IconButton}
            type="button"
            onClick={(e) => onClick(e, staff.personId)}>
            {expandedId === staff.personId ? <HideIcon /> : <ShowIcon />}
          </button>
          <button className={global.IconButtonMobileMargin} type="button" onClick={(e) => onEdit(e, staff.personId)}>
            <EditIcon />
          </button>
        </div>
        <div className={global.SmallTableRowInfo}>
          <div className='row'>
            <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3 visible-xs'].join(' ')}>Club Staff Name</div>
            <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3 visible-xs'].join(' ')}>{staff.preferredName || staff.firstName} {staff.lastName}</div>
            <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3'].join(' ')}>Phone Number</div>
            <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3'].join(' ')}>{formatPhoneNumber(staff.phoneNumber) || <span>&nbsp;</span>}</div>
            <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3'].join(' ')}>Email</div>
            <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3'].join(' ')}>{staff.email || <span>&nbsp;</span>}</div>
            <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3'].join(' ')}>Is In Good Standing?</div>
            <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3'].join(' ')}>{staff.isInGoodStanding === true ? 'Yes' : 'No'}</div>
          </div>
        </div>
      </div>
      {staff && Array.isArray(staff.personOrgRole) && staff.personOrgRole.length > 0 && staff.personId === expandedId &&
        <GridRowDetailTable staff={staff} onDelete={onDelete} registrationRoles={registrationRoles} />
      }
    </Fragment>
  );
};

const SmallGrid = ({ state, onEdit, onDelete, expandedId, onClick, registrationRoles }) => (
  <div className={[global.SmallTable, 'hidden-md hidden-lg'].join(' ')}>
    {state.isArrayLoaded !== true
      ? (
        <div className={global.SmallTableRow}>
          <div className={global.SmallTableRowHead}>
            &nbsp;
          </div>
          <div className={global.SmallTableRowInfo}>
            {state.isArrayLoading === true ?
              <div className={global.SmallTableRowLabels}>Loading...</div>
              : <div className={global.SmallTableRowLabels}>No Staff</div>
            }
          </div>
        </div>
      ) : Array.isArray(state.arrayData) && state.arrayData.length > 0
        ? state.arrayData.map((staff, i) => <GridRow key={i} staff={staff} onEdit={onEdit} onDelete={onDelete}
          expandedId={expandedId} onClick={onClick} registrationRoles={registrationRoles} />) : (
          <div className={global.SmallTableRow}>
            <div className={global.SmallTableRowHead}>
              &nbsp;
            </div>
            <div className={global.SmallTableRowInfo}>
              <div className={global.SmallTableRowLabels}>No Staff</div>
            </div>
          </div>
        )
    }
  </div>
);

const ClubStaffWriteGrid = ({ state, onDelete, onEdit, registrationRoles = [] }) => {
  const { expandedId, onClick } = useExpandedRow();

  return (
    <Fragment>
      <LargeGrid state={state} onDelete={onDelete} onEdit={onEdit} expandedId={expandedId} onClick={onClick} registrationRoles={registrationRoles} />
      <SmallGrid state={state} onDelete={onDelete} onEdit={onEdit} expandedId={expandedId} onClick={onClick} registrationRoles={registrationRoles} />
    </Fragment>
  );
};

export default ClubStaffWriteGrid;