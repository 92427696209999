import React, { Fragment } from 'react';

import useClubFacilitiesAdd from './UseClubFacilitiesAdd';

import PopUpModal from '../../../../common/components/dialogs/PopUpModal';
import AddFacility from '../../../../common/components/searches/searchFacility/addFacility/AddFacility';
import Constants from '../../../../common/utils/Constants';

import NavLinks from '../NavLinks';

const ClubFacilitiesAdd = () => {
  const {
    usasClubFacilityLinksState,
    baseUsasClubState
  } = useClubFacilitiesAdd();

  return (
    <Fragment>
      <AddFacility
        saveUrl={NavLinks.CLUB_FACILITIES_DETAILS}
        cancelUrl={NavLinks.CLUB_FACILITIES_SEARCH}
        orgUnitId={baseUsasClubState.objData.orgUnitId} />
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.SAVING_MSG}
        displayPopUp={usasClubFacilityLinksState.isSaving} />
    </Fragment>
  );
};

export default ClubFacilitiesAdd;