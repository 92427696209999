/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';

import global from '../../../../common/components/GlobalStyle.module.css';

const TableRow = ({ status, categoryMeasure, onEdit }) => (
  <tr>
    <td><a onClick={(e) => { onEdit(e, categoryMeasure); }}>{categoryMeasure.measureName}</a></td>
    <td>{categoryMeasure.isRequired === true ? 'Yes' : 'No'}</td>
    <td>{categoryMeasure.minValue} {categoryMeasure.minValue !== categoryMeasure.maxValue ? '- ' + categoryMeasure.maxValue : ''}</td>
    <td>{categoryMeasure.score >= 0 && categoryMeasure.score !== null ? categoryMeasure.score : '0'}</td>
    <td>{categoryMeasure.status}</td>
  </tr>
);

const CategoryMeasureGridLarge = ({ programLevel, programCategory, isLoading, onEdit }) => (
  <table className={[global.UsasTable, 'visible-md visible-lg'].join(' ')}>
    <thead>
      <tr>
        <th>Task Name</th>
        <th>Required?</th>
        <th>Point Range</th>
        <th>Current Points</th>
        <th>Status</th>
      </tr>
    </thead>
    <tbody>
      {isLoading === true
        ? <tr><td colSpan="5">Loading...</td></tr>
        : Array.isArray(programCategory.categoryMeasure) && programCategory.categoryMeasure.length > 0
          ? programCategory.categoryMeasure.map((categoryMeasure, i) =>
            <TableRow key={i} status={programLevel.status} categoryMeasure={categoryMeasure} onEdit={onEdit} />)
          : <tr><td colSpan="5">No Tasks</td></tr>
      }
    </tbody>
  </table>
);

export default CategoryMeasureGridLarge;