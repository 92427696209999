import { useEffect } from 'react';
import { useNavigate, useLocation } from '../../../../../common/wrappers/ReactRouterDom';

import validate from './ClubGroupTypePracticeValidation';

import { createGroupObjFromGroupInfoForm, createOrgGroupFieldValues, editClubGroupCoaches, getClubGroupCoachIds } from '../utils/ClubGroupsUtils';
import ClubConstants from '../../utils/ClubConstants';

import NavLinks from '../../NavLinks';

import useClubGroupsData from '../../../../state/clubGroups/UseClubGroupsData';

import useOrgGroupPropertiesData from '../../../../../common/state/orgGroupProperties/UseOrgGroupPropertiesData';
import useGroupCategoryData from '../../../../../common/state/groupCategory/UseGroupCategoryData';
import useGroupCoachesData from '../../../../../common/state/groupCoaches/UseGroupCoachesData';
import useBaseUsasClubData from '../../../../../common/state/clubBase/UseBaseUsasClubData';
import useGroupFacilitiesData from '../../../../../common/state/groupFacilities/UseGroupFacilitiesData';
import useGroupFieldNamesData from '../../../../../common/state/groupFieldNames/UseGroupFieldNamesData';

import useForm from '../../../../../common/utils/UseForm';
import Constants from '../../../../../common/utils/Constants';
import HierarchicalDataUtils from '../../../../../common/utils/HierarchicalDataUtils';

const useClubGroupTypePracticeEdit = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { GROUP_CONSTANTS } = useGroupFieldNamesData();
  const { clubGroupState, getClubGroupDetails, putClubGroup } = useClubGroupsData();
  const { baseUsasClubState } = useBaseUsasClubData();
  const { groupCategoryState } = useGroupCategoryData();
  const { groupCoachesState } = useGroupCoachesData();
  const { groupFacilitiesState } = useGroupFacilitiesData();
  const { orgGroupPropertiesState, getOrgGroupProperties } = useOrgGroupPropertiesData();
  const { formState, errorState, setFormState, handleSubmit, onFormValueChanged, onValueTextPairChanged, setIsDirty
  } = useForm(getInitialFormState, submitFormCallback, validate);

  const onBackClicked = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    navigate(NavLinks.CLUB_GROUPS);
  };

  useEffect(() => {
    if (clubGroupState.isObjLoaded === true
      && groupCategoryState.isArrayLoaded === true && groupCategoryState.id === clubGroupState.objData.orgUnitId
      && orgGroupPropertiesState.isArrayLoaded === true && orgGroupPropertiesState.groupTypeId === clubGroupState.objData.groupTypeId
      && groupCoachesState.isArrayLoaded === true && groupCoachesState.usasClubId === baseUsasClubState.objData.usasClubId
      && groupFacilitiesState.isArrayLoaded === true && groupFacilitiesState.usasClubId === baseUsasClubState.objData.usasClubId) {
      const groupObj = clubGroupState.objData;

      const facilityFieldValue = groupObj.orgGroupFieldValue.find(x => x.groupTypeField?.fieldName === GROUP_CONSTANTS.ORG_GROUP_TYPE_FIELD_NAME_FACILITY)?.fieldValue;
      const targetFacility = groupFacilitiesState.options.find(x => `${x.id}` === facilityFieldValue);

      const groupCoachIds = getClubGroupCoachIds(groupCoachesState.options, groupObj.personOrgGroup);

      setFormState({
        ...formState,
        orgUnitId: groupObj.orgUnitId,
        groupTypeId: groupObj.groupTypeId,
        groupTypeName: groupObj.groupType?.typeName || '',
        groupCategories: HierarchicalDataUtils.GetNameIdPairs(groupCategoryState.treeData, [groupObj.groupCategoryId]),
        groupName: groupObj.groupName,
        groupCode: groupObj.groupCode,
        groupDescription: groupObj.groupDescription,
        groupCoaches: HierarchicalDataUtils.GetNameIdPairs(groupCoachesState.options, groupCoachIds),
        facilityId: targetFacility?.id || Constants.DEFAULT_ID,
        facilityName: targetFacility?.name || '--',
        startDate: groupObj.orgGroupFieldValue.find(x => x.groupTypeField?.fieldName === GROUP_CONSTANTS.ORG_GROUP_TYPE_FIELD_NAME_START_DATE)?.fieldValue,
        endDate: groupObj.orgGroupFieldValue.find(x => x.groupTypeField?.fieldName === GROUP_CONSTANTS.ORG_GROUP_TYPE_FIELD_NAME_END_DATE)?.fieldValue
      });

      setIsDirty(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clubGroupState, groupCategoryState, orgGroupPropertiesState, groupCoachesState, groupFacilitiesState]);

  useEffect(() => {
    if (clubGroupState.isSaved === true) {
      navigate(NavLinks.CLUB_GROUPS);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clubGroupState.isSaved]);

  useEffect(() => {
    if (clubGroupState.isObjLoaded === true && clubGroupState.objData?.groupTypeId !== orgGroupPropertiesState.groupTypeId) {
      getOrgGroupProperties(clubGroupState.objData.groupTypeId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clubGroupState, orgGroupPropertiesState]);

  useEffect(() => {
    if (location.state.clubGroupId) {
      getClubGroupDetails(location.state.clubGroupId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.state]);

  function submitFormCallback(formState) {
    const newOrgGroupObj = createGroupObjFromGroupInfoForm(clubGroupState.objData, formState);

    const groupTypeFieldId = orgGroupPropertiesState.arrayData.find(x => x.fieldName === GROUP_CONSTANTS.PERSON_GROUP_TYPE_FIELD_NAME_ATTENDEE_TYPE)?.groupTypeFieldId;

    newOrgGroupObj.personOrgGroup = editClubGroupCoaches(groupCoachesState.options, formState.groupCoaches,
      newOrgGroupObj.personOrgGroup, newOrgGroupObj.orgGroupId, groupTypeFieldId, ClubConstants.COACH_ROLE_NAME);

    newOrgGroupObj.orgGroupFieldValue = createOrgGroupFieldValues([
      { fieldName: GROUP_CONSTANTS.ORG_GROUP_TYPE_FIELD_NAME_START_DATE, fieldValue: formState.startDate },
      { fieldName: GROUP_CONSTANTS.ORG_GROUP_TYPE_FIELD_NAME_END_DATE, fieldValue: formState.endDate },
      { fieldName: GROUP_CONSTANTS.ORG_GROUP_TYPE_FIELD_NAME_FACILITY, fieldValue: formState.facilityId }
    ], orgGroupPropertiesState.arrayData, newOrgGroupObj.orgGroupId);

    if (newOrgGroupObj.orgGroupFieldValue.length > 0) {
      for (const orgGroupField of newOrgGroupObj.orgGroupFieldValue) {
        orgGroupField.groupTypeField = undefined;
      }
    }

    putClubGroup(newOrgGroupObj.orgGroupId, newOrgGroupObj);
  };

  function getInitialFormState() {
    return {
      orgUnitId: '',
      groupTypeId: Constants.DEFAULT_ID,
      groupTypeName: '',
      groupCategories: [],
      groupName: '',
      groupCode: '',
      groupDescription: '',
      groupCoaches: [],
      facilityId: Constants.DEFAULT_ID,
      facilityName: '--',
      startDate: Constants.BLANK_DATE_STRING,
      endDate: Constants.BLANK_DATE_STRING
    };
  };

  return {
    isLoading: orgGroupPropertiesState.isArrayLoading || groupCategoryState.isArrayLoading || clubGroupState.isObjLoading,
    isSaving: clubGroupState.isSaving,
    formState,
    errorState,
    handleSubmit,
    onFormValueChanged,
    onValueTextPairChanged,
    onBackClicked,
    usasClubId: baseUsasClubState.objData.usasClubId || Constants.DEFAULT_ID,
    orgUnitId: baseUsasClubState.objData.orgUnitId || ''
  };
};

export default useClubGroupTypePracticeEdit;