import React, { Fragment } from "react";

import useClubMeets from "./UseClubMeets";

import ClubMeetsGrid from "./ClubMeetsGrid";

import ReportPeriodDropdown from "../../../../common/components/dropdowns/reportPeriodDropdown/ReportPeriodDropdown";
import ActionIntraPageButton from "../../../../common/components/buttons/ActionIntraPageButton";
import PrimaryButton from "../../../../common/components/buttons/PrimaryButton";
import PopUpModal from "../../../../common/components/dialogs/PopUpModal";
import Headings from "../../../../common/components/headings/Headings";
import SecondaryButton from "../../../../common/components/buttons/SecondaryButton";
import Constants from "../../../../common/utils/Constants";

const ClubMeets = () => {
  const {
    clubMeetsState,
    gridState,
    formState,
    errorState,
    deleteModalState,
    isSaving,
    isLoading,
    organizationId,
    clubOrgUnitId,
    onRequestButtonClicked,
    onDeleteClicked,
    onReportPeriodChanged,
    onUploadClicked,
    onModalCancelClicked,
    onModalDeleteClicked
  } = useClubMeets();

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>Club Meets</Headings.H3>
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12 col-sm-6 col-md-8 usas-extra-top-margin">
          <ActionIntraPageButton onClick={onRequestButtonClicked}>Request Meet Sanction</ActionIntraPageButton>
        </div>
        <div className="col-xs-12 col-sm-6 col-md-4">
          <ReportPeriodDropdown
            label="Report Period"
            name="reportPeriodId"
            value={formState.reportPeriodId}
            error={errorState.reportPeriodId}
            message={errorState.reportPeriodId}
            onChange={(value, valueLabel) => onReportPeriodChanged(value, valueLabel)}
            organizationId={organizationId} />
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12 usas-extra-top-margin usas-extra-bottom-margin">
          <ClubMeetsGrid
            data={gridState}
            clubOrgUnitId={clubOrgUnitId}
            isLoading={clubMeetsState.isArrayLoading}
            onUploadClicked={onUploadClicked}
            onDeleteClicked={onDeleteClicked} />
        </div>
      </div>
      <PopUpModal
        widthPct={90}
        maxWidth={720}
        title={'Delete Meet?'}
        displayPopUp={deleteModalState.displayPopUp}
        onModalCanceled={onModalCancelClicked}>
        <div className="row">
          <div className="col-xs-12">
            <p><b>Meet:</b> {deleteModalState.meetName}</p>
            <p><b>Dates:</b> {deleteModalState.meetDates}</p>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12 usas-extra-top-margin">
            <PrimaryButton type="button" onClick={onModalDeleteClicked}>Delete</PrimaryButton>&nbsp;
            <SecondaryButton type="button" onClick={onModalCancelClicked}>Cancel</SecondaryButton>
          </div>
        </div>
      </PopUpModal>
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.SAVING_MSG}
        displayPopUp={isSaving} />
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={isLoading} />
    </Fragment>
  );
};

export default ClubMeets;