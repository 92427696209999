import { useState, useEffect } from 'react';

const useClubRosterExport = (
  clubRosterArray,
  orgUnitCode,
  orgUnitName,
  parentOrgUnitCode,
  clubCity,
  clubStateCode,
  clubAddress1,
  clubPostalCode,
  clubEmail = "",
  rosterType) => {

  const [uiState, setUiState] = useState({ rosterFileCreated: false, fileContent: "" });

  let fileString = "";
  const emailLength = 36, address1Length = 60, cityLength = 30, nameLength = 20;

  function calcCheckSum(record) {
    const c_iHytekCheckSumStringLength = 128;
    let sumOfCharacters = 0;
    for (let i = 0; i < c_iHytekCheckSumStringLength; i++) {
      sumOfCharacters += 2 * record.charCodeAt(i);
    }

    for (let i = 1; i < c_iHytekCheckSumStringLength; i += 3) {
      sumOfCharacters += 3 * record.charCodeAt(i);
    }
    sumOfCharacters = Math.trunc(sumOfCharacters / 24 + 137);
    let sumOfCharactersString = sumOfCharacters.toString();

    return sumOfCharactersString[sumOfCharactersString.length - 1] + sumOfCharactersString[sumOfCharactersString.length - 2];
  }

  function formatFileDate(date) {
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDay().toString().padStart(2, '0');
    const year = date.getFullYear().toString();

    const hours = (date.getHours() % 12).toString().padStart(2, '0');
    const mins = date.getMinutes().toString().padStart(2, '0');
    const ampm = date.getHours() >= 12 ? 'PM' : 'AM';

    const datePart = month + day + year;

    return `${datePart} ${hours}:${mins} ${ampm}`;
  }

  function formatDate(date) {
    //1991-01-01 -> 10092008
    return date.substring(5, 7) + date.substring(8, 10) + date.substring(0, 4);
  }

  function formatPhone(phone) {
    //17192103434 -> 719-210-3434
    return `${phone.substring(1, 4)}-${phone.substring(4, 7)}-${phone.substring(7, 12)}`;
  }

  function buildA1Row() {
    let fileDate = formatFileDate(new Date());
    let A0Template = `A118USAS Club Times Export   US Swimming    V0.00         ${fileDate}USA SWIMS                                            `;
    const checkSumVal = calcCheckSum(A0Template);

    return A0Template += checkSumVal.toString();
  }

  function buildC1Row() {
    const orgUnitCodeLength = 5, orgUnitNameLength = 30, c1UnusedSpace = "", c1UnusedSpaceLength = 16;
    const orgUnitNameAdjusted = orgUnitName.length > orgUnitNameLength ? orgUnitName.substring(0, orgUnitNameLength) : orgUnitName;

    let C1Template =
      `C1${orgUnitCode.padEnd(orgUnitCodeLength)}${orgUnitNameAdjusted.padEnd(orgUnitNameLength)}${c1UnusedSpace.padEnd(c1UnusedSpaceLength)}${parentOrgUnitCode}                                                                         `;
    const checkSumVal = calcCheckSum(C1Template);

    return C1Template += checkSumVal.toString();
  }

  function buildNCAAC1Row() {
    const orgUnitCodeLength = 5, orgUnitNameLength = 30, c1UnusedSpace = "", c1UnusedSpaceLength = 16;
    const orgUnitNameAdjusted = orgUnitName.length > orgUnitNameLength ? orgUnitName.substring(0, orgUnitNameLength) : orgUnitName;

    const stateCode = "  ";

    let C1Template =
      `C1${orgUnitCode.padEnd(orgUnitCodeLength)}${orgUnitNameAdjusted.padEnd(orgUnitNameLength)}${c1UnusedSpace.padEnd(c1UnusedSpaceLength)}${stateCode}                                                                         `;
    const checkSumVal = calcCheckSum(C1Template);

    return C1Template += checkSumVal.toString();
  }

  function buildC2Row() {
    let C2Template = `C2${clubAddress1.padEnd(address1Length)}${clubCity.padEnd(cityLength)}${clubStateCode.padEnd(2)}${clubPostalCode.padEnd(10)}                        `;
    const checkSumVal = calcCheckSum(C2Template);

    return C2Template += checkSumVal.toString();
  }

  function buildC3Row() {
    let C3Template = `C3                                                                                          ${clubEmail.padEnd(emailLength)}`;
    const checkSumVal = calcCheckSum(C3Template);

    return C3Template += checkSumVal.toString();
  }

  function buildD1Row(gender, dob, prefName, middleInitial, athlete) {
    //const genderCode = gender === 1 ? "M" : "F";
    const memberId = athlete.memberId === undefined ? "X1X1X1X1X1X1X1" : athlete.memberId;
    const lastName = athlete.lastName.length > nameLength ? athlete.lastName.substring(0, nameLength) : athlete.lastName;
    const firstName = athlete.firstName.length > nameLength ? athlete.firstName.substring(0, nameLength) : athlete.firstName;
    prefName = prefName.length > nameLength ? prefName.substring(0, nameLength) : prefName;

    let D1Template = `D1${gender}00000${lastName.padEnd(nameLength)}${firstName.padEnd(nameLength)}${prefName}${middleInitial.padEnd(1)}${memberId}     ${dob} ${athlete.age.toString().padEnd(2)}                             `;
    const checkSumVal = calcCheckSum(D1Template);

    return D1Template += checkSumVal.toString();
  }

  function buildNCAAD1Row(gender, dob, prefName, middleInitial, athlete, yearOfEligibility) {
    const eligibilityMap = {
      'Freshman': { code: 'FR' },
      'Sophomore': { code: 'SO' },
      'Junior': { code: 'JR' },
      'Senior': { code: 'SR' },
      'Grad Student': { code: 'GS' },
      '' : { code: ''}
    }

    let yearOfEligibilityCode = "";
    try {
      yearOfEligibilityCode = eligibilityMap[yearOfEligibility].code
    } catch {}

    const memberId = athlete.memberId === undefined ? "X1X1X1X1X1X1X1" : athlete.memberId;
    let D1Template = `D1${gender}00000${athlete.lastName.padEnd(nameLength)}${athlete.firstName.padEnd(nameLength)}${prefName}${middleInitial.padEnd(1)}${memberId}     ${dob} ${athlete.age.toString().padEnd(2)}${yearOfEligibilityCode.padEnd(2)}                           `;
    const checkSumVal = calcCheckSum(D1Template);

    return D1Template += checkSumVal.toString();
  }

  function buildD2Row(address1, city, state, postalCode) {
    let D2Template = `D2${address1.padEnd(address1Length)}${city.padEnd(cityLength)}${state.padEnd(2)}${postalCode.padEnd(10)}                        `;
    const checkSumVal = calcCheckSum(D2Template);

    return D2Template += checkSumVal.toString();
  }

  function buildD3Row(primaryPhone, secondaryPhone, athlete) {
    let email = athlete.email || "";
    email = email.length > emailLength ? "" : email;
    let D3Template = `D3                              ${primaryPhone.padEnd(12)}        ${secondaryPhone.padEnd(12)}                            ${email.padEnd(emailLength)}`;
    const checkSumVal = calcCheckSum(D3Template);

    return D3Template += checkSumVal.toString();
  }

  function buildD5Row(attachDate) {
    let D5Template = `D5                                                                                         ${attachDate.padEnd(8)}NN                           `;
    const checkSumVal = calcCheckSum(D5Template);
    return D5Template += checkSumVal.toString();
  }

  const createRosterFile = () => {
    const COMP_GENDER_TYPE_MALE = "M", COMP_GENDER_TYPE_FEMALE = "F";

    fileString = buildA1Row() + "\r\n" + ( rosterType === "USAS" ? buildC1Row() : buildNCAAC1Row()) + "\r\n";
    
    if (rosterType === "USAS") {
      fileString += buildC2Row() + "\r\n" + buildC3Row() + "\r\n";
    }

    clubRosterArray.forEach(athlete => {
      const gender = athlete.competitionGenderTypeId === 2 ? COMP_GENDER_TYPE_FEMALE : COMP_GENDER_TYPE_MALE;
      const prefName = athlete.preferredName.length > 0 ? athlete.preferredName.padEnd(nameLength) : athlete.firstName.padEnd(nameLength);
      const middleInitial = athlete.preferredName.length > 0 ? athlete.middleName.substring(0, 1) : '';
      const dob = formatDate(athlete.birthDate);

      if (rosterType === "USAS") {
        fileString += buildD1Row(gender, dob, prefName, middleInitial, athlete);
      } else {
        fileString += buildNCAAD1Row(gender, dob, prefName, middleInitial, athlete, athlete.yearOfEligibility !== null ? athlete.yearOfEligibility : "");
      }

      let address1 = "", city = "", state = "", postalCode = "", primaryPhone = "", secondaryPhone = "";
      if (athlete.personContact !== null && athlete.personContact.length > 0) {
        address1 = athlete.personContact[0].address1 !== null ? athlete.personContact[0].address1 : "";
        city = athlete.personContact[0].city !== null ? athlete.personContact[0].city : "";
        state = athlete.personContact[0].stateCode !== null ? athlete.personContact[0].stateCode : "";
        postalCode = athlete.personContact[0].postalCode !== null ? athlete.personContact[0].postalCode : "";
        primaryPhone = (athlete.personContact[0]?.phoneNumber !== null && athlete.personContact[0]?.phoneNumber.length > 0) ? formatPhone(athlete.personContact[0]?.phoneNumber) : "";
        secondaryPhone = (athlete.personContact[1]?.phoneNumber !== null && athlete.personContact[1]?.phoneNumber.length > 0) ? formatPhone(athlete.personContact[1]?.phoneNumber) : "";
      }

      fileString += "\r\n" + buildD2Row(address1, city, state, postalCode);
      fileString += "\r\n" + buildD3Row(primaryPhone, secondaryPhone, athlete);
      let attachDate = athlete.attachDate !== undefined ? formatDate(athlete.attachDate) : "";
      fileString += "\r\n" + buildD5Row(attachDate) + "\r\n";
    });
  }

  const onRosterExportClicked = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    createRosterFile();
    setUiState({...uiState, rosterFileCreated: true, fileContent: fileString });
  }

  const DownloadRoster = () => {
    const file = new Blob([uiState.fileContent], { type: 'application/text' });
    const a = document.createElement('a');
    a.href = URL.createObjectURL(file);

    const today = new Date();

    let fileName = `SWIMSRoster_${orgUnitCode}_${today.getMonth() + 1}${today.getDate()}${today.getFullYear()}.HY3`;
    a.download = fileName;
    a.click();

    URL.revokeObjectURL(a.href);
  };

  useEffect(() => {
    if (uiState.rosterFileCreated === true) {
      DownloadRoster();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uiState]);

  return {
    uiState,
    onRosterExportClicked
  }
}
export default useClubRosterExport;