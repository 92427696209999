import React from 'react';

import useCategoryMeasureDropdown from './UseCategoryMeasureDropdown';

import Dropdown from '../../../../common/components/dropdowns/Dropdown';

import global from '../../../../common/components/GlobalStyle.module.css';

const CategoryMeasureDropdown = ({ label, name, value, error, message, onChange, categoryMeasureId }) => {
  const { categoryMeasureDropdownState } = useCategoryMeasureDropdown(categoryMeasureId);

  return categoryMeasureDropdownState.message
    ? <span className={global.LoadingMsg}>{categoryMeasureDropdownState.message}</span>
    : (
      <Dropdown
        error={error}
        label={label}
        message={message}
        options={categoryMeasureDropdownState.options}
        name={name}
        value={value}
        onChange={onChange}
        isLoading={categoryMeasureDropdownState.isArrayLoading} />
    );
};

export default CategoryMeasureDropdown;