import React, { Fragment } from 'react';

import ClubPreCompGridLargeRead from './ClubPreCompGridLargeRead';
import ClubPreCompGridSmallRead from './ClubPreCompGridSmallRead';

const ClubPreCompGridRead = ({ data, isLoading }) => (
  <Fragment>
    <ClubPreCompGridLargeRead data={data} isLoading={isLoading} />
    <ClubPreCompGridSmallRead data={data} isLoading={isLoading} />
  </Fragment>
);

export default ClubPreCompGridRead;