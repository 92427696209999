import React, { Fragment } from 'react';

import useLearnToSwimContextView from './UseLearnToSwimContextView';

import Headings from '../../../common/components/headings/Headings';
import LeftArrowIcon from '../../../common/components/icons/LeftArrowIcon';
import LargeContextBasedNav from '../../../common/components/contextBasedNav/LargeContextBasedNav';
import MobileContextBasedNav from '../../../common/components/contextBasedNav/MobileContextBasedNav';
import PageNotFound from '../../../common/components/PageNotFound';

import global from '../../../common/components/GlobalStyle.module.css';

const LearnToSwimContextView = ({ children }) => {
  const {
    backToLabel,
    ltsProviderState,
    onBackClicked,
    onHomeClicked,
    selectOrgUnitState,
    backToSelectionText
  } = useLearnToSwimContextView();

  if (ltsProviderState.isObjLoaded !== true && ltsProviderState.isObjLoading !== true) {
    return (
      <Fragment>
        <div className="row usas-extra-bottom-margin">
          <div className="col-xs-12">
            <button className={global.BackToSearchButton}
              type="button"
              onClick={onBackClicked}>
              <LeftArrowIcon />  {backToLabel}
            </button>
          </div>
        </div>
        <div className="row usas-extra-bottom-margin">
          <div className="col-xs-12">
            <PageNotFound />
          </div>
        </div>
      </Fragment>
    );
  }

  return (
    <Fragment>
      <div>
        <div className="row">
          <div className="col-xs-12">
            <button className={global.HeaderButton} onClick={onHomeClicked}>
              <Headings.H3
                className={[global.NoBottomMargin, 'col-xs-12'].join(' ')}
                excludeBar={true}>
                View/Edit Learn To Swim Provider
              </Headings.H3>
            </button>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12">
            <hr className="usas-bar-turmeric" />
          </div>
        </div>
        {selectOrgUnitState.showBackToSelection === true && backToLabel === backToSelectionText &&
        <div className="row">
          <div className="col-xs-12">
            <button className={global.BackToSearchButton}
              type="button"
              onClick={onBackClicked}>
              <LeftArrowIcon />  {backToLabel}
            </button>
          </div>
        </div>}
      {backToLabel !== backToSelectionText &&
        <div className="row">
          <div className="col-xs-12">
            <button className={global.BackToSearchButton}
              type="button"
              onClick={onBackClicked}>
              <LeftArrowIcon />  {backToLabel}
            </button>
          </div>
        </div>}
        <div className='row'>
          <div className="col-xs-12">
            <p className={global.HeaderText}><b>Provider:</b> {ltsProviderState.objData?.entityName || ''} </p>
            <p className={global.HeaderText}><b>Tax Id:</b> {ltsProviderState.objData?.taxId || ''} </p>
          </div>
        </div>
        <div className="row">
          <div className="visible-xs col-xs-12">
            <MobileContextBasedNav taxonomyName="LearnToSwimDetail" renderPillsOnly={true} />
          </div>
          <div className="hidden-xs col-xs-12">
            <LargeContextBasedNav taxonomyName="LearnToSwimDetail" renderPillsOnly={true} />
          </div>
        </div>
      </div>
      {children}
    </Fragment>
  );
};

export default LearnToSwimContextView;