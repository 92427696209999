import usePreCompCurriculumData from "../../../../state/preCompCurriculum/UsePreCompCurriculumData";

const usePreCompInstructorTrainingAgencyDropdown = () => {
  const { preCompCurriculumState } = usePreCompCurriculumData();

  return {
    preCompCurriculumState
  };
};

export default usePreCompInstructorTrainingAgencyDropdown;