import { Fragment } from "react";

import useClubRosterWidget, { MAX_DATA_COUNT, COLUMN_NUMBERS } from "./UseClubRosterWidget";

//TODO add these to common?
import SisenseSGColumnHeader from "../../../components/grids/SisenseSGColumnHeader";
import useSisenseSortableGrid from "../../../utils/UseSisenseSortableGrid";

import Spinner from "../../../../common/components/spinners/Spinner";
import Input from '../../../../common/components/inputs/Input';
import ActionIntraPageButton from '../../../../common/components/buttons/ActionIntraPageButton';
import PrimaryButton from "../../../../common/components/buttons/PrimaryButton";
import SecondaryButton from '../../../../common/components/buttons/SecondaryButton';
import LeftArrowIcon from "../../../../common/components/icons/LeftArrowIcon";
import RightArrowIcon from "../../../../common/components/icons/RightArrowIcon";

import SGConstants from "../../../../common/utils/SGConstants";

import global from '../../../../common/components/GlobalStyle.module.css';
import style from '../ClubPerformance.module.css';

const ClubRosterGridRowSmall = ({ columns, row, onViewTimesClicked }) => (
  <div className={global.SmallTableRow}>
    <div className={global.SmallTableRowHead}>
      <div style={{ maxWidth: '180px' }}><ActionIntraPageButton onClick={(e) => onViewTimesClicked(e, row)}>See Results</ActionIntraPageButton></div></div>
    <div className={global.SmallTableRowInfo}>
      <div className="row">
        {columns.map((column, i) => (
          <Fragment key={i}>
            {column.columnNumber > 0 &&
              <Fragment>
                <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3'].join(' ')}>
                  {column.columnLabel || <span>&nbsp;</span>}
                </div>
                <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3'].join(' ')}>
                  {row[i].cellText || <span>&nbsp;</span>}
                </div>
              </Fragment>
            }
          </Fragment>
        ))}
      </div>
    </div>
  </div>
);

const ClubRosterGridSmall = ({ data, onViewTimesClicked }) => (
  <div className={[global.SmallTable, 'hidden-md hidden-lg'].join(' ')}>
    {Array.isArray(data.rows) && data.rows.length > 0
      ? data.rows.map((row, i) => (
        <ClubRosterGridRowSmall key={i} columns={data.columns} row={row} onViewTimesClicked={onViewTimesClicked} />
      )) : (
        <div className={global.SmallTableRow}>
          <div className={global.SmallTableRowHead}>
            &nbsp;
          </div>
          <div className={global.SmallTableRowInfo}>
            <div className={global.SmallTableRowLabels}>No Results</div>
          </div>
        </div>
      )}
  </div>
);

const ClubRosterGridRowLarge = ({ row, onViewTimesClicked }) => (
  <tr>
    {row.map((cell, i) => {
      if (cell.columnNumber > 0) {
        return <td key={i}> {cell.cellText || ''} </td>;
      }
    })}
    <td><div style={{ paddingTop: '5px' }} className="pull-right"><ActionIntraPageButton onClick={(e) => onViewTimesClicked(e, row)}>View Times</ActionIntraPageButton></div></td>
  </tr>
);

const ClubRosterGridLarge = ({ data, onViewTimesClicked }) => {
  const {
    sortableGridState,
    onColumnClicked
  } = useSisenseSortableGrid(data.rows);

  return (
    <table className={global.UsasTable}>
      <thead>
        <tr>
          {data.columns.map((column, i) =>
            <Fragment key={i}>
              {column.columnNumber === COLUMN_NUMBERS.FULL_NAME || column.columnNumber === COLUMN_NUMBERS.MEMBER_ID
                ? <SisenseSGColumnHeader key={i} sortableGridState={sortableGridState} columnLabel={column.columnLabel} arrayIndex={i}
                  sortType={SGConstants.SORT_TYPE_ALPHABETICAL} onColumnClicked={onColumnClicked} />
                : column.columnNumber === COLUMN_NUMBERS.AGE
                  ? <SisenseSGColumnHeader key={i} sortableGridState={sortableGridState} columnLabel={column.columnLabel} arrayIndex={i}
                    sortType={SGConstants.SORT_TYPE_NUMERICAL} onColumnClicked={onColumnClicked} />
                  : column.columnNumber > 0
                    ? <th key={i}>{column.columnLabel}</th>
                    : <Fragment />
              }
            </Fragment>
          )}
          <th style={{ minWidth: '155px' }}></th>
        </tr>
      </thead>
      <tbody>
        {sortableGridState.sortedGridData.length > 0
          ? sortableGridState.sortedGridData.map((row, i) => <ClubRosterGridRowLarge key={i} row={row} onViewTimesClicked={onViewTimesClicked} />)
          : <tr><td colSpan={data.columns.length}>No Results</td></tr>
        }
      </tbody>
    </table>
  );
};

const ClubRosterGrid = ({ data, onViewTimesClicked }) => (
  <Fragment>
    <div className={'visible-md visible-lg'}>
      <ClubRosterGridLarge data={data} onViewTimesClicked={onViewTimesClicked} />
    </div>
    <div className={'hidden-md hidden-lg'}>
      <ClubRosterGridSmall data={data} onViewTimesClicked={onViewTimesClicked} />
    </div>
  </Fragment>
);

const ClubRosterWidget = ({ dashboardOid, widget }) => {
  const {
    isLoading,
    isError,
    formattedData,
    state,
    formState,
    errorState,
    onFormValueChanged,
    handleSubmit,
    goToNextPage,
    goToPreviousPage,
    onViewTimesClicked
  } = useClubRosterWidget(dashboardOid, widget);

  return (
    <div>
      <form onSubmit={handleSubmit} noValidate>
        <div className="row usas-extra-top-margin">
          <div className="col-xs-12 col-sm-6 col-md-4">
            <Input
              label="First Name"
              name="firstName"
              value={formState.firstName}
              error={errorState.firstName}
              message={errorState.firstName}
              onChange={(value) => { onFormValueChanged('firstName', value) }} />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4">
            <Input
              label="Last Name"
              name="lastName"
              value={formState.lastName}
              error={errorState.lastName}
              message={errorState.lastName}
              onChange={(value) => { onFormValueChanged('lastName', value) }} />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4">
            <Input
              label="Age"
              name="age"
              value={formState.age}
              error={errorState.age}
              message={errorState.age}
              onChange={(value) => { onFormValueChanged('age', value) }} />
          </div>
          <div className="col-xs-12 usas-extra-top-margin">
            <PrimaryButton type="submit" onClick={handleSubmit}>Filter</PrimaryButton>
          </div>
        </div>
      </form>
      {!isLoading && !isError &&
        <div className={style.Widget}>
          <div className="row usas-extra-top-margin">
            <div className="col-xs-12 col-sm-3">
              <p>{formattedData.rows?.length === 1 ? `${formattedData.rows?.length} Result` : `${formattedData.rows?.length} Results`} - Page {(state.offset / MAX_DATA_COUNT) + 1}</p>
            </div>
            <div className="col-xs-12 col-sm-9">
              {formattedData.rows?.length === MAX_DATA_COUNT &&
                <Fragment>
                  <SecondaryButton className={'pull-right usas-extra-top-margin'} onClick={(e) => goToNextPage(e)} >View Next Results Page <RightArrowIcon /></SecondaryButton>
                  <span className="pull-right">&nbsp;</span>
                </Fragment>}
              {state.offset > 0 &&
                <Fragment>
                  <SecondaryButton className={'pull-right usas-extra-top-margin'} onClick={(e) => goToPreviousPage(e)} ><LeftArrowIcon /> View Previous Results Page</SecondaryButton>
                  <span className="pull-right">&nbsp;</span>
                </Fragment>}
            </div>
          </div>
        </div>}
      <div className="row usas-extra-top-margin">
        <div className="col-xs-12">
          {isError
            ? <p className={global.ErrorMessage}>Error...</p>
            : isLoading && state.enabled
              ? <Spinner />
              : formattedData && state.enabled
                ? <ClubRosterGrid data={formattedData || {}} onViewTimesClicked={onViewTimesClicked} />
                : <Fragment />
          }
        </div>
      </div>
      {!isLoading && !isError && formattedData.rows?.length > 5 &&
        <div className={style.Widget}>
          <div className="row usas-extra-top-margin">
            <div className="col-xs-12 col-sm-3">
              <p>{formattedData.rows?.length === 1 ? `${formattedData.rows?.length} Result` : `${formattedData.rows?.length} Results`} - Page {(state.offset / MAX_DATA_COUNT) + 1}</p>
            </div>
            <div className="col-xs-12 col-sm-9">
              {formattedData.rows?.length === MAX_DATA_COUNT &&
                <Fragment>
                  <SecondaryButton className={'pull-right usas-extra-top-margin'} onClick={(e) => goToNextPage(e)} >View Next Results Page <RightArrowIcon /></SecondaryButton>
                  <span className="pull-right">&nbsp;</span>
                </Fragment>}
              {state.offset > 0 &&
                <Fragment>
                  <SecondaryButton className={'pull-right usas-extra-top-margin'} onClick={(e) => goToPreviousPage(e)} ><LeftArrowIcon /> View Previous Results Page</SecondaryButton>
                  <span className="pull-right">&nbsp;</span>
                </Fragment>}
            </div>
          </div>
        </div>}
    </div>
  );
};

export default ClubRosterWidget;