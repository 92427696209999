import { Fragment } from 'react';

import useMeetSessionsDetailForm from './UseMeetSessionsDetailForm';

import Input from '../../../../../../common/components/inputs/Input';
import Dropdown from '../../../../../../common/components/dropdowns/Dropdown';
import SessionDropdown from '../../../../../../common/components/dropdowns/sessionDropdown/SessionDropdown';
import PrimaryButton from '../../../../../../common/components/buttons/PrimaryButton';
import SecondaryButton from '../../../../../../common/components/buttons/SecondaryButton';
import PopUpModal from '../../../../../../common/components/dialogs/PopUpModal';

import global from '../../../../../../common/components/GlobalStyle.module.css';
import Constants from '../../../../../../common/utils/Constants';

const RequiredFieldsEditableOptionalFieldsNotShownForm = ({ formState, errorState, onFormValueChangedSetMeetSessionsState,
  onValueTextPairChangedSetMeetSessionsState, meetDatesState }) => (
  <div className='row'>
    <div className='col-xs-12 col-sm-6 col-md-4'>
      <Input
        label='Session Number*'
        name='sessionOrderId'
        type='number'
        min='1'
        value={formState.sessionOrderId}
        error={errorState.sessionOrderId}
        message={errorState.sessionOrderId}
        onChange={(value) => { onFormValueChangedSetMeetSessionsState('sessionOrderId', value); }} />
    </div>
    <div className='col-xs-12 col-sm-6 col-md-4'>
      <SessionDropdown
        label="Session Type*"
        name="sessionTypeId"
        value={formState.sessionTypeId}
        error={errorState.sessionTypeId}
        message={errorState.sessionTypeId}
        onChange={(id, name) => { onValueTextPairChangedSetMeetSessionsState(id, 'sessionTypeId', name, 'sessionTypeName') }}
      />
    </div>
    <div className='col-xs-12 col-sm-6 col-md-4'>
    <Dropdown
        label="Session Date*"
        name="sessionDateId"
        options={meetDatesState}
        value={formState.sessionDateId}
        error={errorState.sessionDateId}
        message={errorState.sessionDateId}
        isLoading={false}
        onChange={(newValue, newValueLabel, e) => {
          onValueTextPairChangedSetMeetSessionsState(
            newValue, 'sessionDateId',
            newValueLabel, 'sessionDate', e);
        }} />
    </div>
  </div>
)

const MeetSessionsDetailForm = ({ onBackToMeetSessions }) => {
  const {
    meetSessionsState,
    sessionState,
    meetDatesState,
    onFormValueChangedSetMeetSessionsState,
    onValueTextPairChangedSetMeetSessionsState,
    onSaveButtonClicked,
    onBackButtonClicked
  } = useMeetSessionsDetailForm(onBackToMeetSessions);

  return (
    <Fragment>
      <form>
        <div className={meetSessionsState?.showOptionalFields === false ? global.DisplayComponent : global.HideComponent}>
          <RequiredFieldsEditableOptionalFieldsNotShownForm
            formState={meetSessionsState?.formState}
            errorState={meetSessionsState?.errorState}
            onFormValueChangedSetMeetSessionsState={onFormValueChangedSetMeetSessionsState}
            onValueTextPairChangedSetMeetSessionsState={onValueTextPairChangedSetMeetSessionsState}
            meetDatesState={meetDatesState}
          />
        </div>
        <div className='row usas-extra-top-margin'>
          <div className='col-xs-12'>
            {meetSessionsState?.errorState?.error && <p className={[global.ErrorMessage, global.Multiline].join(' ')}>{meetSessionsState?.errorState?.error}</p>}
          </div>
        </div>
        <div className="row usas-extra-top-margin usas-extra-bottom-margin">
          <div className="col-xs-12 usas-extra-top-margin">
            <PrimaryButton type="button" onClick={onSaveButtonClicked}>Save</PrimaryButton>&nbsp;
            <SecondaryButton type="button" onClick={onBackButtonClicked}>Back</SecondaryButton>
          </div>
        </div>
      </form>
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={sessionState.isArrayLoading} />
    </Fragment >
  );
};

export default MeetSessionsDetailForm;