import React from 'react';

import EditIcon from "../../../../../common/components/icons/EditIcon";

import { CURRENCY_FORMATTER } from "../../../../../common/utils/Constants";
import { formatDate } from "../../../../../common/utils/DateFunctions";
import Constants from '../../../../../common/utils/Constants';

import global from '../../../../../common/components/GlobalStyle.module.css';

const GridRow = ({ contract, onEdit }) => (
  <tr>
    <td>{contract.satDate ? formatDate(contract.satDate) : ''}</td>
    <td>{contract.contractStatus.statusDescription === "Canceled" ? contract.contractStatus.statusDescription : contract.satResult[0]?.resultStatus.statusDescription || ''}</td>
    <td>{contract.satResult[0]?.totalAmountCollected ? CURRENCY_FORMATTER.format(contract.satResult[0]?.totalAmountCollected) : ''}</td>
    <td>{contract.satResult[0]?.satParticipant?.length > 0 ? 'Yes' : 'No'}</td>
    <td>{(contract.satResult[0]?.paymentDate && formatDate(contract.satResult[0]?.paymentDate) !== Constants.DEFAULT_MIN_DATE) ? formatDate(contract.satResult[0]?.paymentDate) : ''}</td>
    <td>
      {onEdit && contract.contractStatus.statusDescription !== "Canceled" &&
        <button className={global.IconButtonMargin} type="button" onClick={(e) => onEdit(e, contract)}>
          <EditIcon />
        </button>
      }
    </td>
  </tr>
);

const SATResultsGridLarge = ({ data, isLoading, onEdit }) => (
  <table className={[global.UsasTable, 'visible-md visible-lg'].join(' ')}>
    <thead>
      <tr>
        <th>Swim-a-Thon Date</th>
        <th>Results Status</th>
        <th>Total Amount Collected</th>
        <th>Has Prize Winners?</th>
        <th>Payment Date</th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      {isLoading === true
        ? (
          <tr>
            <td colSpan="5">Loading...</td>
          </tr>
        ) : Array.isArray(data) && data.length > 0
          ? data.map((contract, i) => (
            <GridRow
              key={i}
              contract={contract}
              onEdit={onEdit} />
          )) : (
            <tr>
              <td colSpan="5">No Results</td>
            </tr>
          )
      }
    </tbody>
  </table>
);

export default SATResultsGridLarge;