import React, { Fragment } from 'react';

import useEditLSCRoleEmail from './UseEditLSCRoleEmail';

import Headings from '../../../../../common/components/headings/Headings';
import ReadOnly from '../../../../../common/components/readOnly/ReadOnly';
import PrimaryButton from '../../../../../common/components/buttons/PrimaryButton';
import SecondaryButton from '../../../../../common/components/buttons/SecondaryButton';
import PopUpModal from '../../../../../common/components/dialogs/PopUpModal';
import Input from '../../../../../common/components/inputs/Input';
import Constants from '../../../../../common/utils/Constants';

const EditLSCRoleEmail = () => {
  const {
    isLoading,
    isSaving,
    formState,
    errorState,
    viewState,
    onFormValueChanged,
    handleSubmit,
    onCancelClicked
  } = useEditLSCRoleEmail();

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>Edit Role Email</Headings.H3>
        </div>
      </div>
      <form onSubmit={handleSubmit} noValidate>
        <div className="row">
          <div className="col-xs-12 col-sm-6 col-md-4">
            <ReadOnly
              label={"Role"}
              value={viewState.orgRoleName} />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4">
            <Input
              label={"Email*"}
              name={'email'}
              value={formState.email}
              error={errorState.email}
              message={errorState.email}
              onChange={(value) => onFormValueChanged('email', value)} />
          </div>
        </div>
        <div className="row usas-extra-top-margin">
          <div className="col-xs-12 usas-extra-top-margin usas-extra-bottom-margin">
            <PrimaryButton type="submit">Save</PrimaryButton>&nbsp;
            <SecondaryButton type="button" onClick={onCancelClicked}>Back</SecondaryButton>
          </div>
        </div>
      </form>
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={isLoading} />
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.SAVING_MSG}
        displayPopUp={isSaving} />
    </Fragment>
  );
};

export default EditLSCRoleEmail;