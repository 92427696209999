import { useEffect } from 'react';

import useLearnToSwimClubsData from '../../../state/learnToSwimClubs/UseLearnToSwimClubsData';

const useLearnToSwimClubsCombobox = () => {
  const { ltsClubsState, setLTSClubsState, getLTSClubs } = useLearnToSwimClubsData();

  useEffect(() => {
    if (ltsClubsState.isArrayLoading !== true && ltsClubsState.isArrayLoaded !== true) {
      const getLTSClubsPromise = getLTSClubs();

      if (getLTSClubsPromise !== null) {
        getLTSClubsPromise.then((newState) => {
          if (newState !== null) {
            setLTSClubsState({
              ...newState,
              items: newState.arrayData.map(x => { return { id: x.usasClubId, name: `${x.orgUnitName} (${x.parentOrgUnitCode}/${x.orgUnitCode})` }; }),
              areItemsLoaded: true
            });
          }
        }).catch((e) => {
          //TODO context error
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ltsClubsState]);

  return {
    ltsClubsState
  };
};

export default useLearnToSwimClubsCombobox;