import { BASIC_INITIAL_STATE } from '../../../common/utils/HttpHelper';

import getUsasClubStaffData from '../../../common/state/orgUnitStaff/GetOrgUnitStaffData';
import postUsasClubStaffData from '../../../common/state/orgUnitStaff/PostOrgUnitStaffData';
import putUsasClubStaffData from '../../../common/state/orgUnitStaff/PutOrgUnitStaffData';
import deleteUsasClubStaffData from '../../../common/state/orgUnitStaff/DeletePersonOrgRoleDurationData';

const INITIAL_STATE = {
  ...BASIC_INITIAL_STATE
};

const UsasClubStaffData = {
  INITIAL_STATE, getUsasClubStaffData, postUsasClubStaffData,
  putUsasClubStaffData, deleteUsasClubStaffData
};

export default UsasClubStaffData;