import { useContext } from 'react';
import { GroupCoachesStateContext } from './GroupCoachesContextProvider';

import GroupCoachesData from './GroupCoachesData';

const useGroupCoachesData = () => {
  const [groupCoachesState, setGroupCoachesState] = useContext(GroupCoachesStateContext);

  const getGroupCoachesByUsasClubId = (usasClubId) => {
    if (usasClubId !== groupCoachesState.usasClubId) {
      GroupCoachesData.getGroupCoachesByUsasClubId(usasClubId, groupCoachesState, setGroupCoachesState);
    }
  };

  const getGroupCoachesByOrgUnitId = (orgUnitId) => {
    if (orgUnitId !== groupCoachesState.orgUnitId) {
      GroupCoachesData.getGroupCoachesOrgUnitId(orgUnitId, groupCoachesState, setGroupCoachesState);
    }
  };

  const resetGroupCoachState = () => {
    setGroupCoachesState({ ...GroupCoachesData.INITIAL_STATE });
  };

  return {
    groupCoachesState,
    getGroupCoachesByUsasClubId,
    getGroupCoachesByOrgUnitId,
    resetGroupCoachState
  };
};

export default useGroupCoachesData;