import { API_NAMES, initApi } from "../../../common/utils/HttpApiHelper";

const getLTSClubsData = (state, setState) => {
  const api = initApi(API_NAMES.CLUB, state, setState);
  const url = `/UsasClub/Approved`;

  return api?.executeArray ? api.executeArray(url, 'GET') : null;
};

const LearnToSwimClubsData = {
  getLTSClubsData
};

export default LearnToSwimClubsData;