/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';

import UnlinkIcon from '../../../../../common/components/icons/UnlinkIcon';

import global from '../../../../../common/components/GlobalStyle.module.css';

const GridRow = ({ club, onDelete }) => (
  <div className={global.SmallTableRow}>
    <div className={global.SmallTableRowHead}><span className='hidden-xs'>{club.clubInfo?.name || <span>&nbsp;</span>}</span>&nbsp;
      {onDelete !== undefined &&
        <button
          className={global.IconButton}
          type="button"
          onClick={() => onDelete(club.usasClubId)}>
          <UnlinkIcon />
        </button>
      }
    </div>
    <div className={global.SmallTableRowInfo}>
      <div className='row'>
        <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3 visible-xs'].join(' ')}>Name</div>
        <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3 visible-xs'].join(' ')}>{club.clubInfo?.name || <span>&nbsp;</span>}</div>
        <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3'].join(' ')}>Code</div>
        <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3'].join(' ')}>{club.clubInfo?.code || <span>&nbsp;</span>}</div>
        <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3'].join(' ')}>Location</div>
        <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3'].join(' ')}>{club.clubInfo?.location || <span>&nbsp;</span>}</div>
        <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3'].join(' ')}>LSC</div>
        <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3'].join(' ')}>{club.clubInfo?.lsc || <span>&nbsp;</span>}</div>
      </div>
    </div>
  </div>
);


const PreCompLocationClubGridSmall = ({ data, isLoading, onDelete }) => (
  <div className={[global.SmallTable, 'hidden-md hidden-lg'].join(' ')}>
    {isLoading === true
      ? (
        <div className={global.SmallTableRow}>
          <div className={global.SmallTableRowHead}>&nbsp;</div>
          <div className={global.SmallTableRowInfo}>
            <div className={global.SmallTableRowLabels}>Loading...</div>
          </div>
        </div>
      )
      : data.length > 0
        ? data.map((club, i) => <GridRow key={i} club={club} onDelete={onDelete} />)
        : (
          <div className={global.SmallTableRow}>
            <div className={global.SmallTableRowHead}>&nbsp;</div>
            <div className={global.SmallTableRowInfo}>
              <div className={global.SmallTableRowLabels}>No Clubs</div>
            </div>
          </div>
        )}
  </div>
);

export default PreCompLocationClubGridSmall;