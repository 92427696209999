import { isValidNumber } from '../../../../../common/utils/validation';

export const localValidate = (formState) => {
  let errors = {};

  if (!isValidNumber(formState.amountRaised)) {
    errors.amountRaised = 'Total Amount Raised must be a valid number';
  } else if (formState.amountRaised < 1) {
    errors.amountRaised = 'Total Amount Raised must be a valid amount';
  }

  if (!(formState.satPrizeId > 0)) {
    errors.satPrizeId = 'Prize is required';
  }

  if (formState.participantName.trim().length === 0) {
    errors.participantName = 'Participant Name is required';
  }

  return errors;
};

const SATParticipantFormValidation = async (formState) => {
  let errors = localValidate(formState);

  return errors ? errors : {};
};

export default SATParticipantFormValidation;