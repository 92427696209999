export const localValidate = (formState) => {
  let errors = {};

  if (formState.dropdownId < 0) {
    errors.dropdownId = 'Must select a valid option';
  }

  return errors;
};

const DropdownViewValidation = async (formState) => {
  let errors = localValidate(formState);

  return errors ? errors : {};
};

export default DropdownViewValidation;