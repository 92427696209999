import React, { Fragment } from 'react';

import DeleteIcon from '../../../../common/components/icons/DeleteIcon';
import { formatDate } from '../../../../common/utils/DateFunctions';

import global from '../../../../common/components/GlobalStyle.module.css';

const LargeClubActivateVendorGrid = ({ state, onOpenDeleteVendorModalClicked }) => (
  <Fragment>
    <table className={[global.UsasTable, 'visible-md visible-lg'].join(' ')}>
      <thead>
        <tr>
          <th>Vendor Name</th>
          <th>Date Added</th>
          <th>Date Expired</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {state.isArrayLoaded !== true
          ? (
            <tr>
              {state.isArrayLoading === true
                ? <td colSpan="4">Loading...</td>
                : <td colSpan="4">There are no vendors associated with this club</td>}
            </tr>)
          : Array.isArray(state.arrayData) === true && state.arrayData.length > 0
            ? state.arrayData.map((vendor, i) => (
              <tr key={i}>
                <td>{vendor?.vendor?.vendorName}</td>
                <td>{formatDate(vendor.effectiveDate)}</td>
                <td>{vendor?.expirationDate === null ? ' ' : formatDate(vendor?.expirationDate)}</td>
                <td>
                  {vendor?.expirationDate === null
                    ? <button
                      className={global.IconButtonMargin}
                      type="button"
                      onClick={(e) => onOpenDeleteVendorModalClicked(e, vendor)}> <DeleteIcon />
                    </button>
                    : <Fragment />
                  }
                </td>
              </tr>))
            : (
              <tr>
                <td colSpan="4">There are no vendors associated with this club</td>
              </tr>
            )}
      </tbody>
    </table>
  </Fragment>
);

export default LargeClubActivateVendorGrid;