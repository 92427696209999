import { BASIC_INITIAL_STATE } from '../../utils/HttpHelper';
import Constants from '../../utils/Constants';

import getGroupCoachesByUsasClubId from './GetGroupCoachesByUsasClubIdData';
import getGroupCoachesOrgUnitId from './GetGroupCoachesByOrgUnitIdData';

const INITIAL_STATE = {
  ...BASIC_INITIAL_STATE,
  usasClubId: Constants.DEFAULT_ID,
  orgUnitId: '',
  options: [],
  dataAsOptionsWithNames: []
};

const GroupCoachesData = {
  INITIAL_STATE,
  getGroupCoachesByUsasClubId,
  getGroupCoachesOrgUnitId
};

export default GroupCoachesData;