import { Fragment } from 'react';

import UseClubPerformanceOrgUnitSelection from './UseClubPerformanceOrgUnitSelection';

import SelectOrgUnit from '../../../../common/components/selectOrgUnit/SelectOrgUnit';
import LoadingModal from '../../../../common/components/dialogs/LoadingModal';

const ClubPerformanceOrgUnitSelection = () => {
  const {
    isLoading,
    USAS_COACH_ROLES_ROLE_GROUP_ID,
    NAV_LINK,
    TAXONOMIES,
    SCOPE
  } = UseClubPerformanceOrgUnitSelection();

  return (
    <Fragment>
      {USAS_COACH_ROLES_ROLE_GROUP_ID && NAV_LINK &&
        <SelectOrgUnit
          pageTitle={'Club Performance Selection'}
          roleGroupId={USAS_COACH_ROLES_ROLE_GROUP_ID}
          getFunction={() => {}}
          navLink={NAV_LINK}
          TAXONOMIES={TAXONOMIES}
          SCOPE={SCOPE} />
      }
      {isLoading === true && <LoadingModal />}
    </Fragment>
  );
};

export default ClubPerformanceOrgUnitSelection;