import { useEffect, useState } from 'react';
import { useNavigate } from '../../../../common/wrappers/ReactRouterDom';

import { navLinks } from '../UseMemberAdministration';

import useMemberAdministrationLscSecurityRolesData from '../../../../common/state/memberAdministration/lscSecurityRoles/UseMemberAdministrationLscSecurityRolesData';
import useSelectOrgUnitData from '../../../../common/state/selectOrgUnit/UseSelectOrgUnitData';

import Constants from '../../../../common/utils/Constants';
import { formatDate } from '../../../../common/utils/DateFunctions';

const useLSCSecurityRoles = () => {
  const navigate = useNavigate();
  const { memberAdministrationLscSecurityRolesState, getLscSecurityMembers, deleteLscSecurityMember, confirmSave
  } = useMemberAdministrationLscSecurityRolesData();
  const { selectOrgUnitState } = useSelectOrgUnitData();
  const [viewState, setViewState] = useState(getInitialViewState);
  const [gridState, setGridState] = useState([]);

  const onAddSecurityMember = () => {
    navigate(navLinks.LSC_SECURITY_ROLES_ADD, { state: { activeUserOrgUnitId: selectOrgUnitState.orgUnitId } });
  };

  const onEditSecurityMember = (personId, personOrgRoleDurationId) => {
    navigate(navLinks.LSC_SECURITY_ROLES_EDIT, { state: { personId, personOrgRoleDurationId, activeUserOrgUnitId: selectOrgUnitState.orgUnitId } });
  };

  const onDeleteSecurityMember = (e, personOrgRoleDurationId) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    deleteLscSecurityMember(personOrgRoleDurationId);
  };

  const onFilterCurrentMembers = () => {
    if (memberAdministrationLscSecurityRolesState.isArrayLoading === false) {
      setViewState({
        ...viewState,
        toggleCurrentStaffRoles: !viewState.toggleCurrentStaffRoles
      });
      setGridState(filterSecurityRolesForGrid(memberAdministrationLscSecurityRolesState.arrayData, !viewState.toggleCurrentStaffRoles));
    }
  };

  useEffect(() => {
    if (selectOrgUnitState.orgUnitId) {
      if (memberAdministrationLscSecurityRolesState.isSaved === true) {
        confirmSave();
      } else {
        getLscSecurityMembers(selectOrgUnitState.orgUnitId);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectOrgUnitState.orgUnitId, memberAdministrationLscSecurityRolesState.isSaved]);

  useEffect(() => {
    if (selectOrgUnitState.orgUnitId && memberAdministrationLscSecurityRolesState.isArrayLoading === false
      && memberAdministrationLscSecurityRolesState.isArrayLoaded === true) {
      setGridState(filterSecurityRolesForGrid(memberAdministrationLscSecurityRolesState.arrayData, viewState.toggleCurrentStaffRoles));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectOrgUnitState.orgUnitId, memberAdministrationLscSecurityRolesState]);

  function filterSecurityRolesForGrid(arrayData, toggleCurrentStaffRoles = false) {
    const today = new Date();
    const gridData = [];

    for (const staff of arrayData) {
      const roleDurations = [];

      if (Array.isArray(staff.orgRoles) && staff.orgRoles.length > 0) {
        if (toggleCurrentStaffRoles === true) {
          for (const duration of staff.orgRoles) {
            if (duration.isCurrent === true) {
              roleDurations.push({
                personOrgRoleDurationId: duration.personOrgRoleDurationId || Constants.DEFAULT_ID,
                personOrgRoleId: duration.personOrgRoleId || '',
                effectiveDate: duration.effectiveDate ? formatDate(duration.effectiveDate) : '',
                expirationDate: duration.expirationDate ? formatDate(duration.expirationDate) : '',
                isCurrent: duration.isCurrent === true ? true : false,
                canDelete: new Date(duration.expirationDate) > today ? true : false,
                orgRoleId: duration.orgRoleId || '',
                orgRoleName: duration.orgRoleName || ''
              });
            }
          }
        } else {
          for (const duration of staff.orgRoles) {
            roleDurations.push({
              personOrgRoleDurationId: duration.personOrgRoleDurationId || Constants.DEFAULT_ID,
              personOrgRoleId: duration.personOrgRoleId || Constants.DEFAULT_ID,
              effectiveDate: duration.effectiveDate ? formatDate(duration.effectiveDate) : '',
              expirationDate: duration.expirationDate ? formatDate(duration.expirationDate) : '',
              isCurrent: duration.isCurrent === true ? true : false,
              canDelete: new Date(duration.expirationDate) > today ? true : false,
              orgRoleId: duration.orgRoleId || '',
              orgRoleName: duration.orgRoleName || ''
            });
          }
        }
      }

      if (roleDurations.length > 0) {
        roleDurations.sort((a, b) => new Date(b.expirationDate) - new Date(a.expirationDate));

        gridData.push({
          personId: staff.personId || '',
          name: `${staff.firstName || ''} ${staff.lastName || ''}`,
          email: staff.email || '',
          roleDurations
        });
      }
    }

    return gridData;
  };

  function getInitialViewState() {
    return {
      toggleCurrentStaffRoles: true
    };
  };

  return {
    isSaving: memberAdministrationLscSecurityRolesState.isSaving,
    isGridLoading: memberAdministrationLscSecurityRolesState.isArrayLoading,
    viewState,
    gridState,
    memberAdministrationLscSecurityRolesState,
    onFilterCurrentMembers,
    onAddSecurityMember,
    onEditSecurityMember,
    onDeleteSecurityMember
  };
};

export default useLSCSecurityRoles;