import useSecurityData from "../../../../../common/state/security/UseSecurityData";

const useLoginPopup = () => {
  const { securityState, login } = useSecurityData();
  const isLoggedIn = securityState?.userInfo?.isLoggedIn === true;

  const onLoginClicked = (e) => {
    e?.preventDefault();

    login();
  };

  return {
    isLoggedIn,
    onLoginClicked
  };
};

export default useLoginPopup;