import React, { Fragment } from 'react';

import EditIcon from '../../../../common/components/icons/EditIcon';
import HideIcon from '../../../../common/components/icons/HideIcon';
import ShowIcon from '../../../../common/components/icons/ShowIcon';

import { formatDate } from '../../../../common/utils/DateFunctions';

import global from '../../../../common/components/GlobalStyle.module.css';
import Checkbox from '../../../../common/components/checkboxes/Checkbox';

const DetailTableRow = ({ role }) => (
  <div className={global.SmallTableRow}>
    <div className={global.SmallTableDetailRowHead}>
      <span className='hidden-xs'>{role.orgRoleName || <span>&nbsp;</span>}</span>&nbsp;
    </div>
    <div className={global.SmallTableRowInfo}>
      <div className='row'>
        <div className={[global.SmallTableRowLabels, 'col-xs-7 visible-xs'].join(' ')}>Member Type</div>
        <div className={[global.SmallTableRowData, 'col-xs-5 visible-xs'].join(' ')}>{role.orgRoleName || <span>&nbsp;</span>}</div>
        <div className={[global.SmallTableRowLabels, 'col-xs-7'].join(' ')}>Registration Exp. Date</div>
        <div className={[global.SmallTableRowData, 'col-xs-5'].join(' ')}>{role.registrationExpirationDate ? formatDate(role.registrationExpirationDate) :
          <span>&nbsp;</span>}</div>
        <div className={[global.SmallTableRowLabels, 'col-xs-7'].join(' ')}>Good Standing Exp. Date</div>
        <div className={[global.SmallTableRowData, 'col-xs-5'].join(' ')}>{role.isInGoodStandingExpirationDate ? formatDate(role.isInGoodStandingExpirationDate) :
          <span>&nbsp;</span>}</div>
      </div>
    </div>
  </div>
);

const DetailTable = ({ roles }) => (
  <div className={global.SmallTableDetailTable}>
    {Array.isArray(roles) && roles.map((role, i) =>
      <DetailTableRow key={i} role={role} />)}
  </div>
);

const SmallGridRow = ({ nonAthlete, onEditNonAthlete, expandedId, onClick, onVendorSyncCheckbox }) => (
  <Fragment>
    <div className={global.SmallTableRow}>
      <div className={global.SmallTableRowHead}><span className='hidden-xs'>
        {(nonAthlete.firstName || nonAthlete.middleName || nonAthlete.lastName)
          ? `${nonAthlete.preferredName ? nonAthlete.preferredName : nonAthlete.firstName || ''} ${nonAthlete.lastName || ''}`
          : ''
        }</span>&nbsp;
        <>
          <button className={global.IconButton}
            type="button"
            onClick={(e) => onClick(e, nonAthlete.personId)}>
            {expandedId === nonAthlete.personId ? <HideIcon /> : <ShowIcon />}
          </button>
          {onEditNonAthlete &&
            <button
              className={global.IconButtonMobileMargin}
              type="button"
              onClick={() => onEditNonAthlete(nonAthlete.personId)}>
              <EditIcon />
            </button>
          }
          <Checkbox
            label="Sync"
            name="syncMember"
            onChange={(e) => { onVendorSyncCheckbox(e, nonAthlete.memberId) }}
            checked={nonAthlete.syncMember}
          />
        </>
      </div>
      <div className={global.SmallTableRowInfo}>
        <div className='row'>
          <div className={[global.SmallTableRowLabels, 'col-xs-6 visible-xs'].join(' ')}>Name</div>
          <div className={[global.SmallTableRowData, 'col-xs-6 visible-xs'].join(' ')}>
            {(nonAthlete.firstName || nonAthlete.middleName || nonAthlete.lastName)
              ? `${nonAthlete.preferredName ? nonAthlete.preferredName : nonAthlete.firstName || ''} ${nonAthlete.lastName || ''}`
              : <span>&nbsp;</span>
            }</div>
          <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3'].join(' ')}>Birth Date</div>
          <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3'].join(' ')}>{nonAthlete.birthDate ? formatDate(nonAthlete.birthDate) : <span>&nbsp;</span>}</div>
          <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3'].join(' ')}>Member Type(s)</div>
          <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3'].join(' ')}>{nonAthlete.orgRoleName || <span>&nbsp;</span>}</div>
        </div>
      </div>
    </div>
    {Array.isArray(nonAthlete.goodStandingOrgRole) && nonAthlete.goodStandingOrgRole.length > 0 &&
      expandedId === nonAthlete.personId &&
      <DetailTable roles={nonAthlete.goodStandingOrgRole} />}
  </Fragment>
);

const ClubNonAthleteRosterGridSmall = ({ gridData, isLoading, onEditNonAthlete, expandedId, onClick, onVendorSyncCheckbox }) => (
  <div className={[global.SmallTable, 'hidden-md hidden-lg'].join(' ')}>
    {isLoading === true
      ? (
        <div className={global.SmallTableRow}>
          <div className={global.SmallTableRowHead}>&nbsp;</div>
          <div className={global.SmallTableRowInfo}>
            <div className={global.SmallTableRowLabels}>Loading...</div>
          </div>
        </div>
      )
      : Array.isArray(gridData) && gridData.length > 0
        ? gridData.map((nonAthlete, i) => 
          <SmallGridRow 
            key={i} 
            nonAthlete={nonAthlete} 
            onEditNonAthlete={onEditNonAthlete}
            onVendorSyncCheckbox={onVendorSyncCheckbox}
          expandedId={expandedId} onClick={onClick} />)
        : (
          <div className={global.SmallTableRow}>
            <div className={global.SmallTableRowHead}>&nbsp;</div>
            <div className={global.SmallTableRowInfo}>
              <div className={global.SmallTableRowLabels}>No Non Athletes</div>
            </div>
          </div>
        )
    }
  </div>
);

export default ClubNonAthleteRosterGridSmall;