import { useContext, useEffect } from 'react';

import { TouchpointTypeStateContext } from './TouchpointTypeContextProvider';

import TouchpointTypeData from './TouchpointTypeData';

const useTouchpointTypeData = () => {
  const [touchpointTypeState, setTouchpointTypeState] = useContext(TouchpointTypeStateContext);

  useEffect(() => {
    if (touchpointTypeState.isLoading !== true && touchpointTypeState.isLoaded !== true) {
      TouchpointTypeData.getTouchpointTypes(touchpointTypeState, setTouchpointTypeState);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    touchpointTypeState
  };
};

export default useTouchpointTypeData;