import React, { Fragment } from 'react';

import useClubLegalEntities from './UseClubLegalEntities';

import ClubLegalEntitiesWriteGrid from './ClubLegalEntitiesWriteGrid';
import Headings from '../../../../common/components/headings/Headings';
import ActionIntraPageButton from '../../../../common/components/buttons/ActionIntraPageButton';
import PrimaryButton from '../../../../common/components/buttons/PrimaryButton';
import SecondaryButton from '../../../../common/components/buttons/SecondaryButton';
import PopUpModal from '../../../../common/components/dialogs/PopUpModal';
import Constants from '../../../../common/utils/Constants';

const ClubLegalEntitiesWrite = () => {
  const {
    baseUsasClubState,
    modalState,
    onOpenModalClicked,
    onAddLegalEntity,
    onEditLegalEntity,
    onModalCanceled,
    onDeleteLegalEntityFromClub
  } = useClubLegalEntities();

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>Club Legal Entities</Headings.H3>
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12">
          <p>Please provide the legal entities associated with the club.
            The legal entity name(s) should be the legal name(s) used on tax documents.
            The legal entity tax Id(s) must be in ITIN (XXX-XX-XXXX) or EIN (XX-XXXXXX) format. </p>
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12 usas-extra-top-margin">
          <ActionIntraPageButton onClick={onAddLegalEntity}>Add Legal Entity</ActionIntraPageButton>
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12 usas-extra-top-margin usas-extra-bottom-margin">
          <ClubLegalEntitiesWriteGrid state={baseUsasClubState} onEdit={onEditLegalEntity} onDelete={onOpenModalClicked} />
        </div>
      </div>
      <PopUpModal
        widthPct={90}
        maxWidth={720}
        title={modalState.modalTitle}
        displayPopUp={modalState.displayPopUp}
        onModalCanceled={onModalCanceled}>
        <div className="row">
          <div className="col-xs-12">
            <p><b>Legal Entity Name:</b> {modalState.legalEntityName}</p>
            <p><b>Legal Entity Tax Id:</b> {modalState.legalEntityTaxId}</p>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12 usas-extra-top-margin">
            <PrimaryButton type="button" onClick={onDeleteLegalEntityFromClub}>Delete</PrimaryButton>&nbsp;
            <SecondaryButton type="button" onClick={onModalCanceled}>Cancel</SecondaryButton>
          </div>
        </div>
      </PopUpModal>
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={baseUsasClubState.isObjLoading} />
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.SAVING_MSG}
        displayPopUp={baseUsasClubState.isSaving} />
    </Fragment>
  );
};

export default ClubLegalEntitiesWrite;