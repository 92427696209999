import React from 'react';

import UnlinkIcon from '../../../../common/components/icons/UnlinkIcon';

import global from '../../../../common/components/GlobalStyle.module.css';

const GridRow = ({ preCompLocation, onDelete }) => (
  <tr>
    <td>{preCompLocation.preCompLocationName}</td>
    <td>{preCompLocation.preCompLocationAddress}</td>
    <td><button className={global.IconButtonMargin} type="button" onClick={(e) => onDelete(e, preCompLocation.preCompLocationClubId)}>
      <UnlinkIcon />
    </button>
    </td>
  </tr>
);

const ClubPreCompGridLargeWrite = ({ data, isLoading, onDelete }) => (
  <table className={[global.UsasTable, 'visible-md visible-lg'].join(' ')}>
    <thead>
      <tr>
        <th>Pre-Competitive Location Name</th>
        <th>Address</th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      {isLoading === true
        ? <tr><td colSpan="3">Loading...</td></tr>
        : data.length > 0
          ? data.map((preCompetitiveLocation, i) => <GridRow key={i} preCompLocation={preCompetitiveLocation} onDelete={onDelete} />)
          : <tr><td colSpan="3">No Pre-Competitive Programs</td></tr>
      }
    </tbody>
  </table>
);

export default ClubPreCompGridLargeWrite;