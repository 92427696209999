import React, { Fragment } from 'react';

import useClubFacilities from './UseClubFacilities';

import useExpandedRow from '../../../../common/components/grids/UseExpandedRow';

import LargeClubFacilitiesWriteGrid from './LargeClubFacilitiesWriteGrid';
import SmallClubFacilitiesWriteGrid from './SmallClubFacilitiesWriteGrid';

const ClubFacilitiesWriteGrid = ({ onDelete, onDeletePool }) => {
  const { usasClubFacilityLinksState, onEditFacility, onAddPool, onEditPool } = useClubFacilities();
  const { expandedId, onClick } = useExpandedRow();
  return (
    <Fragment>
      <LargeClubFacilitiesWriteGrid
        state={usasClubFacilityLinksState}
        onEdit={onEditFacility}
        onDelete={onDelete}
        onAddPool={onAddPool}
        onEditPool={onEditPool}
        onDeletePool={onDeletePool}
        expandedId={expandedId}
        onClick={onClick} />
      <SmallClubFacilitiesWriteGrid
        state={usasClubFacilityLinksState}
        onEdit={onEditFacility}
        onDelete={onDelete}
        onAddPool={onAddPool}
        onEditPool={onEditPool}
        onDeletePool={onDeletePool}
        expandedId={expandedId}
        onClick={onClick} />
    </Fragment>
  )
};

export default ClubFacilitiesWriteGrid;