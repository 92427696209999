import React from 'react';

import DeleteIcon from '../../../../../common/components/icons/DeleteIcon';
import EditIcon from '../../../../../common/components/icons/EditIcon';

import { CURRENCY_FORMATTER } from '../../../../../common/utils/Constants';

import global from '../../../../../common/components/GlobalStyle.module.css';

const GridRow = ({ participant, onEdit, onDelete }) => (
  <div className={global.SmallTableRow}>
    <div className={global.SmallTableRowHead}><span className='hidden-xs'>{participant.participantName || ''}</span>&nbsp;
      {onDelete &&
        <button className={global.IconButton} type="button" onClick={(e) => onDelete(e, participant.satParticipantId)}>
          <DeleteIcon />
        </button>
      }
      {onEdit &&
        <button className={global.IconButtonMobileMargin} type="button" onClick={(e) => onEdit(e, participant.satParticipantId)}>
          <EditIcon />
        </button>
      }
    </div>
    <div className={global.SmallTableRowInfo}>
      <div className='row'>
        <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3 visible-xs'].join(' ')}>Participant Name</div>
        <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3 visible-xs'].join(' ')}>{participant.participantName || <span>&nbsp;</span>}</div>
        <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3'].join(' ')}>Amount Raised</div>
        <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3'].join(' ')}>{participant.amountRaised ? CURRENCY_FORMATTER.format(participant.amountRaised) : <span>&nbsp;</span>}</div>
        <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3'].join(' ')}>Award Achieved</div>
        <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3'].join(' ')}>{participant.satPrize?.description || <span>&nbsp;</span>}</div>
      </div>
    </div>
  </div>
);

const SATParticipantsGridSmall = ({ data, isLoading, onEdit, onDelete }) => (
  <div className={[global.SmallTable, 'hidden-md hidden-lg'].join(' ')}>
    {isLoading === true
      ? (
        <div className={global.SmallTableRow}>
          <div className={global.SmallTableRowHead}>
            &nbsp;
          </div>
          <div className={global.SmallTableRowInfo}>
            <div className={global.SmallTableRowLabels}>Loading...</div>
          </div>
        </div>
      ) : Array.isArray(data) && data.length > 0
        ? data.map((participant, i) => (
          <GridRow
            key={i}
            participant={participant}
            onEdit={onEdit}
            onDelete={onDelete} />
        )) : (
          <div className={global.SmallTableRow}>
            <div className={global.SmallTableRowHead}>
              &nbsp;
            </div>
            <div className={global.SmallTableRowInfo}>
              <div className={global.SmallTableRowLabels}>No Participants</div>
            </div>
          </div>
        )
    }
  </div>
);

export default SATParticipantsGridSmall;