//import { isValidDate, isValidBirthDate } from '../../../../common/utils/validation';

export const localValidate = (formState) => {
  let errors = {};
  //Pre Employment Screening
  // if (formState.preEmploymentScreeningRequired === true) {
  //   if (formState.preEmploymentScreeningRequiredSignature.trim() === '') {
  //     errors.preEmploymentScreeningRequiredSignature = 'Signature is required'
  //   }
  // }

  //Racing Start Certified
  // if (formState.racingStartCertified === true) {
  //   if (formState.racingStartCertifiedSignature.trim() === '') {
  //     errors.racingStartCertifiedSignature = 'Signature is required'
  //   }
  // }

  //State Concussion Laws
  // if (formState.stateConcussionsLaws === true) {
  //   if (formState.stateConcussionsLawsSignature.trim() === '') {
  //     errors.stateConcussionsLawsSignature = 'Signature is required'
  //   }
  // }

  //Minor Athlete Abuse Policy
  // if (formState.minorAthleteAbusePreventionPolicy === true) {
  //   if (formState.minorAthleteAbusePreventionPolicySignature.trim() === '') {
  //     errors.minorAthleteAbusePreventionPolicySignature = 'Signature is required'
  //   }
  // }

  //CLBMS 101
  // if (formState.clbmS101 === true) {
  //   if (formState.clbmS101signature.trim() === '' && formState.clubRegistrations?.length < 2) {
  //     errors.clbmS101signature = 'Signature is required'
  //   }
  //   if (formState.clubRegistrationsLength < 2) {
  //     if (!isValidDate(formState.clbmS101CompletionDate)) {
  //       errors.clbmS101CompletionDate = 'Completion Date is required'
  //     } else if (!isValidBirthDate(formState.clbmS101CompletionDate)) {
  //       errors.clbmS101CompletionDate = 'Completion Date cannot be in the future'
  //     }
  //   }
  // }

  //CLBMS 201 (renewing club only)
  // if (formState.isNew === false) {
  //   if (formState.clbmS201 === true) {
  //     if (formState.clbmS201signature.trim() === '' && formState.clubRegistrations?.length === 2) {
  //       errors.clbmS201signature = 'Signature is required'
  //     }
  //     if (formState.clubRegistrationsLength === 2) {
  //       if (!isValidDate(formState.clbmS201CompletionDate)) {
  //         errors.clbmS201CompletionDate = 'Completion Date is required'
  //       } else if (!isValidBirthDate(formState.clbmS201CompletionDate)) {
  //         errors.clbmS201CompletionDate = 'Completion Date cannot be in the future'
  //       }
  //     }
  //   }
  // }

  //New club
  if (formState.isNew === true) {
    if (formState.preEmploymentScreeningRequired === false || formState.racingStartCertified === false ||
      formState.stateConcussionsLaws === false || formState.minorAthleteAbusePreventionPolicy === false) {
      errors.general = 'Must select yes for all screening & policies'
    }
  }

  if (formState.acknowledgementsSignature.trim() === '') {
    errors.acknowledgementsSignature = 'Signature is required'
  }

  //Renewing club
  // if (formState.isNew === false) {
  //   if (formState.preEmploymentScreeningRequired === false || formState.racingStartCertified === false ||
  //     formState.stateConcussionsLaws === false || formState.minorAthleteAbusePreventionPolicy === false) {
  //     errors.general = 'Must select yes for all screening & policies'
  //   }
  // }

  return errors;
};

const ClubRegistrationScreeningPoliciesValidation = async (formState) => {
  let errors = localValidate(formState);

  return errors ? errors : {};
}

export default ClubRegistrationScreeningPoliciesValidation;