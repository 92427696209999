import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from '../../../../common/wrappers/ReactRouterDom';

import NavLinks from '../NavLinks';

import { createLocObjFromLocProgramOfferingForm } from '../../preComp/utils/PreCompUtils';

import validate from '../../preComp/components/forms/PreCompLocationProgramOfferingFormValidation';

import usePreCompLocationRegistrationData from '../../../state/preCompLocationRegistration/UsePreCompLocationRegistrationData';
import usePreCompLocationRegistrationNavData from '../../../state/preCompLocationRegistration/preCompLocationRegistrationLeftNavBar/UsePreCompLocationRegistrationNavData';

import useForm from '../../../../common/utils/UseForm';

const usePreCompRegistrationLocationProgramsDetail = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { preCompLocationRegistrationState, confirmSave, saveObjData } = usePreCompLocationRegistrationData();
  const { locationRegistrationNavState } = usePreCompLocationRegistrationNavData();
  const { formState, errorState, handleSubmit, resetForm, updateFormState, onValueTextPairChanged, setFormData
  } = useForm(getInitialFormState, submitFormCallback, validate);
  const [displayDupModalState, setDisplayDupModalState] = useState(false);

  const onDupModalCancelClicked = () => {
    setDisplayDupModalState(false);
  };

  const onBackClicked = () => {
    navigate(NavLinks.PRE_COMP_REGISTRATION_LOCATION_PROGRAMS);
  };

  useEffect(() => {
    if (location.state?.preCompLocationOfferingId && preCompLocationRegistrationState.isObjLoaded === true) {
      const targetOffering = preCompLocationRegistrationState.objData.preCompLocationOffering.find(x => x.preCompLocationOfferingId === location.state.preCompLocationOfferingId);

      if (targetOffering) {
        setFormData({
          ...formState,
          preCompLocationOfferingId: targetOffering.preCompLocationOfferingId || '',
          preCompLocationOfferingName: targetOffering.preCompOfferingType?.typeName || '',
          scholarshipCount: targetOffering.scholarshipCount ?? '',
          enrollmentCount: targetOffering.enrollmentCount ?? ''
        });
      }
    } else if (preCompLocationRegistrationState.isObjLoaded === false) {
      navigate(NavLinks.PRE_COMP_REGISTRATION_ENTRY);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [preCompLocationRegistrationState.isObjLoaded]);

  useEffect(() => {
    if (preCompLocationRegistrationState.isSaved === true) {
      confirmSave();

      navigate(NavLinks.PRE_COMP_REGISTRATION_LOCATION_PROGRAMS);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [preCompLocationRegistrationState.isSaved]);

  function submitFormCallback(formState) {
    if (!formState.preCompLocationOfferingId) { // on add
      const existingProgramOffering = preCompLocationRegistrationState.objData.preCompLocationOffering.find(x => x.preCompOfferingTypeId === formState.programOffering[0].id);

      if (existingProgramOffering) {
        setDisplayDupModalState(true);
      } else {
        saveObjData(createLocObjFromLocProgramOfferingForm(preCompLocationRegistrationState.objData, formState));
      }
    } else {
      saveObjData(createLocObjFromLocProgramOfferingForm(preCompLocationRegistrationState.objData, formState));
    }
  };

  function getInitialFormState() {
    return {
      programOffering: [],
      preCompLocationOfferingId: '', // only used on edit
      preCompLocationOfferingName: '', // only used on edit
      scholarshipCount: '',
      enrollmentCount: '',
    };
  };

  return {
    formState,
    errorState,
    displayDupModalState,
    locationRegistrationNavState,
    isFirstProgram: preCompLocationRegistrationState.objData?.preCompLocationOffering?.length === 0,
    handleSubmit,
    resetForm,
    onFormValueChanged: updateFormState,
    onValueTextPairChanged,
    onBackClicked,
    onDupModalCancelClicked
  };
};

export default usePreCompRegistrationLocationProgramsDetail;