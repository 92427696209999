const NavLinks = {
  PRE_COMP_REGISTRATION_ROOT: '/precompetitiveregistration',
  PRE_COMP_REGISTRATION_WELCOME: '/precompetitiveregistration/welcome',
  PRE_COMP_REGISTRATION_ENTRY: '/precompetitiveregistration/entry',
  PRE_COMP_REGISTRATION_LOCATION_INFO: '/precompetitiveregistration/location/info',
  PRE_COMP_REGISTRATION_LOCATION_PROGRAMS: '/precompetitiveregistration/location/programs',
  PRE_COMP_REGISTRATION_LOCATION_PROGRAMS_DETAIL: '/precompetitiveregistration/location/programs/detail',
  PRE_COMP_REGISTRATION_LOCATION_CLUB: '/precompetitiveregistration/location/clubs',
  PRE_COMP_REGISTRATION_LOCATION_CLUB_SEARCH: '/precompetitiveregistration/location/clubs/search',
  PRE_COMP_REGISTRATION_LOCATION_STAFF: '/precompetitiveregistration/location/staff',
  PRE_COMP_REGISTRATION_LOCATION_STAFF_DETAIL: '/precompetitiveregistration/location/staff/detail',
  PRE_COMP_REGISTRATION_LOCATION_FACILITY: '/precompetitiveregistration/location/facilities',
  PRE_COMP_REGISTRATION_LOCATION_FACILITY_SEARCH: '/precompetitiveregistration/location/facilities/search',
  PRE_COMP_REGISTRATION_LOCATION_FACILITY_ADD: '/precompetitiveregistration/location/facilities/add',
  PRE_COMP_REGISTRATION_LOCATION_FACILITY_DETAIL: '/precompetitiveregistration/location/facilities/detail',
  PRE_COMP_REGISTRATION_LOCATION_FACILITY_POOL_DETAIL: '/precompetitiveregistration/location/facilities/pools/detail',
  PRE_COMP_REGISTRATION_LOCATION_INSTRUCTOR_TRAINING: '/precompetitiveregistration/location/instructortraining',
  PRE_COMP_REGISTRATION_LOCATION_EMERGENCY_PLAN: '/precompetitiveregistration/location/emergencyplans',
  PRE_COMP_REGISTRATION_ORGANIZATION_INFO: '/precompetitiveregistration/organization/info',
  PRE_COMP_REGISTRATION_ORGANIZATION_INSURANCE: '/precompetitiveregistration/organization/insurance',
  PRE_COMP_REGISTRATION_ORGANIZATION_CONTACT: '/precompetitiveregistration/organization/contacts',
  PRE_COMP_REGISTRATION_ORGANIZATION_CONTACT_DETAIL: '/precompetitiveregistration/organization/contacts/detail',
  PRE_COMP_REGISTRATION_SUBMIT: '/precompetitiveregistration/submit'
};

export default NavLinks;