import React, { Fragment } from 'react';

const ClubGroupAttributesPracticeType = ({ state, clubGroupState }) => {
  return (
    <Fragment>
      <div className="col-xs-6 col-md-3"><p><b>Date Range:</b></p></div>
      <div className="col-xs-6 col-md-3"><p>{state.dateRange}</p></div>
      <div className="col-xs-6 col-md-3"><p><b>Facility:</b></p></div>
      <div className="col-xs-6 col-md-3"><p>{state.facilityName}</p></div>
      <div className="col-xs-6 col-md-3"><p><b>Number of Members:</b></p></div>
      <div className="col-xs-6 col-md-3"><p>{clubGroupState.objData?.personOrgGroup?.length}</p></div>
      <div className="col-xs-6 col-md-3"><p><b>Coach(es) of Group:</b></p></div>
      <div className="col-xs-6 col-md-3"><p>{state.coachName}</p></div>
    </Fragment>
  );
};

export default ClubGroupAttributesPracticeType;