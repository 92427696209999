import React, { createContext, useState } from 'react';

import SearchClubData from './SearchClubData';

export const SearchClubStateContext = createContext();

const SearchClubContextProvider = ({ children }) => {
  const stateHook = useState(SearchClubData.INITIAL_STATE);

  return (
    <SearchClubStateContext.Provider value={stateHook}>
      {children}
    </SearchClubStateContext.Provider>
  );
};

export default SearchClubContextProvider;