import { BASIC_INITIAL_STATE } from '../../../common/utils/HttpHelper';

import getUsasClub from './GetUsasClubRegistrationData';
import postUsasClub from './PostUsasClubRegistrationData';
import putUsasClub from './PutUsasClubRegistrationData';
import putUsasClubAndHelperObj from './PutUsasClubRegistrationAndHelperObjData';

const INITIAL_STATE = {
  ...BASIC_INITIAL_STATE,
  helperObj: {}
};

const ClubRegistrationNewData = {
  INITIAL_STATE,
  getUsasClub,
  postUsasClub,
  putUsasClub,
  putUsasClubAndHelperObj
};

export default ClubRegistrationNewData;