import React from 'react';

import useProviderSearchForm from './UseProviderSearchForm';

import Input from '../../../../../common/components/inputs/Input';
import PrimaryButton from '../../../../../common/components/buttons/PrimaryButton';
import SecondaryButton from '../../../../../common/components/buttons/SecondaryButton';

import global from '../../../../../common/components/GlobalStyle.module.css';

const ProviderSearchForm = ({ searchFilterObject, onSubmitFormCallback }) => {
  const {
    formState,
    errorState,
    updateFormState,
    handleSubmit,
    onClearFormClicked
  } = useProviderSearchForm(onSubmitFormCallback, searchFilterObject);

  return (
    <form onSubmit={handleSubmit} noValidate>
      <div className="row">
        <div className="col-xs-12 col-sm-6 col-md-4">
          <Input
            label='Business Legal Name*'
            name="entityName"
            value={formState.entityName}
            error={errorState.entityName}
            message={errorState.entityName}
            onChange={(value) => { updateFormState('entityName', value) }} />
        </div>
        <div className="col-xs-12 col-sm-6 col-md-4">
          <Input
            label='Trade Name/DBA*'
            name="tradeName"
            value={formState.tradeName}
            error={errorState.tradeName}
            message={errorState.tradeName}
            onChange={(value) => { updateFormState('tradeName', value) }} />
        </div>
        <div className="col-xs-12 col-sm-6 col-md-4">
          <Input
            label='Tax Id*'
            name="taxId"
            value={formState.taxId}
            error={errorState.taxId}
            message={errorState.taxId}
            onChange={(value) => { updateFormState('taxId', value) }} />
        </div>
      </div>
      {errorState.errorOnSubmitAction &&
        <div className="row">
          <div className="col-xs-12">
            <p className={global.ErrorMessage}>{errorState.errorOnSubmitAction || ''}</p>
          </div>
        </div>
      }
      {errorState.filterCount &&
        <div className="row">
          <div className="col-xs-12">
            <p className={global.ErrorMessage}>{errorState.filterCount || ''}</p>
          </div>
        </div>
      }
      <div className="row">
        <div className="col-xs-12 usas-extra-top-margin usas-extra-bottom-margin">
          <PrimaryButton type="submit">Search</PrimaryButton>&nbsp;
          <SecondaryButton type="button" onClick={onClearFormClicked}>Clear Form</SecondaryButton>
        </div>
      </div>
    </form>
  );
};

export default ProviderSearchForm;