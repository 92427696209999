import React, { Fragment } from 'react';

import useLearnToSwimFacilitiesDetail from './UseLearnToSwimFacilitiesDetail';

import LearnToSwimFacilityContactForm from '../../learnToSwim/components/forms/LearnToSwimFacilityContactForm';

import PopUpModal from '../../../../common/components/dialogs/PopUpModal';
import Headings from '../../../../common/components/headings/Headings';

import Constants from '../../../../common/utils/Constants';

const LearnToSwimFacilitiesDetail = () => {
  const {
    isSaving,
    isLoading,
    orgUnitFacilityObj,
    onSubmitFormCallback,
    onCancelClicked
  } = useLearnToSwimFacilitiesDetail();

  return (
    <Fragment>
      <div className='row'>
        <div className='col-xs-12 usas-extra-top-margin'>
          <Headings.H3>Facility Contact Info</Headings.H3>
        </div>
      </div>
      <LearnToSwimFacilityContactForm
        secondaryButtonText={'Cancel'}
        orgUnitFacilityObj={orgUnitFacilityObj}
        onSubmitFormCallback={onSubmitFormCallback}
        onSecondaryButtonClicked={onCancelClicked} />
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.SAVING_MSG}
        displayPopUp={isSaving} />
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={isLoading} />
    </Fragment>
  );
};

export default LearnToSwimFacilitiesDetail;