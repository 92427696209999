import { useEffect, useState } from 'react';

import { createCategoryMeasureScoreObj } from '../../utils/RecognitionProgramUtils';

import useCategoryMeasureScoreData from '../../../state/categoryMeasureScore/UseCategoryMeasureScoreData';
import useRecFilesData from '../../../state/recFiles/UseRecFilesData';

import Constants from '../../../../common/utils/Constants';

const FILE_TYPE_ERROR_MSG = 'File type not permitted. Please provide a (*.pdf), (*.doc), or (*.docx) file.';
const SAVING_ERROR_MSG = 'The last action is still saving. Please try again.';

const useFileUploadView = (programLevel, categoryMeasure, onRedirect) => {
  const { categoryMeasureScoreState, postCategoryMeasureScore, putCategoryMeasureScore } = useCategoryMeasureScoreData();
  const { postRecFiles, recFilesState, getFileNameFromSavedUploadUrl, getFileNameFromUrl, GET_REC_FILES_PATH } = useRecFilesData();
  const [state, setState] = useState({ tryRedirect: false, tryRemove: false, error: '' });
  const [fileState, setFileState] = useState({ templateFileUrl: '', templateFileName: '' });
  const [removeFileModalState, setRemoveFileModalState] = useState(getInitialRemoveFileModalState());

  const onUploadTemplateClicked = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    if (categoryMeasureScoreState.isSaving === true || recFilesState.isSaving === true) {
      setState({ ...state, tryRedirect: false, error: SAVING_ERROR_MSG });
    } else {
      setState({ ...state, tryRedirect: false, error: '' });
      if (e.target.files[0]) {
        const file = e.target.files[0];
        const fileType = e.target.files[0].type;
        if (fileType === Constants.FILE_TYPE_PDF || fileType === Constants.FILE_TYPE_DOC ||
          fileType === Constants.FILE_TYPE_DOCX) {
          postRecFiles(file);
          setState({ ...state, tryRedirect: false, error: '' });
        }
        else {
          setState({ ...state, tryRedirect: false, error: FILE_TYPE_ERROR_MSG });
        }
      }
    }
  };

  const onRemoveTemplateClicked = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    setRemoveFileModalState({ ...getInitialRemoveFileModalState() });

    if (categoryMeasureScoreState.isSaving === true || recFilesState.isSaving === true) {
      setState({ ...state, tryRedirect: false, error: SAVING_ERROR_MSG });
    } else {
      setFileState({ ...fileState, templateFileUrl: '', templateFileName: '' });
      setState({ ...state, tryRedirect: false, tryRemove: true, error: '' });
    }
  };

  const onOpenRemoveFileModalClicked = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    setRemoveFileModalState({ ...removeFileModalState, fileName: fileState.templateFileName, displayPopUp: true });
  };

  const onRemoveFileModalCanceled = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    setRemoveFileModalState({ ...getInitialRemoveFileModalState() });
  };

  const onDownloadUploadedFile = (event) => {
    event.preventDefault();
    const fileUrl = fileState.templateFileUrl;
    window.location.href = import.meta.env.VITE_RECAPI_URL + GET_REC_FILES_PATH + fileUrl;
  };

  useEffect(() => {
    if (Object.keys(categoryMeasure).length > 0) {
      setFileState({
        ...fileState,
        templateFileUrl: categoryMeasure.submittedValue !== null ?
          categoryMeasure.submittedValue : '',
        templateFileName: categoryMeasure.submittedValue !== null ?
          getFileNameFromUrl(categoryMeasure.submittedValue) : ''
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categoryMeasure]);

  useEffect(() => {
    if (recFilesState.isSaved === true && Object.keys(recFilesState.objData).length > 0) {
      setFileState({ ...fileState, templateFileUrl: recFilesState.objData.uploadUrl, templateFileName: getFileNameFromSavedUploadUrl() });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recFilesState]);

  useEffect(() => {
    if (
      recFilesState.objData.uploadUrl?.includes("Bad Request") === false &&
      recFilesState.isSaved === true && 
      Object.keys(recFilesState.objData).length > 0 && 
      fileState.templateFileUrl !== '') {
      if (categoryMeasure.categoryMeasureScoreId > 0) {
        putCategoryMeasureScore(categoryMeasure.categoryMeasureScoreId,
          createCategoryMeasureScoreObj(categoryMeasure, programLevel, fileState.templateFileUrl, categoryMeasure?.maxValue));
      } else {
        postCategoryMeasureScore(createCategoryMeasureScoreObj(categoryMeasure, programLevel, fileState.templateFileUrl, categoryMeasure?.maxValue));
      }
      setState({ ...state, tryRedirect: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recFilesState, fileState]);

  useEffect(() => {
    if (state.tryRemove === true && fileState.templateFileUrl === '') {
      if (categoryMeasure.categoryMeasureScoreId > 0) {
        putCategoryMeasureScore(categoryMeasure.categoryMeasureScoreId, createCategoryMeasureScoreObj(categoryMeasure, programLevel, null, 0));
      } else {
        postCategoryMeasureScore(createCategoryMeasureScoreObj(categoryMeasure, programLevel, null, 0));
      }
      setState({ ...state, tryRemove: false, tryRedirect: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state, fileState]);

  useEffect(() => {
    if (state.tryRedirect === true && categoryMeasureScoreState.isSaved === true) {
      onRedirect(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state, categoryMeasureScoreState]);

  function getInitialRemoveFileModalState() {
    return {
      displayPopUp: false,
      fileName: '',
      modalTitle: 'Remove Uploaded File?'
    };
  };

  return {
    isSaving: categoryMeasureScoreState.isSaving || recFilesState.isSaving,
    categoryMeasureScoreState,
    recFilesState,
    state,
    fileState,
    removeFileModalState,
    onUploadTemplateClicked,
    onRemoveTemplateClicked,
    onDownloadUploadedFile,
    onOpenRemoveFileModalClicked,
    onRemoveFileModalCanceled
  };
};

export default useFileUploadView;