import React, { Fragment } from 'react';

import useClubRegistrationEntry from './UseClubRegistrationEntryNew';

import Headings from '../../../../common/components/headings/Headings';
import PopUpModal from '../../../../common/components/dialogs/PopUpModal';
import Constants from '../../../../common/utils/Constants';

const ClubRegistrationEntryNew = () => {
  const { newClubRegistrationStatusState, uiState } = useClubRegistrationEntry();
  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>Club Registration</Headings.H3>
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12" style={{fontSize: 24, paddingTop: 25}}>
          { uiState.message.length > 0 ? uiState.message : <Fragment /> }
        </div>
      </div>
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={ newClubRegistrationStatusState.isObjLoading } />
    </Fragment>
  )
}

export default ClubRegistrationEntryNew;