import validate from './ClubNonAthleteRosterFormValidation';

import useForm from "../../../../common/utils/UseForm";
import Constants from '../../../../common/utils/Constants';

const competitionGenderOptions = [
  { id: Constants.DEFAULT_ID, name: "--", value: '' },
  { id: 1, name: "Male", value: 1 },
  { id: 2, name: "Female", value: 2 }
];

const useClubNonAthleteRosterForm = (onSubmitFormCallback, clearVendorSync) => {
  const { formState, errorState, setFormData, updateFormState, onValueTextPairChanged, handleSubmit
  } = useForm(getInitialFormState, onSubmitFormCallback, validate);

  const onClearFilterClicked = () => {
    setFormData(getInitialFormState());
    clearVendorSync();

    onSubmitFormCallback(getInitialFormState());
  };

  function getInitialFormState() {
    return {
      competitionGenderTypeId: Constants.DEFAULT_ID,
      competitionGenderTypeName: '',
      minAge: '',
      maxAge: '',
      practiceGroupId: Constants.DEFAULT_ID,
      practiceGroupName: '',
      siteGroupId: Constants.DEFAULT_ID,
      siteGroupName: '',
      firstOrPreferredName: '',
      lastName: ''
    };
  };

  return {
    formState,
    errorState,
    onFormValueChanged: updateFormState,
    onValueTextPairChanged,
    handleSubmit,
    onClearFilterClicked,
    competitionGenderOptions
  };
};

export default useClubNonAthleteRosterForm;