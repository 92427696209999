import { BASIC_INITIAL_STATE } from '../../../utils/HttpHelper';

import getLscBoardMembers from './GetLscBoardMembersData';
import postLscBoardMember from '../../orgUnitStaff/PostOrgUnitStaffData';
import putLscBoardMember from '../../orgUnitStaff/PutOrgUnitStaffData';
import deleteLscBoardMember from '../../orgUnitStaff/DeletePersonOrgRoleDurationData';

const INITIAL_STATE = {
  ...BASIC_INITIAL_STATE
};

const MemberAdministrationLscBoardData = {
  INITIAL_STATE,
  getLscBoardMembers,
  postLscBoardMember,
  putLscBoardMember,
  deleteLscBoardMember
};

export default MemberAdministrationLscBoardData;