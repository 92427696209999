import React, { Fragment } from 'react';

import useLearnToSwimStaffAdd from './UseLearnToSwimStaffAdd';

import ProviderStaffForm from '../components/forms/ProviderStaffForm';
import LearnToSwimStaffDupPopup from '../components/dialogs/LearnToSwimStaffDupPopup';

import PopUpModal from '../../../../common/components/dialogs/PopUpModal';
import Headings from '../../../../common/components/headings/Headings';
import SecondaryButton from '../../../../common/components/buttons/SecondaryButton';
import PrimaryButton from '../../../../common/components/buttons/PrimaryButton';

import Constants from '../../../../common/utils/Constants';

const LearnToSwimStaffAdd = () => {
  const {
    isSaving,
    isLoading,
    dupPersons,
    newPersonState,
    primaryContactModalState,
    displayDupModalState,
    displayLocalDupModalState,
    onSubmitFormCallback,
    onCancelClicked,
    onPrimaryContactModalContinueClicked,
    onPrimaryContactModalCancelClicked,
    onDupModalCancelClicked,
    onLocalDupModalCancelClicked,
    onPersonConfirmed,
    LTS_STAFF_ROLE_GROUP_ID
  } = useLearnToSwimStaffAdd();

  return (
    <Fragment>
      <div className='row'>
        <div className='col-xs-12 usas-extra-top-margin'>
          <Headings.H3>Staff Add</Headings.H3>
        </div>
      </div>
      <ProviderStaffForm
        onSubmitFormCallback={onSubmitFormCallback}
        onSecondaryButtonClicked={onCancelClicked}
        roleGroupId={LTS_STAFF_ROLE_GROUP_ID} />
      {displayDupModalState === true &&
        <LearnToSwimStaffDupPopup
          dupArrayData={dupPersons}
          newPersonObj={newPersonState}
          onPersonConfirmed={onPersonConfirmed}
          onCancelClicked={onDupModalCancelClicked} />
      }
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.SAVING_MSG}
        displayPopUp={isSaving} />
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={isLoading} />
      <PopUpModal
        widthPct={90}
        maxWidth={1020}
        title={'Duplicate Staff Detected'}
        displayPopUp={displayLocalDupModalState === true}
        onModalCanceled={onLocalDupModalCancelClicked}>
        <div className="row">
          <div className="col-xs-12">
            <p>{newPersonState.firstName} {newPersonState.lastName} ({newPersonState.birthDate}) is already a staff member.</p>
          </div>
        </div>
        <div className="row usas-extra-top-margin">
          <div className="col-xs-12">
            <SecondaryButton type="button" onClick={onLocalDupModalCancelClicked}>Cancel</SecondaryButton>
          </div>
        </div>
      </PopUpModal>
      <PopUpModal
        widthPct={90}
        maxWidth={1020}
        title={'Replace Current Primary Contact?'}
        displayPopUp={primaryContactModalState.displayModal}
        onModalCanceled={onPrimaryContactModalCancelClicked}>
        <div className="row">
          <div className="col-xs-12">
            <p>Assigning {newPersonState.firstName} {newPersonState.lastName} the Primary Contact role will remove it from {primaryContactModalState.currentPrimaryContact?.firstName} {primaryContactModalState.currentPrimaryContact?.lastName}.</p>
            {primaryContactModalState.isOnlyRole === true && <p><b>{primaryContactModalState.currentPrimaryContact?.firstName} {primaryContactModalState.currentPrimaryContact?.lastName}'s only role is Primary Contact. Replacing them as Primary Contact will remove them from this provider's staff.</b></p>}
          </div>
        </div>
        <div className="row usas-extra-top-margin">
          <div className="col-xs-12">
            <PrimaryButton type="button" onClick={onPrimaryContactModalContinueClicked}>Yes, Replace Primary Contact</PrimaryButton>&nbsp;
            <SecondaryButton type="button" onClick={onPrimaryContactModalCancelClicked}>Cancel</SecondaryButton>
          </div>
        </div>
      </PopUpModal>
    </Fragment>
  );
};

export default LearnToSwimStaffAdd;