import React, { Fragment } from 'react';

import useClubFacilityDetails from './UseClubFacilityDetails';

import Headings from '../../../../common/components/headings/Headings';
import Input from '../../../../common/components/inputs/Input';
import InternationalPhoneInput from '../../../../common/components/inputs/InternationalPhoneInput';
import ReadOnly from '../../../../common/components/readOnly/ReadOnly';
import PrimaryButton from '../../../../common/components/buttons/PrimaryButton';
import SecondaryButton from '../../../../common/components/buttons/SecondaryButton';
import PopUpModal from '../../../../common/components/dialogs/PopUpModal';
import Constants from '../../../../common/utils/Constants';
import YesNoSwitch from '../../../../common/components/yesNoSwitch/YesNoSwitch';

const ClubFacilityDetails = () => {
  const {
    usasClubFacilityLinksState,
    formState,
    errorState,
    onSaveClicked,
    onFormValueChanged,
    handleSubmit,
    onCancelGoToClubFacilities
  } = useClubFacilityDetails();

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>Edit Club Facility Contact Info</Headings.H3>
        </div>
      </div>
      <form onSubmit={handleSubmit} noValidate>
        <div className="row">
          <div className="col-xs-12 col-sm-6 col-md-4">
            <ReadOnly
              label="Facility Name"
              name="facilityName"
              value={formState.facilityName} />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4">
            <ReadOnly
              label="Address Line 1"
              name="address1"
              value={formState.address1} />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4">
            <ReadOnly
              label="Address Line 2"
              name="address2"
              value={formState.address2} />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4">
            <ReadOnly
              label="City"
              name="city"
              value={formState.city} />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4">
            <ReadOnly
              label="State"
              name="stateCode"
              value={formState.stateCode} />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4">
            <ReadOnly
              label="Zip Code"
              name="postalCode"
              value={formState?.postalCode} />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4">
            <Input
              label="Facility Primary Contact Name*"
              name="contactName"
              value={formState.contactName}
              error={errorState.contactName}
              message={errorState.contactName}
              onChange={(value) => { onFormValueChanged('contactName', value); }} />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4">
            <Input
              label="Facility Email*"
              name="contactEmailAddress"
              value={formState?.contactEmailAddress}
              error={errorState.contactEmailAddress}
              message={errorState.contactEmailAddress}
              onChange={(value) => { onFormValueChanged('contactEmailAddress', value); }} />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4">
            <InternationalPhoneInput
              label="Facility Phone Number*"
              name="contactPhoneNumber"
              value={formState.contactPhoneNumber}
              error={errorState.contactPhoneNumber}
              message={errorState.contactPhoneNumber}
              onChange={(value) => { onFormValueChanged('contactPhoneNumber', value); }} />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4">
            <Input
              label="Name of Club Contact for Facility*"
              name="clubFacilityContactName"
              value={formState.clubFacilityContactName}
              error={errorState.clubFacilityContactName}
              message={errorState.clubFacilityContactName}
              onChange={(value) => { onFormValueChanged('clubFacilityContactName', value); }} />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4">
            <Input
              label="Email of Club Contact for Facility*"
              name="clubFacilityContactEmailAddress"
              value={formState.clubFacilityContactEmailAddress}
              error={errorState.clubFacilityContactEmailAddress}
              message={errorState.clubFacilityContactEmailAddress}
              onChange={(value) => { onFormValueChanged('clubFacilityContactEmailAddress', value); }} />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4">
            <InternationalPhoneInput
              label="Phone Number of Club Contact for Facility*"
              name="clubFacilityContactPhoneNumber"
              value={formState.clubFacilityContactPhoneNumber}
              error={errorState.clubFacilityContactPhoneNumber}
              message={errorState.clubFacilityContactPhoneNumber}
              onChange={(value) => { onFormValueChanged('clubFacilityContactPhoneNumber', value); }} />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4">
            <ReadOnly
              label="Facility Can Host Meet?"
              name="hostMeet"
              value={formState.hostMeet === true ? "Yes" : "No"} />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-8">
            <YesNoSwitch
              label="Is this a training facility? (training facilities will show up in Club Finder)"
              name="trainingFacility"
              checked={formState.trainingFacility}
              error={errorState.trainingFacility}
              message={errorState.trainingFacility}
              onChange={(value) => { onFormValueChanged('trainingFacility', value); }}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12 usas-extra-top-margin usas-extra-bottom-margin">
            <PrimaryButton type="button" onClick={onSaveClicked}>Save</PrimaryButton>&nbsp;
            <SecondaryButton type="button" onClick={onCancelGoToClubFacilities}>Back</SecondaryButton>
          </div>
        </div>
      </form>
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={usasClubFacilityLinksState.isArrayLoading} />
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.SAVING_MSG}
        displayPopUp={usasClubFacilityLinksState.isSaving} />
    </Fragment>
  );
};

export default ClubFacilityDetails;