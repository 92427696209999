import React, { Fragment } from 'react';

import useClubCommunityImpactBulkImport from './UseCommunityImpactBulkImport';

import CurrentSeasonOfferingDropdown from '../../../components/dropdowns/currentSeasonOfferingDropdown/CurrentSeasonOfferingDropdown';

import PrimaryButton from '../../../../common/components/buttons/PrimaryButton';
import SecondaryButton from '../../../../common/components/buttons/SecondaryButton';
import PopUpModal from '../../../../common/components/dialogs/PopUpModal';
import Headings from '../../../../common/components/headings/Headings';
import ViewReport from '../../../../common/components/embeddedReports/ViewReport';

import Constants from '../../../../common/utils/Constants';

import global from '../../../../common/components/GlobalStyle.module.css';

const COMMUNITY_SWIM_IMPORT = 'CommunitySwimImport';

const ClubCommunityImpactBulkImport = () => {
  const {
    isLoading,
    isSaving,
    clubCommunityImpactBulkImportUploadState,
    memberIdArrayState,
    baseUsasClubState,
    fileState,
    formState,
    errorState,
    fileErrorState,
    setFormState,
    onLoadFile,
    onDownloadJSON,
    onUploadMembers,
    GET_DOWNLOAD_TEMPLATE_PATH,
    modalState,
    onModalCanceled,
    onContinueClicked,
    resetClubCommunityImpactBulkImportUploadState,
    INITIAL_VIEW_STATE,
    viewState,
    setViewState,
    handleDelete
  } = useClubCommunityImpactBulkImport();

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>Club Community Impact Bulk Import</Headings.H3>
        </div>
      </div>
      <div className="row">
        <div className='col-xs-12 usas-extra-top-margin usas-extra-bottom-margin'>
          <Headings.H6>Use the Following Template To Bulk Import</Headings.H6>
        </div>
        <div className="col-xs-12 usas-extra-bottom-margin">
          <SecondaryButton type="button" onClick={(event) => {
            event.preventDefault();
            window.location.href = import.meta.env.VITE_CLUBAPI_URL + GET_DOWNLOAD_TEMPLATE_PATH + COMMUNITY_SWIM_IMPORT;
          }}>Download Template</SecondaryButton> &nbsp;
        </div>
      </div>
      <div className="row">
        <div className='col-xs-12 usas-extra-top-margin usas-extra-bottom-margin'>
          <Headings.H6>Select a Membership to Use For Your Bulk Import. Then Select Your Bulk Import File and Upload Your Members.</Headings.H6>
        </div>
        <div className="col-xs-12 col-sm-6 col-md-4 usas-extra-bottom-margin">
          <CurrentSeasonOfferingDropdown
            label={"Membership*"}
            name={"index"}
            value={formState.index}
            error={errorState.index}
            message={errorState.index}
            onChange={(newValue, newValueLabel, e) => {
              e && e.target && e.target.value && setFormState({ ...formState, index: newValue, offeringName: newValueLabel })
              resetClubCommunityImpactBulkImportUploadState();
            }
            }
            orgUnitId={baseUsasClubState.objData?.orgUnitId}
            isAthleteRole={''}
          />
        </div>
        {formState.index !== Constants.DEFAULT_ID &&
          <div className="col-xs-12 usas-extra-top-margin">
            <label htmlFor="member-id-select-file" className={global.FileUpload}>
              Select A File
            </label>
            <input hidden id="member-id-select-file" type="file" onChange={(e) => {
              onLoadFile(e);
              resetClubCommunityImpactBulkImportUploadState();
            }} />
            &nbsp;(*.csv)
          </div>}
      </div>
      <div className="row">
        {fileErrorState && <div className="col-xs-12"><p className={global.ErrorMessage}>{fileErrorState}</p></div>}
      </div>
      {!fileErrorState && fileState.size > 0 &&
        <div className="row">
          <div className="col-xs-12 usas-extra-top-margin">
            <p><b>Members Found:</b> {memberIdArrayState.length}</p>
          </div>
          {memberIdArrayState.length === 0 &&
            <div className="col-xs-12"><p className={global.ErrorMessage}>No Members were detected inside {fileState.name}</p></div>
          }
        </div>
      }
      {!fileErrorState && clubCommunityImpactBulkImportUploadState.isSaved !== true && fileState.size > 0 && memberIdArrayState.length > 0 &&
        <div className='row'>
          <div className="col-xs-12 usas-extra-top-margin usas-extra-bottom-margin">
            <SecondaryButton type="button" onClick={onDownloadJSON}>Download JSON File</SecondaryButton>&nbsp;
            <PrimaryButton type="button" onClick={onUploadMembers}>Upload Members</PrimaryButton>
          </div>
        </div>
      }
      {clubCommunityImpactBulkImportUploadState?.message && clubCommunityImpactBulkImportUploadState?.message !== '' && clubCommunityImpactBulkImportUploadState?.message !== Constants.SAVING_MSG &&
        clubCommunityImpactBulkImportUploadState?.message !== Constants.LOADING_MSG &&
        <div className='row'>
          <div className="col-xs-12 usas-extra-bottom-margin">
            <p className={global.ErrorMessage}>An error occured upon uploading. Please check the formatting of your selected file.</p>
          </div>
        </div>
      }
      {clubCommunityImpactBulkImportUploadState.isSaved === true &&
        <div className='row'>
          <div className="col-xs-12 usas-extra-bottom-margin">
            <p><b>Upload Status:</b> {clubCommunityImpactBulkImportUploadState.objData?.message}</p>
          </div>
        </div>
      }
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={isLoading} />
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.UPLOADING_MSG}
        displayPopUp={isSaving} />
      <PopUpModal
        widthPct={90}
        maxWidth={720}
        title={modalState.modalTitle}
        displayPopUp={modalState.displayPopUp && !clubCommunityImpactBulkImportUploadState.isSaving}
        onModalCanceled={onModalCanceled}>
        <form>
          <div className="row">
            <div className="col-xs-12 usas-extra-bottom-margin">
              <p><b>Upload Status:</b> {clubCommunityImpactBulkImportUploadState.objData?.message}</p>
            </div>
            <div className="col-xs-12">
              <b>Your uploaded {formState.offeringName || ''} memberships have been added to the shopping cart!</b>
            </div>
            <div className="col-xs-12 usas-extra-top-margin usas-extra-bottom-margin">
              <span>Please choose between continuing to the shopping cart to complete your bulk import purchases, or staying on this page and starting the process to bulk import more memberships.</span>
            </div>
            <div className="col-xs-12 usas-extra-top-margin">
              <PrimaryButton type="button" onClick={onContinueClicked}>Continue to Shopping Cart</PrimaryButton>&nbsp;
              <SecondaryButton type="button" onClick={onModalCanceled}>Bulk Import More Memberships</SecondaryButton>
            </div>
          </div>
        </form>
      </PopUpModal>
      {viewState.showReport === true &&
        <ViewReport reportInfoId={viewState.reportInfoId} reportParameters={viewState.reportParameters} onBackToGrid={() => {handleDelete(); setViewState({ INITIAL_VIEW_STATE });}} />}
    </Fragment >
  );
};

export default ClubCommunityImpactBulkImport;