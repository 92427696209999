import { Fragment } from "react";

import useIndividualTimesSearchDashboard from "./UseIndividualTimesSearchDashboard";

import IndividualTimesSearchWidget from "./IndividualTimesSearchWidget";

import LoadingModal from "../../../../../common/components/dialogs/LoadingModal";

const IndividualTimesSearchDashboard = ({ dashboardOid }) => {
  const { isError, isLoading, dashboard } = useIndividualTimesSearchDashboard(dashboardOid);

  if (isError) {
    return <p>Error</p>;
  }

  if (isLoading) {
    return <LoadingModal />;
  }

  if (dashboard) {
    return (
      <Fragment>
        {dashboard.widgets?.map((widget, i) => {
          if (i === 0) {
            return (
              <Fragment key={i}>
                <IndividualTimesSearchWidget dashboardOid={dashboardOid} widget={widget} />
              </Fragment>
            );
          }
        })
        }
      </Fragment>
    );
  }

  return <Fragment />;
};

export default IndividualTimesSearchDashboard;