import { useEffect, useState } from "react";

import { createCategoryMeasureScoreObj } from "../../utils/RecognitionProgramUtils";

import useKpiMeasureData from "../../../state/kpiMeasure/UseKpiMeasureData";
import useCategoryMeasureScoreData from "../../../state/categoryMeasureScore/UseCategoryMeasureScoreData";

import useRecognitionProgramData from "../../../../common/state/recognitionProgram/UseRecognitionProgramData";

const UseKpiBasicReport = (programLevel, categoryMeasure, isReadonly, onRedirect) => {
  const { kpiMeasureState, putKpiMeasure, postKpiMeasure, getKpiMeasure } = useKpiMeasureData();
  const { categoryMeasureScoreState, putCategoryMeasureScore } = useCategoryMeasureScoreData();
  const { recognitionProgramState, getCurrentProgramByProgramType } = useRecognitionProgramData();
  const [kpiReportFieldsState, setKpiReportFieldsState] = useState([]);
  const [state, setState] = useState({ tryScoreOverrideRedirect: false, tryKpiRedirect: false });

  useEffect(() => {
    if (categoryMeasure && programLevel && kpiMeasureState.isArrayLoaded === false) {
      if (categoryMeasure.categoryMeasureScoreId > 0) {
        if (isReadonly === true) {
          getKpiMeasure(programLevel.programLevelInstanceId, categoryMeasure.categoryMeasureId, categoryMeasure.endpointUrl);
        } else if (categoryMeasure.scoreEntryOverride === true) {
          getKpiMeasure(programLevel.programLevelInstanceId, categoryMeasure.categoryMeasureId, categoryMeasure.endpointUrl);
        } else {
          putKpiMeasure(programLevel.programLevelInstanceId, categoryMeasure.categoryMeasureId, categoryMeasure.categoryMeasureScoreId, categoryMeasure.endpointUrl);
        }
      } else if (isReadonly !== true) {
        postKpiMeasure(programLevel.programLevelInstanceId, categoryMeasure.categoryMeasureId, categoryMeasure.endpointUrl);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categoryMeasure, programLevel, isReadonly, kpiMeasureState.isArrayLoaded]);

  useEffect(() => {
    if (kpiMeasureState.isArrayLoaded === true && kpiMeasureState.isArrayLoading === false) {
      if (state.tryKpiRedirect === true) {
        onRedirect(true);
      } else {
        getCurrentProgramByProgramType(recognitionProgramState.orgUnitId, recognitionProgramState.programTypeId);
        setKpiReportFieldsState(organizeKpiReportFieldsArray(kpiMeasureState.arrayData));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.tryKpiRedirect, kpiMeasureState]);

  useEffect(() => {
    if (state.tryScoreOverrideRedirect === true && categoryMeasureScoreState.isSaving === false
      && categoryMeasureScoreState.isSaved === true) {
      onRedirect(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.tryScoreOverrideRedirect, categoryMeasureScoreState]);

  function submitFormCallback(formState) {
    if (formState.scoreEntryOverride === true) {
      putCategoryMeasureScore(categoryMeasure.categoryMeasureScoreId,
        createCategoryMeasureScoreObj(categoryMeasure, programLevel, 'Submitted', formState.score, formState.scoreEntryOverride));

      setState({ ...state, tryScoreOverrideRedirect: true });
    } else {
      putKpiMeasure(programLevel.programLevelInstanceId, categoryMeasure.categoryMeasureId, categoryMeasure.categoryMeasureScoreId, categoryMeasure.endpointUrl);

      setState({ ...state, tryKpiRedirect: true });
    }
  };

  function organizeKpiReportFieldsArray(arrayData) {
    arrayData.sort((a, b) => { return a.displayOrder - b.displayOrder; });
    const reportArray = [];

    for (const fieldObj of arrayData) {
      if (reportArray.length === 0 || fieldObj.displayOrder % 2 > 0) {
        const newArray = [fieldObj];

        reportArray.push(newArray);
      } else {
        reportArray[reportArray.length - 1].push(fieldObj);
      }
    }

    return reportArray;
  };

  return {
    isLoading: kpiMeasureState.isArrayLoading || recognitionProgramState.isArrayLoading,
    isSaving: categoryMeasureScoreState.isSaving,
    isEdit: categoryMeasure.categoryMeasureScoreId > 0,
    kpiReportFieldsState,
    orgUnitId: recognitionProgramState.orgUnitId,
    submitFormCallback
  };
};

export default UseKpiBasicReport;