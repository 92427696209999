import { useContext } from 'react';

import MemberAdministrationLscStaffData from './MemberAdministrationLscStaffData';
import { MemberAdministrationLscStaffStateContext } from './MemberAdministrationLscStaffContextProvider';

const useMemberAdministrationLscStaffData = () => {
  const [memberAdministrationLscStaffState, setMemberAdministrationLscStaffState] = useContext(MemberAdministrationLscStaffStateContext);

  const getLscStaff = (orgUnitId) => MemberAdministrationLscStaffData.getLscStaff(orgUnitId, memberAdministrationLscStaffState, setMemberAdministrationLscStaffState);
  const postLscStaff = (orgUnitId, roleGroupId, staffObj) => MemberAdministrationLscStaffData.postLscStaff(orgUnitId, roleGroupId, staffObj, memberAdministrationLscStaffState, setMemberAdministrationLscStaffState);
  const putLscStaff = (orgUnitId, personId, roleGroupId, staffObj) => MemberAdministrationLscStaffData.putLscStaff(orgUnitId, personId, roleGroupId, staffObj, memberAdministrationLscStaffState, setMemberAdministrationLscStaffState);
  const deleteLscStaff = (personOrgRoleDurationId) => MemberAdministrationLscStaffData.deleteLscStaff(personOrgRoleDurationId, memberAdministrationLscStaffState, setMemberAdministrationLscStaffState);

  const confirmSave = () => {
    setMemberAdministrationLscStaffState({
      ...memberAdministrationLscStaffState,
      isSaved: false
    });
  };

  const clearMemberAdministrationLscStaffArrayData = () => {
    setMemberAdministrationLscStaffState({
      ...memberAdministrationLscStaffState,
      isArrayLoading: false,
      isArrayLoaded: false,
      isSaving: false,
      isSaved: false,
      arrayData: []
    });
  }

  return {
    memberAdministrationLscStaffState,
    getLscStaff,
    postLscStaff,
    putLscStaff,
    deleteLscStaff,
    confirmSave,
    clearMemberAdministrationLscStaffArrayData
  };
};

export default useMemberAdministrationLscStaffData;