import Checkbox from "../../../../common/components/checkboxes/Checkbox";

import { formatDate } from "../../../../common/utils/DateFunctions";

import global from '../../../../common/components/GlobalStyle.module.css';

const SmallGridRow = ({ offering, onCheckboxClicked }) => (
  <div className={global.SmallTableRow}>
    <div className={global.SmallTableRowHead}><span className="hidden-xs">{offering.offeringName || ''}</span>&nbsp;
      {onCheckboxClicked && offering.isEditable === true
        ? (
          <div className={global.CheckboxMobile}>
            <Checkbox
              className={global.IconButton}
              label=""
              name="isOfferedByClub"
              onChange={() => { onCheckboxClicked(offering) }}
              checked={offering.isOfferedByClub} />
          </div>
        )
        : offering.isOfferedByClub === true
          ? <span className={global.IconButton}>Yes</span>
          : <span className={global.IconButton}>No</span>
      }
    </div>
    <div className={global.SmallTableRowInfo}>
      <div className="row">
        <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3 visible-xs'].join(' ')}>Offering</div>
        <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3 visible-xs'].join(' ')}>{offering.offeringName || ''}</div>
        <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3'].join(' ')}>Registration Start Date</div>
        <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3'].join(' ')}>{offering.registrationStartDate ? formatDate(offering.registrationStartDate) : ''}</div>
        <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3'].join(' ')}>Registration End Date</div>
        <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3'].join(' ')}>{offering.registrationEndDate ? formatDate(offering.registrationEndDate) : ''}</div>
      </div>
    </div>
  </div >
);

const ClubManageOfferingsGridSmall = ({ gridData, isLoading, onCheckboxClicked }) => (
  <div className={[global.SmallTable, 'hidden-md hidden-lg'].join(' ')}>
    {isLoading === true
      ? (
        <div className={global.SmallTableRow}>
          <div className={global.SmallTableRowHead}>&nbsp;</div>
          <div className={global.SmallTableRowInfo}>
            <div className={global.SmallTableRowLabels}>Loading...</div>
          </div>
        </div>
      )
      : gridData.length > 0
        ? gridData.map((offering, i) => <SmallGridRow key={i} offering={offering} onCheckboxClicked={onCheckboxClicked} />)
        : (
          <div className={global.SmallTableRow}>
            <div className={global.SmallTableRowHead}>&nbsp;</div>
            <div className={global.SmallTableRowInfo}>
              <div className={global.SmallTableRowLabels}>No Offerings</div>
            </div>
          </div>
        )
    }
  </div>
);

export default ClubManageOfferingsGridSmall;