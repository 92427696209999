import { useState } from 'react';

import ClubAuditData from './ClubAuditData';

const useClubAuditData = () => {
  const [clubAuditState, setClubAuditState] = useState(ClubAuditData.INITIAL_STATE);
  const getClubAudit = (orgUnitId) => ClubAuditData.getClubAudit(orgUnitId, clubAuditState, setClubAuditState);

  return {
    clubAuditState,
    getClubAudit
  };
};

export default useClubAuditData;