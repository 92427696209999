import { BASIC_INITIAL_STATE } from '../../../common/utils/HttpHelper';

import getPersonPotentialMatchesData from './GetPersonPotentialMatchesData';
import putPersonPotentialMatchesData from './PutPersonPotentialMatchesData';

const INITIAL_STATE = {
  ...BASIC_INITIAL_STATE
};

const PersonPotentialMatchData = {
  INITIAL_STATE,
  getPersonPotentialMatchesData,
  putPersonPotentialMatchesData
};

export default PersonPotentialMatchData;