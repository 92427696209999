import React, { Fragment } from 'react';

import ClubRegistrationConstants from '../utils/ClubRegistrationConstants';

import ClubOwnerTypeDropdown from '../../../components/dropdowns/ClubOwnerTypeDropdown';
import BusinessEntityDropdown from '../../../components/dropdowns/BusinessEntityDropdown';

import Input from '../../../../common/components/inputs/Input';
import PrimaryButton from '../../../../common/components/buttons/PrimaryButton';
import SecondaryButton from '../../../../common/components/buttons/SecondaryButton';

import global from '../../../../common/components/GlobalStyle.module.css';

const ClubRegistrationDisclaimerWrite = ({ clubRegistrationState, handleSubmit, formState, errorState, onValueTextPairChanged,
  onFormValueChanged, clubOwnerTypeState, state, onBackClicked }) => (
  <Fragment>
    <div className="row">
      <div className="col-xs-12">
        <p>To register a new club, you will need the following information:</p>
        <li>Staff Member IDs</li>
        <li>Club Federal Tax ID</li>
        <li>Practice facility information</li>
      </div>
    </div>
    <form onSubmit={handleSubmit} noValidate>
      <div className="row usas-extra-top-margin usas-extra-bottom-margin">
        <div className="col-xs-12 col-sm-6 col-md-4">
          <ClubOwnerTypeDropdown
            label="Owner Type*"
            name="clubOwnerTypeId"
            value={formState.clubOwnerTypeId}
            error={errorState.clubOwnerTypeId}
            message={errorState.clubOwnerTypeId}
            onChange={(newValue, newValueLabel, e) => { onValueTextPairChanged(newValue, 'clubOwnerTypeId', newValueLabel, 'ownerTypeName', e); }}
          />
        </div>
        {clubOwnerTypeState.isLoaded === true ?
          <div className="col-xs-12 col-sm-6 col-md-4">
            <BusinessEntityDropdown
              label="Tax Listing*"
              name="businessEntityTypeId"
              value={formState.businessEntityTypeId}
              error={errorState.businessEntityTypeId}
              message={errorState.businessEntityTypeId}
              clubOwnerTypeId={formState.clubOwnerTypeId}
              onChange={(newValue, newValueLabel, e) => { onValueTextPairChanged(newValue, 'businessEntityTypeId', newValueLabel, 'businessEntityTypeName', e); }}
            />
          </div>
          : <Fragment />}
        <div className={state.showNumOfBoardMembers === true && clubOwnerTypeState.isLoaded === true ? global.DisplayComponent : global.HideComponent}>
          <div className="col-xs-12 col-sm-6 col-md-4" >
            <Input
              label="Number of Board Members*"
              name="numOfBoardMembers"
              value={formState.numOfBoardMembers}
              error={errorState.numOfBoardMembers}
              message={errorState.numOfBoardMembers}
              onChange={(value) => { onFormValueChanged('numOfBoardMembers', value) }} />
          </div>
        </div>
      </div>
      <div className="row usas-extra-top-margin">
        <div className="col-xs-12 usas-extra-top-margin usas-extra-bottom-margin">
          <PrimaryButton type="submit">Continue</PrimaryButton>&nbsp;
          {clubRegistrationState.objData?.status === ClubRegistrationConstants.IN_PROGRESS_STATUS ?
            <Fragment /> :
            <SecondaryButton type="button" onClick={() => onBackClicked()}>Back</SecondaryButton>}
        </div>
      </div>
    </form>
  </Fragment>
)

export default ClubRegistrationDisclaimerWrite;