import { Fragment } from 'react';
import SGColumnHeader from '../../../../common/components/grids/SGColumnHeader';

import EditIcon from '../../../../common/components/icons/EditIcon';

import { formatDate } from '../../../../common/utils/DateFunctions';
import useSortableGrid from '../../../../common/utils/UseSortableGrid';
import SGConstants from '../../../../common/utils/SGConstants';

import global from '../../../../common/components/GlobalStyle.module.css';

const GridRow = ({ invoice, onEditClicked }) => {
  return (
    <Fragment>
      <tr>
        <td>{invoice.thirdPartyClubRegistrationId >= 0 ? invoice.thirdPartyClubRegistrationId : ''}</td>
        <td>{invoice.registrationDate ? formatDate(invoice.registrationDate) : ''}</td>
        <td>{invoice.periodName || ''}</td>
        <td>{invoice.memberRegistrationCount >= 0 ? invoice.memberRegistrationCount : ''}</td>
        <td>
          {onEditClicked &&
            <button className={global.IconButtonMargin}
              type="button"
              onClick={() => onEditClicked(invoice)}>
              <EditIcon />
            </button>
          }
        </td>
      </tr>
    </Fragment>
  );
};

const ClubThirdPartyInvoicesGridLarge = ({ gridData, isLoading, onEditClicked }) => {
  const {
    sortableGridState,
    onColumnClicked
  } = useSortableGrid(gridData);

  return (
    <table className={[global.UsasTable, 'visible-md visible-lg'].join(' ')}>
      <thead>
        <tr>
          <SGColumnHeader sortableGridState={sortableGridState} columnLabel={'Third Party Club Registration Id'} columnField={'thirdPartyClubRegistrationId'}
            sortType={SGConstants.SORT_TYPE_NUMERICAL} onColumnClicked={onColumnClicked} />
          <SGColumnHeader sortableGridState={sortableGridState} columnLabel={'Registration Date'} columnField={'registrationDate'}
            sortType={SGConstants.SORT_TYPE_DATE} onColumnClicked={onColumnClicked} />
          <SGColumnHeader sortableGridState={sortableGridState} columnLabel={'Report Period'} columnField={'startDate'}
            sortType={SGConstants.SORT_TYPE_DATE} onColumnClicked={onColumnClicked} />
          <SGColumnHeader sortableGridState={sortableGridState} columnLabel={'Member Registration Count'} columnField={'memberRegistrationCount'}
            sortType={SGConstants.SORT_TYPE_NUMERICAL} onColumnClicked={onColumnClicked} />
          <th></th>
        </tr>
      </thead>
      <tbody>
        {isLoading === true
          ? <tr><td colSpan="5">Loading...</td></tr>
          : Array.isArray(sortableGridState.sortedGridData) && sortableGridState.sortedGridData.length > 0
            ? sortableGridState.sortedGridData.map((invoice, i) => <GridRow key={i} invoice={invoice} onEditClicked={onEditClicked} />)
            : <tr><td colSpan="5">No Invoices</td></tr>
        }
      </tbody>
    </table>
  )
};

export default ClubThirdPartyInvoicesGridLarge;