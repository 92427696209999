import { BASIC_INITIAL_STATE } from '../../utils/HttpHelper';

import getRouteCodeValue from './GetRouteCodeValueData';

const INITIAL_STATE = {
  ...BASIC_INITIAL_STATE,
  routeCodeValue: ''
};


const RouteCodeValueData =
{
  INITIAL_STATE,
  getRouteCodeValue
};

export default RouteCodeValueData;