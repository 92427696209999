import { useContext } from 'react';

import { MemberRegistrationRenewalSelectionStateContext } from './MemberRegistrationRenewalSelectionContextProvider';

import MemberRegistrationRenewalSelectionData from './MemberRegistrationRenewalSelectionData';

const useMemberRegistrationRenewalSelectionData = () => {
  const [memberRegistrationRenewalSelectionState, setMemberRegistrationRenewalSelectionState] = useContext(MemberRegistrationRenewalSelectionStateContext);

  const getMemberRegistrationRenewalSelection = (orgUnitId, filterObj) =>
    MemberRegistrationRenewalSelectionData.getMemberRegistrationRenewalSelectionData(orgUnitId, JSON.stringify(filterObj), memberRegistrationRenewalSelectionState, setMemberRegistrationRenewalSelectionState);

  const postMemberRegistrationRenewalSelection = (orgUnitId, membershipArray) =>
    MemberRegistrationRenewalSelectionData.postMemberRegistrationRenewalSelectionData(orgUnitId, membershipArray, memberRegistrationRenewalSelectionState, setMemberRegistrationRenewalSelectionState);

  const clearMemberRegistrationRenewalSelectionArrayData = () => {
    setMemberRegistrationRenewalSelectionState({
      ...memberRegistrationRenewalSelectionState,
      isArrayLoading: false,
      isArrayLoaded: false,
      isSaving: false,
      isSaved: false,
      arrayData: [],
      message: ''
    });
  };

  const clearMemberRegistrationRenewalSelectionObjData = () => {
    setMemberRegistrationRenewalSelectionState({
      ...memberRegistrationRenewalSelectionState,
      isObjLoading: false,
      isObjLoaded: false,
      isSaving: false,
      isSaved: false,
      objData: {},
      message: ''
    });
  };

  return {
    memberRegistrationRenewalSelectionState,
    setMemberRegistrationRenewalSelectionState,
    getMemberRegistrationRenewalSelection,
    postMemberRegistrationRenewalSelection,
    clearMemberRegistrationRenewalSelectionObjData,
    clearMemberRegistrationRenewalSelectionArrayData
  };
};

export default useMemberRegistrationRenewalSelectionData;