import React, { Fragment } from 'react';

import global from '../../../../common/components/GlobalStyle.module.css';

const LargeGrid = ({ state }) => (
  <table className={[global.UsasTable, 'visible-md visible-lg'].join(' ')}>
    <thead>
      <tr>
        <th>Affiliation Name</th>
      </tr>
    </thead>
    <tbody>
      {state.isObjLoaded !== true
        ? (
          <tr>
            {state.isObjLoading === true ?
              <td colSpan="2">Loading...</td>
              : <td colSpan="2">No Affiliations</td>
            }
          </tr>
        ) : Array.isArray(state.objData.usasClubAffiliations) && state.objData.usasClubAffiliations.length > 0
          ? state.objData.usasClubAffiliations.map(affiliation => (
            <tr key={affiliation.usasClubAffiliationId}>
              <td>{affiliation.clubAffiliationType.typeName}</td>
            </tr>
          )) : (
            <tr>
              <td colSpan="2">No Affiliations</td>
            </tr>
          )
      }
    </tbody>
  </table>
);

const SmallGrid = ({ state }) => (
  <div className={[global.SmallTable, 'hidden-md hidden-lg'].join(' ')}>
    {state.isObjLoaded !== true
      ? (
        <div className={global.SmallTableRow}>
          <div className={global.SmallTableRowHead}>
            &nbsp;
          </div>
          <div className={global.SmallTableRowInfo}>
            {state.isObjLoading === true ?
              <div className={global.SmallTableRowLabels}>Loading... </div>
              : <div className={global.SmallTableRowLabels}>No Affiliations</div>
            }
          </div>
        </div>
      ) : Array.isArray(state.objData.usasClubAffiliations) && state.objData.usasClubAffiliations.length > 0
        ? state.objData.usasClubAffiliations.map(affiliation => (
          <div className={global.SmallTableRow} key={affiliation.usasClubAffiliationId}>
            <div className={global.SmallTableRowHead}><span className='hidden-xs'>{affiliation.clubAffiliationType.typeName}</span>&nbsp;
            </div>
            <div className={global.SmallTableRowInfo}>
              <div className='row'>
                <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3 visible-xs'].join(' ')}>Affiliation Name</div>
                <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3 visible-xs'].join(' ')}>{affiliation.clubAffiliationType.typeName}</div>
              </div>
            </div>
          </div>
        )) : (
          <div className={global.SmallTableRow}>
            <div className={global.SmallTableRowHead}>
              &nbsp;
            </div>
            <div className={global.SmallTableRowInfo}>
              <div className={global.SmallTableRowLabels}>No Affiliations</div>
            </div>
          </div>
        )
    }
  </div >
);

const ClubAffiliationGridRead = ({ state }) => (
  <Fragment>
    <LargeGrid state={state} />
    <SmallGrid state={state} />
  </Fragment>
)

export default ClubAffiliationGridRead;