//TODO move to common?
const getSisenseColumnLabel = (columnName) => {
  return columnName ? columnName === columnName.toUpperCase()
    ? columnName.match(/[A-Z]+|[0-9]+/g)?.join(" ")
    : columnName.match(/[A-Z][a-z]+|[0-9]+/g)?.join(" ") : '';
};

const getSisenseCellText = (rowText) => {
  return rowText ? rowText === 'true' ? 'Yes' : rowText === 'false' ? '' : rowText : '';
};

/**
 * Copies the data from sisense and reorders it based on columnIndexes & columnNumbers while also adding three new fields.
 * columnNumber, for ordering and identifying columns/row cells.
 * columnLabel, user friendly column.name.
 * cellText, user friendly row[i].text.
 * @param {Array} data unmodified data from sisense 
 * @param {Object} columnIndexes the default column indexes of the data sent by sisense(object keys must match columnNumbers)
 * @param {Object} columnNumbers the order the columns should be displayed in(object keys must match columnIndexes)
 * @param {Boolean} areUnmatchedHidden should columns with no columnIndexes entry be hidden(given a negative columnNumber)
 * @returns object with two arrays, columns & rows.
 */
const getSisenseFormattedData = (data, columnIndexes, columnNumbers, areUnmatchedHidden = true) => {
  const dataCopy = JSON.parse(JSON.stringify(data));
  const columnIndexKeyValues = Object.entries(columnIndexes);
  const newFormattedData = { columns: [], rows: [] };

  const newColumnArray = dataCopy.columns?.map((x, j) => {
    const targetKeyValue = columnIndexKeyValues.find(x => x[1] === j);

    if (targetKeyValue) {
      const targetKey = targetKeyValue[0];

      return { ...x, columnNumber: columnNumbers[targetKey], columnLabel: getSisenseColumnLabel(x.name) };
    } else if (areUnmatchedHidden === true) {
      return { ...x, columnNumber: (j + 100) * -1, columnLabel: getSisenseColumnLabel(x.name) };
    } else {
      return { ...x, columnNumber: j + 100, columnLabel: getSisenseColumnLabel(x.name) };
    }
  });

  newFormattedData.columns = newColumnArray.sort((a, b) => (a.columnNumber - b.columnNumber));

  for (let i = 0; i < dataCopy.rows?.length; i++) {
    const newRowObject = dataCopy.rows[i]?.map((x, j) => {
      const targetKeyValue = columnIndexKeyValues.find(x => x[1] === j);

      if (targetKeyValue) {
        const targetKey = targetKeyValue[0];

        return { ...x, columnNumber: columnNumbers[targetKey], cellText: getSisenseCellText(x.text) };
      } else if (areUnmatchedHidden === true) {
        return { ...x, columnNumber: (j + 100) * -1, cellText: getSisenseCellText(x.text) };
      } else {
        return { ...x, columnNumber: j + 100, cellText: getSisenseCellText(x.text) };
      }
    });

    const newRowObjectSorted = newRowObject.sort((a, b) => (a.columnNumber - b.columnNumber));
    newFormattedData.rows.push(newRowObjectSorted);
  }

  console.log(newFormattedData);
  return newFormattedData;
};

export {
  getSisenseColumnLabel,
  getSisenseCellText,
  getSisenseFormattedData
};