import React from 'react';

import useClubStaffTreeView from './UseClubStaffTreeView';

import TreeView from '../../../common/components/tree/TreeView';

import global from '../../../common/components/GlobalStyle.module.css';

const ClubStaffTreeView = ({ label, name, value, error, message, onChange, singleSelect }) => {
  const {
    isLoading,
    apiMessage,
    optionsState
  } = useClubStaffTreeView();

  return apiMessage
    ? <span className={global.LoadingMsg}>{apiMessage}</span>
    : (
      <TreeView
        label={label}
        name={name}
        value={value}
        options={optionsState}
        error={error}
        message={message}
        isLoading={isLoading}
        singleSelect={singleSelect}
        selectableLeavesOnly={true}
        onChange={onChange} />
    );
};

export default ClubStaffTreeView;