import { useEffect } from 'react';
import useGroupCoachesData from '../../../state/groupCoaches/UseGroupCoachesData';

const useGroupCoachesMultiselect = (orgUnitId = null, usasClubId = null) => {
  const { groupCoachesState, getGroupCoachesByOrgUnitId, getGroupCoachesByUsasClubId } = useGroupCoachesData();

  useEffect(() => {
    if (orgUnitId) {
      getGroupCoachesByOrgUnitId(orgUnitId);
    } else if (usasClubId) {
      getGroupCoachesByUsasClubId(usasClubId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orgUnitId, usasClubId])

  return {
    groupCoachesState
  };
};

export default useGroupCoachesMultiselect;