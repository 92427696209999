import React, { createContext, useState } from 'react';

import PreCompOfferingTypeData from './PreCompOfferingTypeData';

export const PreCompOfferingTypeStateContext = createContext();

const PreCompOfferingTypeContextProvider = ({ children }) => {
  const stateHook = useState(PreCompOfferingTypeData.INITIAL_STATE);

  return (
    <PreCompOfferingTypeStateContext.Provider value={stateHook}>
      {children}
    </PreCompOfferingTypeStateContext.Provider>
  );
};

export default PreCompOfferingTypeContextProvider;