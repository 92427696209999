import React from 'react';

import useRadioButtonList from './UseOfferingTypeRegistrationRadioButtonList';

import { formatDate } from '../../../common/utils/DateFunctions';
import global from '../../../common/components/GlobalStyle.module.css';
import { FormatMoneyWithSymbol } from '../../../common/utils/FormatMoney';

import style from './OfferingTypeRegistrationRadiobuttonList.module.css';

const OfferingTypeRegistrationRadiobuttonList = ({ name, label, error, message, value, isVertical, onChange, orgUnitId, category }) => {
  const { offeringTypeState, isDisabled } = useRadioButtonList(orgUnitId, category);

  return (
    <div className={global.ComponentFlex}>
      <label className={global.UsasLabel} htmlFor={name}>{label}</label>
      <div className={isVertical === true ? style.VerticalRadios : style.HorizontalRadios}>
        {offeringTypeState.arrayData && offeringTypeState.arrayData.map((offeringType) => (
          <div key={offeringType.offeringTypeId}>
            {offeringType.offerings && offeringType.offerings.map((offering) => (
              <div className={style.RadioButton} key={offering.offeringInstanceId}>
                <label htmlFor={name + "_" + offering.offeringInstanceId}>
                  <input
                    type="radio"
                    id={name + "_" + offering.offeringInstanceId}
                    name={name}
                    value={offering.offeringInstanceId}
                    checked={value === offering.offeringInstanceId}
                    onChange={(e) => { onChange(e.target.value) }}
                    disabled={isDisabled === true ? 'disabled' : ''}
                  />
                  <span className={global.Bold}>
                    {offering.offeringName} -- USA Swimming Fee: {FormatMoneyWithSymbol(offering.usasPrice)} -- LSC Fee: {FormatMoneyWithSymbol(offering.lscPrice)}
                  </span>
                  <div className={style.Description}>
                    <div className="row">
                      <div className="col-xs-12">
                        {offering.offeringDescription}
                      </div>
                    </div>
                    <span>Registration Dates: {formatDate(offering.effectiveDate)} - {formatDate(offering.expirationDate)}</span>
                  </div>
                </label>
              </div>))}
          </div>
        ))}
      </div>
      {error && <p className={global.ErrorMessage}>{message}</p>}
    </div>
  );
};

export default OfferingTypeRegistrationRadiobuttonList;