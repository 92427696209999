import { BASIC_INITIAL_STATE } from '../../../common/utils/HttpHelper';

import postClubRenew from './PostClubRenewData';

const INITIAL_STATE = {
  ...BASIC_INITIAL_STATE
};

const ClubRenewData = {
  INITIAL_STATE,
  postClubRenew
};

export default ClubRenewData;