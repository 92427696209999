import { useEffect, useState } from 'react';
import { useNavigate } from '../../../../common/wrappers/ReactRouterDom';

import useLearnToSwimData from '../../../state/learnToSwim/UseLearnToSwimData';
import useLearnToSwimStaffData from '../../../state/learnToSwimStaff/UseLearnToSwimStaffData';

import NavLinks from '../utils/NavLinks';
import { PRIMARY_CONTACT_ORG_ROLE_NAME } from '../utils/LearnToSwimConstants';
import { createStaffMember, findCurrentPrimaryContact, findLocalDupPerson } from '../utils/LearnToSwimUtils';

import usePersonData from '../../../../common/state/person/UsePersonData';
import useEnvironmentVariableData from '../../../../common/state/environmentVariable/UseEnvironmentVariableData';

const INITIAL_PRIMARY_CONTACT_MODAL_STATE = {
  currentPrimaryContactPersonId: '',
  currentPrimaryContact: {},
  isOnlyRole: false,
  updateCurrentPrimaryContact: false, // whether or not to PUT the current primary before POST of new primary
  displayModal: false
};

const useLearnToSwimStaffAdd = () => {
  const navigate = useNavigate();
  const { environmentVariableState, LTS_STAFF_ROLE_GROUP_ID } = useEnvironmentVariableData();
  const { ltsProviderState } = useLearnToSwimData();
  const { ltsStaffState, ltsStaffMemberState, putLTSStaffMember, postLTSStaffMember } = useLearnToSwimStaffData();
  const { personState, getPotentialPersonDupes } = usePersonData();
  const [primaryContactModalState, setPrimaryContactModalState] = useState(INITIAL_PRIMARY_CONTACT_MODAL_STATE);
  const [newPersonState, setNewPersonState] = useState({});
  const [displayDupModalState, setDisplayDupModalState] = useState(false);
  const [displayLocalDupModalState, setDisplayLocalDupModalState] = useState(false);

  const onCancelClicked = () => {
    navigate(NavLinks.LEARN_TO_SWIM_STAFF);
  };

  const onPrimaryContactModalContinueClicked = () => {
    setPrimaryContactModalState({
      ...primaryContactModalState,
      updateCurrentPrimaryContact: true,
      displayModal: false
    });

    getPotentialPersonDupes(newPersonState.firstName, newPersonState.lastName, newPersonState.birthDate);
  };

  const onPrimaryContactModalCancelClicked = () => {
    setPrimaryContactModalState({ ...INITIAL_PRIMARY_CONTACT_MODAL_STATE });
  };

  const onDupModalCancelClicked = () => {
    setDisplayDupModalState(false);
    setPrimaryContactModalState({ ...INITIAL_PRIMARY_CONTACT_MODAL_STATE });
  };

  const onLocalDupModalCancelClicked = () => {
    setDisplayLocalDupModalState(false);
  };

  const onPersonConfirmed = (personObj) => {
    const orgUnitId = ltsProviderState.objData?.orgUnitId;
    const staffObj = createStaffMember(personObj, newPersonState);

    if (orgUnitId) {
      if (primaryContactModalState.updateCurrentPrimaryContact === true) {
        const primaryContactStaffObj = createStaffMember(primaryContactModalState.currentPrimaryContact, { orgRoles: primaryContactModalState.currentPrimaryContact.personOrgRole.filter((x) => x.orgRoleName !== PRIMARY_CONTACT_ORG_ROLE_NAME) });
        const putLTSStaffMemberPromise = putLTSStaffMember(orgUnitId, primaryContactModalState.currentPrimaryContactPersonId, LTS_STAFF_ROLE_GROUP_ID, primaryContactStaffObj);

        if (putLTSStaffMemberPromise !== null) {
          putLTSStaffMemberPromise.then((newState) => {
            if (newState !== null) {
              const postLTSStaffMemberPromise = postLTSStaffMember(orgUnitId, LTS_STAFF_ROLE_GROUP_ID, staffObj);

              if (postLTSStaffMemberPromise !== null) {
                postLTSStaffMemberPromise.then((newState) => {
                  if (newState !== null) {
                    navigate(NavLinks.LEARN_TO_SWIM_STAFF, { state: { refreshState: true } })
                  }
                }).catch((e) => {
                  //TODO state error
                });
              }
            }
          }).catch((e) => {
            //TODO state error
          });
        }
      } else {
        const postLTSStaffMemberPromise = postLTSStaffMember(orgUnitId, LTS_STAFF_ROLE_GROUP_ID, staffObj);

        if (postLTSStaffMemberPromise !== null) {
          postLTSStaffMemberPromise.then((newState) => {
            if (newState !== null) {
              navigate(NavLinks.LEARN_TO_SWIM_STAFF, { state: { refreshState: true } })
            }
          }).catch((e) => {
            //TODO state error
          });
        }
      }
    }
  };

  useEffect(() => {
    if (personState.isArrayLoaded === true) {
      setDisplayDupModalState(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [personState.isArrayLoaded]);

  const onSubmitFormCallback = (formState) => {
    const localDupPerson = findLocalDupPerson(ltsStaffState.arrayData, formState.firstName, formState.lastName, formState.birthDate);

    setNewPersonState({ ...formState });

    if (localDupPerson) {
      setDisplayLocalDupModalState(true);
    } else {
      const isPrimaryContactSelected = formState.orgRoles.some((x) => x.name === PRIMARY_CONTACT_ORG_ROLE_NAME);

      if (isPrimaryContactSelected === true) {
        const currentPrimaryContact = findCurrentPrimaryContact(ltsStaffState.arrayData);

        if (currentPrimaryContact) {
          setPrimaryContactModalState({
            ...primaryContactModalState,
            currentPrimaryContactPersonId: currentPrimaryContact.personId,
            currentPrimaryContact,
            isOnlyRole: currentPrimaryContact.personOrgRole.length === 1,
            displayModal: true
          });
        } else {
          getPotentialPersonDupes(formState.firstName, formState.lastName, formState.birthDate);
        }
      } else {
        getPotentialPersonDupes(formState.firstName, formState.lastName, formState.birthDate);
      }
    }
  };

  return {
    isSaving: ltsStaffMemberState.isObjLoading,
    isLoading: personState.isArrayLoading || environmentVariableState.isLoading,
    dupPersons: personState.arrayData,
    newPersonState,
    primaryContactModalState,
    displayDupModalState,
    displayLocalDupModalState,
    onSubmitFormCallback,
    onCancelClicked,
    onPrimaryContactModalContinueClicked,
    onPrimaryContactModalCancelClicked,
    onDupModalCancelClicked,
    onLocalDupModalCancelClicked,
    onPersonConfirmed,
    LTS_STAFF_ROLE_GROUP_ID
  };
};

export default useLearnToSwimStaffAdd;