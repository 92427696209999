import Constants from "../../../../../common/utils/Constants";
import { isValidDate } from "../../../../../common/utils/validation";

export const localValidate = (formState) => {
  let errors = {};

  if (formState.reportPeriodId < 0) {
    errors.reportPeriodId = 'Registration Year is required';
  }

  if (formState.registrationStartDate === Constants.BLANK_DATE_STRING) {
    errors.registrationStartDate = 'Registration Start Date is required';
  } else if (formState.registrationStartDate === Constants.BLANK_DATE_STRING
    || !isValidDate(formState.registrationStartDate)) {
    errors.registrationStartDate = 'Registration Start Date must be a valid date';
  }

  if (formState.registrationEndDate === Constants.BLANK_DATE_STRING) {
    errors.registrationEndDate = 'Registration End Date is required';
  } else if (formState.registrationEndDate === Constants.BLANK_DATE_STRING
    || !isValidDate(formState.registrationEndDate)) {
    errors.registrationEndDate = 'Registration End Date must be a valid date';
  }

  if (errors.registrationStartDate === undefined && errors.registrationEndDate === undefined) {
    const registrationStartDate = new Date(formState.registrationStartDate);
    const registrationEndDate = new Date(formState.registrationEndDate);

    if (registrationStartDate > registrationEndDate) {
      errors.registrationStartDate = 'Registration Start Date must be before the Registration End Date';
      errors.registrationEndDate = 'Registration End Date must be after the Registration Start Date';
    }
  }

  return errors;
};

const LSCRegistrationManagementFormValidation = async (formState) => {
  let errors = localValidate(formState);

  return errors ? errors : {};
};

export default LSCRegistrationManagementFormValidation;