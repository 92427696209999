import { OmrHttpHelper, NO_DATA_MESSAGE, BASIC_INITIAL_STATE } from '../../../common/utils/HttpHelper';

const deleteCommunitClubMemberLoggedInPersonByIdData = (communityClubMemberLoggedInPersonId, state, setState) => {
  if (state.isSaving !== true && communityClubMemberLoggedInPersonId && !state.message) {
    let newState = {
      ...state,
      isSaving: true,
      isSaved: false,
      message: 'Deleting...'
    };
    setState(newState);

    const communityClubMemberLoggedInPersonIdForUrl = communityClubMemberLoggedInPersonId ? encodeURIComponent(communityClubMemberLoggedInPersonId) : '';
    const url = `/MemberRegistration/CommunityClubMemberLoggedInPerson/${communityClubMemberLoggedInPersonIdForUrl}`;
    OmrHttpHelper(url, 'DELETE')
      .then((objData) => {
        if (!objData) {
          throw new Error(NO_DATA_MESSAGE);
        } else {
          newState = {
            ...newState,
            isSaving: false,
            isSaved: true,
            objData,
            message: ''
          };
          setState(newState);
        }

      })
      .catch((e) => {
        const message = e && e.message ? e.message : NO_DATA_MESSAGE;
        setState({
          ...newState,
          isSaving: false,
          isSaved: false,
          message: message
        });
      });
  }
};

const CommunityClubMemberLoggedInPersonData = { INITIAL_STATE: BASIC_INITIAL_STATE, deleteCommunitClubMemberLoggedInPersonByIdData };

export default CommunityClubMemberLoggedInPersonData;