/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';

import Checkbox from '../../../../../common/components/checkboxes/Checkbox';

import { formatDate } from '../../../../../common/utils/DateFunctions';

import global from '../../../../../common/components/GlobalStyle.module.css';

const GridRow = ({ person, onSelected }) => (
  <div className={global.SmallTableRow}>
    <div className={global.SmallTableRowHead}><span className='hidden-xs'>{person.firstName || ''} {person.lastName || ''}</span>&nbsp;
      <div className={global.FloatRight}>
        <Checkbox
          label={`personId${person.personId}`}
          name="isSelected"
          onChange={() => onSelected(person.personId)}
          checked={person.isSelected} />
      </div>
    </div>
    <div className={global.SmallTableRowInfo}>
      <div className='row'>
        <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3 visible-xs'].join(' ')}>Name</div>
        <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3 visible-xs'].join(' ')}>{person.firstName} {person.lastName}</div>
        <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3'].join(' ')}>Birth Date</div>
        <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3'].join(' ')}>{formatDate(person.birthDate)}</div>
        <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3'].join(' ')}>Organization</div>
        <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3'].join(' ')}>{person.orgDuration?.orgUnitName || <span>&nbsp;</span>}</div>
        <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3'].join(' ')}>Effective Date</div>
        <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3'].join(' ')}>{person.orgDuration?.effectiveDate ? formatDate(person.orgDuration.effectiveDate) : <span>&nbsp;</span>}</div>
        <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3'].join(' ')}>Expiration Date</div>
        <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3'].join(' ')}>{person.orgDuration?.expirationDate ? formatDate(person.orgDuration.expirationDate) : <span>&nbsp;</span>}</div>
      </div>
    </div>
  </div>
);

const ProviderStaffDupGridSmall = ({ persons, onSelected }) => (
  <div className={[global.SmallTable, 'hidden-md hidden-lg'].join(' ')}>
    {persons.map((person, i) => <GridRow key={i} person={person} onSelected={onSelected} />)}
  </div>
);

export default ProviderStaffDupGridSmall;