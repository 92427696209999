import useEnvironmentVariableData from '../../../../../../common/state/environmentVariable/UseEnvironmentVariableData';

const useClubPerformanceAthleteTimesProgression = () => {
  const { environmentVariableState, INDIVIDUAL_TIMES_SEARCH_PROGRESSION_OID } = useEnvironmentVariableData();

  return {
    environmentVariableState, INDIVIDUAL_TIMES_SEARCH_PROGRESSION_OID
  };
};

export default useClubPerformanceAthleteTimesProgression;