import { useState } from 'react';

import LearnToSwimBulkImportFilesData from './LearnToSwimBulkImportFilesData';

const useLearnToSwimBulkImportFilesData = () => {
  const [learnToSwimBulkImportFilesState, setLearnToSwimBulkImportFilesState] = useState(LearnToSwimBulkImportFilesData.INITIAL_STATE);

  return {
    learnToSwimBulkImportFilesState,
    setLearnToSwimBulkImportFilesState,
    GET_DOWNLOAD_TEMPLATE_PATH: LearnToSwimBulkImportFilesData.GET_DOWNLOAD_TEMPLATE_PATH,
  };
};

export default useLearnToSwimBulkImportFilesData;