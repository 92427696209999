
import { useState, useEffect } from 'react';
import { useParams } from '../../../../common/wrappers/ReactRouterDom';
import useSecurityData from '../../../../common/state/security/UseSecurityData';
import useRouteCodeValueData from '../../../../common/state/routeCode/UseRouteCodeValueData';
import useLearnToSwimParticipantsData from '../../../state/learnToSwimParticipants/UseLearnToSwimParticipantsData';
import useLearnToSwimVoucherData from '../../../state/learnToSwimVoucher/UseLearnToSwimVoucherData';

const INITIAL_MODAL_STATE = {
  displayModal: false
};

const useLearnToSwimVoucher = () => {
  const { routeCode } = useParams();
  const { securityState } = useSecurityData();
  const { routeCodeValueState, getRouteCodeValue } = useRouteCodeValueData();
  const { ltsParticipantState, postLTSParticipant, getLTSParticipantDup } = useLearnToSwimParticipantsData();
  const { ltsVoucherCityState, getLTSVoucherCity } = useLearnToSwimVoucherData();
  const [state, setState] = useState({ isExpired: false });
  const [modalState, setModalState] = useState(INITIAL_MODAL_STATE);

  const isLoggedIn = securityState?.userInfo?.isLoggedIn === true;

  const onSubmitFormCallback = (formState) => {
    const postObj = {
      learnToSwimVoucherCityVoucherId: routeCodeValueState.learnToSwimVoucherCityVoucherId,
      firstName: formState.firstName,
      lastName: formState.lastName,
      competitionGenderTypeId: formState.competitionGenderTypeId,
      postalCode: formState.postalCode,
      birthDate: formState.birthDate || Constants.BLANK_DATE_STRING,
      email: formState.email,
      routeCode: routeCodeValueState.objData.routeCodeValue
    }

    const getLTSParticipantDupPromise = getLTSParticipantDup(formState.firstName, formState.lastName, formState.birthDate);

    if (getLTSParticipantDupPromise !== null) {
      getLTSParticipantDupPromise.then((newState) => {
        if (newState !== null) {
          if (newState.objData?.learnToSwimVoucherCityVoucherPersonId > 0) {
            setModalState({
              ...modalState,
              displayModal: true
            });
          } else {
            const postLTSParticipantPromise = postLTSParticipant(postObj);
            if (postLTSParticipantPromise !== null) {
              postLTSParticipantPromise.then((newState) => {
                if (newState !== null) {
                  //nothing to do here
                }
              }).catch((e) => {
                //TODO context error
              });
            }
          }
        }
      }).catch((e) => {
        //TODO state error
      });
    }
  }

  const onRegisterAnotherParticipant = () => {
    location.reload();
  }

  const onModalCancelClicked = () => {
    setModalState({
      ...INITIAL_MODAL_STATE
    });
    location.reload();
  };

  useEffect(() => {
    if (routeCode && routeCodeValueState.isObjLoaded !== true) {
      getRouteCodeValue(routeCode);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let isVoucherExpired = false;
    if (routeCodeValueState.isObjLoaded === true) {
      if (routeCodeValueState.objData.routeCodeName === "Error - not an active route code") {
        isVoucherExpired = true;
      }
      setState({ ...state, isExpired: isVoucherExpired });

      if (isVoucherExpired === false) {
        const getLTSVoucherCityPromise = getLTSVoucherCity(routeCodeValueState.learnToSwimVoucherCityVoucherId);
        if (getLTSVoucherCityPromise !== null) {
          getLTSVoucherCityPromise.then((newState) => {
            //no action
          });
        };
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [routeCodeValueState]);

  return {
    routeCode,
    routeCodeValueState,
    ltsParticipantState,
    ltsVoucherCityState,
    state,
    modalState,
    isLoggedIn,
    onSubmitFormCallback,
    onRegisterAnotherParticipant,
    onModalCancelClicked
  }
}

export default useLearnToSwimVoucher;