import React, { Fragment } from 'react';

import PreCompLocationProgramsGridLarge from './PreCompLocationProgramsGridLarge';
import PreCompLocationProgramsGridSmall from './PreCompLocationProgramsGridSmall';

const PreCompLocationProgramsGrid = ({ data, isLoading, onEdit, onDelete }) => (
  <Fragment>
    <PreCompLocationProgramsGridLarge data={data} isLoading={isLoading} onEdit={onEdit} onDelete={onDelete} />
    <PreCompLocationProgramsGridSmall data={data} isLoading={isLoading} onEdit={onEdit} onDelete={onDelete} />
  </Fragment>
);

export default PreCompLocationProgramsGrid;