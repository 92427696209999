import React, { createContext, useState } from 'react';

import PoolDimensionsData from './PoolDimensionsData';

export const PoolDimensionsStateContext = createContext();

const PoolDimensionsContextProvider = ({ children }) => {
  const stateHook = useState(PoolDimensionsData.INITIAL_STATE);

  return (
    <PoolDimensionsStateContext.Provider value={stateHook}>
      {children}
    </PoolDimensionsStateContext.Provider>
  );
};

export default PoolDimensionsContextProvider;