import { useState } from 'react';
import ClubVendorData from './ClubVendorData';

const useClubVendorData = () => {
  const [clubVendorState, setClubVendorState] = useState(ClubVendorData.INITIAL_STATE);

  const getClubVendorsByOrgUnit = (orgUnitId) => {
    ClubVendorData.getClubVendorsByOrgUnit(orgUnitId, clubVendorState, setClubVendorState);
  };

  const deleteClubVendorFromOrgUnit = (orgUnitVendorId) => {
    ClubVendorData.deleteClubVendorFromOrgUnit(orgUnitVendorId, clubVendorState, setClubVendorState);
  };

  const postClubVendorForOrgUnit = (clubVendorObj) => {
    ClubVendorData.postClubVendorForOrgUnit(clubVendorObj, clubVendorState, setClubVendorState);
  };

  return {
    clubVendorState,
    setClubVendorState,
    getClubVendorsByOrgUnit,
    deleteClubVendorFromOrgUnit,
    postClubVendorForOrgUnit
  };
};

export default useClubVendorData;