import Constants from "../../../../../common/utils/Constants";

export const localValidate = (formState) => {
  let errors = {};

  if (formState.logoFile?.size) {
    if (formState.logoFile.type !== Constants.FILE_TYPE_JPG) {
      errors.logoFile = 'File type not permitted. Please provide a (*.jpg) file.';
    }
  }

  if (formState.curriculumFile?.size) {
    if (formState.curriculumFile.type !== Constants.FILE_TYPE_PDF && formState.curriculumFile.type !== Constants.FILE_TYPE_DOC &&
      formState.curriculumFile.type !== Constants.FILE_TYPE_DOCX) {
      errors.curriculumFile = 'File type not permitted. Please provide a (*.pdf), (*.doc), or (*.docx) file.';
    }
  }

  if (formState.proofOfInsuranceFile?.size) {
    if (formState.proofOfInsuranceFile.type !== Constants.FILE_TYPE_PDF && formState.proofOfInsuranceFile.type !== Constants.FILE_TYPE_DOC &&
      formState.proofOfInsuranceFile.type !== Constants.FILE_TYPE_DOCX) {
      errors.proofOfInsuranceFile = 'File type not permitted. Please provide a (*.pdf), (*.doc), or (*.docx) file.';
    }
  }

  if (formState.formW9File?.size) {
    if (formState.formW9File.type !== Constants.FILE_TYPE_PDF && formState.formW9File.type !== Constants.FILE_TYPE_DOC &&
      formState.formW9File.type !== Constants.FILE_TYPE_DOCX) {
      errors.formW9File = 'File type not permitted. Please provide a (*.pdf), (*.doc), or (*.docx) file.';
    }
  }

  return errors;
};

const RegistrationSubmitFormValidation = async (formState) => {
  let errors = localValidate(formState);

  return errors ? errors : {};
};

export default RegistrationSubmitFormValidation;