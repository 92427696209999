import React, { Fragment } from 'react';

import useLSCStaffMembers from './UseLSCStaffMembers';

import LSCStaffMembersGrid from './LSCStaffMembersGrid';

import YesNoSwitch from '../../../../common/components/yesNoSwitch/YesNoSwitch';
import PrimaryButton from '../../../../common/components/buttons/PrimaryButton';
import Headings from '../../../../common/components/headings/Headings';
import PopUpModal from '../../../../common/components/dialogs/PopUpModal';
import SecondaryButton from '../../../../common/components/buttons/SecondaryButton';
import ActionIntraPageButton from '../../../../common/components/buttons/ActionIntraPageButton'

import Constants from '../../../../common/utils/Constants';

const LSCStaffMembers = () => {
  const {
    isLoading,
    isSaving,
    isGridLoading,
    gridState,
    viewState,
    deleteModalState,
    onAddStaffMember,
    onEditStaffRole,
    onDeleteStaffRole,
    onFilterCurrentStaff,
    onModalDeleteClicked,
    onModalCancelClicked
  } = useLSCStaffMembers();
  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>LSC Staff</Headings.H3>
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12 col-sm-8 col-md-9 usas-extra-top-margin">
          <ActionIntraPageButton type="button" onClick={onAddStaffMember}>Add Staff Member</ActionIntraPageButton>
        </div>
        <div className="col-xs-12 col-sm-4 col-md-3">
          <YesNoSwitch
            label="Show Only Current Staff/Roles?"
            name="showCurrentStaff"
            checked={viewState.toggleCurrentStaffRoles}
            onChange={onFilterCurrentStaff} />
        </div>
      </div>
      <div className="row usas-extra-bottom-margin">
        <div className="col-xs-12">
          <LSCStaffMembersGrid
            data={gridState}
            isLoading={isGridLoading}
            onEdit={onEditStaffRole}
            onDelete={onDeleteStaffRole} />
        </div>
      </div>
      <PopUpModal
        widthPct={90}
        maxWidth={720}
        title={'Delete Staff Role?'}
        displayPopUp={deleteModalState.displayModal}
        onModalCanceled={onModalCancelClicked}>
        <div className="row">
          <div className="col-xs-12">
            <p><b>Staff Member:</b> {deleteModalState.personName}</p>
            <p><b>Role:</b> {deleteModalState.orgRoleName}</p>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12 usas-extra-top-margin">
            <PrimaryButton type="button" onClick={onModalDeleteClicked}>Delete Staff Role</PrimaryButton>&nbsp;
            <SecondaryButton type="button" onClick={onModalCancelClicked}>Cancel</SecondaryButton>
          </div>
        </div>
      </PopUpModal>
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={isLoading || isGridLoading} />
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.SAVING_MSG}
        displayPopUp={isSaving} />
    </Fragment>
  );
};

export default LSCStaffMembers;