import { isValidNumber } from '../../../../common/utils/validation';

export const localValidate = (formState) => {
  let errors = {};

  //Owner Type
  if (formState.clubOwnerTypeId < 0) {
    errors.clubOwnerTypeId = 'Owner Type is required'
  }

  //Tax Listing
  if (formState.businessEntityTypeId < 0) {
    errors.businessEntityTypeId = 'Tax Listing is required'
  }

  //Number of Board Members
  if (formState.isNumOfBoardMembersRequired === true) {
    if (formState.numOfBoardMembers === '') {
      errors.numOfBoardMembers = 'Number of Board Members is required for the selected Owner Type'
    } else if (!isValidNumber(Number(formState.numOfBoardMembers)) || Number(formState.numOfBoardMembers) > 15 || Number(formState.numOfBoardMembers) < 1 || !Number.isInteger(Number(formState.numOfBoardMembers))) {
      errors.numOfBoardMembers = 'Number of Board Members must be a whole number between 1 and 15'
    }
  }

  return errors;
};

const ClubRegistrationDisclaimerValidation = async (formState) => {
  let errors = localValidate(formState);

  return errors ? errors : {};
}

export default ClubRegistrationDisclaimerValidation;