import { Fragment } from "react";

import useLearnToSwimSearch from "./UseLearnToSwimSearch";

import ProviderSearchForm from "../components/forms/ProviderSearchForm";
import ProviderSearchGrid from "../components/grids/ProviderSearchGrid";

import Headings from "../../../../common/components/headings/Headings";
import PopUpModal from "../../../../common/components/dialogs/PopUpModal";

import Constants from "../../../../common/utils/Constants";

import global from '../../../../common/components/GlobalStyle.module.css';

const LearnToSwimSearch = () => {
  const {
    isLoading,
    gridData,
    ltsProviderFilterState,
    onEditLTSProviderClicked,
    onSubmitFormCallback
  } = useLearnToSwimSearch();

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>Learn To Swim Provider Search</Headings.H3>
        </div>
      </div>
      <div className={ltsProviderFilterState.showFilters === true ? global.DisplayComponent : global.HideComponent}>
        <ProviderSearchForm
          searchFilterObject={ltsProviderFilterState.filterObject}
          onSubmitFormCallback={onSubmitFormCallback} />
      </div>
      <ProviderSearchGrid
        data={gridData}
        isLoading={isLoading}
        onEditClicked={onEditLTSProviderClicked} />
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={isLoading} />
    </Fragment>
  );
};

export default LearnToSwimSearch;