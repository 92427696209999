import React, { Fragment } from 'react';

import SATResultsGridLarge from './SATResultsGridLarge';
import SATResultsGridSmall from './SATResultsGridSmall';

const SATResultsGrid = ({ data, isLoading, onEdit = undefined }) => (
  <Fragment>
    <SATResultsGridLarge data={data} isLoading={isLoading} onEdit={onEdit} />
    <SATResultsGridSmall data={data} isLoading={isLoading} onEdit={onEdit} />
  </Fragment>
);

export default SATResultsGrid;