import React, { Fragment } from 'react';

import useExpandedRow from '../../../../../common/components/grids/UseExpandedRow';

import ProviderStaffGridLarge from './ProviderStaffGridLarge';
import ProviderStaffGridSmall from './ProviderStaffGridSmall';

const ProviderStaffGrid = ({ data, isLoading, onEditClicked, onDeleteClicked, onDeleteRoleClicked }) => {
  const { expandedId, onClick } = useExpandedRow();

  return (
    <Fragment>
      <ProviderStaffGridLarge data={data} isLoading={isLoading} onEditClicked={onEditClicked} onDeleteClicked={onDeleteClicked}
        onDeleteRoleClicked={onDeleteRoleClicked} expandedId={expandedId} onExpand={onClick} />
      <ProviderStaffGridSmall data={data} isLoading={isLoading} onEditClicked={onEditClicked} onDeleteClicked={onDeleteClicked}
        onDeleteRoleClicked={onDeleteRoleClicked} expandedId={expandedId} onExpand={onClick} />
    </Fragment>
  );
};

export default ProviderStaffGrid;