import Constants from "../../../../../common/utils/Constants";

export const localValidate = (memberTypeSelectionModalFormState) => {
  let errors = {};

  if (memberTypeSelectionModalFormState.index === Constants.DEFAULT_ID) {
    errors.index = 'Renewal Membership is required';
  }

  return errors;
};

const ClubBulkRenewFilterFormValidation = async (memberTypeSelectionModalFormState) => {
  let errors = localValidate(memberTypeSelectionModalFormState);

  return errors ? errors : {};
};

export default ClubBulkRenewFilterFormValidation;