import React, { createContext, useState } from 'react';

import OrgGroupTypesData from './OrgGroupTypesData';

export const OrgGroupTypesStateContext = createContext();

const OrgGroupTypesContextProvider = ({ children }) => {
  const stateHook = useState(OrgGroupTypesData.INITIAL_STATE);

  return (
    <OrgGroupTypesStateContext.Provider value={stateHook}>
      {children}
    </OrgGroupTypesStateContext.Provider>
  );
};

export default OrgGroupTypesContextProvider;