import { useEffect, useState } from 'react';
import { useNavigate, useLocation } from '../../../../../common/wrappers/ReactRouterDom';

import validate from './AddLSCBoardMemberValidation';

import { navLinks } from '../../UseMemberAdministration';

import useMemberAdministrationLscBoardData from '../../../../../common/state/memberAdministration/lscBoard/UseMemberAdministrationLscBoardData';
import useEnvironmentVariableData from '../../../../../common/state/environmentVariable/UseEnvironmentVariableData';

import useForm from '../../../../../common/utils/UseForm';
import Constants from '../../../../../common/utils/Constants';
import { formatDate } from '../../../../../common/utils/DateFunctions';

const useAddLSCBoardMember = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { LSC_BOARD_OF_DIRECTORS_ROLE_GROUP_ID, environmentVariableState } = useEnvironmentVariableData();
  const { memberAdministrationLscBoardState, postLscBoardMember, putLscBoardMember } = useMemberAdministrationLscBoardData();
  const { formState, errorState, isSubmitting, onFormValueChanged, handleSubmit, setErrors, setFormData, setIsDirty
  } = useForm(getInitialFormState(), submitFormCallback, validate);
  const [viewState, setViewState] = useState(getInitialViewState);

  const onSaveClicked = () => {
    setIsDirty(true);
    handleSubmit();
  }

  const onBackClicked = () => {
    navigate(navLinks.LSC_BOARD);
  };

  useEffect(() => {
    if (location.state?.activeUserOrgUnitId && environmentVariableState.isLoaded === true) {
      setViewState({
        ...viewState,
        activeUserOrgUnitId: location.state.activeUserOrgUnitId
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [environmentVariableState]);

  useEffect(() => {
    if (memberAdministrationLscBoardState.isSaved === true) {
      navigate(navLinks.LSC_BOARD);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [memberAdministrationLscBoardState.isSaved]);

  function submitFormCallback() {
    const existingPersonOrgRoles = getExistingPersonOrgRoles(formState.personId);

    if (existingPersonOrgRoles) {
      if (isDurationValid(formState.orgRole[0].id, formState.effectiveDate, formState.hasNoExpirationDate === false ? formState.expirationDate : Constants.DEFAULT_MAX_DATE, existingPersonOrgRoles)) {
        putLscBoardMember(viewState.activeUserOrgUnitId, formState.personId, LSC_BOARD_OF_DIRECTORS_ROLE_GROUP_ID, createStaffObject(formState, existingPersonOrgRoles));
      } else {
        const overlap = getOverlappingDates(formState.orgRole[0].id, formState.effectiveDate, formState.hasNoExpirationDate === false ? formState.expirationDate : Constants.DEFAULT_MAX_DATE, existingPersonOrgRoles);
        setErrors({
          multipleActiveRoles: `This member has an existing ${formState.orgRole[0].name} role from ${formatDate(overlap?.effectiveDate)} - ${formatDate(overlap?.expirationDate)}. Date overlaps are not allowed.`
        });
      }
    } else {
      postLscBoardMember(viewState.activeUserOrgUnitId, LSC_BOARD_OF_DIRECTORS_ROLE_GROUP_ID, createStaffObject(formState));
    }
  };

  function getExistingPersonOrgRoles(personId) {
    const existingPerson = memberAdministrationLscBoardState.arrayData.find(x => x.personId === personId);

    if (existingPerson && Array.isArray(existingPerson.orgRoles)) {
      return existingPerson.orgRoles;
    } else {
      return null;
    }
  };

  function isDurationValid(orgRoleId, effectiveDate, expirationDate, orgRoles) {
    let overlappingDate = false;

    for (const role of orgRoles) {
      if (role.orgRoleId === orgRoleId) {
        if (new Date(effectiveDate) <= new Date(role.expirationDate)
          && new Date(expirationDate) >= new Date(role.effectiveDate)) {
          overlappingDate = true;
          break;
        }
      }
    }

    return !overlappingDate;
  };

  function getOverlappingDates(orgRoleId, effectiveDate, expirationDate, orgRoles) {
    let overlappingDuration = {};

    for (const role of orgRoles) {
      if (role.orgRoleId === orgRoleId) {
        if (new Date(effectiveDate) <= new Date(role.expirationDate)
          && new Date(expirationDate) >= new Date(role.effectiveDate)) {
          overlappingDuration = role;
          break;
        }
      }
    }

    return overlappingDuration;
  };

  function createStaffObject(formState, existingPersonOrgRoles) {
    const filteredPersonOrgRoles = [];

    if (Array.isArray(existingPersonOrgRoles)) {
      for (const role of existingPersonOrgRoles) {
        if (role.isCurrent === true) {
          filteredPersonOrgRoles.push({
            orgRoleId: role.orgRoleId,
            orgRoleName: role.orgRoleName,
            personOrgRoleId: role.personOrgRoleId,
            personOrgRoleDurationId: role.personOrgRoleDurationId
          });
        }
      }
    }

    return {
      personId: formState.personId,
      personOrgRole: [
        {
          orgRoleId: formState.orgRole[0].id,
          orgRoleName: formState.orgRole[0].name,
          effectiveDate: formState.effectiveDate,
          expirationDate: formState.hasNoExpirationDate === false ? formState.expirationDate : Constants.DEFAULT_MAX_DATE,
        },
        ...filteredPersonOrgRoles
      ]
    };
  };


  function getInitialFormState() {
    return {
      orgRole: [],
      memberId: '',
      effectiveDate: Constants.BLANK_DATE_STRING,
      expirationDate: Constants.BLANK_DATE_STRING,
      hasNoExpirationDate: false,
      personId: ''
    };
  };

  function getInitialViewState() {
    return {
      activeUserOrgUnitId: ''
    };
  };

  return {
    isLoading: environmentVariableState.isLoading || isSubmitting,
    isSaving: memberAdministrationLscBoardState.isSaving,
    formState,
    errorState,
    memberAdministrationLscBoardState,
    LSC_BOARD_OF_DIRECTORS_ROLE_GROUP_ID: LSC_BOARD_OF_DIRECTORS_ROLE_GROUP_ID || Constants.DEFAULT_ID,
    onFormValueChanged,
    handleSubmit,
    onBackClicked,
    setFormData,
    onSaveClicked
  };
};

export default useAddLSCBoardMember;