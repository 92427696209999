import { useContext } from 'react';
import { VendorStateContext } from './VendorContextProvider';

import VendorData from './VendorData';

const useVendorData = (isTeamManagement = undefined) => {
  const [vendorState, setVendorState] = useContext(VendorStateContext);

  const getVendors = () => {
    VendorData.getVendors(isTeamManagement, vendorState, setVendorState);
  };

  return {
    getVendors,
    vendorState,
    setVendorState
  };
};

export default useVendorData;