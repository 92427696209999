import { useEffect, useState } from 'react';
import { useNavigate } from '../../../../common/wrappers/ReactRouterDom';

import NavLinks from '../NavLinks';

import usePreCompLocationRegistrationNavData from '../../../state/preCompLocationRegistration/preCompLocationRegistrationLeftNavBar/UsePreCompLocationRegistrationNavData';
import usePreCompLocationRegistrationData from '../../../state/preCompLocationRegistration/UsePreCompLocationRegistrationData';

const usePreCompRegistrationLocationClubsLinkSearch = () => {
  const navigate = useNavigate();
  const { locationRegistrationNavState } = usePreCompLocationRegistrationNavData();
  const { preCompLocationRegistrationState, confirmSave, saveObjData } = usePreCompLocationRegistrationData();
  const [modalState, setModalState] = useState(getInitialModalState);

  const onLinkClub = (e, clubObj) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    let dupClub = null;
    for (const club of preCompLocationRegistrationState.objData.preCompLocationClub) {
      if (club.usasClubId === clubObj.usasClubId) {
        dupClub = club;
        break;
      }
    }

    if (dupClub === null) {
      const locObj = preCompLocationRegistrationState.objData;

      locObj.preCompLocationClub.push({
        usasClubId: clubObj.usasClubId,
        clubInfo: {
          name: clubObj.clubName,
          code: clubObj.clubCode,
          location: clubObj.location,
          lsc: clubObj.lscCode
        }
      });

      saveObjData(locObj);
      setModalState(getInitialModalState);
    } else {
      setModalState({
        ...modalState,
        displayModal: true,
        clubName: dupClub.clubInfo?.name || '',
        clubLocation: dupClub.clubInfo?.location || '',
      });
    }
  };

  const onCancelClicked = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    navigate(NavLinks.PRE_COMP_REGISTRATION_LOCATION_CLUB);
  };

  const onModalCancel = () => {
    setModalState(getInitialModalState);
  };

  useEffect(() => {
    if (preCompLocationRegistrationState.isObjLoaded === false) {
      navigate(NavLinks.PRE_COMP_REGISTRATION_ENTRY);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (preCompLocationRegistrationState.isSaved === true) {
      confirmSave();
      navigate(NavLinks.PRE_COMP_REGISTRATION_LOCATION_CLUB);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [preCompLocationRegistrationState]);

  function getInitialModalState() {
    return {
      displayModal: false,
      clubName: '',
      clubLocation: ''
    };
  };

  return {
    locationRegistrationNavState,
    modalState,
    onLinkClub,
    onCancelClicked,
    onModalCancel
  };
};

export default usePreCompRegistrationLocationClubsLinkSearch;