import { Fragment } from "react";

import DatePicker from "../../datepickers/DatePicker";
import GroupFacilitiesDropdown from "../../dropdowns/groupFacilitiesDropdown/GroupFacilitiesDropdown";
import GroupCoachesMultiselect from "../../multiSelect/groupCoachesMultiSelect/GroupCoachesMultiselect";

const PracticeAttributesForm = ({ formState, errorState, onFormValueChanged, onValueTextPairChanged, orgUnitId, usasClubId, alwaysGetFacilities }) => (
  <Fragment>
    <div className="row">
      <div className="col-xs-12 col-sm-6 col-md-4">
        <GroupCoachesMultiselect
          label="Coach Name(s)"
          name="groupCoaches"
          value={formState.groupCoaches}
          error={errorState.groupCoaches}
          message={errorState.groupCoaches}
          onChange={(value) => { onFormValueChanged('groupCoaches', value); }}
          orgUnitId={orgUnitId}
          usasClubId={usasClubId} />
      </div>
      <div className="col-xs-12 col-sm-6 col-md-4">
        <GroupFacilitiesDropdown
          label="Facility*"
          name="facilityId"
          value={formState.facilityId}
          error={errorState.facilityId}
          message={errorState.facilityId}
          onChange={(newValue, newValueLabel) => { onValueTextPairChanged(newValue, 'facilityId', newValueLabel, 'facilityName'); }}
          orgUnitId={orgUnitId}
          usasClubId={usasClubId}
          alwaysGet={alwaysGetFacilities} />
      </div>
      <div className="col-xs-12 col-sm-6 col-md-4">
        <DatePicker
          label="Start Date*"
          name="startDate"
          value={formState.startDate}
          error={errorState.startDate}
          message={errorState.startDate}
          onChange={(value) => { onFormValueChanged('startDate', value); }}
          countOfYears={15}
          startYearOffset={-5} />
      </div>
      <div className="col-xs-12 col-sm-6 col-md-4">
        <DatePicker
          label="End Date*"
          name="endDate"
          value={formState.endDate}
          error={errorState.endDate}
          message={errorState.endDate}
          onChange={(value) => { onFormValueChanged('endDate', value); }}
          countOfYears={15}
          startYearOffset={-5} />
      </div>
    </div>
  </Fragment>
);

export default PracticeAttributesForm;