import { useContext } from 'react';

import MemberAdministrationLscEmailData from './MemberAdministrationLscEmailData';
import { MemberAdministrationLscEmailStateContext } from './MemberAdministrationLscEmailContextProvider';

const useMemberAdministrationLscEmailData = () => {
  const [memberAdministrationLscRoleEmailsState, setMemberAdministrationLscRoleEmailState] = useContext(MemberAdministrationLscEmailStateContext);

  const getLscRoleEmails = (orgUnitId) => MemberAdministrationLscEmailData.getLscEmails(orgUnitId, memberAdministrationLscRoleEmailsState, setMemberAdministrationLscRoleEmailState);
  const postLscRoleEmail = (roleFieldObj) => MemberAdministrationLscEmailData.postLscEmail(roleFieldObj, memberAdministrationLscRoleEmailsState, setMemberAdministrationLscRoleEmailState);
  const putLscRoleEmail = (orgUnitOrgRoleFieldId, roleFieldObj) => MemberAdministrationLscEmailData.putLscEmail(orgUnitOrgRoleFieldId, roleFieldObj, memberAdministrationLscRoleEmailsState, setMemberAdministrationLscRoleEmailState);

  const confirmSave = () => {
    setMemberAdministrationLscRoleEmailState({
      ...memberAdministrationLscRoleEmailsState,
      isSaved: false
    });
  };

  const clearLscRoleEmailsArrayData = () => {
    setMemberAdministrationLscRoleEmailState({
      ...memberAdministrationLscRoleEmailsState,
      isArrayLoading: false,
      isArrayLoaded: false,
      isSaving: false,
      isSaved: false,
      arrayData: []
    });
  }

  return {
    memberAdministrationLscRoleEmailsState,
    getLscRoleEmails,
    postLscRoleEmail,
    putLscRoleEmail,
    confirmSave,
    clearLscRoleEmailsArrayData
  };
};

export default useMemberAdministrationLscEmailData;