import React, { createContext, useState } from 'react';

import PreCompOrganizationRegistrationData from './PreCompOrganizationRegistrationData';

export const PreCompOrganizationRegistrationStateContext = createContext();

const PreCompOrganizationRegistrationContextProvider = ({ children }) => {
  const stateHook = useState(PreCompOrganizationRegistrationData.INITIAL_STATE);

  return (
    <PreCompOrganizationRegistrationStateContext.Provider value={stateHook}>
      {children}
    </PreCompOrganizationRegistrationStateContext.Provider>
  );
};

export default PreCompOrganizationRegistrationContextProvider;