import { useContext } from "react";

import { ZoneReportingStateContext } from "./ZoneReportingContextProvider";
import ZoneReportingData from "./ZoneReportingData";

const useZoneReportingData = () => {
  const [zoneReportingState, setZoneReportingState] = useContext(ZoneReportingStateContext);

  const getDecryptedOrgUnitId = (orgUnitId) => {
    return ZoneReportingData.getDecryptedIdsData({ orgUnitId }, zoneReportingState, setZoneReportingState);
  };

  return {
    zoneReportingState,
    getDecryptedOrgUnitId
  };
};

export default useZoneReportingData;