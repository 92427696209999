import React, { Fragment } from 'react';

import UseKpiViewStaffRoleReport from './UseKpiViewStaffRoleReport';

import KpiOverrideScoreForm from './KpiOverrideScoreForm';

import Headings from '../../../../common/components/headings/Headings';
import PopUpModal from '../../../../common/components/dialogs/PopUpModal';
import SecondaryButton from '../../../../common/components/buttons/SecondaryButton';
import StaffRoleReportGrid from '../../../../common/components/grids/kpis/StaffRoleReportGrid';

import Constants from '../../../../common/utils/Constants';

const Question = ({ question }) => {
  return (
    <div dangerouslySetInnerHTML={{ __html: question }} />
  );
};

const KpiViewStaffRoleReport = ({ programLevel, categoryMeasure, onRedirect, isReadonly, roleName = 'Staff', canOverrideScore = false }) => {
  const {
    isEdit,
    isSaving,
    kpiMeasureState,
    recognitionProgramState,
    submitFormCallback
  } = UseKpiViewStaffRoleReport(programLevel, categoryMeasure, isReadonly, onRedirect);

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>{categoryMeasure?.measureName}</Headings.H3>
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12">
          {categoryMeasure?.question ?
            <Question question={categoryMeasure.question} /> : <Fragment />}
        </div>
      </div>
      <div className="row usas-extra-top-margin">
        <div className="col-xs-12">
          <StaffRoleReportGrid
            data={kpiMeasureState.arrayData}
            isLoading={kpiMeasureState.isArrayLoading}
            roleName={roleName} />
        </div>
      </div>
      {canOverrideScore === true && isEdit === true && isReadonly !== true
        ? (
          <KpiOverrideScoreForm
            programLevel={programLevel}
            categoryMeasure={categoryMeasure}
            onRedirect={onRedirect}
            submitFormCallback={submitFormCallback} />
        ) : (
          <div className="row usas-extra-top-margin usas-extra-bottom-margin">
            <div className="col-xs-12 usas-extra-top-margin">
              <SecondaryButton type="button" onClick={() => onRedirect(false)}>Back</SecondaryButton>
            </div>
          </div>
        )
      }
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={kpiMeasureState.isArrayLoading || recognitionProgramState.isArrayLoading} />
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.SAVING_MSG}
        displayPopUp={isSaving} />
    </Fragment>
  );
};

export default KpiViewStaffRoleReport;