import { BASIC_INITIAL_STATE } from '../../../common/utils/HttpHelper';

import getClubBaseInfo from './GetClubBaseInfoData';

const INITIAL_STATE = {
  ...BASIC_INITIAL_STATE
};

const ClubBaseInfoData = {
  INITIAL_STATE,
  getClubBaseInfo
};

export default ClubBaseInfoData;