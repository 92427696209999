import useClubRegistrationAffiliationsDetail from './UseClubRegistrationAffiliationsDetail'

import ClubRegistrationConstants from '../utils/ClubRegistrationConstants';

import LeftNav from '../../../../common/components/leftNav/LeftNav';
import Headings from '../../../../common/components/headings/Headings';
import PrimaryButton from '../../../../common/components/buttons/PrimaryButton';
import SecondaryButton from '../../../../common/components/buttons/SecondaryButton';
import PopUpModal from '../../../../common/components/dialogs/PopUpModal';
import ClubAffiliationTypeMultiselect from '../../../../common/components/multiSelect/clubAffTypeMultiSelect/ClubAffiliationTypeMultiselect';
import Constants from '../../../../common/utils/Constants';

const ClubRegistrationAffiliationsDetail = () => {
  const { clubRegistrationState, clubRegistrationNavState, clubAffiliationTypeState, formState, errorState, onFormValueChanged,
    handleSubmit, onSaveClicked, onCancelClicked } = useClubRegistrationAffiliationsDetail();

  return (
    <LeftNav title={ClubRegistrationConstants.LEFT_NAV_TITLE} state={clubRegistrationNavState} validateBeforeRedirect={false}>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>Add Club Affiliation</Headings.H3>
        </div>
      </div>
      <form onSubmit={handleSubmit} noValidate>
        <div className="row usas-extra-top-margin">
          <div className="col-xs-12 col-sm-8 usas-extra-bottom-margin">
            <ClubAffiliationTypeMultiselect
              label={"Club Affiliation(s)*"}
              name={"clubAffiliationTypes"}
              value={formState.clubAffiliationTypes}
              error={errorState.clubAffiliationTypes}
              message={errorState.clubAffiliationTypes}
              onChange={(value) => { onFormValueChanged('clubAffiliationTypes', value) }}
            />
          </div>
        </div>
        <div className="row usas-extra-top-margin usas-extra-bottom-margin">
          <div className="col-xs-12">
            <PrimaryButton type="button" onClick={onSaveClicked}>Save</PrimaryButton>&nbsp;
            <SecondaryButton type="button" onClick={onCancelClicked}>Back</SecondaryButton>
          </div>
        </div>
      </form>
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={clubRegistrationState.isObjLoading || clubAffiliationTypeState.isArrayLoading} />
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.SAVING_MSG}
        displayPopUp={clubRegistrationState.isSaving} />
    </LeftNav>
  )
};

export default ClubRegistrationAffiliationsDetail;