import { useState } from "react";
import { useNavigate } from "../../../../common/wrappers/ReactRouterDom";

import { SUBMIT_NAME } from "../components/leftNav/LearnToSwimRegistrationLeftNavConstants";

import NavLinks from "../utils/NavLinks";

import useLearnToSwimRegistrationData from "../../../state/learnToSwimRegistration/UseLearnToSwimRegistrationData";
import useLearnToSwimRegistrationLeftNavData from "../../../state/learnToSwimRegistrationLeftNav/UseLearnToSwimRegistrationLeftNavData";

const INITIAL_MODAL_STATE = {
  displayModal: false
};

const useLearnToSwimRegistrationSubmit = () => {
  const navigate = useNavigate();
  const { ltsProviderRegistrationState, postLTSProviderRegistration } = useLearnToSwimRegistrationData();
  const { updateCurrentLeftNavData } = useLearnToSwimRegistrationLeftNavData();
  const [modalState, setModalState] = useState(INITIAL_MODAL_STATE);

  const onModalExitClicked = () => {
    navigate(NavLinks.LEARN_TO_SWIM_REGISTRATION_ENTRY);
  };

  const onBackClicked = () => {
    navigate(NavLinks.LEARN_TO_SWIM_REGISTRATION_FACILITIES);
  };

  const onSubmitFormCallback = (formState) => {
    const providerObj = {
      ...ltsProviderRegistrationState.objData,
      logo: formState.logoUrl,
      curriculumUrl: formState.curriculumUrl,
      proofOfInsuranceUrl: formState.proofOfInsuranceUrl,
      formW9Url: formState.formW9Url
    };
    const postLTSProviderRegistrationPromise = postLTSProviderRegistration(providerObj);

    updateCurrentLeftNavData(SUBMIT_NAME, true);

    if (postLTSProviderRegistrationPromise !== null) {
      postLTSProviderRegistrationPromise.then((newState) => {
        if (newState !== null) {
          setModalState({
            ...modalState,
            displayModal: true
          });
        }
      }).catch(() => {
        //TODO context error
        updateCurrentLeftNavData(SUBMIT_NAME, false);
      });
    }
  };

  return {
    isLoading: ltsProviderRegistrationState.isObjLoading,
    modalState,
    ltsProviderRegistrationState,
    onSubmitFormCallback,
    onBackClicked,
    onModalExitClicked
  };
};

export default useLearnToSwimRegistrationSubmit;