import React, { Fragment } from 'react';

import { formatDate } from '../../../../common/utils/DateFunctions';

import global from '../../../../common/components/GlobalStyle.module.css';

const LargeGrid = ({ state, gridState }) => (
  <table className={[global.UsasTable, 'visible-md visible-lg'].join(' ')}>
    <thead>
      <tr>
        <th>LSC Code</th>
        <th>Club Name</th>
        <th>Club Code</th>
        <th>Registration Type</th>
        <th>Dates</th>
      </tr>
    </thead>
    <tbody>
      {state.isObjLoaded !== true
        ? (
          <tr>
            {state.isObjLoading === true
              ? <td colSpan="5">Loading...</td>
              : <td colSpan="5">No Results</td>
            }
          </tr>)
        : Array.isArray(gridState.gridArray) && gridState.isArrayLoaded === true && gridState.gridArray.length > 0
          ? gridState.gridArray.map((history, i) => {
            return (
              <tr key={i}>
                <td>{history.lscCode}</td>
                <td>{history.clubName}</td>
                <td>{history.clubCode}</td>
                <td>{history.clubRegistrationType}</td>
                <td>{history.effectiveDate ? formatDate(history.effectiveDate) : 'Unknown'} - {history.expirationDate ? formatDate(history.expirationDate) : 'Unknown'}</td>
              </tr>
            )
          })
          : (
            <tr>
              <td colSpan="5">No Club Registrations</td>
            </tr>
          )}
    </tbody>
  </table>
);

const SmallGrid = ({ state, gridState }) => (
  <div className={[global.SmallTable, 'hidden-md hidden-lg'].join(' ')}>
    {state.isObjLoaded !== true
      ? (
        <div className={global.SmallTableRow}>
          <div className={global.SmallTableRowHead}>
            &nbsp;
          </div>
          <div className={global.SmallTableRowInfo}>
            {state.isObjLoading === true
              ? <div className={global.SmallTableRowLabels}>Loading... </div>
              : <div className={global.SmallTableRowLabels}>No Club Registrations</div>
            }
          </div>
        </div>
      )
      : Array.isArray(gridState.gridArray) && gridState.isArrayLoaded === true && gridState.gridArray.length > 0
        ? gridState.gridArray.map((history, i) => {
          return (
            <div className={global.SmallTableRow} key={i}>
              <div className={global.SmallTableRowHead}><span className='hidden-xs'>{history.clubName} ({history.clubCode})</span>&nbsp;</div>
              <div className={global.SmallTableRowInfo}>
                <div className='row'>
                  <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3 visible-xs'].join(' ')}>Club Name</div>
                  <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3 visible-xs'].join(' ')}>{history.clubName}</div>
                  <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3 visible-xs'].join(' ')}>Club Code</div>
                  <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3 visible-xs'].join(' ')}>{history.clubCode}</div>
                  <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3'].join(' ')}>LSC Code</div>
                  <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3'].join(' ')}>{history.lscCode}</div>
                  <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3'].join(' ')}>Registration Type</div>
                  <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3'].join(' ')}>{history.clubRegistrationType}</div>
                  <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3'].join(' ')}>Dates</div>
                  <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3'].join(' ')}>{history.effectiveDate ? formatDate(history.effectiveDate)
                    : 'Unknown'} - {history.expirationDate ? formatDate(history.expirationDate) : 'Unknown'}</div>
                </div>
              </div>
            </div>)
        })
        : (
          <div className={global.SmallTableRow}>
            <div className={global.SmallTableRowHead}>
              &nbsp;
            </div>
            <div className={global.SmallTableRowInfo}>
              <div className={global.SmallTableRowLabels}>No Club Registrations</div>
            </div>
          </div>
        )}
  </div>
);

const ClubHistoryGrid = ({ state, clubHistoryState, gridState }) => (
  <Fragment>
    <LargeGrid state={state} clubHistoryState={clubHistoryState} gridState={gridState} />
    <SmallGrid state={state} clubHistoryState={clubHistoryState} gridState={gridState} />
  </Fragment>
);

export default ClubHistoryGrid;