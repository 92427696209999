import { useEffect } from 'react';
import useLscOfferingByOrgUnitIdAndReportPeriodIdDropdownData from '../../../state/lscOfferingByOrgUnitIdAndReportPeriodIdDropdown/UseLscOfferingByOrgUnitIdAndReportPeriodIdDropdownData';

const useLscOfferingByOrgUnitIdAndReportPeriodIdDropdown = (orgUnitId, reportPeriodId) => {
  const { lscOfferingByOrgUnitIdAndReportPeriodIdDropdownState, getLscOfferingByOrgUnitIdAndReportPeriodIdDropdown } = useLscOfferingByOrgUnitIdAndReportPeriodIdDropdownData();

  useEffect(() => {
    if (lscOfferingByOrgUnitIdAndReportPeriodIdDropdownState.isArrayLoading !== true && orgUnitId && reportPeriodId > 0
      && (orgUnitId !== lscOfferingByOrgUnitIdAndReportPeriodIdDropdownState.orgUnitId ||
        reportPeriodId !== lscOfferingByOrgUnitIdAndReportPeriodIdDropdownState.reportPeriodId
      )) {
      getLscOfferingByOrgUnitIdAndReportPeriodIdDropdown(orgUnitId, reportPeriodId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lscOfferingByOrgUnitIdAndReportPeriodIdDropdownState, orgUnitId, reportPeriodId]);

  return {
    lscOfferingByOrgUnitIdAndReportPeriodIdDropdownState
  };
};

export default useLscOfferingByOrgUnitIdAndReportPeriodIdDropdown;