import { isValidDate } from '../../../../../common/utils/validation';
import Constants from '../../../../../common/utils/Constants';

const OCCURRENCE_LIMIT = 1000000;
const AGGREGATE_LIMIT = 3000000;

const PreCompOrganizationInsuranceFormValidation = (formState) => {
  let errors = {};

  const today = new Date();
  const effectiveDate = new Date(formState.effectiveDate);
  const expirationDate = new Date(formState.expirationDate);

  const occurrenceLimit = parseFloat(formState.occurrenceLimit);
  const aggregateLimit = parseFloat(formState.aggregateLimit);

  if (formState.insuranceProviderName.trim() === '') {
    errors.insuranceProviderName = 'Insurance Provider Name is required';
  }

  if (formState.policyNumber.trim() === '') {
    errors.policyNumber = 'Insurance Policy Number is required';
  }

  if (formState.isSelfInsured !== true && formState.isSelfInsured !== false) {
    errors.isSelfInsured = 'Must select yes or no for this field';
  }

  if (formState.effectiveDate === Constants.BLANK_DATE_STRING || formState.effectiveDate === '') {
    errors.effectiveDate = 'Effective Date is required';
  } else if (!isValidDate(formState.effectiveDate)) {
    errors.effectiveDate = 'Effective Date must be a valid date';
  } else if (expirationDate < effectiveDate || formState.effectiveDate === formState.expirationDate) {
    errors.effectiveDate = 'Effective Date must be before the Expiration Date';
  }

  if (formState.expirationDate === Constants.BLANK_DATE_STRING || formState.expirationDate === '') {
    errors.expirationDate = 'Expiration Date is required';
  } else if (!isValidDate(formState.expirationDate)) {
    errors.expirationDate = 'Expiration Date must be a valid date';
  } else if (expirationDate < effectiveDate || formState.effectiveDate === formState.expirationDate) {
    errors.expirationDate = 'Expiration Date must be after the Effective Date';
  } else if (expirationDate <= today) {
    errors.expirationDate = 'Expiration Date must be in the future';
  }

  if (isNaN(occurrenceLimit)) {
    errors.occurrenceLimit = 'Occurrence Limit must be a valid number';
  } else if (occurrenceLimit < OCCURRENCE_LIMIT) {
    errors.occurrenceLimit = `USA Swimming requires a General Liability Insurance occurrence minimum of $1,000,000`;
  } else if (occurrenceLimit > aggregateLimit) {
    errors.occurrenceLimit = 'Occurrence Limit must be less than Aggregate Limit';
  }

  if (isNaN(aggregateLimit)) {
    errors.aggregateLimit = 'Aggregate Limit must be a valid number';
  } else if (aggregateLimit < AGGREGATE_LIMIT) {
    errors.aggregateLimit = `USA Swimming requires a General Liability Insurance aggregate minimum of $3,000,000`;
  } else if (aggregateLimit < occurrenceLimit) {
    errors.aggregateLimit = 'Aggregate Limit must be greater than Occurrence Limit';
  }

  if (!(formState.verificationFile.size) && formState.insuranceValidationUrl === '') {
    errors.insuranceValidationUrl = 'Insurance Verification file is required';
  } else if (formState.verificationFile.size && formState.verificationFile.type !== Constants.FILE_TYPE_PDF && formState.verificationFile.type !== Constants.FILE_TYPE_DOC
    && formState.verificationFile.type !== Constants.FILE_TYPE_DOCX) {
    errors.insuranceValidationUrl = `File type not permitted. Please provide a (*.pdf), (*.doc), or (*.docx) file.`;
  }

  return errors;
};

export default PreCompOrganizationInsuranceFormValidation;