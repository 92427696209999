
import React from "react";

import useKpiOverrideScoreForm from "./UseKpiOverrideScoreForm";

import PrimaryButton from "../../../../common/components/buttons/PrimaryButton";
import SecondaryButton from "../../../../common/components/buttons/SecondaryButton";
import YesNoSwitch from "../../../../common/components/yesNoSwitch/YesNoSwitch";
import Input from "../../../../common/components/inputs/Input";

const KpiOverrideScoreForm = ({ programLevel, categoryMeasure, onRedirect, submitFormCallback }) => {
  const {
    formState,
    errorState,
    onFormValueChanged,
    onSaveClicked
  } = useKpiOverrideScoreForm(programLevel, categoryMeasure, onRedirect, submitFormCallback);

  return (
    <form noValidate>
      <div className="row usas-extra-top-margin">
        <div className="col-xs-12 col-sm-6 col-md-4">
          <YesNoSwitch
            label={'Override Measure Score?'}
            name="scoreEntryOverride"
            checked={formState.scoreEntryOverride}
            error={errorState.scoreEntryOverride}
            message={errorState.scoreEntryOverride}
            onChange={(value) => { onFormValueChanged('scoreEntryOverride', value); }} />
        </div>
        {formState.scoreEntryOverride === true &&
          <div className="col-xs-12 col-sm-6 col-md-4">
            <Input
              label="Score*"
              name="score"
              type="number"
              min="0"
              value={formState.score}
              error={errorState.score}
              message={errorState.score}
              onChange={(value) => { onFormValueChanged('score', value); }} />
          </div>
        }
      </div>
      <div className="row usas-extra-top-margin usas-extra-bottom-margin">
        <div className="col-xs-12 usas-extra-top-margin">
          <PrimaryButton type="button" onClick={onSaveClicked}>Save</PrimaryButton>&nbsp;
          <SecondaryButton type="button" onClick={() => onRedirect(false)}>Back</SecondaryButton>
        </div>
      </div>
    </form>
  );
};

export default KpiOverrideScoreForm;