import { useEffect, useState } from 'react';

import usePrizeData from '../../../state/prize/UsePrizeData';

const usePrizeDropdown = (amountRaised) => {
  const { prizeState } = usePrizeData();
  const [filteredPrizeState, setFilterPrizeState] = useState([]);

  useEffect(() => {
    if (prizeState.isLoaded === true) {
      const filteredData = prizeState.data.filter(prize => prize.prizeMinAmount <= amountRaised && prize.isActive === true);
      const sortedData = filteredData.sort((a, b) => (a.prizeMinAmount < b.prizeMinAmount) ? 1 : -1);
      let filteredDataAsOptionsWithName = sortedData.map((p) => { return { id: p.satPrizeId, name: p.description } });

      setFilterPrizeState(filteredDataAsOptionsWithName);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prizeState.isLoaded, amountRaised])

  return {
    prizeState,
    filteredPrizeState
  };
};

export default usePrizeDropdown;