import { useEffect, useState } from 'react';
import { useNavigate, useLocation } from '../../../../common/wrappers/ReactRouterDom';

import NavLinks from '../utils/NavLinks';
import { createOrgUnitFacilityObjFromFacilityContactAddForm } from '../utils/LearnToSwimUtils';

import useLearnToSwimData from '../../../state/learnToSwim/UseLearnToSwimData';
import useLearnToSwimFacilitiesData from '../../../state/learnToSwimFacilities/UseLearnToSwimFacilitiesData';

import useFacilityData from '../../../../common/state/facility/UseFacilityData';

const INITIAL_STATE = {
  orgUnitFacilityObj: {}
};

const useLearnToSwimFacilitiesDetail = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { ltsProviderState } = useLearnToSwimData();
  const { ltsFacilitiesState, ltsFacilityState, getLTSFacilities, putLTSFacility, postLTSFacility
  } = useLearnToSwimFacilitiesData();
  const { facilityState, getFacility, clearObjData } = useFacilityData();
  const [state, setState] = useState(INITIAL_STATE);

  const onCancelClicked = () => {
    clearObjData(); // facility

    navigate(NavLinks.LEARN_TO_SWIM_FACILITIES, { state: { refreshState: true } });
  };

  useEffect(() => {
    if (location.state?.facilityId > 0 && facilityState.isObjLoading !== true) {
      const orgUnitId = ltsProviderState.objData?.orgUnitId;

      if (ltsFacilitiesState.isArrayLoaded === false) {
        if (orgUnitId) {
          const getLTSFacilitiesPromise = getLTSFacilities(orgUnitId);

          if (getLTSFacilitiesPromise !== null) {
            getLTSFacilitiesPromise.catch((e) => {
              //TODO context error
            });
          }
        }
      } else if (ltsFacilitiesState.isArrayLoaded === true) {
        const facilities = ltsFacilitiesState.arrayData;
        const orgUnitFacilityObj = facilities.find(x => x.facilityId === location.state.facilityId);

        if (orgUnitFacilityObj) {
          setState({
            ...state,
            orgUnitFacilityObj
          });
        } else if (facilityState.isObjLoaded !== true || facilityState.objData?.facilityId !== location.state.facilityId) {
          getFacility(location.state.facilityId);
        } else {
          setState({
            ...state,
            orgUnitFacilityObj: {
              orgUnitId,
              facilityId: facilityState.objData?.facilityId,
              facility: facilityState.objData
            }
          });
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [facilityState, ltsFacilitiesState.isArrayLoaded]);

  function onSubmitFormCallback(formState) {
    const orgUnitId = ltsProviderState.objData?.orgUnitId;
    const orgUnitFacilityId = state.orgUnitFacilityObj?.orgUnitFacilityId;
    const orgUnitFacilityObj = createOrgUnitFacilityObjFromFacilityContactAddForm(formState, state.orgUnitFacilityObj);

    if (orgUnitFacilityId > 0) {
      const putLTSFacilityPromise = putLTSFacility(orgUnitId, orgUnitFacilityId, orgUnitFacilityObj);

      if (putLTSFacilityPromise !== null) {
        putLTSFacilityPromise.then((newState) => {
          if (newState !== null) {
            clearObjData(); // facility

            navigate(NavLinks.LEARN_TO_SWIM_FACILITIES, { state: { refreshState: true } });
          }
        }).catch((e) => {
          //TODO context error
        });
      }
    } else {
      const postLTSFacilityPromise = postLTSFacility(orgUnitId, orgUnitFacilityObj);

      if (postLTSFacilityPromise !== null) {
        postLTSFacilityPromise.then((newState) => {
          if (newState !== null) {
            clearObjData(); // facility

            navigate(NavLinks.LEARN_TO_SWIM_FACILITIES, { state: { refreshState: true } });
          }
        }).catch((e) => {
          //TODO context error
        });
      }
    }
  };

  return {
    isSaving: ltsFacilityState.isObjLoading,
    isLoading: facilityState.isObjLoading,
    orgUnitFacilityObj: state.orgUnitFacilityObj,
    onSubmitFormCallback,
    onCancelClicked
  };
};

export default useLearnToSwimFacilitiesDetail;