import { Fragment } from "react";

import DatePicker from "../../datepickers/DatePicker";
import GroupFacilitiesDropdown from "../../dropdowns/groupFacilitiesDropdown/GroupFacilitiesDropdown";
import GroupCoachesDropdown from "../../dropdowns/groupCoachesDropdown/GroupCoachesDropdown";

const ClubSiteAttributesForm = ({ formState, errorState, onFormValueChanged, onValueTextPairChanged, orgUnitId, usasClubId, alwaysGetFacilities }) => (
  <Fragment>
    <div className="row">
      <div className="col-xs-12 col-sm-6 col-md-4">
        <GroupFacilitiesDropdown
          label="Facility*"
          name="facilityId"
          value={formState.facilityId}
          error={errorState.facilityId}
          message={errorState.facilityId}
          onChange={(newValue, newValueLabel) => { onValueTextPairChanged(newValue, 'facilityId', newValueLabel, 'facilityName'); }}
          orgUnitId={orgUnitId}
          usasClubId={usasClubId}
          alwaysGet={alwaysGetFacilities} />
      </div>
      <div className="col-xs-12 col-sm-6 col-md-4">
        <GroupCoachesDropdown
          label="Lead Coach*"
          name="leadCoachId"
          value={formState.leadCoachId}
          error={errorState.leadCoachId}
          message={errorState.leadCoachId}
          onChange={(newValue, newValueLabel) => { onValueTextPairChanged(newValue, 'leadCoachId', newValueLabel, 'leadCoachName'); }}
          orgUnitId={orgUnitId}
          usasClubId={usasClubId} />
      </div>
      <div className="col-xs-12 col-sm-6 col-md-4">
        <DatePicker
          label="Effective Date*"
          name="effectiveDate"
          value={formState.effectiveDate}
          error={errorState.effectiveDate}
          message={errorState.effectiveDate}
          onChange={(value) => { onFormValueChanged('effectiveDate', value); }}
          countOfYears={30}
          startYearOffset={-15} />
      </div>
      <div className="col-xs-12 col-sm-6 col-md-4">
        <DatePicker
          label="Expiration Date*"
          name="expirationDate"
          value={formState.expirationDate}
          error={errorState.expirationDate}
          message={errorState.expirationDate}
          onChange={(value) => { onFormValueChanged('expirationDate', value); }}
          countOfYears={30}
          startYearOffset={-15} />
      </div>
    </div>
  </Fragment>
);

export default ClubSiteAttributesForm;