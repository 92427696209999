import { Routes, Route } from 'react-router-dom';
import { Navigate } from '../../../common/wrappers/ReactRouterDom';

import ClubPerformanceContextRoutes from './ClubPerformanceContextRoutes';

import ClubPerformanceOrgUnitSelection from './clubPerformanceOrgUnitSelection/ClubPerformanceOrgUnitSelection';

import SecuredRouteElement from '../../../common/components/security/SecuredRouteElement';

import SelectOrgUnitContextProvider from "../../../common/state/selectOrgUnit/SelectOrgUnitContextProvider";
import PersonOrgRoleContextProvider from '../../../common/state/personOrgRole/PersonOrgRoleContextProvider';

const BasicRoutes = ({ navRoutes }) => (
  <Routes>
    <Route path={navRoutes.CLUB_PERFORMANCE_SELECTION?.path}
      element={
        <SecuredRouteElement
          navRoute={navRoutes.CLUB_PERFORMANCE_SELECTION}
          editElement={<ClubPerformanceOrgUnitSelection />} />
      } />

    <Route path={'/*'} element={<ClubPerformanceContextRoutes navRoutes={navRoutes} />} />
    <Route path={'/'} element={<Navigate to={navRoutes.CLUB_PERFORMANCE_SELECTION?.route} replace />} />
  </Routes>
);

const ClubPerformanceBasicRoutes = ({ navRoutes }) => (
  <PersonOrgRoleContextProvider>
    <SelectOrgUnitContextProvider>
      <BasicRoutes navRoutes={navRoutes} />
    </SelectOrgUnitContextProvider>
  </PersonOrgRoleContextProvider>
);

export default ClubPerformanceBasicRoutes;