import { useState } from 'react';

import SwimAThonContractData from './SwimAThonContractData';

const useSwimAThonContractData = () => {
  const [swimAThonContractState, setSwimAThonContractState] = useState(SwimAThonContractData.INITIAL_STATE);

  const postSwimAThonContract = (contractObj) => SwimAThonContractData.postSwimAThonContractData(contractObj, swimAThonContractState, setSwimAThonContractState);
  const putSwimAThonContract = (satContractId, contractObj) => SwimAThonContractData.putSwimAThonContractData(satContractId, contractObj, swimAThonContractState, setSwimAThonContractState);

  return {
    swimAThonContractState,
    postSwimAThonContract,
    putSwimAThonContract
  };
};

export default useSwimAThonContractData;