import { BASIC_INITIAL_STATE } from '../../../common/utils/HttpHelper';

import getPreCompLocationData from './GetPreCompLocationData';
import getPreCompLocationByOrgUnitIdData from './GetPreCompLocationByOrgUnitIdData';
import putPreCompLocationData from './PutPreCompLocationData';

const INITIAL_STATE = {
  ...BASIC_INITIAL_STATE
};

const PreCompLocationData = {
  INITIAL_STATE,
  getPreCompLocationData,
  getPreCompLocationByOrgUnitIdData,
  putPreCompLocationData
};

export default PreCompLocationData;