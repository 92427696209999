import usePreCompAffiliationTypeData from '../../../../state/preCompAffiliationType/UsePreCompAffiliationTypeData';

const usePreCompAffiliationMultiselect = () => {
  const { preCompAffiliationTypeState } = usePreCompAffiliationTypeData();

  return {
    preCompAffiliationTypeState
  };
};

export default usePreCompAffiliationMultiselect;