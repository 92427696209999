import { useState, useEffect } from 'react';

import validate from '../../../../../common/components/searches/searchFacility/addFacility/LearnToSwimFacilityValidation';
import useCountryCombobox from '../../../../../common/components/comboboxes/countryCombobox/UseCountryCombobox';

import useForm from '../../../../../common/utils/UseForm';
import Constants from '../../../../../common/utils/Constants';

const US_COUNTRY_CODE = 'US';

const INITIAL_VALIDATE_STATE = {
  isValidating: false,
  validatedAddress: null
};

const INITIAL_FORM_STATE = {
  facilityName: '',
  address1: '',
  address2: '',
  city: '',
  stateCode: '',
  country: '',
  countryName: '',
  stateName: '',
  postalCode: '',
  contactName: '',
  contactEmailAddress: '',
  contactPhoneNumber: '',
  clubAffiliationId: Constants.DEFAULT_POSITIVE_ID,
  altitude: Constants.DEFAULT_ALTITUDE,
  isInternational: false,
  validatedAddress: null,
  showedAddressSuggestion: false
};

const useLearnToSwimFacilityAddForm = (onSubmitFormCallback) => {
  const { getCountryNameById } = useCountryCombobox();
  const { formState, errorState, onValueTextPairChanged, handleSubmit, updateFormState, setFormData
  } = useForm(INITIAL_FORM_STATE, onSubmitFormCallback, validate);
  const [validateState, setValidateState] = useState(INITIAL_VALIDATE_STATE);

  const handleSubmitProxy = async (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    setValidateState({
      isValidating: true
    });

    await handleSubmit();

    setValidateState({
      isValidating: false,
      validatedAddress: formState.validatedAddress
    });

    updateFormState('showedAddressSuggestion', true);
  };

  useEffect(() => {
    if (formState.isInternational === false) {
      setFormData({
        ...formState,
        country: US_COUNTRY_CODE,
        countryName: getCountryNameById(US_COUNTRY_CODE)
      }, true);
    } else {
      setFormData({
        ...formState,
        country: '',
        countryName: ''
      }, true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formState.isInternational]);

  return {
    formState,
    errorState,
    validateState,
    onFormValueChanged: updateFormState,
    onValueTextPairChanged,
    handleSubmitProxy,
    setFormData
  };
};

export default useLearnToSwimFacilityAddForm;