import Checkbox from '../../../../../common/components/checkboxes/Checkbox';

import global from '../../../../../common/components/GlobalStyle.module.css';

import { formatDate } from '../../../../../common/utils/DateFunctions';

const GridRow = ({ membership, onCheckboxChange, selectionView }) => {
  return (
    <tr key={'personId' + membership.personId + 'offeringInstanceId' + membership.offeringInstanceId}>
      <td>{(membership.firstName || membership.middleName || membership.lastName) ? `${membership.firstName || ''}  ${membership.preferredName !== '' && membership.preferredName !== membership.firstName ? '"' + membership.preferredName + '"' : ''} ${membership.middleName || ''} ${membership.lastName || ''}` : ''}</td>
      <td>{membership.memberId || ''}</td>
      <td>{membership.competitionGenderTypeName || ''}</td>
      {selectionView === true &&
        <td>{membership.birthDate ? formatDate(membership.birthDate) : ''}</td>}
      <td>{membership.age >= 0 ? membership.age : ''}</td>
      <td>{membership.offeringName || ''}</td>
      {selectionView === false &&
        <td>{membership.renewalOfferingName || ''}</td>}
      <td>
        <Checkbox
          label=""
          name="isSelected"
          onChange={(e) => { onCheckboxChange(e, membership) }}
          checked={membership.isSelected || false} />
      </td>
    </tr>
  );
};

const ClubBulkRenewSelectionLargeGrid = ({ gridData, isLoading, onCheckboxChange, selectionView }) => (
  <table className={[global.UsasTable, 'visible-md visible-lg'].join(' ')}>
    <thead>
      <tr>
        <th>Name</th>
        <th>Member Id</th>
        <th style={{ minWidth: '120px' }}>Comp. Category</th>
        {selectionView === true &&
          <th>Birth Date</th>}
        <th>Age</th>
        <th>Last Membership</th>
        {selectionView === false &&
          <th>Renewal Membership</th>}
        <th>Select</th>
      </tr>
    </thead>
    <tbody>
      {isLoading === true ?
        <tr><td colSpan={"7"}>Loading...</td></tr>
        : Array.isArray(gridData) && gridData.length > 0 ?
          gridData.map((membership, i) =>
            <GridRow
              key={i}
              membership={membership}
              onCheckboxChange={onCheckboxChange}
              selectionView={selectionView}
            />)
          :
          <tr>
            <td colSpan={"7"}>No Results</td>
          </tr>
      }
    </tbody>
  </table>
);

export default ClubBulkRenewSelectionLargeGrid;