import { BASIC_INITIAL_STATE } from '../../../common/utils/HttpHelper';

import getLSCRegistrationManagementData from './GetLSCRegistrationManagementData';
import putLSCRegistrationManagementData from './PutLSCRegistrationManagementData';

const INITIAL_STATE = {
  ...BASIC_INITIAL_STATE
};

const LSCRegistrationManagementData = { INITIAL_STATE, getLSCRegistrationManagementData, putLSCRegistrationManagementData };

export default LSCRegistrationManagementData;