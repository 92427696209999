import React, { Fragment } from 'react';

import ReadOnly from '../../../../../common/components/readOnly/ReadOnly';
import YesNoSwitch from '../../../../../common/components/yesNoSwitch/YesNoSwitch';

const PreCompLocationClubsForm = ({ formState, errorState, onFormValueChanged }) => (
  <Fragment>
    <div className="row">
      <div className="col-xs-12 col-md-5">
        {onFormValueChanged === undefined
          ? <ReadOnly
            label="Are you a USA Swimming Member Team?"
            name="isUsasMemberTeam"
            value={formState.isUsasMemberTeam} />
          : (
            <YesNoSwitch
              label="Are you a USA Swimming Member Team?*"
              name="isUsasMemberTeam"
              checked={formState.isUsasMemberTeam}
              error={errorState.isUsasMemberTeam}
              message={errorState.isUsasMemberTeam}
              onChange={(value) => { onFormValueChanged('isUsasMemberTeam', value); }} />
          )
        }
      </div>
      <div className="col-xs-12 col-md-7">
        {onFormValueChanged === undefined
          ? <ReadOnly
            label="Do you refer participants to a USA Swimming team upon readiness?"
            name="referParticipantsToUsas"
            value={formState.referParticipantsToUsas} />
          : (
            <YesNoSwitch
              label="Do you refer participants to a USA Swimming team upon readiness?*"
              name="referParticipantsToUsas"
              checked={formState.referParticipantsToUsas}
              error={errorState.referParticipantsToUsas}
              message={errorState.referParticipantsToUsas}
              onChange={(value) => { onFormValueChanged('referParticipantsToUsas', value); }} />
          )
        }
      </div>
    </div>
  </Fragment>
);

export default PreCompLocationClubsForm;