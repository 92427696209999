import React, { Fragment } from 'react';

import usePreCompContextView from './UsePreCompContextView';
import NavLinks from './NavLinks';

import Headings from '../../../common/components/headings/Headings';
import LeftArrowIcon from '../../../common/components/icons/LeftArrowIcon';
import MobileContextBasedNav from '../../../common/components/contextBasedNav/MobileContextBasedNav';
import LargeContextBasedNav from '../../../common/components/contextBasedNav/LargeContextBasedNav';
import PageNotFound from '../../../common/components/PageNotFound';
import PopUpModal from '../../../common/components/dialogs/PopUpModal';

import Constants from '../../../common/utils/Constants';

import global from '../../../common/components/GlobalStyle.module.css';

const PreCompContextView = ({ children }) => {
  const {
    preCompOrganizationState,
    preCompLocationState,
    backToLabel,
    selectOrgUnitState,
    onBackClicked,
    onHomeClicked,
    onBackToSelectionClicked
  }
    = usePreCompContextView();

  if (!preCompOrganizationState || preCompOrganizationState.isObjLoaded === false) {
    return preCompOrganizationState.isObjLoading === false
      ? (
        <Fragment>
          <div className="row usas-extra-bottom-margin">
            <div className="col-xs-12">
              {selectOrgUnitState.orgUnitId !== '' ?
                <button className={global.BackToSearchButton}
                  type="button"
                  onClick={onBackToSelectionClicked}>
                  <LeftArrowIcon />  Back to Selection
                </button> :
                <button className={global.BackToSearchButton}
                  type="button"
                  onClick={onBackClicked}>
                  <LeftArrowIcon />  {backToLabel}
                </button>}
            </div>
          </div>
          <div className="row usas-extra-bottom-margin">
            <div className="col-xs-12">
              <PageNotFound />
            </div>
          </div>
        </Fragment>
      ) : (
        <div className="row usas-extra-bottom-margin">
          <div className="col-xs-12">
            <PopUpModal
              widthPct={50}
              maxWidth={250}
              title={Constants.LOADING_MSG}
              displayPopUp={true} />
          </div>
        </div>
      );
  }

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <button className={global.HeaderButton} onClick={onHomeClicked}>
            <Headings.H3
              className={[global.NoBottomMargin, 'col-xs-12'].join(' ')}
              excludeBar={true}>
              View/Edit Pre-Competitive Organization/Location
            </Headings.H3>
          </button>
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12">
          <hr className="usas-bar-turmeric" />
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12">
          {selectOrgUnitState.orgUnitId !== '' ?
            <Fragment>
              {selectOrgUnitState.showBackToSelection &&
                <button className={global.BackToSearchButton}
                  type="button"
                  onClick={onBackToSelectionClicked}>
                  <LeftArrowIcon />  Back to Selection
                </button>}</Fragment> :
            <button className={global.BackToSearchButton}
              type="button"
              onClick={onBackClicked}>
              <LeftArrowIcon />  {backToLabel}
            </button>}
        </div>
      </div>
      <div className="row">
        <div className={["col-xs-12 col-sm-12", global.HeaderText].join(' ')}>
          <p><b>Organization Name:</b> {preCompOrganizationState.objData?.orgUnit?.orgUnitName || ''}</p>
          <p><b>Location Name:</b> {preCompLocationState.objData?.locationName || ''}</p>
        </div>
      </div>
      <div className="row">
        <div className="visible-xs col-xs-12">
          <MobileContextBasedNav taxonomyName="PreComp" />
        </div>
        <div className="hidden-xs col-xs-12">
          <LargeContextBasedNav taxonomyName="PreComp" homeRoute={NavLinks.PRE_COMP_ORGANIZATION_INFO} />
        </div>
      </div>
      {children}
    </Fragment>
  );
};

export default PreCompContextView;