import React, { Fragment } from 'react';

import useClubRecognitionCategoryMeasure from './UseClubRecognitionCategoryMeasure';

import ClubRecognitionCategoryMeasureHeader from './components/ClubRecognitionCategoryMeasureHeader';

import TextEntryView from '../../../components/views/textEntryView/TextEntryView';

import PopUpModal from '../../../../common/components/dialogs/PopUpModal';
import Constants from '../../../../common/utils/Constants';
import PageNotFound from '../../../../common/components/PageNotFound';

const ClubRecognitionTextEntry = () => {
  const {
    isLoading,
    programFocusState,
    onRedirectToCategory
  } = useClubRecognitionCategoryMeasure();

  if (!programFocusState || Object.keys(programFocusState?.levelCategoryObj).length === 0 ||
    Object.keys(programFocusState?.programLevelObj).length === 0) {
    return (
      <PageNotFound />
    );
  }

  return (
    <Fragment>
      <ClubRecognitionCategoryMeasureHeader
        programCategory={programFocusState.levelCategoryObj}
        categoryMeasure={programFocusState.categoryMeasureObj}
        onBackClicked={onRedirectToCategory} />
      <div className="row">
        <div className="col-xs-12">
          <TextEntryView
            programLevel={programFocusState.programLevelObj}
            categoryMeasure={programFocusState.categoryMeasureObj}
            onRedirect={onRedirectToCategory} />
        </div>
      </div>
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={isLoading} />
    </Fragment>
  );
};

export default ClubRecognitionTextEntry;