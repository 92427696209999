import { Fragment } from 'react';
import Checkbox from '../../../../../common/components/checkboxes/Checkbox';

import global from '../../../../../common/components/GlobalStyle.module.css';

import { formatDate } from '../../../../../common/utils/DateFunctions';

const ClubBulkRenewSelectionSmallGrid = ({ gridData, isLoading, onCheckboxChange, selectionView }) => (
  <div className={[global.SmallTable, 'hidden-md hidden-lg'].join(' ')}>
    {isLoading === true
      ? (
        <div className={global.SmallTableRow}>
          <div className={global.SmallTableRowHead}>
            &nbsp;
          </div>
          <div className={global.SmallTableRowInfo}>
            <div className={global.SmallTableRowLabels}>
              Loading...
            </div>
          </div>
        </div>
      ) : gridData.length > 0
        ? gridData.map((membership) => (
          <div className={global.SmallTableRow} key={'personId' + membership.personId + 'offeringInstanceId' + membership.offeringInstanceId}>
            <div className={global.SmallTableRowHead}><span className='hidden-xs'>{(membership.firstName || membership.middleName || membership.lastName) ? `${membership.firstName || ''}  ${membership.preferredName !== '' && membership.preferredName !== membership.firstName ? '"' + membership.preferredName + '"' : ''} ${membership.middleName || ''} ${membership.lastName || ''}` : <span>&nbsp;</span>}</span>&nbsp;
              <div className={global.CheckboxMobile}>
                <Checkbox
                  className={global.IconButton}
                  label=""
                  name="isSelected"
                  onChange={(e) => { onCheckboxChange(e, membership) }}
                  checked={membership.isSelected || false} /></div>
            </div>
            <div className={global.SmallTableRowInfo}>
              <div className='row'>
                <div className={[global.SmallTableRowLabels, 'col-xs-6 visible-xs'].join(' ')}>Name</div>
                <div className={[global.SmallTableRowData, 'col-xs-6 visible-xs'].join(' ')}>{(membership.firstName || membership.middleName || membership.lastName) ? `${membership.firstName || ''}  ${membership.preferredName !== '' && membership.preferredName !== membership.firstName ? '"' + membership.preferredName + '"' : ''} ${membership.middleName || ''} ${membership.lastName || ''}` : <span>&nbsp;</span>}</div>
                <div className={['col-xs-6', global.SmallTableRowLabels].join(' ')}>Member Id</div>
                <div className={[global.SmallTableRowData, 'col-xs-6'].join(' ')}>{membership.memberId || <span>&nbsp;</span>}</div>
                <div className={['col-xs-6', global.SmallTableRowLabels].join(' ')}>Comp. Category</div>
                <div className={[global.SmallTableRowData, 'col-xs-6'].join(' ')}>{membership.competitionGenderTypeName || <span>&nbsp;</span>}</div>
                {selectionView === true &&
                  <Fragment>
                    <div className={[global.SmallTableRowLabels, 'col-xs-6'].join(' ')}>Birth Date</div>
                    <div className={[global.SmallTableRowData, 'col-xs-6'].join(' ')}>{membership.birthDate ? formatDate(membership.birthDate) : <span>&nbsp;</span>}</div>
                  </Fragment>}
                <div className={[global.SmallTableRowLabels, 'col-xs-6'].join(' ')}>Age</div>
                <div className={[global.SmallTableRowData, 'col-xs-6'].join(' ')}>{membership.age >= 0 ? membership.age : <span>&nbsp;</span>}</div>
                <div className={[global.SmallTableRowLabels, 'col-xs-6 hidden-xs'].join(' ')}>Last Membership</div>
                    <div className={[global.SmallTableRowLabels, 'col-xs-6 visible-xs'].join(' ')}>Last Mem.</div>
                <div className={[global.SmallTableRowData, 'col-xs-6'].join(' ')}>{membership.offeringName || <span>&nbsp;</span>}</div>
                {selectionView === false &&
                  <Fragment>
                    <div className={[global.SmallTableRowLabels, 'col-xs-6 hidden-xs'].join(' ')}>Renewal Membership</div>
                    <div className={[global.SmallTableRowLabels, 'col-xs-6 visible-xs'].join(' ')}>Renewal Mem.</div>
                    <div className={[global.SmallTableRowData, 'col-xs-6'].join(' ')}>{membership.renewalOfferingName || <span>&nbsp;</span>}</div>
                  </Fragment>}
              </div>
            </div>
          </div>
        )) : (
          <div className={global.SmallTableRow}>
            <div className={global.SmallTableRowHead}>
              &nbsp;
            </div>
            <div className={global.SmallTableRowInfo}>
              <div className={global.SmallTableRowLabels}>No Results</div>
            </div>
          </div>
        )
    }
  </div >
);

export default ClubBulkRenewSelectionSmallGrid;