import { useEffect } from 'react';

import useCurrentSeasonOfferingData from '../../../state/currentSeasonOffering/UseCurrentSeasonOfferingData';

const useCurrentSeasonOfferingDropdown = (orgUnitId, isAthleteRole, isActive) => {
  const { currentSeasonOfferingState, getCurrentSeasonOfferings } = useCurrentSeasonOfferingData();

  useEffect(() => {
    if (currentSeasonOfferingState.isArrayLoading !== true && (currentSeasonOfferingState.isArrayLoaded !== true
      || currentSeasonOfferingState.orgUnitId !== orgUnitId
      || currentSeasonOfferingState.isAthleteRole !== isAthleteRole
      || currentSeasonOfferingState.isActive !== isActive)) {
      getCurrentSeasonOfferings(orgUnitId, isAthleteRole, isActive);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSeasonOfferingState, orgUnitId, isAthleteRole, isActive]);

  return {
    currentSeasonOfferingState
  };
};

export default useCurrentSeasonOfferingDropdown;