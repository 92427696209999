import { OmrHttpHelper, NO_DATA_MESSAGE, BASIC_INITIAL_STATE } from '../../../common/utils/HttpHelper';
import Constants from '../../../common/utils/Constants';

export const INITIAL_STATE = {
  ...BASIC_INITIAL_STATE,
  options: [],
  orgUnitId: Constants.DEFAULT_ID,
  isAthleteRole: '',
  isActive: ''
};

const getCurrentSeasonOfferingData = (state, setState, orgUnitId, isAthleteRole, isActive) => {
  if (state.isArrayLoading !== true && !state.message) {
    let newState = {
      ...state,
      isArrayLoading: true,
      isArrayLoaded: false,
      message: 'Loading...',
      orgUnitId,
      isAthleteRole,
      isActive
    };
    setState(newState);

    const orgUnitIdForUrl = orgUnitId ? encodeURIComponent(orgUnitId) : 'NaN';
    const url = `/Offering/OrgUnit/${orgUnitIdForUrl}/CurrentSeason?isAthleteRole=${isAthleteRole}&isActive=${isActive}`;
    OmrHttpHelper(url, 'GET')
      .then((data) => {
        if (!data) {
          throw new Error(NO_DATA_MESSAGE);
        } else {
          const formattedData = data.map((o, index) => { return { ...o, index } });
          const options = data.map((o, index) => { return { id: index, name: o.offeringName }; });
          options.unshift({ id: Constants.DEFAULT_ID, name: '--' });

          newState = {
            ...newState,
            isArrayLoading: false,
            isArrayLoaded: true,
            arrayData: formattedData,
            options,
            message: '',
            orgUnitId,
            isAthleteRole,
            isActive
          };
          setState(newState);
        }
      })
      .catch((e) => {
        const message = e && e.message ? e.message : NO_DATA_MESSAGE;
        setState({
          ...newState,
          isArrayLoading: false,
          isArrayLoaded: false,
          message: message
        });
      });
  }
};

const CurrentSeasonOfferingData = { INITIAL_STATE, getCurrentSeasonOfferingData };

export default CurrentSeasonOfferingData;