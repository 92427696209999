import useVendorDropdown from './UseVendorDropdown';

import Dropdown from '../Dropdown';

import global from '../../GlobalStyle.module.css';

const VendorDropdown = ({ label, name, value, error, message, onChange, isTeamManagement = undefined }) => {
  const { vendorState } = useVendorDropdown(isTeamManagement);

  return vendorState?.message
    ? <span className={global.LoadingMsg}>{vendorState?.message}</span>
    : (
      <Dropdown
        error={error}
        label={label}
        message={message}
        options={vendorState?.dataAsOptionsWithNames}
        name={name}
        value={value}
        onChange={onChange}
        isLoading={vendorState?.isArrayLoading}
      />
    );
};

export default VendorDropdown;