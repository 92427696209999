import { useEffect, useState } from 'react';
import { useNavigate, useLocation } from '../../../../common/wrappers/ReactRouterDom';

import NavLinks from '../NavLinks';

import validate from '../../preComp/components/forms/PreCompOrganizationContactFormValidation';

import usePreCompLocationRegistrationNavData from '../../../state/preCompLocationRegistration/preCompLocationRegistrationLeftNavBar/UsePreCompLocationRegistrationNavData';
import usePreCompOrganizationRegistrationData from '../../../state/preCompOrganizationRegistration/UsePreCompOrganizationRegistrationData';
import { createShallowPerson } from '../../preComp/utils/PreCompUtils';

import usePersonData from '../../../../common/state/person/UsePersonData';
import useOrgRoleHierarchyData from '../../../../common/state/orgRoleHierarchy/UseOrgRoleHierarchyData';
import useEnvironmentVariableData from '../../../../common/state/environmentVariable/UseEnvironmentVariableData';

import useForm from '../../../../common/utils/UseForm';
import Constants from '../../../../common/utils/Constants';
import { formatDate } from '../../../../common/utils/DateFunctions';

const usePreCompRegistrationOrganizationContactsDetail = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { locationRegistrationNavState } = usePreCompLocationRegistrationNavData();
  const { preCompOrganizationRegistrationState, setPreCompOrganizationRegistrationState, confirmSave, saveContactData } = usePreCompOrganizationRegistrationData();
  const { personState, getPotentialPersonDupes } = usePersonData();
  const { orgRoleHierarchyState, getOrgRolesHierarchyByOrganizationId } = useOrgRoleHierarchyData();
  const { environmentVariableState, PRE_COMP_ORG_ID, PRIMARY_CONTACT_LABEL, SECONDARY_CONTACT_LABEL, CONTACTS } = useEnvironmentVariableData();
  const { formState, errorState, isSubmitting, handleSubmit, updateFormState, setFormData, onValueTextPairChanged, setIsDirty
  } = useForm(getInitialFormState, submitFormCallback, validate);
  const [dupGridState, setDupGridState] = useState([]);
  const [newGridState, setNewGridState] = useState([]);
  const [roleOptionsState, setRoleOptionsState] = useState([]);
  const [existingContactModalState, setExistingContactModalState] = useState(getInitialExistingContactModalState());
  const [displayLocalDupPersonModalState, setDisplayLocalDupPersonModalState] = useState(false);

  const onCancelClicked = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    navigate(NavLinks.PRE_COMP_REGISTRATION_ORGANIZATION_CONTACT);
  };

  const onModalCancelClicked = () => {
    setDupGridState([]);
    setNewGridState([]);
    setIsDirty(true);
  };

  const onModalContinueClicked = () => {
    let matchFound = false;
    for (const dup of dupGridState) {
      if (dup.isSelected === true) {
        let dupContact = {};
        let contacts = preCompOrganizationRegistrationState.objData.preCompetitiveOrganizationContacts;
        for (const contact of contacts) {
          if (contact.personId === dup.personId) {
            dupContact = contact;
          }
        }
        if (dupContact) {
          let preCompetitiveOrganizationContactsCopy = JSON.parse(JSON.stringify(preCompOrganizationRegistrationState.objData.preCompetitiveOrganizationContacts));
          let contacts = preCompetitiveOrganizationContactsCopy.filter(x => x.personId !== dupContact.personId);
          let matchFoundIndex = Constants.DEFAULT_ID;
          for (let i = 0; i < contacts.length; i++) {
            for (let j = 0; j < contacts[i].personOrgRole.length; j++) {
              if (contacts[i].personOrgRole[j].orgRoleId === formState.orgRoleId) {
                matchFoundIndex = i;
                break;
              }
            }
          }
          if (matchFoundIndex >= 0) {
            contacts.splice(matchFoundIndex, 1);
          }
          const newContact = createShallowPerson(dup, [{
            orgRoleId: formState.orgRoleId,
            orgRoleName: formState.orgRoleName
          }]);
          contacts.push(newContact);
          setPreCompOrganizationRegistrationState({
            ...preCompOrganizationRegistrationState,
            isSaved: true,
            objData: {
              ...preCompOrganizationRegistrationState.objData,
              preCompetitiveOrganizationContacts: contacts
            }
          });
        }
        else {
          saveContactData(createShallowPerson(formState, [{
            orgRoleId: formState.orgRoleId,
            orgRoleName: formState.orgRoleName
          }]));
        }
        matchFound = true;
        break;
      }
    }

    if (matchFound === false) {
      saveContactData(createShallowPerson(formState, [{
        orgRoleId: formState.orgRoleId,
        orgRoleName: formState.orgRoleName
      }]));
    }

    setDupGridState([]);
    setNewGridState([]);
  };

  const onExistingContactModalConfirmClicked = () => {
    setExistingContactModalState(getInitialExistingContactModalState());

    getPotentialPersonDupes(formState.firstName, formState.lastName, formState.birthDate);
  };

  const onExistingContactModalCancelClicked = () => {
    setExistingContactModalState(getInitialExistingContactModalState());
  };

  const onSelectGridPerson = (personId) => {
    let newDupGridState = [];
    for (const dup of dupGridState) {
      newDupGridState.push({
        ...dup,
        isSelected: personId === dup.personId ? true : false
      });
    }

    setDupGridState(newDupGridState);
    setNewGridState([{
      ...newGridState[0],
      isSelected: personId ? false : true
    }]);
  };

  const onLocalDupPersonModalCancelClicked = () => {
    setIsDirty(true);
    setDisplayLocalDupPersonModalState(false);
  };

  useEffect(() => {
    if (preCompOrganizationRegistrationState.isObjLoaded === false) {
      navigate(NavLinks.PRE_COMP_REGISTRATION_ENTRY);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [preCompOrganizationRegistrationState.isObjLoaded]);

  useEffect(() => {
    if (personState.isArrayLoaded === true) {
      if (personState.arrayData.length > 0) {
        setDupGridState(personState.arrayData.map(x => {
          let orgDuration = { orgUnitName: '', effectiveDate: '', expirationDate: '' };
          if (Array.isArray(x.orgDurations) && x.orgDurations.length > 0) {
            orgDuration = {
              orgUnitName: x.orgDurations[0].orgUnitName,
              effectiveDate: x.orgDurations[0].effectiveDate,
              expirationDate: x.orgDurations[0].expirationDate
            };
          }

          return {
            personId: x.personId,
            firstName: x.firstName,
            lastName: x.lastName,
            birthDate: x.birthDate,
            email: x.email,
            orgDuration,
            isSelected: false
          };
        }));
        setNewGridState([{
          personId: '',
          firstName: formState.firstName,
          lastName: formState.lastName,
          birthDate: formState.birthDate,
          email: formState.email,
          orgDurations: { organization: '', effectiveDate: '', expirationDate: '' },
          isSelected: true
        }]);
      } else {
        saveContactData(createShallowPerson(formState, [{
          orgRoleId: formState.orgRoleId,
          orgRoleName: formState.orgRoleName
        }]));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [personState.isArrayLoaded]);

  useEffect(() => {
    if (preCompOrganizationRegistrationState.isSaved === true && isSubmitting === false) {
      confirmSave();

      navigate(NavLinks.PRE_COMP_REGISTRATION_ORGANIZATION_CONTACT);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [preCompOrganizationRegistrationState, isSubmitting]);

  useEffect(() => {
    if (roleOptionsState.length > 0) {
      if (location.state && location.state.orgRoleId) {
        setFormData({
          ...formState,
          orgRoleId: location.state.orgRoleId,
          orgRoleName: roleOptionsState.find(x => x.id === location.state.orgRoleId)?.name || ''
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roleOptionsState]);

  useEffect(() => {
    if (PRE_COMP_ORG_ID !== orgRoleHierarchyState.organizationId) {
      getOrgRolesHierarchyByOrganizationId(PRE_COMP_ORG_ID);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [environmentVariableState, orgRoleHierarchyState])

  useEffect(() => {
    if (orgRoleHierarchyState.isArrayLoading === false && orgRoleHierarchyState.isArrayLoaded === true) {
      const orgRoleContacts = orgRoleHierarchyState.arrayData.find(x => x.name === CONTACTS);
      if (orgRoleContacts.children) {
        setRoleOptionsState(orgRoleContacts.children.filter(x => x.name === PRIMARY_CONTACT_LABEL
          || x.name === SECONDARY_CONTACT_LABEL || x.id === Constants.DEFAULT_ID));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [environmentVariableState, orgRoleHierarchyState]);

  function submitFormCallback(formState) {
    const localDupPerson = findLocalDupPerson(preCompOrganizationRegistrationState.objData.preCompetitiveOrganizationContacts);

    if (localDupPerson) {
      setDisplayLocalDupPersonModalState(true);
    } else {
      let hasExistingOrgRole = false;
      let existingContactObj = {};
      for (let i = 0; i < preCompOrganizationRegistrationState.objData.preCompetitiveOrganizationContacts.length; i++) {
        for (let j = 0; j < preCompOrganizationRegistrationState.objData.preCompetitiveOrganizationContacts[i].personOrgRole.length; j++) {
          if (preCompOrganizationRegistrationState.objData.preCompetitiveOrganizationContacts[i].personOrgRole[j]?.orgRoleId === formState.orgRoleId) {
            hasExistingOrgRole = true;
            existingContactObj = preCompOrganizationRegistrationState.objData.preCompetitiveOrganizationContacts[i];
          }
        }
      }

      if (hasExistingOrgRole === true) {
        setExistingContactModalState({
          ...existingContactModalState,
          existingContactFirstName: existingContactObj.firstName,
          existingContactLastName: existingContactObj.lastName,
          existingContactOrgRoleName: formState.orgRoleName,
          displayModal: true
        });
      } else {
        getPotentialPersonDupes(formState.firstName, formState.lastName, formState.birthDate);
      }
    }
  };

  function findLocalDupPerson(contactArray) {
    if (Array.isArray(contactArray) && contactArray.length > 0) {
      for (const contact of contactArray) {
        const isOverwrite = contact.personOrgRole.find(x => x.orgRoleId === formState.orgRoleId);

        if (!isOverwrite) {
          const birthDate = formatDate(contact.birthDate);
          const firstName = contact.firstName;
          const lastName = contact.lastName;

          if (formState.birthDate === birthDate && firstName === formState.firstName && lastName === formState.lastName) {
            return contact;
          }
        }
      }
    }

    return null;
  };

  function getInitialFormState() {
    return {
      personId: '',
      firstName: '',
      lastName: '',
      phoneNumber: '',
      email: '',
      birthDate: Constants.BLANK_DATE_STRING,
      orgRoleId: '',
      orgRoleName: ''
    };
  };

  function getInitialExistingContactModalState() {
    return {
      existingContactFirstName: '',
      existingContactLastName: '',
      existingContactOrgRoleName: '',
      displayModal: false
    };
  };

  return {
    isLoading: personState.isArrayLoading || orgRoleHierarchyState.isArrayLoading,
    isDelete: location.state && location.state.orgRoleId,
    formState,
    errorState,
    preCompOrganizationRegistrationState,
    dupGridState,
    newGridState,
    roleOptionsState,
    locationRegistrationNavState,
    existingContactModalState,
    displayLocalDupPersonModalState,
    handleSubmit,
    onFormValueChanged: updateFormState,
    onValueTextPairChanged,
    onCancelClicked,
    onModalCancelClicked,
    onModalContinueClicked,
    onSelectGridPerson,
    onExistingContactModalConfirmClicked,
    onExistingContactModalCancelClicked,
    onLocalDupPersonModalCancelClicked
  };
};

export default usePreCompRegistrationOrganizationContactsDetail;