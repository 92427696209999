import { useContext } from 'react';

import { PreCompOrganizationRegistrationStateContext } from './PreCompOrganizationRegistrationContextProvider';

import PreCompOrganizationRegistrationData from './PreCompOrganizationRegistrationData';

import useEnvironmentVariableData from '../../../common/state/environmentVariable/UseEnvironmentVariableData';

import { isValidMemberId } from '../../../common/utils/validation';

const usePreCompOrganizationRegistrationData = () => {
  const [preCompOrganizationRegistrationState, setPreCompOrganizationRegistrationState] = useContext(PreCompOrganizationRegistrationStateContext);
  const { PRE_COMP_ORG_ID, PARTNERS_ORG_LEVEL_ID, PARENT_ORG_LEVEL_ID } = useEnvironmentVariableData();

  const getPreCompOrganizationDupCheck = (federalTaxId, state, setState) => {
    PreCompOrganizationRegistrationData.getPreCompOrganizationDupCheckData(federalTaxId, state, setState);
  };

  const postPreCompRegistration = (orgObj, locObj, formState, state, setState) => {
    const registrationObj = JSON.parse(JSON.stringify(orgObj));
    registrationObj.preCompetitiveLocation = JSON.parse(JSON.stringify(locObj));
    registrationObj.preCompetitiveLocation.registrationSignature = formState.signature;
    registrationObj.preCompetitiveLocation.registrationDate = formState.submitDate;
    registrationObj.preCompetitiveLocation.orgUnit.organizationId = PRE_COMP_ORG_ID;
    registrationObj.preCompetitiveLocation.orgUnit.orgLevelId = PARTNERS_ORG_LEVEL_ID;

    registrationObj.orgUnit.organizationId = PRE_COMP_ORG_ID;
    registrationObj.orgUnit.orgLevelId = PARENT_ORG_LEVEL_ID;

    // if contact or staff have a locally made personId(is not a valid memberId), convert to null before post
    for (const contact of registrationObj.preCompetitiveOrganizationContacts) {
      if (!isValidMemberId(contact.personId)) {
        contact.personId = null;
      }
    }

    for (const staff of registrationObj.preCompetitiveLocation.preCompetitiveLocationStaff) {
      if (!isValidMemberId(staff.personId)) {
        staff.personId = null;
      }
    }

    PreCompOrganizationRegistrationData.postPreCompRegistrationData(registrationObj, state, setState);
  };

  const saveObjData = (objData) => {
    setPreCompOrganizationRegistrationState({
      ...preCompOrganizationRegistrationState,
      isSaved: true,
      objData
    });
  };

  const saveContactData = (newContact) => {
    const contacts = preCompOrganizationRegistrationState.objData.preCompetitiveOrganizationContacts;

    let matchFound = false;
    for (let i = 0; i < contacts.length; i++) {
      if (contacts[i].personOrgRole[0].orgRoleId === newContact.personOrgRole[0].orgRoleId) {
        contacts[i] = newContact;
        matchFound = true;
        break;
      }
    }

    if (matchFound === false && contacts.length <= 2) {
      contacts.push(newContact);
    }

    setPreCompOrganizationRegistrationState({
      ...preCompOrganizationRegistrationState,
      isSaved: true,
      objData: {
        ...preCompOrganizationRegistrationState.objData,
        preCompetitiveOrganizationContacts: contacts
      }
    });
  };

  const resetPreCompOrganizationRegistrationState = () => {
    setPreCompOrganizationRegistrationState({
      ...PreCompOrganizationRegistrationData.INITIAL_STATE,
      objData: JSON.parse(JSON.stringify(PreCompOrganizationRegistrationData.PRE_COMP_ORGANIZATION_OBJ))
    });
  };

  const confirmSave = () => {
    setPreCompOrganizationRegistrationState({
      ...preCompOrganizationRegistrationState,
      isSaved: false,
      isObjLoaded: true
    });
  };

  return {
    preCompOrganizationRegistrationState,
    setPreCompOrganizationRegistrationState,
    saveObjData,
    saveContactData,
    getPreCompOrganizationDupCheck,
    postPreCompRegistration,
    resetPreCompOrganizationRegistrationState,
    confirmSave
  };
};

export default usePreCompOrganizationRegistrationData;