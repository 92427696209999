import Constants from '../../../common/utils/Constants';
import { BASIC_INITIAL_STATE, CommonHttpHelper, NO_DATA_MESSAGE } from '../../../common/utils/HttpHelper';

const INITIAL_STATE = {
  ...BASIC_INITIAL_STATE,
  orgUnitId: '',
  groupTypeId: Constants.DEFAULT_ID
};

const getClubGroupsByOrgUnitIdAndGroupTypeIdData = (orgUnitId, groupTypeId, state, setState) => {
  if (state.isArrayLoaded !== true && !state.message) {
    const newState = {
      ...state,
      isArrayLoading: true,
      isArrayLoaded: false,
      orgUnitId,
      groupTypeId,
      message: 'Loading...'
    };
    setState(newState);

    const orgUnitIdForUrl = orgUnitId ? encodeURIComponent(orgUnitId) : 'NaN';
    const groupTypeIdForUrl = groupTypeId ? encodeURIComponent(groupTypeId) : 'NaN';
    const url = `/OrgGroup/orgUnit/${orgUnitIdForUrl}/groupType/${groupTypeIdForUrl}`;
    CommonHttpHelper(url, 'GET')
      .then((arrayData) => {
        if (!arrayData) {
          throw new Error(NO_DATA_MESSAGE);
        } else {
          setState({
            ...newState,
            isArrayLoading: false,
            isArrayLoaded: true,
            arrayData,
            orgUnitId,
            groupTypeId,
            items: arrayData.map(x => { return { id: x.orgGroupId, name: `${x.groupName} (${x.groupCode})` }; }),
            message: ''
          });
        }
      })
      .catch((e) => {
        const message = e && e.message ? e.message : NO_DATA_MESSAGE;
        setState({
          ...newState,
          isArrayLoading: false,
          isArrayLoaded: false,
          message: message
        });
      });
  }
};

const ClubGroupComboboxData = {
  INITIAL_STATE,
  getClubGroupsByOrgUnitIdAndGroupTypeIdData
};

export default ClubGroupComboboxData;