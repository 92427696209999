import useEnvironmentVariableData from '../../../../../../common/state/environmentVariable/UseEnvironmentVariableData';

const useClubPerformanceAthleteTimesMeetSummary = () => {
  const { environmentVariableState, MEET_SUMMARY_OID } = useEnvironmentVariableData();

  return {
    environmentVariableState, MEET_SUMMARY_OID
  };
};

export default useClubPerformanceAthleteTimesMeetSummary;