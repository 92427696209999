import { formatDate } from "../../../../common/utils/DateFunctions";

const isClubArrayDataCurrent = (usasClubId, currentArrayData) => {
  if (currentArrayData.length === 0) {
    return false;
  } else {
    return currentArrayData[0].usasClubId === usasClubId; 
  }
};

const isClubObjectDataCurrent = (usasClubId, currentObjectData) => {
  return usasClubId === currentObjectData.usasClubId;
};

const isCertExpired = (courseExpirationDate) => {
  const today = new Date(formatDate(new Date()));
  const expirationDate = new Date(formatDate(courseExpirationDate));
  if (today > expirationDate) {
    return true;
  }
  return false;
}

export { isClubArrayDataCurrent, isClubObjectDataCurrent, isCertExpired };