import { useContext } from 'react';

import { PreviousSeasonOfferingStateContext } from './PreviousSeasonOfferingContextProvider';

import PreviousSeasonOfferingData from './PreviousSeasonOfferingData';

const usePreviousSeasonOfferingData = () => {
  const [previousSeasonOfferingState, setPreviousSeasonOfferingState] = useContext(PreviousSeasonOfferingStateContext);

  const getPreviousSeasonOfferings = (isAthleteRole) => {
    PreviousSeasonOfferingData.getPreviousSeasonOfferingData(previousSeasonOfferingState, setPreviousSeasonOfferingState, isAthleteRole);
  };

  return {
    previousSeasonOfferingState,
    getPreviousSeasonOfferings
  };
};

export default usePreviousSeasonOfferingData;