import useClubThirdPartyInvoicesDetailForm from "./UseClubThirdPartyInvoicesDetailForm";

import Input from "../../../../common/components/inputs/Input";
import Dropdown from '../../../../common/components/dropdowns/Dropdown';

import global from '../../../../common/components/GlobalStyle.module.css';

const ClubThirdPartyInvoicesDetailForm = ({ clubThirdPartyInvoiceState, setClubThirdPartyInvoiceState, lscOfferingByOrgUnitIdAndReportPeriodIdDropdownState, gridState, setGridState, offeringOptions }) => {
  const {
    formState,
    errorState,
    onFormValueChanged,
    onValueTextPairChanged,
    handleSubmit,
    onClearFilterClicked,
    displayOptions
  } = useClubThirdPartyInvoicesDetailForm(clubThirdPartyInvoiceState, setClubThirdPartyInvoiceState, lscOfferingByOrgUnitIdAndReportPeriodIdDropdownState, gridState, setGridState);

  return (
    <form onSubmit={handleSubmit} >
      <div className='row'>
        <div className="col-xs-12 col-sm-6 col-md-3">
          <Input
            label="First or Preferred Name"
            name="firstOrPreferredName"
            value={formState.firstOrPreferredName}
            error={errorState.firstOrPreferredName}
            message={errorState.firstOrPreferredName}
            onChange={(value) => { onFormValueChanged('firstOrPreferredName', value) }} />
        </div>
        <div className="col-xs-12 col-sm-6 col-md-3">
          <Input
            label="Last Name"
            name="lastName"
            value={formState.lastName}
            error={errorState.lastName}
            message={errorState.lastName}
            onChange={(value) => { onFormValueChanged('lastName', value) }} />
        </div>
        <div className='col-xs-12 col-sm-6 col-md-3'>
          <Dropdown
            label="Submitted Offering Name"
            name="submittedOfferingInstanceId"
            options={offeringOptions.options}
            value={formState.submittedOfferingInstanceId}
            error={errorState.submittedOfferingInstanceId}
            message={errorState.submittedOfferingInstanceId}
            isLoading={false}
            onChange={(newValue, newValueLabel, e) => {
              e && e.target && e.target.value &&
                onValueTextPairChanged(newValue, 'submittedOfferingInstanceId', newValueLabel, 'submittedOfferingName');
            }} />
        </div>
        <div className='col-xs-12 col-sm-6 col-md-3'>
          <Dropdown
            label="Display Options*"
            name="displayOptionId"
            options={displayOptions}
            value={formState.displayOptionId}
            error={errorState.displayOptionId}
            message={errorState.displayOptionId}
            isLoading={false}
            onChange={(newValue, newValueLabel, e) => {
              e && e.target && e.target.value &&
                onValueTextPairChanged(newValue, 'displayOptionId', newValueLabel, 'displayOptionName');
            }} />
        </div>
        <div className='col-xs-6 col-sm-2 col-md-1'>
          <button className={global.PlainButton} type='submit'>Filter</button>
        </div>
        <div className='col-xs-6 col-sm-4 col-md-2'>
          <button className={global.PlainButton} type='button' onClick={onClearFilterClicked}>Clear Filter</button>
        </div>
      </div>
    </form >
  );
};

export default ClubThirdPartyInvoicesDetailForm;