import { Routes, Route } from 'react-router-dom';
import { Navigate } from '../../../common/wrappers/ReactRouterDom';

import ZoneReportingSelection from './zoneReportingSelection/ZoneReportingSelection';
import ZoneReportingReports from './zoneReportingReports/ZoneReportingReports';

import SecuredRouteElement from '../../../common/components/security/SecuredRouteElement';
import LoadingModal from '../../../common/components/dialogs/LoadingModal';

import ZoneReportingContextProvider from '../../state/zoneReporting/ZoneReportingContextProvider';

import useNavRoutes from '../../../common/state/security/UseNavRoutes';
import SelectOrgUnitContextProvider from '../../../common/state/selectOrgUnit/SelectOrgUnitContextProvider';
import PersonOrgRoleContextProvider from '../../../common/state/personOrgRole/PersonOrgRoleContextProvider';

const ZoneReportingRoutes = () => {
  const { navRoutes, isReadyToRoute } = useNavRoutes();

  if (isReadyToRoute !== true) {
    return <LoadingModal />;
  }

  return (
    <Routes>
      <Route path={navRoutes['ZONE_REPORTING_SELECTION']?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes['ZONE_REPORTING_SELECTION']}
            editElement={<ZoneReportingSelection />} />
        }
      />
      <Route path={navRoutes['ZONE_REPORTING_REPORTS']?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes['ZONE_REPORTING_REPORTS']}
            editElement={<ZoneReportingReports />} />
        }
      />

      <Route path={'/*'} element={<Navigate to={navRoutes['ZONE_REPORTING_REPORTS']?.route} replace />} />
      <Route path={'/'} element={<Navigate to={navRoutes['ZONE_REPORTING_SELECTION']?.route} replace />} />
    </Routes>
  );
}

const ZoneReporting = () => (
  <ZoneReportingContextProvider>
    <SelectOrgUnitContextProvider>
      <PersonOrgRoleContextProvider>
        <ZoneReportingRoutes />
      </PersonOrgRoleContextProvider>
    </SelectOrgUnitContextProvider>
  </ZoneReportingContextProvider>
);

export default ZoneReporting;