import { Fragment } from "react";

import { IndicatorChart } from "@sisense/sdk-ui";
import { filterFactory } from "@sisense/sdk-data";

import useMeetSummaryWidget from "./UseMeetSummaryWidget";

import * as DM from '../../../../../../usas-times-data';

import Spinner from "../../../../../../common/components/spinners/Spinner";

import ToIntIfInt from "../../../../../../common/utils/ToIntIfInt";

import global from '../../../../../../common/components/GlobalStyle.module.css';

const MeetSummaryIndicatorWidget = ({ dashboardOid, widgetOid, filterRecordsBroken }) => {
  const {
    isError,
    isLoading,
    widget,
    location,
  } = useMeetSummaryWidget(dashboardOid, widgetOid);

  return (
    <Fragment>
      <div className="col-xs-12 col-sm-6 col-md-3">
        {isError
          ? <p className={global.ErrorMessage}>Error...</p>
          : isLoading
            ? <Spinner />
            : widget
              ?
              <IndicatorChart {...widget.getChartProps()}
                filters={ 
                  filterRecordsBroken === true ?
                  [filterFactory.equals(DM.RecordsCustom.CountAsRecord, 1), 
                    filterFactory.equals(DM.Meet.MeetKey, ToIntIfInt(location.state?.meetKey))] :
                  [filterFactory.equals(DM.Meet.MeetKey, ToIntIfInt(location.state?.meetKey))]}
              />
              : <Fragment />
        }
      </div>
    </Fragment>
  );
};

export default MeetSummaryIndicatorWidget;