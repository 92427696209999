import { isValidAlphanumericAndDollarSign } from '../../../utils/validation';

const DESCRIPTION_MAX_LENGTH = 8000;

const GroupInfoFormValidation = (formState) => {
  let errors = {};

  if (formState.groupName.trim() === '') {
    errors.groupName = 'Group Name is required';
  }

  if (formState.groupCode.trim() === '') {
    errors.groupCode = 'Group Code is required';
  } else if (!isValidAlphanumericAndDollarSign(formState.groupCode.trim())) {
    errors.groupCode = 'Group Code can only contain numbers, letters, and dollar signs';
  }

  if (formState.groupCategories.length === 0) {
    errors.groupCategories = 'Group Category is required';
  }

  if (formState.groupDescription.trim() === '') {
    errors.groupDescription = 'Group Description is required';
  } else if (formState.groupDescription.trim().length > DESCRIPTION_MAX_LENGTH) {
    errors.groupDescription = `Group Description cannot be greater than ${DESCRIPTION_MAX_LENGTH} characters in length`;
  }

  return errors;
};

export default GroupInfoFormValidation;