import React, { Fragment } from 'react';

import PrimaryButton from '../../../../common/components/buttons/PrimaryButton';
import SecondaryButton from '../../../../common/components/buttons/SecondaryButton';

import global from '../../../../common/components/GlobalStyle.module.css';

const ClubRegistrationDisclaimerRead = ({ clubRegistrationState, onContinueClicked, onBackClicked }) => (
  <Fragment>
    <div className={[global.HeaderText, "row usas-extra-bottom-margin"].join(' ')}>
      <div className="col-xs-6 col-md-3">
        <p><b>Owner Type:</b></p>
      </div>
      <div className="col-xs-6 col-md-3">
        <p>{clubRegistrationState.objData?.clubOwnerType?.typeName ?
          clubRegistrationState.objData.clubOwnerType.typeName : 'No Response'}</p>
      </div>
      <div className="col-xs-6 col-md-3">
        <p><b>Tax Listing:</b></p>
      </div>
      <div className="col-xs-6 col-md-3">
        <p>{clubRegistrationState.objData?.businessEntityType?.typeName ?
          clubRegistrationState.objData.businessEntityType.typeName : 'No Response'}</p>
      </div>
    </div>
    <div className="row usas-extra-top-margin usas-extra-bottom-margin">
      <div className="col-xs-12">
        <PrimaryButton type="button" onClick={() => onContinueClicked()}>Continue</PrimaryButton>&nbsp;
        <SecondaryButton type="button" onClick={() => onBackClicked()}>Back</SecondaryButton>
      </div>
    </div>
  </Fragment>
)

export default ClubRegistrationDisclaimerRead;