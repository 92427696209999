import { OmrHttpHelper, NO_DATA_MESSAGE } from '../../../common/utils/HttpHelper';

const PutPersonPotentialMatchesData = (orgUnitId, thirdPartyClubRegistrationId, thirdPartyClubRegistrationPersonId, memberIdObj, state, setState) => {
  if (state.isArrayLoading !== true && !state.message) {
    const newState = {
      ...state,
      isSaving: true,
      isSaved: false,
      message: 'Saving...'
    };
    setState(newState);
    const orgUnitIdForUrl = orgUnitId ? encodeURIComponent(orgUnitId) : '';
    const thirdPartyClubRegistrationIdForUrl = thirdPartyClubRegistrationId ? encodeURIComponent(thirdPartyClubRegistrationId) : '';
    const thirdPartyClubRegistrationPersonIdForUrl = thirdPartyClubRegistrationPersonId ? encodeURIComponent(thirdPartyClubRegistrationPersonId) : 'NaN';
    const url = `/MemberRegistration/OrgUnit/${orgUnitIdForUrl}/TeamInvoice/ThirdPartyClubRegistration/${thirdPartyClubRegistrationIdForUrl}/ThirdPartyClubRegistrationPerson/${thirdPartyClubRegistrationPersonIdForUrl}`;
    OmrHttpHelper(url, 'PUT', memberIdObj)
      .then((objData) => {
        if (!objData) {
          throw new Error(NO_DATA_MESSAGE);
        } else {
          setState({
            ...newState,
            isSaving: false,
            isSaved: true,
            objData,
            message: ''
          })
        }
      })
      .catch((e) => {
        const message = e && e.message ? e.message : NO_DATA_MESSAGE;
        setState({
          ...newState,
          isSaving: false,
          isSaved: false,
          message: message
        });
      });
  }
};

export default PutPersonPotentialMatchesData;