import React, { createContext, useState } from 'react';

import GroupCategoryData from './GroupCategoryData';

export const GroupCategoryStateContext = createContext();

const GroupCategoryContextProvider = ({ children }) => {
  const stateHook = useState(GroupCategoryData.INITIAL_STATE);

  return (
    <GroupCategoryStateContext.Provider value={stateHook}>
      {children}
    </GroupCategoryStateContext.Provider>
  );
};

export default GroupCategoryContextProvider;