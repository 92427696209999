import { isValidDate } from '../../../utils/validation';
import Constants from '../../../utils/Constants';

export const ClubSiteAttributesFormValidation = (formState) => {
  let errors = {};

  const effectiveDate = new Date(formState.effectiveDate);
  const expirationDate = new Date(formState.expirationDate);

  if (formState.facilityId === Constants.DEFAULT_ID || formState.facilityId === '') {
    errors.facilityId = 'Facility is required';
  }

  if (formState.leadCoachId === Constants.DEFAULT_ID || formState.leadCoachId === '') {
    errors.leadCoachId = 'Lead Coach is required';
  }

  if (formState.effectiveDate === Constants.BLANK_DATE_STRING || formState.effectiveDate === '') {
    errors.effectiveDate = 'Effective Date is required';
  } else if (!isValidDate(formState.effectiveDate)) {
    errors.effectiveDate = 'Effective Date must be a valid date';
  } else if (expirationDate < effectiveDate || formState.effectiveDate === formState.expirationDate) {
    errors.effectiveDate = 'Effective Date must be before the Expiration Date';
  }

  if (formState.expirationDate === Constants.BLANK_DATE_STRING || formState.expirationDate === '') {
    errors.expirationDate = 'Expiration Date is required';
  } else if (!isValidDate(formState.expirationDate)) {
    errors.expirationDate = 'Expiration Date must be a valid date';
  } else if (expirationDate < effectiveDate || formState.effectiveDate === formState.expirationDate) {
    errors.expirationDate = 'Expiration Date must be after the Effective Date';
  }

  return errors;
};

export default ClubSiteAttributesFormValidation;