import usePoolDimensionsData from '../../../state/poolDimensions/UsePoolDimensionsData';

const usePoolDimensionsDropdown = () => {
  const { poolDimensionsState } = usePoolDimensionsData();

  return {
    poolDimensionsState
  };
};

export default usePoolDimensionsDropdown;