import { useState } from 'react';

import PersonPotentialMatchData from './PersonPotentialMatchData';

const usePersonPotentialMatchData = () => {
  const [personPotentialMatchState, setPersonPotentialMatchState] = useState(PersonPotentialMatchData.INITIAL_STATE);

  const getPersonPotentialMatches = (firstName, lastName, birthDate) => {
    PersonPotentialMatchData.getPersonPotentialMatchesData(firstName, lastName, birthDate, personPotentialMatchState, setPersonPotentialMatchState);
  };

  const putPersonPotentialMatches = (orgUnitId, thirdPartyClubRegistrationId, thirdPartyClubRegistrationPersonId, memberIdObj) => {
    PersonPotentialMatchData.putPersonPotentialMatchesData(orgUnitId, thirdPartyClubRegistrationId, thirdPartyClubRegistrationPersonId, memberIdObj, personPotentialMatchState, setPersonPotentialMatchState);
  };

  return {
    personPotentialMatchState,
    setPersonPotentialMatchState,
    getPersonPotentialMatches,
    putPersonPotentialMatches
  };
};

export default usePersonPotentialMatchData;