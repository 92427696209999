import { useNavigate } from '../../../common/wrappers/ReactRouterDom';

import NavLinks from './NavLinks';

import usePreCompLocationData from '../../state/preCompLocation/UsePreCompLocationData';

import usePreCompOrganizationData from '../../../common/state/preCompOrganization/UsePreCompOrganizationData';
import useSelectOrgUnitData from '../../../common/state/selectOrgUnit/UseSelectOrgUnitData';
import usePersonOrgRoleData from '../../../common/state/personOrgRole/UsePersonOrgRoleData';
import useTeamFacilityLinksData from '../../../common/state/teamFacilityLinks/UseTeamsFacilityLinksData';

const BACK_TO_LABELS = {
  preCompBackToLabel: 'Back to Pre-Competitive Search'
};

const usePreCompContextView = () => {
  const navigate = useNavigate();
  const clearOrganizationData = usePreCompOrganizationData().clearObjData;
  const clearLocationData = usePreCompLocationData().clearObjData;
  const { preCompOrganizationState } = usePreCompOrganizationData();
  const { preCompLocationState } = usePreCompLocationData();
  const { selectOrgUnitState, resetSelectOrgUnitState } = useSelectOrgUnitData();
  const { clearArrayData } = usePersonOrgRoleData();
  const { clearTeamFacilityLinksArrayData } = useTeamFacilityLinksData();


  const onBackClicked = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    goToPreCompSearch();
  };

  const onHomeClicked = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    navigate(NavLinks.PRE_COMP_ORGANIZATION_INFO);
  };

  const onBackToSelectionClicked = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    resetSelectOrgUnitState();
    clearArrayData();
    clearOrganizationData();
    clearLocationData();
    clearTeamFacilityLinksArrayData();
    navigate(NavLinks.PRE_COMP_SELECTION);
  };

  function goToPreCompSearch() {
    clearOrganizationData();
    clearLocationData();
    clearTeamFacilityLinksArrayData();
    navigate(NavLinks.PRE_COMP_SEARCH);
  };

  function getBackToLabel() {
    return BACK_TO_LABELS.preCompBackToLabel;
  };

  return {
    preCompOrganizationState,
    preCompLocationState,
    backToLabel: getBackToLabel(),
    selectOrgUnitState,
    onBackClicked,
    onHomeClicked,
    onBackToSelectionClicked
  };
};

export default usePreCompContextView;