import { useEffect, useState } from "react";
import { useNavigate } from "../../../../common/wrappers/ReactRouterDom";

import NavLinks from "../NavLinks";

import useClubMeetsData from "../../../state/clubMeets/UseClubMeetsData";

import useReportPeriodSeasonData from "../../../../common/state/reportPeriodSeason/UseReportPeriodSeasonData";
import useBaseUsasClubData from "../../../../common/state/clubBase/UseBaseUsasClubData";
import useMeetSessionsData from "../../../../common/state/meetSessions/UseMeetSessionsData";

import Constants from "../../../../common/utils/Constants";
import UseForm from "../../../../common/utils/UseForm";
import { formatDate } from "../../../../common/utils/DateFunctions";

const UseClubMeets = () => {
  const navigate = useNavigate();
  const { baseUsasClubState } = useBaseUsasClubData();
  const { clubMeetsState, getClubMeets, deleteClubMeet, confirmSave } = useClubMeetsData();
  const { reportPeriodSeasonState } = useReportPeriodSeasonData();
  const { resetMeetSessionsData } = useMeetSessionsData();
  const { formState, errorState, onValueTextPairChanged, setFormState
  } = UseForm(getInitialFormState, () => { }, () => { });
  const [deleteModalState, setDeleteModalState] = useState(getInitialDeleteModalState);
  const [gridState, setGridState] = useState([]);

  const onModalDeleteClicked = () => {
    deleteClubMeet(deleteModalState.meetId);
    setDeleteModalState(getInitialDeleteModalState);
  };

  const onModalCancelClicked = () => {
    setDeleteModalState(getInitialDeleteModalState);
  };

  const onRequestButtonClicked = () => {
    resetMeetSessionsData();
    navigate(NavLinks.CLUB_MEETS_SANCTION);
  };

  const onDeleteClicked = (meet) => {
    setDeleteModalState({
      ...deleteModalState,
      displayPopUp: true,
      meetId: meet.meetId,
      meetName: meet.meetName,
      meetDates: `${formatDate(meet.startDate)} - ${formatDate(meet.endDate)}`
    });
  };

  const onUploadClicked = (meet) => {
    navigate(NavLinks.CLUB_MEETS_UPLOAD, { state: { meet } });
  };

  const onReportPeriodChanged = (value, valueLabel) => {
    onValueTextPairChanged(value, 'reportPeriodId', valueLabel, 'reportPeriodLabel');
  };

  useEffect(() => {
    if (reportPeriodSeasonState.isArrayLoading === false && reportPeriodSeasonState.isArrayLoaded === true) {
      const today = new Date();
      let todayFormatted;
      todayFormatted = new Date(formatDate(today));

      const currentReportPeriods = [];
      const futureReportPeriods = [];
      let startDate;
      let endDate;
      for (let i = 0; i < reportPeriodSeasonState.arrayData.length; i++) {
        const selectedArrayData = reportPeriodSeasonState.arrayData[i];
        startDate = new Date(formatDate(selectedArrayData.startDate));
        endDate = new Date(formatDate(selectedArrayData.endDate));
        if (todayFormatted <= endDate && todayFormatted >= startDate) {
          currentReportPeriods.push(selectedArrayData);
        }
        if (todayFormatted < startDate) {
          futureReportPeriods.push(selectedArrayData);
        }
      }
      const arrayDataCopy = JSON.parse(JSON.stringify(reportPeriodSeasonState.arrayData));
      if (currentReportPeriods.length > 0) {
        const currentOption = arrayDataCopy.find(x => x.reportPeriodId === currentReportPeriods[0].reportPeriodId);
        setFormState({ ...formState, reportPeriodId: currentOption.reportPeriodId, reportPeriodLabel: currentOption.periodName });
      }
      else if (futureReportPeriods.length > 0) {
        const futureOption = arrayDataCopy.find(x => x.reportPeriodId === futureReportPeriods[0].reportPeriodId);
        setFormState({ ...formState, reportPeriodId: futureOption.reportPeriodId, reportPeriodLabel: futureOption.periodName });
      }
      else {
        const currentOption = arrayDataCopy[arrayDataCopy.length - 1];
        setFormState({ ...formState, reportPeriodId: currentOption.reportPeriodId, reportPeriodLabel: currentOption.periodName });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps   
  }, [reportPeriodSeasonState]);

  useEffect(() => {
    if (clubMeetsState.isSaved === false && baseUsasClubState.objData.orgUnitId && formState.reportPeriodId > 0) {
      getClubMeets(baseUsasClubState.objData.orgUnitId, formState.reportPeriodId);
    } else if (clubMeetsState.isSaved === true) {
      confirmSave();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clubMeetsState.isSaved, formState.reportPeriodId]);

  useEffect(() => {
    if (clubMeetsState.isArrayLoading === false && clubMeetsState.isArrayLoaded === true) {
      const today = new Date();
      const newMeetArray = [];

      for (const meet of clubMeetsState.arrayData) {
        const meetStartDate = new Date(meet.startDate);

        newMeetArray.push({
          ...meet,
          isPastMeet: today >= meetStartDate
        });
      }

      setGridState(newMeetArray);
    }
  }, [clubMeetsState]);

  function getInitialFormState() {
    return {
      reportPeriodId: Constants.DEFAULT_ID,
      reportPeriodLabel: ''
    };
  };

  function getInitialDeleteModalState() {
    return {
      displayPopUp: false,
      meetId: Constants.DEFAULT_ID,
      meetName: '',
      meetDates: ''
    };
  };

  return {
    clubMeetsState,
    gridState,
    formState,
    errorState,
    deleteModalState,
    isSaving: clubMeetsState.isSaving,
    isLoading: baseUsasClubState.isObjLoading || clubMeetsState.isArrayLoading,
    organizationId: baseUsasClubState.objData?.orgUnit?.organizationId || Constants.DEFAULT_ID,
    clubOrgUnitId: baseUsasClubState.objData?.orgUnitId || '',
    onRequestButtonClicked,
    onDeleteClicked,
    onReportPeriodChanged,
    onUploadClicked,
    onModalCancelClicked,
    onModalDeleteClicked
  };
};

export default UseClubMeets;