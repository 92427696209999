import { ClubHttpHelper, NO_DATA_MESSAGE } from '../../../common/utils/HttpHelper';

const GetClubSwimAThonLeaderboardData = (orgUnitId, state, setState) => {
  if (state.isObjLoaded !== true && !state.message) {
    const newState = {
      ...state,
      isObjLoading: true,
      isObjLoaded: false,
      message: 'Loading...'
    };
    setState(newState);

    const orgUnitIdForUrl = orgUnitId ? encodeURIComponent(orgUnitId) : 'NaN';
    const url = `/SatResult/Leaderboard?orgUnitId=${orgUnitIdForUrl}`;
    ClubHttpHelper(url, 'GET')
    .then((objData) => {
      if (!objData) {
        throw new Error(NO_DATA_MESSAGE);
      } else {
        setState({
          ...newState,
          isObjLoading: false,
          isObjLoaded: true,
          objData,
          message: ''
        })
      }
      })
      .catch((e) => {
        const message = e && e.message ? e.message : NO_DATA_MESSAGE;
        setState({
          ...newState,
          isObjLoading: false,
          isObjLoaded: false,
          message: message
        });
      });
  }
};

export default GetClubSwimAThonLeaderboardData;