import { useState } from 'react';

import ClubAthleteRosterData from './ClubAthleteRosterData';

const useClubAthleteRosterData = () => {
  const [clubAthleteRosterState, setClubAthleteRosterState] = useState(ClubAthleteRosterData.INITIAL_STATE);

  const searchClubAthleteRoster = (usasClubId, filterObj) => ClubAthleteRosterData.searchClubAthleteRosterData(usasClubId, JSON.stringify(filterObj), clubAthleteRosterState, setClubAthleteRosterState);

  return {
    clubAthleteRosterState,
    searchClubAthleteRoster
  };
};

export default useClubAthleteRosterData;