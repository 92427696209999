import { BASIC_INITIAL_STATE } from '../../../common/utils/HttpHelper';
import Constants from '../../../common/utils/Constants';

import getPreCompOrganizationDupCheckData from './GetPreCompOrganizationDupCheckData';
import postPreCompRegistrationData from './PostPreCompRegistrationData';

const PRE_COMP_ORGANIZATION_OBJ = {
  preCompetitiveOrganizationId: undefined,
  businessEntityTypeId: Constants.DEFAULT_ID,
  federalTaxId: '',
  tradeName: '',
  website: '',
  isActive: false,
  formW9Url: '',
  address: {
    address1: '',
    address2: '',
    city: '',
    stateCode: '',
    postalCode: '',
    countryCode: 'USA',
  },
  orgUnit: {
    organizationId: Constants.DEFAULT_ID,
    orgLevelId: Constants.DEFAULT_ID,
    orgUnitName: ''
  },
  preCompOrganizationAffiliation: [],
  insuranceProvider: [],
  preCompetitiveOrganizationContacts: [],
  preCompetitiveLocation: {}
};

const INITIAL_STATE = {
  ...BASIC_INITIAL_STATE
};

const PreCompOrganizationRegistrationData = {
  PRE_COMP_ORGANIZATION_OBJ,
  INITIAL_STATE,
  getPreCompOrganizationDupCheckData,
  postPreCompRegistrationData
};

export default PreCompOrganizationRegistrationData;
