import React, { Fragment } from 'react';

import useSafeSportCategoryMeasure from './UseSafeSportCategoryMeasure';

import SafeSportCategoryMeasureHeader from './components/SafeSportCategoryMeasureHeader';

import KpiViewBasicReport from '../../../components/views/kpiView/KpiViewBasicReport';
import KpiViewCoachRosterReport from '../../../components/views/kpiView/KpiViewCoachRosterReport';
import KpiViewHeadCoachReport from '../../../components/views/kpiView/KpiViewHeadCoachReport';
import KpiViewClubStaffReport from '../../../components/views/kpiView/KpiViewClubStaffReport';
import KpiViewStaffRoleReport from '../../../components/views/kpiView/KpiViewStaffRoleReport';

import PopUpModal from '../../../../common/components/dialogs/PopUpModal';
import PageNotFound from '../../../../common/components/PageNotFound';

import Constants from '../../../../common/utils/Constants';

const SafeSportKpi = ({ canOverrideScore = false }) => {
  const {
    isLoading,
    isReadonly,
    programFocusState,
    KPI_BASIC_MEASURE_TYPE_ID,
    KPI_COACH_ROSTER_MEASURE_TYPE_ID,
    KPI_COACH_YEARS_MEASURE_TYPE_ID,
    onRedirectToCategory
  } = useSafeSportCategoryMeasure();

  if (!programFocusState || Object.keys(programFocusState?.levelCategoryObj).length === 0 ||
    Object.keys(programFocusState?.programLevelObj).length === 0) {
    return (
      <PageNotFound />
    );
  }

  return (
    <Fragment>
      <SafeSportCategoryMeasureHeader
        programCategory={programFocusState.levelCategoryObj}
        categoryMeasure={programFocusState.categoryMeasureObj}
        onBackClicked={onRedirectToCategory} />
      <div className="row">
        <div className="col-xs-12">
          {isLoading === true
            ? <Fragment />
            : programFocusState.categoryMeasureObj?.measureTypeId === KPI_BASIC_MEASURE_TYPE_ID
              ? (
                <KpiViewBasicReport
                  programLevel={programFocusState.programLevelObj}
                  categoryMeasure={programFocusState.categoryMeasureObj}
                  onRedirect={onRedirectToCategory}
                  isReadonly={isReadonly}
                  canOverrideScore={canOverrideScore} />
              ) : programFocusState.categoryMeasureObj?.measureTypeId === KPI_COACH_ROSTER_MEASURE_TYPE_ID
                ? (
                  <KpiViewCoachRosterReport
                    programLevel={programFocusState.programLevelObj}
                    categoryMeasure={programFocusState.categoryMeasureObj}
                    onRedirect={onRedirectToCategory}
                    isReadonly={isReadonly}
                    canOverrideScore={canOverrideScore} />
                ) : programFocusState.categoryMeasureObj?.measureTypeId === KPI_COACH_YEARS_MEASURE_TYPE_ID
                  ? (
                    <KpiViewHeadCoachReport
                      programLevel={programFocusState.programLevelObj}
                      categoryMeasure={programFocusState.categoryMeasureObj}
                      onRedirect={onRedirectToCategory}
                      isReadonly={isReadonly}
                      canOverrideScore={canOverrideScore} />
                  ) : programFocusState.categoryMeasureObj?.measureTypeName === Constants.KPI_CLUB_STAFF_MEASURE_TYPE_NAME
                    ? (
                      <KpiViewClubStaffReport
                        programLevel={programFocusState.programLevelObj}
                        categoryMeasure={programFocusState.categoryMeasureObj}
                        onRedirect={onRedirectToCategory}
                        isReadonly={isReadonly}
                        includeSubmittedValueInput={true}
                        textInputLabel={'Club Website Url'}
                        canOverrideScore={canOverrideScore} />
                    ) : programFocusState.categoryMeasureObj?.measureTypeName === Constants.KPI_STAFF_ROLE_MEASURE_TYPE_NAME
                      ? (
                        <KpiViewStaffRoleReport
                          programLevel={programFocusState.programLevelObj}
                          categoryMeasure={programFocusState.categoryMeasureObj}
                          onRedirect={onRedirectToCategory}
                          isReadonly={isReadonly}
                          canOverrideScore={canOverrideScore} />
                      ) : <Fragment />
          }
        </div>
      </div>
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={isLoading} />
    </Fragment>
  );
};

export default SafeSportKpi;