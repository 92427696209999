import { useState } from "react";
import { useNavigate } from "../../../../common/wrappers/ReactRouterDom";

import { PROVIDER_INFO_NAME } from "../components/leftNav/LearnToSwimRegistrationLeftNavConstants";

import { createProviderObjFromProviderInfoForm } from "../utils/LearnToSwimRegistrationUtils";
import NavLinks from "../utils/NavLinks";

import useLearnToSwimRegistrationData from "../../../state/learnToSwimRegistration/UseLearnToSwimRegistrationData";
import useLearnToSwimRegistrationLeftNavData from "../../../state/learnToSwimRegistrationLeftNav/UseLearnToSwimRegistrationLeftNavData";

const INITIAL_MODAL_STATE = {
  displayModal: false
};

const useLearnToSwimRegistrationProviderInfo = () => {
  const navigate = useNavigate();
  const { ltsProviderRegistrationState, putLTSProviderRegistration } = useLearnToSwimRegistrationData();
  const { updateCurrentLeftNavData } = useLearnToSwimRegistrationLeftNavData();
  const [modalState, setModalState] = useState(INITIAL_MODAL_STATE);

  const onSubmitFormCallback = (formState) => {
    const providerObj = createProviderObjFromProviderInfoForm(formState, ltsProviderRegistrationState.objData);
    const putLTSProviderRegistrationPromise = putLTSProviderRegistration(providerObj);

    updateCurrentLeftNavData(PROVIDER_INFO_NAME, true);

    if (putLTSProviderRegistrationPromise !== null) {
      putLTSProviderRegistrationPromise.then((newState) => {
        if (newState !== null) {
          navigate(NavLinks.LEARN_TO_SWIM_REGISTRATION_FACILITIES);
        }
      }).catch(() => {
        //TODO context error
        updateCurrentLeftNavData(PROVIDER_INFO_NAME, false);
      });
    }
  };

  const onExitClicked = () => {
    setModalState({
      ...modalState,
      displayModal: true
    });
  };

  const onModalConfirmClicked = () => {
    navigate(NavLinks.LEARN_TO_SWIM_REGISTRATION_ENTRY);
  };

  const onModalCancelClicked = () => {
    setModalState({
      ...INITIAL_MODAL_STATE
    });
  };

  return {
    isLoading: ltsProviderRegistrationState.isObjLoading,
    ltsProviderRegistrationState,
    modalState,
    onSubmitFormCallback,
    onExitClicked,
    onModalConfirmClicked,
    onModalCancelClicked
  };
};

export default useLearnToSwimRegistrationProviderInfo;