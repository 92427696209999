import React, { Fragment } from 'react';

import HideIcon from '../../../../../common/components/icons/HideIcon';
import ShowIcon from '../../../../../common/components/icons/ShowIcon';
import EditIcon from '../../../../../common/components/icons/EditIcon';
import AddPoolIcon from '../../../../../common/components/icons/AddPoolIcon';
import DeleteIcon from '../../../../../common/components/icons/DeleteIcon';
import UnlinkIcon from '../../../../../common/components/icons/UnlinkIcon';

import global from '../../../../../common/components/GlobalStyle.module.css';

const DetailTableRow = ({ pool, onEditPool, onDeletePool }) => (
  <tr>
    <td>{pool.facilityPoolName}</td>
    <td>{pool.poolDimensions?.dimensionName}</td>
    <td>
      {onEditPool !== undefined &&
        <button className={global.IconButtonMargin} type="button" onClick={(e) => onEditPool(e, pool.facilityId, pool.facilityPoolId)}>
          <EditIcon />
        </button>}
      {onDeletePool !== undefined &&
        <button className={global.IconButtonMargin} type="button" onClick={(e) => onDeletePool(e, pool)}>
          <DeleteIcon />
        </button>}
    </td>
  </tr>
);

const DetailTable = ({ pool, onEditPool, onDeletePool }) => (
  <table className={global.DetailTable}>
    <thead>
      <tr>
        <th>Pool Number / Name</th>
        <th>Pool Dimensions</th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      {pool.facility && pool.facility.facilityPool && pool.facility.facilityPool.map((pool, i) =>
        <DetailTableRow pool={pool} key={i} onEditPool={onEditPool} onDeletePool={onDeletePool} />)}
    </tbody>
  </table>
);

const TableRow = ({ facility, onEdit, onDelete, onAddPool, onEditPool, onDeletePool, expandedId, onClick }) => {
  return (
    <Fragment>
      <tr key={facility.facilityId}>
        <td>{facility.facility?.facilityName || ''}</td>
        <td>{facility.facility?.city || ''}</td>
        <td>{facility.facility?.stateCode || ''}</td>
        <td>{facility.facility?.postalCode || ''}</td>
        <td>{facility.facility?.address1 || ''}</td>
        <td>
          {onEdit !== undefined &&
            <button
              className={global.IconButtonMargin}
              type="button"
              onClick={() => onEdit(facility.facilityId)}>
              <EditIcon />
            </button>
          }
          {onDelete !== undefined &&
            <button
              className={global.IconButtonMargin}
              type="button"
              onClick={() => onDelete(facility.facility?.facilityId, `${facility.facility.facilityName}`)}>
              <UnlinkIcon />
            </button>
          }
          {onAddPool !== undefined ?
            <button className={global.IconButtonMargin} type="button" onClick={(e) => onAddPool(e, facility.facilityId)}>
              <AddPoolIcon />
            </button> : <Fragment />}
          <button
            className={global.IconButtonMargin}
            type="button"
            onClick={(e) => onClick(e, facility.facilityId)}>
            {expandedId === facility.facilityId ? <HideIcon /> : <ShowIcon />}
          </button>
        </td>
      </tr>
      {Array.isArray(facility.facility.facilityPool) && facility.facility.facilityPool.length > 0 &&
        expandedId === facility.facilityId &&
        <tr className={global.Expanded}>
          <td colSpan="6">
            <DetailTable pool={facility} onEditPool={onEditPool} onDeletePool={onDeletePool} />
          </td>
        </tr>}
    </Fragment>
  );
};

const PreCompLocationFacilityGridLarge = ({ data, isLoading, onEdit, onDelete, onAddPool, onEditPool, onDeletePool,
  expandedId, onClick }) => (
  <table className={[global.UsasTable, 'visible-md visible-lg'].join(' ')}>
    <thead>
      <tr>
        <th>Facility Name</th>
        <th>City</th>
        <th>State</th>
        <th>Zip Code</th>
        <th>Address</th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      {isLoading === true
        ? <tr><td colSpan="6">Loading...</td></tr>
        : data.length > 0
          ? data.map((facility, i) =>
            <TableRow key={i} facility={facility} onEdit={onEdit}
              onDelete={data.length > 1 ? onDelete : undefined}
              onAddPool={onAddPool} onEditPool={onEditPool} onDeletePool={onDeletePool}
              expandedId={expandedId} onClick={onClick} />)
          : <tr><td colSpan="6">No Facilities</td></tr>
      }
    </tbody>
  </table>
);

export default PreCompLocationFacilityGridLarge;