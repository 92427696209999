import { useContext, useEffect } from 'react';

import { ClubInactiveReasonStateContext } from './ClubInactiveReasonContextProvider';

import ClubInactiveReasonData from './ClubInactiveReasonData';

const useClubInactiveReasonData = () => {
  const [clubInactiveReasonState, setClubInactiveReasonState] = useContext(ClubInactiveReasonStateContext);

  useEffect(() => {
    if (clubInactiveReasonState.isArrayLoading !== true && clubInactiveReasonState.isArrayLoaded !== true) {
      ClubInactiveReasonData.GetClubInactiveReasonsData(clubInactiveReasonState, setClubInactiveReasonState);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (clubInactiveReasonState.isArrayLoaded === true && clubInactiveReasonState.isOptionsLoaded !== true) {
      const newState = {
        ...clubInactiveReasonState,
        options: clubInactiveReasonState.arrayData.map((inactiveReason) => { return { id: inactiveReason.clubInactiveReasonId, name: inactiveReason.inactiveReasonDescription } }),
        isOptionsLoaded: true
      };
      setClubInactiveReasonState(newState);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clubInactiveReasonState.isArrayLoaded, clubInactiveReasonState.isOptionsLoaded]);

  return {
    clubInactiveReasonState
  };
};

export default useClubInactiveReasonData;