export const ORG_ROLE_NAME_SECONDARY_CONTACT = 'Secondary Contact';
export const OFFERING_NAME_STUDENT_CATEGORY = 'Learn To Swim';
export const OFFERING_NAME_LESSON_CATEGORY = 'Training/Certification';
export const POLICY_TYPE_NAME_GENERAL_LIABILITY = 'General Liability';

const PreCompConstants = {
  ORG_ROLE_NAME_SECONDARY_CONTACT,
  OFFERING_NAME_STUDENT_CATEGORY,
  OFFERING_NAME_LESSON_CATEGORY,
  POLICY_TYPE_NAME_GENERAL_LIABILITY
};

export default PreCompConstants;