import { BASIC_INITIAL_STATE } from '../../../../common/utils/HttpHelper';

import getNewClubRegistrationStatus from './GetNewClubRegistrationStatusData';

const INITIAL_STATE = {
  ...BASIC_INITIAL_STATE
};

const NewClubRegistrationStatusData = {
  INITIAL_STATE,
  getNewClubRegistrationStatus
};

export default NewClubRegistrationStatusData;