import { BASIC_INITIAL_STATE } from '../../../common/utils/HttpHelper';

const GET_DOWNLOAD_TEMPLATE_PATH = '/LearnToSwimFiles/downloadTemplate/';

const INITIAL_STATE = {
  ...BASIC_INITIAL_STATE
};

const UsasClubFilesData = {
  GET_DOWNLOAD_TEMPLATE_PATH,
  INITIAL_STATE,
};

export default UsasClubFilesData;