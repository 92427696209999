import { BASIC_INITIAL_STATE } from '../../../utils/HttpHelper';

import getLscSecurityMembers from './GetLscSecurityMembersData';
import postLscSecurityMember from '../../orgUnitStaff/PostOrgUnitStaffData';
import putLscSecurityMember from '../../orgUnitStaff/PutOrgUnitStaffData';
import deleteLscSecurityMember from '../../orgUnitStaff/DeletePersonOrgRoleDurationData';

const INITIAL_STATE = {
  ...BASIC_INITIAL_STATE
};

const MemberAdministrationLscSecurityRolesData = {
  INITIAL_STATE,
  getLscSecurityMembers,
  postLscSecurityMember,
  putLscSecurityMember,
  deleteLscSecurityMember
};

export default MemberAdministrationLscSecurityRolesData;