import { useContext } from 'react';

import { MemberAdministrationHeaderStateContext } from './MemberAdministrationHeaderContextProvider';

const useMemberAdministrationHeaderData = () => {
  const [memberAdministrationHeaderState, setMemberAdministrationHeaderState] = useContext(MemberAdministrationHeaderStateContext);

  const clearMemberAdministrationHeaderObjData = () => {
    setMemberAdministrationHeaderState({
      ...memberAdministrationHeaderState,
      isObjLoading: false,
      isObjLoaded: false,
      isSaving: false,
      isSaved: false,
      objData: {},
      message: '',
      lscManagementHqSelection: false
    });
  };

  return {
    memberAdministrationHeaderState,
    setMemberAdministrationHeaderState,
    clearMemberAdministrationHeaderObjData
  };
};

export default useMemberAdministrationHeaderData;