import { useEffect } from "react";

import useLearnToSwimRegistrationData from "../../../../state/learnToSwimRegistration/UseLearnToSwimRegistrationData";
import useLearnToSwimRegistrationLeftNavData from "../../../../state/learnToSwimRegistrationLeftNav/UseLearnToSwimRegistrationLeftNavData";

const useLearnToSwimRegistrationLeftNav = (viewName) => {
  const { ltsRegistrationLeftNavState, refreshLeftNavState, updateCurrentLeftNavData
  } = useLearnToSwimRegistrationLeftNavData();
  const { ltsProviderRegistrationState } = useLearnToSwimRegistrationData();

  useEffect(() => {
    if (ltsProviderRegistrationState.isObjLoaded === true) {
      if (ltsRegistrationLeftNavState.isLoaded !== true) {
        refreshLeftNavState(viewName, ltsProviderRegistrationState.objData || {});
      } else {
        updateCurrentLeftNavData(viewName);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ltsRegistrationLeftNavState.isLoaded, ltsProviderRegistrationState.isObjLoaded]);

  return {
    ltsRegistrationLeftNavState
  };
};

export default useLearnToSwimRegistrationLeftNav;