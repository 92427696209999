import React, { Fragment } from "react";

import DeleteIcon from "../../../../common/components/icons/DeleteIcon";
import EditIcon from "../../../../common/components/icons/EditIcon";
import HideIcon from "../../../../common/components/icons/HideIcon";
import ShowIcon from "../../../../common/components/icons/ShowIcon";
import SGColumnHeader from "../../../../common/components/grids/SGColumnHeader";

import useSortableGrid from "../../../../common/utils/UseSortableGrid";
import SGConstants from "../../../../common/utils/SGConstants";

import global from '../../../../common/components/GlobalStyle.module.css';

const DetailTableRow = ({ personId, role, onEdit, onDelete }) => (
  <tr>
    <td>{role.orgRoleName || ''}</td>
    <td>{role.effectiveDate || ''}</td>
    <td>{role.expirationDate || ''}</td>
    <td>
      {onEdit !== undefined && role.isCurrent === true &&
        <button
          className={global.IconButtonMargin}
          type="button"
          onClick={() => onEdit(personId, role.personOrgRoleDurationId)}>
          <EditIcon />
        </button>
      }
      {onDelete !== undefined && role.canDelete === true &&
        <button
          className={global.IconButtonMargin}
          type="button"
          onClick={() => onDelete(personId, role.personOrgRoleDurationId)}>
          <DeleteIcon />
        </button>
      }
    </td>
  </tr>
);

const DetailTable = ({ personId, roleDurations, onEdit, onDelete }) => (
  <table className={global.DetailTable}>
    <thead>
      <tr>
        <th>Role</th>
        <th>Effective Date</th>
        <th>Expiration Date</th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      {Array.isArray(roleDurations) && roleDurations.map((role, i) =>
        <DetailTableRow key={i} personId={personId} role={role} onEdit={onEdit} onDelete={onDelete} />)}
    </tbody>
  </table>
);

const GridRow = ({ staff, onEdit, onDelete, expandedId, onClick }) => {
  return (
    <Fragment>
      <tr>
        <td colSpan="2">{staff.firstName || ''} {staff.lastName || ''}</td>
        <td>{staff.email || ''}</td>
        <td>
          <button
            className={global.IconButtonMargin}
            type="button"
            onClick={(e) => onClick(e, staff.personId)}>
            {expandedId === staff.personId ? <HideIcon /> : <ShowIcon />}
          </button>
        </td>
      </tr>
      {Array.isArray(staff.roleDurations) && staff.roleDurations.length > 0 && expandedId === staff.personId &&
        <tr className={global.Expanded}>
          <td colSpan="4">
            <DetailTable personId={staff.personId} roleDurations={staff.roleDurations} onEdit={onEdit} onDelete={onDelete} />
          </td>
        </tr>}
    </Fragment>
  );
};

const LSCBoardMembersGridLarge = ({ data, isLoading, onEdit, onDelete, expandedId, onClick }) => {
  const {
    sortableGridState,
    onColumnClicked
  } = useSortableGrid(data);

  return (
    <table className={[global.UsasTable, 'visible-md visible-lg'].join(' ')}>
      <thead>
        <tr>
          <SGColumnHeader sortableGridState={sortableGridState} columnLabel={'LSC Board Member Name'} columnField={'lastName'}
            sortType={SGConstants.SORT_TYPE_ALPHABETICAL} onColumnClicked={onColumnClicked} colSpan={'2'} />
          <SGColumnHeader sortableGridState={sortableGridState} columnLabel={'Email'} columnField={'email'}
            sortType={SGConstants.SORT_TYPE_ALPHABETICAL} onColumnClicked={onColumnClicked} colSpan={'2'} />
        </tr>
      </thead>
      <tbody>
        {isLoading === true
          ? <tr><td colSpan="4">Loading...</td></tr>
          : sortableGridState.sortedGridData.length > 0
            ? sortableGridState.sortedGridData.map((staff, i) => <GridRow key={i} staff={staff} onEdit={onEdit} onDelete={onDelete}
              expandedId={expandedId} onClick={onClick} />)
            : <tr><td colSpan="4">No LSC Board Members</td></tr>
        }
      </tbody>
    </table>
  );
};

export default LSCBoardMembersGridLarge;