import React, { createContext, useState } from 'react';

import PreviousSeasonOfferingData from './PreviousSeasonOfferingData';

export const PreviousSeasonOfferingStateContext = createContext();

const PreviousSeasonOfferingContextProvider = ({ children }) => {
  const stateHook = useState(PreviousSeasonOfferingData.INITIAL_STATE);

  return (
    <PreviousSeasonOfferingStateContext.Provider value={stateHook}>
      {children}
    </PreviousSeasonOfferingStateContext.Provider>
  );
};

export default PreviousSeasonOfferingContextProvider;