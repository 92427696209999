import React, { createContext, useState } from 'react';

import { BASIC_INITIAL_STATE } from '../../../common/utils/HttpHelper';

export const LearnToSwimStateContext = createContext();

const INITIAL_STATE = {
  ...BASIC_INITIAL_STATE
};

const LearnToSwimContextProvider = ({ children }) => {
  const stateHook = useState(INITIAL_STATE);

  return (
    <LearnToSwimStateContext.Provider value={stateHook}>
      {children}
    </LearnToSwimStateContext.Provider>
  );
};

export default LearnToSwimContextProvider;