import React, { Fragment } from 'react';

import useClubRecognitionProgramLevel from './UseClubRecognitionProgramLevel';

import ProgramLevelGrid from '../../../../common/components/grids/programLevel/ProgramLevelGrid';
import Headings from '../../../../common/components/headings/Headings';
import PopUpModal from '../../../../common/components/dialogs/PopUpModal';
import PrimaryButton from '../../../../common/components/buttons/PrimaryButton';
import SecondaryButton from '../../../../common/components/buttons/SecondaryButton';
import ActionIntraPageButton from '../../../../common/components/buttons/ActionIntraPageButton';

import global from '../../../../common/components/GlobalStyle.module.css';
import Constants from '../../../../common/utils/Constants';

const ClubRecognitionProgramLevel = () => {
  const {
    isLoading,
    isSaving,
    recognitionProgramState,
    modalState,
    buttonsState,
    onSubmitLevelInstance,
    onEditProgramLevel,
    onModalSubmit,
    onModalCanceled,
    onInitiateProgramLevel,
    onRenewProgramLevel
  } = useClubRecognitionProgramLevel();

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>Program Levels</Headings.H3>
        </div>
      </div>
      <div className="row usas-extra-top-margin">
        {buttonsState.showRenewButton === true &&
          <div className="col-xs-12 col-sm-4">
            <ActionIntraPageButton type='button' onClick={onRenewProgramLevel}>
              Renew Level {buttonsState.renewButtonLevel} Program
            </ActionIntraPageButton>
          </div>
        }
        {buttonsState.showInitiateButton === true &&
          <div className="col-xs-12 col-sm-4">
            <ActionIntraPageButton type='button' onClick={onInitiateProgramLevel}>
              Initiate Level {buttonsState.initiateButtonLevel} Program
            </ActionIntraPageButton>
          </div>
        }
      </div>
      <div className="row usas-extra-bottom-margin">
        <div className="col-xs-12">
          <ProgramLevelGrid
            data={recognitionProgramState.arrayData}
            isLoading={recognitionProgramState.isArrayLoading}
            onSubmit={onSubmitLevelInstance}
            onEdit={onEditProgramLevel}
            showStatus={true}
          />
        </div>
      </div>
      <PopUpModal
        widthPct={90}
        maxWidth={720}
        title={modalState.modalTitle}
        displayPopUp={modalState.displayPopUp}
        onModalCanceled={onModalCanceled}>
        {modalState.pointsErrors.length > 0 ?
          <div className="row">
            <div className="col-xs-12" >
              <p><b>Minimum Points Errors:</b></p>
              {modalState.pointsErrors.map((error, i) => <p key={i} className={global.ErrorMessage}>{error}</p>)}
            </div>
          </div> : <Fragment />
        }
        {modalState.requiredErrors.length > 0 ?
          <div className="row">
            <div className="col-xs-12" >
              <p><b>Requirement Errors:</b></p>
              {modalState.requiredErrors.map((error, i) => <p key={i} className={global.ErrorMessage}>{error}</p>)}
            </div>
          </div> : <Fragment />
        }
        <div className="row">
          <div className="col-xs-12 usas-extra-top-margin">
            {modalState.canSubmit === true ?
              <Fragment><PrimaryButton type="button" onClick={onModalSubmit}>Yes, Submit Level</PrimaryButton>&nbsp;</Fragment>
              : <Fragment />}
            <SecondaryButton type="button" onClick={onModalCanceled}>Cancel</SecondaryButton>
          </div>
        </div>
      </PopUpModal>
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={isLoading} />
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.SUBMITTING_MSG}
        displayPopUp={isSaving} />
    </Fragment>
  );
};

export default ClubRecognitionProgramLevel;