import React, { Fragment } from 'react';

import useAddEditFacilityPool from './UseAddEditFacilityPool';

import AddEditFacilityPoolConfiguration from './AddEditFacilityPoolConfiguration';

import FacilityPoolForm from './components/FacilityPoolForm';
import FacilityPoolConfigurationGrid from './components/FacilityPoolConfigurationGrid';

import Headings from '../../../headings/Headings';
import PrimaryButton from '../../../buttons/PrimaryButton';
import SecondaryButton from '../../../buttons/SecondaryButton';
import PopUpModal from '../../../dialogs/PopUpModal';
import ReadOnly from '../../../readOnly/ReadOnly';
import ActionIntraPageButton from '../../../buttons/ActionIntraPageButton';

import Constants from '../../../../utils/Constants';

import global from '../../../GlobalStyle.module.css';

const AddEditFacilityPool = ({ saveUrl, cancelUrl, showFacilityInfo }) => {
  const {
    facilityState,
    poolDimensionsState,
    poolConfigurationState,
    formState,
    errorState,
    handleSubmit,
    onFormValueChanged,
    onValueTextPairChanged,
    onSaveClicked,
    onCancelClicked,
    onAddPoolConfig,
    onEditPoolConfig,
    onDeletePoolConfig
  } = useAddEditFacilityPool(saveUrl, cancelUrl, showFacilityInfo);

  return (
    <Fragment>
      <div className={poolConfigurationState.showPage === true ? global.DisplayComponent : global.HideComponent}>
        <AddEditFacilityPoolConfiguration />
      </div>
      <div className={poolConfigurationState.showPage === false ? global.DisplayComponent : global.HideComponent}>
        <div className="row">
          <div className="col-xs-12">
            <Headings.H3>{formState.poolId > 0 ? 'Edit Pool' : 'Add Pool to Facility'}</Headings.H3>
          </div>
        </div>
        {showFacilityInfo === true ?
          <Fragment>
            <div className="row">
              <div className="col-xs-12 col-sm-6 col-md-4">
                <ReadOnly
                  label="Facility Name"
                  name="facilityName"
                  value={facilityState.objData?.facilityName || ''} />
              </div>
              <div className="col-xs-12 col-sm-6 col-md-4">
                <ReadOnly
                  label="Address Line 1"
                  name="address1"
                  value={facilityState.objData?.address1 || ''} />
              </div>
              <div className="col-xs-12 col-sm-6 col-md-4">
                <ReadOnly
                  label="Address Line 2"
                  name="address2"
                  value={facilityState.objData?.address2 || ''} />
              </div>
              <div className="col-xs-12 col-sm-6 col-md-4">
                <ReadOnly
                  label="City"
                  name="city"
                  value={facilityState.objData?.city || ''} />
              </div>
              <div className="col-xs-12 col-sm-6 col-md-4">
                <ReadOnly
                  label="State"
                  name="stateCode"
                  value={facilityState.objData?.stateCode || ''} />
              </div>
              <div className="col-xs-12 col-sm-6 col-md-4">
                <ReadOnly
                  label="Zip Code"
                  name="postalCode"
                  value={facilityState.objData?.postalCode || ''} />
              </div>
            </div>
          </Fragment> : <Fragment />}
        <form onSubmit={handleSubmit} noValidate>
          <FacilityPoolForm
            formState={formState}
            errorState={errorState}
            onFormValueChanged={onFormValueChanged}
            onValueTextPairChanged={onValueTextPairChanged} />
          <div className="row usas-extra-top-margin usas-extra-bottom-margin">
            <div className="col-xs-12 usas-extra-top-margin">
              <PrimaryButton type="button" onClick={onSaveClicked}>Save</PrimaryButton>&nbsp;
              <SecondaryButton type="button" onClick={onCancelClicked}>Back</SecondaryButton>
            </div>
          </div>
          <hr className={['usas-bar-gray', global.ThinBar].join(' ')} />
          <div className="row">
            <div className="col-xs-12">
              <Headings.H3>Pool Configurations</Headings.H3>
            </div>
          </div>
          <div className="row usas-extra-top-margin">
            <div className="col-xs-12">
              <ActionIntraPageButton onClick={onAddPoolConfig}>Add Pool Configuration</ActionIntraPageButton>
            </div>
          </div>
          <div className="row usas-extra-top-margin usas-extra-bottom-margin">
            <div className="col-xs-12">
              <FacilityPoolConfigurationGrid
                facilityState={facilityState}
                poolConfiguration={formState.poolConfiguration}
                onEdit={onEditPoolConfig}
                onDelete={onDeletePoolConfig}
              />
            </div>
          </div>
        </form>
        <PopUpModal
          widthPct={50}
          maxWidth={250}
          title={Constants.LOADING_MSG}
          displayPopUp={facilityState.isObjLoading || poolDimensionsState.isLoading} />
        <PopUpModal
          widthPct={50}
          maxWidth={250}
          title={Constants.SAVING_MSG}
          displayPopUp={facilityState.isSaving} />
      </div>
    </Fragment>
  );
};

export default AddEditFacilityPool;