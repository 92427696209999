import Constants from '../../../common/utils/Constants';
import { PreCompHttpHelper, BASIC_INITIAL_STATE, NO_DATA_MESSAGE } from '../../../common/utils/HttpHelper';

const INITIAL_STATE = {
  ...BASIC_INITIAL_STATE,
  dataAsOptionsWithNames: []
};

const getPreCompInstructorStudentRatios = (state, setState) => {
  if (state.isArrayLoading !== true && !state.message) {
    let newState = {
      ...state,
      isArrayLoading: true,
      isArrayLoaded: false,
      message: 'Loading...'
    };
    setState(newState);

    const url = `/InstructorStudentRatio`;
    PreCompHttpHelper(url, 'GET')
      .then((arrayData) => {
        if (!arrayData) {
          throw new Error(NO_DATA_MESSAGE);
        } else {
          arrayData.unshift({ instructorStudentRatioId: Constants.DEFAULT_ID, ratioDescription: '--' });
          newState = {
            ...newState,
            isArrayLoading: false,
            isArrayLoaded: true,
            arrayData,
            dataAsOptionsWithNames: arrayData.map(x => { return { id: x.instructorStudentRatioId, name: x.ratioDescription } }),
            message: ''
          };
          setState(newState);
        }
      })
      .catch((e) => {
        const message = e && e.message ? e.message : NO_DATA_MESSAGE;
        setState({
          ...newState,
          isArrayLoading: false,
          isArrayLoaded: false,
          message: message
        });
      });
  }
};

const PreCompInstructorStudentRatioData = {
  INITIAL_STATE,
  getPreCompInstructorStudentRatios
};

export default PreCompInstructorStudentRatioData;