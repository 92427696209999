import { API_NAMES, initApi } from "../../../common/utils/HttpApiHelper";

const postLTSProviderRegistrationData = (providerObj, state, setState) => {
  if (providerObj) {
    const api = initApi(API_NAMES.COMMON, state, setState);
    const url = `/LearnToSwimProvider/Registration`;

    return api?.executeObject ? api.executeObject(url, 'POST', providerObj) : null;
  }
};

const getLTSProviderDupCheckData = (taxId, state, setState) => {
  if (taxId) {
    const api = initApi(API_NAMES.COMMON, state, setState);
    const taxIdForUrl = encodeURIComponent(taxId);
    const url = `/LearnToSwimProvider/query/dupcheck?taxId=${taxIdForUrl}`;

    return api?.executeObject ? api.executeObject(url, 'GET') : null;
  }
};

const putLTSProviderRegistrationData = (providerObj, state, setState) => {
  if (providerObj) {
    // is fake endpoint
    const api = initApi(API_NAMES.COMMON, state, setState, providerObj);
    const url = `/LearnToSwimProvider/Registration`;

    return api?.executeObject ? api.executeObject(url, 'PUT', providerObj) : null;
  }
};

const LearnToSwimRegistrationData = {
  postLTSProviderRegistrationData,
  getLTSProviderDupCheckData,
  putLTSProviderRegistrationData
};

export default LearnToSwimRegistrationData;