import React, { createContext, useState } from 'react';

import OfferingTypeData from './OfferingTypeData';

export const MemberRegistrationHistoryStateContext = createContext();

const MemberRegistrationHistoryContextProvider = ({ children }) => {
  const stateHook = useState(OfferingTypeData.INITIAL_STATE);

  return (
    <MemberRegistrationHistoryStateContext.Provider value={stateHook}>
      {children}
    </MemberRegistrationHistoryStateContext.Provider>
  );
};

export default MemberRegistrationHistoryContextProvider;