import React, { Fragment } from 'react';

import EditIcon from '../../../../../common/components/icons/EditIcon';
import DeleteIcon from '../../../../../common/components/icons/DeleteIcon';
import AddRemoveMembersIcon from '../../../../../common/components/icons/AddRemoveMembersIcon';

import global from '../../../../../common/components/GlobalStyle.module.css';

const GridRow = ({ clubGroup, onAddRemoveMember, onDeleteClubGroup, onEditClubGroup }) => (
  <div className={global.SmallTableRow}>
    <div className={global.SmallTableRowHead}><span className='hidden-xs'>{clubGroup?.groupName}</span>&nbsp;
      <button
        className={global.IconButton}
        type="button"
        onClick={(e) => onAddRemoveMember(e, clubGroup?.orgGroupId)}>
        <AddRemoveMembersIcon />
      </button>
      <button
        className={(clubGroup?.numberOfMembers > 0)
          ? global.HideComponent
          : global.IconButtonMobileMargin}
        type="button"
        onClick={(e) => onDeleteClubGroup(e, clubGroup?.orgGroupId, clubGroup?.groupName)}>
        <DeleteIcon />
      </button>
      <button
        className={global.IconButtonMobileMargin}
        type="button"
        onClick={(e) => onEditClubGroup(e, clubGroup?.orgGroupId, clubGroup.groupTypeName)}>
        <EditIcon />
      </button>
    </div>
    <div className={global.SmallTableRowInfo}>
      <div className='row'>
        <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3 visible-xs'].join(' ')}>Group Name</div>
        <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3 visible-xs'].join(' ')}>{clubGroup?.groupName || <span>&nbsp;</span>}</div>
        <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3'].join(' ')}>Group Type</div>
        <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3'].join(' ')}>{clubGroup?.groupTypeName || <span>&nbsp;</span>}</div>
        <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3'].join(' ')}>Group Code</div>
        <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3'].join(' ')}>{clubGroup?.groupCode || <span>&nbsp;</span>}</div>
        <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3'].join(' ')}>Date Range</div>
        <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3'].join(' ')}>{clubGroup?.dateRange || <span>&nbsp;</span>}</div>
        <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3'].join(' ')}>Members</div>
        <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3'].join(' ')}>{clubGroup?.numberOfMembers ?? <span>&nbsp;</span>}</div>
      </div>
    </div>
  </div>
);

const ClubGroupsGridSmall = ({ state, onEditClubGroup, onDeleteClubGroup, onAddRemoveMember }) => (
  <Fragment>
    <div className={[global.SmallTable, 'hidden-md hidden-lg'].join(' ')}>
      {state.isArrayLoaded !== true
        ? (
          <div className={global.SmallTableRow}>
            <div className={global.SmallTableRowHead}>
              &nbsp;
            </div>
            <div className={global.SmallTableRowInfo}>
              {state.isArrayLoading === true
                ? <div className={global.SmallTableRowLabels}>Loading...</div>
                : <div className={global.SmallTableRowLabels}>No Groups</div>
              }
            </div>
          </div>
        ) : Array.isArray(state.arrayData) && state.arrayData.length > 0
          ? state.arrayData.map((clubGroup, i) => <GridRow key={i} clubGroup={clubGroup} onAddRemoveMember={onAddRemoveMember} onDeleteClubGroup={onDeleteClubGroup} onEditClubGroup={onEditClubGroup} />)
          : (
            <div className={global.SmallTableRow}>
              <div className={global.SmallTableRowHead}>&nbsp;</div>
              <div className={global.SmallTableRowInfo}><div className={global.SmallTableRowLabels}>No Groups</div></div>
            </div>
          )
      }
    </div>
  </Fragment>
);

export default ClubGroupsGridSmall;