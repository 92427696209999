import { useContext, useEffect } from 'react';

import { PrizeStateContext } from './PrizeContextProvider';

import PrizeData from './PrizeData';

const usePrizeData = () => {
  const [prizeState, setPrizeState] = useContext(PrizeStateContext);

  useEffect(() => {
    if (prizeState.isLoading !== true && prizeState.isLoaded !== true) {
      PrizeData.getPrizes(prizeState, setPrizeState);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    prizeState,
    setPrizeState
  };
};

export default usePrizeData;