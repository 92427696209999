import { useEffect } from 'react';

import validate from './ClubThirdPartyInvoicesDetailFormValidation';

import useForm from "../../../../common/utils/UseForm";
import Constants from '../../../../common/utils/Constants';
import ToIntIfInt from '../../../../common/utils/ToIntIfInt';

const displayOptions = [{ id: 1, name: 'Show All Registrations' }, { id: 2, name: 'Show Registrations with Errors' }, { id: 3, name: 'Hide Registrations with Errors' }];

const INITIAL_FORM_STATE = {
  submittedOfferingInstanceId: Constants.DEFAULT_ID,
  submittedOfferingName: '--',
  firstOrPreferredName: '',
  lastName: '',
  displayOptionId: displayOptions[0]?.id,
  displayOptionName: displayOptions[0]?.name
};

const useClubThirdPartyInvoicesDetailForm = (clubThirdPartyInvoiceState, setClubThirdPartyInvoiceState, lscOfferingByOrgUnitIdAndReportPeriodIdDropdownState, gridState, setGridState) => {
  const { formState, errorState, setFormState, updateFormState, onValueTextPairChanged, handleSubmit, setErrors
  } = useForm(INITIAL_FORM_STATE, onSubmitFormCallback, validate);

  const onClearFilterClicked = () => {
    setFormState(INITIAL_FORM_STATE);
    const filteredGridData = filter(INITIAL_FORM_STATE);
    setGridState({ ...gridState, gridData: filteredGridData });
    setErrors({});
  };

  useEffect(() => {
    if (clubThirdPartyInvoiceState.isArrayLoaded === true && clubThirdPartyInvoiceState.isArrayLoading === false &&
      lscOfferingByOrgUnitIdAndReportPeriodIdDropdownState.isArrayLoading === false) {

      const arrayDataCopy = JSON.parse(JSON.stringify(clubThirdPartyInvoiceState.arrayData));
      for (let i = 0; i < arrayDataCopy.length; i++) {
        const matchingOffering = lscOfferingByOrgUnitIdAndReportPeriodIdDropdownState.options.find(x => x.name === arrayDataCopy[i].offeringName);
        if (matchingOffering) {
          arrayDataCopy[i].selectedOfferingInstanceId = matchingOffering.id;
          arrayDataCopy[i].selectedOfferingName = matchingOffering.name;
        }
        else {
          arrayDataCopy[i].selectedOfferingInstanceId = Constants.DEFAULT_ID;
          arrayDataCopy[i].selectedOfferingName = '--';
        }
      }
      setClubThirdPartyInvoiceState({ ...clubThirdPartyInvoiceState, arrayData: arrayDataCopy });

    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clubThirdPartyInvoiceState.isArrayLoaded, clubThirdPartyInvoiceState.isArrayLoading, lscOfferingByOrgUnitIdAndReportPeriodIdDropdownState.isArrayLoading]);

  useEffect(() => {
    if (clubThirdPartyInvoiceState.isArrayLoaded === true && clubThirdPartyInvoiceState.isArrayLoading === false &&
      lscOfferingByOrgUnitIdAndReportPeriodIdDropdownState.isArrayLoading === false) {

      const filteredGridData = filter(formState);
      for (let i = 0; i < filteredGridData.length; i++) {
        if (!filteredGridData[i].selectedOfferingName) {
          const matchingOffering = lscOfferingByOrgUnitIdAndReportPeriodIdDropdownState.options.find(x => x.name === filteredGridData[i].offeringName);
          if (matchingOffering) {
            filteredGridData[i].selectedOfferingInstanceId = matchingOffering.id;
            filteredGridData[i].selectedOfferingName = matchingOffering.name;
          }
          else {
            filteredGridData[i].selectedOfferingInstanceId = Constants.DEFAULT_ID;
            filteredGridData[i].selectedOfferingName = '--';
          }
        }
      }
      setGridState({ ...gridState, gridData: filteredGridData });

    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clubThirdPartyInvoiceState.isArrayLoaded, clubThirdPartyInvoiceState.isArrayLoading, clubThirdPartyInvoiceState.arrayData, lscOfferingByOrgUnitIdAndReportPeriodIdDropdownState.isArrayLoading]);

  function filter(formState) {
    let arrayDataCopy = [];
    arrayDataCopy = JSON.parse(JSON.stringify(clubThirdPartyInvoiceState.arrayData));

    let results = [];
    let submittedOfferingNameFilter = formState.submittedOfferingName?.trim() !== '--' ? true : false;
    let firstOrPreferredNameFilter = formState.firstOrPreferredName?.trim() !== '' ? true : false;
    let lastNameFilter = formState.lastName?.trim() !== '' ? true : false;
    let displayOptionFilter = formState.displayOptionId !== 1 ? true : false;

    //No filters used
    if (submittedOfferingNameFilter === false && firstOrPreferredNameFilter === false && lastNameFilter === false && displayOptionFilter === false) {
      results = arrayDataCopy;
    }
    else {
      //Filter data
      for (let i = 0; i < arrayDataCopy.length; i++) {
        let submittedOfferingNameMatch = false;
        let firstOrPreferredNameMatch = false;
        let lastNameMatch = false;
        let displayOptionMatch = false;
        if (submittedOfferingNameFilter === true) {
          if (ToIntIfInt(arrayDataCopy[i].submittedOfferingName) === ToIntIfInt(formState.submittedOfferingName)) {
            submittedOfferingNameMatch = true;
          }
        }
        if (firstOrPreferredNameFilter === true) {
          if (match('*' + formState.firstOrPreferredName?.trim() + '*', arrayDataCopy[i].firstName) === true ||
            match('*' + formState.firstOrPreferredName?.trim() + '*', arrayDataCopy[i].preferredName) === true) {
            firstOrPreferredNameMatch = true;
          }
        }
        if (lastNameFilter === true) {
          if (match('*' + formState.lastName?.trim() + '*', arrayDataCopy[i].lastName) === true) {
            lastNameMatch = true;
          }
        }
        if (displayOptionFilter === true) {
          let hasOfferingError = false;
          let isClubOfferingError = false;
          let missingSelectionError = false;
          let selectedOfferingError = false;
          let ageSelectionError = false;
          let duplicateOfferingForSamePersonError = false;
          let duplicateAthleteOfferingForSamePersonError = false;

          if (arrayDataCopy[i]?.isClubOffering === false && !arrayDataCopy[i]?.offeringInstanceId && arrayDataCopy[i]?.selectedOfferingInstanceId === Constants.DEFAULT_ID) {
            isClubOfferingError = true
          }

          if (arrayDataCopy[i]?.selectedOfferingName === '--') {
            missingSelectionError = true;
          }

          let selectedOffering = lscOfferingByOrgUnitIdAndReportPeriodIdDropdownState.arrayData?.find((x) => x.offeringName === arrayDataCopy[i].selectedOfferingName);
          //check to see if person's age falls outside the allowed age range of the selected offering
          if (selectedOffering) {
            if (arrayDataCopy[i].age < selectedOffering?.ageStart ||
              arrayDataCopy[i].age > selectedOffering?.ageEnd) {
              ageSelectionError = true;
            }
            if (selectedOffering?.isClubOffering === false) {
              selectedOfferingError = true;
            }
            if (arrayDataCopy[i]?.hasOffering === true && selectedOffering?.isAthleteOffering === true) {
              hasOfferingError = true;
            }
          }

          // don't consider the invoice offering, but check all other rows to make sure that 
          // they aren't person offerings dupes of the same offering types
          let selectedOfferingSamePersonDupe = clubThirdPartyInvoiceState.arrayData?.find((x) =>
            x.selectedOfferingName === arrayDataCopy[i]?.selectedOfferingName && x.thirdPartyClubRegistrationPersonOfferingId !== arrayDataCopy[i].thirdPartyClubRegistrationPersonOfferingId &&
            x.thirdPartyClubRegistrationPersonId === arrayDataCopy[i].thirdPartyClubRegistrationPersonId);

          if (selectedOfferingSamePersonDupe) {
            duplicateOfferingForSamePersonError = true;
          }

          // don't consider the invoice offering, but check all other rows to make sure that 
          // they aren't person offerings dupes of different athlete offering types
          for (let option of clubThirdPartyInvoiceState.arrayData) {
            if (option.thirdPartyClubRegistrationPersonOfferingId !== arrayDataCopy[i]?.thirdPartyClubRegistrationPersonOfferingId) {
              let optionOffering = lscOfferingByOrgUnitIdAndReportPeriodIdDropdownState.arrayData?.find((x) =>
                x.offeringName === option?.selectedOfferingName);
              if ((optionOffering?.isAthleteOffering === true && selectedOffering?.isAthleteOffering === true) &&
                (option?.thirdPartyClubRegistrationPersonId === arrayDataCopy[i]?.thirdPartyClubRegistrationPersonId)) {
                duplicateAthleteOfferingForSamePersonError = true;
              }
            }
          }

          //show errors
          if (formState.displayOptionId === 2) {
            if (hasOfferingError === true || isClubOfferingError === true || missingSelectionError === true ||
              selectedOfferingError === true || ageSelectionError === true || duplicateOfferingForSamePersonError === true ||
              duplicateAthleteOfferingForSamePersonError === true) {
              displayOptionMatch = true;
            }
          }

          //hide errors
          else if (formState.displayOptionId === 3) {
            if (hasOfferingError === false && isClubOfferingError === false && missingSelectionError === false &&
              selectedOfferingError === false && ageSelectionError === false && duplicateOfferingForSamePersonError === false &&
              duplicateAthleteOfferingForSamePersonError === false) {
              displayOptionMatch = true;
            }
          }
        }

        //Does the invoice meet all the filter requirements?
        if ((submittedOfferingNameFilter === false || (submittedOfferingNameFilter === true && submittedOfferingNameMatch === true)) &&
          (firstOrPreferredNameFilter === false || (firstOrPreferredNameFilter === true && firstOrPreferredNameMatch === true)) &&
          (lastNameFilter === false || (lastNameFilter === true && lastNameMatch === true)) &&
          (displayOptionFilter === false || (displayOptionFilter === true && displayOptionMatch === true))) {
          results.push(arrayDataCopy[i]);
        }
      }
    }

    return results;
  }

  function onSubmitFormCallback(formState) {
    const filteredGridData = filter(formState);
    setGridState({
      ...gridState,
      gridData: filteredGridData
    });
  }

  function match(providedFilter, personName) {
    let providedFilterLowerCase = providedFilter.toLowerCase();
    let personNameLowerCase = personName.toLowerCase();
    if (providedFilterLowerCase.length === 0 && personNameLowerCase.length === 0)
      return true;

    if (providedFilterLowerCase.length > 1 && providedFilterLowerCase[0] === '*' &&
      personNameLowerCase.length === 0)
      return false;

    if ((providedFilterLowerCase.length > 1 && providedFilterLowerCase[0] === '?') ||
      (providedFilterLowerCase.length !== 0 && personNameLowerCase.length !== 0 &&
        providedFilterLowerCase[0] === personNameLowerCase[0]))
      return match(providedFilterLowerCase.substring(1),
        personName.substring(1));

    if (providedFilterLowerCase.length > 0 && providedFilterLowerCase[0] === '*')
      return match(providedFilterLowerCase.substring(1), personNameLowerCase) ||
        match(providedFilterLowerCase, personNameLowerCase.substring(1));

    return false;
  }

  return {
    formState,
    errorState,
    onFormValueChanged: updateFormState,
    onValueTextPairChanged,
    handleSubmit,
    onClearFilterClicked,
    gridState,
    setGridState,
    displayOptions
  };
};

export default useClubThirdPartyInvoicesDetailForm;