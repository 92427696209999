import React, { createContext, useState } from 'react';

export const BaseUsasClubFiltersStateContext = createContext();

const INITIAL_STATE = {
  filterObject: {},
  sortBy: '',
  isAdvancedSearch: false,
  backToSystem: 'Club'
};

const BaseUsasClubFiltersContextProvider = ({ children }) => {
  const stateHook = useState(INITIAL_STATE);

  return (
    <BaseUsasClubFiltersStateContext.Provider value={stateHook}>
      {children}
    </BaseUsasClubFiltersStateContext.Provider>
  );
};

export default BaseUsasClubFiltersContextProvider;