import React, { Fragment } from 'react';

import { isCertExpired } from '../utils/ClubUtils';

import ShowIcon from '../../../../common/components/icons/ShowIcon';
import HideIcon from '../../../../common/components/icons/HideIcon';

import { formatPhoneNumber } from '../../../../common/utils/validation';
import { formatDate } from '../../../../common/utils/DateFunctions';
import Constants from '../../../../common/utils/Constants';

import global from '../../../../common/components/GlobalStyle.module.css';

const DetailTableRow = ({ cert }) => (
  <div className={global.SmallTableRow}>
    <div className={global.SmallTableRowInfo}>
      {isCertExpired(cert.courseExpirationDate) || (cert.courseCompletionDate === null && cert.courseRequired === true) ?
        <div className='row'>
          <div className={[global.SmallTableRowData, global.ExpiredCert, 'col-xs-6 col-sm-4'].join(' ')}>{cert.courseName} {cert.courseCode ? `(${cert.courseCode})` : ''}</div>
          <div className={[global.SmallTableRowData, global.ExpiredCert, 'col-xs-3 col-sm-4'].join(' ')}><strong>Date Completed </strong>
            {cert.courseCompletionDate !== null && cert.coursePassed === true
              ? formatDate(cert.courseCompletionDate) === Constants.DEFAULT_MAX_DATE
                ? ' '
                : formatDate(cert.courseCompletionDate)
              : cert.courseCompletionDate === null && cert.courseRequired === false ? 'Not Required Yet' : ' '}</div>
          <div className={[global.SmallTableRowData, global.ExpiredCert, 'col-xs-3 col-sm-4'].join(' ')}> <strong>Expiration Date </strong>
            {cert.courseExpirationDate !== null && cert.coursePassed === true
              ? formatDate(cert.courseExpirationDate) === Constants.DEFAULT_MAX_DATE
                ? `Doesn't Expire`
                : formatDate(cert.courseExpirationDate)
              : ' '
            }
          </div>
        </div>
        :
        <div className='row'>
          <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-4'].join(' ')}>{cert.courseName} {cert.courseCode ? `(${cert.courseCode})` : ''}</div>
          <div className={[global.SmallTableRowData, 'col-xs-3 col-sm-4'].join(' ')}><strong>Date Completed </strong>
            {cert.courseCompletionDate !== null && cert.coursePassed === true
              ? formatDate(cert.courseCompletionDate) === Constants.DEFAULT_MAX_DATE
                ? ' '
                : formatDate(cert.courseCompletionDate)
              : cert.courseCompletionDate === null && cert.courseRequired === false ? 'Not Required Yet' : ' '}</div>
          <div className={[global.SmallTableRowData, 'col-xs-3 col-sm-4'].join(' ')}> <strong>Expiration Date </strong>
            {cert.courseExpirationDate !== null && cert.coursePassed === true
              ? formatDate(cert.courseExpirationDate) === Constants.DEFAULT_MAX_DATE
                ? `Doesn't Expire`
                : formatDate(cert.courseExpirationDate)
              : ' '
            }
          </div>
        </div>
      }
    </div>
  </div>
);

const GridRowDetailTable = ({ staff }) => (
  <div className={global.SmallTableDetailTable}>
    <div className={[global.SmallTableDetailRowHead, 'col-xs-12'].join(' ')}>
      Requirement
    </div>
    {Array.isArray(staff.personRequirement) && staff.personRequirement.map((cert, i) =>
      <DetailTableRow key={i} cert={cert} />)}
  </div>
);

const GridRow = ({ staff, expandedId, onClick }) => (
  <Fragment>
    <div className={global.SmallTableRow}>
      <div className={global.SmallTableRowHead}>
        <span className='hidden-xs'>{staff.preferredName || staff.firstName} {staff.lastName}</span>&nbsp;
        <button className={global.IconButtonMobileMargin}
          type="button"
          onClick={(e) => onClick(e, staff.personId)}>
          {expandedId === staff.personId ? <HideIcon /> : <ShowIcon />}
        </button>
      </div>
      <div className={global.SmallTableRowInfo}>
        <div className='row'>
          <div className={[global.SmallTableRowLabels, 'col-xs-6 visible-xs'].join(' ')}>Club Staff Name</div>
          <div className={[global.SmallTableRowData, 'col-xs-6 visible-xs'].join(' ')}>{staff.preferredName || staff.firstName} {staff.lastName}</div>
          <div className={[global.SmallTableRowLabels, 'col-xs-6 visible-xs'].join(' ')}>Phone Number</div>
          <div className={[global.SmallTableRowData, 'col-xs-6 visible-xs'].join(' ')}>{formatPhoneNumber(staff.phoneNumber) || <span>&nbsp;</span>}</div>
          <div className={[global.SmallTableRowLabels, 'col-xs-6 visible-xs'].join(' ')}>Email</div>
          <div className={[global.SmallTableRowData, 'col-xs-6 visible-xs'].join(' ')}>{staff.email || <span>&nbsp;</span>}</div>
          <div className={[global.SmallTableRowLabels, 'col-xs-6 visible-xs'].join(' ')}>Is In Good Standing?</div>
          <div className={[global.SmallTableRowData, 'col-xs-6 visible-xs'].join(' ')}>{staff.isInGoodStanding === true ? 'Yes' : 'No'}</div>
        </div>
      </div>
    </div>
    {Array.isArray(staff.personRequirement) && staff.personRequirement.length > 0 && expandedId === staff.personId &&
      <GridRowDetailTable staff={staff} />}
  </Fragment>
);

const ClubStaffRequirementsSmallGrid = ({ state, expandedId, onClick }) => (
  <div className={[global.SmallTable, 'hidden-md hidden-lg'].join(' ')}>
    {state.isArrayLoading === true
      ? (
        <div className={global.SmallTableRow}>
          <div className={global.SmallTableRowHead}>&nbsp;</div>
          <div className={global.SmallTableRowInfo}>
            <div className={global.SmallTableRowLabels}>
              &nbsp;&nbsp;Loading...
            </div>
          </div>
        </div>
      )
      : Array.isArray(state.arrayData) && state.arrayData.length > 0
        ? state.arrayData.map((staff, i) => <GridRow key={i} staff={staff} expandedId={expandedId} onClick={onClick} />)
        : (
          <div className={global.SmallTableRow}>
            <div className={global.SmallTableRowHead}>&nbsp;</div>
            <div className={global.SmallTableRowInfo}>
              <div className={global.SmallTableRowLabels}>
                No Staff Requirements
              </div>
            </div>
          </div>)
    }
  </div>
);

export default ClubStaffRequirementsSmallGrid;