import { isValidDate } from '../../../../../common/utils/validation';
import Constants from '../../../../../common/utils/Constants';

export const localValidate = (formState) => {
  let errors = {};
  const effectiveDate = new Date(formState.effectiveDate);
  const expirationDate = new Date(formState.expirationDate);

  if (formState.hasNoExpirationDate === false) {
    if (formState.expirationDate === Constants.BLANK_DATE_STRING
      || !isValidDate(formState.expirationDate)) {
      errors.expirationDate = 'End Date must be a valid date';
    }

    if (expirationDate < effectiveDate) {
      errors.expirationDate = 'End Date must be after the Beginning Date';
    }

    if (formState.effectiveDate !== Constants.BLANK_DATE_STRING
      && formState.expirationDate === Constants.BLANK_DATE_STRING) {
      errors.expirationDate = 'End Date is required if there is a Beginning Date';
    }
  }

  return errors;
};

const EditLSCBoardMemberValidation = async (formState) => {
  let errors = localValidate(formState);

  return errors ? errors : {};
}

export default EditLSCBoardMemberValidation;