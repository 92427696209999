import React, { Fragment } from 'react';

import PlusIcon from '../../../../common/components/icons/PlusIcon';

import global from '../../../../common/components/GlobalStyle.module.css';

const TableRow = ({ preCompLocation, onAdd, usasClubPreCompLinksState }) => {
  const matchIndex = usasClubPreCompLinksState.arrayData.findIndex(e => e.preCompetitiveLocationId === preCompLocation.locationId);
  return (
    <div className={global.SmallTableRow}>
      <div className={global.SmallTableRowHead}><span className='hidden-xs'>{preCompLocation.locationName}</span>&nbsp;
        {matchIndex === -1 ?
          <button
            type="button"
            className={global.IconButton}
            onClick={(e) => onAdd(e, preCompLocation.locationId)}>
            <PlusIcon />
          </button>
          : <Fragment />}
      </div>
      <div className={global.SmallTableRowInfo}>
        <div className='row'>
          <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3 visible-xs'].join(' ')}>Location Name</div>
          <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3 visible-xs'].join(' ')}>{preCompLocation.locationName || <span>&nbsp;</span>}</div>
          <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3'].join(' ')}>Organization Name</div>
          <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3'].join(' ')}>{preCompLocation.organizationName || <span>&nbsp;</span>}</div>
          <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3'].join(' ')}>Address</div>
          <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3'].join(' ')}>{preCompLocation.location || <span>&nbsp;</span>}</div>
        </div>
      </div>
    </div>
  );
}

const ClubPreCompSearchGridSmall = ({ data, isLoading, onAdd, usasClubPreCompLinksState }) => (
  <div className={[global.SmallTable, 'hidden-md hidden-lg'].join(' ')}>
    {isLoading === true
      ? (
        <div className={global.SmallTableRow}>
          <div className={global.SmallTableRowHead}>&nbsp;</div>
          <div className={global.SmallTableRowInfo}>
            <div className={global.SmallTableRowLabels}>Loading...</div>
          </div>
        </div>
      )
      : data.length > 0
        ? data.map((preCompLocation, i) => <TableRow key={i} preCompLocation={preCompLocation} onAdd={onAdd} usasClubPreCompLinksState={usasClubPreCompLinksState} />)
        : (
          <div className={global.SmallTableRow}>
            <div className={global.SmallTableRowHead}>&nbsp;</div>
            <div className={global.SmallTableRowInfo}>
              <div className={global.SmallTableRowLabels}>No Results</div>
            </div>
          </div>
        )}
  </div>
);

export default ClubPreCompSearchGridSmall;