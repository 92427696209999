import { useContext } from 'react';

import { CategoryMeasureMultiselectStateContext } from './CategoryMeasureMultiselectContextProvider';

import CategoryMeasureMultiselectData from './CategoryMeasureMultiselectData';

const useCategoryMeasureMultiselectData = () => {
  const [categoryMeasureMultiselectState, setCategoryMeasureMultiselectState] = useContext(CategoryMeasureMultiselectStateContext);

  const getCategoryMeasureMultiselect = (categoryMeasureId) => {
    CategoryMeasureMultiselectData.getCategoryMeasureMultiselectByCategoryMeasureId(categoryMeasureId, categoryMeasureMultiselectState, setCategoryMeasureMultiselectState);
  };

  return {
    categoryMeasureMultiselectState,
    getCategoryMeasureMultiselect
  };
};

export default useCategoryMeasureMultiselectData;