import React, { Fragment } from "react";
import LSCStaffMembersGridLarge from "./LSCStaffMembersGridLarge";
import LSCStaffMembersGridSmall from "./LSCStaffMembersGridSmall";

import useExpandedRow from "../../../../common/components/grids/UseExpandedRow";

const LSCStaffMembersGrid = ({ data, isLoading, onEdit, onDelete }) => {
  const { expandedId, onClick } = useExpandedRow();
  return (
    <Fragment>
      <LSCStaffMembersGridLarge data={data} isLoading={isLoading} onEdit={onEdit}
        onDelete={onDelete} expandedId={expandedId} onClick={onClick} />
      <LSCStaffMembersGridSmall data={data} isLoading={isLoading} onEdit={onEdit}
        onDelete={onDelete} expandedId={expandedId} onClick={onClick} />
    </Fragment>
  );
};

export default LSCStaffMembersGrid;