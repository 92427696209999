import { useEffect, useState } from 'react';
import { useNavigate, useLocation } from '../../../../common/wrappers/ReactRouterDom';

import validate from './ClubRegistrationStaffDetailValidation';

import NavLinks from '../NavLinks';

import ClubConstants from '../../club/utils/ClubConstants';

import useClubRegistrationNavData from '../../../state/clubRegistration/leftNavigation/UseClubRegistrationNavData';
import useClubRegistrationNewData from '../../../state/clubRegistrationNew/UseClubRegistrationNewData';
import useUsasClubStaffData from '../../../state/clubStaff/UseUsasClubStaffData';

import useOrgRoleStaffRoleHierarchyData from '../../../../common/state/orgRoleStaffRoleHierarchy/UseOrgRoleStaffRoleHierarchyData';
import useEnvironmentVariableData from '../../../../common/state/environmentVariable/UseEnvironmentVariableData';

import useForm from '../../../../common/utils/UseForm';

const registrationRoleNames = [
  ClubConstants.COACH_ROLE_NAME,
  ClubConstants.JUNIOR_COACH_ROLE_NAME,
  ClubConstants.PROVISIONAL_COACH_ROLE_NAME
];

const useClubRegistrationStaffDetail = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [state, setState] = useState({ tryRedirect: false });
  const { clubRegistrationNavState } = useClubRegistrationNavData();
  const { clubRegistrationState } = useClubRegistrationNewData();
  const { orgRoleStaffRoleHierarchyState } = useOrgRoleStaffRoleHierarchyData();
  const { usasClubStaffState, postUsasClubStaff, putUsasClubStaff } = useUsasClubStaffData();
  const {
    environmentVariableState,
    HEAD_COACH_ORG_ROLE_ID,
    ASSISTANT_COACH_ORG_ROLE_ID,
    AGE_GROUP_COACH_ORG_ROLE_ID,
    SENIOR_COACH_ORG_ROLE_ID,
    ORG_ROLE_FIELD_ID_HEAD_COACH_FULL_PART_TIME,
    ORG_ROLE_FIELD_ID_ASSISTANT_COACH_FULL_PART_TIME,
    ORG_ROLE_FIELD_ID_AGE_GROUP_COACH_FULL_PART_TIME,
    ORG_ROLE_FIELD_ID_SENIOR_COACH_FULL_PART_TIME,
    CLUB_STAFF_ROLE_GROUP_ID
  } = useEnvironmentVariableData();
  const { formState, errorState, updateFormState, handleSubmit, isSubmitting,
    isDirty, setIsDirty, setErrors, setFormData, onValueTextPairChanged } = useForm(getFormInitialState(), submitFormCallback, validate);
  const [viewState, setViewState] = useState(getInitialViewState);

  const coachTypeCodeOptions = [
    { id: 'F', name: "Full Time" },
    { id: 'P', name: "Part Time" }];

  const orgRoleToFieldIdMap = {
    [HEAD_COACH_ORG_ROLE_ID]: { id: ORG_ROLE_FIELD_ID_HEAD_COACH_FULL_PART_TIME },
    [ASSISTANT_COACH_ORG_ROLE_ID]: { id: ORG_ROLE_FIELD_ID_ASSISTANT_COACH_FULL_PART_TIME },
    [AGE_GROUP_COACH_ORG_ROLE_ID]: { id: ORG_ROLE_FIELD_ID_AGE_GROUP_COACH_FULL_PART_TIME },
    [SENIOR_COACH_ORG_ROLE_ID]: { id: ORG_ROLE_FIELD_ID_SENIOR_COACH_FULL_PART_TIME }
  }

  const coachRoleIds = [HEAD_COACH_ORG_ROLE_ID, ASSISTANT_COACH_ORG_ROLE_ID, AGE_GROUP_COACH_ORG_ROLE_ID, SENIOR_COACH_ORG_ROLE_ID];

  const onAddClicked = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    let errorMessage = "";
    const conflictingRoles = hasConflictingCoachRoles(formState);

    if (conflictingRoles === true) {
      errorMessage = "Staff member has conflicting coach roles";
    }

    const uniqueRoleError = hasValidUniqueOrgRoles(formState);

    if (uniqueRoleError) {
      setErrors({
        ...errorState,
        uniqueRoleError
      });
    } else if (conflictingRoles) {
      setErrors({
        ...errorState,
        conflictingRolesError: errorMessage
      });
    } else {
      if (isDirty === false) {
        setIsDirty(true);
      }
      handleSubmit();
    }
  }

  const onCancelClicked = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    const showGrid = true;
    navigate(NavLinks.CLUB_REGISTRATION_STAFF, { state: { showGrid } });
  }

  useEffect(() => {
    if (clubRegistrationState.isObjLoaded === false) {
      navigate(NavLinks.CLUB_REGISTRATION_ENTRY_NEW);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (location.state?.personId) { //edit
      const targetStaff = usasClubStaffState.arrayData.find(x => x.personId === location.state.personId);

      if (targetStaff && Array.isArray(targetStaff.personOrgRole)) {
        const coachRole = targetStaff.personOrgRole.find(x =>
        (
          x.orgRoleId === HEAD_COACH_ORG_ROLE_ID ||
          x.orgRoleId === ASSISTANT_COACH_ORG_ROLE_ID ||
          x.orgRoleId === AGE_GROUP_COACH_ORG_ROLE_ID ||
          x.orgRoleId === SENIOR_COACH_ORG_ROLE_ID));

        const coachField = (coachRole && coachRole.personOrgRoleFields && Array.isArray(coachRole.personOrgRoleFields)) ? coachRole.personOrgRoleFields.find(x => (
          x.orgRoleFieldId === ORG_ROLE_FIELD_ID_HEAD_COACH_FULL_PART_TIME ||
          x.orgRoleFieldId === ORG_ROLE_FIELD_ID_ASSISTANT_COACH_FULL_PART_TIME ||
          x.orgRoleFieldId === ORG_ROLE_FIELD_ID_AGE_GROUP_COACH_FULL_PART_TIME ||
          x.orgRoleFieldId === ORG_ROLE_FIELD_ID_SENIOR_COACH_FULL_PART_TIME)) : [];

        const orgRoles = getValidTreeOrgRoles(targetStaff.personOrgRole);

        setFormData({
          ...formState,
          personId: targetStaff.personId,
          personOrgRole: orgRoles.validTreeOrgRoles,
          memberId: targetStaff.memberId,
          coachTypeCode: coachField !== undefined ? coachField.fieldValue : '',
          headCoachOrgRoleId: HEAD_COACH_ORG_ROLE_ID,
          assistantCoachOrgRoleId: ASSISTANT_COACH_ORG_ROLE_ID,
          ageGroupCoachOrgRoleId: AGE_GROUP_COACH_ORG_ROLE_ID,
          seniorCoachOrgRoleId: SENIOR_COACH_ORG_ROLE_ID,
          registrationRoles: orgRoles.registrationRoles
        });

        setViewState({
          ...viewState,
          firstName: targetStaff.firstName,
          lastName: targetStaff.lastName,
          memberId: targetStaff.memberId,
          registrationRoleString: getRegistrationRolesString(orgRoles.registrationRoles)
        });
      }
    } else if (environmentVariableState.isLoaded === true) {
      setFormData({
        ...formState,
        headCoachOrgRoleId: HEAD_COACH_ORG_ROLE_ID,
        assistantCoachOrgRoleId: ASSISTANT_COACH_ORG_ROLE_ID,
        ageGroupCoachOrgRoleId: AGE_GROUP_COACH_ORG_ROLE_ID,
        seniorCoachOrgRoleId: SENIOR_COACH_ORG_ROLE_ID
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    //Wait for add to complete before redirecting
    if (usasClubStaffState.isSaved === true && state.tryRedirect === true) {
      const showGrid = true;
      const addedStaff = true;
      navigate(NavLinks.CLUB_REGISTRATION_STAFF, { state: { showGrid, addedStaff } });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [usasClubStaffState, state.tryRedirect]);

  function submitFormCallback(formState) {
    if (formState.personId) {
      putUsasClubStaff(clubRegistrationState.objData.orgUnitId, formState.personId, CLUB_STAFF_ROLE_GROUP_ID, createPutStaffObj(formState));
      setState({ ...state, tryRedirect: true });
    } else if (formState.postPersonId) {
      const dupStaff = usasClubStaffState.arrayData.find(x => x.personId === formState.postPersonId);
      if (dupStaff) {
        setErrors({
          ...errorState,
          apiError: `${dupStaff.firstName} ${dupStaff.lastName} (${dupStaff.memberId}) is already associated with this club's staff`
        });
      } else {
        postUsasClubStaff(clubRegistrationState.objData.orgUnitId, CLUB_STAFF_ROLE_GROUP_ID, createPostStaffObj(formState));
        setState({ ...state, tryRedirect: true });
      }
    }
  };

  function getValidTreeOrgRoles(personOrgRole) {
    const validTreeOrgRoles = [];
    const registrationRoles = [];

    for (const role of personOrgRole) {
      if (registrationRoleNames.find(x => x === role.orgRoleName)) {
        registrationRoles.push(role);
      } else {
        validTreeOrgRoles.push({ id: role.orgRoleId, name: role.orgRoleName });
      }
    };

    return {
      validTreeOrgRoles,
      registrationRoles
    };
  };

  function getRegistrationRolesString(registrationRoles) {
    let registrationRoleString = '';

    for (const registrationRole of registrationRoles) {
      if (registrationRoleString === '') {
        registrationRoleString = `${registrationRole.orgRoleName}`;
      } else {
        registrationRoleString += `, ${registrationRole.orgRoleName}`;
      }
    }

    return registrationRoleString;
  };

  function formatPersonOrgRoleObj(personOrgRole) {
    let personOrgRoleFieldsArray = [];
    let personOrgRoleFieldsObj = {};

    if (coachRoleIds.includes(personOrgRole.id)) {
      personOrgRoleFieldsObj = {
        fieldName: 'Full_Part_Time',
        orgRoleFieldId: orgRoleToFieldIdMap[personOrgRole.id].id,
        fieldValue: formState.coachTypeCode
      }
    }

    if (Object.keys(personOrgRoleFieldsObj).length > 0) {
      personOrgRoleFieldsArray.push(personOrgRoleFieldsObj);
    }

    let personOrgRoleObj = {
      orgRoleId: personOrgRole.id,
      orgRoleName: personOrgRole.name,
      personOrgRoleFields: personOrgRoleFieldsArray
    }

    return personOrgRoleObj;
  }


  function createPostStaffObj(formState) {
    return {
      personId: formState.postPersonId,
      personOrgRole: formState.personOrgRole.map(x => formatPersonOrgRoleObj(x))
    };
  };

  function createPutStaffObj(formState) {
    const newPersonOrgRole = [];
    const currentStaffMember = usasClubStaffState.arrayData.find(x => x.personId === formState.personId);

    if (currentStaffMember && Array.isArray(currentStaffMember.personOrgRole)) {
      let index = 0;
      for (const newRole of formState.personOrgRole) {
        const oldRole = currentStaffMember.personOrgRole.find(x => x.orgRoleId === newRole.id);
        let fields = [];
        let fieldsObj = {};

        if (oldRole) {
          if (Array.isArray(oldRole.personOrgRoleFields) && oldRole.personOrgRoleFields.length > 0) {
            oldRole.personOrgRoleFields[index].fieldValue = formState.coachTypeCode;
          } else {
            if (coachRoleIds.includes(oldRole.orgRoleId)) {
              fieldsObj = {
                fieldName: "Full_Part_Time",
                orgRoleFieldId: orgRoleToFieldIdMap[oldRole.orgRoleId].id,
                fieldValue: formState.coachTypeCode
              };

              fields.push(fieldsObj);
              oldRole.personOrgRoleFields = fields;
            }
          }

          newPersonOrgRole.push(oldRole);
        } else {
          if (coachRoleIds.includes(newRole.id)) {
            fieldsObj = {
              fieldName: "Full_Part_Time",
              orgRoleFieldId: orgRoleToFieldIdMap[newRole.id].id,
              fieldValue: formState.coachTypeCode
            };

            fields.push(fieldsObj);
            newPersonOrgRole.push({ orgRoleId: newRole.id, orgRoleName: newRole.name, personOrgRoleFields: fields });
          } else {
            newPersonOrgRole.push({ orgRoleId: newRole.id, orgRoleName: newRole.name });
          }
        }
      }
    } else {
      for (const role of formState.personOrgRole) {
        newPersonOrgRole.push({ orgRoleId: role.id, orgRoleName: role.name });
      }
    }

    if (formState.registrationRoles.length > 0) {
      for (const registrationRole of formState.registrationRoles) {
        newPersonOrgRole.push(registrationRole);
      }
    }

    return {
      personId: formState.personId,
      personOrgRole: newPersonOrgRole
    };
  };

  function hasConflictingCoachRoles(formState) {
    const coachRolesObj = orgRoleStaffRoleHierarchyState.arrayData.find(x => x.name === "Coach");
    const assistantCoachRolesObj = coachRolesObj.children.find(x => x.name === "Assistant Coach");

    const isHeadCoachRoleInPersonOrgRoles = formState.personOrgRole.find(x => x.id === HEAD_COACH_ORG_ROLE_ID) !== undefined;
    let isAssistantCoachRoleInPersonOrgRoles = false;

    for (const role of formState.personOrgRole) {
      isAssistantCoachRoleInPersonOrgRoles = assistantCoachRolesObj.children.find(x => x.id === role.id) !== undefined;

      if (isAssistantCoachRoleInPersonOrgRoles === true) {
        break;
      }
    }

    return isHeadCoachRoleInPersonOrgRoles && isAssistantCoachRoleInPersonOrgRoles;
  }

  function hasValidUniqueOrgRoles(formState) {
    const uniqueOrgRoleIds = [HEAD_COACH_ORG_ROLE_ID];

    for (const uniqueRoleId of uniqueOrgRoleIds) {
      const matchingFormRole = formState.personOrgRole.find(x => x.id === uniqueRoleId);

      if (matchingFormRole) {
        for (const staff of usasClubStaffState.arrayData) {
          if (staff.personId !== formState.personId && staff.personId !== formState.postPersonId && Array.isArray(staff.personOrgRole)) {
            const matchingStaffRole = staff.personOrgRole.find(x => x.orgRoleId === uniqueRoleId);

            if (matchingStaffRole) {
              return `Can only have one ${matchingStaffRole.orgRoleName}, current ${matchingStaffRole.orgRoleName} is ${staff.firstName} ${staff.lastName}`;
            }
          }
        }
      }
    }

    return undefined;
  };

  function getFormInitialState() {
    return {
      personId: '',
      memberId: '',
      personOrgRole: [],
      postPersonId: '',
      coachTypeCode: '',
      coachTypeCodeName: '',
      registrationRoles: []
    }
  };

  function getInitialViewState() {
    return {
      firstName: '',
      lastName: '',
      memberId: '',
      registrationRoleString: ''
    };
  };

  return {
    clubRegistrationNavState,
    usasClubStaffState,
    orgRoleStaffRoleHierarchyState,
    isSubmitting,
    viewState,
    isEdit: formState.personId ? true : false,
    formState,
    errorState,
    onFormValueChanged: updateFormState,
    onValueTextPairChanged,
    handleSubmit,
    onAddClicked,
    onCancelClicked,
    coachTypeCodeOptions,
    HEAD_COACH_ORG_ROLE_ID,
    ASSISTANT_COACH_ORG_ROLE_ID,
    AGE_GROUP_COACH_ORG_ROLE_ID,
    SENIOR_COACH_ORG_ROLE_ID,
    setFormData
  }
};

export default useClubRegistrationStaffDetail;