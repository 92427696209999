import useSisenseScyLcmEventNoCourseDropdown from "./UseSisenseScyLcmEventNoCourseDropdown";

import Dropdown from '../../../../common/components/dropdowns/Dropdown';

import Constants from "../../../../common/utils/Constants";

import global from '../../../../common/components/GlobalStyle.module.css';

const SisenseScyLcmEventNoCourseDropdown = ({ label, name, value, error, message, onChange }) => {
  const { scyLcmEventNoCourseOptions } = useSisenseScyLcmEventNoCourseDropdown();

  return !scyLcmEventNoCourseOptions.options
    ? <span className={global.LoadingMsg}>{Constants.LOADING_MSG}</span>
    : (
      <Dropdown
        error={error}
        label={label}
        message={message}
        options={scyLcmEventNoCourseOptions.options}
        name={name}
        value={value}
        onChange={onChange}
        isLoading={!scyLcmEventNoCourseOptions.options}
      />
    );
};

export default SisenseScyLcmEventNoCourseDropdown;