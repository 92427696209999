import { Fragment } from "react";

import useReportListAsButton from "./UseReportListAsButton";

import ViewReport from "./ViewReport";
import ReportGrid from "./ReportGrid";

import PopUpModal from "../dialogs/PopUpModal";
import LoadingModal from "../dialogs/LoadingModal";
import NoReport from "./NoReport";
import ActionIntraPageButton from "../buttons/ActionIntraPageButton";

const ReportGridPopup = ({ title, reportList, onViewReportClicked, onModalCanceled }) => (
  <PopUpModal
    title={title}
    widthPct={50}
    maxWidth={500}
    displayPopUp={true}
    onModalCanceled={onModalCanceled}>
    {reportList.length > 0
      ? <ReportGrid reportList={reportList} onReportSelected={onViewReportClicked} />
      : <NoReport />
    }
  </PopUpModal>
);

const ReportListAsButton = ({ buttonLabel = 'Download Report', listTitle = 'Reports', routeName, contextId = undefined,
  reportParameters }) => {
  const {
    isLoading,
    showGridPopup,
    showReport,
    reportInfoId,
    reportList,

    onCloseGridPopup,
    onCloseViewReportClicked,
    onViewReportClicked,
    onReportListButtonClicked
  } = useReportListAsButton();

  return (
    <Fragment>
      <ActionIntraPageButton onClick={() => onReportListButtonClicked(routeName, contextId)}>{buttonLabel}</ActionIntraPageButton>
      {isLoading === true
        ? <LoadingModal />
        : showGridPopup === true
          ? <ReportGridPopup title={listTitle} reportList={reportList} onViewReportClicked={onViewReportClicked} onModalCanceled={onCloseGridPopup} />
          : showReport === true
            ? <ViewReport reportInfoId={reportInfoId} reportParameters={reportParameters} onBackToGrid={onCloseViewReportClicked} />
            : <Fragment />
      }
    </Fragment>
  );
};

export default ReportListAsButton;