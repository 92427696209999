import PreCompOrganizationBasicInfoFormValidation from '../../preComp/components/forms/PreCompOrganizationBasicInfoFormValidation';

import Constants from '../../../../common/utils/Constants';

const PreCompRegistrationOrganizationInfoValidation = async (formState) => {
  let errors = {};

  const basicInfoFormValidation = await PreCompOrganizationBasicInfoFormValidation(formState);

  errors = {...errors, ...basicInfoFormValidation};

  if (!formState.w9Form.size && formState.formW9Url === '') {
    errors.w9Form = 'W-9 Form file is required';
  } else if (formState.w9Form.size && formState.w9Form.type !== Constants.FILE_TYPE_PDF && formState.w9Form.type !== Constants.FILE_TYPE_DOC
    && formState.w9Form.type !== Constants.FILE_TYPE_DOCX) {
    errors.w9Form = `'File type not permitted. Please provide a (*.pdf), (*.doc), or (*.docx) file.';`;
  }

  return errors ? errors : {};
};

export default PreCompRegistrationOrganizationInfoValidation;