import React, { createContext, useState } from 'react';

import PreCompOrganizationContactsData from './PreCompOrganizationContactsData';

export const PreCompOrganizationContactsStateContext = createContext();

const PreCompOrganizationContactsContextProvider = ({ children }) => {
  const stateHook = useState(PreCompOrganizationContactsData.INITIAL_STATE);

  return (
    <PreCompOrganizationContactsStateContext.Provider value={stateHook}>
      {children}
    </PreCompOrganizationContactsStateContext.Provider>
  );
};

export default PreCompOrganizationContactsContextProvider;