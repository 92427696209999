import { BASIC_INITIAL_STATE } from '../../../common/utils/HttpHelper';

import getPreCompLocationStaffData from '../../../common/state/orgUnitStaff/GetOrgUnitStaffData';
import postPreCompLocationStaffData from '../../../common/state/orgUnitStaff/PostOrgUnitStaffData';
import putPreCompLocationStaffData from '../../../common/state/orgUnitStaff/PutOrgUnitStaffData';
import deletePreCompLocationStaffData from '../../../common/state/orgUnitStaff/DeletePersonOrgRoleDurationData';

const INITIAL_STATE = {
  ...BASIC_INITIAL_STATE
};

const PreCompLocationStaffData = { INITIAL_STATE, getPreCompLocationStaffData, postPreCompLocationStaffData, putPreCompLocationStaffData, deletePreCompLocationStaffData };

export default PreCompLocationStaffData;