import React from 'react';

import EditIcon from '../../../../../common/components/icons/EditIcon';
import UnlinkIcon from '../../../../../common/components/icons/UnlinkIcon';

import global from '../../../../../common/components/GlobalStyle.module.css';

const TableRow = ({ facility, onEditClicked, onDeleteClicked }) => (
  <tr>
    <td>{facility.facility?.facilityName || ''}</td>
    <td>{facility.facility?.city || ''}</td>
    <td>{facility.facility?.stateCode || ''}</td>
    <td>{facility.facility?.postalCode || ''}</td>
    <td>{facility.facility?.address1 || ''}</td>
    <td>
      <button
        className={global.IconButtonMargin}
        type="button"
        onClick={() => onEditClicked(facility.facilityId)}>
        <EditIcon />
      </button>
      <button
        className={global.IconButtonMargin}
        type="button"
        onClick={() => onDeleteClicked(facility.facility?.facilityId)}>
        <UnlinkIcon />
      </button>
    </td>
  </tr>
);

const RegistrationProviderFacilitiesGridLarge = ({ data, isLoading, onEditClicked, onDeleteClicked }) => (
  <table className={[global.UsasTable, 'visible-md visible-lg'].join(' ')}>
    <thead>
      <tr>
        <th>Facility Name</th>
        <th>City</th>
        <th>State</th>
        <th>Zip Code</th>
        <th>Address</th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      {isLoading === true
        ? <tr><td colSpan="6">Loading...</td></tr>
        : data.length > 0
          ? data.map((facility, i) => <TableRow key={i} facility={facility} onEditClicked={onEditClicked} onDeleteClicked={onDeleteClicked} />)
          : <tr><td colSpan="6">No Facilities</td></tr>
      }
    </tbody>
  </table>
);

export default RegistrationProviderFacilitiesGridLarge;