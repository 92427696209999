import { useState, useEffect } from 'react';
import { useNavigate } from '../../../../common/wrappers/ReactRouterDom';

import NavLinks from '../utils/NavLinks';

import useFacilityData from '../../../../common/state/facility/UseFacilityData';
import useClubAffiliationTypeData from '../../../../common/state/clubAffiliationType/UseClubAffiliationTypeData';

import Constants from '../../../../common/utils/Constants';

const INITIAL_STATE = {
  trySubmit: false,
  tryRedirect: false,
  facilityObj: {}
};

const INITIAL_DUP_STATE = {
  arrayData: [],
  isArrayLoaded: false,
  isArrayLoading: false
};

const useLearnToSwimRegistrationFacilitiesAdd = () => {
  const navigate = useNavigate();
  const { facilityState, getPotentialFacilityDupes, postFacility, clearObjData } = useFacilityData();
  const { clubAffiliationTypeState } = useClubAffiliationTypeData();
  const [state, setState] = useState(INITIAL_STATE);
  const [dupsState, setDupsState] = useState(INITIAL_DUP_STATE);

  const onAddDupFacilityClicked = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    setDupsState({ ...INITIAL_DUP_STATE });
    postFacility(state.facilityObj);

    setState({ ...state, tryRedirect: true });
  };

  const onCancelDupFacilityClicked = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    setState({
      ...state,
      submitClicked: false,
      facilityObj: {}
    });

    setDupsState({ ...INITIAL_DUP_STATE });
  };

  const onSubmitFormCallback = (formState) => {
    getPotentialFacilityDupes(formState.address1?.trim(), formState.city?.trim(), formState.stateCode, formState.postalCode?.trim(),
      dupsState, setDupsState);

    setState({
      ...state,
      submitClicked: true,
      facilityObj: createPostFacilityObject(formState)
    });
  };

  const onBackClicked = () => {
    navigate(NavLinks.LEARN_TO_SWIM_REGISTRATION_FACILITIES_SEARCH);
  };

  useEffect(() => {
    clearObjData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (dupsState.isArrayLoading === false && dupsState.isArrayLoaded === true
      && dupsState.arrayData.length === 0 && state.submitClicked === true) {
      postFacility(state.facilityObj);

      setState({ ...state, tryRedirect: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dupsState]);

  useEffect(() => {
    if (state.submitClicked === true && state.tryRedirect === true
      && facilityState.isSaved === true && facilityState.isSaving === false) {
      navigate(NavLinks.LEARN_TO_SWIM_REGISTRATION_FACILITIES_DETAIL, { state: { facilityId: facilityState.objData.facilityId } });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state, facilityState]);

  function createPostFacilityObject(formState) {
    return {
      facilityName: formState.facilityName.trim(),
      address1: formState.address1.trim(),
      address2: formState.address2.trim(),
      city: formState.city.trim(),
      stateCode: formState.stateCode,
      postalCode: formState.postalCode.trim(),
      contactName: formState.contactName.trim(),
      contactEmailAddress: formState.contactEmailAddress.trim(),
      contactPhoneNumber: formState.contactPhoneNumber.trim() !== '' && !Constants.PHONE_AREA_CODES.find(x => x === formState.contactPhoneNumber.trim()) ? formState.contactPhoneNumber.trim() : '',
      clubAffiliationId: formState.clubAffiliationId,
      altitude: formState.altitude.toString().replace(',', ''),
      isInternational: formState.isInternational,
      country: formState.country.trim()
    };
  }

  return {
    isLoading: clubAffiliationTypeState.isArrayLoading || dupsState.isArrayLoading,
    state,
    dupsState,
    clubAffiliationTypeState,
    facilityState,
    onAddDupFacilityClicked,
    onCancelDupFacilityClicked,
    onSubmitFormCallback,
    onBackClicked
  };
};

export default useLearnToSwimRegistrationFacilitiesAdd;