import React, { Fragment } from 'react';

import Input from '../../../../../common/components/inputs/Input';
import ReadOnly from '../../../../../common/components/readOnly/ReadOnly';
import YesNoSwitch from '../../../../../common/components/yesNoSwitch/YesNoSwitch';

const PreCompOrganizationSpecialInfoForm = ({ formState, errorState, onFormValueChanged, includeIsInsuredField }) => (
  <Fragment>
    <div className="row">
      <div className="col-xs-12 col-sm-6 col-md-4">
        {onFormValueChanged === undefined
          ? <ReadOnly
            label="Federal Organization Name"
            name="orgUnitName"
            value={formState.orgUnitName} />
          : (
            <Input
              label="Federal Organization Name*"
              name="orgUnitName"
              value={formState.orgUnitName}
              error={errorState.orgUnitName}
              message={errorState.orgUnitName}
              onChange={(value) => { onFormValueChanged("orgUnitName", value); }} />
          )
        }
      </div>
      <div className="col-xs-12 col-sm-6 col-md-4">
        {onFormValueChanged === undefined
          ? <ReadOnly
            label="Federal Tax Id"
            name="federalTaxId"
            value={formState.federalTaxId} />
          : (
            <Input
              label="Federal Tax Id*"
              name="federalTaxId"
              value={formState.federalTaxId}
              error={errorState.federalTaxId}
              message={errorState.federalTaxId}
              onChange={(value) => { onFormValueChanged("federalTaxId", value); }} />
          )
        }
      </div>
      <div className="col-xs-12 col-sm-6 col-md-4">
        {includeIsInsuredField === true
          ? onFormValueChanged === undefined
            ? <ReadOnly
              label="Organization is insured to provide swim lessons?"
              name="isSwimLessonInsured"
              value={formState.isSwimLessonInsured === 'true' ? 'Yes' : 'No'} />
            : (
              <YesNoSwitch
                label="Organization is insured to provide swim lessons?*"
                name="isSwimLessonInsured"
                checked={formState.isSwimLessonInsured}
                error={errorState.isSwimLessonInsured}
                message={errorState.isSwimLessonInsured}
                onChange={(value) => { onFormValueChanged("isSwimLessonInsured", value); }} />
            )
          : <Fragment />
        }
      </div>
    </div>
  </Fragment>
);

export default PreCompOrganizationSpecialInfoForm;