import Constants from "../../../../common/utils/Constants";
import ToIntIfInt from "../../../../common/utils/ToIntIfInt";
import { isValidName, isValidNumber } from "../../../../common/utils/validation";

export const localValidate = (formState) => {
  let errors = {};

  if (formState.firstOrPreferredName.trim() !== '' && !isValidName(formState.firstOrPreferredName.trim())) {
    errors.firstOrPreferredName = 'First or Preferred Name cannot contain numbers, special characters, or exceed 100 characters';
  }

  if (formState.lastName.trim() !== '' && !isValidName(formState.lastName.trim())) {
    errors.lastName = 'Last Name cannot contain numbers, special characters, or exceed 100 characters';
  }

  if (formState.minAge.trim() !== '' && formState.maxAge.trim() !== '' &&
    ToIntIfInt(formState.minAge.trim()) > ToIntIfInt(formState.maxAge.trim())) {
    errors.minAge = 'Min Age cannot be greater than Max Age';
    errors.maxAge = 'Max Age cannot be less than Min Age';
  } else if (formState.minAge.trim() !== '' && formState.maxAge.trim() === '') {
    errors.maxAge = 'Max Age is required if Min Age is specified';
  } else if (formState.minAge.trim() === '' && formState.maxAge.trim() !== '') {
    errors.minAge = 'Min Age is required if Max Age is specified';
  }

  if (formState.minAge.trim() !== ''
    && (isValidNumber(ToIntIfInt(formState.minAge.trim())) === false || formState.minAge.trim() === '0')) {
    errors.minAge = 'Min Age must be greater than zero';
  } else if (ToIntIfInt(formState.minAge.trim()) !== ''
    && ToIntIfInt(formState.minAge.trim()) > 99) {
    errors.minAge = 'Min Age cannot be greater than 99';
  }

  if (formState.maxAge.trim() !== ''
    && (isValidNumber(ToIntIfInt(formState.maxAge.trim())) === false || formState.maxAge.trim() === '0')) {
    errors.maxAge = 'Max Age must be greater than zero';
  } else if (ToIntIfInt(formState.maxAge.trim()) !== ''
    && ToIntIfInt(formState.maxAge.trim()) > 99) {
    errors.maxAge = 'Max Age cannot be greater than 99';
  }

  if (formState.practiceGroupName.trim() !== '') {
    if (formState.practiceGroupId === Constants.DEFAULT_ID || formState.practiceGroupId === '') {
      errors.practiceGroupId = 'Must be a valid Practice Group';
    }
  }

  if (formState.siteGroupName.trim() !== '') {
    if (formState.siteGroupId === Constants.DEFAULT_ID || formState.siteGroupId === '') {
      errors.siteGroupId = 'Must be a valid Site Group';
    }
  }

  return errors;
};

const ClubAthleteRosterFormValidation = async (formState) => {
  let errors = localValidate(formState);

  return errors ? errors : {};
};

export default ClubAthleteRosterFormValidation;