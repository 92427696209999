import { Fragment } from 'react';

import CopyIcon from '../../../../common/components/icons/CopyIcon';

// import { formatDate } from '../../../../common/utils/DateFunctions';

import global from '../../../../common/components/GlobalStyle.module.css';
import style from './ClubGenerateRegistrationLink.module.css';

const GridRowIcons = ({ link, onOpenNewLinkModalClicked, onClickCopyToClipboard }) => (
	<Fragment>
		<button className={global.IconButtonMobileMargin} type='button' onClick={(e) => onOpenNewLinkModalClicked(e, link)}>
			<span className={[style.SmallGridButtonText, 'pull-right'].join(' ')}>Generate New Link</span>
		</button>
		<button className={[global.IconButtonMobileMargin, 'pull-right'].join(' ')} type="button" onClick={(e) => onClickCopyToClipboard(e, link.resolvedUrl)}>
			<CopyIcon />
		</button>
	</Fragment>
);

const GridRowContent = ({ link }) => (
	<div className={global.SmallTableRowInfo}>
		<div className='row'>
			<div className={['col-xs-6 col-sm-3 visible-xs', global.SmallTableRowLabels].join(' ')}>
				Team Name
			</div>
			<div className={['col-xs-6 col-sm-3 visible-xs', global.SmallTableRowData].join(' ')}>
				{link?.routeCodeName}
			</div>
			{/* <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>
				Effective Date
			</div>
			<div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>
				{formatDate(link?.effectiveDate)}
			</div>
			<div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>
				Expiration Date
			</div>
			<div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>
				{formatDate(link?.expirationDate)}
			</div> */}
		</div>
	</div>
);

const GridRow = ({ link, onOpenNewLinkModalClicked, onClickCopyToClipboard }) => {
	return (
		<Fragment>
			<div className={global.SmallTableRow}>
				<div className={global.SmallTableRowHead}>
					<span className='hidden-xs'>{link?.routeCodeName}</span>&nbsp;
					<GridRowIcons link={link} onOpenNewLinkModalClicked={onOpenNewLinkModalClicked} onClickCopyToClipboard={onClickCopyToClipboard} />
				</div>
				<GridRowContent link={link} />
			</div>
		</Fragment>
	);
}
const SmallClubGenerateRegistrationLinkGrid = ({ state, onOpenNewLinkModalClicked, onClickCopyToClipboard }) => (
	<div className={[global.SmallTable, 'hidden-md hidden-lg'].join(' ')}>
		{state.isArrayLoaded !== true
			? (<div className={global.SmallTableRow}>
				<div className={global.SmallTableRowHead}>
					&nbsp;
				</div>
				<div className={global.SmallTableRowInfo}>
					{state.isArrayLoading === true
						? <div className={global.SmallTableRowLabels}>Loading...</div>
						: <div className={global.SmallTableRowLabels}>No Results</div>
					}
				</div>
			</div>)
			: Array.isArray(state?.arrayData) === true && state?.arrayData?.length > 0
				? state?.arrayData?.map((link, i) =>
					<GridRow
						key={i}
						link={link}
						onOpenNewLinkModalClicked={onOpenNewLinkModalClicked}
						onClickCopyToClipboard={onClickCopyToClipboard} />)
				: (<div className={global.SmallTableRow}>
					<div className={global.SmallTableRowHead}> &nbsp; </div>
					<div className={global.SmallTableRowInfo}>
						<div className={global.SmallTableRowLabels}>No Results</div>
					</div>
				</div>)
		}
	</div>
);

export default SmallClubGenerateRegistrationLinkGrid;