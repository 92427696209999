import React from 'react';

import BaseUsasClubContextProvider from '../../../common/state/clubBase/BaseUsasClubContextProvider';
import BaseUsasClubFiltersContextProvider from '../../../common/state/clubBase/BaseUsasClubFiltersContextProvider';
import PersonOrgRoleContextProvider from '../../../common/state/personOrgRole/PersonOrgRoleContextProvider';
import SearchClubContextProvider from '../../../common/state/searchClub/SearchClubContextProvider';
import SelectOrgUnitContextProvider from '../../../common/state/selectOrgUnit/SelectOrgUnitContextProvider';
import ClubRegistrationNewContextProvider from '../../state/clubRegistrationNew/ClubRegistrationNewContextProvider';

const ClubContextProviders = ({ children }) => {
  return (
    <SelectOrgUnitContextProvider>
      <PersonOrgRoleContextProvider>
        <BaseUsasClubContextProvider>
          <BaseUsasClubFiltersContextProvider>
            <SearchClubContextProvider>
              <ClubRegistrationNewContextProvider>
              {children}
              </ClubRegistrationNewContextProvider>
            </SearchClubContextProvider>
          </BaseUsasClubFiltersContextProvider>
        </BaseUsasClubContextProvider>
      </PersonOrgRoleContextProvider>
    </SelectOrgUnitContextProvider>
  );
};

export default ClubContextProviders;