import { useState } from 'react';
import ClubRegistrationLinkOrgUnitIdData from './ClubRegistrationLinkOrgUnitIdData';

const useClubRegistrationLinkOrgUnitIdData = () => {
  const [clubRegistrationLinkState, setClubRegistrationLinkState] = useState(ClubRegistrationLinkOrgUnitIdData.INITIAL_STATE);

  const getClubRegistrationLinkByOrgUnitId = (orgUnitId) => {
    ClubRegistrationLinkOrgUnitIdData.getClubRegistrationLinkByOrgUnitId(orgUnitId, clubRegistrationLinkState, setClubRegistrationLinkState);
  };

  const putClubRegistrationLinkOrgUnitId = (routeCodeId) => {
    ClubRegistrationLinkOrgUnitIdData.putClubRegistrationLinkOrgUnitId(routeCodeId, clubRegistrationLinkState, setClubRegistrationLinkState);
  };

  return {
    clubRegistrationLinkState,
    setClubRegistrationLinkState,
    getClubRegistrationLinkByOrgUnitId,
    putClubRegistrationLinkOrgUnitId
  };
};

export default useClubRegistrationLinkOrgUnitIdData;