import { useEffect, useState } from 'react';
import { useNavigate } from '../../../common/wrappers/ReactRouterDom';

import useBaseUsasClubData from '../../../common/state/clubBase/UseBaseUsasClubData';
import useSelectOrgUnitData from '../../../common/state/selectOrgUnit/UseSelectOrgUnitData';
import usePersonOrgRoleData from '../../../common/state/personOrgRole/UsePersonOrgRoleData';
import useClubPersistentStorage from '../../../common/state/clubPersistentStorage/UseClubPersistentStorage';
import useClubBaseInfoData from '../../state/clubBaseInfo/UseClubBaseInfoData';

import { navigateToCrossUiRoute } from '../../../common/utils/FormatCrossUiRoute';
import Constants from '../../../common/utils/Constants';

const INITIAL_STATE = {
  returnUrl: '',
  uIProjectName: '',
  backToSystemLabel: ''
};

const useClubHeader = () => {
  const navigate = useNavigate();
  const { getPersistentStorage } = useClubPersistentStorage();
  const { baseUsasClubState, clearBaseUsasClubObjData } = useBaseUsasClubData();
  const { clubBaseInfoState, getClubBaseInfo } = useClubBaseInfoData();
  const { selectOrgUnitState, resetSelectOrgUnitState } = useSelectOrgUnitData();
  const { clearArrayData } = usePersonOrgRoleData();
  const [state, setState] = useState(INITIAL_STATE);

  const isSsrpCurrent = () => {
    const ssrpExpDate = new Date(clubBaseInfoState.objData.ssrpExpirationDate);
    const expDd = ssrpExpDate.getDate();
    const expMm = ssrpExpDate.getMonth() + 1;
    const expYy = ssrpExpDate.getFullYear();
    const expDt = new Date(expMm + '-' + expDd + '-' + expYy);

    const today = new Date();
    const dd = today.getDate();
    const mm = today.getMonth() + 1;
    const yy = today.getFullYear();
    const todayDt = new Date(mm + '-' + dd + '-' + yy);

    return expDt >= todayDt;
  }

  const getLogoOverlayText = () => {
    const ssrpExpDate = new Date(clubBaseInfoState.objData.ssrpExpirationDate);
    const expMm = ssrpExpDate.toLocaleString('default', { month: 'long' });
      
    const expYy = ssrpExpDate.getFullYear();
    let textToOverlay = `Until ${expMm} ${expYy}`;

    return textToOverlay;
  }

  const getXCoord = () => {
    const ssrpExpDate = new Date(clubBaseInfoState.objData.ssrpExpirationDate);
    const expMm = ssrpExpDate.toLocaleString('default', { month: 'long' });

    return 58 - (expMm.length * 2.5);
  }

  const onBackClicked = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    if (state.uIProjectName !== import.meta.env.VITE_PROJECT_NAME) {
      navigateToCrossUiRoute(state.uIProjectName, state.returnUrl, navigate);
    } else {
      resetSelectOrgUnitState();
      clearArrayData();
      clearBaseUsasClubObjData();

      navigate(state.returnUrl);
    }
  };

  useEffect(() => {
    const { returnUrl, uIProjectName, backToSystemLabel } = getPersistentStorage();

    setState({
      returnUrl: returnUrl || '/',
      uIProjectName: uIProjectName || Constants.UI_PROJECT_NAMES.CLUB,
      backToSystemLabel: backToSystemLabel || 'Back to Club Search'
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (baseUsasClubState.isObjLoaded === true) {
      getClubBaseInfo(baseUsasClubState.objData.usasClubId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [baseUsasClubState]);

  return {
    state,
    baseUsasClubState,
    clubBaseInfoState,
    onBackClicked,
    isSSRPCurrent: isSsrpCurrent(),
    logoOverlayText: getLogoOverlayText(),
    xCoord: getXCoord(),
    selectOrgUnitState,
    backToSelectionText: 'Back to Selection'
  };
};

export default useClubHeader;