import React, { Fragment } from 'react';

import useClubAffiliation from './UseClubAffiliation';

import ClubAffiliationReadGrid from './ClubAffiliationReadGrid';

import Headings from '../../../../common/components/headings/Headings';
import PopUpModal from '../../../../common/components/dialogs/PopUpModal';
import Constants from '../../../../common/utils/Constants';

const ClubAffiliationRead = () => {
  const { baseUsasClubState } = useClubAffiliation();

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>Club Affiliations</Headings.H3>
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12 usas-extra-top-margin usas-extra-bottom-margin">
          <ClubAffiliationReadGrid state={baseUsasClubState} />
        </div>
      </div>
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={baseUsasClubState.isObjLoading} />
    </Fragment>
  );
};

export default ClubAffiliationRead;