import React, { Fragment } from 'react';

import usePreCompLocationFacilitiesPoolsDetail from './UsePreCompLocationFacilitiesPoolsDetail';

import NavLinks from '../NavLinks';

import PopUpModal from '../../../../common/components/dialogs/PopUpModal';
import AddEditFacilityPool from '../../../../common/components/searches/searchFacility/addEditPool/AddEditFacilityPool';
import Constants from '../../../../common/utils/Constants';

const PreCompLocationFacilitiesPoolsDetail = () => {
  const {
    preCompLocationState
  } = usePreCompLocationFacilitiesPoolsDetail();

  return (
    <Fragment>
      <AddEditFacilityPool
        saveUrl={NavLinks.PRE_COMP_LOCATION_FACILITY}
        cancelUrl={NavLinks.PRE_COMP_LOCATION_FACILITY}
        showFacilityInfo={true} />
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.SAVING_MSG}
        displayPopUp={preCompLocationState.isSaving} />
    </Fragment>
  );
};

export default PreCompLocationFacilitiesPoolsDetail;