import useEnvironmentVariableData from '../../../../../../common/state/environmentVariable/UseEnvironmentVariableData';

const useClubPerformanceAthleteTimesMeetResults = () => {
  const { environmentVariableState, MEET_RESULTS_OID } = useEnvironmentVariableData();

  return {
    environmentVariableState, MEET_RESULTS_OID
  };
};

export default useClubPerformanceAthleteTimesMeetResults;