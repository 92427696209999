import { useEffect, useState } from 'react';
import { useNavigate } from '../../../../common/wrappers/ReactRouterDom';

import NavLinks from '../NavLinks';

import usePreCompLocationRegistrationNavData from '../../../state/preCompLocationRegistration/preCompLocationRegistrationLeftNavBar/UsePreCompLocationRegistrationNavData';
import usePreCompLocationRegistrationData from '../../../state/preCompLocationRegistration/UsePreCompLocationRegistrationData';
import PreCompRegistrationConstants from '../utils/PreCompRegistrationConstants';

const REQUIRED_ERROR = 'Must add at least one Program Offering';

const usePreCompRegistrationLocationPrograms = () => {
  const navigate = useNavigate();
  const { preCompLocationRegistrationState, confirmSave, saveObjData } = usePreCompLocationRegistrationData();
  const { locationRegistrationNavState, updateCurrentNavState } = usePreCompLocationRegistrationNavData();
  const [gridState, setGridState] = useState([]);
  const [pageErrorMessageState, setPageErrorMessageState] = useState('');

  const onContinueClicked = () => {
    if (preCompLocationRegistrationState.objData.preCompLocationOffering.length > 0) {
      navigate(NavLinks.PRE_COMP_REGISTRATION_LOCATION_STAFF);
    } else {
      setPageErrorMessageState(REQUIRED_ERROR);
    }
  };

  const onBackClicked = () => {
    if (preCompLocationRegistrationState.objData.preCompLocationOffering.length > 0) {
      navigate(NavLinks.PRE_COMP_REGISTRATION_LOCATION_INFO);
    } else {
      setPageErrorMessageState(REQUIRED_ERROR);
    }
  };

  const onAddOffering = () => {
    navigate(NavLinks.PRE_COMP_REGISTRATION_LOCATION_PROGRAMS_DETAIL);
  };

  const onEditOffering = (preCompLocationOfferingId) => {
    navigate(NavLinks.PRE_COMP_REGISTRATION_LOCATION_PROGRAMS_DETAIL, { state: { preCompLocationOfferingId } });
  };

  const onDeleteOffering = (preCompLocationOfferingId) => {
    const locObj = preCompLocationRegistrationState.objData;
    const newPreCompLocationOffering = locObj.preCompLocationOffering.filter(x => x.preCompLocationOfferingId !== preCompLocationOfferingId);

    locObj.preCompLocationOffering = newPreCompLocationOffering;

    saveObjData(locObj);
  };

  useEffect(() => {
    if (preCompLocationRegistrationState.objData?.preCompLocationOffering?.length > 0) {
      updateCurrentNavState(PreCompRegistrationConstants.LOC_PROGRAMS, true);
    } else {
      updateCurrentNavState(PreCompRegistrationConstants.LOC_PROGRAMS, false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (preCompLocationRegistrationState.isSaved === true) {
      confirmSave();

      setPageErrorMessageState('');
      if (preCompLocationRegistrationState.objData.preCompLocationOffering.length > 0) {
        updateCurrentNavState(PreCompRegistrationConstants.LOC_PROGRAMS, true);
      } else {
        updateCurrentNavState(PreCompRegistrationConstants.LOC_PROGRAMS, false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [preCompLocationRegistrationState.isSaved]);

  useEffect(() => {
    if (preCompLocationRegistrationState.isObjLoaded === true && preCompLocationRegistrationState.isSaved === false) {
      setGridState(preCompLocationRegistrationState.objData?.preCompLocationOffering || []);
    } else if (preCompLocationRegistrationState.isObjLoaded === false) {
      navigate(NavLinks.PRE_COMP_REGISTRATION_ENTRY);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [preCompLocationRegistrationState.isObjLoaded, preCompLocationRegistrationState.isSaved]);

  return {
    gridState,
    locationRegistrationNavState,
    pageErrorMessageState,
    onAddOffering,
    onEditOffering,
    onDeleteOffering,
    onContinueClicked,
    onBackClicked
  };
};

export default usePreCompRegistrationLocationPrograms;