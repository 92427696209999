import { useEffect, useRef } from 'react';

import useBaseUsasClubData from '../../../common/state/clubBase/UseBaseUsasClubData';
import useClubPersistentStorage from '../../../common/state/clubPersistentStorage/UseClubPersistentStorage';
import useNavRoutes from '../../../common/state/security/UseNavRoutes';
import useSecurityData from '../../../common/state/security/UseSecurityData';

import { useLocation } from '../../../common/wrappers/ReactRouterDom';

const TAXONOMIES = ['ClubDetail'];
const SCOPE = 'Club';

const useClubContextRoutes = () => {
  const location = useLocation();
  const { navRoutes } = useNavRoutes();
  const { getPersistentStorage, resetClubExpiration } = useClubPersistentStorage();
  const { contextSecurityState, getContextSecurity } = useSecurityData();
  const { baseUsasClubState, getBaseUsasClubByOrgUnitId } = useBaseUsasClubData();
  const loadingStatus = useRef('not started');

  useEffect(() => {
    resetClubExpiration();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  useEffect(() => {
    const persistentStorage = getPersistentStorage();

    if (!persistentStorage?.orgUnitId) {
      loadingStatus.current = 'error';
    } else if (loadingStatus.current === 'not started') {
      getBaseUsasClubByOrgUnitId(persistentStorage.orgUnitId);
      loadingStatus.current = 'getting base club';
    } else if (loadingStatus.current === 'getting base club' && baseUsasClubState.isObjLoaded === true) {
      const ctxStatus = getContextSecurity(baseUsasClubState.objData?.orgUnitId || '0', TAXONOMIES, SCOPE);
      loadingStatus.current = ctxStatus === 'sent' ? 'getting context security' : 'loaded';
    } else if (loadingStatus.current === 'getting context security' && contextSecurityState.isObjLoaded === true) {
      loadingStatus.current = 'loaded';
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [baseUsasClubState, contextSecurityState]);

  return {
    isLoading: loadingStatus.current !== 'loaded',
    isError: loadingStatus.current === 'error',
    navRoutes
  };
};

export default useClubContextRoutes;