import { BASIC_INITIAL_STATE } from '../../../common/utils/HttpHelper';

import postKpiMeasureData from './PostKpiMeasureData';
import putKpiMeasureData from './PutKpiMeasureData';
import getKpiMeasureData from './GetKpiMeasureData';

const INITIAL_STATE = {
  ...BASIC_INITIAL_STATE,
};

const KpiMeasureData = {
  INITIAL_STATE,
  postKpiMeasureData,
  putKpiMeasureData,
  getKpiMeasureData
};

export default KpiMeasureData;