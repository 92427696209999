import { useEffect, useState } from 'react';

import validate from './SearchPreCompValidation';

import usePreCompOrganizationData from '../../../state/preCompOrganization/UsePreCompOrganizationData';
import useSearchPreCompData from '../../../state/searchPreComp/UseSearchPreCompData';

import useForm from '../../../utils/UseForm';

import useStatesCombobox from '../../comboboxes/statesCombobox/UseStatesCombobox';

const sortByOptions = [
  { id: 1, name: 'Organization Name (A-Z)', value: 'TradeName' },
  { id: 2, name: 'Location Name (A-Z)', value: 'LocationName' }
];

const useSearchPreComp = () => {
  const { preCompOrganizationState, searchFilterState, searchPreCompOrganizationLocations } = usePreCompOrganizationData();
  const { searchPreCompState, setSearchPreCompState } = useSearchPreCompData();
  const { getStateNameById } = useStatesCombobox();
  const { formState, errorState, handleSubmit, updateFormState, setFormState, onValueTextPairChanged, setErrors,
    setIsDirty, isDirty } = useForm(getInitialFormState(), submitFormCallback, validate);
  const [gridState, setGridState] = useState([]);

  const onFiltersToggle = () => {
    setSearchPreCompState({ ...searchPreCompState, showFilters: !searchPreCompState.showFilters });
    setIsDirty(true);
  }

  const clearForm = () => {
    setFormState(getInitialFormState());
    setErrors({});
  };

  useEffect(() => {
    if (preCompOrganizationState.isArrayLoaded === true) {
      const preCompLocations = [];

      for (const preCompOrg of preCompOrganizationState.arrayData) {
        if (Array.isArray(preCompOrg.locations) && preCompOrg.locations.length > 0) {
          for (const location of preCompOrg.locations) {
            preCompLocations.push({
              ...location,
              organizationId: preCompOrg.preCompetitiveOrganizationId
            });
          }
        }
      }

      setGridState(preCompLocations);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [preCompOrganizationState.isArrayLoaded]);

  useEffect(() => {
    if (Object.keys(searchFilterState.filterObject).length > 0) {
      const oldSearchValues = JSON.parse(JSON.stringify(searchFilterState.filterObject))
      let sortByIndex = undefined;

      for (let i = 0; i < sortByOptions.length; i++) {
        if (searchFilterState.sortBy === sortByOptions[i].value) {
          sortByIndex = i;
          break;
        }
      }

      setFormState({
        ...formState,
        ...oldSearchValues,
        stateCode: oldSearchValues.state || '',
        stateName: oldSearchValues.state ? getStateNameById(oldSearchValues.state) : '',
        sortById: sortByIndex !== undefined ? sortByOptions[sortByIndex].id : sortByOptions[0].id,
        sortByName: sortByIndex !== undefined ? sortByOptions[sortByIndex].name : sortByOptions[0].name
      });
      setIsDirty(true);

      if (preCompOrganizationState.isArrayLoading === false) {
        searchPreCompOrganizationLocations(searchFilterState.filterObject, searchFilterState.sortBy || '');
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (searchPreCompState.showFilters === true && isDirty === false) {
      setIsDirty(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchPreCompState.showFilters, isDirty]);

  function submitFormCallback(formState) {
    setSearchPreCompState({ ...searchPreCompState, tryRedirect: false });

    let sortBy = undefined;

    for (const sortByOption of sortByOptions) {
      if (formState.sortById === sortByOption.id) {
        sortBy = sortByOption.value;
        break;
      }
    }

    if (preCompOrganizationState.isArrayLoading === false) {
      const filterObject = createSearchFilterObj(formState);

      searchPreCompOrganizationLocations(filterObject, sortBy || '', {});
    }
  };

  function createSearchFilterObj(formState) {
    return {
      name: formState.name.trim() || undefined,
      federalTaxId: formState.federalTaxId.trim() || undefined,
      city: formState.city.trim() || undefined,
      state:  formState.stateName !== '' ? formState.stateCode : undefined,
      zipCode: formState.zipCode.trim() || undefined
    };
  };

  function getInitialFormState() {
    return {
      name: '',
      federalTaxId: '',
      city: '',
      stateCode: '',
      stateName: '',
      zipCode: '',
      sortById: sortByOptions[0].id,
      sortByName: sortByOptions[0].name
    }
  };

  return {
    formState,
    errorState,
    preCompOrganizationState,
    gridState,
    searchPreCompState,
    setSearchPreCompState,
    clearForm,
    onFormValueChanged: updateFormState,
    onValueTextPairChanged,
    handleSubmit,
    setIsDirty,
    sortByOptions,
    onFiltersToggle
  }
}

export default useSearchPreComp;