import { useContext } from 'react';

import { OrgUnitBulkRenewalOptInStateContext } from './OrgUnitBulkRenewalOptInContextProvider';

import OrgUnitBulkRenewalOptInData from './OrgUnitBulkRenewalOptInData';

import Constants from '../../../common/utils/Constants';

const useOrgUnitBulkRenewalOptInData = () => {
  const [orgUnitBulkRenewalOptInState, setOrgUnitBulkRenewalOptInState] = useContext(OrgUnitBulkRenewalOptInStateContext);

  const getOrgUnitBulkRenewalOptIn = (orgUnitId) => { OrgUnitBulkRenewalOptInData.getOrgUnitBulkRenewalOptInData(orgUnitId, orgUnitBulkRenewalOptInState, setOrgUnitBulkRenewalOptInState); }

  const putOrgUnitBulkRenewalOptIn = (orgUnitId, optInObj) => { OrgUnitBulkRenewalOptInData.putOrgUnitBulkRenewalOptInData(orgUnitId, optInObj, orgUnitBulkRenewalOptInState, setOrgUnitBulkRenewalOptInState); }

  const clearOrgUnitBulkRenewalOptInObjData = () => {
    setOrgUnitBulkRenewalOptInState({
      ...orgUnitBulkRenewalOptInState,
      orgUnitId: Constants.DEFAULT_ID,
      isObjLoading: false,
      isObjLoaded: false,
      isSaving: false,
      isSaved: false,
      objData: {},
      message: ''
    });
  };

  return {
    orgUnitBulkRenewalOptInState,
    getOrgUnitBulkRenewalOptIn,
    putOrgUnitBulkRenewalOptIn,
    clearOrgUnitBulkRenewalOptInObjData
  };
};

export default useOrgUnitBulkRenewalOptInData;