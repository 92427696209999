const NavLinks = {
  SAFE_SPORT_ROOT: '/safesport',
  SAFE_SPORT_SELECTION: '/safesport/selection',
  SAFE_SPORT_PROGRAM_LEVEL: '/safesport/programlevel',
  SAFE_SPORT_PROGRAM_CATEGORY: '/safesport/programcategory',
  SAFE_SPORT_PROGRAM_CATEGORY_YES_NO: '/safesport/programcategory/yesno',
  SAFE_SPORT_PROGRAM_CATEGORY_FILE_UPLOAD: '/safesport/programcategory/fileupload',
  SAFE_SPORT_PROGRAM_CATEGORY_TEXT_ENTRY: '/safesport/programcategory/textentry',
  SAFE_SPORT_PROGRAM_CATEGORY_DROPDOWN: '/safesport/programcategory/dropdown',
  SAFE_SPORT_PROGRAM_CATEGORY_KPI: '/safesport/programcategory/kpi',
  SAFE_SPORT_PROGRAM_CATEGORY_MULTISELECT: '/safesport/programcategory/multiselect',
  SAFE_SPORT_PROGRAM_CATEGORY_MEMBER_ID: '/safesport/programcategory/memberId',
  SAFE_SPORT_PROGRAM_CATEGORY_ELSEWHERE: '/safesport/programcategory/swims'
};

export default NavLinks;