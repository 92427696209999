import { useContext } from 'react';

import MemberAdministrationLscBoardData from './MemberAdministrationLscBoardData';
import { MemberAdministrationLscBoardStateContext } from './MemberAdministrationLscBoardContextProvider';

const useMemberAdministrationLscBoardData = () => {
  const [memberAdministrationLscBoardState, setMemberAdministrationLscBoardState] = useContext(MemberAdministrationLscBoardStateContext);

  const getLscBoardMembers = (orgUnitId) => MemberAdministrationLscBoardData.getLscBoardMembers(orgUnitId, memberAdministrationLscBoardState, setMemberAdministrationLscBoardState);
  const postLscBoardMember = (orgUnitId, roleGroupId, staffObj) => MemberAdministrationLscBoardData.postLscBoardMember(orgUnitId, roleGroupId, staffObj, memberAdministrationLscBoardState, setMemberAdministrationLscBoardState);
  const putLscBoardMember = (orgUnitId, personId, roleGroupId, staffObj) => MemberAdministrationLscBoardData.putLscBoardMember(orgUnitId, personId, roleGroupId, staffObj, memberAdministrationLscBoardState, setMemberAdministrationLscBoardState);
  const deleteLscBoardMember = (personOrgRoleDurationId) => MemberAdministrationLscBoardData.deleteLscBoardMember(personOrgRoleDurationId, memberAdministrationLscBoardState, setMemberAdministrationLscBoardState);

  const confirmSave = () => {
    setMemberAdministrationLscBoardState({
      ...memberAdministrationLscBoardState,
      isSaved: false
    });
  };

  const clearLscBoardMembersArrayData = () => {
    setMemberAdministrationLscBoardState({
      ...memberAdministrationLscBoardState,
      isArrayLoading: false,
      isArrayLoaded: false,
      isSaving: false,
      isSaved: false,
      arrayData: []
    });
  }


  return {
    memberAdministrationLscBoardState,
    getLscBoardMembers,
    postLscBoardMember,
    putLscBoardMember,
    deleteLscBoardMember,
    confirmSave,
    clearLscBoardMembersArrayData
  };
};

export default useMemberAdministrationLscBoardData;