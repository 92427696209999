import { API_NAMES, initApi } from "../../../common/utils/HttpApiHelper";

const getLegalEntityTypeData = (state, setState) => {
    const api = initApi(API_NAMES.CLUB, state, setState);
    const url = `/LegalEntityType`;

    return api?.executeArray ? api.executeArray(url, 'GET') : null;
};

const LegalEntityTypeData = {
    getLegalEntityTypeData
};

export default LegalEntityTypeData;