import Checkbox from "../../../../common/components/checkboxes/Checkbox";

import SGColumnHeader from "../../../../common/components/grids/SGColumnHeader";

import { formatDate } from "../../../../common/utils/DateFunctions";
import useSortableGrid from "../../../../common/utils/UseSortableGrid";
import SGConstants from "../../../../common/utils/SGConstants";

import global from '../../../../common/components/GlobalStyle.module.css';

const LargeGridRow = ({ offering, onCheckboxClicked }) => (
  <tr>
    <td>{offering.offeringName || ''}</td>
    <td>{offering.registrationStartDate ? formatDate(offering.registrationStartDate) : ''}</td>
    <td>{offering.registrationEndDate ? formatDate(offering.registrationEndDate) : ''}</td>
    <td>
      {onCheckboxClicked && (offering.isEditable === true || offering.isOfferedByClub === false)
        ? (
          <Checkbox
            label=""
            name="isOfferedByClub"
            onChange={() => { onCheckboxClicked(offering) }}
            checked={offering.isOfferedByClub} />
        )
        : offering.isOfferedByClub === true
          ? <b>Yes</b>
          : <b>No</b>
      }
    </td>
  </tr>
);

const ClubManageOfferingsGridLarge = ({ gridData, isLoading, onCheckboxClicked }) => {
  const {
    sortableGridState,
    onColumnClicked
  } = useSortableGrid(gridData);

  return (
    <table className={[global.UsasTable, 'visible-md visible-lg'].join(' ')}>
      <thead>
        <tr>
          <SGColumnHeader sortableGridState={sortableGridState} columnLabel={'Offering'} columnField={'offeringName'}
            sortType={SGConstants.SORT_TYPE_ALPHABETICAL} onColumnClicked={onColumnClicked} />
          <SGColumnHeader sortableGridState={sortableGridState} columnLabel={'Registration Start Date'} columnField={'registrationStartDate'}
            sortType={SGConstants.SORT_TYPE_DATE} onColumnClicked={onColumnClicked} />
          <SGColumnHeader sortableGridState={sortableGridState} columnLabel={'Registration End Date'} columnField={'registrationEndDate'}
            sortType={SGConstants.SORT_TYPE_DATE} onColumnClicked={onColumnClicked} />
          <SGColumnHeader sortableGridState={sortableGridState} columnLabel={'Offered By Club?'} columnField={'isOfferedByClub'}
            sortType={SGConstants.SORT_TYPE_NUMERICAL} onColumnClicked={onColumnClicked} />
        </tr>
      </thead>
      <tbody>
        {isLoading === true
          ? <tr><td colSpan="4">Loading...</td></tr>
          : sortableGridState.sortedGridData.length > 0
            ? sortableGridState.sortedGridData.map((offering, i) => <LargeGridRow key={i} offering={offering} onCheckboxClicked={onCheckboxClicked} />)
            : <tr><td colSpan="4">No Offerings</td></tr>
        }
      </tbody>
    </table>
  );
};

export default ClubManageOfferingsGridLarge;