import React from 'react';

import global from '../../GlobalStyle.module.css';

const GridRow = ({ staff, roleName }) => (
  <div className={global.SmallTableRow}>
    <div className={global.SmallTableRowHead}>
      <span className='hidden-xs'>{staff.firstName} {staff.lastName}</span>&nbsp;
    </div>
    <div className={global.SmallTableRowInfo}>
      <div className='row'>
        <div className={['col-xs-6 col-sm-3 visible-xs', global.SmallTableRowLabels].join(' ')}>{roleName} Name</div>
        <div className={['col-xs-6 col-sm-3 visible-xs', global.SmallTableRowData].join(' ')}>{staff.firstName} {staff.lastName}</div>
        <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>Member Id</div>
        <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>{staff.memberId || <span>&nbsp;</span>}</div>
        <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>Is In Good Standing?</div>
        <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>{staff.isInGoodStanding === true ? 'Yes' : 'No'}</div>
      </div>
    </div>
  </div>
);

const StaffRoleReportGridSmall = ({ data, isLoading, roleName = 'Staff' }) => (
  <div className={[global.SmallTable, 'hidden-md hidden-lg'].join(' ')}>
    {isLoading === true ? (
      <div className={global.SmallTableRow}>
        <div className={global.SmallTableRowHead}>&nbsp;</div>
        <div className={global.SmallTableRowInfo}>
          <div className={global.SmallTableRowLabels}>Loading...</div>
        </div>
      </div>
    ) : Array.isArray(data) && data.length > 0 ?
      data.map((staff, i) => <GridRow key={i} staff={staff} roleName={roleName} />)
      : (
        <div className={global.SmallTableRow}>
          <div className={global.SmallTableRowHead}>&nbsp;</div>
          <div className={global.SmallTableRowInfo}>
            <div className={global.SmallTableRowLabels}>No {roleName}</div>
          </div>
        </div>
      )
    }
  </div>
);

export default StaffRoleReportGridSmall;