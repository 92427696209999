import React, {createContext, useState} from 'react';

import GroupFacilitiesData from './GroupFacilitiesData';

export const GroupFacilitiesStateContext = createContext();

const GroupFacilitiesContextProvider = ({children}) => {
  const stateHook = useState(GroupFacilitiesData.INITIAL_STATE);

  return (
    <GroupFacilitiesStateContext.Provider value={stateHook}>
      {children}
    </GroupFacilitiesStateContext.Provider>
  );
};

export default GroupFacilitiesContextProvider;