export const COACH_ROLE_NAME = 'Coach';
export const JUNIOR_COACH_ROLE_NAME = 'Junior Coach';
export const PROVISIONAL_COACH_ROLE_NAME = 'Provisional Coach';
export const ATHLETE_ROLE_NAME = 'Athlete';

const ClubConstants = {
  COACH_ROLE_NAME,
  JUNIOR_COACH_ROLE_NAME,
  PROVISIONAL_COACH_ROLE_NAME,
  ATHLETE_ROLE_NAME
};

export default ClubConstants;