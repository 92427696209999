import { useEffect, useState } from 'react';
import { useNavigate } from '../../../../common/wrappers/ReactRouterDom';

import NavLinks from '../NavLinks';

import PreCompRegistrationConstants from '../utils/PreCompRegistrationConstants';

import validate from '../../preComp/components/forms/PreCompOrganizationInsuranceFormValidation';

import { createOrgObjFromInsuranceForm } from '../../preComp/utils/PreCompUtils';
import PreCompConstants from '../../preComp/utils/PreCompConstants';

import usePreCompLocationRegistrationNavData from '../../../state/preCompLocationRegistration/preCompLocationRegistrationLeftNavBar/UsePreCompLocationRegistrationNavData';
import usePreCompOrganizationRegistrationData from '../../../state/preCompOrganizationRegistration/UsePreCompOrganizationRegistrationData';
import usePreCompFilesInsuranceVerificationData from '../../../state/preCompFilesInsuranceVerification/UsePreCompFilesInsuranceVerificationData';
import usePreCompPolicyTypeData from '../../../state/preCompPolicyType/UsePreCompPolicyTypeData';

import UseForm from '../../../../common/utils/UseForm';
import Constants from '../../../../common/utils/Constants';
import { formatDate } from '../../../../common/utils/DateFunctions';

const FILE_TYPE_ERROR_MSG = 'File type not permitted. Please provide a (*.pdf), (*.doc), or (*.docx) file.';

const usePreCompRegistrationOrganizationInsurance = () => {
  const navigate = useNavigate();
  const { locationRegistrationNavState, updateCurrentNavState } = usePreCompLocationRegistrationNavData();
  const { preCompPolicyTypeState } = usePreCompPolicyTypeData();
  const { preCompOrganizationRegistrationState, saveObjData, confirmSave } = usePreCompOrganizationRegistrationData();
  const { preCompFilesInsuranceVerificationState, postPreCompFilesInsuranceVerification,
    GET_PRE_COMP_FILES_INSURANCE_VERIFICATION_PATH, getFileNameFromUrl } = usePreCompFilesInsuranceVerificationData();
  const { formState, errorState, isSubmitting, handleSubmit, updateFormState, setFormData,
    setFormState, setErrors, setIsDirty } = UseForm(getInitialFormState, submitFormCallback, validate);
  const [continueState, setContinueState] = useState(getInitialContinueState());
  const [state, setState] = useState(getInitialState());

  const onContinueClicked = () => {
    setContinueState({
      ...continueState,
      destination: NavLinks.PRE_COMP_REGISTRATION_LOCATION_INFO
    });
    handleSubmit();
  };

  const onBackClicked = () => {
    setContinueState({
      ...continueState,
      destination: NavLinks.PRE_COMP_REGISTRATION_ORGANIZATION_CONTACT
    });
    handleSubmit();
  };

  const onUploadVerification = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    setErrors({ ...errorState, insuranceValidationUrl: '' });
    if (e.target.files[0]) {
      const selectedFile = e.target.files[0];
      const fileType = e.target.files[0].type;
      if (fileType === Constants.FILE_TYPE_PDF || fileType === Constants.FILE_TYPE_DOC ||
        fileType === Constants.FILE_TYPE_DOCX) {
        updateFormState('verificationFile', selectedFile);
      }
      else {
        setErrors({ ...errorState, insuranceValidationUrl: FILE_TYPE_ERROR_MSG });
      }
    }
  };

  const onRemoveVerification = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    setFormState({
      ...formState,
      verificationFile: {},
      insuranceValidationUrl: '',
      insuranceValidationName: ''
    });
    setIsDirty(true);
  };

  const onDownloadUploadedFile = (event) => {
    event.preventDefault();
    const fileUrl = formState.insuranceValidationUrl;
    window.location.href = import.meta.env.VITE_PRECOMP_URL + GET_PRE_COMP_FILES_INSURANCE_VERIFICATION_PATH + fileUrl;
  };

  useEffect(() => {
    updateCurrentNavState(PreCompRegistrationConstants.ORG_INSURANCE);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (preCompOrganizationRegistrationState.isObjLoaded === true) {
      let newFormState = {};
      let newState = {};

      if (Array.isArray(preCompOrganizationRegistrationState.objData.insuranceProvider)
        && preCompOrganizationRegistrationState.objData.insuranceProvider.length > 0) {
        const targetProvider = preCompOrganizationRegistrationState.objData.insuranceProvider[0];

        if (targetProvider) {
          newFormState.insuranceProviderName = targetProvider.insuranceProviderName || '';
          newFormState.isSelfInsured = targetProvider.isSelfInsured ?? '';
          newState.isProviderEdit = true;

          if (Array.isArray(targetProvider.insuranceProviderPolicy)
            && targetProvider.insuranceProviderPolicy.length > 0) {
            const targetPolicy = targetProvider.insuranceProviderPolicy[0];

            if (targetPolicy) {
              newFormState.policyNumber = targetPolicy.policyNumber || '';
              newFormState.policyTypeId = targetPolicy.policyTypeId || Constants.DEFAULT_ID;
              newFormState.policyTypeName = targetPolicy.policyType?.typeName || '';
              newFormState.effectiveDate = targetPolicy.effectiveDate
                ? formatDate(targetPolicy.effectiveDate)
                : Constants.BLANK_DATE_STRING;
              newFormState.expirationDate = targetPolicy.expirationDate
                ? formatDate(targetPolicy.expirationDate)
                : Constants.BLANK_DATE_STRING;
              newFormState.occurrenceLimit = targetPolicy.occurrenceLimit || '';
              newFormState.aggregateLimit = targetPolicy.aggregateLimit || '';
              newFormState.insuranceValidationUrl = targetPolicy.insuranceValidationUrl || '';
              newFormState.insuranceValidationName = getFileNameFromUrl(targetPolicy.insuranceValidationUrl) || '';
              newState.isPolicyEdit = true;
            }
          }
        }
      }

      setFormData({
        ...formState,
        ...newFormState
      }, true);
      setState({
        ...state,
        ...newState
      });
    } else {
      navigate(NavLinks.PRE_COMP_REGISTRATION_ENTRY);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [preCompOrganizationRegistrationState.isObjLoaded]);

  useEffect(() => {
    if (preCompOrganizationRegistrationState.isSaved === true && isSubmitting === false) {
      confirmSave();
      updateCurrentNavState(PreCompRegistrationConstants.ORG_INSURANCE, true);

      setContinueState({
        ...continueState,
        tryRedirect: true
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [preCompOrganizationRegistrationState.isSaved, isSubmitting]);

  useEffect(() => {
    if (preCompFilesInsuranceVerificationState.isSaving === false && preCompFilesInsuranceVerificationState.isSaved === true) {
      const policyTypeId = preCompPolicyTypeState.options.find(x => x.name === PreCompConstants.POLICY_TYPE_NAME_GENERAL_LIABILITY)?.id;

      if (policyTypeId) {
        saveObjData(createOrgObjFromInsuranceForm(preCompOrganizationRegistrationState.objData, formState, preCompFilesInsuranceVerificationState.objData.uploadUrl,
          state.isProviderEdit === true ? 0 : -1, state.isPolicyEdit === true ? 0 : -1, policyTypeId));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [preCompFilesInsuranceVerificationState]);

  useEffect(() => {
    if (continueState.tryRedirect === true) {
      if (continueState.destination !== '') {
        navigate(continueState.destination);
      } else {
        setContinueState(getInitialContinueState);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [continueState.tryRedirect]);

  function submitFormCallback(formState) {
    if (formState.verificationFile.size > 0) {
      postPreCompFilesInsuranceVerification(formState.verificationFile);
    } else {
      const policyTypeId = preCompPolicyTypeState.options.find(x => x.name === PreCompConstants.POLICY_TYPE_NAME_GENERAL_LIABILITY)?.id;

      if (policyTypeId) {
        saveObjData(createOrgObjFromInsuranceForm(preCompOrganizationRegistrationState.objData, formState, formState.insuranceValidationUrl,
          state.isProviderEdit === true ? 0 : -1, state.isPolicyEdit === true ? 0 : -1, policyTypeId));
      }
    }
  };

  function getInitialFormState() {
    return {
      insuranceProviderName: '',
      policyNumber: '',
      policyTypeId: Constants.DEFAULT_ID,
      policyTypeName: '',
      effectiveDate: Constants.BLANK_DATE_STRING,
      expirationDate: Constants.BLANK_DATE_STRING,
      occurrenceLimit: '',
      aggregateLimit: '',
      insuranceValidationUrl: '',
      insuranceValidationName: '',
      isSelfInsured: '',
      verificationFile: {}
    };
  };

  function getInitialContinueState() {
    return {
      tryRedirect: false,
      destination: ''
    };
  };

  function getInitialState() {
    return {
      isProviderEdit: false,
      isPolicyEdit: false
    };
  };

  return {
    isSaving: preCompFilesInsuranceVerificationState.isSaving,
    isLoading: preCompPolicyTypeState.isArrayLoading,
    preCompOrganizationRegistrationState,
    locationRegistrationNavState,
    formState,
    errorState,
    onBackClicked,
    onContinueClicked,
    onUploadVerification,
    onRemoveVerification,
    onDownloadUploadedFile,
    handleSubmit,
    onFormValueChanged: updateFormState
  };
};

export default usePreCompRegistrationOrganizationInsurance;