import React, { createContext, useState } from 'react';

import PreCompAffiliationTypeData from './PreCompAffiliationTypeData';

export const PreCompAffiliationTypeStateContext = createContext();

const PreCompAffiliationTypeContextProvider = ({ children }) => {
  const stateHook = useState(PreCompAffiliationTypeData.INITIAL_STATE);

  return (
    <PreCompAffiliationTypeStateContext.Provider value={stateHook}>
      {children}
    </PreCompAffiliationTypeStateContext.Provider>
  );
};

export default PreCompAffiliationTypeContextProvider;