import React, { Fragment } from 'react';

import useLearnToSwimStaffEdit from './UseLearnToSwimStaffEdit';

import ProviderStaffForm from '../components/forms/ProviderStaffForm';

import PopUpModal from '../../../../common/components/dialogs/PopUpModal';
import Headings from '../../../../common/components/headings/Headings';
import SecondaryButton from '../../../../common/components/buttons/SecondaryButton';
import PrimaryButton from '../../../../common/components/buttons/PrimaryButton';

import Constants from '../../../../common/utils/Constants';

const LearnToSwimStaffEdit = () => {
  const {
    isSaving,
    isLoading,
    existingPersonState,
    primaryContactModalState,
    displayPrimaryContactErrorModalState,
    onSubmitFormCallback,
    onCancelClicked,
    onPrimaryContactModalContinueClicked,
    onPrimaryContactModalCancelClicked,
    onPrimaryContactErrorModalCancelClicked,
    LTS_STAFF_ROLE_GROUP_ID
  } = useLearnToSwimStaffEdit();

  return (
    <Fragment>
      <div className='row'>
        <div className='col-xs-12 usas-extra-top-margin'>
          <Headings.H3>Staff Edit</Headings.H3>
        </div>
      </div>
      <ProviderStaffForm
        onSubmitFormCallback={onSubmitFormCallback}
        onSecondaryButtonClicked={onCancelClicked}
        staffObj={existingPersonState}
        roleGroupId={LTS_STAFF_ROLE_GROUP_ID} />
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.SAVING_MSG}
        displayPopUp={isSaving} />
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={isLoading} />
      <PopUpModal
        widthPct={90}
        maxWidth={1020}
        title={'Primary Contact Error'}
        displayPopUp={displayPrimaryContactErrorModalState === true}
        onModalCanceled={onPrimaryContactErrorModalCancelClicked}>
        <div className="row">
          <div className="col-xs-12">
            <p>Cannot directly remove Primary Contact role from {existingPersonState.firstName} {existingPersonState.lastName}. To remove the Primary Contact role, assign the role to a different staff member.</p>
          </div>
        </div>
        <div className="row usas-extra-top-margin">
          <div className="col-xs-12">
            <SecondaryButton type="button" onClick={onPrimaryContactErrorModalCancelClicked}>Cancel</SecondaryButton>
          </div>
        </div>
      </PopUpModal>
      <PopUpModal
        widthPct={90}
        maxWidth={1020}
        title={'Replace Current Primary Contact?'}
        displayPopUp={primaryContactModalState.displayModal}
        onModalCanceled={onPrimaryContactModalCancelClicked}>
        <div className="row">
          <div className="col-xs-12">
            <p>Assigning {existingPersonState.firstName} {existingPersonState.lastName} the Primary Contact role will remove it from {primaryContactModalState.currentPrimaryContact?.firstName} {primaryContactModalState.currentPrimaryContact?.lastName}.</p>
            {primaryContactModalState.isOnlyRole === true && <p><b>{primaryContactModalState.currentPrimaryContact?.firstName} {primaryContactModalState.currentPrimaryContact?.lastName}'s only role is Primary Contact. Replacing them as Primary Contact will remove them from this provider's staff.</b></p>}
          </div>
        </div>
        <div className="row usas-extra-top-margin">
          <div className="col-xs-12">
            <PrimaryButton type="button" onClick={onPrimaryContactModalContinueClicked}>Yes, Replace Primary Contact</PrimaryButton>&nbsp;
            <SecondaryButton type="button" onClick={onPrimaryContactModalCancelClicked}>Cancel</SecondaryButton>
          </div>
        </div>
      </PopUpModal>
    </Fragment>
  );
};

export default LearnToSwimStaffEdit;