import { useEffect } from 'react';
import { useNavigate } from '../../../../common/wrappers/ReactRouterDom';

import NavLinks from '../NavLinks';

import usePreCompLocationData from '../../../state/preCompLocation/UsePreCompLocationData';
import useUsasClubPreCompLinksData from '../../../state/clubPreCompLinks/UseUsasClubPreCompLinksData';

import useSearchPreComp from '../../../../common/components/searches/searchPreComp/UseSearchPreComp';

import useBaseUsasClubData from '../../../../common/state/clubBase/UseBaseUsasClubData';

const useClubPreCompetitiveProgramsSearch = () => {
  const navigate = useNavigate();
  const { searchPreCompState, setSearchPreCompState, onFiltersToggle } = useSearchPreComp();
  const { preCompLocationState } = usePreCompLocationData();
  const { baseUsasClubState } = useBaseUsasClubData();
  const { usasClubPreCompLinksState, postUsasClubPreCompLinks } = useUsasClubPreCompLinksData();

  const onAdd = (e, locationId) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    };

    setSearchPreCompState({ ...searchPreCompState, tryRedirect: true });
    postUsasClubPreCompLinks(baseUsasClubState.objData.usasClubId, locationId);
  };

  const onCancelClicked = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    navigate(NavLinks.CLUB_PRECOMPETITIVE);
  };

  useEffect(() => {
    if (usasClubPreCompLinksState.isSaved === true && searchPreCompState.tryRedirect === true) {
      setSearchPreCompState({ ...searchPreCompState, tryRedirect: false });
      navigate(NavLinks.CLUB_PRECOMPETITIVE, { state: { tryGet: true } });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [usasClubPreCompLinksState, searchPreCompState]);

  return {
    usasClubPreCompLinksState,
    searchPreCompState,
    preCompLocationState,
    baseUsasClubState,
    onFiltersToggle,
    onAdd,
    onCancelClicked
  };
};

export default useClubPreCompetitiveProgramsSearch;