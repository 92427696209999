import React, { createContext, useState } from 'react';

import UsasClubStaffData from './UsasClubStaffData';

export const UsasClubStaffStateContext = createContext();

const UsasClubStaffContextProvider = ({ children }) => {
  const stateHook = useState(UsasClubStaffData.INITIAL_STATE);

  return (
    <UsasClubStaffStateContext.Provider value={stateHook}>
      {children}
    </UsasClubStaffStateContext.Provider>
  );
};

export default UsasClubStaffContextProvider;