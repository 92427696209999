import React, { Fragment } from 'react';

import useStatesCombobox from '../../../../../common/components/comboboxes/statesCombobox/UseStatesCombobox';
import ReadOnly from '../../../../../common/components/readOnly/ReadOnly';

const PreCompLocationFacilityReadOnlyInfo = ({ facilityObj }) => {
  const { getStateNameById } = useStatesCombobox();

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12 col-sm-6 col-md-4">
          <ReadOnly
            label="Facility Name"
            name="facilityName"
            value={facilityObj?.facilityName || ''} />
        </div>
        <div className="col-xs-12 col-sm-6 col-md-4">
          <ReadOnly
            label="Address Line 1"
            name="address1"
            value={facilityObj?.address1 || ''} />
        </div>
        <div className="col-xs-12 col-sm-6 col-md-4">
          <ReadOnly
            label="Address Line 2"
            name="address2"
            value={facilityObj?.address2 || ''} />
        </div>
        <div className="col-xs-12 col-sm-6 col-md-4">
          <ReadOnly
            label="City"
            name="city"
            value={facilityObj?.city || ''} />
        </div>
        <div className="col-xs-12 col-sm-6 col-md-4">
          <ReadOnly
            label="State"
            name="stateCode"
            value={getStateNameById(facilityObj?.stateCode) || ''} />
        </div>
        <div className="col-xs-12 col-sm-6 col-md-4">
          <ReadOnly
            label="Zip Code"
            name="postalCode"
            value={facilityObj?.postalCode || ''} />
        </div>
      </div>
    </Fragment>
  );
};

export default PreCompLocationFacilityReadOnlyInfo;