import { useContext } from 'react';

import PreCompLocationRegistrationNavData from './PreCompLocationRegistrationNavData';
import { PreCompLocationRegistrationNavStateContext } from './PreCompLocationRegistrationNavContextProvider';
import PreCompRegistrationConstants from '../../../views/preCompRegistration/utils/PreCompRegistrationConstants';

const usePreCompLocationRegistrationNavData = () => {
  const [locationRegistrationNavState, setLocationRegistrationNavState] = useContext(PreCompLocationRegistrationNavStateContext);

  const updateCurrentNavState = (currentNavName, isComplete = undefined) => {
    const navState = locationRegistrationNavState;

    for (const navItem of navState) {
      if (navItem.name === currentNavName) {
        navItem.isCurrent = true;
        navItem.isVisited = true;

        if (isComplete !== undefined) {
          navItem.isComplete = isComplete;
        }
      } else {
        navItem.isCurrent = false;
      }
    }

    if (isComplete !== undefined) {
      const submitNavItem = navState.find(x => x.name === PreCompRegistrationConstants.SUBMIT);

      if (submitNavItem) {
        submitNavItem.arePrerequisitesDone = canAccessSubmit(navState);
      }
    }

    setLocationRegistrationNavState(navState);
  };

  const resetNavState = () => {
    setLocationRegistrationNavState([]);
  };

  const setupNavState = (includeOrg) => {
    if (includeOrg === true) {
      setLocationRegistrationNavState([
        ...JSON.parse(JSON.stringify(PreCompLocationRegistrationNavData.INITIAL_ORG_NAV_STATE)),
        ...JSON.parse(JSON.stringify(PreCompLocationRegistrationNavData.INITIAL_LOC_NAV_STATE)),
      ]);
    } else {
      setLocationRegistrationNavState([
        ...JSON.parse(JSON.stringify(PreCompLocationRegistrationNavData.INITIAL_LOC_NAV_STATE))
      ]);
    }
  };

  const canAccessSubmit = (navState = undefined) => {
    if (navState === undefined) {
      navState = locationRegistrationNavState;
    }

    for (const navItem of navState) {
      if (navItem.isComplete === false && navItem.name !== PreCompRegistrationConstants.SUBMIT) {
        return false;
      }
    }

    return true;
  };

  return {
    locationRegistrationNavState,
    updateCurrentNavState,
    resetNavState,
    setupNavState,
    canAccessSubmit
  };
};

export default usePreCompLocationRegistrationNavData;