import useSisenseShowAllTimesDropdown from "./UseSisenseShowAllTimesDropdown";

import Dropdown from '../../../../common/components/dropdowns/Dropdown';

import Constants from "../../../../common/utils/Constants";

import global from '../../../../common/components/GlobalStyle.module.css';

const SisenseShowAllTimesDropdown = ({ label, name, value, error, message, onChange }) => {
  const { showAllTimesOptions } = useSisenseShowAllTimesDropdown();

  return showAllTimesOptions.options?.length === 0
    ? <span className={global.LoadingMsg}>{Constants.LOADING_MSG}</span>
    : (
      <Dropdown
        error={error}
        label={label}
        message={message}
        options={showAllTimesOptions.options}
        name={name}
        value={value}
        onChange={onChange}
        isLoading={showAllTimesOptions.options?.length === 0}
      />
    );
};

export default SisenseShowAllTimesDropdown;