import { useEffect, useState } from "react";
import { useNavigate } from "../../../../common/wrappers/ReactRouterDom";

import usePreCompFilesEmergencyPlansData from "../../../state/preCompFilesEmergencyPlans/UsePreCompFilesEmergencyPlansData";
import usePreCompLocationRegistrationNavData from "../../../state/preCompLocationRegistration/preCompLocationRegistrationLeftNavBar/UsePreCompLocationRegistrationNavData";
import usePreCompLocationRegistrationData from "../../../state/preCompLocationRegistration/UsePreCompLocationRegistrationData";

import Constants from "../../../../common/utils/Constants";
import PreCompRegistrationConstants from "../utils/PreCompRegistrationConstants";
import NavLinks from "../NavLinks";

const FILE_TYPE_ERROR_MSG = 'File type not permitted. Please provide a (*.pdf), (*.doc), or (*.docx) file.';
const REQUIRED_ERROR_MSG = 'Emergency Plan file is required';
const UNABLE_TO_SUBMIT_ERROR_MSG = 'Must complete all required registration steps before continuing to the submit page';

const usePreCompRegistrationLocationEmergencyPlan = () => {
  const navigate = useNavigate();
  const { preCompLocationRegistrationState, saveObjData, confirmSave } = usePreCompLocationRegistrationData();
  const { locationRegistrationNavState, updateCurrentNavState, canAccessSubmit } = usePreCompLocationRegistrationNavData();
  const { preCompFilesEmergencyPlansState, GET_PRE_COMP_FILES_EMERGENCY_PLANS_PATH, 
    postPreCompFilesEmergencyPlans, getFileNameFromUrl, resetPreCompFilesEmergencyPlansState } = usePreCompFilesEmergencyPlansData();
  const [fileState, setFileState] = useState(getInitialFileState);
  const [displayModalState, setDisplayModalState] = useState(false);
  const [pageErrorMessageState, setPageErrorMessageState] = useState('');

  const onContinueClicked = () => {
    if (preCompLocationRegistrationState.objData.emergencyPlanUrl) {
      if (canAccessSubmit() === true) {
        navigate(NavLinks.PRE_COMP_REGISTRATION_SUBMIT);
      } else {
        setPageErrorMessageState(UNABLE_TO_SUBMIT_ERROR_MSG);
      }
    } else {
      setPageErrorMessageState(REQUIRED_ERROR_MSG);
    }
  };

  const onBackClicked = () => {
    if (preCompLocationRegistrationState.objData.emergencyPlanUrl) {
      navigate(NavLinks.PRE_COMP_REGISTRATION_LOCATION_FACILITY);
    } else {
      setPageErrorMessageState(REQUIRED_ERROR_MSG);
    }
  };

  const onModalRemoveClicked = () => {
    saveObjData(createLocObj(preCompLocationRegistrationState.objData, ''));
    setDisplayModalState(false);
  };

  const onModalBackClicked = () => {
    setDisplayModalState(false);
  };

  const onRemoveFile = () => {
    setDisplayModalState(true);
  };

  const onUploadEmergencyFile = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    if (e.target.files[0]) {
      const selectedFile = e.target.files[0];

      if (selectedFile.type === Constants.FILE_TYPE_PDF
        || selectedFile.type === Constants.FILE_TYPE_DOC
        || selectedFile.type === Constants.FILE_TYPE_DOCX) {
        postPreCompFilesEmergencyPlans(selectedFile, `${selectedFile.name}`.replace(' ', ''));
      } else {
        setFileState({
          ...fileState,
          fileError: true,
          errorMessage: FILE_TYPE_ERROR_MSG
        });
      }

    }
  };

  const onDownloadUploadedFile = () => {
    if (preCompLocationRegistrationState.objData.emergencyPlanUrl) {
      const fileUrl = preCompLocationRegistrationState.objData.emergencyPlanUrl;
      window.location.href = import.meta.env.VITE_PRECOMP_URL + GET_PRE_COMP_FILES_EMERGENCY_PLANS_PATH + fileUrl;
    }
  };

  useEffect(() => {
    if (preCompLocationRegistrationState.objData.emergencyPlanUrl) {
      updateCurrentNavState(PreCompRegistrationConstants.LOC_EMERGENCY_PLAN, true);
    } else {
      updateCurrentNavState(PreCompRegistrationConstants.LOC_EMERGENCY_PLAN, false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (preCompLocationRegistrationState.isSaved === true) {
      confirmSave();
      setPageErrorMessageState('');

      if (preCompLocationRegistrationState.objData.emergencyPlanUrl) {
        updateCurrentNavState(PreCompRegistrationConstants.LOC_EMERGENCY_PLAN, true);
      } else {
        updateCurrentNavState(PreCompRegistrationConstants.LOC_EMERGENCY_PLAN, false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [preCompLocationRegistrationState.isSaved]);

  useEffect(() => {
    if (preCompLocationRegistrationState.isObjLoaded === true && preCompLocationRegistrationState.isSaved === false) {
      setFileState({
        ...fileState,
        fileUrl: preCompLocationRegistrationState.objData.emergencyPlanUrl || '',
        fileName: getFileNameFromUrl(preCompLocationRegistrationState.objData.emergencyPlanUrl) || '',
        fileError: false,
        errorMessage: ''
      });
    } else if (preCompLocationRegistrationState.isObjLoaded === false) {
      navigate(NavLinks.PRE_COMP_REGISTRATION_ENTRY);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [preCompLocationRegistrationState]);

  useEffect(() => {
    if (preCompFilesEmergencyPlansState.isSaving === false && preCompFilesEmergencyPlansState.isSaved === true) {
      saveObjData(createLocObj(preCompLocationRegistrationState.objData, preCompFilesEmergencyPlansState.objData.uploadUrl));
      resetPreCompFilesEmergencyPlansState();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [preCompFilesEmergencyPlansState]);

  function getInitialFileState() {
    return {
      fileUrl: '',
      fileName: '',
      fileError: false,
      errorMessage: ''
    };
  };

  function createLocObj(locObj, emergencyPlanUrl) {
    return {
      ...locObj,
      emergencyPlanUrl
    };
  };

  return {
    isSaving: preCompFilesEmergencyPlansState.isSaving,
    locationRegistrationNavState,
    fileState,
    displayModalState,
    pageErrorMessageState,
    onContinueClicked,
    onBackClicked,
    onDownloadUploadedFile,
    onRemoveFile,
    onUploadEmergencyFile,
    onModalRemoveClicked,
    onModalBackClicked
  };
};

export default usePreCompRegistrationLocationEmergencyPlan;