import GroupInfoFormValidation from "../../../../../common/components/forms/groups/GroupInfoFormValidation";
import ClubSiteAttributesFormValidation from "../../../../../common/components/forms/groups/ClubSiteAttributesFormValidation";

const ClubGroupTypeSiteValidation = async (formState) => {
  let errors = {
    ...GroupInfoFormValidation(formState),
    ...ClubSiteAttributesFormValidation(formState)
  };

  return errors ? errors : {};
};

export default ClubGroupTypeSiteValidation;