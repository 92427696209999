import { BASIC_INITIAL_STATE } from '../../utils/HttpHelper';

import searchPreCompOrganizationData from './SearchPreCompOrganizationData';
import getPreCompOrganizationData from './GetPreCompOrganizationData';
import getPreCompOrganizationByOrgUnitIdData from './GetPreCompOrganizationByOrgUnitIdData';
import getPreCompOrganizationByLocationOrgUnitIdData from './GetPreCompOrganizationByLocationOrgUnitIdData';
import putPreCompOrganizationData from './PutPreCompOrganizationData';

const INITIAL_STATE = {
  ...BASIC_INITIAL_STATE
};

const PreCompOrganizationData = {
  INITIAL_STATE,
  searchPreCompOrganizationData,
  getPreCompOrganizationData,
  getPreCompOrganizationByOrgUnitIdData,
  getPreCompOrganizationByLocationOrgUnitIdData,
  putPreCompOrganizationData
};

export default PreCompOrganizationData;
