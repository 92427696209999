import React, { Fragment } from 'react';

import ClubManageOfferingsGrid from './ClubManageOfferingsGrid';

import useClubManageOfferings from './UseClubManageOfferings';

import Headings from '../../../../common/components/headings/Headings';
import PopUpModal from '../../../../common/components/dialogs/PopUpModal';
import ReportPeriodDropdown from '../../../../common/components/dropdowns/reportPeriodDropdown/ReportPeriodDropdown';

import Constants from '../../../../common/utils/Constants';

const ClubManageOfferings = () => {
  const {
    isLoading,
    isSaving,
    isGridLoading,
    gridState,
    formState,
    errorState,
    onValueTextPairChanged,
    onCheckboxClicked,
    USAS_ORGANIZATION_ID
  } = useClubManageOfferings();

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>Manage Offerings</Headings.H3>
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12 col-sm-6 col-md-4">
          {USAS_ORGANIZATION_ID > 0 &&
            <ReportPeriodDropdown
              label="Report Period"
              name="reportPeriodId"
              value={formState.reportPeriodId}
              error={errorState.reportPeriodId}
              message={errorState.reportPeriodId}
              onChange={(value, valueLabel) => onValueTextPairChanged(value, 'reportPeriodId', valueLabel, 'reportPeriodLabel')}
              organizationId={USAS_ORGANIZATION_ID} />
          }
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12 usas-extra-top-margin usas-extra-bottom-margin">
          <ClubManageOfferingsGrid
            gridData={gridState}
            isLoading={isGridLoading}
            onCheckboxClicked={onCheckboxClicked} />
        </div>
      </div>
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={isLoading} />
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.SAVING_MSG}
        displayPopUp={isSaving} />
    </Fragment>
  );
};

export default ClubManageOfferings;