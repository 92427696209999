export const PROVIDER_INFO_NAME = 'PROVIDER_INFO';
export const FACILITIES_NAME = 'FACILITIES';
export const SUBMIT_NAME = 'SUBMIT';

const LearnToSwimRegistrationLeftNavConstants = {
  PROVIDER_INFO_NAME,
  FACILITIES_NAME,
  SUBMIT_NAME
};

export default LearnToSwimRegistrationLeftNavConstants;