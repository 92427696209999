import { useContext, useEffect } from 'react';

import { ClubAffiliationTypeStateContext } from './ClubAffiliationTypeContextProvider';

import ClubAffiliationTypeData from './ClubAffiliationTypeData';

const useClubAffiliationTypeData = () => {
  const [clubAffiliationTypeState, setClubAffiliationTypeState] = useContext(ClubAffiliationTypeStateContext);

  const getClubAffiliationTypes = () => {ClubAffiliationTypeData.getClubAffiliationTypes(clubAffiliationTypeState, setClubAffiliationTypeState)}

  useEffect(() => {
    if (clubAffiliationTypeState.isArrayLoading !== true && clubAffiliationTypeState.isArrayLoaded !== true) {
      ClubAffiliationTypeData.getClubAffiliationTypes(clubAffiliationTypeState, setClubAffiliationTypeState);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (clubAffiliationTypeState.isArrayLoaded === true && clubAffiliationTypeState.isOptionsLoaded !== true) {
      const newState = {
        ...clubAffiliationTypeState,
        options: clubAffiliationTypeState.arrayData.map((affiliation) => { return { id: affiliation.clubAffiliationTypeId, name: affiliation.typeName } }),
        isOptionsLoaded: true
      };
      setClubAffiliationTypeState(newState);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clubAffiliationTypeState.isArrayLoaded, clubAffiliationTypeState.isOptionsLoaded]);

  return {
    clubAffiliationTypeState,
    getClubAffiliationTypes
  };
};

export default useClubAffiliationTypeData;