import { RecHttpHelper, NO_DATA_MESSAGE } from '../../../common/utils/HttpHelper';

const PostKpiMeasureData = (programLevelInstanceId, categoryMeasureId, submittedValue, kpiUrl, state, setState) => {
  if (state.isArrayLoading !== true && !state.message && programLevelInstanceId > 0 && categoryMeasureId > 0 && kpiUrl) {
    let newState = {
      ...state,
      isArrayLoading: true,
      isArrayLoaded: false,
      message: 'Loading...'
    };
    setState(newState);

    const payload = {
      programLevelInstanceId,
      categoryMeasureId,
      submittedValue
    };
    const url = `${kpiUrl}`;
    RecHttpHelper(url, 'POST', payload)
      .then((arrayData) => {
        if (!arrayData) {
          throw new Error(NO_DATA_MESSAGE);
        } else {
          setState({
            ...newState,
            isArrayLoading: false,
            isArrayLoaded: true,
            arrayData,
            message: ''
          });
        }
      })
      .catch((e) => {
        const message = e && e.message ? e.message : NO_DATA_MESSAGE;
        setState({
          ...newState,
          isArrayLoading: false,
          isArrayLoaded: false,
          message: message
        });
      });
  };
};

export default PostKpiMeasureData;