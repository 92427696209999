export const ORG_INFO = 'ORG_INFO';
export const ORG_CONTACT = 'ORG_CONTACT';
export const ORG_INSURANCE = 'ORG_INSURANCE';
export const LOC_INFO = 'LOC_INFO';
export const LOC_PROGRAMS = 'LOC_PROGRAMS';
export const LOC_STAFF = 'LOC_STAFF';
export const LOC_INSTRUCTOR_TRAINING = 'LOC_INSTRUCTOR_TRAINING';
export const LOC_CLUB = 'LOC_CLUB';
export const LOC_FACILITY = 'LOC_FACILITY';
export const LOC_EMERGENCY_PLAN = 'LOC_EMERGENCY_PLAN';
export const SUBMIT = 'SUBMIT';
export const EXIT_REGISTRATION = 'Exit Registration?';
export const LEFT_NAV_TITLE = 'Pre-Competitive Registration';

const PreCompRegistrationConstants = {
  ORG_INFO,
  ORG_CONTACT,
  ORG_INSURANCE,
  LOC_INFO,
  LOC_PROGRAMS,
  LOC_STAFF,
  LOC_INSTRUCTOR_TRAINING,
  LOC_CLUB,
  LOC_FACILITY,
  LOC_EMERGENCY_PLAN,
  SUBMIT,
  EXIT_REGISTRATION,
  LEFT_NAV_TITLE
};

export default PreCompRegistrationConstants;