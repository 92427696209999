import React, { Fragment } from 'react';

import Input from '../../inputs/Input';
import Textarea from '../../inputs/Textarea';
import ReadOnly from '../../readOnly/ReadOnly';
import GroupCategoriesTreeView from '../../tree/groupCategoriesTree/GroupCategoriesTreeView';

import global from '../../GlobalStyle.module.css'

const GroupInfoForm = ({ formState, errorState, onFormValueChanged, orgUnitId, attributeForm = <Fragment /> }) => (
  <Fragment>
    <div className='row'>
      <div className="col-xs-12 col-sm-6 col-md-4">
        <ReadOnly
          label="Group Type"
          name="groupTypeId"
          value={formState.groupTypeName} />
      </div>
      <div className="col-xs-12 col-sm-6 col-md-4">
        <GroupCategoriesTreeView
          label="Group Category*"
          name="groupCategories"
          value={formState.groupCategories}
          error={errorState.groupCategories}
          message={errorState.groupCategories}
          selectableLeavesOnly={true}
          singleSelect={true}
          onChange={(value) => { onFormValueChanged('groupCategories', value) }}
          orgUnitId={orgUnitId} />
      </div>
      <div className="col-xs-12 col-sm-6 col-md-4">
        <Input
          label="Group Name*"
          name="groupName"
          value={formState.groupName}
          error={errorState.groupName}
          message={errorState.groupName}
          onChange={(value) => { onFormValueChanged('groupName', value) }} />
      </div>
      <div className="col-xs-12 col-sm-6 col-md-4">
        <Input
          label="Group Code*"
          name="groupCode"
          value={formState.groupCode}
          error={errorState.groupCode}
          message={errorState.groupCode}
          onChange={(value) => { onFormValueChanged('groupCode', value) }} />
      </div>
    </div>
    {attributeForm}
    <div className='row'>
      <div className="col-xs-12">
        <Textarea
          className={global.Textarea}
          label="Group Description*"
          name="groupDescription"
          value={formState.groupDescription}
          error={errorState.groupDescription}
          message={errorState.groupDescription}
          onChange={(value) => { onFormValueChanged('groupDescription', value) }} />
      </div>
    </div>
  </Fragment>
);

export default GroupInfoForm;