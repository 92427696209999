import { PersonHttpHelper, NO_DATA_MESSAGE } from '../../../../common/utils/HttpHelper';
import { isValidDate, isValidMemberId } from '../../../../common/utils/validation';

import Constants from "../../../../common/utils/Constants";

const localValidate = (formState) => {
  let errors = {};
  const startDate = new Date(formState.startDate);
  const endDate = new Date(formState.endDate);

  //Meet Name
  if (formState.meetName.trim() === '') {
    errors.meetName = 'Meet Name is required';
  }
  else if ((formState.autogeneratedMeetYear + ' ' + formState.meetName.trim()).toString().length > 200) {
    errors.meetName = 'Meet Name cannot exceed 200 characters';
  }

  //Start Date
  if (formState.startDate === Constants.BLANK_DATE_STRING) {
    errors.startDate = 'Start Date is required';
  } else if (!isValidDate(formState.startDate)) {
    errors.startDate = 'Start Date must be a valid date';
  }
  else if (endDate < startDate && formState.startDate !== formState.endDate) {
    errors.startDate = 'Start Date must be before the End Date';
  }

  //End Date
  if (formState.endDate === Constants.BLANK_DATE_STRING) {
    errors.endDate = 'End Date is required';
  } else if (!isValidDate(formState.endDate)) {
    errors.endDate = 'End Date must be a valid date';
  }
  else if (endDate < startDate && formState.startDate !== formState.endDate) {
    errors.endDate = 'End Date must be after the Start Date';
  }

  if (formState.hasPaid === false) {
    //Meet Classification
    if (formState.meetClassification.length === 0) {
      errors.meetClassification = 'Meet Classification is required';
    }

    //Meet Course
    if (formState.meetCourse.length === 0) {
      errors.meetCourse = "Must pick at least one Course";
    }

    //Meet Software
    if (formState.meetSoftwareId <= 0) {
      errors.meetSoftwareId = 'Meet Management Software is required';
    }

    //Facility
    if (formState.facilityId <= 0) {
      errors.facilityId = 'Facility is required';
    }

    //Meet Referee
    if (formState.meetStaffMemberId === null || formState.meetStaffMemberId.trim() === '') {
      errors.meetStaffMemberId = 'Meet Staff Member Id is required';
    } else if (!isValidMemberId(formState.meetStaffMemberId.trim())) {
      errors.meetStaffMemberId = 'Meet Staff Member Id must be 14 alphanumeric characters';
    }
  }

  return errors;
}

const serverValidate = async (formState) => {
  let errors = {};

  if (formState.meetClassification.length > 0) {
    if (formState.meetClassification[0].id === formState.BLOCK_PARTY_OTS_APPROVED_MEET_CLASSIFICATION_ID) {
      //Meet Referee
      const meetRefereeMemberIdForUrl = formState.meetStaffMemberId ? encodeURIComponent(formState.meetStaffMemberId) : 'NaN';
      await PersonHttpHelper(`/Person/flat/memberId/${meetRefereeMemberIdForUrl}`, 'GET')
        .then((objData) => {
          if (!objData) {
            throw new Error(NO_DATA_MESSAGE);
          }
          else {
            if (objData.isOrgRoleDurationActive === false || !(objData.personId)) {
              errors.meetStaffMemberId = "Meet Referee Member Id does not have an active member registration";
            } else {
              formState.meetStaffMemberId = objData.memberId;
            }
          }
        })
        .catch(() => {
          errors.meetStaffMemberId = 'Meet Referee Member Id is not valid';
        });

      const meetRefereePersonIdForUrl = formState.meetStaffMemberId ? encodeURIComponent(formState.meetStaffMemberId) : 'NaN';
      const roleGroupIdForUrl = formState.OFFICIALS_ROLES_ROLE_GROUP_ID ? encodeURI(formState.OFFICIALS_ROLES_ROLE_GROUP_ID) : 'NaN';
      await PersonHttpHelper(`/Person/member/${meetRefereePersonIdForUrl}/hasActiveRegistration?roleGroupId=${roleGroupIdForUrl}`, 'GET')
        .then((objData) => {
          if (!objData) {
            throw new Error(NO_DATA_MESSAGE);
          } else {
            if (objData.hasActiveRegistration === false) {
              errors.meetStaffMemberId = "The Meet Referee Member Id does not have an active official registration";
            }
          }
        })
        .catch(() => {
          errors.meetStaffMemberId = "Meet Referee Member Id does not have an active official registration";
        });
    } else if (formState.meetClassification[0].id === formState.BLOCK_PARTY_NON_OTS_APPROVED_MEET_CLASSIFICATION_ID) {
      //Meet Contact
      const meetContactMemberIdForUrl = formState.meetStaffMemberId ? encodeURIComponent(formState.meetStaffMemberId) : 'NaN';
      await PersonHttpHelper(`/Person/flat/memberId/${meetContactMemberIdForUrl}`, 'GET')
        .then((objData) => {
          if (!objData) {
            throw new Error(NO_DATA_MESSAGE);
          }
          else {
            if (objData.isOrgRoleDurationActive === false || !(objData.personId)) {
              errors.meetStaffMemberId = "Meet Contact Member Id does not have an active member registration";
            } else {
              formState.meetStaffMemberId = objData.memberId;
            }
          }
        })
        .catch(() => {
          errors.meetStaffMemberId = 'Meet Contact Member Id is not valid';
        });
    }
  }

  return errors;
}

const ClubBlockPartyMeetAddValidation = async (formState) => {
  let errors = localValidate(formState);
  if (!errors || Object.keys(errors).length === 0 && formState.hasPaid === false) {
    errors = await serverValidate(formState);
  }

  return errors ? errors : {};
};

export default ClubBlockPartyMeetAddValidation;