import { useEffect, useState } from 'react';
import { useNavigate, useLocation } from '../../../../common/wrappers/ReactRouterDom';

import NavLinks from '../NavLinks';

import { createSATContractObj } from '../utils/SATUtils';

import validate from '../components/forms/SATContractFormValidation';

import useSwimAThonData from '../../../state/swimAThon/UseSwimAThonData';
import useSwimAThonContractData from '../../../state/swimAThon/swimAThonContract/UseSwimAThonContractData';

import useStatesCombobox from '../../../../common/components/comboboxes/statesCombobox/UseStatesCombobox';
import useEnvironmentVariableData from '../../../../common/state/environmentVariable/UseEnvironmentVariableData';

import useForm from '../../../../common/utils/UseForm';
import Constants from '../../../../common/utils/Constants';
import { formatDate } from "../../../../common/utils/DateFunctions";

const INITIAL_VALIDATE_STATE = {
  isValidating: false,
  validatedAddress: null
};

const useClubSwimAThonContractDetail = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { 
    environmentVariableState, 
    SAT_APPROVED_STATUS_ID, 
    SAT_SUBMITTED_STATUS_ID, 
    SAT_SAVED_STATUS_ID, 
    SAT_CANCELED_STATUS_ID, 
    SAT_PAID_STATUS_ID,
    SAT_FULFILLED_STATUS_ID } = useEnvironmentVariableData();
  const { satState } = useSwimAThonData();
  const { swimAThonContractState, postSwimAThonContract, putSwimAThonContract } = useSwimAThonContractData();
  const { getStateNameById } = useStatesCombobox();
  const { formState, errorState, updateFormState, handleSubmit, setFormData, onValueTextPairChanged, setIsDirty } 
    = useForm(getFormInitialState(), submitFormCallback, validate);
  const [uiState, setUiState] = useState({ tryRedirect: false });
  const [validateState, setValidateState] = useState(INITIAL_VALIDATE_STATE);
  const [contractModalState, setContractModalState] = useState(getInitialContractModalState());
  const [cancelContractModalState, setCancelContractModalState] = useState(getInitialCancelContractModalState());

  const handleSubmitProxy = async (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    setValidateState({
      isValidating: true
    });
    await handleSubmit();
    setValidateState({
      isValidating: false,
      validatedAddress: formState.validatedAddress
    });
    updateFormState('showedAddressSuggestion', true);
  };

  const onContinueSaveClicked = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    
    if (location.state?.satContract) {
      const statusId = formState.fulfillmentDate === Constants.BLANK_DATE_STRING ? SAT_APPROVED_STATUS_ID : SAT_FULFILLED_STATUS_ID;

      putSwimAThonContract(location.state.satContract.satContractId,
        createSATContractObj(formState, satState.orgUnitId, statusId, location.state.satContract));
    } else {
      postSwimAThonContract(createSATContractObj(formState, satState.orgUnitId, SAT_APPROVED_STATUS_ID));
    }

    setContractModalState(getInitialContractModalState());
    setUiState({ ...uiState, tryRedirect: true });
  };

  const onContinueCancelContractClicked = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    putSwimAThonContract(location.state.satContract.satContractId,
      createSATContractObj(formState, satState.orgUnitId, SAT_CANCELED_STATUS_ID, location.state.satContract));

    setUiState({ ...uiState, tryRedirect: true });
  }

  const onCancelClicked = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    navigate(NavLinks.CLUB_SWIMATHON_CONTRACTS, { state: { performGet: false } });
  };

  const onContractModalCanceled = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    setIsDirty(true);
    setContractModalState(getInitialContractModalState());
  };

  const onCancelContractModelCanceled = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    setIsDirty(true);
    setCancelContractModalState(getInitialCancelContractModalState());
  }

  const onCancelContract = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    setCancelContractModalState({
      ...cancelContractModalState,
      displayPopup: true,
      contractDate: formState.satDate
    });
  }

  useEffect(() => {
    if (swimAThonContractState.isSaved === true && uiState.tryRedirect === true) {
      navigate(NavLinks.CLUB_SWIMATHON_CONTRACTS, { state: { performGet: true } });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [swimAThonContractState, uiState.tryRedirect]);

  useEffect(() => {
    if (location.state?.satContract) {
      const selectedContract = location.state.satContract;


      if (selectedContract) {
        setFormData({
          ...formState,
          contactName: selectedContract.satContact[0].contactName,
          contactEmail: selectedContract.satContact[0].email,
          contactPhoneNumber: selectedContract.satContact[0].phoneNumber,
          address1: selectedContract.satAddress[0].address1,
          address2: selectedContract.satAddress[0].address2 || '',
          city: selectedContract.satAddress[0].city,
          stateCode: selectedContract.satAddress[0].state,
          stateName: selectedContract.satAddress[0] ? getStateNameById(selectedContract.satAddress[0].state) : '',
          postalCode: selectedContract.satAddress[0].zip,
          satDate: selectedContract.satDate ? formatDate(selectedContract.satDate) : Constants.BLANK_DATE_STRING,
          fundraisingGoal: selectedContract.fundraisingGoal,
          numberOfAthletes: selectedContract.numberOfAthletes,
          specialInstructions: selectedContract.specialInstructions,
          contractStatusId: selectedContract.contractStatusId,
          signature: selectedContract.signature,
          signatureDate: selectedContract.signatureDate ? formatDate(selectedContract.signatureDate) : Constants.BLANK_DATE_STRING,
          fulfillmentDate: selectedContract.fulfillmentDate ? formatDate(selectedContract.fulfillmentDate) : Constants.BLANK_DATE_STRING,
          resultStatusId: selectedContract.satResult[0]?.resultStatusId || Constants.DEFAULT_ID
        }, true);
      } else {
        setFormData({
          ...formState,
          signatureDate: formatDate(new Date()),
        }, true);
      }
    } else {
      setFormData({
        ...formState,
        signatureDate: formatDate(new Date()),
      }, true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function submitFormCallback() {
    setContractModalState({
      ...contractModalState,
      displayPopup: true,
      contractDate: formState.satDate
    });
  };

  function getFormInitialState() {
    return {
      contactName: '',
      contactEmail: '',
      contactPhoneNumber: '',
      address1: '',
      address2: '',
      city: '',
      stateCode: '',
      stateName: '',
      postalCode: '',
      satDate: Constants.BLANK_DATE_STRING,
      fundraisingGoal: '',
      numberOfAthletes: '',
      specialInstructions: '',
      contractStatusId: Constants.DEFAULT_ID,
      statusDate: Constants.BLANK_DATE_STRING,
      signature: '',
      signatureDate: Constants.BLANK_DATE_STRING,
      fulfillmentDate: Constants.BLANK_DATE_STRING,
      validatedAddress: null,
      showedAddressSuggestion: false,
      resultStatusId: Constants.DEFAULT_ID
    };
  };

  function getInitialContractModalState() {
    return {
      modalTitle: 'Submit this Contract?',
      displayPopup: false,
      contractDate: ''
    };
  };

  function getInitialCancelContractModalState() {
    return {
      modalTitle: 'Cancel this Contract?',
      displayPopup: false,
      contractDate: ''
    }
  }

  return {
    satState,
    isSubmitted: (location.state?.satContract?.contractStatusId === SAT_SAVED_STATUS_ID || location.state?.satContract?.contractStatusId === undefined) ? false : true,
    isEdit: location.state?.satContract ? true : false,
    isSaving: swimAThonContractState.isSaving,
    isLoading: environmentVariableState.isLoading,
    formState,
    errorState,
    validateState,
    contractModalState,
    cancelContractModalState,
    onFormValueChanged: updateFormState,
    onCancelClicked,
    onValueTextPairChanged,
    onContractModalCanceled,
    onContinueSaveClicked,
    onContinueCancelContractClicked,
    onCancelContractModelCanceled,
    onCancelContract,
    handleSubmitProxy,
    setFormData,
    submmitedStatusId: SAT_SUBMITTED_STATUS_ID,
    paidStatusId: SAT_PAID_STATUS_ID
  };
};

export default useClubSwimAThonContractDetail;