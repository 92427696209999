import React, { Fragment } from 'react';

import useClubAthleteRoster from './UseClubAthleteRoster';

import ClubAthleteRosterForm from './ClubAthleteRosterForm';
import ClubAthleteRosterGrid from './ClubAthleteRosterGrid';

import Headings from '../../../../common/components/headings/Headings';
import PopUpModal from '../../../../common/components/dialogs/PopUpModal';

import Constants from '../../../../common/utils/Constants';

const ClubAthleteRoster = () => {
  const {
    isLoading,
    state,
    clubAthleteRosterState,
    clubRosterVendorSyncState,
    vendorSyncState,
    onSubmitFormCallback,
    onEditAthlete,
    onVendorSyncCheckbox,
    onSyncSelectedMembers,
    clearVendorSync,
    orgUnitId,
    orgUnitCode,
    orgUnitName,
    parentOrgUnitCode,
    clubCity,
    clubStateCode, 
    clubAddress1,
    clubPostalCode
  } = useClubAthleteRoster();

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>Athlete Roster</Headings.H3>
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12">
        {clubRosterVendorSyncState.isSaved === true && <p style={{fontSize: 24, color: 'slategray'}}>{clubRosterVendorSyncState.objData.toString()} Athletes Synced</p>} 
        </div>
      </div>
      <div className='row usas-extra-bottom-margin'>
        <div className="col-xs-12">
          <ClubAthleteRosterForm
            onSubmitFormCallback={onSubmitFormCallback}
            onSyncSelectedMembers={onSyncSelectedMembers}
            orgUnitId={orgUnitId}
            PRACTICE_GROUP_TYPE_ID={state.practiceGroupTypeId}
            SITE_GROUP_TYPE_ID={state.siteGroupTypeId}
            clubRosterArray={clubAthleteRosterState.arrayData}
            orgUnitCode={orgUnitCode}
            orgUnitName={orgUnitName}
            parentOrgUnitCode={parentOrgUnitCode}
            clubCity={clubCity}
            clubStateCode={clubStateCode}
            clubAddress1={clubAddress1}
            clubPostalCode={clubPostalCode}
            clearVendorSync={clearVendorSync}
            vendorSyncState={vendorSyncState}
            />
        </div>
      </div>
      <div className='row usas-extra-top-margin usas-extra-bottom-margin'>
        <div className="col-xs-12">
          <ClubAthleteRosterGrid
            gridData={clubAthleteRosterState.arrayData}
            isLoading={clubAthleteRosterState.isArrayLoading}
            onEditAthlete={onEditAthlete} 
            onVendorSyncCheckbox={onVendorSyncCheckbox}
            />
        </div>
      </div>
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={isLoading} />
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.SAVING_MSG}
        displayPopUp={clubRosterVendorSyncState.isSaving} />
    </Fragment>
  );
};

export default ClubAthleteRoster;