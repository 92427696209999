import { BASIC_INITIAL_STATE } from '../../../common/utils/HttpHelper';

import getMemberRegistrationRenewalSelectionData from './GetMemberRegistrationRenewalSelectionData';
import postMemberRegistrationRenewalSelectionData from './PostMemberRegistrationRenewalSelectionData';

const INITIAL_STATE = {
  ...BASIC_INITIAL_STATE
};

const MemberRegistrationRenewalSelectionData = {
  INITIAL_STATE, getMemberRegistrationRenewalSelectionData, postMemberRegistrationRenewalSelectionData
}

export default MemberRegistrationRenewalSelectionData;