import { useEffect, useState } from 'react';
import { useNavigate, useLocation } from '../../../../../common/wrappers/ReactRouterDom';

import validate from './AddLSCRoleEmailValidation';

import { navLinks } from '../../UseMemberAdministration';

import MemberAdministrationConstants from '../../utils/MemberAdministrationConstants';

import useMemberAdministrationLscEmailData from '../../../../../common/state/memberAdministration/lscEmail/UseMemberAdministrationLscEmailData';
import useEnvironmentVariableData from '../../../../../common/state/environmentVariable/UseEnvironmentVariableData';
import useOrgRoleStaffRoleHierarchyData from '../../../../../common/state/orgRoleStaffRoleHierarchy/UseOrgRoleStaffRoleHierarchyData';

import UseForm from '../../../../../common/utils/UseForm';
import Constants from '../../../../../common/utils/Constants';

const UseAddLSCRoleEmail = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { LSC_EMAIL_ROLE_GROUP_ID } = useEnvironmentVariableData();
  const { memberAdministrationLscRoleEmailsState, postLscRoleEmail, putLscRoleEmail } = useMemberAdministrationLscEmailData();
  const { orgRoleStaffRoleHierarchyState } = useOrgRoleStaffRoleHierarchyData();
  const { formState, errorState, setIsDirty, handleSubmit, updateFormState } = 
    UseForm(getInitialFormState, submitFormCallback, validate);
  const [viewState, setViewState] = useState(getInitialViewState);
  const [modalState, setModalState] = useState(getInitialModalState);

  const onCancelClicked = () => {
    navigate(navLinks.LSC_EMAILS);
  };

  const onModalConfirm = () => {
    putLscRoleEmail(modalState.orgUnitOrgRoleFieldId,
      { orgUnitOrgRoleFieldId: modalState.orgUnitOrgRoleFieldId, ...createOrgRoleFieldObj(viewState, formState) });
  };

  const onModalCancel = () => {
    setModalState(getInitialModalState);
    setIsDirty(true);
  };

  useEffect(() => {
    if (location.state?.activeUserOrgUnitId) {
      setViewState({
        ...viewState,
        activeUserOrgUnitId: location.state.activeUserOrgUnitId,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (memberAdministrationLscRoleEmailsState.isSaved === true) {
      navigate(navLinks.LSC_EMAILS);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [memberAdministrationLscRoleEmailsState.isSaved]);

  function submitFormCallback(formState) {
    
    const matchingRole = memberAdministrationLscRoleEmailsState.arrayData.find(x => x.orgRoleId === formState.orgRole[0].id);

    if (matchingRole) {
      setModalState({
        ...modalState,
        currentEmail: matchingRole.email,
        newEmail: formState.email,
        orgRoleName: matchingRole.orgRoleName,
        orgUnitOrgRoleFieldId: matchingRole.orgUnitOrgRoleFieldId,
        displayModal: true
      });
    } else {
      postLscRoleEmail(createOrgRoleFieldObj(viewState, formState));
    }
  };

  function getOrgRoleFieldId(orgRoleId, arrayData) {
    for (const roleType of arrayData) {
      for (const role of roleType.children) {
        if (role.id === orgRoleId && Array.isArray(role.orgRoleField)) {
          for (const roleField of role.orgRoleField) {
            if (roleField.fieldName === MemberAdministrationConstants.ORG_ROLE_FIELD_NAME_EMAIL) { 
              return roleField.orgRoleFieldId;
            }
          }
        }
      }
    }
  };

  function createOrgRoleFieldObj(viewState, formState) {
    return {
      orgRoleFieldId: getOrgRoleFieldId(formState.orgRole[0].id, orgRoleStaffRoleHierarchyState.arrayData),
      orgUnitId: viewState.activeUserOrgUnitId,
      fieldValue: formState.email
    };
  };

  function getInitialFormState() {
    return {
      orgRole: [],
      orgRoleName: '',
      orgRoleId: '',
      email: ''
    };
  };

  function getInitialViewState() {
    return {
      activeUserOrgUnitId: ''
    };
  };

  function getInitialModalState() {
    return {
      currentEmail: '',
      newEmail: '',
      orgRoleName: '',
      orgUnitOrgRoleFieldId: Constants.DEFAULT_ID,
      displayModal: false
    };
  };

  return {
    isLoading: memberAdministrationLscRoleEmailsState.isArrayLoading,
    isSaving: memberAdministrationLscRoleEmailsState.isSaving,
    formState,
    errorState,
    viewState,
    modalState,
    onFormValueChanged: updateFormState,
    handleSubmit,
    onCancelClicked,
    onModalConfirm,
    onModalCancel,
    LSC_EMAIL_ROLE_GROUP_ID: LSC_EMAIL_ROLE_GROUP_ID || Constants.DEFAULT_ID
  };
};

export default UseAddLSCRoleEmail;