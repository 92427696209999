
import React, { Fragment } from 'react';

import useOrgUnitSelection from './UseOrgUnitSelection';

import SelectOrgUnit from '../../../../common/components/selectOrgUnit/SelectOrgUnit';

const OrgUnitSelection = () => {
  const { NavLinks, TAXONOMIES, SCOPE, getSATBySelection, SWIMATHON_ROLE_GROUP_ID } = useOrgUnitSelection();

  return (
    <Fragment>
      <SelectOrgUnit
        pageTitle={'Club Swim-A-Thon'}
        navLink={NavLinks.CLUB_SWIMATHON_CONTRACTS}
        roleGroupId={SWIMATHON_ROLE_GROUP_ID}
        getFunction={getSATBySelection}
        TAXONOMIES={TAXONOMIES}
        SCOPE={SCOPE} />
    </Fragment>
  );
};

export default OrgUnitSelection;