import { useContext } from 'react';

import MemberAdministrationLscSecurityRolesData from './MemberAdministrationLscSecurityRolesData';
import { MemberAdministrationLscSecurityRolesStateContext } from './MemberAdministrationLscSecurityRolesContextProvider';

const useMemberAdministrationLscSecurityRolesData = () => {
  const [memberAdministrationLscSecurityRolesState, setMemberAdministrationLscSecurityRolesState] = useContext(MemberAdministrationLscSecurityRolesStateContext);

  const getLscSecurityMembers = (orgUnitId) => MemberAdministrationLscSecurityRolesData.getLscSecurityMembers(orgUnitId, memberAdministrationLscSecurityRolesState, setMemberAdministrationLscSecurityRolesState);
  const postLscSecurityMember = (orgUnitId, roleGroupId, staffObj) => MemberAdministrationLscSecurityRolesData.postLscSecurityMember(orgUnitId, roleGroupId, staffObj, memberAdministrationLscSecurityRolesState, setMemberAdministrationLscSecurityRolesState);
  const putLscSecurityMember = (orgUnitId, personId, roleGroupId, staffObj) => MemberAdministrationLscSecurityRolesData.putLscSecurityMember(orgUnitId, personId, roleGroupId, staffObj, memberAdministrationLscSecurityRolesState, setMemberAdministrationLscSecurityRolesState);
  const deleteLscSecurityMember = (personOrgRoleDurationId) => MemberAdministrationLscSecurityRolesData.deleteLscSecurityMember(personOrgRoleDurationId, memberAdministrationLscSecurityRolesState, setMemberAdministrationLscSecurityRolesState);

  const confirmSave = () => {
    setMemberAdministrationLscSecurityRolesState({
      ...memberAdministrationLscSecurityRolesState,
      isSaved: false
    });
  };

  const clearLscSecurityMembersArrayData = () => {
    setMemberAdministrationLscSecurityRolesState({
      ...memberAdministrationLscSecurityRolesState,
      isArrayLoading: false,
      isArrayLoaded: false,
      isSaving: false,
      isSaved: false,
      arrayData: []
    });
  }


  return {
    memberAdministrationLscSecurityRolesState,
    getLscSecurityMembers,
    postLscSecurityMember,
    putLscSecurityMember,
    deleteLscSecurityMember,
    confirmSave,
    clearLscSecurityMembersArrayData
  };
};

export default useMemberAdministrationLscSecurityRolesData;