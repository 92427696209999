import React, { Fragment } from 'react';

import PreCompLocationClubGridLarge from './PreCompLocationClubGridLarge';
import PreCompLocationClubGridSmall from './PreCompLocationClubGridSmall';

const PreCompLocationClubGrid = ({ data, isLoading, onDelete }) => (
  <Fragment>
    <PreCompLocationClubGridLarge data={data} isLoading={isLoading} onDelete={onDelete} />
    <PreCompLocationClubGridSmall data={data} isLoading={isLoading} onDelete={onDelete} />
  </Fragment>
);

export default PreCompLocationClubGrid;