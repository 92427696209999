import { useEffect, useState } from 'react';
import { useNavigate } from '../../../../common/wrappers/ReactRouterDom';

import NavLinks from '../NavLinks';

import { createSATContractResultObj } from '../utils/SATUtils';

import useSwimAThonResultData from '../../../state/swimAThon/swimAThonResult/UseSwimAThonResultData';
import useSwimAThonContractData from '../../../state/swimAThon/swimAThonContract/UseSwimAThonContractData';

import useEnvironmentVariableData from '../../../../common/state/environmentVariable/UseEnvironmentVariableData';

import { formatDate } from '../../../../common/utils/DateFunctions';
import Constants from '../../../../common/utils/Constants';

const useClubSwimAThonParticipant = () => {
  const navigate = useNavigate();
  const { environmentVariableState, SAT_SUBMITTED_STATUS_ID, SAT_SAVED_STATUS_ID } = useEnvironmentVariableData();
  const { swimAThonContractState, putSwimAThonContract } = useSwimAThonContractData();
  const { swimAThonResultState, updateResultObj } = useSwimAThonResultData();
  const [modalState, setModalState] = useState(getInitialModalState())
  const [uiState, setUiState] = useState({ tryECommerceRedirect: false, tryRedirect: false });

  const onSaveClicked = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    const newSatContract = createSATContractResultObj(swimAThonResultState.satContract, JSON.parse(JSON.stringify(swimAThonResultState.objData)), SAT_SAVED_STATUS_ID);

    putSwimAThonContract(newSatContract.satContractId, newSatContract);
    setUiState({ ...uiState, tryRedirect: true });
  };

  const onSubmitClicked = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    setModalState({
      ...modalState,
      displayPopup: true,
      contractDate: swimAThonResultState.satContract.satDate
        ? formatDate(swimAThonResultState.satContract.satDate)
        : Constants.BLANK_DATE_STRING
    });
  };

  const onBackClicked = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    navigate(NavLinks.CLUB_SWIMATHON_RESULTS_DETAIL);
  };

  const onAddParticipant = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    navigate(NavLinks.CLUB_SWIMATHON_RESULTS_DETAIL_PRIZEWINNERS_DETAIL);
  };

  const onEditParticipant = (e, satParticipantId) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    navigate(NavLinks.CLUB_SWIMATHON_RESULTS_DETAIL_PRIZEWINNERS_DETAIL, { state: { satParticipantId } });
  }

  const onDeleteParticipant = (e, satParticipantId) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    const newSatResultObj = JSON.parse(JSON.stringify(swimAThonResultState.objData));
    newSatResultObj.satParticipant = swimAThonResultState.objData.satParticipant.filter(x => x.satParticipantId !== satParticipantId);

    updateResultObj(newSatResultObj);
  };

  const onModalSubmitClicked = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    const newSatContract = createSATContractResultObj(swimAThonResultState.satContract, JSON.parse(JSON.stringify(swimAThonResultState.objData)), SAT_SUBMITTED_STATUS_ID);

    putSwimAThonContract(newSatContract.satContractId, newSatContract);
    setModalState(getInitialModalState());
    setUiState({ ...uiState, tryECommerceRedirect: true });
  };

  const onModalCancelClicked = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    setModalState(getInitialModalState());
  };

  useEffect(() => {
    if (swimAThonContractState.isSaved === true && uiState.tryRedirect === true) {
      navigate(NavLinks.CLUB_SWIMATHON_RESULTS, { state: { performGet: true } });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [swimAThonContractState, uiState.tryRedirect]);

  useEffect(() => {
    if (swimAThonContractState.isSaved === true && uiState.tryECommerceRedirect === true) {
      navigate(NavLinks.CLUB_SWIMATHON_RESULTS_PAYMENTS, { state: { performGet: true } });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [swimAThonContractState, uiState.tryECommerceRedirect]);

  function getInitialModalState() {
    return {
      modalTitle: 'Submit Results?',
      displayPopup: false,
      contractDate: ''
    };
  };

  return {
    isLoading: environmentVariableState.isLoading,
    isSaving: swimAThonContractState.isSaving,
    swimAThonResultState,
    modalState,
    onSaveClicked,
    onSubmitClicked,
    onBackClicked,
    onAddParticipant,
    onEditParticipant,
    onDeleteParticipant,
    onModalSubmitClicked,
    onModalCancelClicked
  };
};

export default useClubSwimAThonParticipant
