import { isValidDate } from '../../../../common/utils/validation';
import Constants from '../../../../common/utils/Constants';

export const localValidate = (formState) => {
  let errors = {};

  if (formState.clbmS101 === true) {
    if (formState.clbmS101CompletionDate === Constants.BLANK_DATE_STRING) {
      errors.clbmS101CompletionDate = 'CLMBS 101 Completion Date is required';
    } else if (!isValidDate(formState.clbmS101CompletionDate)) {
      errors.clbmS101CompletionDate = 'CLMBS 101 Completion Date must be a valid date';
    }
  }

  if (formState.clbmS201 === true) {
    if (formState.clbmS201CompletionDate === Constants.BLANK_DATE_STRING) {
      errors.clbmS201CompletionDate = 'CLMBS 201 Completion Date is required';
    } else if (!isValidDate(formState.clbmS201CompletionDate)) {
      errors.clbmS201CompletionDate = 'CLMBS 201 Completion Date must be a valid date';
    }
  }

  if ((formState.clbmS101 === null || formState.clbmS101 === false) && isValidDate(formState.clbmS101CompletionDate) === true) {
    errors.clbmS101CompletionDate = 'CLMBS 101 must be selected if the Completion Date has been entered';
  }

  if ((formState.clbmS201 === null || formState.clbmS201 === false) && isValidDate(formState.clbmS201CompletionDate) === true) {
    errors.clbmS201CompletionDate = 'CLMBS 201 must be selected if the Completion Date has been entered';
  }

  return errors;
};

const ClubPoliciesValidation = async (formState) => {
  let errors = localValidate(formState);

  return errors ? errors : {};
};

export default ClubPoliciesValidation;