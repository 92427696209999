import { useContext } from 'react';

import LegalEntityTypeData from './LegalEntityTypeData';
import { LegalEntityTypeStateContext } from './LegalEntityTypeContextProvider';

const useLegalEntityTypeData = () => {
  const [legalEntityTypeState, setLegalEntityTypeState] = useContext(LegalEntityTypeStateContext);

  const getLegalEntityTypes = () => {
    return LegalEntityTypeData.getLegalEntityTypeData(legalEntityTypeState, setLegalEntityTypeState);
  };

  return {
    legalEntityTypeState,
    setLegalEntityTypeState,
    getLegalEntityTypes,
  };
};

export default useLegalEntityTypeData;