import { BASIC_INITIAL_STATE } from '../../../common/utils/HttpHelper';

import getPreCompOrganizationContactsData from '../../../common/state/orgUnitStaff/GetOrgUnitStaffData';
import postPreCompOrganizationContactsData from '../../../common/state/orgUnitStaff/PostOrgUnitStaffData';
import putPreCompOrganizationContactsData from '../../../common/state/orgUnitStaff/PutOrgUnitStaffData';
import deletePreCompOrganizationContactsData from '../../../common/state/orgUnitStaff/DeletePersonOrgRoleDurationData';

const INITIAL_STATE = {
  ...BASIC_INITIAL_STATE
};

const PreCompOrganizationContactsData = { INITIAL_STATE, getPreCompOrganizationContactsData, postPreCompOrganizationContactsData, putPreCompOrganizationContactsData, deletePreCompOrganizationContactsData };

export default PreCompOrganizationContactsData;