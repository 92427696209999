import React, { createContext, useState } from 'react';

import ClubInactiveReasonData from './ClubInactiveReasonData';

export const ClubInactiveReasonStateContext = createContext();

const ClubInactiveReasonContextProvider = ({ children }) => {
  const stateHook = useState(ClubInactiveReasonData.INITIAL_STATE);

  return (
    <ClubInactiveReasonStateContext.Provider value={stateHook}>
      {children}
    </ClubInactiveReasonStateContext.Provider>
  );
};

export default ClubInactiveReasonContextProvider;