import React, { Fragment } from 'react';

import CoachRosterReportGridLarge from './CoachRosterReportGridLarge';
import CoachRosterReportGridSmall from './CoachRosterReportGridSmall';

const CoachRosterReportGrid = ({ data, isLoading }) => (
  <Fragment>
    <CoachRosterReportGridLarge data={data} isLoading={isLoading} />
    <CoachRosterReportGridSmall data={data} isLoading={isLoading} />
  </Fragment>
);

export default CoachRosterReportGrid;