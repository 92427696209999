import { useState, useContext } from 'react';

import LearnToSwimData from './LearnToSwimData';

import { LearnToSwimStateContext } from './LearnToSwimContextProvider';
import { LearnToSwimFilterStateContext } from './LearnToSwimFilterContextProvider';

import { BASIC_INITIAL_STATE } from '../../../common/utils/HttpHelper';

const INITIAL_LTS_PROVIDER_SEARCH_STATE = {
  ...BASIC_INITIAL_STATE
};

const useLearnToSwimData = () => {
  const [ltsProviderState, setLTSProviderState] = useContext(LearnToSwimStateContext);
  const [ltsProviderFilterState, setLTSProviderFilterState] = useContext(LearnToSwimFilterStateContext);
  const [ltsProviderSearchState, setLTSProviderSearchState] = useState(INITIAL_LTS_PROVIDER_SEARCH_STATE);

  const searchLTSProviders = (filterObject) => {
    setLTSProviderFilterState({ ...ltsProviderFilterState, filterObject });

    return LearnToSwimData.searchLTSProvidersData(JSON.stringify(filterObject), ltsProviderSearchState, setLTSProviderSearchState);
  };

  const getLTSProvider = (learnToSwimProviderId) => {
    return LearnToSwimData.getLTSProviderData(learnToSwimProviderId, ltsProviderState, setLTSProviderState);
  };

  const getLTSProviderByOrgUnitId = (orgUnitId) => {
    return LearnToSwimData.getLTSProviderOrgUnitIdData(orgUnitId, ltsProviderState, setLTSProviderState);
  };

  const putLTSProvider = (learnToSwimProviderId, providerObj) => {
    return LearnToSwimData.putLTSProviderData(learnToSwimProviderId, providerObj, ltsProviderState, setLTSProviderState);
  };

  const resetLTSProviderState = () => {
    setLTSProviderState({
      ...BASIC_INITIAL_STATE,
    });
  };

  return {
    ltsProviderState,
    ltsProviderFilterState,
    ltsProviderSearchState,
    setLTSProviderState,
    searchLTSProviders,
    getLTSProvider,
    getLTSProviderByOrgUnitId,
    putLTSProvider,
    resetLTSProviderState
  };
};

export default useLearnToSwimData;