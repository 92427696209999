import { useEffect } from 'react';

import validate from './LearnToSwimFacilityContactFormValidation';

import useStatesCombobox from '../../../../../common/components/comboboxes/statesCombobox/UseStatesCombobox';

import UseForm from "../../../../../common/utils/UseForm";

const INITIAL_FORM_STATE = {
  contactName: '',
  contactEmailAddress: '',
  contactPhoneNumber: '',
  clubFacilityContactName: '',
  clubFacilityContactEmailAddress: '',
  clubFacilityContactPhoneNumber: ''
};

const useLearnToSwimFacilityContactForm = (onSubmitFormCallback, orgUnitFacilityObj) => {
  const { getStateNameById } = useStatesCombobox();
  const { formState, errorState, handleSubmit, updateFormState, setFormData
  } = UseForm(INITIAL_FORM_STATE, onSubmitFormCallback, validate);

  useEffect(() => {
    if (orgUnitFacilityObj) {
      setFormData({
        ...formState,
        contactName: orgUnitFacilityObj.facility?.contactName || '',
        contactEmailAddress: orgUnitFacilityObj.facility?.contactEmailAddress || '',
        contactPhoneNumber: orgUnitFacilityObj.facility?.contactPhoneNumber || '',
        clubFacilityContactName: orgUnitFacilityObj.clubFacilityContactName || '',
        clubFacilityContactEmailAddress: orgUnitFacilityObj.clubFacilityContactEmailAddress || '',
        clubFacilityContactPhoneNumber: orgUnitFacilityObj.clubFacilityContactPhoneNumber || ''
      }, true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orgUnitFacilityObj]);

  return {
    formState,
    errorState,
    onFormValueChanged: updateFormState,
    handleSubmit,
    getStateNameById
  };
};

export default useLearnToSwimFacilityContactForm;