import { BASIC_INITIAL_STATE } from '../../../common/utils/HttpHelper';
import { API_NAMES, initApi } from "../../../common/utils/HttpApiHelper";

const getLTSParticipantDupData = (firstName, lastName, birthDate, state, setState) => {
  if (lastName) {
    const api = initApi(API_NAMES.COMMON, state, setState);
    const url = `/LearnToSwimParticipant/dupcheck?firstName=${firstName}&lastName=${lastName}&birthDate=${birthDate}`;

    return api?.executeObject ? api.executeObject(url, 'GET') : null;
  }
};

const postLTSParticipantData = (participantObj, state, setState) => {
  if (participantObj) {
    const api = initApi(API_NAMES.COMMON, state, setState);
    const url = `/LearnToSwimParticipant`;

    return api?.executeObject ? api.executeObject(url, 'POST', participantObj) : null;
  }
};

const LearnToSwimParticipantsData = { 
  INITIAL_STATE: BASIC_INITIAL_STATE, 
  postLTSParticipantData, 
  getLTSParticipantDupData };

export default LearnToSwimParticipantsData;
