import React, { Fragment } from 'react';

import useEditLSCStaffMember from './UseEditLSCStaffMember';

import Headings from '../../../../../common/components/headings/Headings';
import ReadOnly from '../../../../../common/components/readOnly/ReadOnly';
import PrimaryButton from '../../../../../common/components/buttons/PrimaryButton';
import SecondaryButton from '../../../../../common/components/buttons/SecondaryButton';
import Dropdown from '../../../../../common/components/dropdowns/Dropdown';
import PopUpModal from '../../../../../common/components/dialogs/PopUpModal';

import Constants from '../../../../../common/utils/Constants';

const EditLSCStaffMember = () => {
  const {
    isLoading,
    isSaving,
    formState,
    errorState,
    viewState,
    onValueTextPairChanged,
    handleSubmit,
    onCancelClicked,
    onSaveClicked,
    paidOrVolunteerOptions
  } = useEditLSCStaffMember();

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>Edit LSC Staff Role</Headings.H3>
        </div>
      </div>
      <form onSubmit={handleSubmit} noValidate>
        <div className="row">
          <div className="col-xs-12 col-sm-6 col-md-4">
            <ReadOnly
              label={"LSC Staff Role"}
              value={viewState.orgRoleName} />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4">
            <ReadOnly
              label={"Member Id"}
              value={viewState.memberId} />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4">
            <ReadOnly
              label={"Member Name"}
              value={viewState.memberName} />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4">
            <ReadOnly
              label={"Effective Date"}
              value={viewState.effectiveDate} />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4">
            <ReadOnly
              label={"Expiration Date"}
              value={viewState.expirationDate} />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4">
            <Dropdown
              label={"Paid or Volunteer?*"}
              name={"paidOrVolunteerId"}
              value={formState.paidOrVolunteerId}
              options={paidOrVolunteerOptions}
              error={errorState.paidOrVolunteerId}
              message={errorState.paidOrVolunteerId}
              isLoading={false}
              areValuesIntegers={true}
              onChange={(newValue, newName, e) => e && e.target && e.target.value
                && onValueTextPairChanged(newValue, 'paidOrVolunteerId', newName, 'paidOrVolunteerName', e)} />
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12 usas-extra-top-margin usas-extra-bottom-margin">
            <PrimaryButton type="button" onClick={onSaveClicked}>Save</PrimaryButton>&nbsp;
            <SecondaryButton type="button" onClick={onCancelClicked}>Back</SecondaryButton>
          </div>
        </div>
      </form>
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={isLoading} />
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.SAVING_MSG}
        displayPopUp={isSaving} />
    </Fragment>
  );
};

export default EditLSCStaffMember;