import React, { Fragment } from 'react';

import useClubAudit from './UseClubAudit';

import ClubAuditGrid from './ClubAuditGrid';

import Headings from '../../../../common/components/headings/Headings';
import PopUpModal from '../../../../common/components/dialogs/PopUpModal';

const ClubAudit = () => {
  const { title, Constants, clubAuditState, baseUsasClubState } = useClubAudit();

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>{title}</Headings.H3>
        </div>
      </div>
      <div className="row usas-extra-top-margin usas-extra-bottom-margin">
        <div className="col-xs-12">
          <ClubAuditGrid state={clubAuditState} />
        </div>
      </div>
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={baseUsasClubState.isObjLoading || clubAuditState.isLoading} />
    </Fragment>
  );
};

export default ClubAudit;