import { useEffect } from 'react';
import { useNavigate } from '../../../../common/wrappers/ReactRouterDom';

import NavLinks from '../NavLinks';

import useClubRegistrationNewData from '../../../state/clubRegistrationNew/UseClubRegistrationNewData';

import useFacilityData from '../../../../common/state/facility/UseFacilityData';
import useClubRegistrationNavData from '../../../state/clubRegistration/leftNavigation/UseClubRegistrationNavData';

const useClubRegistrationFacilityAdd = () => {
  const navigate = useNavigate();
  const { clubRegistrationState } = useClubRegistrationNewData();
  const { clubRegistrationNavState } = useClubRegistrationNavData();
  const { facilityState } = useFacilityData();

  useEffect(() => {
    if (clubRegistrationState.isObjLoaded === false) {
      navigate(NavLinks.CLUB_REGISTRATION_ENTRY_NEW);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    clubRegistrationState,
    clubRegistrationNavState,
    facilityState
  };
};

export default useClubRegistrationFacilityAdd;