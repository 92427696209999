import Input from '../../../../common/components/inputs/Input';
import ReadOnly from '../../../../common/components/readOnly/ReadOnly';
import Dropdown from '../../../../common/components/dropdowns/Dropdown';
import LscDropdown from '../../../../common/components/dropdowns/lscDropdown/LscDropdown';
import StatesCombobox from '../../../../common/components/comboboxes/statesCombobox/StatesCombobox';
import AddressValidation from '../../../../common/components/addressValidation/AddressValidation';

import global from '../../../../common/components/GlobalStyle.module.css';
import YesNoSwitch from '../../../../common/components/yesNoSwitch/YesNoSwitch';

const ClubRegistrationInfoWrite = ({ state, handleSubmit, formState, errorState, onValueTextPairChanged,
  onFormValueChanged, settingOptions, validateState, setFormData }) => (
  <div className={state.showPage === true ? global.DisplayComponent : global.HideComponent}>
    <form onSubmit={handleSubmit} noValidate>
      <div className="row">
        <div className="col-xs-12 col-sm-6 col-md-4">
          <Input
            label="Club Name*"
            name="clubName"
            value={formState.clubName}
            error={errorState.clubName}
            message={errorState.clubName}
            onChange={(value) => { onFormValueChanged('clubName', value) }} />
        </div>
        <div className="col-xs-12 col-sm-6 col-md-4">
          <Input
            label="Club Code*"
            name="clubCode"
            value={formState.clubCode}
            error={errorState.clubCode}
            message={errorState.clubCode}
            onChange={(value) => { onFormValueChanged('clubCode', value) }} />
        </div>
        <div className="col-xs-12 col-sm-6 col-md-4">
          <LscDropdown
            label="LSC*"
            name="lscId"
            value={formState.lscId}
            error={errorState.lscId}
            message={errorState.lscId}
            onChange={(newValue, newValueLabel, e) => { onValueTextPairChanged(newValue, 'lscId', newValueLabel, 'lscname', e); }}
          />
        </div>
        <div className="col-xs-12 col-sm-6 col-md-4">
          <Input
            label="Year Founded"
            name="yearFounded"
            value={formState.yearFounded}
            error={errorState.yearFounded}
            message={errorState.yearFounded}
            onChange={(value) => { onFormValueChanged('yearFounded', value) }} />
        </div>
        <div className="col-xs-12 col-sm-6 col-md-4">
          <ReadOnly
            label="Owner Type"
            name="ownerTypeName"
            value={formState.ownerTypeName}
          />
        </div>
        <div className="col-xs-12 col-sm-6 col-md-4">
          <ReadOnly
            label="Tax Listing"
            name="businessEntityTypeName"
            value={formState.businessEntityTypeName} />
        </div>
        <div className="col-xs-12 col-sm-6 col-md-4">
          <Dropdown
            label="Setting"
            options={settingOptions}
            name="clubSetting"
            value={formState.clubSetting}
            error={errorState.clubSetting}
            message={errorState.clubSetting}
            onChange={(value) => { onFormValueChanged('clubSetting', value) }} />
        </div>
        <div className="col-xs-12 col-sm-6 col-md-4">
          <Input
            label="Primary Mailing Club Address*"
            name="address1"
            value={formState.address1}
            error={errorState.address1}
            message={errorState.address1}
            onChange={(value) => { onFormValueChanged('address1', value) }} />
        </div>
        <div className="col-xs-12 col-sm-6 col-md-4">
          <Input
            label="Address Line 2"
            name="address2"
            value={formState.address2}
            error={errorState.address2}
            message={errorState.address2}
            onChange={(value) => { onFormValueChanged('address2', value) }} />
        </div>
        <div className="col-xs-12 col-sm-6 col-md-4">
          <Input
            label="City*"
            name="city"
            value={formState.city}
            error={errorState.city}
            message={errorState.city}
            onChange={(value) => { onFormValueChanged('city', value) }} />
        </div>
        <div className="col-xs-12 col-sm-6 col-md-4">
          <StatesCombobox
            label="State*"
            name="stateCode"
            valueToMatch={formState.stateName}
            error={errorState.stateCode}
            message={errorState.stateCode}
            isUSA={true}
            onChange={(newValue, newValueLabel, e) => { onValueTextPairChanged(newValue, 'stateCode', newValueLabel, 'stateName', e); }} />
        </div>
        <div className="col-xs-12 col-sm-6 col-md-4">
          <Input
            label="Zip Code*"
            name="postalCode"
            value={formState.postalCode}
            error={errorState.postalCode}
            message={errorState.postalCode}
            onChange={(value) => { onFormValueChanged('postalCode', value) }} />
        </div>
        <div className="col-xs-12 col-sm-12 col-md-6">
          <Input
            label="Website Address"
            name="websiteAddress"
            value={formState.websiteAddress}
            error={errorState.websiteAddress}
            message={errorState.websiteAddress}
            onChange={(value) => { onFormValueChanged('websiteAddress', value) }} />
        </div>
        <div className="col-xs-12 col-sm-12 col-md-6">
          <YesNoSwitch
              label="Include in 'Find a Team' finder"
              name="includeOnFindAteam"
              checked={formState.includeOnFindAteam}
              error={errorState.includeOnFindAteam}
              message={errorState.includeOnFindAteam}
              onChange={(value) => { onFormValueChanged('includeOnFindAteam', value); }}
            />
        </div>
        <AddressValidation formState={formState} setFormData={setFormData} />
      </div>
    </form>
  </div>
)

export default ClubRegistrationInfoWrite;