import { useState } from 'react';
import ClubRenewData from './ClubRenewData';

const useClubRenewData = () => {
  const [clubRenewState, setClubRenewState] = useState(ClubRenewData.INITIAL_STATE);

  const postClubRenew = (clubId, offeringInstanceId, registrationSignature) => {
    ClubRenewData.postClubRenew(clubId, offeringInstanceId, registrationSignature, clubRenewState, setClubRenewState);
  };

  return {
    clubRenewState,
    setClubRenewState,
    postClubRenew
  };
};

export default useClubRenewData;