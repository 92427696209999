import { useEffect } from "react";

import validate from './KpiSubmittedValueFormValidation';

import UseForm from "../../../../common/utils/UseForm";

const INITIAL_FORM_STATE = {
  submittedValue: ''
};

const useKpiSubmittedValueForm = (programLevel, categoryMeasure, onRedirect, submitFormCallback) => {
  const { formState, errorState, isDirty, updateFormState, handleSubmit, setFormData
  } = UseForm(INITIAL_FORM_STATE, submitFormCallback, validate);

  const onSaveClicked = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    if (isDirty === false) {
      onRedirect(false);
    } else {
      handleSubmit();
    }
  };

  useEffect(() => {
    if (categoryMeasure && programLevel) {
      setFormData({
        ...formState,
        submittedValue: categoryMeasure.submittedValue
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categoryMeasure, programLevel]);

  return {
    formState,
    errorState,
    onFormValueChanged: updateFormState,
    onSaveClicked
  };
};

export default useKpiSubmittedValueForm;