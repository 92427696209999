import React from "react";

import EditIcon from "../../../../common/components/icons/EditIcon";

import global from '../../../../common/components/GlobalStyle.module.css';

const GridRow = ({ roleField, onEdit }) => (
  <div className={global.SmallTableRow}>
    <div className={global.SmallTableRowHead}>
      <span className="hidden-xs">{roleField.orgRoleName || <span>&nbsp;</span>}</span>&nbsp;
      {onEdit !== undefined &&
        <button
          className={global.IconButton}
          type="button"
          onClick={() => onEdit(roleField.orgUnitOrgRoleFieldId)}>
          <EditIcon />
        </button>
      }
    </div>
    <div className={global.SmallTableRowInfo}>
      <div className="row">
        <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3 visible-xs'].join(' ')}>Role</div>
        <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3 visible-xs'].join(' ')}>{roleField.orgRoleName || <span>&nbsp;</span>}</div>
        <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3'].join(' ')}>Email</div>
        <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-9'].join(' ')}>{roleField.email || <span>&nbsp;</span>}</div>
      </div>
    </div>
  </div>
);

const LSCStaffMembersGridSmall = ({ data, isLoading, onEdit }) => (
  <div className={[global.SmallTable, 'hidden-md hidden-lg'].join(' ')}>
    {isLoading === true
      ? (
        <div className={global.SmallTableRow}>
          <div className={global.SmallTableRowHead}>&nbsp;</div>
          <div className={global.SmallTableRowInfo}>
            <div className={global.SmallTableRowLabels}>Loading...</div>
          </div>
        </div>
      )
      : data.length > 0
        ? data.map((roleField, i) => <GridRow key={i} roleField={roleField} onEdit={onEdit} />)
        : (
          <div className={global.SmallTableRow}>
            <div className={global.SmallTableRowHead}>&nbsp;</div>
            <div className={global.SmallTableRowInfo}>
              <div className={global.SmallTableRowLabels}>No LSC Role Emails</div>
            </div>
          </div>
        )
    }
  </div>
);

export default LSCStaffMembersGridSmall;