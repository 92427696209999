import Constants from '../../utils/Constants';
import { ClubHttpHelper, NO_DATA_MESSAGE } from '../../utils/HttpHelper';

const getGroupCoachesUsasClubIdData = (usasClubId, state, setState) => {
  if (state.isArrayLoading !== true && !state.message) {
    let newState = {
      ...state,
      usasClubId,
      orgUnitId: '',
      isArrayLoading: true,
      isArrayLoaded: false,
      message: 'Loading...'
    };
    setState(newState);

    const usasClubIdForUrl = usasClubId ? encodeURIComponent(usasClubId) : 'NaN';
    const url = `/UsasClub/${usasClubIdForUrl}/coaches`;
    ClubHttpHelper(url, 'GET')
      .then((arrayData) => {
        if (!arrayData) {
          throw new Error(NO_DATA_MESSAGE);
        } else {
          const dataAsOptionsWithNames = arrayData.map((coach) => {
            return { id: coach.personId, name: `${coach.firstName} ${coach.lastName}` };
          });
          dataAsOptionsWithNames.unshift({ id: Constants.DEFAULT_ID, name: '--' });

          newState = {
            ...newState,
            isArrayLoading: false,
            isArrayLoaded: true,
            arrayData,
            options: arrayData.map((coach) => {
              return { id: coach.personId, name: `${coach.firstName} ${coach.lastName}` };
            }),
            dataAsOptionsWithNames,
            message: ''
          };
          setState(newState);
        }
      })
      .catch((e) => {
        const message = e && e.message ? e.message : NO_DATA_MESSAGE;
        setState({
          ...newState,
          isArrayLoading: false,
          isArrayLoaded: false,
          message: message
        });
      });
  }
};


export default getGroupCoachesUsasClubIdData;