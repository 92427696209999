import React, { Fragment } from 'react';

import UseKpiViewClubStaffReport from './UseKpiViewClubStaffReport';

import KpiOverrideScoreForm from './KpiOverrideScoreForm';
import KpiSubmittedValueForm from './KpiSubmittedValueForm';

import Headings from '../../../../common/components/headings/Headings';
import PopUpModal from '../../../../common/components/dialogs/PopUpModal';
import SecondaryButton from '../../../../common/components/buttons/SecondaryButton';
import ClubStaffReportGrid from '../../../../common/components/grids/kpis/ClubStaffReportGrid';
import ReadOnly from '../../../../common/components/readOnly/ReadOnly';

import Constants from '../../../../common/utils/Constants';

const Question = ({ question }) => {
  return (
    <div dangerouslySetInnerHTML={{ __html: question }} />
  );
};

const KpiViewClubStaffReport = ({ programLevel, categoryMeasure, onRedirect, isReadonly, includeSubmittedValueInput = false, textInputLabel, canOverrideScore = false }) => {
  const {
    isEdit,
    isSaving,
    kpiMeasureState,
    recognitionProgramState,
    submitFormCallback,
    onSaveSubmittedValueClicked
  } = UseKpiViewClubStaffReport(programLevel, categoryMeasure, isReadonly, includeSubmittedValueInput, onRedirect);

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>{categoryMeasure?.measureName}</Headings.H3>
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12">
          {categoryMeasure?.question ?
            <Question question={categoryMeasure.question} /> : <Fragment />
          }
        </div>
      </div>
      <div className="row usas-extra-top-margin">
        {categoryMeasure.submittedValue
          && (
            <Fragment>
              {isReadonly === true &&
                <div className="col-xs-12">
                  <ReadOnly
                    label={textInputLabel}
                    name="submittedValue"
                    value={categoryMeasure.submittedValue} />
                </div>
              }
              <div className="col-xs-12">
                <ClubStaffReportGrid
                  state={kpiMeasureState} />
              </div>
            </Fragment>
          )
        }
      </div>
      {canOverrideScore === true && isEdit === true && isReadonly !== true
        ? (
          <Fragment>
            {includeSubmittedValueInput === true &&
              <KpiSubmittedValueForm
                programLevel={programLevel}
                categoryMeasure={categoryMeasure}
                inputLabel={textInputLabel}
                onRedirect={onRedirect}
                submitFormCallback={onSaveSubmittedValueClicked} />
            }
            <KpiOverrideScoreForm
              programLevel={programLevel}
              categoryMeasure={categoryMeasure}
              onRedirect={onRedirect}
              submitFormCallback={submitFormCallback} />
          </Fragment>
        ) : includeSubmittedValueInput === true && isReadonly !== true ? (
          <KpiSubmittedValueForm
            programLevel={programLevel}
            categoryMeasure={categoryMeasure}
            inputLabel={textInputLabel}
            onRedirect={onRedirect}
            submitFormCallback={onSaveSubmittedValueClicked} />
        ) : (
          <div className="row usas-extra-top-margin usas-extra-bottom-margin">
            <div className="col-xs-12 usas-extra-top-margin">
              <SecondaryButton type="button" onClick={() => onRedirect(false)}>Back</SecondaryButton>
            </div>
          </div>
        )
      }
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={kpiMeasureState.isArrayLoading || recognitionProgramState.isArrayLoading} />
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.SAVING_MSG}
        displayPopUp={isSaving} />
    </Fragment>
  );
};

export default KpiViewClubStaffReport;