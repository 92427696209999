import React, { createContext, useState } from 'react';

import UsasClubFacilityLinksData from './UsasClubFacilityLinksData';

export const UsasClubFacilityLinksStateContext = createContext();

const UsasClubFacilityLinksContextProvider = ({ children }) => {
  const stateHook = useState(UsasClubFacilityLinksData.INITIAL_STATE);

  return (
    <UsasClubFacilityLinksStateContext.Provider value={stateHook}>
      {children}
    </UsasClubFacilityLinksStateContext.Provider>
  );
};

export default UsasClubFacilityLinksContextProvider;