import React from 'react';

import global from '../../../../../common/components/GlobalStyle.module.css';

const GridRow = ({ club }) => (
  <tr>
    <td>{club.leaderboardPosition || ''}</td>
    <td>{club.clubName || ''}</td>
    <td>{club.pointsForDollarsToUSAS || ''}</td>
    <td>{club.averageGross || ''}</td>
    <td>{club.totalPoints || ''}</td>
  </tr>
);

const SATLeaderboardGridLarge = ({ data, isLoading }) => (
  <table className={[global.UsasTable, 'visible-md visible-lg'].join(' ')}>
    <thead>
      <tr>
        <th>Leaderboard Position</th>
        <th>Club Name</th>
        <th>5 x 2</th>
        <th>Average Gross</th>
        <th>Grand Total</th>
      </tr>
    </thead>
    <tbody>
      {isLoading === true
        ? (
          <tr>
            <td colSpan="5">Loading...</td>
          </tr>
        ) : Array.isArray(data) && data.length > 0
          ? data.map((club, i) => (
            <GridRow
              key={i}
              club={club} />
          )) : (
            <tr>
              <td colSpan="5">No Clubs</td>
            </tr>
          )
      }
    </tbody>
  </table>
);

export default SATLeaderboardGridLarge;