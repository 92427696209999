import { API_NAMES, initApi } from "../../../common/utils/HttpApiHelper";

const postLTSFormW9Data = (file, state, setState) => {
  if (file) {
    const api = initApi(API_NAMES.COMMON, state, setState);
    const url = `/LearnToSwimFiles`;
    const formData = new FormData();

    formData.append("name", file?.name || '');
    formData.append("file", file);

    return api?.executeObject ? api.executeObject(url, 'POST', formData, true) : null;
  }
};

const LearnToSwimFormW9Data = {
  postLTSFormW9Data
};

export default LearnToSwimFormW9Data;