import { useEffect } from 'react';
import { useLocation, useNavigate } from '../../../../../common/wrappers/ReactRouterDom';

import { createGroupObjFromGroupInfoForm, getBaseGroupData } from '../utils/ClubGroupsUtils';

import NavLinks from '../../NavLinks';

import useClubGroupsData from '../../../../state/clubGroups/UseClubGroupsData';

import validate from '../../../../../common/components/forms/groups/GroupInfoFormValidation';

import useGroupCategoryData from '../../../../../common/state/groupCategory/UseGroupCategoryData';
import useBaseUsasClubData from '../../../../../common/state/clubBase/UseBaseUsasClubData';

import useForm from '../../../../../common/utils/UseForm';
import Constants from '../../../../../common/utils/Constants';

const useClubGroupTypeListAdd = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { baseUsasClubState } = useBaseUsasClubData();
  const { clubGroupState, postClubGroup } = useClubGroupsData();
  const { groupCategoryState } = useGroupCategoryData();
  const { formState, errorState, handleSubmit, onFormValueChanged, setFormData
  } = useForm(getInitialFormState, submitFormCallback, validate);

  const onBackClicked = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    navigate(NavLinks.CLUB_GROUPS_GROUP_TYPE);
  };

  useEffect(() => {
    if (groupCategoryState.isArrayLoaded === true && baseUsasClubState.isObjLoaded === true) {
      if (location.state?.groupTypeId && location.state?.groupTypeName && baseUsasClubState.objData.orgUnitId) {
        setFormData({
          ...formState,
          groupTypeId: location.state.groupTypeId,
          groupTypeName: location.state.groupTypeName,
          orgUnitId: baseUsasClubState.objData.orgUnitId
        });
      } else {
        navigate(NavLinks.CLUB_GROUPS_GROUP_TYPE);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [baseUsasClubState, groupCategoryState]);

  useEffect(() => {
    if (clubGroupState.isSaved === true) {
      navigate(NavLinks.CLUB_GROUPS);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clubGroupState]);

  function submitFormCallback(formState) {
    postClubGroup(createGroupObjFromGroupInfoForm(getBaseGroupData, formState));
  };

  function getInitialFormState() {
    return {
      orgUnitId: '',
      groupTypeId: Constants.DEFAULT_ID,
      groupTypeName: '',
      groupCategories: [],
      groupName: '',
      groupCode: '',
      groupDescription: ''
    };
  };

  return {
    clubGroupState,
    groupCategoryState,
    formState,
    errorState,
    handleSubmit,
    onFormValueChanged,
    onBackClicked,
    orgUnitId: baseUsasClubState.objData.orgUnitId || ''
  };
};

export default useClubGroupTypeListAdd;