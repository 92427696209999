import Constants from '../../../../../common/utils/Constants';
import { isValidNumber, isValidAlphanumeric } from '../../../../../common/utils/validation';

export const localValidate = (formState) => {
  let errors = {};

  if (formState.poolName === '') {
    errors.poolName = 'Pool Number / Name is required';
  } else if (isValidAlphanumeric(formState.poolName) === false) {
    errors.poolName = 'Pool Number / Name can only contain up to 100 alphanumeric characters';
  }

  if (formState.poolDimensions === '' || formState.poolDimensionsId === Constants.DEFAULT_ID) {
    errors.poolDimensionsId = 'Pool Dimensions is required';
  }

  if (isValidNumber(formState.poolMaxDepth) === false ||
    (formState.poolMaxDepth !== '' && Number(formState.poolMaxDepth) < 0.005)) {
    errors.poolMaxDepth = 'Pool Max Depth must be a positive number'
  } else if (formState.poolMaxDepth !== '' && Number(formState.poolMaxDepth) > 300) {
    errors.poolMaxDepth = 'Pool Max Depth cannot exceed 300 ft'
  }

  if (isValidNumber(formState.averagePoolTemperature) === false) {
    errors.averagePoolTemperature = 'Average Pool Temperature must be a positive number'
  } else if (formState.averagePoolTemperature !== '' && Number(formState.averagePoolTemperature) < 60) {
    errors.averagePoolTemperature = 'Average Pool Temperature cannot be below 60 degrees F'
  } else if (formState.averagePoolTemperature !== '' && Number(formState.averagePoolTemperature) > 110) {
    errors.averagePoolTemperature = 'Average Pool Temperature cannot exceed 110 degrees F'
  }

  if (formState.hasStartingBlocks !== true && formState.hasStartingBlocks !== false) {
    errors.hasStartingBlocks = 'Must select yes or no'
  }

  return errors;
};

const FacilityPoolValidate = async (formState) => {
  let errors = localValidate(formState);

  return errors ? errors : {};
};

export default FacilityPoolValidate;