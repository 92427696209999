import React, { createContext, useState } from 'react';

import BaseUsasClubData from './BaseUsasClubData';

export const BaseUsasClubStateContext = createContext();

const BaseUsasClubContextProvider = ({ children }) => {
  const stateHook = useState(BaseUsasClubData.INITIAL_STATE);

  return (
    <BaseUsasClubStateContext.Provider value={stateHook}>
      {children}
    </BaseUsasClubStateContext.Provider>
  );
};

export default BaseUsasClubContextProvider;