import { useEffect, useState } from 'react';
import { useNavigate } from '../../../../common/wrappers/ReactRouterDom';

import NavLinks from '../NavLinks';

import usePreCompLocationData from '../../../state/preCompLocation/UsePreCompLocationData';
import usePreCompLocationStaffData from '../../../state/preCompLocationStaff/UsePreCompLocationStaffData';

import useEnvironmentVariableData from '../../../../common/state/environmentVariable/UseEnvironmentVariableData';

import Constants from '../../../../common/utils/Constants';

const usePreCompLocationStaff = () => {
  const navigate = useNavigate();
  const { environmentVariableState, PRECOMP_STAFF_ROLE_GROUP_ID, PRIMARY_CONTACT_ORG_ROLE_ID } = useEnvironmentVariableData();
  const { preCompLocationState } = usePreCompLocationData();
  const { preCompLocationStaffState, getPreCompLocationStaff, deletePreCompLocationStaff, resetPreCompLocationStaffState
  } = usePreCompLocationStaffData();
  const [deleteModalState, setDeleteModalState] = useState(getInitialDeleteModalState);

  const onAddStaff = () => {
    navigate(NavLinks.PRE_COMP_LOCATION_STAFF_DETAIL);
  };

  const onEditStaff = (staffObj) => {
    navigate(NavLinks.PRE_COMP_LOCATION_STAFF_DETAIL, { state: { staffObj } });
  };

  // Only exists to allow existing pre comp locations with more than one primary contact to delete
  const canDeletePrimaryContact = (staffData) => {
    let primaryContactCount = 0;
    for (const staff of staffData) {
      if (Array.isArray(staff.personOrgRole) && staff.personOrgRole.findIndex(x =>
        x.orgRoleId === PRIMARY_CONTACT_ORG_ROLE_ID) >= 0) {
        primaryContactCount += 1;
      }
    }

    if (primaryContactCount > 1) {
      return true;
    } else {
      return false;
    }
  };

  const onDeleteRole = (personId, personOrgRoleDurationId) => {
    const targetPerson = preCompLocationStaffState.arrayData.find(x => x.personId === personId);

    if (targetPerson && Array.isArray(targetPerson.personOrgRole)) {
      if (targetPerson.personOrgRole.length === 1 && targetPerson.personOrgRole[0].personOrgRoleDurationId === personOrgRoleDurationId) {
        setDeleteModalState({
          ...deleteModalState,
          personOrgRoleDurationId,
          personName: `${targetPerson.firstName} ${targetPerson.lastName}`,
          displayModal: true
        });
      } else {
        deletePreCompLocationStaff(personOrgRoleDurationId);
      }
    }
  };

  const onModalDeleteClicked = () => {
    deletePreCompLocationStaff(deleteModalState.personOrgRoleDurationId);
    setDeleteModalState(getInitialDeleteModalState);
  };

  const onModalCancelClicked = () => {
    setDeleteModalState(getInitialDeleteModalState);
  };

  useEffect(() => {
    if (preCompLocationStaffState.isSaving === false && preCompLocationStaffState.isSaved === true) {
      resetPreCompLocationStaffState();
    } else if (preCompLocationStaffState.isArrayLoading === false && preCompLocationStaffState.isArrayLoaded === false
      && environmentVariableState.isLoaded === true) {
      getPreCompLocationStaff(preCompLocationState.objData.orgUnitId, PRECOMP_STAFF_ROLE_GROUP_ID);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [preCompLocationStaffState, environmentVariableState]);

  function getInitialDeleteModalState() {
    return {
      personOrgRoleDurationId: Constants.DEFAULT_ID,
      personName: '',
      displayModal: false
    };
  };

  return {
    isSaving: preCompLocationState.isSaving || preCompLocationStaffState.isSaving,
    isLoading: preCompLocationState.isObjLoading || preCompLocationStaffState.isArrayLoading,
    preCompLocationState,
    preCompLocationStaffState,
    deleteModalState,
    onAddStaff,
    onEditStaff,
    onDeleteRole,
    canDeletePrimaryContact,
    onModalDeleteClicked,
    onModalCancelClicked,
    PRIMARY_CONTACT_ORG_ROLE_ID
  };
};

export default usePreCompLocationStaff;