import { createProviderObjFromProviderInfoForm } from "../utils/LearnToSwimUtils";

import useLearnToSwimData from "../../../state/learnToSwim/UseLearnToSwimData";

const useLearnToSwimProviderInfo = () => {
  const { ltsProviderState, setLTSProviderState, putLTSProvider } = useLearnToSwimData();

  const onSubmitFormCallback = (formState) => {
    const providerObj = createProviderObjFromProviderInfoForm(formState, ltsProviderState.objData);
    const learnToSwimProviderId = providerObj.learnToSwimProviderId;
    const putLTSProviderPromise = putLTSProvider(learnToSwimProviderId, providerObj);

    if (putLTSProviderPromise !== null) {
      putLTSProviderPromise.then((newState) => {
        if (newState !== null) {
          setLTSProviderState({
            ...newState,
            isSaving: false
          });
        }
      }).catch((e) => {
        //TODO context error
      });
    }
  };

  return {
    isLoading: ltsProviderState.isObjLoading,
    ltsProviderState,
    onSubmitFormCallback
  };
};

export default useLearnToSwimProviderInfo;