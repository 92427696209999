import { RecHttpHelper, NO_DATA_MESSAGE } from '../../../common/utils/HttpHelper';
import Constants from '../../../common/utils/Constants';

export const INITIAL_STATE = {
  categoryMeasureId: Constants.DEFAULT_ID,
  isArrayLoading: false,
  isArrayLoaded: false,
  arrayData: [],
  options: [],
  message: ''
};

const getCategoryMeasureDropdownByCategoryMeasureId = (categoryMeasureId, state, setState) => {
  if (state.isArrayLoading !== true && categoryMeasureId > 0 && !state.message) {
    let newState = {
      ...state,
      categoryMeasureId,
      isArrayLoading: true,
      isArrayLoaded: false,
      message: 'Loading...'
    };
    setState(newState);

    const categoryMeasureIdForUrl = categoryMeasureId ? encodeURIComponent(categoryMeasureId) : 'NaN';
    const url = `/CategoryMeasureDropdown/CategoryMeasure/${categoryMeasureIdForUrl}`;
    RecHttpHelper(url, 'GET')
      .then((data) => {
        if (!data) {
          throw new Error(NO_DATA_MESSAGE);
        } else {
          const options = data.map((o) => { return { id: o.categoryMeasureDropdownId, name: o.dropdownValue }; });
          options.unshift({ id: Constants.DEFAULT_ID, name: '--' });

          newState = {
            ...newState,
            isArrayLoading: false,
            isArrayLoaded: true,
            arrayData: data,
            options,
            message: ''
          };
          setState(newState);
        }
      })
      .catch((e) => {
        const message = e && e.message ? e.message : NO_DATA_MESSAGE;
        setState({
          ...newState,
          isArrayLoading: false,
          isArrayLoaded: false,
          message: message
        });
      });
  }
};

const CategoryMeasureDropdownData = { INITIAL_STATE, getCategoryMeasureDropdownByCategoryMeasureId };

export default CategoryMeasureDropdownData;