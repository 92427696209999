import { useEffect } from "react";
import { useNavigate } from "../../../../common/wrappers/ReactRouterDom";

import NavLinks from "../utils/NavLinks";

import useLearnToSwimData from "../../../state/learnToSwim/UseLearnToSwimData";

import useSecurityData from "../../../../common/state/security/UseSecurityData";
import useSelectOrgUnitData from "../../../../common/state/selectOrgUnit/UseSelectOrgUnitData";

const TAXONOMIES = ['LearnToSwimDetail'];
const SCOPE = 'LearnToSwim';

const useLearnToSwimSearch = () => {
  const navigate = useNavigate();
  const { getContextSecurity, contextSecurityState } = useSecurityData();
  const { ltsProviderState, ltsProviderFilterState, ltsProviderSearchState, getLTSProvider,
    searchLTSProviders } = useLearnToSwimData();
  const { resetSelectOrgUnitState } = useSelectOrgUnitData();

  const onEditLTSProviderClicked = (learnToSwimProviderId) => {
    if (learnToSwimProviderId > 0) {
      getContextSecurity(learnToSwimProviderId, TAXONOMIES, SCOPE);

      const getLTSProviderPromise = getLTSProvider(learnToSwimProviderId);

      if (getLTSProviderPromise !== null) {
        getLTSProviderPromise.then((newState) => {
          if (newState !== null) {
            navigate(NavLinks.LEARN_TO_SWIM_PROVIDER_INFO);
          }
        }).catch((e) => {
          //TODO context error
        });
      }
    }
  };

  const onSubmitFormCallback = (formState) => {
    const searchLTSProvidersPromise = searchLTSProviders(createFilterObj(formState));

    if (searchLTSProvidersPromise !== null) {
      searchLTSProvidersPromise.catch((e) => {
        //TODO state error
      });
    }
  };

  useEffect(() => {
    resetSelectOrgUnitState();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  function createFilterObj(formState) {
    return {
      entityName: formState.entityName.trim() || undefined,
      tradeName: formState.tradeName.trim() || undefined,
      taxId: formState.taxId.trim() || undefined,
    };
  };

  return {
    isLoading: ltsProviderSearchState.isArrayLoading || ltsProviderState.isObjLoading || contextSecurityState.isObjLoading,
    gridData: ltsProviderSearchState.arrayData || [],
    ltsProviderFilterState,
    onEditLTSProviderClicked,
    onSubmitFormCallback
  };
};

export default useLearnToSwimSearch;