import { Fragment } from "react";

import { ColumnChart } from "@sisense/sdk-ui";
import { filterFactory } from "@sisense/sdk-data";

import useMeetSummaryWidget from "./UseMeetSummaryWidget";

import * as DM from '../../../../../../usas-times-data';

import Spinner from "../../../../../../common/components/spinners/Spinner";
import Headings from "../../../../../../common/components/headings/Headings";

import ToIntIfInt from "../../../../../../common/utils/ToIntIfInt";

import global from '../../../../../../common/components/GlobalStyle.module.css';

const MeetSummaryColumnChartWidget = ({ dashboardOid, widgetOid }) => {
  const { location, isError, isLoading, widget, eventData } = useMeetSummaryWidget(dashboardOid, widgetOid);

  return (
    <Fragment>
      <div className="col-xs-12 col-md-8">
        {isError
          ? <p className={global.ErrorMessage}>Error...</p>
          : isLoading
            ? <Spinner />
            : widget && eventData
              ?
              <Fragment>
                <span style={{ textAlign: 'center', marginBottom: '10px' }}>
                  <Headings.H6>{widget.title || ''}</Headings.H6>
                </span>
                <ColumnChart {...widget.getChartProps()
                }
                  onBeforeRender={(options) => {
                    //sort data by swim event key order
                    //handle x axis labels
                    const sortedXAxisOptions = eventData.rows?.map((event) => {
                      const xAxisMatch = options.xAxis[0]?.categories.find((option) => option === event[1].text);
                      return (xAxisMatch);
                    })
                    const filteredSortedXAxisOptions = sortedXAxisOptions.filter((option) => (option !== undefined));
                    options.xAxis[0].categories = filteredSortedXAxisOptions;

                    //handle data series (female, male, mixed)
                    for (let i = 0; i < options.series.length; i++) {
                      const sortedSeries = eventData.rows?.map((event) => {
                        const dataMatch = options.series[i]?.data.find((data) => data.custom?.xValue[0] === event[1].text);
                        return (dataMatch);
                      })
                      const filteredSortedSeries = sortedSeries.filter((data) => (data !== undefined));
                      options.series[i].data = filteredSortedSeries;
                    }

                    return options;
                  }}
                  filters={[filterFactory.equals(DM.Meet.MeetKey, ToIntIfInt(location.state?.meetKey))]}
                />
              </Fragment>
              : <Fragment />
        }
      </div>
    </Fragment>
  );
};

export default MeetSummaryColumnChartWidget;