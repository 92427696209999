import { useEffect, useState } from 'react';

import validate from './ProviderInfoFormValidation';

import useLearnToSwimClubsData from '../../../../state/learnToSwimClubs/UseLearnToSwimClubsData';
import useLearnToSwimCurriculumData from '../../../../state/learnToSwimCurriculum/UseLearnToSwimCurriculumData';
import useLearnToSwimLogoData from '../../../../state/learnToSwimFiles/UseLearnToSwimLogoData';
import useLearnToSwimCurriculumFileData from '../../../../state/learnToSwimFiles/UseLearnToSwimCurriculumFileData';
import useLearnToSwimProofOfInsuranceData from '../../../../state/learnToSwimFiles/UseLearnToSwimProofOfInsuranceData';
import useLearnToSwimFormW9Data from '../../../../state/learnToSwimFiles/UseLearnToSwimFormW9Data';

import UseForm from "../../../../../common/utils/UseForm";
import Constants from '../../../../../common/utils/Constants';
import { formatDate, getTodaysDate } from '../../../../../common/utils/DateFunctions';

const INITIAL_FORM_STATE = {
  entityName: '',
  tradeName: '',
  taxId: '',
  learnToSwimCurriculumId: Constants.DEFAULT_ID,
  learnToSwimCurriculumName: '--',
  website: '',
  offersFinancialAssistance: '',
  currentLTSGrantRecipient: '',
  affiliatedWithUsasClub: '',
  childProtectionTraining: '',
  offerAdultLearnToSwim: '',
  offerSpecialNeeds: '',
  backgroundCheckAcknowledgement: '',
  backgroundCheckNotUsedExplanation: '',
  indemnificationAgreement: '',
  indemnificationAgreementSignature: '',
  indemnificationAgreementSignatureDate: Constants.BLANK_DATE_STRING,
  usasClubId: '',
  usasClubName: '',
  logoUrl: '',
  logoFile: {},
  curriculumUrl: '',
  curriculumFile: {},
  proofOfInsuranceUrl: '',
  proofOfInsuranceFile: {},
  formW9Url: '',
  formW9File: {}
};

const useProviderInfoForm = (onSubmitFormCallback, providerObj) => {
  const { ltsClubsState } = useLearnToSwimClubsData();
  const { ltsCurriculumState } = useLearnToSwimCurriculumData();
  const { ltsLogoState, postLTSLogo } = useLearnToSwimLogoData();
  const { ltsCurriculumFileState, postLTSCurriculum } = useLearnToSwimCurriculumFileData();
  const { ltsProofOfInsuranceState, postLTSProofOfInsurance } = useLearnToSwimProofOfInsuranceData();
  const { ltsFormW9State, postLTSFormW9 } = useLearnToSwimFormW9Data();
  const [state, setState] = useState({ trySubmit: false, fixFiles: true });
  const { formState, errorState, isDirty, setIsDirty, setFormState, handleSubmit, updateFormState, onValueTextPairChanged,
    resetForm, setErrors, setFormData
  } = UseForm(INITIAL_FORM_STATE, localSubmitFormCallback, validate);

  const resetFormFunction = () => {
    resetForm();
    setState({ ...state, fixFiles: true });
  }

  const onLogoFileLoad = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    setErrors({ ...errorState, logoFile: '' });
    if (e.target.files[0]) {
      const selectedFile = e.target.files[0];
      const fileType = e.target.files[0].type;

      if (fileType === Constants.FILE_TYPE_JPG) {
        updateFormState('logoFile', selectedFile);
      } else {
        setErrors({ ...errorState, logoFile: 'File type not permitted. Please provide a (*.jpg) file.' });
      }
    }
  };

  const onLogoFileUnload = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    setFormState({
      ...formState,
      logoFile: {},
      logoUrl: ''
    });
  };

  const onLogoFileDownload = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    window.open(formState.logoUrl);
  };

  const onCurriculumFileLoad = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    setErrors({ ...errorState, curriculumFile: '' });
    if (e.target.files[0]) {
      const selectedFile = e.target.files[0];
      const fileType = e.target.files[0].type;

      if (fileType === Constants.FILE_TYPE_PDF || fileType === Constants.FILE_TYPE_DOC ||
        fileType === Constants.FILE_TYPE_DOCX) {
        updateFormState('curriculumFile', selectedFile);
      } else {
        setErrors({ ...errorState, curriculumFile: 'File type not permitted. Please provide a (*.pdf), (*.doc), or (*.docx) file.' });
      }
    }
  };

  const onCurriculumFileUnload = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    setFormState({
      ...formState,
      curriculumFile: {},
      curriculumUrl: ''
    });
  };

  const onCurriculumFileDownload = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    window.open(formState.curriculumUrl);
  };

  const onProofOfInsuranceFileLoad = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    setErrors({ ...errorState, proofOfInsuranceFile: '' });
    if (e.target.files[0]) {
      const selectedFile = e.target.files[0];
      const fileType = e.target.files[0].type;

      if (fileType === Constants.FILE_TYPE_PDF || fileType === Constants.FILE_TYPE_DOC ||
        fileType === Constants.FILE_TYPE_DOCX) {
        updateFormState('proofOfInsuranceFile', selectedFile);
      } else {
        setErrors({ ...errorState, proofOfInsuranceFile: 'File type not permitted. Please provide a (*.pdf), (*.doc), or (*.docx) file.' });
      }
    }
  };

  const onProofOfInsuranceFileUnload = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    setFormState({
      ...formState,
      proofOfInsuranceFile: {},
      proofOfInsuranceUrl: ''
    });
  };

  const onProofOfInsuranceFileDownload = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    window.open(formState.proofOfInsuranceUrl);
  };

  const onFormW9FileLoad = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    setErrors({ ...errorState, formW9File: '' });
    if (e.target.files[0]) {
      const selectedFile = e.target.files[0];
      const fileType = e.target.files[0].type;

      if (fileType === Constants.FILE_TYPE_PDF || fileType === Constants.FILE_TYPE_DOC ||
        fileType === Constants.FILE_TYPE_DOCX) {
        updateFormState('formW9File', selectedFile);
      } else {
        setErrors({ ...errorState, formW9File: 'File type not permitted. Please provide a (*.pdf), (*.doc), or (*.docx) file.' });
      }
    }
  };

  const onFormW9FileUnload = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    setFormState({
      ...formState,
      formW9File: {},
      formW9Url: ''
    });
  };

  const onFormW9FileDownload = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    window.open(formState.formW9Url);
  };

  useEffect(() => {
    if (providerObj && ltsClubsState.areItemsLoaded === true && ltsCurriculumState.areItemsLoaded === true) {
      let usasClub = null;
      let learnToSwimCurriculum = null;

      if (providerObj.affiliatedWithUsasClub === true && providerObj.usasClubId) {
        usasClub = ltsClubsState.items.find(x => x.id === providerObj.usasClubId);
      }

      if (providerObj.learnToSwimCurriculumId > 0) {
        learnToSwimCurriculum = ltsCurriculumState.items.find(x => x.id === providerObj.learnToSwimCurriculumId);
      }

      setFormData({
        ...formState,
        entityName: providerObj.entityName || '',
        tradeName: providerObj.tradeName || '',
        taxId: providerObj.taxId || '',
        learnToSwimCurriculumId: learnToSwimCurriculum?.id || Constants.DEFAULT_ID,
        learnToSwimCurriculumName: learnToSwimCurriculum?.name || '--',
        website: providerObj.website || '',
        offersFinancialAssistance: providerObj.offersFinancialAssistance === true || providerObj.offersFinancialAssistance === false
          ? providerObj.offersFinancialAssistance
          : '',
        currentLTSGrantRecipient: providerObj.currentLTSGrantRecipient === true || providerObj.currentLTSGrantRecipient === false
          ? providerObj.currentLTSGrantRecipient
          : '',
        affiliatedWithUsasClub: providerObj.affiliatedWithUsasClub === true || providerObj.affiliatedWithUsasClub === false
          ? providerObj.affiliatedWithUsasClub
          : '',
        childProtectionTraining: providerObj.childProtectionTraining === true || providerObj.childProtectionTraining === false
          ? providerObj.childProtectionTraining
          : '',
        offerAdultLearnToSwim: providerObj.offerAdultLearnToSwim === true || providerObj.offerAdultLearnToSwim === false
          ? providerObj.offerAdultLearnToSwim
          : '',
        offerSpecialNeeds: providerObj.offerSpecialNeeds === true || providerObj.offerSpecialNeeds === false
          ? providerObj.offerSpecialNeeds
          : '',
        backgroundCheckAcknowledgement: providerObj.backgroundCheckAcknowledgement === true || providerObj.backgroundCheckAcknowledgement === false
          ? providerObj.backgroundCheckAcknowledgement
          : '',
        backgroundCheckNotUsedExplanation: providerObj.backgroundCheckAcknowledgement === false && providerObj.backgroundCheckNotUsedExplanation ?
          providerObj.backgroundCheckNotUsedExplanation : '',
        indemnificationAgreement: providerObj.indemnificationAgreement === true || providerObj.indemnificationAgreement === false
          ? providerObj.indemnificationAgreement
          : '',
        indemnificationAgreementSignature: providerObj.indemnificationAgreementSignature || '',
        indemnificationAgreementSignatureDate: providerObj.indemnificationAgreementSignatureDate && providerObj.indemnificationAgreementSignatureDate !== Constants.BLANK_DATE_STRING ? formatDate(providerObj.indemnificationAgreementSignatureDate) : getTodaysDate(),
        usasClubId: usasClub?.id || '',
        usasClubName: usasClub?.name || '',
        logoUrl: providerObj.logo || '',
        curriculumUrl: providerObj.curriculumUrl || '',
        proofOfInsuranceUrl: providerObj.proofOfInsuranceUrl || '',
        formW9Url: providerObj.formW9Url || '',
      });
      setState({ ...state, fixFiles: false });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [providerObj, ltsClubsState.areItemsLoaded, ltsCurriculumState.areItemsLoaded, state.fixFiles]);

  //TODO better way to do this?
  useEffect(() => {
    if (isDirty !== true) {
      setIsDirty(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDirty]);

  useEffect(() => {
    if (state.trySubmit === true) {
      if (ltsLogoState.isObjLoading === false &&
        (ltsLogoState.isObjLoaded === true && ltsLogoState.objData.uploadUrl ||
          ltsCurriculumFileState.isObjLoaded === true && ltsCurriculumFileState.objData.uploadUrl ||
          ltsProofOfInsuranceState.isObjLoaded === true && ltsProofOfInsuranceState.objData.uploadUrl ||
          ltsFormW9State.isObjLoaded === true && ltsFormW9State.objData.uploadUrl)) {
        onSubmitFormCallback({
          ...formState,
          logoUrl: ltsLogoState.objData.uploadUrl ? ltsLogoState.objData.uploadUrl : formState.logoUrl || null,
          curriculumUrl: ltsCurriculumFileState.objData.uploadUrl ? ltsCurriculumFileState.objData.uploadUrl : formState.curriculumUrl || null,
          proofOfInsuranceUrl: ltsProofOfInsuranceState.objData.uploadUrl ? ltsProofOfInsuranceState.objData.uploadUrl : formState.proofOfInsuranceUrl || null,
          formW9Url: ltsFormW9State.objData.uploadUrl ? ltsFormW9State.objData.uploadUrl : formState.formW9Url || null
        });
        setState({ ...state, trySubmit: false });
      }
      else if (ltsLogoState.isObjLoading === false && ltsCurriculumFileState.isObjLoading === false &&
        ltsProofOfInsuranceState.isObjLoading === false && ltsFormW9State.isObjLoading === false) {
        onSubmitFormCallback({
          ...formState
        });
        setState({ ...state, trySubmit: false });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state, ltsLogoState, ltsCurriculumFileState, ltsProofOfInsuranceState, ltsFormW9State])

  function localSubmitFormCallback(formState) {
    if (formState.logoFile?.size) {
      const postLTSLogoPromise = postLTSLogo(formState.logoFile);

      if (postLTSLogoPromise !== null) {
        postLTSLogoPromise.then(() => {
        }).catch(() => {
          //TODO state error
        });
      }
    }
    if (formState.curriculumFile?.size) {
      const postLTSCurriculumPromise = postLTSCurriculum(formState.curriculumFile);

      if (postLTSCurriculumPromise !== null) {
        postLTSCurriculumPromise.then(() => {
        }).catch(() => {
          //TODO state error
        });
      }
    }
    if (formState.proofOfInsuranceFile?.size) {
      const postLTSProofOfInsurancePromise = postLTSProofOfInsurance(formState.proofOfInsuranceFile);

      if (postLTSProofOfInsurancePromise !== null) {
        postLTSProofOfInsurancePromise.then(() => {
        }).catch(() => {
          //TODO state error
        });
      }
    }
    if (formState.formW9File?.size) {
      const postLTSFormW9romise = postLTSFormW9(formState.formW9File);

      if (postLTSFormW9romise !== null) {
        postLTSFormW9romise.then(() => {
        }).catch(() => {
          //TODO state error
        });
      }
    }

    if ((!formState.curriculumFile?.size && !formState.curriculumUrl)
      || (!formState.proofOfInsuranceFile?.size && !formState.proofOfInsuranceUrl)
      || (!formState.formW9File?.size && !formState.formW9Url)) {
      setErrors({
        ...errorState,
        curriculumFile: formState.curriculumFile?.size ? '' : 'A file upload is required',
        proofOfInsuranceFile: formState.proofOfInsuranceFile?.size ? '' : 'A file upload is required',
        formW9File: formState.formW9File?.size ? '' : 'A file upload is required'
      });
    }
    else {
      setState({ ...state, trySubmit: true });
    }
  }

  return {
    isLoading: ltsClubsState.isArrayLoading || ltsCurriculumState.isArrayLoading,
    isSaving: ltsLogoState.isObjLoading || ltsCurriculumFileState.isObjLoading ||
      ltsProofOfInsuranceState.isObjLoading || ltsFormW9State.isObjLoading,
    formState,
    errorState,
    handleSubmit,
    updateFormState,
    onValueTextPairChanged,
    resetForm,
    onLogoFileLoad,
    onLogoFileUnload,
    onLogoFileDownload,
    onCurriculumFileLoad,
    onCurriculumFileUnload,
    onCurriculumFileDownload,
    onProofOfInsuranceFileLoad,
    onProofOfInsuranceFileUnload,
    onProofOfInsuranceFileDownload,
    onFormW9FileLoad,
    onFormW9FileUnload,
    onFormW9FileDownload,
    resetFormFunction
  };
};

export default useProviderInfoForm;