import React from 'react';

import DeleteIcon from '../../../../../common/components/icons/DeleteIcon';

import { formatPhoneNumber } from '../../../../../common/utils/validation';

import global from '../../../../../common/components/GlobalStyle.module.css';

const GridRow = ({ contact, onDelete }) => {
  const role = Array.isArray(contact.personOrgRole) && contact.personOrgRole.length > 0 ? contact.personOrgRole[0] : null;

  return (
    <tr>
      <td>{`${contact.firstName} ${contact.lastName}`}</td>
      <td>{role ? role.orgRoleName : ''}</td>
      <td>{contact.phoneNumber ? formatPhoneNumber(contact.phoneNumber) : ''}</td>
      <td>{contact.email || ''}</td>
      <td>
        {onDelete !== undefined &&
          <button
            className={global.IconButtonMargin}
            type="button"
            onClick={() => onDelete(role ? role.orgRoleId : '')}>
            <DeleteIcon />
          </button>
        }
      </td>
    </tr>
  );
};

const PreCompOrganizationContactsGridLarge = ({ data, isLoading, onDelete }) => (
  <table className={[global.UsasTable, 'visible-md visible-lg'].join(' ')}>
    <thead>
      <tr>
        <th>Contact Name</th>
        <th>Primary/Secondary</th>
        <th>Phone Number</th>
        <th>Email</th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      {isLoading === true
        ? <tr><td colSpan="5">Loading...</td></tr>
        : data.length > 0
          ? data.map((contact, i) => <GridRow key={i} contact={contact} onDelete={onDelete} />)
          : <tr><td colSpan="5">No Contacts</td></tr>
      }
    </tbody>
  </table>
);

export default PreCompOrganizationContactsGridLarge;