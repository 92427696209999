import { Fragment } from 'react';

import global from '../../components/GlobalStyle.module.css';

import useClubRosterExport from './UseClubRosterExport';

const ClubRosterExport = ({ 
  clubRosterArray, 
  orgUnitCode, 
  orgUnitName, 
  parentOrgUnitCode, 
  clubCity,
  clubStateCode,
  clubAddress1,
  clubPostalCode,
  clubEmail,
  rosterType}) => {

  const { onRosterExportClicked } = useClubRosterExport(
      clubRosterArray, 
      orgUnitCode, 
      orgUnitName, 
      parentOrgUnitCode,
      clubCity,
      clubStateCode,
      clubAddress1,
      clubPostalCode,
      clubEmail,
      rosterType);

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <button className={global.PlainButton} type='button' onClick={onRosterExportClicked}>Export Current Roster</button>
        </div>
      </div>
    </Fragment>
  );
};

export default ClubRosterExport;