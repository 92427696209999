import { useEffect, useState } from 'react';
import { useNavigate } from '../../../../common/wrappers/ReactRouterDom';
import { navigateToCrossUiRoute } from '../../../../common/utils/FormatCrossUiRoute';

import validate from './ClubRegistrationSubmitValidation';

import NavLinks from '../NavLinks';

import ClubRegistrationConstants from '../utils/ClubRegistrationConstants';

import useClubRegistrationNavData from '../../../state/clubRegistration/leftNavigation/UseClubRegistrationNavData';
import useClubRegistrationNewData from '../../../state/clubRegistrationNew/UseClubRegistrationNewData';
import useClubRegistrationPaymentData from '../../../state/clubRegistration/payment/UseClubRegistrationPaymentData';

import useOfferingTypeData from '../../../../common/state/offeringTypes/UseOfferingTypeData';
import useGlobalRoutesData from '../../../../common/state/security/UseGlobalRoutesData';

import useForm from '../../../../common/utils/UseForm';
import Constants from '../../../../common/utils/Constants';
import { formatDate } from '../../../../common/utils/DateFunctions';

const useClubRegistrationSubmit = () => {
  const navigate = useNavigate();
  const [state, setState] = useState({ submitButton: '', tryRedirect: false });
  const [uiState, setUiState] = useState({ message: '' });
  const [modalState, setModalState] = useState(getInitialModalState());
  const { clubRegistrationNavState, setClubRegistrationNavState } = useClubRegistrationNavData();
  const { clubRegistrationState, putUsasClub } = useClubRegistrationNewData();
  const { clubRegistrationPaymentState } = useClubRegistrationPaymentData();
  const { offeringTypeState, getOfferingTypesForClubRegistration } = useOfferingTypeData();
  const { getGlobalRoute } = useGlobalRoutesData();

  const { handleSubmit, updateFormState, formState, errorState, setFormData, isSubmitting, isDirty, setErrors, setIsDirty
  } = useForm(getFormInitialState(), submitFormCallback, validate);

  const editClubObj = () => {
    let clubCopy = JSON.parse(JSON.stringify(clubRegistrationState.objData));

    if (clubCopy.clubRegistrations.length === 1) {
      //clubCopy.status = clubCopy.clubRegistrations[0].paymentProcessed === true ? ClubRegistrationConstants.PENDING_STATUS : ClubRegistrationConstants.PENDING_PAYMENT_STATUS;
      clubCopy.status = ClubRegistrationConstants.PENDING_STATUS;
      clubCopy.clubRegistrations = [{
        ...clubCopy.clubRegistrations[0],
        registrationSignature: formState.registrationSignature.trim().length > 0 ? formState.registrationSignature.trim() : null,
        registrationSignatureDate: formState.registrationDate !== Constants.BLANK_DATE_STRING ? formState.registrationDate : null,
        registrationDate: formState.registrationDate !== Constants.BLANK_DATE_STRING ? formState.registrationDate : null,
        //status: clubCopy.clubRegistrations[0].paymentProcessed === true ? ClubRegistrationConstants.PENDING_STATUS : ClubRegistrationConstants.PENDING_PAYMENT_STATUS
        status: ClubRegistrationConstants.PENDING_STATUS
      }]
    }


    return clubCopy;
  }

  const editClubObjForPayment = () => {
    let clubCopy = JSON.parse(JSON.stringify(clubRegistrationState.objData));

    if (clubCopy.clubRegistrations.length > 0) {
      const selectedRegistrationIndex = clubRegistrationState.objData.clubRegistrations.findIndex(x =>
        (x.status !== ClubRegistrationConstants.APPROVED_STATUS && x.status !== null));

      clubCopy.clubRegistrations[selectedRegistrationIndex] = {
        ...clubCopy.clubRegistrations[selectedRegistrationIndex],
        paymentProcessed: true
      }
    }

    return clubCopy;
  }

  function submitFormCallback() {
    putUsasClub(clubRegistrationState.objData.usasClubId, editClubObj());
  };

  const onContinueClicked = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    handleSubmit();
    if (isDirty === false && formState.registrationSignature.trim() !== '') {
      setState({ ...state, tryRedirect: true, submitButton: ClubRegistrationConstants.CONTINUE });
    }
    else {
      setState({ ...state, submitButton: ClubRegistrationConstants.CONTINUE });
    }
  }

  const onSaveAndExitClicked = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    handleSubmit();
    setState({ ...state, tryRedirect: false, submitButton: ClubRegistrationConstants.EXIT });
  }

  const onBackClicked = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    
    setState({ ...state, tryRedirect: true, submitButton: ClubRegistrationConstants.BACK });
  }

  const onOpenModal = () => {
    if (Object.keys(errorState).length > 0) {
      setModalState({ ...modalState, displayPopUp: true });
    }
    else {
      setModalState({ ...modalState, modalTitle: ClubRegistrationConstants.EXIT_CONFIRMATION, displayPopUp: true });
    }
  }

  const onModalContinue = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    setState({ ...state, tryRedirect: true });
    setModalState({ ...getInitialModalState() });
  }

  const onModalCanceled = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    if (clubRegistrationState.objData.status === ClubRegistrationConstants.RENEWING_STATUS && Object.keys(formState.selectedRegistration).length > 0 &&
      formState.selectedRegistration.isRenewal === true && formState.selectedRegistration.newFederalClubTaxId === false
      && formState.selectedRegistration.newOwner === false && formState.selectedRegistration.paymentProcessed === false) {
      setErrors({});
    }
    setState({ ...state, submitButton: '', tryRedirect: false });
    setModalState({ ...getInitialModalState() });
  }

  const updateNavState = () => {
    for (let i = 0; i < clubRegistrationNavState.length; i++) {
      clubRegistrationNavState[i].isCurrent = false
    }
    const navIndex = clubRegistrationNavState.findIndex(x => x.name === ClubRegistrationConstants.SUBMIT)

    if (clubRegistrationState.objData.status === ClubRegistrationConstants.IN_PROGRESS_STATUS ||
      clubRegistrationState.objData.status === ClubRegistrationConstants.RENEWING_STATUS) {
      clubRegistrationNavState[navIndex] =
      {
        ...clubRegistrationNavState[navIndex],
        isCurrent: true,
        isComplete: false,
        isVisited: true,
        arePrerequisitesDone: true
      }
    }
    else if (clubRegistrationState.objData.status === ClubRegistrationConstants.PENDING_STATUS
      || clubRegistrationState.objData.status === ClubRegistrationConstants.APPROVED_STATUS) {
      clubRegistrationNavState[navIndex] =
      {
        ...clubRegistrationNavState[navIndex],
        isCurrent: true,
        isComplete: true,
        isVisited: true,
        arePrerequisitesDone: true
      }
    }
    setClubRegistrationNavState(clubRegistrationNavState);
  }

  const onNavigateBack = null;
  const onError = null;
  const onPreCompletion = null;
  const onCompletion = null;
  const onPostCompletion = null;

  useEffect(() => {
    if (clubRegistrationState.isObjLoaded === false) {
      navigate(NavLinks.CLUB_REGISTRATION_ENTRY_NEW);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (clubRegistrationState.isObjLoaded === true && clubRegistrationState.objData.status === ClubRegistrationConstants.PENDING_PAYMENT_STATUS) {
      setUiState({ message: 'Once your club registration is approved you will recieve an email notifying you that you can pay your fees' });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clubRegistrationState]);

  useEffect(() => {
    const navIndex = clubRegistrationNavState.findIndex(x => x.name === ClubRegistrationConstants.SUBMIT)
    if (clubRegistrationNavState[navIndex].isCurrent === false) {
      updateNavState();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clubRegistrationNavState])

  useEffect(() => {
    if (Object.keys(clubRegistrationState.objData).length > 0 && offeringTypeState.isArrayLoaded === false) {
      getOfferingTypesForClubRegistration(clubRegistrationState.objData.orgUnit?.parentOrgUnitId, '', 'club');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clubRegistrationState.objData, offeringTypeState.isArrayLoaded])

  useEffect(() => {
    if (clubRegistrationPaymentState.status === ClubRegistrationConstants.PAID_STATUS &&
      Object.keys(formState.selectedRegistration).length > 0 && formState.selectedRegistration.status !== ClubRegistrationConstants.APPROVED_STATUS) {
      putUsasClub(clubRegistrationState.objData.usasClubId, editClubObjForPayment());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clubRegistrationPaymentState])

  useEffect(() => {
    if (Object.keys(clubRegistrationState.objData).length > 0 && offeringTypeState.isArrayLoaded === true) {
      let today = formatDate(new Date());
      let lscname = '';
      updateNavState();

      if (clubRegistrationState.objData?.clubRegistrations?.length === 1) {
        lscname = clubRegistrationState.objData.clubRegistrations[0].lscname;
        let usasFeeAmount = '';
        let lscFeeAmount = '';
        let offeringName = '';
        
        for (let i = 0; i < offeringTypeState.arrayData.length; i++) {
          for (let j = 0; j < offeringTypeState.arrayData[i].offerings.length; j++) {
            if (offeringTypeState.arrayData[i].offerings[j].offeringInstanceId === clubRegistrationState.objData.clubRegistrations[0].offeringInstanceId) {
              offeringName = offeringTypeState.arrayData[i].offerings[j].offeringName;
              usasFeeAmount = offeringTypeState.arrayData[i].offerings[j].usasPrice;
              lscFeeAmount = offeringTypeState.arrayData[i].offerings[j].lscPrice;
            }
          }
        }
        setFormData({
          ...formState,
          today: today,
          registrationSignature: clubRegistrationState.objData.clubRegistrations[0].registrationSignature !== null ?
            clubRegistrationState.objData.clubRegistrations[0].registrationSignature : '',
          registrationDate: clubRegistrationState.objData.clubRegistrations[0].registrationDate !== null ?
            formatDate(clubRegistrationState.objData.clubRegistrations[0].registrationDate) : today,
          lscname: lscname,
          selectedRegistration: clubRegistrationState.objData.clubRegistrations[0],
          usasFeeAmount: usasFeeAmount,
          offeringName: offeringName,
          lscFeeAmount: lscFeeAmount
        });
        setIsDirty(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clubRegistrationState.objData, offeringTypeState])

  useEffect(() => {
    //Handle validation modal and navigation
    if (state.submitButton !== '' && clubRegistrationState.isObjLoaded === true &&
      clubRegistrationState.isSaving === false && isSubmitting === false) {
      if (state.tryRedirect === false) {

        if ((Object.keys(errorState).length > 0 || state.submitButton === ClubRegistrationConstants.EXIT) &&
          clubRegistrationState.objData.status !== ClubRegistrationConstants.PENDING_PAYMENT_STATUS) {
          onOpenModal();
        }
      }
      if (state.tryRedirect === true) {
        if (Object.keys(errorState).length === 0) {
          const navIndex = clubRegistrationNavState.findIndex(x => x.name === ClubRegistrationConstants.SUBMIT)
          clubRegistrationNavState[navIndex] =
          {
            ...clubRegistrationNavState[navIndex],
            isCurrent: true,
            isComplete: true,
            isVisited: true,
            arePrerequisitesDone: true
          }
          setClubRegistrationNavState(clubRegistrationNavState);
        }

        if (state.submitButton === ClubRegistrationConstants.CONTINUE) {
          setState({ submitButton: '', tryRedirect: false })
        }
        else if (state.submitButton === ClubRegistrationConstants.EXIT) {
          navigate(clubRegistrationState.objData.clubRegistrations.length === 1 ? NavLinks.CLUB_REGISTRATION_ENTRY_NEW : NavLinks.CLUB_REGISTRATION_ENTRY);
        }
        else if (state.submitButton === ClubRegistrationConstants.BACK) {
          navigate(NavLinks.CLUB_REGISTRATION_EMERGENCY_PLAN);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state, errorState, isSubmitting, clubRegistrationState.isObjLoaded, clubRegistrationState.isSaving])

  return {
    clubRegistrationPaymentState,
    clubRegistrationNavState,
    clubRegistrationState,
    offeringTypeState,
    state,
    modalState,
    formState,
    errorState,
    uiState,
    handleSubmit,
    onFormValueChanged: updateFormState,
    onModalContinue,
    onModalCanceled,
    onContinueClicked,
    onSaveAndExitClicked,
    onBackClicked,
    onNavigateBack,
    onError,
    onPreCompletion,
    onCompletion,
    onPostCompletion
  }
}

export default useClubRegistrationSubmit;

function getFormInitialState() {
  return {
    today: Constants.BLANK_DATE_STRING,
    registrationSignature: '',
    registrationDate: Constants.BLANK_DATE_STRING,
    lscname: '',
    selectedRegistration: {},
    usasFeeAmount: '',
    offeringName: '',
    lscFeeAmount: ''
  }
};

function getInitialModalState() {
  return {
    displayPopUp: false,
    modalTitle: ClubRegistrationConstants.MISSING_REQUIRED_INFORMATION
  }
};