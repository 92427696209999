import { useContext, useEffect } from 'react';

import { PreCompBusinessEntityTypeStateContext } from './PreCompBusinessEntityTypeContextProvider';
import PreCompBusinessEntityTypeData from './PreCompBusinessEntityTypeData';

const usePreCompBusinessEntityTypeData = () => {
  const [preCompBusinessEntityTypeState, setPreCompBusinessEntityTypeState] = useContext(PreCompBusinessEntityTypeStateContext);

  const getBusinessEntityTypes = () => { PreCompBusinessEntityTypeData.getPreCompBusinessEntityTypes(preCompBusinessEntityTypeState, setPreCompBusinessEntityTypeState); };

  useEffect(() => {
    if (preCompBusinessEntityTypeState.isArrayLoading !== true && preCompBusinessEntityTypeState.isArrayLoaded !== true) {
      getBusinessEntityTypes(preCompBusinessEntityTypeState, setPreCompBusinessEntityTypeState);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    preCompBusinessEntityTypeState,
    getBusinessEntityTypes,
  };
};

export default usePreCompBusinessEntityTypeData;