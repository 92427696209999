import React, { createContext, useState } from 'react';

import TouchpointTypeData from './TouchpointTypeData';

export const TouchpointTypeStateContext = createContext();

const TouchpointTypeContextProvider = ({ children }) => {
  const stateHook = useState(TouchpointTypeData.INITIAL_STATE);

  return (
    <TouchpointTypeStateContext.Provider value={stateHook}>
      {children}
    </TouchpointTypeStateContext.Provider>
  );
};

export default TouchpointTypeContextProvider;