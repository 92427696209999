import usePreCompInstructorStudentRatioData from '../../../../state/preCompInstructorStudentRatio/UsePreCompInstructorStudentRatioData';

const useInstructorStudentRatioDropdown = () => {
  const { preCompInstructorStudentRatioState } = usePreCompInstructorStudentRatioData();

  return {
    preCompInstructorStudentRatioState
  };
};

export default useInstructorStudentRatioDropdown;