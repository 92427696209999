import { useState, useEffect } from 'react';
import { useNavigate, useLocation } from '../../../../common/wrappers/ReactRouterDom';

import NavLinks from '../NavLinks';

import useUsasClubFacilityLinksData from '../../../state/clubFacilityLinks/UseUsasClubFacilityLinksData';

import useBaseUsasClubData from '../../../../common/state/clubBase/UseBaseUsasClubData';
import useFacilityData from '../../../../common/state/facility/UseFacilityData';

import Constants from '../../../../common/utils/Constants';

const useClubFacilities = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { usasClubFacilityLinksState, getUsasClubFacilityLinks, deleteUsasClubFacilityLinks } = useUsasClubFacilityLinksData();
  const { baseUsasClubState } = useBaseUsasClubData();
  const { facilityState, deleteFacilityPool } = useFacilityData();
  const [state, setState] = useState({ tryGet: false });
  const [modalState, setModalState] = useState(getInitialModalState());
  const [poolModalState, setPoolModalState] = useState(getInitialPoolModalState());

  const onDeleteFacilityFromClub = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    deleteUsasClubFacilityLinks(baseUsasClubState.objData.usasClubId, modalState.orgUnitFacilityId);
    setModalState({
      ...getInitialModalState()
    });
    setState({ ...state, tryGet: true });
  };

  const onAddFacility = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    navigate(NavLinks.CLUB_FACILITIES_SEARCH);
  };

  const onEditFacility = (e, facilityId) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    navigate(NavLinks.CLUB_FACILITIES_DETAILS, { state: { facilityId: facilityId } });
  };

  const onAddPool = (e, facilityId) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    navigate(NavLinks.CLUB_FACILITIES_POOLS_DETAIL, { state: { facilityId: facilityId, } });
  };

  const onEditPool = (e, facilityId, poolId) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    navigate(NavLinks.CLUB_FACILITIES_POOLS_DETAIL, { state: { facilityId: facilityId, poolId: poolId } });
  };

  const onDeletePool = (e, pool) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    setPoolModalState({
      ...poolModalState,
      displayPopup: true,
      poolId: pool.facilityPoolId,
      poolName: pool.facilityPoolName,
      poolDimensions: pool.poolDimensions?.dimensionName || ''
    });
  };

  const onClickDeletePool = (e, poolId) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    deleteFacilityPool(poolId);
    setPoolModalState(getInitialPoolModalState());
    setState({ ...state, tryGet: true });
  };

  const onPoolModalCanceled = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    setPoolModalState(getInitialPoolModalState());
  };

  const onModalCanceled = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    setModalState(getInitialModalState());
  };

  const onOpenModalClicked = (e, facilityId, facilityName) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    const targetIndex = usasClubFacilityLinksState.arrayData.findIndex((usasClubFacility) => usasClubFacility.facilityId === facilityId);

    if (targetIndex >= 0) {
      setModalState({
        ...modalState,
        displayPopUp: true,
        facilityId: facilityId,
        facilityName: facilityName,
        facilityCity: usasClubFacilityLinksState.arrayData[targetIndex].facility.city,
        facilityStateCode: usasClubFacilityLinksState.arrayData[targetIndex].facility.stateCode,
        facilityPostalCode: usasClubFacilityLinksState.arrayData[targetIndex].facility.postalCode,
        targetIndex,
        orgUnitFacilityId: usasClubFacilityLinksState.arrayData[targetIndex].orgUnitFacilityId
      });
    }
  };

  useEffect(() => {
    if (usasClubFacilityLinksState.isArrayLoaded === false) {
      if (baseUsasClubState.isObjLoaded === true && usasClubFacilityLinksState.isArrayLoading === false
        && usasClubFacilityLinksState.isSaving === false) {
        getUsasClubFacilityLinks(baseUsasClubState.objData.usasClubId);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [usasClubFacilityLinksState, baseUsasClubState]);

  useEffect(() => {
    if (location.state && (location.state.addedPool === true
      || location.state.hasNewFacilityDetails === true)) {
      setState({ ...state, tryGet: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (state.tryGet === true) {
      if (baseUsasClubState.isObjLoaded === true && usasClubFacilityLinksState.isArrayLoading === false
        && usasClubFacilityLinksState.isSaving === false && facilityState.isSaving === false) {
        getUsasClubFacilityLinks(baseUsasClubState.objData.usasClubId);
        setState({ ...state, tryGet: false });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state, baseUsasClubState, usasClubFacilityLinksState, facilityState]);

  return {
    usasClubFacilityLinksState,
    facilityState,
    modalState,
    poolModalState,
    onAddFacility,
    onEditFacility,
    onAddPool,
    onEditPool,
    onDeletePool,
    onClickDeletePool,
    onPoolModalCanceled,
    onModalCanceled,
    onOpenModalClicked,
    onDeleteFacilityFromClub
  };
};

export default useClubFacilities;

function getInitialModalState() {
  return {
    displayPopUp: false,
    modalTitle: 'Unlink Facility From Club?',
    facilityId: Constants.DEFAULT_ID,
    facilityName: '',
    facilityCity: '',
    facilityStateCode: '',
    facilityPostalCode: '',
    targetIndex: undefined,
    orgUnitFacilityId: Constants.DEFAULT_ID
  };
};

function getInitialPoolModalState() {
  return {
    modalTitle: 'Delete this pool from the facility?',
    displayPopup: false,
    poolId: Constants.DEFAULT_ID,
    poolName: '',
    poolDimensions: ''
  };
};