import { useEffect, useState } from 'react';
import { useNavigate } from '../../../../common/wrappers/ReactRouterDom';

import NavLinks from '../NavLinks';

import useProgramLevelInstanceData from '../../../state/programLevelInstance/UseProgramLevelInstanceData';

import useEnvironmentVariableData from '../../../../common/state/environmentVariable/UseEnvironmentVariableData';
import useSelectOrgUnitData from '../../../../common/state/selectOrgUnit/UseSelectOrgUnitData';

import Constants from '../../../../common/utils/Constants';
import useRecognitionProgramData from '../../../../common/state/recognitionProgram/UseRecognitionProgramData';

const useClubRecognitionProgramLevel = () => {
  const navigate = useNavigate();
  const { environmentVariableState, CLUB_RECOGNITION_PROGRAM_TYPE_ID, CRP_MAX_PROGRAM_LEVEL_ID,
    RECOGNITION_PROGRAM_STATUS_ACHIEVED } = useEnvironmentVariableData();
  const { selectOrgUnitState } = useSelectOrgUnitData();
  const { recognitionProgramState, getCurrentProgramByProgramType, postProgramLevelInitiation, postProgramLevelRenewal,
    updateProgramFocus } = useRecognitionProgramData();
  const { submitLevelState, postProgramLevelInstanceSubmitLevel } = useProgramLevelInstanceData();
  const [modalState, setModalState] = useState(getInitialModalState());
  const [buttonsState, setButtonsState] = useState(getInitialButtonsState);

  const onSubmitLevelInstance = (e, programLevelInstanceId) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    const programLevelObj = recognitionProgramState.arrayData.find((programLevel) =>
      programLevel.programLevelInstanceId === programLevelInstanceId);

    if (programLevelObj) {
      const validationResult = validateProgramLevel(programLevelObj);

      setModalState({
        ...modalState,
        programLevelInstanceId: programLevelInstanceId,
        canSubmit: !(validationResult.pointsErrors.length > 0 || validationResult.requiredErrors.length > 0),
        pointsErrors: validationResult.pointsErrors,
        requiredErrors: validationResult.requiredErrors,
        modalTitle: `Submit ${programLevelObj.levelName}?`,
        displayPopUp: true
      });
    }
  };

  const onInitiateProgramLevel = () => {
    if (buttonsState.initiateOrgUnitId) {
      postProgramLevelInitiation(buttonsState.initiateOrgUnitId, CLUB_RECOGNITION_PROGRAM_TYPE_ID);
    }
  };

  const onRenewProgramLevel = () => {
    if (buttonsState.renewProgramLevelInstanceId > 0) {
      postProgramLevelRenewal(buttonsState.renewProgramLevelInstanceId);
    }
  };

  const onEditProgramLevel = (e, programLevelInstanceId, programCategoryId) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    navigate(NavLinks.CLUB_RECOGNITION_PROGRAM_CATEGORY, { state: { programLevelInstanceId, programCategoryId } });
  };

  const onModalSubmit = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    if (modalState.programLevelInstanceId > 0 && modalState.canSubmit === true) {
      const programLevelObj = recognitionProgramState.arrayData.find((programLevel) => programLevel.programLevelInstanceId === modalState.programLevelInstanceId);

      postProgramLevelInstanceSubmitLevel(programLevelObj.programLevelInstanceId);
      setModalState(getInitialModalState());
    }
  };

  const onModalCanceled = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    setModalState(getInitialModalState());
  };

  useEffect(() => {
    if (environmentVariableState.isLoaded === true && submitLevelState.isSaving === false && selectOrgUnitState?.orgUnitId) {
      getCurrentProgramByProgramType(selectOrgUnitState.orgUnitId, CLUB_RECOGNITION_PROGRAM_TYPE_ID);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [environmentVariableState, submitLevelState, selectOrgUnitState.orgUnitId]);

  useEffect(() => {
    if (environmentVariableState.isLoaded === true && submitLevelState.isSaving === false
      && selectOrgUnitState?.orgUnitId && recognitionProgramState.isArrayLoaded === true) {
      let initiateButtonObj = {
        showInitiateButton: false,
        initiateOrgUnitId: '',
      };
      let renewButtonObj = {
        showRenewButton: false,
        renewProgramLevelInstanceId: Constants.DEFAULT_ID
      };

      if (Array.isArray(recognitionProgramState.arrayData)) {
        if (recognitionProgramState.arrayData.length === 0) {
          initiateButtonObj = {
            showInitiateButton: true,
            initiateButtonLevel: 1,
            initiateOrgUnitId: selectOrgUnitState.orgUnitId
          };
        } else {
          const highestProgramLevelId = Math.max.apply(null, recognitionProgramState.arrayData.map(x => { return x.programLevelId; }));

          if (highestProgramLevelId > 0) {
            const newestProgram = recognitionProgramState.arrayData.find(x => x.programLevelId === highestProgramLevelId);

            if (newestProgram && newestProgram.status === RECOGNITION_PROGRAM_STATUS_ACHIEVED) {
              renewButtonObj = {
                showRenewButton: true,
                renewButtonLevel: highestProgramLevelId,
                renewProgramLevelInstanceId: newestProgram.programLevelInstanceId
              };

              if (highestProgramLevelId < CRP_MAX_PROGRAM_LEVEL_ID) {
                initiateButtonObj = {
                  showInitiateButton: true,
                  initiateButtonLevel: highestProgramLevelId + 1,
                  initiateOrgUnitId: selectOrgUnitState.orgUnitId
                };
              }
            }
          }
        }
      }

      setButtonsState({
        ...buttonsState,
        ...initiateButtonObj,
        ...renewButtonObj
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectOrgUnitState.orgUnitId, environmentVariableState, submitLevelState, recognitionProgramState]);

  useEffect(() => {
    updateProgramFocus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function validateProgramLevel(programLevel) {
    const validationObj = {
      pointsErrors: [],
      requiredErrors: []
    };

    if (programLevel.currentPointValue < programLevel.minimumValue) { // level minimum points check
      validationObj.pointsErrors.push(`Minimum Points for ${programLevel.levelName} not met | Current Points: ${programLevel.currentPointValue} | Minimum Points: ${programLevel.minimumValue}`);
    }

    if (Array.isArray(programLevel.programCategory)) {
      for (const category of programLevel.programCategory) { // categories minimum points check
        if (category.currentPointValue < category.minimumValue) {
          validationObj.pointsErrors.push(`Minimum Points for ${category.categoryName} not met | Current Points: ${category.currentPointValue} | Minimum Points: ${category.minimumValue}`);
        }

        if (Array.isArray(category.categoryMeasure)) {
          for (const measure of category.categoryMeasure) { // measures is required check
            if (measure.isRequired === true && (!(measure.score > 0) || measure.score < measure.minValue)) {
              validationObj.requiredErrors.push(`${measure.measureName} in ${category.categoryName} is required`);
            }
          }
        }
      }
    }

    return validationObj;
  };

  return {
    isLoading: recognitionProgramState.isArrayLoading || environmentVariableState.isLoading,
    isSaving: submitLevelState.isSaving || recognitionProgramState.isSaving,
    recognitionProgramState,
    modalState,
    buttonsState,
    onSubmitLevelInstance,
    onEditProgramLevel,
    onModalCanceled,
    onModalSubmit,
    onInitiateProgramLevel,
    onRenewProgramLevel
  };
};

function getInitialButtonsState() {
  return {
    showInitiateButton: false,
    initiateButtonLevel: '',
    initiateOrgUnitId: '',
    showRenewButton: false,
    renewButtonLevel: '',
    renewProgramLevelInstanceId: Constants.DEFAULT_ID
  };
};

function getInitialModalState() {
  return {
    programLevelInstanceId: Constants.DEFAULT_ID,
    canSubmit: false,
    pointsErrors: [],
    requiredErrors: [],
    modalTitle: 'Submit Level?',
    displayPopUp: false
  };
};

export default useClubRecognitionProgramLevel;