import { useEffect } from "react";

import validate from './KpiOverrideScoreFormValidation';

import UseForm from "../../../../common/utils/UseForm";

const INITIAL_FORM_STATE = {
  scoreEntryOverride: false,
  score: '',
  minValue: 0,
  maxValue: 0
};

const useKpiOverrideScoreForm = (programLevel, categoryMeasure, onRedirect, submitFormCallback) => {
  const { formState, errorState, isDirty, updateFormState, handleSubmit, setFormData
  } = UseForm(INITIAL_FORM_STATE, submitFormCallback, validate);

  const onSaveClicked = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    if (isDirty === false) {
      onRedirect(false);
    } else {
      handleSubmit();
    }
  };

  useEffect(() => {
    if (categoryMeasure && programLevel) {
      setFormData({
        ...formState,
        scoreEntryOverride: categoryMeasure.scoreEntryOverride,
        score: categoryMeasure.score,
        minValue: categoryMeasure.minValue,
        maxValue: categoryMeasure.maxValue
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categoryMeasure, programLevel]);

  return {
    formState,
    errorState,
    onFormValueChanged: updateFormState,
    onSaveClicked
  };
};

export default useKpiOverrideScoreForm;