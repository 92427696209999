import React from 'react';

import EditIcon from '../../../../../common/components/icons/EditIcon';
import DeleteIcon from '../../../../../common/components/icons/DeleteIcon';

import global from '../../../../../common/components/GlobalStyle.module.css';

const GridRow = ({ offering, onEdit, onDelete }) => (
  <tr>
    <td>{offering.preCompOfferingType?.typeName || ''}</td>
    <td>{offering.enrollmentCount ?? ''}</td>
    <td>{offering.scholarshipCount ?? ''}</td>
    <td>
      {onEdit !== undefined &&
        <button
          className={global.IconButtonMargin}
          type="button"
          onClick={() => onEdit(offering.preCompLocationOfferingId)}>
          <EditIcon />
        </button>
      }
      {onDelete !== undefined &&
        <button
          className={global.IconButtonMargin}
          type="button"
          onClick={() => onDelete(offering.preCompLocationOfferingId)}>
          <DeleteIcon />
        </button>
      }
    </td>
  </tr>
);

const PreCompLocationProgramsGridLarge = ({ data, isLoading, onEdit, onDelete }) => (
  <table className={[global.UsasTable, 'visible-md visible-lg'].join(' ')}>
    <thead>
      <tr>
        <th>Offering Type</th>
        <th>Avg. Number of Participants</th>
        <th>Avg. Number of Scholarships</th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      {isLoading === true
        ? <tr><td colSpan="4">Loading...</td></tr>
        : data.length > 0
          ? data.map((offering, i) => <GridRow key={i} offering={offering} onEdit={onEdit} onDelete={onDelete} />)
          : <tr><td colSpan="4">No Offerings</td></tr>
      }
    </tbody>
  </table>
);

export default PreCompLocationProgramsGridLarge;