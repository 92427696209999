import { Fragment } from "react";
import global from '../../../../common/components/GlobalStyle.module.css';
import Input from '../../../../common/components/inputs/Input';
import PrimaryButton from '../../../../common/components/buttons/PrimaryButton';
import SecondaryButton from '../../../../common/components/buttons/SecondaryButton';
import DatePicker from '../../../../common/components/datepickers/DatePicker';
import ReadOnly from '../../../../common/components/readOnly/ReadOnly';
import BpMeetClassificationTreeView from '../../../../common/components/tree/bpMeetClassificationTree/BpMeetClassificationTreeView';
import Dropdown from '../../../../common/components/dropdowns/Dropdown';
import CourseMultiSelect from '../../../../common/components/multiSelect/courseMultiSelect/CourseMultiSelect';
import MeetSoftwareDropdown from '../../../../common/components/dropdowns/meetSoftwareDropdown/MeetSoftwareDropdown';
import SearchMemberWithLscPopup from "../../../../common/components/searches/searchMember/SearchMemberWithLscPopup";
import PopUpModal from "../../../../common/components/dialogs/PopUpModal";
import YesNoSwitch from "../../../../common/components/yesNoSwitch/YesNoSwitch";

const ClubBlockPartyMeetAddForm = ({
  handleSubmitProxy,
  formState,
  errorState,
  facilityOptionsState,
  state,
  modalState,
  orgUnitId,
  onBackClicked,
  onUploadTemplateClicked,
  onOpenRemoveFileModalClicked,
  onFormValueChanged,
  onValueTextPairChanged,
  onModalCancelClicked,
  BLOCK_PARTY_MEET_CLASSIFICATION_PARENT_ID,
  GET_MEET_FILES_PATH,
  setFormState,
  status
}) => (
  <Fragment>
    <form onSubmit={handleSubmitProxy} noValidate>
      {status !== 'Paid' &&
        <>
          <div className="col-xs-12">
            Meet Announcement Document (optional)
          </div>
          <div className="col-xs-12 usas-extra-bottom-margin usas-extra-top-margin">
            {formState.meetAnnouncementUrl === '' || formState.meetAnnouncementUrl === 'Invalid file type' ?
              <Fragment>
                <label htmlFor="announcement-file-upload" className={global.FileUpload}>
                  Upload File
                </label>
                <input hidden id="announcement-file-upload" type="file" onChange={onUploadTemplateClicked} />
                &nbsp;(*.pdf), (*.doc), or (*.docx)
              </Fragment>
              :
              <Fragment>
                <SecondaryButton onClick={onOpenRemoveFileModalClicked}>Remove Uploaded File</SecondaryButton>&nbsp;
                <PrimaryButton type="button" onClick={(event) => {
                  event.preventDefault();
                  const fileUrl = formState.meetAnnouncementUrl;
                  window.location.href = import.meta.env.VITE_MEETAPI_URL + GET_MEET_FILES_PATH + fileUrl;
                }}>Download Uploaded File</PrimaryButton>
              </Fragment>}
          </div>
        </>
      }

      {formState.meetAnnouncementUrl !== "Invalid file type" &&
        <div className="usas-extra-bottom-margin" hidden={formState.meetAnnouncementUrl === ''}>
          <div className="col-xs-12">
            <p><b>Uploaded File Name: </b> {formState.meetAnnouncementFileName}</p>
          </div>
        </div>
      }
      {formState.meetAnnouncementUrl === "Invalid file type" &&
        <div className="col-xs-12">
          <p className={global.ErrorMessage} style={{ fontSize: 16, fontWeight: 'bold' }}>{formState.meetAnnouncementUrl}</p>
        </div>
      }
      {state.error &&
        <div className="col-xs-12">
          <p className={global.ErrorMessage}>{state?.error ? state?.error : ''}</p>
        </div>
      }
      <div className="col-xs-12 usas-extra-top-margin"></div>
      <div className="col-xs-12 col-sm-6 col-md-4">
        <ReadOnly
          label="Year for Meet Name"
          name="autogeneratedMeetYear"
          value={formState.autogeneratedMeetYear}
        />
      </div>
      <div className="col-xs-12 col-sm-6 col-md-4">
        <Input
          label="Meet Name* (Do not include Year or LSC)"
          name="meetName"
          value={formState.meetName}
          error={errorState.meetName}
          message={errorState.meetName}
          onChange={(value) => { onFormValueChanged('meetName', value); }} />
      </div>
      <div className="col-xs-12 col-sm-6 col-md-4">
        <DatePicker label="Start Date*"
          name="startDate"
          value={formState.startDate}
          error={errorState.startDate}
          message={errorState.startDate}
          onChange={(value) => {
            onFormValueChanged('startDate', value);
            onFormValueChanged('endDate', value);
            onFormValueChanged('autogeneratedMeetYear', value.toString().substring(6, 10) !== '0000' ? value.toString().substring(6, 10) : '')
          }}
          countOfYears={5}
          startYearOffset={0} />
      </div>
      <div className="col-xs-12 col-sm-6 col-md-4">
        {status !== 'Paid' ?
          <BpMeetClassificationTreeView
            label="Meet Classification*"
            name="meetClassification"
            value={formState.meetClassification}
            error={errorState.meetClassification}
            message={errorState.meetClassification}
            selectableLeavesOnly={true}
            singleSelect={true}
            onChange={(newValue) => {
              onFormValueChanged('meetClassification', newValue);
              onFormValueChanged('meetStaffTypeLabel', newValue[0].id === 103 ? 'Meet Referee' : 'Meet Contact')
            }}
            orgUnitId={orgUnitId || ''}
            filterId={BLOCK_PARTY_MEET_CLASSIFICATION_PARENT_ID}
            filterMode={"include_only_filter_id"} />
          :
          <ReadOnly
            label="Meet Classification"
            name="meetClassification"
            value={formState.meetClassification[0]?.name}
          />
        }
      </div>
      <div className="col-xs-12 col-sm-6 col-md-4">
        {status !== 'Paid' ?
          <Dropdown
            label="Facility*"
            name="facilityId"
            value={formState.facilityId}
            error={errorState.facilityId}
            message={errorState.facilityId}
            options={facilityOptionsState}
            onChange={(newValue, newValueLabel, e) => 
              { 
                onValueTextPairChanged(newValue, 'facilityId', newValueLabel, 'facilityName', e); 
                onFormValueChanged('depthRequirementMet', false);
                onFormValueChanged('inWaterStart', false);
              }} />
          :
          <ReadOnly
            label="Facility"
            name="facilityName"
            value={formState.facilityName}
          />
        }
      </div>
      <div className="col-xs-12 col-sm-6 col-md-4">
        <CourseMultiSelect
          label="Meet Course(s)*"
          name="meetCourse"
          value={formState.meetCourse}
          error={errorState.meetCourse}
          message={errorState.meetCourse}
          onChange={(value) => { onFormValueChanged('meetCourse', value); }}
          isVisible={status !== 'Paid'} />

        {status === 'Paid' &&
          <ReadOnly
            label="Meet Course"
            name="meetCourse"
            value={formState.meetCourseForDisplay}
          />
        }
      </div>
      <div className="col-xs-12 col-sm-6 col-md-4">
        {status !== 'Paid' ?
          <MeetSoftwareDropdown
            label="Meet Management Software*"
            name="meetSoftwareId"
            value={formState.meetSoftwareId}
            error={errorState.meetSoftwareId}
            message={errorState.meetSoftwareId}
            onChange={(newValue, newValueLabel, e) => { onValueTextPairChanged(newValue, 'meetSoftwareId', newValueLabel, 'meetSoftwareName', e); }} />
          :
          <ReadOnly
            label="Meet Management Software"
            name="softwareName"
            value={formState.softwareName}
          />
        }
      </div>
      <div className="col-xs-12 col-sm-6 col-md-4">
        {status !== 'Paid' ?
          <SearchMemberWithLscPopup
            label={`${formState.meetStaffTypeLabel}*`}
            memberIdName="meetStaffMemberId"
            formState={formState}
            errorState={errorState}
            setFormData={setFormState}
            onFormValueChanged={onFormValueChanged} />
          :
          <ReadOnly
            label={`${formState.meetStaffTypeLabel}`}
            name="meetStaffMemberId"
            value={formState.meetStaffMemberId}
          />
        }
      </div>
      <div className="col-xs-12 col-sm-6 col-md-4" hidden={formState.meetSanctionNumber === ''}>
        <ReadOnly
          label="Meet Sanction Number"
          name="meetSanctionNumber"
          value={formState.meetSanctionNumber}
        />
      </div>
      <div className="row">
        <div className="col-xs-12 usas-extra-top-margin usas-extra-bottom-margin">
          <PrimaryButton type="submit">Save {status !== 'Paid' && formState.hasCartItem === false ? 'and Pay' : ''}</PrimaryButton>&nbsp;
          <SecondaryButton type="button" onClick={onBackClicked}>Back</SecondaryButton>&nbsp;&nbsp;
        </div>
      </div>
      <PopUpModal
        widthPct={90}
        maxWidth={720}
        title="Facility Pool Depth Requirement Confirmation"
        displayPopUp={modalState.displayModal}
        onModalCanceled={onModalCancelClicked}>
        <div className="row">
          <div className="col-xs-12">
            <p><b>Facility Name:</b> {modalState.facilityName}</p>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12">
            Please confirm that minimum water depth for racing starts during practice and competition measured for a distance 3 feet 3-1/2 inches (1.0 meter) to 16 feet 5 inches (5.0 meters) from the end wall is at least 4 feet. 
            <br />
            <p style={{fontWeight: 'bold'}}>Selecting 'Pool Depth Requirement Met' will update the facility.</p>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12">
            {/* <RadiobuttonList
              label="Facility Confirmation Options*"
              name={"facilityConfirmationOptionId"}
              items={facilityConfirmationState}
              value={formState.facilityConfirmationOptionId}
              error={errorState.facilityConfirmationOptionId}
              message={errorState.facilityConfirmationOptionId}
              isVertical={true}
              onChange={(value) => { onFormValueChanged("facilityConfirmationOptionId", value) }} /> */}
            <YesNoSwitch
              label="Pool Depth Requirement Met?"
              name="depthRequirementMet"
              checked={formState.depthRequirementMet}
              error={errorState.depthRequirementMet}
              message={errorState.depthRequirementMet}
              onChange={(value) => { onFormValueChanged('depthRequirementMet', value); }}
            />
          </div>
        </div>
        <div className="row usas-extra-top-margin">
          <div className="col-xs-12">
            The pool depth confirmation is not required if in-water starts will be used.
            <br />
            <YesNoSwitch
              label="In-Water Start?"
              name="isInWaterStart"
              checked={formState.isInWaterStart}
              error={errorState.isInWaterStart}
              message={errorState.isInWaterStart}
              onChange={(value) => { onFormValueChanged('isInWaterStart', value); }}
            />
          </div>
        </div>
        <div className="row usas-extra-top-margin">
          <div className="col-xs-12">
            <SecondaryButton type="button" onClick={onModalCancelClicked}>Continue</SecondaryButton>
          </div>
        </div>
      </PopUpModal>
    </form>
  </Fragment>
);

export default ClubBlockPartyMeetAddForm;