import { useEffect, useState } from 'react';
import { useNavigate, useLocation } from '../../../../../common/wrappers/ReactRouterDom';

import validate from './EditLSCRoleEmailValidation';

import { navLinks } from '../../UseMemberAdministration';

import MemberAdministrationConstants from '../../utils/MemberAdministrationConstants';

import useMemberAdministrationLscEmailData from '../../../../../common/state/memberAdministration/lscEmail/UseMemberAdministrationLscEmailData';
import useEnvironmentVariableData from '../../../../../common/state/environmentVariable/UseEnvironmentVariableData';
import useOrgRoleStaffRoleHierarchyData from '../../../../../common/state/orgRoleStaffRoleHierarchy/UseOrgRoleStaffRoleHierarchyData';

import UseForm from '../../../../../common/utils/UseForm';
import Constants from '../../../../../common/utils/Constants';

const UseEditLSCRoleEmail = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { LSC_EMAIL_ROLE_GROUP_ID, environmentVariableState } = useEnvironmentVariableData();
  const { memberAdministrationLscRoleEmailsState, putLscRoleEmail } = useMemberAdministrationLscEmailData();
  const { orgRoleStaffRoleHierarchyState, getOrgRolesStaffRolesHierarchy } = useOrgRoleStaffRoleHierarchyData();
  const { formState, errorState, handleSubmit, setFormData, updateFormState, setIsDirty
  } = UseForm(getInitialFormState, submitFormCallback, validate);
  const [viewState, setViewState] = useState(getInitialViewState);

  const onCancelClicked = () => {
    navigate(navLinks.LSC_EMAILS);
  };

  useEffect(() => {
    if (location.state?.orgUnitOrgRoleFieldId > 0 && location.state?.activeUserOrgUnitId
      && environmentVariableState.isLoaded === true) {
      const orgUnitOrgRoleFieldId = location.state.orgUnitOrgRoleFieldId;

      const targetRoleEmail = memberAdministrationLscRoleEmailsState.arrayData.find(x => x.orgUnitOrgRoleFieldId === orgUnitOrgRoleFieldId);

      if (targetRoleEmail) {
        setViewState({
          ...viewState,
          orgRoleName: targetRoleEmail.orgRoleName,
          orgRoleId: targetRoleEmail.orgRoleId,
          orgUnitOrgRoleFieldId,
          activeUserOrgUnitId: location.state.activeUserOrgUnitId,
        });
        setFormData({
          ...formState,
          email: targetRoleEmail.email
        });
        setIsDirty(true);
        getOrgRolesStaffRolesHierarchy(LSC_EMAIL_ROLE_GROUP_ID);
      }
    }
    else {
      onCancelClicked();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [environmentVariableState.isLoaded]);

  useEffect(() => {
    if (orgRoleStaffRoleHierarchyState.isArrayLoading === false && orgRoleStaffRoleHierarchyState.isArrayLoaded === true && viewState.orgRoleId) {
      setViewState({
        ...viewState,
        orgRoleFieldId: getOrgRoleFieldId(viewState.orgRoleId, orgRoleStaffRoleHierarchyState.arrayData)
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orgRoleStaffRoleHierarchyState, viewState.orgRoleId]);

  useEffect(() => {
    if (memberAdministrationLscRoleEmailsState.isSaved === true) {
      navigate(navLinks.LSC_EMAILS);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [memberAdministrationLscRoleEmailsState.isSaved]);

  function submitFormCallback(formState) {
    putLscRoleEmail(viewState.orgUnitOrgRoleFieldId, createOrgRoleFieldObj(viewState, formState));
  };

  function getOrgRoleFieldId(orgRoleId, arrayData) {
    for (const roleType of arrayData) {
      for (const role of roleType.children) {
        if (role.id === orgRoleId && Array.isArray(role.orgRoleField)) {
          for (const roleField of role.orgRoleField) {
            if (roleField.fieldName === MemberAdministrationConstants.ORG_ROLE_FIELD_NAME_EMAIL) {
              return roleField.orgRoleFieldId;
            }
          }
        }
      }
    }
  };

  function createOrgRoleFieldObj(viewState, formState) {
    return {
      orgUnitOrgRoleFieldId: viewState.orgUnitOrgRoleFieldId,
      orgRoleFieldId: viewState.orgRoleFieldId,
      orgUnitId: viewState.activeUserOrgUnitId,
      fieldValue: formState.email
    };
  };

  function getInitialFormState() {
    return {
      email: ''
    };
  };

  function getInitialViewState() {
    return {
      orgRoleName: '',
      orgRoleId: '',
      orgRoleFieldId: Constants.DEFAULT_ID,
      orgUnitOrgRoleFieldId: Constants.DEFAULT_ID,
      activeUserOrgUnitId: ''
    };
  };

  return {
    isLoading: memberAdministrationLscRoleEmailsState.isArrayLoading,
    isSaving: memberAdministrationLscRoleEmailsState.isSaving,
    formState,
    errorState,
    viewState,
    onFormValueChanged: updateFormState,
    handleSubmit,
    onCancelClicked
  };
};

export default UseEditLSCRoleEmail;