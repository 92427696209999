import GroupInfoFormValidation from "../../../../../common/components/forms/groups/GroupInfoFormValidation";
import PracticeAttributesFormValidation from "../../../../../common/components/forms/groups/PracticeAttributesFormValidation";

const ClubGroupTypePracticeValidation = async (formState) => {
  let errors = {
    ...GroupInfoFormValidation(formState),
    ...PracticeAttributesFormValidation(formState)
  };

  return errors ? errors : {};
};

export default ClubGroupTypePracticeValidation;