import React, { createContext, useState } from 'react';

import PoolConfigurationData from './PoolConfigurationData';

export const PoolConfigurationStateContext = createContext();

const PoolConfigurationContextProvider = ({ children }) => {
  const stateHook = useState(PoolConfigurationData.INITIAL_STATE);

  return (
    <PoolConfigurationStateContext.Provider value={stateHook}>
      {children}
    </PoolConfigurationStateContext.Provider>
  );
};

export default PoolConfigurationContextProvider;