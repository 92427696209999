import { useEffect } from 'react';

import validate from './SearchClubValidation';

import useBaseUsasClubData from '../../../state/clubBase/UseBaseUsasClubData';
import useSearchClubData from '../../../state/searchClub/UseSearchClubData';
import useLscData from '../../../state/lsc/UseLscData';
import useEnvironmentVariableData from '../../../state/environmentVariable/UseEnvironmentVariableData';

import useStatesCombobox from '../../comboboxes/statesCombobox/UseStatesCombobox';

import useForm from '../../../utils/UseForm';
import Constants from '../../../utils/Constants';

const sortByOptions = [
  { id: 1, name: "Club Name (A-Z)", value: 'clubName' },
  { id: 2, name: "LSC Code (A-Z)", value: 'lscCode' },
  { id: 3, name: "Club Code (A-Z)", value: 'clubCode' },
  { id: 4, name: "Location (A-Z)", value: 'location' },
  { id: 5, name: "Number of Members", value: 'memberCount' }];

const isCurrentOptions = [
  { id: 1, name: "--", value: '' },
  { id: 2, name: "Current", value: true },
  { id: 3, name: "Not Current", value: false }];

const useSearchClub = () => {
  const { baseUsasClubState, searchUsasClubs, baseUsasClubFiltersState } = useBaseUsasClubData();
  const { searchClubState, setSearchClubState } = useSearchClubData();
  const { lscState } = useLscData();
  const { getStateNameById } = useStatesCombobox();
  const { environmentVariableState, USAS_ORGANIZATION_ID } = useEnvironmentVariableData();
  const { formState, errorState, handleSubmit, updateFormState, setFormState, setErrors, onValueTextPairChanged,
    setIsDirty, isDirty } = useForm(getInitialFormState(), submitFormCallback, validate);

  const clearForm = () => {
    setFormState(getInitialFormState());
    setErrors({});
  };

  const onFiltersToggle = () => {
    setSearchClubState({ ...searchClubState, showFilters: !searchClubState.showFilters });
    setIsDirty(true);
  };

  useEffect(() => {
    if (environmentVariableState.isLoaded === true) {
      if (formState.organizationId !== USAS_ORGANIZATION_ID) {
        setFormState({
          ...formState,
          organizationId: USAS_ORGANIZATION_ID,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [environmentVariableState.isLoaded, formState.organizationId]);

  useEffect(() => {
    if (formState.lscOrgUnitId === Constants.DEFAULT_ID && formState.orgUnitLabel !== Constants.USAS_ORG_UNIT_LABEL_LSC) {
      setFormState({
        ...formState,
        orgUnitLabel: Constants.USAS_ORG_UNIT_LABEL_LSC
      });
    }
    else if (formState.lscOrgUnitId !== Constants.DEFAULT_ID && formState.orgUnitLabel !== Constants.USAS_ORG_UNIT_LABEL) {
      setFormState({
        ...formState,
        orgUnitLabel: Constants.USAS_ORG_UNIT_LABEL
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formState.lscOrgUnitId]);

  useEffect(() => {
    if (Object.keys(baseUsasClubFiltersState.filterObject).length > 0) {
      const oldSearchValues = JSON.parse(JSON.stringify(baseUsasClubFiltersState.filterObject));
      let sortByIndex = undefined;
      for (let i = 0; i < sortByOptions.length; i++) {
        if (baseUsasClubFiltersState.sortBy === sortByOptions[i].value) {
          sortByIndex = i;
          break;
        }
      }

      let isCurrentIndex = undefined;
      for (let i = 0; i < isCurrentOptions.length; i++) {
        if (baseUsasClubFiltersState.filterObject.isCurrent === isCurrentOptions[i].value) {
          isCurrentIndex = i;
          break;
        }
      }

      setFormState({
        ...formState,
        ...oldSearchValues,
        zip: oldSearchValues.postalCode || '',
        stateCode: oldSearchValues.state || '',
        stateName: oldSearchValues.state ? getStateNameById(oldSearchValues.state) : '',
        sortById: sortByIndex !== undefined ? sortByOptions[sortByIndex].id : sortByOptions[0].id,
        sortByName: sortByIndex !== undefined ? sortByOptions[sortByIndex].name : sortByOptions[0].name,
        isCurrentId: isCurrentIndex !== undefined ? isCurrentOptions[isCurrentIndex].id : isCurrentOptions[0].id,
        isCurrentName: isCurrentIndex !== undefined ? isCurrentOptions[isCurrentIndex].name : isCurrentOptions[0].name,
      });
      setIsDirty(true);

      if (baseUsasClubState.isArrayLoading === false) {
        searchUsasClubs(baseUsasClubFiltersState.filterObject, baseUsasClubFiltersState.sortBy || '');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (searchClubState.showFilters === true && isDirty === false) {
      setIsDirty(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchClubState.showFilters, isDirty]);

  function submitFormCallback(formState) {
    setSearchClubState({ ...searchClubState, tryRedirect: false });

    let sortBy = undefined;
    for (const sortByOption of sortByOptions) {
      if (formState.sortById === sortByOption.id) {
        sortBy = sortByOption.value;
        break;
      }
    }

    if (baseUsasClubState.isArrayLoading === false) {
      const filterObject = createSearchFilterObj(formState);
      searchUsasClubs(filterObject, sortBy || '', {});
    }
  };

  function createSearchFilterObj(formState) {
    let isCurrent = undefined;
    if (formState.isCurrentId > 1) {
      isCurrent = isCurrentOptions.find(x => x.id === formState.isCurrentId);
    }

    return {
      organizationId: formState.organizationId > 0 ? formState.organizationId : undefined,
      lscOrgUnitId: formState.lscOrgUnitId !== Constants.DEFAULT_ID ? formState.lscOrgUnitId : undefined,
      orgUnitId: formState.orgUnitId ? formState.orgUnitId : undefined,
      orgUnitName: formState.orgUnitName.trim() || undefined,
      city: formState.city.trim() || undefined,
      state: formState.stateName !== '' ? formState.stateCode : undefined,
      postalCode: formState.zip.trim() || undefined,
      isCurrent: formState.isCurrentId > 1 ? isCurrent.value : undefined
    };
  }

  function getInitialFormState() {
    return {
      organizationId: Constants.DEFAULT_ID,
      lscOrgUnitId: Constants.DEFAULT_ID,
      lscName: '',
      orgUnitId: '',
      orgUnitName: '',
      orgUnitLabel: Constants.USAS_ORG_UNIT_LABEL_LSC,
      city: '',
      stateCode: '',
      stateName: '',
      zip: '',
      isCurrentId: isCurrentOptions[0].id,
      isCurrentName: isCurrentOptions[0].name,
      sortById: sortByOptions[0].id,
      sortByName: sortByOptions[0].name
    };
  };

  return {
    formState,
    errorState,
    baseUsasClubState,
    searchClubState,
    setSearchClubState,
    lscState,
    clearForm,
    onFormValueChanged: updateFormState,
    onValueTextPairChanged,
    handleSubmit,
    setIsDirty,
    sortByOptions,
    isCurrentOptions,
    environmentVariableState,
    onFiltersToggle
  };
};

export default useSearchClub;