import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from '../../../../common/wrappers/ReactRouterDom';

import NavLinks from '../NavLinks';

import { createLocObjFromLocProgramOfferingForm } from '../utils/PreCompUtils';

import validate from '../components/forms/PreCompLocationProgramOfferingFormValidation';

import usePreCompLocationData from '../../../state/preCompLocation/UsePreCompLocationData';

import useForm from '../../../../common/utils/UseForm';

const usePreCompLocationProgramsDetail = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { preCompLocationState, putPreCompLocation } = usePreCompLocationData();
  const { formState, errorState, handleSubmit, resetForm, updateFormState, onValueTextPairChanged, setFormData
  } = useForm(getInitialFormState, submitFormCallback, validate);
  const [displayDupModalState, setDisplayDupModalState] = useState(false);

  const onDupModalCancelClicked = () => {
    setDisplayDupModalState(false);
  };

  const onBackClicked = () => {
    navigate(NavLinks.PRE_COMP_LOCATION_PROGRAMS);
  };

  useEffect(() => {
    if (location.state?.preCompLocationOfferingId && preCompLocationState.isObjLoaded === true) {
      const targetOffering = preCompLocationState.objData.preCompLocationOffering.find(x => x.preCompLocationOfferingId === location.state.preCompLocationOfferingId);

      if (targetOffering) {
        setFormData({
          ...formState,
          preCompLocationOfferingId: targetOffering.preCompLocationOfferingId || '',
          preCompLocationOfferingName: targetOffering.preCompOfferingType?.typeName || '',
          scholarshipCount: targetOffering.scholarshipCount ?? '',
          enrollmentCount: targetOffering.enrollmentCount ?? ''
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [preCompLocationState.isObjLoaded]);

  useEffect(() => {
    if (preCompLocationState.isSaving === false && preCompLocationState.isSaved === true) {
      navigate(NavLinks.PRE_COMP_LOCATION_PROGRAMS);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [preCompLocationState]);

  function submitFormCallback(formState) {
    if (preCompLocationState.isSaving === false) {
      if (!formState.preCompLocationOfferingId) { // on add
        const existingProgramOffering = preCompLocationState.objData.preCompLocationOffering.find(x => x.preCompOfferingTypeId === formState.programOffering[0].id);

        if (existingProgramOffering) {
          setDisplayDupModalState(true);
        } else {
          putPreCompLocation(preCompLocationState.objData.preCompetitiveLocationId, createLocObjFromLocProgramOfferingForm(JSON.parse(JSON.stringify(preCompLocationState.objData)), formState));
        }
      } else {
        putPreCompLocation(preCompLocationState.objData.preCompetitiveLocationId, createLocObjFromLocProgramOfferingForm(JSON.parse(JSON.stringify(preCompLocationState.objData)), formState));
      }
    }
  };

  function getInitialFormState() {
    return {
      programOffering: [],
      preCompLocationOfferingId: '', // only used on edit
      preCompLocationOfferingName: '', // only used on edit
      scholarshipCount: '',
      enrollmentCount: ''
    };
  };

  return {
    isSaving: preCompLocationState.isSaving,
    isLoading: preCompLocationState.isObjLoading,
    formState,
    errorState,
    displayDupModalState,
    isFirstProgram: preCompLocationState.objData?.preCompLocationOffering?.length === 0,
    handleSubmit,
    resetForm,
    onFormValueChanged: updateFormState,
    onValueTextPairChanged,
    onBackClicked,
    onDupModalCancelClicked
  };
};

export default usePreCompLocationProgramsDetail;