import PrimaryButton from "../../../../common/components/buttons/PrimaryButton";
import DatePicker from "../../../../common/components/datepickers/DatePicker";
import Dropdown from "../../../../common/components/dropdowns/Dropdown";
import Input from "../../../../common/components/inputs/Input";
import useLearnToSwimVoucherForm from "./UseLearnToSwimVoucherForm";

const LearnToSwimVoucherForm = ({ onSubmitFormCallback }) => {
  const {
    formState,
    errorState,
    onFormValueChanged,
    onValueTextPairChanged,
    handleSubmit,
    competitionGenderOptions
  } = useLearnToSwimVoucherForm(onSubmitFormCallback);

  return (
    <form onSubmit={handleSubmit}>
      <div className="row">
        <div className="col-xs-12 col-sm-6 col-md-4">
          <Input
            label='First Name*'
            name="firstName"
            value={formState.firstName}
            error={errorState.firstName}
            message={errorState.firstName}
            onChange={(value) => { onFormValueChanged('firstName', value) }}
          />
        </div>
        <div className="col-xs-12 col-sm-6 col-md-4">
          <Input
            label='Last Name*'
            name="lastName"
            value={formState.lastName}
            error={errorState.lastName}
            message={errorState.lastName}
            onChange={(value) => { onFormValueChanged('lastName', value) }}
          />
        </div>
        <div className='col-xs-12 col-sm-6 col-md-4'>
          <Dropdown
            label="Competition Gender*"
            name="competitionGenderTypeId"
            options={competitionGenderOptions}
            value={formState.competitionGenderTypeId}
            error={errorState.competitionGenderTypeId}
            message={errorState.competitionGenderTypeId}
            isLoading={false}
            onChange={(newValue, newValueLabel, e) => {
              e && e.target && e.target.value &&
                onValueTextPairChanged(newValue, 'competitionGenderTypeId', newValueLabel, 'competitionGenderTypeName');
            }} />
        </div>
        <div className="col-xs-12 col-sm-6 col-md-4">
          <Input
            label='Postal Code*'
            name="postalCode"
            value={formState.postalCode}
            error={errorState.postalCode}
            message={errorState.postalCode}
            onChange={(value) => { onFormValueChanged('postalCode', value) }}
          />
        </div>
        <div className="col-xs-12 col-sm-6">
          <DatePicker
            label="Birth Date*"
            name="birthDate"
            value={formState.birthDate}
            error={errorState.birthDate}
            message={errorState.birthDate}
            onChange={(value) => { onFormValueChanged('birthDate', value); }} />
        </div>
        <div className="col-xs-12 col-sm-6">
          <Input
            label="Email*"
            name="email"
            value={formState.email}
            error={errorState.email}
            message={errorState.email}
            onChange={(value) => { onFormValueChanged('email', value); }} />
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12 usas-extra-top-margin usas-extra-bottom-margin">
          <PrimaryButton type="submit">Save</PrimaryButton>
        </div>
      </div>
    </form>
  )
}

export default LearnToSwimVoucherForm;