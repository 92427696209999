import { BASIC_INITIAL_STATE } from '../../../common/utils/HttpHelper';

import getClubSwimAThonLeaderboard from './GetClubSwimAThonLeaderboardData';

const INITIAL_STATE = {
  ...BASIC_INITIAL_STATE
};

const ClubSwimAThonLeaderboardData = {
  INITIAL_STATE,
  getClubSwimAThonLeaderboard
};

export default ClubSwimAThonLeaderboardData;