import { isValidEmail, isValidUSOrCanadianPhoneNumber, isValidName, isValidDate, isValidBirthDate } from '../../../../../common/utils/validation';
import Constants from '../../../../../common/utils/Constants';

const PreCompLocationStaffFormValidation = (formState) => {
  let errors = {};

  // Add validation only
  if (!(formState.personId)) {
    if (formState.firstName.trim() === '') {
      errors.firstName = 'First Name is required';
    } else if (!isValidName(formState.firstName.trim())) {
      errors.firstName = 'First Name cannot contain numbers, special characters, or exceed 100 characters';
    }

    if (formState.lastName.trim() === '') {
      errors.lastName = 'Last Name is required';
    } else if (!isValidName(formState.lastName.trim())) {
      errors.lastName = 'Last Name cannot contain numbers, special characters, or exceed 100 characters';
    }

    if (!formState.phoneNumber) {
      errors.phoneNumber = 'Phone Number is required';
    } else if (!isValidUSOrCanadianPhoneNumber(formState.phoneNumber)) {
      errors.phoneNumber = 'Must enter a valid US phone number in +1 (XXX) XXX-XXXX format';
    }

    if (formState.email.trim() === '') {
      errors.email = 'Email is required';
    } else if (!isValidEmail(formState.email.trim())) {
      errors.email = 'Email must be in a valid format';
    } else if (formState.email.trim().length > 512) {
      errors.email = 'Email cannot exceed 512 characters'
    }

    if (formState.birthDate === Constants.BLANK_DATE_STRING) {
      errors.birthDate = 'Birth Date is required';
    } else if (!isValidDate(formState.birthDate)) {
      errors.birthDate = 'Birth Date must be a valid date';
    } else if (!isValidBirthDate(formState.birthDate)) {
      errors.birthDate = 'Birth Date cannot be in the future';
    }
  }

  if (formState.orgRolesId.length < 1) {
    errors.orgRolesId = 'Must select at least one staff role';
  }

  return errors;
};

export default PreCompLocationStaffFormValidation;