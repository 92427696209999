import { useState } from "react";
import { navigateToCrossUiRoute } from '../../../../common/utils/FormatCrossUiRoute';

import useClubCommunityImpactBulkImportUploadData from "../../../state/clubCommunityImpactBulkImportUpload/UseClubCommunityImpactBulkImportUploadData";
import useUsasClubFilesData from "../../../state/clubFiles/UseUsasClubFilesData";
import useCurrentSeasonOfferingData from "../../../state/currentSeasonOffering/UseCurrentSeasonOfferingData";
import useCommunityClubMemberLoggedInPersonData from "../../../state/communityClubMemberLoggedInPerson/UseCommunityClubMemberLoggedInPersonData";

import useBaseUsasClubData from "../../../../common/state/clubBase/UseBaseUsasClubData";
import useGlobalRoutesData from "../../../../common/state/security/UseGlobalRoutesData";
import useEmbeddedReportData from "../../../../common/state/embeddedReport/UseEmbeddedReportData";

import useForm from '../../../../common/utils/UseForm';
import Constants from "../../../../common/utils/Constants";

import { formatDate } from "../../../../common/utils/DateFunctions";
import { useNavigate } from "../../../../common/wrappers/ReactRouterDom";
import { useEffect } from "react";

const INITIAL_FORM_STATE = {
  index: Constants.DEFAULT_ID,
  offeringName: ''
};

const INITIAL_MODAL_STATE = {
  displayPopUp: false,
  modalTitle: 'Community Impact Bulk Import - Upload Confirmation'
};

const INITIAL_VIEW_STATE = {
  reportParameters: { communityClubMemberLoggedInPersonId: '' },
  contextId: '',
  routeName: ''
};

const useClubCommunityImpactBulkImport = () => {
  const navigate = useNavigate();
  const { baseUsasClubState } = useBaseUsasClubData();
  const { clubCommunityImpactBulkImportUploadState, postValidateClubCommunityImpactBulkImportIds, postProcessClubCommunityImpactBulkImportIds,
    resetClubCommunityImpactBulkImportUploadState, resetState } = useClubCommunityImpactBulkImportUploadData();
  const { communityClubMemberLoggedInPersonState, deleteCommunitClubMemberLoggedInPersonById } = useCommunityClubMemberLoggedInPersonData();
  const { currentSeasonOfferingState } = useCurrentSeasonOfferingData();
  const { GET_DOWNLOAD_TEMPLATE_PATH } = useUsasClubFilesData();
  const { getEmbeddedReportListForContext } = useEmbeddedReportData();
  const [viewState, setViewState] = useState(INITIAL_VIEW_STATE);
  const [fileState, setFileState] = useState({});
  const [memberIdArrayState, setMemberIdArrayState] = useState([]);
  const [fileErrorState, setFileErrorState] = useState('');
  const [modalState, setModalState] = useState(INITIAL_MODAL_STATE);
  const { formState, errorState, setFormState, handleSubmit } = useForm(INITIAL_FORM_STATE, submitFormCallback, {});
  const { getGlobalRoute } = useGlobalRoutesData();

  const onLoadFile = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    const file = e.target.files[0];
    if (file) {
      resetState();

      if (file.type === Constants.FILE_TYPE_CSV) {
        readCsvFile(file);
      } else {
        setFileErrorState(`Invalid file type ${file.type ? `(${file.type}) ` : ''}loaded`);
      }
    } else {
      setFileErrorState('No file loaded');
    }
  };

  const onDownloadJSON = () => {
    const json = JSON.stringify(memberIdArrayState);
    const blob = new Blob([json], { type: 'application/json' });
    const href = URL.createObjectURL(blob);
    const link = document.createElement('a');

    link.href = href;
    link.download = formState.offeringName + 'CommunityImpactMembers.json';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const onUploadMembers = () => {
    const offeringInstanceObj = currentSeasonOfferingState?.arrayData?.find(x => x.offeringName === formState.offeringName);
    postValidateClubCommunityImpactBulkImportIds(baseUsasClubState.objData?.orgUnitId, offeringInstanceObj.offeringInstanceId, offeringInstanceObj.offeringInstanceCouponId, memberIdArrayState);
  };

  const handleDelete = () => {
    deleteCommunitClubMemberLoggedInPersonById(clubCommunityImpactBulkImportUploadState.objData.communityClubMemberLoggedInPersonId);
  };

  const onModalCanceled = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    setModalState(INITIAL_MODAL_STATE);
  };

  const onContinueClicked = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    onModalCanceled();
    //Navigate to cart
    const paymentsRoute = getGlobalRoute('PAYMENTS_SHOPPING_CART');
    if (paymentsRoute && paymentsRoute.route) {
      navigateToCrossUiRoute(paymentsRoute.uIProjectName, paymentsRoute.route, navigate);
    }
  };

  useEffect(() => {
    //Handle Errors
    if (clubCommunityImpactBulkImportUploadState.isObjLoaded === true &&
      clubCommunityImpactBulkImportUploadState.isObjLoading === false &&
      clubCommunityImpactBulkImportUploadState.isSaving === false) {
      if (clubCommunityImpactBulkImportUploadState.objData.messageTitle === 'Error') {
        setViewState({
          ...viewState,
          reportParameters: {
            communityClubMemberLoggedInPersonId: clubCommunityImpactBulkImportUploadState.objData.communityClubMemberLoggedInPersonId
          },
          contextId: baseUsasClubState.objData.orgUnitId,
          routeName: 'CLUB_COMMUNITY_IMPACT_BULK_IMPORT',
        });
      }
      else {
        //No Errors, Allow Upload
        const offeringInstanceObj = currentSeasonOfferingState?.arrayData?.find(x => x.offeringName === formState.offeringName);
        postProcessClubCommunityImpactBulkImportIds(baseUsasClubState.objData?.orgUnitId, offeringInstanceObj.offeringInstanceId, offeringInstanceObj.offeringInstanceCouponId, memberIdArrayState);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clubCommunityImpactBulkImportUploadState.isObjLoaded])

  useEffect(() => {
    if (viewState.routeName && viewState.contextId) {
      const reportListPromise = getEmbeddedReportListForContext(viewState.routeName, viewState.contextId);

      if (reportListPromise) {
        reportListPromise.then((stateObj) => {
          if (Array.isArray(stateObj.arrayData)) {
            const listNames = [];
            stateObj.arrayData.forEach(x => {
              if (listNames.filter(a => a.id === x.listName).length === 0) {
                listNames.push({ id: x.listName, name: x.listDisplayName });
              }
            });

            setViewState({
              ...viewState,
              isLoading: false,
              showGrid: false,
              showReport: stateObj.arrayData.length === 1,
              reportInfoId: stateObj.arrayData.length === 1 ? stateObj.arrayData[0].reportInfoId : -1,
              listNames,
              rawReportList: [...stateObj.arrayData],
              reportList: [...stateObj.arrayData]
            });
          } else {
            setViewState({
              ...viewState,
              isLoading: false,
              showGrid: false,
              showReport: false,
              reportInfoId: -1,
              listNames: [],
              rawReportList: [],
              reportList: []
            });
          }
        }).catch((err) => {
          setViewState({
            ...viewState,
            isLoading: false,
            showGrid: false,
            showReport: false,
            reportInfoId: -1,
            listNames: [],
            rawReportList: [],
            reportList: []
          });
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewState.routeName && viewState.contextId]);

  useEffect(() => {
    if (clubCommunityImpactBulkImportUploadState.isSaved === true &&
      clubCommunityImpactBulkImportUploadState.isObjLoading === false &&
      clubCommunityImpactBulkImportUploadState.isSaving === false) {
      setModalState({ ...modalState, displayPopUp: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clubCommunityImpactBulkImportUploadState])

  function submitFormCallback() {
  };

  function readCsvFile(file) {
    const fileReader = new FileReader();

    fileReader.onload = function (event) {
      // Read file data
      var csvdata = event.target.result;

      // Split by line break to gets rows Array
      var rowData = csvdata.split('\n');
      parseFileResult(rowData);
    };

    fileReader.readAsText(file);

    setFileState(file);
    setFileErrorState('');
  };

  function parseFileResult(rowData) {
    const result = [];
    // Loop on the row Array (change row=0 if you also want to read 1st header row)
    for (var row = 1; row < rowData.length - 1; row++) {
      // Split by comma (,) to get column Array
      let rowColData = rowData[row].split(',');
      result.push({
        memberId: rowColData[0]?.trim() || null,
        firstName: rowColData[1]?.trim() || '',
        middleName: rowColData[3]?.trim() || '',
        preferredName: rowColData[4]?.trim() || '',
        lastName: rowColData[2]?.trim() || '',
        emailAddress: rowColData[5]?.trim() || '',
        birthDate: rowColData[6]?.trim() ? formatDate(rowColData[6]?.trim()) : '',
        isUsCitizen: rowColData[8]?.trim() || '',
        competitionCategory: rowColData[7]?.trim() || '',
        emergencyContactFirstName: rowColData[9]?.trim() || '',
        emergencyContactLastName: rowColData[10]?.trim() || '',
        emergencyContactEmail: rowColData[11]?.trim() || ''
      });
    }

    setMemberIdArrayState(result);
  };

  return {
    isLoading: currentSeasonOfferingState.isArrayLoading || clubCommunityImpactBulkImportUploadState.isObjLoading || communityClubMemberLoggedInPersonState?.isSaving,
    isSaving: clubCommunityImpactBulkImportUploadState?.isSaving,
    clubCommunityImpactBulkImportUploadState,
    memberIdArrayState,
    baseUsasClubState,
    fileState,
    formState,
    errorState,
    handleSubmit,
    fileErrorState,
    setFormState,
    onLoadFile,
    onDownloadJSON,
    onUploadMembers,
    GET_DOWNLOAD_TEMPLATE_PATH,
    modalState,
    INITIAL_VIEW_STATE,
    viewState,
    setViewState,
    onModalCanceled,
    onContinueClicked,
    resetClubCommunityImpactBulkImportUploadState,
    handleDelete
  };
};

export default useClubCommunityImpactBulkImport;