import { useEffect } from 'react';
import { useNavigate } from '../../../../common/wrappers/ReactRouterDom';

import NavLinks from '../NavLinks';

import useClubPersistentStorage from '../../../../common/state/clubPersistentStorage/UseClubPersistentStorage';
import useSearchClubData from '../../../../common/state/searchClub/UseSearchClubData';

const useClubSearch = () => {
  const navigate = useNavigate();
  const { setPersistentStorage, resetPersistentStorage } = useClubPersistentStorage();
  const { searchClubState } = useSearchClubData();

  const onEditClub = (e, clubObj) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    const selectedOrgUnitId = clubObj.orgUnitId;

    setPersistentStorage(selectedOrgUnitId, import.meta.env.VITE_PROJECT_NAME, NavLinks.CLUB_SEARCH, 'Back To Club Search');

    navigate(NavLinks.CLUB_INFO);
  };

  useEffect(() => {
    resetPersistentStorage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    searchClubState,
    onEditClub
  };
};

export default useClubSearch;