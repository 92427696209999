import Constants from '../../../common/utils/Constants';
import { BASIC_INITIAL_STATE } from '../../../common/utils/HttpHelper';

import getClubHistoryData from './GetClubHistoryData';

const INITIAL_STATE = {
  ...BASIC_INITIAL_STATE,
  usasClubId: Constants.DEFAULT_ID
};

const ClubHistoryData = {
  INITIAL_STATE, getClubHistoryData
};

export default ClubHistoryData;