import { Fragment } from 'react';

import Input from '../../../../inputs/Input';
import YesNoSwitch from '../../../../yesNoSwitch/YesNoSwitch';
import PoolDimensionsDropdown from '../../../../dropdowns/poolDimensionsDropdown/PoolDimensionsDropdown';

const FacilityPoolForm = ({ formState, errorState, onFormValueChanged, onValueTextPairChanged }) => (
  <Fragment>
    <div className="row">
      <div className="col-xs-12 col-sm-6 col-md-4">
        <Input
          label="Pool Number / Name*"
          name="poolName"
          value={formState.poolName}
          error={errorState.poolName}
          message={errorState.poolName}
          onChange={(value) => { onFormValueChanged('poolName', value) }} />
      </div>
      <div className="col-xs-12 col-sm-6 col-md-4">
        <PoolDimensionsDropdown
          label="Pool Dimensions*"
          name="poolDimensionsId"
          value={formState.poolDimensionsId}
          error={errorState.poolDimensionsId}
          message={errorState.poolDimensionsId}
          onChange={(value, newValueLabel, e) => {
            e && e.target && e.target.value &&
              onValueTextPairChanged(value, 'poolDimensionsId', newValueLabel, 'poolDimensions')
          }} />
      </div>
      <div className="col-xs-12 col-sm-6 col-md-4">
        <YesNoSwitch
          label="Pool Has Starting Blocks*"
          name="hasStartingBlocks"
          checked={formState.hasStartingBlocks}
          error={errorState.hasStartingBlocks}
          message={errorState.hasStartingBlocks}
          onChange={(value) => { onFormValueChanged('hasStartingBlocks', value) }} />
      </div>
      <div className="col-xs-12 col-sm-6 col-md-4">
        <Input
          label="Pool Max Depth (ft)"
          name="poolMaxDepth"
          type="number"
          min="0"
          value={formState.poolMaxDepth}
          error={errorState.poolMaxDepth}
          message={errorState.poolMaxDepth}
          onChange={(value) => { onFormValueChanged('poolMaxDepth', value) }} />
      </div>
      <div className="col-xs-12 col-sm-6 col-md-4">
        <Input
          label="Average Pool Temperature (&deg;F)"
          name="averagePoolTemperature"
          type="number"
          min="0"
          value={formState.averagePoolTemperature}
          error={errorState.averagePoolTemperature}
          message={errorState.averagePoolTemperature}
          onChange={(value) => { onFormValueChanged('averagePoolTemperature', value) }} />
      </div>
    </div>
  </Fragment>
);

export default FacilityPoolForm;