import { useContext } from 'react';

import { BaseUsasClubStateContext } from './BaseUsasClubContextProvider';
import { BaseUsasClubFiltersStateContext } from './BaseUsasClubFiltersContextProvider';
import BaseUsasClubData from './BaseUsasClubData';

const useBaseUsasClubData = () => {
  const [baseUsasClubState, setBaseUsasClubState] = useContext(BaseUsasClubStateContext);
  const [baseUsasClubFiltersState, setBaseUsasClubFiltersState] = useContext(BaseUsasClubFiltersStateContext);

  const searchUsasClubs = (filterObject, sortBy) => {
    setBaseUsasClubFiltersState({ ...baseUsasClubFiltersState, filterObject, sortBy });
    const filterObj = { ...filterObject };
    delete filterObj.orgUnitName;
    BaseUsasClubData.searchUsasClubsData(JSON.stringify(filterObj), sortBy, baseUsasClubState, setBaseUsasClubState);
  };
  const getBaseUsasClub = (usasClubId) => BaseUsasClubData.getBaseUsasClubData(usasClubId, baseUsasClubState, setBaseUsasClubState);
  const getBaseUsasClubByOrgUnitId = (orgUnitId) => BaseUsasClubData.getBaseUsasClubByOrgUnitIdData(orgUnitId, baseUsasClubState, setBaseUsasClubState);
  const putBaseUsasClub = (usasClubId, usasClubObj) => BaseUsasClubData.putBaseUsasClubData(usasClubId, usasClubObj, baseUsasClubState, setBaseUsasClubState);
  const deleteUsasClubAffiliation = (usasClubAffiliationId) => BaseUsasClubData.deleteUsasClubAffiliationData(usasClubAffiliationId, baseUsasClubState, setBaseUsasClubState);

  const clearBaseUsasClubObjData = () => {
    setBaseUsasClubState({
      ...baseUsasClubState,
      isObjLoading: false,
      isObjLoaded: false,
      isSaving: false,
      isSaved: false,
      objData: {},
      message: ''
    });
  };

  const clearBaseUsasClubArrayData = () => {
    setBaseUsasClubState({
      ...baseUsasClubState,
      isArrayLoading: false,
      isArrayLoaded: false,
      isSaving: false,
      isSaved: false,
      arrayData: [],
      message: ''
    });
  };

  const clearBaseUsasClubFilterState = () => {
    setBaseUsasClubFiltersState({
      filterObject: {},
      sortBy: ''
    });
  };

  const confirmBaseUsasClubSave = () => {
    setBaseUsasClubState({
      ...baseUsasClubState,
      isSaved: false
    });
  };


  return {
    baseUsasClubState,
    baseUsasClubFiltersState,
    setBaseUsasClubState,
    setBaseUsasClubFiltersState,
    clearBaseUsasClubObjData,
    clearBaseUsasClubArrayData,
    clearBaseUsasClubFilterState,
    confirmBaseUsasClubSave,
    searchUsasClubs,
    getBaseUsasClub,
    getBaseUsasClubByOrgUnitId,
    putBaseUsasClub,
    deleteUsasClubAffiliation
  };
};

export default useBaseUsasClubData;