import React, { Fragment } from "react";

import { getFivePercentOfTotal } from "../../utils/SATUtils";

import Input from "../../../../../common/components/inputs/Input";
import ReadOnly from "../../../../../common/components/readOnly/ReadOnly";
import CurrencyInput from "../../../../../common/components/inputs/CurrencyInput";

import { CURRENCY_FORMATTER } from "../../../../../common/utils/Constants";

const SATResultForm = ({ formState, errorState = {}, onFormValueChanged = undefined }) => (
  <Fragment>
    <div className="row usas-extra-top-margin" style={{ marginLeft: 1, fontSize: 20 }}>
      <b>Goals</b>
    </div>
    <div className="row usas-extra-top-margin">
      <div className="col-xs-12 col-sm-6 col-md-4">
        <ReadOnly
          label="Fundraising Goal"
          name="fundraisingGoal"
          value={CURRENCY_FORMATTER.format(formState.fundraisingGoal)} />
      </div>
      <div className="col-xs-12 col-sm-6 col-md-4">
        {onFormValueChanged ? (
          <CurrencyInput
            label="Total Amount Collected*"
            name="totalAmountCollected"
            value={formState.totalAmountCollected}
            error={errorState.totalAmountCollected}
            message={errorState.totalAmountCollected}
            onChange={(value) => { onFormValueChanged("totalAmountCollected", value); }} />
        ) : (
          <ReadOnly
            label="Total Amount Collected"
            name="totalAmountCollected"
            value={CURRENCY_FORMATTER.format(formState.totalAmountCollected)} />
        )}
      </div>
      <div className="col-xs-12 col-sm-6 col-md-4">
        <ReadOnly
          label="5% of Total Collected"
          name="totalAmountCollected"
          value={CURRENCY_FORMATTER.format(getFivePercentOfTotal(formState.totalAmountCollected))} />
      </div>
    </div>
    <div className="row usas-extra-top-margin" style={{ marginLeft: 1, fontSize: 20 }}>
      <b>Participation</b>
    </div>
    <div className="row usas-extra-top-margin">
      <div className="col-xs-12 col-sm-6 col-md-4">
        <ReadOnly
          label="Number of Participants"
          name="numberOfParticipatingSwimmers"
          value={formState.numberOfParticipatingSwimmers} />
      </div>
      <div className="col-xs-12 col-sm-6 col-md-4">
        {onFormValueChanged ? (
          <Input
            label="Number of Bag Tags for 100 Lengths"
            name="bagTags100Length"
            value={formState.bagTags100Length}
            error={errorState.bagTags100Length}
            message={errorState.bagTags100Length}
            onChange={(value) => { onFormValueChanged('bagTags100Length', value); }} />
        ) : (
          <ReadOnly
            label="Number of Bag Tags for 100 Lengths"
            name="bagTags100Length"
            value={formState.bagTags100Length} />
        )}
      </div>
      <div className="col-xs-12 col-sm-6 col-md-4">
        {onFormValueChanged ? (
          <Input
            label="Number of Bag Tags for 200 Lengths"
            name="bagTags200Length"
            value={formState.bagTags200Length}
            error={errorState.bagTags200Length}
            message={errorState.bagTags200Length}
            onChange={(value) => { onFormValueChanged('bagTags200Length', value); }} />
        ) : (
          <ReadOnly
            label="Number of Bag Tags for 200 Lengths"
            name="bagTags200Length"
            value={formState.bagTags200Length} />
        )}
      </div>
    </div>
  </Fragment>
);

export default SATResultForm;