import React, { Fragment } from "react";

import useClubMeetRecon from "./UseClubMeetRecon";

import Headings from "../../../../../common/components/headings/Headings";
import MeetReconUpload from "../../../../../common/components/fileUpload/meetReconUpload/MeetReconUpload";
import SecondaryButton from "../../../../../common/components/buttons/SecondaryButton";

import global from '../../../../../common/components/GlobalStyle.module.css';

const ClubMeetRecon = () => {
  const {
    meetId,
    meetHeaderState,
    onBackClicked
  } = useClubMeetRecon();

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>Block Party Meet Recon</Headings.H3>
        </div>
      </div>
      <div className={["row", global.HeaderText].join(' ')}>
        <div className="col-xs-12">
          <p><b>Meet Name:</b> {meetHeaderState.meetName}</p>
          <p><b>Meet Dates:</b> {meetHeaderState.meetDates}</p>
        </div>
      </div>
      {
        (meetId > 0) ?
        <MeetReconUpload meetId={meetId} /> : <Fragment />
      }
      <div className="row usas-extra-top-margin usas-extra-bottom-margin">
        <div className="col-xs-12">
          <SecondaryButton type="button" onClick={onBackClicked}>Back</SecondaryButton>
        </div>
      </div>
    </Fragment>
  );
};

export default ClubMeetRecon;