import React from 'react';

import useClubGroupCombobox from './UseClubGroupCombobox';

import Combobox from '../../../../common/components/comboboxes/Combobox';

import global from '../../../../common/components/GlobalStyle.module.css';

const ClubGroupCombobox = ({ label, name, valueToMatch, error, message, onChange, orgUnitId, groupTypeId }) => {
  const { clubGroupComboboxState } = useClubGroupCombobox(orgUnitId, groupTypeId);

  return clubGroupComboboxState.message
    ? <span className={global.LoadingMsg}>{clubGroupComboboxState.message}</span>
    : <Combobox
      label={label}
      name={name}
      isLoading={clubGroupComboboxState.isArrayLoading}
      items={clubGroupComboboxState.items}
      valueToMatch={valueToMatch}
      error={error}
      message={message}
      onChange={onChange} />
};

export default ClubGroupCombobox;