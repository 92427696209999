import { useLocation, useNavigate } from "../../../../../../common/wrappers/ReactRouterDom";

import { useGetDashboardModel } from "@sisense/sdk-ui";

import useNavRoutes from "../../../../../../common/state/security/UseNavRoutes";
import useEnvironmentVariableData from "../../../../../../common/state/environmentVariable/UseEnvironmentVariableData";

const useMeetSummaryDashboard = (dashboardOid) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { navRoutes } = useNavRoutes();
  const { dashboard, isLoading, isError } = useGetDashboardModel({ dashboardOid, includeWidgets: true });
  const { MS_NUM_OF_SWIMS_WIDGET_OID, MS_NUM_OF_SWIMMERS_WIDGET_OID, MS_NUM_OF_LSCS_WIDGET_OID, MS_NUM_OF_TEAMS_WIDGET_OID,
    MS_PCT_LIFETIME_BEST_WIDGET_OID, MS_PCT_SEASON_BEST_WIDGET_OID, MS_NUM_OF_RECORDS_BROKEN_WIDGET_OID,
    MS_NUM_SWIMMERS_TO_BREAK_RECORD_WIDGET_OID, MS_NUM_OF_SWIMMERS_PER_EVENT_WIDGET_OID, MS_INDIVIDUAL_COMPETITION_CATEGORIES_WIDGET_OID,
    MS_NUMBER_OF_RELAYS_PER_EVENT_WIDGET_OID, MS_RELAY_COMPETITION_CATEGORIES_WIDGET_OID, MS_TIME_STANDARDS_ACHIEVED_BY_SWIM_WIDGET_OID,
    MS_TIME_STANDARDS_ACHIEVED_BY_PERSON_WIDGET_OID } = useEnvironmentVariableData();

  const onBackToPersonTimesSearch = (e) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }

    navigate(navRoutes.CLUB_PERFORMANCE_ATHLETE_TIMES_DETAIL?.route, {
      state: {
        personKey: location.state?.personKey,
        personName: location.state?.personName,
        clubRosterFilters: location.state?.clubRosterFilters,
        individualTimesSearchFilters: location.state?.individualTimesSearchFilters
      }
    });
  };

  const onGoToMeetResults = (e) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }

    navigate(navRoutes.CLUB_PERFORMANCE_ATHLETE_TIMES_DETAIL_MEET_RESULTS?.route, {
      state: {
        meetKey: location.state?.meetKey,
        meetName: location.state?.meetName,
        personKey: location.state?.personKey,
        personName: location.state?.personName,
        clubRosterFilters: location.state?.clubRosterFilters,
        individualTimesSearchFilters: location.state?.individualTimesSearchFilters
      }
    });
  };

  return {
    location,
    navRoutes,
    dashboard,
    isError,
    isLoading,
    MS_NUM_OF_SWIMS_WIDGET_OID,
    MS_NUM_OF_SWIMMERS_WIDGET_OID,
    MS_NUM_OF_LSCS_WIDGET_OID,
    MS_NUM_OF_TEAMS_WIDGET_OID,
    MS_PCT_LIFETIME_BEST_WIDGET_OID,
    MS_PCT_SEASON_BEST_WIDGET_OID,
    MS_NUM_OF_RECORDS_BROKEN_WIDGET_OID,
    MS_NUM_SWIMMERS_TO_BREAK_RECORD_WIDGET_OID,
    MS_NUM_OF_SWIMMERS_PER_EVENT_WIDGET_OID,
    MS_INDIVIDUAL_COMPETITION_CATEGORIES_WIDGET_OID,
    MS_NUMBER_OF_RELAYS_PER_EVENT_WIDGET_OID,
    MS_RELAY_COMPETITION_CATEGORIES_WIDGET_OID,
    MS_TIME_STANDARDS_ACHIEVED_BY_SWIM_WIDGET_OID,
    MS_TIME_STANDARDS_ACHIEVED_BY_PERSON_WIDGET_OID,
    onBackToPersonTimesSearch,
    onGoToMeetResults
  };
};

export default useMeetSummaryDashboard;