import React, { Fragment } from 'react';

import useClubRegistrationFacilities from './UseClubRegistrationFacilities';

import LargeClubRegistrationFacilitiesGridWrite from './LargeClubRegistrationFacilitiesGridWrite';
import SmallClubRegistrationFacilitiesGridWrite from './SmallClubRegistrationFacilitiesGridWrite';

import useExpandedRow from '../../../../common/components/grids/UseExpandedRow';

const ClubRegistrationFacilitiesGridWrite = ({ onDelete, onDeletePool }) => {
  const { usasClubFacilityLinksState, onEditFacility, onAddPool, onEditPool } = useClubRegistrationFacilities();
  const { expandedId, onClick } = useExpandedRow();
  return (
    <Fragment>
      <LargeClubRegistrationFacilitiesGridWrite
        state={usasClubFacilityLinksState}
        onEdit={onEditFacility}
        onDelete={onDelete}
        onAddPool={onAddPool}
        onEditPool={onEditPool}
        onDeletePool={onDeletePool}
        expandedId={expandedId}
        onClick={onClick}
      />
      <SmallClubRegistrationFacilitiesGridWrite
        state={usasClubFacilityLinksState}
        onEdit={onEditFacility}
        onDelete={onDelete}
        onAddPool={onAddPool}
        onEditPool={onEditPool}
        onDeletePool={onDeletePool}
        expandedId={expandedId}
        onClick={onClick}
      />
    </Fragment>
  )
};

export default ClubRegistrationFacilitiesGridWrite;