import React, { Fragment } from "react";

import UseMeetSanctionStaffForm from "./UseMeetSanctionStaffForm";

import PrimaryButton from "../../../../../../common/components/buttons/PrimaryButton";
import SecondaryButton from "../../../../../../common/components/buttons/SecondaryButton";
import PopUpModal from "../../../../../../common/components/dialogs/PopUpModal";
import Constants from "../../../../../../common/utils/Constants";
import SearchMemberWithLscPopup from "../../../../../../common/components/searches/searchMember/SearchMemberWithLscPopup";

const MeetSanctionStaffForm = ({ savedFormState, onSubmitFormCallback, onBackClicked, OFFICIAL_ORG_ROLE_ID, OFFICIALS_ROLES_ROLE_GROUP_ID }) => {
  const {
    formState,
    errorState,
    isSubmitting,
    handleSubmit,
    onFormValueChanged,
    setFormState
  } = UseMeetSanctionStaffForm(savedFormState, onSubmitFormCallback, OFFICIAL_ORG_ROLE_ID, OFFICIALS_ROLES_ROLE_GROUP_ID);

  return (
    <Fragment>
      <form onSubmit={handleSubmit} noValidate>
        <div className="row usas-extra-bottom-margin">
          <div className="col-xs-12 col-sm-6 col-md-4">
            <SearchMemberWithLscPopup label="Meet Director Member Id*" memberIdName="meetDirectorMemberId" formState={formState} errorState={errorState} setFormData={setFormState} onFormValueChanged={onFormValueChanged} />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4">
            <SearchMemberWithLscPopup label="Meet Referee Member Id*" memberIdName="meetRefereeMemberId" formState={formState} errorState={errorState} setFormData={setFormState} onFormValueChanged={onFormValueChanged} />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4">
            <SearchMemberWithLscPopup label="Meet Contact Member Id*" memberIdName="meetContactMemberId" formState={formState} errorState={errorState} setFormData={setFormState} onFormValueChanged={onFormValueChanged} />
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12 usas-extra-top-margin usas-extra-bottom-margin">
            <PrimaryButton type="submit">Submit</PrimaryButton>&nbsp;
            <SecondaryButton type="button" onClick={() => onBackClicked(formState)}>Back</SecondaryButton>
          </div>
        </div>
      </form >
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={isSubmitting} />
    </Fragment>
  );
};

export default MeetSanctionStaffForm;