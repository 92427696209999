import React from 'react';

import useClubInactiveReasonDropdown from './UseClubInactiveReasonDropdown';

import Dropdown from '../../../common/components/dropdowns/Dropdown';

import global from '../../../common/components/GlobalStyle.module.css';

const ClubInactiveReasonDropdown = ({ label, name, value, error, message, onChange }) => {
  const { clubInactiveReasonState } = useClubInactiveReasonDropdown();

  return clubInactiveReasonState.message
    ? <span className={global.LoadingMsg}>{clubInactiveReasonState.message}</span>
    : (
      <Dropdown
        error={error}
        label={label}
        message={message}
        options={clubInactiveReasonState.options}
        name={name}
        value={value}
        onChange={onChange}
        isLoading={clubInactiveReasonState.isArrayLoading}
      />
    );
};

export default ClubInactiveReasonDropdown;