import { OmrHttpHelper, NO_DATA_MESSAGE } from '../../../common/utils/HttpHelper';
import Constants from '../../../common/utils/Constants';

export const INITIAL_STATE = {
  orgUnitId: Constants.DEFAULT_ID,
  reportPeriodId: Constants.DEFAULT_ID,
  isArrayLoading: false,
  isArrayLoaded: false,
  arrayData: [],
  options: [],
  message: ''
};

const getLscOfferingByOrgUnitIdAndReportPeriodId = (orgUnitId, reportPeriodId, state, setState) => {
  if (state.isArrayLoading !== true && orgUnitId && reportPeriodId > 0) {
    let newState = {
      ...state,
      orgUnitId,
      reportPeriodId,
      isArrayLoading: true,
      isArrayLoaded: false,
      message: 'Loading...'
    };
    setState(newState);

    const orgUnitIdForUrl = orgUnitId ? encodeURIComponent(orgUnitId) : 'NaN';
    const reportPeriodIdForUrl = reportPeriodId ? encodeURIComponent(reportPeriodId) : 'NaN';
    const url = `/Offering/LscOffering/OrgUnit/${orgUnitIdForUrl}/ReportPeriod/${reportPeriodIdForUrl}`;
    OmrHttpHelper(url, 'GET')
      .then((data) => {
        if (!data) {
          throw new Error(NO_DATA_MESSAGE);
        } else {
          const options = data.map((o) => { return { id: o.offeringInstanceCouponId ? o.offeringInstanceId + 'offeringInstanceCouponId' + o.offeringInstanceCouponId : o.offeringInstanceId, name: o.offeringName }; });
          options.unshift({ id: Constants.DEFAULT_ID, name: '--' });

          newState = {
            ...newState,
            isArrayLoading: false,
            isArrayLoaded: true,
            arrayData: data,
            options,
            message: ''
          };
          setState(newState);
        }
      })
      .catch((e) => {
        const message = e && e.message ? e.message : NO_DATA_MESSAGE;
        setState({
          ...newState,
          isArrayLoading: false,
          isArrayLoaded: false,
          message: message
        });
      });
  }
};

const LscOfferingByOrgUnitIdAndReportPeriodIdDropdownData = { INITIAL_STATE, getLscOfferingByOrgUnitIdAndReportPeriodId };

export default LscOfferingByOrgUnitIdAndReportPeriodIdDropdownData;