/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Fragment } from 'react';

import PlusIcon from '../../icons/PlusIcon';
import EditIcon from '../../icons/EditIcon';

import SGColumnHeader from '../../grids/SGColumnHeader';

import { formatDate } from '../../../utils/DateFunctions';
import useSortableGrid from '../../../utils/UseSortableGrid';
import SGConstants from '../../../utils/SGConstants';

import global from '../../GlobalStyle.module.css';

const LargeGrid = ({ state, onEdit, onCancelClicked, sendObjOnEdit }) => {
  const {
    sortableGridState,
    onColumnClicked
  } = useSortableGrid(state.arrayData);

  return (
    <table className={[global.UsasTable, 'visible-md visible-lg'].join(' ')}>
      <thead>
        <tr>
          <SGColumnHeader sortableGridState={sortableGridState} columnLabel={'Club Name'} columnField={'clubName'}
            sortType={SGConstants.SORT_TYPE_ALPHABETICAL} onColumnClicked={onColumnClicked} />
          <SGColumnHeader sortableGridState={sortableGridState} columnLabel={'LSC / Club Code'} columnField={'clubCode'}
            sortType={SGConstants.SORT_TYPE_ALPHABETICAL} onColumnClicked={onColumnClicked} />
          <SGColumnHeader sortableGridState={sortableGridState} columnLabel={'Location'} columnField={'location'}
            sortType={SGConstants.SORT_TYPE_ALPHABETICAL} onColumnClicked={onColumnClicked} />
          <SGColumnHeader sortableGridState={sortableGridState} columnLabel={'Members'} columnField={'memberCount'}
            sortType={SGConstants.SORT_TYPE_NUMERICAL} onColumnClicked={onColumnClicked} />
          <SGColumnHeader sortableGridState={sortableGridState} columnLabel={'Expiration Date'} columnField={'expirationDate'}
            sortType={SGConstants.SORT_TYPE_DATE} onColumnClicked={onColumnClicked} />
          {onCancelClicked ?
            <th></th> : <Fragment />}
        </tr>
      </thead>
      <tbody>
        {state.isArrayLoaded !== true
          ? (
            <tr>
              {onCancelClicked ?
                <Fragment>
                  {state.isArrayLoading === true ?
                    <td colSpan="7">Loading...</td>
                    : <td colSpan="7">No Results</td>
                  }
                </Fragment>
                :
                <Fragment>
                  {state.isArrayLoading === true ?
                    <td colSpan="6">Loading...</td>
                    : <td colSpan="6">No Results</td>
                  }
                </Fragment>}
            </tr>
          ) : Array.isArray(sortableGridState.sortedGridData) && sortableGridState.sortedGridData.length > 0
            ? sortableGridState.sortedGridData.map(club => (
              <tr key={club.usasClubId}>
                {onCancelClicked ?
                  <td>{club.clubName}</td> :
                  <td><a onClick={(e) => onEdit(e, sendObjOnEdit === true ? club : club.usasClubId)}>{club.clubName}</a></td>}
                <td>{club.lscCode}/{club.clubCode}</td>
                <td>{club.location}</td>
                <td>{club.memberCount}</td>
                <td>{formatDate(club.expirationDate)}</td>
                {onCancelClicked ? <td>
                  <button className={global.IconButtonMargin} type="button" onClick={(e) => onEdit(e, sendObjOnEdit === true ? club : club.usasClubId)}>
                    <PlusIcon />
                  </button>
                </td> : <Fragment />}
              </tr>
            ))
            : (
              <Fragment>
                {onCancelClicked ?
                  <tr>
                    <td colSpan="7">No Results</td>
                  </tr>
                  :
                  <tr>
                    <td colSpan="6">No Results</td>
                  </tr>
                }
              </Fragment>
            )}
      </tbody>
    </table>
  );
};

const SmallGrid = ({ state, onEdit, onCancelClicked, sendObjOnEdit }) => (
  <div className={[global.SmallTable, 'hidden-md hidden-lg'].join(' ')}>
    {state.isArrayLoaded !== true
      ? (
        <div className={global.SmallTableRow}>
          <div className={global.SmallTableRowHead}>&nbsp;</div>
          <div className={global.SmallTableRowInfo}>
            {state.isArrayLoading === true
              ? <div className={global.SmallTableRowLabels}>Loading...</div>
              : <div className={global.SmallTableRowLabels}>No Results</div>
            }
          </div>
        </div>
      )
      : Array.isArray(state.arrayData) && state.arrayData.length > 0
        ? state.arrayData.map(club => (
          <div className={global.SmallTableRow} key={club.usasClubId}>
            <div className={global.SmallTableRowHead}>
              <span className='hidden-xs'>{club.clubName}</span>&nbsp;
              {onCancelClicked ?
                <button className={global.IconButton} type="button" onClick={(e) => onEdit(e, sendObjOnEdit === true ? club : club.usasClubId)}>
                  <PlusIcon />
                </button>
                :
                <button className={global.IconButton} type="button" onClick={(e) => onEdit(e, sendObjOnEdit === true ? club : club.usasClubId)}>
                  <EditIcon />
                </button>}
            </div>
            <div className={global.SmallTableRowInfo}>
              <div className='row'>
                <div className={['col-xs-6 col-sm-3 visible-xs', global.SmallTableRowLabels].join(' ')}>Club Name</div>
                <div className={['col-xs-6 col-sm-3 visible-xs', global.SmallTableRowData].join(' ')}>{club.clubName}</div>
                <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>LSC / Club Code</div>
                <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>{club.lscCode}/{club.clubCode}</div>
                <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>Location</div>
                <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>{club.location}</div>
                <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>Members</div>
                <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>{club.memberCount}</div>
                <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>Expiration Date</div>
                <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>{formatDate(club.expirationDate)}</div>
              </div>
            </div>
          </div>
        ))
        : (
          <div className={global.SmallTableRow}>
            <div className={global.SmallTableRowHead}>&nbsp;</div>
            <div className={global.SmallTableRowInfo}>
              <div className={global.SmallTableRowLabels}>No Results</div>
            </div>
          </div>
        )}
  </div>
);

const SearchClubGrid = ({ baseUsasClubState, searchClubState, onEdit, onFiltersToggle, onCancelClicked, sendObjOnEdit }) => (
  <Fragment>
    {baseUsasClubState.isArrayLoading === false
      ? <div className="row">
        <div className="col-xs-12 col-sm-10">
          {baseUsasClubState.arrayData.length > 499
            ? <p className={global.ErrorMessage}>More than 500 Search Results returned. Please add additional filters to narrow your search results.</p>
            : <p>{baseUsasClubState.arrayData.length === 1 ? `${baseUsasClubState.arrayData.length} Search Result` : `${baseUsasClubState.arrayData.length} Search Results`}</p>}
        </div>
        {onFiltersToggle ?
          <div className="col-xs-12 col-sm-2">
            <button className={global.ToggleButton} type="button" onClick={() => onFiltersToggle()}>{searchClubState.showFilters === true ? 'Hide Filters' : 'Show Filters'}</button>
          </div> : <Fragment />}
      </div>
      : <Fragment>
        <div className="row usas-extra-top-margin usas-extra-bottom-margin">
        </div>
      </Fragment >}
    <LargeGrid state={baseUsasClubState} onEdit={onEdit} onCancelClicked={onCancelClicked} sendObjOnEdit={sendObjOnEdit} />
    <SmallGrid state={baseUsasClubState} onEdit={onEdit} onCancelClicked={onCancelClicked} sendObjOnEdit={sendObjOnEdit} />
  </Fragment>
);

export default SearchClubGrid;