import { Fragment } from 'react';

import Checkbox from '../../../../common/components/checkboxes/Checkbox';

import { formatDate } from '../../../../common/utils/DateFunctions';

import global from '../../../../common/components/GlobalStyle.module.css';

const SmallGridRow = ({ person, onSelected }) => {

  return (
    <Fragment>
      <div className={global.SmallTableRow}>
        <div className={global.SmallTableRowHead}><span className='hidden-xs'>
          {(person?.firstName || person?.middleName || person?.lastName) ? `${person?.firstName || ''}  ${person?.preferredName !== '' && person?.preferredName !== person?.firstName ? '"' + person?.preferredName + '"' : ''} ${person?.middleName || ''} ${person?.lastName || ''}` : ''}</span>&nbsp;
          <div className={global.FloatRight}>
            <Checkbox
              label={''}
              name="isSelected"
              onChange={() => onSelected(person.memberId, person.isSelected)}
              checked={person.isSelected ?? false} />
          </div>
        </div>
        <div className={global.SmallTableRowInfo}>
          <div className='row'>
            <div className={[global.SmallTableRowLabels, 'col-xs-6 visible-xs'].join(' ')}>Name</div>
            <div className={[global.SmallTableRowData, 'col-xs-6 visible-xs'].join(' ')}>
              {(person?.firstName || person?.middleName || person?.lastName) ? `${person?.firstName || ''}  ${person?.preferredName !== '' && person?.preferredName !== person?.firstName ? '"' + person?.preferredName + '"' : ''} ${person?.middleName || ''} ${person?.lastName || ''}`
                : <span>&nbsp;</span>
              }</div>
            <div className={[global.SmallTableRowLabels, 'col-xs-6'].join(' ')}>Member ID</div>
            <div className={[global.SmallTableRowData, 'col-xs-6'].join(' ')}>{person?.memberId || <span>&nbsp;</span>}</div>
            <div className={['col-xs-6 hidden-xs', global.SmallTableRowLabels].join(' ')}>Competition Category</div>
            <div className={['col-xs-6 visible-xs', global.SmallTableRowLabels].join(' ')}>Comp. Category</div>
            <div className={[global.SmallTableRowData, 'col-xs-6'].join(' ')}>{person?.competitionGenderTypeName || <span>&nbsp;</span>}</div>
            <div className={[global.SmallTableRowLabels, 'col-xs-6'].join(' ')}>Birth Date</div>
            <div className={[global.SmallTableRowData, 'col-xs-6'].join(' ')}>{person?.birthDate ? formatDate(person?.birthDate) : <span>&nbsp;</span>}</div>
            <div className={[global.SmallTableRowLabels, 'col-xs-6'].join(' ')}>LSC / Club Code</div>
            <div className={[global.SmallTableRowData, 'col-xs-6'].join(' ')}>{person?.lscCode || <span>&nbsp;</span>}/{person?.clubCode || <span>&nbsp;</span>}</div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

const ClubThirdPartyInvoicesDetailPersonPotentialMatchGridSmall = ({ gridData, isLoading, onSelected }) => (
  <div className={[global.SmallTable, 'hidden-md hidden-lg'].join(' ')}>
    {isLoading === true
      ? (
        <div className={global.SmallTableRow}>
          <div className={global.SmallTableRowHead}>&nbsp;</div>
          <div className={global.SmallTableRowInfo}>
            <div className={global.SmallTableRowLabels}>Loading...</div>
          </div>
        </div>
      )
      : Array.isArray(gridData) && gridData.length > 0
        ? gridData.map((person, i) => <SmallGridRow key={i} person={person} onSelected={onSelected} />)
        : (
          <div className={global.SmallTableRow}>
            <div className={global.SmallTableRowHead}>&nbsp;</div>
            <div className={global.SmallTableRowInfo}>
              <div className={global.SmallTableRowLabels}>No Potential Matches</div>
            </div>
          </div>
        )
    }
  </div>
);

export default ClubThirdPartyInvoicesDetailPersonPotentialMatchGridSmall;