import React, { Fragment } from 'react';

import ClubPreCompGridLargeWrite from './ClubPreCompGridLargeWrite';
import ClubPreCompGridSmallWrite from './ClubPreCompGridSmallWrite';

const ClubPreCompGridWrite = ({ data, isLoading, onDelete }) => (
  <Fragment>
    <ClubPreCompGridLargeWrite data={data} isLoading={isLoading} onDelete={onDelete} />
    <ClubPreCompGridSmallWrite data={data} isLoading={isLoading} onDelete={onDelete} />
  </Fragment>
);

export default ClubPreCompGridWrite;