import { useState } from 'react';

import MeetFilesData from './UsasMeetFilesData';

const useMeetFilesData = () => {
  const [meetFilesState, setMeetFilesState] = useState(MeetFilesData.INITIAL_STATE);

  const postMeetFiles = (file) => {
    MeetFilesData.postMeetFilesData(file, meetFilesState, setMeetFilesState);
  };

  const resetMeetFilesState = () => {
    setMeetFilesState(MeetFilesData.INITIAL_STATE);
  }

  const getFileNameFromSavedUploadUrl = () => {
    if (meetFilesState.isObjLoaded === true) {
      const fileNameWithDateTime = meetFilesState.objData.uploadUrl.replace(MeetFilesData.BASE_UPLOAD_URL, '');
      const indexOfEndOfDateTime = fileNameWithDateTime.indexOf('.') + 1;
      const fileName = fileNameWithDateTime.substring(indexOfEndOfDateTime);
      return meetFilesState.objData.uploadUrl ? decodeURI(fileName) : '';
    } else {
      return '';
    }
  };

  const getFileNameFromUrl = (url) => {
    const fileNameWithDateTime = url.replace(MeetFilesData.BASE_UPLOAD_URL, '');
    const indexOfEndOfDateTime = fileNameWithDateTime.indexOf('.') + 1;
    const fileName = fileNameWithDateTime.substring(indexOfEndOfDateTime);
    return decodeURI(fileName) || '';
  };

  return {
    meetFilesState,
    postMeetFiles,
    getFileNameFromSavedUploadUrl,
    getFileNameFromUrl,
    resetMeetFilesState,
    BASE_UPLOAD_URL: MeetFilesData.BASE_UPLOAD_URL,
    GET_MEET_FILES_PATH: MeetFilesData.GET_MEET_FILES_PATH
  };
};

export default useMeetFilesData;