import { useEffect, useState } from "react";
import { useNavigate, useLocation } from '../../../../common/wrappers/ReactRouterDom';

import validate from './ClubStaffDetailValidation';

import ClubConstants from "../utils/ClubConstants";

import NavLinks from "../NavLinks";

import useUsasClubStaffData from "../../../state/clubStaff/UseUsasClubStaffData";

import useEnvironmentVariableData from "../../../../common/state/environmentVariable/UseEnvironmentVariableData";
import useBaseUsasClubData from "../../../../common/state/clubBase/UseBaseUsasClubData";

import useForm from '../../../../common/utils/UseForm';
import Constants from "../../../../common/utils/Constants";

const registrationRoleNames = [
  ClubConstants.COACH_ROLE_NAME,
  ClubConstants.JUNIOR_COACH_ROLE_NAME,
  ClubConstants.PROVISIONAL_COACH_ROLE_NAME
];

const useClubStaffDetail = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const {
    environmentVariableState,
    HEAD_COACH_ORG_ROLE_ID,
    ASSISTANT_COACH_ORG_ROLE_ID,
    AGE_GROUP_COACH_ORG_ROLE_ID,
    SENIOR_COACH_ORG_ROLE_ID,
    ORG_ROLE_FIELD_ID_HEAD_COACH_FULL_PART_TIME,
    ORG_ROLE_FIELD_ID_ASSISTANT_COACH_FULL_PART_TIME,
    ORG_ROLE_FIELD_ID_AGE_GROUP_COACH_FULL_PART_TIME,
    ORG_ROLE_FIELD_ID_SENIOR_COACH_FULL_PART_TIME,
    CLUB_STAFF_ROLE_GROUP_ID,
    CLUB_STAFF_REGISTRATION_ROLE_GROUP_ID
  } = useEnvironmentVariableData();
  const { baseUsasClubState } = useBaseUsasClubData();
  const { usasClubStaffState, postUsasClubStaff, putUsasClubStaff, confirmUsasClubStaffSave } = useUsasClubStaffData();
  const {
    formState,
    errorState,
    isSubmitting,
    handleSubmit,
    updateFormState,
    setErrors,
    setFormData,
    onValueTextPairChanged,
    setIsDirty } = useForm(getFormInitialState, submitFormCallback, validate);
  const [viewState, setViewState] = useState(getInitialViewState);

  const coachTypeCodeOptions = [
    { id: 'F', name: "Full Time" },
    { id: 'P', name: "Part Time" }];

  const orgRoleToFieldIdMap = {
    [HEAD_COACH_ORG_ROLE_ID]: { id: ORG_ROLE_FIELD_ID_HEAD_COACH_FULL_PART_TIME },
    [ASSISTANT_COACH_ORG_ROLE_ID]: { id: ORG_ROLE_FIELD_ID_ASSISTANT_COACH_FULL_PART_TIME },
    [AGE_GROUP_COACH_ORG_ROLE_ID]: { id: ORG_ROLE_FIELD_ID_AGE_GROUP_COACH_FULL_PART_TIME },
    [SENIOR_COACH_ORG_ROLE_ID]: { id: ORG_ROLE_FIELD_ID_SENIOR_COACH_FULL_PART_TIME }
  };

  const coachRoleIds = [HEAD_COACH_ORG_ROLE_ID, ASSISTANT_COACH_ORG_ROLE_ID, AGE_GROUP_COACH_ORG_ROLE_ID, SENIOR_COACH_ORG_ROLE_ID];

  const onCancelClicked = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    navigate(NavLinks.CLUB_STAFF);
  };

  useEffect(() => {
    if (location.state?.personId) {
      const targetStaff = usasClubStaffState.arrayData.find(x => x.personId === location.state.personId);

      if (targetStaff && Array.isArray(targetStaff.personOrgRole)) {
        const coachRole = targetStaff.personOrgRole.find(x =>
        (
          x.orgRoleId === HEAD_COACH_ORG_ROLE_ID ||
          x.orgRoleId === ASSISTANT_COACH_ORG_ROLE_ID ||
          x.orgRoleId === AGE_GROUP_COACH_ORG_ROLE_ID ||
          x.orgRoleId === SENIOR_COACH_ORG_ROLE_ID));

        const coachField = (coachRole && coachRole.personOrgRoleFields && Array.isArray(coachRole.personOrgRoleFields)) ? coachRole.personOrgRoleFields.find(x => (
          x.orgRoleFieldId === ORG_ROLE_FIELD_ID_HEAD_COACH_FULL_PART_TIME ||
          x.orgRoleFieldId === ORG_ROLE_FIELD_ID_ASSISTANT_COACH_FULL_PART_TIME ||
          x.orgRoleFieldId === ORG_ROLE_FIELD_ID_AGE_GROUP_COACH_FULL_PART_TIME ||
          x.orgRoleFieldId === ORG_ROLE_FIELD_ID_SENIOR_COACH_FULL_PART_TIME)) : [];

        const orgRoles = getValidTreeOrgRoles(targetStaff.personOrgRole);

        setFormData({
          ...formState,
          personId: targetStaff.personId,
          personOrgRole: orgRoles.validTreeOrgRoles,
          coachTypeCode: coachField !== undefined ? coachField.fieldValue : '',
          headCoachOrgRoleId: HEAD_COACH_ORG_ROLE_ID,
          assistantCoachOrgRoleId: ASSISTANT_COACH_ORG_ROLE_ID,
          ageGroupCoachOrgRoleId: AGE_GROUP_COACH_ORG_ROLE_ID,
          seniorCoachOrgRoleId: SENIOR_COACH_ORG_ROLE_ID,
          clubStaffRegistrationRoleGroupId: CLUB_STAFF_REGISTRATION_ROLE_GROUP_ID,
          registrationRoles: orgRoles.registrationRoles
        });
        setIsDirty(true);
        setViewState({
          ...viewState,
          firstName: targetStaff.firstName || '',
          lastName: targetStaff.lastName || '',
          memberId: targetStaff.memberId || '',
          email: targetStaff.email || '',
          phoneNumber: targetStaff.phoneNumber || '',
          registrationRoleString: getRegistrationRolesString(orgRoles.registrationRoles)
        });
      }
    } else if (environmentVariableState.isLoaded === true) {
      setFormData({
        ...formState,
        headCoachOrgRoleId: HEAD_COACH_ORG_ROLE_ID,
        assistantCoachOrgRoleId: ASSISTANT_COACH_ORG_ROLE_ID,
        ageGroupCoachOrgRoleId: AGE_GROUP_COACH_ORG_ROLE_ID,
        seniorCoachOrgRoleId: SENIOR_COACH_ORG_ROLE_ID,
        clubStaffRegistrationRoleGroupId: CLUB_STAFF_REGISTRATION_ROLE_GROUP_ID
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (usasClubStaffState.isSaved === true) {
      confirmUsasClubStaffSave();
      setViewState({
        ...viewState,
        tryRedirect: true
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [usasClubStaffState]);

  useEffect(() => {
    if (viewState.tryRedirect === true) {
      navigate(NavLinks.CLUB_STAFF);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewState.tryRedirect]);

  function submitFormCallback(formState) {
    const uniqueRoleError = hasValidUniqueOrgRoles(formState);

    if (uniqueRoleError) {
      setErrors({
        ...errorState,
        uniqueRoleError
      });
    } else {
      if (formState.personId) {
        putUsasClubStaff(baseUsasClubState.objData.orgUnitId, formState.personId, CLUB_STAFF_ROLE_GROUP_ID, createPutStaffObj(formState.personId, formState.personOrgRole, formState.coachTypeCode));
      } else if (formState.postPersonId) {
        const dupStaff = usasClubStaffState.arrayData.find(x => x.personId === formState.postPersonId);

        if (dupStaff) {
          const combinedPersonOrgRoles = dupStaff.personOrgRole.map(x => { return { id: x.orgRoleId, name: x.orgRoleName }; });

          for (const role of formState.personOrgRole) {
            if (!combinedPersonOrgRoles.some(x => x.id === role.id) === true) {
              combinedPersonOrgRoles.push(role);
            }
          }

          putUsasClubStaff(baseUsasClubState.objData.orgUnitId, formState.postPersonId, CLUB_STAFF_ROLE_GROUP_ID, createPutStaffObj(formState.postPersonId, combinedPersonOrgRoles, formState.coachTypeCode));
        } else {
          postUsasClubStaff(baseUsasClubState.objData.orgUnitId, CLUB_STAFF_ROLE_GROUP_ID, createPostStaffObj(formState));
        }
      }
    }
  };

  function getValidTreeOrgRoles(personOrgRole) {
    const validTreeOrgRoles = [];
    const registrationRoles = [];

    for (const role of personOrgRole) {
      if (registrationRoleNames.find(x => x === role.orgRoleName)) {
        registrationRoles.push(role);
      } else {
        validTreeOrgRoles.push({ id: role.orgRoleId, name: role.orgRoleName });
      }
    };

    return {
      validTreeOrgRoles,
      registrationRoles
    };
  };

  function getRegistrationRolesString(registrationRoles) {
    let registrationRoleString = '';

    for (const registrationRole of registrationRoles) {
      if (registrationRoleString === '') {
        registrationRoleString = `${registrationRole.orgRoleName}`;
      } else {
        registrationRoleString += `, ${registrationRole.orgRoleName}`;
      }
    }

    return registrationRoleString;
  };

  function formatPersonOrgRoleObj(personOrgRole) {
    let personOrgRoleFieldsArray = [];
    let personOrgRoleFieldsObj = {};

    if (coachRoleIds.includes(personOrgRole.id)) {
      personOrgRoleFieldsObj = {
        fieldName: 'Full_Part_Time',
        orgRoleFieldId: orgRoleToFieldIdMap[personOrgRole.id].id,
        fieldValue: formState.coachTypeCode
      }
    }

    if (Object.keys(personOrgRoleFieldsObj).length > 0) {
      personOrgRoleFieldsArray.push(personOrgRoleFieldsObj);
    }

    let personOrgRoleObj = {
      orgRoleId: personOrgRole.id,
      orgRoleName: personOrgRole.name,
      personOrgRoleFields: personOrgRoleFieldsArray
    }

    return personOrgRoleObj;
  };

  function createPostStaffObj(formState) {
    return {
      personId: formState.postPersonId,
      personOrgRole: formState.personOrgRole.map(x => formatPersonOrgRoleObj(x))
    };
  };

  function createPutStaffObj(personId, personOrgRole, coachTypeCode) {
    const newPersonOrgRole = [];
    const currentStaffMember = usasClubStaffState.arrayData.find(x => x.personId === personId);

    if (currentStaffMember && Array.isArray(currentStaffMember.personOrgRole)) {
      let index = 0;
      for (const newRole of personOrgRole) {
        const oldRole = currentStaffMember.personOrgRole.find(x => x.orgRoleId === newRole.id);
        let fields = [];
        let fieldsObj = {};

        if (oldRole) {
          if (Array.isArray(oldRole.personOrgRoleFields) && oldRole.personOrgRoleFields.length > 0) {
            oldRole.personOrgRoleFields[index].fieldValue = coachTypeCode;
          } else {
            if (coachRoleIds.includes(oldRole.orgRoleId)) {
              fieldsObj = {
                fieldName: "Full_Part_Time",
                orgRoleFieldId: orgRoleToFieldIdMap[oldRole.orgRoleId].id,
                fieldValue: coachTypeCode
              };

              fields.push(fieldsObj);
              oldRole.personOrgRoleFields = fields;
            }
          }

          newPersonOrgRole.push(oldRole);
        } else {
          if (coachRoleIds.includes(newRole.id)) {
            fieldsObj = {
              fieldName: "Full_Part_Time",
              orgRoleFieldId: orgRoleToFieldIdMap[newRole.id].id,
              fieldValue: coachTypeCode
            };

            fields.push(fieldsObj);
            newPersonOrgRole.push({ orgRoleId: newRole.id, orgRoleName: newRole.name, personOrgRoleFields: fields });
          } else {
            newPersonOrgRole.push({ orgRoleId: newRole.id, orgRoleName: newRole.name });
          }
        }
      }
    } else {
      for (const role of personOrgRole) {
        newPersonOrgRole.push({ orgRoleId: role.id, orgRoleName: role.name });
      }
    }

    if (formState.registrationRoles.length > 0) {
      for (const registrationRole of formState.registrationRoles) {
        newPersonOrgRole.push(registrationRole);
      }
    }

    return {
      personId: personId,
      personOrgRole: newPersonOrgRole
    };
  };

  function hasValidUniqueOrgRoles(formState) {
    const uniqueOrgRoleIds = [HEAD_COACH_ORG_ROLE_ID];

    for (const uniqueRoleId of uniqueOrgRoleIds) {
      const matchingFormRole = formState.personOrgRole.find(x => x.id === uniqueRoleId);

      if (matchingFormRole) {
        for (const staff of usasClubStaffState.arrayData) {
          if (staff.personId !== formState.personId && staff.personId !== formState.postPersonId && Array.isArray(staff.personOrgRole)) {
            const matchingStaffRole = staff.personOrgRole.find(x => x.orgRoleId === uniqueRoleId);

            if (matchingStaffRole) {
              return `Can only have one ${matchingStaffRole.orgRoleName}, current ${matchingStaffRole.orgRoleName} is ${staff.firstName} ${staff.lastName}`;
            }
          }
        }
      }
    }

    return undefined;
  };

  function getFormInitialState() {
    return {
      personId: '',
      memberId: '',
      personOrgRole: [],
      postPersonId: '',
      coachTypeCode: '',
      coachTypeCodeName: '',
      headCoachOrgRoleId: Constants.DEFAULT_ID,
      assistantCoachOrgRoleId: Constants.DEFAULT_ID,
      ageGroupCoachOrgRoleId: Constants.DEFAULT_ID,
      seniorCoachOrgRoleId: Constants.DEFAULT_ID,
      clubStaffRegistrationRoleGroupId: Constants.DEFAULT_ID,
      registrationRoles: []
    };
  };

  function getInitialViewState() {
    return {
      firstName: '',
      lastName: '',
      memberId: '',
      phoneNumber: '',
      email: '',
      tryRedirect: false,
      registrationRoleString: ''
    };
  };

  return {
    formState,
    viewState,
    errorState,
    setFormData,
    environmentVariableState,
    usasClubStaffState,
    isEdit: formState.personId ? true : false,
    isSubmitting,
    onFormValueChanged: updateFormState,
    onValueTextPairChanged,
    handleSubmit,
    onCancelClicked,
    coachTypeCodeOptions,
    HEAD_COACH_ORG_ROLE_ID,
    ASSISTANT_COACH_ORG_ROLE_ID,
    AGE_GROUP_COACH_ORG_ROLE_ID,
    SENIOR_COACH_ORG_ROLE_ID
  };
};

export default useClubStaffDetail;