import React from 'react';

import usePreCompInstructorTrainingAgencyDropdown from './UsePreCompInstructorTrainingAgencyDropdown';

import Dropdown from '../../../../../common/components/dropdowns/Dropdown';

import global from '../../../../../common/components/GlobalStyle.module.css';

const PreCompInstructorTrainingAgencyDropdown = ({ label, name, value, error, message, onChange }) => {
  const { preCompCurriculumState } = usePreCompInstructorTrainingAgencyDropdown();

  return preCompCurriculumState.message
    ? <span className={global.LoadingMsg}>{preCompCurriculumState.message}</span>
    : (
      <Dropdown
        error={error}
        label={label}
        message={message}
        options={preCompCurriculumState.dataAsOptionsWithNames}
        name={name}
        value={value}
        onChange={onChange}
        isLoading={preCompCurriculumState.isLoading}
      />
    );
};

export default PreCompInstructorTrainingAgencyDropdown;