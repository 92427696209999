import React, { Fragment } from 'react';

import useSearchPreComp from './UseSearchPreComp';

import Input from '../../inputs/Input';
import Dropdown from '../../dropdowns/Dropdown';
import PrimaryButton from '../../buttons/PrimaryButton';
import SecondaryButton from '../../buttons/SecondaryButton';
import Headings from '../../headings/Headings';
import StatesCombobox from '../../comboboxes/statesCombobox/StatesCombobox';
import PopUpModal from '../../dialogs/PopUpModal';
import Constants from '../../../utils/Constants';

import global from '../../GlobalStyle.module.css';

const Form = ({ handleSubmit, formState, errorState, onFormValueChanged, onValueTextPairChanged, clearForm, sortByOptions,
  onCancelClicked, submitButtonText }) => (
  <form onSubmit={handleSubmit} noValidate>
    <div className="row usas-extra-top-margin">
      <div className="col-xs-12 col-sm-6 col-md-4">
        <Input
          label="Organization/Location Name"
          name="name"
          value={formState.name}
          error={errorState.name}
          message={errorState.name}
          onChange={(value) => { onFormValueChanged('name', value) }} />
      </div>
      <div className="col-xs-12 col-sm-6 col-md-4">
        <Input
          label="Federal Tax Id"
          name="federalTaxId"
          value={formState.federalTaxId}
          error={errorState.federalTaxId}
          message={errorState.federalTaxId}
          onChange={(value) => { onFormValueChanged('federalTaxId', value) }} />
      </div>
      <div className="col-xs-12 col-sm-6 col-md-4">
        <Input
          label="City"
          name="city"
          value={formState.city}
          error={errorState.city}
          message={errorState.city}
          onChange={(value) => { onFormValueChanged('city', value) }} />
      </div>
      <div className="col-xs-12 col-sm-6 col-md-4">
        <StatesCombobox
          label="State"
          name="stateCode"
          valueToMatch={formState.stateName}
          error={errorState.stateCode}
          message={errorState.stateCode}
          isUSA={true}
          onChange={(newValue, newValueLabel) => {
            onValueTextPairChanged(newValue, "stateCode", newValueLabel, "stateName");
          }} />
      </div>
      <div className="col-xs-12 col-sm-6 col-md-4">
        <Input
          label="Zip Code"
          name="zipCode"
          value={formState.zipCode}
          error={errorState.zipCode}
          message={errorState.zipCode}
          onChange={(value) => { onFormValueChanged('zipCode', value) }} />
      </div>
      <div className="col-xs-12 col-sm-6 col-md-4">
        <Dropdown
          label="Sort Results By"
          name="sortById"
          options={sortByOptions}
          value={formState.sortById}
          error={errorState.sortById}
          message={errorState.sortById}
          isLoading={false}
          onChange={(newValue, newValueLabel, e) => {
            e && e.target && e.target.value &&
              onValueTextPairChanged(newValue, 'sortById', newValueLabel, 'sortByName');
          }} />
      </div>
    </div>
    {(errorState.filter || errorState.errorOnSubmitAction) &&
      <div className="row">
        <div className="col-xs-12">
          <p className={global.ErrorMessage}>{errorState.filter || ''}</p>
          <p className={global.ErrorMessage}>{errorState.errorOnSubmitAction || ''}</p>
        </div>
      </div>}
    <div className="row usas-extra-top-margin usas-extra-bottom-margin">
      <div className="col-xs-12 usas-extra-top-margin">
        <PrimaryButton type="submit">{submitButtonText}</PrimaryButton>&nbsp;
        <SecondaryButton type="button" onClick={() => clearForm()}>Clear Form</SecondaryButton>&nbsp;
        {onCancelClicked ? <SecondaryButton type="button" onClick={() => onCancelClicked()}>Back</SecondaryButton> : <Fragment />}
      </div>
    </div>
  </form>
)

const SearchPreComp = ({ pageTitle, submitButtonText, grid, searchPreCompState, onCancelClicked }) => {
  const {
    formState,
    errorState,
    preCompOrganizationState,
    clearForm,
    handleSubmit,
    onFormValueChanged,
    onValueTextPairChanged,
    sortByOptions } = useSearchPreComp();

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>{pageTitle}</Headings.H3>
        </div>
      </div>
      {/* If the search component setup (either 1. toggles filters and results or 2. shows filters and results at the same time) is the same everywhere the component is used, 
      then the below ternany is not needed. You would simply pick whichever option is appropriate, and delete the other option. However, in this case one view uses option #1 
      and the other uses option #2, so I set up a ternary that looks to see if 'searchPreCompState' is defined because that's the context provider state where 'showResults' 
      is stored. During this refactor, I had to change the local state for 'showResults' into the 'searchPreCompState' context provider state because I was referencing 
      'showsResults' in multiple files. I wrapped this context provider around the Routes in the PreComp.js and ClubRegistration.js files.
      Also, if the grid setups where more similar, I would have imported the same grid component into the file, instead of passing in the separate grids */}
      {searchPreCompState !== undefined ?
        //club registration (toggles filters and results)
        <Fragment>
          <div className={searchPreCompState.showFilters === true ? global.DisplayComponent : global.HideComponent}>
            <Form
              formState={formState}
              errorState={errorState}
              clearForm={clearForm}
              handleSubmit={handleSubmit}
              onFormValueChanged={onFormValueChanged}
              onValueTextPairChanged={onValueTextPairChanged}
              sortByOptions={sortByOptions}
              onCancelClicked={onCancelClicked}
              submitButtonText={submitButtonText}
              searchPreCompState={searchPreCompState}
            />
          </div>
          <div className="row usas-extra-bottom-margin">
            <div className="col-xs-12">
              {grid}
            </div>
          </div>
          {onCancelClicked && searchPreCompState.showFilters === false ?
            <Fragment>
              <div className="row usas-extra-top-margin usas-extra-bottom-margin">
                <div className="col-xs-12 usas-extra-top-margin">
                  <SecondaryButton type="button" onClick={() => onCancelClicked()}>Back</SecondaryButton>
                </div>
              </div>
            </Fragment> : <Fragment />}
        </Fragment>
        :
        //pre comp (shows filters and results at the same time)
        <Fragment>
          <Form
            formState={formState}
            errorState={errorState}
            clearForm={clearForm}
            handleSubmit={handleSubmit}
            onFormValueChanged={onFormValueChanged}
            onValueTextPairChanged={onValueTextPairChanged}
            sortByOptions={sortByOptions}
            submitButtonText={submitButtonText}
          />
          <div className="row usas-extra-bottom-margin">
            <div className="col-xs-12">
              {grid}
            </div>
          </div>
        </Fragment>
      }
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={preCompOrganizationState.isArrayLoading || preCompOrganizationState.isObjLoading} />
    </Fragment >
  )
}

export default SearchPreComp;