import { useEffect } from 'react';
import { useNavigate } from '../../../../../common/wrappers/ReactRouterDom';
import NavLinks from '../../NavLinks';

import useBaseUsasClubData from '../../../../../common/state/clubBase/UseBaseUsasClubData';
import useVendorData from '../../../../../common/state/vendor/UseVendorData';
import useClubVendorData from '../../../../state/clubVendor/UseClubVendorData';
import useForm from '../../../../../common/utils/UseForm';
import Constants from '../../../../../common/utils/Constants';

import validate from './ClubAddVendorValidation';

const INITIAL_FORM_STATE = {
  vendorId: Constants.DEFAULT_ID,
  vendorName: '',
};

const useClubAddVendor = () => {
  const navigate = useNavigate();
  const { baseUsasClubState } = useBaseUsasClubData();
  const { postClubVendorForOrgUnit, clubVendorState } = useClubVendorData();
  const { vendorState } = useVendorData(true);
  const { formState, errorState, handleSubmit, onValueTextPairChanged } = useForm(INITIAL_FORM_STATE, submitFormCallback, validate);

  function submitFormCallback() {
    if (baseUsasClubState?.objData?.orgUnitId !== undefined) {
      const orgUnitId = baseUsasClubState.objData.orgUnitId;

      const addVendorObj = {
        orgUnitId: orgUnitId,
        vendorId: formState.vendorId
      };

      postClubVendorForOrgUnit(addVendorObj);
    }
  };

  const onBackToVendorsButtonClicked = (e) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }

    navigate(NavLinks.CLUB_ACTIVATE_VENDOR);
  }

  useEffect(() => {
    if (clubVendorState.isSaved === true) {

      navigate(NavLinks.CLUB_ACTIVATE_VENDOR);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clubVendorState.isSaved]);

  return {
    vendorState,
    formState,
    errorState,
    onBackToVendorsButtonClicked,
    onValueTextPairChanged,
    clubVendorState,
    handleSubmit
  };
};

export default useClubAddVendor;