import { createContext, useState } from 'react';

export const MemberAdministrationHeaderStateContext = createContext();

const INITIAL_STATE = {
  isObjLoading: false,
  isObjLoaded: false,
  isSaving: false,
  isSaved: false,
  objData: {},
  message: '',
  myOtsMeets: false,
  myLscOtsMeetSearch: false
};

const MemberAdministrationHeaderContextProvider = ({ children }) => {
  const stateHook = useState(INITIAL_STATE);

  return (
    <MemberAdministrationHeaderStateContext.Provider value={stateHook}>
      {children}
    </MemberAdministrationHeaderStateContext.Provider>
  );
};

export default MemberAdministrationHeaderContextProvider;