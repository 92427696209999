import React, { Fragment } from 'react';

import global from '../../../../GlobalStyle.module.css';

const LargeGrid = ({ state }) => (
  <table className={[global.UsasTable, 'visible-lg'].join(' ')}>
    <thead>
      <tr>
        <th>Facility Name</th>
        <th>Address</th>
        <th>City</th>
        <th>State</th>
        <th>Zip Code</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>{state.facilityName}</td>
        <td>{state.address1} {state.address2}</td>
        <td>{state.city}</td>
        <td>{state.stateCode}</td>
        <td>{state.postalCode}</td>
      </tr>
    </tbody>
  </table>
);


const SmallGrid = ({ state }) => (
  <div className={[global.SmallTable, 'hidden-lg'].join(' ')}>
    <div className={global.SmallTableRowHead}><span className='hidden-xs'>{state.facilityName || <span>&nbsp;</span>}</span>&nbsp;</div>
    <div className={global.SmallTableRowInfo}>
      <div className='row'>
        <div className={[global.SmallTableRowLabels, 'col-xs-6 visible-xs'].join(' ')}>Facility Name</div>
        <div className={[global.SmallTableRowData, 'col-xs-6 visible-xs'].join(' ')}>{state.facilityName || <span>&nbsp;</span>}</div>
        <div className={[global.SmallTableRowLabels, 'col-xs-6'].join(' ')}>Address</div>
        <div className={[global.SmallTableRowData, 'col-xs-6'].join(' ')}>{state.address1} {state.address2}</div>
        <div className={[global.SmallTableRowLabels, 'col-xs-6'].join(' ')}>City</div>
        <div className={[global.SmallTableRowData, 'col-xs-6'].join(' ')}>{state.city || <span>&nbsp;</span>}</div>
        <div className={[global.SmallTableRowLabels, 'col-xs-6'].join(' ')}>State</div>
        <div className={[global.SmallTableRowData, 'col-xs-6'].join(' ')}>{state.stateCode || <span>&nbsp;</span>}</div>
        <div className={[global.SmallTableRowLabels, 'col-xs-6'].join(' ')}>Zip Code</div>
        <div className={[global.SmallTableRowData, 'col-xs-6'].join(' ')}>{state.postalCode || <span>&nbsp;</span>}</div>
      </div>
    </div>
  </div>
);

const FacilityConfirmationGrid = ({ state }) => (
  <Fragment>
    <p className={global.Bold}>Are you sure you would like to add the facility listed below?</p>
    <LargeGrid state={state} />
    <SmallGrid state={state} />
  </Fragment>
);

export default FacilityConfirmationGrid;