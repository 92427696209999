import React, { Fragment } from 'react';

import usePreCompLocationStaff from './UsePreCompLocationStaff';

import PreCompLocationStaffGrid from '../components/grids/PreCompLocationStaffGrid';

import PopUpModal from '../../../../common/components/dialogs/PopUpModal';
import Headings from '../../../../common/components/headings/Headings';
import PrimaryButton from '../../../../common/components/buttons/PrimaryButton';
import SecondaryButton from '../../../../common/components/buttons/SecondaryButton';
import Constants from '../../../../common/utils/Constants';
import ActionIntraPageButton from '../../../../common/components/buttons/ActionIntraPageButton';

const PreCompLocationStaff = () => {
  const {
    isSaving,
    isLoading,
    preCompLocationStaffState,
    deleteModalState,
    onAddStaff,
    onEditStaff,
    onDeleteRole,
    canDeletePrimaryContact,
    onModalCancelClicked,
    onModalDeleteClicked,
    PRIMARY_CONTACT_ORG_ROLE_ID
  } = usePreCompLocationStaff();

  return (
    <Fragment>
      <Headings.H3>Location Staff</Headings.H3>
      <div className="row">
        <div className="col-xs-12 usas-extra-top-margin">
          <ActionIntraPageButton onClick={onAddStaff}>Add Staff Member</ActionIntraPageButton>
        </div>
      </div>
      <div className="row usas-extra-top-margin usas-extra-bottom-margin">
        <div className="col-xs-12">
          <PreCompLocationStaffGrid
            data={preCompLocationStaffState.arrayData}
            isLoading={preCompLocationStaffState.isArrayLoading}
            onEdit={onEditStaff}
            onDelete={onDeleteRole}
            canDeletePrimaryContact={canDeletePrimaryContact(preCompLocationStaffState.arrayData) ? true : false} 
            PRIMARY_CONTACT_ORG_ROLE_ID={PRIMARY_CONTACT_ORG_ROLE_ID}
            />
        </div>
      </div>
      <PopUpModal
        widthPct={90}
        maxWidth={720}
        title={'Remove Staff Member?'}
        displayPopUp={deleteModalState.displayModal}
        onModalCanceled={onModalCancelClicked}>
        <div className="row">
          <div className="col-xs-12">
            <p>{`Removing ${deleteModalState.personName}'s last role will remove them from this pre-competitive location's staff`}</p>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12 usas-extra-top-margin">
            <PrimaryButton type="button" onClick={onModalDeleteClicked}>Remove Staff Member</PrimaryButton>&nbsp;
            <SecondaryButton type="button" onClick={onModalCancelClicked}>Cancel</SecondaryButton>
          </div>
        </div>
      </PopUpModal>
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.SAVING_MSG}
        displayPopUp={isSaving} />
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={isLoading} />
    </Fragment>
  );
};

export default PreCompLocationStaff;