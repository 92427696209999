import React, { Fragment } from 'react';

import PreCompOfferingTypeTreeView from '../offeringTreeView/PreCompOfferingTypeTreeView';

import Input from '../../../../../common/components/inputs/Input';
import ReadOnly from '../../../../../common/components/readOnly/ReadOnly';

const PreCompLocationProgramOfferingForm = ({ formState, errorState, onFormValueChanged, onOfferingTypeChanged }) => (
  <Fragment>
    <div className="row">
      <div className="col-xs-12 col-md-6">
        {onOfferingTypeChanged === undefined
          ? <ReadOnly
            label="Program Offering"
            name="programOffering"
            value={formState.preCompLocationOfferingName} />
          : (
            <PreCompOfferingTypeTreeView
              label="Program Offering*"
              name="programOffering"
              value={formState.programOffering}
              error={errorState.programOffering}
              message={errorState.programOffering}
              onChange={(value) => { onOfferingTypeChanged("programOffering", value); }} />
          )
        }
      </div>
      <div className="col-xs-12 col-sm-6 col-md-6">
        {onFormValueChanged === undefined
          ? <ReadOnly
            label={`Average Number of Unique Participants Annually`}
            name="enrollmentCount"
            value={formState.enrollmentCount} />
          : (
            <Input
              label={`Average Number of Unique Participants Annually*`}
              name="enrollmentCount"
              value={formState.enrollmentCount}
              error={errorState.enrollmentCount}
              message={errorState.enrollmentCount}
              onChange={(value) => { onFormValueChanged("enrollmentCount", value); }} />
          )
        }
      </div>
      <div className="col-xs-12 col-sm-6 col-md-6">
        {onFormValueChanged === undefined
          ? <ReadOnly
            label={`Average Number of Unique Participants Given Full or Partial Scholarships Annually`}
            name="scholarshipCount"
            value={formState.scholarshipCount} />
          : (
            <Input
              label={`Average Number of Unique Participants Given Full or Partial Scholarships Annually*`}
              name="scholarshipCount"
              value={formState.scholarshipCount}
              error={errorState.scholarshipCount}
              message={errorState.scholarshipCount}
              onChange={(value) => { onFormValueChanged("scholarshipCount", value); }} />
          )
        }
      </div>
    </div>
  </Fragment>
);

export default PreCompLocationProgramOfferingForm;