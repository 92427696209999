import React, { Fragment } from 'react';

import PlusIcon from '../../../../common/components/icons/PlusIcon';

import global from '../../../../common/components/GlobalStyle.module.css';

const GridRow = ({ preCompLocation, onAdd, usasClubPreCompLinksState }) => {
  const matchIndex = usasClubPreCompLinksState.arrayData.findIndex(e => e.preCompetitiveLocationId === preCompLocation.locationId);
  return (
    <tr>
      <td>{preCompLocation.locationName}</td>
      <td>{preCompLocation.organizationName}</td>
      <td>{preCompLocation.location}</td>
      <td> {matchIndex === -1 ?
        <button className={global.IconButtonMargin} type="button" onClick={(e) => onAdd(e, preCompLocation.locationId)}>
          <PlusIcon />
        </button> : <Fragment />}
      </td>
    </tr>
  )
}

const ClubPreCompSearchGridLarge = ({ data, isLoading, onAdd, usasClubPreCompLinksState }) => (
  <table className={[global.UsasTable, 'visible-md visible-lg'].join(' ')}>
    <thead>
      <tr>
        <th>Location Name</th>
        <th>Organization Name</th>
        <th>Address</th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      {isLoading === true
        ? <tr><td colSpan="4">Loading...</td></tr>
        : data.length > 0
          ? data.map((preCompLocation, i) => <GridRow key={i} preCompLocation={preCompLocation} onAdd={onAdd} usasClubPreCompLinksState={usasClubPreCompLinksState} />)
          : <tr><td colSpan="4">No Results</td></tr>
      }
    </tbody>
  </table>
);

export default ClubPreCompSearchGridLarge;