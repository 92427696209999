import { BASIC_INITIAL_STATE } from '../../utils/HttpHelper';
import Constants from '../../utils/Constants';

import getGroupFacilitiesByUsasClubId from './GetGroupFacilitiesByUsasClubIdData';
import getGroupFacilitiesOrgUnitId from './GetGroupFacilitiesByOrgUnitIdData';

const INITIAL_STATE = {
  ...BASIC_INITIAL_STATE,
  options: [],
  usasClubId: Constants.DEFAULT_ID,
  orgUnitID: ''
};

const GroupFacilitiesData = {
  INITIAL_STATE,
  getGroupFacilitiesByUsasClubId,
  getGroupFacilitiesOrgUnitId
};

export default GroupFacilitiesData;