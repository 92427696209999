import { useEffect, useState } from "react";
import { useGetWidgetModel, ChartProps } from "@sisense/sdk-ui";
import { useLocation, useNavigate } from "../../../../../../common/wrappers/ReactRouterDom";

import useNavRoutes from "../../../../../../common/state/security/UseNavRoutes";

const useIndividualTimesSearchProgressionWidget = (dashboardOid, widgetOid) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { navRoutes } = useNavRoutes();
  const today = new Date();
  const month = today.getMonth();
  const year = today.getFullYear();
  const greaterThanYear = month <= 7 ? year - 10 : year - 9;
  const [hookEnabled, setHookEnabled] = useState(true);
  const [chartProps, setChartProps] = useState<ChartProps>();

  const { widget, isLoading, isError } = useGetWidgetModel({
    dashboardOid: dashboardOid,
    widgetOid: widgetOid,
    enabled: hookEnabled
  });

  const onBackToPersonTimesSearchClicked = (e) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }

    navigate(navRoutes.CLUB_PERFORMANCE_ATHLETE_TIMES_DETAIL?.route, {
      state: {
        personKey: location.state?.personKey,
        personName: location.state?.personName,
        clubRosterFilters: location.state?.clubRosterFilters,
        individualTimesSearchFilters: location.state?.individualTimesSearchFilters
      }
    })
  };

  function convertRawTime(seconds) {
    var totalSeconds = parseFloat(seconds); // Ensure we're working with a number, maintaining decimals
    var sign = totalSeconds < 0 ? '-' : '';
    var absTotalSeconds = Math.abs(totalSeconds);
    var minutes = Math.floor(absTotalSeconds / 60);
    var preciseSeconds = (absTotalSeconds % 60).toFixed(2); // Keeps two decimal places for seconds
    var preciseSecondsNum = Number(preciseSeconds);
    var formattedTime = minutes > 0 ? (minutes < 10 ? minutes : minutes) + ":" +
      (preciseSecondsNum < 10 ? "0" + preciseSeconds : preciseSeconds) :
      (preciseSecondsNum < 10 ? "0" + preciseSeconds : preciseSeconds);
    return sign + formattedTime;
  }

  useEffect(() => {
    if (widget && !chartProps) {
      setChartProps(widget.getChartProps());
    }
    if (chartProps && hookEnabled) {
      setHookEnabled(false);
    }
  }, [widget, chartProps])

  return {
    chartProps,
    isLoading,
    isError,
    widget,
    location,
    navRoutes,
    greaterThanYear,
    onBackToPersonTimesSearchClicked,
    convertRawTime
  };
};

export default useIndividualTimesSearchProgressionWidget;