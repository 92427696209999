import { BASIC_INITIAL_STATE } from '../../utils/HttpHelper';

import getVendors from './GetVendorData';

const INITIAL_STATE = {
  ...BASIC_INITIAL_STATE
};

const VendorData = {
  INITIAL_STATE,
  getVendors

};

export default VendorData;