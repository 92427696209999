import { API_NAMES, initApi } from "../../../common/utils/HttpApiHelper";
import { BASIC_INITIAL_STATE } from "../../../common/utils/HttpHelper";

const getDecryptedIdsData = (encryptedIds, state, setState) => {
  const api = initApi(API_NAMES.COMMON, state, setState);
  const url = `/App/DecryptId`;

  return api ? api.executeObject(url, 'POST', encryptedIds) : null;
};

const INITIAL_STATE = {
  ...BASIC_INITIAL_STATE
};

const ClubPerformanceData = {
  INITIAL_STATE,
  getDecryptedIdsData
};

export default ClubPerformanceData;