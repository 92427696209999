import { useEffect, useState } from 'react';
import { useNavigate } from '../../../../common/wrappers/ReactRouterDom';

import NavLinks from '../NavLinks';

import validate from './PreCompRegistrationSubmitValidation';

import PreCompRegistrationConstants from '../utils/PreCompRegistrationConstants';

import usePreCompOrganizationRegistrationData from '../../../state/preCompOrganizationRegistration/UsePreCompOrganizationRegistrationData';
import usePreCompLocationRegistrationData from '../../../state/preCompLocationRegistration/UsePreCompLocationRegistrationData';
import usePreCompLocationRegistrationNavData from '../../../state/preCompLocationRegistration/preCompLocationRegistrationLeftNavBar/UsePreCompLocationRegistrationNavData';

import useForm from '../../../../common/utils/UseForm';
import Constants from '../../../../common/utils/Constants';
import { formatDate } from '../../../../common/utils/DateFunctions';

const usePreCompRegistrationSubmit = () => {
  const navigate = useNavigate();
  const { preCompOrganizationRegistrationState, postPreCompRegistration } = usePreCompOrganizationRegistrationData();
  const { preCompLocationRegistrationState } = usePreCompLocationRegistrationData();
  const { locationRegistrationNavState, updateCurrentNavState } = usePreCompLocationRegistrationNavData();
  const { formState, errorState, handleSubmit, updateFormState, setFormData
  } = useForm(getInitialFormState, submitFormCallback, validate);
  const [submitState, setSubmitState] = useState(getInitialSubmitState);

  const onBackClicked = () => {
    navigate(NavLinks.PRE_COMP_REGISTRATION_LOCATION_EMERGENCY_PLAN);
  };

  const onExitRegistrationClicked = () => {
    navigate(NavLinks.PRE_COMP_REGISTRATION_ENTRY);
  };

  useEffect(() => {
    updateCurrentNavState(PreCompRegistrationConstants.SUBMIT);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (preCompLocationRegistrationState.isObjLoaded === true && preCompOrganizationRegistrationState.isObjLoaded === true) {
      setFormData({
        ...formState,
        submitDate: formatDate(new Date())
      });
    } else {
      navigate(NavLinks.PRE_COMP_REGISTRATION_ENTRY);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [preCompLocationRegistrationState.isObjLoaded, preCompOrganizationRegistrationState.isObjLoaded]);

  function submitFormCallback(formState) {
    if (submitState.isSaving === false) {
      postPreCompRegistration(preCompOrganizationRegistrationState.objData, preCompLocationRegistrationState.objData, formState, submitState, setSubmitState);
    }
  };

  function getInitialFormState() {
    return {
      signature: '',
      submitDate: Constants.BLANK_DATE_STRING
    };
  };

  function getInitialSubmitState() {
    return {
      objData: {},
      isObjLoaded: false,
      isObjLoading: false,
      isSaved: false,
      isSaving: false
    };
  };

  return {
    isSaving: submitState.isSaving,
    isLoading: submitState.isObjLoading,
    displaySavedModal: submitState.isSaved,
    locationRegistrationNavState,
    formState,
    errorState,
    handleSubmit,
    onFormValueChanged: updateFormState,
    onExitRegistrationClicked,
    onBackClicked
  };
};

export default usePreCompRegistrationSubmit;