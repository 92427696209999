import React, { Fragment } from 'react';

import HideIcon from '../../../../common/components/icons/HideIcon';
import ShowIcon from '../../../../common/components/icons/ShowIcon';
import DeleteIcon from '../../../../common/components/icons/DeleteIcon';
import EditIcon from '../../../../common/components/icons/EditIcon';

import global from '../../../../common/components/GlobalStyle.module.css';

const DetailTableRow = ({ personId, memberId, role, onDelete, registrationRoles }) => (
  <div className={global.SmallTableRow}>
    <div className={global.SmallTableDetailRowHead}><span className='hidden-xs'>{role.orgRoleName}</span>&nbsp;
      {!registrationRoles.find(x => x === role.orgRoleName) &&
        <button className={global.IconButton} type="button" onClick={(e) =>
          onDelete(e, personId, memberId, role.orgRoleId, role.personOrgRoleDurationId)}>
          <DeleteIcon />
        </button>
      }
    </div>
    <div className={global.SmallTableRowInfo}>
      <div className='row'>
        <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3 visible-xs'].join(' ')}>Staff Role</div>
        <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3 visible-xs'].join(' ')}>{role.orgRoleName}</div>
      </div>
    </div>
  </div>
);

const DetailTable = ({ staff, onDelete, registrationRoles }) => (
  <div className={global.SmallTableDetailTable}>
    {staff && Array.isArray(staff.personOrgRole) && staff.personOrgRole.map((role, i) =>
      <DetailTableRow key={i} personId={staff.personId} memberId={staff.memberId} role={role} onDelete={onDelete} registrationRoles={registrationRoles} />)}
  </div>
);

const TableRow = ({ staff, onEdit, onDelete, expandedId, onClick, registrationRoles }) => {
  return (
    <Fragment>
      <div className={global.SmallTableRow}>
        <div className={global.SmallTableRowHead}>
          <span className='hidden-xs'>{staff.firstName} {staff.lastName}</span>&nbsp;
          <button className={global.IconButton}
            type="button"
            onClick={(e) => onClick(e, staff.personId)}>
            {staff.personId === expandedId ? <HideIcon /> : <ShowIcon />}
          </button>
          <button className={global.IconButtonMobileMargin} type="button" onClick={(e) => onEdit(e, staff.personId)}>
            <EditIcon />
          </button>
        </div>
        <div className={global.SmallTableRowInfo}>
          <div className='row'>
            <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3 visible-xs'].join(' ')}>Member Name</div>
            <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3 visible-xs'].join(' ')}>{staff.firstName} {staff.lastName}</div>
            <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3'].join(' ')}>Member Id</div>
            <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3'].join(' ')}>{staff.memberId || <span>&nbsp;</span>}</div>
            <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3'].join(' ')}>Is In Good Standing?</div>
            <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3'].join(' ')}>{staff.isInGoodStanding === true ? 'Yes' : 'No'}</div>
          </div>
        </div>
      </div>
      {Array.isArray(staff.personOrgRole) && staff.personOrgRole.length > 0 && expandedId === staff.personId &&
        <DetailTable staff={staff} onDelete={onDelete} registrationRoles={registrationRoles} />}
    </Fragment>
  );
};

const ClubRegistrationSmallGridWrite = ({ state, onEdit, onDelete, expandedId, onClick, registrationRoles }) => (
  <div className={[global.SmallTable, 'hidden-md hidden-lg'].join(' ')}>
    {state.isArrayLoading === true ? (
      <div className={global.SmallTableRow}>
        <div className={global.SmallTableRowHead}>&nbsp;</div>
        <div className={global.SmallTableRowInfo}>
          <div className={global.SmallTableRowLabels}>
            Loading...
          </div>
        </div>
      </div>
    ) : Array.isArray(state.arrayData) && state.arrayData.length > 0 ?
      state.arrayData.map((staff, i) => <TableRow key={i} staff={staff} onEdit={onEdit} onDelete={onDelete}
        expandedId={expandedId} onClick={onClick} registrationRoles={registrationRoles} />)
      : (
        <div className={global.SmallTableRow}>
          <div className={global.SmallTableRowHead}>&nbsp;</div>
          <div className={global.SmallTableRowInfo}>
            <div className={global.SmallTableRowLabels}>
              No Staff
            </div>
          </div>
        </div>)
    }
  </div>
);

export default ClubRegistrationSmallGridWrite;