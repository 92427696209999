import { useState } from 'react';

import ClubGroupComboboxData from './ClubGroupComboboxData';

const useClubGroupComboboxData = () => {
  const [clubGroupComboboxState, setClubGroupComboboxState] = useState(ClubGroupComboboxData.INITIAL_STATE);

  const getClubGroupsByOrgUnitIdAndGroupTypeId = (orgUnitId, groupTypeId) => {
    ClubGroupComboboxData.getClubGroupsByOrgUnitIdAndGroupTypeIdData(orgUnitId, groupTypeId, clubGroupComboboxState, setClubGroupComboboxState);
  };

  return {
    clubGroupComboboxState,
    getClubGroupsByOrgUnitIdAndGroupTypeId
  };
};

export default useClubGroupComboboxData;