import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Fragment } from "react";

const RequiredRolesDisplay = ({ rolesArray }) => {
return (
    <div>
        {rolesArray.map((row, i) => (
        <div key={i} className="row mx-auto">
          <div className="col-xs-12">
          {row.map((col, i) => (
            <span style={
                {
                    backgroundColor: col.met ? "lightgreen" : "red", 
                    borderRadius: 10, paddingRight: 10, paddingLeft: 10, marginRight: 10,
                    color: col.met ? "navyblue" : "white", fontSize: 15
                }
            } key={i}>{col.orgRoleName}&nbsp;&nbsp;{col.met ? <FontAwesomeIcon icon={faCheck} /> : <Fragment />}</span>
          ))}
          </div>
        </div>
      ))}
    </div>
  )};
  
  export default RequiredRolesDisplay;