import { useEffect } from 'react';

import useClubGroupComboboxData from '../../../state/clubGroupCombobox/UseClubGroupComboboxData';

const useClubGroupCombobox = (orgUnitId, groupTypeId) => {
  const { clubGroupComboboxState, getClubGroupsByOrgUnitIdAndGroupTypeId } = useClubGroupComboboxData();

  useEffect(() => {
    if (orgUnitId && groupTypeId > 0 && clubGroupComboboxState.isArrayLoaded !== true
      && clubGroupComboboxState.isArrayLoading !== true) {
      getClubGroupsByOrgUnitIdAndGroupTypeId(orgUnitId, groupTypeId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clubGroupComboboxState, orgUnitId, groupTypeId]);

  return {
    clubGroupComboboxState
  };
};

export default useClubGroupCombobox;