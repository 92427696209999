import { useEffect, useState } from 'react';

import validate from './RegistrationSubmitFormValidation';

import useLearnToSwimLogoData from '../../../../state/learnToSwimFiles/UseLearnToSwimLogoData';
import useLearnToSwimCurriculumFileData from '../../../../state/learnToSwimFiles/UseLearnToSwimCurriculumFileData';
import useLearnToSwimProofOfInsuranceData from '../../../../state/learnToSwimFiles/UseLearnToSwimProofOfInsuranceData';
import useLearnToSwimFormW9Data from '../../../../state/learnToSwimFiles/UseLearnToSwimFormW9Data';

import UseForm from "../../../../../common/utils/UseForm";
import Constants from '../../../../../common/utils/Constants';

const INITIAL_FORM_STATE = {
  logoUrl: '',
  logoFile: {},
  curriculumUrl: '',
  curriculumFile: {},
  proofOfInsuranceUrl: '',
  proofOfInsuranceFile: {},
  formW9Url: '',
  formW9File: {}
};

const useRegistrationSubmitForm = (onSubmitFormCallback) => {
  const { ltsLogoState, postLTSLogo } = useLearnToSwimLogoData();
  const { ltsCurriculumFileState, postLTSCurriculum } = useLearnToSwimCurriculumFileData();
  const { ltsProofOfInsuranceState, postLTSProofOfInsurance } = useLearnToSwimProofOfInsuranceData();
  const { ltsFormW9State, postLTSFormW9 } = useLearnToSwimFormW9Data();
  const [state, setState] = useState({ trySubmit: false });
  const { formState, errorState, isDirty, setIsDirty, setFormState, handleSubmit, updateFormState,
    setErrors
  } = UseForm(INITIAL_FORM_STATE, localSubmitFormCallback, validate);

  const onLogoFileLoad = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    setErrors({ ...errorState, logoFile: '' });
    if (e.target.files[0]) {
      const selectedFile = e.target.files[0];
      const fileType = e.target.files[0].type;

      if (fileType === Constants.FILE_TYPE_JPG) {
        updateFormState('logoFile', selectedFile);
      } else {
        setErrors({ ...errorState, logoFile: 'File type not permitted. Please provide a (*.jpg) file.' });
      }
    }
  };

  const onLogoFileUnload = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    setFormState({
      ...formState,
      logoFile: {},
      logoUrl: ''
    });
  };

  const onCurriculumFileLoad = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    setErrors({ ...errorState, curriculumFile: '' });
    if (e.target.files[0]) {
      const selectedFile = e.target.files[0];
      const fileType = e.target.files[0].type;

      if (fileType === Constants.FILE_TYPE_PDF || fileType === Constants.FILE_TYPE_DOC ||
        fileType === Constants.FILE_TYPE_DOCX) {
        updateFormState('curriculumFile', selectedFile);
      } else {
        setErrors({ ...errorState, curriculumFile: 'File type not permitted. Please provide a (*.pdf), (*.doc), or (*.docx) file.' });
      }
    }
  };

  const onCurriculumFileUnload = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    setFormState({
      ...formState,
      curriculumFile: {},
      curriculumUrl: ''
    });
  };

  const onProofOfInsuranceFileLoad = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    setErrors({ ...errorState, proofOfInsuranceFile: '' });
    if (e.target.files[0]) {
      const selectedFile = e.target.files[0];
      const fileType = e.target.files[0].type;

      if (fileType === Constants.FILE_TYPE_PDF || fileType === Constants.FILE_TYPE_DOC ||
        fileType === Constants.FILE_TYPE_DOCX) {
        updateFormState('proofOfInsuranceFile', selectedFile);
      } else {
        setErrors({ ...errorState, proofOfInsuranceFile: 'File type not permitted. Please provide a (*.pdf), (*.doc), or (*.docx) file.' });
      }
    }
  };

  const onProofOfInsuranceFileUnload = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    setFormState({
      ...formState,
      proofOfInsuranceFile: {},
      proofOfInsuranceUrl: ''
    });
  };

  const onFormW9FileLoad = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    setErrors({ ...errorState, formW9File: '' });
    if (e.target.files[0]) {
      const selectedFile = e.target.files[0];
      const fileType = e.target.files[0].type;

      if (fileType === Constants.FILE_TYPE_PDF || fileType === Constants.FILE_TYPE_DOC ||
        fileType === Constants.FILE_TYPE_DOCX) {
        updateFormState('formW9File', selectedFile);
      } else {
        setErrors({ ...errorState, formW9File: 'File type not permitted. Please provide a (*.pdf), (*.doc), or (*.docx) file.' });
      }
    }
  };

  const onFormW9FileUnload = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    setFormState({
      ...formState,
      formW9File: {},
      formW9Url: ''
    });
  };

  useEffect(() => {
    if (isDirty !== true) {
      setIsDirty(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDirty]);

  useEffect(() => {
    if (state.trySubmit === true && ltsLogoState.isObjLoading === false &&
      ltsCurriculumFileState.isObjLoaded === true && ltsCurriculumFileState.objData.uploadUrl &&
      ltsProofOfInsuranceState.isObjLoaded === true && ltsProofOfInsuranceState.objData.uploadUrl &&
      ltsFormW9State.isObjLoaded === true && ltsFormW9State.objData.uploadUrl) {
      onSubmitFormCallback({
        logoUrl: ltsLogoState.objData.uploadUrl || null,
        curriculumUrl: ltsCurriculumFileState.objData.uploadUrl || null,
        proofOfInsuranceUrl: ltsProofOfInsuranceState.objData.uploadUrl || null,
        formW9Url: ltsFormW9State.objData.uploadUrl || null
      });
      setState({ ...state, trySubmit: false });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state, ltsLogoState, ltsCurriculumFileState, ltsProofOfInsuranceState, ltsFormW9State])

  function localSubmitFormCallback(formState) {

    if (formState.logoFile?.size) {
      const postLTSLogoPromise = postLTSLogo(formState.logoFile);

      if (postLTSLogoPromise !== null) {
        postLTSLogoPromise.then(() => {
        }).catch(() => {
          //TODO state error
        });
      }
    }
    if (formState.curriculumFile?.size) {
      const postLTSCurriculumPromise = postLTSCurriculum(formState.curriculumFile);

      if (postLTSCurriculumPromise !== null) {
        postLTSCurriculumPromise.then(() => {
        }).catch(() => {
          //TODO state error
        });
      }
    }
    if (formState.proofOfInsuranceFile?.size) {
      const postLTSProofOfInsurancePromise = postLTSProofOfInsurance(formState.proofOfInsuranceFile);

      if (postLTSProofOfInsurancePromise !== null) {
        postLTSProofOfInsurancePromise.then(() => {
        }).catch(() => {
          //TODO state error
        });
      }
    }
    if (formState.formW9File?.size) {
      const postLTSFormW9romise = postLTSFormW9(formState.formW9File);

      if (postLTSFormW9romise !== null) {
        postLTSFormW9romise.then(() => {
        }).catch(() => {
          //TODO state error
        });
      }
    }

    if (!formState.curriculumFile?.size || !formState.proofOfInsuranceFile?.size || !formState.formW9File?.size) {
      setErrors({
        ...errorState,
        curriculumFile: formState.curriculumFile?.size ? '' : 'A file upload is required',
        proofOfInsuranceFile: formState.proofOfInsuranceFile?.size ? '' : 'A file upload is required',
        formW9File: formState.formW9File?.size ? '' : 'A file upload is required'
      });
    }
    else {
      setState({ ...state, trySubmit: true });
    }
  }

  return {
    isSaving: ltsLogoState.isObjLoading || ltsCurriculumFileState.isObjLoading ||
      ltsProofOfInsuranceState.isObjLoading || ltsFormW9State.isObjLoading,
    formState,
    errorState,
    handleSubmit,
    onLogoFileLoad,
    onLogoFileUnload,
    onCurriculumFileLoad,
    onCurriculumFileUnload,
    onProofOfInsuranceFileLoad,
    onProofOfInsuranceFileUnload,
    onFormW9FileLoad,
    onFormW9FileUnload
  };
};

export default useRegistrationSubmitForm;