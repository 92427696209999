import Constants from '../../../common/utils/Constants';
import { ClubHttpHelper, NO_DATA_MESSAGE } from '../../../common/utils/HttpHelper';

export const INITIAL_STATE = {
  isLoading: false,
  isLoaded: false,
  data: [],
  dataAsOptionsWithNames: [],
  message: ''
};

const getPrizes = (state, setState) => {
  if (state.isLoading !== true && state.isLoaded !== true && !state.message) {
    const newState = {
      ...state,
      isLoading: true,
      isLoaded: false,
      message: 'Loading...'
    };
    setState(newState);

    const url = `/SatPrize`;
    ClubHttpHelper(url, 'GET')
      .then((data) => {
        if (!data) {
          throw new Error(NO_DATA_MESSAGE);
        } else {
          data.unshift({ satPrizeId: Constants.DEFAULT_ID, typeName: '--' });
          setState({
            ...newState,
            isLoading: false,
            isLoaded: true,
            data: data,
            dataAsOptionsWithNames: data.map((p) => { return { id: p.satPrizeId, name: p.description } }),
            message: '',
          });
        }
      })
      .catch((e) => {
        const message = e && e.message ? e.message : NO_DATA_MESSAGE;
        setState({
          ...newState,
          isLoading: false,
          isLoaded: false,
          message: message
        });
      });
  }
};

const PrizeData = { INITIAL_STATE, getPrizes };

export default PrizeData;