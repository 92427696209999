import React, { Fragment } from 'react';

import useSearchFacility from './UseSearchFacility';
import SearchFacilityGrid from './SearchFacilityGrid';

import Dropdown from '../../dropdowns/Dropdown';
import Headings from '../../headings/Headings';
import Input from '../../inputs/Input';
import SecondaryButton from '../../buttons/SecondaryButton';
import PrimaryButton from '../../buttons/PrimaryButton';
import ActionIntraPageButton from '../../buttons/ActionIntraPageButton';
import StatesCombobox from '../../comboboxes/statesCombobox/StatesCombobox';
import CountryCombobox from '../../comboboxes/countryCombobox/CountryCombobox';
import PopUpModal from '../../dialogs/PopUpModal';

import global from '../../GlobalStyle.module.css';
import Constants from '../../../utils/Constants';

const Form = ({ handleSubmit, formState, errorState, onFormValueChanged, onValueTextPairChanged, clearForm, sortByOptions,
  onAdd, onCancelClicked }) => (
  <form onSubmit={handleSubmit} noValidate>
    <div className="row">
      <div className="col-xs-12 col-sm-6 col-md-4">
        <Input
          label="Facility Name"
          name="facilityName"
          value={formState.facilityName}
          error={errorState.facilityName}
          message={errorState.facilityName}
          onChange={(value) => { onFormValueChanged('facilityName', value) }} />
      </div>
      <div className="col-xs-12 col-sm-6 col-md-4">
        <Input
          label="City"
          name="city"
          value={formState.city}
          error={errorState.city}
          message={errorState.city}
          onChange={(value) => { onFormValueChanged('city', value) }} />
      </div>
      <div className="col-xs-12 col-sm-6 col-md-4">
        <StatesCombobox
          label="State"
          name="stateCode"
          valueToMatch={formState.stateName}
          error={errorState.stateCode}
          message={errorState.stateCode}
          isUSA={true}
          onChange={(newValue, newValueLabel, e) => { onValueTextPairChanged(newValue, 'stateCode', newValueLabel, 'stateName', e); }} />
      </div>
      <div className={"col-xs-12 col-sm-6 col-md-4"}>
        <CountryCombobox
          label="Country"
          name="country"
          valueToMatch={formState.countryName}
          error={errorState.country}
          message={errorState.country}
          onChange={(newValue, newValueLabel, e) => { onValueTextPairChanged(newValue, 'country', newValueLabel, 'countryName', e); }} />
      </div>
      <div className={"col-xs-12 col-sm-6 col-md-4"}>
        <Input
          label="Zip Code"
          name="postalcode"
          value={formState.postalCode}
          error={errorState.postalCode}
          message={errorState.postalCode}
          onChange={(value) => { onFormValueChanged('postalCode', value) }} />
      </div>
      <div className="col-xs-12 col-sm-6 col-md-4">
        <Dropdown
          label="Sort Results By"
          name="sortById"
          options={sortByOptions}
          value={formState.sortById}
          error={errorState.sortById}
          message={errorState.sortById}
          showEmptySelection={false}
          isLoading={false}
          onChange={(newValue, newValueLabel, e) => {
            e && e.target && e.target.value &&
              onValueTextPairChanged(newValue, 'sortById', newValueLabel, 'sortByName');
          }} />
      </div>
    </div>
    {errorState.filter &&
      <div className="row">
        <div className="col-xs-12">
          <p className={global.ErrorMessage}>{errorState.filter ? errorState.filter : ''}</p>
        </div>
      </div>}
    <div className="row usas-extra-top-margin usas-extra-bottom-margin">
      <div className="col-xs-12 usas-extra-top-margin">
        <PrimaryButton type="submit">Search for Facility</PrimaryButton>&nbsp;
        <SecondaryButton type="button" onClick={clearForm}>Clear Form</SecondaryButton>&nbsp;
        {onAdd && <ActionIntraPageButton aria-hidden="true" type="button" onClick={onAdd}>Add Facility</ActionIntraPageButton>}
        {onCancelClicked && <SecondaryButton type="button" onClick={onCancelClicked}>Back</SecondaryButton>}
      </div>
    </div>
  </form>
)

const SearchFacility = ({ pageTitle, onEdit, onAdd, onCancelClicked, onAddANewFacility, contactLabel }) => {
  const {
    formState,
    errorState,
    facilityState,
    searchFacilityState,
    modalState,
    onOpenModal,
    onModalCanceled,
    clearForm,
    handleSubmit,
    onFormValueChanged,
    onValueTextPairChanged,
    sortByOptions,
    onFiltersToggle
  } = useSearchFacility();

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>{pageTitle}</Headings.H3>
        </div>
      </div>
      <div className={searchFacilityState.showFilters === true ? global.DisplayComponent : global.HideComponent}>
        <Form
          formState={formState}
          errorState={errorState}
          clearForm={clearForm}
          handleSubmit={handleSubmit}
          onFormValueChanged={onFormValueChanged}
          onValueTextPairChanged={onValueTextPairChanged}
          sortByOptions={sortByOptions}
          onAdd={onAdd}
          onCancelClicked={onCancelClicked}
        />
      </div>
      <div className="row usas-extra-bottom-margin">
        <div className="col-xs-12">
          <SearchFacilityGrid
            facilityState={facilityState}
            searchFacilityState={searchFacilityState}
            onEdit={onEdit}
            onFiltersToggle={onFiltersToggle}
            onCancelClicked={onCancelClicked}
            onAdd={onAdd}
          />
        </div>
      </div>
      {
        onAddANewFacility && facilityState.arrayData.length > 0 && searchFacilityState.showFilters === true ?
          <Fragment>
            <div className="row usas-extra-top-margin usas-extra-bottom-margin">
              <div className="col-xs-12 usas-extra-top-margin">
                <ActionIntraPageButton type="button" onClick={onOpenModal}>Can't Find Facility</ActionIntraPageButton>&nbsp;
              </div>
            </div>
          </Fragment> :
          <Fragment />
      }
      {
        onCancelClicked && searchFacilityState.showFilters === false ?
          <Fragment>
            {onAddANewFacility ? <div className="row usas-extra-top-margin">
              <div className="col-xs-12 usas-extra-top-margin">
                <ActionIntraPageButton type="button" onClick={onOpenModal}>Can't Find Facility</ActionIntraPageButton>
              </div>
            </div>
              : <Fragment />}
            <div className="row usas-extra-top-margin usas-extra-bottom-margin">
              <div className="col-xs-12 usas-extra-top-margin">
                <SecondaryButton type="button" onClick={() => onCancelClicked()}>Back</SecondaryButton>
              </div>
            </div>
          </Fragment>
          : <Fragment />
      }
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={facilityState.isArrayLoading || facilityState.isObjLoading} />
      {
        onAddANewFacility ?
          <PopUpModal
            widthPct={90}
            maxWidth={720}
            title={modalState.modalTitle}
            displayPopUp={facilityState.isArrayLoaded === true && modalState.displayPopUp === true}
            onModalCanceled={onModalCanceled}>
            <div className="row">
              <div className="col-xs-12">
                <p>{modalState.noResults === true ? 'There were no results for the below filters. Do you want to add a new facility?' : ` Do you want to add a new facility because you were unable to find your facility using the below filters?`}</p>
                {formState.facilityName
                  ? <p className={global.Bold}>Facility Name: {formState.facilityName}</p>
                  : <Fragment />}
                {formState.city
                  ? <p className={global.Bold}>City: {formState.city}</p>
                  : <Fragment />}
                {formState.stateCode
                  ? <p className={global.Bold}>State: {formState.stateName}</p>
                  : <Fragment />}
                {formState.postalCode
                  ? <p className={global.Bold}>Zip Code: {formState.postalCode}</p>
                  : <Fragment />}
              </div>
            </div>
            <div className="row usas-extra-top-margin">
              <div className="col-xs-12 ">
                <PrimaryButton type="button" onClick={onAddANewFacility}>Yes, Add Facility</PrimaryButton>&nbsp;
                <SecondaryButton type="button" onClick={onModalCanceled}>Cancel</SecondaryButton>
              </div>
            </div>
          </PopUpModal>
          : <Fragment />}
    </Fragment>
  );
};

export default SearchFacility;