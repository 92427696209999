import React, { Fragment } from 'react'

import FacilityDupCheckGrid from './FacilityDupCheckGrid';
import FacilityConfirmationGrid from './FacilityConfirmationGrid';

import PrimaryButton from '../../../../buttons/PrimaryButton';
import SecondaryButton from '../../../../buttons/SecondaryButton';

const FacilityDupCheckContent = ({ formState, dupsState, onAddDupFacilityClicked, onCancelDupFacilityClicked }) => (
  <Fragment>
    <div className="row">
      <div className="col-xs-12">
        <p>At least one facility with the same Address, City, State and Zip Code already exists in the database.</p>
      </div>
    </div>
    <div className="row usas-extra-top-margin usas-extra-bottom-margin">
      <div className="col-xs-12">
        <FacilityDupCheckGrid state={dupsState} />
      </div>
    </div>
    <div className="row usas-extra-top-margin usas-extra-bottom-margin">
      <div className="col-xs-12">
        <FacilityConfirmationGrid state={formState} />
      </div>
    </div>
    <div className="row usas-extra-top-margin">
      <div className="col-xs-12 usas-extra-top-margin">
        <PrimaryButton type="button" onClick={onAddDupFacilityClicked}>Yes, Add Facility</PrimaryButton>&nbsp;
        <SecondaryButton type="button" onClick={onCancelDupFacilityClicked}>Cancel</SecondaryButton>
      </div>
    </div>
  </Fragment>
);

export default FacilityDupCheckContent;