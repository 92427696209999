import { useEffect } from 'react';

import usePreviousSeasonOfferingData from '../../../state/previousSeasonOffering/UsePreviousSeasonOfferingData';

const usePreviousSeasonOfferingDropdown = (isAthleteRole) => {
  const { previousSeasonOfferingState, getPreviousSeasonOfferings } = usePreviousSeasonOfferingData();

  useEffect(() => {
    if (previousSeasonOfferingState.isArrayLoading !== true && (previousSeasonOfferingState.isArrayLoaded !== true || previousSeasonOfferingState.isAthleteRole !== isAthleteRole)) {
      getPreviousSeasonOfferings(isAthleteRole);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [previousSeasonOfferingState, isAthleteRole]);

  return {
    previousSeasonOfferingState
  };
};

export default usePreviousSeasonOfferingDropdown;