import React, { Fragment } from 'react';

import ClubStaffRequirementsLargeGrid from './ClubStaffRequirementsLargeGrid';
import ClubStaffRequirementsSmallGrid from './ClubStaffRequirementsSmallGrid';

import useExpandedRow from '../../../../common/components/grids/UseExpandedRow';

const ClubStaffRequirementsGrid = ({ state }) => {
  const { expandedId, onClick } = useExpandedRow();

  return (
    <Fragment>
      <ClubStaffRequirementsLargeGrid state={state} expandedId={expandedId} onClick={onClick} />
      <ClubStaffRequirementsSmallGrid state={state} expandedId={expandedId} onClick={onClick} />
    </Fragment>
  );
};

export default ClubStaffRequirementsGrid;