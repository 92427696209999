import { useEffect, useState } from 'react';
import { useNavigate } from '../../../../common/wrappers/ReactRouterDom';

import ZoneReportingNavLinks from '../ZoneReportingNavLinks';

import useSelectOrgUnitData from '../../../../common/state/selectOrgUnit/UseSelectOrgUnitData';
import useZoneReportingData from '../../../state/zoneReporting/UseZoneReportingData';

const INITIAL_VIEW_STATE = {
  reportParameters: { ZoneId: '' },
  routeName: ''
};

const useZoneReportingReports = () => {
  const navigate = useNavigate();
  const { selectOrgUnitState } = useSelectOrgUnitData();
  const { getDecryptedOrgUnitId, zoneReportingState } = useZoneReportingData();
  const [viewState, setViewState] = useState({ ...INITIAL_VIEW_STATE });

  const onBackToSelectionClicked = () => {
    navigate(ZoneReportingNavLinks.ZONE_REPORTING_SELECTION);
  };

  useEffect(() => {
    if (selectOrgUnitState.orgUnitId) {
      const getDecryptedOrgUnitIdPromise = getDecryptedOrgUnitId(selectOrgUnitState.orgUnitId);

      if (getDecryptedOrgUnitIdPromise ?? false) {
        getDecryptedOrgUnitIdPromise.then((newState) => {
          setViewState({
            ...viewState,
            reportParameters: { ZoneId: newState.objData.orgUnitNumber },
            orgUnitId: selectOrgUnitState.orgUnitId,
            orgUnitName: selectOrgUnitState.orgUnitName,
            routeName: 'ZONE_REPORTING_REPORTS'
          });
        }).catch(() => {

        })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectOrgUnitState.orgUnitId]);

  return {
    ...viewState,
    selectOrgUnitState,
    zoneReportingState,
    onBackToSelectionClicked
  };
};

export default useZoneReportingReports;