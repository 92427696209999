import { useGetDashboardModel } from "@sisense/sdk-ui";

const UseClubRosterDashboard = (dashboardOid) => {
  const { dashboard, isLoading, isError } = useGetDashboardModel({ dashboardOid, includeWidgets: true });

  return {
    dashboard, isError, isLoading
  };
};

export default UseClubRosterDashboard;