import { Fragment } from "react";

import LeftArrowIcon from '../../../../../common/components/icons/LeftArrowIcon';

import global from '../../../../../common/components/GlobalStyle.module.css';

const ClubRecognitionCategoryMeasureHeader = ({ programCategory, categoryMeasure, onBackClicked }) => (
  <Fragment>
    <div className="row">
      <div className="col-xs-12">
        <hr className={[global.ThinBar, 'usas-bar-gray'].join(' ')} />
      </div>
    </div>
    <div className="row">
      <div className="col-xs-12">
        <button className={global.BackToSearchButton} type="button" onClick={() => onBackClicked(false)}>
          <LeftArrowIcon /> Back to {programCategory.categoryName}
        </button>
      </div>
    </div>
    <div className="row">
      <div className={["col-xs-12", global.HeaderText].join(' ')}>
        <p><b>{categoryMeasure.measureName} Point Range:</b> {categoryMeasure.minValue}
          {categoryMeasure.minValue !== categoryMeasure.maxValue ? ' - ' + categoryMeasure.maxValue : ''}</p>
        <p><b>{categoryMeasure.measureName} Current Points:</b> {categoryMeasure.score >= 0 && categoryMeasure.score !== null ? categoryMeasure.score : '0'}</p>
        <p><b>{categoryMeasure.measureName} Status:</b> {categoryMeasure.status || ''}</p>
      </div>
    </div>
    <div className="row">
      <div className="col-xs-12">
        <hr className={[global.ThinBar, 'usas-bar-gray'].join(' ')} />
      </div>
    </div>
  </Fragment>
);

export default ClubRecognitionCategoryMeasureHeader;