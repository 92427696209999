import React, { Fragment } from 'react';

import Input from '../../../../common/components/inputs/Input';
//import DatePicker from '../../../../common/components/datepickers/DatePicker';
import YesNoSwitch from '../../../../common/components/yesNoSwitch/YesNoSwitch';
import ReadOnly from '../../../../common/components/readOnly/ReadOnly';

import global from '../../../../common/components/GlobalStyle.module.css';

// const ClbmS101Write = ({ formState, errorState, onFormValueChanged }) => (
//   <Fragment>
//     <div className="row">
//       <div className="col-xs-12">
//         <b>CLBMS 101</b>
//       </div>
//     </div>
//     <div className="row">
//       <div className="col-xs-12">
//         <YesNoSwitch
//           label="By selecting yes and signing below, I formally acknowledge that this club head coach 
//         has taken their CLMBS 101 course and has completed all modules and requirements."
//           checked={formState.clbmS101}
//           error={errorState.clbmS101}
//           message={errorState.clbmS101}
//           onChange={(value) => { onFormValueChanged('clbmS101', value) }} />
//       </div>
//     </div>
//     <div className="row">
//       <div className="col-xs-12 col-sm-6 col-md-4">
//         <Input
//           label="First and Last Name*"
//           name="clbmS101signature"
//           value={formState.clbmS101signature}
//           error={errorState.clbmS101signature}
//           message={errorState.clbmS101signature}
//           onChange={(value) => {
//             onFormValueChanged('clbmS101signature', value);
//             onFormValueChanged('clbmS101signatureDate', formState.today)
//           }} />
//       </div>
//       <div className="col-xs-12 col-sm-3">
//         <ReadOnly
//           label="Signature Date"
//           name="clbmS101signatureDate"
//           value={formState.clbmS101signatureDate} />
//       </div>
//       <div className="col-xs-12 col-sm-5">
//         <DatePicker
//           label="Completion Date*"
//           name="clbmS101CompletionDate"
//           value={formState.clbmS101CompletionDate}
//           error={errorState.clbmS101CompletionDate}
//           message={errorState.clbmS101CompletionDate}
//           onChange={(value) => { onFormValueChanged('clbmS101CompletionDate', value); }}
//           countOfYears={10}
//           startYearOffset={-10} />
//       </div>
//     </div>
//   </Fragment>
// )

// const ClbmS101Read = ({ formState }) => (
//   <Fragment>
//     <div className="row">
//       <div className="col-xs-12">
//         <b>CLBMS 101</b>
//       </div>
//     </div>
//     <div className="row">
//       <div className="col-xs-12">
//         <YesNoSwitch
//           label="By selecting yes and signing below, I formally acknowledge that this club head coach 
//           has taken their CLMBS 101 course and has completed all modules and requirements."
//           checked={formState.clbmS101}
//           disabled={true} />
//       </div>
//     </div>
//     <div className="row">
//       <div className="col-xs-12 col-sm-6 col-md-4">
//         <ReadOnly
//           label="First and Last Name"
//           name="clbmS101signature"
//           value={formState.clbmS101signature} />
//       </div>
//       <div className="col-xs-12 col-sm-6 col-md-4">
//         <ReadOnly
//           label="Signature Date"
//           name="clbmS101signatureDate"
//           value={formState.clbmS101signatureDate} />
//       </div>
//       <div className="col-xs-12 col-sm-6 col-md-4">
//         <ReadOnly
//           label="Completion Date"
//           name="clbmS101CompletionDate"
//           value={formState.clbmS101CompletionDate} />
//       </div>
//     </div>
//   </Fragment>
// )

// const ClbmS201Write = ({ formState, errorState, onFormValueChanged }) => (
//   <Fragment>
//     <div className="row usas-extra-top-margin">
//       <div className="col-xs-12">
//         <b>CLBMS 201</b>
//       </div>
//     </div>
//     <div className="row">
//       <div className="col-xs-12">
//         <YesNoSwitch
//           label="By selecting yes and signing below, I formally acknowledge that this club head coach 
//     has taken their CLMBS 201 course and has completed all modules and requirements."
//           checked={formState.clbmS201}
//           error={errorState.clbmS201}
//           message={errorState.clbmS201}
//           onChange={(value) => { onFormValueChanged('clbmS201', value) }} />
//       </div>
//     </div>
//     <div className="row">
//       <div className="col-xs-12 col-sm-6 col-md-4">
//         <Input
//           label="First and Last Name*"
//           name="clbmS201signature"
//           value={formState.clbmS201signature}
//           error={errorState.clbmS201signature}
//           message={errorState.clbmS201signature}
//           onChange={(value) => {
//             onFormValueChanged('clbmS201signature', value);
//             onFormValueChanged('clbmS201signatureDate', formState.today)
//           }} />
//       </div>
//       <div className="col-xs-12 col-sm-3">
//         <ReadOnly
//           label="Signature Date"
//           name="clbmS201signatureDate"
//           value={formState.clbmS201signatureDate} />
//       </div>
//       <div className="col-xs-12 col-sm-5">
//         <DatePicker
//           label="Completion Date*"
//           name="clbmS201CompletionDate"
//           value={formState.clbmS201CompletionDate}
//           error={errorState.clbmS201CompletionDate}
//           message={errorState.clbmS201CompletionDate}
//           onChange={(value) => { onFormValueChanged('clbmS201CompletionDate', value); }}
//           countOfYears={10}
//           startYearOffset={-10} />
//       </div>
//     </div>
//   </Fragment>
// )

// const ClbmS201Read = ({ formState }) => (
//   <Fragment>
//     <div className="row usas-extra-top-margin">
//       <div className="col-xs-12">
//         <b>CLBMS 201</b>
//       </div>
//     </div>
//     <div className="row">
//       <div className="col-xs-12">
//         <YesNoSwitch
//           label="By selecting yes and signing below, I formally acknowledge that this club head coach 
//         has taken their CLMBS 201 course and has completed all modules and requirements."
//           checked={formState.clbmS201}
//           disabled={true} />
//       </div>
//     </div>
//     <div className="row">
//       <div className="col-xs-12 col-sm-6 col-md-4">
//         <ReadOnly
//           label="First and Last Name"
//           name="clbmS201signature"
//           value={formState.clbmS201signature} />
//       </div>
//       <div className="col-xs-12 col-sm-6 col-md-4">
//         <ReadOnly
//           label="Signature Date"
//           name="clbmS201signatureDate"
//           value={formState.clbmS201signatureDate} />
//       </div>
//       <div className="col-xs-12 col-sm-6 col-md-4">
//         <ReadOnly
//           label="Completion Date"
//           name="clbmS201CompletionDate"
//           value={formState.clbmS201CompletionDate} />
//       </div>
//     </div>
//   </Fragment>
// )

const ClubRegistrationScreeningPoliciesWrite = ({ clubRegistrationState, handleSubmit, formState, errorState, onFormValueChanged }) => (
  <Fragment>
    <form onSubmit={handleSubmit} noValidate>
      <div className="row usas-extra-top-margin">
        <div className="col-xs-12">
          <b>Pre-Employment Screening</b>
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12">
          <YesNoSwitch
            label="By selecting yes and signing below, I formally acknowledge that this club 
              is conducting pre-employment screening on all new employees who are required to be 
              members of USA Swimming as required in the USA Swimming Rules &amp; Regulations, Article 502.6.8"
            name="preEmploymentScreeningRequired"
            checked={formState.preEmploymentScreeningRequired}
            error={errorState.preEmploymentScreeningRequired}
            message={errorState.preEmploymentScreeningRequired}
            onChange={(value) => { onFormValueChanged('preEmploymentScreeningRequired', value) }} />
        </div>
      </div>
      {/* <div className="row">
        <div className="col-xs-12 col-sm-6 col-md-4">
          <Input
            label="First and Last Name*"
            name="preEmploymentScreeningRequiredSignature"
            value={formState.preEmploymentScreeningRequiredSignature}
            error={errorState.preEmploymentScreeningRequiredSignature}
            message={errorState.preEmploymentScreeningRequiredSignature}
            onChange={(value) => {
              onFormValueChanged('preEmploymentScreeningRequiredSignature', value);
              onFormValueChanged('preEmploymentScreeningRequiredSignatureDate', formState.today);
            }} />
        </div>
        <div className="col-xs-12 col-sm-6 col-md-4">
          <ReadOnly
            label="Signature Date"
            name="preEmploymentScreeningRequiredSignatureDate"
            value={formState.preEmploymentScreeningRequiredSignatureDate} />
        </div>
      </div> */}
      <div className="row usas-extra-top-margin">
        <div className="col-xs-12">
          <b>Racing Start Certification</b>
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12">
          <YesNoSwitch
            label="By selecting yes and signing below, I formally acknowledge that this club complies 
              with all Racing Start Certification requirements as stated in the USA Swimming Rules &amp; 
              Regulations, Article 103.2.2 and maintains records for its athlete members."
            checked={formState.racingStartCertified}
            error={errorState.racingStartCertified}
            message={errorState.racingStartCertified}
            onChange={(value) => { onFormValueChanged('racingStartCertified', value) }} />
        </div>
      </div>
      {/* <div className="row">
        <div className="col-xs-12 col-sm-6 col-md-4">
          <Input
            label="First and Last Name*"
            name="racingStartCertifiedSignature"
            value={formState.racingStartCertifiedSignature}
            error={errorState.racingStartCertifiedSignature}
            message={errorState.racingStartCertifiedSignature}
            onChange={(value) => {
              onFormValueChanged('racingStartCertifiedSignature', value);
              onFormValueChanged('racingStartCertifiedSignatureDate', formState.today);
            }}
          />
        </div>
        <div className="col-xs-12 col-sm-6 col-md-4">
          <ReadOnly
            label="Signature Date"
            name="racingStartCertifiedSignatureDate"
            value={formState.racingStartCertifiedSignatureDate} />
        </div>
      </div> */}
      <div className="row usas-extra-top-margin">
        <div className="col-xs-12">
          <b>State Concussion Laws</b>
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12">
          <YesNoSwitch
            label="By selecting yes and signing below, I formally acknowledge that this club is following 
              the state concussion laws regarding training coaches and providing educational information to 
              athletes, parents, and guardians as required."
            checked={formState.stateConcussionsLaws}
            error={errorState.stateConcussionsLaws}
            message={errorState.stateConcussionsLaws}
            onChange={(value) => { onFormValueChanged('stateConcussionsLaws', value) }} />
        </div>
      </div>
      {/* <div className="row">
        <div className="col-xs-12 col-sm-6 col-md-4">
          <Input
            label="First and Last Name*"
            name="stateConcussionsLawsSignature"
            value={formState.stateConcussionsLawsSignature}
            error={errorState.stateConcussionsLawsSignature}
            message={errorState.stateConcussionsLawsSignature}
            onChange={(value) => {
              onFormValueChanged('stateConcussionsLawsSignature', value);
              onFormValueChanged('stateConcussionsLawsSignatureDate', formState.today)
            }} />
        </div>
        <div className="col-xs-12 col-sm-6 col-md-4">
          <ReadOnly
            label="Signature Date"
            name="stateConcussionsLawsSignatureDate"
            value={formState.stateConcussionsLawsSignatureDate} />
        </div>
      </div> */}
      <div className="row usas-extra-top-margin">
        <div className="col-xs-12">
          <b>Minor Athlete Abuse Prevention Policy</b>
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12">
          <YesNoSwitch
            label="By selecting yes and signing below, I formally acknowledge that this club 
              has implemented USA Swimming Minor Athlete Abuse Prevention Policy, and require all 
              athletes, parents, coaches, and other non-athlete members of the club to review and 
              agree to the Policy on an annual basis with such written agreement to be retained by the club."
            checked={formState.minorAthleteAbusePreventionPolicy}
            error={errorState.minorAthleteAbusePreventionPolicy}
            message={errorState.minorAthleteAbusePreventionPolicy}
            onChange={(value) => { onFormValueChanged('minorAthleteAbusePreventionPolicy', value) }} />
        </div>
      </div>
      <div className="row usas-extra-top-margin">
        <div className="col-xs-12">
          <b>Screening & Policies Signature</b>
        </div>
      </div>
      <div className="row usas-extra-bottom-margin">
        <div className="col-xs-12 col-sm-6 col-md-4">
          <Input
            label="Acknowledgements Signature*"
            name="acknowledgementsSignature"
            value={formState.acknowledgementsSignature}
            error={errorState.acknowledgementsSignature}
            message={errorState.acknowledgementsSignature}
            onChange={(value) => {
              onFormValueChanged('acknowledgementsSignature', value);
              onFormValueChanged('acknowledgementsSignatureDate', formState.today);
            }}
          />
        </div>
        <div className="col-xs-12 col-sm-6 col-md-4">
          <ReadOnly
            label="Signature Date"
            name="acknowledgementsSignatureDate"
            value={formState.acknowledgementsSignatureDate} />
        </div>
      </div>
      {/* {clubRegistrationState.objData?.clubRegistrations?.length < 2 ?
        <ClbmS101Write
          formState={formState}
          errorState={errorState}
          onFormValueChanged={onFormValueChanged}
        />
        : <Fragment />} */}
      {/* {clubRegistrationState.objData?.clubRegistrations?.length === 2 ?
        <Fragment>
          <ClbmS101Read
            formState={formState}
          />
          <ClbmS201Write
            formState={formState}
            errorState={errorState}
            onFormValueChanged={onFormValueChanged}
          />
        </Fragment>
        : <Fragment />} */}
      {/* {clubRegistrationState.objData?.clubRegistrations?.length > 2 ?
        <Fragment>
          <ClbmS101Read
            formState={formState}
          />
          <ClbmS201Read
            formState={formState}
          />
        </Fragment>
        : <Fragment />} */}
    </form>
    {errorState.general &&
      <div className="row">
        <div className="col-xs-12">
          <p className={global.ErrorMessage}>{errorState.general ? errorState.general : ''}</p>
        </div>
      </div>}
  </Fragment>
);


export default ClubRegistrationScreeningPoliciesWrite;