import React, { Fragment } from "react";

import useClubMeetUpload from "./UseClubMeetUpload";

import Headings from "../../../../../common/components/headings/Headings";
import MeetResultsUpload from "../../../../../common/components/fileUpload/meetResultsUpload/MeetResultsUpload";
import SecondaryButton from "../../../../../common/components/buttons/SecondaryButton";

import global from '../../../../../common/components/GlobalStyle.module.css';

const ClubMeetUpload = () => {
  const {
    meetId,
    meetHeaderState,
    onBackClicked,
    meetStartDate,
    meetEndDate
  } = useClubMeetUpload();

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>Upload Meet Results</Headings.H3>
        </div>
      </div>
      <div className={["row", global.HeaderText].join(' ')}>
        <div className="col-xs-12">
          <p><b>Meet Name:</b> {meetHeaderState.meetName}</p>
          <p><b>Meet Host:</b> {meetHeaderState.meetHost}</p>
          <p><b>Meet Dates:</b> {meetHeaderState.meetDates}</p>
        </div>
      </div>
      {
        (meetId > 0) ?
        <MeetResultsUpload meetId={meetId} meetStartDate={meetStartDate} meetEndDate={meetEndDate} /> : <Fragment />
      }
      <div className="row usas-extra-top-margin usas-extra-bottom-margin">
        <div className="col-xs-12">
          <SecondaryButton type="button" onClick={onBackClicked}>Back</SecondaryButton>
        </div>
      </div>
    </Fragment>
  );
};

export default ClubMeetUpload;