/* eslint-disable react/no-unescaped-entities */
import { Fragment } from 'react';

import useGenerateRegistrationLink from './UseClubGenerateRegistrationLink';

import LargeClubGenerateRegistrationLinkGrid from './LargeClubGenerateRegistrationLinkGrid';
import SmallClubGenerateRegistrationLinkGrid from './SmallClubGenerateRegistrationLinkGrid';

import Headings from '../../../../common/components/headings/Headings';
import PopUpModal from '../../../../common/components/dialogs/PopUpModal';
import SecondaryButton from '../../../../common/components/buttons/SecondaryButton';
import PrimaryButton from '../../../../common/components/buttons/PrimaryButton';
import Input from '../../../../common/components/inputs/Input';

import Constants from '../../../../common/utils/Constants';
// import { formatDate } from '../../../../common/utils/DateFunctions';

const ClubGenerateRegistrationLink = () => {
  const {
    clubRegistrationLinkState,
    modalState,
    requiredPhraseState,
    copyToClipboardState,
    onModalCanceled,
    onOpenNewLinkModalClicked,
    onGenerateNewLink,
    onClickCopyToClipboard,
    onCopyToClipboardModalCanceled,
    onPhraseUpdated,
    REQUIRED_PHRASE
  } = useGenerateRegistrationLink();

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>Club Registration Link</Headings.H3>
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12 usas-extra-top-margin usas-extra-bottom-margin">
          <LargeClubGenerateRegistrationLinkGrid
            state={clubRegistrationLinkState}
            onOpenNewLinkModalClicked={onOpenNewLinkModalClicked}
            onClickCopyToClipboard={onClickCopyToClipboard} />
          <SmallClubGenerateRegistrationLinkGrid
            state={clubRegistrationLinkState}
            onOpenNewLinkModalClicked={onOpenNewLinkModalClicked}
            onClickCopyToClipboard={onClickCopyToClipboard} />
        </div>
      </div>
      <PopUpModal
        widthPct={50}
        maxWidth={720}
        title="Generate New Registration Link"
        onModalCanceled={onModalCanceled}
        displayPopUp={modalState.displayPopUp}>
        <div className="row">
          <div className="col-xs-12">
            <p className={'col-xs-12 text-center'}>You are about to generate a new {modalState.membershipGroup} link</p>
            <p className={'col-xs-12 text-center'}><b>Membership Group Name:</b> {modalState.routeCodeName} </p>
            {/* <p className={'col-xs-12 text-center'}><b>Expiration Date:</b> {formatDate(modalState.expirationDate)}</p>
            <p className={'col-xs-12 text-center'}><b>Effective Date:</b> {formatDate(modalState.effectiveDate)}</p> */}
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12">
            <p className={'col-xs-12 text-center'}>Type '{REQUIRED_PHRASE}' in the box below to delete your existing OMR Link and generate a new one.</p>
            <p className={'col-xs-12 text-center'}><b>WARNING THIS WILL INVALIDATE ALL EXISTING OMR LINKS</b></p>
          </div>
          <div className='hidden-xs col-sm-3 col-md-4' />
          <div className="col-xs-12 col-sm-6 col-md-4 usas-extra-bottom-margin">
            <Input
              label={`Type '${REQUIRED_PHRASE}'`}
              name="phraseValue"
              value={requiredPhraseState.phraseValue}
              onChange={(value) => { onPhraseUpdated(value); }} />
          </div>
          <div className='hidden-xs col-sm-3 col-md-4' />
        </div>
        <div className="row">
          <div className="col-xs-12 usas-extra-top-margin text-center">
            <SecondaryButton type="button" onClick={onModalCanceled}>Cancel</SecondaryButton>&nbsp;
            <PrimaryButton type="button" isDisabled={requiredPhraseState.isMatch !== true} onClick={(e) => onGenerateNewLink(e, modalState.routeCodeId)}>Yes, New Link</PrimaryButton>
          </div>
        </div>
      </PopUpModal>
      <PopUpModal
        widthPct={75}
        maxWidth={450}
        title={copyToClipboardState.modalTitle}
        onCopyToClipboardModalCanceled={onCopyToClipboardModalCanceled}
        displayPopUp={copyToClipboardState.displayPopUp}>
        <div className="row">
          <div className="col-xs-12">
            <p className={'col-xs-12 text-center'}>You have copied your Club's OMR registration link to the clipboard.</p>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12 usas-extra-top-margin text-center">
            <SecondaryButton type="button" onClick={onCopyToClipboardModalCanceled}>Ok</SecondaryButton>&nbsp;
          </div>
        </div>
      </PopUpModal>
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={clubRegistrationLinkState.isArrayLoading} />
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.SAVING_MSG}
        displayPopUp={clubRegistrationLinkState.isSaving} />
    </Fragment>
  );
};

export default ClubGenerateRegistrationLink;