import React, { Fragment } from 'react';

import HideIcon from '../../../../common/components/icons/HideIcon';
import ShowIcon from '../../../../common/components/icons/ShowIcon';
import EditIcon from '../../../../common/components/icons/EditIcon';
import DeleteIcon from '../../../../common/components/icons/DeleteIcon';

import global from '../../../../common/components/GlobalStyle.module.css';

const DetailTableRow = ({ personId, memberId, role, onDelete, registrationRoles }) => (
  <tr>
    <td>{role.orgRoleName}</td>
    <td>
      {!registrationRoles.find(x => x === role.orgRoleName) &&
        <button className={global.IconButtonMargin} type="button" onClick={(e) =>
          onDelete(e, personId, memberId, role.orgRoleId, role.personOrgRoleDurationId)}>
          <DeleteIcon />
        </button>
      }
    </td>
  </tr>
);

const DetailTable = ({ staff, onDelete, registrationRoles }) => (
  <table className={global.DetailTable}>
    <thead>
      <tr>
        <th>Staff Role</th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      {staff && staff.personOrgRole && staff.personOrgRole.map((role, i) =>
        <DetailTableRow personId={staff.personId} memberId={staff.memberId} role={role} onDelete={onDelete} key={i} registrationRoles={registrationRoles} />)}
    </tbody>
  </table>
);

const TableRow = ({ staff, onEdit, onDelete, expandedId, onClick, registrationRoles }) => {
  return (
    <Fragment>
      <tr>
        <td>{staff.firstName} {staff.lastName}</td>
        <td>{staff.memberId}</td>
        <td>{staff.isInGoodStanding === true ? "Yes" : "No"}</td>
        <td>
          <button className={global.IconButtonMargin} type="button" onClick={(e) => onEdit(e, staff.personId)}>
            <EditIcon />
          </button>
          <button
            className={global.IconButtonMargin}
            type="button"
            onClick={(e) => onClick(e, staff.personId)}>
            {expandedId === staff.personId ? <HideIcon /> : <ShowIcon />}
          </button>
        </td>
      </tr>
      {Array.isArray(staff.personOrgRole) && staff.personOrgRole.length > 0 && staff.personId === expandedId &&
        <tr className={global.Expanded}>
          <td colSpan="4">
            <DetailTable staff={staff} onDelete={onDelete} registrationRoles={registrationRoles} />
          </td>
        </tr>}
    </Fragment>
  );
};

const ClubRegistrationStaffLargeGridWrite = ({ state, onEdit, onDelete, expandedId, onClick, registrationRoles }) => (
  <table className={[global.UsasTable, 'visible-md visible-lg'].join(' ')}>
    <thead>
      <tr>
        <th>Member Name</th>
        <th>Member Id</th>
        <th>Is In Good Standing?</th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      {state.isArrayLoaded !== true
        ? (
          <tr>
            {state.isArrayLoading === true ?
              <td colSpan="4">Loading...</td>
              : <td colSpan="4">No Staff</td>
            }
          </tr>
        ) : Array.isArray(state.arrayData) && state.arrayData.length > 0
          ?
          state.arrayData.map((staff, i) =>
            <TableRow staff={staff} key={i} onEdit={onEdit} onDelete={onDelete} expandedId={expandedId} onClick={onClick} registrationRoles={registrationRoles} />)
          : (
            <tr>
              <td colSpan="4">No Staff</td>
            </tr>
          )
      }
    </tbody>
  </table>
);

export default ClubRegistrationStaffLargeGridWrite;