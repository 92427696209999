import { isValidEIN, isValidITIN } from "../../../../../common/utils/validation";

export const localValidate = (formState) => {
  let errors = {};

  if (formState.entityName.trim() === '') {
    errors.entityName = 'Business Legal Name is required';
  }

  if (formState.tradeName.trim() === '') {
    errors.tradeName = 'Trade Name/DBA is required';
  }

  if (formState.taxId.trim() === '') {
    errors.taxId = 'Tax Id is required';
  } else if (!isValidITIN(formState.taxId.trim()) && !isValidEIN(formState.taxId.trim())) {
    errors.taxId = 'Must be valid Tax Id format XXX-XX-XXXX or XX-XXXXXXX';
  }

  return errors;
};

const RegistrationEntryFormValidation = async (formState) => {
  let errors = localValidate(formState);

  return errors ? errors : {};
};

export default RegistrationEntryFormValidation;