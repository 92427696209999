import { Fragment } from "react";

import useClubBlockPartyMeets from "./UseClubBlockPartyMeets";
import ClubBlockPartyMeetsGrid from "./ClubBlockPartyMeetsGrid";

import Headings from "../../../../common/components/headings/Headings";
import ReportPeriodDropdown from "../../../../common/components/dropdowns/reportPeriodDropdown/ReportPeriodDropdown";
import ActionIntraPageButton from "../../../../common/components/buttons/ActionIntraPageButton";
import PopUpModal from "../../../../common/components/dialogs/PopUpModal";
import Constants from "../../../../common/utils/Constants";
import ViewReport from "../../../../common/components/embeddedReports/ViewReport";

const ClubBlockPartyMeets = () => {
  const {
    clubBlockPartyMeetsState,
    gridState,
    formState,
    errorState,
    viewState,
    setViewState,
    organizationId,
    onDelete,
    onEdit,
    onUploadClicked,
    onReportPeriodChanged,
    onAddBlockPartyMeetClicked,
    onMeetReconClicked,
    onViewReport,
    onViewMeetAnnouncementReport,
    onGoToCart,
    INITIAL_VIEW_STATE
  } = useClubBlockPartyMeets();

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>Block Party Meets</Headings.H3>
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12 col-sm-6 col-md-8 usas-extra-top-margin">
          <ActionIntraPageButton onClick={onAddBlockPartyMeetClicked}>Add Block Party Meet</ActionIntraPageButton>
        </div>
        <div className="col-xs-12 col-sm-6 col-md-4">
          <ReportPeriodDropdown
            label="Report Period"
            name="reportPeriodId"
            value={formState.reportPeriodId}
            error={errorState.reportPeriodId}
            message={errorState.reportPeriodId}
            onChange={(value, valueLabel) => onReportPeriodChanged(value, valueLabel)}
            organizationId={organizationId} />
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12 usas-extra-top-margin usas-extra-bottom-margin">
          <ClubBlockPartyMeetsGrid
            data={gridState}
            isLoading={clubBlockPartyMeetsState.isArrayLoading}
            onUploadClicked={onUploadClicked}
            onDelete={onDelete}
            onEdit={onEdit} 
            onMeetReconClicked={onMeetReconClicked}
            onViewReport={onViewReport}
            onViewMeetAnnouncementReport={onViewMeetAnnouncementReport}
            onGoToCart={onGoToCart}
            />
        </div>
      </div>
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.SAVING_MSG}
        displayPopUp={clubBlockPartyMeetsState.isSaving} />
      {viewState.showReport === true &&
        <div className="row">
          <div className="col-xs-12 usas-extra-top-margin">
            <ViewReport
              reportInfoId={viewState.reportInfoId}
              reportParameters={viewState.reportParameters}
              onBackToGrid={() => setViewState({ INITIAL_VIEW_STATE })} />
          </div>
        </div>
      }
    </Fragment>
  );
};

export default ClubBlockPartyMeets;