import React, { Fragment } from 'react';

import HideIcon from '../../../../common/components/icons/HideIcon';
import ShowIcon from '../../../../common/components/icons/ShowIcon';
import useExpandedRow from '../../../../common/components/grids/UseExpandedRow';
import SGColumnHeader from '../../../../common/components/grids/SGColumnHeader';

import { formatPhoneNumber } from '../../../../common/utils/validation';
import useSortableGrid from '../../../../common/utils/UseSortableGrid';
import SGConstants from '../../../../common/utils/SGConstants';

import global from '../../../../common/components/GlobalStyle.module.css';

const DetailTableRow = ({ role }) => (
  <tr>
    <td>{role.orgRoleName}</td>
  </tr>
);

const DetailTable = ({ staff }) => (
  <table className={global.DetailTable}>
    <thead>
      <tr>
        <th>Role</th>
      </tr>
    </thead>
    <tbody>
      {staff && staff.personOrgRole && staff.personOrgRole.map((role, i) =>
        <DetailTableRow role={role} key={i} />)}
    </tbody>
  </table>
);

const TableRow = ({ staff, expandedId, onClick }) => {
  return (
    <Fragment>
      <tr>
        <td>
          {staff.preferredName || staff.firstName} {staff.lastName}
        </td>
        <td>
          {formatPhoneNumber(staff.phoneNumber)}
        </td>
        <td>
          {staff.email}
        </td>
        <td>
          {staff.isInGoodStanding === true ? 'Yes' : 'No'}
        </td>
        <td>
          <button
            className={global.IconButton}
            type="button"
            onClick={(e) => onClick(e, staff.personId)}>
            {expandedId === staff.personId ? <HideIcon /> : <ShowIcon />}
          </button>
        </td>
      </tr>
      {staff && staff.personOrgRole && staff.personOrgRole.length > 0 && expandedId === staff.personId &&
        <tr className={global.Expanded}>
          <td colSpan="6">
            <DetailTable staff={staff} />
          </td>
        </tr>}
    </Fragment>
  );
};

const LargeGrid = ({ state, expandedId, onClick }) => {
  const {
    sortableGridState,
    onColumnClicked
  } = useSortableGrid(state.arrayData);
  return (
    <table className={[global.UsasTable, 'visible-md visible-lg'].join(' ')}>
      <thead>
        <tr>
          <SGColumnHeader sortableGridState={sortableGridState} columnLabel={'Club Staff Name'} columnField={'lastName'}
            sortType={SGConstants.SORT_TYPE_ALPHABETICAL} onColumnClicked={onColumnClicked} />
          <SGColumnHeader sortableGridState={sortableGridState} columnLabel={'Phone Number'} columnField={'phoneNumber'}
            sortType={SGConstants.SORT_TYPE_NUMERICAL} onColumnClicked={onColumnClicked} />
          <SGColumnHeader sortableGridState={sortableGridState} columnLabel={'Email'} columnField={'email'}
            sortType={SGConstants.SORT_TYPE_ALPHABETICAL} onColumnClicked={onColumnClicked} />
          <SGColumnHeader sortableGridState={sortableGridState} columnLabel={'Is in good standing?'} columnField={'isInGoodStanding'}
            sortType={SGConstants.SORT_TYPE_NUMERICAL} onColumnClicked={onColumnClicked} />
          <th></th>
        </tr>
      </thead>
      <tbody>
        {state.isArrayLoaded !== true
          ? (
            <tr>
              {state.isArrayLoading === true
                ? <td colSpan="5">Loading...</td>
                : <td colSpan="5">No Staff</td>
              }
            </tr>)
          : Array.isArray(sortableGridState.sortedGridData) && sortableGridState.sortedGridData.length > 0
            ? sortableGridState.sortedGridData.map((staff, i) => <TableRow key={i} staff={staff} expandedId={expandedId} onClick={onClick} />)
            : (
              <tr>
                <td colSpan="5">No Staff</td>
              </tr>
            )}
      </tbody>
    </table>
  );
};

const GridRowDetailTableRow = ({ role }) => (
  <div className={global.SmallTableRow}>
    <div className={global.SmallTableDetailRowHead}><span className='hidden-xs'>{role.orgRoleName}</span>&nbsp;</div>
    <div className={global.SmallTableRowInfo}>
      <div className='row'>
        <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3 visible-xs'].join(' ')}>Role</div>
        <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3 visible-xs'].join(' ')}>{role.orgRoleName}</div>
      </div>
    </div>
  </div>
);

const GridRowDetailTable = ({ staff }) => (
  <div className={global.SmallTableDetailTable}>
    {staff && Array.isArray(staff.personOrgRole) && staff.personOrgRole.map((role, i) =>
      <GridRowDetailTableRow key={i} role={role} />)}
  </div>
);

const GridRow = ({ staff, expandedId, onClick }) => {

  return (
    <Fragment>
      <div className={global.SmallTableRow}>
        <div className={global.SmallTableRowHead}>
          <span className='hidden-xs'>{staff.preferredName || staff.firstName} {staff.lastName}</span>&nbsp;
          <button className={global.IconButton}
            type="button"
            onClick={(e) => onClick(e, staff.personId)}>
            {expandedId === staff.personId ? <HideIcon /> : <ShowIcon />}
          </button>
        </div>
        <div className={global.SmallTableRowInfo}>
          <div className='row'>
            <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3 visible-xs'].join(' ')}>Club Staff Name</div>
            <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3 visible-xs'].join(' ')}>{staff.preferredName || staff.firstName} {staff.lastName}</div>
            <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3'].join(' ')}>Phone Number</div>
            <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3'].join(' ')}>{formatPhoneNumber(staff.phoneNumber) || <span>&nbsp;</span>}</div>
            <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3'].join(' ')}>Email</div>
            <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3'].join(' ')}>{staff.email || <span>&nbsp;</span>}</div>
            <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3'].join(' ')}>Is In Good Standing?</div>
            <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3'].join(' ')}>{staff.isInGoodStanding === true ? 'Yes' : 'No'}</div>
          </div>
        </div>
      </div>
      {staff && Array.isArray(staff.personOrgRole) && staff.personOrgRole.length > 0 && staff.personId === expandedId &&
        <GridRowDetailTable staff={staff} />
      }
    </Fragment>
  );
};

const SmallGrid = ({ state, expandedId, onClick }) => (
  <div className={[global.SmallTable, 'hidden-md hidden-lg'].join(' ')}>
    {state.isArrayLoaded !== true
      ? (
        <div className={global.SmallTableRow}>
          <div className={global.SmallTableRowHead}>
            &nbsp;
          </div>
          <div className={global.SmallTableRowInfo}>
            {state.isArrayLoading === true ?
              <div className={global.SmallTableRowLabels}>Loading...</div>
              : <div className={global.SmallTableRowLabels}>No Staff</div>
            }
          </div>
        </div>
      ) : Array.isArray(state.arrayData) && state.arrayData.length > 0
        ? state.arrayData.map((staff, i) => <GridRow key={i} staff={staff} expandedId={expandedId} onClick={onClick} />) : (
          <div className={global.SmallTableRow}>
            <div className={global.SmallTableRowHead}>
              &nbsp;
            </div>
            <div className={global.SmallTableRowInfo}>
              <div className={global.SmallTableRowLabels}>No Staff</div>
            </div>
          </div>
        )
    }
  </div>
);

const ClubStaffReadGrid = ({ state }) => {
  const { expandedId, onClick } = useExpandedRow();
  return (
    <Fragment>
      <LargeGrid state={state} expandedId={expandedId} onClick={onClick} />
      <SmallGrid state={state} expandedId={expandedId} onClick={onClick} />
    </Fragment>
  );
}

export default ClubStaffReadGrid;