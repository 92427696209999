import React, { Fragment } from 'react';

import useLearnToSwimFacilitiesSearch from './UseLearnToSwimFacilitiesSearch';

import SearchFacility from '../../../../common/components/searches/searchFacility/SearchFacility';
import PopUpModal from '../../../../common/components/dialogs/PopUpModal';
import SecondaryButton from '../../../../common/components/buttons/SecondaryButton';

import Constants from '../../../../common/utils/Constants';

const LearnToSwimFacilitiesSearch = () => {
  const {
    isLoading,
    modalState,
    onLinkFacilityClicked,
    onCancelClicked,
    onModalCancelClicked,
    onAddANewFacilityClicked
  } = useLearnToSwimFacilitiesSearch();

  return (
    <Fragment>
      <SearchFacility
        pageTitle={"Facility Search"}
        onEdit={onLinkFacilityClicked}
        onCancelClicked={onCancelClicked}
        onAddANewFacility={onAddANewFacilityClicked}
        contactLabel={'Learn To Swim'} />
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={isLoading} />
      <PopUpModal
        widthPct={90}
        maxWidth={720}
        title="Duplicate Facility Error"
        displayPopUp={modalState.displayModal}
        onModalCanceled={onModalCancelClicked}>
        <div className="row">
          <div className="col-xs-12">
            <p>{modalState.facilityName} is already linked to this location</p>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12 usas-extra-top-margin">
            <SecondaryButton type="button" onClick={onModalCancelClicked}>Cancel</SecondaryButton>
          </div>
        </div>
      </PopUpModal>
    </Fragment>
  );
};

export default LearnToSwimFacilitiesSearch;