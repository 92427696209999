import { PreCompHttpHelper, NO_DATA_MESSAGE } from '../../utils/HttpHelper';

const GetPreCompOrganizationByOrgUnitIdData = (orgUnitId, state, setState) => {
  if (state.isObjLoading !== true && !state.message) {
    const newState = {
      ...state,
      isObjLoading: true,
      isObjLoaded: false,
      message: 'Loading...'
    };
    setState(newState);

    const orgUnitIdForUrl = orgUnitId ? encodeURIComponent(orgUnitId) : 'NaN';
    const url = `/PreCompetitiveOrganization/orgUnit/${orgUnitIdForUrl}`;
    PreCompHttpHelper(url, 'GET')
      .then((objData) => {
        if (!objData) {
          throw new Error(NO_DATA_MESSAGE);
        } else {
          setState({
            ...newState,
            isObjLoading: false,
            isObjLoaded: true,
            objData,
            message: ''
          });
        }
      })
      .catch((e) => {
        const message = e && e.message ? e.message : NO_DATA_MESSAGE;
        setState({
          ...newState,
          isObjLoading: false,
          isObjLoaded: false,
          message: message
        });
      });
  }
};

export default GetPreCompOrganizationByOrgUnitIdData;