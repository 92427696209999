import { isValidDate, isValidBirthDate } from '../../../../../../common/utils/validation';
import Constants from '../../../../../../common/utils/Constants';

const MeetSanctionInfoFormValidation = async (formState) => {
  let errors = {};

  const startDate = new Date(formState.startDate);
  const endDate = new Date(formState.endDate);

  //Meet Name
  if (formState.meetName.trim() === '') {
    errors.meetName = 'Meet Name is required';
  } else if ((formState.autogeneratedMeetYear + ' ' + formState.autogeneratedMeetOrg + ' ' + formState.meetName.trim()).toString().length > 200) {
    errors.meetName = 'Meet Name cannot exceed 200 characters';
  }

  //Meet Classification
  if (formState.meetClassification.length === 0) {
    errors.meetClassification = 'Meet Classification is required';
  }

  //Meet Course
  if (formState.meetCourse.length === 0) {
    errors.meetCourse = "Must pick at least one Course";
  }

  //Start Date
  if (formState.startDate === Constants.BLANK_DATE_STRING) {
    errors.startDate = 'Start Date is required';
  } else if (!isValidDate(formState.startDate)) {
    errors.startDate = 'Start Date must be a valid date';
  } else if (isValidBirthDate(formState.startDate)) {
    errors.startDate = 'Start Date cannot be in the past';
  } else if (endDate < startDate && formState.startDate !== formState.endDate) {
    errors.startDate = 'Start Date must be before the End Date';
  }

  //End Date
  if (formState.endDate === Constants.BLANK_DATE_STRING) {
    errors.endDate = 'End Date is required';
  } else if (!isValidDate(formState.endDate)) {
    errors.endDate = 'End Date must be a valid date';
  } else if (isValidBirthDate(formState.endDate)) {
    errors.endDate = 'End Date cannot be in the past';
  } else if (endDate < startDate && formState.startDate !== formState.endDate) {
    errors.endDate = 'End Date must be after the Start Date';
  }

  //Meet Software
  if (formState.meetSoftwareId <= 0) {
    errors.meetSoftwareId = 'Meet Management Software is required';
  }

  //Facility
  if (formState.facilityId <= 0) {
    errors.facilityId = 'Facility is required';
  }

  return errors;
};

export default MeetSanctionInfoFormValidation;