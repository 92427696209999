import { useEffect } from 'react';
import { useNavigate } from '../../../../common/wrappers/ReactRouterDom';

import NavLinks from '../NavLinks';

import useSecurityData from '../../../../common/state/security/UseSecurityData';

const usePreCompRegistrationWelcome = () => {
  const navigate = useNavigate();
  const { securityState, login } = useSecurityData();
  const isLoggedIn = securityState?.userInfo?.isLoggedIn === true;

  const onLoginClicked = (e) => {
    e?.preventDefault();

    login();
  };

  useEffect(() => {
    if (isLoggedIn === true) {
      navigate(NavLinks.PRE_COMP_REGISTRATION_ENTRY);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [securityState]);

  return {
    isLoggedIn,
    onLoginClicked
  };
};

export default usePreCompRegistrationWelcome;