import { useState } from 'react';
import RequiredRolesData from './RequiredRolesData';

const useRequiredRolesData = () => {
  const [requiredRolesState, setRequiredRolesState] = useState(RequiredRolesData.INITIAL_STATE);

  const getRequiredRolesByOrgLevelTypeId = (orgLevelTypeId) => {
    RequiredRolesData.getRequiredRolesByOrgLevelTypeId(orgLevelTypeId, requiredRolesState, setRequiredRolesState);
  };

  return {
    requiredRolesState,
    setRequiredRolesState,
    getRequiredRolesByOrgLevelTypeId
  };
};

export default useRequiredRolesData;