import { useContext } from 'react';

import { UsasClubFacilityLinksStateContext } from './UsasClubFacillityLinksContextProvider';
import UsasClubFacilityLinksData from './UsasClubFacilityLinksData';

const useUsasClubFacilityLinksData = () => {
  const [usasClubFacilityLinksState, setUsasClubFacilityLinksState] = useContext(UsasClubFacilityLinksStateContext);

  const getUsasClubFacilityLinks = (usasClubId) => UsasClubFacilityLinksData.getUsasClubFacilityLinksData(usasClubId, usasClubFacilityLinksState, setUsasClubFacilityLinksState);
  const postUsasClubFacilityLinks = (usasClubId, usasClubObj) => UsasClubFacilityLinksData.postUsasClubFacilityLinksData(usasClubId, usasClubObj, usasClubFacilityLinksState, setUsasClubFacilityLinksState);
  const putUsasClubFacilityLinks = (usasClubId, orgUnitFacilityId, usasClubObj) => UsasClubFacilityLinksData.putUsasClubFacilityLinksData(usasClubId, orgUnitFacilityId, usasClubObj, usasClubFacilityLinksState, setUsasClubFacilityLinksState);
  const deleteUsasClubFacilityLinks = (usasClubId, orgUnitFacilityId) => UsasClubFacilityLinksData.deleteUsasClubFacilityLinksData(usasClubId, orgUnitFacilityId, usasClubFacilityLinksState, setUsasClubFacilityLinksState);

  const clearUsasClubFacilityLinksObjData = () => {
    setUsasClubFacilityLinksState({
      ...usasClubFacilityLinksState,
      isObjLoading: false,
      isObjLoaded: false,
      isSaving: false,
      isSaved: false,
      objData: {},
      message: ''
    });
  };

  const clearUsasClubFacilityLinksArrayData = () => {
    setUsasClubFacilityLinksState({
      ...usasClubFacilityLinksState,
      isArrayLoading: false,
      isArrayLoaded: false,
      isSaving: false,
      isSaved: false,
      arrayData: [],
      message: ''
    });
  };

  const confirmUsasClubFacilityLinksSave = () => {
    setUsasClubFacilityLinksState({
      ...usasClubFacilityLinksState,
      isSaved: false
    });
  };

  return {
    usasClubFacilityLinksState,
    setUsasClubFacilityLinksState,
    clearUsasClubFacilityLinksObjData,
    clearUsasClubFacilityLinksArrayData,
    confirmUsasClubFacilityLinksSave,
    getUsasClubFacilityLinks,
    postUsasClubFacilityLinks,
    putUsasClubFacilityLinks,
    deleteUsasClubFacilityLinks
  };
};

export default useUsasClubFacilityLinksData;