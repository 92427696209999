import { useContext } from 'react';

import { BPMeetClassificationStateContext } from './BPMeetClassificationContextProvider';

import BPMeetClassificationData from './BPMeetClassificationData';

const useBPMeetClassificationData = () => {
  const [bpMeetClassificationState, setBpMeetClassificationState] = useContext(BPMeetClassificationStateContext);

  const getBPMeetClassificationsByOrgUnitId = (orgUnitId, filterId, filterMode) => {
    BPMeetClassificationData.getBPMeetClassificationsByOrgUnitId(orgUnitId, filterId, filterMode, bpMeetClassificationState, setBpMeetClassificationState);
  };

  return {
    bpMeetClassificationState,
    setBpMeetClassificationState,
    getBPMeetClassificationsByOrgUnitId
  };
};

export default useBPMeetClassificationData;