const KpiSubmittedValueFormValidation = async (formState) => {
  let errors = {};

  if (formState.submittedValue.trim() === '') {
    errors.submittedValue = 'This field is required';
  } else if (formState.submittedValue.trim().length > 8000) {
    errors.submittedValue = 'Cannot exceed 8000 characters';
  }

  return errors ? errors : {};
};

export default KpiSubmittedValueFormValidation;