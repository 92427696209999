import { useState, useContext } from 'react';

import LearnToSwimRegistrationData from './LearnToSwimRegistrationData';

import { LearnToSwimRegistrationStateContext } from './LearnToSwimRegistrationContextProvider';

import { PROVIDER_OBJ } from '../../views/learnToSwimRegistration/utils/LearnToSwimRegistrationConstants';

import { BASIC_INITIAL_STATE } from '../../../common/utils/HttpHelper';

const INITIAL_LTS_PROVIDER_DUP_CHECK_STATE = {
  ...BASIC_INITIAL_STATE
};

const useLearnToSwimRegistrationData = () => {
  const [ltsProviderRegistrationState, setLTSProviderRegistrationState] = useContext(LearnToSwimRegistrationStateContext);
  const [ltsProviderDupCheckState, setLTSProviderDupCheckState] = useState(INITIAL_LTS_PROVIDER_DUP_CHECK_STATE);

  const postLTSProviderRegistration = (providerObj) => {
    return LearnToSwimRegistrationData.postLTSProviderRegistrationData(providerObj, ltsProviderRegistrationState, setLTSProviderRegistrationState);
  };

  const getLTSProviderDupCheck = (taxId) => {
    return LearnToSwimRegistrationData.getLTSProviderDupCheckData(taxId, ltsProviderDupCheckState, setLTSProviderDupCheckState);
  };

  const putLTSProviderRegistration = (providerObj) => {
    return LearnToSwimRegistrationData.putLTSProviderRegistrationData(providerObj, ltsProviderRegistrationState, setLTSProviderRegistrationState)
  };

  const resetLTSProviderRegistrationState = () => {
    setLTSProviderRegistrationState({
      ...BASIC_INITIAL_STATE,
      objData: { ...PROVIDER_OBJ }
    });
  };

  return {
    ltsProviderRegistrationState,
    ltsProviderDupCheckState,
    resetLTSProviderRegistrationState,
    postLTSProviderRegistration,
    getLTSProviderDupCheck,
    putLTSProviderRegistration
  };
};

export default useLearnToSwimRegistrationData;