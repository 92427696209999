import { useEffect, useState } from 'react';

import validate from './PreCompOrganizationInfoValidation';

import usePreCompAffiliationTypeData from '../../../state/preCompAffiliationType/UsePreCompAffiliationTypeData';
import usePreCompBusinessEntityTypeData from '../../../state/preCompBusinessEntityType/UsePreCompBusinessEntityTypeData';

import { createOrgObjFromOrgInfoForm } from '../utils/PreCompUtils';

import usePreCompOrganizationData from '../../../../common/state/preCompOrganization/UsePreCompOrganizationData';
import useForm from '../../../../common/utils/UseForm';
import Constants from '../../../../common/utils/Constants';
import HierarchicalDataUtils from '../../../../common/utils/HierarchicalDataUtils';

import useStatesCombobox from '../../../../common/components/comboboxes/statesCombobox/UseStatesCombobox';

const INITIAL_VALIDATE_STATE = {
  isValidating: false,
  validatedAddress: null
};

const usePreCompOrganizationInfo = () => {
  const { getStateNameById } = useStatesCombobox();
  const { preCompBusinessEntityTypeState } = usePreCompBusinessEntityTypeData();
  const { preCompAffiliationTypeState } = usePreCompAffiliationTypeData();
  const { preCompOrganizationState, putPreCompOrganization, confirmSave } = usePreCompOrganizationData();
  const [validateState, setValidateState] = useState(INITIAL_VALIDATE_STATE);
  const { formState, errorState, handleSubmit, resetForm, updateFormState, onValueTextPairChanged, setFormData
  } = useForm(getInitialFormState, submitFormCallback, validate);

  const handleSubmitProxy = async (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    setValidateState({
      isValidating: true
    });
    await handleSubmit();
    setValidateState({
      isValidating: false,
      validatedAddress: formState.validatedAddress
    });
    updateFormState('showedAddressSuggestion', true);
  };

  useEffect(() => {
    if (preCompOrganizationState.isObjLoaded === true && preCompBusinessEntityTypeState.isArrayLoaded === true
      && preCompAffiliationTypeState.isArrayLoaded === true) {

      const preCompAffiliationIds = Array.isArray(preCompOrganizationState.objData.preCompOrganizationAffiliation)
        ? preCompOrganizationState.objData.preCompOrganizationAffiliation.map(x => x.preCompetitiveAffiliationTypeId) : [];
      const affiliations = HierarchicalDataUtils.GetNameIdPairs(preCompAffiliationTypeState.options, preCompAffiliationIds);

      setFormData({
        ...formState,
        orgUnitName: preCompOrganizationState.objData.orgUnit?.orgUnitName || '',
        federalTaxId: preCompOrganizationState.objData.federalTaxId ? preCompOrganizationState.objData.federalTaxId + '' : '',
        address1: preCompOrganizationState.objData.address?.address1 || '',
        address2: preCompOrganizationState.objData.address?.address2 || '',
        tradeName: preCompOrganizationState.objData.tradeName || '',
        city: preCompOrganizationState.objData.address?.city || '',
        stateCode: preCompOrganizationState.objData.address?.stateCode || '',
        stateName: preCompOrganizationState.objData.address?.stateCode ? getStateNameById(preCompOrganizationState.objData.address.stateCode) : '',
        postalCode: preCompOrganizationState.objData.address?.postalCode || '',
        businessEntityTypeId: preCompOrganizationState.objData.businessEntityTypeId || Constants.DEFAULT_ID,
        businessEntityTypeName: preCompOrganizationState.objData.businessEntityType?.typeName || '',
        preCompOrganizationAffiliation: affiliations || [],
        website: preCompOrganizationState.objData.website || ''
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [preCompOrganizationState.isObjLoaded, preCompAffiliationTypeState.isArrayLoaded, preCompBusinessEntityTypeState.isArrayLoaded]);

  useEffect(() => {
    if (preCompOrganizationState.isSaving === false && preCompOrganizationState.isSaved === true) {
      confirmSave();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [preCompOrganizationState]);

  function submitFormCallback(formState) {
    if (preCompOrganizationState.isSaving === false) {
      putPreCompOrganization(preCompOrganizationState.objData.preCompetitiveOrganizationId,
        createOrgObjFromOrgInfoForm(JSON.parse(JSON.stringify(preCompOrganizationState.objData)), formState, true, true));
    }
  };

  function getInitialFormState() {
    return {
      orgUnitName: '',
      federalTaxId: '',
      address1: '',
      address2: '',
      tradeName: '',
      city: '',
      stateCode: '',
      stateName: '',
      postalCode: '',
      businessEntityTypeId: Constants.DEFAULT_ID,
      businessEntityTypeName: '',
      preCompOrganizationAffiliation: [],
      website: '',
      validatedAddress: null,
      showedAddressSuggestion: false
    };
  };

  return {
    isSaving: preCompOrganizationState.isSaving,
    isLoading: preCompOrganizationState.isObjLoading || preCompAffiliationTypeState.isArrayLoading || preCompBusinessEntityTypeState.isArrayLoading,
    formState,
    errorState,
    preCompOrganizationState,
    resetForm,
    onFormValueChanged: updateFormState,
    onValueTextPairChanged,
    handleSubmitProxy,
    setFormData,
    validateState
  };
};

export default usePreCompOrganizationInfo;