import { useState } from 'react';
import ClubRegistrationStatusData from './ClubRegistrationStatusData';

const useClubRegistrationStatusData = () => {
  const [clubRegistrationStatusState, setClubRegistrationStatusState] = useState(ClubRegistrationStatusData.INITIAL_STATE);

  const getClubRegistrationStatus = (clubId) => {
    ClubRegistrationStatusData.getClubRegistrationStatus(clubId, clubRegistrationStatusState, setClubRegistrationStatusState);
  };

  return {
    clubRegistrationStatusState,
    setClubRegistrationStatusState,
    getClubRegistrationStatus
  };
};

export default useClubRegistrationStatusData;