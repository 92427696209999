import { useEffect, useState } from 'react';
import { useNavigate } from '../../../../common/wrappers/ReactRouterDom';

import NavLinks from '../NavLinks';

import usePreCompLocationRegistrationNavData from '../../../state/preCompLocationRegistration/preCompLocationRegistrationLeftNavBar/UsePreCompLocationRegistrationNavData';
import usePreCompOrganizationRegistrationData from '../../../state/preCompOrganizationRegistration/UsePreCompOrganizationRegistrationData';

import PreCompRegistrationConstants from '../utils/PreCompRegistrationConstants';
import useEnvironmentVariableData from '../../../../common/state/environmentVariable/UseEnvironmentVariableData';

const REQUIRED_CONTACT_ERROR_MSG = 'Must add both a Primary and Secondary Contact';

const usePreCompRegistrationOrganizationContacts = () => {
  const navigate = useNavigate();
  const { preCompOrganizationRegistrationState } = usePreCompOrganizationRegistrationData();
  const { locationRegistrationNavState, updateCurrentNavState } = usePreCompLocationRegistrationNavData();
  const { PRIMARY_CONTACT_LABEL, SECONDARY_CONTACT_LABEL } = useEnvironmentVariableData();
  const [gridState, setGridState] = useState([]);
  const [pageErrorMessageState, setPageErrorMessageState] = useState('');

  const onContinueClicked = () => {
    if (isContactDataValid(preCompOrganizationRegistrationState.objData?.preCompetitiveOrganizationContacts || []) === true) {
      navigate(NavLinks.PRE_COMP_REGISTRATION_ORGANIZATION_INSURANCE);
    } else {
      setPageErrorMessageState(REQUIRED_CONTACT_ERROR_MSG);
    }
  };

  const onBackClicked = () => {
    if (isContactDataValid(preCompOrganizationRegistrationState.objData?.preCompetitiveOrganizationContacts || []) === true) {
      navigate(NavLinks.PRE_COMP_REGISTRATION_ORGANIZATION_INFO);
    } else {
      setPageErrorMessageState(REQUIRED_CONTACT_ERROR_MSG);
    }
  };

  const onAddStaff = () => {
    navigate(NavLinks.PRE_COMP_REGISTRATION_ORGANIZATION_CONTACT_DETAIL);
  };

  const onDeleteStaff = (orgRoleId) => {
    navigate(NavLinks.PRE_COMP_REGISTRATION_ORGANIZATION_CONTACT_DETAIL, { state: { orgRoleId } });
  };

  useEffect(() => {
    updateCurrentNavState(PreCompRegistrationConstants.ORG_CONTACT, isContactDataValid(preCompOrganizationRegistrationState.objData?.preCompetitiveOrganizationContacts || []));
    setGridState(preCompOrganizationRegistrationState.objData?.preCompetitiveOrganizationContacts || []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (preCompOrganizationRegistrationState.isObjLoaded === false) {
      navigate(NavLinks.PRE_COMP_REGISTRATION_ENTRY);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [preCompOrganizationRegistrationState.isObjLoaded]);

  function isContactDataValid(contactData) {
    let primaryFound = false;
    let secondaryFound = false;

    if (Array.isArray(contactData) && contactData.length === 2) {
      for (const contact of contactData) {
        if (contact.personOrgRole[0].orgRoleName === PRIMARY_CONTACT_LABEL) {
          primaryFound = true;
        } else if (contact.personOrgRole[0].orgRoleName === SECONDARY_CONTACT_LABEL) {
          secondaryFound = true;
        }
      }
    }

    if (primaryFound === true && secondaryFound === true) {
      return true;
    } else {
      return false;
    }
  };

  return {
    locationRegistrationNavState,
    gridState,
    pageErrorMessageState,
    onAddStaff,
    onDeleteStaff,
    onContinueClicked,
    onBackClicked
  };
};

export default usePreCompRegistrationOrganizationContacts;