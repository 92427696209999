import { useContext } from 'react';

import { ClubRegistrationNewStateContext } from './ClubRegistrationNewContextProvider';
import ClubRegistrationNewData from './ClubRegistrationNewData';

const useClubRegistrationNewData = () => {
  const [clubRegistrationState, setClubRegistrationState] = useContext(ClubRegistrationNewStateContext);

  const getUsasClub = (usasClubId) => ClubRegistrationNewData.getUsasClub(usasClubId, clubRegistrationState, setClubRegistrationState);
  const postUsasClub = (clubObj) => ClubRegistrationNewData.postUsasClub(clubObj, clubRegistrationState, setClubRegistrationState);
  const putUsasClub = (usasClubId, clubObj) => ClubRegistrationNewData.putUsasClub(usasClubId, clubObj, clubRegistrationState, setClubRegistrationState);
  const putUsasClubAndHelperObj = (usasClubId, clubObj, helperObj) => ClubRegistrationNewData.putUsasClubAndHelperObj(usasClubId, clubObj, helperObj, clubRegistrationState, setClubRegistrationState);

  const clearObjData = () => {
    setClubRegistrationState({
      ...clubRegistrationState,
      isObjLoading: false,
      isObjLoaded: false,
      isSaving: false,
      isSaved: false,
      objData: {},
      message: '',
      helperObj: {}
    });
  }

  return {
    clubRegistrationState,
    setClubRegistrationState,
    getUsasClub,
    postUsasClub,
    putUsasClub,
    clearObjData,
    putUsasClubAndHelperObj
  };
};

export default useClubRegistrationNewData;