import { useEffect, useState } from "react";

import validate from "./MeetSanctionInfoFormValidation";

import useBaseUsasClubData from "../../../../../../common/state/clubBase/UseBaseUsasClubData";
import useMeetFilesData from "../../../../../../common/state/meetFiles/UseMeetFilesData";

import Constants from "../../../../../../common/utils/Constants";
import UseForm from "../../../../../../common/utils/UseForm";

const SAVING_ERROR_MSG = 'The last action is still saving. Please try again.';
const FILE_TYPE_ERROR_MSG = 'File type not permitted. Please provide a (*.pdf), (*.doc), or (*.docx) file.';

const UseMeetSanctionInfoForm = (savedFormState, onSubmitFormCallback, state, setState) => {
  const { formState, errorState, setIsDirty, handleSubmit, updateFormState, onValueTextPairChanged, setFormState
  } = UseForm(getInitialFormState, onSubmitFormCallback, validate);
  const { baseUsasClubState } = useBaseUsasClubData();
  const [removeFileModalState, setRemoveFileModalState] = useState(getInitialRemoveFileModalState());
  const { meetFilesState, postMeetFiles, getFileNameFromSavedUploadUrl, GET_MEET_FILES_PATH } = useMeetFilesData();

  const onOpenRemoveFileModalClicked = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    setRemoveFileModalState({ ...removeFileModalState, fileName: formState.meetAnnouncementFileName, displayPopUp: true });
  }

  const onRemoveFileModalCanceled = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    setRemoveFileModalState({ ...getInitialRemoveFileModalState() });
  }

  const onUploadTemplateClicked = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    };
    if (meetFilesState.isSaving === true) {
      setState({ ...state, error: SAVING_ERROR_MSG });
    }
    else {
      setState({ ...state, error: '' });
      if (e.target.files[0]) {
        const file = e.target.files[0];
        const fileType = e.target.files[0].type;
        if (fileType === Constants.FILE_TYPE_PDF || fileType === Constants.FILE_TYPE_DOC ||
          fileType === Constants.FILE_TYPE_DOCX) {
          postMeetFiles(file);
          setState({ ...state, error: '' });
        }
        else {
          setState({ ...state, error: FILE_TYPE_ERROR_MSG });
        }
      }
    }
  }

  const onRemoveTemplateClicked = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    };
    setRemoveFileModalState({ ...getInitialRemoveFileModalState() });
    if (meetFilesState.isSaving === true) {
      setState({ ...state, error: SAVING_ERROR_MSG });
    }
    else {
      setState({ ...state, error: '' });
      setFormState({ ...formState, meetAnnouncementUrl: '', meetAnnouncementFileName: '' });
    }
  }

  useEffect(() => {
    setFormState({
      ...formState,
      ...savedFormState,
      autogeneratedMeetLsc: baseUsasClubState?.objData?.orgUnit?.parentOrgUnitCode || ''
    });
    setIsDirty(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (meetFilesState.isSaved === true && Object.keys(meetFilesState.objData).length > 0) {
      setFormState({ ...formState, meetAnnouncementUrl: meetFilesState.objData.uploadUrl, meetAnnouncementFileName: getFileNameFromSavedUploadUrl() });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [meetFilesState])

  function getInitialFormState() {
    return {
      meetName: '',
      startDate: Constants.BLANK_DATE_STRING,
      endDate: Constants.BLANK_DATE_STRING,
      meetClassification: [],
      meetCourse: [],
      meetSoftwareId: Constants.DEFAULT_ID,
      meetSoftwareName: '',
      facilityId: Constants.DEFAULT_ID,
      facilityName: '',
      autogeneratedMeetYear: '',
      autogeneratedMeetLsc: '',
      meetAnnouncementUrl: '',
      meetAnnouncementFileName: ''
    };
  };

  return {
    formState,
    errorState,
    handleSubmit,
    onFormValueChanged: updateFormState,
    onValueTextPairChanged,
    onUploadTemplateClicked,
    onOpenRemoveFileModalClicked,
    onRemoveFileModalCanceled,
    onRemoveTemplateClicked,
    GET_MEET_FILES_PATH,
    meetFilesState,
    removeFileModalState
  };
};

export default UseMeetSanctionInfoForm;

function getInitialRemoveFileModalState() {
  return {
    displayPopUp: false,
    fileName: '',
    modalTitle: 'Remove Uploaded File?'
  }
};