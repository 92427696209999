import React from 'react';

import EditIcon from '../../../../common/components/icons/EditIcon';

import global from '../../../../common/components/GlobalStyle.module.css';

const GridRow = ({ status, categoryMeasure, onEdit }) => (
  <div className={global.SmallTableRow}>
    <div className={global.SmallTableRowHead}><span className='hidden-xs'>{categoryMeasure.measureName || <span>&nbsp;</span>}</span>&nbsp;
      <div className={global.FloatRight}>
        <button
          type="button"
          className={global.IconButton}
          onClick={(e) => onEdit(e, categoryMeasure)}>
          <EditIcon />
        </button>
      </div>
    </div>
    <div className={global.SmallTableRowInfo}>
      <div className='row'>
        <div className={['col-xs-6 col-sm-3 visible-xs', global.SmallTableRowLabels].join(' ')}>Task Name</div>
        <div className={['col-xs-6 col-sm-3 visible-xs', global.SmallTableRowData].join(' ')}>{categoryMeasure.measureName || <span>&nbsp;</span>}</div>
        <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>Required?</div>
        <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>
          {categoryMeasure.isRequired === true ? 'Yes' : categoryMeasure.isRequired === false ? 'No' : <span>&nbsp;</span>}
        </div>
        <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>Point Range</div>
        <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>
          {categoryMeasure.minValue} {categoryMeasure.maxValue && categoryMeasure.minValue !== categoryMeasure.maxValue ? '- ' + categoryMeasure.maxValue : <span>&nbsp;</span>}
        </div>
        <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>Current Points</div>
        <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>{categoryMeasure.score >= 0 && categoryMeasure.score !== null
          ? categoryMeasure.score : '0'}</div>
        <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>Status</div>
        <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>{categoryMeasure.status || <span>&nbsp;</span>}</div>
      </div>
    </div>
  </div>
);

const CategoryMeasureGridSmall = ({ programLevel, programCategory, isLoading, onEdit }) => (
  <div className={[global.SmallTable, 'hidden-md hidden-lg'].join(' ')}>
    {isLoading === true
      ? (
        <div className={global.SmallTableRow}>
          <div className={global.SmallTableRowHead}>&nbsp;</div>
          <div className={global.SmallTableRowInfo}>
            <div className={global.SmallTableRowLabels}>Loading...</div>
          </div>
        </div>
      ) : Array.isArray(programCategory.categoryMeasure) && programCategory.categoryMeasure.length > 0
        ? programCategory.categoryMeasure.map((categoryMeasure, i) =>
          <GridRow key={i} status={programLevel.status} categoryMeasure={categoryMeasure} onEdit={onEdit} />
        ) : (
          <div className={global.SmallTableRow}>
            <div className={global.SmallTableRowHead}>&nbsp;</div>
            <div className={global.SmallTableRowInfo}>
              <div className={global.SmallTableRowLabels}>No Tasks</div>
            </div>
          </div>
        )
    }
  </div>
);

export default CategoryMeasureGridSmall;