import { useState } from "react";

import LearnToSwimBulkImportUploadData from "./LearnToSwimBulkImportUploadData";

const useLearnToSwimBulkImportUploadData = () => {
  const [learnToSwimBulkImportUploadState, setLearnToSwimBulkImportUploadState] = useState(LearnToSwimBulkImportUploadData.INITIAL_STATE);

  const postProcessLearnToSwimBulkImportIds = (learnToSwimArray) => LearnToSwimBulkImportUploadData.postProcessLearnToSwimBulkImportIds(learnToSwimArray, learnToSwimBulkImportUploadState, setLearnToSwimBulkImportUploadState);

  const resetLearnToSwimBulkImportUploadState = () => {
    setLearnToSwimBulkImportUploadState({ ...LearnToSwimBulkImportUploadData.INITIAL_STATE });
  };

  const confirmSave = () => {
    setLearnToSwimBulkImportUploadState({
      ...learnToSwimBulkImportUploadState,
      isSaved: false
    });
  };

  const resetState = () => {
    setLearnToSwimBulkImportUploadState(LearnToSwimBulkImportUploadData.INITIAL_STATE);
  }

  return {
    learnToSwimBulkImportUploadState,
    postProcessLearnToSwimBulkImportIds,
    resetLearnToSwimBulkImportUploadState,
    confirmSave,
    resetState
  };
};

export default useLearnToSwimBulkImportUploadData;