import React, { createContext, useState } from 'react';

import BusinessEntityData from './BusinessEntityData';

export const BusinessEntityStateContext = createContext();

const BusinessEntityContextProvider = ({ children }) => {
  const stateHook = useState(BusinessEntityData.INITIAL_STATE);

  return (
    <BusinessEntityStateContext.Provider value={stateHook}>
      {children}
    </BusinessEntityStateContext.Provider>
  );
};

export default BusinessEntityContextProvider;