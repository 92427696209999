import React from 'react';

import useGroupFacilitiesDropdown from './UseGroupFacilitiesDropdown';

import Dropdown from '../Dropdown';

import global from '../../GlobalStyle.module.css';

const GroupFacilitiesDropdown = ({ label, name, value, error, message, onChange, orgUnitId, usasClubId, alwaysGet }) => {
  const { groupFacilitiesState } = useGroupFacilitiesDropdown(orgUnitId, usasClubId, alwaysGet);

  return groupFacilitiesState.message
    ? <span className={global.LoadingMsg}>{groupFacilitiesState.message}</span>
    : (
      <Dropdown
        error={error}
        label={label}
        message={message}
        options={groupFacilitiesState.options}
        name={name}
        value={value}
        onChange={onChange}
        areValuesIntegers={true}
        isLoading={groupFacilitiesState.isArrayLoading} />
    );
};

export default GroupFacilitiesDropdown;