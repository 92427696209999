import React, { Fragment } from 'react';

import useClubRegistrationStaff from './UseClubRegistrationStaff';
import StaffGridComponentWrite from './StaffGridComponentWrite';
import RequiredRolesDisplay from './RequiredRolesDisplay';

import ClubRegistrationConstants from '../utils/ClubRegistrationConstants';
import ValidationPopUpModal from '../components/ValidationPopUpModal';

import LeftNav from '../../../../common/components/leftNav/LeftNav';
import Headings from '../../../../common/components/headings/Headings';
import PopUpModal from '../../../../common/components/dialogs/PopUpModal';

import Constants from '../../../../common/utils/Constants';

const ClubRegistrationStaff = () => {
  const {
    clubRegistrationNavState, clubRegistrationState, usasClubStaffState, state, errorState, modalState, requiredRolesFormattedState,
    registrationRoles, onAddOptionalStaff, onEdit, onDelete, onContinueClicked, onSaveAndExitClicked, onBackClicked,
    onModalContinue, onModalCanceled, arrayChunk } = useClubRegistrationStaff();

  return (
    <LeftNav title={ClubRegistrationConstants.LEFT_NAV_TITLE} state={clubRegistrationNavState} validateBeforeRedirect={false}>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>Club Staff</Headings.H3>
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12">
          {requiredRolesFormattedState.isArrayLoaded === true ?
            <RequiredRolesDisplay rolesArray={arrayChunk(requiredRolesFormattedState.arrayData, 5)} /> : <Fragment />
          }
        </div>
      </div>
      <Fragment>
        <StaffGridComponentWrite
          onAddOptionalStaff={onAddOptionalStaff}
          usasClubStaffState={usasClubStaffState}
          onEdit={onEdit}
          onDelete={onDelete}
          errorState={errorState}
          registrationRoles={registrationRoles}
          onContinueClicked={onContinueClicked}
          onSaveAndExitClicked={onSaveAndExitClicked}
          onBackClicked={onBackClicked}
        />
      </Fragment>
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={clubRegistrationState.isObjLoading || usasClubStaffState.isArrayLoading || requiredRolesFormattedState.isArrayLoading} />
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.SAVING_MSG}
        displayPopUp={clubRegistrationState.isSaving || usasClubStaffState.isSaving} />
      <ValidationPopUpModal
        clubRegistrationState={clubRegistrationState}
        state={state}
        modalState={modalState}
        onModalContinue={onModalContinue}
        onModalCanceled={onModalCanceled}
      />
    </LeftNav>
  );
};

export default ClubRegistrationStaff;