import React, { Fragment } from 'react';

import ClubStaffCertificationLargeGridWrite from './ClubRegistrationStaffLargeGridWrite';
import ClubStaffCertificationSmallGridWrite from './ClubRegistrationStaffSmallGridWrite';

import useExpandedRow from '../../../../common/components/grids/UseExpandedRow';

const ClubRegistrationStaffGridWrite = ({ state, onEdit, onDelete, registrationRoles = [] }) => {
  const { expandedId, onClick } = useExpandedRow();

  return (
    <Fragment>
      <ClubStaffCertificationLargeGridWrite state={state} onEdit={onEdit} onDelete={onDelete}
        expandedId={expandedId} onClick={onClick} registrationRoles={registrationRoles} />
      <ClubStaffCertificationSmallGridWrite state={state} onEdit={onEdit} onDelete={onDelete}
        expandedId={expandedId} onClick={onClick} registrationRoles={registrationRoles} />
    </Fragment>
  );
};

export default ClubRegistrationStaffGridWrite;