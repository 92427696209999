import useClubStaffRoleMultiselect from './UseClubAffiliationTypeMultiselect';

import MultiSelect from '../MultiSelect';

import global from '../../GlobalStyle.module.css';

const ClubAffiliationTypeMultiselect = ({ label, name, value, error, message, onChange }) => {
  const { clubAffiliationTypeState } = useClubStaffRoleMultiselect();

  return clubAffiliationTypeState.message
    ? <span className={global.LoadingMsg}>{clubAffiliationTypeState.message}</span>
    : (
      <MultiSelect
        label={label}
        name={name}
        value={value}
        options={clubAffiliationTypeState.options}
        error={error}
        message={message}
        isLoading={clubAffiliationTypeState.isArrayLoading}
        onChange={onChange} />
    );
};

export default ClubAffiliationTypeMultiselect;