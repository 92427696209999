import { BASIC_INITIAL_STATE } from '../../../common/utils/HttpHelper';

import getClubGroupsData from './GetClubGroupsData';
import getClubGroupMembersData from './GetClubGroupMembersData';
import deleteClubGroupData from './DeleteClubGroupData';

import getClubGroupDetailsData from '../../../common/state/group/GetGroupData';
import postClubGroupData from '../../../common/state/group/PostGroupData';
import putClubGroupData from '../../../common/state/group/PutGroupData';

const INITIAL_STATE = {
  ...BASIC_INITIAL_STATE
};

const ClubGroupsData = {
  INITIAL_STATE,
  getClubGroupsData,
  getClubGroupDetailsData,
  getClubGroupMembersData,
  postClubGroupData,
  putClubGroupData,
  deleteClubGroupData
};

export default ClubGroupsData;