import React, { createContext, useState } from 'react';

import MemberAdministrationLscStaffData from './MemberAdministrationLscStaffData';

export const MemberAdministrationLscStaffStateContext = createContext();

const MemberAdministrationLscStaffContextProvider = ({ children }) => {
  const stateHook = useState(MemberAdministrationLscStaffData.INITIAL_STATE);

  return (
    <MemberAdministrationLscStaffStateContext.Provider value={stateHook}>
      {children}
    </MemberAdministrationLscStaffStateContext.Provider>
  );
};

export default MemberAdministrationLscStaffContextProvider;