
import useEnvironmentVariableData from '../../../../common/state/environmentVariable/UseEnvironmentVariableData';

const TAXONOMIES = ['ClubPerformanceDetail'];
const SCOPE = 'ClubPerformance';
const NAV_LINK = '/clubperformance/athletetimes';

const useClubPerformanceOrgUnitSelection = () => {
  const { environmentVariableState, USAS_COACH_ROLES_ROLE_GROUP_ID } = useEnvironmentVariableData();

  return {
    isLoading: environmentVariableState.isLoading,
    USAS_COACH_ROLES_ROLE_GROUP_ID,
    NAV_LINK,
    TAXONOMIES,
    SCOPE
  };
};

export default useClubPerformanceOrgUnitSelection;