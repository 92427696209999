/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Fragment } from 'react';

import ProviderStaffDupGridLarge from './ProviderStaffDupGridLarge';
import ProviderStaffDupGridSmall from './ProviderStaffDupGridSmall';

const ProviderStaffDupGrid = ({ actionLabel, persons, onSelected }) => (
  <Fragment>
    <ProviderStaffDupGridLarge actionLabel={actionLabel} persons={persons} onSelected={onSelected} />
    <ProviderStaffDupGridSmall persons={persons} onSelected={onSelected} />
  </Fragment>
);

export default ProviderStaffDupGrid;