import React, { Fragment } from 'react';

import { formatPhoneNumber } from '../../../../../common/utils/validation';
import DatePicker from '../../../../../common/components/datepickers/DatePicker';
import Input from '../../../../../common/components/inputs/Input';
import ReadOnly from '../../../../../common/components/readOnly/ReadOnly';
import Dropdown from '../../../../../common/components/dropdowns/Dropdown';
import InternationalPhoneInput from '../../../../../common/components/inputs/InternationalPhoneInput';

const PreCompOrganizationContactForm = ({ formState, errorState, roleOptions, onFormValueChanged, onValueTextPairChanged }) => (
  <Fragment>
    <div className="row">
      <div className="col-xs-12 col-sm-6 col-md-4">
        {onFormValueChanged === undefined
          ? <ReadOnly
            label="First Name"
            name="firstName"
            value={formState.firstName} />
          : (
            <Input
              label="First Name*"
              name="firstName"
              value={formState.firstName}
              error={errorState.firstName}
              message={errorState.firstName}
              onChange={(value) => { onFormValueChanged('firstName', value); }} />
          )
        }
      </div>
      <div className="col-xs-12 col-sm-6 col-md-4">
        {onFormValueChanged === undefined
          ? <ReadOnly
            label="Last Name"
            name="lastName"
            value={formState.lastName} />
          : (
            <Input
              label="Last Name*"
              name="lastName"
              value={formState.lastName}
              error={errorState.lastName}
              message={errorState.lastName}
              onChange={(value) => { onFormValueChanged('lastName', value); }} />
          )
        }
      </div>
      <div className="col-xs-12 col-sm-6 col-md-4">
        {onFormValueChanged === undefined
          ? <ReadOnly
            label="Phone Number"
            name="phoneNumber"
            value={formatPhoneNumber(formState.phoneNumber)} />
          : (
            <InternationalPhoneInput
              label="Phone Number*"
              name="phoneNumber"
              value={formState.phoneNumber}
              error={errorState.phoneNumber}
              message={errorState.phoneNumber}
              onChange={(value) => { onFormValueChanged('phoneNumber', value); }} />
          )
        }
      </div>
      <div className="col-xs-12 col-sm-6 col-md-4">
        {onFormValueChanged === undefined
          ? <ReadOnly
            label="Email"
            name="email"
            value={formState.email} />
          : (
            <Input
              label="Email*"
              name="email"
              value={formState.email}
              error={errorState.email}
              message={errorState.email}
              onChange={(value) => { onFormValueChanged('email', value); }} />
          )
        }
      </div>
      <div className="col-xs-12 col-sm-6 col-md-4">
        {onFormValueChanged === undefined
          ? <ReadOnly
            label="Birth Date"
            name="birthDate"
            value={formState.birthDate} />
          : (
            <DatePicker
              label="Birth Date*"
              name="birthDate"
              value={formState.birthDate}
              error={errorState.birthDate}
              message={errorState.birthDate}
              onChange={(value) => { onFormValueChanged('birthDate', value); }} />
          )
        }
      </div>
      <div className="col-xs-12 col-sm-6 col-md-4">
        {onValueTextPairChanged === undefined
          ? <ReadOnly
            label="Primary/Secondary"
            name="orgRoleId"
            value={formState.orgRoleName} />
          : (
            <Dropdown
              label="Primary/Secondary*"
              name="orgRoleId"
              value={formState.orgRoleId}
              error={errorState.orgRoleId}
              message={errorState.orgRoleId}
              options={roleOptions}
              areValuesIntegers={true}
              onChange={(newValue, newValueLabel, e) => {
                e && e.target && e.target.value &&
                  onValueTextPairChanged(newValue, "orgRoleId", newValueLabel, "orgRoleName");
              }} />
          )
        }
      </div>
    </div>
  </Fragment>
);

export default PreCompOrganizationContactForm;