import { useEffect, useState } from 'react';
import { useNavigate } from '../../../../common/wrappers/ReactRouterDom';

import validate from './ClubRegistrationTypeValidation';

import NavLinks from '../NavLinks';

import ClubRegistrationConstants from '../utils/ClubRegistrationConstants';

import useClubRegistrationNavData from '../../../state/clubRegistration/leftNavigation/UseClubRegistrationNavData';
import useClubRegistrationNewData from '../../../state/clubRegistrationNew/UseClubRegistrationNewData';

import useOfferingTypeData from '../../../../common/state/offeringTypes/UseOfferingTypeData';
import useEnvironmentVariableData from '../../../../common/state/environmentVariable/UseEnvironmentVariableData';

import useForm from '../../../../common/utils/UseForm';
import Constants from '../../../../common/utils/Constants';
import { formatDate } from '../../../../common/utils/DateFunctions';

const useClubRegistrationType = () => {
  const navigate = useNavigate();
  const [state, setState] = useState({ submitButton: '', tryRedirect: false });
  const [submitPrequisitesState, setSubmitPrequisitesState] = useState({ areSubmitPrerequisitesDone: false });
  const [modalState, setModalState] = useState(getInitialModalState());
  const { clubRegistrationNavState, setClubRegistrationNavState } = useClubRegistrationNavData();
  const { clubRegistrationState, putUsasClub } = useClubRegistrationNewData();
  const { offeringTypeState, getOfferingTypesForClubRegistration } = useOfferingTypeData();
  const { environmentVariableState, USAS_FEE_TYPE_ID, LSC_FEE_TYPE_ID } = useEnvironmentVariableData();
  const { handleSubmit, updateFormState, formState, errorState, setFormData, isSubmitting, isDirty
  } = useForm(getFormInitialState(), submitFormCallback, validate);

  function submitFormCallback() {
    putUsasClub(clubRegistrationState.objData.usasClubId, editClubObj());
    if (state.submitButton === ClubRegistrationConstants.EXIT) {
      setState({ ...state, tryRedirect: false });
    }
    else {
      setState({ ...state, tryRedirect: true });
    }
  };

  const editClubObj = () => {
    let clubCopy = JSON.parse(JSON.stringify(clubRegistrationState.objData));

    let selectedOfferingInstance = {};
    for (let i = 0; i < offeringTypeState.arrayData.length; i++) {
      for (let j = 0; j < offeringTypeState.arrayData[i].offerings.length; j++) {
        if (offeringTypeState.arrayData[i].offerings[j].offeringInstanceId === formState.offeringInstanceId) {
          selectedOfferingInstance = offeringTypeState.arrayData[i].offerings[j];
        }
      }
    }


    if (clubCopy.clubRegistrations.length === 1) {
      clubCopy.clubRegistrations = [{
        ...clubCopy.clubRegistrations[0],
        offeringInstanceId: formState.offeringInstanceId,
        offeringInstance: undefined,
        //effectiveDate: selectedOfferingInstance.effectiveDate,
        expirationDate: selectedOfferingInstance.expirationDate
      }]
    }

    else if (clubCopy.clubRegistrations.length === 0) {

      const currentDate = new Date();
      
      clubCopy.clubRegistrations = [{
        usasClubId: clubCopy.usasClubId,
        registrationDate: formatDate(currentDate), 
        isRenewal: false,
        expirationDate: selectedOfferingInstance.expirationDate,
        offeringInstanceId: formState.offeringInstanceId,
        offeringInstance: undefined,
        registrationSignature: null,
        registrationSignatureDate: null,
        status: ClubRegistrationConstants.IN_PROGRESS_STATUS,
        paymentProcessed: false,
        completedByPersonId: null
      }]
    }

    return clubCopy;
  }

  const onContinueClicked = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    handleSubmit();
    if (isDirty === false && formState.offeringInstanceId !== '') {
      setState({ ...state, tryRedirect: true, submitButton: ClubRegistrationConstants.CONTINUE });
    }
    else {
      setState({ ...state, submitButton: ClubRegistrationConstants.CONTINUE });
    }
  }

  const onSaveAndExitClicked = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    handleSubmit();
    setState({ ...state, submitButton: ClubRegistrationConstants.EXIT });
  }

  const onBackClicked = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    handleSubmit();
    if (isDirty === false && formState.offeringInstanceId !== '') {
      setState({ ...state, tryRedirect: true, submitButton: ClubRegistrationConstants.BACK });
    }
    else {
      setState({ ...state, submitButton: ClubRegistrationConstants.BACK });
    }
  }

  const onOpenModal = () => {
    if (Object.keys(errorState).length > 0) {
      setModalState({ ...modalState, displayPopUp: true });
    }
    else {
      setModalState({ ...modalState, modalTitle: ClubRegistrationConstants.EXIT_CONFIRMATION, displayPopUp: true });
    }
  }

  const onModalContinue = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    setState({ ...state, tryRedirect: true });
    setModalState({ ...getInitialModalState() });
  }

  const onModalCanceled = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    setModalState({ ...getInitialModalState() });
  }

  const updateNavState = () => {
    for (let i = 0; i < clubRegistrationNavState.length; i++) {
      clubRegistrationNavState[i].isCurrent = false
    }
    const navIndex = clubRegistrationNavState.findIndex(x => x.name === ClubRegistrationConstants.CLUB_TYPE)
    //New club
    if (clubRegistrationState.objData?.clubRegistrations?.length > 0) {
      if (clubRegistrationState.objData.clubRegistrations?.length === 1) {
        if (clubRegistrationState.objData.clubRegistrations[0].offeringInstanceId !== null) {
          clubRegistrationNavState[navIndex] =
          {
            ...clubRegistrationNavState[navIndex],
            isCurrent: true,
            isComplete: true,
            isVisited: true,
            arePrerequisitesDone: true
          }
        }
        else {
          clubRegistrationNavState[navIndex] =
          {
            ...clubRegistrationNavState[navIndex],
            isCurrent: true,
            isComplete: false,
            isVisited: true,
            arePrerequisitesDone: true
          }
        }
      }


    }
    else {
      clubRegistrationNavState[navIndex] =
      {
        ...clubRegistrationNavState[navIndex],
        isCurrent: true,
        isComplete: false,
        isVisited: true,
        arePrerequisitesDone: true
      }
    }

    //Submit
    let areSubmitPrerequisitesDone = true;
    let isSubmitComplete = false;
    for (let i = 0; i < clubRegistrationNavState.length; i++) {
      if (clubRegistrationNavState[i].name !== ClubRegistrationConstants.SUBMIT
        && clubRegistrationNavState[i].isComplete === false) {
        areSubmitPrerequisitesDone = false;
      }
      if (clubRegistrationNavState[i].name === ClubRegistrationConstants.SUBMIT) {
        if (clubRegistrationState.objData.status === ClubRegistrationConstants.PENDING_STATUS ||
          clubRegistrationState.objData.status === ClubRegistrationConstants.APPROVED_STATUS) {
          isSubmitComplete = true
        }
      }
    }
    const submitNavIndex = clubRegistrationNavState.findIndex(x => x.name === ClubRegistrationConstants.SUBMIT)
    clubRegistrationNavState[submitNavIndex] =
    {
      ...clubRegistrationNavState[submitNavIndex],
      isCurrent: false,
      isComplete: isSubmitComplete,
      arePrerequisitesDone: areSubmitPrerequisitesDone
    }
    setSubmitPrequisitesState({ ...state, areSubmitPrerequisitesDone: areSubmitPrerequisitesDone });
    setClubRegistrationNavState(clubRegistrationNavState);
  }

  useEffect(() => {
    if (clubRegistrationState.isObjLoaded === false) {
      navigate(NavLinks.CLUB_REGISTRATION_ENTRY_NEW);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const navIndex = clubRegistrationNavState.findIndex(x => x.name === ClubRegistrationConstants.CLUB_TYPE)
    if (clubRegistrationNavState[navIndex].isCurrent === false) {
      updateNavState(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clubRegistrationNavState])

  useEffect(() => {
    const submitNavIndex = clubRegistrationNavState.findIndex(x => x.name === ClubRegistrationConstants.SUBMIT)
    if (clubRegistrationNavState[submitNavIndex].arePrerequisitesDone === false && submitPrequisitesState.areSubmitPrerequisitesDone === true) {
      updateNavState();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clubRegistrationNavState, submitPrequisitesState.areSubmitPrerequisitesDone])

  useEffect(() => {
    if (Object.keys(clubRegistrationState.objData).length > 0 && formState.lscId === Constants.DEFAULT_ID) {
      setFormData({
        ...formState,
        lscId: clubRegistrationState.objData.orgUnit?.parentOrgUnitId
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clubRegistrationState.objData, formState.lscId])

  useEffect(() => {
    //Get offering types for read only view
    if (Object.keys(clubRegistrationState.objData).length > 0 && formState.lscId !== Constants.DEFAULT_ID
      && offeringTypeState.isArrayLoaded === false && offeringTypeState.isArrayLoading === false) {
      getOfferingTypesForClubRegistration(formState.lscId, '', 'club');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clubRegistrationState.objData, formState.lscId, offeringTypeState])

  useEffect(() => {
    if (Object.keys(clubRegistrationState.objData).length > 0 && formState.lscId.length > 0
      && environmentVariableState.isLoaded === true && offeringTypeState.isArrayLoaded === true) {
      updateNavState();

      //New club
      if (clubRegistrationState.objData.clubRegistrations?.length === 1) {
        let usasFeeAmount = '';
        let lscFeeAmount = '';
        
        let offeringName = '';
        let offeringDescription = '';
        let offeringEffectiveDate = '';
        let offeringExpirationDate = '';
        for (let i = 0; i < offeringTypeState.arrayData.length; i++) {
          for (let j = 0; j < offeringTypeState.arrayData[i].offerings.length; j++) {
            if (offeringTypeState.arrayData[i].offerings[j].offeringInstanceId === clubRegistrationState.objData.clubRegistrations[0].offeringInstanceId) {
              offeringName = offeringTypeState.arrayData[i].offerings[j].offeringName;
              offeringDescription = offeringTypeState.arrayData[i].offerings[j].offeringDescription;
              offeringEffectiveDate = offeringTypeState.arrayData[i].offerings[j].effectiveDate;
              offeringExpirationDate = offeringTypeState.arrayData[i].offerings[j].expirationDate;
              usasFeeAmount = offeringTypeState.arrayData[i].offerings[j].usasPrice;
              lscFeeAmount = offeringTypeState.arrayData[i].offerings[j].lscPrice;
            }
          }
        }

        setFormData({
          ...formState,
          offeringInstanceId: clubRegistrationState.objData.clubRegistrations[0].offeringInstanceId !== null ?
            clubRegistrationState.objData.clubRegistrations[0].offeringInstanceId : '',
          offeringEffectiveDate: offeringEffectiveDate,
          offeringExpirationDate: offeringExpirationDate,
          usasFeeAmount: usasFeeAmount,
          lscFeeAmount: lscFeeAmount,
          offeringName: offeringName,
          offeringDescription: offeringDescription
        });
      }


    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clubRegistrationState.objData, formState.lscId, environmentVariableState, offeringTypeState])

  useEffect(() => {
    //Handle validation modal and navigation
    if (state.submitButton !== '' && clubRegistrationState.isObjLoaded === true && clubRegistrationState.isSaving === false && isSubmitting === false) {
      if (state.tryRedirect === false) {
        if (Object.keys(errorState).length > 0 || state.submitButton === ClubRegistrationConstants.EXIT) {
          onOpenModal();
        }
      }
      if (state.tryRedirect === true) {
        if (Object.keys(errorState).length === 0) {
          const navIndex = clubRegistrationNavState.findIndex(x => x.name === ClubRegistrationConstants.CLUB_TYPE)
          clubRegistrationNavState[navIndex] =
          {
            ...clubRegistrationNavState[navIndex],
            isCurrent: false,
            isComplete: true,
            isVisited: true,
            arePrerequisitesDone: true
          }
          //Submit
          let areSubmitPrerequisitesDone = true;
          let isSubmitComplete = false;
          for (let i = 0; i < clubRegistrationNavState.length; i++) {
            if (clubRegistrationNavState[i].name !== ClubRegistrationConstants.SUBMIT
              && clubRegistrationNavState[i].isComplete === false) {
              areSubmitPrerequisitesDone = false;
            }
            if (clubRegistrationNavState[i].name === ClubRegistrationConstants.SUBMIT) {
              if (clubRegistrationState.objData.status === ClubRegistrationConstants.PENDING_STATUS ||
                clubRegistrationState.objData.status === ClubRegistrationConstants.APPROVED_STATUS) {
                isSubmitComplete = true
              }
            }
          }
          const submitNavIndex = clubRegistrationNavState.findIndex(x => x.name === ClubRegistrationConstants.SUBMIT)
          clubRegistrationNavState[submitNavIndex] =
          {
            ...clubRegistrationNavState[submitNavIndex],
            isCurrent: false,
            isComplete: isSubmitComplete,
            arePrerequisitesDone: areSubmitPrerequisitesDone
          }
          setClubRegistrationNavState(clubRegistrationNavState);
        }
        if (state.submitButton === ClubRegistrationConstants.CONTINUE) {
          navigate(NavLinks.CLUB_REGISTRATION_LEGAL_ENTITY);
        }
        else if (state.submitButton === ClubRegistrationConstants.EXIT) {
          navigate(NavLinks.CLUB_REGISTRATION_ENTRY_NEW);
        }
        else if (state.submitButton === ClubRegistrationConstants.BACK) {
          navigate(NavLinks.CLUB_REGISTRATION_STAFF);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state, errorState, isSubmitting, clubRegistrationState.isObjLoaded, clubRegistrationState.isSaving])

  return {
    clubRegistrationNavState,
    clubRegistrationState,
    offeringTypeState,
    state,
    modalState,
    onModalContinue,
    onModalCanceled,
    formState,
    errorState,
    onFormValueChanged: updateFormState,
    onContinueClicked,
    onSaveAndExitClicked,
    onBackClicked
  }
}

export default useClubRegistrationType;

function getFormInitialState() {
  return {
    offeringInstanceId: '',
    //offeringInstance: {},
    offeringEffectiveDate: Constants.BLANK_DATE_STRING,
    offeringExpirationDate: Constants.BLANK_DATE_STRING,
    selectedRegistration: {},
    lscId: Constants.DEFAULT_ID,
    usasFeeAmount: '',
    lscFeeAmount: '',
    offeringName: '',
    offeringDescription: ''
  }
};

function getInitialModalState() {
  return {
    displayPopUp: false,
    modalTitle: ClubRegistrationConstants.MISSING_REQUIRED_INFORMATION
  }
};