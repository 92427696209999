import React, { Fragment } from 'react';

import PreCompInstructionHoursDropdown from '../instructionHoursDropdown/PreCompInstructionHoursDropdown';
import PreCompCurriculumDropdown from '../curriculumDropdown/PreCompCurriculumDropdown';
import PreCompInstructorStudentRatioDropdown from '../instructorStudentRatioDropdown/PreCompInstructorStudentRatioDropdown';

import Input from '../../../../../common/components/inputs/Input';
import ReadOnly from '../../../../../common/components/readOnly/ReadOnly';
import CurrencyInput from '../../../../../common/components/inputs/CurrencyInput';
import YesNoSwitch from '../../../../../common/components/yesNoSwitch/YesNoSwitch';

import { CURRENCY_FORMATTER } from '../../../../../common/utils/Constants';

const PreCompLocationInfoForm = ({ formState, errorState, onFormValueChanged, onValueTextPairChanged }) => (
  <Fragment>
    <div className="row">
      <div className="col-xs-12 col-sm-6 col-md-4">
        {onFormValueChanged === undefined
          ? <ReadOnly
            label="Location Name"
            name="locationName"
            value={formState.locationName} />
          : (
            <Input
              label="Location Name*"
              name="locationName"
              value={formState.locationName}
              error={errorState.locationName}
              message={errorState.locationName}
              onChange={(value) => { onFormValueChanged("locationName", value); }} />
          )
        }
      </div>
      <div className="col-xs-12 col-sm-6 col-md-4">
        {onFormValueChanged === undefined
          ? <ReadOnly
            label="Average Cost per Single Lesson"
            name="averageLessonCost"
            value={CURRENCY_FORMATTER.format(formState.averageLessonCost)} />
          : (
            <CurrencyInput
              label="Average Cost per Single Lesson*"
              name="averageLessonCost"
              value={formState.averageLessonCost}
              error={errorState.averageLessonCost}
              message={errorState.averageLessonCost}
              onChange={(value) => { onFormValueChanged("averageLessonCost", value); }} />
          )
        }
      </div>
      <div className="col-xs-12 col-sm-6 col-md-4">
        {onFormValueChanged === undefined
          ? <ReadOnly
            label="Average Cost per Session/Month"
            name="averageSessionCost"
            value={CURRENCY_FORMATTER.format(formState.averageSessionCost)} />
          : (
            <CurrencyInput
              label="Average Cost per Session/Month*"
              name="averageSessionCost"
              value={formState.averageSessionCost}
              error={errorState.averageSessionCost}
              message={errorState.averageSessionCost}
              onChange={(value) => { onFormValueChanged("averageSessionCost", value); }} />
          )
        }
      </div>
      <div className="col-xs-12 col-sm-6 col-md-4">
        {onValueTextPairChanged === undefined
          ? <ReadOnly
            label="Total In-Water Instructional Time/Child (per Session/Month)"
            name="instructionHoursPerSessionId"
            value={formState.instructionHoursPerSessionName} />
          : (
            <PreCompInstructionHoursDropdown
              label="Total In-Water Instructional Time/Child (per Session/Month)*"
              name="instructionHoursPerSessionId"
              value={formState.instructionHoursPerSessionId}
              error={errorState.instructionHoursPerSessionId}
              message={errorState.instructionHoursPerSessionId}
              onChange={(newValue, newValueLabel, e) => {
                e && e.target && e.target.value &&
                  onValueTextPairChanged(newValue, "instructionHoursPerSessionId", newValueLabel, "instructionHoursPerSessionName");
              }} />
          )
        }
      </div>
      <div className="col-xs-12 col-sm-6 col-md-4">
        {onValueTextPairChanged === undefined
          ? <ReadOnly
            label="Instructor to Student Ratio for Youth and Beginning Swimmers"
            name="instructorStudentRatioId"
            value={formState.instructorStudentRatioName} />
          : (
            <PreCompInstructorStudentRatioDropdown
              label="Instructor to Student Ratio for Youth and Beginning Swimmers*"
              name="instructorStudentRatioId"
              value={formState.instructorStudentRatioId}
              error={errorState.instructorStudentRatioId}
              message={errorState.instructorStudentRatioId}
              onChange={(newValue, newValueLabel, e) => {
                e && e.target && e.target.value &&
                  onValueTextPairChanged(newValue, "instructorStudentRatioId", newValueLabel, "instructorStudentRatioName");
              }} />
          )
        }
      </div>
      <div className="col-xs-12 col-sm-6 col-md-4">
        {onValueTextPairChanged === undefined
          ? <ReadOnly
            label="Learn to Swim Curriculum"
            name="preCompCurriculumId"
            value={formState.preCompCurriculumName} />
          : (
            <PreCompCurriculumDropdown
              label="Learn to Swim Curriculum*"
              name="preCompCurriculumId"
              value={formState.preCompCurriculumId}
              error={errorState.preCompCurriculumId}
              message={errorState.preCompCurriculumId}
              onChange={(newValue, newValueLabel, e) => {
                e && e.target && e.target.value &&
                  onValueTextPairChanged(newValue, "preCompCurriculumId", newValueLabel, "preCompCurriculumName");
              }} />
          )
        }
      </div>
      <div className="col-xs-12 col-md-6">
        {onFormValueChanged === undefined
          ? <ReadOnly
            label="Location Website"
            name="website"
            value={formState.website} />
          : (
            <Input
              label="Location Website*"
              name="website"
              value={formState.website}
              error={errorState.website}
              message={errorState.website}
              onChange={(value) => { onFormValueChanged("website", value); }} />
          )
        }
      </div>
      <div className="col-xs-12 col-sm-6 col-md-4">
        {onFormValueChanged === undefined
          ? <ReadOnly
            label="Offer Financial-Aid Opportunities?"
            name="financialAidOffered"
            value={formState.financialAidOffered} />
          : (
            <YesNoSwitch
              label="Offer Financial-Aid Opportunities?*"
              name="financialAidOffered"
              checked={formState.financialAidOffered}
              error={errorState.financialAidOffered}
              message={errorState.financialAidOffered}
              onChange={(value) => { onFormValueChanged("financialAidOffered", value); }} />
          )
        }
      </div>
    </div>
  </Fragment>
);

export default PreCompLocationInfoForm;