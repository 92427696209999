import React, { Fragment } from 'react';

import usePreCompLocationClubsLinkSearch from './UsePreCompLocationClubsLinkSearch';

import useSearchClub from '../../../../common/components/searches/searchClub/UseSearchClub';

import SearchClub from '../../../../common/components/searches/searchClub/SearchClub';
import PopUpModal from '../../../../common/components/dialogs/PopUpModal';
import Constants from '../../../../common/utils/Constants';

const PreCompLocationClubsLinkSearch = () => {
  const {
    isSaving,
    isLoading,
    modalState,
    onLinkClub,
    onCancelClicked,
    onModalCancel
  } = usePreCompLocationClubsLinkSearch();
  const { searchClubState } = useSearchClub();

  return (
    <Fragment>
      <SearchClub
        pageTitle={"Club Search"}
        onEdit={onLinkClub}
        searchClubState={searchClubState}
        onCancelClicked={onCancelClicked} />
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.SAVING_MSG}
        displayPopUp={isSaving} />
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={isLoading} />
      <PopUpModal
        widthPct={90}
        maxWidth={720}
        title="Duplicate Club Error"
        displayPopUp={modalState.displayModal}
        onModalCanceled={onModalCancel}>
        <div className="row">
          <div className="col-xs-12">
            <p>{modalState.clubName} ({modalState.clubLocation}) is already linked to this location</p>
          </div>
        </div>
      </PopUpModal>
    </Fragment>
  );
};

export default PreCompLocationClubsLinkSearch;