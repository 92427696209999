import { ClubHttpHelper, NO_DATA_MESSAGE } from '../../utils/HttpHelper';
import Constants from '../../utils/Constants';

const getGroupFacilitiesOrgUnitIdData = (orgUnitId, state, setState) => {
  if (state.isArrayLoading !== true && !state.message) {
    let newState = {
      ...state,
      orgUnitId,
      usasClubId: Constants.DEFAULT_ID,
      isArrayLoading: true,
      isArrayLoaded: false,
      message: 'Loading...'
    };
    setState(newState);

    const orgUnitIdForUrl = orgUnitId ? encodeURIComponent(orgUnitId) : 'NaN';
    const url = `/UsasClub/orgUnit/${orgUnitIdForUrl}/facilityLinks`;
    ClubHttpHelper(url, 'GET')
      .then((arrayData) => {
        if (!arrayData) {
          throw new Error(NO_DATA_MESSAGE);
        } else {
          const options = arrayData.map((facility) => {
            return { id: facility.facilityId, name: facility.facility?.facilityName };
          });
          options.unshift({ id: Constants.DEFAULT_ID, name: '--' });

          newState = {
            ...newState,
            isArrayLoading: false,
            isArrayLoaded: true,
            arrayData,
            options,
            message: ''
          };
          setState(newState);
        }
      })
      .catch((e) => {
        const message = e && e.message ? e.message : NO_DATA_MESSAGE;
        setState({
          ...newState,
          isArrayLoading: false,
          isArrayLoaded: false,
          message: message
        });
      });
  }
};


export default getGroupFacilitiesOrgUnitIdData;