import React, { createContext, useState } from 'react';

import OrgGroupCategoriesData from './OrgGroupCategoriesData';

export const OrgGroupCategoriesStateContext = createContext();

const OrgGroupCategoriesContextProvider = ({ children }) => {
  const stateHook = useState(OrgGroupCategoriesData.INITIAL_STATE);

  return (
    <OrgGroupCategoriesStateContext.Provider value={stateHook}>
      {children}
    </OrgGroupCategoriesStateContext.Provider>
  );
};

export default OrgGroupCategoriesContextProvider;