import { useContext, useEffect } from 'react';
import Constants from '../../utils/Constants';

import { RecognitionProgramStateContext } from './RecognitionProgramContextProvider';
import { ProgramFocusStateContext } from './ProgramFocusContextProvider';

import RecognitionProgramData from './RecognitionProgramData';

const useRecognitionProgramData = () => {
  const [recognitionProgramState, setRecognitionProgramState] = useContext(RecognitionProgramStateContext);
  const [programFocusState, setProgramFocusState] = useContext(ProgramFocusStateContext);

  const getCurrentProgramByProgramType = (orgUnitId, programTypeId) => {
    RecognitionProgramData.getCurrentProgramByProgramTypeData(orgUnitId, programTypeId, recognitionProgramState, setRecognitionProgramState);
  };

  const getAllProgramsByProgramType = (orgUnitId, programTypeId) => {
    RecognitionProgramData.getAllProgramsByProgramTypeData(orgUnitId, programTypeId, recognitionProgramState, setRecognitionProgramState);
  };

  const postProgramLevelInitiation = (orgUnitId, programTypeId) => {
    RecognitionProgramData.postProgramLevelInitiationData(orgUnitId, programTypeId, recognitionProgramState, setRecognitionProgramState);
  };

  const postProgramLevelRenewal = (programLevelInstanceId) => {
    RecognitionProgramData.postProgramLevelRenewalData(programLevelInstanceId, recognitionProgramState, setRecognitionProgramState);
  };

  const putProgramLevelRevoke = (programLevelInstanceId, revokeObj) => {
    return RecognitionProgramData.putProgramLevelRevokeData(programLevelInstanceId, revokeObj, recognitionProgramState, setRecognitionProgramState);
  };

  const updateProgramFocus = (programLevelInstanceId = null, programCategoryId = null, categoryMeasureId = null) => {
    refreshProgramFocusState(programLevelInstanceId || Constants.DEFAULT_ID,
      programCategoryId || Constants.DEFAULT_ID, categoryMeasureId || Constants.DEFAULT_ID);
  };

  useEffect(() => {
    if (recognitionProgramState.isArrayLoading === false && recognitionProgramState.isArrayLoaded === true) {
      refreshProgramFocusState(programFocusState.programLevelInstanceId || Constants.DEFAULT_ID,
        programFocusState.programCategoryId || Constants.DEFAULT_ID,
        programFocusState.categoryMeasureId || Constants.DEFAULT_ID);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recognitionProgramState]);

  function refreshProgramFocusState(programLevelInstanceId, programCategoryId, categoryMeasureId) {
    let newFocusState = RecognitionProgramData.INITIAL_PROGRAM_FOCUS_STATE;

    if (Array.isArray(recognitionProgramState.arrayData) && programLevelInstanceId > 0) {
      const programLevelObj = recognitionProgramState.arrayData.find(x => x.programLevelInstanceId === programLevelInstanceId);

      if (programLevelObj) {
        newFocusState = {
          ...newFocusState,
          programLevelObj,
          programLevelInstanceId
        };

        if (Array.isArray(programLevelObj.programCategory)) {
          const levelCategoryObj = programLevelObj.programCategory.find(x => x.programCategoryId === programCategoryId);

          if (levelCategoryObj) {
            newFocusState = {
              ...newFocusState,
              levelCategoryObj,
              programCategoryId
            };

            if (Array.isArray(levelCategoryObj.categoryMeasure)) {
              const categoryMeasureObj = levelCategoryObj.categoryMeasure.find(x => x.categoryMeasureId === categoryMeasureId);

              if (categoryMeasureObj) {
                newFocusState = {
                  ...newFocusState,
                  categoryMeasureObj,
                  categoryMeasureId
                };
              }
            }
          }
        }
      }
    }

    setProgramFocusState(newFocusState);
  };

  return {
    recognitionProgramState,
    programFocusState,
    getCurrentProgramByProgramType,
    getAllProgramsByProgramType,
    postProgramLevelInitiation,
    postProgramLevelRenewal,
    putProgramLevelRevoke,
    updateProgramFocus
  };
};

export default useRecognitionProgramData;