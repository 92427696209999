import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from '../../../../wrappers/ReactRouterDom';

import validate from './FacilityPoolValidation';

import FacilityPoolsUtils from './utils/FacilityPoolsUtils';

import useForm from '../../../../utils/UseForm';
import useFacilityData from '../../../../state/facility/UseFacilityData';
import useFacilityPoolDimensionsData from '../../../../state/poolDimensions/UsePoolDimensionsData';
import usePoolConfigurationData from '../../../../state/poolConfiguration/UsePoolConfigurationData';

import ToBoolIfBool from '../../../../utils/ToBoolIfBool';
import Constants from '../../../../utils/Constants';

const useAddEditFacilityPool = (saveUrl, cancelUrl, showFacilityInfo) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { poolConfigurationState, setPoolConfigurationState, resetPoolConfigurationState, resetPoolConfigurationStateUpdateGrid } = usePoolConfigurationData();
  const { facilityState, getFacility, putFacility, clearObjData } = useFacilityData();
  const { poolDimensionsState } = useFacilityPoolDimensionsData();
  const { getFacilityCopy, createNewPoolObject, updatePoolObject } = FacilityPoolsUtils();
  const { formState, errorState, setFormState, updateFormState, handleSubmit, onValueTextPairChanged, isDirty
  } = useForm(getInitialFormState, submitFormCallback, validate);
  const [state, setState] = useState({ tryRedirect: false, initialLoadCompleted: false });

  const onSaveClicked = () => {
    //Redirect without saving if no changes are made to edit pool
    if (formState.poolId > 0 && isDirty === false) {
      navigate(saveUrl, { state: { facilityId: formState.facilityId } });
    }
    else {
      handleSubmit();
    }
  };

  const onCancelClicked = () => {
    navigate(cancelUrl, { state: { facilityId: formState.facilityId } });
  };

  const onAddPoolConfig = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    setPoolConfigurationState({
      ...poolConfigurationState,
      showPage: true,
      poolId: formState.poolId,
      poolName: formState.poolName,
      poolDimensionsId: formState.poolDimensionsId,
      poolDimensions: formState.poolDimensions,
      poolConfiguration: formState.poolConfiguration,
      poolConfigurationIndex: Constants.DEFAULT_ID,
      poolConfigurationId: Constants.DEFAULT_ID,
      course: '',
      numberOfLanes: '',
      updatePoolConfigGrid: false
    });
  };

  const onEditPoolConfig = (e, poolConfiguration, i) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    setPoolConfigurationState({
      ...poolConfigurationState,
      showPage: true,
      poolId: formState.poolId,
      poolName: formState.poolName,
      poolDimensionsId: formState.poolDimensionsId,
      poolDimensions: formState.poolDimensions,
      poolConfiguration: formState.poolConfiguration,
      poolConfigurationIndex: i,
      poolConfigurationId: poolConfiguration.poolConfigurationId > 0 ? poolConfiguration.poolConfigurationId : Constants.DEFAULT_ID,
      courseId: poolConfiguration.courseId,
      course: poolConfiguration.course?.courseCode,
      numberOfLanes: poolConfiguration.numberOfLanes,
      updatePoolConfigGrid: false
    });
  };

  const onDeletePoolConfig = (e, i) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    let poolConfigurationArray = [];
    if (formState.poolConfiguration.length > 1) {
      poolConfigurationArray = JSON.parse(JSON.stringify(formState.poolConfiguration));
      poolConfigurationArray.splice(i, 1);
      updateFormState('poolConfiguration', poolConfigurationArray);
    }
    else {
      updateFormState('poolConfiguration', poolConfigurationArray);
    }
    //If pool exists, API delete
    if (formState.poolId > 0) {
      const facilityCopy = getFacilityCopy();
      const selectedPoolIndex = facilityCopy.facilityPool
        .findIndex((pool) => pool.facilityPoolId === formState.poolId);
      facilityCopy.facilityPool[selectedPoolIndex].poolConfiguration = poolConfigurationArray;
      putFacility(facilityCopy.facilityId, facilityCopy);
      resetPoolConfigurationStateUpdateGrid();
    }
  };

  useEffect(() => {
    if (location.state && location.state.facilityId) {
      getFacility(location.state.facilityId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (facilityState.isObjLoaded === true && facilityState.isSaving === false && poolConfigurationState.updatePoolConfigGrid === true) {
      if (poolConfigurationState.poolConfiguration.length > 0) {
        setFormState({
          ...formState,
          poolConfiguration: poolConfigurationState.poolConfiguration
        });
      }
      else {
        let selectedPool = {};
        if (location.state && location.state.poolId > 0) {
          selectedPool = [...facilityState.objData.facilityPool]
            .find((pool) => pool.facilityPoolId === location.state.poolId);
        }
        setFormState({
          ...formState,
          poolConfiguration: Object.keys(selectedPool).length > 0 ? selectedPool.poolConfiguration : []
        });
      }
      resetPoolConfigurationState();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [facilityState, poolConfigurationState.updatePoolConfigGrid]);

  useEffect(() => {
    if (facilityState.isObjLoaded === true && state.initialLoadCompleted === false &&
      location.state && location.state.facilityId === facilityState.objData.facilityId) {
      if (location.state.poolId > 0) {
        //Edit
        const selectedPool = [...facilityState.objData.facilityPool]
          .find((pool) => pool.facilityPoolId === location.state.poolId);
        if (selectedPool) {
          setFormState({
            ...formState,
            facilityId: selectedPool.facilityId,
            poolId: selectedPool.facilityPoolId,
            poolName: selectedPool.facilityPoolName || '',
            poolDimensionsId: selectedPool.poolDimensionsId || Constants.DEFAULT_ID,
            poolDimensions: selectedPool.poolDimensions?.dimensionName || '',
            poolMaxDepth: selectedPool.poolMaxDepth !== null && selectedPool.poolMaxDepth >= 0 ?
              Number(selectedPool.poolMaxDepth) : '',
            averagePoolTemperature: selectedPool.averagePoolTemperature !== null && selectedPool.averagePoolTemperature >= 0 ?
              Number(selectedPool.averagePoolTemperature) : '',
            hasStartingBlocks: ToBoolIfBool(selectedPool.startingBlocks) !== null ? ToBoolIfBool(selectedPool.startingBlocks) : '',
            poolConfiguration: selectedPool.poolConfiguration || []
          });
        }
      }
      else {
        //Add
        updateFormState('facilityId', facilityState.objData.facilityId);
      }
      setState({ ...state, initialLoadCompleted: true })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [facilityState.isObjLoaded, state.initialLoadCompleted]);

  useEffect(() => {
    if (state.tryRedirect === true && facilityState.isSaving === false && facilityState.isSaved === true) {
      if (showFacilityInfo === true) {
        clearObjData();
      }
      navigate(saveUrl, { state: { addedPool: true } });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state, facilityState]);

  function submitFormCallback() {
    const updatedFacility = createFacilityObjForSubmit();

    if (facilityState.isSaving === false) {
      putFacility(updatedFacility.facilityId, updatedFacility);
      setState({ tryRedirect: true });
    }
  };

  function createFacilityObjForSubmit() {
    const facilityCopy = getFacilityCopy();

    if (formState.poolId < 0) {
      //Add
      const newPool = createNewPoolObject(formState);
      facilityCopy.facilityPool.push(newPool);
    }
    else {
      //Edit
      const updatedPool = updatePoolObject(formState);

      const newPoolsArray = facilityCopy.facilityPool.filter((pool) => pool.facilityPoolId !== formState.poolId);
      newPoolsArray.push(updatedPool);

      facilityCopy.facilityPool = newPoolsArray;
    }

    return facilityCopy;
  };

  return {
    facilityState,
    poolDimensionsState,
    poolConfigurationState,
    setPoolConfigurationState,
    formState,
    errorState,
    handleSubmit,
    onFormValueChanged: updateFormState,
    onSaveClicked,
    onCancelClicked,
    onValueTextPairChanged,
    onAddPoolConfig,
    onEditPoolConfig,
    onDeletePoolConfig
  };
};

export default useAddEditFacilityPool;

function getInitialFormState() {
  return {
    facilityId: Constants.DEFAULT_ID,
    poolId: Constants.DEFAULT_ID,
    poolDimensionsId: Constants.DEFAULT_ID,
    poolDimensions: '',
    poolName: '',
    poolMaxDepth: '',
    averagePoolTemperature: '',
    hasStartingBlocks: '',
    poolConfiguration: []
  };
};