import { useState } from 'react';

import ClubMeetsData from './ClubMeetsData';

const useClubMeetsData = () => {
  const [clubMeetsState, setClubMeetsState] = useState(ClubMeetsData.INITIAL_STATE);

  const getClubMeets = (orgUnitId, reportPeriodId) => {
    ClubMeetsData.getClubMeetsData(orgUnitId, reportPeriodId, clubMeetsState, setClubMeetsState);
  };

  const postMeetSanctionRequest = (meetSanctionRequest) => {
    ClubMeetsData.postClubMeetSanctionRequestData(meetSanctionRequest, clubMeetsState, setClubMeetsState);
  };

  const deleteClubMeet = (meetId) => {
    ClubMeetsData.deleteMeetData(meetId, clubMeetsState, setClubMeetsState);
  };

  const clearObjData = () => {
    setClubMeetsState({
      ...clubMeetsState,
      isObjLoading: false,
      isObjLoaded: false,
      isSaving: false,
      isSaved: false,
      objData: {}
    });
  };

  const clearArrayData = () => {
    setClubMeetsState({
      ...clubMeetsState,
      isArrayLoading: false,
      isArrayLoaded: false,
      isSaving: false,
      isSaved: false,
      arrayData: []
    });
  };

  const confirmSave = () => {
    setClubMeetsState({
      ...clubMeetsState,
      isSaved: false
    });
  };

  return {
    clubMeetsState,
    getClubMeets,
    postMeetSanctionRequest,
    deleteClubMeet,
    clearArrayData,
    clearObjData,
    confirmSave
  };
};

export default useClubMeetsData;