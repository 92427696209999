import { useContext, useEffect } from 'react';

import { PoolDimensionsStateContext } from './PoolDimensionsContextProvider';

import PoolDimensionsData from './PoolDimensionsData';

const usePoolDimensionsData = () => {
  const [poolDimensionsState, setPoolDimensionsState] = useContext(PoolDimensionsStateContext);

  useEffect(() => {
    if (poolDimensionsState.isLoading !== true && poolDimensionsState.isLoaded !== true) {
      PoolDimensionsData.getPoolDimensions(poolDimensionsState, setPoolDimensionsState);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    poolDimensionsState
  };
};

export default usePoolDimensionsData;