import React, { createContext, useState } from 'react';

const INITIAL_STATE = {
  filterObject: {},
  sortBy: ''
};

export const PreCompSearchFilterStateContext = createContext();

const PreCompSearchFilterContextProvider = ({ children }) => {
  const stateHook = useState(INITIAL_STATE);

  return (
    <PreCompSearchFilterStateContext.Provider value={stateHook}>
      {children}
    </PreCompSearchFilterStateContext.Provider>
  );
};

export default PreCompSearchFilterContextProvider;