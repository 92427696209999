import { useContext } from 'react';

import { ClubHistoryStateContext } from './ClubHistoryContextProvider';

import ClubHistoryData from './ClubHistoryData';

import Constants from '../../../common/utils/Constants';

const useClubHistoryData = () => {
  const [clubHistoryState, setClubHistoryState] = useContext(ClubHistoryStateContext);
  const getClubHistory = (usasClubId) => { ClubHistoryData.getClubHistoryData(usasClubId, clubHistoryState, setClubHistoryState); }

  const clearClubHistoryArrayData = () => {
    setClubHistoryState({
      ...clubHistoryState,
      usasClubId: Constants.DEFAULT_ID,
      isArrayLoading: false,
      isArrayLoaded: false,
      isSaving: false,
      isSaved: false,
      arrayData: [],
      message: ''
    });
  };

  return {
    clubHistoryState,
    getClubHistory,
    clearClubHistoryArrayData
  };
};

export default useClubHistoryData;