import ClubPerformanceBasicRoutes from "./ClubPerformanceBasicRoutes";

import LoadingModal from "../../../common/components/dialogs/LoadingModal";

import useNavRoutes from "../../../common/state/security/UseNavRoutes";

const ClubPerformance = () => {
  const { navRoutes, isReadyToRoute } = useNavRoutes();

  if (isReadyToRoute !== true) {
    return <LoadingModal />;
  }

  return <ClubPerformanceBasicRoutes navRoutes={navRoutes} />;
};

export default ClubPerformance;