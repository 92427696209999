import { useEffect, useState } from 'react';
import { useNavigate } from '../../../../common/wrappers/ReactRouterDom';

import NavLinks from '../NavLinks';

import usePreCompLocationData from '../../../state/preCompLocation/UsePreCompLocationData';

const usePreCompLocationPrograms = () => {
  const navigate = useNavigate();
  const { preCompLocationState, confirmSave, putPreCompLocation } = usePreCompLocationData();
  const [gridState, setGridState] = useState([]);

  const onAddOffering = () => {
    navigate(NavLinks.PRE_COMP_LOCATION_PROGRAMS_DETAIL);
  };

  const onEditOffering = (preCompLocationOfferingId) => {
    navigate(NavLinks.PRE_COMP_LOCATION_PROGRAMS_DETAIL, { state: { preCompLocationOfferingId } });
  };

  const onDeleteOffering = (preCompLocationOfferingId) => {
    if (preCompLocationState.isSaving === false) {
      const locationCopy = JSON.parse(JSON.stringify( preCompLocationState.objData));
      const newPreCompLocationOffering = locationCopy.preCompLocationOffering.filter(x => x.preCompLocationOfferingId !== preCompLocationOfferingId);

      locationCopy.preCompLocationOffering = newPreCompLocationOffering;

      putPreCompLocation(preCompLocationState.objData.preCompetitiveLocationId, locationCopy);
    }
  };

  useEffect(() => {
    if (preCompLocationState.isSaving === false && preCompLocationState.isSaved === true) {
      confirmSave();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [preCompLocationState]);

  useEffect(() => {
    if (preCompLocationState.isObjLoaded === true) {
      setGridState(preCompLocationState.objData.preCompLocationOffering || []);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [preCompLocationState.objData]);

  return {
    isSaving: preCompLocationState.isSaving,
    isLoading: preCompLocationState.isObjLoading,
    gridState,
    onAddOffering,
    onEditOffering,
    onDeleteOffering
  };
};

export default usePreCompLocationPrograms;