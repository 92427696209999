const NavLinks = {
  LEARN_TO_SWIM_SEARCH: '/learntoswim/search',
  LEARN_TO_SWIM_MY_PROVIDERS: '/learntoswim/myprovider/selection',
  LEARN_TO_SWIM_PROVIDER_INFO: '/learntoswim/providerinfo',
  LEARN_TO_SWIM_FACILITIES: '/learntoswim/facilities',
  LEARN_TO_SWIM_FACILITIES_SEARCH: '/learntoswim/facilities/search',
  LEARN_TO_SWIM_FACILITIES_ADD: '/learntoswim/facilities/add',
  LEARN_TO_SWIM_FACILITIES_DETAIL: '/learntoswim/facilities/detail',
  LEARN_TO_SWIM_FACILITIES_POOLS_DETAIL: '/learntoswim/facilities/pools/detail',
  LEARN_TO_SWIM_STAFF: '/learntoswim/staff',
  LEARN_TO_SWIM_STAFF_ADD: '/learntoswim/staff/add',
  LEARN_TO_SWIM_STAFF_EDIT: '/learntoswim/staff/edit'
};

export default NavLinks;