import { Route, Routes } from "react-router-dom";
import { Navigate } from "../../../common/wrappers/ReactRouterDom";

import LearnToSwimContextView from "./LearnToSwimContextView";

import LearnToSwimSearch from "./learnToSwimSearch/LearnToSwimSearch";
import LearnToSwimOrgUnitSelection from "./learnToSwimMyProviders/LearnToSwimOrgUnitSelection";
import LearnToSwimProviderInfo from "./learnToSwimProviderInfo/LearnToSwimProviderInfo";
import LearnToSwimFacilities from "./learnToSwimFacilities/LearnToSwimFacilities";
import LearnToSwimFacilitiesSearch from "./learnToSwimFacilities/LearnToSwimFacilitiesSearch";
import LearnToSwimFacilitiesAdd from "./learnToSwimFacilities/LearnToSwimFacilitiesAdd";
import LearnToSwimFacilitiesDetail from "./learnToSwimFacilities/LearnToSwimFacilitiesDetail";
import LearnToSwimFacilitiesPoolsDetail from "./learnToSwimFacilities/LearnToSwimFacilitiesPoolsDetail";
import LearnToSwimStaff from "./learnToSwimStaff/LearnToSwimStaff";
import LearnToSwimStaffAdd from "./learnToSwimStaff/LearnToSwimStaffAdd";
import LearnToSwimStaffEdit from "./learnToSwimStaff/LearnToSwimStaffEdit";

import LearnToSwimContextProvider from "../../state/learnToSwim/LearnToSwimContextProvider";
import LearnToSwimClubsContextProvider from "../../state/learnToSwimClubs/LearnToSwimClubsContextProvider";
import LearnToSwimCurriculumContextProvider from "../../state/learnToSwimCurriculum/LearnToSwimCurriculumContextProvider";
import LearnToSwimFilterContextProvider from "../../state/learnToSwim/LearnToSwimFilterContextProvider";
import LearnToSwimFacilitiesContextProvider from "../../state/learnToSwimFacilities/LearnToSwimFacilitiesContextProvider";
import LearnToSwimStaffContextProvider from "../../state/learnToSwimStaff/LearnToSwimStaffContextProvider";

import LoadingModal from "../../../common/components/dialogs/LoadingModal";
import SecuredRouteElement from "../../../common/components/security/SecuredRouteElement";

import useNavRoutes from "../../../common/state/security/UseNavRoutes";
import FacilityContextProvider from "../../../common/state/facility/FacilityContextProvider";
import FacilityFiltersContextProvider from "../../../common/state/facility/FacilityFiltersContextProvider";
import SearchFacilityContextProvider from "../../../common/state/searchFacility/SearchFacilityContextProvider";
import PersonOrgRoleContextProvider from "../../../common/state/personOrgRole/PersonOrgRoleContextProvider";
import SelectOrgUnitContextProvider from "../../../common/state/selectOrgUnit/SelectOrgUnitContextProvider";

const LearnToSwimContextRoutesContextProviders = ({ children }) => (
	<LearnToSwimCurriculumContextProvider>
		<LearnToSwimClubsContextProvider>
			<FacilityContextProvider>
				<FacilityFiltersContextProvider>
					<SearchFacilityContextProvider>
						<LearnToSwimFacilitiesContextProvider>
							<LearnToSwimStaffContextProvider>
								{children}
							</LearnToSwimStaffContextProvider>
						</LearnToSwimFacilitiesContextProvider>
					</SearchFacilityContextProvider>
				</FacilityFiltersContextProvider>
			</FacilityContextProvider>
		</LearnToSwimClubsContextProvider>
	</LearnToSwimCurriculumContextProvider>
);

const LearnToSwimBasicRoutesContextProviders = ({ children }) => (
	<PersonOrgRoleContextProvider>
		<SelectOrgUnitContextProvider>
			<LearnToSwimFilterContextProvider>
				<LearnToSwimContextProvider>
					{children}
				</LearnToSwimContextProvider>
			</LearnToSwimFilterContextProvider>
		</SelectOrgUnitContextProvider>
	</PersonOrgRoleContextProvider>
);

const LearnToSwimContextRoutes = ({ navRoutes }) => (
	<LearnToSwimContextRoutesContextProviders>
		<LearnToSwimContextView>
			<Routes>
				<Route path={navRoutes.LEARN_TO_SWIM_PROVIDER_INFO?.path}
					element={
						<SecuredRouteElement
							navRoute={navRoutes.LEARN_TO_SWIM_PROVIDER_INFO}
							editElement={<LearnToSwimProviderInfo />} />
					} />
				<Route path={navRoutes.LEARN_TO_SWIM_FACILITIES?.path}
					element={
						<SecuredRouteElement
							navRoute={navRoutes.LEARN_TO_SWIM_FACILITIES}
							editElement={<LearnToSwimFacilities />} />
					} />
				<Route path={navRoutes.LEARN_TO_SWIM_FACILITIES_SEARCH?.path}
					element={
						<SecuredRouteElement
							navRoute={navRoutes.LEARN_TO_SWIM_FACILITIES_SEARCH}
							editElement={<LearnToSwimFacilitiesSearch />} />
					} />
				<Route path={navRoutes.LEARN_TO_SWIM_FACILITIES_ADD?.path}
					element={
						<SecuredRouteElement
							navRoute={navRoutes.LEARN_TO_SWIM_FACILITIES_ADD}
							editElement={<LearnToSwimFacilitiesAdd />} />
					} />
				<Route path={navRoutes.LEARN_TO_SWIM_FACILITIES_DETAIL?.path}
					element={
						<SecuredRouteElement
							navRoute={navRoutes.LEARN_TO_SWIM_FACILITIES_DETAIL}
							editElement={<LearnToSwimFacilitiesDetail />} />
					} />
				<Route path={navRoutes.LEARN_TO_SWIM_FACILITIES_POOLS_DETAIL?.path}
					element={
						<SecuredRouteElement
							navRoute={navRoutes.LEARN_TO_SWIM_FACILITIES_POOLS_DETAIL}
							editElement={<LearnToSwimFacilitiesPoolsDetail />} />
					} />
				<Route path={navRoutes.LEARN_TO_SWIM_STAFF?.path}
					element={
						<SecuredRouteElement
							navRoute={navRoutes.LEARN_TO_SWIM_STAFF}
							editElement={<LearnToSwimStaff />} />
					} />
				<Route path={navRoutes.LEARN_TO_SWIM_STAFF_ADD?.path}
					element={
						<SecuredRouteElement
							navRoute={navRoutes.LEARN_TO_SWIM_STAFF_ADD}
							editElement={<LearnToSwimStaffAdd />} />
					} />
				<Route path={navRoutes.LEARN_TO_SWIM_STAFF_EDIT?.path}
					element={
						<SecuredRouteElement
							navRoute={navRoutes.LEARN_TO_SWIM_STAFF_EDIT}
							editElement={<LearnToSwimStaffEdit />} />
					} />
			</Routes>
		</LearnToSwimContextView>
	</LearnToSwimContextRoutesContextProviders>
);

const LearnToSwimBasicRoutes = ({ navRoutes }) => (
	<LearnToSwimBasicRoutesContextProviders>
		<Routes>
			<Route path={navRoutes.LEARN_TO_SWIM_SEARCH?.path}
				element={
					<SecuredRouteElement
						navRoute={navRoutes.LEARN_TO_SWIM_SEARCH}
						editElement={<LearnToSwimSearch />} />
				} />
			<Route path={navRoutes.LEARN_TO_SWIM_MY_PROVIDERS?.path}
				element={
					<SecuredRouteElement
						navRoute={navRoutes.LEARN_TO_SWIM_MY_PROVIDERS}
						editElement={<LearnToSwimOrgUnitSelection />} />
				} />

			<Route path={'/*'} element={<LearnToSwimContextRoutes navRoutes={navRoutes} />} />
			<Route path={'/'} element={<Navigate to={navRoutes.LEARN_TO_SWIM_SEARCH?.path} replace />} />
		</Routes>
	</LearnToSwimBasicRoutesContextProviders>
);

const LearnToSwim = () => {
	const { navRoutes, isReadyToRoute } = useNavRoutes();

	if (isReadyToRoute !== true) {
		return <LoadingModal />;
	}

	return (
		<LearnToSwimBasicRoutes navRoutes={navRoutes} />
	);
};

export default LearnToSwim;