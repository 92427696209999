import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { Navigate } from '../../../common/wrappers/ReactRouterDom';

import PreCompLocationContextProvider from '../../state/preCompLocation/PreCompLocationContextProvider';
import PreCompAffiliationTypeContextProvider from '../../state/preCompAffiliationType/PreCompAffiliationTypeContextProvider';
import PreCompBusinessEntityTypeContextProvider from '../../state/preCompBusinessEntityType/PreCompBusinessEntityTypeContextProvider';
import PreCompCurriculumContextProvider from '../../state/preCompCurriculum/PreCompCurriculumContextProvider';
import PreCompInstructorStudentRatioContextProvider from '../../state/preCompInstructorStudentRatio/PreCompInstructorStudentRatioContextProvider';
import PreCompInstructionHoursContextProvider from '../../state/preCompInstructionHours/PreCompInstructionHoursContextProvider';
import PreCompOfferingTypeContextProvider from '../../state/preCompOfferingType/PreCompOfferingTypeContextProvider';
import PreCompPolicyTypeContextProvider from '../../state/preCompPolicyType/PreCompPolicyTypeContextProvider';
import PreCompOrganizationContactsContextProvider from '../../state/preCompOrganizationContacts/PreCompOrganizationContactsContextProvider';
import PreCompLocationStaffContextProvider from '../../state/preCompLocationStaff/PreCompLocationStaffContextProvider';

import PreCompOrganizationContextProvider from '../../../common/state/preCompOrganization/PreCompOrganizationContextProvider';
import PreCompSearchFilterContextProvider from '../../../common/state/preCompOrganization/PreCompSearchFilterContextProvider';
import SearchPreCompContextProvider from '../../../common/state/searchPreComp/SearchPreCompContextProvider';
import BaseUsasClubContextProvider from '../../../common/state/clubBase/BaseUsasClubContextProvider';
import BaseUsasClubFiltersContextProvider from '../../../common/state/clubBase/BaseUsasClubFiltersContextProvider';
import SearchClubContextProvider from '../../../common/state/searchClub/SearchClubContextProvider';
import FacilityContextProvider from '../../../common/state/facility/FacilityContextProvider';
import FacilityFiltersContextProvider from '../../../common/state/facility/FacilityFiltersContextProvider';
import SearchFacilityContextProvider from '../../../common/state/searchFacility/SearchFacilityContextProvider';
import OrgRoleHierarchyContextProvider from '../../../common/state/orgRoleHierarchy/OrgRoleHierarchyContextProvider';
import PersonGeneralContextProvider from '../../../common/state/personGeneral/PersonGeneralContextProvider';
import PoolConfigurationContextProvider from '../../../common/state/poolConfiguration/PoolConfigurationContextProvider';
import SelectOrgUnitContextProvider from '../../../common/state/selectOrgUnit/SelectOrgUnitContextProvider';
import PersonOrgRoleContextProvider from '../../../common/state/personOrgRole/PersonOrgRoleContextProvider';
import OrgRoleStaffRoleHierarchyContextProvider from '../../../common/state/orgRoleStaffRoleHierarchy/OrgRoleStaffRoleHierarchyContextProvider';
import TeamFacilityLinksContextProvider from '../../../common/state/teamFacilityLinks/TeamFacilityLinksContextProvider';

import PreCompContextView from './PreCompContextView';

import PreCompSearch from './preCompSearch/PreCompSearch';
import PreCompLocationInfo from './preCompLocationInfo/PreCompLocationInfo';
import PreCompLocationClubs from './preCompLocationClubs/PreCompLocationClubs';
import PreCompLocationStaff from './preCompLocationStaff/PreCompLocationStaff';
import PreCompLocationFacilities from './preCompLocationFacilities/PreCompLocationFacilities';
import PreCompLocationFacilitiesLinkSearch from './preCompLocationFacilities/PreCompLocationFacilitiesLinkSearch';
import PreCompLocationFacilitiesAdd from './preCompLocationFacilities/PreCompLocationFacilitiesAdd';
import PreCompLocationFacilitiesPoolsDetail from './preCompLocationFacilities/PreCompLocationFacilitiesPoolsDetail';
import PreCompLocationInstructorTraining from './preCompLocationInstructorTraining/PreCompLocationInstructorTraining';
import PreCompOrganizationInfo from './preCompOrganizationInfo/PreCompOrganizationInfo';
import PreCompOrganizationInsurance from './preCompOrganizationInsurance/PreCompOrganizationInsurance';
import PreCompOrganizationContacts from './preCompOrganizationContacts/PreCompOrganizationContacts';
import PreCompLocationClubsLinkSearch from './preCompLocationClubs/PreCompLocationClubsLinkSearch';
import PreCompLocationFacilitiesDetail from './preCompLocationFacilities/PreCompLocationFacilitiesDetail';
import PreCompLocationStaffDetail from './preCompLocationStaff/PreCompLocationStaffDetail';
import PreCompOrganizationContactsDetail from './preCompOrganizationContacts/PreCompOrganizationContactsDetail';
import OrgUnitSelection from './orgUnitSelection/OrgUnitSelection';
import PreCompLocationPrograms from './preCompLocationPrograms/PreCompLocationPrograms';
import PreCompLocationProgramsDetail from './preCompLocationPrograms/PreCompLocationProgramsDetail';

import LoadingModal from '../../../common/components/dialogs/LoadingModal';
import useNavRoutes from '../../../common/state/security/UseNavRoutes';
import SecuredRouteElement from '../../../common/components/security/SecuredRouteElement';

const ContextRoutes = ({ navRoutes }) => (
  <PreCompContextView>
    <Routes>
      <Route path={navRoutes['PRE_COMP_LOCATION_INFO']?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes['PRE_COMP_LOCATION_INFO']}
            editElement={<PreCompLocationInfo />} />
        } />
      <Route path={navRoutes['PRE_COMP_LOCATION_PROGRAMS']?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes['PRE_COMP_LOCATION_PROGRAMS']}
            editElement={<PreCompLocationPrograms />} />
        } />
      <Route path={navRoutes['PRE_COMP_LOCATION_PROGRAMS_DETAIL']?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes['PRE_COMP_LOCATION_PROGRAMS_DETAIL']}
            editElement={<PreCompLocationProgramsDetail />} />
        } />
      <Route path={navRoutes['PRE_COMP_LOCATION_CLUB']?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes['PRE_COMP_LOCATION_CLUB']}
            editElement={<PreCompLocationClubs />} />
        } />
      <Route path={navRoutes['PRE_COMP_LOCATION_CLUB_SEARCH']?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes['PRE_COMP_LOCATION_CLUB_SEARCH']}
            editElement={<PreCompLocationClubsLinkSearch />} />
        } />
      <Route path={navRoutes['PRE_COMP_LOCATION_STAFF']?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes['PRE_COMP_LOCATION_STAFF']}
            editElement={<PreCompLocationStaff />} />
        } />
      <Route path={navRoutes['PRE_COMP_LOCATION_STAFF_DETAIL']?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes['PRE_COMP_LOCATION_STAFF_DETAIL']}
            editElement={<PreCompLocationStaffDetail />} />
        } />
      <Route path={navRoutes['PRE_COMP_LOCATION_FACILITY']?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes['PRE_COMP_LOCATION_FACILITY']}
            editElement={<PreCompLocationFacilities />} />
        } />
      <Route path={navRoutes['PRE_COMP_LOCATION_FACILITY_SEARCH']?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes['PRE_COMP_LOCATION_FACILITY_SEARCH']}
            editElement={<PreCompLocationFacilitiesLinkSearch />} />
        } />
      <Route path={navRoutes['PRE_COMP_LOCATION_FACILITY_ADD']?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes['PRE_COMP_LOCATION_FACILITY_ADD']}
            editElement={<PreCompLocationFacilitiesAdd />} />
        } />
      <Route path={navRoutes['PRE_COMP_LOCATION_FACILITY_DETAIL']?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes['PRE_COMP_LOCATION_FACILITY_DETAIL']}
            editElement={<PreCompLocationFacilitiesDetail />} />
        } />
      <Route path={navRoutes['PRE_COMP_LOCATION_FACILITY_POOL_DETAIL']?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes['PRE_COMP_LOCATION_FACILITY_POOL_DETAIL']}
            editElement={<PreCompLocationFacilitiesPoolsDetail />} />
        } />
      <Route path={navRoutes['PRE_COMP_LOCATION_INSTRUCTOR_TRAINING']?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes['PRE_COMP_LOCATION_INSTRUCTOR_TRAINING']}
            editElement={<PreCompLocationInstructorTraining />} />
        } />
      <Route path={navRoutes['PRE_COMP_ORGANIZATION_INFO']?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes['PRE_COMP_ORGANIZATION_INFO']}
            editElement={<PreCompOrganizationInfo />} />
        } />
      <Route path={navRoutes['PRE_COMP_ORGANIZATION_INSURANCE']?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes['PRE_COMP_ORGANIZATION_INSURANCE']}
            editElement={<PreCompOrganizationInsurance />} />
        } />
      <Route path={navRoutes['PRE_COMP_ORGANIZATION_CONTACT']?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes['PRE_COMP_ORGANIZATION_CONTACT']}
            editElement={<PreCompOrganizationContacts />} />
        } />
      <Route path={navRoutes['PRE_COMP_ORGANIZATION_CONTACT_DETAIL']?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes['PRE_COMP_ORGANIZATION_CONTACT_DETAIL']}
            editElement={<PreCompOrganizationContactsDetail />} />
        } />
    </Routes>
  </PreCompContextView>
);

const PreCompRoutes = () => {
  const { navRoutes, isReadyToRoute } = useNavRoutes();

  if (isReadyToRoute !== true) {
    return <LoadingModal />;
  }

  return (
    <Routes>
      <Route path={navRoutes['PRE_COMP_SEARCH']?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes['PRE_COMP_SEARCH']}
            editElement={<PreCompSearch />} />
        } />
      <Route path={navRoutes['MY_PRECOMP_SELECTION']?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes['MY_PRECOMP_SELECTION']}
            editElement={<OrgUnitSelection />} />
        } />
      <Route path={'/*'} element={<ContextRoutes navRoutes={navRoutes} />} />
      <Route path={'/'} element={<Navigate to={'search'} />} />
    </Routes>
  );
};

const PreComp = () => (
  <SelectOrgUnitContextProvider>
    <PersonOrgRoleContextProvider>
      <PreCompAffiliationTypeContextProvider>
        <PreCompBusinessEntityTypeContextProvider>
          <PreCompCurriculumContextProvider>
            <TeamFacilityLinksContextProvider>
              <PreCompInstructorStudentRatioContextProvider>
                <PreCompInstructionHoursContextProvider>
                  <PreCompOfferingTypeContextProvider>
                    <PreCompPolicyTypeContextProvider>
                      <PersonGeneralContextProvider>
                        <OrgRoleHierarchyContextProvider>
                          <OrgRoleStaffRoleHierarchyContextProvider>
                            <PreCompOrganizationContextProvider>
                              <PreCompLocationStaffContextProvider>
                                <PreCompLocationContextProvider>
                                  <PreCompOrganizationContactsContextProvider>
                                    <PreCompSearchFilterContextProvider>
                                      <SearchPreCompContextProvider>
                                        <BaseUsasClubContextProvider>
                                          <BaseUsasClubFiltersContextProvider>
                                            <SearchClubContextProvider>
                                              <FacilityContextProvider>
                                                <FacilityFiltersContextProvider>
                                                  <SearchFacilityContextProvider>
                                                    <PoolConfigurationContextProvider>
                                                      <PreCompRoutes />
                                                    </PoolConfigurationContextProvider>
                                                  </SearchFacilityContextProvider>
                                                </FacilityFiltersContextProvider>
                                              </FacilityContextProvider>
                                            </SearchClubContextProvider>
                                          </BaseUsasClubFiltersContextProvider>
                                        </BaseUsasClubContextProvider>
                                      </SearchPreCompContextProvider>
                                    </PreCompSearchFilterContextProvider>
                                  </PreCompOrganizationContactsContextProvider>
                                </PreCompLocationContextProvider>
                              </PreCompLocationStaffContextProvider>
                            </PreCompOrganizationContextProvider>
                          </OrgRoleStaffRoleHierarchyContextProvider>
                        </OrgRoleHierarchyContextProvider>
                      </PersonGeneralContextProvider>
                    </PreCompPolicyTypeContextProvider>
                  </PreCompOfferingTypeContextProvider>
                </PreCompInstructionHoursContextProvider>
              </PreCompInstructorStudentRatioContextProvider>
            </TeamFacilityLinksContextProvider>
          </PreCompCurriculumContextProvider>
        </PreCompBusinessEntityTypeContextProvider >
      </PreCompAffiliationTypeContextProvider>
    </PersonOrgRoleContextProvider>
  </SelectOrgUnitContextProvider>
);

export default PreComp;