import useSisenseTimeStandardDropdown from "./UseSisenseTimeStandardDropdown";

import Dropdown from '../../../../common/components/dropdowns/Dropdown';

import Constants from "../../../../common/utils/Constants";

import global from '../../../../common/components/GlobalStyle.module.css';

const SisenseTimeStandardDropdown = ({ label, name, value, error, message, onChange, showDefault, showForRelays }) => {
  const { timeStandardOptions, isLoading } = useSisenseTimeStandardDropdown(showDefault, showForRelays);

  return isLoading === true
    ? <span className={global.LoadingMsg}>{Constants.LOADING_MSG}</span>
    : (
      <Dropdown
        error={error}
        label={label}
        message={message}
        options={timeStandardOptions.options}
        name={name}
        value={value}
        onChange={onChange}
        isLoading={isLoading === true}
      />
    );
};

export default SisenseTimeStandardDropdown;