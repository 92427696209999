import { useEffect, useState } from "react";

import useUsasClubStaffData from "../../../state/clubStaff/UseUsasClubStaffData";

import useBaseUsasClubData from "../../../../common/state/clubBase/UseBaseUsasClubData";
import useEnvironmentVariableData from "../../../../common/state/environmentVariable/UseEnvironmentVariableData";
import useEmbeddedReportData from "../../../../common/state/embeddedReport/UseEmbeddedReportData";

const INITIAL_VIEW_STATE = {
  reportParameters: { orgUnitId: '' },
  routeName: '',
  reportViewerType: '',
  reportName: '',
  showReport: false,
  isLoading: false,
};

const useClubStaffRequirements = () => {
  const { baseUsasClubState } = useBaseUsasClubData();
  const { environmentVariableState, CLUB_STAFF_ROLE_GROUP_ID } = useEnvironmentVariableData();
  const { usasClubStaffState, getUsasClubStaff } = useUsasClubStaffData();
  const { getEmbeddedReportListForRoute } = useEmbeddedReportData();
  const [viewState, setViewState] = useState(INITIAL_VIEW_STATE);

  const onRunClubStaffRequirementsReport = () => {
    setViewState({
      ...viewState,
      reportParameters: { orgUnitId: baseUsasClubState.objData.orgUnitId },
      routeName: 'CLUB_STAFF_REQUIREMENTS',
      reportViewerType: 'ActiveReports',
      reportName: 'Member Requirements Printable Report',
      isLoading: true
    });
  };

  useEffect(() => {
    if (viewState.routeName) {
      const reportListPromise = getEmbeddedReportListForRoute(viewState.routeName);

      if (reportListPromise) {
        reportListPromise.then((stateObj) => {
          if (Array.isArray(stateObj.arrayData)) {
            const reportIdx = stateObj.arrayData.findIndex(x => ((x.reportViewerType === viewState.reportViewerType) && (x.reportName === viewState.reportName)));

            setViewState({
              ...viewState,
              isLoading: false,
              showReport: stateObj.arrayData.length > 0,
              reportInfoId: stateObj.arrayData[reportIdx].reportInfoId || -1,
            });
          } else {
            setViewState({
              ...viewState,
              isLoading: false,
              showReport: false,
              reportInfoId: -1
            });
          }
        }).catch((err) => {
          setViewState({
            ...viewState,
            isLoading: false,
            showReport: false,
            reportInfoId: -1
          });
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewState.routeName]);

  useEffect(() => {
    if (baseUsasClubState.isObjLoaded === true && environmentVariableState.isLoaded === true) {
      getUsasClubStaff(baseUsasClubState.objData.orgUnitId, CLUB_STAFF_ROLE_GROUP_ID);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps   
  }, [usasClubStaffState.isSaved, environmentVariableState]);

  return {
    usasClubStaffState,
    viewState,
    setViewState,
    INITIAL_VIEW_STATE,
    onRunClubStaffRequirementsReport
  };
};

export default useClubStaffRequirements;