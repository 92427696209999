import { useEffect, useState } from 'react';
import { useNavigate } from '../../../../common/wrappers/ReactRouterDom';

import NavLinks from '../NavLinks';

import usePreCompLocationData from '../../../state/preCompLocation/UsePreCompLocationData';

const usePreCompLocationClubsLinkSearch = () => {
  const navigate = useNavigate();
  const { preCompLocationState, confirmSave, putPreCompLocation } = usePreCompLocationData();
  const [modalState, setModalState] = useState(getInitialModalState);

  const onLinkClub = (e, usasClubId) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    let dupClub = null;
    for (const club of preCompLocationState.objData.preCompLocationClub) {
      if (club.usasClubId === usasClubId) {
        dupClub = club;
        break;
      }
    }

    if (dupClub === null) {
      const locationCopy = JSON.parse(JSON.stringify(preCompLocationState.objData));

      locationCopy.preCompLocationClub.push({
        preCompetitiveLocationId: preCompLocationState.objData.preCompetitiveLocationId,
        usasClubId,
      });

      putPreCompLocation(preCompLocationState.objData.preCompetitiveLocationId, locationCopy);

      setModalState(getInitialModalState);
    } else {
      setModalState({
        ...modalState,
        displayModal: true,
        clubName: dupClub.clubInfo?.name || '',
        clubLocation: dupClub.clubInfo?.location || '',
      });
    }
  };

  const onCancelClicked = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    navigate(NavLinks.PRE_COMP_LOCATION_CLUB);
  }

  const onModalCancel = () => {
    setModalState(getInitialModalState);
  }

  useEffect(() => {
    if (preCompLocationState.isSaving === false && preCompLocationState.isSaved === true) {
      confirmSave();
      navigate(NavLinks.PRE_COMP_LOCATION_CLUB);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [preCompLocationState]);

  function getInitialModalState() {
    return {
      displayModal: false,
      clubName: '',
      clubLocation: ''
    }
  }

  return {
    isSaving: preCompLocationState.isSaving,
    isLoading: preCompLocationState.isObjLoading,
    preCompLocationState,
    modalState,
    onLinkClub,
    onCancelClicked,
    onModalCancel
  };
};

export default usePreCompLocationClubsLinkSearch;