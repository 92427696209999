import React, { Fragment } from 'react';

import useClubRegistrationLegalEntities from './UseClubRegistrationLegalEntities';

import ClubRegistrationLegalEntitiesGridWrite from './ClubRegistrationLegalEntitiesGridWrite';

import ValidationPopUpModal from '../components/ValidationPopUpModal';

import ClubRegistrationConstants from '../utils/ClubRegistrationConstants';

import LeftNav from '../../../../common/components/leftNav/LeftNav';
import Headings from '../../../../common/components/headings/Headings';
import PrimaryButton from '../../../../common/components/buttons/PrimaryButton';
import SecondaryButton from '../../../../common/components/buttons/SecondaryButton';
import ActionIntraPageButton from '../../../../common/components/buttons/ActionIntraPageButton';
import PopUpModal from '../../../../common/components/dialogs/PopUpModal';
import Constants from '../../../../common/utils/Constants';

import global from '../../../../common/components/GlobalStyle.module.css';

const WriteComponent = ({ clubRegistrationState, onAdd, onEdit, onDelete, state }) => (
  <Fragment>
    <div className="row">
      <div className="col-xs-12">
        <p>Please provide the legal entities associated with the club.
          The legal entity name(s) should be the legal name(s) used on tax documents.
          The legal entity tax Id(s) must be in ITIN (XXX-XX-XXXX) or EIN (XX-XXXXXX) format. </p>
      </div>
    </div>
    {/* {clubRegistrationState.objData?.clubRegistrations?.length > 1 ?
      <div className="row">
        <div className="col-xs-12">
          <p><b>Any changes made on this step will require the club application to undergo review by USA Swimming</b></p>
        </div>
      </div>
      : <Fragment />} */}
    <div className="row usas-extra-top-margin">
      <div className="col-xs-12">
        <ActionIntraPageButton type="button" onClick={() => onAdd()}>Add Legal Entity</ActionIntraPageButton>&nbsp;
      </div>
    </div>
    <div className="row  usas-extra-top-margin">
      <div className="col-xs-12">
        <ClubRegistrationLegalEntitiesGridWrite state={clubRegistrationState} onEdit={onEdit} onDelete={onDelete} />
      </div>
    </div>
    {state.error &&
      <div className="row">
        <div className="col-xs-12">
          <p className={global.ErrorMessage}>{state.error ? state.error : ''}</p>
        </div>
      </div>}
  </Fragment>
);

const ClubRegistrationLegalEntities = () => {
  const { clubRegistrationNavState, clubRegistrationState, state, modalState, onModalContinue, onModalCanceled,
    onAdd, onEdit, onDelete, onContinueClicked, onSaveAndExitClicked, onBackClicked } = useClubRegistrationLegalEntities();

  return (
    <LeftNav title={ClubRegistrationConstants.LEFT_NAV_TITLE} state={clubRegistrationNavState} validateBeforeRedirect={false}>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>Club Legal Entities</Headings.H3>
        </div>
      </div>
      <WriteComponent
        clubRegistrationState={clubRegistrationState}
        onAdd={onAdd}
        onEdit={onEdit}
        onDelete={onDelete}
        state={state}
      />
      <div className="row usas-extra-top-margin usas-extra-bottom-margin">
        <div className="col-xs-12 usas-extra-top-margin">
          <PrimaryButton type="button" onClick={() => onContinueClicked()}>Continue</PrimaryButton>&nbsp;
          <PrimaryButton type="button" onClick={() => onSaveAndExitClicked()}>
            {clubRegistrationState.objData.status === ClubRegistrationConstants.IN_PROGRESS_STATUS ||
              clubRegistrationState.objData.status === ClubRegistrationConstants.RENEWING_STATUS ?
              ClubRegistrationConstants.SAVE_AND_EXIT : ClubRegistrationConstants.EXIT}</PrimaryButton>&nbsp;
          <SecondaryButton type="button" onClick={() => onBackClicked()}>Back</SecondaryButton>
        </div>
      </div>
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={clubRegistrationState.isObjLoading} />
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.SAVING_MSG}
        displayPopUp={clubRegistrationState.isSaving} />
      <ValidationPopUpModal
        clubRegistrationState={clubRegistrationState}
        state={state}
        modalState={modalState}
        onModalContinue={onModalContinue}
        onModalCanceled={onModalCanceled}
      />
    </LeftNav>
  );
};

export default ClubRegistrationLegalEntities;