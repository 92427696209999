import { Route, Routes } from "react-router-dom";
import { Navigate } from "../../../common/wrappers/ReactRouterDom";

import LearnToSwimRegistrationEntry from "./learnToSwimRegistrationEntry/LearnToSwimRegistrationEntry";
import LearnToSwimRegistrationProviderInfo from "./learnToSwimRegistrationProviderInfo/LearnToSwimRegistrationProviderInfo";
import LearnToSwimRegistrationFacilities from "./learnToSwimRegistrationFacilities/LearnToSwimRegistrationFacilities";
import LearnToSwimRegistrationFacilitiesSearch from "./learnToSwimRegistrationFacilities/LearnToSwimRegistrationFacilitiesSearch";
import LearnToSwimRegistrationFacilitiesAdd from "./learnToSwimRegistrationFacilities/LearnToSwimRegistrationFacilitiesAdd";
import LearnToSwimRegistrationFacilitiesDetail from "./learnToSwimRegistrationFacilities/LearnToSwimRegistrationFacilitiesDetail";
import LearnToSwimRegistrationSubmit from "./learnToSwimRegistrationSubmit/LearnToSwimRegistrationSubmit";

import LearnToSwimRegistrationContextProvider from "../../state/learnToSwimRegistration/LearnToSwimRegistrationContextProvider";
import LearnToSwimClubsContextProvider from "../../state/learnToSwimClubs/LearnToSwimClubsContextProvider";
import LearnToSwimCurriculumContextProvider from "../../state/learnToSwimCurriculum/LearnToSwimCurriculumContextProvider";
import LearnToSwimRegistrationLeftNavContextProvider from "../../state/learnToSwimRegistrationLeftNav/LearnToSwimRegistrationLeftNavContextProvider";

import LoadingModal from "../../../common/components/dialogs/LoadingModal";
import SecuredRouteElement from "../../../common/components/security/SecuredRouteElement";

import useNavRoutes from "../../../common/state/security/UseNavRoutes";
import LeftNavModalContextProvider from "../../../common/state/leftNavModal/LeftNavModalContextProvider";
import FacilityContextProvider from "../../../common/state/facility/FacilityContextProvider";
import FacilityFiltersContextProvider from "../../../common/state/facility/FacilityFiltersContextProvider";
import SearchFacilityContextProvider from "../../../common/state/searchFacility/SearchFacilityContextProvider";

const LearnToSwimContextRoutesContextProviders = ({ children }) => (
	<LearnToSwimCurriculumContextProvider>
		<LearnToSwimClubsContextProvider>
			<FacilityContextProvider>
				<FacilityFiltersContextProvider>
					<SearchFacilityContextProvider>
						<LeftNavModalContextProvider>
							<LearnToSwimRegistrationLeftNavContextProvider>
								{children}
							</LearnToSwimRegistrationLeftNavContextProvider>
						</LeftNavModalContextProvider>
					</SearchFacilityContextProvider>
				</FacilityFiltersContextProvider>
			</FacilityContextProvider>
		</LearnToSwimClubsContextProvider>
	</LearnToSwimCurriculumContextProvider>
);

const LearnToSwimBasicRoutesContextProviders = ({ children }) => (
	<LearnToSwimRegistrationContextProvider>
		{children}
	</LearnToSwimRegistrationContextProvider>
);

const LearnToSwimContextRoutes = ({ navRoutes }) => (
	<LearnToSwimContextRoutesContextProviders>
		<Routes>
			<Route path={navRoutes.LEARN_TO_SWIM_REGISTRATION_PROVIDER_INFO?.path}
				element={
					<SecuredRouteElement
						navRoute={navRoutes.LEARN_TO_SWIM_REGISTRATION_PROVIDER_INFO}
						editElement={<LearnToSwimRegistrationProviderInfo />} />
				} />
			<Route path={navRoutes.LEARN_TO_SWIM_REGISTRATION_FACILITIES?.path}
				element={
					<SecuredRouteElement
						navRoute={navRoutes.LEARN_TO_SWIM_REGISTRATION_FACILITIES}
						editElement={<LearnToSwimRegistrationFacilities />} />
				} />
			<Route path={navRoutes.LEARN_TO_SWIM_REGISTRATION_FACILITIES_SEARCH?.path}
				element={
					<SecuredRouteElement
						navRoute={navRoutes.LEARN_TO_SWIM_REGISTRATION_FACILITIES_SEARCH}
						editElement={<LearnToSwimRegistrationFacilitiesSearch />} />
				} />
			<Route path={navRoutes.LEARN_TO_SWIM_REGISTRATION_FACILITIES_ADD?.path}
				element={
					<SecuredRouteElement
						navRoute={navRoutes.LEARN_TO_SWIM_REGISTRATION_FACILITIES_ADD}
						editElement={<LearnToSwimRegistrationFacilitiesAdd />} />
				} />
			<Route path={navRoutes.LEARN_TO_SWIM_REGISTRATION_FACILITIES_DETAIL?.path}
				element={
					<SecuredRouteElement
						navRoute={navRoutes.LEARN_TO_SWIM_REGISTRATION_FACILITIES_DETAIL}
						editElement={<LearnToSwimRegistrationFacilitiesDetail />} />
				} />
			<Route path={navRoutes.LEARN_TO_SWIM_REGISTRATION_SUBMIT?.path}
				element={
					<SecuredRouteElement
						navRoute={navRoutes.LEARN_TO_SWIM_REGISTRATION_SUBMIT}
						editElement={<LearnToSwimRegistrationSubmit />} />
				} />
		</Routes>
	</LearnToSwimContextRoutesContextProviders>
);

const LearnToSwimBasicRoutes = ({ navRoutes }) => (
	<LearnToSwimBasicRoutesContextProviders>
		<Routes>
			<Route path={navRoutes.LEARN_TO_SWIM_REGISTRATION_ENTRY?.path}
				element={
					<SecuredRouteElement
						navRoute={navRoutes.LEARN_TO_SWIM_REGISTRATION_ENTRY}
						editElement={<LearnToSwimRegistrationEntry />} />
				} />

			<Route path={'/*'} element={<LearnToSwimContextRoutes navRoutes={navRoutes} />} />
			<Route path={'/'} element={<Navigate to={navRoutes.LEARN_TO_SWIM_REGISTRATION_ENTRY?.path} replace />} />
		</Routes>
	</LearnToSwimBasicRoutesContextProviders>
);

const LearnToSwimRegistration = () => {
	const { navRoutes, isReadyToRoute } = useNavRoutes();

	if (isReadyToRoute !== true) {
		return <LoadingModal />;
	}

	return (
		<LearnToSwimBasicRoutes navRoutes={navRoutes} />
	);
};

export default LearnToSwimRegistration;