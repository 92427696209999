import { useContext } from 'react';

import { CategoryMeasureDropdownStateContext } from './CategoryMeasureDropdownContextProvider';

import CategoryMeasureDropdownData from './CategoryMeasureDropdownData';

const useCategoryMeasureDropdownData = () => {
  const [categoryMeasureDropdownState, setCategoryMeasureDropdownState] = useContext(CategoryMeasureDropdownStateContext);

  const getCategoryMeasureDropdown = (categoryMeasureId) => {
    CategoryMeasureDropdownData.getCategoryMeasureDropdownByCategoryMeasureId(categoryMeasureId, categoryMeasureDropdownState, setCategoryMeasureDropdownState);
  };

  return {
    categoryMeasureDropdownState,
    getCategoryMeasureDropdown
  };
};

export default useCategoryMeasureDropdownData;