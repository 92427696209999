import { Routes, Route } from 'react-router-dom';
import { Navigate } from '../../../common/wrappers/ReactRouterDom';

import ClubPerformanceContextView from './ClubPerformanceContextView';

import ClubPerformanceAthleteTimes from './clubPerformanceAthleteTimes/ClubPerformanceAthleteTimes';
import ClubPerformanceAthleteTimesDetail from './clubPerformanceAthleteTimes/clubPerformanceAthleteTimesDetail/ClubPerformanceAthleteTimesDetail';
import ClubPerformanceAthleteTimesMeetSummary from './clubPerformanceAthleteTimes/clubPerformanceAthleteTimesDetail/clubPerformanceAthleteTimesMeetSummary/ClubPerformanceAthleteTimesMeetSummary';
import ClubPerformanceAthleteTimesProgression from './clubPerformanceAthleteTimes/clubPerformanceAthleteTimesDetail/clubPerformanceAthleteTimesProgression/ClubPerformanceAthleteTimesProgression';
import ClubPerformanceAthleteTimesMeetResults from './clubPerformanceAthleteTimes/clubPerformanceAthleteTimesDetail/clubPerformanceAthleteTimesMeetResults/ClubPerformanceAthleteTimesMeetResults';

import ClubPerformanceContextProvider from '../../state/clubPerformance/ClubPerformanceContextProvider';

import SecuredRouteElement from '../../../common/components/security/SecuredRouteElement';

const ContextRoutes = ({ navRoutes }) => (
  <ClubPerformanceContextView>
    <Routes>
      <Route path={navRoutes.CLUB_PERFORMANCE_ATHLETE_TIMES?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes.CLUB_PERFORMANCE_ATHLETE_TIMES}
            editElement={<ClubPerformanceAthleteTimes />} />
        } />
      <Route path={navRoutes.CLUB_PERFORMANCE_ATHLETE_TIMES_DETAIL?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes.CLUB_PERFORMANCE_ATHLETE_TIMES_DETAIL}
            editElement={<ClubPerformanceAthleteTimesDetail />} />
        } />
      <Route path={navRoutes.CLUB_PERFORMANCE_ATHLETE_TIMES_DETAIL_PROGRESSION?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes.CLUB_PERFORMANCE_ATHLETE_TIMES_DETAIL_PROGRESSION}
            editElement={<ClubPerformanceAthleteTimesProgression />} />
        } />
      <Route path={navRoutes.CLUB_PERFORMANCE_ATHLETE_TIMES_DETAIL_MEET_SUMMARY?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes.CLUB_PERFORMANCE_ATHLETE_TIMES_DETAIL_MEET_SUMMARY}
            editElement={<ClubPerformanceAthleteTimesMeetSummary />} />
        } />
      <Route path={navRoutes.CLUB_PERFORMANCE_ATHLETE_TIMES_DETAIL_MEET_RESULTS?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes.CLUB_PERFORMANCE_ATHLETE_TIMES_DETAIL_MEET_RESULTS}
            editElement={<ClubPerformanceAthleteTimesMeetResults />} />
        } />

      <Route path={'/'} element={<Navigate to={navRoutes.CLUB_PERFORMANCE_ATHLETE_TIMES?.route} replace />} />
    </Routes>
  </ClubPerformanceContextView>
);

const ClubPerformanceContextRoutes = ({ navRoutes }) => (
  <ClubPerformanceContextProvider>
    <ContextRoutes navRoutes={navRoutes} />
  </ClubPerformanceContextProvider>
);

export default ClubPerformanceContextRoutes;