import React, { Fragment } from 'react';

import useAddFacility from './UseAddFacility';

import FacilityDupCheckContent from './components/FacilityDupCheckContent';
import FacilityForm from './components/FacilityForm';
import AddressValidation from '../../../addressValidation/AddressValidation';

import Headings from '../../../headings/Headings';
import PrimaryButton from '../../../buttons/PrimaryButton';
import SecondaryButton from '../../../buttons/SecondaryButton';
import PopUpModal from '../../../dialogs/PopUpModal';
import Constants from '../../../../utils/Constants';

const AddFacility = ({ saveUrl, cancelUrl, orgUnitId }) => {
  const {
    formState,
    errorState,
    dupsState,
    clubAffiliationTypeState,
    facilityState,
    onFormValueChanged,
    onCancelClicked,
    onAddDupFacilityClicked,
    onCancelDupFacilityClicked,
    onValueTextPairChanged,
    handleSubmitProxy,
    setFormData,
    validateState,
    contextSecurityState
  } = useAddFacility(saveUrl, cancelUrl, orgUnitId);

  return (
    <Fragment>
      <form onSubmit={handleSubmitProxy} noValidate>
        <div className="row">
          <div className="col-xs-12">
            <Headings.H3>Add Facility</Headings.H3>
          </div >
        </div >
        <FacilityForm
          formState={formState}
          errorState={errorState}
          onFormValueChanged={onFormValueChanged}
          onValueTextPairChanged={onValueTextPairChanged} />
        {formState.isInternational === false
          ? <AddressValidation formState={formState} setFormData={setFormData} />
          : <Fragment />}
        <div className="row usas-extra-top-margin usas-extra-bottom-margin">
          <div className="col-xs-12 usas-extra-top-margin">
            <PrimaryButton type="submit">Save</PrimaryButton>&nbsp;
            <SecondaryButton type="button" onClick={onCancelClicked}>Back</SecondaryButton>
          </div>
        </div>
      </form >
      {validateState.isValidating === true && (
        <PopUpModal
          displayPopUp={true}
          maxWidth={250}
          title="Validating..."
          widthPct={50} />
      )}
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={clubAffiliationTypeState.isArrayLoading || dupsState.isArrayLoading || contextSecurityState.isObjLoading} />
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.SAVING_MSG}
        displayPopUp={facilityState.isSaving} />
      <PopUpModal
        title="Facility Duplicate Check"
        displayPopUp={dupsState.arrayData.length > 0}>
        <FacilityDupCheckContent
          formState={formState}
          dupsState={dupsState}
          onAddDupFacilityClicked={onAddDupFacilityClicked}
          onCancelDupFacilityClicked={onCancelDupFacilityClicked} />
      </PopUpModal>
    </Fragment >
  );
};

export default AddFacility;