import { useState } from 'react';
import LearnToSwimParticipantsData from './LearnToSwimParticipantsData';

const useLearnToSwimRegistrationData = () => {
  const [ ltsParticipantState, setLTSParticipantState ] = useState(LearnToSwimParticipantsData.INITIAL_STATE);
  const [ ltsParticipantDupState, setLTSParticipantDupState ] = useState(LearnToSwimParticipantsData.INITIAL_STATE);

  const postLTSParticipant = (participantObj) => {
    return LearnToSwimParticipantsData.postLTSParticipantData(participantObj, ltsParticipantState, setLTSParticipantState);
  };

  const getLTSParticipantDup = (firstName, lastName, birthDate) => {
    return LearnToSwimParticipantsData.getLTSParticipantDupData(firstName, lastName, birthDate, ltsParticipantDupState, setLTSParticipantDupState);
  }

  return {
    ltsParticipantState,
    ltsParticipantDupState,
    postLTSParticipant,
    getLTSParticipantDup
  }
}

export default useLearnToSwimRegistrationData;
