import NavLinks from '../NavLinks';

import useSwimAThonData from '../../../state/swimAThon/UseSwimAThonData';

import useEnvironmentVariableData from '../../../../common/state/environmentVariable/UseEnvironmentVariableData';
import usePersonOrgRoleData from '../../../../common/state/personOrgRole/UsePersonOrgRoleData';

const TAXONOMIES = ['SwimAThonDetail'];
const SCOPE = 'ClubSwimAThon';

const useOrgUnitSelection = () => {
  const { SWIMATHON_ROLE_GROUP_ID } = useEnvironmentVariableData();
  const { personOrgRoleState } = usePersonOrgRoleData();
  const { getSAT } = useSwimAThonData();

  const getSATBySelection = (orgUnitId) => {
    const orgUnitName = personOrgRoleState.arrayData.find(x => x.orgUnitId === orgUnitId)?.orgUnitName;

    getSAT(orgUnitId, orgUnitName, 'SATSelection');
  };

  return {
    NavLinks, 
    getSATBySelection,
    TAXONOMIES,
    SCOPE,
    SWIMATHON_ROLE_GROUP_ID
  };
};

export default useOrgUnitSelection;