import Constants from "../../../../../common/utils/Constants";

/**
 * @param {object} baseGroupObjData 
 * @param {object} formState orgUnitId, groupTypeId, groupCategories, groupName, groupCode, groupDescription
 * @returns {object} group object
 */
const createGroupObjFromGroupInfoForm = (baseGroupObjData, formState) => {
  return {
    ...baseGroupObjData,
    orgUnitId: formState.orgUnitId,
    groupTypeId: formState.groupTypeId,
    groupCategoryId: formState.groupCategories[0].id,
    groupName: formState.groupName.trim(),
    groupCode: formState.groupCode.trim(),
    groupDescription: formState.groupDescription.trim(),
    groupType: undefined,
    groupCategory: undefined
  };
};

/**
 * @param {Array} orgGroupFields objects in format {fieldName, fieldValue}
 * @param {Array} orgGroupProperties 
 * @param {Number} orgGroupId optional
 * @returns {Array} org group field values
 */
const createOrgGroupFieldValues = (orgGroupFields, orgGroupProperties, orgGroupId = undefined) => {
  const orgGroupFieldValue = [];

  for (const groupField of orgGroupFields) {
    const groupTypeField = orgGroupProperties.find(x => x.fieldName === groupField.fieldName);

    if (groupTypeField) {
      orgGroupFieldValue.push({
        groupTypeField,
        groupTypeFieldId: groupTypeField.groupTypeFieldId,
        fieldValue: groupField.fieldValue,
        orgGroupId
      });
    }
  }

  return orgGroupFieldValue;
};

const getBaseGroupData = () => {
  return {
    groupCategoryId: Constants.DEFAULT_ID,
    groupTypeId: Constants.DEFAULT_ID,
    orgUnitId: '',
    groupCode: '',
    groupDescription: '',
    groupName: '',
    isDeleted: false,
    orgGroupEligibility: [],
    orgGroupFieldValue: [],
    personOrgGroup: [],
    hasOrgRoleRestriction: null,
    hasRegistration: null,
    requiresApproval: null,
    registrationCloseDate: null,
    registrationOpenDate: null
  };
};

const addClubGroupCoaches = (groupCoaches, groupTypeFieldId, fieldValue) => {
  const newGroupMembers = [];

  for (const coach of groupCoaches) {
    newGroupMembers.push({
      personId: coach.id,
      personGroupTypeField: [
        {
          groupTypeFieldId,
          fieldValue,
          fieldOrderId: 1
        }
      ],
      personOrgGroupQualification: []
    });
  }

  return newGroupMembers;
};

const editClubGroupCoaches = (clubCoaches, groupCoaches, groupMembers, orgGroupId, groupTypeFieldId, fieldValue) => {
  const newGroupMembers = [];

  for (const coach of groupCoaches) {
    newGroupMembers.push({
      orgGroupId,
      personId: coach.id,
      personGroupTypeField: [
        {
          groupTypeFieldId,
          fieldValue,
          fieldOrderId: 1
        }
      ],
      personOrgGroupQualification: []
    });
  }

  // If member is not a coach, add them back into the group
  for (const member of groupMembers) {
    if (!clubCoaches.find(x => x.id === member.personId)) {
      newGroupMembers.push(member);
    }
  }

  return newGroupMembers;
};

const getClubGroupCoachIds = (clubCoaches, groupMembers) => {
  const coachIds = [];

  for (const coach of clubCoaches) {
    const groupCoach = groupMembers.find(x => x.personId === coach.id);

    if (groupCoach) {
      coachIds.push(groupCoach.personId);
    }
  }

  return coachIds;
};

const editClubGroupAthletes = (clubAthletes, groupAthletes, groupMembers, orgGroupId, groupTypeFieldId, fieldValue) => {
  const newGroupMembers = [];

  for (const athlete of groupAthletes) {
    newGroupMembers.push({
      orgGroupId,
      personId: athlete.personId,
      personGroupTypeField: groupTypeFieldId > 0 && fieldValue
        ? [{
          groupTypeFieldId,
          fieldValue,
          fieldOrderId: 1
        }]
        : [],
      personOrgGroupQualification: []
    });
  }

  // If member is not an athlete, add them back into the group
  for (const member of groupMembers) {
    if (!clubAthletes.find(x => x.personId === member.personId)) {
      newGroupMembers.push(member);
    }
  }

  return newGroupMembers;
};

export {
  createGroupObjFromGroupInfoForm, createOrgGroupFieldValues, getBaseGroupData, addClubGroupCoaches,
  editClubGroupCoaches, getClubGroupCoachIds, editClubGroupAthletes
};