import { useEffect } from 'react';

import NavLinks from '../NavLinks';

import useBaseUsasClubData from '../../../../common/state/clubBase/UseBaseUsasClubData';
import useEnvironmentVariableData from '../../../../common/state/environmentVariable/UseEnvironmentVariableData';
import useClubPersistentStorage from '../../../../common/state/clubPersistentStorage/UseClubPersistentStorage';

import Constants from '../../../../common/utils/Constants';

const TAXONOMIES = ['ClubDetail'];
const SCOPE = 'Club';

const useOrgUnitSelection = () => {
  const { setPersistentStorage, resetPersistentStorage } = useClubPersistentStorage();
  const { getBaseUsasClubByOrgUnitId } = useBaseUsasClubData();
  const { MY_CLUB_ROLE_GROUP_ID } = useEnvironmentVariableData();

  const getBaseUsasClub = (orgUnitId) => {
    setPersistentStorage(orgUnitId, Constants.UI_PROJECT_NAMES.CLUB, NavLinks.CLUB_MY_CLUB, 'Back to Selection');

    getBaseUsasClubByOrgUnitId(orgUnitId);
  };

  useEffect(() => {
    resetPersistentStorage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    NavLinks,
    getBaseUsasClubByOrgUnitId: getBaseUsasClub,
    TAXONOMIES,
    SCOPE,
    MY_CLUB_ROLE_GROUP_ID
  };
};

export default useOrgUnitSelection;