import React, { Fragment } from 'react';

import useClubFacilitiesPoolsDetail from './UseClubFacilitiesPoolsDetail';

import PopUpModal from '../../../../common/components/dialogs/PopUpModal';
import AddEditFacilityPool from '../../../../common/components/searches/searchFacility/addEditPool/AddEditFacilityPool';
import Constants from '../../../../common/utils/Constants';

import NavLinks from '../NavLinks';

const ClubFacilitiesPoolsDetail = () => {
  const {
    usasClubFacilityLinksState
  } = useClubFacilitiesPoolsDetail();

  return (
    <Fragment>
      <AddEditFacilityPool
        saveUrl={NavLinks.CLUB_FACILITIES}
        cancelUrl={NavLinks.CLUB_FACILITIES}
        showFacilityInfo={true} />
      <div className="row">
        <div className="col-xs-12 usas-extra-bottom-margin"></div>
      </div>
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.SAVING_MSG}
        displayPopUp={usasClubFacilityLinksState.isSaving} />
    </Fragment>
  );
};

export default ClubFacilitiesPoolsDetail;