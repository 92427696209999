const NavLinks = {
  CLUB_SWIMATHON_ROOT: '/clubswimathon',
  CLUB_SWIMATHON_SELECTION: '/clubswimathon/selection',
  CLUB_SWIMATHON_SEARCH: '/clubswimathon/search',
  CLUB_SWIMATHON_CONTRACTS: '/clubswimathon/contracts',
  CLUB_SWIMATHON_CONTRACTS_DETAIL: '/clubswimathon/contracts/detail',
  CLUB_SWIMATHON_RESULTS: '/clubswimathon/results',
  CLUB_SWIMATHON_RESULTS_DETAIL: '/clubswimathon/results/detail',
  CLUB_SWIMATHON_RESULTS_DETAIL_PRIZEWINNERS: '/clubswimathon/results/detail/prizewinners',
  CLUB_SWIMATHON_RESULTS_DETAIL_PRIZEWINNERS_DETAIL: '/clubswimathon/results/detail/prizewinners/detail',
  CLUB_SWIMATHON_RESULTS_PAYMENTS: '/clubswimathon/results/payments',
  CLUB_SWIMATHON_LEADERBOARD: '/clubswimathon/leaderboard'
};

export default NavLinks;