import Constants from '../../../../../common/utils/Constants';
import { isValidEmail, isValidUSOrCanadianPhoneNumber, isValidMexicanPhoneNumber, isValidName } from '../../../../../common/utils/validation';

const localValidate = (formState) => {
  let errors = {};

  if (formState.contactName.trim() !== '') {
    if (!isValidName(formState.contactName.trim())) {
      errors.contactName = 'Facility Primary Contact Name cannot contain numbers, special characters, or exceed 100 characters';
    }
  }

  if (formState.contactEmailAddress.trim() !== '') {
    if (!isValidEmail(formState.contactEmailAddress.trim())) {
      errors.contactEmailAddress = 'Facility Email must be in a valid format';
    } else if (formState.contactEmailAddress.trim().length > 512) {
      errors.contactEmailAddress = 'Facility Email cannot exceed 512 characters'
    }
  }

  if (formState.contactPhoneNumber.trim() !== '' && !Constants.PHONE_AREA_CODES.find(x => x === formState.contactPhoneNumber.trim())) {
    if (!isValidUSOrCanadianPhoneNumber(formState.contactPhoneNumber) && !isValidMexicanPhoneNumber(formState.contactPhoneNumber)) {
      errors.contactPhoneNumber = 'Please enter a valid US, Canadian, or Mexican phone number in +1/+52 (XXX) XXX-XXXX format'
    }
  }

  if (formState.clubFacilityContactName.trim() !== '') {
    if (!isValidName(formState.clubFacilityContactName.trim())) {
      errors.clubFacilityContactName = 'Learn To Swim Primary Contact Name for Facility cannot contain numbers, special characters, or exceed 100 characters';
    }
  }

  if (formState.clubFacilityContactEmailAddress.trim() !== '') {
    if (!isValidEmail(formState.clubFacilityContactEmailAddress.trim())) {
      errors.clubFacilityContactEmailAddress = 'Learn To Swim Primary Contact Email must be in a valid format';
    } else if (formState.clubFacilityContactEmailAddress.trim().length > 512) {
      errors.clubFacilityContactEmailAddress = 'Learn To Swim Primary Contact Email cannot exceed 512 characters'
    }
  }

  if (formState.clubFacilityContactPhoneNumber.trim() !== '' && !Constants.PHONE_AREA_CODES.find(x => x === formState.clubFacilityContactPhoneNumber.trim())) {
    if (!isValidUSOrCanadianPhoneNumber(formState.clubFacilityContactPhoneNumber)) {
      errors.clubFacilityContactPhoneNumber = 'Please enter a valid US phone number in +1 (XXX) XXX-XXXX format';
    }
  }

  return errors;
};

const LearnToSwimFacilityContactFormValidation = async (formState) => {
  let errors = localValidate(formState);

  return errors ? errors : {};
};

export default LearnToSwimFacilityContactFormValidation;