import { useEffect, useState } from 'react';
import { useNavigate } from '../../../../common/wrappers/ReactRouterDom';

import NavLinks from '../NavLinks';

import usePreCompLocationData from '../../../state/preCompLocation/UsePreCompLocationData';
import useFacilityData from '../../../../common/state/facility/UseFacilityData';
import useSearchFacility from '../../../../common/components/searches/searchFacility/UseSearchFacility';
import useTeamFacilityLinksData from '../../../../common/state/teamFacilityLinks/UseTeamsFacilityLinksData';

import Constants from '../../../../common/utils/Constants';

const usePreCompLocationFacilitiesLinkSearch = () => {
  const navigate = useNavigate();
  const { searchFacilityState, setSearchFacilityState, getFacility } = useSearchFacility();
  const { facilityState } = useFacilityData();
  const { teamFacilityLinksState, postTeamFacilityLinks, getTeamFacilityLinks, clearTeamFacilityLinksArrayData } = useTeamFacilityLinksData();
  const { preCompLocationState } = usePreCompLocationData();
  const [modalState, setModalState] = useState(getInitialModalState);
  const [state, setState] = useState({ facilityId: Constants.DEFAULT_ID });

  function createObjectForPost(facilityObjData) {
    return {
      orgUnitId: preCompLocationState.objData.orgUnitId,
      facilityId: facilityObjData.facilityId,
      effectiveDate: null,
      expirationDate: null,
      facility: undefined,
      clubFacilityContactName: null,
      clubFacilityContactEmailAddress: null,
      clubFacilityContactPhoneNumber: null,
      facilityContactName: facilityObjData.contactName,
      facilityContactEmailAddress: facilityObjData.contactEmailAddress,
      facilityContactPhoneNumber: facilityObjData.contactPhoneNumber
    };
  }

  const onLinkFacility = (e, facilityId) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    let dupFacility = null;

    for (const preCompfacility of teamFacilityLinksState.arrayData) {
      if (preCompfacility.facilityId === facilityId) {
        dupFacility = preCompfacility.facility;
        break;
      }
    }

    if (dupFacility === null) {
      setState({ ...state, facilityId: facilityId });
      getFacility(facilityId);
      setModalState(getInitialModalState);
    } else {
      setModalState({
        ...modalState,
        displayModal: true,
        facilityName: dupFacility.facility?.facilityName || ''
      });
    }
  };

  const onCancelClicked = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    navigate(NavLinks.PRE_COMP_LOCATION_FACILITY);
  };

  const onModalCancel = () => {
    setModalState(getInitialModalState);
  };

  const onAddANewFacility = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    navigate(NavLinks.PRE_COMP_LOCATION_FACILITY_ADD);
  };

  useEffect(() => {
    clearTeamFacilityLinksArrayData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (facilityState.isObjLoaded === true && state.facilityId === facilityState.objData.facilityId) {
      postTeamFacilityLinks(preCompLocationState.objData.orgUnitId, createObjectForPost(facilityState.objData));
      setSearchFacilityState({ ...searchFacilityState, tryRedirect: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [facilityState]);

  useEffect(() => {
    //Wait for add to complete before getting updated precomp & redirecting
    if (teamFacilityLinksState.isSaving === false && searchFacilityState.tryRedirect === true && state.facilityId > 0) {
      getTeamFacilityLinks(preCompLocationState.objData.orgUnitId);
      setSearchFacilityState({ ...searchFacilityState, tryRedirect: false });
      navigate(NavLinks.PRE_COMP_LOCATION_FACILITY_DETAIL, { state: { facilityId: state.facilityId } });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teamFacilityLinksState, searchFacilityState, state]);

  function getInitialModalState() {
    return {
      displayModal: false,
      facilityName: '',
    };
  };

  return {
    isSaving: preCompLocationState.isSaving || facilityState.isSaving,
    isLoading: preCompLocationState.isObjLoading,
    preCompLocationState,
    modalState,
    onLinkFacility,
    onCancelClicked,
    onModalCancel,
    onAddANewFacility
  };
};

export default usePreCompLocationFacilitiesLinkSearch;