import React, { Fragment } from 'react';

import HideIcon from '../../../../../common/components/icons/HideIcon';
import ShowIcon from '../../../../../common/components/icons/ShowIcon';
import EditIcon from '../../../../../common/components/icons/EditIcon';
import DeleteIcon from '../../../../../common/components/icons/DeleteIcon';
import { formatPhoneNumber } from '../../../../../common/utils/validation';

import global from '../../../../../common/components/GlobalStyle.module.css';

const DetailTableRow = ({ personId, role, onDelete, canDeletePrimaryContact, PRIMARY_CONTACT_ORG_ROLE_ID }) => (
  <tr>
    <td colSpan="3">{role.orgRoleName}</td>
    <td>
      {onDelete !== undefined && !(role.orgRoleId === PRIMARY_CONTACT_ORG_ROLE_ID && canDeletePrimaryContact === false) &&
        <button
          className={global.IconButtonMargin}
          type="button"
          onClick={() => onDelete(personId, role.personOrgRoleDurationId || role.orgRoleId)}>
          <DeleteIcon />
        </button>
      }
    </td>
  </tr>
);

const DetailTable = ({ personId, personOrgRole, onDelete, canDeletePrimaryContact, PRIMARY_CONTACT_ORG_ROLE_ID }) => (
  <table className={global.DetailTable}>
    <thead>
      <tr>
        <th colSpan="3">Role</th>
        <th colSpan="3"></th>
      </tr>
    </thead>
    <tbody>
      {Array.isArray(personOrgRole) && personOrgRole.map((role, i) =>
        <DetailTableRow key={i} personId={personId} role={role} onDelete={onDelete}
          canDeletePrimaryContact={canDeletePrimaryContact} PRIMARY_CONTACT_ORG_ROLE_ID={PRIMARY_CONTACT_ORG_ROLE_ID} />)}
    </tbody>
  </table>
);

const GridRow = ({ staff, onEdit, onDelete, canDeletePrimaryContact, expandedId, onClick, PRIMARY_CONTACT_ORG_ROLE_ID }) => {
  return (
    <Fragment>
      <tr>
        <td>{`${staff.firstName} ${staff.lastName}`}</td>
        <td>{staff.phoneNumber ? formatPhoneNumber(staff.phoneNumber) : ''}</td>
        <td>{staff.email || ''}</td>
        <td>
          {onEdit !== undefined &&
            <button
              className={global.IconButtonMargin}
              type="button"
              onClick={() => onEdit(staff)}>
              <EditIcon />
            </button>
          }
          <button
            className={global.IconButtonMargin}
            type="button"
            onClick={(e) => onClick(e, staff.personId)}>
            {expandedId === staff.personId ? <HideIcon /> : <ShowIcon />}
          </button>
        </td>
      </tr>
      {Array.isArray(staff.personOrgRole) && staff.personOrgRole.length > 0 && expandedId === staff.personId &&
        <tr className={global.Expanded}>
          <td colSpan="4">
            <DetailTable personId={staff.personId} personOrgRole={staff.personOrgRole} onDelete={onDelete}
              canDeletePrimaryContact={canDeletePrimaryContact} PRIMARY_CONTACT_ORG_ROLE_ID={PRIMARY_CONTACT_ORG_ROLE_ID} />
          </td>
        </tr>}
    </Fragment>
  );
};

const PreCompLocationStaffGridLarge = ({ data, isLoading, onEdit, onDelete, canDeletePrimaryContact, expandedId, onClick, PRIMARY_CONTACT_ORG_ROLE_ID }) => (
  <table className={[global.UsasTable, 'visible-md visible-lg'].join(' ')}>
    <thead>
      <tr>
        <th>Staff Name</th>
        <th>Phone Number</th>
        <th>Email</th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      {isLoading === true
        ? <tr><td colSpan="4">Loading...</td></tr>
        : data.length > 0
          ? data.map((staff, i) => <GridRow key={i} staff={staff} onEdit={onEdit} onDelete={onDelete}
            canDeletePrimaryContact={canDeletePrimaryContact} expandedId={expandedId} onClick={onClick} PRIMARY_CONTACT_ORG_ROLE_ID={PRIMARY_CONTACT_ORG_ROLE_ID} />)
          : <tr><td colSpan="4">No Staff</td></tr>
      }
    </tbody>
  </table>
);

export default PreCompLocationStaffGridLarge;