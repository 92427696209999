import { useEffect, useState } from 'react';
import { useNavigate } from '../../../../common/wrappers/ReactRouterDom';

import usePreCompLocationRegistrationData from '../../../state/preCompLocationRegistration/UsePreCompLocationRegistrationData';
import usePreCompLocationRegistrationNavData from '../../../state/preCompLocationRegistration/preCompLocationRegistrationLeftNavBar/UsePreCompLocationRegistrationNavData';

import useEnvironmentVariableData from '../../../../common/state/environmentVariable/UseEnvironmentVariableData';

import NavLinks from '../NavLinks';

import PreCompRegistrationConstants from '../utils/PreCompRegistrationConstants';

const PRIMARY_CONTACT_REQUIRED_ERROR_MSG = 'Must add at least one staff member with the Primary Contact role';

const usePreCompRegistrationLocationStaff = () => {
  const navigate = useNavigate();
  const { preCompLocationRegistrationState, confirmSave, deleteStaffRoleData } = usePreCompLocationRegistrationData();
  const { locationRegistrationNavState, updateCurrentNavState } = usePreCompLocationRegistrationNavData();
  const { PRIMARY_CONTACT_ORG_ROLE_ID } = useEnvironmentVariableData();
  const [gridState, setGridState] = useState([]);
  const [pageErrorMessageState, setPageErrorMessageState] = useState('');
  const [deleteModalState, setDeleteModalState] = useState(getInitialDeleteModalState);

  const onContinueClicked = () => {
    if (isStaffDataValid(preCompLocationRegistrationState.objData?.preCompetitiveLocationStaff || [])) {
      navigate(NavLinks.PRE_COMP_REGISTRATION_LOCATION_INSTRUCTOR_TRAINING);
    } else {
      setPageErrorMessageState(PRIMARY_CONTACT_REQUIRED_ERROR_MSG);
    }
  };

  const onBackClicked = () => {
    if (isStaffDataValid(preCompLocationRegistrationState.objData?.preCompetitiveLocationStaff || [])) {
      navigate(NavLinks.PRE_COMP_REGISTRATION_LOCATION_PROGRAMS);
    } else {
      setPageErrorMessageState(PRIMARY_CONTACT_REQUIRED_ERROR_MSG);
    }
  };

  const onAddStaff = () => {
    navigate(NavLinks.PRE_COMP_REGISTRATION_LOCATION_STAFF_DETAIL);
  };

  const onEditStaff = (staffObj) => {
    navigate(NavLinks.PRE_COMP_REGISTRATION_LOCATION_STAFF_DETAIL, { state: { staffObj } });
  };

  const onDeleteRole = (personId, orgRoleId) => {
    const staff = preCompLocationRegistrationState.objData.preCompetitiveLocationStaff;
    const targetIndex = staff.findIndex(x => x.personId === personId);

    if (targetIndex >= 0) {
      const newOrgRolesId = staff[targetIndex].personOrgRole.filter(x => x.orgRoleId !== orgRoleId);

      if (newOrgRolesId.length === 0) {
        setDeleteModalState({
          ...deleteModalState,
          personId: personId,
          orgRoleId: orgRoleId,
          personName: `${staff[targetIndex].firstName} ${staff[targetIndex].lastName}`,
          displayModal: true
        });
      } else {
        deleteStaffRoleData(personId, orgRoleId);
      }
    }
  };

  const onModalDeleteClicked = () => {
    deleteStaffRoleData(deleteModalState.personId, deleteModalState.orgRoleId);
    setDeleteModalState(getInitialDeleteModalState);
  };

  const onModalCancelClicked = () => {
    setDeleteModalState(getInitialDeleteModalState);
  };

  useEffect(() => {
    updateCurrentNavState(PreCompRegistrationConstants.LOC_STAFF, isStaffDataValid(preCompLocationRegistrationState.objData?.preCompetitiveLocationStaff || []));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (preCompLocationRegistrationState.isObjLoaded === true && preCompLocationRegistrationState.isSaved === false) {
      setGridState(preCompLocationRegistrationState.objData.preCompetitiveLocationStaff || []);
    } else if (preCompLocationRegistrationState.isObjLoaded === false) {
      navigate(NavLinks.PRE_COMP_REGISTRATION_ENTRY);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [preCompLocationRegistrationState.isObjLoaded, preCompLocationRegistrationState.isSaved]);

  useEffect(() => {
    if (preCompLocationRegistrationState.isSaved === true) {
      confirmSave();

      setPageErrorMessageState('');
      updateCurrentNavState(PreCompRegistrationConstants.LOC_STAFF, isStaffDataValid(preCompLocationRegistrationState.objData?.preCompetitiveLocationStaff || []));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [preCompLocationRegistrationState.isSaved]);

  function isStaffDataValid(staffData) {
    let primaryMatchCount = 0;
    for (const staff of staffData) {
      if (Array.isArray(staff.personOrgRole) && staff.personOrgRole.findIndex(x =>
        x.orgRoleId === PRIMARY_CONTACT_ORG_ROLE_ID) !== -1) {
        primaryMatchCount += 1;
      }
    }

    if (primaryMatchCount === 1) {
      return true;
    } else {
      return false;
    }
  }

  function getInitialDeleteModalState() {
    return {
      personId: '',
      orgRoleId: '',
      personName: '',
      displayModal: false
    };
  };

  return {
    locationRegistrationNavState,
    gridState,
    pageErrorMessageState,
    deleteModalState,
    onAddStaff,
    onEditStaff,
    onDeleteRole,
    onBackClicked,
    onContinueClicked,
    onModalDeleteClicked,
    onModalCancelClicked,
    PRIMARY_CONTACT_ORG_ROLE_ID
  };
};

export default usePreCompRegistrationLocationStaff;