import React, { Fragment } from 'react';

import global from '../../../../GlobalStyle.module.css';

const LargeGridRow = ({ facility }) => {
  return (
    <tr>
      <td>{facility.facilityName}</td>
      <td>{facility.address1} {facility.address2}</td>
      <td>{facility.city}</td>
      <td>{facility.stateCode}</td>
      <td>{facility.postalCode}</td>
    </tr>
  )
};

const LargeGrid = ({ state }) => (
  <table className={[global.UsasTable, 'visible-lg'].join(' ')}>
    <thead>
      <tr>
        <th>Facility Name</th>
        <th>Address</th>
        <th>City</th>
        <th>State</th>
        <th>Zip Code</th>
      </tr>
    </thead>
    <tbody>
      {state.arrayData.map((facility, i) => <LargeGridRow facility={facility} key={i} />)}
    </tbody>
  </table>
);

const SmallGridRow = ({ facility }) => {
  return (
    <div className={[global.SmallTable, 'hidden-lg'].join(' ')}>
      <div className={global.SmallTableRowHead}><span className='hidden-xs'>{facility.facilityName || <span>&nbsp;</span>}</span>&nbsp;</div>
      <div className={global.SmallTableRowInfo}>
        <div className='row'>
          <div className={[global.SmallTableRowLabels, 'col-xs-6 visible-xs'].join(' ')}>Facility Name</div>
          <div className={[global.SmallTableRowData, 'col-xs-6 visible-xs'].join(' ')}>{facility.facilityName || <span>&nbsp;</span>}</div>
          <div className={[global.SmallTableRowLabels, 'col-xs-6'].join(' ')}>Address</div>
          <div className={[global.SmallTableRowData, 'col-xs-6'].join(' ')}>{facility.address1} {facility.address2}</div>
          <div className={[global.SmallTableRowLabels, 'col-xs-6'].join(' ')}>City</div>
          <div className={[global.SmallTableRowData, 'col-xs-6'].join(' ')}>{facility.city || <span>&nbsp;</span>}</div>
          <div className={[global.SmallTableRowLabels, 'col-xs-6'].join(' ')}>State</div>
          <div className={[global.SmallTableRowData, 'col-xs-6'].join(' ')}>{facility.stateCode || <span>&nbsp;</span>}</div>
          <div className={[global.SmallTableRowLabels, 'col-xs-6'].join(' ')}>Zip Code</div>
          <div className={[global.SmallTableRowData, 'col-xs-6'].join(' ')}>{facility.postalCode || <span>&nbsp;</span>}</div>
        </div>
      </div>
    </div>
  )
}

const SmallGrid = ({ state }) => (
  <div className={[global.SmallTable, 'hidden-lg'].join(' ')}>
    {Array.isArray(state.arrayData) && state.arrayData.length > 0
      ? state.arrayData.map((facility, i) => <SmallGridRow facility={facility} key={i} />)
      : <Fragment />}
  </div>
);

const DupFacilityGrid = ({ state }) => (
  <Fragment>
    <p className={global.Bold}>Database Matches on Address, City, State Abbreviation and Zip Code</p>
    <LargeGrid state={state} />
    <SmallGrid state={state} />
  </Fragment>
);

export default DupFacilityGrid;