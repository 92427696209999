import { useEffect, useState } from 'react';

import ClubConstants from '../../views/club/utils/ClubConstants';

import useEnvironmentVariableData from '../../../common/state/environmentVariable/UseEnvironmentVariableData';
import useOrgRoleStaffRoleHierarchyData from '../../../common/state/orgRoleStaffRoleHierarchy/UseOrgRoleStaffRoleHierarchyData';

const rolesToExclude = [
  ClubConstants.JUNIOR_COACH_ROLE_NAME,
  ClubConstants.PROVISIONAL_COACH_ROLE_NAME
];

const useOrgRoleStaffRoleTreeView = () => {
  const { environmentVariableState, CLUB_STAFF_ROLE_GROUP_ID } = useEnvironmentVariableData();
  const { orgRoleStaffRoleHierarchyState, getOrgRolesStaffRolesHierarchy } = useOrgRoleStaffRoleHierarchyData();
  const [optionsState, setOptionsState] = useState([]);

  useEffect(() => {
    if (environmentVariableState.isLoaded === true && orgRoleStaffRoleHierarchyState.roleGroupId !== CLUB_STAFF_ROLE_GROUP_ID
      && orgRoleStaffRoleHierarchyState.isArrayLoading === false) {
      getOrgRolesStaffRolesHierarchy(CLUB_STAFF_ROLE_GROUP_ID);
    } else if (orgRoleStaffRoleHierarchyState.roleGroupId === CLUB_STAFF_ROLE_GROUP_ID
      && orgRoleStaffRoleHierarchyState.isArrayLoaded === true) {
      // WILL NOT EXCLUDE ROLES THAT APPEAR IN CHILDREN
      const newOptions = [];

      for (const role of orgRoleStaffRoleHierarchyState.treeData) {
        if (!rolesToExclude.find(x => x === role.name)) {
          newOptions.push(role);
        }
      }

      setOptionsState(newOptions);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [environmentVariableState, orgRoleStaffRoleHierarchyState]);

  return {
    isLoading: orgRoleStaffRoleHierarchyState.isArrayLoading && optionsState.length > 0,
    message: '',
    optionsState
  };
};

export default useOrgRoleStaffRoleTreeView;