import { BASIC_INITIAL_STATE } from '../../../common/utils/HttpHelper';

import getThirdPartyInvoiceData from './GetThirdPartyInvoiceData';
import getThirdPartyInvoiceByThirdPartyClubRegistrationIdData from './GetThirdPartyInvoiceByThirdPartyClubRegistrationIdData';
import postThirdPartyInvoiceByThirdPartyClubRegistrationIdProcessData from './PostThirdPartyInvoiceByThirdPartyClubRegistrationIdProcessData';
import putThirdPartyInvoiceByThirdPartyClubRegistrationIdData from './PutThirdPartyInvoiceByThirdPartyClubRegistrationIdData';
import deleteThirdPartyInvoiceData from './DeleteThirdPartyInvoiceData';

const INITIAL_STATE = {
  ...BASIC_INITIAL_STATE
};

const UsasClubThirdPartyInvoicesData = {
  INITIAL_STATE, getThirdPartyInvoiceData, getThirdPartyInvoiceByThirdPartyClubRegistrationIdData,
  postThirdPartyInvoiceByThirdPartyClubRegistrationIdProcessData, putThirdPartyInvoiceByThirdPartyClubRegistrationIdData, deleteThirdPartyInvoiceData
};

export default UsasClubThirdPartyInvoicesData;