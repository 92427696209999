import React, { createContext, useState } from 'react';

import CategoryMeasureDropdownData from './CategoryMeasureDropdownData';

export const CategoryMeasureDropdownStateContext = createContext();

const CategoryMeasureDropdownContextProvider = ({ children }) => {
  const stateHook = useState(CategoryMeasureDropdownData.INITIAL_STATE);

  return (
    <CategoryMeasureDropdownStateContext.Provider value={stateHook}>
      {children}
    </CategoryMeasureDropdownStateContext.Provider>
  );
};

export default CategoryMeasureDropdownContextProvider;