import { useEffect, useState } from 'react';
import { useNavigate } from '../../../../common/wrappers/ReactRouterDom';

import NavLinks from '../NavLinks';

import useClubGroupsData from '../../../state/clubGroups/UseClubGroupsData';

import useBaseUsasClubData from '../../../../common/state/clubBase/UseBaseUsasClubData';
import useGroupFieldNamesData from '../../../../common/state/groupFieldNames/UseGroupFieldNamesData';

import Constants from '../../../../common/utils/Constants';

const useClubGroups = () => {
  const navigate = useNavigate();
  const { GROUP_CONSTANTS } = useGroupFieldNamesData();
  const { baseUsasClubState } = useBaseUsasClubData();
  const { clubGroupState, getClubGroups, deleteClubGroup } = useClubGroupsData();
  const [modalState, setModalState] = useState(getInitialModalState);

  const onAddRemoveMember = (e, clubGroupId) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    navigate(NavLinks.CLUB_GROUPS_MEMBER_DETAIL, { state: { clubGroupId } });
  };

  const onEditClubGroup = (e, clubGroupId, groupTypeName) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    switch (groupTypeName) {
      case GROUP_CONSTANTS.GROUP_TYPE_NAME_LIST:
        navigate(NavLinks.CLUB_GROUPS_LIST_EDIT, { state: { clubGroupId } });
        break;
      case GROUP_CONSTANTS.GROUP_TYPE_NAME_PRACTICE_GROUP:
        navigate(NavLinks.CLUB_GROUPS_PRACTICE_EDIT, { state: { clubGroupId } });
        break;
      case GROUP_CONSTANTS.GROUP_TYPE_NAME_TRAVEL_TRIP:
        navigate(NavLinks.CLUB_GROUPS_TRAVEL_EDIT, { state: { clubGroupId } });
        break;
      case GROUP_CONSTANTS.GROUP_TYPE_NAME_CLUB_SITE:
        navigate(NavLinks.CLUB_GROUPS_CLUB_SITE_EDIT, { state: { clubGroupId } });
        break;
      default:
        console.log('An error occurred');
    }
  };

  const onAddClubGroup = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    navigate(NavLinks.CLUB_GROUPS_GROUP_TYPE);
  };

  const onDeleteClubGroup = (e, clubGroupId, clubGroupName) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    setModalState({
      ...modalState,
      displayPopUp: true,
      groupId: clubGroupId,
      groupName: clubGroupName
    });
  };

  const onClickRemoveGroup = (e, clubGroupId) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    deleteClubGroup(clubGroupId);
    setModalState(getInitialModalState);
  };

  const onModalCanceled = () => {
    setModalState({
      ...modalState,
      displayPopUp: false
    });
  };

  useEffect(() => {
    if (baseUsasClubState.isObjLoaded === true) {
      getClubGroups(baseUsasClubState.objData.orgUnitId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [baseUsasClubState]);

  function getInitialModalState() {
    return {
      groupId: Constants.DEFAULT_ID,
      groupName: '',
      displayPopUp: false
    };
  };

  return {
    modalState,
    clubGroupState,
    onModalCanceled,
    onClickRemoveGroup,
    onEditClubGroup,
    onAddClubGroup,
    onDeleteClubGroup,
    onAddRemoveMember
  };
};

export default useClubGroups;