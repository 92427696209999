import { useEffect, useState } from "react";

import useClubManageOfferingsData from "../../../state/clubManageOfferings/UseClubManageOfferingsData";

import useReportPeriodSeasonData from "../../../../common/state/reportPeriodSeason/UseReportPeriodSeasonData";
import useBaseUsasClubData from "../../../../common/state/clubBase/UseBaseUsasClubData";
import useEnvironmentVariableData from "../../../../common/state/environmentVariable/UseEnvironmentVariableData";

import Constants from "../../../../common/utils/Constants";
import UseForm from "../../../../common/utils/UseForm";
import { formatDate } from "../../../../common/utils/DateFunctions";

const useClubManageOfferings = () => {
  const { environmentVariableState, USAS_ORGANIZATION_ID } = useEnvironmentVariableData();
  const { baseUsasClubState } = useBaseUsasClubData();
  const { reportPeriodSeasonState } = useReportPeriodSeasonData();
  const { clubManageOfferingsState, getClubOfferings, postOfferingInstanceExclusion, deleteOfferingInstanceExclusion
  } = useClubManageOfferingsData();
  const { formState, errorState, onValueTextPairChanged, setFormState
  } = UseForm(getInitialFormState, () => { }, () => { });
  const [gridState, setGridState] = useState([]);

  const onCheckboxClicked = (offeringInstance) => {
    if (offeringInstance.isOfferedByClub === true && offeringInstance.offeringInstanceId > 0
      && baseUsasClubState.objData?.orgUnitId) {
      postOfferingInstanceExclusion({
        offeringInstanceId: offeringInstance.offeringInstanceId, orgUnitId: baseUsasClubState.objData.orgUnitId
      });
    } else if (offeringInstance.isOfferedByClub === false && offeringInstance.offeringInstanceOrgUnitExclusionId > 0) {
      deleteOfferingInstanceExclusion(offeringInstance.offeringInstanceOrgUnitExclusionId)
    }
  };

  useEffect(() => {
    if (baseUsasClubState.isObjLoaded === true && clubManageOfferingsState.isSaving === false) {
      getClubOfferings(baseUsasClubState.objData.usasClubId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [baseUsasClubState, clubManageOfferingsState.isSaving]);

  useEffect(() => {
    if (formState.reportPeriodId > 0 && clubManageOfferingsState.isArrayLoading === false
      && clubManageOfferingsState.isArrayLoaded === true) {
      const newGridData = clubManageOfferingsState.arrayData.filter(x => x.reportPeriodId === formState.reportPeriodId);

      setGridState(newGridData);
    }
  }, [formState, clubManageOfferingsState.arrayData, clubManageOfferingsState.isArrayLoading, clubManageOfferingsState.isArrayLoaded]);

  useEffect(() => {
    if (reportPeriodSeasonState.isArrayLoading === false && reportPeriodSeasonState.isArrayLoaded === true) {
      const currentReportPeriods = [];
      const futureReportPeriods = [];
      const today = new Date(formatDate(new Date()));

      for (const reportPeriod of reportPeriodSeasonState.arrayData) {
        const startDate = new Date(formatDate(reportPeriod.startDate));
        const endDate = new Date(formatDate(reportPeriod.endDate));

        if (today <= endDate && today >= startDate) {
          currentReportPeriods.push(reportPeriod);
        } else if (today < startDate) {
          futureReportPeriods.push(reportPeriod);
        }
      }

      if (currentReportPeriods.length > 0) {
        const currentOption = currentReportPeriods[0];
        setFormState({ ...formState, reportPeriodId: currentOption.reportPeriodId, reportPeriodLabel: currentOption.periodName });
      } else if (futureReportPeriods.length > 0) {
        const futureOption = futureReportPeriods[0];
        setFormState({ ...formState, reportPeriodId: futureOption.reportPeriodId, reportPeriodLabel: futureOption.periodName });
      } else if (reportPeriodSeasonState.arrayData.length > 0) {
        const currentOption = reportPeriodSeasonState.arrayData[reportPeriodSeasonState.arrayData.length - 1];
        setFormState({ ...formState, reportPeriodId: currentOption.reportPeriodId, reportPeriodLabel: currentOption.periodName });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps   
  }, [reportPeriodSeasonState]);

  function getInitialFormState() {
    return {
      reportPeriodId: Constants.DEFAULT_ID,
      reportPeriodLabel: ''
    };
  };

  return {
    isLoading: clubManageOfferingsState.isArrayLoading || clubManageOfferingsState.isObjLoading || environmentVariableState.isLoading,
    isSaving: clubManageOfferingsState.isSaving,
    isGridLoading: clubManageOfferingsState.isArrayLoading,
    formState,
    errorState,
    gridState,
    onValueTextPairChanged,
    onCheckboxClicked,
    USAS_ORGANIZATION_ID: USAS_ORGANIZATION_ID || Constants.DEFAULT_ID
  };
};

export default useClubManageOfferings;