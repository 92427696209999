import React, { createContext, useState } from 'react';

export const PreCompLocationRegistrationNavStateContext = createContext();

const PreCompLocationRegistrationNavContextProvider = ({ children }) => {
  const stateHook = useState([]);

  return (
    <PreCompLocationRegistrationNavStateContext.Provider value={stateHook}>
      {children}
    </PreCompLocationRegistrationNavStateContext.Provider>
  );
};

export default PreCompLocationRegistrationNavContextProvider;