import { useState, useEffect } from 'react';
import { useNavigate } from '../../../../common/wrappers/ReactRouterDom';

import validate from './ClubRegistrationDisclaimerValidation';

import NavLinks from '../NavLinks';
import ClubRegistrationConstants from '../utils/ClubRegistrationConstants';

import useClubRegistrationNavData from '../../../state/clubRegistration/leftNavigation/UseClubRegistrationNavData';
import useClubRegistrationNewData from '../../../state/clubRegistrationNew/UseClubRegistrationNewData';
import useClubRegistrationPaymentData from '../../../state/clubRegistration/payment/UseClubRegistrationPaymentData';
import useClubOwnerTypeData from '../../../state/clubOwnerType/UseClubOwnerTypeData';
import useBusinessEntityData from '../../../state/businessEntity/UseBusinessEntityData';

import useEnvironmentVariableData from '../../../../common/state/environmentVariable/UseEnvironmentVariableData';
import useForm from '../../../../common/utils/UseForm';
import Constants from '../../../../common/utils/Constants';

const useClubRegistrationDisclaimer = () => {
  const navigate = useNavigate();
  const { clubRegistrationNavState, setClubRegistrationNavState, resetClubRegistrationNavState } = useClubRegistrationNavData();
  const { clubRegistrationState, setClubRegistrationState, putUsasClubAndHelperObj, clearObjData } = useClubRegistrationNewData();
  const { clearClubRegistrationPaymentState } = useClubRegistrationPaymentData();
  const { clubOwnerTypeState } = useClubOwnerTypeData();
  const { businessEntityState } = useBusinessEntityData();
  const { environmentVariableState, BOARD_OWNED_ORG_LEVEL_TYPE_ID } = useEnvironmentVariableData();
  const [state, setState] = useState({ showNumOfBoardMembers: false, isInitialPageLoad: true, tryRedirect: false });
  const { handleSubmit, updateFormState, formState, setFormState, errorState, onValueTextPairChanged, setIsDirty
  } = useForm(getFormInitialState(), submitFormCallback, validate);

  function submitFormCallback() {
    let clubCopy = JSON.parse(JSON.stringify(clubRegistrationState.objData));
    //Initial Add
    if (Object.keys(clubCopy).length < 3) {
      setClubRegistrationState({
        ...clubRegistrationState,
        objData: {
          ...clubRegistrationState.objData,
          clubOwnerTypeId: formState.clubOwnerTypeId,
          businessEntityTypeId: formState.businessEntityTypeId,
        },
        helperObj: {
          ...clubRegistrationState.helperObj,
          ownerTypeName: formState.ownerTypeName,
          businessEntityTypeName: formState.businessEntityTypeName,
          numOfBoardMembers: formState.numOfBoardMembers
        }
      });
      onContinueClicked();
    }
    //Edit (on returning to page)
    else if (Object.keys(clubCopy).length > 2) {
      if (clubRegistrationState.objData.status === ClubRegistrationConstants.IN_PROGRESS_STATUS
        || clubRegistrationState.objData.status === ClubRegistrationConstants.RENEWING_STATUS) {
        //set staff nav state to incomplete on changes to ownerType
        const selectedClubOwnerType = clubOwnerTypeState.data.find(e => e.clubOwnerTypeId === formState.clubOwnerTypeId);
        if (selectedClubOwnerType.orgLevelType?.orgLevelTypeId !== clubRegistrationState.objData.ownerType?.orgLevelTypeId) {
          const navIndex = clubRegistrationNavState.findIndex(x => x.name === ClubRegistrationConstants.CLUB_STAFF)
          clubRegistrationNavState[navIndex] =
          {
            ...clubRegistrationNavState[navIndex],
            isComplete: false
          }
          setClubRegistrationNavState(clubRegistrationNavState);
        }

        //save any changes
        if (formState.clubOwnerTypeId !== clubRegistrationState.objData.clubOwnerTypeId ||
          formState.businessEntityTypeId !== clubRegistrationState.objData.businessEntityTypeId) {
          const helperObj = {
            ...clubRegistrationState.helperObj,
            ownerTypeName: formState.ownerTypeName,
            businessEntityTypeName: formState.businessEntityTypeName,
            numOfBoardMembers: formState.numOfBoardMembers
          }
          
          putUsasClubAndHelperObj(clubCopy.usasClubId, editClubObj(clubCopy), helperObj);
          setState({ ...state, tryRedirect: true });
        }
        else {
          onContinueClicked();
        }
      }
    }
  };

  const onContinueClicked = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    navigate(NavLinks.CLUB_REGISTRATION_INFO);
  }

  const onBackClicked = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    
    resetClubRegistrationNavState();
    clearObjData();
    clearClubRegistrationPaymentState();
    navigate(NavLinks.CLUB_REGISTRATION_ENTRY_NEW);
  }

  const editClubObj = (clubCopy) => {
    clubCopy = {
      ...clubRegistrationState.objData,
      businessEntityTypeId: formState.businessEntityTypeId,
      clubOwnerTypeId: formState.clubOwnerTypeId,
      businessEntityType: undefined,
      ownerType: undefined
    }
    return clubCopy;
  }

  useEffect(() => {
    //If navigating back to this page, set state to any previous data values
    if (Object.keys(clubRegistrationState.objData).length > 0) {
      setState({
        ...state,
        showNumOfBoardMembers: clubRegistrationState.helperObj.numOfBoardMembers === '' ? false : true
      });
      if (Object.keys(clubRegistrationState.helperObj).length > 0) {
        setFormState({
          ...formState,
          clubOwnerTypeId: clubRegistrationState.objData.clubOwnerTypeId || Constants.DEFAULT_ID,
          ownerTypeName: clubRegistrationState.helperObj.ownerTypeName || '',
          businessEntityTypeId: clubRegistrationState.objData.businessEntityTypeId || Constants.DEFAULT_ID,
          businessEntityTypeName: clubRegistrationState.helperObj.businessEntityTypeName || '',
          numOfBoardMembers: clubRegistrationState.helperObj.numOfBoardMembers || '',
          isNumOfBoardMembersRequired: clubRegistrationState.helperObj.numOfBoardMembers === '' ? false : true
        });
      }
      else {
        setFormState({
          ...formState,
          clubOwnerTypeId: clubRegistrationState.objData.clubOwnerTypeId || Constants.DEFAULT_ID,
          businessEntityTypeId: clubRegistrationState.objData.businessEntityTypeId || Constants.DEFAULT_ID
        });
      }
      setIsDirty(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (formState.clubOwnerTypeId === clubRegistrationState.objData.clubOwnerTypeId &&
      clubOwnerTypeState.isLoaded === true && clubOwnerTypeState.isLoading === false &&
      businessEntityState.isArrayLoading === false && businessEntityState.clubOwnerTypeId === formState.clubOwnerTypeId
      && formState.businessEntityTypeId > Constants.DEFAULT_ID && state.isInitialPageLoad === true) {
      //If navigating back to this page, handle loading delay for setting the tax listing from previous data
      if (Object.keys(clubRegistrationState.objData).length > 0) {
        setState({ ...state, isInitialPageLoad: false })
        if (Object.keys(clubRegistrationState.helperObj).length > 0) {
          setFormState({
            ...formState,
            businessEntityTypeId: clubRegistrationState.objData.businessEntityTypeId,
            businessEntityTypeName: clubRegistrationState.helperObj.businessEntityTypeName || '',
          });
        }
        else {
          setFormState({
            ...formState,
            businessEntityTypeId: clubRegistrationState.objData.businessEntityTypeId
          });
        }
      }
      setIsDirty(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clubOwnerTypeState, businessEntityState, formState.businessEntityTypeId])

  useEffect(() => {
    //Handle changes to club owner type
    if (environmentVariableState.isLoaded === true && clubOwnerTypeState.isLoaded === true
      && clubOwnerTypeState.isLoading === false && formState.clubOwnerTypeId > 0) {
      const selectedClubOwnerType = clubOwnerTypeState.data.find(e => e.clubOwnerTypeId === formState.clubOwnerTypeId);
      if (selectedClubOwnerType.orgLevelType?.orgLevelTypeId === BOARD_OWNED_ORG_LEVEL_TYPE_ID) {
        setState({ ...state, showNumOfBoardMembers: true })
        setFormState({ ...formState, isNumOfBoardMembersRequired: true });
      }
      else {
        setState({ ...state, showNumOfBoardMembers: false })
        setFormState({ ...formState, numOfBoardMembers: '', isNumOfBoardMembersRequired: false });
      }
      setIsDirty(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [environmentVariableState, clubOwnerTypeState, formState.clubOwnerTypeId])

  useEffect(() => {
    if (state.tryRedirect === true && clubRegistrationState.isObjLoaded === true && clubRegistrationState.isSaving === false) {
      onContinueClicked();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.tryRedirect, clubRegistrationState])

  function getFormInitialState() {
    return {
      clubOwnerTypeId: Constants.DEFAULT_ID,
      ownerTypeName: '',
      businessEntityTypeId: Constants.DEFAULT_ID,
      businessEntityTypeName: '',
      numOfBoardMembers: '',
      isNumOfBoardMembersRequired: false
    };
  }

  return {
    state,
    clubRegistrationState,
    clubOwnerTypeState,
    businessEntityState,
    formState,
    errorState,
    onFormValueChanged: updateFormState,
    onValueTextPairChanged,
    handleSubmit,
    onContinueClicked,
    onBackClicked
  }
}

export default useClubRegistrationDisclaimer;