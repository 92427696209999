import React, { Fragment } from "react";

import StatesCombobox from "../../../../../common/components/comboboxes/statesCombobox/StatesCombobox";
import InternationalPhoneInput from "../../../../../common/components/inputs/InternationalPhoneInput";
import DatePicker from "../../../../../common/components/datepickers/DatePicker";
import CurrencyInput from "../../../../../common/components/inputs/CurrencyInput";
import Input from "../../../../../common/components/inputs/Input";
import ReadOnly from "../../../../../common/components/readOnly/ReadOnly";

import Constants from "../../../../../common/utils/Constants";

const SATContractForm = ({ formState, errorState = {}, onFormValueChanged = undefined, onValueTextPairChanged = undefined,
  canEditFulfillmentDate = false, canEditSignatureDate = false }) => (
  <Fragment>
    <div className="row usas-extra-top-margin">
      <div className="col-xs-12 col-sm-6 col-md-4">
        {onFormValueChanged ? (
          <Input
            label="Contact Name*"
            name="contactName"
            value={formState.contactName}
            error={errorState.contactName}
            message={errorState.contactName}
            onChange={(value) => { onFormValueChanged('contactName', value); }} />
        ) : (
          <ReadOnly
            label="Contact Name"
            name="contactName"
            value={formState.contactName} />
        )}
      </div>
      <div className="col-xs-12 col-sm-6 col-md-4">
        {onFormValueChanged ? (
          <Input
            label="Contact Email*"
            name="contactEmail"
            value={formState.contactEmail}
            error={errorState.contactEmail}
            message={errorState.contactEmail}
            onChange={(value) => { onFormValueChanged('contactEmail', value); }} />
        ) : (
          <ReadOnly
            label="Contact Email"
            name="contactEmail"
            value={formState.contactEmail} />
        )}
      </div>
      <div className="col-xs-12 col-sm-6 col-md-4">
        {onFormValueChanged ? (
          <InternationalPhoneInput
            label="Contact Phone Number*"
            name="contactPhoneNumber"
            value={formState.contactPhoneNumber}
            error={errorState.contactPhoneNumber}
            message={errorState.contactPhoneNumber}
            onChange={(value) => { onFormValueChanged('contactPhoneNumber', value); }} />
        ) : (
          <ReadOnly
            label="Contact Phone Number"
            name="contactPhoneNumber"
            value={formState.contactPhoneNumber} />
        )}
      </div>
      <div className="col-xs-12 col-sm-6 col-md-4">
        {onFormValueChanged ? (
          <Input
            label="Shipping Address 1*"
            name="address1"
            value={formState.address1}
            error={errorState.address1}
            message={errorState.address1}
            onChange={(value) => { onFormValueChanged('address1', value); }} />
        ) : (
          <ReadOnly
            label="Shipping Address 1"
            name="address1"
            value={formState.address1} />
        )}
      </div>
      <div className="col-xs-12 col-sm-6 col-md-4">
        {onFormValueChanged ? (
          <Input
            label="Shipping Address 2"
            name="address2"
            value={formState.address2}
            error={errorState.address2}
            message={errorState.address2}
            onChange={(value) => { onFormValueChanged('address2', value); }} />
        ) : (
          <ReadOnly
            label="Shipping Address 2"
            name="address2"
            value={formState.address2} />
        )}
      </div>
      <div className="col-xs-12 col-sm-6 col-md-4">
        {onFormValueChanged ? (
          <Input
            label="Shipping City*"
            name="city"
            value={formState.city}
            error={errorState.city}
            message={errorState.city}
            onChange={(value) => { onFormValueChanged('city', value); }} />
        ) : (
          <ReadOnly
            label="Shipping City"
            name="city"
            value={formState.city} />
        )}
      </div>
      <div className="col-xs-12 col-sm-6 col-md-4">
        {onValueTextPairChanged ? (
          <StatesCombobox
            label="Shipping State*"
            name="stateCode"
            valueToMatch={formState.stateName}
            error={errorState.stateCode}
            message={errorState.stateCode}
            isUSA={true}
            onChange={(newValue, newValueLabel, e) => { onValueTextPairChanged(newValue, 'stateCode', newValueLabel, 'stateName', e); }} />
        ) : (
          <ReadOnly
            label="Shipping State"
            name="stateName"
            value={formState.stateName} />
        )}
      </div>
      <div className="col-xs-12 col-sm-6 col-md-4">
        {onFormValueChanged ? (
          <Input
            label="Shipping Zip*"
            name="postalCode"
            value={formState.postalCode}
            error={errorState.postalCode}
            message={errorState.postalCode}
            onChange={(value) => { onFormValueChanged('postalCode', value); }} />
        ) : (
          <ReadOnly
            label="Shipping Zip"
            name="postalCode"
            value={formState.postalCode} />
        )}
      </div>
      <div className="col-xs-12 col-sm-6 col-md-4">
        {onFormValueChanged ? (
          <DatePicker
            label="Swim-a-Thon Date*"
            name="satDate"
            value={formState.satDate}
            error={errorState.satDate}
            message={errorState.satDate}
            countOfYears={150}
            startYearOffset={-149} 
            onChange={(value) => { onFormValueChanged('satDate', value); }} />
        ) : (
          <ReadOnly
            label="Swim-a-Thon Date"
            name="satDate"
            value={formState.satDate} />
        )}
      </div>
      <div className="col-xs-12 col-sm-6 col-md-4">
        {onFormValueChanged ? (
          <CurrencyInput
            label="Fundraising Goal*"
            name="fundraisingGoal"
            value={formState.fundraisingGoal}
            error={errorState.fundraisingGoal}
            message={errorState.fundraisingGoal}
            onChange={(value) => { onFormValueChanged('fundraisingGoal', value); }} />
        ) : (
          <ReadOnly
            label="Fundraising Goal"
            name="fundraisingGoal"
            value={formState.fundraisingGoal} />
        )}
      </div>
      <div className="col-xs-12 col-sm-6 col-md-4">
        {onFormValueChanged ? (
          <Input
            label="Number of Athletes*"
            name="numberOfAthletes"
            value={formState.numberOfAthletes}
            error={errorState.numberOfAthletes}
            message={errorState.numberOfAthletes}
            onChange={(value) => { onFormValueChanged('numberOfAthletes', value); }} />
        ) : (
          <ReadOnly
            label="Number of Athletes"
            name="numberOfAthletes"
            value={formState.numberOfAthletes} />
        )}
      </div>
      <div className="col-xs-12 col-sm-6 col-md-4">
        {onFormValueChanged ? (
          <Input
            label="Special Instructions"
            name="specialInstructions"
            value={formState.specialInstructions}
            error={errorState.specialInstructions}
            message={errorState.specialInstructions}
            onChange={(value) => { onFormValueChanged('specialInstructions', value); }} />
        ) : (
          <ReadOnly
            label="Special Instructions"
            name="specialInstructions"
            value={formState.specialInstructions} />
        )}
      </div>
    </div>
    <div className="row usas-extra-top-margin">
      <div className="col-xs-12 col-sm-6 col-md-4">
        {onFormValueChanged ? (
          <Input
            label="Signature*"
            name="signature"
            value={formState.signature}
            error={errorState.signature}
            message={errorState.signature}
            onChange={(value) => { onFormValueChanged('signature', value); }} />
        ) : (
          <ReadOnly
            label="Signature"
            name="signature"
            value={formState.signature} />
        )}
      </div>
      <div className="col-xs-12 col-sm-6 col-md-4">
        {onFormValueChanged && canEditSignatureDate ? (
          <DatePicker
            label="Signature Date*"
            name="signatureDate"
            value={formState.signatureDate}
            error={errorState.signatureDate}
            message={errorState.signatureDate}
            countOfYears={150}
            startYearOffset={-149} 
            onChange={(value) => { onFormValueChanged('signatureDate', value); }} />
        ) : (
          <ReadOnly
            label="Signature Date"
            name="signatureDate"
            value={formState.signatureDate} />
        )}
      </div>
    </div>
    <div className="row usas-extra-top-margin">
      <div className="col-xs-12 col-sm-6 col-md-4">
        {onFormValueChanged && canEditFulfillmentDate ? (
          <DatePicker
            label="Fulfillment Date"
            name="fulfillmentDate"
            value={formState.fulfillmentDate}
            error={errorState.fulfillmentDate}
            message={errorState.fulfillmentDate}
            countOfYears={150}
            startYearOffset={-149} 
            onChange={(value) => { onFormValueChanged('fulfillmentDate', value); }} />
        ) : formState.fulfillmentDate !== Constants.BLANK_DATE_STRING ? (
          <ReadOnly
            label="Fulfillment Date"
            name="fulfillmentDate"
            value={formState.fulfillmentDate} />
        ) : (
          <Fragment />
        )}
      </div>
    </div>
  </Fragment>
);

export default SATContractForm;