import React from 'react';

import usePreCompRegistrationOrganizationInsurance from './UsePreCompRegistrationOrganizationInsurance';

import PreCompRegistrationConstants from '../utils/PreCompRegistrationConstants';

import PreCompOrganizationInsuranceForm from '../../preComp/components/forms/PreCompOrganizationInsuranceForm';

import PopUpModal from '../../../../common/components/dialogs/PopUpModal';
import Headings from '../../../../common/components/headings/Headings';
import PrimaryButton from '../../../../common/components/buttons/PrimaryButton';
import SecondaryButton from '../../../../common/components/buttons/SecondaryButton';
import LeftNav from '../../../../common/components/leftNav/LeftNav';

import Constants from '../../../../common/utils/Constants';

const PreCompRegistrationOrganizationInsurance = () => {
  const {
    isSaving,
    isLoading,
    formState,
    errorState,
    locationRegistrationNavState,
    handleSubmit,
    onFormValueChanged,
    onUploadVerification,
    onRemoveVerification,
    onDownloadUploadedFile,
    onBackClicked,
    onContinueClicked
  } = usePreCompRegistrationOrganizationInsurance();

  return (
    <LeftNav title={PreCompRegistrationConstants.LEFT_NAV_TITLE} state={locationRegistrationNavState} validateBeforeRedirect={false}>
      <Headings.H3>Organization Insurance</Headings.H3>
      <form onSubmit={handleSubmit} noValidate>
        <PreCompOrganizationInsuranceForm
          formState={formState}
          errorState={errorState}
          onFormValueChanged={onFormValueChanged}
          onUploadVerification={onUploadVerification}
          onRemoveVerification={onRemoveVerification}
          onDownloadUploadedFile={onDownloadUploadedFile} />
        {errorState.errorOnSubmitAction &&
          <div className="row">
            <div className="col-xs-12">
              <p className={global.ErrorMessage}>{errorState.errorOnSubmitAction || ''}</p>
            </div>
          </div>}
        <div className="row usas-extra-top-margin usas-extra-bottom-margin">
          <div className="col-xs-12 usas-extra-top-margin">
            <PrimaryButton type="button" onClick={onContinueClicked}>Continue</PrimaryButton>&nbsp;
            <SecondaryButton type="button" onClick={onBackClicked}>Back</SecondaryButton>
          </div>
        </div>
      </form>
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.SAVING_MSG}
        displayPopUp={isSaving} />
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={isLoading} />
    </LeftNav>
  );
};

export default PreCompRegistrationOrganizationInsurance;