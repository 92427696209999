import useClubAffiliationTypeData from '../../../state/clubAffiliationType/UseClubAffiliationTypeData';

const useClubAffiliationTypeDropdown = () => {
  const { clubAffiliationTypeState } = useClubAffiliationTypeData();

  return {
    clubAffiliationTypeState
  };
};

export default useClubAffiliationTypeDropdown;