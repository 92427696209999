import React, { Fragment } from 'react';

import useClubAffiliation from './UseClubAffiliation';
import ClubAffiliationWriteGrid from './ClubAffiliationWriteGrid';

import Headings from '../../../../common/components/headings/Headings';
import ActionIntraPageButton from '../../../../common/components/buttons/ActionIntraPageButton';
import PopUpModal from '../../../../common/components/dialogs/PopUpModal';
import Constants from '../../../../common/utils/Constants';

const ClubAffiliationWrite = () => {
  const { baseUsasClubState, onDelete, onAdd } = useClubAffiliation();

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>Club Affiliations</Headings.H3>
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12 usas-extra-top-margin">
          <ActionIntraPageButton onClick={onAdd}>Add Affiliation</ActionIntraPageButton>
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12 usas-extra-top-margin usas-extra-bottom-margin">
          <ClubAffiliationWriteGrid state={baseUsasClubState} onDelete={onDelete} />
        </div>
      </div>
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={baseUsasClubState.isObjLoading} />
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.SAVING_MSG}
        displayPopUp={baseUsasClubState.isSaving} />
    </Fragment>
  );
};

export default ClubAffiliationWrite;