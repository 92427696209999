import { useState } from 'react';

import ClubManageOfferingsData from './ClubManageOfferingsData';

const useClubManageOfferingsData = () => {
  const [clubManageOfferingsState, setClubManageOfferingsState] = useState(ClubManageOfferingsData.INITIAL_STATE);

  const getClubOfferings = (usasClubId, reportPeriodId = undefined) => {
    ClubManageOfferingsData.getClubManageOfferingsData(usasClubId, reportPeriodId, clubManageOfferingsState, setClubManageOfferingsState);
  };

  const postOfferingInstanceExclusion = (offeringInstanceExclusionObj) => {
    ClubManageOfferingsData.postOfferingInstanceExclusionData(offeringInstanceExclusionObj, clubManageOfferingsState, setClubManageOfferingsState);
  };

  const deleteOfferingInstanceExclusion = (offeringInstanceOrgUnitExclusionId) => {
    ClubManageOfferingsData.deleteOfferingInstanceExclusionData(offeringInstanceOrgUnitExclusionId, clubManageOfferingsState, setClubManageOfferingsState);
  };

  return {
    clubManageOfferingsState,
    getClubOfferings,
    postOfferingInstanceExclusion,
    deleteOfferingInstanceExclusion
  };
};

export default useClubManageOfferingsData;