import { useEffect, useState } from 'react';

import validate from '../components/forms/PreCompOrganizationInsuranceFormValidation';

import { createOrgObjFromInsuranceForm } from '../utils/PreCompUtils';
import PreCompConstants from '../utils/PreCompConstants';

import usePreCompFilesInsuranceVerificationData from '../../../state/preCompFilesInsuranceVerification/UsePreCompFilesInsuranceVerificationData';
import usePreCompPolicyTypeData from '../../../state/preCompPolicyType/UsePreCompPolicyTypeData';

import usePreCompOrganizationData from '../../../../common/state/preCompOrganization/UsePreCompOrganizationData';

import UseForm from '../../../../common/utils/UseForm';
import { formatDate } from '../../../../common/utils/DateFunctions';
import Constants from '../../../../common/utils/Constants';

const FILE_TYPE_ERROR_MSG = 'File type not permitted. Please provide a (*.pdf), (*.doc), or (*.docx) file.';

const usePreCompOrganizationInsurance = () => {
  const { preCompPolicyTypeState } = usePreCompPolicyTypeData();
  const { preCompOrganizationState, confirmSave, putPreCompOrganization } = usePreCompOrganizationData();
  const { preCompFilesInsuranceVerificationState, postPreCompFilesInsuranceVerification,
    GET_PRE_COMP_FILES_INSURANCE_VERIFICATION_PATH, getFileNameFromUrl } = usePreCompFilesInsuranceVerificationData();
  const { formState, errorState, handleSubmit, updateFormState, setFormData, setIsDirty,
    setErrors, resetForm, setFormState } = UseForm(getInitialFormState, submitFormCallback, validate);
  const [state, setState] = useState();

  const onUploadVerification = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    setErrors({ ...errorState, insuranceValidationUrl: '' });
    if (e.target.files[0]) {
      const selectedFile = e.target.files[0];
      const fileType = e.target.files[0].type;
      if (fileType === Constants.FILE_TYPE_PDF || fileType === Constants.FILE_TYPE_DOC ||
        fileType === Constants.FILE_TYPE_DOCX) {
        updateFormState('verificationFile', selectedFile);
      }
      else {
        setErrors({ ...errorState, insuranceValidationUrl: FILE_TYPE_ERROR_MSG });
      }
    }
  };

  const onRemoveVerification = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    setFormState({
      ...formState,
      verificationFile: {},
      insuranceValidationUrl: '',
      insuranceValidationName: ''
    });
    setIsDirty(true);
  };

  const onDownloadUploadedFile = (event) => {
    event.preventDefault();
    const fileUrl = formState.insuranceValidationUrl;
    window.location.href = import.meta.env.VITE_PRECOMP_URL + GET_PRE_COMP_FILES_INSURANCE_VERIFICATION_PATH + fileUrl;
  };

  useEffect(() => {
    if (preCompOrganizationState.isSaving === false && preCompOrganizationState.isSaved === true) {
      confirmSave();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [preCompOrganizationState]);

  useEffect(() => {
    if (preCompOrganizationState.isObjLoaded === true) {
      let newFormState = {};
      let newState = {};

      if (Array.isArray(preCompOrganizationState.objData.insuranceProvider)
        && preCompOrganizationState.objData.insuranceProvider.length > 0) {
        const targetProvider = preCompOrganizationState.objData.insuranceProvider[0];

        if (targetProvider) {
          newFormState.insuranceProviderName = targetProvider.insuranceProviderName || '';
          newFormState.isSelfInsured = targetProvider.isSelfInsured ?? '';
          newState.isProviderEdit = true;

          if (Array.isArray(targetProvider.insuranceProviderPolicy)
            && targetProvider.insuranceProviderPolicy.length > 0) {
            const targetPolicy = targetProvider.insuranceProviderPolicy[0];

            if (targetPolicy) {
              newFormState.policyNumber = targetPolicy.policyNumber || '';
              newFormState.effectiveDate = targetPolicy.effectiveDate
                ? formatDate(targetPolicy.effectiveDate)
                : Constants.BLANK_DATE_STRING;
              newFormState.expirationDate = targetPolicy.expirationDate
                ? formatDate(targetPolicy.expirationDate)
                : Constants.BLANK_DATE_STRING;
              newFormState.occurrenceLimit = targetPolicy.occurrenceLimit || '';
              newFormState.aggregateLimit = targetPolicy.aggregateLimit || '';
              newFormState.insuranceValidationUrl = targetPolicy.insuranceValidationUrl || '';
              newFormState.insuranceValidationName = getFileNameFromUrl(targetPolicy.insuranceValidationUrl) || '';
              newState.isPolicyEdit = true;
            }
          }
        }
      }

      setFormData({
        ...formState,
        ...newFormState
      }, true);
      setState({
        ...state,
        ...newState
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [preCompOrganizationState.objData]);

  useEffect(() => {
    if (preCompFilesInsuranceVerificationState.isSaving === false && preCompFilesInsuranceVerificationState.isSaved === true) {
      const policyTypeId = preCompPolicyTypeState.options.find(x => x.name === PreCompConstants.POLICY_TYPE_NAME_GENERAL_LIABILITY)?.id;

      if (policyTypeId) {
        putPreCompOrganization(preCompOrganizationState.objData.preCompetitiveOrganizationId,
          createOrgObjFromInsuranceForm(JSON.parse(JSON.stringify(preCompOrganizationState.objData)), formState, preCompFilesInsuranceVerificationState.objData.uploadUrl,
            state.isProviderEdit === true ? 0 : -1, state.isPolicyEdit === true ? 0 : -1, policyTypeId));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [preCompFilesInsuranceVerificationState]);

  function submitFormCallback(formState) {
    if (formState.verificationFile.size > 0) {
      postPreCompFilesInsuranceVerification(formState.verificationFile);
    } else {
      const policyTypeId = preCompPolicyTypeState.options.find(x => x.name === PreCompConstants.POLICY_TYPE_NAME_GENERAL_LIABILITY)?.id;

      if (policyTypeId) {
        putPreCompOrganization(preCompOrganizationState.objData.preCompetitiveOrganizationId,
          createOrgObjFromInsuranceForm(JSON.parse(JSON.stringify(preCompOrganizationState.objData)), formState, formState.insuranceValidationUrl,
            state.isProviderEdit === true ? 0 : -1, state.isPolicyEdit === true ? 0 : -1, policyTypeId));
      }
    }
  };

  function getInitialFormState() {
    return {
      insuranceProviderName: '',
      policyNumber: '',
      effectiveDate: Constants.BLANK_DATE_STRING,
      expirationDate: Constants.BLANK_DATE_STRING,
      occurrenceLimit: '',
      aggregateLimit: '',
      insuranceValidationUrl: '',
      insuranceValidationName: '',
      isSelfInsured: '',
      verificationFile: {}
    };
  };

  return {
    isSaving: preCompOrganizationState.isSaving || preCompFilesInsuranceVerificationState.isSaving,
    isLoading: preCompOrganizationState.isObjLoading || preCompPolicyTypeState.isArrayLoading,
    formState,
    errorState,
    handleSubmit,
    onFormValueChanged: updateFormState,
    resetForm,
    onUploadVerification,
    onRemoveVerification,
    onDownloadUploadedFile
  };
};

export default usePreCompOrganizationInsurance;