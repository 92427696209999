import { CommonHttpHelper, NO_DATA_MESSAGE, BASIC_INITIAL_STATE } from '../../utils/HttpHelper';
import HierarchicalDataUtils from '../../utils/HierarchicalDataUtils';

const INITIAL_STATE = {
  ...BASIC_INITIAL_STATE,
  treeData: [],
  level: ''
};

const getOrgGroupCategories = (level, state, setState) => {
  if (state.isArrayLoading !== true && !state.message) {
    let newState = {
      ...state,
      isArrayLoading: true,
      isArrayLoaded: false,
      message: 'Loading...'
    };
    setState(newState);

    const url = `/OrgGroup/categories/flat/${level}`;
    CommonHttpHelper(url, 'GET')
      .then((arrayData) => {
        if (!arrayData) {
          throw new Error(NO_DATA_MESSAGE);
        } else {
          newState = {
            ...newState,
            isArrayLoading: false,
            isArrayLoaded: true,
            arrayData,
            message: ''
          };
          setState(newState);
        }
      })
      .catch((e) => {
        const message = e && e.message ? e.message : NO_DATA_MESSAGE;
        setState({
          ...newState,
          isArrayLoading: false,
          isArrayLoaded: false,
          message: message
        });
      });
  }
};

const getOrgGroupCategoriesH = (level, state, setState) => {
  if (state.isArrayLoading !== true && level && level !== state.level && !state.message) {
    let newState = {
      ...state,
      isArrayLoading: true,
      isArrayLoaded: false,
      message: 'Loading...',
      level
    };
    setState(newState);

    const url = `/OrgGroup/categories/${level}`;
    CommonHttpHelper(url, 'GET')
      .then((arrayData) => {
        if (!arrayData) {
          throw new Error(NO_DATA_MESSAGE);
        } else {
          newState = {
            ...newState,
            isArrayLoading: false,
            isArrayLoaded: true,
            arrayData,
            treeData: HierarchicalDataUtils.MapArrayToTreeData(arrayData, 'groupCategoryId', 'categoryName'),
            message: ''
          };
          setState(newState);
        }
      })
      .catch((e) => {
        const message = e && e.message ? e.message : NO_DATA_MESSAGE;
        setState({
          ...newState,
          isArrayLoading: false,
          isArrayLoaded: false,
          message: message
        });
      });
  }
};

const OrgGroupCategoriesData = { INITIAL_STATE, getOrgGroupCategoriesH, getOrgGroupCategories };

export default OrgGroupCategoriesData;