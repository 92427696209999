import { useEffect, useState } from 'react';
import { useNavigate } from '../../../../common/wrappers/ReactRouterDom';

import NavLinks from '../NavLinks';

import useProgramLevelInstanceData from '../../../state/programLevelInstance/UseProgramLevelInstanceData';

import useEnvironmentVariableData from '../../../../common/state/environmentVariable/UseEnvironmentVariableData';
import useRecognitionProgramData from '../../../../common/state/recognitionProgram/UseRecognitionProgramData';
import useSelectOrgUnitData from '../../../../common/state/selectOrgUnit/UseSelectOrgUnitData';

import Constants, { BLANK_DATE_STRING } from '../../../../common/utils/Constants';

const INITIAL_REVOKE_MODAL_STATE = {
  programLevelInstanceId: Constants.DEFAULT_ID,
  displayPopUp: false
};

const useSafeSportProgramLevel = () => {
  const navigate = useNavigate();
  const { environmentVariableState, SAFE_SPORT_PROGRAM_TYPE_ID } = useEnvironmentVariableData();
  const { selectOrgUnitState } = useSelectOrgUnitData()
  const { recognitionProgramState, getAllProgramsByProgramType, postProgramLevelInitiation, postProgramLevelRenewal,
    putProgramLevelRevoke, updateProgramFocus } = useRecognitionProgramData();
  const { submitLevelState, postProgramLevelInstanceSubmitLevel } = useProgramLevelInstanceData();
  const [modalState, setModalState] = useState(getInitialModalState());
  const [revokeModalState, setRevokeModalState] = useState(INITIAL_REVOKE_MODAL_STATE);
  const [buttonsState, setButtonsState] = useState(getInitialButtonsState);

  const onSubmitLevelInstance = (e, programLevelInstanceId) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    const programLevelObj = recognitionProgramState.arrayData.find((programLevel) =>
      programLevel.programLevelInstanceId === programLevelInstanceId);

    if (programLevelObj) {
      const validationResult = validateProgramLevel(programLevelObj);

      setModalState({
        ...modalState,
        programLevelInstanceId: programLevelInstanceId,
        canSubmit: !(validationResult.pointsErrors.length > 0 || validationResult.requiredErrors.length > 0),
        pointsErrors: validationResult.pointsErrors,
        requiredErrors: validationResult.requiredErrors,
        modalTitle: `Submit ${programLevelObj.levelName}?`,
        displayPopUp: true
      });
    }
  };

  const onInitiateProgramLevel = () => {
    if (buttonsState.initiateOrgUnitId) {
      postProgramLevelInitiation(buttonsState.initiateOrgUnitId, SAFE_SPORT_PROGRAM_TYPE_ID);
    }
  };

  const onRenewProgramLevel = () => {
    if (buttonsState.renewProgramLevelInstanceId > 0) {
      postProgramLevelRenewal(buttonsState.renewProgramLevelInstanceId);
    }
  };

  const onRevokeSSRP = () => {
    if (buttonsState.renewProgramLevelInstanceId > 0) {
      setRevokeModalState({
        ...revokeModalState,
        programLevelInstanceId: buttonsState.renewProgramLevelInstanceId,
        displayPopUp: true
      });
    }
  };

  const onEditProgramLevel = (e, programLevelInstanceId, programCategoryId) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    navigate(NavLinks.SAFE_SPORT_PROGRAM_CATEGORY, { state: { programLevelInstanceId, programCategoryId } });
  };

  const onModalSubmit = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    if (modalState.programLevelInstanceId > 0 && modalState.canSubmit === true) {
      const programLevelObj = recognitionProgramState.arrayData.find((programLevel) => programLevel.programLevelInstanceId === modalState.programLevelInstanceId);

      postProgramLevelInstanceSubmitLevel(programLevelObj.programLevelInstanceId);
      setModalState(getInitialModalState());
    }
  };

  const onModalCanceled = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    setModalState(getInitialModalState());
  };

  const onRevokeSSRPModalSubmit = (formState) => {
    if (revokeModalState.programLevelInstanceId > 0) {
      const revokeObj = {
        expirationDate: formState.expirationDate
      };

      putProgramLevelRevoke(revokeModalState.programLevelInstanceId, revokeObj);

      setRevokeModalState(INITIAL_REVOKE_MODAL_STATE);
    }
  };

  const onRevokeSSRPModalCanceled = () => {
    setRevokeModalState(INITIAL_REVOKE_MODAL_STATE);
  };

  useEffect(() => {
    if (environmentVariableState.isLoaded === true && submitLevelState.isSaving === false && selectOrgUnitState?.orgUnitId) {
      getAllProgramsByProgramType(selectOrgUnitState.orgUnitId, SAFE_SPORT_PROGRAM_TYPE_ID);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectOrgUnitState.orgUnitId, environmentVariableState, submitLevelState, selectOrgUnitState.orgUnitId]);

  useEffect(() => {
    if (environmentVariableState.isLoaded === true && submitLevelState.isSaving === false
      && selectOrgUnitState?.orgUnitId && recognitionProgramState.isArrayLoaded === true) {
      const programs = recognitionProgramState.arrayData || [];

      if (programs.length === 0) { // no previous programs
        setButtonsState({
          ...buttonsState,
          showInitiateButton: true,
          initiateOrgUnitId: selectOrgUnitState.orgUnitId,
          showRenewButton: false,
          showRevokeButton: false,
          renewProgramLevelInstanceId: Constants.DEFAULT_ID,
          currentProgramLevelEffectiveDate: BLANK_DATE_STRING
        });
      } else if (programs.find(x => x.effectiveDate === null)) { // program in progress
        setButtonsState({
          ...buttonsState,
          showInitiateButton: false,
          initiateOrgUnitId: '',
          showRenewButton: false,
          showRevokeButton: false,
          renewProgramLevelInstanceId: Constants.DEFAULT_ID,
          currentProgramLevelEffectiveDate: BLANK_DATE_STRING
        });
      } else {
        const today = new Date();
        let mostRecentProgram = programs[0];

        for (const program of programs) {
          if (new Date(program.effectiveDate) > new Date(mostRecentProgram.effectiveDate)) {
            mostRecentProgram = program;
          }
        }

        if (today <= new Date(mostRecentProgram.expirationDate)) { // program current
          setButtonsState({
            ...buttonsState,
            showInitiateButton: false,
            initiateOrgUnitId: '',
            showRenewButton: true,
            showRevokeButton: true,
            renewProgramLevelInstanceId: mostRecentProgram.programLevelInstanceId,
            currentProgramLevelEffectiveDate: mostRecentProgram.effectiveDate
          });
        } else { // program expired
          setButtonsState({
            ...buttonsState,
            showInitiateButton: true,
            initiateOrgUnitId: selectOrgUnitState.orgUnitId,
            showRenewButton: false,
            showRevokeButton: false,
            renewProgramLevelInstanceId: Constants.DEFAULT_ID,
            currentProgramLevelEffectiveDate: BLANK_DATE_STRING
          });
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectOrgUnitState.orgUnitId, environmentVariableState, submitLevelState, recognitionProgramState]);

  useEffect(() => {
    updateProgramFocus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function validateProgramLevel(programLevel) {
    const validationObj = {
      pointsErrors: [],
      requiredErrors: []
    };

    if (programLevel.currentPointValue < programLevel.minimumValue) { // level minimum points check
      validationObj.pointsErrors.push(`Minimum Points for ${programLevel.levelName} not met | Current Points: ${programLevel.currentPointValue} | Minimum Points: ${programLevel.minimumValue}`);
    }

    if (Array.isArray(programLevel.programCategory)) {
      for (const category of programLevel.programCategory) { // categories minimum points check
        if (category.currentPointValue < category.minimumValue) {
          validationObj.pointsErrors.push(`Minimum Points for ${category.categoryName} not met | Current Points: ${category.currentPointValue} | Minimum Points: ${category.minimumValue}`);
        }

        if (Array.isArray(category.categoryMeasure)) {
          for (const measure of category.categoryMeasure) { // measures is required check
            if (measure.isRequired === true && (!(measure.score > 0) || measure.score < measure.minValue)) {
              validationObj.requiredErrors.push(`${measure.measureName} in ${category.categoryName} is required`);
            }
          }
        }
      }
    }

    return validationObj;
  };

  return {
    isLoading: recognitionProgramState.isArrayLoading || environmentVariableState.isLoading,
    isSaving: submitLevelState.isSaving || recognitionProgramState.isSaving,
    recognitionProgramState,
    modalState,
    buttonsState,
    revokeModalState,
    onSubmitLevelInstance,
    onEditProgramLevel,
    onModalCanceled,
    onModalSubmit,
    onInitiateProgramLevel,
    onRenewProgramLevel,
    onRevokeSSRP,
    onRevokeSSRPModalSubmit,
    onRevokeSSRPModalCanceled
  };
};

function getInitialButtonsState() {
  return {
    showInitiateButton: false,
    initiateOrgUnitId: '',
    showRenewButton: false,
    showRevokeButton: false,
    renewProgramLevelInstanceId: Constants.DEFAULT_ID,
    currentProgramLevelEffectiveDate: BLANK_DATE_STRING
  };
};

function getInitialModalState() {
  return {
    programLevelInstanceId: Constants.DEFAULT_ID,
    canSubmit: false,
    pointsErrors: [],
    requiredErrors: [],
    modalTitle: 'Submit Level?',
    displayPopUp: false
  };
};

export default useSafeSportProgramLevel;