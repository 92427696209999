import React, { createContext, useState } from 'react';

import ClubOwnerTypeData from './ClubOwnerTypeData';

export const ClubOwnerTypeStateContext = createContext();

const ClubOwnerTypeContextProvider = ({ children }) => {
  const stateHook = useState(ClubOwnerTypeData.INITIAL_STATE);

  return (
    <ClubOwnerTypeStateContext.Provider value={stateHook}>
      {children}
    </ClubOwnerTypeStateContext.Provider>
  );
};

export default ClubOwnerTypeContextProvider;