import React, { createContext, useState } from 'react';

import PreCompInstructionHoursData from './PreCompInstructionHoursData';

export const PreCompInstructionHoursStateContext = createContext();

const PreCompInstructionHoursContextProvider = ({ children }) => {
  const stateHook = useState(PreCompInstructionHoursData.INITIAL_STATE);

  return (
    <PreCompInstructionHoursStateContext.Provider value={stateHook}>
      {children}
    </PreCompInstructionHoursStateContext.Provider>
  );
};

export default PreCompInstructionHoursContextProvider;