import React, { Fragment } from 'react';

import NavLinks from '../NavLinks';

import usePreCompLocationFacilitiesAdd from './UsePreCompLocationFacilitiesAdd';

import PopUpModal from '../../../../common/components/dialogs/PopUpModal';
import AddFacility from '../../../../common/components/searches/searchFacility/addFacility/AddFacility';

import Constants from '../../../../common/utils/Constants';

const PreCompLocationFacilitiesAdd = () => {
  const {
    preCompLocationState
  } = usePreCompLocationFacilitiesAdd();

  return (
    <Fragment>
      <AddFacility
        saveUrl={NavLinks.PRE_COMP_LOCATION_FACILITY_DETAIL}
        cancelUrl={NavLinks.PRE_COMP_LOCATION_FACILITY_SEARCH}
        orgUnitId={preCompLocationState.objData.orgUnitId} />
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.SAVING_MSG}
        displayPopUp={preCompLocationState.isSaving} />
    </Fragment>
  );
};

export default PreCompLocationFacilitiesAdd;