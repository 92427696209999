import React, { Fragment } from 'react';

import { PRIMARY_CONTACT_ORG_ROLE_NAME } from '../../utils/LearnToSwimConstants';

import HideIcon from '../../../../../common/components/icons/HideIcon';
import ShowIcon from '../../../../../common/components/icons/ShowIcon';
import EditIcon from '../../../../../common/components/icons/EditIcon';
import DeleteIcon from '../../../../../common/components/icons/DeleteIcon';

import { formatPhoneNumber } from '../../../../../common/utils/validation';

import global from '../../../../../common/components/GlobalStyle.module.css';

const DetailTableRow = ({ role, personId, onDeleteRoleClicked }) => (
  <tr>
    <td colSpan="4">{role.orgRoleName || ''}</td>
    <td colSpan="1">
      {role.orgRoleName !== PRIMARY_CONTACT_ORG_ROLE_NAME &&
        <button
          className={global.IconButtonMargin}
          type="button"
          onClick={() => onDeleteRoleClicked(personId, role.personOrgRoleDurationId)}>
          <DeleteIcon />
        </button>
      }
    </td>
  </tr>
);

const DetailTable = ({ personId, personOrgRole, onDeleteRoleClicked }) => (
  <table className={global.DetailTable}>
    <thead>
      <tr>
        <th colSpan="4">Role</th>
        <th colSpan="1"></th>
      </tr>
    </thead>
    <tbody>
      {Array.isArray(personOrgRole) && personOrgRole.map((role, i) =>
        <DetailTableRow key={i} role={role} personId={personId} onDeleteRoleClicked={onDeleteRoleClicked} />)
      }
    </tbody>
  </table>
);

const GridRow = ({ staff, expandedId, onEditClicked, onDeleteClicked, onDeleteRoleClicked, onExpand }) => (
  <Fragment>
    <tr>
      <td>{`${staff.firstName || ''} ${staff.lastName || ''}`}</td>
      <td>{staff.memberId || ''}</td>
      <td>{staff.email || ''}</td>
      <td>{staff.phoneNumber ? formatPhoneNumber(staff.phoneNumber) : ''}</td>
      <td>
        <button
          className={global.IconButtonMargin}
          type="button"
          onClick={() => onEditClicked(staff)}>
          <EditIcon />
        </button>
        {!(staff.personOrgRole.some((x) => x.orgRoleName === PRIMARY_CONTACT_ORG_ROLE_NAME)) &&
          <button
            className={global.IconButtonMargin}
            type="button"
            onClick={() => onDeleteClicked(staff.personId)}>
            <DeleteIcon />
          </button>
        }
        <button
          className={global.IconButtonMargin}
          type="button"
          onClick={(e) => onExpand(e, staff.personId)}>
          {expandedId === staff.personId ? <HideIcon /> : <ShowIcon />}
        </button>
      </td>
    </tr>
    {Array.isArray(staff.personOrgRole) && staff.personOrgRole.length > 0 && expandedId === staff.personId &&
      <tr className={global.Expanded}>
        <td colSpan="5">
          <DetailTable personId={staff.personId} personOrgRole={staff.personOrgRole} onDeleteRoleClicked={onDeleteRoleClicked} />
        </td>
      </tr>
    }
  </Fragment>
);

const ProviderStaffGridLarge = ({ data, isLoading, expandedId, onEditClicked, onDeleteClicked, onDeleteRoleClicked, onExpand }) => (
  <table className={[global.UsasTable, 'visible-md visible-lg'].join(' ')}>
    <thead>
      <tr>
        <th>Staff Name</th>
        <th>Member Id</th>
        <th>Email</th>
        <th>Phone Number</th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      {isLoading === true
        ? <tr><td colSpan="5">Loading...</td></tr>
        : data.length > 0
          ? data.map((staff, i) => <GridRow key={i} staff={staff} expandedId={expandedId} onEditClicked={onEditClicked}
            onDeleteClicked={onDeleteClicked} onDeleteRoleClicked={onDeleteRoleClicked} onExpand={onExpand} />)
          : <tr><td colSpan="5">No Staff</td></tr>
      }
    </tbody>
  </table>
);

export default ProviderStaffGridLarge;