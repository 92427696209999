import Constants from '../../../../utils/Constants';
import { isValidNumber } from '../../../../utils/validation';

export const localValidate = (formState) => {
  let errors = {};

  if (formState.courseId === Constants.DEFAULT_ID || formState.courseName === '') {
    errors.courseId = 'Course is required';
  }

  if (formState.numberOfLanes === '') {
    errors.numberOfLanes = 'Number of Lanes is required';
  } else if (isValidNumber(formState.numberOfLanes) === false ||
    (formState.numberOfLanes !== '' && Number(formState.numberOfLanes) < 1)) {
    errors.numberOfLanes = 'Number of Lanes must be a positive number'
  } else if (formState.numberOfLanes !== '' && Number(formState.numberOfLanes) > 100) {
    errors.numberOfLanes = 'Number of Lanes cannot exceed 100'
  }

  return errors;
};

const FacilityPoolConfigurationValidate = async (formState) => {
  let errors = localValidate(formState);

  return errors ? errors : {};
};

export default FacilityPoolConfigurationValidate;