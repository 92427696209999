import global from '../../../../../common/components/GlobalStyle.module.css';

const GridRow = ({ membership }) => {
  return (
    <tr key={'personId' + membership.personId + 'offeringInstanceId' + membership.offeringInstanceId}>
      <td>{(membership.firstName || membership.middleName || membership.lastName) ? `${membership.firstName || ''} ${membership.middleName ? membership.middleName[0] + '.' : ''} ${membership.lastName || ''}` : ''} {`(${membership.offeringName || ''})`}</td>
    </tr>
  );
};

const ClubBulkRenewMembersReadOnlyLargeGrid = ({ gridData, isLoading }) => (
  <table className={[global.UsasTable, 'visible-md visible-lg'].join(' ')}>
    <thead>
      <tr>
        <th>Name (Last Membership)</th>
      </tr>
    </thead>
    <tbody>
      {isLoading === true ?
        <tr><td colSpan={"1"}>Loading...</td></tr>
        : Array.isArray(gridData) && gridData.length > 0 ?
          gridData.map((membership, i) =>
            <GridRow
              key={i}
              membership={membership}
            />)
          :
          <tr>
            <td colSpan={"1"}>No Unselected Memberships</td>
          </tr>
      }
    </tbody>
  </table>
);

export default ClubBulkRenewMembersReadOnlyLargeGrid;