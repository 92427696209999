import React, { Fragment } from "react";
import LSCRoleEmailsGridLarge from "./LSCRoleEmailsGridLarge";
import LSCRoleEmailsGridSmall from "./LSCRoleEmailsGridSmall";


const LSCRoleEmailsGrid = ({ data, isLoading, onEdit }) => (
  <Fragment>
    <LSCRoleEmailsGridLarge data={data} isLoading={isLoading} onEdit={onEdit} />
    <LSCRoleEmailsGridSmall data={data} isLoading={isLoading} onEdit={onEdit} />
  </Fragment>
);

export default LSCRoleEmailsGrid;