import { API_NAMES, initApi } from "../../../common/utils/HttpApiHelper";

const postLTSStaffMemberData = (orgUnitId, roleGroupId, staffObj, state, setState) => {
  if (orgUnitId && roleGroupId > 0 && staffObj) {  
    const api = initApi(API_NAMES.COMMON, state, setState);
    const orgUnitIdForUrl = orgUnitId ? encodeURIComponent(orgUnitId) : 'NaN';
    const roleGroupIdForUrl = roleGroupId ? encodeURIComponent(roleGroupId) : 'NaN';
    const url = `/OrgUnit/${orgUnitIdForUrl}/staff/roleGroup/${roleGroupIdForUrl}`;

    return api?.executeObject ? api.executeObject(url, 'POST', staffObj) : null;
  }
};

const getLTSStaffData = (orgUnitId, roleGroupId, isCurrentOnly, state, setState) => {
  if (orgUnitId && roleGroupId > 0) {
    const api = initApi(API_NAMES.COMMON, state, setState);
    const orgUnitIdForUrl = orgUnitId ? encodeURIComponent(orgUnitId) : 'NaN';
    const roleGroupIdForUrl = roleGroupId ? encodeURIComponent(roleGroupId) : 'NaN';
    const url = `/OrgUnit/${orgUnitIdForUrl}/staff/roleGroup/${roleGroupIdForUrl}?isCurrentOnly=${isCurrentOnly}`;

    return api?.executeArray ? api.executeArray(url, 'GET') : null;
  }
};

const putLTSStaffMemberData = (orgUnitId, personId, roleGroupId, staffObj, state, setState) => {
  if (orgUnitId && personId && roleGroupId > 0 && staffObj) {
    const api = initApi(API_NAMES.COMMON, state, setState);
    const orgUnitIdForUrl = orgUnitId ? encodeURIComponent(orgUnitId) : 'NaN';
    const roleGroupIdForUrl = roleGroupId ? encodeURIComponent(roleGroupId) : 'NaN';
    const personIdForUrl = personId ? encodeURIComponent(personId) : 'NaN';
    const url = `/OrgUnit/${orgUnitIdForUrl}/staff/roleGroup/${roleGroupIdForUrl}/person/${personIdForUrl}`;

    return api?.executeObject ? api.executeObject(url, 'PUT', staffObj) : null;
  }
};

const deleteLTSStaffMemberRoleData = (personOrgRoleDurationId, state, setState) => {
  if (personOrgRoleDurationId > 0) {
    const api = initApi(API_NAMES.PERSON, state, setState);
    const personOrgRoleDurationIdForUrl = personOrgRoleDurationId ? encodeURIComponent(personOrgRoleDurationId) : 'NaN';
    const url = `/PersonOrgRoleDuration/${personOrgRoleDurationIdForUrl}`;

    return api?.executeObject ? api.executeObject(url, 'DELETE') : null;
  }
};

const LearnToSwimStaffData = {
  postLTSStaffMemberData,
  getLTSStaffData,
  putLTSStaffMemberData,
  deleteLTSStaffMemberRoleData
};

export default LearnToSwimStaffData;