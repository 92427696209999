import { Fragment } from 'react';

import useClubStaffDetail from './UseClubStaffDetail';

import ClubStaffTreeView from '../../../components/treeView/ClubStaffTreeView';

import Headings from '../../../../common/components/headings/Headings';
import PopUpModal from '../../../../common/components/dialogs/PopUpModal';
import Dropdown from '../../../../common/components/dropdowns/Dropdown';
import PrimaryButton from '../../../../common/components/buttons/PrimaryButton';
import SecondaryButton from '../../../../common/components/buttons/SecondaryButton';
import ReadOnly from '../../../../common/components/readOnly/ReadOnly';
import SearchMemberWithLscPopup from '../../../../common/components/searches/searchMember/SearchMemberWithLscPopup';

import { formatPhoneNumber } from '../../../../common/utils/validation';
import Constants from '../../../../common/utils/Constants';

import global from '../../../../common/components/GlobalStyle.module.css';

const ClubStaffDetail = () => {
  const {
    formState,
    viewState,
    errorState,
    setFormData,
    environmentVariableState,
    usasClubStaffState,
    isEdit,
    isSubmitting,
    onFormValueChanged,
    onValueTextPairChanged,
    handleSubmit,
    onCancelClicked,
    coachTypeCodeOptions,
    HEAD_COACH_ORG_ROLE_ID,
    ASSISTANT_COACH_ORG_ROLE_ID,
    AGE_GROUP_COACH_ORG_ROLE_ID,
    SENIOR_COACH_ORG_ROLE_ID,
  } = useClubStaffDetail();

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>Club Staff</Headings.H3>
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H6>{isEdit === false ? 'Add' : 'Edit'} Staff Member</Headings.H6>
        </div>
      </div>
      <form onSubmit={handleSubmit} noValidate>
        <div className="row usas-extra-top-margin">
          {isEdit === false ? (
            <div className="col-xs-12 col-sm-6 col-md-4">
              <SearchMemberWithLscPopup
                formState={formState}
                errorState={errorState}
                setFormData={setFormData}
                onFormValueChanged={onFormValueChanged} />
            </div>
          ) : (
            <Fragment>
              <div className="col-xs-12 col-sm-6 col-md-4">
                <ReadOnly
                  label="Member Name (Member Id)"
                  name="memberName"
                  value={viewState.firstName + ' ' + viewState.lastName + ' (' + viewState.memberId + ')'}
                />
              </div>
              <div className="col-xs-12 col-sm-6 col-md-4">
                <ReadOnly
                  label="Phone Number"
                  name="phoneNumber"
                  value={formatPhoneNumber(viewState.phoneNumber)}
                />
              </div>
              <div className="col-xs-12 col-sm-6 col-md-4">
                <ReadOnly
                  label="Email"
                  name="email"
                  value={viewState.email}
                />
              </div>
            </Fragment>
          )}
          {formState.registrationRoles.length > 0 &&
            <div className="col-xs-12 col-sm-6 col-md-4">
              <ReadOnly
                label={'Registration Role(s)'}
                name={'registrationRoles'}
                value={viewState.registrationRoleString} />
            </div>
          }
        </div>
        <div className="row usas-extra-bottom-margin">
          <div className="col-xs-12 col-sm-6 col-md-8">
            <ClubStaffTreeView
              label={formState.registrationRoles.length > 0 ? "Staff Role(s)" : "Staff Role(s)*"}
              name="personOrgRole"
              value={formState.personOrgRole}
              error={errorState.personOrgRole}
              message={errorState.personOrgRole}
              singleSelect={false}
              onChange={(newValue) => { onFormValueChanged('personOrgRole', newValue); }} />
          </div>
          {formState.personOrgRole.find(
            x => (x.id === HEAD_COACH_ORG_ROLE_ID ||
              x.id === ASSISTANT_COACH_ORG_ROLE_ID ||
              x.id === AGE_GROUP_COACH_ORG_ROLE_ID ||
              x.id === SENIOR_COACH_ORG_ROLE_ID)) !== undefined ?
            <div className="col-xs-12 col-sm-6 col-md-4">
              <Dropdown
                label="Full Time / Part Time Coach*"
                name="coachTypeCode"
                options={coachTypeCodeOptions}
                value={formState.coachTypeCode}
                error={errorState.coachTypeCode}
                message={errorState.coachTypeCode}
                showEmptySelection={false}
                isLoading={false}
                onChange={(newValue, newValueLabel, e) => {
                  e && e.target && e.target.value &&
                    onValueTextPairChanged(newValue, 'coachTypeCode', newValueLabel, 'coachTypeCodeName');
                }} />
            </div> : <Fragment />
          }
        </div>
        {errorState.apiError &&
          <div className="row">
            <div className="col-xs-12 usas-extra-top-margin">
              <p className={global.ErrorMessage}>{errorState.apiError}</p>
            </div>
          </div>
        }
        {errorState.uniqueRoleError &&
          <div className="row">
            <div className="col-xs-12 usas-extra-top-margin">
              <p className={global.ErrorMessage}>{errorState.uniqueRoleError}</p>
            </div>
          </div>
        }
        <div className="row">
          <div className="col-xs-12 usas-extra-top-margin usas-extra-bottom-margin">
            <PrimaryButton type="submit">{isEdit ? 'Save' : 'Add Staff'}</PrimaryButton>&nbsp;
            <SecondaryButton type="button" onClick={onCancelClicked}>Back</SecondaryButton>
          </div>
        </div>
      </form>
      <div className="usas-extra-bottom-margin" />
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={environmentVariableState.isObjLoading || isSubmitting} />
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.SAVING_MSG}
        displayPopUp={usasClubStaffState.isSaving} />
    </Fragment>
  );
};

export default ClubStaffDetail;