/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Fragment } from 'react';

import { formatDate } from '../../../../../common/utils/DateFunctions';
import Checkbox from '../../../../../common/components/checkboxes/Checkbox';

import global from '../../../../../common/components/GlobalStyle.module.css';

const LargeGrid = ({ actionLabel, persons, onSelected }) => (
  <table className={[global.UsasTable, 'visible-md visible-lg'].join(' ')}>
    <thead>
      <tr>
        <th>First Name</th>
        <th>Last Name</th>
        <th>Birth Date</th>
        <th>Organization</th>
        <th>Effective Date</th>
        <th>Expiration Date</th>
        <th>{actionLabel}</th>
      </tr>
    </thead>
    <tbody>
      {persons.map((p, i) => (
        <tr key={i}>
          <td>{p.firstName}</td>
          <td>{p.lastName}</td>
          <td>{formatDate(p.birthDate)}</td>
          <td>{p.orgDuration?.orgUnitName || ''}</td>
          <td>{p.orgDuration?.effectiveDate ? formatDate(p.orgDuration.effectiveDate) : ''}</td>
          <td>{p.orgDuration?.expirationDate ? formatDate(p.orgDuration.expirationDate) : ''}</td>
          <td>
            <Checkbox
              label=""
              name="isSelected"
              onChange={() => onSelected(p.personId)}
              checked={p.isSelected} />
          </td>
        </tr>
      ))}
    </tbody>
  </table >
);

const SmallGrid = ({ persons, onSelected }) => (
  <div className={[global.SmallTable, 'hidden-md hidden-lg'].join(' ')}>
    {persons.map((p, i) => (
      <div className={global.SmallTableRow} key={i}>
        <div className={global.SmallTableRowHead}><span className='hidden-xs'>{p.firstName} {p.lastName}</span>&nbsp;
          <div className={global.FloatRight}>
            <Checkbox
              label=""
              name="isSelected"
              onChange={() => onSelected(p.personId)}
              checked={p.isSelected} />
          </div>
        </div>
        <div className={global.SmallTableRowInfo}>
          <div className='row'>
            <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3 visible-xs'].join(' ')}>Name</div>
            <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3 visible-xs'].join(' ')}>{p.firstName} {p.lastName}</div>
            <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3'].join(' ')}>Birth Date</div>
            <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3'].join(' ')}>{formatDate(p.birthDate)}</div>
            <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3'].join(' ')}>Organization</div>
            <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3'].join(' ')}>{p.orgDuration?.orgUnitName || <span>&nbsp;</span>}</div>
            <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3'].join(' ')}>Effective Date</div>
            <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3'].join(' ')}>{p.orgDuration?.effectiveDate ? formatDate(p.orgDuration.effectiveDate) : <span>&nbsp;</span>}</div>
            <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3'].join(' ')}>Expiration Date</div>
            <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3'].join(' ')}>{p.orgDuration?.expirationDate ? formatDate(p.orgDuration.expirationDate) : <span>&nbsp;</span>}</div>
          </div>
        </div>
      </div>
    ))}
  </div>
);

const PreCompLocationStaffDupGrid = ({ actionLabel, persons, onSelected }) => (
  <Fragment>
    <LargeGrid actionLabel={actionLabel} persons={persons} onSelected={onSelected} />
    <SmallGrid persons={persons} onSelected={onSelected} />
  </Fragment>
);

export default PreCompLocationStaffDupGrid;