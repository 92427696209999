import React, { Fragment } from 'react';

import MultipleCheckbox from '../../../../../common/components/checkboxes/MultipleCheckbox';

import global from '../../../../../common/components/GlobalStyle.module.css';

const Grid = ({ data, onCheckBoxChange }) => {
  return (
    data.length > 0
      ? data.map((person, i) => (
        <Fragment key={i}>
          {person.isVisible === true
            ? (
              <div className={global.SmallTableRow}>
                <div className={global.SmallTableRowHead}><span className='hidden-xs'>
                  {`${person?.firstName} ${person?.lastName}`}</span>&nbsp;
                  <div className={global.FloatRight}>
                    <MultipleCheckbox
                      checked={person?.isInGroup}
                      onChange={(e) => { onCheckBoxChange(person?.personId); }}
                      id={person?.personId}
                    />
                  </div>
                </div>
                <div className={global.SmallTableRowInfo}>
                  <div className='row'>
                    <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3 visible-xs'].join(' ')}>Name</div>
                    <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3 visible-xs'].join(' ')}>{`${person?.firstName} ${person?.lastName}`}</div>
                    <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3'].join(' ')}>Age</div>
                    <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3'].join(' ')}>{person.age || <span>&nbsp;</span>}</div>
                    <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3'].join(' ')}>Competition Category</div>
                    <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3'].join(' ')}>{person.typeName || <span>&nbsp;</span>}</div>
                  </div>
                </div>
              </div>
            )
            : <Fragment />
          }
        </Fragment>
      )) : (
        <div className={global.SmallTableRow}>
          <div className={global.SmallTableRowHead}>
            &nbsp;
          </div>
          <div className={global.SmallTableRowInfo}>
            <div className={global.SmallTableRowLabels}>
              No Athletes
            </div>
          </div>
        </div>
      )
  );
};

const SmallGrid = ({ data, onCheckBoxChange }) => (
  <Fragment>
    <div className={[global.SmallTable, 'hidden-md hidden-lg'].join(' ')}>
      <Grid
        data={data}
        onCheckBoxChange={onCheckBoxChange} />
    </div>
  </Fragment>
);

export default SmallGrid;