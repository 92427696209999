import Constants from '../../../../common/utils/Constants';
import { isValidITIN, isValidEIN } from '../../../../common/utils/validation';

export const localValidate = (formState) => {
  let errors = {};

  if (formState.legalEntityTypeId === Constants.DEFAULT_ID) {
    errors.legalEntityTypeId = 'Legal Entity Type is required'
  }

  if (formState.legalEntityName.trim().length === 0) {
    errors.legalEntityName = 'Legal Entity Name is required'
  } else if (formState.legalEntityName.trim().length > 100) {
    errors.legalEntityName = 'Legal Entity Name cannot exceed 100 characters'
  }

  if (formState.legalEntityTaxId.trim().length === 0) {
    errors.legalEntityTaxId = 'Legal Entity Tax Id is required'
  }
  else if (!isValidITIN(formState.legalEntityTaxId.trim()) && !isValidEIN(formState.legalEntityTaxId.trim())) {
    errors.legalEntityTaxId = 'Legal Entity Tax Id must be in ITIN (XXX-XX-XXXX) or EIN (XX-XXXXXXX) format'
  }

  return errors;
};

const ClubRegistrationLegalEntitiesDetailValidation = async (formState) => {
  let errors = localValidate(formState);

  return errors ? errors : {};
}

export default ClubRegistrationLegalEntitiesDetailValidation;