import React, { Fragment } from 'react';

import usePreCompOrganizationContacts from './UsePreCompOrganizationContacts';

import PreCompOrganizationContactsGrid from '../components/grids/PreCompOrganizationContactsGrid';

import PopUpModal from '../../../../common/components/dialogs/PopUpModal';
import Headings from '../../../../common/components/headings/Headings';
import ActionIntraPageButton from '../../../../common/components/buttons/ActionIntraPageButton';
import Constants from '../../../../common/utils/Constants';

const PreCompOrganizationContacts = () => {
  const {
    isSaving,
    isLoading,
    preCompOrganizationContactsState,
    onAddStaff,
    onDeleteStaff
  } = usePreCompOrganizationContacts();

  return (
    <Fragment>
      <Headings.H3>Organization Contacts</Headings.H3>
      <div className="row">
        <div className="col-xs-12 usas-extra-top-margin">
          <ActionIntraPageButton onClick={onAddStaff}>Add Contact</ActionIntraPageButton>
        </div>
      </div>
      <div className="row usas-extra-top-margin usas-extra-bottom-margin">
        <div className="col-xs-12">
          <PreCompOrganizationContactsGrid
            data={preCompOrganizationContactsState.arrayData}
            isLoading={preCompOrganizationContactsState.isArrayLoading}
            onDelete={onDeleteStaff} />
        </div>
      </div>
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.SAVING_MSG}
        displayPopUp={isSaving} />
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={isLoading} />
    </Fragment>
  );
};

export default PreCompOrganizationContacts;