import { BASIC_INITIAL_STATE } from '../../../common/utils/HttpHelper';

import getClubTouchpointData from './GetClubTouchpointData';
import postClubTouchpointData from './PostClubTouchpointData';
import putClubTouchpointData from './PutClubTouchpointData';
import deleteClubTouchpointData from './DeleteClubTouchpointData';

const INITIAL_STATE = {
  ...BASIC_INITIAL_STATE
};

const ClubTouchpointData = {
  INITIAL_STATE,
  getClubTouchpointData,
  postClubTouchpointData,
  putClubTouchpointData,
  deleteClubTouchpointData
};

export default ClubTouchpointData;