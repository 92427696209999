/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useNavigate, useLocation } from '../../../../common/wrappers/ReactRouterDom';

import validate from './ClubLegalEntitiesDetailValidation';

import NavLinks from '../NavLinks';

import useLegalEntityTypeData from '../../../state/legalEntity/UseLegalEntityTypeData';

import useBaseUsasClubData from '../../../../common/state/clubBase/UseBaseUsasClubData';

import useForm from '../../../../common/utils/UseForm';
import Constants from '../../../../common/utils/Constants';

const useClubLegalEntitiesDetail = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { baseUsasClubState, putBaseUsasClub } = useBaseUsasClubData();
  const { legalEntityTypeState, setLegalEntityTypeState, getLegalEntityTypes } = useLegalEntityTypeData();
  const [state, setState] = useState({ tryRedirect: false });
  const {
    formState,
    errorState,
    updateFormState,
    handleSubmit,
    isDirty,
    setFormData,
    setErrors,
    onValueTextPairChanged }
    = useForm(getFormInitialState, submitFormCallback, validate);

  function submitFormCallback() {
    //Check for legal entity dupes
    let dupLegalEntity = false;
    let selectedLegalEntity = {};
    if (location.state && location.state.usasClubLegalEntityId > 0) {
      selectedLegalEntity = baseUsasClubState.objData.usasClubLegalEntities.find(
        e => e.usasClubLegalEntityId === location.state.usasClubLegalEntityId);
    }
    for (const legalEntity of baseUsasClubState.objData.usasClubLegalEntities) {
      //Don't check against entity being edited
      if ((Object.keys(selectedLegalEntity).length > 0 && selectedLegalEntity.usasClubLegalEntityId !== legalEntity.usasClubLegalEntityId) ||
        Object.keys(selectedLegalEntity).length === 0) {
        if (legalEntity.legalEntityName === formState.legalEntityName && legalEntity.legalEntityTaxId === formState.legalEntityTaxId) {
          dupLegalEntity = true;
          setErrors({
            ...errorState, legalEntityName: 'This Legal Entity Name is already linked to the club',
            legalEntityTaxId: 'This Legal Entity Tax Id is already linked to the club'
          });
        }
        else if (legalEntity.legalEntityName !== formState.legalEntityName && legalEntity.legalEntityTaxId === formState.legalEntityTaxId) {
          dupLegalEntity = true;
          setErrors({ ...errorState, legalEntityTaxId: 'This Legal Entity Tax Id is already linked to the club' });
        }
        else if (legalEntity.legalEntityName === formState.legalEntityName && legalEntity.legalEntityTaxId !== formState.legalEntityTaxId) {
          dupLegalEntity = true;
          setErrors({ ...errorState, legalEntityName: 'This Legal Entity Name is already linked to the club' });
        }
      }
    }
    if (dupLegalEntity === false) {
      putBaseUsasClub(baseUsasClubState.objData.usasClubId, editBaseUsasClubObj());
      setState({ ...state, tryRedirect: true });
    }
  };

  const editBaseUsasClubObj = () => {
    let baseUsasClubCopy = JSON.parse(JSON.stringify(baseUsasClubState.objData));

    //Edit
    if (location.state && location.state.usasClubLegalEntityId > 0) {
      const selectedLegalEntityIndex = baseUsasClubState.objData.usasClubLegalEntities.findIndex(
        e => e.usasClubLegalEntityId === location.state.usasClubLegalEntityId);
      baseUsasClubCopy.usasClubLegalEntities[selectedLegalEntityIndex] = {
        ...baseUsasClubCopy.usasClubLegalEntities[selectedLegalEntityIndex],
        legalEntityName: formState.legalEntityName.trim(),
        legalEntityTaxId: formState.legalEntityTaxId.trim(),
        legalEntityTypeId: formState.legalEntityTypeId
      };
    }
    //Add
    else {
      baseUsasClubCopy.usasClubLegalEntities.push({
        usasClubId: baseUsasClubCopy.usasClubId,
        legalEntityName: formState.legalEntityName.trim(),
        legalEntityTaxId: formState.legalEntityTaxId.trim(),
        legalEntityTypeId: formState.legalEntityTypeId
      });
    }
    return baseUsasClubCopy;
  };

  const onSaveClicked = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    handleSubmit();
    if (isDirty === false) {
      setState({ ...state, tryRedirect: true });
    }
  };

  const onCancelClicked = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    navigate(NavLinks.CLUB_LEGAL_ENTITIES);
  };

  useEffect(() => {
    if (baseUsasClubState.isSaved === true && state.tryRedirect === true) {
      navigate(NavLinks.CLUB_LEGAL_ENTITIES);
    }
  }, [baseUsasClubState, state.tryRedirect]);

  useEffect(() => {
    if (legalEntityTypeState.isArrayLoaded === true && legalEntityTypeState.areItemsLoaded === true) {
      if (location.state && location.state.usasClubLegalEntityId > 0) {
        const selectedLegalEntity = baseUsasClubState.objData.usasClubLegalEntities.find(
          e => e.usasClubLegalEntityId === location.state.usasClubLegalEntityId);
        setFormData({
          ...formState,
          usasClubLegalEntityId: selectedLegalEntity.usasClubLegalEntityId,
          legalEntityName: selectedLegalEntity.legalEntityName,
          legalEntityTaxId: selectedLegalEntity.legalEntityTaxId,
          legalEntityTypeId: selectedLegalEntity.legalEntityTypeId
        });
      }
    }
  }, [legalEntityTypeState]);

  useEffect(() => {
    if (baseUsasClubState.isObjLoaded === true) {
      if (legalEntityTypeState.isArrayLoading !== true && legalEntityTypeState.isArrayLoaded !== true) {
        const getLegalEntityTypePromise = getLegalEntityTypes();

        if (getLegalEntityTypePromise !== null) {
          getLegalEntityTypePromise.then((newState) => {
            if (newState !== null) {
              const items = newState.arrayData.map(x => { return { id: x.legalEntityTypeId, name: x.typeName } });
              items.unshift({ id: Constants.DEFAULT_ID, name: '--' })

              setLegalEntityTypeState({
                ...newState,
                items,
                areItemsLoaded: true
              });
            }
          }).catch((e) => {
            //TODO context error
          });
        }
      }
    }
  }, []);

  return {
    baseUsasClubState,
    formState,
    errorState,
    legalEntityTypeState,
    onFormValueChanged: updateFormState,
    handleSubmit,
    onSaveClicked,
    onCancelClicked,
    onValueTextPairChanged
  };
};

export default useClubLegalEntitiesDetail;

function getFormInitialState() {
  return {
    usasClubLegalEntityId: Constants.DEFAULT_ID,
    legalEntityName: '',
    legalEntityTaxId: '',
    legalEntityTypeId: Constants.DEFAULT_ID,
    typeName: ''
  };
};