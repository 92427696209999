import React, { Fragment } from 'react';

import StaffRoleReportGridLarge from './StaffRoleReportGridLarge';
import StaffRoleReportGridSmall from './StaffRoleReportGridSmall';

const StaffRoleReportGrid = ({ data, isLoading, roleName = 'Staff' }) => (
  <Fragment>
    <StaffRoleReportGridLarge data={data} isLoading={isLoading} roleName={roleName} />
    <StaffRoleReportGridSmall data={data} isLoading={isLoading} roleName={roleName} />
  </Fragment>
);

export default StaffRoleReportGrid;