import { useEffect, useState } from 'react';
import useCourseData from '../../../state/course/UseCourseData';

const useClubAffiliationTypeMultiselect = () => {
  const { courseState, getCourses } = useCourseData();
  const [options, setOptions] = useState([]);

  useEffect(() => {
    if (courseState.isLoading === false && courseState.isLoaded === false) {
      getCourses();
    } else if (courseState.isLoading === false && courseState.isLoaded === true) {
      const courseOptions = [];

      for (const course of courseState.data) {
        if (course.courseId > 0) {
          courseOptions.push({ id: course.courseId, name: course.courseCode });
        }
      }

      setOptions(courseOptions);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [courseState]);

  return {
    courseState,
    options
  };
};

export default useClubAffiliationTypeMultiselect;