const KpiOverrideScoreFormValidation = async (formState) => {
  let errors = {};

  if (formState.scoreEntryOverride === true) {
    if (formState.score === '' || parseInt(formState.score) < 0) {
      errors.score = `Score must be a positive number`
    } else if (parseInt(formState.score) < formState.minValue || parseInt(formState.score) > formState.maxValue) {
      errors.score = `Score must be in the allowed point range`
    }
  }

  return errors ? errors : {};
};

export default KpiOverrideScoreFormValidation;