import React from 'react';

import EditIcon from "../../../../../common/components/icons/EditIcon";

import { formatDate } from "../../../../../common/utils/DateFunctions";

import global from '../../../../../common/components/GlobalStyle.module.css';

const GridRow = ({ club, onEdit }) => (
  <div className={global.SmallTableRow}>
    <div className={global.SmallTableRowHead}><span className='hidden-xs'>{club.clubName || ''}</span>&nbsp;
      <button className={global.IconButton} type="button" onClick={(e) => onEdit(e, club.orgUnitId, club.clubName)}>
        <EditIcon />
      </button>
    </div>
    <div className={global.SmallTableRowInfo}>
      <div className='row'>
        <div className={[global.SmallTableRowLabels, 'col-xs-6 visible-xs'].join(' ')}>Club Name</div>
        <div className={[global.SmallTableRowData, 'col-xs-6 visible-xs'].join(' ')}>{club.clubName || <span>&nbsp;</span>}</div>
        <div className={[global.SmallTableRowLabels, 'col-xs-6'].join(' ')}>Expiration Date</div>
        <div className={[global.SmallTableRowData, 'col-xs-6'].join(' ')}>{club.expirationDate ? formatDate(club.expirationDate) : <span>&nbsp;</span>}</div>
      </div>
    </div>
  </div>
);

const SATSearchGridSmall = ({ data, isLoading, onEdit }) => (
  <div className={[global.SmallTable, 'hidden-md hidden-lg'].join(' ')}>
    {isLoading === true
      ? (
        <div className={global.SmallTableRow}>
          <div className={global.SmallTableRowHead}>
            &nbsp;
          </div>
          <div className={global.SmallTableRowInfo}>
            <div className={global.SmallTableRowLabels}>Loading...</div>
          </div>
        </div>
      ) : Array.isArray(data) && data.length > 0
        ? data.map((club, i) => (
          <GridRow
            key={i}
            club={club}
            onEdit={onEdit} />
        )) : (
          <div className={global.SmallTableRow}>
            <div className={global.SmallTableRowHead}>
              &nbsp;
            </div>
            <div className={global.SmallTableRowInfo}>
              <div className={global.SmallTableRowLabels}>No Clubs</div>
            </div>
          </div>
        )
    }
  </div >
);

export default SATSearchGridSmall;