import React, { createContext, useState } from 'react';

export const LearnToSwimFilterStateContext = createContext();

const INITIAL_STATE = {
  filterObject: {},
  showFilters: true
};

const LearnToSwimFilterContextProvider = ({ children }) => {
  const stateHook = useState(INITIAL_STATE);

  return (
    <LearnToSwimFilterStateContext.Provider value={stateHook}>
      {children}
    </LearnToSwimFilterStateContext.Provider>
  );
};

export default LearnToSwimFilterContextProvider;