/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useNavigate, useLocation } from '../../../../common/wrappers/ReactRouterDom';

import NavLinks from '../NavLinks';

import { createSATParticipants } from '../utils/SATUtils';

import validate from '../components/forms/SATParticipantFormValidation';

import usePrizeData from "../../../state/prize/UsePrizeData";
import useSwimAThonResultData from '../../../state/swimAThon/swimAThonResult/UseSwimAThonResultData';

import useForm from '../../../../common/utils/UseForm';
import Constants from '../../../../common/utils/Constants';

const useClubSwimAThonParticipantDetail = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { swimAThonResultState, updateResultObj } = useSwimAThonResultData();
  const { prizeState } = usePrizeData();
  const { formState, errorState, updateFormState, onValueTextPairChanged, handleSubmit, setFormData, setFormState
  } = useForm(getFormInitialState, submitFormCallback, validate);
  const [uiState, setUiState] = useState({ tryRedirect: false });

  const onCancelClicked = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    navigate(NavLinks.CLUB_SWIMATHON_RESULTS_DETAIL_PRIZEWINNERS)
  };

  const onAmountRaisedChanged = (amountRaised) => {
    const filteredData = prizeState.data.filter(prize => prize.prizeMinAmount <= amountRaised  && prize.isActive === true);

    if (filteredData.length > 0) {
      const satPrize = filteredData.find(x => x.satPrizeId === formState.satPrizeId);
      const highestPrizeIndex = filteredData.length -1;

      setFormState({
        ...formState,
        amountRaised,
        satPrizeId: satPrize?.satPrizeId || filteredData[highestPrizeIndex].satPrizeId,
        satPrizeName: satPrize?.description || filteredData[highestPrizeIndex].description
      });
    } else {
      setFormState({
        ...formState,
        amountRaised,
        satPrizeId: Constants.DEFAULT_ID,
        satPrizeName: ''
      });
    }
  };

  useEffect(() => {
    //Wait for add to complete before redirecting
    if (uiState.tryRedirect === true) {
      navigate(NavLinks.CLUB_SWIMATHON_RESULTS_DETAIL_PRIZEWINNERS)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uiState.tryRedirect]);

  useEffect(() => {
    if (location.state?.satParticipantId && prizeState.isLoaded === true
      && Array.isArray(swimAThonResultState.objData.satParticipant) && swimAThonResultState.objData.satParticipant.length > 0) {
      const selectedParticipant = swimAThonResultState.objData.satParticipant.find(
        p => p.satParticipantId === location.state.satParticipantId);
      const filteredData = prizeState.data.filter(prize => prize.prizeMinAmount <= selectedParticipant.amountRaised);
      
      const satPrize = filteredData.find(x => x.satPrizeId === selectedParticipant.satPrizeId);
      


      if (selectedParticipant) {
        setFormData({
          ...formState,
          amountRaised: selectedParticipant.amountRaised || 0,
          participantName: selectedParticipant.participantName || '',
          satPrizeId: satPrize?.satPrizeId || Constants.DEFAULT_ID,
          satPrizeName: satPrize?.description || ''
        }, true);
      }
    }
  }, [prizeState]);

  function submitFormCallback(formState) {
    const newResultObj = JSON.parse(JSON.stringify(swimAThonResultState.objData));


    newResultObj.satParticipant = createSATParticipants(formState, swimAThonResultState.objData.satResultId, newResultObj.satParticipant || [], location.state?.satParticipantId);

    updateResultObj(newResultObj);

    setUiState({ ...uiState, tryRedirect: true });
  };

  function getFormInitialState() {
    return {
      amountRaised: '',
      participantName: '',
      satPrizeId: Constants.DEFAULT_ID,
      satPrizeName: ''
    };
  };

  return {
    isEdit: location.state?.satParticipantId ? true : false,
    formState,
    errorState,
    onCancelClicked,
    onAmountRaisedChanged,
    onFormValueChanged: updateFormState,
    onValueTextPairChanged,
    handleSubmit
  };
};

export default useClubSwimAThonParticipantDetail;