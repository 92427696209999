import { useState } from 'react';

import ClubSwimAThonLeaderboardData from './ClubSwimAThonLeaderboardData';

const useClubSwimAThonLeaderboardData = () => {
  const [clubSwimAThonLeaderboardState, setClubSwimAThonLeaderboardState] = useState(ClubSwimAThonLeaderboardData.INITIAL_STATE);

  const getClubSwimAThonLeaderboard = (orgUnitId) => {
    ClubSwimAThonLeaderboardData.getClubSwimAThonLeaderboard(orgUnitId, clubSwimAThonLeaderboardState, setClubSwimAThonLeaderboardState);
  };

  return {
    clubSwimAThonLeaderboardState,
    getClubSwimAThonLeaderboard
  };
};

export default useClubSwimAThonLeaderboardData;