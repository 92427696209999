import { BASIC_INITIAL_STATE } from '../../../common/utils/HttpHelper';

import getUsasClubPreCompLinksData from './GetUsasClubPreCompLinksData';
import postUsasClubPreCompLinksData from './PostUsasClubPreCompLinksData';
import deleteUsasClubPreCompLinksData from './DeleteUsasClubPreCompLinksData';

const INITIAL_STATE = {
  ...BASIC_INITIAL_STATE
};

const UsasClubPreCompLinksData = {
  INITIAL_STATE, getUsasClubPreCompLinksData, postUsasClubPreCompLinksData, deleteUsasClubPreCompLinksData
};

export default UsasClubPreCompLinksData;