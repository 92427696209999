import { Fragment } from 'react';

import useLearnToSwimBulkImport from './UseLearnToSwimBulkImport';

import PrimaryButton from '../../../../common/components/buttons/PrimaryButton';
import SecondaryButton from '../../../../common/components/buttons/SecondaryButton';
import PopUpModal from '../../../../common/components/dialogs/PopUpModal';
import Headings from '../../../../common/components/headings/Headings';

import Constants from '../../../../common/utils/Constants';

import global from '../../../../common/components/GlobalStyle.module.css';

const LEARN_TO_SWIM_BULK_IMPORT = 'LtsImport';

const LearnToSwimBulkImport = () => {
  const {
    isLoading,
    isSaving,
    learnToSwimBulkImportUploadState,
    learnToSwimProvidersArrayState,
    fileState,
    fileErrorState,
    onLoadFile,
    onDownloadJSON,
    onUploadLearnToSwimProviders,
    GET_DOWNLOAD_TEMPLATE_PATH,
    modalState,
    onModalCanceled,
    onContinueClicked,
    resetLearnToSwimBulkImportUploadState
  } = useLearnToSwimBulkImport();

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>Learn To Swim Bulk Import</Headings.H3>
        </div>
      </div>
      <div className="row">
        <div className='col-xs-12 usas-extra-top-margin usas-extra-bottom-margin'>
          <Headings.H6>Use the Following Template To Bulk Import</Headings.H6>
        </div>
        <div className="col-xs-12 usas-extra-bottom-margin">
          <SecondaryButton type="button" onClick={(event) => {
            event.preventDefault();
            window.location.href = import.meta.env.VITE_COMMONAPI_URL + GET_DOWNLOAD_TEMPLATE_PATH + LEARN_TO_SWIM_BULK_IMPORT;
          }}>Download Template</SecondaryButton> &nbsp;
        </div>
      </div>
      <div className="row">
        <div className='col-xs-12 usas-extra-top-margin usas-extra-bottom-margin'>
          <Headings.H6>Select Your Bulk Import File and Upload Your Learn To Swim Providers.</Headings.H6>
        </div>
        <div className="col-xs-12 usas-extra-top-margin">
          <label htmlFor="learn-to-swim-provider-id-select-file" className={global.FileUpload}>
            Select A File
          </label>
          <input hidden id="learn-to-swim-provider-id-select-file" type="file" onChange={(e) => {
            onLoadFile(e);
            resetLearnToSwimBulkImportUploadState();
          }} />
          &nbsp;(*.csv)
        </div>
      </div>
      <div className="row">
        {fileErrorState && <div className="col-xs-12"><p className={global.ErrorMessage}>{fileErrorState}</p></div>}
      </div>
      {!fileErrorState && fileState.size > 0 &&
        <div className="row">
          <div className="col-xs-12 usas-extra-top-margin">
            <p><b>Learn To Swim Providers Found:</b> {learnToSwimProvidersArrayState.length}</p>
          </div>
          {learnToSwimProvidersArrayState.length === 0 &&
            <div className="col-xs-12"><p className={global.ErrorMessage}>No Learn To Swim Providers were detected inside {fileState.name}</p></div>
          }
        </div>
      }
      {!fileErrorState && learnToSwimBulkImportUploadState.isSaved !== true && fileState.size > 0 && learnToSwimProvidersArrayState.length > 0 &&
        <div className='row'>
          <div className="col-xs-12 usas-extra-top-margin usas-extra-bottom-margin">
            <SecondaryButton type="button" onClick={onDownloadJSON}>Download JSON File</SecondaryButton>&nbsp;
            <PrimaryButton type="button" onClick={onUploadLearnToSwimProviders}>Upload Learn To Swim Providers</PrimaryButton>
          </div>
        </div>
      }
      {learnToSwimBulkImportUploadState?.message && learnToSwimBulkImportUploadState?.message !== '' && learnToSwimBulkImportUploadState?.message !== Constants.SAVING_MSG &&
        learnToSwimBulkImportUploadState?.message !== Constants.LOADING_MSG &&
        <div className='row'>
          <div className="col-xs-12 usas-extra-bottom-margin">
            <p className={global.ErrorMessage}>An error occured upon uploading. Please check the formatting of your selected file.</p>
          </div>
        </div>
      }
      {learnToSwimBulkImportUploadState.isSaved === true &&
        <div className='row'>
          <div className="col-xs-12 usas-extra-bottom-margin">
            <p><b>Upload Status:</b> {learnToSwimBulkImportUploadState.objData?.message}</p>
          </div>
        </div>
      }
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={isLoading} />
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.UPLOADING_MSG}
        displayPopUp={isSaving} />
      <PopUpModal
        widthPct={90}
        maxWidth={720}
        title={modalState.modalTitle}
        displayPopUp={modalState.displayPopUp && !learnToSwimBulkImportUploadState.isSaving}
        onModalCanceled={onModalCanceled}>
        <form>
          <div className="row">
            <div className="col-xs-12 usas-extra-bottom-margin">
              <p><b>Upload Status:</b> {learnToSwimBulkImportUploadState.objData?.message}</p>
            </div>
            <div className="col-xs-12 usas-extra-bottom-margin">
              <b>Your learn to swim providers have been uploaded!</b>
            </div>
            <div className="col-xs-12 usas-extra-top-margin">
              <SecondaryButton type="button" onClick={onModalCanceled}>Close</SecondaryButton>
            </div>
          </div>
        </form>
      </PopUpModal>
    </Fragment >
  );
};

export default LearnToSwimBulkImport;