import React, { Fragment } from "react";

import Dropdown from "../../../../../common/components/dropdowns/Dropdown";
import Input from "../../../../../common/components/inputs/Input";

const SATSearchForm = ({ formState, errorState, onFormValueChanged, onValueTextPairChanged, organizationOptions }) => (
  <Fragment>
    <div className="row">
      <div className="col-xs-12 col-md-6 col-sm-4">
        {organizationOptions.length > 0 &&
          <Dropdown
            label="Organization*"
            name="organizationId"
            options={organizationOptions}
            value={formState.organizationId}
            error={errorState.organizationId}
            message={errorState.organizationId}
            isLoading={false}
            onChange={(newValue, newValueLabel, e) => {
              e && e.target && e.target.value &&
                onValueTextPairChanged(newValue, 'organizationId', newValueLabel, 'organizationName');
            }} />
        }
      </div>
      <div className="col-xs-12 col-md-6 col-sm-4">
        <Input
          label="Club Name*"
          name="clubName"
          value={formState.clubName}
          error={errorState.clubName}
          message={errorState.clubName}
          onChange={(value) => { onFormValueChanged('clubName', value); }} />
      </div>
    </div>
  </Fragment>
);

export default SATSearchForm;