import React, { createContext, useState } from 'react';

import TeamFacilityLinksData from './TeamFacilityLinksData';

export const TeamFacilityLinksStateContext = createContext();

const TeamFacilityLinksContextProvider = ({ children }) => {
  const stateHook = useState(TeamFacilityLinksData.INITIAL_STATE);

  return (
    <TeamFacilityLinksStateContext.Provider value={stateHook}>
      {children}
    </TeamFacilityLinksStateContext.Provider>
  );
};

export default TeamFacilityLinksContextProvider;