import { useState } from 'react';

import ClubGroupData from './ClubGroupsData';

const useClubGroupsData = () => {
  const [clubGroupState, setClubGroupState] = useState(ClubGroupData.INITIAL_STATE);

  const getClubGroups = (orgUnitId) => {
    ClubGroupData.getClubGroupsData(orgUnitId, clubGroupState, setClubGroupState);
  };

  const getClubGroupDetails = (orgGroupId) => {
    ClubGroupData.getClubGroupDetailsData(orgGroupId, clubGroupState, setClubGroupState);
  };

  const getClubGroupMembersInfo = (orgGroupId, localState, setLocalState) => {
    ClubGroupData.getClubGroupMembersData(orgGroupId, localState, setLocalState);
  };

  const postClubGroup = (orgGroupObj) => {
    ClubGroupData.postClubGroupData(orgGroupObj, clubGroupState, setClubGroupState);
  };

  const putClubGroup = (orgGroupId, orgGroupObj) => {
    ClubGroupData.putClubGroupData(orgGroupId, orgGroupObj, clubGroupState, setClubGroupState);
  };

  const deleteClubGroup = (orgGroupId) => {
    ClubGroupData.deleteClubGroupData(orgGroupId, clubGroupState, setClubGroupState);
  };

  return {
    clubGroupState,
    setClubGroupState,
    getClubGroups,
    getClubGroupDetails,
    getClubGroupMembersInfo,
    postClubGroup,
    putClubGroup,
    deleteClubGroup
  };
};

export default useClubGroupsData;