import React, { Fragment } from 'react';

import useLearnToSwimRegistrationFacilitiesSearch from './UseLearnToSwimRegistrationFacilitiesSearch';

import LearnToSwimRegistrationLeftNav from '../components/leftNav/LearnToSwimRegistrationLeftNav';
import { FACILITIES_NAME } from '../components/leftNav/LearnToSwimRegistrationLeftNavConstants';

import SearchFacility from '../../../../common/components/searches/searchFacility/SearchFacility';
import PopUpModal from '../../../../common/components/dialogs/PopUpModal';
import SecondaryButton from '../../../../common/components/buttons/SecondaryButton';

const LearnToSwimRegistrationFacilitiesSearch = () => {
  const {
    modalState,
    onLinkFacilityClicked,
    onCancelClicked,
    onModalCancelClicked,
    onAddANewFacilityClicked
  } = useLearnToSwimRegistrationFacilitiesSearch();

  return (
    <Fragment>
      <LearnToSwimRegistrationLeftNav viewName={FACILITIES_NAME}>
        <SearchFacility
          pageTitle={"Facility Search"}
          onEdit={onLinkFacilityClicked}
          onCancelClicked={onCancelClicked}
          onAddANewFacility={onAddANewFacilityClicked}
          contactLabel={'Learn To Swim'} />
        <PopUpModal
          widthPct={90}
          maxWidth={720}
          title="Duplicate Facility Error"
          displayPopUp={modalState.displayModal}
          onModalCanceled={onModalCancelClicked}>
          <div className="row">
            <div className="col-xs-12">
              <p>{modalState.facilityName} is already linked to this location</p>
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12 usas-extra-top-margin">
              <SecondaryButton type="button" onClick={onModalCancelClicked}>Cancel</SecondaryButton>
            </div>
          </div>
        </PopUpModal>
      </LearnToSwimRegistrationLeftNav>
    </Fragment>
  );
};

export default LearnToSwimRegistrationFacilitiesSearch;