import React, { createContext, useState } from 'react';

import PreCompLocationData from './PreCompLocationData';

export const PreCompLocationStateContext = createContext();

const PreCompLocationContextProvider = ({ children }) => {
  const stateHook = useState(PreCompLocationData.INITIAL_STATE);

  return (
    <PreCompLocationStateContext.Provider value={stateHook}>
      {children}
    </PreCompLocationStateContext.Provider>
  );
};

export default PreCompLocationContextProvider;