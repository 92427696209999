import { useEffect, useState } from 'react';
import { useNavigate, useLocation } from '../../../../common/wrappers/ReactRouterDom';

import validate from './ClubFacilityDetailValidation';

import NavLinks from '../NavLinks';

import useUsasClubFacilityLinksData from '../../../state/clubFacilityLinks/UseUsasClubFacilityLinksData';

import useBaseUsasClubData from '../../../../common/state/clubBase/UseBaseUsasClubData';

import useForm from '../../../../common/utils/UseForm';
import Constants from '../../../../common/utils/Constants';

const useClubFacilityDetails = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [state, setState] = useState({
    tryRedirect: false,
    isFacilityInClubObj: false, facilityId: Constants.DEFAULT_ID, isNewFacility: false
  });
  const { usasClubFacilityLinksState, putUsasClubFacilityLinks, getUsasClubFacilityLinks } = useUsasClubFacilityLinksData();
  const { baseUsasClubState } = useBaseUsasClubData();
  const {
    formState,
    errorState,
    setFormState,
    handleSubmit,
    onFormValueChanged,
    onValueTextPairChanged,
    isDirty,
    isSubmitting
  } = useForm(getInitialClubFacilityState, submitFormCallback, validate);

  function submitFormCallback() {
    putUsasClubFacilityLinks(baseUsasClubState.objData.usasClubId, formState.orgUnitFacilityId, constructObjectForUpdate());
    setState({ ...state, tryRedirect: true });
  };

  function constructObjectForUpdate() {
    let obj = {
      orgUnitFacilityId: formState.orgUnitFacilityId,
      orgUnitId: formState.orgUnitId,
      facilityId: formState.facilityId,
      effectiveDate: null,
      expirationDate: null,
      facility: undefined,
      clubFacilityContactName: formState.clubFacilityContactName.trim(),
      clubFacilityContactEmailAddress: formState.clubFacilityContactEmailAddress.trim(),
      clubFacilityContactPhoneNumber: formState.clubFacilityContactPhoneNumber.trim(),
      facilityContactName: formState.contactName.trim(),
      facilityContactEmailAddress: formState.contactEmailAddress.trim(),
      facilityContactPhoneNumber: formState.contactPhoneNumber.trim(),
      trainingFacility: formState.trainingFacility
    };

    return obj;
  };

  const onSaveClicked = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    handleSubmit();
    if (isDirty === false) {
      setState({ ...state, tryRedirect: true });
    }
  };

  const onCancelGoToClubFacilities = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    navigate(NavLinks.CLUB_FACILITIES);
  };

  useEffect(() => {
    if (location.state && location.state.facilityId
      && location.state.isNewFacility === true) {
      getUsasClubFacilityLinks(baseUsasClubState.objData.usasClubId);
      setState({ ...state, isFacilityInClubObj: true, facilityId: location.state.facilityId, isNewFacility: true });
    }
    else if (location.state && location.state.facilityId
      && location.state.isNewFacility === undefined) {
      setState({ ...state, isFacilityInClubObj: true, facilityId: location.state.facilityId, isNewFacility: false });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (usasClubFacilityLinksState.isArrayLoaded === true && usasClubFacilityLinksState.isSaving === false) {
      if (state.isFacilityInClubObj === true && state.facilityId > 0) {
        const selectedFacility = usasClubFacilityLinksState.arrayData.find((facility) =>
          facility.facilityId === state.facilityId);

        setFormState({
          ...formState,
          facilityId: selectedFacility.facilityId || Constants.DEFAULT_ID,
          facilityName: selectedFacility.facility.facilityName || '',
          address1: selectedFacility.facility.address1 || '',
          address2: selectedFacility.facility.address2 || '',
          city: selectedFacility.facility.city || '',
          stateCode: selectedFacility.facility.stateCode || '',
          postalCode: selectedFacility.facility.postalCode || '',
          contactName: selectedFacility.facility.contactName || '',
          contactEmailAddress: selectedFacility.facility.contactEmailAddress || '',
          contactPhoneNumber: selectedFacility.facility.contactPhoneNumber || '',
          hostMeet: selectedFacility.facility.hostMeet || '',
          clubFacilityContactName: selectedFacility.clubFacilityContactName || '',
          clubFacilityContactEmailAddress: selectedFacility.clubFacilityContactEmailAddress || '',
          clubFacilityContactPhoneNumber: selectedFacility.clubFacilityContactPhoneNumber || '',
          orgUnitFacilityId: selectedFacility.orgUnitFacilityId || Constants.DEFAULT_ID,
          orgUnitId: selectedFacility.orgUnitId || '',
          trainingFacility: selectedFacility.trainingFacility !== undefined && selectedFacility.trainingFacility !== null ? selectedFacility.trainingFacility : ''
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [usasClubFacilityLinksState, state]);

  useEffect(() => {
    if (usasClubFacilityLinksState.isArrayLoaded === true && usasClubFacilityLinksState.isSaving === false && state.tryRedirect === true
      && isSubmitting === false && Object.keys(errorState).length === 0) {
      if (state.isNewFacility === true) {
        navigate(NavLinks.CLUB_FACILITIES_POOLS_DETAIL, { state: { facilityId: state.facilityId } });
      }
      else if (state.isNewFacility === false) {
        navigate(NavLinks.CLUB_FACILITIES, { state: { hasNewFacilityDetails: true } });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [usasClubFacilityLinksState, state, isSubmitting, errorState]);

  return {
    usasClubFacilityLinksState,
    formState,
    errorState,
    onSaveClicked,
    handleSubmit,
    onFormValueChanged,
    onValueTextPairChanged,
    onCancelGoToClubFacilities
  };

  function getInitialClubFacilityState() {
    return {
      facilityId: Constants.DEFAULT_ID,
      facilityName: '',
      address1: '',
      address2: '',
      city: '',
      stateCode: '',
      postalCode: '',
      contactName: '',
      contactEmailAddress: '',
      contactPhoneNumber: '',
      hostMeet: '',
      clubFacilityContactName: '',
      clubFacilityContactEmailAddress: '',
      clubFacilityContactPhoneNumber: '',
      orgUnitFacilityId: Constants.DEFAULT_ID,
      orgUnitId: '',
      trainingFacility: ''
    };
  };
};

export default useClubFacilityDetails;