import { useEffect, useState } from 'react';
import { useNavigate, useLocation } from '../../../../common/wrappers/ReactRouterDom';

import NavLinks from '../NavLinks';

import useUsasClubFacilityLinksData from '../../../state/clubFacilityLinks/UseUsasClubFacilityLinksData';
import useClubRegistrationNavData from '../../../state/clubRegistration/leftNavigation/UseClubRegistrationNavData';
import useClubRegistrationNewData from '../../../state/clubRegistrationNew/UseClubRegistrationNewData';

import useFacilityData from '../../../../common/state/facility/UseFacilityData';
import Constants from '../../../../common/utils/Constants';
import ClubRegistrationConstants from '../utils/ClubRegistrationConstants';

const ERROR_MSG = 'At least one facility is required';

const useClubRegistrationFacilities = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { clubRegistrationNavState, setClubRegistrationNavState } = useClubRegistrationNavData();
  const { clubRegistrationState } = useClubRegistrationNewData();
  const { usasClubFacilityLinksState, getUsasClubFacilityLinks, deleteUsasClubFacilityLinks } = useUsasClubFacilityLinksData();
  const { facilityState, deleteFacilityPool } = useFacilityData();
  const [state, setState] = useState({ submitButton: '', tryRedirect: false, error: '', tryGet: false });
  const [submitPrequisitesState, setSubmitPrequisitesState] = useState({ areSubmitPrerequisitesDone: false });
  const [modalState, setModalState] = useState(getInitialModalState());
  const [deleteModalState, setDeleteModalState] = useState(getInitialDeleteModalState());
  const [poolModalState, setPoolModalState] = useState(getInitialPoolModalState());

  const onDeleteFacilityFromClub = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    if (usasClubFacilityLinksState.arrayData.length === 1) {
      const navIndex = clubRegistrationNavState.findIndex(x =>
        x.name === ClubRegistrationConstants.FACILITY)
      clubRegistrationNavState[navIndex] =
      {
        ...clubRegistrationNavState[navIndex],
        isCurrent: true,
        isComplete: false,
        isVisited: true,
        arePrerequisitesDone: true
      }
      const submitNavIndex = clubRegistrationNavState.findIndex(x =>
        x.name === ClubRegistrationConstants.SUBMIT)
      clubRegistrationNavState[submitNavIndex] =
      {
        ...clubRegistrationNavState[submitNavIndex],
        isCurrent: false,
        isComplete: false,
        isVisited: false,
        arePrerequisitesDone: false
      }
      setSubmitPrequisitesState({ ...submitPrequisitesState, areSubmitPrerequisitesDone: false });
      setClubRegistrationNavState(clubRegistrationNavState);
    }

    deleteUsasClubFacilityLinks(clubRegistrationState.objData.usasClubId, deleteModalState.orgUnitFacilityId);
    setDeleteModalState({
      ...getInitialDeleteModalState()
    });
    setState({ ...state, tryGet: true });
  };

  const onAddFacility = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    navigate(NavLinks.CLUB_REGISTRATION_FACILITY_SEARCH);
  };

  const onEditFacility = (e, facilityId) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    navigate(NavLinks.CLUB_REGISTRATION_FACILITY_DETAILS, { state: { facilityId } });
  };

  const onAddPool = (e, facilityId) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    navigate(NavLinks.CLUB_REGISTRATION_FACILITY_POOL_DETAIL, { state: { facilityId } });
  }

  const onEditPool = (e, facilityId, poolId) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    navigate(NavLinks.CLUB_REGISTRATION_FACILITY_POOL_DETAIL, { state: { facilityId, poolId } });
  }

  const onDeletePool = (e, pool) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    setPoolModalState({
      ...poolModalState,
      displayPopup: true,
      poolId: pool.facilityPoolId,
      poolName: pool.facilityPoolName,
      poolDimensions: pool.poolDimensions.dimensionName
    });
  };

  const onClickDeletePool = (e, poolId) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    setPoolModalState(getInitialPoolModalState());
    deleteFacilityPool(poolId);
    setState({ ...state, tryGet: true });
  };

  const onPoolModalCanceled = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    setPoolModalState(getInitialPoolModalState());
  };

  const onDeleteModalCanceled = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    setDeleteModalState(getInitialDeleteModalState());
  };

  const onOpenDeleteModalClicked = (e, facilityId, facilityName) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    const targetIndex = usasClubFacilityLinksState.arrayData.findIndex((usasClubFacility) => usasClubFacility.facilityId === facilityId);

    if (targetIndex >= 0) {
      setDeleteModalState({
        ...deleteModalState,
        displayPopUp: true,
        facilityId: facilityId,
        facilityName: facilityName,
        facilityCity: usasClubFacilityLinksState.arrayData[targetIndex].facility.city,
        facilityStateCode: usasClubFacilityLinksState.arrayData[targetIndex].facility.stateCode,
        facilityPostalCode: usasClubFacilityLinksState.arrayData[targetIndex].facility.postalCode,
        targetIndex,
        orgUnitFacilityId: usasClubFacilityLinksState.arrayData[targetIndex].orgUnitFacilityId
      });
    }
  };

  const onContinueClicked = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    if (usasClubFacilityLinksState.arrayData.length > 0) {
      setState({ ...state, tryRedirect: true, submitButton: ClubRegistrationConstants.CONTINUE, error: '' });
    }
    else {
      setState({ ...state, submitButton: ClubRegistrationConstants.CONTINUE, error: ERROR_MSG });
    }
  }

  const onSaveAndExitClicked = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    if (usasClubFacilityLinksState.arrayData.length > 0) {
      setState({ ...state, submitButton: ClubRegistrationConstants.EXIT, error: '' });
    }
    else {
      setState({ ...state, submitButton: ClubRegistrationConstants.EXIT, error: ERROR_MSG });
    }
  }

  const onBackClicked = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    if (usasClubFacilityLinksState.arrayData.length > 0) {
      setState({ ...state, tryRedirect: true, submitButton: ClubRegistrationConstants.BACK, error: '' });
    }
    else {
      setState({ ...state, submitButton: ClubRegistrationConstants.BACK, error: ERROR_MSG });
    }
  }

  const onOpenModal = () => {
    if (state.error !== '') {
      setModalState({ ...modalState, displayPopUp: true });
    }
    else {
      setModalState({ ...modalState, modalTitle: ClubRegistrationConstants.EXIT_CONFIRMATION, displayPopUp: true });
    }
  }

  const onModalContinue = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    setState({ ...state, tryRedirect: true });
    setModalState({ ...getInitialModalState() });
  }

  const onModalCanceled = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    setModalState({ ...getInitialModalState() });
  }

  const updateNavState = () => {
    for (let i = 0; i < clubRegistrationNavState.length; i++) {
      clubRegistrationNavState[i].isCurrent = false
    }
    const navIndex = clubRegistrationNavState.findIndex(x =>
      x.name === ClubRegistrationConstants.FACILITY)
    if (usasClubFacilityLinksState.arrayData.length > 0) {
      clubRegistrationNavState[navIndex] =
      {
        ...clubRegistrationNavState[navIndex],
        isCurrent: true,
        isComplete: true,
        isVisited: true,
        arePrerequisitesDone: true
      }
    }
    else {
      clubRegistrationNavState[navIndex] =
      {
        ...clubRegistrationNavState[navIndex],
        isCurrent: true,
        isComplete: false,
        isVisited: true,
        arePrerequisitesDone: true
      }
    }
    //Submit
    let areSubmitPrerequisitesDone = true;
    let isSubmitComplete = false;
    for (let i = 0; i < clubRegistrationNavState.length; i++) {
      if (clubRegistrationNavState[i].name !== ClubRegistrationConstants.SUBMIT
        && clubRegistrationNavState[i].isComplete === false) {
        areSubmitPrerequisitesDone = false;
      }
      if (clubRegistrationNavState[i].name === ClubRegistrationConstants.SUBMIT) {
        if (clubRegistrationState.objData.status === ClubRegistrationConstants.PENDING_STATUS ||
          clubRegistrationState.objData.status === ClubRegistrationConstants.APPROVED_STATUS) {
          isSubmitComplete = true
        }
      }
    }
    const submitNavIndex = clubRegistrationNavState.findIndex(x =>
      x.name === ClubRegistrationConstants.SUBMIT)
    clubRegistrationNavState[submitNavIndex] =
    {
      ...clubRegistrationNavState[submitNavIndex],
      isCurrent: false,
      isComplete: isSubmitComplete,
      isVisited: areSubmitPrerequisitesDone === false ? false : clubRegistrationNavState[submitNavIndex].isVisited,
      arePrerequisitesDone: areSubmitPrerequisitesDone
    }
    setSubmitPrequisitesState({ ...state, areSubmitPrerequisitesDone: areSubmitPrerequisitesDone });
    setClubRegistrationNavState(clubRegistrationNavState);
  }

  useEffect(() => {
    if (clubRegistrationState.isObjLoaded === false) {
      navigate(NavLinks.CLUB_REGISTRATION_ENTRY_NEW);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (usasClubFacilityLinksState.isArrayLoaded === false) {
      if (clubRegistrationState.isObjLoaded === true && usasClubFacilityLinksState.isArrayLoading === false
        && usasClubFacilityLinksState.isSaving === false) {
        getUsasClubFacilityLinks(clubRegistrationState.objData.usasClubId);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [usasClubFacilityLinksState, clubRegistrationState])

  useEffect(() => {
    if (location.state && (location.state.addedPool === true
      || location.state.hasNewFacilityDetails === true)) {
      setState({ ...state, tryGet: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (state.tryGet === true) {
      if (clubRegistrationState.isObjLoaded === true && usasClubFacilityLinksState.isArrayLoading === false
        && usasClubFacilityLinksState.isSaving === false && facilityState.isSaving === false) {
        getUsasClubFacilityLinks(clubRegistrationState.objData.usasClubId);
        setState({ ...state, tryGet: false });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state, clubRegistrationState, usasClubFacilityLinksState, facilityState])

  useEffect(() => {
    const navIndex = clubRegistrationNavState.findIndex(x =>
      x.name === ClubRegistrationConstants.FACILITY)
    if (clubRegistrationNavState[navIndex].isCurrent === false) {
      updateNavState();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clubRegistrationNavState])

  useEffect(() => {
    const submitNavIndex = clubRegistrationNavState.findIndex(x =>
      x.name === ClubRegistrationConstants.SUBMIT)
    if (clubRegistrationNavState[submitNavIndex].arePrerequisitesDone === false
      && submitPrequisitesState.areSubmitPrerequisitesDone === true) {
      updateNavState();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clubRegistrationNavState, submitPrequisitesState.areSubmitPrerequisitesDone])

  useEffect(() => {
    if (Object.keys(clubRegistrationState.objData).length > 0) {
      updateNavState();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clubRegistrationState.objData])

  useEffect(() => {
    //Handle validation modal and navigation
    if (state.submitButton !== '' && clubRegistrationState.isObjLoaded === true) {
      if (state.tryRedirect === false) {
        if (state.error !== '' || state.submitButton === ClubRegistrationConstants.EXIT) {
          onOpenModal();
        }
      }
      if (state.tryRedirect === true) {
        if (state.error === '') {
          const navIndex = clubRegistrationNavState.findIndex(x =>
            x.name === ClubRegistrationConstants.FACILITY)
          clubRegistrationNavState[navIndex] =
          {
            ...clubRegistrationNavState[navIndex],
            isCurrent: false,
            isComplete: true,
            isVisited: true,
            arePrerequisitesDone: true
          }
          //Submit
          let areSubmitPrerequisitesDone = true;
          let isSubmitComplete = false;
          for (let i = 0; i < clubRegistrationNavState.length; i++) {
            if (clubRegistrationNavState[i].name !== ClubRegistrationConstants.SUBMIT
              && clubRegistrationNavState[i].isComplete === false) {
              areSubmitPrerequisitesDone = false;
            }
            if (clubRegistrationNavState[i].name === ClubRegistrationConstants.SUBMIT) {
              if (clubRegistrationState.objData.status === ClubRegistrationConstants.PENDING_STATUS ||
                clubRegistrationState.objData.status === ClubRegistrationConstants.APPROVED_STATUS) {
                isSubmitComplete = true
              }
            }
          }
          const submitNavIndex = clubRegistrationNavState.findIndex(x =>
            x.name === ClubRegistrationConstants.SUBMIT)
          clubRegistrationNavState[submitNavIndex] =
          {
            ...clubRegistrationNavState[submitNavIndex],
            isCurrent: false,
            isComplete: isSubmitComplete,
            isVisited: areSubmitPrerequisitesDone === false ? false : clubRegistrationNavState[submitNavIndex].isVisited,
            arePrerequisitesDone: areSubmitPrerequisitesDone
          }
          setClubRegistrationNavState(clubRegistrationNavState);
        }
        if (state.submitButton === ClubRegistrationConstants.CONTINUE) {
          navigate(NavLinks.CLUB_REGISTRATION_MISSION_STATEMENT_BUDGET);
        }
        else if (state.submitButton === ClubRegistrationConstants.EXIT) {
          navigate(NavLinks.CLUB_REGISTRATION_ENTRY_NEW);
        }
        else if (state.submitButton === ClubRegistrationConstants.BACK) {
          navigate(NavLinks.CLUB_REGISTRATION_SCREENING_POLICIES);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state, clubRegistrationState.isObjLoaded])

  return {
    usasClubFacilityLinksState,
    clubRegistrationNavState,
    clubRegistrationState,
    facilityState,
    state,
    modalState,
    onModalContinue,
    onModalCanceled,
    deleteModalState,
    poolModalState,
    onAddFacility,
    onEditFacility,
    onAddPool,
    onEditPool,
    onDeletePool,
    onClickDeletePool,
    onPoolModalCanceled,
    onDeleteModalCanceled,
    onOpenDeleteModalClicked,
    onDeleteFacilityFromClub,
    onContinueClicked,
    onSaveAndExitClicked,
    onBackClicked
  }
}

export default useClubRegistrationFacilities;

function getInitialDeleteModalState() {
  return {
    displayPopUp: false,
    modalTitle: 'Unlink Facility From Club?',
    facilityId: Constants.DEFAULT_ID,
    facilityName: '',
    facilityCity: '',
    facilityStateCode: '',
    facilityPostalCode: '',
    targetIndex: undefined,
    orgUnitFacilityId: Constants.DEFAULT_ID
  }
};

function getInitialModalState() {
  return {
    displayPopUp: false,
    modalTitle: ClubRegistrationConstants.MISSING_REQUIRED_INFORMATION
  }
};

function getInitialPoolModalState() {
  return {
    modalTitle: 'Delete this pool from the facility?',
    displayPopup: false,
    poolId: Constants.DEFAULT_ID,
    poolName: '',
    poolDimensions: ''
  };
};