import { useEffect, useState } from 'react';
import { useLocation } from '../../../../common/wrappers/ReactRouterDom';

import validate from './ClubInfoValidation';

import ClubRegistrationConstants from '../../clubRegistration/utils/ClubRegistrationConstants';

import useClubOwnerTypeData from '../../../state/clubOwnerType/UseClubOwnerTypeData';
import useBusinessEntityData from '../../../state/businessEntity/UseBusinessEntityData';

import useBaseUsasClubData from '../../../../common/state/clubBase/UseBaseUsasClubData';

import useStatesCombobox from '../../../../common/components/comboboxes/statesCombobox/UseStatesCombobox';

import useForm from '../../../../common/utils/UseForm';
import Constants from '../../../../common/utils/Constants';

const settingOptions = [
  { id: 'Rural', name: 'Rural', value: 'Rural' },
  { id: 'Urban', name: 'Urban', value: 'Urban' },
  { id: 'Suburban', name: 'Suburban', value: 'Suburban' }
];

const INITIAL_VALIDATE_STATE = {
  isValidating: false,
  validatedAddress: null
};

const useClubInfo = () => {
  const location = useLocation();
  const { baseUsasClubState, putBaseUsasClub } = useBaseUsasClubData();
  const { clubOwnerTypeState } = useClubOwnerTypeData();
  const { businessEntityState } = useBusinessEntityData();
  const { getStateNameById } = useStatesCombobox();
  const { formState, errorState, handleSubmit, updateFormState, resetForm, setFormData, onValueTextPairChanged
  } = useForm(getInitialFormState(), submitFormCallback, validate);
  const [validateState, setValidateState] = useState(INITIAL_VALIDATE_STATE);
  const [state, setState] = useState({ updateBusinessEntity: false });

  const handleSubmitProxy = async (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    setValidateState({
      isValidating: true
    });
    await handleSubmit();
    setValidateState({
      isValidating: false,
      validatedAddress: formState.validatedAddress
    });
    updateFormState('showedAddressSuggestion', true);
  };

  const addFacilityToClub = (usasClubId, orgUnitId, facilityId) => {
    let facilities = baseUsasClubState.objData.orgUnit.orgUnitFacility;

    // check if facility already associated to this club
    let found = false;
    for (var i = 0; i < facilities.length; i++) {
      if (facilities[i].facilityId === facilityId) {
        found = true;
        break;
      }
    }

    // if not already associated, create new association
    if (!found) {
      baseUsasClubState.objData.orgUnit.orgUnitFacility.push({ orgUnitId: orgUnitId, facilityId: facilityId });
      // save the updated club data
      putBaseUsasClub(usasClubId, baseUsasClubState.objData);
    }
  };

  const onCancelClicked = () => {
    setState({ ...state, updateBusinessEntity: true });
    resetForm();
  };

  const updateClubStateFromFormState = () => {
    //format club object to send to the PUT API call
    let clubToPut = JSON.parse(JSON.stringify(baseUsasClubState.objData));
    let addressObj = {};
    let usasClubAddressObj = {};
    if (clubToPut.usasClubAddress.length > 0) {
      addressObj = {
        ...clubToPut.usasClubAddress[0].address,
        address1: formState.address1.trim(),
        address2: formState.address2.trim(),
        city: formState.city.trim(),
        stateCode: formState.stateCode,
        postalCode: formState.postalCode.trim()
      };
      usasClubAddressObj = {
        ...clubToPut.usasClubAddress[0],
        address: addressObj
      };
    }
    else {
      addressObj = {
        address1: formState.address1.trim(),
        address2: formState.address2.trim(),
        city: formState.city.trim(),
        stateCode: formState.stateCode,
        postalCode: formState.postalCode.trim(),
        countryCode: 'USA'
      };
      usasClubAddressObj = {
        address: addressObj,
        addressName: "Primary",
        isPrimary: true,
        usasClubId: clubToPut.usasClubId
      };
    }

    let usasClubAddressArray = [];
    usasClubAddressArray.push(usasClubAddressObj);

    clubToPut = {
      ...clubToPut,
      yearFounded: formState.yearFounded.length > 0 ? parseInt(formState.yearFounded) : null,
      websiteAddress: formState.websiteAddress.trim(),
      clubOwnerTypeId: formState.ownerTypeId > 0 ? formState.ownerTypeId : null,
      clubSetting: formState.clubSettingId !== '' ? formState.clubSettingId : null,
      businessEntityTypeId: formState.businessEntityTypeId > 0 ? formState.businessEntityTypeId : null,
      usasClubAddress: usasClubAddressArray
    };


    return clubToPut;
  };

  useEffect(() => {
    if (baseUsasClubState.isObjLoading === false && baseUsasClubState.isSaving === false
      && baseUsasClubState.isObjLoaded === true && clubOwnerTypeState.isLoaded === true) {
      let orgLevelTypeName = 'Unknown';
      for (let i = 0; i < clubOwnerTypeState.data.length; i++) {
        if (clubOwnerTypeState.data[i].clubOwnerTypeId === formState.ownerTypeId) {
          orgLevelTypeName = clubOwnerTypeState.data[i].orgLevelType?.typeName;
        }
      }
      updateFormState('orgLevelTypeName', orgLevelTypeName);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formState.ownerTypeId, baseUsasClubState, clubOwnerTypeState])

  useEffect(() => {
    
    // if we were routed to this page with parameters...
    if (location.state) {
      // if we already have a club object in state...
      if (Object.keys(baseUsasClubState.objData).length > 0) {
        const facilityId = location.state.linkedfacilityId;
        // if facilityId was one of the parameters passed..,
        if (facilityId) {
          // if there is an orgUnit associate with the club object..
          if (baseUsasClubState.objData.orgUnit) {
            const orgUnitId = baseUsasClubState.objData.orgUnit.orgUnitId;
            const usasClubId = baseUsasClubState.objData.usasClubId;

            // create a new association between the orgUnit and passed in facilityId
            addFacilityToClub(usasClubId, orgUnitId, facilityId);
          }
        }
      }
    }

    if (baseUsasClubState.isObjLoading === false && baseUsasClubState.isSaving === false && baseUsasClubState.isObjLoaded === true
      && clubOwnerTypeState.isLoaded === true) {

      let orgLevelTypeName = 'Unknown';
      for (let i = 0; i < clubOwnerTypeState.data.length; i++) {
        if (clubOwnerTypeState.data[i].clubOwnerTypeId === baseUsasClubState.objData.clubOwnerTypeId) {
          orgLevelTypeName = clubOwnerTypeState.data[i].orgLevelType?.typeName;
        }
      }
      setFormData({
        ...formState,
        orgUnitId: baseUsasClubState.objData.orgUnit.orgUnitId,
        orgUnitName: baseUsasClubState.objData.orgUnit.orgUnitName,
        orgUnitCode: baseUsasClubState.objData.orgUnit.orgUnitCode,
        yearFounded: baseUsasClubState.objData.yearFounded ? baseUsasClubState.objData.yearFounded.toString() : '',
        offeringInstanceId: Constants.DEFAULT_ID,
        orgLevelTypeName: orgLevelTypeName,
        ownerTypeId: baseUsasClubState.objData.clubOwnerTypeId || Constants.DEFAULT_ID,
        businessEntityTypeId: baseUsasClubState.objData.businessEntityTypeId || Constants.DEFAULT_ID,
        address1: baseUsasClubState.objData.usasClubAddress[0] ? baseUsasClubState.objData.usasClubAddress[0].address.address1 : '',
        address2: baseUsasClubState.objData.usasClubAddress[0] ? baseUsasClubState.objData.usasClubAddress[0].address.address2 : '',
        city: baseUsasClubState.objData.usasClubAddress[0] ? baseUsasClubState.objData.usasClubAddress[0].address.city : '',
        stateCode: baseUsasClubState.objData.usasClubAddress[0] ? baseUsasClubState.objData.usasClubAddress[0].address.stateCode : '',
        stateName: baseUsasClubState.objData.usasClubAddress[0] ? getStateNameById(baseUsasClubState.objData.usasClubAddress[0].address.stateCode) : '',
        postalCode: baseUsasClubState.objData.usasClubAddress[0] ? baseUsasClubState.objData.usasClubAddress[0].address.postalCode : '',
        websiteAddress: baseUsasClubState.objData.websiteAddress || '',
        clubSettingId: baseUsasClubState.objData.clubSetting || '',
        clubSetting: baseUsasClubState.objData.clubSetting || '',
        ownerType: baseUsasClubState.objData.ownerType ? baseUsasClubState.objData.clubOwnerType.typeName : '',
        businessEntityType: baseUsasClubState.objData.businessEntityType ? baseUsasClubState.objData.businessEntityType.typeName : '',
      });
      setState({ ...state, updateBusinessEntity: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [baseUsasClubState, clubOwnerTypeState]);

  useEffect(() => {
    if (formState.ownerTypeId === baseUsasClubState.objData.clubOwnerTypeId &&
      clubOwnerTypeState.isLoaded === true && clubOwnerTypeState.isLoading === false
      && formState.businessEntityTypeId > Constants.DEFAULT_ID && businessEntityState.isArrayLoading === false
      && businessEntityState.clubOwnerTypeId === formState.ownerTypeId && state.updateBusinessEntity === true) {
      //If navigating back to this page, handle loading delay for setting the tax listing from previous data
      setState({ ...state, updateBusinessEntity: false });
      onValueTextPairChanged(baseUsasClubState.objData.businessEntityTypeId || Constants.DEFAULT_ID, 'businessEntityTypeId', baseUsasClubState.objData.businessEntityType || '', 'businessEntityType');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [baseUsasClubState, clubOwnerTypeState, businessEntityState, formState.businessEntityTypeId]);

  function submitFormCallback() {
    const putObj = updateClubStateFromFormState();
    putBaseUsasClub(putObj.usasClubId, putObj);
  };

  return {
    formState,
    errorState,
    baseUsasClubState,
    onFormValueChanged: updateFormState,
    onCancelClicked,
    onValueTextPairChanged,
    settingOptions,
    clubOwnerTypeState,
    handleSubmitProxy,
    setFormData,
    validateState
  };
};

export default useClubInfo;

function getInitialFormState() {
  return {
    orgUnitId: '',
    orgUnitName: '',
    orgUnitCode: '',
    yearFounded: '',
    offeringInstanceId: Constants.DEFAULT_ID,
    orgLevelTypeName: '',
    ownerTypeId: Constants.DEFAULT_ID,
    ownerType: '',
    businessEntityTypeId: Constants.DEFAULT_ID,
    businessEntityType: '',
    address1: '',
    address2: '',
    city: '',
    stateCode: '',
    stateName: '',
    postalCode: '',
    websiteAddress: '',
    clubSettingId: '',
    clubSetting: '',
    validatedAddress: null,
    showedAddressSuggestion: false
  };
};