import React, { Fragment } from 'react';

import useLSCRegistrationManagementGrid from './UseLSCRegistrationManagementGrid';
import useExpandedRow from '../../../../../common/components/grids/UseExpandedRow';

import PrimaryButton from '../../../../../common/components/buttons/PrimaryButton';
import SecondaryButton from '../../../../../common/components/buttons/SecondaryButton';
import LSCRegistrationManagementGridLarge from './LSCRegistrationManagementGridLarge';
import LSCRegistrationManagementGridSmall from './LSCRegistrationManagementGridSmall';

const LSCRegistrationManagementGrid = ({ registrationData, isLoading, isGridDirty, setIsGridDirty, onSaveChanges }) => {
  const {
    gridState,
    onToggleLSCApprovedOffering,
    onToggleLSCApprovedClub,
    onCancelChanges
  } = useLSCRegistrationManagementGrid(registrationData, isGridDirty, setIsGridDirty);
  const { expandedId, onClick } = useExpandedRow();
  return (
    <Fragment>
      <LSCRegistrationManagementGridLarge
        data={gridState}
        isLoading={isLoading}
        onToggleClubApproved={onToggleLSCApprovedClub}
        onToggleOfferingApproved={onToggleLSCApprovedOffering}
        expandedId={expandedId}
        onClick={onClick}
      />
      <LSCRegistrationManagementGridSmall
        data={gridState}
        isLoading={isLoading}
        onToggleClubApproved={onToggleLSCApprovedClub}
        onToggleOfferingApproved={onToggleLSCApprovedOffering}
        expandedId={expandedId}
        onClick={onClick}
      />
      {gridState.length > 0 &&
        <div className="row usas-extra-top-margin">
          <div className="col-xs-12 usas-extra-top-margin">
            <PrimaryButton type="button" onClick={() => onSaveChanges(gridState)}>Save Changes</PrimaryButton>&nbsp;
            <SecondaryButton type="button" onClick={onCancelChanges}>Cancel</SecondaryButton>
          </div>
        </div>}
    </Fragment >
  );
};

export default LSCRegistrationManagementGrid;