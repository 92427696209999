import { BASIC_INITIAL_STATE } from '../../../common/utils/HttpHelper';

import postCategoryMeasureScoreData from './PostCategoryMeasureScoreData';
import putCategoryMeasureScoreData from './PutCategoryMeasureScoreData';

const INITIAL_STATE = {
  ...BASIC_INITIAL_STATE
};

const CategoryMeasureScoreData = {
  INITIAL_STATE,
  postCategoryMeasureScoreData,
  putCategoryMeasureScoreData
};

export default CategoryMeasureScoreData;