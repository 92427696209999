import { useEffect, useState } from "react";

import usePersonData from "../../../../../common/state/person/UsePersonData";
import useEnvironmentVariableData from "../../../../../common/state/environmentVariable/UseEnvironmentVariableData";

const useLearnToSwimStaffDupPopup = (dupArrayData, newPersonObj, onPersonConfirmed) => {
  const { environmentVariableState, UNKNOWN_COMPETITION_GENDER_ID } = useEnvironmentVariableData();
  const { personState, postBasePerson } = usePersonData();
  const [dupGridState, setDupGridState] = useState([]);
  const [newGridState, setNewGridState] = useState([]);

  const onContinueClicked = () => {
    let matchFound = false;

    for (const dup of dupGridState) {
      if (dup.isSelected === true) {
        matchFound = true;
        onPersonConfirmed(dup);
        break;
      }
    }

    if (matchFound === false) {
      postBasePerson({
        firstName: newPersonObj.firstName.trim(),
        lastName: newPersonObj.lastName.trim(),
        birthDate: newPersonObj.birthDate,
        email: newPersonObj.email.trim(),
        competitionGenderTypeId: UNKNOWN_COMPETITION_GENDER_ID,
        isDeleted: false
      });
    }
  };

  const onSelectGridPerson = (personId) => {
    let newDupGridState = [];

    for (const dup of dupGridState) {
      newDupGridState.push({
        ...dup,
        isSelected: personId === dup.personId ? true : false
      });
    }

    setDupGridState(newDupGridState);
    setNewGridState([{
      ...newGridState[0],
      isSelected: personId === '' ? true : false
    }]);
  };

  useEffect(() => {
    if (Array.isArray(dupArrayData) && newPersonObj) {
      if (dupArrayData.length > 0) {
        setDupGridState(dupArrayData.map(x => {
          let orgDuration = { orgUnitName: '', effectiveDate: '', expirationDate: '' };

          if (Array.isArray(x.orgDurations) && x.orgDurations.length > 0) {
            orgDuration = {
              orgUnitName: x.orgDurations[0].orgUnitName,
              effectiveDate: x.orgDurations[0].effectiveDate,
              expirationDate: x.orgDurations[0].expirationDate
            };
          }

          return {
            personId: x.personId,
            firstName: x.firstName,
            lastName: x.lastName,
            birthDate: x.birthDate,
            orgDuration,
            isSelected: false
          };
        }));
        setNewGridState([{
          personId: '',
          firstName: newPersonObj.firstName,
          lastName: newPersonObj.lastName,
          birthDate: newPersonObj.birthDate,
          orgDurations: { organization: '', effectiveDate: '', expirationDate: '' },
          isSelected: true
        }]);
      } else {
        postBasePerson({
          firstName: newPersonObj.firstName.trim(),
          lastName: newPersonObj.lastName.trim(),
          birthDate: newPersonObj.birthDate,
          email: newPersonObj.email.trim(),
          competitionGenderTypeId: UNKNOWN_COMPETITION_GENDER_ID,
          isDeleted: false
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (personState.isSaved) {
      onPersonConfirmed(personState.objData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [personState.isSaved]);

  return {
    isLoading: environmentVariableState.isLoading,
    isSaving: personState.isSaving,
    dupGridState,
    newGridState,
    onContinueClicked,
    onSelectGridPerson
  };
};

export default useLearnToSwimStaffDupPopup;