//import { useContext } from 'react';
import { useState } from 'react';

import { ClubBlockPartyMeetsStateContext } from './ClubBlockPartyMeetsContextProvider';
import ClubBlockPartyMeetsData from './ClubBlockPartyMeetsData';

const useClubBlockPartyMeetsData = () => {
  //const [clubBlockPartyMeetsState, setClubBlockPartyMeetsState] = useContext(ClubBlockPartyMeetsStateContext);
  const [clubBlockPartyMeetsState, setClubBlockPartyMeetsState] = useState(ClubBlockPartyMeetsData.INITIAL_STATE);

  const getClubBlockPartyMeets = (hostOrgUnitId, reportPeriodId) => {
    ClubBlockPartyMeetsData.getClubBlockPartyMeetsData(hostOrgUnitId, reportPeriodId, clubBlockPartyMeetsState, setClubBlockPartyMeetsState);
  };

  const postClubBlockPartyMeet = (blockPartyObj) => {
    ClubBlockPartyMeetsData.postClubBlockPartMeetData(blockPartyObj, clubBlockPartyMeetsState, setClubBlockPartyMeetsState);
  }

  const putClubBlockPartyMeet = (meetId, blockPartyObj) => {
    ClubBlockPartyMeetsData.putClubBlockPartyMeetData(meetId, blockPartyObj, clubBlockPartyMeetsState, setClubBlockPartyMeetsState)
  }

  const deleteClubBlockPartyMeet = (blockPartyMeetId) => {
    ClubBlockPartyMeetsData.deleteClubBlockPartyMeetData(blockPartyMeetId, clubBlockPartyMeetsState, setClubBlockPartyMeetsState);
  }

  const clearArrayData = () => {
    setClubBlockPartyMeetsState({
      ...clubBlockPartyMeetsState,
      isArrayLoading: false,
      isArrayLoaded: false,
      isSaving: false,
      isSaved: false,
      arrayData: []
    });
  };

  const confirmSave = () => {
    setClubBlockPartyMeetsState({
      ...clubBlockPartyMeetsState,
      isSaved: false
    });
  };

  return {
    clubBlockPartyMeetsState,
    getClubBlockPartyMeets,
    postClubBlockPartyMeet,
    putClubBlockPartyMeet,
    deleteClubBlockPartyMeet,
    clearArrayData,
    confirmSave
  }
}

export default useClubBlockPartyMeetsData;