import { useEffect } from 'react';

import validate from './LSCRegistrationManagementFormValidation';

import useReportPeriodSeasonData from '../../../../../common/state/reportPeriodSeason/UseReportPeriodSeasonData';

import Constants from "../../../../../common/utils/Constants";
import UseForm from "../../../../../common/utils/UseForm";
import { formatDate } from '../../../../../common/utils/DateFunctions';

const useLSCRegistrationManagementForm = (onSubmitFormCallback) => {
  const { reportPeriodSeasonState } = useReportPeriodSeasonData();
  const { formState, errorState, updateFormState, onValueTextPairChanged, handleSubmit, setFormState
  } = UseForm(getInitialFormState, onSubmitFormCallback, validate);

  useEffect(() => {
    if (reportPeriodSeasonState.isArrayLoading === false && reportPeriodSeasonState.isArrayLoaded === true) {
      const today = new Date();
      let todayFormatted;
      todayFormatted = new Date(formatDate(today));

      const currentReportPeriods = [];
      const futureReportPeriods = [];
      let startDate;
      let endDate;

      for (let i = 0; i < reportPeriodSeasonState.arrayData.length; i++) {
        const selectedArrayData = reportPeriodSeasonState.arrayData[i];
        startDate = new Date(formatDate(selectedArrayData.startDate));
        endDate = new Date(formatDate(selectedArrayData.endDate));
        if (todayFormatted <= endDate && todayFormatted >= startDate) {
          currentReportPeriods.push(selectedArrayData);
        }
        if (todayFormatted < startDate) {
          futureReportPeriods.push(selectedArrayData);
        }
      }

      const arrayDataCopy = JSON.parse(JSON.stringify(reportPeriodSeasonState.arrayData));
      if (currentReportPeriods.length > 0) {
        const currentOption = arrayDataCopy.find(x => x.reportPeriodId === currentReportPeriods[0].reportPeriodId);
        setFormState({ ...formState, reportPeriodId: currentOption.reportPeriodId, reportPeriodName: currentOption.periodName });
      } else if (futureReportPeriods.length > 0) {
        const futureOption = arrayDataCopy.find(x => x.reportPeriodId === futureReportPeriods[0].reportPeriodId);
        setFormState({ ...formState, reportPeriodId: futureOption.reportPeriodId, reportPeriodName: futureOption.periodName });
      } else {
        const currentOption = arrayDataCopy[arrayDataCopy.length - 1];
        setFormState({ ...formState, reportPeriodId: currentOption.reportPeriodId, reportPeriodName: currentOption.periodName });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps   
  }, [reportPeriodSeasonState]);

  function getInitialFormState() {
    return {
      reportPeriodId: Constants.DEFAULT_ID,
      reportPeriodName: '',
      registrationStartDate: Constants.BLANK_DATE_STRING,
      registrationEndDate: Constants.BLANK_DATE_STRING,
      orgUnitId: '',
      orgUnitName: '',
    };
  };

  return {
    formState,
    errorState,
    onFormValueChanged: updateFormState,
    onValueTextPairChanged,
    handleSubmit
  };
};

export default useLSCRegistrationManagementForm;