import React, { Fragment } from 'react';

import useClubRegistrationType from './UseClubRegistrationType';

import ClubRegistrationConstants from '../utils/ClubRegistrationConstants';

import ValidationPopUpModal from '../components/ValidationPopUpModal';

import OfferingTypeRegistrationRadiobuttonList from '../../../components/radiobuttonlists/OfferingTypeRegistrationRadiobuttonList';

import LeftNav from '../../../../common/components/leftNav/LeftNav';
import Headings from '../../../../common/components/headings/Headings';
import PrimaryButton from '../../../../common/components/buttons/PrimaryButton';
import SecondaryButton from '../../../../common/components/buttons/SecondaryButton';
import PopUpModal from '../../../../common/components/dialogs/PopUpModal';

import { formatDate } from '../../../../common/utils/DateFunctions';
import { FormatMoneyWithSymbol } from '../../../../common/utils/FormatMoney';
import Constants from '../../../../common/utils/Constants';

import global from '../../../../common/components/GlobalStyle.module.css';

const WriteComponent = ({ errorState, formState, onFormValueChanged }) => (
  <div className="row usas-extra-top-margin">
    <div className="col-xs-12">
      <OfferingTypeRegistrationRadiobuttonList
        label={"Select a Club Type"}
        name={"offeringInstanceId"}
        error={errorState.offeringInstanceId}
        message={errorState.offeringInstanceId}
        value={formState.offeringInstanceId}
        isVertical={true}
        onChange={(value) => { onFormValueChanged('offeringInstanceId', parseInt(value)) }}
        orgUnitId={formState.lscId}
        category={'club'}
      />
    </div>
  </div>
)

const ReadComponent = ({ formState }) => {
  return (
    <Fragment>
      <div className={["row", global.HeaderText].join(' ')}>
        <div className="col-xs-12">
          <p><b>{formState.offeringName} -- USA Swimming Price: {FormatMoneyWithSymbol(formState.usasFeeAmount)} -- LSC Price: {FormatMoneyWithSymbol(formState.lscFeeAmount)}</b></p>
          <p>{formState.offeringDescription}</p>
          <p>Registration Dates: {formatDate(formState.offeringEffectiveDate)} - {formatDate(formState.offeringExpirationDate)}</p>
        </div>
      </div>
    </Fragment>);
}

const ClubRegistrationType = () => {
  const { clubRegistrationNavState, clubRegistrationState, offeringTypeState, modalState, state,
    onModalContinue, onModalCanceled, formState, errorState, onFormValueChanged, onContinueClicked, onSaveAndExitClicked,
    onBackClicked } = useClubRegistrationType();

  return (
    <LeftNav title={ClubRegistrationConstants.LEFT_NAV_TITLE} state={clubRegistrationNavState} validateBeforeRedirect={false}>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>Club Type</Headings.H3>
        </div>
      </div>
      {
        //new club
        (clubRegistrationState.objData.status === ClubRegistrationConstants.IN_PROGRESS_STATUS &&
          (clubRegistrationState.objData?.clubRegistrations.length === 0 || clubRegistrationState.objData?.clubRegistrations[0]?.paymentProcessed === false)) ||
          //renewing club
          (clubRegistrationState.objData.status === ClubRegistrationConstants.RENEWING_STATUS && formState.selectedRegistration.paymentProcessed === false) ?
          <WriteComponent
            errorState={errorState}
            formState={formState}
            onFormValueChanged={onFormValueChanged}
          />
          :
          <ReadComponent
            formState={formState}
          />
      }
      <div className="row usas-extra-top-margin usas-extra-bottom-margin">
        <div className="col-xs-12 usas-extra-top-margin">
          <PrimaryButton type="button" onClick={() => onContinueClicked()}>Continue</PrimaryButton>&nbsp;
          <PrimaryButton type="button" onClick={() => onSaveAndExitClicked()}>
            {clubRegistrationState.objData.status === ClubRegistrationConstants.IN_PROGRESS_STATUS
              || clubRegistrationState.objData.status === ClubRegistrationConstants.RENEWING_STATUS ?
              ClubRegistrationConstants.SAVE_AND_EXIT : ClubRegistrationConstants.EXIT}</PrimaryButton>&nbsp;
          <SecondaryButton type="button" onClick={() => onBackClicked()}>Back</SecondaryButton>
        </div>
      </div>
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={clubRegistrationState.isObjLoading || offeringTypeState.isArrayLoading} />
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.SAVING_MSG}
        displayPopUp={clubRegistrationState.isSaving} />
      <ValidationPopUpModal
        clubRegistrationState={clubRegistrationState}
        state={state}
        modalState={modalState}
        onModalContinue={onModalContinue}
        onModalCanceled={onModalCanceled}
      />
    </LeftNav>
  );
};

export default ClubRegistrationType;