import { BASIC_INITIAL_STATE } from '../../../../common/utils/HttpHelper';

import getClubRegistrationStatus from './GetClubRegistrationStatusData';

const INITIAL_STATE = {
  ...BASIC_INITIAL_STATE
};

const ClubRegistrationStatusData = {
  INITIAL_STATE,
  getClubRegistrationStatus
};

export default ClubRegistrationStatusData;