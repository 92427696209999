import React, { Fragment } from 'react';

import useClubInfo from './UseClubInfo';

import BusinessEntityDropdown from '../../../components/dropdowns/BusinessEntityDropdown';
import ClubOwnerTypeDropdown from '../../../components/dropdowns/ClubOwnerTypeDropdown';

import Headings from '../../../../common/components/headings/Headings';
import Input from '../../../../common/components/inputs/Input';
import PrimaryButton from '../../../../common/components/buttons/PrimaryButton';
import SecondaryButton from '../../../../common/components/buttons/SecondaryButton';
import StatesCombobox from '../../../../common/components/comboboxes/statesCombobox/StatesCombobox';
import Dropdown from '../../../../common/components/dropdowns/Dropdown';
import PopUpModal from '../../../../common/components/dialogs/PopUpModal';
import ReadOnly from '../../../../common/components/readOnly/ReadOnly';
import AddressValidation from '../../../../common/components/addressValidation/AddressValidation';

import Constants from '../../../../common/utils/Constants';

const ClubInfoWrite = () => {
  const {
    baseUsasClubState,
    formState,
    errorState,
    onFormValueChanged,
    onValueTextPairChanged,
    onCancelClicked,
    settingOptions,
    clubOwnerTypeState,
    handleSubmitProxy,
    setFormData,
    validateState
  } = useClubInfo();

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>Club Info</Headings.H3>
        </div>
      </div>
      <form onSubmit={handleSubmitProxy} noValidate>
        <div className="row">
          <div className="col-xs-12 col-sm-6 col-md-4">
            <Input
              label="Year Founded"
              name="yearFounded"
              value={formState.yearFounded}
              error={errorState.yearFounded}
              message={errorState.yearFounded}
              onChange={(value) => { onFormValueChanged('yearFounded', value); }} />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4">
            <ReadOnly
              label="Club Type"
              name="orgLevelTypeName"
              value={formState.orgLevelTypeName || 'Unknown'} />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4">
            <ClubOwnerTypeDropdown
              label="Owner Type*"
              name="ownerTypeId"
              value={formState.ownerTypeId}
              error={errorState.ownerTypeId}
              message={errorState.ownerTypeId}
              onChange={(value) => { onFormValueChanged('ownerTypeId', value); }}
            />
          </div>
          {clubOwnerTypeState.isLoaded === true ?
            <div className="col-xs-12 col-sm-6 col-md-4">
              <BusinessEntityDropdown
                label="Tax Listing*"
                name="businessEntityTypeId"
                value={formState.businessEntityTypeId}
                error={errorState.businessEntityTypeId}
                message={errorState.businessEntityTypeId}
                clubOwnerTypeId={formState.ownerTypeId}
                onChange={(value) => { onFormValueChanged('businessEntityTypeId', value); }} />
            </div> : <Fragment />}
          <div className="col-xs-12 col-sm-6 col-md-4">
            <Dropdown
              label="Setting"
              options={settingOptions}
              name="clubSettingId"
              value={formState.clubSettingId}
              error={errorState.clubSettingId}
              message={errorState.clubSettingId}
              areValuesIntegers={false}
              onChange={(value) => {
                onFormValueChanged('clubSettingId', value);
              }} />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4">
            <Input
              label="Primary Mailing Address Line 1*"
              name="address1"
              value={formState.address1}
              error={errorState.address1}
              message={errorState.address1}
              onChange={(value) => { onFormValueChanged('address1', value); }} />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4">
            <Input
              label="Address Line 2"
              name="address2"
              value={formState.address2}
              error={errorState.address2}
              message={errorState.address2}
              onChange={(value) => { onFormValueChanged('address2', value); }} />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4">
            <Input
              label="City*"
              name="city"
              value={formState.city}
              error={errorState.city}
              message={errorState.city}
              onChange={(value) => { onFormValueChanged('city', value); }} />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4">
            <StatesCombobox
              label="State*"
              name="stateCode"
              valueToMatch={formState.stateName}
              error={errorState.stateCode}
              message={errorState.stateCode}
              isUSA={true}
              onChange={(newValue, newValueLabel, e) => { onValueTextPairChanged(newValue, 'stateCode', newValueLabel, 'stateName', e); }} />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4">
            <Input
              label="Zip Code*"
              name="postalCode"
              value={formState.postalCode}
              error={errorState.postalCode}
              message={errorState.postalCode}
              onChange={(value) => { onFormValueChanged('postalCode', value); }} />
          </div>
          <div className="col-xs-12 col-sm-12 col-md-6 usas-extra-bottom-margin">
            <Input
              label="Website Address"
              name="websiteAddress"
              value={formState.websiteAddress}
              error={errorState.websiteAddress}
              message={errorState.websiteAddress}
              onChange={(value) => { onFormValueChanged('websiteAddress', value); }} />
          </div>
          <AddressValidation formState={formState} setFormData={setFormData} />
        </div>
        <div className="row">
          <div className="col-xs-12 usas-extra-top-margin usas-extra-bottom-margin">
            <PrimaryButton type="submit">Save</PrimaryButton>&nbsp;
            <SecondaryButton type="button" onClick={onCancelClicked}>Cancel</SecondaryButton>
          </div>
        </div>
      </form >
      {validateState.isValidating === true && (
        <PopUpModal
          displayPopUp={true}
          maxWidth={250}
          title="Validating..."
          widthPct={50} />
      )}
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={baseUsasClubState.isObjLoading || clubOwnerTypeState.isLoading} />
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.SAVING_MSG}
        displayPopUp={baseUsasClubState.isSaving} />
    </Fragment >
  );
};

export default ClubInfoWrite;