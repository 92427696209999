import { useEffect, useState } from "react";
import { useNavigate } from "../../../../common/wrappers/ReactRouterDom";

import NavLinks from "../NavLinks";

import useBaseUsasClubData from '../../../../common/state/clubBase/UseBaseUsasClubData';
import useClubAffiliationTypeData from "../../../../common/state/clubAffiliationType/UseClubAffiliationTypeData";

import useForm from '../../../../common/utils/UseForm';

const useClubAffiliationDetail = () => {
  const navigate = useNavigate();
  const { baseUsasClubState, putBaseUsasClub } = useBaseUsasClubData();
  const { clubAffiliationTypeState } = useClubAffiliationTypeData();
  const [state, setState] = useState({ tryRedirect: false });

  const {
    handleSubmit,
    updateFormState,
    formState,
    errorState,
    setFormState,
    isDirty
  } = useForm(getFormInitialState(), submitFormCallback, () => { });

  function submitFormCallback() {
    const putObj = getClubToPut();
    putBaseUsasClub(putObj.usasClubId, putObj);
    setState({ ...state, tryRedirect: true });
  };

  const onSaveClicked = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    handleSubmit();
    if (isDirty === false) {
      setState({ ...state, tryRedirect: true });
    }
  };

  const onCancelClicked = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    navigate(NavLinks.CLUB_AFFILIATION);
  };

  const getClubToPut = () => {
    let usasClubAffiliationsArray = [];

    for (const affiliation of formState.clubAffiliationTypes) {
      let affiliationObj = {
        usasClubId: baseUsasClubState.objData.usasClubId,
        affiliationTypeId: affiliation.id,
      };
      usasClubAffiliationsArray.push(affiliationObj);
    }

    let clubToPut = {
      ...baseUsasClubState.objData,
      usasClubAffiliations: usasClubAffiliationsArray
    };

    return clubToPut;
  };

  useEffect(() => {
    if (baseUsasClubState.isObjLoaded === true && clubAffiliationTypeState.isArrayLoaded === true) {
      let clubAffiliationTypesArray = [];

      for (const affiliation of baseUsasClubState.objData.usasClubAffiliations) {
        let affiliationObj = {
          id: affiliation.affiliationTypeId,
          name: affiliation.clubAffiliationType?.typeName
        };
        clubAffiliationTypesArray.push(affiliationObj);
      }

      setFormState({
        ...formState,
        clubAffiliationTypes: clubAffiliationTypesArray
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [baseUsasClubState.isObjLoaded, clubAffiliationTypeState.isArrayLoaded]);

  useEffect(() => {
    if (state.tryRedirect === true && baseUsasClubState.isObjLoaded === true && baseUsasClubState.isSaving === false) {
      navigate(NavLinks.CLUB_AFFILIATION);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [baseUsasClubState, state]);

  return {
    formState,
    errorState,
    baseUsasClubState,
    clubAffiliationTypeState,
    onFormValueChanged: updateFormState,
    handleSubmit,
    onSaveClicked,
    onCancelClicked
  };
};

export default useClubAffiliationDetail;

function getFormInitialState() {
  return {
    clubAffiliationTypes: []
  };
};