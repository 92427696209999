import React, { Fragment } from 'react';

import useClubGroupTypesHeader from './UseClubGroupTypesHeader';

import ClubGroupAttributesListType from './ClubGroupAttributesListType';
import ClubGroupAttributesTravelType from './ClubGroupAttributesTravelType';
import ClubGroupAttributesPracticeType from './ClubGroupAttributesPracticeType';

import global from '../../../../../../common/components/GlobalStyle.module.css';

const HeaderAttributes = ({ state, clubGroupState, GROUP_CONSTANTS }) => {
  switch (clubGroupState.objData?.groupType?.typeName) {
    case GROUP_CONSTANTS.GROUP_TYPE_NAME_LIST:
      return <ClubGroupAttributesListType clubGroupState={clubGroupState} />;
    case GROUP_CONSTANTS.GROUP_TYPE_NAME_TRAVEL_TRIP:
      return <ClubGroupAttributesTravelType state={state} clubGroupState={clubGroupState} />;
    case GROUP_CONSTANTS.GROUP_TYPE_NAME_PRACTICE_GROUP:
      return <ClubGroupAttributesPracticeType state={state} clubGroupState={clubGroupState} />;
    default:
      return <Fragment />;
  }
};

const ClubGroupInfoHeader = ({ clubGroupState }) => {
  const {
    state,
    GROUP_CONSTANTS
  } = useClubGroupTypesHeader(clubGroupState);

  return (
    <Fragment>
      {clubGroupState.isObjLoaded === true
        ? (
          <div className={["row", global.HeaderText].join(' ')}>
            <div className="col-xs-6 col-md-3"><p><b>Group Name:</b></p></div>
            <div className="col-xs-6 col-md-3"> <p>{clubGroupState.objData?.groupName}</p></div>
            <div className="col-xs-6 col-md-3"><p><b>Group Code:</b></p></div>
            <div className="col-xs-6 col-md-3"> <p> {clubGroupState.objData?.groupCode}</p></div>
            <div className="col-xs-6 col-md-3"><p><b>Group Type:</b></p></div>
            <div className="col-xs-6 col-md-3"> <p>{clubGroupState.objData?.groupType?.typeName}</p></div>
            <div className="col-xs-6 col-md-3"><p><b>Group Category:</b></p></div>
            <div className="col-xs-6 col-md-3"> <p>{clubGroupState.objData?.groupCategory?.categoryName}</p></div>
            <HeaderAttributes state={state} clubGroupState={clubGroupState} GROUP_CONSTANTS={GROUP_CONSTANTS} />
          </div>
        ) : <Fragment />
      }
    </Fragment >
  );
};

export default ClubGroupInfoHeader;