import useEnvironmentVariableData from '../../../../common/state/environmentVariable/UseEnvironmentVariableData';
import NavLinks from '../NavLinks';

const TAXONOMIES = [''];
const SCOPE = 'ClubRecognition';

const useOrgUnitSelection = () => {
  const { CRP_ROLE_GROUP_ID } = useEnvironmentVariableData();

  return {
    NavLinks,
    CRP_ROLE_GROUP_ID,
    TAXONOMIES,
    SCOPE
  };
};

export default useOrgUnitSelection;