import SGColumnHeader from '../../../../common/components/grids/SGColumnHeader';

import EditIcon from '../../../../common/components/icons/EditIcon';

import { formatDate } from '../../../../common/utils/DateFunctions';
import useSortableGrid from '../../../../common/utils/UseSortableGrid';
import SGConstants from '../../../../common/utils/SGConstants';
import Checkbox from '../../../../common/components/checkboxes/Checkbox';

import global from '../../../../common/components/GlobalStyle.module.css';

const GridRow = ({ athlete, onEditAthlete, onVendorSyncCheckbox }) => {
  return (
    <tr>
      <td>
        {(athlete.firstName || athlete.preferredName || athlete.lastName)
          ? `${athlete.preferredName ? athlete.preferredName : athlete.firstName || ''} ${athlete.lastName || ''}`
          : ''
        }
      </td>
      <td>{athlete.competitionGenderTypeName || ''}</td>
      <td>{athlete.birthDate ? formatDate(athlete.birthDate) : ''}</td>
      <td>{athlete.age >= 0 ? athlete.age : ''}</td>
      <td>{athlete.isInGoodStandingExpirationDate ? formatDate(athlete.isInGoodStandingExpirationDate) : ''}</td>
      <td>{athlete.orgRoleName || ''}</td>
      <td>{formatDate(athlete.attachDate) || ''}</td>
      <td>
        <Checkbox
          label=""
          name="syncMember"
          onChange={(e) => { onVendorSyncCheckbox(e, athlete.memberId) }}
          checked={athlete.syncMember}
        />
      </td>
      <td>
        {onEditAthlete &&
          <button className={global.IconButtonMargin}
            type="button"
            onClick={() => onEditAthlete(athlete.personId)}>
            <EditIcon />
          </button>
        }
      </td>
    </tr>
  );
};

const ClubAthleteRosterGridLarge = ({ gridData, isLoading, onEditAthlete, onVendorSyncCheckbox }) => {
  const {
    sortableGridState,
    onColumnClicked
  } = useSortableGrid(gridData);
  return (
    <table className={[global.UsasTable, 'visible-md visible-lg'].join(' ')}>
      <thead>
        <tr>
          <SGColumnHeader sortableGridState={sortableGridState} columnLabel={'Name'} columnField={'lastName'}
            sortType={SGConstants.SORT_TYPE_ALPHABETICAL} onColumnClicked={onColumnClicked} />
          <SGColumnHeader sortableGridState={sortableGridState} columnLabel={'Competition Category'} columnField={'competitionGenderTypeName'}
            sortType={SGConstants.SORT_TYPE_ALPHABETICAL} onColumnClicked={onColumnClicked} />
          <SGColumnHeader sortableGridState={sortableGridState} columnLabel={'Birth Date'} columnField={'birthDate'}
            sortType={SGConstants.SORT_TYPE_DATE} onColumnClicked={onColumnClicked} />
          <SGColumnHeader sortableGridState={sortableGridState} columnLabel={'Age'} columnField={'age'}
            sortType={SGConstants.SORT_TYPE_NUMERICAL} onColumnClicked={onColumnClicked} />
          <SGColumnHeader sortableGridState={sortableGridState} columnLabel={'Good Standing Expiration Date'} columnField={'isInGoodStandingExpirationDate'}
            sortType={SGConstants.SORT_TYPE_DATE} onColumnClicked={onColumnClicked} />
          <SGColumnHeader sortableGridState={sortableGridState} columnLabel={'Member Type'} columnField={'orgRoleName'}
            sortType={SGConstants.SORT_TYPE_ALPHABETICAL} onColumnClicked={onColumnClicked} />
          <SGColumnHeader sortableGridState={sortableGridState} columnLabel={'Attach Date'} columnField={'attachDate'}
            sortType={SGConstants.SORT_TYPE_DATE} onColumnClicked={onColumnClicked} />
          <th>Sync</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {isLoading === true
          ? <tr><td colSpan="8">Loading...</td></tr>
          : Array.isArray(sortableGridState.sortedGridData) && sortableGridState.sortedGridData.length > 0
            ? sortableGridState.sortedGridData.map((athlete, i) => <GridRow key={i} athlete={athlete} onEditAthlete={onEditAthlete} onVendorSyncCheckbox={onVendorSyncCheckbox} />)
            : <tr><td colSpan="8">No Athletes</td></tr>
        }
      </tbody>
    </table>
  )
};

export default ClubAthleteRosterGridLarge;