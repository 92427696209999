import NavLinks from "../../../views/preCompRegistration/NavLinks";
import PreCompRegistrationConstants from "../../../views/preCompRegistration/utils/PreCompRegistrationConstants";

const INITIAL_ORG_NAV_STATE = [
  {
    name: PreCompRegistrationConstants.ORG_INFO,
    displayName: 'Organization Info',
    path: NavLinks.PRE_COMP_REGISTRATION_ORGANIZATION_INFO,
    isCurrent: false,
    isComplete: false,
    isVisited: false,
    arePrerequisitesDone: true
  },
  {
    name: PreCompRegistrationConstants.ORG_CONTACT,
    displayName: 'Organization Contacts',
    path: NavLinks.PRE_COMP_REGISTRATION_ORGANIZATION_CONTACT,
    isCurrent: false,
    isComplete: false,
    isVisited: false,
    arePrerequisitesDone: true
  },
  {
    name: PreCompRegistrationConstants.ORG_INSURANCE,
    displayName: 'Organization Insurance',
    path: NavLinks.PRE_COMP_REGISTRATION_ORGANIZATION_INSURANCE,
    isCurrent: false,
    isComplete: false,
    isVisited: false,
    arePrerequisitesDone: true
  }
];

const INITIAL_LOC_NAV_STATE = [
  {
    name: PreCompRegistrationConstants.LOC_INFO,
    displayName: 'Location Info',
    path: NavLinks.PRE_COMP_REGISTRATION_LOCATION_INFO,
    isCurrent: false,
    isComplete: false,
    isVisited: false,
    arePrerequisitesDone: true
  },
  {
    name: PreCompRegistrationConstants.LOC_PROGRAMS,
    displayName: 'Program Offerings',
    path: NavLinks.PRE_COMP_REGISTRATION_LOCATION_PROGRAMS,
    isCurrent: false,
    isComplete: false,
    isVisited: false,
    arePrerequisitesDone: true
  },
  {
    name: PreCompRegistrationConstants.LOC_STAFF,
    displayName: 'Location Staff',
    path: NavLinks.PRE_COMP_REGISTRATION_LOCATION_STAFF,
    isCurrent: false,
    isComplete: false,
    isVisited: false,
    arePrerequisitesDone: true
  },
  {
    name: PreCompRegistrationConstants.LOC_INSTRUCTOR_TRAINING,
    displayName: 'Location Instructor Training',
    path: NavLinks.PRE_COMP_REGISTRATION_LOCATION_INSTRUCTOR_TRAINING,
    isCurrent: false,
    isComplete: false,
    isVisited: false,
    arePrerequisitesDone: true
  },
  {
    name: PreCompRegistrationConstants.LOC_CLUB,
    displayName: 'Location Clubs',
    path: NavLinks.PRE_COMP_REGISTRATION_LOCATION_CLUB,
    isCurrent: false,
    isComplete: false,
    isVisited: false,
    arePrerequisitesDone: true
  },
  {
    name: PreCompRegistrationConstants.LOC_FACILITY,
    displayName: 'Location Facilities',
    path: NavLinks.PRE_COMP_REGISTRATION_LOCATION_FACILITY,
    isCurrent: false,
    isComplete: false,
    isVisited: false,
    arePrerequisitesDone: true
  },
  {
    name: PreCompRegistrationConstants.LOC_EMERGENCY_PLAN,
    displayName: 'Location Emergency Plans',
    path: NavLinks.PRE_COMP_REGISTRATION_LOCATION_EMERGENCY_PLAN,
    isCurrent: false,
    isComplete: false,
    isVisited: false,
    arePrerequisitesDone: true
  },
  {
    name: PreCompRegistrationConstants.SUBMIT,
    displayName: 'Submit',
    path: NavLinks.PRE_COMP_REGISTRATION_SUBMIT,
    isCurrent: false,
    isComplete: false,
    isVisited: false,
    arePrerequisitesDone: false
  },
];

const PreCompLocationRegistrationNavData = { INITIAL_ORG_NAV_STATE, INITIAL_LOC_NAV_STATE };

export default PreCompLocationRegistrationNavData;