import React, { Fragment } from 'react';

import useClubPolicies from './UseClubPolicies';
import Constants from '../../../../common/utils/Constants';

import Headings from '../../../../common/components/headings/Headings';
import PopUpModal from '../../../../common/components/dialogs/PopUpModal';

import global from '../../../../common/components/GlobalStyle.module.css';

const ClubPoliciesRead = () => {
  const {
    state,
    baseUsasClubState
  } = useClubPolicies();

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>Club Policies</Headings.H3>
        </div>
      </div>
      <div className={["row usas-extra-top-margin usas-extra-bottom-margin", global.HeaderText].join(' ')}>
        <div className="col-xs-8 col-md-5">
          <p><b>Pre-Employment Screening Required?</b></p>
        </div>
        <div className="col-xs-4 col-md-1">
          <p>{state.preEmploymentScreeningRequired === true ? "Yes" : "No"}</p>
        </div>
        <div className="col-xs-8 col-md-5">
          <p><b>Include In Find-A-Club Search?</b></p>
        </div>
        <div className="col-xs-4 col-md-1">
          <p>{state.includeInFindAclubSearch === true ? "Yes" : "No"}</p>
        </div>
        <div className="col-xs-8 col-md-5">
          <p><b>Racing start Certified?</b></p>
        </div>
        <div className="col-xs-4 col-md-1">
          <p>{state.racingStartCertified === true ? "Yes" : "No"}</p>
        </div>
        <div className="col-xs-8 col-md-5">
          <p><b>State Concussion Laws?</b></p>
        </div>
        <div className="col-xs-4 col-md-1">
          <p>{state.stateConcussionsLaws === true ? "Yes" : "No"}</p>
        </div>
        <div className="col-xs-8 col-md-5">
          <p><b>Minor Abuse prevention policy?</b></p>
        </div>
        <div className="col-xs-4 col-md-1">
          <p>{state.minorAthleteAbusePreventionPolicy === true ? "Yes" : "No"}</p>
        </div>
        <div className="row visible-md visible-lg">
          <div className='col-xs-12'></div>
        </div>
        <div className="col-xs-8 col-md-5">
          <p><b>Completed CLBMS 101?</b></p>
        </div>
        <div className="col-xs-4 col-md-1">
          <p>{state.clbmS101 === true ? "Yes" : "No"}</p>
        </div>
        <div className="col-xs-8 col-md-4">
          <p><b>CLBMS 101 Completion Date:</b></p>
        </div>
        <div className="col-xs-4 col-md-2">
          <p>{state.clbmS101 === null || state.clbmS101 === Constants.BLANK_DATE_STRING ? 'Not Yet Taken' : state.clbmS101CompletionDate}</p>
        </div>
        <div className="col-xs-8 col-md-5">
          <p><b>Completed CLBMS 201?</b></p>
        </div>
        <div className="col-xs-4 col-md-1">
          <p>{state.clbmS201 === true ? "Yes" : "No"}</p>
        </div>
        <div className="col-xs-8 col-md-4">
          <p><b>CLBMS 201 Completion Date:</b></p>
        </div>
        <div className="col-xs-4 col-md-2">
          <p>{state.clbmS201 === null || state.clbmS201 === Constants.BLANK_DATE_STRING ? 'Not Yet Taken' : state.clbmS201CompletionDate}</p>
        </div>
      </div>
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={baseUsasClubState.isObjLoading} />
    </Fragment>
  );
};

export default ClubPoliciesRead;
