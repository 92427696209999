import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "../../../../../common/wrappers/ReactRouterDom";

import NavLinks from "../../NavLinks";

import { formatDate } from "../../../../../common/utils/DateFunctions";

const UseClubMeetUpload = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [meetHeaderState, setMeetHeaderState] = useState(getInitialMeetHeaderState);

  const BLOCK_PARTY_OTS_MEET_CLASSIFICATION_ID = 103;
  const BLOCK_PARTY_NON_OTS_MEET_CLASSIFICATION_ID = 104;

  const onBackClicked = () => {
    if (location.state.meet.meetClassificationId === BLOCK_PARTY_OTS_MEET_CLASSIFICATION_ID || location.state.meet.meetClassificationId === BLOCK_PARTY_NON_OTS_MEET_CLASSIFICATION_ID) {
      navigate(NavLinks.CLUB_BLOCKPARTY);
    }
    else {
      navigate(NavLinks.CLUB_MEETS);
    }
  };

  useEffect(() => {
    if (location.state?.meet) {
      setMeetHeaderState({
        ...meetHeaderState,
        meetName: location.state.meet.meetName,
        meetDates: formatDate(location.state.meet.startDate)
          + ' - ' + formatDate(location.state.meet.endDate),
        meetHost: location.state.meet.orgUnitName
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function getInitialMeetHeaderState() {
    return {
      meetName: '',
      meetDates: '',
      meetHost: ''
    };
  }

  return {
    meetId: location.state?.meet?.meetId || -1,
    meetHeaderState,
    onBackClicked,
    meetStartDate: location.state.meet.startDate,
    meetEndDate: location.state.meet.endDate
  };
};

export default UseClubMeetUpload;