import React, { Fragment } from 'react';

import usePreCompOrganizationContactsDetail from './UsePreCompOrganizationContactsDetail';

import PreCompLocationStaffDupGrid from '../components/grids/PreCompLocationStaffDupGrid';
import PreCompOrganizationContactForm from '../components/forms/PreCompOrganizationContactForm';

import Headings from '../../../../common/components/headings/Headings';
import PrimaryButton from '../../../../common/components/buttons/PrimaryButton';
import SecondaryButton from '../../../../common/components/buttons/SecondaryButton';
import PopUpModal from '../../../../common/components/dialogs/PopUpModal';

import Constants from '../../../../common/utils/Constants';

const PreCompOrganizationContactsDetail = () => {
  const {
    isSaving,
    isLoading,
    isDelete,
    formState,
    errorState,
    dupGridState,
    newGridState,
    roleOptionsState,
    existingContactModalState,
    displayLocalDupPersonModalState,
    onFormValueChanged,
    handleSubmit,
    onValueTextPairChanged,
    onCancelClicked,
    onModalCancelClicked,
    onModalContinueClicked,
    onSelectGridPerson,
    onExistingContactModalConfirmClicked,
    onExistingContactModalCancelClicked,
    onLocalDupPersonModalCancelClicked
  } = usePreCompOrganizationContactsDetail();

  return (
    <Fragment>
      <Headings.H3>{isDelete ? `Replace ${formState.orgRoleName || 'Contact'}` : 'Add Contact'}</Headings.H3>
      <form onSubmit={handleSubmit} noValidate>
        <PreCompOrganizationContactForm
          formState={formState}
          errorState={errorState}
          roleOptions={roleOptionsState}
          onFormValueChanged={onFormValueChanged}
          onValueTextPairChanged={isDelete ? undefined : onValueTextPairChanged} />
        {errorState.errorOnSubmitAction &&
          <div className="row">
            <div className="col-xs-12">
              <p className={global.ErrorMessage}>{errorState.errorOnSubmitAction || ''}</p>
            </div>
          </div>}
        <div className="row usas-extra-top-margin usas-extra-bottom-margin">
          <div className="col-xs-12 usas-extra-top-margin">
            <PrimaryButton type="submit">Save</PrimaryButton>&nbsp;
            <SecondaryButton type="button" onClick={onCancelClicked}>Back</SecondaryButton>
          </div>
        </div>
      </form>
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.SAVING_MSG}
        displayPopUp={isSaving} />
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={isLoading} />
      <PopUpModal
        widthPct={90}
        maxWidth={1020}
        title={"Organization Contact Dupe Check"}
        displayPopUp={dupGridState.length > 0}
        onModalCanceled={onModalCancelClicked}>
        <p><b>Database Matches Found</b></p>
        <div className="row usas-extra-bottom-margin">
          <div className="col-xs-12">
            <PreCompLocationStaffDupGrid
              actionLabel={'Use Existing Person'}
              persons={dupGridState}
              onSelected={onSelectGridPerson} />
          </div>
        </div>
        <p><b>Info Provided By User</b></p>
        <div className="row usas-extra-bottom-margin">
          <div className="col-xs-12">
            <PreCompLocationStaffDupGrid
              actionLabel={'Create New Person'}
              persons={newGridState}
              onSelected={onSelectGridPerson} />
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12 usas-extra-top-margin">
            <PrimaryButton type="button" onClick={onModalContinueClicked}>Continue</PrimaryButton>&nbsp;
            <SecondaryButton type="button" onClick={onModalCancelClicked}>Cancel</SecondaryButton>
          </div>
        </div>
      </PopUpModal>
      <PopUpModal
        widthPct={90}
        maxWidth={1020}
        title={`Replace Current ${existingContactModalState.existingContactOrgRoleName}?`}
        displayPopUp={existingContactModalState.displayModal === true}
        onModalCanceled={onModalCancelClicked}>
        <div className="row usas-extra-bottom-margin">
          <div className="col-xs-12">
            <p>Current {existingContactModalState.existingContactOrgRoleName} is {existingContactModalState.existingContactFirstName} {existingContactModalState.existingContactLastName}. Are you sure you want to replace them?</p>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12 usas-extra-top-margin">
            <PrimaryButton type="button" onClick={onExistingContactModalConfirmClicked}>Yes, Save</PrimaryButton>&nbsp;
            <SecondaryButton type="button" onClick={onExistingContactModalCancelClicked}>Cancel</SecondaryButton>
          </div>
        </div>
      </PopUpModal>
      <PopUpModal
        widthPct={90}
        maxWidth={1020}
        title={'Duplicate Contact Detected'}
        displayPopUp={displayLocalDupPersonModalState === true}
        onModalCanceled={onLocalDupPersonModalCancelClicked}>
        <div className="row">
          <div className="col-xs-12">
            <p>{formState.firstName} {formState.lastName} ({formState.birthDate}) is already a contact.</p>
          </div>
        </div>
        <div className="row usas-extra-top-margin">
          <div className="col-xs-12">
            <SecondaryButton type="button" onClick={onLocalDupPersonModalCancelClicked}>Cancel</SecondaryButton>
          </div>
        </div>
      </PopUpModal>
    </Fragment>
  );
};

export default PreCompOrganizationContactsDetail;