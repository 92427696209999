import React from 'react';

import usePreCompRegistrationLocationStaff from './UsePreCompRegistrationLocationStaff';

import PreCompRegistrationConstants from '../utils/PreCompRegistrationConstants';

import PreCompLocationStaffGrid from '../../preComp/components/grids/PreCompLocationStaffGrid';

import Headings from '../../../../common/components/headings/Headings';
import PrimaryButton from '../../../../common/components/buttons/PrimaryButton';
import LeftNav from '../../../../common/components/leftNav/LeftNav';
import SecondaryButton from '../../../../common/components/buttons/SecondaryButton';
import PopUpModal from '../../../../common/components/dialogs/PopUpModal';
import ActionIntraPageButton from '../../../../common/components/buttons/ActionIntraPageButton';

import global from '../../../../common/components/GlobalStyle.module.css';

const PreCompRegistrationLocationStaff = () => {
  const {
    locationRegistrationNavState,
    gridState,
    pageErrorMessageState,
    deleteModalState,
    onAddStaff,
    onEditStaff,
    onDeleteRole,
    onContinueClicked,
    onBackClicked,
    onModalCancelClicked,
    onModalDeleteClicked,
    PRIMARY_CONTACT_ORG_ROLE_ID
  } = usePreCompRegistrationLocationStaff();

  return (
    <LeftNav title={PreCompRegistrationConstants.LEFT_NAV_TITLE} state={locationRegistrationNavState} validateBeforeRedirect={false}>
      <Headings.H3>Location Staff</Headings.H3>
      <div className="row">
        <div className="col-xs-12 col-sm-12">
          <p><b>*At least one staff member with the Primary Contact role is required</b></p>
        </div>
      </div>
      <div className="row usas-extra-top-margin">
        <div className="col-xs-12">
          <ActionIntraPageButton onClick={onAddStaff}>Add Staff Member</ActionIntraPageButton>
        </div>
      </div>
      <div className="row usas-extra-top-margin">
        <div className="col-xs-12">
          <PreCompLocationStaffGrid
            data={gridState}
            isLoading={false}
            onEdit={onEditStaff}
            onDelete={onDeleteRole}
            canDeletePrimaryContact={false} 
            PRIMARY_CONTACT_ORG_ROLE_ID={PRIMARY_CONTACT_ORG_ROLE_ID}/>
        </div>
      </div>
      {pageErrorMessageState &&
        <div className="row">
          <div className="col-xs-12">
            <p className={global.ErrorMessage}>{pageErrorMessageState}</p>
          </div>
        </div>}
      <div className="row usas-extra-top-margin usas-extra-bottom-margin">
        <div className="col-xs-12 usas-extra-top-margin">
          <PrimaryButton type="button" onClick={onContinueClicked}>Continue</PrimaryButton>&nbsp;
          <SecondaryButton type="button" onClick={onBackClicked}>Back</SecondaryButton>
        </div>
      </div>
      <PopUpModal
        widthPct={90}
        maxWidth={720}
        title={'Remove Staff Member?'}
        displayPopUp={deleteModalState.displayModal}
        onModalCanceled={onModalCancelClicked}>
        <div className="row">
          <div className="col-xs-12">
            <p>{`Removing ${deleteModalState.personName}'s last role will remove them from this pre-competitive location's staff`}</p>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12 usas-extra-top-margin">
            <PrimaryButton type="button" onClick={onModalDeleteClicked}>Remove Staff Member</PrimaryButton>&nbsp;
            <SecondaryButton type="button" onClick={onModalCancelClicked}>Cancel</SecondaryButton>
          </div>
        </div>
      </PopUpModal>
    </LeftNav>
  );
};

export default PreCompRegistrationLocationStaff;