import { useEffect } from 'react';
import { useNavigate } from '../../../../common/wrappers/ReactRouterDom';

import { navLinks } from '../UseMemberAdministration';

import validate from './LSCHQSelectionValidation';

import useMemberAdministrationHeaderData from '../../../state/memberAdministrationHeader/UseMemberAdministrationHeaderData';

import useSelectOrgUnitData from '../../../../common/state/selectOrgUnit/UseSelectOrgUnitData';

import UseForm from '../../../../common/utils/UseForm';
import Constants from '../../../../common/utils/Constants';

const useLSCHQSelection = () => {
    const navigate = useNavigate();
    const { selectOrgUnitState, setSelectOrgUnitState } = useSelectOrgUnitData();
    const { memberAdministrationHeaderState, setMemberAdministrationHeaderState,
        clearMemberAdministrationHeaderObjData } = useMemberAdministrationHeaderData();
    const { handleSubmit, formState, errorState, onValueTextPairChanged
    } = UseForm(getInitialFormState, submitFormCallback, validate);

    useEffect(() => {
        //Clear out any prior data
        clearMemberAdministrationHeaderObjData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    function submitFormCallback() {
        setSelectOrgUnitState({
            ...selectOrgUnitState,
            orgUnitId: formState.orgUnitId,
            orgUnitName: formState.orgUnitName,
            showBackToSelection: true
        });
        setMemberAdministrationHeaderState({ ...memberAdministrationHeaderState, lscManagementHqSelection: true });
        navigate(navLinks.LSC_STAFF);
    }

    function getInitialFormState() {
        return {
            orgUnitId: Constants.DEFAULT_ID,
            orgUnitName: '--'
        };
    }

    return {
        handleSubmit,
        formState,
        errorState,
        onValueTextPairChanged
    };
};

export default useLSCHQSelection;