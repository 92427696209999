/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';

import EditIcon from '../../../../common/components/icons/EditIcon';

import global from '../../../../common/components/GlobalStyle.module.css';

const TableRow = ({ preCompLocation, onEdit }) => (
  <div className={global.SmallTableRow}>
    <div className={global.SmallTableRowHead}>
      <span className='hidden-xs'>{preCompLocation.locationName}</span>&nbsp;
      <button
        className={global.IconButton}
        type="button"
        onClick={() => onEdit(preCompLocation.locationId, preCompLocation.organizationId)}>
        <EditIcon />
      </button>
    </div>
    <div className={global.SmallTableRowInfo}>
      <div className='row'>
        <div className={['col-xs-6 col-sm-3 visible-xs', global.SmallTableRowLabels].join(' ')}>Location Name</div>
        <div className={['col-xs-6 col-sm-3 visible-xs', global.SmallTableRowData].join(' ')}>{preCompLocation.locationName}</div>
        <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>Organization Name</div>
        <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>{preCompLocation.organizationName || <span>&nbsp;</span>}</div>
        <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>Contact</div>
        <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>{preCompLocation.primaryContactName || <span>&nbsp;</span>}</div>
        <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>Contact Email</div>
        <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>{preCompLocation.primaryEmail || <span>&nbsp;</span>}</div>
        <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>Address</div>
        <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>{preCompLocation.location || <span>&nbsp;</span>}</div>
      </div>
    </div>
  </div>
);

const PreCompSearchGridSmall = ({ data, isLoading, onEdit }) => (
  <div className={[global.SmallTable, 'hidden-md hidden-lg'].join(' ')}>
    {isLoading === true
      ? (
        <div className={global.SmallTableRow}>
          <div className={global.SmallTableRowHead}>&nbsp;</div>
          <div className={global.SmallTableRowInfo}>
            <div className={global.SmallTableRowLabels}>Loading...</div>
          </div>
        </div>
      )
      : data.length > 0
        ? data.map((preCompLocation, i) => <TableRow key={i} preCompLocation={preCompLocation} onEdit={onEdit} />)
        : (
          <div className={global.SmallTableRow}>
            <div className={global.SmallTableRowHead}>&nbsp;</div>
            <div className={global.SmallTableRowInfo}>
              <div className={global.SmallTableRowLabels}>No Results</div>
            </div>
          </div>
        )}
  </div>
);

export default PreCompSearchGridSmall;