import React from 'react';

import ClubBasicRoutes from './ClubBasicRoutes';

import ClubContextProviders from './ClubContextProviders';

const Club = () => (
  <ClubContextProviders>
    <ClubBasicRoutes />
  </ClubContextProviders>
);

export default Club;