import React, { Fragment } from 'react';

import useFileUploadView from './UseFileUploadView';

import Headings from '../../../../common/components/headings/Headings';
import PopUpModal from '../../../../common/components/dialogs/PopUpModal';
import PrimaryButton from '../../../../common/components/buttons/PrimaryButton';
import SecondaryButton from '../../../../common/components/buttons/SecondaryButton';

import Constants from '../../../../common/utils/Constants';

import global from '../../../../common/components/GlobalStyle.module.css';

const Question = ({ question }) => (
  <div dangerouslySetInnerHTML={{ __html: question }} />
);

const FileUploadView = ({ programLevel, categoryMeasure, isReadonly = false, onRedirect }) => {
  const {
    isSaving,
    state,
    fileState,
    removeFileModalState,
    onUploadTemplateClicked,
    onRemoveTemplateClicked,
    onOpenRemoveFileModalClicked,
    onRemoveFileModalCanceled,
    onDownloadUploadedFile
  } = useFileUploadView(programLevel, categoryMeasure, onRedirect);

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>{categoryMeasure?.measureName}</Headings.H3>
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12">
          <Question question={categoryMeasure.question} />
        </div>
      </div>
      <div className="row usas-extra-top-margin">
        {isReadonly === true
          ? (
            <div className="col-xs-12">
              {fileState.templateFileUrl === '' ?
                <Fragment>
                  <p>No file found</p>
                </Fragment>
                :
                <Fragment>
                  <PrimaryButton type="button" onClick={(event) => onDownloadUploadedFile(event)}>Download Uploaded File</PrimaryButton>
                </Fragment>
              }
            </div>
          ) : (
            <div className="col-xs-12">
              {fileState.templateFileUrl === '' ?
                <Fragment>
                  <label htmlFor="emergency-plan-file-upload" className={global.FileUpload}>
                    Upload File
                  </label>
                  <input hidden id="emergency-plan-file-upload" type="file" onChange={onUploadTemplateClicked} />
                  &nbsp;(*.pdf), (*.doc), or (*.docx)
                </Fragment>
                :
                <Fragment>
                  <SecondaryButton onClick={onOpenRemoveFileModalClicked}>Remove Uploaded File</SecondaryButton>&nbsp;
                  <PrimaryButton type="button" onClick={(event) => onDownloadUploadedFile(event)}>Download Uploaded File</PrimaryButton>
                </Fragment>
              }
            </div>
          )}
      </div>
      <div className="row usas-extra-bottom-margin" hidden={fileState.templateFileUrl === ''}>
        <div className="col-xs-12">
          {fileState.templateFileName.includes("Bad Request")
            ? <p className={global.ErrorMessage} style={{ fontSize: 16, fontWeight: 'bold' }}>Invalid File Type</p>
            : <p><b>Uploaded File Name:</b> {fileState.templateFileName}</p>
          }
        </div>
      </div>
      {state.error &&
        <div className="row">
          <div className="col-xs-12">
            <p className={global.ErrorMessage}>{state.error ? state.error : ''}</p>
          </div>
        </div>
      }
      <div className="row usas-extra-top-margin usas-extra-bottom-margin">
        <div className="col-xs-12 usas-extra-top-margin">
          <SecondaryButton type="button" onClick={() => onRedirect(true)}>Back</SecondaryButton>
        </div>
      </div>
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.SAVING_MSG}
        displayPopUp={isSaving} />
      <PopUpModal
        widthPct={90}
        maxWidth={720}
        title={removeFileModalState.modalTitle}
        displayPopUp={removeFileModalState.displayPopUp}
        onModalCanceled={onRemoveFileModalCanceled}>
        <div className="row">
          <div className="col-xs-12">
            <p><b>File Type:</b> {categoryMeasure?.measureName}</p>
            {removeFileModalState.fileName.includes("Bad Request")
              ? <p className={global.ErrorMessage} style={{ fontSize: 16, fontWeight: 'bold' }}>Invalid File Type</p>
              : <p><b>Uploaded File Name:</b> {removeFileModalState.fileName}</p>
            }
          </div>
        </div>
        <div className="row usas-extra-top-margin">
          <div className="col-xs-12">
            <PrimaryButton type="button" onClick={onRemoveTemplateClicked}>Yes, Remove</PrimaryButton>&nbsp;
            <SecondaryButton type="button" onClick={onRemoveFileModalCanceled}>Cancel</SecondaryButton>
          </div>
        </div>
      </PopUpModal>
    </Fragment >
  );
};

export default FileUploadView;