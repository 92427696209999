import React from 'react';

import usePreCompRegistrationLocationInfo from './UsePreCompRegistrationLocationInfo';

import PreCompRegistrationConstants from '../utils/PreCompRegistrationConstants';

import PreCompLocationInfoForm from '../../preComp/components/forms/PreCompLocationInfoForm';

import Headings from '../../../../common/components/headings/Headings';
import PrimaryButton from '../../../../common/components/buttons/PrimaryButton';
import SecondaryButton from '../../../../common/components/buttons/SecondaryButton';
import PopUpModal from '../../../../common/components/dialogs/PopUpModal';
import LeftNav from '../../../../common/components/leftNav/LeftNav';

import Constants from '../../../../common/utils/Constants';

const PreCompRegistrationLocationInfo = () => {
  const {
    isLoading,
    formState,
    errorState,
    locationRegistrationNavState,
    displayExitModalState,
    onFormValueChanged,
    onValueTextPairChanged,
    onContinueClicked,
    onBackClicked,
    onModalCancelClicked,
    onModalExitClicked
  } = usePreCompRegistrationLocationInfo();

  return (
    <LeftNav title={PreCompRegistrationConstants.LEFT_NAV_TITLE} state={locationRegistrationNavState} validateBeforeRedirect={false}>
      <Headings.H3>Location Info</Headings.H3>
      <form noValidate>
        <PreCompLocationInfoForm
          formState={formState}
          errorState={errorState}
          onFormValueChanged={onFormValueChanged}
          onValueTextPairChanged={onValueTextPairChanged} />
        {errorState.errorOnSubmitAction &&
          <div className="row">
            <div className="col-xs-12">
              <p className={global.ErrorMessage}>{errorState.errorOnSubmitAction || ''}</p>
            </div>
          </div>}
        <div className="row usas-extra-top-margin usas-extra-bottom-margin">
          <div className="col-xs-12 usas-extra-top-margin">
            <PrimaryButton type="button" onClick={onContinueClicked}>Continue</PrimaryButton>&nbsp;
            <SecondaryButton type="button" onClick={onBackClicked}>Back</SecondaryButton>
          </div>
        </div>
      </form>
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={isLoading} />
      <PopUpModal
        widthPct={90}
        maxWidth={720}
        title={PreCompRegistrationConstants.EXIT_REGISTRATION}
        displayPopUp={displayExitModalState}
        onModalCanceled={onModalCancelClicked}>
        <div className="row">
          <div className="col-xs-12">
            <p>Are you sure you want to exit Pre-Competitive Registration?</p>
            <p><b>All progress will be lost.</b></p>
          </div>
        </div>
        <div className="row usas-extra-top-margin">
          <div className="col-xs-12">
            <PrimaryButton type="button" onClick={onModalExitClicked}>Yes, Exit Registration</PrimaryButton>&nbsp;
            <SecondaryButton type="button" onClick={onModalCancelClicked}>Cancel</SecondaryButton>
          </div>
        </div>
      </PopUpModal>
    </LeftNav>
  );
};

export default PreCompRegistrationLocationInfo;