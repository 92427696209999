import { useState } from "react";

import useLSCRegistrationManagementData from "../../../state/lscRegistrationManagement/UseLSCRegistrationManagementData";
import useReportPeriodSeasonData from "../../../../common/state/reportPeriodSeason/UseReportPeriodSeasonData";

import useOrgUnitLowestChildrenData from "../../../../common/state/orgUnitLowestChildren/UseOrgUnitLowestChildrenData";
import useSelectOrgUnitData from "../../../../common/state/selectOrgUnit/UseSelectOrgUnitData";

const useLSCRegistrationManagement = () => {
  const { selectOrgUnitState } = useSelectOrgUnitData();
  const { lscRegistrationManagementState, getLSCRegManagement, putLSCRegManagement } = useLSCRegistrationManagementData();
  const { reportPeriodSeasonState } = useReportPeriodSeasonData();
  const { orgUnitLowestChildrenState } = useOrgUnitLowestChildrenData();
  const [viewState, setViewState] = useState(getInitialViewState);
  const [warningModalState, setWarningModalState] = useState(getInitialWarningModalState);

  const onFilterGrid = (formState) => {
    if (viewState.isGridDirty === true) {
      setWarningModalState({
        ...warningModalState,
        displayModal: true,
        formState
      });
    } else {
      getLSCRegManagement(selectOrgUnitState.orgUnitId, formState.reportPeriodId, formState.registrationStartDate,
        formState.registrationEndDate, formState.orgUnitId ? formState.orgUnitId : undefined);
      setViewState({
        ...viewState,
        isGridDirty: false
      });
    }
  };

  const onSaveChanges = (gridData) => {
    if (viewState.isGridDirty === true) {
      setViewState({
        ...viewState,
        isGridDirty: false
      });

      putLSCRegManagement(selectOrgUnitState.orgUnitId, gridData);
    }
  };

  const setIsGridDirty = (isGridDirty) => {
    setViewState({
      ...viewState,
      isGridDirty
    });
  };

  const onWarningModalConfirm = () => {
    getLSCRegManagement(selectOrgUnitState.orgUnitId, warningModalState.formState.reportPeriodId,
      warningModalState.formState.registrationStartDate, warningModalState.formState.registrationEndDate,
      warningModalState.formState.orgUnitId ? warningModalState.formState.orgUnitId : undefined);
    setWarningModalState(getInitialWarningModalState);
    setViewState({
      ...viewState,
      isGridDirty: false
    });
  };

  const onWarningModalCancel = () => {
    setWarningModalState(getInitialWarningModalState);
  };

  function getInitialViewState() {
    return {
      organizationId: 1,
      isGridDirty: false
    };
  };

  function getInitialWarningModalState() {
    return {
      displayModal: false,
      formState: {}
    };
  };

  return {
    isSaving: lscRegistrationManagementState.isSaving,
    registrationData: lscRegistrationManagementState.arrayData,
    isGridLoading: lscRegistrationManagementState.isArrayLoading,
    viewState,
    warningModalState,
    selectOrgUnitState,
    reportPeriodSeasonState,
    orgUnitLowestChildrenState,
    onFilterGrid,
    onSaveChanges,
    setIsGridDirty,
    onWarningModalConfirm,
    onWarningModalCancel
  };
};

export default useLSCRegistrationManagement;