import { isValidDate } from '../../../utils/validation';
import Constants from '../../../utils/Constants';

export const PracticeAttributesFormValidation = (formState) => {
  let errors = {};

  const startDate = new Date(formState.startDate);
  const endDate = new Date(formState.endDate);

  if (formState.facilityId === Constants.DEFAULT_ID || formState.facilityId === '') {
    errors.facilityId = 'Facility is required';
  }

  if (formState.startDate === Constants.BLANK_DATE_STRING || formState.startDate === '') {
    errors.startDate = 'Start Date is required';
  } else if (!isValidDate(formState.startDate)) {
    errors.startDate = 'Start Date must be a valid date';
  } else if (endDate < startDate || formState.startDate === formState.endDate) {
    errors.startDate = 'Start Date must be before the End Date';
  }

  if (formState.endDate === Constants.BLANK_DATE_STRING || formState.endDate === '') {
    errors.endDate = 'End Date is required';
  } else if (!isValidDate(formState.endDate)) {
    errors.endDate = 'End Date must be a valid date';
  } else if (endDate < startDate || formState.startDate === formState.endDate) {
    errors.endDate = 'End Date must be after the Start Date';
  }

  return errors;
};

export default PracticeAttributesFormValidation;