//TODO move to common?
import { useEffect, useState } from 'react';

import SGConstants from '../../common/utils/SGConstants';
import { compareAlphabetical, compareDate, compareLength, compareNumerical, compareTime } from '../../common/utils/SortingUtils';

const INITIAL_SORTABLE_GRID_STATE = {
  sortedGridData: [],
  targetArrayIndex: '',
  sortDirection: SGConstants.SORT_DIRECTION_NONE,
  sortType: ''
};

const useSisenseSortableGrid = (gridData) => {
  const [sortableGridState, setSortableGridState] = useState(INITIAL_SORTABLE_GRID_STATE);

  const onColumnClicked = (arrayIndex, sortType) => {
    const newSortDirection = getNewSortDirection(arrayIndex, sortableGridState.sortDirection);

    setSortableGridState({
      ...sortableGridState,
      sortedGridData: sortGridData(sortableGridState.sortedGridData, arrayIndex, sortType, newSortDirection),
      sortDirection: newSortDirection,
      targetArrayIndex: arrayIndex,
      sortType
    });
  };

  useEffect(() => {
    if (Array.isArray(gridData)) {
      setSortableGridState({
        ...sortableGridState,
        sortedGridData: sortGridData(JSON.parse(JSON.stringify(gridData)), sortableGridState.targetArrayIndex, sortableGridState.sortType, sortableGridState.sortDirection)
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gridData]);

  function sortGridData(dataToSort, targetArrayIndex, sortType, sortDirection) {
    if (sortDirection !== SGConstants.SORT_DIRECTION_NONE && targetArrayIndex && sortType) {
      switch (sortType) {
        case SGConstants.SORT_TYPE_ALPHABETICAL:
          return sortGridAlphabetical(dataToSort, targetArrayIndex, sortDirection);
        case SGConstants.SORT_TYPE_NUMERICAL:
          return sortGridNumerical(dataToSort, targetArrayIndex, sortDirection);
        case SGConstants.SORT_TYPE_DATE:
          return sortGridDate(dataToSort, targetArrayIndex, sortDirection);
        case SGConstants.SORT_TYPE_TIME:
          return sortGridTime(dataToSort, targetArrayIndex, sortDirection);
        case SGConstants.SORT_TYPE_LENGTH:
          return sortGridLength(dataToSort, targetArrayIndex, sortDirection);
        default:
          return dataToSort;
      }
    } else {
      if (Array.isArray(gridData)) {
        return JSON.parse(JSON.stringify(gridData));
      } else {
        return [];
      }
    }
  }

  function sortGridAlphabetical(dataToSort, targetArrayIndex, sortDirection) {
    if (sortDirection === SGConstants.SORT_DIRECTION_DESCENDING) {
      return dataToSort.sort((a, b) => compareAlphabetical(a[targetArrayIndex].data, b[targetArrayIndex].data) * -1);
    } else {
      return dataToSort.sort((a, b) => compareAlphabetical(a[targetArrayIndex].data, b[targetArrayIndex].data));
    }
  }

  function sortGridNumerical(dataToSort, targetArrayIndex, sortDirection) {
    if (sortDirection === SGConstants.SORT_DIRECTION_DESCENDING) {
      return dataToSort.sort((a, b) => compareNumerical(a[targetArrayIndex].data, b[targetArrayIndex].data) * -1);
    } else {
      return dataToSort.sort((a, b) => compareNumerical(a[targetArrayIndex].data, b[targetArrayIndex].data));
    }
  }

  function sortGridDate(dataToSort, targetArrayIndex, sortDirection) {
    if (sortDirection === SGConstants.SORT_DIRECTION_DESCENDING) {
      return dataToSort.sort((a, b) => compareDate(a[targetArrayIndex].data, b[targetArrayIndex].data) * -1);
    } else {
      return dataToSort.sort((a, b) => compareDate(a[targetArrayIndex].data, b[targetArrayIndex].data));
    }
  }

  function sortGridTime(dataToSort, targetArrayIndex, sortDirection) {
    if (sortDirection === SGConstants.SORT_DIRECTION_DESCENDING) {
      return dataToSort.sort((a, b) => compareTime(a[targetArrayIndex].data?.replace('r', '').replace('c', '').replace('e', ''), b[targetArrayIndex].data?.replace('r', '').replace('c', '').replace('e', '')) * -1);
    } else {
      return dataToSort.sort((a, b) => compareTime(a[targetArrayIndex].data?.replace('r', '').replace('c', '').replace('e', ''), b[targetArrayIndex].data?.replace('r', '').replace('c', '').replace('e', '')));
    }
  }

  function sortGridLength(dataToSort, targetArrayIndex, sortDirection) {
    if (sortDirection === SGConstants.SORT_DIRECTION_DESCENDING) {
      return dataToSort.sort((a, b) => compareLength(a[targetArrayIndex].data, b[targetArrayIndex].data) * -1);
    } else {
      return dataToSort.sort((a, b) => compareLength(a[targetArrayIndex].data, b[targetArrayIndex].data));
    }
  }

  function getNewSortDirection(targetArrayIndex, sortDirection) {
    if (targetArrayIndex === sortableGridState.targetArrayIndex) {
      switch (sortDirection) {
        case SGConstants.SORT_DIRECTION_NONE: // first click
          return SGConstants.SORT_DIRECTION_ASCENDING;
        case SGConstants.SORT_DIRECTION_ASCENDING: // second click
          return SGConstants.SORT_DIRECTION_DESCENDING;
        default: // third click + catch
          return SGConstants.SORT_DIRECTION_NONE
      }
    } else { // first click
      return SGConstants.SORT_DIRECTION_ASCENDING;
    }
  }

  return {
    sortableGridState,
    onColumnClicked
  };
};

export default useSisenseSortableGrid;