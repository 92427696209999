import useSisenseLcmEventNoCourseDropdown from "./UseSisenseLcmEventNoCourseDropdown";

import Dropdown from '../../../../common/components/dropdowns/Dropdown';

import Constants from "../../../../common/utils/Constants";

import global from '../../../../common/components/GlobalStyle.module.css';

const SisenseLcmEventNoCourseDropdown = ({ label, name, value, error, message, onChange }) => {
  const { lcmEventNoCourseOptions } = useSisenseLcmEventNoCourseDropdown();

  return !lcmEventNoCourseOptions.options
    ? <span className={global.LoadingMsg}>{Constants.LOADING_MSG}</span>
    : (
      <Dropdown
        error={error}
        label={label}
        message={message}
        options={lcmEventNoCourseOptions.options}
        name={name}
        value={value}
        onChange={onChange}
        isLoading={!lcmEventNoCourseOptions.options}
      />
    );
};

export default SisenseLcmEventNoCourseDropdown;