import { Fragment } from "react";

import useRegistrationSubmitForm from "./UseRegistrationSubmitForm";

import { getUploadedFileName } from "../../../learnToSwim/utils/LearnToSwimUtils";

import PrimaryButton from "../../../../../common/components/buttons/PrimaryButton";
import SecondaryButton from "../../../../../common/components/buttons/SecondaryButton";
import PopUpModal from "../../../../../common/components/dialogs/PopUpModal";
import Headings from "../../../../../common/components/headings/Headings";

import Constants from "../../../../../common/utils/Constants";

import global from '../../../../../common/components/GlobalStyle.module.css';

const RegistrationSubmitForm = ({ submitButtonText = 'Submit', secondaryButtonText = 'Back',
  providerObj, onSubmitFormCallback, onSecondaryButtonClicked }) => {
  const {
    isSaving,
    formState,
    errorState,
    handleSubmit,
    onLogoFileLoad,
    onLogoFileUnload,
    onCurriculumFileLoad,
    onCurriculumFileUnload,
    onProofOfInsuranceFileLoad,
    onProofOfInsuranceFileUnload,
    onFormW9FileLoad,
    onFormW9FileUnload
  } = useRegistrationSubmitForm(onSubmitFormCallback, providerObj);

  return (
    <Fragment>
      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-xs-12 usas-extra-top-margin usas-extra-bottom-margin">
            <Headings.H6>Logo (Optional)</Headings.H6>
          </div>
          <div className="col-xs-12">
            {formState.logoFile?.size > 0 || formState.logoUrl !== ''
              ? (
                <Fragment>
                  <SecondaryButton onClick={(e) => onLogoFileUnload(e)}>Remove Uploaded File</SecondaryButton>&nbsp;
                  <p><b>Uploaded Logo File:</b> {formState.logoFile.name || getUploadedFileName(formState.logoUrl)}</p>
                </Fragment>
              ) : (
                <Fragment>
                  <label htmlFor="logo-file-upload" className={global.FileUpload}>Upload Logo</label>
                  <input hidden id="logo-file-upload" type="file" onChange={(e) => onLogoFileLoad(e)} />&nbsp;(*.jpg)
                  {errorState.logoFile && <p className={global.ErrorMessage}>{errorState.logoFile}</p>}
                </Fragment>
              )
            }
          </div>
          <div className="col-xs-12 usas-extra-top-margin usas-extra-bottom-margin">
            <Headings.H6>Curriculum (Required)</Headings.H6>
          </div>
          <div className="col-xs-12">
            {formState.curriculumFile?.size > 0 || formState.curriculumUrl !== ''
              ? (
                <Fragment>
                  <SecondaryButton onClick={(e) => onCurriculumFileUnload(e)}>Remove Uploaded File</SecondaryButton>&nbsp;
                  <p><b>Uploaded Curriculum File:</b> {formState.curriculumFile.name || getUploadedFileName(formState.curriculumUrl)}</p>
                </Fragment>
              ) : (
                <Fragment>
                  <label htmlFor="curriculum-file-upload" className={global.FileUpload}>Upload Curriculum</label>
                  <input hidden id="curriculum-file-upload" type="file" onChange={(e) => onCurriculumFileLoad(e)} />&nbsp;(*.pdf), (*.doc), or (*.docx)
                  {errorState.curriculumFile && <p className={global.ErrorMessage}>{errorState.curriculumFile}</p>}
                </Fragment>
              )
            }
          </div>
          <div className="col-xs-12 usas-extra-top-margin usas-extra-bottom-margin">
            <Headings.H6>Proof of Insurance (Required)</Headings.H6>
          </div>
          <div className="col-xs-12">
            {formState.proofOfInsuranceFile?.size > 0 || formState.proofOfInsuranceUrl !== ''
              ? (
                <Fragment>
                  <SecondaryButton onClick={(e) => onProofOfInsuranceFileUnload(e)}>Remove Uploaded File</SecondaryButton>&nbsp;
                  <p><b>Uploaded Proof Of Insurance File:</b> {formState.proofOfInsuranceFile.name || getUploadedFileName(formState.proofOfInsuranceUrl)}</p>
                </Fragment>
              ) : (
                <Fragment>
                  <label htmlFor="proof-of-insurance-file-upload" className={global.FileUpload}>Upload Proof of Insurance</label>
                  <input hidden id="proof-of-insurance-file-upload" type="file" onChange={(e) => onProofOfInsuranceFileLoad(e)} />&nbsp;(*.pdf), (*.doc), or (*.docx)
                  {errorState.proofOfInsuranceFile && <p className={global.ErrorMessage}>{errorState.proofOfInsuranceFile}</p>}
                </Fragment>
              )
            }
          </div>
          <div className="col-xs-12 usas-extra-top-margin usas-extra-bottom-margin">
            <Headings.H6>W9 Form (Required)</Headings.H6>
          </div>
          <div className="col-xs-12">
            {formState.formW9File?.size > 0 || formState.formW9Url !== ''
              ? (
                <Fragment>
                  <SecondaryButton onClick={(e) => onFormW9FileUnload(e)}>Remove Uploaded File</SecondaryButton>&nbsp;
                  <p><b>Uploaded W9 Form File:</b> {formState.formW9File.name || getUploadedFileName(formState.formW9Url)}</p>
                </Fragment>
              ) : (
                <Fragment>
                  <label htmlFor="w9-form-file-upload" className={global.FileUpload}>Upload W9 Form</label>
                  <input hidden id="w9-form-file-upload" type="file" onChange={(e) => onFormW9FileLoad(e)} />&nbsp;(*.pdf), (*.doc), or (*.docx)
                  {errorState.formW9File && <p className={global.ErrorMessage}>{errorState.formW9File}</p>}
                </Fragment>
              )
            }
          </div>
        </div>
        <div className="row pull-right">
          <div className="col-xs-12 usas-extra-top-margin usas-extra-bottom-margin">
            <SecondaryButton type="button" onClick={onSecondaryButtonClicked}>{secondaryButtonText}</SecondaryButton>&nbsp;
            <PrimaryButton type="submit">{submitButtonText}</PrimaryButton>
          </div>
        </div>
      </form>
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.UPLOADING_MSG}
        displayPopUp={isSaving} />
    </Fragment>
  );
};

export default RegistrationSubmitForm;