/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Fragment } from 'react';

import PlusIcon from '../../icons/PlusIcon';
import EditIcon from '../../icons/EditIcon';

import global from '../../GlobalStyle.module.css';

const LargeGrid = ({ state, onEdit, onCancelClicked }) => (
  <table className={[global.UsasTable, 'visible-lg visible-md'].join(' ')}>
    <thead>
      <tr>
        <th>Facility Name</th>
        <th>City</th>
        <th>State</th>
        <th style={{ minWidth: "70px" }}>Zip Code</th>
        <th>Address</th>
        {onCancelClicked ?
          <th></th> : <Fragment />}
      </tr>
    </thead>
    <tbody>
      {state.isArrayLoaded !== true
        ? (
          <tr>
            {onCancelClicked ?
              <Fragment>
                {state.isArrayLoading === true ?
                  <td colSpan="6">Loading...</td>
                  : <td colSpan="6">No Results</td>
                }
              </Fragment>
              :
              <Fragment>
                {state.isArrayLoading === true ?
                  <td colSpan="5">Loading...</td>
                  : <td colSpan="5">No Results</td>
                }
              </Fragment>}
          </tr>
        ) : Array.isArray(state.arrayData) && state.arrayData.length > 0
          ? state.arrayData.map((facility, i) => (
            <tr key={facility.facilityId > 0 ? facility.facilityId : i}>
              {onCancelClicked ?
                <td>{facility.facilityName}</td> :
                <td><a onClick={(e) => onEdit(e, facility.facilityId)}>{facility.facilityName}</a></td>}
              <td>{facility.city}</td>
              <td>{facility.stateCode}</td>
              <td>{facility.postalCode}</td>
              <td>{facility.address}</td>
              {onCancelClicked ? <td>
                <button className={global.IconButtonMargin} type="button" onClick={(e) => onEdit(e, facility.facilityId)}>
                  <PlusIcon />
                </button>
              </td> : <Fragment />}
            </tr>))
          : (
            <Fragment>
              {onCancelClicked ?
                <tr>
                  <td colSpan="6">No Results</td>
                </tr>
                :
                <tr>
                  <td colSpan="5">No Results</td>
                </tr>
              }
            </Fragment>
          )
      }
    </tbody>
  </table>
);

const SmallGrid = ({ state, onEdit, onCancelClicked }) => (
  <div className={[global.SmallTable, 'hidden-lg hidden-md'].join(' ')}>
    {state.isArrayLoaded !== true
      ? (
        <div className={global.SmallTableRow}>
          <div className={global.SmallTableRowHead}>
            &nbsp;
          </div>
          <div className={global.SmallTableRowInfo}>
            {state.isArrayLoading === true
              ? <div className={global.SmallTableRowLabels}>Loading...</div>
              : <div className={global.SmallTableRowLabels}>No Results</div>
            }
          </div>
        </div>
      ) : Array.isArray(state.arrayData) && state.arrayData.length > 0
        ? state.arrayData.map((facility, i) => (
          <div className={global.SmallTableRow} key={facility.facilityId > 0 ? facility.facilityId : i}>
            <div className={global.SmallTableRowHead}><span className='hidden-xs'>{facility.facilityName}</span>&nbsp;
              {onCancelClicked ?
                <button className={global.IconButton} type="button" onClick={(e) => onEdit(e, facility.facilityId)}>
                  <PlusIcon />
                </button> :
                <button className={global.IconButton} type="button" onClick={(e) => onEdit(e, facility.facilityId)}>
                  <EditIcon />
                </button>}
            </div>
            <div className={global.SmallTableRowInfo}>
              <div className='row'>
                <div className={['col-xs-6 col-sm-3 visible-xs', global.SmallTableRowLabels].join(' ')}>Facility Name</div>
                <div className={['col-xs-6 col-sm-3  visible-xs', global.SmallTableRowData].join(' ')}>{facility.facilityName || <span>&nbsp;</span>}</div>
                <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>City</div>
                <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>{facility.city || <span>&nbsp;</span>}</div>
                <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>State</div>
                <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>{facility.stateCode || <span>&nbsp;</span>}</div>
                <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>Zip Code</div>
                <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>{facility.postalCode || <span>&nbsp;</span>}</div>
                <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>Address</div>
                <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>{facility.address || <span>&nbsp;</span>}</div>
              </div>
            </div>
          </div>
        )) : (
          <div className={global.SmallTableRow}>
            <div className={global.SmallTableRowHead}>
              &nbsp;
            </div>
            <div className={global.SmallTableRowInfo}>
              <div className={global.SmallTableRowLabels}>
                No Results</div>
            </div>
          </div>
        )
    }
  </div>
);

const SearchFacilityGrid = ({ facilityState, searchFacilityState, onEdit, onFiltersToggle, onCancelClicked }) => (
  <Fragment>
    {facilityState.isArrayLoading === false ?
      <div className="row">
        <div className="col-xs-12 col-sm-10">
          {facilityState.arrayData.length > 499 ?
            <p className={global.ErrorMessage}>More than 500 Search Results returned. Please add additional filters to narrow your search results.</p> :
            <p>{facilityState.arrayData.length === 1 ? `${facilityState.arrayData.length} Search Result` : `${facilityState.arrayData.length} Search Results`}</p>}
        </div>
        <div className="col-xs-12 col-sm-2">
          <button className={global.ToggleButton} type="button" onClick={() => onFiltersToggle()}>{searchFacilityState.showFilters === true ? 'Hide Filters' : 'Show Filters'}</button>
        </div>
      </div> :
      <Fragment>
        <div className="row usas-extra-top-margin usas-extra-bottom-margin">
        </div>
      </Fragment>}
    <LargeGrid state={facilityState} onEdit={onEdit} onCancelClicked={onCancelClicked} />
    <SmallGrid state={facilityState} onEdit={onEdit} onCancelClicked={onCancelClicked} />
  </Fragment>
);

export default SearchFacilityGrid;