
import React, { Fragment } from 'react';

import SelectOrgUnit from '../../../../common/components/selectOrgUnit/SelectOrgUnit';

import useOrgUnitSelection from './UseOrgUnitSelection';

const OrgUnitSelection = () => {
  const { NavLinks, CRP_ROLE_GROUP_ID, TAXONOMIES, SCOPE } = useOrgUnitSelection();

  return (
    <Fragment>
      <SelectOrgUnit
        pageTitle={'Club Recognition Program'}
        navLink={NavLinks.CLUB_RECOGNITION_PROGRAM_LEVEL}
        roleGroupId={CRP_ROLE_GROUP_ID}
        getFunction={() => { }}
        TAXONOMIES={TAXONOMIES}
        SCOPE={SCOPE} />
    </Fragment>
  );
};

export default OrgUnitSelection;