import { isValidNumber } from '../../../../../common/utils/validation';

const TOTAL_AMOUNT_COLLECTED_MAXIMUM = 19000000;

export const localValidate = (formState) => {
  let errors = {};

  const totalAmountCollected = parseFloat(formState.totalAmountCollected);

  if (isNaN(totalAmountCollected)) {
    errors.totalAmountCollected = 'Total Amount Collected must be a valid number';
  } else if (totalAmountCollected < 1) {
    errors.totalAmountCollected = 'Total Amount Collected must be a valid amount';
  } else if (totalAmountCollected > TOTAL_AMOUNT_COLLECTED_MAXIMUM) {
    errors.totalAmountCollected = `Total Amount Collected cannot be greater than $19,000,000.00`;
  }

  if (formState.bagTags100Length.length > 0) {
    if (!isValidNumber(formState.bagTags100Length)) {
      errors.bagTags100Length = 'Number of Bag Tags for 100 Lengths must be a valid number';
    }
  }

  if (formState.bagTags200Length.length > 0) {
    if (!isValidNumber(formState.bagTags200Length)) {
      errors.bagTags200Length = 'Number of Bag Tags for 200 Lengths must be a valid number';
    }
  }

  return errors;
};

const SATResultFormValidation = async (formState) => {
  let errors = localValidate(formState);

  return errors ? errors : {};
};

export default SATResultFormValidation;