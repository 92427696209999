import React, { Fragment } from 'react';

import useClubRegistrationAffiliations from './UseClubRegistrationAffiliations';

import ClubRegistrationAffiliationsGridWrite from './ClubRegistrationAffiliationsGridWrite';
import ClubRegistrationAffiliationsGridRead from './ClubRegistrationAffiliationsGridRead';

import ValidationPopUpModal from '../components/ValidationPopUpModal';

import ClubRegistrationConstants from '../utils/ClubRegistrationConstants';

import LeftNav from '../../../../common/components/leftNav/LeftNav';
import Headings from '../../../../common/components/headings/Headings';
import PrimaryButton from '../../../../common/components/buttons/PrimaryButton';
import SecondaryButton from '../../../../common/components/buttons/SecondaryButton';
import ActionIntraPageButton from '../../../../common/components/buttons/ActionIntraPageButton';
import PopUpModal from '../../../../common/components/dialogs/PopUpModal';

import global from '../../../../common/components/GlobalStyle.module.css';
import Constants from '../../../../common/utils/Constants';

const WriteComponent = ({ clubRegistrationState, onAdd, onDelete, state }) => (
  <Fragment>
    <div className="row usas-extra-top-margin">
      <div className="col-xs-12">
        <ActionIntraPageButton type="button" onClick={() => onAdd()}>Add Affiliation</ActionIntraPageButton>
      </div>
    </div>
    <div className="row usas-extra-top-margin">
      <div className="col-xs-12">
        <ClubRegistrationAffiliationsGridWrite state={clubRegistrationState} onDelete={onDelete} />
      </div>
    </div>
    {state.error &&
      <div className="row">
        <div className="col-xs-12">
          <p className={global.ErrorMessage}>{state.error ? state.error : ''}</p>
        </div>
      </div>
    }
  </Fragment>
);

const ReadComponent = ({ clubRegistrationState }) => (
  <Fragment>
    <div className="row usas-extra-top-margin">
      <div className="col-xs-12 usas-extra-bottom-margin">
        <ClubRegistrationAffiliationsGridRead state={clubRegistrationState} />
      </div>
    </div>
  </Fragment>
);

const ClubRegistrationAffiliations = () => {
  const { clubRegistrationNavState, clubRegistrationState, state, modalState, onModalContinue, onModalCanceled,
    onAdd, onDelete, onContinueClicked, onSaveAndExitClicked, onBackClicked } = useClubRegistrationAffiliations();

  return (
    <LeftNav title={ClubRegistrationConstants.LEFT_NAV_TITLE} state={clubRegistrationNavState} validateBeforeRedirect={false}>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>Club Affiliations</Headings.H3>
        </div>
      </div>
      {clubRegistrationState.objData.status === ClubRegistrationConstants.IN_PROGRESS_STATUS
        || clubRegistrationState.objData.status === ClubRegistrationConstants.RENEWING_STATUS ?
        <WriteComponent
          clubRegistrationState={clubRegistrationState}
          onAdd={onAdd}
          onDelete={onDelete}
          state={state}
        /> :
        <ReadComponent
          clubRegistrationState={clubRegistrationState}
        />
      }
      <div className="row usas-extra-top-margin usas-extra-bottom-margin">
        <div className="col-xs-12 usas-extra-top-margin">
          <PrimaryButton type="button" onClick={() => onContinueClicked()}>Continue</PrimaryButton>&nbsp;
          <PrimaryButton type="button" onClick={() => onSaveAndExitClicked()}>
            {clubRegistrationState.objData.status === ClubRegistrationConstants.IN_PROGRESS_STATUS
              || clubRegistrationState.objData.status === ClubRegistrationConstants.RENEWING_STATUS ?
              ClubRegistrationConstants.SAVE_AND_EXIT : ClubRegistrationConstants.EXIT}</PrimaryButton>&nbsp;
          <SecondaryButton type="button" onClick={() => onBackClicked()}>Back</SecondaryButton>
        </div>
      </div>
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={clubRegistrationState.isObjLoading} />
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.SAVING_MSG}
        displayPopUp={clubRegistrationState.isSaving} />
      <ValidationPopUpModal
        clubRegistrationState={clubRegistrationState}
        state={state}
        modalState={modalState}
        onModalContinue={onModalContinue}
        onModalCanceled={onModalCanceled}
      />
    </LeftNav>
  )
}

export default ClubRegistrationAffiliations;