import { useEffect } from 'react';

import useClubAuditData from '../../../state/clubAudit/UseClubAuditData';

import useBaseUsasClubData from '../../../../common/state/clubBase/UseBaseUsasClubData';

import Constants from '../../../../common/utils/Constants';

const TITLE = 'Club Audit';

const useClubAudit = () => {
  const { baseUsasClubState } = useBaseUsasClubData();
  const { clubAuditState, getClubAudit } = useClubAuditData();

  useEffect(() => {
    if (baseUsasClubState.isObjLoaded === true) {
      getClubAudit(baseUsasClubState.objData.usasClubId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [baseUsasClubState]);

  return { title: TITLE, Constants, clubAuditState, baseUsasClubState };
};

export default useClubAudit;