import NavLinks from '../NavLinks';

import useEnvironmentVariableData from '../../../../common/state/environmentVariable/UseEnvironmentVariableData';

const TAXONOMIES = [''];
const SCOPE = 'ClubSafeSport';

const useOrgUnitSelection = () => {
    const { SSRP_ROLE_GROUP_ID } = useEnvironmentVariableData();
    return {
        NavLinks,
        SSRP_ROLE_GROUP_ID,
        TAXONOMIES,
        SCOPE
    };
};

export default useOrgUnitSelection;