import { Fragment } from 'react';

import useClubThirdPartyInvoicesDetail from './UseClubThirdPartyInvoicesDetail';

import ClubThirdPartyInvoicesDetailForm from './ClubThirdPartyInvoicesDetailForm';
import ClubThirdPartyInvoicesDetailGrid from './ClubThirdPartyInvoicesDetailGrid';
import ClubThirdPartyInvoicesDetailPersonPotentialMatchGrid from './ClubThirdPartyInvoicesDetailPersonPotentialMatchGrid';

import Headings from '../../../../common/components/headings/Headings';
import PopUpModal from '../../../../common/components/dialogs/PopUpModal';
import PrimaryButton from '../../../../common/components/buttons/PrimaryButton';
import SecondaryButton from '../../../../common/components/buttons/SecondaryButton';

import Constants from '../../../../common/utils/Constants';
import { formatDate } from '../../../../common/utils/DateFunctions';

import global from '../../../../common/components/GlobalStyle.module.css';

const ClubThirdPartyInvoicesDetail = ({ modalState, onModalCanceled }) => {
  const {
    state,
    baseUsasClubState,
    isLoading,
    isSaving,
    clubThirdPartyInvoiceState,
    setClubThirdPartyInvoiceState,
    lscOfferingByOrgUnitIdAndReportPeriodIdDropdownState,
    onDeleteClicked,
    gridState,
    setGridState,
    offeringOptions,
    personPotentialMatchState,
    personPotentialMatchModalState,
    onPersonPotentialMatchModalOpenClicked,
    onSelectMemberIdMatch,
    onPersonPotentialMatchModalSaveClicked,
    onPersonPotentialMatchModalCancelClicked,
    deleteModalState,
    onModalDeleteClicked,
    onModalCancelClicked,
    onCheckOutClicked,
    checkOutConfirmationModalState,
    onCheckOutConfirmationContinueClicked,
    onCheckOutConfirmationModalCanceled,
    onCancelClicked,
    onUpdateSelectedOfferingNameValueChange
  } = useClubThirdPartyInvoicesDetail(modalState, onModalCanceled);

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12 usas-extra-top-margin usas-extra-bottom-margin">
          <Headings.H5>Third Party Club Registration Id: {modalState && modalState?.invoice?.thirdPartyClubRegistrationId >= 0 ? modalState?.invoice?.thirdPartyClubRegistrationId : ''}</Headings.H5>
          <Headings.H5>Registration Date: {modalState && modalState?.invoice?.registrationDate ? formatDate(modalState?.invoice?.registrationDate) : ''}</Headings.H5>
          <Headings.H5>Report Period: {modalState && modalState?.invoice?.periodName || ''}</Headings.H5>
        </div>
      </div>
      <div className='row usas-extra-bottom-margin'>
        <div className="col-xs-12">
          <ClubThirdPartyInvoicesDetailForm
            clubThirdPartyInvoiceState={clubThirdPartyInvoiceState}
            setClubThirdPartyInvoiceState={setClubThirdPartyInvoiceState}
            lscOfferingByOrgUnitIdAndReportPeriodIdDropdownState={lscOfferingByOrgUnitIdAndReportPeriodIdDropdownState}
            gridState={gridState}
            setGridState={setGridState}
            offeringOptions={offeringOptions}
          />
        </div>
      </div>
      <div className='row usas-extra-top-margin usas-extra-bottom-margin'>
        <div className="col-xs-12">
          <ClubThirdPartyInvoicesDetailGrid
            lscOfferingByOrgUnitIdAndReportPeriodIdDropdownState={lscOfferingByOrgUnitIdAndReportPeriodIdDropdownState}
            orgUnitId={baseUsasClubState.objData.orgUnitId}
            reportPeriodId={modalState?.invoice?.reportPeriodId}
            gridData={gridState.gridData}
            isLoading={clubThirdPartyInvoiceState.isArrayLoading}
            onCheckOutClicked={onCheckOutClicked}
            onDeleteClicked={onDeleteClicked}
            onUpdateSelectedOfferingNameValueChange={onUpdateSelectedOfferingNameValueChange}
            showError={state.showError}
            onPersonPotentialMatchModalOpenClicked={onPersonPotentialMatchModalOpenClicked} />
        </div>
      </div>
      {state.showError === true && <p className={global.ErrorMessage}>At least one of the member registrations associated with this invoice has an error. Click &apos;Clear Filter&apos; above to ensure that all of the invoice&apos;s member registrations (and any of their error messages) are visible.  </p>}
      <div className="row">
        <div className="col-xs-12 usas-extra-top-margin usas-extra-bottom-margin">
          <PrimaryButton type="button" onClick={onCheckOutClicked}>Check Out</PrimaryButton>&nbsp;
          <SecondaryButton type="button" onClick={onCancelClicked}>Cancel</SecondaryButton>
        </div>
      </div>
      <PopUpModal
        widthPct={90}
        maxWidth={1000}
        title={'Potential Member ID Matches'}
        displayPopUp={personPotentialMatchModalState.displayPopUp}
        onModalCanceled={onPersonPotentialMatchModalCancelClicked}>
        <div className="row">
          <div className="col-xs-12">
            <p><b>Name:</b> {(personPotentialMatchModalState.invoice?.firstName || personPotentialMatchModalState.invoice?.middleName || personPotentialMatchModalState.invoice?.lastName) ? `${personPotentialMatchModalState.invoice?.firstName || ''}  ${personPotentialMatchModalState.invoice?.preferredName !== '' && personPotentialMatchModalState.invoice?.preferredName !== personPotentialMatchModalState.invoice?.firstName ? '"' + personPotentialMatchModalState.invoice?.preferredName + '"' : ''} ${personPotentialMatchModalState.invoice?.middleName || ''} ${personPotentialMatchModalState.invoice?.lastName || ''}` : ''}</p>
            <p><b>Competition Category:</b> {personPotentialMatchModalState.invoice?.competitionGenderTypeName}</p>
            <p><b>Birth Date:</b> {formatDate(personPotentialMatchModalState.invoice?.birthDate)}</p>
            <p><b>Submitted Offering Name:</b> {personPotentialMatchModalState.invoice?.submittedOfferingName}</p>
            <p><b>Offering Name:</b> {personPotentialMatchModalState.invoice?.selectedOfferingName}</p>
          </div>
          <div className="col-xs-12">
            <ClubThirdPartyInvoicesDetailPersonPotentialMatchGrid
              gridData={personPotentialMatchState.arrayData}
              isLoading={personPotentialMatchState.isArrayLoading}
              onSelected={onSelectMemberIdMatch}
            />
          </div>
          {personPotentialMatchModalState.showError === true &&
            <div className="col-xs-12">
              <p className={global.ErrorMessage}>{personPotentialMatchModalState.error}</p>
            </div>}
        </div>
        <div className="row">
          <div className="col-xs-12 usas-extra-top-margin">
            <PrimaryButton type="button" onClick={onPersonPotentialMatchModalSaveClicked}>Save</PrimaryButton>&nbsp;
            <SecondaryButton type="button" onClick={onPersonPotentialMatchModalCancelClicked}>Cancel</SecondaryButton>
          </div>
        </div>
      </PopUpModal>
      <PopUpModal
        widthPct={90}
        maxWidth={720}
        title={'Delete Third Party Invoice?'}
        displayPopUp={deleteModalState.displayPopUp}
        onModalCanceled={onModalCancelClicked}>
        <div className="row">
          <div className="col-xs-12">
            <p><b>Name:</b> {deleteModalState.name}</p>
            <p><b>Member ID:</b> {deleteModalState.memberId}</p>
            <p><b>Competition Category:</b> {deleteModalState.competitionCategory}</p>
            <p><b>Birth Date:</b> {deleteModalState.birthDate}</p>
            <p><b>Submitted Offering Name:</b> {deleteModalState.submittedOfferingName}</p>
            <p><b>Offering Name:</b> {deleteModalState.offeringName}</p>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12 usas-extra-top-margin">
            <PrimaryButton type="button" onClick={onModalDeleteClicked}>Delete</PrimaryButton>&nbsp;
            <SecondaryButton type="button" onClick={onModalCancelClicked}>Cancel</SecondaryButton>
          </div>
        </div>
      </PopUpModal>
      <PopUpModal
        widthPct={90}
        maxWidth={720}
        title={checkOutConfirmationModalState.modalTitle}
        displayPopUp={checkOutConfirmationModalState.displayPopUp && !clubThirdPartyInvoiceState.isSaving}
        onModalCanceled={onCheckOutConfirmationModalCanceled}>
        <form>
          <div className="row">
            <div className="col-xs-12">
              <b>Your selected third party invoice has been added to the shopping cart!</b>
            </div>
            <div className="col-xs-12 usas-extra-top-margin usas-extra-bottom-margin">
              <span>Please choose between continuing to the shopping cart to complete your invoice purchases, or navigating back to start the process to check out more invoices.</span>
            </div>
            <div className="col-xs-12 usas-extra-top-margin">
              <PrimaryButton type="button" onClick={onCheckOutConfirmationContinueClicked}>Continue to Shopping Cart</PrimaryButton>&nbsp;
              <SecondaryButton type="button" onClick={onCheckOutConfirmationModalCanceled}>Check Out More Invoices</SecondaryButton>
            </div>
          </div>
        </form>
      </PopUpModal>
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={isLoading} />
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.SAVING_MSG}
        displayPopUp={isSaving} />
    </Fragment>
  );
};

export default ClubThirdPartyInvoicesDetail;