import { useEffect } from 'react';
import { useNavigate } from '../../../common/wrappers/ReactRouterDom';

import NavLinks from './NavLinks';

import useSecurityData from '../../../common/state/security/UseSecurityData';
import usePersonOrgRoleData from '../../../common/state/personOrgRole/UsePersonOrgRoleData';
import useSelectOrgUnitData from '../../../common/state/selectOrgUnit/UseSelectOrgUnitData';

const useSafeSportContextView = () => {
  const navigate = useNavigate();
  const { securityState, userInfo, login } = useSecurityData();
  const { clearArrayData } = usePersonOrgRoleData();
  const { selectOrgUnitState, resetSelectOrgUnitState } = useSelectOrgUnitData();

  const onBackClicked = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    navigate(NavLinks.SAFE_SPORT_PROGRAM_LEVEL);
  };

  const onBackToSelectionClicked = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    resetSelectOrgUnitState();
    clearArrayData();
    goToSafeSportRoot();
  };

  useEffect(() => {
    if (userInfo?.isLoggedIn !== true) {
      login();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [securityState]);

  function goToSafeSportRoot() {
    navigate(NavLinks.SAFE_SPORT_SELECTION);
  };

  return {
    isLoggedIn: securityState.isLoggedIn,
    isLoading: securityState.isLoading,
    onBackClicked,
    onBackToSelectionClicked,
    selectOrgUnitState
  };
};

export default useSafeSportContextView;
