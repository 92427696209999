import { Fragment } from "react";

import useRegistrationProviderInfoForm from "./UseRegistrationProviderInfoForm";

import LearnToSwimCurriculumDropdown from "../../../../components/dropdowns/learnToSwimCurriculumDropdown/LearnToSwimCurriculumDropdown";
import LearnToSwimClubsCombobox from "../../../../components/comboboxes/learnToSwimClubsCombobox/LearnToSwimClubsCombobox";

import Input from "../../../../../common/components/inputs/Input";
import ReadOnly from "../../../../../common/components/readOnly/ReadOnly";
import YesNoSwitch from "../../../../../common/components/yesNoSwitch/YesNoSwitch";
import PrimaryButton from "../../../../../common/components/buttons/PrimaryButton";
import SecondaryButton from "../../../../../common/components/buttons/SecondaryButton";
import PopUpModal from "../../../../../common/components/dialogs/PopUpModal";
import Textarea from '../../../../../common/components/inputs/Textarea';

import Constants from "../../../../../common/utils/Constants";

import global from '../../../../../common/components/GlobalStyle.module.css';

const RegistrationProviderInfoForm = ({ submitButtonText = 'Save & Continue', secondaryButtonText = 'Back',
  providerObj, onSubmitFormCallback, onSecondaryButtonClicked }) => {
  const {
    isLoading,
    formState,
    errorState,
    handleSubmit,
    updateFormState,
    onValueTextPairChanged
  } = useRegistrationProviderInfoForm(onSubmitFormCallback, providerObj);

  return (
    <Fragment>
      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-xs-12 col-sm-6">
            <ReadOnly
              label='Business Legal Name'
              name="entityName"
              value={formState.entityName} />
          </div>
          <div className="col-xs-12 col-sm-6">
            <ReadOnly
              label='Trade Name/DBA'
              name="tradeName"
              value={formState.tradeName} />
          </div>
          <div className="col-xs-12 col-sm-6">
            <ReadOnly
              label='Tax Id'
              name="taxId"
              value={formState.taxId} />
          </div>
          <div className="col-xs-12 col-sm-6">
            <LearnToSwimCurriculumDropdown
              label='Learn To Swim Curriculum*'
              name="learnToSwimCurriculumId"
              value={formState.learnToSwimCurriculumId}
              error={errorState.learnToSwimCurriculumId}
              message={errorState.learnToSwimCurriculumId}
              onChange={(value, valueLabel) => { onValueTextPairChanged(value, 'learnToSwimCurriculumId', valueLabel, 'learnToSwimCurriculumName') }} />
          </div>
          <div className="col-xs-12 col-sm-6">
            <Input
              label='Website*'
              name="website"
              value={formState.website}
              error={errorState.website}
              message={errorState.website}
              onChange={(value) => { updateFormState('website', value) }} />
          </div>
          <div className="col-xs-12 col-sm-6">
            <YesNoSwitch
              label='Offers Financial Assistance?*'
              name="offersFinancialAssistance"
              checked={formState.offersFinancialAssistance}
              error={errorState.offersFinancialAssistance}
              message={errorState.offersFinancialAssistance}
              onChange={(value) => { updateFormState('offersFinancialAssistance', value) }} />
          </div>
          <div className="col-xs-12 col-sm-6">
            <YesNoSwitch
              label='Is A Current Year Learn To Swim Grant Recipient?*'
              name="currentLTSGrantRecipient"
              checked={formState.currentLTSGrantRecipient}
              error={errorState.currentLTSGrantRecipient}
              message={errorState.currentLTSGrantRecipient}
              onChange={(value) => { updateFormState('currentLTSGrantRecipient', value) }} />
          </div>
          <div className="col-xs-12 col-sm-6">
            <YesNoSwitch
              label='Is this Program operated by a USA Swimming Club?*'
              name="affiliatedWithUsasClub"
              checked={formState.affiliatedWithUsasClub}
              error={errorState.affiliatedWithUsasClub}
              message={errorState.affiliatedWithUsasClub}
              onChange={(value) => { updateFormState('affiliatedWithUsasClub', value) }} />
          </div>
          <div className={formState.affiliatedWithUsasClub === true ? global.DisplayComponent : global.HideComponent}>
            <div className="col-xs-12 col-sm-6">
              <LearnToSwimClubsCombobox
                label='Affiliated USA Swimming Club*'
                name="usasClubId"
                valueToMatch={formState.usasClubName}
                error={errorState.usasClubId}
                message={errorState.usasClubId}
                onChange={(value, valueLabel) => { onValueTextPairChanged(value, 'usasClubId', valueLabel, 'usasClubName') }} />
            </div>
          </div>
          <div className="col-xs-12">
            <YesNoSwitch
              label='Do you conduct pre-employment screenings (background checks) and reference checks on all employees?*'
              name="backgroundCheckAcknowledgement"
              checked={formState.backgroundCheckAcknowledgement}
              error={errorState.backgroundCheckAcknowledgement}
              message={errorState.backgroundCheckAcknowledgement}
              onChange={(value) => {
                updateFormState('backgroundCheckAcknowledgement', value);
                if (value === true) { updateFormState('backgroundCheckNotUsedExplanation', ''); }
              }} />
          </div>
          <div className={formState.backgroundCheckAcknowledgement === false ? global.DisplayComponent : global.HideComponent}>
            <div className="col-xs-12">
              <Textarea
                className={global.Textarea}
                label="If 'No', please explain using the space provided*"
                name="backgroundCheckNotUsedExplanation"
                value={formState.backgroundCheckNotUsedExplanation}
                error={errorState.backgroundCheckNotUsedExplanation}
                message={errorState.backgroundCheckNotUsedExplanation}
                onChange={(value) => { updateFormState('backgroundCheckNotUsedExplanation', value); }} />
            </div>
          </div>
          <div className="col-xs-12">
            <YesNoSwitch
              label='Does your program provide Child Protection Training for staff?*'
              name="childProtectionTraining"
              checked={formState.childProtectionTraining}
              error={errorState.childProtectionTraining}
              message={errorState.childProtectionTraining}
              onChange={(value) => { updateFormState('childProtectionTraining', value) }} />
          </div>
          <div className="col-xs-12 col-sm-6">
            <YesNoSwitch
              label='Do you offer Adult Learn to Swim Lessons?*'
              name="offerAdultLearnToSwim"
              checked={formState.offerAdultLearnToSwim}
              error={errorState.offerAdultLearnToSwim}
              message={errorState.offerAdultLearnToSwim}
              onChange={(value) => { updateFormState('offerAdultLearnToSwim', value) }} />
          </div>
          <div className="col-xs-12 col-sm-6">
            <YesNoSwitch
              label='Do you offer Adaptive or Special Needs Lessons?*'
              name="offerSpecialNeeds"
              checked={formState.offerSpecialNeeds}
              error={errorState.offerSpecialNeeds}
              message={errorState.offerSpecialNeeds}
              onChange={(value) => { updateFormState('offerSpecialNeeds', value) }} />
          </div>
          <div className="col-xs-12">
            <p><i>Please read the following Indemnification Agreement:</i></p>
            <p>To the greatest extent permitted by law, Swim Lesson Provider shall indemnify, defend and hold harmless the USA Swimming Foundation and USA Swimming and the directors, officers, employees, agents and volunteers on both organizations from any and all claims, damages (including but not limited to injury to persons or property), liabilities, fines and expenses, including but not limited to court costs and attorney’s fees, that arise out of or are related to: (a) the actions or omissions, negligence or intentional misconduct of the Swim Lesson Provider, its affiliates, agents, volunteers or employees; (b) use by the Swim Lesson Provider of any equipment or swimwear provided by the USA Swimming Foundation; and (c) breach of this Agreement by the Swim Lesson Provider.</p>
            <p>The Swim Lesson Provider shall, at its option, maintain in force either (a) policy or policies of insurance, (b) self-insurance program, or (c) combination of insurance policy and self-insurance policy, with limits as specified below, insuring against liability which may be imposed arising out of the above:</p>
            <p>Commercial general liability including personal injury insurance with the following limits:</p>
            <ul>
              <li style={{ marginLeft: '20px' }}>Each occurrence: $1,000,000</li>
              <li style={{ marginLeft: '20px' }}>General aggregate: $3,000,000</li>
            </ul>
            <p><b>BY ENTERING MY NAME, MY PROGRAM&apos;S NAME, AND THE DATE, I AGREE TO ALL OF THE TERMS AND CONDITIONS DESCRIBED ABOVE IN THE INDEMNIFICATION AGREEMENT.</b></p>
          </div>
          <div className="col-xs-12">
            <YesNoSwitch
              label=''
              name="indemnificationAgreement"
              checked={formState.indemnificationAgreement}
              error={errorState.indemnificationAgreement}
              message={errorState.indemnificationAgreement}
              onChange={(value) => { updateFormState('indemnificationAgreement', value) }} />
          </div>
          <div className="col-xs-12 col-sm-6">
            <Input
              label="Signature*"
              name="indemnificationAgreementSignature"
              value={formState.indemnificationAgreementSignature}
              error={errorState.indemnificationAgreementSignature}
              message={errorState.indemnificationAgreementSignature}
              onChange={(value) => { updateFormState('indemnificationAgreementSignature', value); }} />
          </div>
          <div className="col-xs-12 col-sm-6">
            <ReadOnly
              label="Signature Date*"
              name="indemnificationAgreementSignatureDate"
              value={formState.indemnificationAgreementSignatureDate} />
          </div>
        </div>
        <div className="row pull-right usas-extra-top-margin">
          <div className="col-xs-12 usas-extra-top-margin usas-extra-bottom-margin">
            <SecondaryButton type="button" onClick={onSecondaryButtonClicked}>{secondaryButtonText}</SecondaryButton>&nbsp;
            <PrimaryButton type="submit">{submitButtonText}</PrimaryButton>
          </div>
        </div>
      </form>
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={isLoading} />
    </Fragment>
  );
};

export default RegistrationProviderInfoForm;