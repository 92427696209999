import { useEffect } from 'react';

import useGroupCategoryData from '../../../state/groupCategory/UseGroupCategoryData';

const useGroupCategoriesTreeView = (orgUnitId = null) => {
  const { groupCategoryState, getGroupCategoriesByOrgUnitId } = useGroupCategoryData();

  useEffect(() => {
    if (orgUnitId) {
      getGroupCategoriesByOrgUnitId(orgUnitId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orgUnitId]);

  return {
    groupCategoryState
  };
};

export default useGroupCategoriesTreeView;