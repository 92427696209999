import { useEffect, useState } from 'react';
import { useNavigate, useLocation } from '../../../../common/wrappers/ReactRouterDom';

import NavLinks from '../NavLinks';

import useUsasClubPreCompLinksData from '../../../state/clubPreCompLinks/UseUsasClubPreCompLinksData';

import useBaseUsasClubData from '../../../../common/state/clubBase/UseBaseUsasClubData';

const useClubPreCompetitiveProgramsWrite = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { baseUsasClubState } = useBaseUsasClubData();
  const { usasClubPreCompLinksState, getUsasClubPreCompLinks, deleteUsasClubPreCompLinks } = useUsasClubPreCompLinksData();
  const [state, setState] = useState({ tryGet: false });

  const onAdd = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    };

    navigate(NavLinks.CLUB_PRECOMPETITIVE_SEARCH);
  };

  const onDelete = (e, preCompLocationClubId) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    };

    deleteUsasClubPreCompLinks(baseUsasClubState.objData.usasClubId, preCompLocationClubId);
  };

  useEffect(() => {
    if (location.state && location.state.tryGet === true) {
      setState({ ...state, tryGet: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (baseUsasClubState.isObjLoaded === true) {
      if ((usasClubPreCompLinksState.isArrayLoaded === false
        && usasClubPreCompLinksState.isArrayLoading === false) ||
        state.tryGet === true) {
        getUsasClubPreCompLinks(baseUsasClubState.objData.usasClubId);
        if (state.tryGet === true) {
          setState({ ...state, tryGet: false });
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [baseUsasClubState, usasClubPreCompLinksState, state]);

  return {
    usasClubPreCompLinksState,
    baseUsasClubState,
    onAdd,
    onDelete
  };
};

export default useClubPreCompetitiveProgramsWrite;