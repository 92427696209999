import { useEffect } from 'react';
import { useNavigate, useLocation } from '../../../../common/wrappers/ReactRouterDom';

import NavLinks from '../NavLinks';

import useRecognitionProgramData from '../../../../common/state/recognitionProgram/UseRecognitionProgramData';
import useEnvironmentVariableData from '../../../../common/state/environmentVariable/UseEnvironmentVariableData';

import Constants from '../../../../common/utils/Constants';

const useSafeSportProgramCategory = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { environmentVariableState, YES_NO_MEASURE_TYPE_ID, FILE_UPLOAD_MEASURE_TYPE_ID,
    TEXT_ENTRY_MEASURE_TYPE_ID, DROPDOWN_MEASURE_TYPE_ID, KPI_BASIC_MEASURE_TYPE_ID, ELSEWHERE_MEASURE_TYPE_ID,
    KPI_COACH_ROSTER_MEASURE_TYPE_ID, KPI_COACH_YEARS_MEASURE_TYPE_ID } = useEnvironmentVariableData();
  const { recognitionProgramState, programFocusState, getCurrentProgramByProgramType, updateProgramFocus
  } = useRecognitionProgramData();

  const onBackClicked = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    navigate(NavLinks.SAFE_SPORT_PROGRAM_LEVEL);
  };

  const onEditCategoryMeasure = (e, categoryMeasure) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    if (categoryMeasure.measureTypeId === YES_NO_MEASURE_TYPE_ID) {
      navigate(NavLinks.SAFE_SPORT_PROGRAM_CATEGORY_YES_NO, {
        state: {
          categoryMeasureId: categoryMeasure.categoryMeasureId
        }
      });
    } else if (categoryMeasure.measureTypeId === FILE_UPLOAD_MEASURE_TYPE_ID) {
      navigate(NavLinks.SAFE_SPORT_PROGRAM_CATEGORY_FILE_UPLOAD, {
        state: {
          categoryMeasureId: categoryMeasure.categoryMeasureId
        }
      });
    } else if (categoryMeasure.measureTypeId === TEXT_ENTRY_MEASURE_TYPE_ID) {
      navigate(NavLinks.SAFE_SPORT_PROGRAM_CATEGORY_TEXT_ENTRY, {
        state: {
          categoryMeasureId: categoryMeasure.categoryMeasureId
        }
      });
    } else if (categoryMeasure.measureTypeId === DROPDOWN_MEASURE_TYPE_ID) {
      navigate(NavLinks.SAFE_SPORT_PROGRAM_CATEGORY_DROPDOWN, {
        state: {
          categoryMeasureId: categoryMeasure.categoryMeasureId
        }
      });
    } else if (categoryMeasure.measureTypeId === KPI_BASIC_MEASURE_TYPE_ID
      || categoryMeasure.measureTypeId === KPI_COACH_ROSTER_MEASURE_TYPE_ID
      || categoryMeasure.measureTypeId === KPI_COACH_YEARS_MEASURE_TYPE_ID
      || categoryMeasure.measureTypeName === Constants.KPI_CLUB_STAFF_MEASURE_TYPE_NAME
      || categoryMeasure.measureTypeName === Constants.KPI_STAFF_ROLE_MEASURE_TYPE_NAME) {
      navigate(NavLinks.SAFE_SPORT_PROGRAM_CATEGORY_KPI, {
        state: {
          categoryMeasureId: categoryMeasure.categoryMeasureId
        }
      });
    } else if (categoryMeasure.measureTypeName === Constants.MULTISELECT_MEASURE_TYPE_NAME) {
      navigate(NavLinks.SAFE_SPORT_PROGRAM_CATEGORY_MULTISELECT, {
        state: {
          categoryMeasureId: categoryMeasure.categoryMeasureId
        }
      });
    } else if (categoryMeasure.measureTypeName === 'Member Id') {//TODO magic string
      navigate(NavLinks.SAFE_SPORT_PROGRAM_CATEGORY_MEMBER_ID, {
        state: {
          categoryMeasureId: categoryMeasure.categoryMeasureId
        }
      });
    } else if (categoryMeasure.measureTypeId === ELSEWHERE_MEASURE_TYPE_ID) {
      navigate(NavLinks.SAFE_SPORT_PROGRAM_CATEGORY_ELSEWHERE, {
        state: {
          categoryMeasureId: categoryMeasure.categoryMeasureId
        }
      });
    }
  };

  useEffect(() => {
    if (location.state?.performGet === true) {
      getCurrentProgramByProgramType(recognitionProgramState.orgUnitId, recognitionProgramState.programTypeId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (recognitionProgramState.isArrayLoading === false) {
      if (location.state?.programCategoryId > 0 && location.state?.programLevelInstanceId > 0) {
        updateProgramFocus(location.state.programLevelInstanceId, location.state.programCategoryId);
      } else if (!(programFocusState?.programLevelInstanceId > 0) && !(programFocusState?.programCategoryId > 0)) {
        navigate(NavLinks.SAFE_SPORT_ROOT);
      } else {
        updateProgramFocus(programFocusState?.programLevelInstanceId, programFocusState?.programCategoryId);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recognitionProgramState.isArrayLoading]);

  return {
    isLoading: environmentVariableState.isLoading || recognitionProgramState.isArrayLoading,
    programFocusState,
    onBackClicked,
    onEditCategoryMeasure
  };
};

export default useSafeSportProgramCategory;