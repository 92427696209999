import { useEffect } from 'react';

import useGroupFacilitiesData from '../../../state/groupFacilities/UseGroupFacilitiesData';

const useGroupFacilitiesDropdown = (orgUnitId = null, usasClubId = null, alwaysGet = false) => {
  const { groupFacilitiesState, getGroupFacilitiesByOrgUnitId, getGroupFacilitiesByUsasClubId } = useGroupFacilitiesData();

  useEffect(() => {
    if (orgUnitId) {
      getGroupFacilitiesByOrgUnitId(orgUnitId, alwaysGet);
    } else if (usasClubId) {
      getGroupFacilitiesByUsasClubId(usasClubId, alwaysGet);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orgUnitId, usasClubId]);

  return {
    groupFacilitiesState
  };
};

export default useGroupFacilitiesDropdown;