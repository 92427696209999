import { useContext } from "react";

import { SwimAThonStateContext } from "./SwimAThonContextProvider";
import { SwimAThonFiltersStateContext } from "./SwimAThonFiltersContextProvider";

import SwimAThonData from "./SwimAThonData";

const useSwimAThonData = () => {
  const [satState, setSATState] = useContext(SwimAThonStateContext);
  const [satFiltersState, setSATFiltersState] = useContext(SwimAThonFiltersStateContext);

  const searchSAT = (filterObject) => {
    setSATFiltersState({ ...satFiltersState, filterObject });
    SwimAThonData.searchSwimAThonOrgUnitData(filterObject, satState, setSATState);
  };

  const getSAT = (orgUnitId, orgUnitName = undefined, backToSystem = undefined) => {
    setSATFiltersState({ ...satFiltersState, backToSystem: backToSystem || satFiltersState.backToSystem })
    SwimAThonData.getSwimAThonContractsData(orgUnitId, orgUnitName || satState.orgUnitName, satState, setSATState);
  };

  const clearObjData = () => {
    setSATState({
      ...satState,
      isObjLoading: false,
      isObjLoaded: false,
      isSaving: false,
      isSaved: false,
      objData: {},
      orgUnitId: '',
      orgUnitName: '',
      message: ''
    });
  };

  return {
    satState,
    satFiltersState,
    setSATFiltersState,
    searchSAT,
    getSAT,
    clearObjData
  };
};

export default useSwimAThonData;