import React, { Fragment } from 'react';

import useClubFacilities from './UseClubFacilities';

import ClubFacilitiesWriteGrid from './ClubFacilitiesWriteGrid';

import Headings from '../../../../common/components/headings/Headings';
import ActionIntraPageButton from '../../../../common/components/buttons/ActionIntraPageButton';
import PrimaryButton from '../../../../common/components/buttons/PrimaryButton';
import SecondaryButton from '../../../../common/components/buttons/SecondaryButton';
import PopUpModal from '../../../../common/components/dialogs/PopUpModal';
import Constants from '../../../../common/utils/Constants';

const ClubFacilitiesWrite = () => {
  const {
    usasClubFacilityLinksState,
    modalState,
    poolModalState,
    onClickDeletePool,
    onPoolModalCanceled,
    onAddFacility,
    onDeletePool,
    onModalCanceled,
    onOpenModalClicked,
    onDeleteFacilityFromClub }
    = useClubFacilities();

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>Club Facilities</Headings.H3>
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12 usas-extra-top-margin">
          <ActionIntraPageButton type="button" onClick={onAddFacility}>Add Facility</ActionIntraPageButton>
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12 usas-extra-top-margin usas-extra-bottom-margin">
          <ClubFacilitiesWriteGrid onDelete={onOpenModalClicked} onDeletePool={onDeletePool} />
        </div>
      </div>
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={usasClubFacilityLinksState.isArrayLoading} />
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.SAVING_MSG}
        displayPopUp={usasClubFacilityLinksState.isSaving} />
      <PopUpModal
        widthPct={90}
        maxWidth={720}
        title={modalState.modalTitle}
        displayPopUp={modalState.displayPopUp}
        onModalCanceled={onModalCanceled}>
        <div className="row">
          <div className="col-xs-12">
            <p><b>Facility Name:</b> {modalState.facilityName}</p>
            <p><b>Facility Location:</b> {modalState.facilityCity}, {modalState.facilityStateCode} {modalState.facilityPostalCode}</p>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12 usas-extra-top-margin">
            <PrimaryButton type="button" onClick={onDeleteFacilityFromClub}>Unlink</PrimaryButton>&nbsp;
            <SecondaryButton type="button" onClick={onModalCanceled}>Cancel</SecondaryButton>
          </div>
        </div>
      </PopUpModal>
      <PopUpModal
        title={poolModalState.modalTitle}
        displayPopUp={poolModalState.displayPopup}
        widthPct={90}
        maxWidth={720}
        onModalCanceled={onPoolModalCanceled}>
        <div className="row">
          <div className="col-xs-12">
            <p><b>Pool Number / Name:</b> {poolModalState.poolName}</p>
            <p><b>Pool Dimensions:</b> {poolModalState.poolDimensions}</p>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12 usas-extra-top-margin">
            <PrimaryButton type="button" onClick={(e) => { onClickDeletePool(e, poolModalState.poolId); }}>Yes, delete</PrimaryButton>&nbsp;
            <SecondaryButton type="button" onClick={onPoolModalCanceled}>Cancel</SecondaryButton>
          </div>
        </div>
      </PopUpModal>
    </Fragment>
  );
};

export default ClubFacilitiesWrite;