import { Fragment } from "react"

import useClubSwimAThonSearch from './UseClubSwimAThonSearch';

import SATSearchGrid from "../components/grids/SATSearchGrid";
import SATSearchForm from "../components/forms/SATSearchForm";

import PrimaryButton from "../../../../common/components/buttons/PrimaryButton";
import SecondaryButton from "../../../../common/components/buttons/SecondaryButton";
import PopUpModal from "../../../../common/components/dialogs/PopUpModal";
import Headings from "../../../../common/components/headings/Headings";

import Constants from "../../../../common/utils/Constants";

const ClubSwimAThonSearch = () => {
  const {
    satState,
    formState,
    errorState,
    onClearFormClicked,
    handleSubmit,
    onFormValueChanged,
    onValueTextPairChanged,
    onEditSATOrgUnit,
    organizationOptionsState,
  } = useClubSwimAThonSearch();

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>Swim-a-Thon Search</Headings.H3>
        </div>
      </div>
      <form onSubmit={handleSubmit} noValidate>
        <SATSearchForm
          formState={formState}
          errorState={errorState}
          onFormValueChanged={onFormValueChanged}
          onValueTextPairChanged={onValueTextPairChanged}
          organizationOptions={organizationOptionsState} />
        <div className="row usas-extra-top-margin usas-extra-bottom-margin">
          <div className="col-xs-12 usas-extra-top-margin">
            <PrimaryButton type="submit">Search for Club</PrimaryButton>&nbsp;
            <SecondaryButton type="button" onClick={(e) => onClearFormClicked(e)}>Clear Form</SecondaryButton>&nbsp;
          </div>
        </div>
      </form>
      <div className="row usas-extra-bottom-margin">
        <div className="col-xs-12">
          <SATSearchGrid
            data={satState.arrayData}
            isLoading={satState.isArrayLoading}
            onEdit={onEditSATOrgUnit} />
        </div>
      </div>
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={satState.isArrayLoading || satState.isObjLoading} />
    </Fragment>
  );
};

export default ClubSwimAThonSearch;