import React, { Fragment } from 'react';

import useLearnToSwimProviderInfo from './UseLearnToSwimProviderInfo';

import ProviderInfoForm from '../components/forms/ProviderInfoForm';

import PopUpModal from '../../../../common/components/dialogs/PopUpModal';
import Headings from '../../../../common/components/headings/Headings';

import Constants from '../../../../common/utils/Constants';

const LearnToSwimProviderInfo = () => {
  const {
    isLoading,
    ltsProviderState,
    onSubmitFormCallback,
  } = useLearnToSwimProviderInfo();

  return (
    <Fragment>
      <div className='row usas-extra-top-margin'>
        <div className='col-xs-12'>
          <Headings.H3>Provider Info</Headings.H3>
        </div>
      </div>
      <ProviderInfoForm
        providerObj={ltsProviderState.objData}
        onSubmitFormCallback={onSubmitFormCallback} />
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={isLoading} />
    </Fragment>
  );
};

export default LearnToSwimProviderInfo;