import React, { createContext, useState } from 'react';

import ClubHistoryData from './ClubHistoryData';

export const ClubHistoryStateContext = createContext();

const ClubHistoryContextProvider = ({ children }) => {
  const stateHook = useState(ClubHistoryData.INITIAL_STATE);

  return (
    <ClubHistoryStateContext.Provider value={stateHook}>
      {children}
    </ClubHistoryStateContext.Provider>
  );
};

export default ClubHistoryContextProvider;