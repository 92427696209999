import React, { Fragment } from 'react';

import useLSCRegistrationManagement from './UseLSCRegistrationManagement';

import LSCRegistrationManagementForm from './form/LSCRegistrationManagementForm';
import LSCRegistrationManagementGrid from './grid/LSCRegistrationManagementGrid';

import Headings from '../../../../common/components/headings/Headings';
import PopUpModal from '../../../../common/components/dialogs/PopUpModal';
import PrimaryButton from '../../../../common/components/buttons/PrimaryButton';
import SecondaryButton from '../../../../common/components/buttons/SecondaryButton';

import Constants from '../../../../common/utils/Constants';

const LSCRegistrationManagement = () => {
  const {
    isSaving,
    registrationData,
    isGridLoading,
    viewState,
    warningModalState,
    selectOrgUnitState,
    reportPeriodSeasonState,
    orgUnitLowestChildrenState,
    onFilterGrid,
    onSaveChanges,
    setIsGridDirty,
    onWarningModalConfirm,
    onWarningModalCancel
  } = useLSCRegistrationManagement();

  return (
    <Fragment>
      <div className="row usas-extra-bottom-margin">
        <div className="col-xs-12">
          <Headings.H3>Registration Management</Headings.H3>
        </div>
      </div>
      <LSCRegistrationManagementForm
        onSubmitFormCallback={onFilterGrid}
        organizationId={viewState.organizationId}
        lscId={selectOrgUnitState.orgUnitId} />
      <div className="row usas-extra-top-margin usas-extra-bottom-margin">
        <div className="col-xs-12">
          <LSCRegistrationManagementGrid
            registrationData={registrationData}
            isLoading={isGridLoading}
            isGridDirty={viewState.isGridDirty}
            setIsGridDirty={setIsGridDirty}
            onSaveChanges={onSaveChanges} />
        </div>
      </div>
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={isGridLoading} />
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.SAVING_MSG}
        displayPopUp={isSaving} />
      <PopUpModal
        widthPct={90}
        maxWidth={720}
        title={'Unsaved Changes Detected'}
        displayPopUp={warningModalState.displayModal}
        onModalCanceled={onWarningModalCancel}>
        <div className="row">
          <div className="col-xs-12">
            <p>All unsaved changes will be lost after filter. Do you wish to continue?</p>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12 usas-extra-top-margin">
            <PrimaryButton type="button" onClick={onWarningModalConfirm}>Confirm</PrimaryButton>&nbsp;
            <SecondaryButton type="button" onClick={onWarningModalCancel}>Cancel</SecondaryButton>
          </div>
        </div>
      </PopUpModal>
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={reportPeriodSeasonState.isArrayLoading || orgUnitLowestChildrenState.isLoading} />
    </Fragment>
  );
};

export default LSCRegistrationManagement;