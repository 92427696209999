import { Fragment } from "react";
import PreviousSeasonOfferingDropdown from "../../../../components/dropdowns/previousSeasonOfferingDropdown/PreviousSeasonOfferingDropdown";

import Dropdown from "../../../../../common/components/dropdowns/Dropdown";
import Headings from "../../../../../common/components/headings/Headings";
import Input from "../../../../../common/components/inputs/Input";

import global from '../../../../../common/components/GlobalStyle.module.css';

const ClubBulkRenewFiltersForm = ({ title, competitionGenderOptions, formState, errorState, onValueTextPairChanged, onFormValueChanged, onFilterClicked, onClearFilterClicked, isAthleteRole }) => {
  return (
    <form>
      <div className='row usas-extra-top-margin usas-extra-bottom-margin'>
        <div className="col-xs-12 usas-extra-bottom-margin">
          <Headings.H6>{title}</Headings.H6>
        </div>
        {isAthleteRole === true &&
          <Fragment>
            <div className='col-xs-12 col-md-4'>
              <Dropdown
                label="Competition Category"
                name="competitionGenderTypeId"
                options={competitionGenderOptions}
                value={formState.competitionGenderTypeId}
                error={errorState.competitionGenderTypeId}
                message={errorState.competitionGenderTypeId}
                isLoading={false}
                onChange={(newValue, newValueLabel, e) => {
                  e && e.target && e.target.value &&
                    onValueTextPairChanged(newValue, 'competitionGenderTypeId', newValueLabel, 'competitionGenderTypeName');
                }} />
            </div>
            <div className="col-xs-6 col-md-2">
              <Input
                label="Min Age"
                name="minAge"
                type="number"
                min="1"
                max="99"
                value={formState.minAge}
                error={errorState.minAge}
                message={errorState.minAge}
                onChange={(value) => { onFormValueChanged('minAge', value) }} />
            </div>
            <div className="col-xs-6 col-md-2">
              <Input
                label="Max Age"
                name="maxAge"
                type="number"
                min="1"
                max="99"
                value={formState.maxAge}
                error={errorState.maxAge}
                message={errorState.maxAge}
                onChange={(value) => { onFormValueChanged('maxAge', value) }} />
            </div>
          </Fragment>}
        <div className="col-xs-12 col-md-4">
          <PreviousSeasonOfferingDropdown
            label={"Last Membership"}
            name={"index"}
            value={formState.index}
            error={errorState.index}
            message={errorState.index}
            onChange={(newValue, newValueLabel, e) =>
              e && e.target && e.target.value && onValueTextPairChanged(newValue, 'index', newValueLabel, 'offeringName', e)}
            isAthleteRole={isAthleteRole === true ? isAthleteRole : null}
          />
        </div>
        <div className={isAthleteRole === true ? "col-xs-12 col-md-3 col-lg-3" : "col-xs-12 col-md-4"} >
          <Input
            label="First or Preferred Name"
            name="firstOrPreferredName"
            value={formState.firstOrPreferredName}
            error={errorState.firstOrPreferredName}
            message={errorState.firstOrPreferredName}
            onChange={(value) => { onFormValueChanged('firstOrPreferredName', value) }} />
        </div>
        <div className={isAthleteRole === true ? "col-xs-12 col-md-3 col-lg-4" : "col-xs-12 col-md-4"} >
          <Input
            label="Last Name"
            name="lastName"
            value={formState.lastName}
            error={errorState.lastName}
            message={errorState.lastName}
            onChange={(value) => { onFormValueChanged('lastName', value) }} />
        </div>
        <div className='col-xs-5 col-md-2 col-lg-2'>
          <button className={global.PlainButton} onClick={(e) => onFilterClicked(e)}>Filter</button>
        </div>
        <div className='col-xs-7 col-md-4 col-lg-3'>
          <button className={global.PlainButton} onClick={(e) => onClearFilterClicked(e)}>Clear Filter</button>
        </div>
      </div>
    </form>
  )
};

export default ClubBulkRenewFiltersForm;