import { Fragment } from "react";

import ClubManageOfferingsGridLarge from "./ClubManageOfferingsGridLarge";
import ClubManageOfferingsGridSmall from "./ClubManageOfferingsGridSmall";

const ClubManageOfferingsGrid = ({ gridData, isLoading, onCheckboxClicked }) => (
  <Fragment>
    <ClubManageOfferingsGridLarge gridData={gridData} isLoading={isLoading} onCheckboxClicked={onCheckboxClicked} />
    <ClubManageOfferingsGridSmall gridData={gridData} isLoading={isLoading} onCheckboxClicked={onCheckboxClicked} />
  </Fragment>
);

export default ClubManageOfferingsGrid;