import React, { Fragment } from 'react';

import { formatDate } from '../../../../common/utils/DateFunctions';
import DeleteIcon from '../../../../common/components/icons/DeleteIcon';

import global from '../../../../common/components/GlobalStyle.module.css';

const GridRowIcons = ({ vendor, onOpenDeleteVendorModalClicked }) => (
	<Fragment>
		{vendor?.expirationDate === null
			? <button
				className={[global.IconButtonMargin, 'pull-right'].join(' ')}
				type="button"
				onClick={(e) => onOpenDeleteVendorModalClicked(e, vendor)}>
				<DeleteIcon />
			</button>
			: <Fragment />
		}
	</Fragment>
);

const GridRowContent = ({ vendor }) => (
	<div className={global.SmallTableRowInfo}>
		<div className='row'>
			<div className={['col-xs-6 col-sm-3 visible-xs', global.SmallTableRowLabels].join(' ')}>
				Vendor Name
			</div>
			<div className={['col-xs-6 col-sm-3 visible-xs', global.SmallTableRowData].join(' ')}>
				{vendor.vendor.vendorName}
			</div>
			<div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>
				Date Added
			</div>
			<div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>
				{formatDate(vendor.effectiveDate)}
			</div>
			<div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>
				Date Expired
			</div>
			<div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>
				{vendor?.expirationDate === null ? '  ' : formatDate(vendor?.expirationDate)}
			</div>
		</div>
	</div>
);

const GridRow = ({ vendor, onOpenDeleteVendorModalClicked }) => {
	return (
		<Fragment>
			<div className={global.SmallTableRow}>
				<div className={global.SmallTableRowHead}>
					<span className='hidden-xs'>{vendor?.vendor?.vendorName}</span>&nbsp;
					<GridRowIcons vendor={vendor} onOpenDeleteVendorModalClicked={onOpenDeleteVendorModalClicked} />
				</div>
				<GridRowContent vendor={vendor} />
			</div>
		</Fragment>
	);
}
const SmallClubActivateVendorGrid = ({ state, onOpenDeleteVendorModalClicked }) => (
	<div className={[global.SmallTable, 'hidden-md hidden-lg'].join(' ')}>
		{state.isArrayLoaded !== true
			? (<div className={global.SmallTableRow}>
				<div className={global.SmallTableRowHead}> &nbsp; </div>
				<div className={global.SmallTableRowInfo}>
					{state.isArrayLoading === true
						? <div className={global.SmallTableRowLabels}>Loading...</div>
						: <div className={global.SmallTableRowLabels}>There are no vendors associated with this club</div>
					}
				</div>
			</div>)
			: Array.isArray(state?.arrayData) === true && state?.arrayData?.length > 0
				? state?.arrayData?.map((vendor, i) =>
					<GridRow
						key={i}
						vendor={vendor}
						onOpenDeleteVendorModalClicked={onOpenDeleteVendorModalClicked} />)
				: (<div className={global.SmallTableRow}>
					<div className={global.SmallTableRowHead}>
						&nbsp;
					</div>
					<div className={global.SmallTableRowInfo}>
						<div className={global.SmallTableRowLabels}>There are no vendors associated with this club</div>
					</div>
				</div>)
		}
	</div>
);

export default SmallClubActivateVendorGrid;