import { useEffect, useRef, useCallback } from 'react';

const useCanvasImage = (image, textToOverlay, xLoc, yLoc, fontName, fontSize) => {
  const canvasRef = useRef(null)

  //useCallback is used here to prevent the function from making the dependencies of the useEffect change on every render
  //wrapping the function in useCallback allows it to maintain the same memory reference through each render
  const draw = useCallback(ctx => {
    let img = new Image();
    img.addEventListener("load", () => {
      ctx.drawImage(img, 0, 0);
      ctx.font = `${fontSize}px ${fontName}`;
      ctx.fillStyle = "#1D3160";
      ctx.fillText(textToOverlay, xLoc, yLoc);
    });
    img.src = image;
  }, [fontName, fontSize, image, textToOverlay, xLoc, yLoc]);

  useEffect(() => {
    const canvas = canvasRef.current;
    const context = canvas.getContext('2d');

    draw(context);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [draw])

  return { canvasRef }
}

export default useCanvasImage;