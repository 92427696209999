import { useState } from 'react';

import ClubBaseInfoData from './ClubBaseInfoData';

const useClubBaseInfoData = () => {
  const [clubBaseInfoState, setBaseInfoState] = useState(ClubBaseInfoData.INITIAL_STATE);

  const getClubBaseInfo = (usasClubId) => {
    ClubBaseInfoData.getClubBaseInfo(usasClubId, clubBaseInfoState, setBaseInfoState);
  };

  return {
    clubBaseInfoState,
    getClubBaseInfo
  };
};

export default useClubBaseInfoData;