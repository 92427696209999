import useSisenseScyScmEventNoCourseDropdown from "./UseSisenseScyScmEventNoCourseDropdown";

import Dropdown from '../../../../common/components/dropdowns/Dropdown';

import Constants from "../../../../common/utils/Constants";

import global from '../../../../common/components/GlobalStyle.module.css';

const SisenseScyScmEventNoCourseDropdown = ({ label, name, value, error, message, onChange }) => {
  const { scyScmEventNoCourseOptions } = useSisenseScyScmEventNoCourseDropdown();

  return !scyScmEventNoCourseOptions.options
    ? <span className={global.LoadingMsg}>{Constants.LOADING_MSG}</span>
    : (
      <Dropdown
        error={error}
        label={label}
        message={message}
        options={scyScmEventNoCourseOptions.options}
        name={name}
        value={value}
        onChange={onChange}
        isLoading={!scyScmEventNoCourseOptions.options}
      />
    );
};

export default SisenseScyScmEventNoCourseDropdown;