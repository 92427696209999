import React, { Fragment } from 'react';

import usePreCompSearch from './UsePreCompSearch';

import useSearchPreComp from '../../../../common/components/searches/searchPreComp/UseSearchPreComp';

import PreCompSearchGrid from './PreCompSearchGrid';

import SearchPreComp from '../../../../common/components/searches/searchPreComp/SearchPreComp';
import PopUpModal from '../../../../common/components/dialogs/PopUpModal';
import Constants from '../../../../common/utils/Constants';

const PreCompSearch = () => {
  const { onEditOrganization, preCompLocationState } = usePreCompSearch();
  const { gridState, preCompOrganizationState, searchPreCompState, onFiltersToggle } = useSearchPreComp();

  return (
    <Fragment>
      <SearchPreComp
        pageTitle={"Pre-Competitive Organization/Location Search"}
        submitButtonText={"Search For Programs"}
        grid={<PreCompSearchGrid
          data={gridState}
          searchPreCompState={searchPreCompState}
          isLoading={preCompOrganizationState.isArrayLoading}
          onEdit={onEditOrganization}
          onFiltersToggle={onFiltersToggle} />
        }
        searchPreCompState={searchPreCompState} />
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={preCompLocationState.isObjLoading && !preCompOrganizationState.isObjLoading}
      />
    </Fragment>
  );
};

export default PreCompSearch;