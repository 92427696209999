import { useState, useEffect } from "react";
import { useNavigate } from "../../../../../common/wrappers/ReactRouterDom";

import NavLinks from "../../NavLinks";

import useClubMeetsData from "../../../../state/clubMeets/UseClubMeetsData";
import useUsasClubFacilityLinksData from '../../../../state/clubFacilityLinks/UseUsasClubFacilityLinksData';

import useMeetSessionsData from '../../../../../common/state/meetSessions/UseMeetSessionsData';
import useBaseUsasClubData from "../../../../../common/state/clubBase/UseBaseUsasClubData";
import useEnvironmentVariableData from "../../../../../common/state/environmentVariable/UseEnvironmentVariableData";

import Constants from "../../../../../common/utils/Constants";
import { formatDate } from "../../../../../common/utils/DateFunctions";


const UseClubMeetSanctionRequest = () => {
  const navigate = useNavigate();
  const { environmentVariableState, MEET_DIRECTOR_STAFF_TYPE_ID, MEET_REFEREE_STAFF_TYPE_ID, MEET_CONTACT_STAFF_TYPE_ID,
    OFFICIAL_ORG_ROLE_ID, OFFICIALS_ROLES_ROLE_GROUP_ID } = useEnvironmentVariableData();
  const { baseUsasClubState } = useBaseUsasClubData();
  const { usasClubFacilityLinksState, getUsasClubFacilityLinks } = useUsasClubFacilityLinksData();
  const { clubMeetsState, postMeetSanctionRequest } = useClubMeetsData();
  const [displayMeetInfoFormState, setDisplayMeetInfoFormState] = useState(true);
  const [displayMeetSessionsFormState, setDisplayMeetSessionsFormState] = useState(false);
  const [displayMeetSessionsDetailFormState, setDisplayMeetSessionsDetailFormState] = useState(false);
  const [displayStaffFormState, setDisplayStaffFormState] = useState(false);
  const [meetInfoState, setMeetInfoState] = useState({});
  const { meetSessionsState, setMeetSessionsState, resetModalState } = useMeetSessionsData();
  const [meetStaffState, setMeetStaffState] = useState({});
  const [facilityOptionsState, setFacilityOptionsState] = useState([{ id: Constants.DEFAULT_ID, name: '--' }]);
  const [state, setState] = useState({ error: '' });

  const onInfoFormSubmit = (infoFormState) => {
    setState({ error: '' });
    setDisplayMeetInfoFormState(false);
    setDisplayMeetSessionsFormState(true);
    setMeetInfoState(infoFormState);
    setMeetSessionsState({
      ...meetSessionsState,
      formState: {
        ...meetSessionsState.formState,
        meetStartDate: meetInfoState.startDate ? formatDate(meetInfoState.startDate) : Constants.BLANK_DATE_STRING,
        meetEndDate: meetInfoState.endDate ? formatDate(meetInfoState.endDate) : Constants.BLANK_DATE_STRING
      }
    })
  };

  const onAddSession = () => {
    const prevHighestSessionOrderId = meetSessionsState.sortedMeetSessionArray?.length > 0 ? Math.max(...meetSessionsState.sortedMeetSessionArray?.map(o => o.sessionOrderId)) : 0;
    setMeetSessionsState({ ...meetSessionsState, formState: { ...meetSessionsState.formState, sessionOrderId: prevHighestSessionOrderId >= 0 ? prevHighestSessionOrderId + 1 : '' }, errorState: {} });
    setDisplayMeetSessionsFormState(false);
    setDisplayMeetSessionsDetailFormState(true);
  }

  const onEditSession = (e, session) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }
    setMeetSessionsState({ ...meetSessionsState, errorState: {}, formState: { ...meetSessionsState.formState, meetSessionId: session.meetSessionId } });
    setDisplayMeetSessionsFormState(false);
    setDisplayMeetSessionsDetailFormState(true);
  }

  const onDeleteSession = (e, meetSessionId) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }
    const meetSessionArrayCopy = JSON.parse(JSON.stringify(meetSessionsState.meetSessionArray));
    const selectedArrayIndex = meetSessionArrayCopy.findIndex(x => x.meetSessionId === meetSessionId);
    if (selectedArrayIndex > -1) {
      meetSessionArrayCopy.splice(selectedArrayIndex, 1);
      setMeetSessionsState({
        ...meetSessionsState,
        meetSessionArray: meetSessionArrayCopy,
        modalState: {
          displayPopUp: false,
          modalTitle: 'Delete Session?',
          meetSessionId: Constants.DEFAULT_ID,
          sessionOrderId: '',
          sessionTypeName: '',
          sessionDate: Constants.BLANK_DATE_STRING
        }
      })
    }
  }

  const onDeleteSessionClicked = (e, session) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    setMeetSessionsState({
      ...meetSessionsState,
      modalState: {
        ...meetSessionsState.modalState,
        displayPopUp: true,
        meetSessionId: session.meetSessionId,
        sessionOrderId: session.sessionOrderId,
        sessionTypeName: session.sessionType?.typeName,
        sessionDate: session.sessionDate
      }
    })
  };

  const onModalCanceled = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    resetModalState();
  };

  const onSessionsFormSubmit = () => {
    let dateError = false;
    if (meetSessionsState.meetSessionArray.length > 0) {
      const meetStartDate = new Date(meetSessionsState.formState?.meetStartDate);
      const meetEndDate = new Date(meetSessionsState.formState?.meetEndDate);

      for (let i = 0; i < meetSessionsState.meetSessionArray.length; i++) {
        const sessionDate = new Date(meetSessionsState.meetSessionArray[i].sessionDate);
        if (sessionDate < meetStartDate || sessionDate > meetEndDate) {
          dateError = true;
        }
      }

      if (dateError === true) {
        setMeetSessionsState({ ...meetSessionsState, errorState: { error: `All Session Dates must be between the Meet Start Date and Meet End Date (${meetSessionsState?.formState.meetStartDate || ''} - ${meetSessionsState?.formState.meetEndDate || ''}).` } });
      }
      else {
        setDisplayMeetSessionsFormState(false);
        setDisplayStaffFormState(true);
      }
    }
    else {
      setMeetSessionsState({ ...meetSessionsState, errorState: { error: 'At least one Session is required.' } });
    }
  };

  const onStaffFormSubmit = (staffFormState) => {
    setMeetStaffState(staffFormState);

    postMeetSanctionRequest(createMeetSanctionRequest(baseUsasClubState.objData.orgUnitId, meetInfoState, staffFormState));
  };

  const onCancelClicked = () => {
    navigate(NavLinks.CLUB_MEETS);
  };

  const onBackToMeetInfoClicked = () => {
    let dateError = false;
    if (meetSessionsState.meetSessionArray.length > 0) {
      const meetStartDate = new Date(meetSessionsState.formState.meetStartDate);
      const meetEndDate = new Date(meetSessionsState.formState.meetEndDate);

      for (let i = 0; i < meetSessionsState.meetSessionArray.length; i++) {
        const sessionDate = new Date(meetSessionsState.meetSessionArray[i].sessionDate);
        if (sessionDate < meetStartDate || sessionDate > meetEndDate) {
          dateError = true;
        }
      }

      if (dateError === true) {
        setMeetSessionsState({ ...meetSessionsState, errorState: { error: `All Session Dates must be between the Meet Start Date and Meet End Date (${meetSessionsState?.formState?.meetStartDate || ''} - ${meetSessionsState?.formState?.meetEndDate || ''}).` } });
      }
      else {
        setDisplayMeetSessionsFormState(false);
        setDisplayMeetInfoFormState(true);
      }
    }
    else {
      setMeetSessionsState({ ...meetSessionsState, errorState: { error: 'At least one Session is required.' } });
    }
  };

  const onBackToMeetSessionsClicked = (staffFormState) => {
    setDisplayStaffFormState(false);
    setDisplayMeetSessionsFormState(true);
    setMeetStaffState(staffFormState);
  };

  const onBackToMeetSessionsFromDetail = () => {
    setDisplayMeetSessionsDetailFormState(false);
    setDisplayMeetSessionsFormState(true);
  };


  useEffect(() => {
    if (baseUsasClubState.isObjLoading === false && baseUsasClubState.isObjLoaded === true
      && usasClubFacilityLinksState.isArrayLoaded === false && usasClubFacilityLinksState.isArrayLoading === false) {
      getUsasClubFacilityLinks(baseUsasClubState.objData.usasClubId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [baseUsasClubState, usasClubFacilityLinksState]);

  useEffect(() => {
    if (baseUsasClubState.isObjLoading === false && baseUsasClubState.isObjLoaded === true
      && usasClubFacilityLinksState.isArrayLoading === false && usasClubFacilityLinksState.isArrayLoaded === true) {
      setFacilityOptionsState(getFacilityAsOptions(usasClubFacilityLinksState.arrayData || []));
    }
  }, [baseUsasClubState, usasClubFacilityLinksState]);

  useEffect(() => {
    if (meetSessionsState.meetSessionArray?.length > 0) {
      //Sort grid data to display in ascending order
      const meetSessionArrayCopy = JSON.parse(JSON.stringify(meetSessionsState.meetSessionArray));
      meetSessionArrayCopy.sort(function (a, b) { return a.sessionOrderId - b.sessionOrderId });
      setMeetSessionsState({
        ...meetSessionsState,
        sortedMeetSessionArray: meetSessionArrayCopy,
        formState: {
          ...meetSessionsState.formState,
          meetStartDate: meetInfoState.startDate ? formatDate(meetInfoState.startDate) : Constants.BLANK_DATE_STRING,
          meetEndDate: meetInfoState.endDate ? formatDate(meetInfoState.endDate) : Constants.BLANK_DATE_STRING
        }
      });
    }
    else {
      setMeetSessionsState({
        ...meetSessionsState,
        sortedMeetSessionArray: [],
        formState: {
          ...meetSessionsState.formState,
          meetStartDate: meetInfoState.startDate ? formatDate(meetInfoState.startDate) : Constants.BLANK_DATE_STRING,
          meetEndDate: meetInfoState.endDate ? formatDate(meetInfoState.endDate) : Constants.BLANK_DATE_STRING
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [meetSessionsState.meetSessionArray, meetInfoState.startDate, meetInfoState.endDate]);

  useEffect(() => {
    if (clubMeetsState.isSaved === true) {
      navigate(NavLinks.CLUB_MEETS);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clubMeetsState.isSaved]);

  function getFacilityAsOptions(facilityArray) {
    const facilityOptions = [{ id: Constants.DEFAULT_ID, name: '--' }];
    if (facilityArray) {
      for (const facility of facilityArray) {
        if (facility.facility && facility.facility.hostMeet === true) {
          facilityOptions.push({ id: facility.facility.facilityId, name: facility.facility.facilityName });
        }
      }
    }
    return facilityOptions;
  };

  function createMeetSanctionRequest(orgUnitId, meetInfo, meetStaff) {
    let meetSessionArrayCopy = JSON.parse(JSON.stringify(meetSessionsState.meetSessionArray));
    for (let i = 0; i < meetSessionArrayCopy.length; i++) {
      delete meetSessionArrayCopy[i].meetSessionId;
      meetSessionArrayCopy[i].sessionType = undefined;
    }

    return {
      meetClassificationId: meetInfo.meetClassification[0].id,
      meetName: meetInfo.autogeneratedMeetYear + ' ' + meetInfo.autogeneratedMeetLsc + ' ' + meetInfo.meetName,
      startDate: meetInfo.startDate,
      endDate: meetInfo.endDate,
      hostOrgUnitId: orgUnitId,
      isUsasMeet: true,
      isFinaMeet: false,
      isInternationalSwimLeagueMeet: false,
      isNcaaMeet: false,
      meetCourse: meetInfo.meetCourse.map(course => { return { courseId: course.id }; }),
      facilityId: meetInfo.facilityId,
      meetAbbreviation: '',
      meetSoftwareId: meetInfo.meetSoftwareId,
      meetAnnouncementUrl: meetInfo.meetAnnouncementUrl,
      status: Constants.WORKFLOW_STATUS_PENDING,
      meetSession: meetSessionArrayCopy,
      personMeetStaff: [
        {
          personId: meetStaff.meetDirectorPersonId,
          personMeetStaffType: [
            {
              meetStaffTypeId: MEET_DIRECTOR_STAFF_TYPE_ID,
              meetStaffType: null
            }
          ]
        },
        {
          personId: meetStaff.meetRefereePersonId,
          personMeetStaffType: [
            {
              meetStaffTypeId: MEET_REFEREE_STAFF_TYPE_ID,
              meetStaffType: null
            }
          ]
        },
        {
          personId: meetStaff.meetContactPersonId,
          personMeetStaffType: [
            {
              meetStaffTypeId: MEET_CONTACT_STAFF_TYPE_ID,
              meetStaffType: null
            }
          ]
        },
      ]
    };
  };

  return {
    isSaving: clubMeetsState.isSaving,
    isLoading: environmentVariableState.isLoading,
    meetInfoState,
    meetSessionsState,
    meetStaffState,
    displayMeetInfoFormState,
    displayMeetSessionsFormState,
    displayMeetSessionsDetailFormState,
    displayStaffFormState,
    facilityOptionsState,
    orgUnitId: baseUsasClubState.objData.orgUnitId || '',
    onInfoFormSubmit,
    onSessionsFormSubmit,
    onStaffFormSubmit,
    onAddSession,
    onEditSession,
    onDeleteSession,
    onDeleteSessionClicked,
    onModalCanceled,
    onBackToMeetInfoClicked,
    onBackToMeetSessionsClicked,
    onBackToMeetSessionsFromDetail,
    onCancelClicked,
    state,
    setState,
    OFFICIAL_ORG_ROLE_ID,
    OFFICIALS_ROLES_ROLE_GROUP_ID
  };
};

export default UseClubMeetSanctionRequest;

