import { useEffect, useState } from 'react';

import { useExecuteQuery } from '@sisense/sdk-ui';

import * as DM from '../../../../usas-times-data';

import useEnvironmentVariableData from '../../../../common/state/environmentVariable/UseEnvironmentVariableData';

const useSisenseCompetitionCategoryNoDefaultDropdown = () => {
    const queryProps = { dataSource: DM.DataSource, dimensions: [DM.EventCompetitionCategory.TypeName] };
    const { data, isLoading } = useExecuteQuery(queryProps);
    const [competitionCategoryNoDefaultOptions, setCompetitionCategoryNoDefaultOptions] = useState({ options: [] });
    const { environmentVariableState, SISENSE_UNKNOWN_TEXT } = useEnvironmentVariableData();

    useEffect(() => {
        if (data && environmentVariableState.isLoaded === true) {
            const formattedOptions = [];
            data.rows.map((row) => {
                if (row[0].text !== SISENSE_UNKNOWN_TEXT) {
                    return (formattedOptions.push({ id: row[0].data, name: row[0].text }))
                }
            });
            setCompetitionCategoryNoDefaultOptions({ ...competitionCategoryNoDefaultOptions, options: formattedOptions });
        }
        else {
            setCompetitionCategoryNoDefaultOptions({ ...competitionCategoryNoDefaultOptions, options: [] });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, environmentVariableState])

    return {
        data,
        isLoading,
        competitionCategoryNoDefaultOptions
    };
};

export default useSisenseCompetitionCategoryNoDefaultDropdown;