import { useEffect } from 'react';

import useCategoryMeasureDropdownData from '../../../state/categoryMeasureDropdown/UseCategoryMeasureDropdownData';

const useCategoryMeasureDropdown = (categoryMeasureId) => {
  const { categoryMeasureDropdownState, getCategoryMeasureDropdown } = useCategoryMeasureDropdownData();

  useEffect(() => {
    if (categoryMeasureDropdownState.isArrayLoading !== true && categoryMeasureId > 0 && categoryMeasureId !== categoryMeasureDropdownState.categoryMeasureId) {
      getCategoryMeasureDropdown(categoryMeasureId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categoryMeasureDropdownState, categoryMeasureId]);

  return {
    categoryMeasureDropdownState
  };
};

export default useCategoryMeasureDropdown;