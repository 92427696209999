import React, { Fragment } from 'react';

import UseClubMeetSanctionRequest from './UseClubMeetSanctionRequest';

import MeetSanctionInfoForm from './forms/MeetSanctionInfoForm';
import MeetSanctionStaffForm from './forms/MeetSanctionStaffForm';

import PopUpModal from '../../../../../common/components/dialogs/PopUpModal';
import Headings from '../../../../../common/components/headings/Headings';
import ActionIntraPageButton from '../../../../../common/components/buttons/ActionIntraPageButton';
import SessionsGrid from '../../../../../common/components/meetSessions/SessionsGrid';
import SecondaryButton from '../../../../../common/components/buttons/SecondaryButton';
import PrimaryButton from '../../../../../common/components/buttons/PrimaryButton';

import { formatDate } from '../../../../../common/utils/DateFunctions';
import Constants from '../../../../../common/utils/Constants';
import global from '../../../../../common/components/GlobalStyle.module.css'
import MeetSessionsDetailForm from './forms/MeetSessionsDetailForm';

const ClubMeetSanctionRequest = () => {
  const {
    isSaving,
    isLoading,
    displayMeetInfoFormState,
    displayMeetSessionsFormState,
    displayMeetSessionsDetailFormState,
    displayStaffFormState,
    meetInfoState,
    meetSessionsState,
    meetStaffState,
    facilityOptionsState,
    orgUnitId,
    onInfoFormSubmit,
    onSessionsFormSubmit,
    onStaffFormSubmit,
    onBackToMeetInfoClicked,
    onBackToMeetSessionsClicked,
    onBackToMeetSessionsFromDetail,
    onAddSession,
    onEditSession,
    onDeleteSession,
    onDeleteSessionClicked,
    onModalCanceled,
    onCancelClicked,
    state, 
    setState,
    OFFICIAL_ORG_ROLE_ID,
    OFFICIALS_ROLES_ROLE_GROUP_ID
  } = UseClubMeetSanctionRequest();

  return (
    <Fragment>
      {displayMeetInfoFormState === true &&
        <Fragment>
          <div className="row">
            <div className="col-xs-12">
              <Headings.H3>Meet Sanction Request - Meet Info</Headings.H3>
            </div>
          </div>
          <MeetSanctionInfoForm
            savedFormState={meetInfoState}
            clubFacilities={facilityOptionsState}
            orgUnitId={orgUnitId}
            onSubmitFormCallback={onInfoFormSubmit}
            onCancelClicked={onCancelClicked}
            state={state}
            setState={setState}
          />
        </Fragment>}
      {displayMeetSessionsFormState === true &&
        <Fragment>
          <div className="row">
            <div className="col-xs-12">
              <Headings.H3>Meet Sanction Request - Sessions</Headings.H3>
            </div>
          </div>
          <div className='row'>
            <div className='col-xs-12 usas-extra-top-margin'>
              <ActionIntraPageButton onClick={onAddSession}>Add Session</ActionIntraPageButton>
            </div>
            <div className='col-xs-12 usas-extra-bottom-margin'>
              <SessionsGrid
                showOptionalFields={false}
                sortedMeetSessionArray={meetSessionsState.sortedMeetSessionArray}
                onEdit={onEditSession}
                onDelete={onDeleteSession}
                onDeleteClicked={onDeleteSessionClicked}
              />
            </div>
          </div>
          {meetSessionsState.errorState?.error &&
            <div className="row">
              <div className="col-xs-12">
                <p className={global.ErrorMessage}>{meetSessionsState.errorState?.error ? meetSessionsState.errorState?.error : ''}</p>
              </div>
            </div>
          }
          <div className="row">
            <div className="col-xs-12 usas-extra-top-margin usas-extra-bottom-margin">
              <PrimaryButton type="button" onClick={() => onSessionsFormSubmit()}>Continue</PrimaryButton>&nbsp;
              <SecondaryButton type="button" onClick={() => onBackToMeetInfoClicked()}>Back</SecondaryButton>
            </div>
          </div>
          <PopUpModal
            widthPct={90}
            maxWidth={720}
            title={meetSessionsState?.modalState?.modalTitle}
            displayPopUp={meetSessionsState?.modalState?.displayPopUp}
            onModalCanceled={onModalCanceled}>
            <div className="row">
              <div className="col-xs-12">
                <p><b>Session Number:</b>&nbsp;{meetSessionsState?.modalState?.sessionOrderId}</p>
                <p><b>Session Type:</b>&nbsp;{meetSessionsState?.modalState?.sessionTypeName}</p>
                <p><b>Session Date:</b>&nbsp;{meetSessionsState?.modalState?.sessionDate ? formatDate(meetSessionsState?.modalState?.sessionDate) : ''}</p>
              </div>
              <div className="col-xs-12 usas-extra-top-margin">
                <PrimaryButton type="button" onClick={(e) => onDeleteSession(e, meetSessionsState?.modalState?.meetSessionId)}>Delete</PrimaryButton>&nbsp;
                <SecondaryButton type="button" onClick={onModalCanceled}>Cancel</SecondaryButton>
              </div>
            </div>
          </PopUpModal>
        </Fragment>
      }
      {displayMeetSessionsDetailFormState === true &&
        <Fragment >
          <div className="row">
            <div className="col-xs-12">
              <Headings.H3>Meet Sanction Request - {meetSessionsState.formState.meetSessionId > 0 ? 'Edit' : 'Add'} Session</Headings.H3>
            </div>
          </div>
          <MeetSessionsDetailForm
            onBackToMeetSessions={onBackToMeetSessionsFromDetail}
          />
        </Fragment>}
      {displayStaffFormState === true &&
        <Fragment>
          <div className="row">
            <div className="col-xs-12">
              <Headings.H3>Meet Sanction Request - Staff</Headings.H3>
            </div>
          </div>
          <MeetSanctionStaffForm
            savedFormState={meetStaffState}
            onSubmitFormCallback={onStaffFormSubmit}
            onBackClicked={onBackToMeetSessionsClicked}
            OFFICIAL_ORG_ROLE_ID={OFFICIAL_ORG_ROLE_ID}
            OFFICIALS_ROLES_ROLE_GROUP_ID={OFFICIALS_ROLES_ROLE_GROUP_ID} />
        </Fragment>
      }
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title="Submitting Request..."
        displayPopUp={isSaving} />
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={isLoading} />
    </Fragment >
  );
};

export default ClubMeetSanctionRequest;