import { useEffect } from 'react';

import validate from './RevokeSSRPFormValidation';

import UseForm from '../../../common/utils/UseForm';
import { BLANK_DATE_STRING } from '../../../common/utils/Constants';
import { formatDate } from '../../../common/utils/DateFunctions';

const INITIAL_FORM_STATE = {
  expirationDate: BLANK_DATE_STRING
};

const useRevokeSSRPForm = (onSubmitFormCallback, effectiveDate) => {
  const { formState, errorState, handleSubmit, updateFormState, setFormData,
  } = UseForm(INITIAL_FORM_STATE, onSubmitFormCallback, validate);

  useEffect(() => {
    const today = formatDate(new Date());

    setFormData({
      ...formState,
      effectiveDate: formatDate(effectiveDate),
      expirationDate: today
    }, true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    formState,
    errorState,
    handleSubmit,
    updateFormState
  };
};

export default useRevokeSSRPForm;