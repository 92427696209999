import { useEffect, useState } from 'react';

import useClubHistoryData from '../../../state/clubHistory/UseClubHistoryData';

import useBaseUsasClubData from '../../../../common/state/clubBase/UseBaseUsasClubData';

const useClubHistory = () => {
  const { baseUsasClubState } = useBaseUsasClubData();
  const { clubHistoryState, getClubHistory } = useClubHistoryData();
  const [gridState, setGridState] = useState({ gridArray: [], isArrayLoaded: false });

  const sortArray = (arr) => {
    arr.sort(function (a, b) {
      return new Date(b.effectiveDate) - new Date(a.effectiveDate);
    });
    return arr;
  }

  useEffect(() => {
    if (Object.keys(baseUsasClubState.objData).length > 0) {
      getClubHistory(baseUsasClubState.objData.usasClubId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [baseUsasClubState.objData])

  useEffect(() => {
    if (Object.keys(baseUsasClubState.objData).length > 0 && clubHistoryState.isArrayLoaded === true &&
      clubHistoryState.usasClubId === baseUsasClubState.objData.usasClubId) {
      const clubHistoryArrayCopy = JSON.parse(JSON.stringify(clubHistoryState.arrayData));
      const gridArray = sortArray(clubHistoryArrayCopy);
      setGridState({ ...gridState, gridArray: gridArray, isArrayLoaded: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [baseUsasClubState.objData, clubHistoryState])

  return { baseUsasClubState, clubHistoryState, sortArray, gridState };
};

export default useClubHistory;