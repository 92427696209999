/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';

import DeleteIcon from '../../../../../common/components/icons/DeleteIcon';

import { formatPhoneNumber } from '../../../../../common/utils/validation';

import global from '../../../../../common/components/GlobalStyle.module.css';

const GridRow = ({ contact, onDelete }) => {
  const role = Array.isArray(contact.personOrgRole) && contact.personOrgRole.length > 0 ? contact.personOrgRole[0] : null;

  return (
    <div className={global.SmallTableRow}>
      <div className={global.SmallTableRowHead}>{<span className='hidden-xs'>{contact.firstName} {contact.lastName}</span>}&nbsp;
        {onDelete !== undefined &&
          <button
            className={global.IconButton}
            type="button"
            onClick={() => onDelete(role ? role.orgRoleId : '')}>
            <DeleteIcon />
          </button>
        }
      </div>
      <div className={global.SmallTableRowInfo}>
        <div className='row'>
          <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3 visible-xs'].join(' ')}>Contact Name</div>
          <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3 visible-xs'].join(' ')}>{contact.firstName} {contact.lastName}</div>
          <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3'].join(' ')}>Primary/Secondary</div>
          <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3'].join(' ')}>{role ? role.orgRoleName : <span>&nbsp;</span>}</div>
          <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3'].join(' ')}>Phone Number</div>
          <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3'].join(' ')}>{contact.phoneNumber ? formatPhoneNumber(contact.phoneNumber) : <span>&nbsp;</span>}</div>
          <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3'].join(' ')}>Email</div>
          <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3'].join(' ')}>{contact.email || <span>&nbsp;</span>}</div>
        </div>
      </div>
    </div>
  );
};

const PreCompOrganizationContactsGridSmall = ({ data, isLoading, onDelete }) => (
  <div className={[global.SmallTable, 'hidden-md hidden-lg'].join(' ')}>
    {isLoading === true
      ? (
        <div className={global.SmallTableRow}>
          <div className={global.SmallTableRowHead}>&nbsp;</div>
          <div className={global.SmallTableRowInfo}>
            <div className={global.SmallTableRowLabels}>Loading...</div>
          </div>
        </div>
      )
      : data.length > 0
        ? data.map((contact, i) => <GridRow key={i} contact={contact} onDelete={onDelete} />)
        : (
          <div className={global.SmallTableRow}>
            <div className={global.SmallTableRowHead}>&nbsp;</div>
            <div className={global.SmallTableRowInfo}>
              <div className={global.SmallTableRowLabels}>No Contacts</div>
            </div>
          </div>
        )}
  </div>
);

export default PreCompOrganizationContactsGridSmall;