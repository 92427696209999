import { createContext, useState } from 'react';

import ClubPerformanceData from './ClubPerformanceData';

export const ClubPerformanceStateContext = createContext();

const ClubPerformanceContextProvider = ({ children }) => {
  const stateHook = useState(ClubPerformanceData.INITIAL_STATE);

  return (
    <ClubPerformanceStateContext.Provider value={stateHook}>
      {children}
    </ClubPerformanceStateContext.Provider>
  );
};

export default ClubPerformanceContextProvider;