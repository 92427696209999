export const CLUB_INFO = 'CLUB_INFO';
export const CLUB_STAFF = 'CLUB_STAFF';
export const CLUB_TYPE = 'CLUB_TYPE';
export const LEGAL_ENTITY = 'LEGAL_ENTITY';
export const AFFILIATION = 'AFFILIATION';
export const SCREENING_POLICIES = 'SCREENING_POLICIES';
export const PRE_COMPETITIVE_PROGRAMS = 'PRE_COMPETITIVE_PROGRAMS';
export const FACILITY = 'FACILITY';
export const MISSION_STATEMENT_BUDGET = 'MISSION_STATEMENT_BUDGET';
export const EMERGENCY_PLAN = 'EMERGENCY_PLAN';
export const SUBMIT = 'SUBMIT';
export const CONTINUE = 'Continue';
export const EXIT = 'Exit';
export const SAVE_AND_EXIT = 'Save & Exit';
export const SUBMIT_AND_EXIT = 'Submit & Exit';
export const BACK = 'Back';
export const EXIT_CONFIRMATION = 'Exit Confirmation';
export const IN_PROGRESS_STATUS = 'In Progress';
export const RENEWING_STATUS = 'Renewing';
export const PENDING_STATUS = 'Pending';
export const PENDING_PAYMENT_STATUS = 'Pending Payment';
export const APPROVED_STATUS = 'Approved';
export const PAID_STATUS = 'paid';
export const MISSING_REQUIRED_INFORMATION = 'Missing Required Information';
export const UNSAVED_CHANGES = 'Any unsaved changes you have made on this page will not be saved.';
export const LEFT_NAV_TITLE = 'Club Registration';

const ClubRegistrationConstants = {
  CLUB_INFO,
  CLUB_STAFF,
  CLUB_TYPE,
  LEGAL_ENTITY,
  AFFILIATION,
  SCREENING_POLICIES,
  PRE_COMPETITIVE_PROGRAMS,
  FACILITY,
  MISSION_STATEMENT_BUDGET,
  EMERGENCY_PLAN,
  SUBMIT,
  CONTINUE,
  EXIT,
  SAVE_AND_EXIT,
  SUBMIT_AND_EXIT,
  BACK,
  EXIT_CONFIRMATION,
  IN_PROGRESS_STATUS,
  RENEWING_STATUS,
  PENDING_STATUS,
  PENDING_PAYMENT_STATUS,
  APPROVED_STATUS,
  PAID_STATUS,
  MISSING_REQUIRED_INFORMATION,
  UNSAVED_CHANGES,
  LEFT_NAV_TITLE
};

export default ClubRegistrationConstants;