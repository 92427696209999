import React from 'react';

import SGColumnHeader from '../SGColumnHeader';

import SGConstants from '../../../utils/SGConstants';
import useSortableGrid from '../../../utils/UseSortableGrid';

import global from '../../GlobalStyle.module.css';

const GridRow = ({ coach }) => (
  <tr>
    <td>{coach.firstName || ''} {coach.lastName || ''}</td>
    <td>{coach.orgRoleName || ''}</td>
    <td>{coach.isInGoodStanding === true ? 'Yes' : 'No'}</td>
  </tr>
);

const CoachRosterReportGridLarge = ({ data, isLoading }) => {
  const {
    sortableGridState,
    onColumnClicked
  } = useSortableGrid(data);

  return (
    <table className={[global.UsasTable, 'visible-md visible-lg'].join(' ')}>
      <thead>
        <tr>
          <SGColumnHeader sortableGridState={sortableGridState} columnLabel={'Coach Name'} columnField={'lastName'}
            sortType={SGConstants.SORT_TYPE_ALPHABETICAL} onColumnClicked={onColumnClicked} />
          <SGColumnHeader sortableGridState={sortableGridState} columnLabel={'Role'} columnField={'orgRoleName'}
            sortType={SGConstants.SORT_TYPE_ALPHABETICAL} onColumnClicked={onColumnClicked} />
          <SGColumnHeader sortableGridState={sortableGridState} columnLabel={'Is In Good Standing?'} columnField={'isInGoodStanding'}
            sortType={SGConstants.SORT_TYPE_NUMERICAL} onColumnClicked={onColumnClicked} />
          <th></th>
        </tr>
      </thead>
      <tbody>
        {isLoading === true
          ? <tr><td colSpan="3">Loading...</td></tr>
          : Array.isArray(sortableGridState.sortedGridData) && sortableGridState.sortedGridData.length > 0
            ? sortableGridState.sortedGridData.map((coach, i) => <GridRow key={i} coach={coach} />)
            : <tr><td colSpan="3">No Coaches</td></tr>
        }
      </tbody>
    </table>
  );
};

export default CoachRosterReportGridLarge;