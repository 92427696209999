import { BASIC_INITIAL_STATE } from '../../../common/utils/HttpHelper';

import postPreCompFilesInsuranceVerificationData from './PostPreCompFilesInsuranceVerificationData';

const BASE_UPLOAD_URL = 'https://precompdocs.blob.core.windows.net/uploaded-docs/';
const GET_DOWNLOAD_TEMPLATE_PATH = '/PreCompetitiveFiles/downloadTemplate/';
const GET_PRE_COMP_FILES_INSURANCE_VERIFICATION_PATH = '/PreCompetitiveFiles/InsuranceVerification?fileUrl=';

const INITIAL_STATE = {
  ...BASIC_INITIAL_STATE
};

const PreCompFilesInsuranceVerificationData = {
  BASE_UPLOAD_URL,
  GET_DOWNLOAD_TEMPLATE_PATH,
  GET_PRE_COMP_FILES_INSURANCE_VERIFICATION_PATH,
  INITIAL_STATE,
  postPreCompFilesInsuranceVerificationData
};

export default PreCompFilesInsuranceVerificationData;