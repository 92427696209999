import { useEffect } from 'react';
import { navLinks } from '../UseMemberAdministration';

import useMemberAdministrationHeaderData from '../../../state/memberAdministrationHeader/UseMemberAdministrationHeaderData';

import useEnvironmentVariableData from '../../../../common/state/environmentVariable/UseEnvironmentVariableData';

const useOrgUnitSelection = () => {
    const { LSC_MEMBER_ADMIN_ROLE_GROUP_ID } = useEnvironmentVariableData();
    const { clearMemberAdministrationHeaderObjData } = useMemberAdministrationHeaderData();

    useEffect(() => {
        //Clear out any prior data
        clearMemberAdministrationHeaderObjData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return {
        navLinks,
        LSC_MEMBER_ADMIN_ROLE_GROUP_ID
    };
};

export default useOrgUnitSelection;