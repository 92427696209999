import { Fragment } from 'react';

import LscOfferingByOrgUnitIdAndReportPeriodIdDropdown from '../../../components/dropdowns/lscOfferingByOrgUnitIdAndReportPeriodIdDropdown/LscOfferingByOrgUnitIdAndReportPeriodIdDropdown';

import DeleteIcon from '../../../../common/components/icons/DeleteIcon';
import ExclamationIcon from '../../../../common/components/icons/ExclamationIcon';
import LookupIcon from '../../../../common/components/icons/LookupIcon';

import { formatDate } from '../../../../common/utils/DateFunctions';
import Constants from '../../../../common/utils/Constants';

import global from '../../../../common/components/GlobalStyle.module.css';

const SmallGridRow = ({ invoice, gridData, lscOfferingByOrgUnitIdAndReportPeriodIdDropdownState, onDeleteClicked,
  orgUnitId, reportPeriodId, onUpdateSelectedOfferingNameValueChange, showError, onPersonPotentialMatchModalOpenClicked }) => {
  let selectedOffering = lscOfferingByOrgUnitIdAndReportPeriodIdDropdownState.arrayData?.find((x) =>
    x.offeringName === invoice?.selectedOfferingName);
  // don't consider the invoice offering, but check all other rows to make sure that 
  // they aren't person offerings dupes of the same offering types
  let selectedOfferingSamePersonDupe = gridData?.find((x) =>
    x.selectedOfferingName === invoice?.selectedOfferingName && x.thirdPartyClubRegistrationPersonOfferingId !== invoice.thirdPartyClubRegistrationPersonOfferingId &&
    x.thirdPartyClubRegistrationPersonId === invoice.thirdPartyClubRegistrationPersonId);

  return (
    <Fragment>
      <div className={global.SmallTableRow}>
        <div className={global.SmallTableRowHead}><span className='hidden-xs'>
          {(invoice.firstName || invoice.middleName || invoice.lastName) ? `${invoice.firstName || ''}  ${invoice.preferredName !== '' && invoice.preferredName !== invoice.firstName ? '"' + invoice.preferredName + '"' : ''} ${invoice.middleName || ''} ${invoice.lastName || ''}` : ''}</span>&nbsp;
          {onDeleteClicked &&
            <button
              className={global.IconButton}
              type="button"
              onClick={() => onDeleteClicked(invoice)}>
              <DeleteIcon />
            </button>
          }
        </div>
        <div className={global.SmallTableRowInfo}>
          <div className='row'>
            <div className={[global.SmallTableRowLabels, 'col-xs-6 visible-xs'].join(' ')}>Name</div>
            <div className={[global.SmallTableRowData, 'col-xs-6 visible-xs'].join(' ')}>
              {(invoice.firstName || invoice.middleName || invoice.lastName) ? `${invoice.firstName || ''}  ${invoice.preferredName !== '' && invoice.preferredName !== invoice.firstName ? '"' + invoice.preferredName + '"' : ''} ${invoice.middleName || ''} ${invoice.lastName || ''}`
                : <span>&nbsp;</span>
              }</div>
            <div className={[global.SmallTableRowLabels, 'col-xs-6'].join(' ')}>Member ID</div>
            <div className={[global.SmallTableRowData, 'col-xs-6'].join(' ')}>{invoice.memberIdValid === true ? invoice.memberId || <span>&nbsp;</span> : invoice.hasPossibleMatch === true ? <a onClick={(e) => onPersonPotentialMatchModalOpenClicked(e, invoice)}>View Potential Matches&nbsp;&nbsp;<LookupIcon /></a> : <span>&nbsp;</span>}</div>
            <div className={['col-xs-6 hidden-xs', global.SmallTableRowLabels].join(' ')}>Competition Category</div>
            <div className={['col-xs-6 visible-xs', global.SmallTableRowLabels].join(' ')}>Comp. Category</div>
            <div className={[global.SmallTableRowData, 'col-xs-6'].join(' ')}>{invoice.competitionGenderTypeName || <span>&nbsp;</span>}</div>
            <div className={[global.SmallTableRowLabels, 'col-xs-6'].join(' ')}>Birth Date</div>
            <div className={[global.SmallTableRowData, 'col-xs-6'].join(' ')}>{invoice.birthDate ? formatDate(invoice.birthDate) : <span>&nbsp;</span>}</div>

            <div className={[global.SmallTableRowLabels, 'col-xs-6'].join(' ')}>Submitted Offering Name</div>
            <div className={[global.SmallTableRowData, 'col-xs-6'].join(' ')}>{invoice.submittedOfferingName || <span>&nbsp;</span>}</div>
            <div className={[global.SmallTableRowLabels, 'col-xs-12'].join(' ')}>Offering Name {selectedOffering && selectedOffering?.isClubOffering === false && <span style={{ color: '#BA0C2F' }}><ExclamationIcon toolTipText='The selected Offering Name is not offered by the club for the report period. Please select a valid Offering Name using the dropdown.' />&nbsp;The selected Offering Name is not offered by the club for the report period&nbsp;</span>}
              {invoice.isClubOffering === false && !invoice?.offeringInstanceId && invoice?.selectedOfferingInstanceId === Constants.DEFAULT_ID && <span style={{ color: '#BA0C2F' }}><ExclamationIcon toolTipText='No available club membership offerings for the report period match the Submitted Offering Name. Please select a valid Offering Name using the dropdown.' />&nbsp;No available club membership offerings for the report period match the Submitted Offering Name&nbsp;</span>}
              {invoice.hasOffering === true && selectedOffering?.isAthleteOffering === true && <span style={{ color: '#BA0C2F' }}><ExclamationIcon toolTipText='This person already has an athlete membership for the report period. Please delete this row or select a different membership type.' />&nbsp;This person already has an athlete membership for the report period&nbsp;</span>}</div>
            <div className={[global.SmallTableRowData, 'col-xs-12'].join(' ')}>
              <LscOfferingByOrgUnitIdAndReportPeriodIdDropdown
                orgUnitId={orgUnitId}
                reportPeriodId={reportPeriodId}
                label=""
                name={"selectedOfferingInstance" + invoice?.thirdPartyClubRegistrationPersonId + "thirdPartyClubRegistrationPersonId" + invoice?.thirdPartyClubRegistrationPersonOfferingId + "thirdPartyClubRegistrationPersonOfferingId"}
                value={invoice?.selectedOfferingInstanceId}
                onChange={(newValue, newValueLabel, e) => {
                  onUpdateSelectedOfferingNameValueChange(e, invoice, newValue, newValueLabel);
                }}
                areValuesIntegers={false}
                error={showError === true && (invoice?.selectedOfferingInstanceId < 0 || selectedOfferingSamePersonDupe || invoice.hasDupOffering === true || (selectedOffering && (invoice.age < selectedOffering?.ageStart ||
                  invoice.age > selectedOffering?.ageEnd))) ? true : false}
                message={invoice?.selectedOfferingInstanceId < 0 && showError === true ? 'Please select an Offering Name' :
                  (selectedOfferingSamePersonDupe && showError === true) ? `This Offering Name selection is not allowed for this Person, as it is a duplicate. Please select a different Offering.` :
                    (invoice.hasDupOffering === true && showError === true) ? invoice.offeringError :
                      (selectedOffering && (invoice.age < selectedOffering?.ageStart ||
                        invoice.age > selectedOffering?.ageEnd)) && showError === true ? `This Offering Name selection is not allowed for this Person, as their age (${invoice.age || ''}) is outside of the Offering's allowed age range (${selectedOffering?.ageStart || ''} -  ${selectedOffering?.ageEnd || ''}). Please select a different Offering.` :
                        ''} />
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

const ClubThirdPartyInvoicesDetailGridSmall = ({ lscOfferingByOrgUnitIdAndReportPeriodIdDropdownState, gridData, isLoading,
  onDeleteClicked, orgUnitId, reportPeriodId, onUpdateSelectedOfferingNameValueChange, showError, onPersonPotentialMatchModalOpenClicked }) => (
  <div className={[global.SmallTable, 'hidden-md hidden-lg'].join(' ')}>
    {isLoading === true
      ? (
        <div className={global.SmallTableRow}>
          <div className={global.SmallTableRowHead}>&nbsp;</div>
          <div className={global.SmallTableRowInfo}>
            <div className={global.SmallTableRowLabels}>Loading...</div>
          </div>
        </div>
      )
      : Array.isArray(gridData) && gridData.length > 0
        ? gridData.map((invoice, i) => <SmallGridRow key={i} invoice={invoice} gridData={gridData} lscOfferingByOrgUnitIdAndReportPeriodIdDropdownState={lscOfferingByOrgUnitIdAndReportPeriodIdDropdownState}
          onDeleteClicked={onDeleteClicked} onUpdateSelectedOfferingNameValueChange={onUpdateSelectedOfferingNameValueChange}
          orgUnitId={orgUnitId} reportPeriodId={reportPeriodId} showError={showError} onPersonPotentialMatchModalOpenClicked={onPersonPotentialMatchModalOpenClicked} />)
        : (
          <div className={global.SmallTableRow}>
            <div className={global.SmallTableRowHead}>&nbsp;</div>
            <div className={global.SmallTableRowInfo}>
              <div className={global.SmallTableRowLabels}>No Invoices</div>
            </div>
          </div>
        )
    }
  </div>
);

export default ClubThirdPartyInvoicesDetailGridSmall;