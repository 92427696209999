import React, { Fragment } from 'react';

import useLearnToSwimFacilities from './UseLearnToSwimFacilities';

import ProviderFacilitiesGrid from '../components/grids/ProviderFacilitiesGrid';

import Headings from '../../../../common/components/headings/Headings';
import PrimaryButton from '../../../../common/components/buttons/PrimaryButton';
import PopUpModal from '../../../../common/components/dialogs/PopUpModal';
import SecondaryButton from '../../../../common/components/buttons/SecondaryButton';
import ActionIntraPageButton from '../../../../common/components/buttons/ActionIntraPageButton';

import Constants from '../../../../common/utils/Constants';

const LearnToSwimFacilities = () => {
  const {
    isLoading,
    isSaving,
    deleteModalState,
    deletePoolModalState,
    ltsFacilitiesState,
    onAddFacilityClicked,
    onEditFacilityClicked,
    onAddPoolClicked,
    onEditPoolClicked,
    onDeleteFacilityClicked,
    onDeletePoolClicked,
    onDeleteModalDeleteClicked,
    onDeleteModalCancelClicked,
    onDeletePoolModalDeleteClicked,
    onDeletePoolModalCancelClicked
  } = useLearnToSwimFacilities();

  return (
    <Fragment>
      <div className='row'>
        <div className='col-xs-12 usas-extra-top-margin'>
          <Headings.H3>Facilities</Headings.H3>
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12 usas-extra-top-margin">
          <ActionIntraPageButton onClick={onAddFacilityClicked}>Add Facility</ActionIntraPageButton>
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12 usas-extra-top-margin usas-extra-bottom-margin">
          <ProviderFacilitiesGrid
            isLoading={isLoading}
            data={ltsFacilitiesState.arrayData || []}
            onEdit={onEditFacilityClicked}
            onDelete={onDeleteFacilityClicked}
            onAddPool={onAddPoolClicked}
            onEditPool={onEditPoolClicked}
            onDeletePool={onDeletePoolClicked} />
        </div>
      </div>
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.SAVING_MSG}
        displayPopUp={isSaving} />
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={isLoading} />
      <PopUpModal
        widthPct={90}
        maxWidth={720}
        title={"Unlink Facility?"}
        displayPopUp={deleteModalState.displayPopUp}
        onModalCanceled={onDeleteModalCancelClicked}>
        <div className="row">
          <div className="col-xs-12">
            <p><b>Facility Name:</b> {deleteModalState.facilityName}</p>
            <p><b>Facility Location:</b> {deleteModalState.facilityCity}, {deleteModalState.facilityStateCode} {deleteModalState.facilityPostalCode}</p>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12 usas-extra-top-margin">
            <PrimaryButton type="button" onClick={onDeleteModalDeleteClicked}>Yes, Unlink</PrimaryButton>&nbsp;
            <SecondaryButton type="button" onClick={onDeleteModalCancelClicked}>Cancel</SecondaryButton>
          </div>
        </div>
      </PopUpModal>
      <PopUpModal
        title={'Delete This Pool From The Facility?'}
        displayPopUp={deletePoolModalState.displayPopup}
        widthPct={90}
        maxWidth={720}
        onModalCanceled={onDeletePoolModalCancelClicked}>
        <div className="row">
          <div className="col-xs-12">
            <p><b>Pool Number / Name:</b> {deletePoolModalState.poolName}</p>
            <p><b>Pool Dimensions:</b> {deletePoolModalState.poolDimensions}</p>
          </div>
        </div>
        <div className="row usas-extra-top-margin">
          <div className="col-xs-12">
            <PrimaryButton type="button" onClick={onDeletePoolModalDeleteClicked}>Yes, Delete</PrimaryButton>&nbsp;
            <SecondaryButton type="button" onClick={onDeletePoolModalCancelClicked}>Cancel</SecondaryButton>
          </div>
        </div>
      </PopUpModal>
    </Fragment>
  );
};

export default LearnToSwimFacilities;