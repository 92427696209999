import { useContext } from 'react';

import { BusinessEntityStateContext } from './BusinessEntityContextProvider';

import BusinessEntityData from './BusinessEntityData';

const useBusinessEntityData = () => {
  const [businessEntityState, setBusinessEntityState] = useContext(BusinessEntityStateContext);
  const getBusinessEntityTypes = (clubOwnerTypeId) => {
    BusinessEntityData.getBusinessEntityTypes(businessEntityState, setBusinessEntityState, clubOwnerTypeId);
  }

  return {
    businessEntityState,
    getBusinessEntityTypes
  };
};

export default useBusinessEntityData;