import { isValidEmail } from '../../../../../common/utils/validation';

export const localValidate = (formState) => {
  let errors = {};

  if (formState.email.trim() === '') {
    errors.email = 'Email is required';
  } else if (!isValidEmail(formState.email.trim())) {
    errors.email = 'Email must be in a valid format';
  }

  if (formState.orgRole.length === 0) {
    errors.orgRoleId = 'Role is required';
  }

  return errors;
};

const AddLSCRoleEmailValidation = async (formState) => {
  let errors = localValidate(formState);

  return errors ? errors : {};
};

export default AddLSCRoleEmailValidation;