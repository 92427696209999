import React, { Fragment } from 'react';

import ProviderSearchGridLarge from './ProviderSearchGridLarge';
import ProviderSearchGridSmall from './ProviderSearchGridSmall';

const ProviderSearchGrid = ({ data, isLoading, onEditClicked }) => (
  <Fragment>
    <ProviderSearchGridLarge data={data} isLoading={isLoading} onEditClicked={onEditClicked} />
    <ProviderSearchGridSmall data={data} isLoading={isLoading} onEditClicked={onEditClicked} />
  </Fragment>
);

export default ProviderSearchGrid;