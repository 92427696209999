import { useState } from 'react';

import UsasClubFilesData from './UsasClubFilesData';

const useUsasClubFilesData = () => {
  const [usasClubFilesState, setUsasClubFilesState] = useState(UsasClubFilesData.INITIAL_STATE);

  const postUsasClubFiles = (file) => {
    UsasClubFilesData.postUsasClubFilesData(file, usasClubFilesState, setUsasClubFilesState);
  };

  const resetUsasClubFilesState = () => {
    setUsasClubFilesState(UsasClubFilesData.INITIAL_STATE);
  }

  const getFileNameFromSavedUploadUrl = () => {
    if (usasClubFilesState.isObjLoaded === true) {
      const fileNameWithDateTime = usasClubFilesState.objData.uploadUrl.replace(UsasClubFilesData.BASE_UPLOAD_URL, '');
      const indexOfEndOfDateTime = fileNameWithDateTime.indexOf('.') + 1;
      const fileName = fileNameWithDateTime.substring(indexOfEndOfDateTime);
      return usasClubFilesState.objData.uploadUrl ? decodeURI(fileName) : '';
    } else {
      return '';
    }
  };

  const getFileNameFromUrl = (url) => {
    const fileNameWithDateTime = url.replace(UsasClubFilesData.BASE_UPLOAD_URL, '');
    const indexOfEndOfDateTime = fileNameWithDateTime.indexOf('.') + 1;
    const fileName = fileNameWithDateTime.substring(indexOfEndOfDateTime);
    return decodeURI(fileName) || '';
  };

  return {
    usasClubFilesState,
    postUsasClubFiles,
    getFileNameFromSavedUploadUrl,
    getFileNameFromUrl,
    resetUsasClubFilesState,
    BASE_UPLOAD_URL: UsasClubFilesData.BASE_UPLOAD_URL,
    GET_DOWNLOAD_TEMPLATE_PATH: UsasClubFilesData.GET_DOWNLOAD_TEMPLATE_PATH,
    GET_USAS_CLUB_FILES_PATH: UsasClubFilesData.GET_USAS_CLUB_FILES_PATH
  };
};

export default useUsasClubFilesData;