import React, { Fragment } from 'react';

import useEditLSCBoardMember from './UseEditLSCBoardMember';

import PrimaryButton from '../../../../../common/components/buttons/PrimaryButton';
import SecondaryButton from '../../../../../common/components/buttons/SecondaryButton';
import DatePicker from '../../../../../common/components/datepickers/DatePicker';
import ReadOnly from '../../../../../common/components/readOnly/ReadOnly';
import Headings from '../../../../../common/components/headings/Headings';
import PopUpModal from '../../../../../common/components/dialogs/PopUpModal';
import YesNoSwitch from '../../../../../common/components/yesNoSwitch/YesNoSwitch';

import Constants from '../../../../../common/utils/Constants';

import global from '../../../../../common/components/GlobalStyle.module.css';

const EditLSCBoardMember = () => {
  const {
    isLoading,
    isSaving,
    viewState,
    formState,
    errorState,
    onFormValueChanged,
    handleSubmit,
    onBackClicked,
    onSaveClicked
  } = useEditLSCBoardMember();

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>Edit LSC Board of Directors Role</Headings.H3>
        </div>
      </div>
      <form onSubmit={handleSubmit} noValidate>
        <div className="row">
          <div className="col-xs-12 col-sm-6 col-md-4">
            <ReadOnly
              label={"LSC Board of Directors Role"}
              value={viewState.orgRoleName} />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4">
            <ReadOnly
              label={"Member Id"}
              value={viewState.memberId} />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4">
            <ReadOnly
              label={"Member Name"}
              value={viewState.memberName} />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4">
            <ReadOnly
              label={"Effective Date"}
              value={formState.effectiveDate} />
          </div>
          <div className='col-xs-12 col-sm-6 col-md-4'>
            <YesNoSwitch
              label={'No Expiration Date?'}
              name={'hasNoExpirationDate'}
              checked={formState.hasNoExpirationDate === true}
              error={formState.hasNoExpirationDate}
              message={formState.hasNoExpirationDate}
              onChange={(value) => onFormValueChanged('hasNoExpirationDate', value)} />
          </div>
          {formState.hasNoExpirationDate === false &&
            <div className="col-xs-12 col-sm-6 col-md-4">
              <DatePicker
                label={"Expiration Date*"}
                name={"expirationDate"}
                value={formState.expirationDate}
                error={errorState.expirationDate}
                message={errorState.expirationDate}
                startYearOffset={-140}
                onChange={(value) => onFormValueChanged('expirationDate', value)} />
            </div>
          }
        </div>
        {errorState.multipleActiveRoles ?
          <div className="row">
            <div className="col-xs-12">
              <p className={global.ErrorMessage}>{errorState.multipleActiveRoles}</p>
            </div>
          </div> : <Fragment />}
        <div className="row usas-extra-top-margin usas-extra-bottom-margin">
          <div className="col-xs-12 usas-extra-top-margin">
            <PrimaryButton type="button" onClick={onSaveClicked}>Save</PrimaryButton>&nbsp;
            <SecondaryButton type="button" onClick={onBackClicked}>Back</SecondaryButton>
          </div>
        </div>
      </form>
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={isLoading} />
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.SAVING_MSG}
        displayPopUp={isSaving} />
    </Fragment>
  );
};

export default EditLSCBoardMember;