import React, { Fragment } from 'react';

import PreCompBusinessEntityDropdown from '../businessEntityDropdown/PreCompBusinessEntityDropdown';
import PreCompAffiliationMultiselect from '../affiliationMultiSelect/PreCompAffiliationMultiselect';
import StatesCombobox from '../../../../../common/components/comboboxes/statesCombobox/StatesCombobox';

import Input from '../../../../../common/components/inputs/Input';
import ReadOnly from '../../../../../common/components/readOnly/ReadOnly';

const PreCompOrganizationBasicInfoForm = ({ formState, errorState, onFormValueChanged, onValueTextPairChanged }) => (
  <Fragment>
    <div className="row">
      <div className="col-xs-12 col-sm-6 col-md-4">
        {onFormValueChanged === undefined
          ? <ReadOnly
            label="Organization Mailing Address Line 1"
            name="address1"
            value={formState.address1} />
          : (
            <Input
              label="Organization Mailing Address Line 1*"
              name="address1"
              value={formState.address1}
              error={errorState.address1}
              message={errorState.address1}
              onChange={(value) => { onFormValueChanged("address1", value); }} />
          )
        }
      </div>
      <div className="col-xs-12 col-sm-6 col-md-4">
        {onFormValueChanged === undefined
          ? <ReadOnly
            label="Organization Mailing Address Line 2"
            name="address2"
            value={formState.address2} />
          : (
            <Input
              label="Organization Mailing Address Line 2"
              name="address2"
              value={formState.address2}
              error={errorState.address2}
              message={errorState.address2}
              onChange={(value) => { onFormValueChanged("address2", value); }} />
          )
        }
      </div>
      <div className="col-xs-12 col-sm-6 col-md-4">
        {onFormValueChanged === undefined
          ? <ReadOnly
            label="Trade Name, DBA Name"
            name="tradeName"
            value={formState.tradeName} />
          : (
            <Input
              label="Trade Name, DBA Name*"
              name="tradeName"
              value={formState.tradeName}
              error={errorState.tradeName}
              message={errorState.tradeName}
              onChange={(value) => { onFormValueChanged("tradeName", value); }} />
          )
        }
      </div>
      <div className="col-xs-12 col-sm-6 col-md-4">
        {onFormValueChanged === undefined
          ? <ReadOnly
            label="City"
            name="city"
            value={formState.city} />
          : (
            <Input
              label="City*"
              name="city"
              value={formState.city}
              error={errorState.city}
              message={errorState.city}
              onChange={(value) => { onFormValueChanged("city", value); }} />
          )
        }
      </div>
      <div className="col-xs-12 col-sm-6 col-md-4">
        {onValueTextPairChanged === undefined
          ? <ReadOnly
            label="State"
            name="stateCode"
            value={formState.stateCode} />
          : (
            <StatesCombobox
              label="State*"
              name="stateCode"
              valueToMatch={formState.stateName}
              error={errorState.stateCode}
              message={errorState.stateCode}
              isUSA={true}
              onChange={(newValue, newValueLabel) => {
                onValueTextPairChanged(newValue, "stateCode", newValueLabel, "stateName");
              }} />
          )
        }
      </div>
      <div className="col-xs-12 col-sm-6 col-md-4">
        {onFormValueChanged === undefined
          ? <ReadOnly
            label="Zip Code"
            name="postalCode"
            value={formState.postalCode} />
          : (
            <Input
              label="Zip Code*"
              name="postalCode"
              value={formState.postalCode}
              error={errorState.postalCode}
              message={errorState.postalCode}
              onChange={(value) => { onFormValueChanged("postalCode", value); }} />
          )
        }
      </div>
      <div className="col-xs-12 col-sm-6 col-md-4">
        {onValueTextPairChanged === undefined
          ? <ReadOnly
            label="Business Entity Type"
            name="businessEntityTypeId"
            value={formState.businessEntityTypeName} />
          : (
            <PreCompBusinessEntityDropdown
              label="Business Entity Type*"
              name="businessEntityTypeId"
              value={formState.businessEntityTypeId}
              error={errorState.businessEntityTypeId}
              message={errorState.businessEntityTypeId}
              onChange={(newValue, newValueLabel, e) => {
                e && e.target && e.target.value &&
                onValueTextPairChanged(newValue, "businessEntityTypeId", newValueLabel, "businessEntityTypeName");
              }} />
          )
        }
      </div>
      <div className="col-xs-12 col-sm-12 col-md-8">
        {onValueTextPairChanged === undefined
          ? <ReadOnly
            label="Organization Affiliation(s)"
            name="preCompOrganizationAffiliation"
            value={formState.preCompOrganizationAffiliation.map(a => `${a.name}/`)} />
          : (
            <PreCompAffiliationMultiselect
              label="Organization Affiliation(s)*"
              name="preCompOrganizationAffiliation"
              value={formState.preCompOrganizationAffiliation}
              error={errorState.preCompOrganizationAffiliation}
              message={errorState.preCompOrganizationAffiliation}
              onChange={(value) => { onFormValueChanged("preCompOrganizationAffiliation", value); }} />
          )
        }
      </div>
      <div className="col-xs-12 col-sm-12 col-md-6">
        {onFormValueChanged === undefined
          ? <ReadOnly
            label="Website"
            name="website"
            value={formState.website} />
          : (
            <Input
              label="Website"
              name="website"
              value={formState.website}
              error={errorState.website}
              message={errorState.website}
              onChange={(value) => { onFormValueChanged("website", value); }} />
          )
        }
      </div>
    </div>
  </Fragment>
);

export default PreCompOrganizationBasicInfoForm;