import React, { Fragment } from 'react';

import useClubPreCompetitiveProgramsSearch from './UseClubPreCompetitiveProgramsSearch';
import useSearchPreComp from '../../../../common/components/searches/searchPreComp/UseSearchPreComp';

import ClubPreCompSearchGrid from './ClubPreCompSearchGrid';
import SearchPreComp from '../../../../common/components/searches/searchPreComp/SearchPreComp';
import PopUpModal from '../../../../common/components/dialogs/PopUpModal';
import Constants from '../../../../common/utils/Constants';

const ClubPreCompetitiveProgramsSearch = () => {
  const { usasClubPreCompLinksState, preCompLocationState, searchPreCompState, onFiltersToggle, onAdd,
    onCancelClicked } = useClubPreCompetitiveProgramsSearch();
  const { preCompOrganizationState, gridState } = useSearchPreComp();

  return (
    <Fragment>
      <SearchPreComp
        pageTitle={"Pre-Competitive Programs Search"}
        submitButtonText={"Search for Programs"}
        grid={<ClubPreCompSearchGrid
          data={gridState}
          searchPreCompState={searchPreCompState}
          isLoading={preCompOrganizationState.isArrayLoading}
          onAdd={onAdd}
          usasClubPreCompLinksState={usasClubPreCompLinksState}
          onFiltersToggle={onFiltersToggle}
        />}
        searchPreCompState={searchPreCompState}
        onCancelClicked={onCancelClicked} />
      <div className="row">
        <div className="col-xs-12 usas-extra-bottom-margin"></div>
      </div>
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={preCompLocationState.isObjLoading} />
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.SAVING_MSG}
        displayPopUp={usasClubPreCompLinksState.isSaving} />
    </Fragment >
  );
};

export default ClubPreCompetitiveProgramsSearch;