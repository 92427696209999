import React, { Fragment } from 'react';

import useClubInfo from './UseClubInfo';

import Headings from '../../../../common/components/headings/Headings';
import PopUpModal from '../../../../common/components/dialogs/PopUpModal';
import Constants from '../../../../common/utils/Constants';

import global from '../../../../common/components/GlobalStyle.module.css';

const ClubInfoRead = () => {
  const {
    formState,
    baseUsasClubState,
    clubOwnerTypeState
  } = useClubInfo();

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>Club Info</Headings.H3>
        </div>
      </div>
      <div className={["row usas-extra-top-margin usas-extra-bottom-margin", global.HeaderText].join(' ')}>
        <div className="col-xs-6 col-md-3">
          <p><b>Year Founded:</b></p>
        </div>
        <div className="col-xs-6 col-md-3">
          <p>{formState.yearFounded !== '' ? formState.yearFounded : "No Response"}</p>
        </div>
        <div className="col-xs-6 col-md-3">
          <p><b>Club Type:</b></p>
        </div>
        <div className="col-xs-6 col-md-3">
          <p>{formState.orgLevelTypeName && formState.orgLevelTypeName !== '' ? formState.orgLevelTypeName : "No Response"}</p>
        </div>
        <div className="col-xs-6 col-md-3">
          <p><b>Owner Type:</b></p>
        </div>
        <div className="col-xs-6 col-md-3">
          <p>{formState.ownerType ? formState.ownerType : "No Response"}</p>
        </div>
        <div className="col-xs-6 col-md-3">
          <p><b>Tax Listing:</b></p>
        </div>
        <div className="col-xs-6 col-md-3">
          <p>{formState.businessEntityType ? formState.businessEntityType : "No Response"}</p>
        </div>
        <div className="col-xs-6 col-md-3">
          <p><b>Setting:</b></p>
        </div>
        <div className="col-xs-6 col-md-3">
          <p>{formState.clubSetting !== '' ? formState.clubSetting : "No Response"}</p>
        </div>
        <div className="row visible-md visible-lg">
          <div className='col-xs-12'></div>
        </div>
        <div className="col-xs-6 col-md-3">
          <p><b>Website Address:</b></p>
        </div>
        <div className="col-xs-6 col-md-9">
          <p>{formState.websiteAddress ? formState.websiteAddress : "No Response"}</p>
        </div>
        <div className="col-xs-6 col-md-3">
          <p><b>Mailing Address:</b></p>
        </div>
        <div className="col-xs-6 col-md-9">
          {baseUsasClubState.objData?.usasClubAddress && baseUsasClubState.objData?.usasClubAddress?.length > 0 ?
            <p>{formState.address1}{formState.address2 !== '' ? '  ' + formState.address2 : ''},  {formState.city}, {formState.stateCode} {formState.postalCode}</p> :
            <p>No Response</p>}
        </div>
      </div>
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={baseUsasClubState.isObjLoading || clubOwnerTypeState.isLoading} />
    </Fragment >
  );
};

export default ClubInfoRead;