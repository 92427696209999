import React, { createContext, useState } from 'react';

import MemberAdministrationLscBoardData from './MemberAdministrationLscBoardData';

export const MemberAdministrationLscBoardStateContext = createContext();

const MemberAdministrationLscBoardContextProvider = ({ children }) => {
  const stateHook = useState(MemberAdministrationLscBoardData.INITIAL_STATE);

  return (
    <MemberAdministrationLscBoardStateContext.Provider value={stateHook}>
      {children}
    </MemberAdministrationLscBoardStateContext.Provider>
  );
};

export default MemberAdministrationLscBoardContextProvider;