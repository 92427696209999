import React from 'react';

import useClubRegistrationFacilityPoolDetail from './UseClubRegistrationFacilityPoolDetail';

import NavLinks from '../NavLinks';

import ClubRegistrationConstants from '../utils/ClubRegistrationConstants';

import PopUpModal from '../../../../common/components/dialogs/PopUpModal';
import AddEditFacilityPool from '../../../../common/components/searches/searchFacility/addEditPool/AddEditFacilityPool';
import LeftNav from '../../../../common/components/leftNav/LeftNav';
import Constants from '../../../../common/utils/Constants';

const ClubRegistrationFacilityPoolDetail = () => {
  const {
    clubRegistrationState,
    clubRegistrationNavState
  } = useClubRegistrationFacilityPoolDetail();

  return (
    <LeftNav title={ClubRegistrationConstants.LEFT_NAV_TITLE} state={clubRegistrationNavState} validateBeforeRedirect={false}>
      <AddEditFacilityPool
        saveUrl={NavLinks.CLUB_REGISTRATION_FACILITY}
        cancelUrl={NavLinks.CLUB_REGISTRATION_FACILITY}
        showFacilityInfo={true}
      />
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.SAVING_MSG}
        displayPopUp={clubRegistrationState.isSaving} />
    </LeftNav>
  );
};

export default ClubRegistrationFacilityPoolDetail;