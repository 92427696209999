import React, { createContext, useState } from 'react';

import PreCompBusinessEntityTypeData from './PreCompBusinessEntityTypeData';

export const PreCompBusinessEntityTypeStateContext = createContext();

const PreCompBusinessEntityTypeContextProvider = ({ children }) => {
  const stateHook = useState(PreCompBusinessEntityTypeData.INITIAL_STATE);

  return (
    <PreCompBusinessEntityTypeStateContext.Provider value={stateHook}>
      {children}
    </PreCompBusinessEntityTypeStateContext.Provider>
  );
};

export default PreCompBusinessEntityTypeContextProvider;