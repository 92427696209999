import React, { createContext, useState } from 'react';

import BPMeetClassificationData from './BPMeetClassificationData';

export const BPMeetClassificationStateContext = createContext();

const BPMeetClassificationContextProvider = ({ children }) => {
  const stateHook = useState(BPMeetClassificationData.INITIAL_STATE);

  return (
    <BPMeetClassificationStateContext.Provider value={stateHook}>
      {children}
    </BPMeetClassificationStateContext.Provider>
  );
};

export default BPMeetClassificationContextProvider;