import { useState, useEffect } from 'react';
import { useNavigate } from '../../../../common/wrappers/ReactRouterDom';

import NavLinks from '../NavLinks';

import ClubRegistrationConstants from '../utils/ClubRegistrationConstants';

import useClubRegistrationNavData from '../../../state/clubRegistration/leftNavigation/UseClubRegistrationNavData';
import useClubRegistrationNewData from '../../../state/clubRegistrationNew/UseClubRegistrationNewData';

const ERROR_MSG = 'At least one legal entity is required';

const useClubRegistrationLegalEntities = () => {
  const navigate = useNavigate();
  const [state, setState] = useState({ submitButton: '', tryRedirect: false, error: '', selectedRegistration: {} });
  const [submitPrequisitesState, setSubmitPrequisitesState] = useState({ areSubmitPrerequisitesDone: false });
  const [modalState, setModalState] = useState(getInitialModalState());
  const { clubRegistrationNavState, setClubRegistrationNavState } = useClubRegistrationNavData();
  const { clubRegistrationState, putUsasClub } = useClubRegistrationNewData();

  const editClubObj = (usasClubLegalEntityId) => {
    let clubCopy = JSON.parse(JSON.stringify(clubRegistrationState.objData));

    //Delete
    const selectedLegalEntityIndex = clubRegistrationState.objData.usasClubLegalEntities.findIndex(
      e => e.usasClubLegalEntityId === usasClubLegalEntityId);

    clubCopy.usasClubLegalEntities.splice(selectedLegalEntityIndex, 1);

    return clubCopy;
  }

  const onAdd = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    navigate(NavLinks.CLUB_REGISTRATION_LEGAL_ENTITY_DETAIL);
  }

  const onEdit = (e, usasClubLegalEntityId) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    navigate(NavLinks.CLUB_REGISTRATION_LEGAL_ENTITY_DETAIL, { state: { usasClubLegalEntityId } });
  }

  const onDelete = (e, usasClubLegalEntityId) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    if (clubRegistrationState.objData.usasClubLegalEntities.length === 1) {
      const navIndex = clubRegistrationNavState.findIndex(x => x.name === ClubRegistrationConstants.LEGAL_ENTITY)
      clubRegistrationNavState[navIndex] =
      {
        ...clubRegistrationNavState[navIndex],
        isCurrent: true,
        isComplete: false,
        isVisited: true,
        arePrerequisitesDone: true
      }
      const submitNavIndex = clubRegistrationNavState.findIndex(x => x.name === ClubRegistrationConstants.SUBMIT)
      clubRegistrationNavState[submitNavIndex] =
      {
        ...clubRegistrationNavState[submitNavIndex],
        isCurrent: false,
        isComplete: false,
        isVisited: false,
        arePrerequisitesDone: false
      }
      setSubmitPrequisitesState({ ...submitPrequisitesState, areSubmitPrerequisitesDone: false });
      setClubRegistrationNavState(clubRegistrationNavState);
    }
    putUsasClub(clubRegistrationState.objData.usasClubId, editClubObj(usasClubLegalEntityId));
  }

  const onContinueClicked = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    if (clubRegistrationState.objData?.usasClubLegalEntities?.length > 0) {
      setState({ ...state, tryRedirect: true, submitButton: ClubRegistrationConstants.CONTINUE, error: '' });
    }
    else {
      setState({ ...state, submitButton: ClubRegistrationConstants.CONTINUE, error: ERROR_MSG });
    }
  }

  const onSaveAndExitClicked = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    if (clubRegistrationState.objData?.usasClubLegalEntities?.length > 0) {
      setState({ ...state, submitButton: ClubRegistrationConstants.EXIT, error: '' });
    }
    else {
      setState({ ...state, submitButton: ClubRegistrationConstants.EXIT, error: ERROR_MSG });
    }
  }

  const onBackClicked = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    if (clubRegistrationState.objData?.usasClubLegalEntities?.length > 0) {
      setState({ ...state, tryRedirect: true, submitButton: ClubRegistrationConstants.BACK, error: '' });
    }
    else {
      setState({ ...state, submitButton: ClubRegistrationConstants.BACK, error: ERROR_MSG });
    }
  }

  const onOpenModal = () => {
    if (state.error !== '') {
      setModalState({ ...modalState, displayPopUp: true });
    }
    else {
      setModalState({ ...modalState, modalTitle: ClubRegistrationConstants.EXIT_CONFIRMATION, displayPopUp: true });
    }
  }

  const onModalContinue = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    setState({ ...state, tryRedirect: true });
    setModalState({ ...getInitialModalState() });
  }

  const onModalCanceled = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    setModalState({ ...getInitialModalState() });
  }

  const updateNavState = () => {
    for (let i = 0; i < clubRegistrationNavState.length; i++) {
      clubRegistrationNavState[i].isCurrent = false
    }
    const navIndex = clubRegistrationNavState.findIndex(x =>
      x.name === ClubRegistrationConstants.LEGAL_ENTITY)
    if (clubRegistrationState.objData?.usasClubLegalEntities?.length > 0) {
      clubRegistrationNavState[navIndex] =
      {
        ...clubRegistrationNavState[navIndex],
        isCurrent: true,
        isComplete: true,
        isVisited: true,
        arePrerequisitesDone: true
      }
    }
    else {
      clubRegistrationNavState[navIndex] =
      {
        ...clubRegistrationNavState[navIndex],
        isCurrent: true,
        isComplete: false,
        isVisited: true,
        arePrerequisitesDone: true
      }
    }
    //Submit
    let areSubmitPrerequisitesDone = true;
    let isSubmitComplete = false;
    for (let i = 0; i < clubRegistrationNavState.length; i++) {
      if (clubRegistrationNavState[i].name !== ClubRegistrationConstants.SUBMIT
        && clubRegistrationNavState[i].isComplete === false) {
        areSubmitPrerequisitesDone = false;
      }
      if (clubRegistrationNavState[i].name === ClubRegistrationConstants.SUBMIT) {
        if (clubRegistrationState.objData.status === ClubRegistrationConstants.PENDING_STATUS ||
          clubRegistrationState.objData.status === ClubRegistrationConstants.APPROVED_STATUS) {
          isSubmitComplete = true
        }
      }
    }
    const submitNavIndex = clubRegistrationNavState.findIndex(x =>
      x.name === ClubRegistrationConstants.SUBMIT)
    clubRegistrationNavState[submitNavIndex] =
    {
      ...clubRegistrationNavState[submitNavIndex],
      isCurrent: false,
      isComplete: isSubmitComplete,
      isVisited: areSubmitPrerequisitesDone === false ? false : clubRegistrationNavState[submitNavIndex].isVisited,
      arePrerequisitesDone: areSubmitPrerequisitesDone
    }
    setSubmitPrequisitesState({ ...state, areSubmitPrerequisitesDone: areSubmitPrerequisitesDone });
    setClubRegistrationNavState(clubRegistrationNavState);
  }

  useEffect(() => {
    if (clubRegistrationState.isObjLoaded === false) {
      navigate(NavLinks.CLUB_REGISTRATION_ENTRY_NEW);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const navIndex = clubRegistrationNavState.findIndex(x =>
      x.name === ClubRegistrationConstants.LEGAL_ENTITY)
    if (clubRegistrationNavState[navIndex].isCurrent === false) {
      updateNavState();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clubRegistrationNavState])

  useEffect(() => {
    const submitNavIndex = clubRegistrationNavState.findIndex(x =>
      x.name === ClubRegistrationConstants.SUBMIT)
    if (clubRegistrationNavState[submitNavIndex].arePrerequisitesDone === false
      && submitPrequisitesState.areSubmitPrerequisitesDone === true) {
      updateNavState();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clubRegistrationNavState, submitPrequisitesState.areSubmitPrerequisitesDone])

  useEffect(() => {
    if (Object.keys(clubRegistrationState.objData).length > 0) {
      updateNavState();
      //Renewing club
      // if (clubRegistrationState.objData.clubRegistrations?.length > 1) {
      //   let selectedRegistration = clubRegistrationState.objData.clubRegistrations.find(x =>
      //     (x.status !== ClubRegistrationConstants.APPROVED_STATUS && x.status !== null));
      //   if (selectedRegistration === undefined) {
      //     selectedRegistration = clubRegistrationState.objData.clubRegistrations.reduce((a, b) => {
      //       return new Date(a.expirationDate) > new Date(b.expirationDate) ? a : b;
      //     });
      //   }
      //   setState({
      //     ...state,
      //     selectedRegistration: selectedRegistration
      //   });
      // }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clubRegistrationState.objData])

  useEffect(() => {
    //Handle validation modal and navigation
    if (state.submitButton !== '' && clubRegistrationState.isObjLoaded === true) {
      if (state.tryRedirect === false) {
        if (state.error !== '' || state.submitButton === ClubRegistrationConstants.EXIT) {
          onOpenModal();
        }
      }
      if (state.tryRedirect === true) {
        if (state.error === '') {
          const navIndex = clubRegistrationNavState.findIndex(x =>
            x.name === ClubRegistrationConstants.LEGAL_ENTITY)
          clubRegistrationNavState[navIndex] =
          {
            ...clubRegistrationNavState[navIndex],
            isCurrent: false,
            isComplete: true,
            isVisited: true,
            arePrerequisitesDone: true
          }
          //Submit
          let areSubmitPrerequisitesDone = true;
          let isSubmitComplete = false;
          for (let i = 0; i < clubRegistrationNavState.length; i++) {
            if (clubRegistrationNavState[i].name !== ClubRegistrationConstants.SUBMIT
              && clubRegistrationNavState[i].isComplete === false) {
              areSubmitPrerequisitesDone = false;
            }
            if (clubRegistrationNavState[i].name === ClubRegistrationConstants.SUBMIT) {
              if (clubRegistrationState.objData.status === ClubRegistrationConstants.PENDING_STATUS ||
                clubRegistrationState.objData.status === ClubRegistrationConstants.APPROVED_STATUS) {
                isSubmitComplete = true
              }
            }
          }
          const submitNavIndex = clubRegistrationNavState.findIndex(x => x.name === ClubRegistrationConstants.SUBMIT)
          clubRegistrationNavState[submitNavIndex] =
          {
            ...clubRegistrationNavState[submitNavIndex],
            isCurrent: false,
            isComplete: isSubmitComplete,
            isVisited: areSubmitPrerequisitesDone === false ? false : clubRegistrationNavState[submitNavIndex].isVisited,
            arePrerequisitesDone: areSubmitPrerequisitesDone
          }
          setClubRegistrationNavState(clubRegistrationNavState);
        }
        if (state.submitButton === ClubRegistrationConstants.CONTINUE) {
          navigate(NavLinks.CLUB_REGISTRATION_AFFILIATION);
        }
        else if (state.submitButton === ClubRegistrationConstants.EXIT) {
          navigate(NavLinks.CLUB_REGISTRATION_ENTRY_NEW);
        }
        else if (state.submitButton === ClubRegistrationConstants.BACK) {
          navigate(NavLinks.CLUB_REGISTRATION_TYPE);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state, clubRegistrationState.isObjLoaded])

  return {
    clubRegistrationNavState,
    clubRegistrationState,
    state,
    modalState,
    onModalContinue,
    onModalCanceled,
    onAdd,
    onEdit,
    onDelete,
    onContinueClicked,
    onSaveAndExitClicked,
    onBackClicked
  }
}

export default useClubRegistrationLegalEntities;

function getInitialModalState() {
  return {
    displayPopUp: false,
    modalTitle: ClubRegistrationConstants.MISSING_REQUIRED_INFORMATION
  }
};