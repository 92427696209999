import { useEffect } from "react";
import { useNavigate, useLocation } from "../../../../common/wrappers/ReactRouterDom";
import NavLinks from "../NavLinks";

const UseClubBlockPartyMeetAddHQ = () => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const meetObj = location.state.meet;
    navigate(NavLinks.CLUB_BLOCKPARTY_ADD, { state: { meet: meetObj, hasAdminRole: true } });
      
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {}
}

export default UseClubBlockPartyMeetAddHQ;