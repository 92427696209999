import React, { Fragment } from 'react';

import useClubNonAthleteRoster from './UseClubNonAthleteRoster';

import ClubNonAthleteRosterForm from './ClubNonAthleteRosterForm';
import ClubNonAthleteRosterGrid from './ClubNonAthleteRosterGrid';

import Headings from '../../../../common/components/headings/Headings';
import PopUpModal from '../../../../common/components/dialogs/PopUpModal';

import Constants from '../../../../common/utils/Constants';

const ClubNonAthleteRoster = () => {
  const {
    isLoading,
    state,
    clubNonAthleteRosterState,
    vendorSyncState,
    clubRosterVendorSyncState,
    clearVendorSync,
    onSubmitFormCallback,
    onEditNonAthlete,
    onSyncSelectedMembers,
    onVendorSyncCheckbox,
    orgUnitId,
  } = useClubNonAthleteRoster();

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>Non Athlete Roster</Headings.H3>
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12">
        {clubRosterVendorSyncState.isSaved === true && <p style={{fontSize: 24, color: 'slategray'}}>{clubRosterVendorSyncState.objData.toString()} Non-athletes Synced</p>} 
        </div>
      </div>
      <div className='row usas-extra-bottom-margin'>
        <div className="col-xs-12">
          <ClubNonAthleteRosterForm
            onSubmitFormCallback={onSubmitFormCallback}
            orgUnitId={orgUnitId}
            PRACTICE_GROUP_TYPE_ID={state.practiceGroupTypeId}
            SITE_GROUP_TYPE_ID={state.siteGroupTypeId} 
            vendorSyncState={vendorSyncState}
            clearVendorSync={clearVendorSync}
            onSyncSelectedMembers={onSyncSelectedMembers}
            />
        </div>
      </div>
      <div className='row usas-extra-top-margin usas-extra-bottom-margin'>
        <div className="col-xs-12">
          <ClubNonAthleteRosterGrid
            gridData={clubNonAthleteRosterState.arrayData}
            isLoading={clubNonAthleteRosterState.isArrayLoading}
            onEditNonAthlete={onEditNonAthlete} 
            onVendorSyncCheckbox={onVendorSyncCheckbox} />
        </div>
      </div>
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={isLoading} />
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.SAVING_MSG}
        displayPopUp={clubRosterVendorSyncState.isSaving} />
    </Fragment>
  );
};

export default ClubNonAthleteRoster;