import { useNavigate } from '../../../../common/wrappers/ReactRouterDom';

import NavLinks from '../NavLinks';

import useSwimAThonData from '../../../state/swimAThon/UseSwimAThonData';

const useClubSwimAThonContract = () => {
  const navigate = useNavigate();
  const { satState } = useSwimAThonData();

  const onAddContract = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    navigate(NavLinks.CLUB_SWIMATHON_CONTRACTS_DETAIL);
  };

  const onEditContract = (e, satContract) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    
    navigate(NavLinks.CLUB_SWIMATHON_CONTRACTS_DETAIL, { state: { satContract } });
  };

  return {
    satState,
    onAddContract,
    onEditContract
  };
};

export default useClubSwimAThonContract;