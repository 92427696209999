import { ClubHttpHelper, NO_DATA_MESSAGE, BASIC_INITIAL_STATE } from '../../../common/utils/HttpHelper';
import Constants from '../../../common/utils/Constants';

export const INITIAL_STATE = {
  ...BASIC_INITIAL_STATE,
  clubOwnerTypeId: Constants.DEFAULT_ID,
  dataAsOptionsWithNames: []
};

const getBusinessEntityTypes = (state, setState, clubOwnerTypeId) => {
  if (state.isArrayLoading !== true && clubOwnerTypeId > 0 
    && clubOwnerTypeId !== state.clubOwnerTypeId && !state.message) {

    let newState = {
      ...state,
      isArrayLoading: true,
      isArrayLoaded: false,
      message: 'Loading...'
    };
    setState(newState);

    const clubOwnerTypeIdForUrl = clubOwnerTypeId ? encodeURIComponent(clubOwnerTypeId) : 'NaN';
    const url = `/BusinessEntityType/clubOwnerType/${clubOwnerTypeIdForUrl}`;
    ClubHttpHelper(url, 'GET')
      .then((arrayData) => {
        if (!arrayData) {
          throw new Error(NO_DATA_MESSAGE);
        } else {
          arrayData.unshift({ businessEntityTypeId: Constants.DEFAULT_ID, typeName: '--' });
          newState = {
            ...newState,
            clubOwnerTypeId,
            isArrayLoading: false,
            isArrayLoaded: true,
            arrayData,
            dataAsOptionsWithNames: arrayData.map((e) => { return { id: e.businessEntityTypeId, name: e.typeName } }),
            message: ''
          };
          setState(newState);
        }
      })
      .catch((e) => {
        const message = e && e.message ? e.message : NO_DATA_MESSAGE;
        setState({
          ...newState,
          isArrayLoading: false,
          isArrayLoaded: false,
          message: message
        });
      });
  }
};

const BusinessEntityData = { INITIAL_STATE, getBusinessEntityTypes };

export default BusinessEntityData;