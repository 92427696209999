import { useContext } from 'react';

import { UsasClubStaffStateContext } from './UsasClubStaffContextProvider';
import UsasClubStaffData from './UsasClubStaffData';

const useUsasClubStaffData = () => {
  const [usasClubStaffState, setUsasClubStaffState] = useContext(UsasClubStaffStateContext);

  const getUsasClubStaff = (orgUnitId, roleGroupId, isCurrentOnly) => UsasClubStaffData.getUsasClubStaffData(orgUnitId, roleGroupId, isCurrentOnly, usasClubStaffState, setUsasClubStaffState);
  const postUsasClubStaff = (orgUnitId, roleGroupId,  staffObj) => UsasClubStaffData.postUsasClubStaffData(orgUnitId, roleGroupId, staffObj, usasClubStaffState, setUsasClubStaffState);
  const putUsasClubStaff = (orgUnitId, personId, roleGroupId, staffObj) => UsasClubStaffData.putUsasClubStaffData(orgUnitId, personId, roleGroupId, staffObj, usasClubStaffState, setUsasClubStaffState);
  const deleteUsasClubStaff = (personOrgRoleDurationId) => UsasClubStaffData.deleteUsasClubStaffData(personOrgRoleDurationId, usasClubStaffState, setUsasClubStaffState);

  const clearUsasClubStaffObjData = () => {
    setUsasClubStaffState({
      ...usasClubStaffState,
      isObjLoading: false,
      isObjLoaded: false,
      isSaving: false,
      isSaved: false,
      objData: {},
      message: ''
    });
  };

  const clearUsasClubStaffArrayData = () => {
    setUsasClubStaffState({
      ...usasClubStaffState,
      isArrayLoading: false,
      isArrayLoaded: false,
      isSaving: false,
      isSaved: false,
      arrayData: [],
      message: ''
    });
  };

  const confirmUsasClubStaffSave = () => {
    setUsasClubStaffState({
      ...usasClubStaffState,
      isSaved: false
    });
  };

  return {
    usasClubStaffState,
    setUsasClubStaffState,
    clearUsasClubStaffObjData,
    clearUsasClubStaffArrayData,
    confirmUsasClubStaffSave,
    getUsasClubStaff,
    postUsasClubStaff,
    putUsasClubStaff,
    deleteUsasClubStaff
  };
};

export default useUsasClubStaffData;