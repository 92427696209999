import { useContext } from 'react';

import { LearnToSwimClubsStateContext } from './LearnToSwimClubsContextProvider';

import LearnToSwimClubsData from './LearnToSwimClubsData';

const useLearnToSwimClubsData = () => {
  const [ltsClubsState, setLTSClubsState] = useContext(LearnToSwimClubsStateContext);

  const getLTSClubs = () => {
    return LearnToSwimClubsData.getLTSClubsData(ltsClubsState, setLTSClubsState);
  };

  return {
    ltsClubsState,
    setLTSClubsState,
    getLTSClubs
  };
};

export default useLearnToSwimClubsData;