import React, { Fragment } from 'react';

import useClubAdminChanges from './UseClubAdminChanges';

import ClubInactiveReasonDropdown from '../../../components/dropdowns/ClubInactiveReasonDropdown';

import Headings from '../../../../common/components/headings/Headings';
import PrimaryButton from '../../../../common/components/buttons/PrimaryButton';
import SecondaryButton from '../../../../common/components/buttons/SecondaryButton';
import YesNoSwitch from '../../../../common/components/yesNoSwitch/YesNoSwitch';
import PopUpModal from '../../../../common/components/dialogs/PopUpModal';
import Input from '../../../../common/components/inputs/Input';

import Constants from '../../../../common/utils/Constants';

import global from '../../../../common/components/GlobalStyle.module.css';
import ReadOnly from '../../../../common/components/readOnly/ReadOnly';

const ClubAdminChanges = () => {
  const {
    formState,
    errorState,
    isSubmitting,
    baseUsasClubState,
    clubInactiveReasonState,
    onCancelClicked,
    onFormValueChanged,
    handleSubmit
  } = useClubAdminChanges();

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>Club Admin Changes</Headings.H3>
        </div>
      </div>
      <form onSubmit={handleSubmit} noValidate>
        <div className="row">
          <div className="col-xs-12 col-sm-6 col-md-4">
            {formState.isClubCurrent === true ? 
            <Input
              label="Club Name*"
              name="clubName"
              value={formState.clubName}
              error={errorState.clubName}
              message={errorState.clubName}
              onChange={(value) => { onFormValueChanged('clubName', value) }} /> :
            <ReadOnly 
              label="Club Name"
              name="clubName"
              value={formState.clubName}
            />
            }
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4">
            {formState.isClubCurrent === true ?
            <Input
              label="Club Code*"
              name="clubCode"
              value={formState.clubCode}
              error={errorState.clubCode}
              message={errorState.clubCode}
              onChange={(value) => { onFormValueChanged('clubCode', value) }} /> :
            <ReadOnly 
              label="Club Code"
              name="clubCode"
              value={formState.clubCode}
            />
            }
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4">
            {formState.isClubCurrent === true ?
            <YesNoSwitch
              label="Is Club Active?"
              name="isActive"
              checked={formState.isActive}
              error={errorState.isActive}
              message={errorState.isActive}
              onChange={(value) => { onFormValueChanged('isActive', value); }}
            /> :
            <ReadOnly 
              label="Is Club Active?"
              name="isActive"
              value={formState.isActive === true ? 'Yes' : 'No'}
            />
            }
          </div>
          <div className={formState.isActive === false ? global.DisplayComponent : global.HideComponent}>
            <div className="col-xs-12 col-sm-6 col-md-4">
              <ClubInactiveReasonDropdown
                label="Inactive Reason*"
                name="clubInactiveReasonId"
                value={formState.clubInactiveReasonId}
                error={errorState.clubInactiveReasonId}
                message={errorState.clubInactiveReasonId}
                onChange={(value) => { onFormValueChanged('clubInactiveReasonId', value); }}
              />
            </div>
          </div>
        </div>
        {formState.isClubCurrent === true ?
        <div className="row usas-extra-top-margin">
          <div className="col-xs-12 usas-extra-top-margin usas-extra-bottom-margin">
            <PrimaryButton type="submit">Save</PrimaryButton>&nbsp;
            <SecondaryButton type="button" onClick={onCancelClicked}>Cancel</SecondaryButton>
          </div>
        </div> : <Fragment />
        }
      </form >
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={baseUsasClubState.isObjLoading || clubInactiveReasonState.isArrayLoading} />
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.SAVING_MSG}
        displayPopUp={baseUsasClubState.isSaving || isSubmitting} />
    </Fragment >
  );
};

export default ClubAdminChanges;