import { useEffect, useState } from 'react';
import { useNavigate } from '../../../../common/wrappers/ReactRouterDom';

import validate from './ClubRegistrationAffiliationsDetailValidation';

import NavLinks from '../NavLinks';

import useClubRegistrationNavData from '../../../state/clubRegistration/leftNavigation/UseClubRegistrationNavData';
import useClubRegistrationNewData from '../../../state/clubRegistrationNew/UseClubRegistrationNewData';

import useClubAffiliationTypeData from '../../../../common/state/clubAffiliationType/UseClubAffiliationTypeData';
import useForm from '../../../../common/utils/UseForm';

const useClubRegistrationAffiliationsDetail = () => {
  const navigate = useNavigate();
  const { clubRegistrationNavState } = useClubRegistrationNavData();
  const { clubRegistrationState, putUsasClub } = useClubRegistrationNewData();
  const { clubAffiliationTypeState } = useClubAffiliationTypeData();
  const [state, setState] = useState({ showDuplicateMsg: false, tryRedirect: false });
  const { handleSubmit, updateFormState, formState, setFormState, errorState, isDirty
  } = useForm(getFormInitialState(), submitFormCallback, validate);

  function submitFormCallback() {
    putUsasClub(clubRegistrationState.objData.usasClubId, editClubObj());
    setState({ ...state, tryRedirect: true });
  };

  const editClubObj = () => {
    let clubCopy = JSON.parse(JSON.stringify(clubRegistrationState.objData));
    let usasClubAffiliationsArray = [];

    for (const affiliation of formState.clubAffiliationTypes) {
      let affiliationObj = {
        usasClubId: clubCopy.usasClubId,
        affiliationTypeId: affiliation.id,
      };
      usasClubAffiliationsArray.push(affiliationObj);
    }

    clubCopy.usasClubAffiliations = usasClubAffiliationsArray

    return clubCopy;
  };

  const onSaveClicked = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    handleSubmit();
    if (isDirty === false && formState.clubAffiliationTypes.length > 0) {
      setState({ ...state, tryRedirect: true });
    }
  }

  const onCancelClicked = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    navigate(NavLinks.CLUB_REGISTRATION_AFFILIATION);
  }

  useEffect(() => {
    if (clubRegistrationState.isObjLoaded === false) {
      navigate(NavLinks.CLUB_REGISTRATION_ENTRY_NEW);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (clubRegistrationState.isObjLoaded === true && clubAffiliationTypeState.isArrayLoaded === true) {
      let clubAffiliationTypesArray = [];

      for (const affiliation of clubRegistrationState.objData.usasClubAffiliations) {
        let affiliationObj = {
          id: affiliation.affiliationTypeId,
          name: affiliation.clubAffiliationType?.typeName
        };
        clubAffiliationTypesArray.push(affiliationObj);
      }

      setFormState({
        ...formState,
        clubAffiliationTypes: clubAffiliationTypesArray
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clubRegistrationState.isObjLoaded, clubAffiliationTypeState.isArrayLoaded]);


  useEffect(() => {
    if (state.tryRedirect === true && clubRegistrationState.isSaving === false) {
      navigate(NavLinks.CLUB_REGISTRATION_AFFILIATION);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.tryRedirect, clubRegistrationState]);

  return {
    clubAffiliationTypeState,
    clubRegistrationNavState,
    state,
    setState,
    formState,
    errorState,
    clubRegistrationState,
    onFormValueChanged: updateFormState,
    handleSubmit,
    onSaveClicked,
    onCancelClicked
  }
};

export default useClubRegistrationAffiliationsDetail;

function getFormInitialState() {
  return {
    clubAffiliationTypes: []
  }
};