import { Fragment } from "react";

import { SisenseContextProvider } from "@sisense/sdk-ui";

import useClubPerformanceAthleteTimesMeetResults from "./UseClubPerformanceAthleteTimesMeetResults";

import MeetResultsDashboard from "./MeetResultsDashboard";

import * as DM from '../../../../../../usas-times-data';

import Headings from "../../../../../../common/components/headings/Headings";

const ClubPerformanceAthleteTimesMeetResults = () => {
  const { environmentVariableState, MEET_RESULTS_OID } = useClubPerformanceAthleteTimesMeetResults();

  return (
    <Fragment>
      {environmentVariableState.isLoaded === true &&
        <SisenseContextProvider
          defaultDataSource={DM.DataSource}
          url={import.meta.env.VITE_SISENSE_URL}
          token={import.meta.env.VITE_SISENSE_API_TOKEN}>
          <div className="row usas-extra-bottom-margin">
            <div className="col-xs-12">
              <Headings.H3>Athlete Times Detail - Meet Results</Headings.H3>
              <MeetResultsDashboard dashboardOid={MEET_RESULTS_OID} />
            </div>
          </div>
        </SisenseContextProvider>}
    </Fragment>
  );
}
export default ClubPerformanceAthleteTimesMeetResults;