import React, { Fragment } from 'react';

import useClubFacilities from './UseClubFacilities';

import useExpandedRow from '../../../../common/components/grids/UseExpandedRow';

import LargeClubFacilitiesReadGrid from './LargeClubFacilitiesReadGrid';
import SmallClubFacilitiesReadGrid from './SmallClubFacilitiesReadGrid';

const ClubFacilitiesReadGrid = () => {
  const { usasClubFacilityLinksState } = useClubFacilities();
  const { expandedId, onClick } = useExpandedRow();
  return (
    <Fragment>
      <LargeClubFacilitiesReadGrid state={usasClubFacilityLinksState}
        expandedId={expandedId}
        onClick={onClick} />
      <SmallClubFacilitiesReadGrid state={usasClubFacilityLinksState}
        expandedId={expandedId}
        onClick={onClick} />
    </Fragment>
  )
};

export default ClubFacilitiesReadGrid;

