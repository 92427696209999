/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Fragment } from 'react';

import HideIcon from '../../../../../common/components/icons/HideIcon';
import ShowIcon from '../../../../../common/components/icons/ShowIcon';
import EditIcon from '../../../../../common/components/icons/EditIcon';
import DeleteIcon from '../../../../../common/components/icons/DeleteIcon';
import { formatPhoneNumber } from '../../../../../common/utils/validation';

import global from '../../../../../common/components/GlobalStyle.module.css';

const DetailTableRow = ({ personId, role, onDelete, canDeletePrimaryContact, PRIMARY_CONTACT_ORG_ROLE_ID }) => (
  <div className={global.SmallTableRow}>
    <div className={global.SmallTableDetailRowHead}><span className='hidden-xs'>{role.orgRoleName}</span>&nbsp;
      {onDelete !== undefined && !(role.orgRoleId === PRIMARY_CONTACT_ORG_ROLE_ID && canDeletePrimaryContact === false) &&
        <button
          className={global.IconButton}
          type="button"
          onClick={() => onDelete(personId, role.personOrgRoleDurationId || role.orgRoleId)}>
          <DeleteIcon />
        </button>
      }
    </div>
    <div className={global.SmallTableRowInfo}>
      <div className='row'>
        <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3 visible-xs'].join(' ')}>Role</div>
        <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3 visible-xs'].join(' ')}>{role.orgRoleName}</div>
      </div>
    </div>
  </div>
);

const DetailTable = ({ personId, personOrgRole, onDelete, canDeletePrimaryContact, PRIMARY_CONTACT_ORG_ROLE_ID }) => (
  <div className={global.SmallTableDetailTable}>
    {Array.isArray(personOrgRole) && personOrgRole.map((role, i) =>
      <DetailTableRow key={i} personId={personId} role={role} onDelete={onDelete} canDeletePrimaryContact={canDeletePrimaryContact} PRIMARY_CONTACT_ORG_ROLE_ID={PRIMARY_CONTACT_ORG_ROLE_ID} />)}
  </div>
);

const TableRow = ({ staff, onEdit, onDelete, canDeletePrimaryContact, expandedId, onClick, PRIMARY_CONTACT_ORG_ROLE_ID }) => {
  return (
    <Fragment>
      <div className={global.SmallTableRow}>
        <div className={global.SmallTableRowHead}><span className='hidden-xs'>{staff.firstName} {staff.lastName}</span>&nbsp;
          <button className={global.IconButton}
            type="button"
            onClick={(e) => onClick(e, staff.personId)}>
            {expandedId === staff.personId ? <HideIcon /> : <ShowIcon />}
          </button>
          {onEdit !== undefined &&
            <button
              className={global.IconButtonMobileMargin}
              type="button"
              onClick={() => onEdit(staff)}>
              <EditIcon />
            </button>
          }
        </div>
        <div className={global.SmallTableRowInfo}>
          <div className='row'>
            <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3 visible-xs'].join(' ')}>Staff Name</div>
            <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3 visible-xs'].join(' ')}>{`${staff.firstName} ${staff.lastName}`}</div>
            <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3'].join(' ')}>Phone Number</div>
            <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3'].join(' ')}>{staff.phoneNumber ? formatPhoneNumber(staff.phoneNumber) : <span>&nbsp;</span>}</div>
            <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3'].join(' ')}>Email</div>
            <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3'].join(' ')}>{staff.email || <span>&nbsp;</span>}</div>
          </div>
        </div>
      </div>
      {Array.isArray(staff.personOrgRole) && staff.personOrgRole.length > 0 && expandedId === staff.personId &&
        <DetailTable personId={staff.personId} personOrgRole={staff.personOrgRole} onDelete={onDelete}
          canDeletePrimaryContact={canDeletePrimaryContact} PRIMARY_CONTACT_ORG_ROLE_ID={PRIMARY_CONTACT_ORG_ROLE_ID} />}
    </Fragment>
  );
};

const PreCompLocationStaffGridSmall = ({ data, isLoading, onEdit, onDelete, canDeletePrimaryContact, expandedId, onClick, PRIMARY_CONTACT_ORG_ROLE_ID }) => (
  <div className={[global.SmallTable, 'hidden-md hidden-lg'].join(' ')}>
    {isLoading === true
      ? (
        <div className={global.SmallTableRow}>
          <div className={global.SmallTableRowHead}>&nbsp;</div>
          <div className={global.SmallTableRowInfo}>
            <div className={global.SmallTableRowLabels}>Loading...</div>
          </div>
        </div>
      )
      : data.length > 0
        ? data.map((staff, i) => <TableRow key={i} staff={staff} onEdit={onEdit} onDelete={onDelete}
          canDeletePrimaryContact={canDeletePrimaryContact} expandedId={expandedId} onClick={onClick}
          PRIMARY_CONTACT_ORG_ROLE_ID={PRIMARY_CONTACT_ORG_ROLE_ID} />)
        : (
          <div className={global.SmallTableRow}>
            <div className={global.SmallTableRowHead}>&nbsp;</div>
            <div className={global.SmallTableRowInfo}>
              <div className={global.SmallTableRowLabels}>No Staff</div>
            </div>
          </div>
        )}
  </div>
);

export default PreCompLocationStaffGridSmall;