import { isValidMemberId, isValidDate } from '../../../../../common/utils/validation';
import { PersonHttpHelper, NO_DATA_MESSAGE } from '../../../../../common/utils/HttpHelper';
import Constants from '../../../../../common/utils/Constants';

export const localValidate = (formState) => {
  let errors = {};
  const effectiveDate = new Date(formState.effectiveDate);
  const expirationDate = new Date(formState.expirationDate);

  if (formState.memberId.trim().length === 0) {
    errors.memberId = 'Member Id is required';
  }

  if (isValidMemberId(formState.memberId.trim()) === false) {
    errors.memberId = 'A valid Member Id is required';
  }

  if (formState.effectiveDate === Constants.BLANK_DATE_STRING
    || !isValidDate(formState.effectiveDate)) {
    errors.effectiveDate = 'Beginning Date must be a valid date';
  }

  if (formState.hasNoExpirationDate === false) {
    if (formState.expirationDate === Constants.BLANK_DATE_STRING
      || !isValidDate(formState.expirationDate)) {
      errors.expirationDate = 'End Date must be a valid date';
    }

    if (expirationDate < effectiveDate) {
      errors.effectiveDate = 'Beginning Date must be before the End Date';
      errors.expirationDate = 'End Date must be after the Beginning Date';
    }

    if (formState.effectiveDate !== Constants.BLANK_DATE_STRING
      && formState.expirationDate === Constants.BLANK_DATE_STRING) {
      errors.expirationDate = 'End Date is required if there is a Beginning Date';
    }

    if (formState.effectiveDate === Constants.BLANK_DATE_STRING
      && formState.expirationDate !== Constants.BLANK_DATE_STRING) {
      errors.effectiveDate = 'Beginning Date is required if there is a End Date';
    }
  }

  if (formState.orgRole.length === 0) {
    errors.orgRole = 'LSC Security Role is required';
  }

  return errors;
};

const serverValidate = async (formState) => {
  let errors = {};

  const memberIdForUrl = formState.memberId ? encodeURIComponent(formState.memberId) : 'NaN';
  const url = `/Person/flat/memberId/${memberIdForUrl}`;

  await PersonHttpHelper(url, 'GET')
    .then((objData) => {
      if (!objData) {
        throw new Error(NO_DATA_MESSAGE);
      } else {
        formState.personId = objData.personId;
      }
    })
    .catch((e) => {
      errors.apiError = 'Member Id is not valid';
    });

  return errors;
};


const AddLSCSecurityRoleValidation = async (formState) => {
  let errors = localValidate(formState);
  if (!errors || Object.keys(errors).length === 0) {
    errors = await serverValidate(formState);
  }

  return errors ? errors : {};
};

export default AddLSCSecurityRoleValidation;