import { useState, useEffect } from 'react';

import validate from '../../../../../../common/components/meetSessions/SessionsDetailValidation';

import useMeetSessionsData from '../../../../../../common/state/meetSessions/UseMeetSessionsData';
import useTimeData from '../../../../../../common/state/time/UseTimeData';

import Constants from '../../../../../../common/utils/Constants';
import { formatDate, formatDateTimeToTime } from '../../../../../../common/utils/DateFunctions';
import ToIntIfInt from '../../../../../../common/utils/ToIntIfInt';

const SESSION_NUMBER_DUPE_ERROR = 'The provided Session Number is already associated with an existing session. Duplicate session numbers are not allowed.'

const INITIAL_VIEW_STATE = {
  trySetFormData: true,
  trySave: false,
  tryRedirect: false
}

const useSessionsDetail = (onBackToMeetSessions) => {
  const { sessionState } = useTimeData();
  const [state, setState] = useState(INITIAL_VIEW_STATE);
  const [meetDatesState, setMeetDatesState] = useState([{ id: Constants.DEFAULT_ID, name: "--" }]);
  const { meetSessionsState, setMeetSessionsState, resetDetailViewState } = useMeetSessionsData();

  const saveFunction = (e) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }
    const meetSessionArrayCopy = JSON.parse(JSON.stringify(meetSessionsState.meetSessionArray));
    //Add
    if (meetSessionsState.formState?.meetSessionId < 0) {
      let meetSessionIds = [];
      if (meetSessionArrayCopy.length > 0) {
        meetSessionIds = meetSessionArrayCopy.map(object => {
          return object.meetSessionId;
        });
      }
      const maxMeetSessionIds = Math.max(...meetSessionIds);
      const maxMeetSessionIdPlusOne = maxMeetSessionIds + 1;

      meetSessionArrayCopy.push({
        meetSessionId: meetSessionArrayCopy.length > 0 ? maxMeetSessionIdPlusOne : 1,
        sessionOrderId: meetSessionsState.formState?.sessionOrderId,
        sessionTypeId: meetSessionsState.formState?.sessionTypeId,
        sessionType: { typeName: meetSessionsState.formState?.sessionTypeName },
        sessionDate: meetSessionsState.formState?.sessionDate,
        warmUpTime: meetSessionsState.formState?.warmUpTime !== Constants.BLANK_TIME_STRING ? meetSessionsState.formState?.sessionDate + ' ' + meetSessionsState.formState?.warmUpTime : null,
        startTime: meetSessionsState.formState?.startTime !== Constants.BLANK_TIME_STRING ? meetSessionsState.formState?.sessionDate + ' ' + meetSessionsState.formState?.startTime : null,
        meetEvent: []
      })
    }
    //Edit
    else {
      const selectedIndex = meetSessionArrayCopy.findIndex(x => x.meetSessionId === meetSessionsState.formState?.meetSessionId);
      if (selectedIndex > -1) {
        meetSessionArrayCopy[selectedIndex] = {
          ...meetSessionArrayCopy[selectedIndex],
          sessionOrderId: meetSessionsState.formState?.sessionOrderId,
          sessionTypeId: meetSessionsState.formState?.sessionTypeId,
          sessionType: { typeName: meetSessionsState.formState?.sessionTypeName },
          sessionDate: meetSessionsState.formState?.sessionDate,
          warmUpTime: meetSessionsState.formState?.warmUpTime !== Constants.BLANK_TIME_STRING ? meetSessionsState.formState?.sessionDate + ' ' + meetSessionsState.formState?.warmUpTime : null,
          startTime: meetSessionsState.formState?.startTime !== Constants.BLANK_TIME_STRING ? meetSessionsState.formState?.sessionDate + ' ' + meetSessionsState.formState?.startTime : null
        }
      }
    }

    setMeetSessionsState({ ...meetSessionsState, meetSessionArray: meetSessionArrayCopy });
  }

  const tryValidateBeforeSave = async () => {
    const errors = await validate(meetSessionsState.formState) || {};
    setMeetSessionsState({ ...meetSessionsState, errorState: errors });
    if (Object.keys(errors).length === 0) {
      const updatedMeetSessionArrayCopy = JSON.parse(JSON.stringify(meetSessionsState?.meetSessionArray));

      if (tryAddEditSession(updatedMeetSessionArrayCopy) === true) {
        saveFunction();
        setState({ ...state, tryRedirect: true });
      }
    }
  };

  const tryValidateBeforeRedirect = async () => {
    const errors = await validate(meetSessionsState.formState) || {};
    setMeetSessionsState({ ...meetSessionsState, errorState: errors });
    if (Object.keys(errors).length === 0) {
      setState({ ...state, tryRedirect: true });
    }
  };

  const onSaveButtonClicked = (e) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }
    if (meetSessionsState.isDirty === false) {
      tryValidateBeforeRedirect();
    }
    else {
      tryValidateBeforeSave();
    }
  };

  const onBackButtonClicked = (e) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }
    resetDetailViewState();
    onBackToMeetSessions();
  };


  const onFormValueChangedSetMeetSessionsState = (compnentName, componentValue) => {
    if (meetSessionsState.formState[compnentName] !== componentValue) {
      const updatedFormState = { ...meetSessionsState.formState, [compnentName]: componentValue };
      setMeetSessionsState({ ...meetSessionsState, formState: updatedFormState, isDirty: true });
    }
  };

  const onValueTextPairChangedSetMeetSessionsState = (newValue, valuePropertyName, newValueLabel, labelPropertyName) => {
    if (meetSessionsState.formState[valuePropertyName] !== newValue
      || meetSessionsState.formState[labelPropertyName] !== newValueLabel) {
      const updatedFormState = {
        ...meetSessionsState.formState,
        [valuePropertyName]: newValue,
        [labelPropertyName]: newValueLabel
      };
      setMeetSessionsState({ ...meetSessionsState, formState: updatedFormState, isDirty: true });
    }
  };

  const getMeetDates = (startDate, endDate) => {
    let dates = []
    const theStartDate = new Date(startDate)
    const theEndDate = new Date(endDate)
    while (theStartDate <= theEndDate) {
      dates = [...dates, formatDate(theStartDate)]
      let newStartDate = theStartDate.getDate() + 1;
      theStartDate.setDate(newStartDate)
    }
    return dates
  };

  function validateSession(meetSessionArrayCopy) {
    //Check for duplicate session numbers
    let duplicate = false;
    for (let i = 0; i < meetSessionArrayCopy.length; i++) {
      if (ToIntIfInt(meetSessionArrayCopy[i].meetSessionId) !== ToIntIfInt(meetSessionsState?.formState?.meetSessionId)) {
        if (ToIntIfInt(meetSessionArrayCopy[i].sessionOrderId) === ToIntIfInt(meetSessionsState?.formState?.sessionOrderId)) {
          duplicate = true;
        }
      }
    }

    if (duplicate === true) {
      setMeetSessionsState({
        ...meetSessionsState,
        errorState:
        {
          error: SESSION_NUMBER_DUPE_ERROR
        }
      });
      return false;
    }

    return true;
  }

  function tryAddEditSession(updatedMeetSessionArrayCopy) {
    if (validateSession(updatedMeetSessionArrayCopy) === true) {
      return true;
    }
    else {
      return null;
    }
  }

  useEffect(() => {
    if (meetSessionsState) {
      const startDate = formatDate(meetSessionsState.formState?.meetStartDate);
      const endDate = formatDate(meetSessionsState.formState?.meetEndDate);
      const meetDatesRange = getMeetDates(startDate, endDate).map((date, i) => {
        return {
          id: i + 1,
          name: date
        }
      })
      setMeetDatesState([
        { id: Constants.DEFAULT_ID, name: "--" },
        ...meetDatesRange
      ]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [meetSessionsState.formState?.meetStartDate, meetSessionsState.formState?.meetEndDate]);

  useEffect(() => {
    if (state.trySetFormData === true && meetSessionsState?.meetSessionArray && Object.keys(meetSessionsState?.meetSessionArray).length > 0 &&
      sessionState.isArrayLoading === false && sessionState.isArrayLoaded === true && meetDatesState.length > 1) {
      //Edit
      const meetSessionArrayCopy = JSON.parse(JSON.stringify(meetSessionsState?.meetSessionArray));
      if (meetSessionArrayCopy.length > 0 && meetSessionsState?.formState?.meetSessionId > 0) {
        const selectedMeetSession = meetSessionArrayCopy?.find(x =>
          x.meetSessionId === meetSessionsState?.formState?.meetSessionId);
        if (Object.keys(selectedMeetSession).length > 0) {
          setMeetSessionsState({
            ...meetSessionsState,
            formState: {
              ...meetSessionsState.formState,
              meetSessionId: selectedMeetSession.meetSessionId || Constants.DEFAULT_ID,
              sessionOrderId: selectedMeetSession.sessionOrderId || Constants.DEFAULT_ID,
              sessionTypeId: selectedMeetSession.sessionTypeId || Constants.DEFAULT_ID,
              sessionTypeName: selectedMeetSession?.sessionType?.typeName || Constants.DEFAULT_ID,
              sessionDateId: selectedMeetSession.sessionDate ? (meetDatesState.find(x => x.name === formatDate(selectedMeetSession.sessionDate))?.id || Constants.DEFAULT_ID) : Constants.DEFAULT_ID,
              sessionDate: selectedMeetSession.sessionDate ? formatDate(selectedMeetSession.sessionDate) : '',
              startTime: selectedMeetSession.startTime ? formatDateTimeToTime(selectedMeetSession.startTime) : Constants.BLANK_TIME_STRING,
              warmUpTime: selectedMeetSession.warmUpTime ? formatDateTimeToTime(selectedMeetSession.warmUpTime) : Constants.BLANK_TIME_STRING
            },
            isDirty: false
          });
          setState({ ...state, trySetFormData: false })
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.trySetFormData, state.trySetFormData, sessionState, meetDatesState]);

  useEffect(() => {
    if (state.tryRedirect === true) {
      resetDetailViewState();
      onBackToMeetSessions();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.tryRedirect]);

  return {
    meetSessionsState,
    setMeetSessionsState,
    sessionState,
    meetDatesState,
    onSaveButtonClicked,
    onBackButtonClicked,
    onFormValueChangedSetMeetSessionsState,
    onValueTextPairChangedSetMeetSessionsState
  };
};

export default useSessionsDetail;