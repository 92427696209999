import React, { createContext, useState } from 'react';

import PreCompPolicyTypeData from './PreCompPolicyTypeData';

export const PreCompPolicyTypeStateContext = createContext();

const PreCompPolicyTypeContextProvider = ({ children }) => {
  const stateHook = useState(PreCompPolicyTypeData.INITIAL_STATE);

  return (
    <PreCompPolicyTypeStateContext.Provider value={stateHook}>
      {children}
    </PreCompPolicyTypeStateContext.Provider>
  );
};

export default PreCompPolicyTypeContextProvider;