import { Fragment } from "react";

import useProviderStaffForm from "./UseProviderStaffForm";

import Input from "../../../../../common/components/inputs/Input";
import PrimaryButton from "../../../../../common/components/buttons/PrimaryButton";
import SecondaryButton from "../../../../../common/components/buttons/SecondaryButton";
import PopUpModal from "../../../../../common/components/dialogs/PopUpModal";
import ReadOnly from "../../../../../common/components/readOnly/ReadOnly";
import DatePicker from "../../../../../common/components/datepickers/DatePicker";
import InternationalPhoneInput from "../../../../../common/components/inputs/InternationalPhoneInput";
import OrgRoleStaffRoleTreeView from "../../../../../common/components/tree/orgRoleTree/OrgRoleStaffRoleTreeView";

import Constants from "../../../../../common/utils/Constants";

const ProviderStaffForm = ({ submitButtonText = 'Save', secondaryButtonText = 'Cancel', staffObj = undefined,
  onSubmitFormCallback, onSecondaryButtonClicked, roleGroupId }) => {
  const {
    isLoading,
    formState,
    errorState,
    handleSubmit,
    updateFormState
  } = useProviderStaffForm(onSubmitFormCallback, staffObj);

  return (
    <Fragment>
      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-xs-12 col-sm-6 col-md-4">
            {staffObj
              ? (
                <ReadOnly
                  label='First Name'
                  name="firstName"
                  value={formState.firstName} />
              ) : (
                <Input
                  label='First Name*'
                  name="firstName"
                  value={formState.firstName}
                  error={errorState.firstName}
                  message={errorState.firstName}
                  onChange={(value) => { updateFormState('firstName', value) }} />
              )
            }
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4">
            {staffObj
              ? (
                <ReadOnly
                  label='Last Name'
                  name="lastName"
                  value={formState.lastName} />
              ) : (
                <Input
                  label='Last Name*'
                  name="lastName"
                  value={formState.lastName}
                  error={errorState.lastName}
                  message={errorState.lastName}
                  onChange={(value) => { updateFormState('lastName', value) }} />
              )
            }
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4">
            {staffObj
              ? (
                <ReadOnly
                  label='Birth Date'
                  name="birthDate"
                  value={formState.birthDate} />
              ) : (
                <DatePicker
                  label="Birth Date*"
                  name="birthDate"
                  value={formState.birthDate}
                  error={errorState.birthDate}
                  message={errorState.birthDate}
                  onChange={(value) => { updateFormState('birthDate', value); }} />
              )
            }
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4">
            <Input
              label='Email*'
              name="email"
              value={formState.email}
              error={errorState.email}
              message={errorState.email}
              onChange={(value) => { updateFormState('email', value) }} />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4">
            <InternationalPhoneInput
              label="Phone Number*"
              name="phoneNumber"
              value={formState.phoneNumber}
              error={errorState.phoneNumber}
              message={errorState.phoneNumber}
              onChange={(value) => { updateFormState('phoneNumber', value); }} />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4">
            {roleGroupId &&
              <OrgRoleStaffRoleTreeView
                label="Staff Role(s)*"
                name="orgRoles"
                value={formState.orgRoles}
                error={errorState.orgRoles}
                message={errorState.orgRoles}
                singleSelect={false}
                onChange={(value) => { updateFormState('orgRoles', value) }}
                roleGroupId={roleGroupId} />
            }
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12 usas-extra-top-margin usas-extra-bottom-margin">
            <PrimaryButton type="submit">{submitButtonText}</PrimaryButton>&nbsp;
            <SecondaryButton type="button" onClick={onSecondaryButtonClicked}>{secondaryButtonText}</SecondaryButton>
          </div>
        </div>
      </form>
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={isLoading} />
    </Fragment>
  );
};

export default ProviderStaffForm;