import { useEffect, useState } from 'react';

import { useExecuteQuery } from '@sisense/sdk-ui';

import * as DM from '../../../../usas-times-data';

import useEnvironmentVariableData from '../../../../common/state/environmentVariable/UseEnvironmentVariableData';

import Constants from '../../../../common/utils/Constants';

const useSisenseAgeGroup1Dropdown = () => {
    const queryProps = { dataSource: DM.DataSource, dimensions: [DM.Age.AgeGroup1] };
    const { data } = useExecuteQuery(queryProps);
    const [ageGroupOptions, setAgeGroup1Options] = useState({ options: [] });
    const { environmentVariableState, SISENSE_NOT_APPLICABLE_TEXT} = useEnvironmentVariableData();

    useEffect(() => {
        const defaultOptions = [{ id: Constants.DEFAULT_ID, name: '--' }];
        if (data && environmentVariableState.isLoaded === true) {
            const formattedOptions = [];
            formattedOptions.push(defaultOptions[0]);
            data.rows.map((row) => {
                if (row[0].data !== 0 && row[0].text !== SISENSE_NOT_APPLICABLE_TEXT) {
                    return (formattedOptions.push({ id: row[0].data, name: row[0].text, children: [] }))
                }
            });
            setAgeGroup1Options({ ...ageGroupOptions, options: formattedOptions });
        }
        else {
            setAgeGroup1Options({ ...ageGroupOptions, options: defaultOptions });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, environmentVariableState])

    return {
        data,
        ageGroupOptions
    };
};

export default useSisenseAgeGroup1Dropdown;