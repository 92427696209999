import { } from '../../../../common/utils/validation';

export const localValidate = (formState) => {
  let errors = {};

  if (formState.offeringInstanceId === '') {
    errors.offeringInstanceId = "Club Type is required"
  }
  return errors;
};

const ClubRegistrationTypeValidation = async (formState) => {
  let errors = localValidate(formState);

  return errors ? errors : {};
}

export default ClubRegistrationTypeValidation;