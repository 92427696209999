import React, { Fragment } from 'react';

const MemberAdministrator = () => {
  return (
    <Fragment>
      <div className="row usas-extra-bottom-margin">
        <div className="col-xs-12">
          <p>Member Administration Landing Page</p>
        </div>
      </div>
    </Fragment>
  );
};

export default MemberAdministrator;