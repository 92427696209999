import global from '../../../../../common/components/GlobalStyle.module.css';

const ClubBulkRenewSelectedReadOnlySmallGrid = ({ gridData, isLoading }) => (
  <div className={[global.SmallTable, 'hidden-md hidden-lg'].join(' ')}>
    {isLoading === true
      ? (
        <div className={global.SmallTableRow}>
          <div className={global.SmallTableRowHead}>
            &nbsp;
          </div>
          <div className={global.SmallTableRowInfo}>
            <div className={global.SmallTableRowLabels}>
              Loading...
            </div>
          </div>
        </div>
      ) : gridData.length > 0
        ? gridData.map((membership) => (
          <div className={global.SmallTableRow} key={'personId' + membership.personId + 'offeringInstanceId' + membership.offeringInstanceId}>
            <div className={global.SmallTableRowHead}><span className='hidden-xs'>{(membership.firstName || membership.middleName || membership.lastName) ? `${membership.firstName || ''} ${membership.middleName ? membership.middleName[0] + '.' : ''} ${membership.lastName || ''}` : <span>&nbsp;</span>}</span>&nbsp;
            </div>
            <div className={global.SmallTableRowInfo}>
              <div className='row'>
                <div className={[global.SmallTableRowLabels, 'col-xs-6 hidden-xs'].join(' ')}>Renewal Membership</div>
                <div className={[global.SmallTableRowData, 'col-xs-6 hidden-xs'].join(' ')}>{membership.renewalOfferingName}</div>
                <div className={[global.SmallTableRowLabels, 'col-xs-6 visible-xs'].join(' ')}>Name</div>
                <div className={[global.SmallTableRowData, 'col-xs-6 visible-xs'].join(' ')}>{(membership.firstName || membership.middleName || membership.lastName) ? `${membership.firstName || ''} ${membership.middleName ? membership.middleName[0] + '.' : ''} ${membership.lastName || ''}` : <span>&nbsp;</span>}</div>
                <div className={[global.SmallTableRowLabels, 'col-xs-6 visible-xs'].join(' ')}>Renewal Mem.</div>
                <div className={[global.SmallTableRowData, 'col-xs-6 visible-xs'].join(' ')}>{membership.renewalOfferingName}</div>
              </div>
            </div>
          </div>
        )) : (
          <div className={global.SmallTableRow}>
            <div className={global.SmallTableRowHead}>
              &nbsp;
            </div>
            <div className={global.SmallTableRowInfo}>
              <div className={global.SmallTableRowLabels}>No Selected Memberships</div>
            </div>
          </div>
        )
    }
  </div >
);

export default ClubBulkRenewSelectedReadOnlySmallGrid;