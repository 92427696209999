import React from 'react';
import OrgUnitCombobox from '../../../../../common/components/comboboxes/orgUnitCombobox/OrgUnitCombobox';
import DatePicker from '../../../../../common/components/datepickers/DatePicker';

import ReportPeriodDropdown from '../../../../../common/components/dropdowns/reportPeriodDropdown/ReportPeriodDropdown';

import useLSCRegistrationManagementForm from './UseLSCRegistrationManagementForm';

import global from '../../../../../common/components/GlobalStyle.module.css';

const LSCRegistrationManagementForm = ({ onSubmitFormCallback, organizationId = -1, lscId = -1 }) => {
  const {
    formState,
    errorState,
    onFormValueChanged,
    onValueTextPairChanged,
    handleSubmit
  } = useLSCRegistrationManagementForm(onSubmitFormCallback);

  return (
    <form onSubmit={handleSubmit} noValidate>
      <div className="row usas-extra-top-margin">
        <div className="col-xs-12 col-sm-6 col-md-4">
          {organizationId > 0 && (
            <ReportPeriodDropdown
              label="Registration Year*"
              name="reportPeriodId"
              value={formState.reportPeriodId}
              error={errorState.reportPeriodId}
              message={errorState.reportPeriodId}
              onChange={(newValue, newValueLabel) =>
                onValueTextPairChanged(newValue, 'reportPeriodId', newValueLabel, 'reportPeriodName')}
              organizationId={organizationId} />
          )}
        </div>
        <div className="col-xs-12 col-sm-6 col-md-4">
          <DatePicker
            label={"Registration Start Date*"}
            name={"registrationStartDate"}
            value={formState.registrationStartDate}
            error={errorState.registrationStartDate}
            message={errorState.registrationStartDate}
            startYearOffset={-140}
            onChange={(value) => onFormValueChanged('registrationStartDate', value)} />
        </div>
        <div className="col-xs-12 col-sm-6 col-md-4">
          <DatePicker
            label={"Registration End Date*"}
            name={"registrationEndDate"}
            value={formState.registrationEndDate}
            error={errorState.registrationEndDate}
            message={errorState.registrationEndDate}
            startYearOffset={-140}
            onChange={(value) => onFormValueChanged('registrationEndDate', value)} />
        </div>
        <div className="col-xs-12 col-sm-6 col-md-4">
          {organizationId > 0 && lscId && (
            <OrgUnitCombobox
              label={'Club Name (Club Code)'}
              name="orgUnitName"
              valueToMatch={formState.orgUnitName}
              error={errorState.orgUnitId}
              message={errorState.orgUnitId}
              onChange={(newValue, newValueLabel) => {
                onValueTextPairChanged(newValue, 'orgUnitId', newValueLabel, 'orgUnitName');
              }}
              organizationId={organizationId}
              lscId={lscId} />
          )}
        </div>
        <div className="col-xs-12 col-sm-6 col-md-4">
          <button className={global.PlainButton} type="submit">Filter</button>
        </div>
      </div>
    </form>
  );
};

export default LSCRegistrationManagementForm;