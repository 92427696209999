import { useEffect, useState } from 'react';
import { useNavigate, useLocation } from '../../../../common/wrappers/ReactRouterDom';

import NavLinks from '../utils/NavLinks';

import useLearnToSwimData from '../../../state/learnToSwim/UseLearnToSwimData';
import useLearnToSwimFacilitiesData from '../../../state/learnToSwimFacilities/UseLearnToSwimFacilitiesData';

import useFacilityData from '../../../../common/state/facility/UseFacilityData';

import Constants from '../../../../common/utils/Constants';

const INITIAL_DELETE_MODAL_STATE = {
  displayPopUp: false,
  orgUnitFacilityId: Constants.DEFAULT_ID,
  facilityName: '',
  facilityCity: '',
  facilityStateCode: '',
  facilityPostalCode: '',
};

const INITIAL_DELETE_POOL_MODAL_STATE = {
  displayPopup: false,
  facilityPoolId: Constants.DEFAULT_ID,
  poolName: '',
  poolDimensions: ''
};

const useLearnToSwimFacilities = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { ltsProviderState } = useLearnToSwimData();
  const { ltsFacilitiesState, ltsFacilityState, getLTSFacilities, deleteLTSFacility } = useLearnToSwimFacilitiesData();
  const { facilityState, confirmSave, deleteFacilityPool } = useFacilityData();
  const [deleteModalState, setDeleteModalState] = useState(INITIAL_DELETE_MODAL_STATE);
  const [deletePoolModalState, setDeletePoolModalState] = useState(INITIAL_DELETE_POOL_MODAL_STATE);

  const onAddFacilityClicked = () => {
    navigate(NavLinks.LEARN_TO_SWIM_FACILITIES_SEARCH);
  };

  const onEditFacilityClicked = (facilityId) => {
    navigate(NavLinks.LEARN_TO_SWIM_FACILITIES_DETAIL, { state: { facilityId } });
  };

  const onAddPoolClicked = (facilityId) => {
    navigate(NavLinks.LEARN_TO_SWIM_FACILITIES_POOLS_DETAIL, { state: { facilityId } });
  };

  const onEditPoolClicked = (facilityId, poolId) => {
    navigate(NavLinks.LEARN_TO_SWIM_FACILITIES_POOLS_DETAIL, { state: { facilityId, poolId } });
  };

  const onDeleteFacilityClicked = (facilityId) => {
    const facilities = ltsFacilitiesState.arrayData;
    const orgUnitFacility = facilities.find((x) => x.facilityId === facilityId);

    if (orgUnitFacility) {
      setDeleteModalState({
        ...deleteModalState,
        displayPopUp: true,
        orgUnitFacilityId: orgUnitFacility.orgUnitFacilityId,
        facilityName: orgUnitFacility.facility?.facilityName || '',
        facilityCity: orgUnitFacility.facility?.city || '',
        facilityStateCode: orgUnitFacility.facility?.stateCode || '',
        facilityPostalCode: orgUnitFacility.facility?.postalCode || ''
      });
    }
  };

  const onDeletePoolClicked = (pool) => {
    setDeletePoolModalState({
      ...deletePoolModalState,
      displayPopup: true,
      facilityPoolId: pool.facilityPoolId,
      poolName: pool.facilityPoolName || '',
      poolDimensions: pool.poolDimensions?.dimensionName || ''
    });
  };

  const onDeleteModalDeleteClicked = () => {
    const orgUnitId = ltsProviderState.objData?.orgUnitId;

    if (orgUnitId) {
      const deleteLTSFacilityPromise = deleteLTSFacility(orgUnitId, deleteModalState.orgUnitFacilityId);

      setDeleteModalState({ ...INITIAL_DELETE_MODAL_STATE });

      if (deleteLTSFacilityPromise !== null) {
        deleteLTSFacilityPromise.then((newState) => {
          const orgUnitId = ltsProviderState.objData?.orgUnitId;

          if (orgUnitId) {
            const getLTSFacilitiesPromise = getLTSFacilities(orgUnitId);

            if (getLTSFacilitiesPromise !== null) {
              getLTSFacilitiesPromise.catch((e) => {
                //TODO context error
              });
            }
          }
        }).catch((e) => {
          //TODO context error
        });
      }
    }
  };

  const onDeleteModalCancelClicked = () => {
    setDeleteModalState({ ...INITIAL_DELETE_MODAL_STATE });
  };

  const onDeletePoolModalDeleteClicked = () => {
    deleteFacilityPool(deletePoolModalState.facilityPoolId);
    setDeletePoolModalState({ ...INITIAL_DELETE_POOL_MODAL_STATE });
  };

  const onDeletePoolModalCancelClicked = () => {
    setDeletePoolModalState({ ...INITIAL_DELETE_POOL_MODAL_STATE });
  };

  useEffect(() => {
    if (ltsFacilitiesState.isArrayLoaded !== true || location.state?.refreshState || location.state?.addedPool) {
      const orgUnitId = ltsProviderState.objData?.orgUnitId;

      if (orgUnitId) {
        const getLTSFacilitiesPromise = getLTSFacilities(orgUnitId);

        if (getLTSFacilitiesPromise !== null) {
          getLTSFacilitiesPromise.catch((e) => {
            //TODO context error
          });
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (facilityState.isSaved === true && facilityState.isSaving !== true) {
      const orgUnitId = ltsProviderState.objData?.orgUnitId;

      confirmSave();

      if (orgUnitId) {
        const getLTSFacilitiesPromise = getLTSFacilities(orgUnitId);

        if (getLTSFacilitiesPromise !== null) {
          getLTSFacilitiesPromise.catch((e) => {
            //TODO context error
          });
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [facilityState]);

  return {
    isLoading: ltsFacilitiesState.isArrayLoading,
    isSaving: ltsFacilityState.isObjLoading || facilityState.isSaving,
    deleteModalState,
    deletePoolModalState,
    ltsFacilitiesState,
    onAddFacilityClicked,
    onEditFacilityClicked,
    onAddPoolClicked,
    onEditPoolClicked,
    onDeleteFacilityClicked,
    onDeletePoolClicked,
    onDeleteModalDeleteClicked,
    onDeleteModalCancelClicked,
    onDeletePoolModalDeleteClicked,
    onDeletePoolModalCancelClicked
  };
};

export default useLearnToSwimFacilities;