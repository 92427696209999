import { API_NAMES, initApi } from "../../../common/utils/HttpApiHelper";

const getLTSCurriculumData = (state, setState) => {
  const api = initApi(API_NAMES.COMMON, state, setState);
  const url = `/LearnToSwimCurriculum`;

  return api?.executeArray ? api.executeArray(url, 'GET') : null;
};

const LearnToSwimCurriculumData = {
  getLTSCurriculumData
};

export default LearnToSwimCurriculumData;