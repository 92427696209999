import { useEffect, useState } from 'react';
import { useNavigate, useLocation } from '../../../../common/wrappers/ReactRouterDom';

import NavLinks from '../NavLinks';

import validate from '../components/forms/SATResultFormValidation';

import { createSATResultObj } from '../utils/SATUtils';

import useSwimAThonResultData from '../../../state/swimAThon/swimAThonResult/UseSwimAThonResultData';

import useEnvironmentVariableData from '../../../../common/state/environmentVariable/UseEnvironmentVariableData';

import useForm from '../../../../common/utils/UseForm';
import Constants from '../../../../common/utils/Constants';
import { formatDate } from "../../../../common/utils/DateFunctions";

const useClubSwimAThonResultDetail = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { environmentVariableState, SAT_SAVED_STATUS_ID } = useEnvironmentVariableData();
  const { swimAThonResultState, updateResultObj } = useSwimAThonResultData();
  const { formState, errorState, updateFormState, handleSubmit, setFormData
  } = useForm(getFormInitialState, submitFormCallback, validate);
  const [uiState, setUiState] = useState({ tryContinue: false });

  const onCancelClicked = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    navigate(NavLinks.CLUB_SWIMATHON_RESULTS, { state: { performGet: false } });
  };

  useEffect(() => {
    if (uiState.tryContinue === true) {
      navigate(NavLinks.CLUB_SWIMATHON_RESULTS_DETAIL_PRIZEWINNERS);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uiState.tryContinue]);

  useEffect(() => {
    if (swimAThonResultState.isObjLoaded === true) {
      setFormData({
        ...formState,
        fundraisingGoal: swimAThonResultState.satContract.fundraisingGoal || 0,
        satDate: swimAThonResultState.satContract.satDate ? formatDate(swimAThonResultState.satContract.satDate) : Constants.BLANK_DATE_STRING,
        numberOfParticipatingSwimmers: swimAThonResultState.satContract.numberOfAthletes || 0,
        totalAmountCollected: swimAThonResultState.objData.totalAmountCollected || 0,
        bagTags100Length: swimAThonResultState.objData.bagTags100Length || 0,
        bagTags200Length: swimAThonResultState.objData.bagTags200Length || 0,
      }, true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [swimAThonResultState.isObjLoaded]);

  useEffect(() => {
    if (location.state?.satContract) {
      const selectedContractResult = location.state.satContract?.satResult[0];

      updateResultObj(selectedContractResult, location.state.satContract);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function submitFormCallback(formState) {
    const newResultObj = createSATResultObj(formState, SAT_SAVED_STATUS_ID, swimAThonResultState.satContract?.satContractId, swimAThonResultState.objData);

    updateResultObj(newResultObj);
    setUiState({ ...uiState, tryContinue: true });
  };

  function getFormInitialState() {
    return {
      fundraisingGoal: '',
      totalAmountCollected: '',
      numberOfParticipatingSwimmers: '',
      bagTags100Length: '',
      bagTags200Length: '',
    };
  };

  return {
    isSubmitted: (swimAThonResultState.objData.resultStatusId === SAT_SAVED_STATUS_ID || swimAThonResultState.objData.resultStatusId === undefined) ? false : true,
    isLoading: environmentVariableState.isLoading,
    swimAThonResultState,
    formState,
    errorState,
    onFormValueChanged: updateFormState,
    handleSubmit,
    onCancelClicked
  };
};

export default useClubSwimAThonResultDetail;