import { useEffect, useState } from 'react';
import { useNavigate } from '../../../../common/wrappers/ReactRouterDom';

import NavLinks from '../NavLinks';

import useBaseUsasClubData from '../../../../common/state/clubBase/UseBaseUsasClubData';
import useClubTouchpointData from '../../../state/clubTouchpoints/UseClubTouchpointData';

const useClubTouchpoints = () => {
  const navigate = useNavigate();
  const { baseUsasClubState } = useBaseUsasClubData();
  const { clubTouchpointState, getClubTouchpoints, deleteClubTouchpoint } = useClubTouchpointData();
  const [ uiState, setUiState ] = useState({hasBeenDeleted: false});

  const onAdd = (e) => {
    navigate(NavLinks.CLUB_TOUCHPOINTS_DETAILS);
  }
  
  const onEdit = (e, usasClubTouchpointId, touchpointNote, touchpointDate, touchpointTypeId) => {
    if (baseUsasClubState.isObjLoaded === true) {
      navigate(NavLinks.CLUB_TOUCHPOINTS_DETAILS, 
        { state: { 
          usasClubTouchpointId: usasClubTouchpointId, 
          touchpointNote: touchpointNote, 
          touchpointDate: touchpointDate,
          touchpointTypeId: touchpointTypeId } 
        });
    }
  }

  const onDelete = (e, usasClubTouchpointId) => {
    if (clubTouchpointState.isArrayLoaded === true) {
      deleteClubTouchpoint(usasClubTouchpointId);
      setUiState({hasBeenDeleted: true});
    }
  }

  useEffect(() => {
    if (uiState.hasBeenDeleted === true && clubTouchpointState.isSaving === false && clubTouchpointState.isArrayLoading === false) {
      setUiState({hasBeenDeleted: false});
      getClubTouchpoints(baseUsasClubState.objData.usasClubId);
    }
  }, [uiState, clubTouchpointState]);

  useEffect(() => {
    if (baseUsasClubState.isObjLoaded === true) {
      getClubTouchpoints(baseUsasClubState.objData.usasClubId);
    }
  }, []);

  return {
    baseUsasClubState,
    clubTouchpointState,
    onAdd,
    onEdit,
    onDelete
  }
};

export default useClubTouchpoints;