import { useEffect } from 'react';
import { useNavigate } from '../../../../common/wrappers/ReactRouterDom';

import NavLinks from '../NavLinks';

import useSwimAThonData from '../../../state/swimAThon/UseSwimAThonData';
import useSwimAThonResultData from '../../../state/swimAThon/swimAThonResult/UseSwimAThonResultData';

const useClubSwimAThonResult = () => {
  const navigate = useNavigate();
  const { satState } = useSwimAThonData();
  const { clearObjData } = useSwimAThonResultData();

  const onEditResult = (e, satContract) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    navigate(NavLinks.CLUB_SWIMATHON_RESULTS_DETAIL, { state: { satContract } });
  };

  const onPaymentsClicked = () => {
    navigate(NavLinks.CLUB_SWIMATHON_RESULTS_PAYMENTS);
  };

  useEffect(() => {
    clearObjData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    satState,
    onEditResult,
    onPaymentsClicked
  };
};

export default useClubSwimAThonResult;