import { useContext } from 'react';

import { TeamFacilityLinksStateContext } from './TeamFacilityLinksContextProvider';

import TeamFacilityLinksData from './TeamFacilityLinksData';

const useTeamFacilityLinksData = () => {
  const [teamFacilityLinksState, setTeamFacilityLinksState] = useContext(TeamFacilityLinksStateContext);

  const getTeamFacilityLinks = (orgUnitId) => TeamFacilityLinksData.getTeamFacilityLinksData(orgUnitId, teamFacilityLinksState, setTeamFacilityLinksState);
  const postTeamFacilityLinks = (orgUnitId, teamObj) => TeamFacilityLinksData.postTeamFacilityLinksData(orgUnitId, teamObj, teamFacilityLinksState, setTeamFacilityLinksState);
  const putTeamFacilityLinks = (orgUnitId, orgUnitFacilityId, teamObj) => TeamFacilityLinksData.putTeamFacilityLinksData(orgUnitId, orgUnitFacilityId, teamObj, teamFacilityLinksState, setTeamFacilityLinksState);
  const deleteTeamFacilityLinks = (orgUnitId, orgUnitFacilityId) => TeamFacilityLinksData.deleteTeamFacilityLinksData(orgUnitId, orgUnitFacilityId, teamFacilityLinksState, setTeamFacilityLinksState);

  const clearTeamacilityLinksObjData = () => {
    setTeamFacilityLinksState({
      ...teamFacilityLinksState,
      isObjLoading: false,
      isObjLoaded: false,
      isSaving: false,
      isSaved: false,
      objData: {},
      message: ''
    });
  };

  const clearTeamFacilityLinksArrayData = () => {
    setTeamFacilityLinksState({
      ...teamFacilityLinksState,
      isArrayLoading: false,
      isArrayLoaded: false,
      isSaving: false,
      isSaved: false,
      arrayData: [],
      message: ''
    });
  };

  const confirmTeamFacilityLinksSave = () => {
    setTeamFacilityLinksState({
      ...teamFacilityLinksState,
      isSaved: false
    });
  };

  return {
    teamFacilityLinksState,
    setTeamFacilityLinksState,
    clearTeamacilityLinksObjData,
    clearTeamFacilityLinksArrayData,
    confirmTeamFacilityLinksSave,
    getTeamFacilityLinks,
    postTeamFacilityLinks,
    putTeamFacilityLinks,
    deleteTeamFacilityLinks
  };
};

export default useTeamFacilityLinksData;