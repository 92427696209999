import React, { createContext, useState } from 'react';

import OfferingTypeData from './OfferingTypeData';

export const OfferingTypesStateContext = createContext();

const OfferingTypesContextProvider = ({ children }) => {
  const stateHook = useState(OfferingTypeData.INITIAL_STATE);

  return (
    <OfferingTypesStateContext.Provider value={stateHook}>
      {children}
    </OfferingTypesStateContext.Provider>
  );
};

export default OfferingTypesContextProvider;