import React, { createContext, useState } from 'react';

import PreCompLocationRegistrationData from './PreCompLocationRegistrationData';

export const PreCompLocationRegistrationStateContext = createContext();

const PreCompLocationRegistrationContextProvider = ({ children }) => {
  const stateHook = useState(PreCompLocationRegistrationData.INITIAL_STATE);

  return (
    <PreCompLocationRegistrationStateContext.Provider value={stateHook}>
      {children}
    </PreCompLocationRegistrationStateContext.Provider>
  );
};

export default PreCompLocationRegistrationContextProvider;