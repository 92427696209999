import usePreCompBusinessEntityTypeData from '../../../../state/preCompBusinessEntityType/UsePreCompBusinessEntityTypeData';

const useBusinessEntityDropdown = () => {
  const { preCompBusinessEntityTypeState } = usePreCompBusinessEntityTypeData();

  return {
    preCompBusinessEntityTypeState
  };
};

export default useBusinessEntityDropdown;