import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { Navigate } from '../../../common/wrappers/ReactRouterDom';

//old - will remove eventually
import ClubRegistrationContextProvider from '../../state/clubRegistration/ClubRegistrationContextProvider';

//new - should replace old one above
import ClubRegistrationNewContextProvider from '../../state/clubRegistrationNew/ClubRegistrationNewContextProvider';

import ClubRegistrationNavContextProvider from '../../state/clubRegistration/leftNavigation/ClubRegistrationNavContextProvider';
import ClubRegistrationPaymentContextProvider from '../../state/clubRegistration/payment/ClubRegistrationPaymentContextProvider';
import UsasClubStaffContextProvider from '../../state/clubStaff/UsasClubStaffContextProvider';
import UsasClubFacilityLinksContextProvider from '../../state/clubFacilityLinks/UsasClubFacillityLinksContextProvider';
import UsasClubPreCompLinksContextProvider from '../../state/clubPreCompLinks/UsasClubPreCompLinksContextProvider';
import PreCompLocationContextProvider from '../../state/preCompLocation/PreCompLocationContextProvider';

import OfferingTypesContextProvider from '../../../common/state/offeringTypes/OfferingTypesContextProvider';
import MemberRegistrationHistoryContextProvider from '../../../common/state/offeringTypes/MemberRegistrationHistoryContextProvider';
import PreCompOrganizationContextProvider from '../../../common/state/preCompOrganization/PreCompOrganizationContextProvider';
import PreCompSearchFilterContextProvider from '../../../common/state/preCompOrganization/PreCompSearchFilterContextProvider';
import SearchPreCompContextProvider from '../../../common/state/searchPreComp/SearchPreCompContextProvider';
import FacilityContextProvider from '../../../common/state/facility/FacilityContextProvider';
import FacilityFiltersContextProvider from '../../../common/state/facility/FacilityFiltersContextProvider';
import SearchFacilityContextProvider from '../../../common/state/searchFacility/SearchFacilityContextProvider';
import LeftNavModalContextProvider from '../../../common/state/leftNavModal/LeftNavModalContextProvider';

//import ClubRegistrationEntry from './clubRegistrationEntry/ClubRegistrationEntry';
import ClubRegistrationEntryNew from './clubRegistrationEntry/ClubRegistrationEntryNew';
import ClubRegistrationDisclaimer from './clubRegistrationDisclaimer/ClubRegistrationDisclaimer';
//import ClubRegistrationNewClubSearch from './clubRegistrationNewClubSearch/ClubRegistrationNewClubSearch';
//import ClubRegistrationExistingClubRenewal from './clubRegistrationExistingClubRenewal/ClubRegistrationExistingClubRenewal';
//import ClubRegistrationExistingClubSearch from './clubRegistrationExistingClubSearch/ClubRegistrationExistingClubSearch';
import ClubRegistrationInfo from './clubRegistrationInfo/ClubRegistrationInfo';
import ClubRegistrationStaff from './clubRegistrationStaff/ClubRegistrationStaff';
import ClubRegistrationStaffDetail from './clubRegistrationStaff/ClubRegistrationStaffDetail';
import ClubRegistrationType from './clubRegistrationType/ClubRegistrationType';
import ClubRegistrationLegalEntities from './clubRegistrationLegalEntities/ClubRegistrationLegalEntities';
import ClubRegistrationLegalEntitiesDetail from './clubRegistrationLegalEntities/ClubRegistrationLegalEntitiesDetail';
import ClubRegistrationAffiliations from './clubRegistrationAffiliations/ClubRegistrationAffiliations';
import ClubRegistrationAffiliationsDetail from './clubRegistrationAffiliations/ClubRegistrationAffiliationsDetail';
import ClubRegistrationScreeningPolicies from './clubRegistrationScreeningPolicies/ClubRegistrationScreeningPolicies';
//import ClubRegistrationPreCompetitivePrograms from './clubRegistrationPreCompetitivePrograms/ClubRegistrationPreCompetitivePrograms';
//import ClubRegistrationPreCompetitiveProgramsSearch from './clubRegistrationPreCompetitivePrograms/ClubRegistrationPreCompetitiveProgramsSearch';
import ClubRegistrationFacilities from './clubRegistrationFacilities/ClubRegistrationFacilities';
import ClubRegistrationFacilityDetails from './clubRegistrationFacilities/ClubRegistrationFacilityDetails';
import ClubRegistrationFacilitySearch from './clubRegistrationFacilities/ClubRegistrationFacilitySearch';
import ClubRegistrationFacilityAdd from './clubRegistrationFacilities/ClubRegistrationFacilityAdd';
import ClubRegistrationFacilityPoolDetail from './clubRegistrationFacilities/ClubRegistrationFacilityPoolDetail';
import ClubRegistrationMissionStatementBudget from './clubRegistrationMissionStatementBudget/ClubRegistrationMissionStatementBudget';
import ClubRegistrationEmergencyPlan from './clubRegistrationEmergencyPlan/ClubRegistrationEmergencyPlan';
import ClubRegistrationSubmit from './clubRegistrationSubmit/ClubRegistrationSubmit';

import PageNotFound from '../../../common/components/PageNotFound';
import SecuredRouteElement from '../../../common/components/security/SecuredRouteElement';
import useNavRoutes from '../../../common/state/security/UseNavRoutes';
import LoadingModal from '../../../common/components/dialogs/LoadingModal';
import OrgUnitSelection from './orgUnitSelection/OrgUnitSelection';
import SelectOrgUnitContextProvider from '../../../common/state/selectOrgUnit/SelectOrgUnitContextProvider';
import PersonOrgRoleContextProvider from '../../../common/state/personOrgRole/PersonOrgRoleContextProvider';
import BaseUsasClubContextProvider from '../../../common/state/clubBase/BaseUsasClubContextProvider';
import BaseUsasClubFiltersContextProvider from '../../../common/state/clubBase/BaseUsasClubFiltersContextProvider';
import LegalEntityTypeContextProvider from '../../state/legalEntity/LegalEntityTypeContextProvider';

const ClubRegistrationRoutes = () => {
  const { navRoutes, isReadyToRoute } = useNavRoutes();

  if (isReadyToRoute !== true) {
    return <LoadingModal />;
  }

  return (
    <Routes>
      <Route path={navRoutes['CLUB_REGISTRATION_SELECTION']?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes['CLUB_REGISTRATION_SELECTION']}
            editElement={<OrgUnitSelection />} />
        } />
      <Route path={navRoutes['CLUB_REGISTRATION_ENTRY_NEW']?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes['CLUB_REGISTRATION_ENTRY_NEW']}
            editElement={<ClubRegistrationEntryNew />} />
        } />
      <Route path={navRoutes['CLUB_REGISTRATION_DISCLAIMER']?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes['CLUB_REGISTRATION_DISCLAIMER']}
            editElement={<ClubRegistrationDisclaimer />} />
        } />
      <Route path={navRoutes['CLUB_REGISTRATION_INFO']?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes['CLUB_REGISTRATION_INFO']}
            editElement={<ClubRegistrationInfo />} />
        } />
      <Route path={navRoutes['CLUB_REGISTRATION_STAFF']?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes['CLUB_REGISTRATION_STAFF']}
            editElement={<ClubRegistrationStaff />} />
        } />
      <Route path={navRoutes['CLUB_REGISTRATION_STAFF_DETAIL']?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes['CLUB_REGISTRATION_STAFF_DETAIL']}
            editElement={<ClubRegistrationStaffDetail />} />
        } />
      <Route path={navRoutes['CLUB_REGISTRATION_TYPE']?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes['CLUB_REGISTRATION_TYPE']}
            editElement={<ClubRegistrationType />} />
        } />
      <Route path={navRoutes['CLUB_REGISTRATION_LEGAL_ENTITY']?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes['CLUB_REGISTRATION_LEGAL_ENTITY']}
            editElement={<ClubRegistrationLegalEntities />} />
        } />
      <Route path={navRoutes['CLUB_REGISTRATION_LEGAL_ENTITY_DETAIL']?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes['CLUB_REGISTRATION_LEGAL_ENTITY_DETAIL']}
            editElement={<ClubRegistrationLegalEntitiesDetail />} />
        } />
      <Route path={navRoutes['CLUB_REGISTRATION_AFFILIATION']?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes['CLUB_REGISTRATION_AFFILIATION']}
            editElement={<ClubRegistrationAffiliations />} />
        } />
      <Route path={navRoutes['CLUB_REGISTRATION_AFFILIATION_DETAIL']?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes['CLUB_REGISTRATION_AFFILIATION_DETAIL']}
            editElement={<ClubRegistrationAffiliationsDetail />} />
        } />
      <Route path={navRoutes['CLUB_REGISTRATION_SCREENING_POLICIES']?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes['CLUB_REGISTRATION_SCREENING_POLICIES']}
            editElement={<ClubRegistrationScreeningPolicies />} />
        } />
      <Route path={navRoutes['CLUB_REGISTRATION_FACILITY']?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes['CLUB_REGISTRATION_FACILITY']}
            editElement={<ClubRegistrationFacilities />} />
        } />
      <Route path={navRoutes['CLUB_REGISTRATION_FACILITY_SEARCH']?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes['CLUB_REGISTRATION_FACILITY_SEARCH']}
            editElement={<ClubRegistrationFacilitySearch />} />
        } />
      <Route path={navRoutes['CLUB_REGISTRATION_FACILITY_ADD']?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes['CLUB_REGISTRATION_FACILITY_ADD']}
            editElement={<ClubRegistrationFacilityAdd />} />
        } />
      <Route path={navRoutes['CLUB_REGISTRATION_FACILITY_DETAILS']?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes['CLUB_REGISTRATION_FACILITY_DETAILS']}
            editElement={<ClubRegistrationFacilityDetails />} />
        } />
      <Route path={navRoutes['CLUB_REGISTRATION_FACILITY_POOL_DETAIL']?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes['CLUB_REGISTRATION_FACILITY_POOL_DETAIL']}
            editElement={<ClubRegistrationFacilityPoolDetail />} />
        } />
      <Route path={navRoutes['CLUB_REGISTRATION_MISSION_STATEMENT_BUDGET']?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes['CLUB_REGISTRATION_MISSION_STATEMENT_BUDGET']}
            editElement={<ClubRegistrationMissionStatementBudget />} />
        } />
      <Route path={navRoutes['CLUB_REGISTRATION_EMERGENCY_PLAN']?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes['CLUB_REGISTRATION_EMERGENCY_PLAN']}
            editElement={<ClubRegistrationEmergencyPlan />} />
        } />
      <Route path={navRoutes['CLUB_REGISTRATION_SUBMIT']?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes['CLUB_REGISTRATION_SUBMIT']}
            editElement={<ClubRegistrationSubmit />} />
        } />

      <Route path={'/'} element={<Navigate to={navRoutes['CLUB_REGISTRATION_ENTRY_NEW']?.path} replace />} />
      <Route path={'/*'} element={<PageNotFound />} />
    </Routes>
  );
};

const ClubRegistration = () => (
  <BaseUsasClubContextProvider>
    <BaseUsasClubFiltersContextProvider>
      <SelectOrgUnitContextProvider>
        <PersonOrgRoleContextProvider>
          <LeftNavModalContextProvider>
            <ClubRegistrationContextProvider>
              <ClubRegistrationNewContextProvider>
                <ClubRegistrationPaymentContextProvider>
                  <UsasClubStaffContextProvider>
                    <UsasClubFacilityLinksContextProvider>
                      <UsasClubPreCompLinksContextProvider>
                        <OfferingTypesContextProvider>
                          <MemberRegistrationHistoryContextProvider>
                            <LegalEntityTypeContextProvider>
                              <PreCompOrganizationContextProvider>
                                <PreCompLocationContextProvider>
                                  <PreCompSearchFilterContextProvider>
                                    <SearchPreCompContextProvider>
                                      <FacilityContextProvider>
                                        <FacilityFiltersContextProvider>
                                          <SearchFacilityContextProvider>
                                            <ClubRegistrationNavContextProvider>
                                              <ClubRegistrationRoutes />
                                            </ClubRegistrationNavContextProvider>
                                          </SearchFacilityContextProvider>
                                        </FacilityFiltersContextProvider>
                                      </FacilityContextProvider>
                                    </SearchPreCompContextProvider>
                                  </PreCompSearchFilterContextProvider>
                                </PreCompLocationContextProvider>
                              </PreCompOrganizationContextProvider>
                            </LegalEntityTypeContextProvider>
                          </MemberRegistrationHistoryContextProvider>
                        </OfferingTypesContextProvider>
                      </UsasClubPreCompLinksContextProvider>
                    </UsasClubFacilityLinksContextProvider>
                  </UsasClubStaffContextProvider>
                </ClubRegistrationPaymentContextProvider>
              </ClubRegistrationNewContextProvider>
            </ClubRegistrationContextProvider>
          </LeftNavModalContextProvider>
        </PersonOrgRoleContextProvider>
      </SelectOrgUnitContextProvider>
    </BaseUsasClubFiltersContextProvider>
  </BaseUsasClubContextProvider>
);

export default ClubRegistration;