import React, { Fragment } from 'react';
import global from '../../../../common/components/GlobalStyle.module.css';

import useActivateVendor from './UseClubActivateVendor';
import SmallClubActivateVendorGrid from './SmallClubActivateVendorGrid';
import LargeClubActivateVendorGrid from './LargeClubActivateVendorGrid';

import Headings from '../../../../common/components/headings/Headings';
import PopUpModal from '../../../../common/components/dialogs/PopUpModal';
import PrimaryButton from '../../../../common/components/buttons/PrimaryButton';
import SecondaryButton from '../../../../common/components/buttons/SecondaryButton';
import ActionIntraPageButton from '../../../../common/components/buttons/ActionIntraPageButton';
import Constants from '../../../../common/utils/Constants';
import { formatDate } from '../../../../common/utils/DateFunctions';

const ClubActivateVendor = () => {
  const {
    modalState,
    onModalCanceled,
    onDeleteClubVendor,
    onOpenDeleteVendorModalClicked,
    clubVendorState,
    onAddVendor,
    clubThirdPartyActivateVendorId
  } = useActivateVendor();

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>Club Activate Vendor</Headings.H3>
        </div>
        <div className="col-xs-12">
          <p><strong>Club Id to Activate Third Party Vendor : </strong>{clubThirdPartyActivateVendorId}</p>
        </div>
      </div>
      <div className='row'>
        <div className='col-xs-12 usas-extra-top-margin usas-extra-bottom-margin'>
          <ActionIntraPageButton onClick={onAddVendor}>Add Vendor</ActionIntraPageButton>
        </div>
      </div>
      <div className='row'>
        <div className='col-xs-12'>
          <LargeClubActivateVendorGrid state={clubVendorState} onOpenDeleteVendorModalClicked={onOpenDeleteVendorModalClicked} />
          <SmallClubActivateVendorGrid state={clubVendorState} onOpenDeleteVendorModalClicked={onOpenDeleteVendorModalClicked} />
        </div>
      </div>
      <PopUpModal
        widthPct={50}
        maxWidth={720}
        title="Delete Club Vendor?"
        onModalCanceled={onModalCanceled}
        displayPopUp={modalState.displayPopUp}>
        <div className="row">
          <div className="col-xs-12">
            <p className={['col-xs-12 text-center', global.Bold].join(' ')}>You are about to delete:</p>
            <p className={['col-xs-12 text-center', global.Bold].join(' ')}>Vendor: {modalState.vendorName}</p>
            <p className={['col-xs-12 text-center', global.Bold].join(' ')}>Effective Date: {formatDate(modalState.effectiveDate)}</p>
            <p className={['col-xs-12 text-center', global.Bold].join(' ')}>Are you sure you want to complete this action?  You cannot undo this action.</p>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12 usas-extra-top-margin text-center">
            <SecondaryButton type="button" onClick={onModalCanceled}>Cancel</SecondaryButton>&nbsp;
            <PrimaryButton type="button" onClick={(e) => onDeleteClubVendor(e, modalState?.orgUnitVendorId)}>Yes, Delete</PrimaryButton>
          </div>
        </div>
      </PopUpModal>
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={clubVendorState?.isArrayLoading} />
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.SAVING_MSG}
        displayPopUp={clubVendorState?.isSaving} />
    </Fragment>
  );
};

export default ClubActivateVendor;