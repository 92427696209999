import { useEffect, useState } from 'react';
import { useNavigate, useLocation } from '../../../../../common/wrappers/ReactRouterDom';

import validate from './EditLSCBoardMemberValidation';

import { navLinks } from '../../UseMemberAdministration';

import useEnvironmentVariableData from '../../../../../common/state/environmentVariable/UseEnvironmentVariableData';
import useMemberAdministrationLscBoardData from '../../../../../common/state/memberAdministration/lscBoard/UseMemberAdministrationLscBoardData';

import useForm from '../../../../../common/utils/UseForm';
import Constants from '../../../../../common/utils/Constants';
import { formatDate } from '../../../../../common/utils/DateFunctions';

const useEditLSCBoardMember = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { LSC_STAFF_ROLE_GROUP_ID } = useEnvironmentVariableData();
  const { memberAdministrationLscBoardState, putLscBoardMember } = useMemberAdministrationLscBoardData();
  const { formState, errorState, setFormData, onFormValueChanged, handleSubmit, setErrors, setIsDirty, isSubmitting
  } = useForm(getInitialFormState(), submitFormCallback, validate);
  const [viewState, setViewState] = useState(getInitialViewState);

  const onSaveClicked = () => {
    setIsDirty(true);
    handleSubmit();
  }

  const onBackClicked = () => {
    navigate(navLinks.LSC_BOARD);
  };

  useEffect(() => {
    if (location.state?.personId && location.state?.personOrgRoleDurationId > 0
      && location.state?.activeUserOrgUnitId) {
      const personId = location.state.personId;
      const personOrgRoleDurationId = location.state.personOrgRoleDurationId;
      const activeUserOrgUnitId = location.state.activeUserOrgUnitId;

      const targetPerson = memberAdministrationLscBoardState.arrayData.find(x => x.personId === personId);

      if (targetPerson && Array.isArray(targetPerson.orgRoles)) {
        const targetRole = targetPerson.orgRoles.find(x => x.personOrgRoleDurationId === personOrgRoleDurationId);

        if (targetRole) {
          setFormData({
            ...formState,
            effectiveDate: targetRole.effectiveDate ? formatDate(targetRole.effectiveDate) : '',
            expirationDate: targetRole.expirationDate ? formatDate(targetRole.expirationDate) : '',
            hasNoExpirationDate: targetRole.expirationDate ? formatDate(targetRole.expirationDate) === Constants.DEFAULT_MAX_DATE : false
          });
          setViewState({
            ...viewState,
            orgRoleName: targetRole.orgRoleName,
            orgRoleId: targetRole.orgRoleId,
            memberName: `${targetPerson.firstName} ${targetPerson.lastName}`,
            memberId: targetPerson.memberId || '',
            personId,
            personOrgRoleId: targetRole.personOrgRoleId,
            personOrgRoleDurationId,
            activeUserOrgUnitId
          });
        }
      }
    }
    else {
      onBackClicked();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (memberAdministrationLscBoardState.isSaved === true) {
      navigate(navLinks.LSC_BOARD);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [memberAdministrationLscBoardState.isSaved]);

  function submitFormCallback() {
    const existingPersonOrgRoles = getExistingPersonOrgRoles(viewState.personId);
    if (existingPersonOrgRoles) {
      if (isDurationValid(viewState.personOrgRoleId, viewState.personOrgRoleDurationId, formState.effectiveDate, formState.hasNoExpirationDate === false ? formState.expirationDate : Constants.DEFAULT_MAX_DATE, existingPersonOrgRoles)) {
        putLscBoardMember(viewState.activeUserOrgUnitId, viewState.personId, LSC_STAFF_ROLE_GROUP_ID, createStaffObject(formState, viewState, existingPersonOrgRoles));
      } else {
        const overlap = getOverlappingDates(viewState.personOrgRoleId, viewState.personOrgRoleDurationId, formState.effectiveDate, formState.hasNoExpirationDate === false ? formState.expirationDate : Constants.DEFAULT_MAX_DATE, existingPersonOrgRoles);
        setErrors({
          multipleActiveRoles: `This member has an existing ${viewState.orgRoleName} role from ${formatDate(overlap?.effectiveDate)} - ${formatDate(overlap?.expirationDate)}. Date overlaps are not allowed.`
        });
      }
    }
  };

  function getExistingPersonOrgRoles(personId) {
    const existingPerson = memberAdministrationLscBoardState.arrayData.find(x => x.personId === personId);

    if (existingPerson && Array.isArray(existingPerson.orgRoles)) {
      return existingPerson.orgRoles;
    } else {
      return null;
    }
  };

  function isDurationValid(personOrgRoleId, personOrgRoleDurationId, effectiveDate, expirationDate, orgRoles) {
    let overlappingDate = false;

    for (const role of orgRoles) {
      if (role.personOrgRoleId === personOrgRoleId && role.personOrgRoleDurationId !== personOrgRoleDurationId) {
        if (new Date(effectiveDate) <= new Date(role.expirationDate)
          && new Date(expirationDate) >= new Date(role.effectiveDate)) {
          overlappingDate = true;
          break;
        }
      }
    }

    return !overlappingDate;
  };

  function getOverlappingDates(personOrgRoleId, personOrgRoleDurationId, effectiveDate, expirationDate, orgRoles) {
    let overlappingDuration = {};

    for (const role of orgRoles) {
      if (role.personOrgRoleId === personOrgRoleId && role.personOrgRoleDurationId !== personOrgRoleDurationId) {
        if (new Date(effectiveDate) <= new Date(role.expirationDate)
          && new Date(expirationDate) >= new Date(role.effectiveDate)) {
          overlappingDuration = role;
          break;
        }
      }
    }

    return overlappingDuration;
  };

  function createStaffObject(formState, viewState, existingPersonOrgRoles) {
    const filteredPersonOrgRoles = [];

    if (Array.isArray(existingPersonOrgRoles)) {
      for (const role of existingPersonOrgRoles) {
        if (role.isCurrent === true && role.personOrgRoleDurationId !== viewState.personOrgRoleDurationId) {
          filteredPersonOrgRoles.push({
            orgRoleId: role.orgRoleId,
            orgRoleName: role.orgRoleName,
            personOrgRoleId: role.personOrgRoleId,
            personOrgRoleDurationId: role.personOrgRoleDurationId
          });
        }
      }
    }

    return {
      personId: viewState.personId,
      personOrgRole: [
        {
          orgRoleId: viewState.orgRoleId,
          orgRoleName: viewState.orgRoleName,
          expirationDate: formState.hasNoExpirationDate === false ? formState.expirationDate : Constants.DEFAULT_MAX_DATE,
          personOrgRoleId: viewState.personOrgRoleId,
          personOrgRoleDurationId: viewState.personOrgRoleDurationId
        },
        ...filteredPersonOrgRoles
      ]
    };
  };

  function getInitialFormState() {
    return {
      effectiveDate: Constants.BLANK_DATE_STRING,
      expirationDate: Constants.BLANK_DATE_STRING,
      hasNoExpirationDate: false
    };
  };

  function getInitialViewState() {
    return {
      orgRoleName: '',
      orgRoleId: '',
      memberName: '',
      memberId: '',
      personId: '',
      personOrgRoleId: Constants.DEFAULT_ID,
      personOrgRoleDurationId: Constants.DEFAULT_ID,
      activeUserOrgUnitId: ''
    };
  };

  return {
    isLoading: memberAdministrationLscBoardState.isArrayLoading || isSubmitting,
    isSaving: memberAdministrationLscBoardState.isSaving,
    viewState,
    formState,
    errorState,
    onFormValueChanged,
    handleSubmit,
    onBackClicked,
    onSaveClicked
  };
};

export default useEditLSCBoardMember;