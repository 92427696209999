import React, { createContext, useState } from 'react';

import CurrentSeasonOfferingData from './CurrentSeasonOfferingData';

export const CurrentSeasonOfferingStateContext = createContext();

const CurrentSeasonOfferingContextProvider = ({ children }) => {
  const stateHook = useState(CurrentSeasonOfferingData.INITIAL_STATE);

  return (
    <CurrentSeasonOfferingStateContext.Provider value={stateHook}>
      {children}
    </CurrentSeasonOfferingStateContext.Provider>
  );
};

export default CurrentSeasonOfferingContextProvider;