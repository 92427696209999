import React, { Fragment } from 'react';

import useClubStaff from './UseClubStaff';

import ClubStaffReadGrid from './ClubStaffReadGrid';
import Headings from '../../../../common/components/headings/Headings';
import PopUpModal from '../../../../common/components/dialogs/PopUpModal';
import Constants from '../../../../common/utils/Constants';

const ClubStaffRead = () => {
  const {
    usasClubStaffState,
    environmentVariableState
  } = useClubStaff();

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>Club Staff</Headings.H3>
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12 usas-extra-top-margin usas-extra-bottom-margin">
          <ClubStaffReadGrid state={usasClubStaffState} />
        </div>
      </div>
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={usasClubStaffState.isArrayLoading || environmentVariableState.isLoading} />
    </Fragment>
  );
};

export default ClubStaffRead;