import React from 'react';

import EditIcon from "../../../../../common/components/icons/EditIcon";

import { CURRENCY_FORMATTER } from "../../../../../common/utils/Constants";
import { formatDate } from "../../../../../common/utils/DateFunctions";

import global from '../../../../../common/components/GlobalStyle.module.css';

const GridRow = ({ contract, onEdit }) => (
  <div className={global.SmallTableRow}>
    <div className={global.SmallTableRowHead}><span className='hidden-xs'>{contract.satDate ? formatDate(contract.satDate) : ''}</span>&nbsp;
      {onEdit &&
        <button className={global.IconButton} type="button" onClick={(e) => onEdit(e, contract)}>
          <EditIcon />
        </button>
      }
    </div>
    <div className={global.SmallTableRowInfo}>
      <div className='row'>
        <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3 visible-xs'].join(' ')}>Swim-A-Thon Date</div>
        <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3 visible-xs'].join(' ')}>{contract.satDate ? formatDate(contract.satDate) : <span>&nbsp;</span>}</div>
        <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3'].join(' ')}>Results Status</div>
        <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3'].join(' ')}>{contract.satResult[0]?.resultStatus.statusDescription || <span>&nbsp;</span>}</div>
        <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3'].join(' ')}>Total Amt. Collected</div>
        <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3'].join(' ')}>{contract.satResult[0]?.totalAmountCollected ? CURRENCY_FORMATTER.format(contract.satResult[0]?.totalAmountCollected) : <span>&nbsp;</span>}</div>
        <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3'].join(' ')}>Has Prize Winners?</div>
        <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3'].join(' ')}>{contract.satResult[0]?.satParticipant?.length > 0 ? 'Yes' : 'No'}</div>
      </div>
    </div>
  </div>
);

const SATResultsGridSmall = ({ data, isLoading, onEdit }) => (
  <div className={[global.SmallTable, 'hidden-md hidden-lg'].join(' ')}>
    {isLoading === true
      ? (
        <div className={global.SmallTableRow}>
          <div className={global.SmallTableRowHead}>
            &nbsp;
          </div>
          <div className={global.SmallTableRowInfo}>
            <div className={global.SmallTableRowLabels}>Loading...</div>
          </div>
        </div>
      ) : Array.isArray(data) && data.length > 0
        ? data.map((contract, i) => (
          <GridRow
            key={i}
            contract={contract}
            onEdit={onEdit} />
        )) : (
          <div className={global.SmallTableRow}>
            <div className={global.SmallTableRowHead}>
              &nbsp;
            </div>
            <div className={global.SmallTableRowInfo}>
              <div className={global.SmallTableRowLabels}>No Results</div>
            </div>
          </div>
        )
    }
  </div>
);

export default SATResultsGridSmall;