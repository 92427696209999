import { useEffect } from 'react';

import useSwimAThonData from '../../../state/swimAThon/UseSwimAThonData';
import useClubSwimAThonLeaderboardData from '../../../state/clubSwimAThonLeaderboard/UseClubSwimAThonLeaderboardData';

const useClubSwimAThonLeaderboard = () => {
  const { satState } = useSwimAThonData();
  const { clubSwimAThonLeaderboardState, getClubSwimAThonLeaderboard } = useClubSwimAThonLeaderboardData();

  useEffect(() => {
    if (satState.isObjLoading === false && satState.isObjLoaded === true && satState.orgUnitId) {
      getClubSwimAThonLeaderboard(satState.orgUnitId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clubSwimAThonLeaderboardState, satState]);

  return {
    satState,
    clubSwimAThonLeaderboardState
  };
};

export default useClubSwimAThonLeaderboard;