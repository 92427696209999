import { useEffect, useState } from 'react';
import { useNavigate } from '../../../../common/wrappers/ReactRouterDom';

import NavLinks from '../NavLinks';

import validate from './SafeSportSearchValidation';

import useSecurityData from '../../../../common/state/security/UseSecurityData';
import useEnvironmentVariableData from '../../../../common/state/environmentVariable/UseEnvironmentVariableData';
import useSelectOrgUnitData from '../../../../common/state/selectOrgUnit/UseSelectOrgUnitData';
import UseForm from '../../../../common/utils/UseForm';

import Constants from '../../../../common/utils/Constants';

const TAXONOMIES = [''];
const SCOPE = 'ClubSafeSport';

const useSafeSportSearch = () => {
  const navigate = useNavigate();
  const { getContextSecurity } = useSecurityData();
  const { environmentVariableState, USAS_ORGANIZATION_ID } = useEnvironmentVariableData();
  const { selectOrgUnitState, setSelectOrgUnitState } = useSelectOrgUnitData();
  const { formState, errorState, handleSubmit, setFormState, onValueTextPairChanged
  } = UseForm(getInitialFormState(), submitFormCallback, validate);
  const [state, setState] = useState({ tryRedirect: false });

  function submitFormCallback() {
    setSelectOrgUnitState({
      ...selectOrgUnitState,
      orgUnitId: formState.orgUnitId,
      orgUnitName: formState.orgUnitName,
      showBackToSelection: false
    });
    getContextSecurity(formState.orgUnitId, TAXONOMIES, SCOPE)

    setState({
      ...state,
      tryRedirect: true
    });
  };

  const clearForm = () => {
    setFormState(getInitialFormState());
  };

  useEffect(() => {
    if (selectOrgUnitState.orgUnitId === formState.orgUnitId
      && selectOrgUnitState.orgUnitName === formState.orgUnitName
      && state.tryRedirect === true) {
      navigate(NavLinks.SAFE_SPORT_PROGRAM_LEVEL);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectOrgUnitState.orgUnitId, selectOrgUnitState.orgUnitName, state.tryRedirect]);

  useEffect(() => {
    if (environmentVariableState.isLoaded === true) {
      if (formState.organizationId !== USAS_ORGANIZATION_ID) {
        setFormState({
          ...formState,
          organizationId: USAS_ORGANIZATION_ID,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [environmentVariableState.isLoaded, formState.organizationId]);

  return {
    formState,
    errorState,
    environmentVariableState,
    onValueTextPairChanged,
    clearForm,
    handleSubmit
  };
};

export default useSafeSportSearch;

function getInitialFormState() {
  return {
    organizationId: Constants.DEFAULT_ID,
    lscOrgUnitId: Constants.DEFAULT_ID,
    orgUnitId: '',
    orgUnitName: ''
  };
};