import Constants from '../../../common/utils/Constants';
import { BASIC_INITIAL_STATE } from '../../../common/utils/HttpHelper';

import getOrgUnitBulkRenewalOptInData from './GetOrgUnitBulkRenewalOptInData';
import putOrgUnitBulkRenewalOptInData from './PutOrgUnitBulkRenewalOptInData';

const INITIAL_STATE = {
  ...BASIC_INITIAL_STATE,
  orgUnitId: Constants.DEFAULT_ID
};

const OrgUnitBulkRenewalOptInData = {
  INITIAL_STATE, getOrgUnitBulkRenewalOptInData,putOrgUnitBulkRenewalOptInData
};

export default OrgUnitBulkRenewalOptInData;