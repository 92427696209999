import { useEffect } from 'react';

import validate from './ProviderSearchFormValidation';

import UseForm from "../../../../../common/utils/UseForm";

const INITIAL_FORM_STATE = {
  entityName: '',
  tradeName: '',
  taxId: ''
};

const useLearnToSwimFacilityContactForm = (onSubmitFormCallback, searchFilterObject) => {
  const { formState, errorState, handleSubmit, updateFormState, setErrors, setFormState
  } = UseForm(INITIAL_FORM_STATE, onSubmitFormCallback, validate);

  const onClearFormClicked = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    setFormState({ ...INITIAL_FORM_STATE });
    setErrors({});
  };

  useEffect(() => {
    if (searchFilterObject) {
      const newFormState = {
        entityName: searchFilterObject.entityName || '',
        tradeName: searchFilterObject.tradeName || '',
        taxId: searchFilterObject.taxId || ''
      };
      const validatePromise = validate(newFormState);

      validatePromise.then((errors) => {
        if (Object.keys(errors).length === 0 && Object.keys(searchFilterObject).length > 0) {
          onSubmitFormCallback(newFormState);
        }

        setFormState({
          ...formState,
          ...newFormState
        });
      }).catch((e) => {
        console.log(e);
      });;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    formState,
    errorState,
    updateFormState,
    handleSubmit,
    onClearFormClicked
  };
};

export default useLearnToSwimFacilityContactForm;