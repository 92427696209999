import useSisenseEventNoCourseDropdown from "./UseSisenseEventNoCourseDropdown";

import Dropdown from '../../../../common/components/dropdowns/Dropdown';

import Constants from "../../../../common/utils/Constants";

import global from '../../../../common/components/GlobalStyle.module.css';

const SisenseEventNoCourseDropdown = ({ label, name, value, error, message, onChange }) => {
  const { eventNoCourseOptions } = useSisenseEventNoCourseDropdown();

  return !eventNoCourseOptions.options
    ? <span className={global.LoadingMsg}>{Constants.LOADING_MSG}</span>
    : (
      <Dropdown
        error={error}
        label={label}
        message={message}
        options={eventNoCourseOptions.options}
        name={name}
        value={value}
        onChange={onChange}
        isLoading={!eventNoCourseOptions.options}
      />
    );
};

export default SisenseEventNoCourseDropdown;