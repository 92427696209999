import React, { createContext, useState } from 'react';

import SwimAThonData from './SwimAThonData';

export const SwimAThonStateContext = createContext();

const SwimAThonContextProvider = ({ children }) => {
  const stateHook = useState(SwimAThonData.INITIAL_STATE);

  return (
    <SwimAThonStateContext.Provider value={stateHook}>
      {children}
    </SwimAThonStateContext.Provider>
  );
};

export default SwimAThonContextProvider;