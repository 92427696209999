import React, { Fragment } from 'react';

import MultipleCheckbox from '../../../../../common/components/checkboxes/MultipleCheckbox';

import style from './ClubGroupsAddRemoveMembersGrid.module.css';

import global from '../../../../../common/components/GlobalStyle.module.css';

const Grid = ({ data, onCheckBoxChange }) => {
  return (
    <Fragment>
      {data.length > 0
        ? data.map((person, i) => (
          <Fragment key={i}>
            {person.isVisible === true
              ? (
                <tr>
                  <td>{`${person?.firstName} ${person?.lastName}`}</td>
                  <td>{person?.typeName}</td>
                  <td>{person?.age}</td>
                  <td>
                    <MultipleCheckbox
                      checked={person?.isInGroup}
                      onChange={(e) => { onCheckBoxChange(person?.personId); }}
                      id={person?.personId}
                    />
                  </td>
                </tr>
              )
              : <Fragment />
            }
          </Fragment>
        )) : (
          <tr>
            <td colSpan="4">No Athletes</td>
          </tr>
        )
      }
    </Fragment>
  );
};

const LargeGrid = ({ data, onCheckBoxChange }) => (
  <Fragment>
    <table className={[global.UsasTable, 'visible-md visible-lg'].join(' ')}>
      <thead>
        <tr>
          <th className={style.FullNameWidth}>Name</th>
          <th className={style.ColumnMinWidth}>Competition Category</th>
          <th className={style.ColumnMinWidth}>Age</th>
          <th className={style.AddOrRemoveColumnWidth}>Add or Remove From Group</th>
        </tr>
      </thead>
      <tbody>
        <Grid
          data={data}
          onCheckBoxChange={onCheckBoxChange} />
      </tbody>
    </table>
  </Fragment>
);

export default LargeGrid;