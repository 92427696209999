import React from 'react';

import global from '../../../../common/components/GlobalStyle.module.css';

const TableRow = ({ preCompLocation }) => (
  <div className={global.SmallTableRow}>
    <div className={global.SmallTableRowHead}><span className='hidden-xs'>{preCompLocation.preCompLocationName}</span>&nbsp;</div>
    <div className={global.SmallTableRowInfo}>
      <div className='row'>
        <div className={[global.SmallTableRowLabels, 'col-xs-6 visible-xs'].join(' ')}>Location Name</div>
        <div className={[global.SmallTableRowData, 'col-xs-6 visible-xs'].join(' ')}>{preCompLocation.preCompLocationName}</div>
        <div className={[global.SmallTableRowLabels, 'col-xs-6'].join(' ')}>Address</div>
        <div className={[global.SmallTableRowData, 'col-xs-6'].join(' ')}>{preCompLocation.preCompLocationAddress || <span>&nbsp;</span>}</div>
      </div>
    </div>
  </div>
);

const ClubPreCompGridSmallRead = ({ data, isLoading }) => (
  <div className={[global.SmallTable, 'hidden-md hidden-lg'].join(' ')}>
    {isLoading === true
      ? (
        <div className={global.SmallTableRow}>
          <div className={global.SmallTableRowHead}>&nbsp;</div>
          <div className={global.SmallTableRowInfo}>
            <div className={global.SmallTableRowLabels}>Loading...</div>
          </div>
        </div>
      )
      : data.length > 0
        ? data.map((preCompetitiveLocation, i) => <TableRow key={i} preCompLocation={preCompetitiveLocation} />)
        : (
          <div className={global.SmallTableRow}>
            <div className={global.SmallTableRowHead}>&nbsp;</div>
            <div className={global.SmallTableRowInfo}>
              <div className={global.SmallTableRowLabels}>No Pre-Competitive Programs</div>
            </div>
          </div>
        )}
  </div>
);

export default ClubPreCompGridSmallRead;