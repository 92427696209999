import React, { Fragment } from 'react';

import PlusIcon from '../../../../../../common/components/icons/PlusIcon';

import global from '../../../../../../common/components/GlobalStyle.module.css';
import style from '../ClubGroupsAddRemoveMembersGrid.module.css';

const SmallGrid = ({ gridState, onCheckBoxChange }) => {
  return (
    <Fragment>
      {gridState.membersRemovedFromGroup?.length > 0
        ? gridState.membersRemovedFromGroup?.map((person, i) => (
          <Fragment key={i}>
            <div className={global.SmallTableRow}>
              <div className={global.SmallTableRowHead}><span className='hidden-xs'>
                {`${person?.firstName} ${person?.lastName}`}</span>&nbsp;
                <div className={global.FloatRight}>
                  <button className={global.IconButton} id={person?.personId} type="button" onClick={e => onCheckBoxChange(person?.personId)}>
                    <PlusIcon />
                  </button>
                </div>
              </div>
              <div className={global.SmallTableRowInfo}>
                <div className='row'>
                  <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3 visible-xs'].join(' ')}>Name</div>
                  <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3 visible-xs'].join(' ')}>{`${person?.firstName} ${person?.lastName}`}</div>
                  <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3'].join(' ')}>Age</div>
                  <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3'].join(' ')}>{person?.age || <span>&nbsp;</span>}</div>
                  <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3'].join(' ')}>Competition Category</div>
                  <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3'].join(' ')}>{person?.typeName || <span>&nbsp;</span>}</div>
                </div>
              </div>
            </div>
          </Fragment>
        )) : (
          <div className={global.SmallTableRow}>
            <div className={global.SmallTableRowHead}>
              &nbsp;
            </div>
            <div className={global.SmallTableRowInfo}>
              <div className={global.SmallTableRowLabels}>
                No Results
              </div>
            </div>
          </div>
        )}
    </Fragment>
  );
};

const SmallRemoveGrid = ({ gridState, onCheckBoxChange }) => (
  <Fragment>
    <div className={[global.SmallTable, 'hidden-md hidden-lg'].join(' ')}>
      <SmallGrid
        gridState={gridState}
        onCheckBoxChange={onCheckBoxChange} />
    </div>
  </Fragment>
);

const LargeGrid = ({ gridState, onCheckBoxChange }) => {
  return (
    <Fragment>
      {gridState.membersRemovedFromGroup?.length > 0
        ? gridState.membersRemovedFromGroup?.map((person, i) => (
          <tr key={person?.personOrgGroupId > 0 ? person?.personOrgGroupId : i}>
            <td>{`${person?.firstName} ${person?.lastName}`}</td>
            <td>{person?.typeName}</td>
            <td>{person?.age}</td>
            <td>
              <button className={global.IconButtonMargin} id={person?.personId} type="button" onClick={e => onCheckBoxChange(person?.personId)}>
                <PlusIcon />
              </button>
            </td>
          </tr>
        ))
        : (
          <tr>
            <td colSpan="4">No Results</td>
          </tr>
        )
      }
    </Fragment>
  );
};

const LargeRemoveGrid = ({ gridState, onCheckBoxChange }) => (
  <Fragment>
    <table className={[global.UsasTable, 'visible-md visible-lg'].join(' ')}>
      <thead>
        <tr>
          <th className={style.FullNameWidth}>Name</th>
          <th className={style.ColumnMinWidth}>Competition Category</th>
          <th className={style.ColumnMinWidth}>Age</th>
          <th className={style.AddOrRemoveColumnWidth}>Keep In Group</th>
        </tr>
      </thead>
      <tbody>
        <LargeGrid
          gridState={gridState}
          onCheckBoxChange={onCheckBoxChange} />
      </tbody>
    </table>
  </Fragment>
);

const RemoveGrid = ({ gridState, onCheckBoxChange }) => (
  <Fragment>
    <LargeRemoveGrid gridState={gridState} onCheckBoxChange={onCheckBoxChange} />
    <SmallRemoveGrid gridState={gridState} onCheckBoxChange={onCheckBoxChange} />
  </Fragment>
)

export default RemoveGrid;