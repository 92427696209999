import React, { Fragment } from 'react';

import useSafeSportCategoryMeasure from './UseSafeSportCategoryMeasure';

import SafeSportCategoryMeasureHeader from './components/SafeSportCategoryMeasureHeader';

import TextEntryView from '../../../components/views/textEntryView/TextEntryView';

import PopUpModal from '../../../../common/components/dialogs/PopUpModal';
import PageNotFound from '../../../../common/components/PageNotFound';

import Constants from '../../../../common/utils/Constants';

const SafeSportTextEntry = () => {
  const {
    isLoading,
    isReadonly,
    programFocusState,
    onRedirectToCategory
  } = useSafeSportCategoryMeasure();

  if (!programFocusState || Object.keys(programFocusState?.levelCategoryObj).length === 0 ||
    Object.keys(programFocusState?.programLevelObj).length === 0) {
    return (
      <PageNotFound />
    );
  }

  return (
    <Fragment>
      <SafeSportCategoryMeasureHeader
        programCategory={programFocusState.levelCategoryObj}
        categoryMeasure={programFocusState.categoryMeasureObj}
        onBackClicked={onRedirectToCategory} />
      <div className="row">
        <div className="col-xs-12">
          <TextEntryView
            programLevel={programFocusState.programLevelObj}
            categoryMeasure={programFocusState.categoryMeasureObj}
            isReadonly={isReadonly}
            onRedirect={onRedirectToCategory} />
        </div>
      </div>
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={isLoading} />
    </Fragment>
  );
};

export default SafeSportTextEntry;