import { Fragment } from 'react';

import useClubHeader from './UseClubHeader';

import NavLinks from '../../views/club/NavLinks';

import CanvasImage from '../canvasImage/CanvasImage';

import PopUpModal from '../../../common/components/dialogs/PopUpModal';
import LeftArrowIcon from '../../../common/components/icons/LeftArrowIcon';
import ExclamationIcon from '../../../common/components/icons/ExclamationIcon';
import IsInGoodStandingIcon from '../../../common/components/icons/InGoodStandingIcon';
import LargeContextBasedNav from '../../../common/components/contextBasedNav/LargeContextBasedNav';
import MobileContextBasedNav from '../../../common/components/contextBasedNav/MobileContextBasedNav';

import Constants from '../../../common/utils/Constants';

import global from '../../../common/components/GlobalStyle.module.css';

const ClubHeader = ({ children }) => {
  const {
    state,
    baseUsasClubState,
    clubBaseInfoState,
    onBackClicked,
    isSSRPCurrent,
    logoOverlayText,
    xCoord,
    selectOrgUnitState,
    backToSelectionText
  } = useClubHeader();

  const Logo = () => {
    return (
      <div style={{ fontSize: 18 }}>
        <CanvasImage image={`${import.meta.env.VITE_CDN_URL}/SafeSportVerifiedLogo_200.png`} textToOverlay={logoOverlayText} xLoc={xCoord} yLoc={114} fontName={'oswald'} fontSize={16} />
      </div>
    )
  }

  return (
    <Fragment>
      {selectOrgUnitState.showBackToSelection === true && state.backToSystemLabel === backToSelectionText &&
        <div className="row">
          <div className="col-xs-12">
            <button className={global.BackToSearchButton}
              type="button"
              onClick={onBackClicked}>
              <LeftArrowIcon />  {state.backToSystemLabel}
            </button>
          </div>
        </div>}
      {state.backToSystemLabel !== backToSelectionText &&
        <div className="row">
          <div className="col-xs-12">
            <button className={global.BackToSearchButton}
              type="button"
              onClick={onBackClicked}>
              <LeftArrowIcon />  {state.backToSystemLabel}
            </button>
          </div>
        </div>}
      <div className="row">
        <div className="col-xs-12 col-lg-6">
          <p className={global.HeaderText}><strong>LSC:</strong>&nbsp;{baseUsasClubState.objData.orgUnit?.parentOrgUnitCode || ''}</p>
          <p className={global.HeaderText}><strong>Club Code:</strong>&nbsp;{baseUsasClubState.objData.orgUnit?.orgUnitCode || ''}</p>
          <p className={global.HeaderText}><strong>Club Name:</strong>&nbsp;{baseUsasClubState.objData.orgUnit?.orgUnitName || ''}</p>
          <p className={global.HeaderText}><strong>Club in Good Standing?</strong> {baseUsasClubState.objData.isInGoodStanding === true ?
            <span style={{ color: '#b1953a' }}><IsInGoodStandingIcon /></span>
            : <span><ExclamationIcon /> {baseUsasClubState.objData.usasClubGoodStanding[0].notInGoodStandingReason}</span>}</p>
        </div>
        <div className="col-lg-6">
          {clubBaseInfoState.isObjLoading === false && isSSRPCurrent && <Logo />}
        </div>
      </div>
      <div className="row">
        <div className="visible-xs col-xs-12">
          <MobileContextBasedNav taxonomyName="ClubDetail" />
        </div>
        <div className="hidden-xs col-xs-12">
          <LargeContextBasedNav taxonomyName="ClubDetail" homeRoute={NavLinks.CLUB_INFO} />
        </div>
      </div>
      {children}
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={clubBaseInfoState.isObjLoading} />
    </Fragment>
  );
};

export default ClubHeader;