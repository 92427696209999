import { BASIC_INITIAL_STATE } from '../../../utils/HttpHelper';

import getLscEmails from './GetLscEmailsData';
import postLscEmail from './PostLscEmailData';
import putLscEmail from './PutLscEmailData';

const INITIAL_STATE = {
  ...BASIC_INITIAL_STATE
};

const MemberAdministrationLscEmailData = {
  INITIAL_STATE,
  getLscEmails,
  postLscEmail,
  putLscEmail
};

export default MemberAdministrationLscEmailData;