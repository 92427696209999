import React, { Fragment } from 'react';

import useSafeSportProgramCategory from './UseSafeSportProgramCategory';

import CategoryMeasureGrid from '../../../components/grids/categoryMeasure/CategoryMeasureGrid';

import Headings from '../../../../common/components/headings/Headings';
import PopUpModal from '../../../../common/components/dialogs/PopUpModal';
import LeftArrowIcon from '../../../../common/components/icons/LeftArrowIcon';
import PageNotFound from '../../../../common/components/PageNotFound';

import Constants from '../../../../common/utils/Constants';
import global from '../../../../common/components/GlobalStyle.module.css';

const Header = ({ programLevel, programCategory, isLoaded, onBackClicked }) => (
  <Fragment>
    <div className="row">
      <div className="col-xs-12">
        <hr className={[global.ThinBar, 'usas-bar-gray'].join(' ')} />
      </div>
    </div>
    <div className="row">
      <div className="col-xs-12">
        <button className={global.BackToSearchButton} type="button" onClick={onBackClicked}>
          <LeftArrowIcon /> Back to Program Level
        </button>
      </div>
    </div>
    {isLoaded === true ?
      <div className="row">
        <div className={["col-xs-12", global.HeaderText].join(' ')}>
          <p><b>{programLevel.levelName || ''} Minimum Points:</b> {programLevel.minimumValue >= 0 ? programLevel.minimumValue : ''}</p>
          <p><b>{programLevel.levelName || ''} Current Points:</b> {programLevel.currentPointValue >= 0 && programLevel.currentPointValue !== null ? programLevel.currentPointValue : '0'}</p>
          <p><b>{programCategory.categoryName || ''} Minimum Points:</b> {programCategory.minimumValue >= 0 ? programCategory.minimumValue : ''}</p>
          <p><b>{programCategory.categoryName || ''} Current Points:</b> {programCategory.currentPointValue >= 0 && programCategory.currentPointValue !== null ? programCategory.currentPointValue : '0'}</p>
        </div>
      </div>
      : <Fragment />}
    <div className="row">
      <div className="col-xs-12">
        <hr className={[global.ThinBar, 'usas-bar-gray'].join(' ')} />
      </div>
    </div>
    {isLoaded === true ?
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>{programCategory.categoryName || ''}</Headings.H3>
        </div>
      </div> : <Fragment />}
  </Fragment>
);

const SafeSportProgramCategory = () => {
  const {
    isLoading,
    programFocusState,
    onBackClicked,
    onEditCategoryMeasure
  } = useSafeSportProgramCategory();

  if (!programFocusState || Object.keys(programFocusState?.levelCategoryObj).length === 0 ||
    Object.keys(programFocusState?.programLevelObj).length === 0) {
    return (
      <PageNotFound />
    );
  }

  return (
    <Fragment>
      <Header
        programCategory={programFocusState.levelCategoryObj}
        programLevel={programFocusState.programLevelObj}
        isLoaded={isLoading === false}
        onBackClicked={onBackClicked} />
      <div className="row usas-extra-bottom-margin">
        <div className="col-xs-12">
          <CategoryMeasureGrid
            programCategory={programFocusState.levelCategoryObj}
            programLevel={programFocusState.programLevelObj}
            isLoading={isLoading}
            onEdit={onEditCategoryMeasure} />
        </div>
      </div>
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={isLoading} />
    </Fragment >
  );
};

export default SafeSportProgramCategory;