import { useContext, useEffect } from 'react';

import { OrgGroupTypesStateContext } from './OrgGroupTypesContextProvider';
import OrgGroupTypesData from './OrgGroupTypesData';

const useOrgGroupTypesData = () => {
  const [orgGroupTypesState, setOrgGroupTypesState] = useContext(OrgGroupTypesStateContext);

  //TODO update club groups to not use this, then delete
  const getOrgGroupTypes = () => {
    OrgGroupTypesData.getOrgGroupTypes(orgGroupTypesState, setOrgGroupTypesState);
  };

  useEffect(() => {
    if (orgGroupTypesState.isArrayLoaded === false && orgGroupTypesState.isArrayLoading === false) {
      OrgGroupTypesData.getOrgGroupTypes(orgGroupTypesState, setOrgGroupTypesState);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    orgGroupTypesState,
    getOrgGroupTypes,
  };
};

export default useOrgGroupTypesData;