import useRevokeSSRPForm from "./UseRevokeSSRPForm";

import PrimaryButton from "../../../common/components/buttons/PrimaryButton";
import SecondaryButton from "../../../common/components/buttons/SecondaryButton";
import DatePicker from "../../../common/components/datepickers/DatePicker";

const RevokeSSRPForm = ({ effectiveDate, submitButtonText = 'Save', secondaryButtonText = 'Cancel', onSubmitFormCallback, onSecondaryButtonClicked }) => {
  const {
    formState,
    errorState,
    handleSubmit,
    updateFormState
  } = useRevokeSSRPForm(onSubmitFormCallback, effectiveDate);

  return (
    <form onSubmit={handleSubmit}>
      <div className="row">
        <div className="col-xs-12 col-sm-6 usas-extra-bottom-margin">
          <DatePicker
            label={'Expiration Date'}
            name={'expirationDate'}
            value={formState.expirationDate}
            error={errorState.expirationDate}
            message={errorState.expirationDate}
            onChange={(value) => updateFormState('expirationDate', value)} />
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12 usas-extra-top-margin usas-extra-bottom-margin">
          <PrimaryButton type="submit">{submitButtonText}</PrimaryButton>&nbsp;
          <SecondaryButton type="button" onClick={onSecondaryButtonClicked}>{secondaryButtonText}</SecondaryButton>
        </div>
      </div>
    </form>
  );
};

export default RevokeSSRPForm;