import React, { Fragment } from 'react';
import useExpandedRow from '../../../../../common/components/grids/UseExpandedRow';

import PreCompLocationFacilityGridLarge from './PreCompLocationFacilityGridLarge';
import PreCompLocationFacilityGridSmall from './PreCompLocationFacilityGridSmall';

const PreCompLocationFacilityGrid = ({ data, isLoading, onEdit, onDelete, onAddPool, onEditPool, onDeletePool }) => {
  const { expandedId, onClick } = useExpandedRow();
  return (
    <Fragment>
      <PreCompLocationFacilityGridLarge data={data} isLoading={isLoading} onEdit={onEdit} onDelete={onDelete}
        onAddPool={onAddPool} onEditPool={onEditPool} onDeletePool={onDeletePool} expandedId={expandedId} onClick={onClick} />
      <PreCompLocationFacilityGridSmall data={data} isLoading={isLoading} onEdit={onEdit} onDelete={onDelete}
        onAddPool={onAddPool} onEditPool={onEditPool} onDeletePool={onDeletePool} expandedId={expandedId} onClick={onClick} />
    </Fragment>
  );
}

export default PreCompLocationFacilityGrid;