import useEnvironmentVariableData from "../../../../common/state/environmentVariable/UseEnvironmentVariableData";

const useClubPerformanceAthleteTimes = () => {
  const { environmentVariableState, CLUB_ROSTER_OID } = useEnvironmentVariableData();

  return {
    environmentVariableState,
    CLUB_ROSTER_OID
  };
};

export default useClubPerformanceAthleteTimes;