import { useEffect, useState } from 'react';

import validate from './TextEntryViewValidation';

import { createCategoryMeasureScoreObj } from '../../utils/RecognitionProgramUtils';

import useCategoryMeasureScoreData from '../../../state/categoryMeasureScore/UseCategoryMeasureScoreData';

import useForm from "../../../../common/utils/UseForm";

const useTextEntryView = (programLevel, categoryMeasure, onRedirect) => {
  const { categoryMeasureScoreState, postCategoryMeasureScore, putCategoryMeasureScore
  } = useCategoryMeasureScoreData();
  const { handleSubmit, updateFormState, formState, errorState, setFormData, isDirty
  } = useForm(getInitialFormState(), submitFormCallback, validate);
  const [state, setState] = useState({ tryRedirect: false, showClearEntryButton: false });

  const onSaveClicked = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    if (isDirty === false && formState.textEntry.trim() !== ''
      && formState.textEntry.trim().length <= 8000) {
      onRedirect(false);
    } else {
      handleSubmit();
    }
  };

  const onClearEntryClicked = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    if (categoryMeasure.categoryMeasureScoreId > 0) {
      putCategoryMeasureScore(categoryMeasure.categoryMeasureScoreId,
        createCategoryMeasureScoreObj(categoryMeasure, programLevel, '', 0));
    } else {
      postCategoryMeasureScore(createCategoryMeasureScoreObj(categoryMeasure, programLevel, '', 0));
    }
    setState({ ...state, tryRedirect: true });
  };

  function submitFormCallback() {
    if (categoryMeasure.categoryMeasureScoreId > 0) {
      putCategoryMeasureScore(categoryMeasure.categoryMeasureScoreId,
        createCategoryMeasureScoreObj(categoryMeasure, programLevel, formState.textEntry.trim(), categoryMeasure?.maxValue));
    } else {
      postCategoryMeasureScore(createCategoryMeasureScoreObj(categoryMeasure, programLevel, formState.textEntry.trim(), categoryMeasure?.maxValue));
    }
    setState({ ...state, tryRedirect: true });
  };

  useEffect(() => {
    if (Object.keys(categoryMeasure).length > 0) {
      setFormData({
        ...formState,
        textEntry: categoryMeasure.submittedValue || ''
      });

      if (categoryMeasure.submittedValue) {
        setState({ ...state, showClearEntryButton: true });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categoryMeasure]);

  useEffect(() => {
    if (state.tryRedirect === true && categoryMeasureScoreState.isSaved === true) {
      onRedirect(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state, categoryMeasureScoreState]);

  return {
    showClearEntryButton: state.showClearEntryButton === true,
    categoryMeasureScoreState,
    formState,
    errorState,
    handleSubmit,
    onFormValueChanged: updateFormState,
    onSaveClicked,
    onClearEntryClicked
  };
};

function getInitialFormState() {
  return {
    textEntry: ''
  };
};

export default useTextEntryView;