import React, { Fragment } from "react";

import HideIcon from "../../../../../common/components/icons/HideIcon";
import ShowIcon from "../../../../../common/components/icons/ShowIcon";
import Checkbox from '../../../../../common/components/checkboxes/Checkbox';
import SGColumnHeader from "../../../../../common/components/grids/SGColumnHeader";

import { CURRENCY_FORMATTER } from "../../../../../common/utils/Constants";
import { formatDate } from "../../../../../common/utils/DateFunctions";
import useSortableGrid from "../../../../../common/utils/UseSortableGrid";
import SGConstants from "../../../../../common/utils/SGConstants";

import global from '../../../../../common/components/GlobalStyle.module.css';

const DetailTableRow = ({ offering, onToggleOfferingApproved }) => (
  <tr>
    <td>{offering.firstName || ''} {offering.lastName || ''}</td>
    <td>{offering.registrationDateTime ? formatDate(offering.registrationDateTime) : ''}</td>
    <td>{offering.registrationType}</td>
    <td>{offering.offeringName || ''}</td>
    <td>{offering.feeAmount > 0 ? CURRENCY_FORMATTER.format(offering.feeAmount) : offering.feeAmount === 0 ? '$0.00 - BOGO' : ''}</td>
    <td>
      <Checkbox
        label=""
        name="isLscApproved"
        checked={offering.isLscApproved}
        onChange={() => { onToggleOfferingApproved(offering.memberRegistrationId); }} />
    </td>
  </tr>
);

const DetailTable = ({ offerings, onToggleOfferingApproved }) => (
  <table className={global.DetailTable}>
    <thead>
      <tr>
        <th>Member Name</th>
        <th>Registration Date</th>
        <th>New/Renew</th>
        <th>Offering</th>
        <th>Fee Amount</th>
        <th>LSC Approved?</th>
      </tr>
    </thead>
    <tbody>
      {Array.isArray(offerings) && offerings.map((offering, i) =>
        <DetailTableRow key={i} offering={offering} onToggleOfferingApproved={onToggleOfferingApproved} />)}
    </tbody>
  </table>
);

const GridRow = ({ club, onToggleClubApproved, onToggleOfferingApproved, expandedId, onClick }) => (
  <Fragment>
    <tr>
      <td colSpan="2">{club.orgUnitName || ''}</td>
      <td>{club.periodName || ''}</td>
      <td>{club.totalFeeAmount > 0 ? CURRENCY_FORMATTER.format(club.totalFeeAmount) : club.totalFeeAmount === 0 ? '$0.00 - BOGO' : ''}</td>
      <td>
        <Checkbox
          label=""
          name="isLscApproved"
          checked={club.isLscApproved}
          onChange={() => { onToggleClubApproved(club.orgUnitId); }} />
      </td>
      <td>
        <button
          className={global.IconButtonMargin}
          type="button"
          onClick={(e) => onClick(e, club.orgUnitId)}>
          {expandedId === club.orgUnitId ? <HideIcon /> : <ShowIcon />}
        </button>
      </td>
    </tr>
    {Array.isArray(club.members) && club.members.length > 0 && expandedId === club.orgUnitId &&
      <tr className={global.Expanded}>
        <td colSpan="6">
          <DetailTable offerings={club.members} onToggleOfferingApproved={onToggleOfferingApproved} />
        </td>
      </tr>}
  </Fragment>
);

const LSCRegistrationManagementGridLarge = ({ data, isLoading, onToggleClubApproved, onToggleOfferingApproved,
  expandedId, onClick }) => {
  const {
    sortableGridState,
    onColumnClicked
  } = useSortableGrid(data);

  return (
    <table className={[global.UsasTable, 'visible-md visible-lg'].join(' ')}>
      <thead>
        <tr>
          <SGColumnHeader sortableGridState={sortableGridState} columnLabel={'Club Name'} columnField={'orgUnitName'}
            sortType={SGConstants.SORT_TYPE_ALPHABETICAL} onColumnClicked={onColumnClicked} colSpan={'2'} />
          <th>Registration Year</th>
          <SGColumnHeader sortableGridState={sortableGridState} columnLabel={'Total Fee Amount'} columnField={'totalFeeAmount'}
            sortType={SGConstants.SORT_TYPE_NUMERICAL} onColumnClicked={onColumnClicked} />
          <SGColumnHeader sortableGridState={sortableGridState} columnLabel={'LSC Approved?'} columnField={'isLscApproved'}
            sortType={SGConstants.SORT_TYPE_NUMERICAL} onColumnClicked={onColumnClicked} />
          <th></th>
        </tr>
      </thead>
      <tbody>
        {isLoading === true
          ? <tr><td colSpan="6">Loading...</td></tr>
          : sortableGridState.sortedGridData.length > 0
            ? sortableGridState.sortedGridData.map((club, i) => <GridRow key={i} club={club} onToggleClubApproved={onToggleClubApproved}
              onToggleOfferingApproved={onToggleOfferingApproved} expandedId={expandedId} onClick={onClick} />)
            : <tr><td colSpan="6">No Offerings</td></tr>
        }
      </tbody>
    </table>
  );
};

export default LSCRegistrationManagementGridLarge;