import { useContext } from 'react';

import { SwimAThonResultStateContext } from './SwimAThonResultContextProvider';

// this is a local only state context, used to manage a result object through multiple different views without the API
const useSwimAThonResultData = () => {
  const [swimAThonResultState, setSwimAThonResultState] = useContext(SwimAThonResultStateContext);

  const updateResultObj = (satResultObj, satContract = undefined) => {
    setSwimAThonResultState({
      ...swimAThonResultState,
      isObjLoaded: true,
      objData: satResultObj || {},
      satContract: satContract || swimAThonResultState.satContract
    });
  };

  const clearObjData = () => {
    setSwimAThonResultState({
      ...swimAThonResultState,
      isObjLoading: false,
      isObjLoaded: false,
      isSaving: false,
      isSaved: false,
      objData: {},
      satContract: {},
      message: ''
    });
  };

  return {
    swimAThonResultState,
    updateResultObj,
    clearObjData
  };
};

export default useSwimAThonResultData;