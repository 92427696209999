import { Fragment } from "react";

import HideIcon from "../../icons/HideIcon";
import ShowIcon from "../../icons/ShowIcon";
import global from '../../GlobalStyle.module.css';

const GridRowDetailTableRow = ({ role }) => (
  <div className={global.SmallTableRow}>
    <div className={global.SmallTableDetailRowHead}><span className='hidden-xs'>{role.orgRoleName || ''}</span>&nbsp;</div>
    <div className={global.SmallTableRowInfo}>
      <div className='row'>
        <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3 visible-xs'].join(' ')}>Role</div>
        <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3 visible-xs'].join(' ')}>{role.orgRoleName || <span>&nbsp;</span>}</div>
      </div>
    </div>
  </div>
);

const GridRowDetailTable = ({ staff }) => (
  <div className={global.SmallTableDetailTable}>
    {staff && Array.isArray(staff.personOrgRole) && staff.personOrgRole.map((role, i) =>
      <GridRowDetailTableRow key={i} role={role} />)}
  </div>
);

const GridRow = ({ staff, expandedId, onClick }) => (
  <Fragment>
    <div className={global.SmallTableRow}>
      <div className={global.SmallTableRowHead}>
        <span className='hidden-xs'>{staff.firstName || ''} {staff.lastName || ''}</span>&nbsp;
        <button className={global.IconButton}
          type="button"
          onClick={(e) => onClick(e, staff.personId)}>
          {expandedId === staff.personId ? <HideIcon /> : <ShowIcon />}
        </button>
      </div>
      <div className={global.SmallTableRowInfo}>
        <div className='row'>
          <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3 visible-xs'].join(' ')}>Club Staff Name</div>
          <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3 visible-xs'].join(' ')}>{staff.firstName || ''} {staff.lastName || ''}</div>
          <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3'].join(' ')}>Member Id</div>
          <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3'].join(' ')}>{staff.memberId || <span>&nbsp;</span>}</div>
          <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3'].join(' ')}>Is In Good Standing?</div>
          <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3'].join(' ')}>{staff.isInGoodStanding === true ? 'Yes' : 'No'}</div>
        </div>
      </div>
    </div>
    {staff && Array.isArray(staff.personOrgRole) && staff.personOrgRole.length > 0 && staff.personId === expandedId &&
      <GridRowDetailTable staff={staff} />
    }
  </Fragment>
);

const ClubStaffReportGridSmall = ({ state, expandedId, onClick }) => (
  <div className={[global.SmallTable, 'hidden-md hidden-lg'].join(' ')}>
    {state.isArrayLoaded !== true
      ? (
        <div className={global.SmallTableRow}>
          <div className={global.SmallTableRowHead}>
            &nbsp;
          </div>
          <div className={global.SmallTableRowInfo}>
            {state.isArrayLoading === true ?
              <div className={global.SmallTableRowLabels}>Loading...</div>
              : <div className={global.SmallTableRowLabels}>No Staff</div>
            }
          </div>
        </div>
      ) : Array.isArray(state.arrayData) && state.arrayData.length > 0
        ? state.arrayData.map((staff, i) => <GridRow key={i} staff={staff} expandedId={expandedId} onClick={onClick} />) : (
          <div className={global.SmallTableRow}>
            <div className={global.SmallTableRowHead}>
              &nbsp;
            </div>
            <div className={global.SmallTableRowInfo}>
              <div className={global.SmallTableRowLabels}>No Staff</div>
            </div>
          </div>
        )
    }
  </div>
);

export default ClubStaffReportGridSmall;