const PreCompLocationInfoFormValidation = (formState) => {
  let errors = {};

  if (formState.preEmploymentScreening !== true && formState.preEmploymentScreening !== false) {
    errors.preEmploymentScreening = 'Must select yes or no for this field';
  }

  if (!(formState.instructorTrainingAgencyId > 0)) {
    errors.instructorTrainingAgencyId = 'Instructor Training Agency is required';
  }

  if (!(formState.inServiceInstructionHoursId > 0)) {
    errors.inServiceInstructionHoursId = 'A valid selection is required';
  }

  if (!(formState.inClassroomInstructionHoursId > 0)) {
    errors.inClassroomInstructionHoursId = 'A valid selection is required';
  }

  if (!(formState.inWaterInstructionHoursId > 0)) {
    errors.inWaterInstructionHoursId = 'A valid selection is required';
  }

  if (!(formState.inShadowInstructionHoursId > 0)) {
    errors.inShadowInstructionHoursId = 'A valid selection is required';
  }

  if (!(formState.inPracticeInstructionHoursId > 0)) {
    errors.inPracticeInstructionHoursId = 'A valid selection is required';
  }

  return errors;
};

export default PreCompLocationInfoFormValidation;