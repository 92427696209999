import React from 'react';

import useClubRegistrationScreeningPolicies from './UseClubRegistrationScreeningPolicies';

import ClubRegistrationScreeningPoliciesWrite from './ClubRegistrationScreeningPoliciesWrite';
import ClubRegistrationScreeningPoliciesRead from './ClubRegistrationScreeningPoliciesRead';

import ValidationPopUpModal from '../components/ValidationPopUpModal';

import ClubRegistrationConstants from '../utils/ClubRegistrationConstants';

import LeftNav from '../../../../common/components/leftNav/LeftNav';
import Headings from '../../../../common/components/headings/Headings';
import PrimaryButton from '../../../../common/components/buttons/PrimaryButton';
import SecondaryButton from '../../../../common/components/buttons/SecondaryButton';
import PopUpModal from '../../../../common/components/dialogs/PopUpModal';
import Constants from '../../../../common/utils/Constants';

const ClubRegistrationScreeningPolicies = () => {
  const { clubRegistrationNavState, clubRegistrationState, formState, errorState, onFormValueChanged,
    handleSubmit, modalState, state, onModalContinue, onModalCanceled, onContinueClicked,
    onSaveAndExitClicked, onBackClicked } = useClubRegistrationScreeningPolicies();
    
  return (
    <LeftNav title={ClubRegistrationConstants.LEFT_NAV_TITLE} state={clubRegistrationNavState} validateBeforeRedirect={false}>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>Screening &amp; Policies</Headings.H3>
        </div>
      </div>
      {clubRegistrationState.objData.status === ClubRegistrationConstants.IN_PROGRESS_STATUS ||
        clubRegistrationState.objData.status === ClubRegistrationConstants.RENEWING_STATUS ?
        <ClubRegistrationScreeningPoliciesWrite
          clubRegistrationState={clubRegistrationState}
          handleSubmit={handleSubmit}
          formState={formState}
          errorState={errorState}
          onFormValueChanged={onFormValueChanged}
        />
        :
        <ClubRegistrationScreeningPoliciesRead
          formState={formState}
          clubRegistrationState={clubRegistrationState}
        />
      }
      <div className="row usas-extra-top-margin usas-extra-bottom-margin">
        <div className="col-xs-12 usas-extra-top-margin">
          <PrimaryButton type="button" onClick={() => onContinueClicked()}>Continue</PrimaryButton>&nbsp;
          <PrimaryButton type="button" onClick={() => onSaveAndExitClicked()}>
            {clubRegistrationState.objData.status === ClubRegistrationConstants.IN_PROGRESS_STATUS
              || clubRegistrationState.objData.status === ClubRegistrationConstants.RENEWING_STATUS ?
              ClubRegistrationConstants.SAVE_AND_EXIT : ClubRegistrationConstants.EXIT}</PrimaryButton>&nbsp;
          <SecondaryButton type="button" onClick={() => onBackClicked()}>Back</SecondaryButton>
        </div>
      </div>
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={clubRegistrationState.isObjLoading} />
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.SAVING_MSG}
        displayPopUp={clubRegistrationState.isSaving} />
      <ValidationPopUpModal
        clubRegistrationState={clubRegistrationState}
        state={state}
        modalState={modalState}
        onModalContinue={onModalContinue}
        onModalCanceled={onModalCanceled}
      />
    </LeftNav>
  )
}

export default ClubRegistrationScreeningPolicies;