import { Fragment } from "react";
import SecondaryButton from '../../../../common/components/buttons/SecondaryButton';
import ReadOnly from '../../../../common/components/readOnly/ReadOnly';
import CourseMultiSelect from '../../../../common/components/multiSelect/courseMultiSelect/CourseMultiSelect';

const ClubBlockPartyMeetViewForm = ({
  formState,
  onBackClicked,
}) => (
  <Fragment>
    <form noValidate>
      <div className="col-xs-12">
        Meet Announcement Document (optional)
      </div>
      {formState.meetAnnouncementUrl !== "Invalid file type" &&
        <div className="usas-extra-bottom-margin" hidden={formState.meetAnnouncementUrl === ''}>
          <div className="col-xs-12">
            <p><b>Uploaded File Name: </b> {formState.meetAnnouncementFileName}</p>
          </div>
        </div>
      }
      <div className="col-xs-12 usas-extra-top-margin"></div>
      <div className="col-xs-12 col-sm-6 col-md-4">
        <ReadOnly
          label="Meet Name"
          name="meetName"
          value={formState.meetName}
        />
      </div>
      <div className="col-xs-12 col-sm-6 col-md-4">
        <ReadOnly
          label="Start Date"
          name="startDate"
          value={formState.startDate}
        />
      </div>
      <div className="col-xs-12 col-sm-6 col-md-4">
        <ReadOnly
          label="Meet Classification"
          name="meetClassification"
          value={formState.meetClassification[0]?.name}
        />
      </div>
      <div className="col-xs-12 col-sm-6 col-md-4">
        <ReadOnly
          label="Facility"
          name="facilityName"
          value={formState.facilityName}
        />
      </div>
      <div className="col-xs-12 col-sm-6 col-md-4">
        {/* render this but don't display it so that courseState gets populated */}
        <CourseMultiSelect
          label="Meet Course(s)*"
          name="meetCourse"
          value={formState.meetCourse}
          error={() => {}}
          message={() => {}}
          isVisible={false} />
        <ReadOnly
          label="Meet Course"
          name="meetCourse"
          value={formState.meetCourseForDisplay}
        />
      </div>
      <div className="col-xs-12 col-sm-6 col-md-4">
        <ReadOnly
          label="Meet Management Software"
          name="softwareName"
          value={formState.softwareName}
        />
      </div>
      <div className="col-xs-12 col-sm-6 col-md-4">
        <ReadOnly
          label={`${formState.meetStaffTypeLabel}`}
          name="meetStaffMemberId"
          value={formState.meetStaffMemberId}
        />
      </div>
      <div className="col-xs-12 col-sm-6 col-md-4" hidden={formState.meetSanctionNumber === ''}>
        <ReadOnly
          label="Meet Sanction Number"
          name="meetSanctionNumber"
          value={formState.meetSanctionNumber}
        />
      </div>
      <div className="row">
        <div className="col-xs-12 usas-extra-top-margin usas-extra-bottom-margin">
          <SecondaryButton type="button" onClick={onBackClicked}>Back</SecondaryButton>&nbsp;&nbsp;
        </div>
      </div>
    </form>
  </Fragment>
);

export default ClubBlockPartyMeetViewForm;