import React from 'react';

import useLearnToSwimRegistrationFacilitiesDetail from './UseLearnToSwimRegistrationFacilitiesDetail';

import LearnToSwimRegistrationLeftNav from '../components/leftNav/LearnToSwimRegistrationLeftNav';
import { FACILITIES_NAME } from '../components/leftNav/LearnToSwimRegistrationLeftNavConstants';

import LearnToSwimFacilityContactForm from '../../learnToSwim/components/forms/LearnToSwimFacilityContactForm';

import PopUpModal from '../../../../common/components/dialogs/PopUpModal';

import Constants from '../../../../common/utils/Constants';

const LearnToSwimRegistrationFacilitiesDetail = () => {
  const {
    isLoading,
    orgUnitFacilityObj,
    onSubmitFormCallback,
    onCancelClicked
  } = useLearnToSwimRegistrationFacilitiesDetail();

  return (
    <LearnToSwimRegistrationLeftNav viewName={FACILITIES_NAME} viewTitle={'Facility Contact Info'}>
      <LearnToSwimFacilityContactForm
        secondaryButtonText={'Cancel'}
        orgUnitFacilityObj={orgUnitFacilityObj}
        onSubmitFormCallback={onSubmitFormCallback}
        onSecondaryButtonClicked={onCancelClicked} />
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={isLoading} />
    </LearnToSwimRegistrationLeftNav>
  );
};

export default LearnToSwimRegistrationFacilitiesDetail;