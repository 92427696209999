
import React, { Fragment } from 'react';

import SelectOrgUnit from '../../../../common/components/selectOrgUnit/SelectOrgUnit';

import useOrgUnitSelection from './UseOrgUnitSelection';

const OrgUnitSelection = () => {
    const { NavLinks, TAXONOMIES, SCOPE, getPreCompOrganizationAndLocationFunction, MY_PRECOMP_ROLE_GROUP_ID } = useOrgUnitSelection();
    return (
        <Fragment>
            <SelectOrgUnit
                pageTitle={'My Programs'}
                navLink={NavLinks.PRE_COMP_ORGANIZATION_INFO}
                roleGroupId={MY_PRECOMP_ROLE_GROUP_ID}
                getFunction={getPreCompOrganizationAndLocationFunction}
                TAXONOMIES={TAXONOMIES}
                SCOPE={SCOPE} />
        </Fragment>
    );
};

export default OrgUnitSelection;