import { useEffect, useState } from 'react';

import validate from './DropdownViewValidation';

import { createCategoryMeasureScoreObj } from '../../utils/RecognitionProgramUtils';

import useCategoryMeasureScoreData from '../../../state/categoryMeasureScore/UseCategoryMeasureScoreData';
import useCategoryMeasureDropdownData from '../../../state/categoryMeasureDropdown/UseCategoryMeasureDropdownData';

import Constants from "../../../../common/utils/Constants";
import useForm from "../../../../common/utils/UseForm";

const useDropdownView = (programLevel, categoryMeasure, isReadonly, onRedirect) => {
  const { categoryMeasureScoreState, postCategoryMeasureScore, putCategoryMeasureScore
  } = useCategoryMeasureScoreData();
  const { categoryMeasureDropdownState, getCategoryMeasureDropdown } = useCategoryMeasureDropdownData();
  const { handleSubmit, onValueTextPairChanged, formState, errorState, setFormData, isDirty
  } = useForm(getInitialFormState(), submitFormCallback, validate);
  const [state, setState] = useState({ tryRedirect: false });

  const onSaveClicked = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    if (isDirty === false && formState.dropdownId > 0) {
      onRedirect(false);
    } else {
      handleSubmit();
    }
  };

  useEffect(() => {
    if (isReadonly === true) {
      const categoryMeasureId = categoryMeasure.categoryMeasureId;

      if (categoryMeasureDropdownState.isArrayLoading !== true && categoryMeasureId > 0 && categoryMeasureId !== categoryMeasureDropdownState.categoryMeasureId) {
        getCategoryMeasureDropdown(categoryMeasureId);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categoryMeasureDropdownState, categoryMeasure.categoryMeasureId, isReadonly]);

  useEffect(() => {
    if (Object.keys(categoryMeasure).length > 0 &&
      categoryMeasureDropdownState.categoryMeasureId === categoryMeasure?.categoryMeasureId) {
      setFormData({
        ...formState,
        dropdownId: categoryMeasureDropdownState.options.find((option) =>
          option.name === categoryMeasure.submittedValue)?.id || Constants.DEFAULT_ID,
        dropdownName: categoryMeasure.submittedValue || '',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categoryMeasureDropdownState, categoryMeasure]);

  useEffect(() => {
    if (state.tryRedirect === true && categoryMeasureScoreState.isSaved === true) {
      onRedirect(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state, categoryMeasureScoreState]);

  function submitFormCallback() {
    if (categoryMeasure.categoryMeasureScoreId > 0) {
      putCategoryMeasureScore(categoryMeasure.categoryMeasureScoreId,
        createCategoryMeasureScoreObj(categoryMeasure, programLevel, formState.dropdownName, getScore(formState.dropdownId)));
    } else {
      postCategoryMeasureScore(createCategoryMeasureScoreObj(categoryMeasure, programLevel, formState.dropdownName, getScore(formState.dropdownId)));
    }
    setState({ ...state, tryRedirect: true });
  };

  function getScore(dropdownId) {
    const targetDropdownOption = categoryMeasureDropdownState.arrayData.find(x => x.categoryMeasureDropdownId === dropdownId);

    return targetDropdownOption?.score ?? 0;
  };

  return {
    categoryMeasureScoreState,
    categoryMeasureDropdownState,
    formState,
    errorState,
    handleSubmit,
    onValueTextPairChanged,
    onSaveClicked
  };
};

function getInitialFormState() {
  return {
    dropdownId: Constants.DEFAULT_ID,
    dropdownName: ''
  };
};

export default useDropdownView;