import React from 'react';

import useCurrentSeasonOfferingDropdown from './UseCurrentSeasonOfferingDropdown';

import Dropdown from '../../../../common/components/dropdowns/Dropdown';

import global from '../../../../common/components/GlobalStyle.module.css';

const CurrentSeasonOfferingDropdown = ({ label, name, value, error, message, onChange, orgUnitId, isAthleteRole = '', isActive = '' }) => {
  const { currentSeasonOfferingState } = useCurrentSeasonOfferingDropdown(orgUnitId, isAthleteRole, isActive);

  return currentSeasonOfferingState.message
    ? <span className={global.LoadingMsg}>{currentSeasonOfferingState.message}</span>
    : (
      <Dropdown
        error={error}
        label={label}
        message={message}
        options={currentSeasonOfferingState.options}
        name={name}
        value={value}
        onChange={onChange}
        isLoading={currentSeasonOfferingState.isArrayLoading} />
    );
};

export default CurrentSeasonOfferingDropdown;