import { useEffect, useState } from 'react';
import { useNavigate } from '../../../../common/wrappers/ReactRouterDom';

import NavLinks from '../NavLinks';

import usePreCompLocationData from '../../../state/preCompLocation/UsePreCompLocationData';
import usePreCompOrganizationContactsData from '../../../state/preCompOrganizationContacts/UsePreCompOrganizationContactsData';
import usePreCompLocationStaffData from '../../../state/preCompLocationStaff/UsePreCompLocationStaffData';

import useSecurityData from '../../../../common/state/security/UseSecurityData';
import usePreCompOrganizationData from '../../../../common/state/preCompOrganization/UsePreCompOrganizationData';
import useSearchFacilityData from '../../../../common/state/searchFacility/UseSearchFacilityData';
import useFacilityData from '../../../../common/state/facility/UseFacilityData';
import useSearchClubData from '../../../../common/state/searchClub/UseSearchClubData';
import useBaseUsasClubData from '../../../../common/state/clubBase/UseBaseUsasClubData';

const INITIAL_VIEW_STATE = {
  text: 'Search',
  isItemSelected: false
};

const TAXONOMIES = ['PreComp'];
const SCOPE = 'PreComp';

const usePreCompSearchForm = () => {
  const navigate = useNavigate();
  const { getContextSecurity } = useSecurityData();
  const { preCompOrganizationState, getPreCompOrganization } = usePreCompOrganizationData();
  const { preCompLocationState, getPreCompLocation } = usePreCompLocationData();
  const { resetPreCompOrganizationContactsState } = usePreCompOrganizationContactsData();
  const { resetPreCompLocationStaffState } = usePreCompLocationStaffData();
  const { resetSearchFacilityState } = useSearchFacilityData();
  const { clearFacilityArrayData, clearFilterState } = useFacilityData();
  const { resetSearchClubState } = useSearchClubData();
  const { clearBaseUsasClubFilterState, clearBaseUsasClubArrayData } = useBaseUsasClubData();
  const [state, setState] = useState(INITIAL_VIEW_STATE);

  const onEditOrganization = (locationId, organizationId) => {
    getPreCompLocation(locationId);
    getContextSecurity(locationId, TAXONOMIES, SCOPE);
    getPreCompOrganization(organizationId);
    setState({ ...state, isItemSelected: true });
  }

  useEffect(() => {
    //Clear out any club or facility search data
    clearBaseUsasClubFilterState();
    clearBaseUsasClubArrayData();
    resetSearchClubState();

    //Clear out any staff or contact data
    resetPreCompOrganizationContactsState();
    resetPreCompLocationStaffState();

    clearFilterState();
    clearFacilityArrayData();
    resetSearchFacilityState();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (state.isItemSelected === true) {
      if (preCompOrganizationState.isObjLoading !== true && preCompOrganizationState.isObjLoaded === true &&
        preCompLocationState.isObjLoading !== true && preCompLocationState.isObjLoaded === true) {
        if (preCompOrganizationState.objData.preCompetitiveOrganizationId > 0 &&
          preCompLocationState.objData.preCompetitiveLocationId > 0) {
          navigate(NavLinks.PRE_COMP_ORGANIZATION_INFO);
        } else {
          setState(INITIAL_VIEW_STATE);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state, preCompOrganizationState, preCompLocationState]);

  return {
    onEditOrganization,
    preCompLocationState
  };
};

export default usePreCompSearchForm;