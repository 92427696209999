import Constants from '../../../common/utils/Constants';
import { PreCompHttpHelper, BASIC_INITIAL_STATE, NO_DATA_MESSAGE } from '../../../common/utils/HttpHelper';

const INITIAL_STATE = {
  ...BASIC_INITIAL_STATE,
  dataAsOptionsWithNames: []
};

const getPreCompBusinessEntityTypes = (state, setState) => {
  if (state.isArrayLoading !== true && !state.message) {
    let newState = {
      ...state,
      isArrayLoading: true,
      isArrayLoaded: false,
      message: 'Loading...'
    };
    setState(newState);

    const url = `/BusinessEntityType`;
    PreCompHttpHelper(url, 'GET')
      .then((arrayData) => {
        if (!arrayData) {
          throw new Error(NO_DATA_MESSAGE);
        } else {
          arrayData.unshift({ businessEntityTypeId: Constants.DEFAULT_ID, typeName: '--' });
          newState = {
            ...newState,
            isArrayLoading: false,
            isArrayLoaded: true,
            arrayData,
            dataAsOptionsWithNames: arrayData.map((e) => { return { id: e.businessEntityTypeId, name: e.typeName } }),
            message: ''
          };
          setState(newState);
        }
      })
      .catch((e) => {
        const message = e && e.message ? e.message : NO_DATA_MESSAGE;
        setState({
          ...newState,
          isArrayLoading: false,
          isArrayLoaded: false,
          message: message
        });
      });
  }
};

const PreCompBusinessEntityTypeData = {
  INITIAL_STATE,
  getPreCompBusinessEntityTypes
};

export default PreCompBusinessEntityTypeData;