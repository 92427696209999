import { useEffect } from 'react';
import { useNavigate } from '../../../common/wrappers/ReactRouterDom';

import NavLinks from './NavLinks';

import useSecurityData from '../../../common/state/security/UseSecurityData';
import usePersonOrgRoleData from '../../../common/state/personOrgRole/UsePersonOrgRoleData';
import useSelectOrgUnitData from '../../../common/state/selectOrgUnit/UseSelectOrgUnitData';

const useClubRenewalContextView = () => {
  const navigate = useNavigate();
  const { securityState, userInfo, login } = useSecurityData();
  const { clearArrayData } = usePersonOrgRoleData();
  const { selectOrgUnitState, resetSelectOrgUnitState } = useSelectOrgUnitData();

  const onBackClicked = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    navigate(NavLinks.CLUB_RENEWAL_RENEW);
  }

  const onBackToSelectionClicked = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    resetSelectOrgUnitState();
    clearArrayData();
    goToClubRenewalRoot();
  }

  useEffect(() => {
    if (userInfo?.isLoggedIn !== true) {
      login();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [securityState])


  function goToClubRenewalRoot() {
    navigate(NavLinks.CLUB_RENEWAL_SELECTION);
  };

  return {
    isLoggedIn: securityState.isLoggedIn,
    isLoading: securityState.isLoading,
    onBackClicked,
    onBackToSelectionClicked,
    selectOrgUnitState
  };
};

export default useClubRenewalContextView;
