import { filterFactory } from "@sisense/sdk-data";
import { useExecuteQuery, useGetDashboardModel } from "@sisense/sdk-ui";
import { useLocation } from "../../../../../../common/wrappers/ReactRouterDom";

import * as DM from '../../../../../../usas-times-data';

import ToIntIfInt from "../../../../../../common/utils/ToIntIfInt";

const useMeetResultsDashboard = (dashboardOid) => {
  const location = useLocation();
  const { dashboard, isLoading, isError } = useGetDashboardModel({ dashboardOid, includeWidgets: true });
  const queryProps = {
    dataSource: DM.DataSource, dimensions: [DM.Meet.SCY, DM.Meet.SCM, DM.Meet.LCM, DM.Meet.Final, DM.Meet.Semi, DM.Meet.Prelim],
    filters: filterFactory.equals(DM.Meet.MeetKey, ToIntIfInt(location.state?.meetKey))
  };
  const { data } = useExecuteQuery(queryProps);

  return {
    meetData: data, dashboard, isError, isLoading
  };
};

export default useMeetResultsDashboard;