import React, { Fragment } from 'react';

import useClubSwimAThonContract from './UseClubSwimAThonContract';

import SATContractsGrid from '../components/grids/SATContractsGrid';

import Headings from '../../../../common/components/headings/Headings';
import PopUpModal from '../../../../common/components/dialogs/PopUpModal';
import ActionIntraPageButton from '../../../../common/components/buttons/ActionIntraPageButton';

import Constants from '../../../../common/utils/Constants';

const ClubSwimAThonContract = () => {
  const {
    satState,
    onAddContract,
    onEditContract
  } = useClubSwimAThonContract();

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>Swim-a-Thon Contracts</Headings.H3>
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12 usas-extra-top-margin">
          <ActionIntraPageButton onClick={onAddContract}>Start a New Swim-a-Thon</ActionIntraPageButton>
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12 usas-extra-top-margin usas-extra-bottom-margin">
          <SATContractsGrid
            data={satState.objData?.satContracts || []}
            isLoading={satState.isObjLoading}
            onEdit={onEditContract} />
        </div>
      </div>
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={satState.isObjLoading} />
    </Fragment>
  );
};

export default ClubSwimAThonContract;