export const localValidate = (formState) => {
  let errors = {};

  if (formState.yesNo !== true && formState.yesNo !== false) {
    errors.yesNo = 'Must select yes or no';
  }

  return errors;
};

const YesNoViewValidation = async (formState) => {
  let errors = localValidate(formState);

  return errors ? errors : {};
};

export default YesNoViewValidation;