import React from 'react';

import UsasClubFacilityLinksContextProvider from '../../state/clubFacilityLinks/UsasClubFacillityLinksContextProvider';
import UsasClubPreCompLinksContextProvider from '../../state/clubPreCompLinks/UsasClubPreCompLinksContextProvider';
import UsasClubStaffContextProvider from '../../state/clubStaff/UsasClubStaffContextProvider';
import PreCompLocationContextProvider from '../../state/preCompLocation/PreCompLocationContextProvider';
import ClubHistoryContextProvider from '../../state/clubHistory/ClubHistoryContextProvider';
import PreviousSeasonOfferingContextProvider from '../../state/previousSeasonOffering/PreviousSeasonOfferingContextProvider';
import CurrentSeasonOfferingContextProvider from '../../state/currentSeasonOffering/CurrentSeasonOfferingContextProvider';
import MemberRegistrationRenewalSelectionContextProvider from '../../state/memberRegistrationRenewalSelection/MemberRegistrationRenewalSelectionContextProvider';
import OrgUnitBulkRenewalOptInContextProvider from '../../state/orgUnitBulkRenewalOptIn/OrgUnitBulkRenewalOptInContextProvider';
import ClubBlockPartyMeetsContextProvider from '../../state/clubBlockPartyMeets/ClubBlockPartyMeetsContextProvider';
import TouchpointTypeContextProvider from '../../state/touchpointType/TouchpointTypeContextProvider';
import UsasClubThirdPartyInvoicesContextProvider from '../../state/clubThirdPartyInvoices/UsasClubThirdPartyInvoicesContextProvider';
import LscOfferingByOrgUnitIdAndReportPeriodIdDropdownContextProvider from '../../state/lscOfferingByOrgUnitIdAndReportPeriodIdDropdown/LscOfferingByOrgUnitIdAndReportPeriodIdDropdownContextProvider';

import OrgGroupTypesContextProvider from '../../../common/state/orgGroupTypes/OrgGroupTypesContextProvider';
import ReportPeriodSeasonContextProvider from '../../../common/state/reportPeriodSeason/ReportPeriodSeasonContextProvider';
import PreCompOrganizationContextProvider from '../../../common/state/preCompOrganization/PreCompOrganizationContextProvider';
import PreCompSearchFilterContextProvider from '../../../common/state/preCompOrganization/PreCompSearchFilterContextProvider';
import SearchPreCompContextProvider from '../../../common/state/searchPreComp/SearchPreCompContextProvider';
import OrgGroupCategoriesContextProvider from '../../../common/state/orgGroupCategories/OrgGroupCategoriesContextProvider';
import GroupCategoryContextProvider from '../../../common/state/groupCategory/GroupCategoryContextProvider';
import OrgGroupPropertiesContextProvider from '../../../common/state/orgGroupProperties/OrgGroupPropertiesContextProvider';
import GroupCoachesContextProvider from '../../../common/state/groupCoaches/GroupCoachesContextProvider';
import GroupFacilitiesContextProvider from '../../../common/state/groupFacilities/GroupFacilitiesContextProvider';
import FacilityContextProvider from '../../../common/state/facility/FacilityContextProvider';
import FacilityFiltersContextProvider from '../../../common/state/facility/FacilityFiltersContextProvider';
import SearchFacilityContextProvider from '../../../common/state/searchFacility/SearchFacilityContextProvider';
import MeetSessionsContextProvider from '../../../common/state/meetSessions/MeetSessionsContextProvider';
import TimeContextProvider from '../../../common/state/time/TimeContextProvider';
import TimeFiltersContextProvider from '../../../common/state/time/TimeFiltersContextProvider';
import TimesOrganizationContextProvider from '../../../common/state/time/TimesOrganizationContextProvider';
import SessionContextProvider from '../../../common/state/time/SessionContextProvider';
import LegalEntityTypeContextProvider from '../../state/legalEntity/LegalEntityTypeContextProvider';

const ClubContextRoutesContextProviders = ({ children }) => (
  <LscOfferingByOrgUnitIdAndReportPeriodIdDropdownContextProvider>
    <OrgUnitBulkRenewalOptInContextProvider>
      <MemberRegistrationRenewalSelectionContextProvider>
        <PreviousSeasonOfferingContextProvider>
          <CurrentSeasonOfferingContextProvider>
            <UsasClubStaffContextProvider>
              <ClubHistoryContextProvider>
                <OrgGroupTypesContextProvider>
                  <TouchpointTypeContextProvider>
                    <LegalEntityTypeContextProvider>
                      <ClubBlockPartyMeetsContextProvider>
                        <UsasClubThirdPartyInvoicesContextProvider>
                          <OrgGroupCategoriesContextProvider>
                            <OrgGroupPropertiesContextProvider>
                              <GroupCategoryContextProvider>
                                <GroupCoachesContextProvider>
                                  <GroupFacilitiesContextProvider>
                                    <ReportPeriodSeasonContextProvider>
                                      <UsasClubPreCompLinksContextProvider>
                                        <PreCompOrganizationContextProvider>
                                          <PreCompLocationContextProvider>
                                            <PreCompSearchFilterContextProvider>
                                              <SearchPreCompContextProvider>
                                                <UsasClubFacilityLinksContextProvider>
                                                  <FacilityContextProvider>
                                                    <FacilityFiltersContextProvider>
                                                      <SearchFacilityContextProvider>
                                                        <MeetSessionsContextProvider>
                                                          <TimeContextProvider>
                                                            <TimeFiltersContextProvider>
                                                              <TimesOrganizationContextProvider>
                                                                <SessionContextProvider>
                                                                  {children}
                                                                </SessionContextProvider>
                                                              </TimesOrganizationContextProvider>
                                                            </TimeFiltersContextProvider>
                                                          </TimeContextProvider>
                                                        </MeetSessionsContextProvider>
                                                      </SearchFacilityContextProvider>
                                                    </FacilityFiltersContextProvider>
                                                  </FacilityContextProvider>
                                                </UsasClubFacilityLinksContextProvider>
                                              </SearchPreCompContextProvider>
                                            </PreCompSearchFilterContextProvider>
                                          </PreCompLocationContextProvider>
                                        </PreCompOrganizationContextProvider>
                                      </UsasClubPreCompLinksContextProvider>
                                    </ReportPeriodSeasonContextProvider>
                                  </GroupFacilitiesContextProvider>
                                </GroupCoachesContextProvider>
                              </GroupCategoryContextProvider>
                            </OrgGroupPropertiesContextProvider>
                          </OrgGroupCategoriesContextProvider>
                        </UsasClubThirdPartyInvoicesContextProvider >
                      </ClubBlockPartyMeetsContextProvider>
                    </LegalEntityTypeContextProvider>
                  </TouchpointTypeContextProvider>
                </OrgGroupTypesContextProvider>
              </ClubHistoryContextProvider>
            </UsasClubStaffContextProvider>
          </CurrentSeasonOfferingContextProvider>
        </PreviousSeasonOfferingContextProvider>
      </MemberRegistrationRenewalSelectionContextProvider>
    </OrgUnitBulkRenewalOptInContextProvider>
  </LscOfferingByOrgUnitIdAndReportPeriodIdDropdownContextProvider>
);

export default ClubContextRoutesContextProviders;