import React, { Fragment } from 'react';

import ClubBulkRenewMembersReadOnlyLargeGrid from './ClubBulkRenewMembersReadOnlyLargeGrid';
import ClubBulkRenewMembersReadOnlySmallGrid from './ClubBulkRenewMembersReadOnlySmallGrid';

const ClubBulkRenewMembersReadOnlyGrid = ({ gridData, isLoading }) => {
  return (
    <Fragment>
      {isLoading === false ?
        <div className="row">
          <div className="col-xs-12">
              <p>{gridData.length === 1 ? `${gridData.length} Unselected Membership` : `${gridData.length} Unselected Memberships`}</p>
          </div>
        </div>
        : <Fragment><div className="row usas-extra-top-margin usas-extra-bottom-margin"></div></Fragment>}
      <ClubBulkRenewMembersReadOnlyLargeGrid
        gridData={gridData}
        isLoading={isLoading}
      />
      <ClubBulkRenewMembersReadOnlySmallGrid
        gridData={gridData}
        isLoading={isLoading}
      />
    </Fragment>
  )
};

export default ClubBulkRenewMembersReadOnlyGrid;