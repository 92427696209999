import { useContext } from 'react';

import { CurrentSeasonOfferingStateContext } from './CurrentSeasonOfferingContextProvider';

import CurrentSeasonOfferingData from './CurrentSeasonOfferingData';

const useCurrentSeasonOfferingData = () => {
  const [currentSeasonOfferingState, setCurrentSeasonOfferingState] = useContext(CurrentSeasonOfferingStateContext);

  const getCurrentSeasonOfferings = (orgUnitId, isAthleteRole, isActive) => {
    CurrentSeasonOfferingData.getCurrentSeasonOfferingData(currentSeasonOfferingState, setCurrentSeasonOfferingState, orgUnitId, isAthleteRole, isActive);
  };

  return {
    currentSeasonOfferingState,
    getCurrentSeasonOfferings
  };
};

export default useCurrentSeasonOfferingData;