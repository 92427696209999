import { useEffect } from 'react';
import { useNavigate, useLocation } from '../../../../common/wrappers/ReactRouterDom';

import NavLinks from '../NavLinks';

import useRecognitionProgramData from '../../../../common/state/recognitionProgram/UseRecognitionProgramData';
import useEnvironmentVariableData from '../../../../common/state/environmentVariable/UseEnvironmentVariableData';

import Constants from '../../../../common/utils/Constants';

const useSafeSportCategoryMeasure = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { environmentVariableState, KPI_BASIC_MEASURE_TYPE_ID, KPI_COACH_ROSTER_MEASURE_TYPE_ID, KPI_COACH_YEARS_MEASURE_TYPE_ID
  } = useEnvironmentVariableData();
  const { programFocusState, updateProgramFocus } = useRecognitionProgramData();

  const onRedirectToCategory = (performGet) => {
    navigate(NavLinks.SAFE_SPORT_PROGRAM_CATEGORY, { state: { performGet } });
  };

  useEffect(() => {
    if (location.state?.categoryMeasureId > 0) {
      updateProgramFocus(programFocusState?.programLevelInstanceId, programFocusState?.programCategoryId, location.state?.categoryMeasureId);
    } else if (!(programFocusState?.categoryMeasureId > 0)) {
      navigate(NavLinks.SAFE_SPORT_ROOT);
    } else {
      updateProgramFocus(programFocusState?.programLevelInstanceId, programFocusState?.programCategoryId, programFocusState?.categoryMeasureId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function getIsReadonly(status) {
    if (status !== Constants.WORKFLOW_STATUS_IN_PROGRESS && status !== Constants.WORKFLOW_STATUS_NOT_SUBMITTED) {
      return true;
    } else {
      return false;
    }
  }

  return {
    isLoading: environmentVariableState.isLoading,
    isReadonly: getIsReadonly(programFocusState.programLevelObj?.status),
    programFocusState,
    KPI_BASIC_MEASURE_TYPE_ID,
    KPI_COACH_ROSTER_MEASURE_TYPE_ID,
    KPI_COACH_YEARS_MEASURE_TYPE_ID,
    onRedirectToCategory
  };
};

export default useSafeSportCategoryMeasure;