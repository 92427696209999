import React, { Fragment } from 'react';

import useAddLSCSecurityRole from './UseAddLSCSecurityRole';

import OrgRoleStaffRoleTreeView from '../../../../../common/components/tree/orgRoleTree/OrgRoleStaffRoleTreeView';

import PrimaryButton from '../../../../../common/components/buttons/PrimaryButton';
import SecondaryButton from '../../../../../common/components/buttons/SecondaryButton';
import DatePicker from '../../../../../common/components/datepickers/DatePicker';
import Headings from '../../../../../common/components/headings/Headings';
import PopUpModal from '../../../../../common/components/dialogs/PopUpModal';
import YesNoSwitch from '../../../../../common/components/yesNoSwitch/YesNoSwitch';
import SearchMemberPopup from '../../../../../common/components/searches/searchMember/SearchMemberPopup';

import Constants from '../../../../../common/utils/Constants';

import global from '../../../../../common/components/GlobalStyle.module.css';

const AddLSCSecurityRole = () => {
  const {
    isLoading,
    isSaving,
    formState,
    errorState,
    LSC_SECURITY_GROUPS_ROLE_GROUP_ID,
    onFormValueChanged,
    handleSubmit,
    onBackClicked,
    setFormData,
    onSaveClicked
  } = useAddLSCSecurityRole();

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>Add LSC Security Role Member</Headings.H3>
        </div>
      </div>
      <form onSubmit={handleSubmit} noValidate>
        <div className="row">
          <div className="col-xs-12 col-sm-6 col-md-4">
            {LSC_SECURITY_GROUPS_ROLE_GROUP_ID && (
              <OrgRoleStaffRoleTreeView
                label={'LSC Security Role*'}
                name={'orgRole'}
                value={formState.orgRole}
                error={errorState.orgRole}
                message={errorState.orgRole}
                singleSelect={true}
                onChange={(value) => onFormValueChanged('orgRole', value)}
                roleGroupId={LSC_SECURITY_GROUPS_ROLE_GROUP_ID} />
            )}
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4">
            <SearchMemberPopup formState={formState} errorState={errorState} setFormData={setFormData} onFormValueChanged={onFormValueChanged} />
          </div>
        </div>
        <div className='row'>
          <div className='col-xs-12 col-sm-6 col-md-4'>
            <YesNoSwitch
              label={'No Expiration Date?'}
              name={'hasNoExpirationDate'}
              checked={formState.hasNoExpirationDate === true}
              error={formState.hasNoExpirationDate}
              message={formState.hasNoExpirationDate}
              onChange={(value) => onFormValueChanged('hasNoExpirationDate', value)} />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4">
            <DatePicker
              label={"Effective Date*"}
              name={"effectiveDate"}
              value={formState.effectiveDate}
              error={errorState.effectiveDate}
              message={errorState.effectiveDate}
              startYearOffset={-140}
              onChange={(value) => onFormValueChanged('effectiveDate', value)} />
          </div>
          {formState.hasNoExpirationDate === false &&
            <div className="col-xs-12 col-sm-6 col-md-4">
              <DatePicker
                label={"Expiration Date*"}
                name={"expirationDate"}
                value={formState.expirationDate}
                error={errorState.expirationDate}
                message={errorState.expirationDate}
                startYearOffset={-140}
                onChange={(value) => onFormValueChanged('expirationDate', value)} />
            </div>
          }
        </div>
        {errorState.apiError ?
          <div className="row">
            <div className="col-xs-12">
              <p className={global.ErrorMessage}>{errorState.apiError}</p>
            </div>
          </div> : <Fragment />}
        {errorState.multipleActiveRoles ?
          <div className="row">
            <div className="col-xs-12">
              <p className={global.ErrorMessage}>{errorState.multipleActiveRoles}</p>
            </div>
          </div> : <Fragment />}
        <div className="row usas-extra-top-margin usas-extra-bottom-margin">
          <div className="col-xs-12 usas-extra-top-margin">
            <PrimaryButton type="button" onClick={onSaveClicked}>Save</PrimaryButton>&nbsp;
            <SecondaryButton type="button" onClick={onBackClicked}>Back</SecondaryButton>
          </div>
        </div>
      </form>
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={isLoading} />
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.SAVING_MSG}
        displayPopUp={isSaving} />
    </Fragment>
  );
};

export default AddLSCSecurityRole;