import { isValidNumber } from '../../../../../common/utils/validation';

const ClubGroupsAddRemoveMembersGridValidation = (formState) => {
  let errors = {};

  if (formState.startAge !== '' && formState.endAge !== '') {
    const startAge = parseInt(formState.startAge);
    const endAge = parseInt(formState.endAge);

    // Start Age
    if (!isValidNumber(formState.startAge)) {
      errors.startAge = 'Must be a number';
    }
    else if (endAge < startAge) {
      errors.startAge = 'Must be less than end age';
    }

    // End Age
    if (!isValidNumber(formState.endAge)) {
      errors.endAge = 'Must be a number';
    }
    else if (endAge < startAge) {
      errors.endAge = 'Must be greater than start age';
    }
  } else if (formState.startAge !== '') {
    errors.endAge = 'Must enter an end age';
  } else if (formState.endAge !== '') {
    errors.startAge = 'Must enter a start age';
  }

  return errors;
};

export default ClubGroupsAddRemoveMembersGridValidation;