import { isValidEmail, isValidName, isValid5DigitZip, isValidBirthDate, isValidDate } from '../../../../common/utils/validation';
import Constants from '../../../../common/utils/Constants';

export const localValidate = (formState) => {
  let errors = {};

  if (formState.firstName.trim() === '') {
    errors.firstName = 'First Name is required';
  } else if (!isValidName(formState.firstName.trim())) {
    errors.firstName = 'First Name cannot contain numbers, special characters, or exceed 100 characters';
  }

  if (formState.lastName.trim() === '') {
    errors.lastName = 'Last Name is required';
  } else if (!isValidName(formState.lastName.trim())) {
    errors.lastName = 'Lasst Name cannot contain numbers, special characters, or exceed 100 characters';
  }

  if (formState.competitionGenderTypeId === Constants.DEFAULT_ID) {
    errors.competitionGenderTypeId = 'A Competition Gender must be selected';
  }

  if (isValid5DigitZip(formState.postalCode) === false) {
    errors.postalCode = 'Postal code is invalid';
  }

  if (isValidBirthDate(formState.birthDate) === false || isValidDate(formState.birthDate) === false) {
    errors.birthDate = 'Birth date is invalid';
  } 

  if (isValidEmail(formState.email) === false) {
    errors.email = 'Email is invalid';
  }

  return errors;
}

const LearnToSwimVoucherFormValidation = async (formState) => {
  let errors = localValidate(formState);

  return errors ? errors : {};
};

export default LearnToSwimVoucherFormValidation;