import React from 'react';

import useLearnToSwimCurriculumDropdown from './UseLearnToSwimCurriculumDropdown';

import Dropdown from '../../../../common/components/dropdowns/Dropdown';

import global from '../../../../common/components/GlobalStyle.module.css';

const LearnToSwimCurriculumDropdown = ({ label, name, value, error, message, onChange, categoryMeasureId }) => {
  const { ltsCurriculumState } = useLearnToSwimCurriculumDropdown(categoryMeasureId);

  return ltsCurriculumState.message
    ? <span className={global.LoadingMsg}>{ltsCurriculumState.message}</span>
    : (
      <Dropdown
        error={error}
        label={label}
        message={message}
        options={ltsCurriculumState.items}
        name={name}
        value={value}
        onChange={onChange}
        isLoading={ltsCurriculumState.isArrayLoading} />
    );
};

export default LearnToSwimCurriculumDropdown;