import { useEffect, useState } from 'react';
import { useNavigate } from '../../../../common/wrappers/ReactRouterDom';

import NavLinks from '../NavLinks';

import useClubRegistrationNavData from '../../../state/clubRegistration/leftNavigation/UseClubRegistrationNavData';
import useClubRegistrationNewData from '../../../state/clubRegistrationNew/UseClubRegistrationNewData';
import useUsasClubFilesData from '../../../state/clubFiles/UseUsasClubFilesData';

import Constants from '../../../../common/utils/Constants';
import ClubRegistrationConstants from '../utils/ClubRegistrationConstants';

const MISSION_STATEMENT = 'Mission Statement';
const BUDGET_OR_BUSINESS_PLAN = 'First Year Budget or Business Plan';
const SAVING_ERROR_MSG = 'The last action is still saving. Please try again.';
const FILE_TYPE_ERROR_MSG = 'File type not permitted. Please provide a (*.pdf), (*.doc), or (*.docx) file.';
const REQUIRED_ERROR_MSG = 'An uploaded mission statement and budget are required';
const FILE_MODAL_TITLE = 'Remove Uploaded File?';

const useClubRegistrationMissionStatementBudget = () => {
  const navigate = useNavigate();
  const [state, setState] = useState({ submitButton: '', tryRedirect: false, error: '' });
  const [submitPrequisitesState, setSubmitPrequisitesState] = useState({ areSubmitPrerequisitesDone: false });
  const [fileState, setFileState] = useState({
    missionStatementFile: false, missionStatementFileUrl: '', missionStatementFileName: '',
    budgetFile: false, budgetFileUrl: '', budgetFileName: ''
  });
  const [modalState, setModalState] = useState(getInitialModalState());
  const [removeFileModalState, setRemoveFileModalState] = useState(getInitialRemoveFileModalState());
  const { clubRegistrationNavState, setClubRegistrationNavState } = useClubRegistrationNavData();
  const { clubRegistrationState, putUsasClub } = useClubRegistrationNewData();
  const { usasClubFilesState, postUsasClubFiles, getFileNameFromSavedUploadUrl, getFileNameFromUrl, GET_DOWNLOAD_TEMPLATE_PATH,
    GET_USAS_CLUB_FILES_PATH } = useUsasClubFilesData();

  const editClubObj = (name, type) => {
    let clubCopy = JSON.parse(JSON.stringify(clubRegistrationState.objData));

    if (clubCopy.usasClubAcknowledgement.length === 1) {
      if (type === MISSION_STATEMENT) {
        clubCopy.usasClubAcknowledgement = [{
          ...clubCopy.usasClubAcknowledgement[0],
          missionStatementUrl: name
        }]
      }
      else if (type === BUDGET_OR_BUSINESS_PLAN) {
        clubCopy.usasClubAcknowledgement = [{
          ...clubCopy.usasClubAcknowledgement[0],
          budgetUrl: name
        }]
      }
    }
    else if (clubCopy.usasClubAcknowledgement.length === 0) {
      clubCopy.usasClubAcknowledgement = [{
        usasClubId: clubCopy.usasClubId,
        missionStatementUrl: type === MISSION_STATEMENT ? name : null,
        budgetUrl: type === BUDGET_OR_BUSINESS_PLAN ? name : null
      }]
    }

    return clubCopy;
  }

  const onUploadMissionStatementClicked = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    };
    if (clubRegistrationState.isSaving === true) {
      setState({ ...state, submitButton: '', tryRedirect: false, error: SAVING_ERROR_MSG });
    }
    else {
      setState({ ...state, submitButton: '', tryRedirect: false, error: '' });
      if (e.target.files[0]) {
        const file = e.target.files[0];
        const fileName = clubRegistrationState.objData.usasClubId + ' ' + e.target.files[0].name;
        const fileType = e.target.files[0].type;
        if (fileType === Constants.FILE_TYPE_PDF || fileType === Constants.FILE_TYPE_DOC ||
          fileType === Constants.FILE_TYPE_DOCX) {
          setFileState({ ...fileState, missionStatementFile: true, budgetFile: false });
          setState({ ...state, submitButton: '', tryRedirect: false, error: '' });
          postUsasClubFiles(file, fileName);
        }
        else {
          setState({ ...state, submitButton: '', tryRedirect: false, error: FILE_TYPE_ERROR_MSG });
        }
      }
    }
  }

  const onRemoveMissionStatementClicked = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    };
    setRemoveFileModalState({ ...getInitialRemoveFileModalState() });
    if (clubRegistrationState.isSaving === true) {
      setState({ ...state, submitButton: '', tryRedirect: false, error: SAVING_ERROR_MSG });
    }
    else {
      const navIndex = clubRegistrationNavState.findIndex(x =>
        x.name === ClubRegistrationConstants.MISSION_STATEMENT_BUDGET)
      clubRegistrationNavState[navIndex] =
      {
        ...clubRegistrationNavState[navIndex],
        isCurrent: true,
        isComplete: false,
        isVisited: true,
        arePrerequisitesDone: true
      }
      const submitNavIndex = clubRegistrationNavState.findIndex(x =>
        x.name === ClubRegistrationConstants.SUBMIT)
      clubRegistrationNavState[submitNavIndex] =
      {
        ...clubRegistrationNavState[submitNavIndex],
        isCurrent: false,
        isComplete: false,
        isVisited: false,
        arePrerequisitesDone: false
      }
      setSubmitPrequisitesState({ ...submitPrequisitesState, areSubmitPrerequisitesDone: false });
      setClubRegistrationNavState(clubRegistrationNavState);
      setState({ ...state, submitButton: '', tryRedirect: false, error: '' });
      setFileState({ ...fileState, missionStatementFileUrl: '', missionStatementFileName: '' });
      const name = null;
      putUsasClub(clubRegistrationState.objData.usasClubId, editClubObj(name, MISSION_STATEMENT));
    }
  }

  const onUploadBudgetClicked = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    };
    if (clubRegistrationState.isSaving === true) {
      setState({ ...state, submitButton: '', tryRedirect: false, error: SAVING_ERROR_MSG });
    }
    else {
      setState({ ...state, submitButton: '', tryRedirect: false, error: '' });
      if (e.target.files[0]) {
        const file = e.target.files[0];
        const fileName = clubRegistrationState.objData.usasClubId + ' ' + e.target.files[0].name;
        const fileType = e.target.files[0].type;
        if (fileType === Constants.FILE_TYPE_PDF || fileType === Constants.FILE_TYPE_DOC ||
          fileType === Constants.FILE_TYPE_DOCX) {
          setFileState({ ...fileState, missionStatementFile: false, budgetFile: true });
          setState({ ...state, submitButton: '', tryRedirect: false, error: '' });
          postUsasClubFiles(file, fileName);
        }
        else {
          setState({ ...state, submitButton: '', tryRedirect: false, error: FILE_TYPE_ERROR_MSG });
        }
      }
    }
  }

  const onRemoveBudgetClicked = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    };
    setRemoveFileModalState({ ...getInitialRemoveFileModalState() });
    if (clubRegistrationState.isSaving === true) {
      setState({ ...state, submitButton: '', tryRedirect: false, error: SAVING_ERROR_MSG });
    }
    else {
      const navIndex = clubRegistrationNavState.findIndex(x => x.name === ClubRegistrationConstants.MISSION_STATEMENT_BUDGET)
      clubRegistrationNavState[navIndex] =
      {
        ...clubRegistrationNavState[navIndex],
        isCurrent: true,
        isComplete: false,
        isVisited: true,
        arePrerequisitesDone: true
      }
      const submitNavIndex = clubRegistrationNavState.findIndex(x => x.name === ClubRegistrationConstants.SUBMIT)
      clubRegistrationNavState[submitNavIndex] =
      {
        ...clubRegistrationNavState[submitNavIndex],
        isCurrent: false,
        isComplete: false,
        isVisited: false,
        arePrerequisitesDone: false
      }
      setSubmitPrequisitesState({ ...submitPrequisitesState, areSubmitPrerequisitesDone: false });
      setClubRegistrationNavState(clubRegistrationNavState);
      setState({ ...state, submitButton: '', tryRedirect: false, error: '' });
      setFileState({ ...fileState, budgetFileUrl: '', budgetFileName: '' });
      const name = null;
      putUsasClub(clubRegistrationState.objData.usasClubId, editClubObj(name, BUDGET_OR_BUSINESS_PLAN));
    }
  }

  const onContinueClicked = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    };

    if (clubRegistrationState.objData?.usasClubAcknowledgement?.length > 0) {
      if (clubRegistrationState.objData.usasClubAcknowledgement.length === 1) {
        if (clubRegistrationState.objData?.usasClubAcknowledgement[0].missionStatementUrl !== null &&
          clubRegistrationState.objData?.usasClubAcknowledgement[0].budgetUrl !== null &&
          clubRegistrationState.objData?.usasClubAcknowledgement[0].missionStatementUrl.includes("Bad Request") === false &&
          clubRegistrationState.objData?.usasClubAcknowledgement[0].budgetUrl.includes("Bad Request") === false
        ) {
          setState({ ...state, tryRedirect: true, submitButton: ClubRegistrationConstants.CONTINUE, error: '' });
        }
        else {
          setState({ ...state, submitButton: ClubRegistrationConstants.CONTINUE, error: REQUIRED_ERROR_MSG });
        }
      }
    }
    else {
      setState({ ...state, submitButton: ClubRegistrationConstants.CONTINUE, error: REQUIRED_ERROR_MSG });
    }
  }

  const onSaveAndExitClicked = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    if (clubRegistrationState.objData?.usasClubAcknowledgement?.length > 0) {
      if (clubRegistrationState.objData.usasClubAcknowledgement.length === 1) {
        if (clubRegistrationState.objData?.usasClubAcknowledgement[0].missionStatementUrl !== null &&
          clubRegistrationState.objData?.usasClubAcknowledgement[0].budgetUrl !== null &&
          clubRegistrationState.objData?.usasClubAcknowledgement[0].missionStatementFileName.includes("Bad Request") === false &&
          clubRegistrationState.objData?.usasClubAcknowledgement[0].budgetFileName.includes("Bad Request") === false
        ) {
          setState({ ...state, submitButton: ClubRegistrationConstants.EXIT, error: '' });
        }
        else {
          setState({ ...state, submitButton: ClubRegistrationConstants.EXIT, error: REQUIRED_ERROR_MSG });
        }
      }
    }
    else {
      setState({ ...state, submitButton: ClubRegistrationConstants.EXIT, error: REQUIRED_ERROR_MSG });
    }
  }

  const onBackClicked = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    if (clubRegistrationState.objData?.usasClubAcknowledgement?.length > 0) {
      if (clubRegistrationState.objData.usasClubAcknowledgement.length === 1) {
        if (clubRegistrationState.objData?.usasClubAcknowledgement[0].missionStatementUrl !== null &&
          clubRegistrationState.objData?.usasClubAcknowledgement[0].budgetUrl !== null &&
          clubRegistrationState.objData?.usasClubAcknowledgement[0].missionStatementUrl.includes("Bad Request") === false &&
          clubRegistrationState.objData?.usasClubAcknowledgement[0].budgetUrl.includes("Bad Request") === false
        ) {
          setState({ ...state, tryRedirect: true, submitButton: ClubRegistrationConstants.BACK, error: '' });
        }
        else {
          setState({ ...state, submitButton: ClubRegistrationConstants.BACK, error: REQUIRED_ERROR_MSG });
        }
      }
    }
    else {
      setState({ ...state, submitButton: ClubRegistrationConstants.BACK, error: REQUIRED_ERROR_MSG });
    }
  }

  const onOpenModal = () => {
    if (state.error !== '') {
      setModalState({ ...modalState, displayPopUp: true });
    }
    else {
      setModalState({ ...modalState, modalTitle: ClubRegistrationConstants.EXIT_CONFIRMATION, displayPopUp: true });
    }
  }

  const onModalContinue = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    setState({ ...state, tryRedirect: true });
    setModalState({ ...getInitialModalState() });
  }

  const onModalCanceled = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    setModalState({ ...getInitialModalState() });
  }

  const onOpenRemoveFileModalClicked = (e, fileType) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    setRemoveFileModalState({
      ...removeFileModalState, fileType: fileType, fileName: fileType === MISSION_STATEMENT
        ? fileState.missionStatementFileName : fileState.budgetFileName, displayPopUp: true
    });
  }

  const onRemoveFileModalCanceled = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    setRemoveFileModalState({ ...getInitialRemoveFileModalState() });
  }

  const updateNavState = () => {
    for (let i = 0; i < clubRegistrationNavState.length; i++) {
      clubRegistrationNavState[i].isCurrent = false
    }
    const navIndex = clubRegistrationNavState.findIndex(x => x.name === ClubRegistrationConstants.MISSION_STATEMENT_BUDGET)
    
    if (clubRegistrationState.objData?.usasClubAcknowledgement?.length > 0) {
      if (clubRegistrationState.objData.usasClubAcknowledgement.length === 1) {
        if (clubRegistrationState.objData.usasClubAcknowledgement[0].missionStatementUrl !== null &&
          clubRegistrationState.objData.usasClubAcknowledgement[0].budgetUrl !== null &&
          clubRegistrationState.objData?.usasClubAcknowledgement[0].missionStatementUrl.includes("Bad Request") === false &&
          clubRegistrationState.objData?.usasClubAcknowledgement[0].budgetUrl.includes("Bad Request") === false
        ) {
          clubRegistrationNavState[navIndex] =
          {
            ...clubRegistrationNavState[navIndex],
            isCurrent: true,
            isComplete: true,
            isVisited: true,
            arePrerequisitesDone: true
          }
        }
        else {
          clubRegistrationNavState[navIndex] =
          {
            ...clubRegistrationNavState[navIndex],
            isCurrent: true,
            isComplete: false,
            isVisited: true,
            arePrerequisitesDone: true
          }
        }
      }
    }
    else {
      clubRegistrationNavState[navIndex] =
      {
        ...clubRegistrationNavState[navIndex],
        isCurrent: true,
        isComplete: false,
        isVisited: true,
        arePrerequisitesDone: true
      }
    }
    //Submit
    let areSubmitPrerequisitesDone = true;
    let isSubmitComplete = false;
    for (let i = 0; i < clubRegistrationNavState.length; i++) {
      if (clubRegistrationNavState[i].name !== ClubRegistrationConstants.SUBMIT
        && clubRegistrationNavState[i].isComplete === false) {
        areSubmitPrerequisitesDone = false;
      }
      if (clubRegistrationNavState[i].name === ClubRegistrationConstants.SUBMIT) {
        if (clubRegistrationState.objData.status === ClubRegistrationConstants.PENDING_STATUS ||
          clubRegistrationState.objData.status === ClubRegistrationConstants.APPROVED_STATUS) {
          isSubmitComplete = true
        }
      }
    }
    const submitNavIndex = clubRegistrationNavState.findIndex(x => x.name === ClubRegistrationConstants.SUBMIT)
    clubRegistrationNavState[submitNavIndex] =
    {
      ...clubRegistrationNavState[submitNavIndex],
      isCurrent: false,
      isComplete: isSubmitComplete,
      isVisited: areSubmitPrerequisitesDone === false ? false : clubRegistrationNavState[submitNavIndex].isVisited,
      arePrerequisitesDone: areSubmitPrerequisitesDone
    }
    setSubmitPrequisitesState({ ...state, areSubmitPrerequisitesDone: areSubmitPrerequisitesDone });
    setClubRegistrationNavState(clubRegistrationNavState);
  }

  useEffect(() => {
    if (clubRegistrationState.isObjLoaded === false) {
      navigate(NavLinks.CLUB_REGISTRATION_ENTRY_NEW);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const navIndex = clubRegistrationNavState.findIndex(x => x.name === ClubRegistrationConstants.MISSION_STATEMENT_BUDGET)
    if (clubRegistrationNavState[navIndex].isCurrent === false) {
      updateNavState();
      if (clubRegistrationState.objData?.usasClubAcknowledgement?.length === 1) {
        setFileState({
          ...fileState,
          missionStatementFileUrl: clubRegistrationState.objData.usasClubAcknowledgement[0].missionStatementUrl !== null ?
            clubRegistrationState.objData.usasClubAcknowledgement[0].missionStatementUrl : '',
          missionStatementFileName: clubRegistrationState.objData.usasClubAcknowledgement[0].missionStatementUrl !== null ?
            getFileNameFromUrl(clubRegistrationState.objData.usasClubAcknowledgement[0].missionStatementUrl) : '',
          budgetFileUrl: clubRegistrationState.objData.usasClubAcknowledgement[0].budgetUrl !== null ?
            clubRegistrationState.objData.usasClubAcknowledgement[0].budgetUrl : '',
          budgetFileName: clubRegistrationState.objData.usasClubAcknowledgement[0].budgetUrl !== null ?
            getFileNameFromUrl(clubRegistrationState.objData.usasClubAcknowledgement[0].budgetUrl) : '',
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clubRegistrationNavState])

  useEffect(() => {
    const submitNavIndex = clubRegistrationNavState.findIndex(x => x.name === ClubRegistrationConstants.SUBMIT)
    if (clubRegistrationNavState[submitNavIndex].arePrerequisitesDone === false && submitPrequisitesState.areSubmitPrerequisitesDone === true) {
      updateNavState();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clubRegistrationNavState, submitPrequisitesState.areSubmitPrerequisitesDone])

  useEffect(() => {
    if (Object.keys(clubRegistrationState.objData).length > 0) {
      updateNavState();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clubRegistrationState.objData])

  useEffect(() => {
    if (usasClubFilesState.isSaved === true && Object.keys(usasClubFilesState.objData).length > 0) {
      if (fileState.missionStatementFile === true) {
        setFileState({ ...fileState, missionStatementFile: false, missionStatementFileUrl: usasClubFilesState.objData.uploadUrl, missionStatementFileName: getFileNameFromSavedUploadUrl() });
        putUsasClub(clubRegistrationState.objData.usasClubId, editClubObj(usasClubFilesState.objData.uploadUrl, MISSION_STATEMENT));
      }
      else if (fileState.budgetFile === true) {
        setFileState({ ...fileState, budgetFile: false, budgetFileUrl: usasClubFilesState.objData.uploadUrl, budgetFileName: getFileNameFromSavedUploadUrl() });
        putUsasClub(clubRegistrationState.objData.usasClubId, editClubObj(usasClubFilesState.objData.uploadUrl, BUDGET_OR_BUSINESS_PLAN));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [usasClubFilesState, fileState])

  useEffect(() => {
    //Handle validation modal and navigation
    if (state.submitButton !== '' && clubRegistrationState.isObjLoaded === true) {
      if (clubRegistrationState.isSaving === true) {
        setState({ ...state, submitButton: '', tryRedirect: false, error: SAVING_ERROR_MSG });
      }
      else if (state.tryRedirect === false) {
        if (state.error !== '' || state.submitButton === ClubRegistrationConstants.EXIT) {
          onOpenModal();
        }
      }
      else if (state.tryRedirect === true) {
        //If no error (docs not uploaded) or this is a renew (length > 1) set isComplete to true
        if (state.error === '' 
          ) {
          const navIndex = clubRegistrationNavState.findIndex(x => x.name === ClubRegistrationConstants.MISSION_STATEMENT_BUDGET)
          clubRegistrationNavState[navIndex] =
          {
            ...clubRegistrationNavState[navIndex],
            isCurrent: false,
            isComplete: true,
            isVisited: true,
            arePrerequisitesDone: true
          }
          //Submit
          let areSubmitPrerequisitesDone = true;
          let isSubmitComplete = false;
          for (let i = 0; i < clubRegistrationNavState.length; i++) {
            if (clubRegistrationNavState[i].name !== ClubRegistrationConstants.SUBMIT
              && clubRegistrationNavState[i].isComplete === false) {
              areSubmitPrerequisitesDone = false;
            }
            if (clubRegistrationNavState[i].name === ClubRegistrationConstants.SUBMIT) {
              if (clubRegistrationState.objData.status === ClubRegistrationConstants.PENDING_STATUS ||
                clubRegistrationState.objData.status === ClubRegistrationConstants.APPROVED_STATUS) {
                isSubmitComplete = true
              }
            }
          }
          const submitNavIndex = clubRegistrationNavState.findIndex(x => x.name === ClubRegistrationConstants.SUBMIT)
          clubRegistrationNavState[submitNavIndex] =
          {
            ...clubRegistrationNavState[submitNavIndex],
            isCurrent: false,
            isComplete: isSubmitComplete,
            isVisited: areSubmitPrerequisitesDone === false ? false : clubRegistrationNavState[submitNavIndex].isVisited,
            arePrerequisitesDone: areSubmitPrerequisitesDone
          }
          setClubRegistrationNavState(clubRegistrationNavState);
        }
        if (state.submitButton === ClubRegistrationConstants.CONTINUE) {
          navigate(NavLinks.CLUB_REGISTRATION_EMERGENCY_PLAN);
        }
        else if (state.submitButton === ClubRegistrationConstants.EXIT) {
          navigate(NavLinks.CLUB_REGISTRATION_ENTRY_NEW);
        }
        else if (state.submitButton === ClubRegistrationConstants.BACK) {
          navigate(NavLinks.CLUB_REGISTRATION_FACILITY);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state, clubRegistrationState.isObjLoaded])

  return {
    clubRegistrationNavState,
    clubRegistrationState,
    state,
    fileState,
    modalState,
    removeFileModalState,
    usasClubFilesState,
    onUploadMissionStatementClicked,
    onRemoveMissionStatementClicked,
    onUploadBudgetClicked,
    onRemoveBudgetClicked,
    onOpenRemoveFileModalClicked,
    onRemoveFileModalCanceled,
    onModalContinue,
    onModalCanceled,
    onContinueClicked,
    onSaveAndExitClicked,
    onBackClicked,
    GET_DOWNLOAD_TEMPLATE_PATH,
    GET_USAS_CLUB_FILES_PATH
  }
}

export default useClubRegistrationMissionStatementBudget;

function getInitialModalState() {
  return {
    displayPopUp: false,
    modalTitle: ClubRegistrationConstants.MISSING_REQUIRED_INFORMATION
  }
};

function getInitialRemoveFileModalState() {
  return {
    displayPopUp: false,
    fileType: '',
    fileName: '',
    modalTitle: FILE_MODAL_TITLE
  }
};