import { Fragment } from 'react';

import useSearchClub from './UseSearchClub';

import Constants from '../../../utils/Constants';

import SearchClubGrid from './SearchClubGrid';

import Input from '../../inputs/Input';
import Dropdown from '../../dropdowns/Dropdown';
import PrimaryButton from '../../buttons/PrimaryButton';
import SecondaryButton from '../../buttons/SecondaryButton';
import Headings from '../../headings/Headings';
import LscDropdown from '../../dropdowns/lscDropdown/LscDropdown';
import ApprovedClubCombobox from '../../comboboxes/approvedClubCombobox/ApprovedClubCombobox';
import StatesCombobox from '../../comboboxes/statesCombobox/StatesCombobox';
import PopUpModal from '../../dialogs/PopUpModal';

import global from '../../GlobalStyle.module.css';

const Form = ({ handleSubmit, formState, errorState, onFormValueChanged, onValueTextPairChanged, clearForm, sortByOptions,
  isCurrentOptions, onCancelClicked }) => (
  <form onSubmit={handleSubmit} noValidate>
    <div className="row">
      <div className="col-xs-12 col-sm-6 col-md-4">
        <LscDropdown
          label="LSC"
          name="lscOrgUnitId"
          value={formState.lscOrgUnitId}
          error={errorState.lscOrgUnitId}
          message={errorState.lscOrgUnitId}
          onChange={(newValue, newValueLabel, e) => {
            onValueTextPairChanged(newValue, 'lscOrgUnitId', newValueLabel, 'lscName', e);
            onValueTextPairChanged('', 'orgUnitId', '', 'orgUnitName', e);
          }} />
      </div>
      <div className="col-xs-12 col-sm-6 col-md-4">
        <ApprovedClubCombobox
          label={formState.orgUnitLabel}
          name="orgUnitName"
          valueToMatch={formState.orgUnitName}
          error={errorState.orgUnitId}
          message={errorState.orgUnitId}
          onChange={(newValue, newValueLabel, e) => { onValueTextPairChanged(newValue, 'orgUnitId', newValueLabel, 'orgUnitName', e); }}
          lscId={formState.lscOrgUnitId} />
      </div>
      <div className="col-xs-12 col-sm-6 col-md-4">
        <Dropdown
          label="Club Status"
          options={isCurrentOptions}
          name="isCurrentId"
          value={formState.isCurrentId}
          error={errorState.isCurrentId}
          message={errorState.isCurrentId}
          isLoading={false}
          onChange={(newValue, newValueLabel, e) => {
            e && e.target && e.target.value &&
              onValueTextPairChanged(newValue, 'isCurrentId', newValueLabel, 'isCurrentName');
          }} />
      </div>
      <div className="col-xs-12 col-sm-6 col-md-4">
        <Input
          label="City"
          name="city"
          value={formState.city}
          error={errorState.city}
          message={errorState.city}
          onChange={(value) => { onFormValueChanged('city', value); }} />
      </div>
      <div className="col-xs-12 col-sm-6 col-md-4">
        <StatesCombobox
          label="State"
          name="stateCode"
          valueToMatch={formState.stateName}
          error={errorState.stateCode}
          message={errorState.stateCode}
          isUSA={true}
          onChange={(newValue, newValueLabel) => {
            onValueTextPairChanged(newValue, "stateCode", newValueLabel, "stateName");
          }} />
      </div>
      <div className="col-xs-12 col-sm-6 col-md-4">
        <Input
          label="Zip Code"
          name="zip"
          value={formState.zip}
          error={errorState.zip}
          message={errorState.zip}
          onChange={(value) => { onFormValueChanged('zip', value); }} />
      </div>
      <div className="col-xs-12 col-sm-6 col-md-4">
        <Dropdown
          label="Sort Results By"
          name="sortById"
          options={sortByOptions}
          value={formState.sortById}
          error={errorState.sortById}
          message={errorState.sortById}
          showEmptySelection={false}
          isLoading={false}
          onChange={(newValue, newValueLabel, e) => {
            e && e.target && e.target.value &&
              onValueTextPairChanged(newValue, 'sortById', newValueLabel, 'sortByName');
          }} />
      </div>
    </div>
    {(errorState.filter || errorState.errorOnSubmitAction) &&
      <div className="row">
        <div className="col-xs-12">
          <p className={global.ErrorMessage}>{errorState.filter ? errorState.filter : ''}</p>
          <p className={global.ErrorMessage}>{errorState.errorOnSubmitAction || ''}</p>
        </div>
      </div>}
    <div className="row usas-extra-top-margin usas-extra-bottom-margin">
      <div className="col-xs-12 usas-extra-top-margin">
        <PrimaryButton type="submit">Search for Club</PrimaryButton>&nbsp;
        <SecondaryButton type="button" onClick={() => clearForm()}>Clear Form</SecondaryButton>&nbsp;
        {onCancelClicked ? <SecondaryButton type="button" onClick={() => onCancelClicked()}>Back</SecondaryButton> : <Fragment />}
      </div>
    </div>
  </form >
);

// TODO:  Get rid of searchClubState from parameter list.  It doesn't belong here
const SearchClub = ({ pageTitle, onEdit, searchClubState, onCancelClicked, sendObjOnEdit }) => {
  const {
    formState,
    errorState,
    baseUsasClubState,
    lscState,
    clearForm,
    handleSubmit,
    onFormValueChanged,
    onValueTextPairChanged,
    sortByOptions,
    isCurrentOptions,
    environmentVariableState,
    onFiltersToggle
  } = useSearchClub();

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>{pageTitle}</Headings.H3>
        </div>
      </div>
      {searchClubState !== undefined ?
        //toggles filters and results
        <Fragment>
          <div className={searchClubState.showFilters === true ? global.DisplayComponent : global.HideComponent}>
            <Form
              formState={formState}
              errorState={errorState}
              clearForm={clearForm}
              handleSubmit={handleSubmit}
              onFormValueChanged={onFormValueChanged}
              onValueTextPairChanged={onValueTextPairChanged}
              sortByOptions={sortByOptions}
              isCurrentOptions={isCurrentOptions}
              onCancelClicked={onCancelClicked}
              searchClubState={searchClubState}
            />
          </div>
          <div className="row usas-extra-bottom-margin">
            <div className="col-xs-12">
              <SearchClubGrid
                baseUsasClubState={baseUsasClubState}
                searchClubState={searchClubState}
                onEdit={onEdit}
                onFiltersToggle={onFiltersToggle}
                onCancelClicked={onCancelClicked}
                sendObjOnEdit={sendObjOnEdit} />
            </div>
          </div>
          {onCancelClicked && searchClubState.showFilters === false ?
            <Fragment>
              <div className="row usas-extra-top-margin usas-extra-bottom-margin">
                <div className="col-xs-12 usas-extra-top-margin">
                  <SecondaryButton type="button" onClick={() => onCancelClicked()}>Back</SecondaryButton>
                </div>
              </div>
            </Fragment> : <Fragment />}
        </Fragment>
        :
        //shows filters and results at the same time
        <Fragment>
          <Form
            formState={formState}
            errorState={errorState}
            clearForm={clearForm}
            handleSubmit={handleSubmit}
            onFormValueChanged={onFormValueChanged}
            onValueTextPairChanged={onValueTextPairChanged}
            sortByOptions={sortByOptions}
            isCurrentOptions={isCurrentOptions}
            environmentVariableState={environmentVariableState}
          />
          <div className="row usas-extra-bottom-margin">
            <div className="col-xs-12">
              <SearchClubGrid
                baseUsasClubState={baseUsasClubState}
                onEdit={onEdit}
                sendObjOnEdit={sendObjOnEdit} />
            </div>
          </div>
        </Fragment>
      }
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={baseUsasClubState.isArrayLoading || baseUsasClubState.isObjLoading || lscState.isLoading} />
    </Fragment >
  );
};

export default SearchClub;