import { useEffect, useState } from 'react';
import { useNavigate, useLocation } from '../../../../../common/wrappers/ReactRouterDom';

import validate from './AddLSCStaffMemberValidation';

import { navLinks } from '../../UseMemberAdministration';

import useMemberAdministrationLscStaffData from '../../../../../common/state/memberAdministration/lscStaff/UseMemberAdministrationLscStaffData';
import useEnvironmentVariableData from '../../../../../common/state/environmentVariable/UseEnvironmentVariableData';
import useOrgRoleStaffRoleHierarchyData from '../../../../../common/state/orgRoleStaffRoleHierarchy/UseOrgRoleStaffRoleHierarchyData';
import useMemberRegistrationStatusData from '../../../../../common/state/memberRegistrationStatus/UseMemberRegistrationStatusData';

import useForm from '../../../../../common/utils/UseForm';
import Constants from '../../../../../common/utils/Constants';
import { formatDate } from '../../../../../common/utils/DateFunctions';

const useAddLSCStaffMember = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { LSC_STAFF_ROLE_GROUP_ID, ORG_ROLE_FIELD_PAID_OR_VOLUNTEER_FIELD_NAME, environmentVariableState } = useEnvironmentVariableData();
  const { orgRoleStaffRoleHierarchyState } = useOrgRoleStaffRoleHierarchyData();
  const { memberAdministrationLscStaffState, postLscStaff, putLscStaff } = useMemberAdministrationLscStaffData();
  const { memberRegistrationStatusState, getMemberRegistrationStatus } = useMemberRegistrationStatusData();
  const { formState, errorState, isSubmitting, updateFormState, onValueTextPairChanged, handleSubmit, setErrors, setFormData, setIsDirty
  } = useForm(getInitialFormState, onSubmitFormCallback, validate);
  const [viewState, setViewState] = useState(getInitialViewState);
  const [postValidationState, setPostValicationState] = useState({ doPost: false, postValid: false });

  const onSaveClicked = () => {
    setIsDirty(true);
    handleSubmit();
  }

  const onCancelClicked = () => {
    navigate(navLinks.LSC_STAFF);
  };

  useEffect(() => {
    if (location.state?.activeUserOrgUnitId && environmentVariableState.isLoaded === true) {
      setViewState({
        ...viewState,
        activeUserOrgUnitId: location.state.activeUserOrgUnitId
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [environmentVariableState]);

  useEffect(() => {
    if (memberAdministrationLscStaffState.isSaved === true) {
      navigate(navLinks.LSC_STAFF);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [memberAdministrationLscStaffState.isSaved]);

  useEffect(() => {
    if (postValidationState.doPost === true && memberRegistrationStatusState.isObjLoaded === true) {
      if (memberRegistrationStatusState.objData.hasActiveRegistration === true) {
        postLscStaff(viewState.activeUserOrgUnitId, LSC_STAFF_ROLE_GROUP_ID, createStaffObject(formState));
      } else {
        setErrors({
          memberId: `Member not current.`
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [postValidationState, memberRegistrationStatusState]);

  function onSubmitFormCallback(formState) {
    const existingPersonOrgRoles = getExistingPersonOrgRoles(formState.personId);

    if (existingPersonOrgRoles) {
      if (isDurationValid(formState.orgRole[0].id, formState.effectiveDate, formState.hasNoExpirationDate === false ? formState.expirationDate : Constants.DEFAULT_MAX_DATE, existingPersonOrgRoles)) {
        putLscStaff(viewState.activeUserOrgUnitId, formState.personId, LSC_STAFF_ROLE_GROUP_ID, createStaffObject(formState, existingPersonOrgRoles));
      } else {
        const overlap = getOverlappingDates(formState.orgRole[0].id, formState.effectiveDate, formState.hasNoExpirationDate === false ? formState.expirationDate : Constants.DEFAULT_MAX_DATE, existingPersonOrgRoles);
        setErrors({
          multipleActiveRoles: `This member has an existing ${formState.orgRole[0].name} role from ${formatDate(overlap?.effectiveDate)} - ${formatDate(overlap?.expirationDate)}. Date overlaps are not allowed.`
        });
      }
    } else {
      setPostValicationState({ ...postValidationState, doPost: true });
      getMemberRegistrationStatus(formState.memberId, -1);
    }
  };

  function getExistingPersonOrgRoles(personId) {
    const existingPerson = memberAdministrationLscStaffState.arrayData.find(x => x.personId === personId);

    if (existingPerson && Array.isArray(existingPerson.personOrgRole)) {
      return existingPerson.personOrgRole;
    } else {
      return null;
    }
  };

  function isDurationValid(orgRoleId, effectiveDate, expirationDate, personOrgRole) {
    let overlappingDate = false;

    for (const role of personOrgRole) {
      if (role.orgRoleId === orgRoleId && Array.isArray(role.personDurations)) {
        for (const duration of role.personDurations) {
          if (new Date(effectiveDate) <= new Date(duration.expirationDate)
            && new Date(expirationDate) >= new Date(duration.effectiveDate)) {
            overlappingDate = true;
            break;
          }
        }
      }
    }
    return !overlappingDate;
  };

  function getOverlappingDates(orgRoleId, effectiveDate, expirationDate, personOrgRole) {
    let overlappingDuration = {};

    for (const role of personOrgRole) {
      if (role.orgRoleId === orgRoleId && Array.isArray(role.personDurations)) {
        for (const duration of role.personDurations) {
          if (new Date(effectiveDate) <= new Date(duration.expirationDate)
            && new Date(expirationDate) >= new Date(duration.effectiveDate)) {
            overlappingDuration = duration;
            break;
          }
        }
      }
    }

    return overlappingDuration;
  };

  function createStaffObject(formState, existingPersonOrgRoles) {
    const filteredPersonOrgRoles = [];

    if (Array.isArray(existingPersonOrgRoles)) {
      for (const role of existingPersonOrgRoles) {
        if (Array.isArray(role.personDurations)) {
          for (let i = 0; i < role.personDurations.length; i++) {
            filteredPersonOrgRoles.push({
              personOrgRoleId: role.personOrgRoleId,
              personOrgRoleDurationId: role.personDurations[i].personOrgRoleDurationId,
              orgRoleId: role.orgRoleId,
              orgRoleName: role.orgRoleName
            })
          }
        }
      }
    }

    return {
      personId: formState.personId,
      personOrgRole: [
        {
          orgRoleId: formState.orgRole[0].id,
          orgRoleName: formState.orgRole[0].name,
          effectiveDate: formState.effectiveDate,
          expirationDate: formState.hasNoExpirationDate === false ? formState.expirationDate : Constants.DEFAULT_MAX_DATE,
          personOrgRoleFields: [
            {
              fieldName: ORG_ROLE_FIELD_PAID_OR_VOLUNTEER_FIELD_NAME,
              orgRoleFieldId: getOrgRoleFieldId(orgRoleStaffRoleHierarchyState.arrayData, formState.orgRole[0].id, ORG_ROLE_FIELD_PAID_OR_VOLUNTEER_FIELD_NAME),
              fieldValue: formState.paidOrVolunteerName
            }
          ],
        },
        ...filteredPersonOrgRoles
      ]
    };
  };

  function getOrgRoleFieldId(orgRoleTreeArray, orgRoleId, orgRoleFieldName) {
    if (Array.isArray(orgRoleTreeArray) && orgRoleTreeArray.length > 0 && orgRoleId && orgRoleFieldName) {
      for (const parentRole of orgRoleTreeArray) {
        if (Array.isArray(parentRole.children) && parentRole.children.length > 0) {
          const targetOrgRole = parentRole.children.find(x => x.id === orgRoleId);

          if (targetOrgRole && Array.isArray(targetOrgRole.orgRoleField) && targetOrgRole.orgRoleField.length > 0) {
            const targetOrgRoleField = targetOrgRole.orgRoleField.find(x => x.fieldName === orgRoleFieldName);

            if (targetOrgRoleField && targetOrgRoleField.orgRoleFieldId) {
              return targetOrgRoleField.orgRoleFieldId;
            }
          }
        }
      }
    }

    return null;
  };

  function getInitialFormState() {
    return {
      orgRole: [],
      memberId: '',
      effectiveDate: Constants.BLANK_DATE_STRING,
      expirationDate: Constants.BLANK_DATE_STRING,
      hasNoExpirationDate: false,
      paidOrVolunteerId: Constants.DEFAULT_ID,
      paidOrVolunteerName: '',
      personId: ''
    };
  };

  function getInitialViewState() {
    return {
      activeUserOrgUnitId: ''
    };
  };

  return {
    isLoading: environmentVariableState.isLoading || isSubmitting,
    isSaving: memberAdministrationLscStaffState.isSaving,
    formState,
    errorState,
    LSC_STAFF_ROLE_GROUP_ID: LSC_STAFF_ROLE_GROUP_ID || Constants.DEFAULT_ID,
    onFormValueChanged: updateFormState,
    onValueTextPairChanged,
    handleSubmit,
    onCancelClicked,
    setFormData,
    onSaveClicked,
    paidOrVolunteerOptions: [
      { id: Constants.DEFAULT_ID, name: "--" },
      { id: 1, name: "Paid" },
      { id: 2, name: "Volunteer" }
    ]
  };
};

export default useAddLSCStaffMember;