import { useEffect, useState } from 'react';
import { useNavigate, useLocation } from '../../../../common/wrappers/ReactRouterDom';

import NavLinks from '../NavLinks';

import validate from '../components/forms/PreCompOrganizationContactFormValidation';

import usePreCompOrganizationContactsData from '../../../state/preCompOrganizationContacts/UsePreCompOrganizationContactsData';

import useOrgRoleHierarchyData from '../../../../common/state/orgRoleHierarchy/UseOrgRoleHierarchyData';
import usePersonData from '../../../../common/state/person/UsePersonData';
import usePersonGeneralData from '../../../../common/state/personGeneral/UsePersonGeneralData';
import usePreCompOrganizationData from '../../../../common/state/preCompOrganization/UsePreCompOrganizationData';
import useEnvironmentVariableData from '../../../../common/state/environmentVariable/UseEnvironmentVariableData';

import useForm from '../../../../common/utils/UseForm';
import Constants from '../../../../common/utils/Constants';
import { formatDate } from '../../../../common/utils/DateFunctions';

const usePreCompOrganizationContactsDetail = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { preCompOrganizationState } = usePreCompOrganizationData();
  const { preCompOrganizationContactsState, savePreCompOrganizationContacts, deletePreCompOrganizationContacts, resetPreCompOrganizationContactsState } = usePreCompOrganizationContactsData();
  const { personState, getPotentialPersonDupes, postBasePerson } = usePersonData();
  const { personGeneralState, getPersonGeneral, putPersonGeneralContact, clearObjData } = usePersonGeneralData();
  const { orgRoleHierarchyState, getOrgRolesHierarchyByOrganizationId } = useOrgRoleHierarchyData();
  const { environmentVariableState, UNKNOWN_COMPETITION_GENDER_ID, OTHER_PHONE_TYPE_ID, PRECOMP_STAFF_ROLE_GROUP_ID, PRE_COMP_ORG_ID,
    PRIMARY_CONTACT_ORG_ROLE_ID, SECONDARY_CONTACT_ORG_ROLE_ID, PRIMARY_CONTACT_LABEL, SECONDARY_CONTACT_LABEL, CONTACTS, SELF_RELATIONSHIP_ID } = useEnvironmentVariableData();
  const { formState, errorState, handleSubmit, updateFormState, setFormData, onValueTextPairChanged, setIsDirty
  } = useForm(getInitialFormState, submitFormCallback, validate);
  const [state, setState] = useState({ trySave: false, savePersonId: '', tryRedirect: false });
  const [dupGridState, setDupGridState] = useState([]);
  const [newGridState, setNewGridState] = useState([]);
  const [roleOptionsState, setRoleOptionsState] = useState([]);
  const [existingContactModalState, setExistingContactModalState] = useState(getInitialExistingContactModalState());
  const [displayLocalDupPersonModalState, setDisplayLocalDupPersonModalState] = useState(false);

  const onCancelClicked = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    navigate(NavLinks.PRE_COMP_ORGANIZATION_CONTACT);
  };

  const onModalCancelClicked = () => {
    setDupGridState([]);
    setNewGridState([]);
    setIsDirty(true);
  };

  const onModalContinueClicked = () => {
    let matchFound = false;

    for (const dup of dupGridState) {
      if (dup.isSelected === true) {
        let hasExistingOrgRole = false;
        let existingContactObj = {};
        for (let i = 0; i < preCompOrganizationContactsState.arrayData.length; i++) {
          for (let j = 0; j < preCompOrganizationContactsState.arrayData[i].personOrgRole.length; j++) {
            if (preCompOrganizationContactsState.arrayData[i].personOrgRole[j]?.orgRoleId === formState.orgRoleId) {
              hasExistingOrgRole = true;
              existingContactObj = preCompOrganizationContactsState.arrayData[i];
            }
          }
        }
        //Delete old contact before adding new one of that type
        if (hasExistingOrgRole === true) {
          const targetRole = existingContactObj.personOrgRole.find(x => x.orgRoleId === formState.orgRoleId);

          if (targetRole && targetRole.personOrgRoleDurationId > 0) {
            deletePreCompOrganizationContacts(targetRole.personOrgRoleDurationId);
            setState({ ...state, trySave: true, savePersonId: dup.personId, tryRedirect: false });
          } else {
            //no change
            navigate(NavLinks.PRE_COMP_ORGANIZATION_CONTACT);
          }
        }
        else {
          //Add new contact if there isn't an exisiting one of that type
          let personOrgRole = [];
          for (let i = 0; i < preCompOrganizationContactsState.arrayData.length; i++) {
            if (preCompOrganizationContactsState.arrayData[i].personId === personState.objData.personId) {
              personOrgRole = preCompOrganizationContactsState.arrayData[i].personOrgRole;
            }
          }
          if (personOrgRole.length === 0) {
            savePreCompOrganizationContacts(preCompOrganizationState.objData.orgUnitId, dup.personId, PRECOMP_STAFF_ROLE_GROUP_ID, createPersonOrgRole(formState));
          }
          else {
            let personOrgRoleWithoutContacts = personOrgRole.filter(x => x.orgRoleId !== (PRIMARY_CONTACT_ORG_ROLE_ID && SECONDARY_CONTACT_ORG_ROLE_ID));
            savePreCompOrganizationContacts(preCompOrganizationState.objData.orgUnitId, dup.personId, PRECOMP_STAFF_ROLE_GROUP_ID, combineNewAndExistingPersonOrgRole(personOrgRoleWithoutContacts, formState));
          }
          setState({ ...state, trySave: false, tryRedirect: true });
        }
        matchFound = true;
        break;
      }
    }

    if (matchFound === false) {
      postBasePerson({
        firstName: formState.firstName.trim(),
        lastName: formState.lastName.trim(),
        birthDate: formState.birthDate,
        email: formState.email.trim(),
        competitionGenderTypeId: UNKNOWN_COMPETITION_GENDER_ID,
        isDeleted: false
      });
    }

    setDupGridState([]);
    setNewGridState([]);
  };

  const onExistingContactModalConfirmClicked = () => {
    setExistingContactModalState(getInitialExistingContactModalState());

    getPotentialPersonDupes(formState.firstName, formState.lastName, formState.birthDate);
  };

  const onExistingContactModalCancelClicked = () => {
    setExistingContactModalState(getInitialExistingContactModalState());
  };

  const onSelectGridPerson = (personId) => {
    let newDupGridState = [];
    for (const dup of dupGridState) {
      newDupGridState.push({
        ...dup,
        isSelected: personId === dup.personId ? true : false
      });
    }

    setDupGridState(newDupGridState);
    setNewGridState([{
      ...newGridState[0],
      isSelected: personId ? false : true
    }]);
  };

  const onLocalDupPersonModalCancelClicked = () => {
    setIsDirty(true);
    setDisplayLocalDupPersonModalState(false);
  };

  useEffect(() => {
    if (personState.isArrayLoaded === true && environmentVariableState.isLoaded === true) {
      if (personState.arrayData.length > 0) {
        setDupGridState(personState.arrayData.map(x => {
          let orgDuration = { orgUnitName: '', effectiveDate: '', expirationDate: '' };
          if (Array.isArray(x.orgDurations) && x.orgDurations.length > 0) {
            orgDuration = {
              orgUnitName: x.orgDurations[0].orgUnitName,
              effectiveDate: x.orgDurations[0].effectiveDate,
              expirationDate: x.orgDurations[0].expirationDate
            };
          }

          return {
            personId: x.personId,
            firstName: x.firstName,
            lastName: x.lastName,
            birthDate: x.birthDate,
            orgDuration,
            isSelected: false
          };
        }));
        setNewGridState([{
          personId: '',
          firstName: formState.firstName,
          lastName: formState.lastName,
          birthDate: formState.birthDate,
          orgDurations: { organization: '', effectiveDate: '', expirationDate: '' },
          isSelected: true
        }]);
      } else {
        postBasePerson({
          firstName: formState.firstName.trim(),
          lastName: formState.lastName.trim(),
          birthDate: formState.birthDate,
          email: formState.email.trim(),
          competitionGenderTypeId: UNKNOWN_COMPETITION_GENDER_ID,
          isDeleted: false
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [personState.isArrayLoaded, environmentVariableState]);

  useEffect(() => {
    if (personState.isSaving === false && personState.isSaved === true) {
      getPersonGeneral(personState.objData.personId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [personState]);

  useEffect(() => {
    if (personGeneralState.isObjLoaded === true && personGeneralState.isObjLoading === false &&
      personGeneralState.isSaving === false && personGeneralState.isSaved === false) {
        putPersonGeneralContact(personGeneralState.objData.personId, createPutPersonGeneralObj(JSON.parse(JSON.stringify(personGeneralState.objData)), formState));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [personGeneralState]);

  useEffect(() => {
    if ((personGeneralState.isSaving === false && personGeneralState.isSaved === true) ||
      (personState.isObjLoading === false && personState.isObjLoaded === true)) {
      if (preCompOrganizationContactsState.isSaving === false && preCompOrganizationContactsState.isSaved === false) {
        let hasExistingOrgRole = false;
        let existingContactObj = {};
        for (let i = 0; i < preCompOrganizationContactsState.arrayData.length; i++) {
          for (let j = 0; j < preCompOrganizationContactsState.arrayData[i].personOrgRole.length; j++) {
            if (preCompOrganizationContactsState.arrayData[i].personOrgRole[j]?.orgRoleId === formState.orgRoleId) {
              hasExistingOrgRole = true;
              existingContactObj = preCompOrganizationContactsState.arrayData[i];
            }
          }
        }
        //Delete old contact before adding new one of that type
        if (hasExistingOrgRole === true) {
          const targetRole = existingContactObj.personOrgRole.find(x => x.orgRoleId === formState.orgRoleId);

          if (targetRole && targetRole.personOrgRoleDurationId > 0) {
            deletePreCompOrganizationContacts(targetRole.personOrgRoleDurationId);
            setState({ ...state, trySave: true, savePersonId: personState.objData.personId, tryRedirect: false });
          }
        }
        else {
          //Add new contact if there isn't an exisiting one of that type
          savePreCompOrganizationContacts(preCompOrganizationState.objData.orgUnitId, personState.objData.personId, PRECOMP_STAFF_ROLE_GROUP_ID, createPersonOrgRole(formState));
          setState({ ...state, trySave: false, tryRedirect: true });
          if (personGeneralState.isSaved === true) {
            clearObjData();
          }
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [personGeneralState, personState]);

  useEffect(() => {
    if (preCompOrganizationContactsState.isSaving === false && preCompOrganizationContactsState.isSaved === true && state.trySave === true) {
      savePreCompOrganizationContacts(preCompOrganizationState.objData.orgUnitId, state.savePersonId ? state.savePersonId : personState.objData.personId, PRECOMP_STAFF_ROLE_GROUP_ID, createPersonOrgRole(formState));
      setState({ ...state, trySave: false, savePersonId: '', tryRedirect: true });
      if (personGeneralState.isSaved === true) {
        clearObjData();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [preCompOrganizationContactsState, state]);


  useEffect(() => {
    if (preCompOrganizationContactsState.isSaving === false && preCompOrganizationContactsState.isSaved === true && state.tryRedirect === true) {
      resetPreCompOrganizationContactsState();
      navigate(NavLinks.PRE_COMP_ORGANIZATION_CONTACT);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [preCompOrganizationContactsState, state]);

  useEffect(() => {
    if (roleOptionsState.length > 0) {
      if (location.state && location.state.orgRoleId) {
        setFormData({
          ...formState,
          orgRoleId: location.state.orgRoleId,
          orgRoleName: roleOptionsState.find(x => x.id === location.state.orgRoleId)?.name || ''
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roleOptionsState]);

  useEffect(() => {
    if (PRE_COMP_ORG_ID !== orgRoleHierarchyState.organizationId) {
      getOrgRolesHierarchyByOrganizationId(PRE_COMP_ORG_ID);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [environmentVariableState, orgRoleHierarchyState])

  useEffect(() => {
    if (orgRoleHierarchyState.isArrayLoading === false && orgRoleHierarchyState.isArrayLoaded === true) {
      const orgRoleContacts = orgRoleHierarchyState.arrayData.find(x => x.name === CONTACTS);
      if (orgRoleContacts.children) {
        setRoleOptionsState(orgRoleContacts.children.filter(x => x.name === PRIMARY_CONTACT_LABEL
          || x.name === SECONDARY_CONTACT_LABEL || x.id === Constants.DEFAULT_ID));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [environmentVariableState, orgRoleHierarchyState]);

  function submitFormCallback(formState) {
    const localDupPerson = findLocalDupPerson(preCompOrganizationContactsState.arrayData);

    if (localDupPerson) {
      setDisplayLocalDupPersonModalState(true);
    } else {
      let hasExistingOrgRole = false;
      let existingContactObj = {};
      for (let i = 0; i < preCompOrganizationContactsState.arrayData.length; i++) {
        for (let j = 0; j < preCompOrganizationContactsState.arrayData[i].personOrgRole.length; j++) {
          if (preCompOrganizationContactsState.arrayData[i].personOrgRole[j]?.orgRoleId === formState.orgRoleId) {
            hasExistingOrgRole = true;
            existingContactObj = preCompOrganizationContactsState.arrayData[i];
          }
        }
      }

      if (hasExistingOrgRole === true) {
        setExistingContactModalState({
          ...existingContactModalState,
          existingContactFirstName: existingContactObj.firstName,
          existingContactLastName: existingContactObj.lastName,
          existingContactOrgRoleName: formState.orgRoleName,
          displayModal: true
        });
      } else {
        getPotentialPersonDupes(formState.firstName, formState.lastName, formState.birthDate);
      }
    }
  };

  function createPersonOrgRole(formState) {
    const personOrgRole = [];
    personOrgRole.push({
      orgRoleId: formState.orgRoleId,
      orgRoleName: formState.orgRoleName,
      personOrgRoleFields: []
    });
    return personOrgRole;
  };

  function combineNewAndExistingPersonOrgRole(personOrgRole, formState) {
    personOrgRole.push({
      orgRoleId: formState.orgRoleId,
      orgRoleName: formState.orgRoleName,
      personOrgRoleFields: []
    });
    return personOrgRole;
  };

  function createPutPersonGeneralObj(personGeneralObjData, formState) {
    if (personGeneralObjData.personContact) {
      const selfIndex = personGeneralObjData.personContact.findIndex(x => x.contactRelationshipTypeId === SELF_RELATIONSHIP_ID)
      if (selfIndex > -1) {
        if (personGeneralObjData.personContact[selfIndex].personContactPhone)
          personGeneralObjData.personContact[selfIndex].personContactPhone.push({
            personContactId: personGeneralObjData.personContact[selfIndex].personContactId,
            phoneTypeId: OTHER_PHONE_TYPE_ID,
            phoneNumber: formState.phoneNumber,
            isPrimary: true,
            phoneType: undefined
          });
      }
    }

    return personGeneralObjData;
  };

  function findLocalDupPerson(contactArray) {
    if (Array.isArray(contactArray) && contactArray.length > 0) {
      for (const contact of contactArray) {
        const isOverwrite = contact.personOrgRole.find(x => x.orgRoleId === formState.orgRoleId);

        if (!isOverwrite) {
          const birthDate = formatDate(contact.birthDate);
          const firstName = contact.firstName;
          const lastName = contact.lastName;

          if (formState.birthDate === birthDate && firstName === formState.firstName && lastName === formState.lastName) {
            return contact;
          }
        }
      }
    }

    return null;
  };

  function getInitialFormState() {
    return {
      personId: '',
      firstName: '',
      lastName: '',
      phoneNumber: '',
      email: '',
      birthDate: Constants.BLANK_DATE_STRING,
      orgRoleId: '',
      orgRoleName: ''
    };
  };

  function getInitialExistingContactModalState() {
    return {
      existingContactFirstName: '',
      existingContactLastName: '',
      existingContactOrgRoleName: '',
      displayModal: false
    };
  };

  return {
    isSaving: preCompOrganizationState.isSaving || preCompOrganizationContactsState.isSaving || personState.isSaving || personGeneralState.isSaving,
    isLoading: preCompOrganizationState.isObjLoading || personState.isArrayLoading || orgRoleHierarchyState.isArrayLoading || personGeneralState.isObjLoading,
    isDelete: location.state && location.state.orgRoleId,
    formState,
    errorState,
    preCompOrganizationState,
    dupGridState,
    newGridState,
    roleOptionsState,
    existingContactModalState,
    displayLocalDupPersonModalState,
    handleSubmit,
    onFormValueChanged: updateFormState,
    onValueTextPairChanged,
    onCancelClicked,
    onModalCancelClicked,
    onModalContinueClicked,
    onSelectGridPerson,
    onExistingContactModalConfirmClicked,
    onExistingContactModalCancelClicked,
    onLocalDupPersonModalCancelClicked
  };
};

export default usePreCompOrganizationContactsDetail;