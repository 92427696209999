import { useContext } from 'react';

import { UsasClubPreCompLinksStateContext } from './UsasClubPreCompLinksContextProvider';
import UsasClubPreCompLinksData from './UsasClubPreCompLinksData';

const useUsasClubPreCompLinksData = () => {
  const [usasClubPreCompLinksState, setUsasClubPreCompLinksState] = useContext(UsasClubPreCompLinksStateContext);

  const getUsasClubPreCompLinks = (usasClubId) => UsasClubPreCompLinksData.getUsasClubPreCompLinksData(usasClubId, usasClubPreCompLinksState, setUsasClubPreCompLinksState);
  const postUsasClubPreCompLinks = (usasClubId, preCompLocationId) => UsasClubPreCompLinksData.postUsasClubPreCompLinksData(usasClubId, preCompLocationId, usasClubPreCompLinksState, setUsasClubPreCompLinksState);
  const deleteUsasClubPreCompLinks = (usasClubId, preCompLocationId) => UsasClubPreCompLinksData.deleteUsasClubPreCompLinksData(usasClubId, preCompLocationId, usasClubPreCompLinksState, setUsasClubPreCompLinksState);

  const clearUsasClubPreCompLinksObjData = () => {
    setUsasClubPreCompLinksState({
      ...usasClubPreCompLinksState,
      isObjLoading: false,
      isObjLoaded: false,
      isSaving: false,
      isSaved: false,
      objData: {},
      message: ''
    });
  };

  const clearUsasClubPreCompLinksArrayData = () => {
    setUsasClubPreCompLinksState({
      ...usasClubPreCompLinksState,
      isArrayLoading: false,
      isArrayLoaded: false,
      isSaving: false,
      isSaved: false,
      arrayData: [],
      message: ''
    });
  };

  const confirmUsasClubPreCompLinksSave = () => {
    setUsasClubPreCompLinksState({
      ...usasClubPreCompLinksState,
      isSaved: false
    });
  };

  return {
    usasClubPreCompLinksState,
    setUsasClubPreCompLinksState,
    clearUsasClubPreCompLinksObjData,
    clearUsasClubPreCompLinksArrayData,
    confirmUsasClubPreCompLinksSave,
    getUsasClubPreCompLinks,
    postUsasClubPreCompLinks,
    deleteUsasClubPreCompLinks
  };
};

export default useUsasClubPreCompLinksData;