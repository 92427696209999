import React, { Fragment } from 'react';

import usePreCompOrganizationInsurance from './UsePreCompOrganizationInsurance';

import PreCompOrganizationInsuranceForm from '../components/forms/PreCompOrganizationInsuranceForm';

import PopUpModal from '../../../../common/components/dialogs/PopUpModal';
import Headings from '../../../../common/components/headings/Headings';
import PrimaryButton from '../../../../common/components/buttons/PrimaryButton';
import SecondaryButton from '../../../../common/components/buttons/SecondaryButton';

import Constants from '../../../../common/utils/Constants';

const PreCompOrganizationInsurance = () => {
  const {
    isSaving,
    isLoading,
    formState,
    errorState,
    handleSubmit,
    onFormValueChanged,
    resetForm,
    onUploadVerification,
    onRemoveVerification,
    onDownloadUploadedFile
  } = usePreCompOrganizationInsurance();

  return (
    <Fragment>
      <Headings.H3>Organization Insurance</Headings.H3>
      <form onSubmit={handleSubmit} noValidate>
        <PreCompOrganizationInsuranceForm
          formState={formState}
          errorState={errorState}
          onFormValueChanged={onFormValueChanged}
          onUploadVerification={onUploadVerification}
          onRemoveVerification={onRemoveVerification}
          onDownloadUploadedFile={onDownloadUploadedFile} />
        {errorState.errorOnSubmitAction &&
          <div className="row">
            <div className="col-xs-12">
              <p className={global.ErrorMessage}>{errorState.errorOnSubmitAction || ''}</p>
            </div>
          </div>}
        <div className="row usas-extra-top-margin usas-extra-bottom-margin">
          <div className="col-xs-12 usas-extra-top-margin">
            <PrimaryButton type="submit">Save</PrimaryButton>&nbsp;
            <SecondaryButton type="button" onClick={resetForm}>Cancel</SecondaryButton>
          </div>
        </div>
      </form>
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.SAVING_MSG}
        displayPopUp={isSaving} />
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={isLoading} />
    </Fragment>
  );
};

export default PreCompOrganizationInsurance;