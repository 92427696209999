import React, { Fragment } from 'react';

import usePreCompRegistrationLocationClubs from './UsePreCompRegistrationLocationClubs';

import PreCompLocationClubsForm from '../../preComp/components/forms/PreCompLocationClubsForm';
import PreCompLocationClubGrid from '../../preComp/components/grids/PreCompLocationClubGrid';

import PreCompRegistrationConstants from '../utils/PreCompRegistrationConstants';

import Headings from '../../../../common/components/headings/Headings';
import PopUpModal from '../../../../common/components/dialogs/PopUpModal';
import PrimaryButton from '../../../../common/components/buttons/PrimaryButton';
import SecondaryButton from '../../../../common/components/buttons/SecondaryButton';
import LeftNav from '../../../../common/components/leftNav/LeftNav';
import ActionIntraPageButton from '../../../../common/components/buttons/ActionIntraPageButton';

import global from '../../../../common/components/GlobalStyle.module.css';

const PreCompRegistrationLocationClubs = () => {
  const {
    gridState,
    modalState,
    formState,
    errorState,
    formModalState,
    locationRegistrationNavState,
    pageErrorMessageState,
    onFormValueChanged,
    onLinkClub,
    onClubDelete,
    onModalCancelClicked,
    onModalDeleteClicked,
    onFormModalCancelClicked,
    onFormModalConfirmClicked,
    onContinueClicked,
    onBackClicked
  } = usePreCompRegistrationLocationClubs();

  return (
    <LeftNav title={PreCompRegistrationConstants.LEFT_NAV_TITLE} state={locationRegistrationNavState} validateBeforeRedirect={false}>
      <Headings.H3>Location Clubs</Headings.H3>
      <div className="row usas-extra-top-margin usas-extra-bottom-margin">
        <div className="col-xs-12 usas-extra-top-margin">
          <form noValidate>
            <PreCompLocationClubsForm
              formState={formState}
              errorState={errorState}
              onFormValueChanged={onFormValueChanged} />
          </form>
        </div>
      </div>
      {(formState.isUsasMemberTeam === true || formState.referParticipantsToUsas === true) &&
        <Fragment>
          <div className="row usas-extra-top-margin">
            <div className="col-xs-12">
              <ActionIntraPageButton onClick={onLinkClub}>Link Club</ActionIntraPageButton>
            </div>
          </div>
          <div className="row usas-extra-top-margin">
            <div className="col-xs-12">
              <PreCompLocationClubGrid
                data={gridState}
                isLoading={false}
                onDelete={onClubDelete} />
            </div>
          </div>
        </Fragment>}
      {pageErrorMessageState &&
        <div className="row usas-extra-top-margin">
          <div className="col-xs-12">
            <p className={global.ErrorMessage}>{pageErrorMessageState}</p>
          </div>
        </div>}
      <div className="row usas-extra-top-margin usas-extra-bottom-margin">
        <div className="col-xs-12 usas-extra-top-margin">
          <PrimaryButton type="button" onClick={onContinueClicked}>Continue</PrimaryButton>&nbsp;
          <SecondaryButton type="button" onClick={onBackClicked}>Back</SecondaryButton>
        </div>
      </div>
      <PopUpModal
        widthPct={90}
        maxWidth={720}
        title={"Unlink Club?"}
        displayPopUp={modalState.displayPopUp}
        onModalCanceled={onModalCancelClicked}>
        <div className="row">
          <div className="col-xs-12">
            <p><b>Club Name:</b> {modalState.clubName}</p>
            <p><b>Club Location:</b> {modalState.clubLocation}</p>
            {modalState.showLastClubWarning
              ? <p>*Unlinking the last associated club will set both 'Are you a USA Swimming Member Team?' and 'Do you refer participants to a USA Swimming team upon readiness?' to 'No'</p>
              : <Fragment />}
          </div>
        </div>
        <div className="row usas-extra-top-margin">
          <div className="col-xs-12">
            <PrimaryButton type="button" onClick={() => onModalDeleteClicked(modalState.targetIndex)}>
              Unlink
            </PrimaryButton>&nbsp;
            <SecondaryButton type="button" onClick={onModalCancelClicked}>Cancel</SecondaryButton>
          </div>
        </div>
      </PopUpModal>
      <PopUpModal
        widthPct={90}
        maxWidth={820}
        title={"Remove all linked Clubs?"}
        displayPopUp={formModalState.displayPopUp}
        onModalCanceled={onFormModalCancelClicked}>
        <div className="row">
          <div className="col-xs-12">
            <p>Selecting 'No' for both 'Are you a USA Swimming Member Team?' and 'Do you refer participants to a USA Swimming team upon readiness?' will remove all clubs currently linked to this location</p>
            <p>Do you want to commit this change?</p>
          </div>
        </div>
        <div className="row usas-extra-top-margin">
          <div className="col-xs-12">
            <PrimaryButton type="button" onClick={onFormModalConfirmClicked}>Yes</PrimaryButton>&nbsp;
            <SecondaryButton type="button" onClick={onFormModalCancelClicked}>Cancel</SecondaryButton>
          </div>
        </div>
      </PopUpModal>
    </LeftNav>
  );
};

export default PreCompRegistrationLocationClubs;