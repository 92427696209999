import React, { Fragment } from 'react';

import useClubRegistrationFacilities from './UseClubRegistrationFacilities';

import useExpandedRow from '../../../../common/components/grids/UseExpandedRow';

import LargeClubRegistrationFacilitiesGridRead from './LargeClubRegistrationFacilitiesGridRead';
import SmallClubRegistrationFacilitiesGridRead from './SmallClubRegistrationFacilitiesGridRead';

const ClubRegistrationFacilitiesGridRead = () => {
  const { usasClubFacilityLinksState } = useClubRegistrationFacilities();
  const { expandedId, onClick } = useExpandedRow();
  return (
    <Fragment>
      <LargeClubRegistrationFacilitiesGridRead
        state={usasClubFacilityLinksState}
        expandedId={expandedId}
        onClick={onClick}
      />
      <SmallClubRegistrationFacilitiesGridRead
        state={usasClubFacilityLinksState}
        expandedId={expandedId}
        onClick={onClick}
      />
    </Fragment>
  )
};

export default ClubRegistrationFacilitiesGridRead;