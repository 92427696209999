import useGroupCoachesMultiselect from './UseGroupCoachesMultiselect';

import MultiSelect from '../MultiSelect';

import global from '../../GlobalStyle.module.css';

const GroupCoachesMultiselect = ({ label, name, value, error, message, onChange, orgUnitId, usasClubId }) => {
  const { groupCoachesState } = useGroupCoachesMultiselect(orgUnitId, usasClubId);

  return groupCoachesState.message
    ? <span className={global.LoadingMsg}>{groupCoachesState.message}</span>
    : (
      <MultiSelect
        label={label}
        name={name}
        value={value}
        options={groupCoachesState.options}
        error={error}
        message={message}
        isLoading={groupCoachesState.isArrayLoading}
        onChange={onChange} />
    );
};

export default GroupCoachesMultiselect;