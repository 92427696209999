import React, { Fragment } from 'react';

import usePreCompRegistrationEntry from './UsePreCompRegistrationEntry';

import PreCompOrganizationSpecialInfoForm from '../../preComp/components/forms/PreCompOrganizationSpecialInfoForm';

import PopUpModal from '../../../../common/components/dialogs/PopUpModal';
import PrimaryButton from '../../../../common/components/buttons/PrimaryButton';
import SecondaryButton from '../../../../common/components/buttons/SecondaryButton';
import Headings from '../../../../common/components/headings/Headings';
import Constants from '../../../../common/utils/Constants';

const PreCompRegistrationEntry = () => {
  const {
    isLoading,
    formState,
    errorState,
    displayDupModal,
    onFormValueChanged,
    handleSubmit,
    resetForm,
    onErrorModalCancelClicked,
    onDupModalCancelClicked,
    onDupModalContinueClicked
  } = usePreCompRegistrationEntry();

  return (
    <Fragment>
      <Headings.H3>Pre-Competitive Organization Registration</Headings.H3>
      <div className="row">
        <div className="col-xs-12 col-sm-12">
          <p><b>*If Pre-Competitive Organization already exists, enter existing Federal Tax Id</b></p>
        </div>
      </div>
      <form onSubmit={handleSubmit} noValidate>
        <PreCompOrganizationSpecialInfoForm
          formState={formState}
          errorState={errorState}
          onFormValueChanged={onFormValueChanged}
          includeIsInsuredField={true} />
        {errorState.errorOnSubmitAction &&
          <div className="row">
            <div className="col-xs-12">
              <p className={global.ErrorMessage}>{errorState.errorOnSubmitAction || ''}</p>
            </div>
          </div>}
        <div className="row usas-extra-top-margin usas-extra-bottom-margin">
          <div className="col-xs-12 usas-extra-top-margin">
            <PrimaryButton type="submit">Continue</PrimaryButton>&nbsp;
            <SecondaryButton type="button" onClick={resetForm}>Cancel</SecondaryButton>
          </div>
        </div>
      </form>
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={isLoading} />
      <PopUpModal
        widthPct={90}
        maxWidth={620}
        title="Swim Lesson Insurance Required"
        displayPopUp={errorState.modalError !== undefined}
        onModalCanceled={onErrorModalCancelClicked}>
        <div className="row">
          <div className="col-xs-12">
            <p>{errorState.modalError}</p>
          </div>
        </div>
      </PopUpModal>
      <PopUpModal
        widthPct={90}
        maxWidth={920}
        title="Federal Tax Id Identified"
        displayPopUp={displayDupModal}
        onModalCanceled={onDupModalCancelClicked}>
        <div className="row">
          <div className="col-xs-12">
            <p><b>Our records indicate that a Pre-Competitive Organization with the provided Federal Tax Id has already been registered in our system. If you wish to add new Pre-Competitive Location information to the Pre-Competitive Organization click "Continue".</b></p>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12">
            <PreCompOrganizationSpecialInfoForm
              formState={formState}
              errorState={errorState}
              onFormValueChanged={undefined}
              includeIsInsuredField={false} />
          </div>
        </div>
        <div className="row usas-extra-top-margin">
          <div className="col-xs-12">
            <p>If you are a new Pre-Competitive Organization that has yet to register, contact 719-555-USAS for further information.</p>
          </div>
        </div>
        <div className="row usas-extra-top-margin">
          <div className="col-xs-12">
            <PrimaryButton type="button" onClick={onDupModalContinueClicked}>Continue</PrimaryButton>&nbsp;
            <SecondaryButton type="button" onClick={onDupModalCancelClicked}>Cancel</SecondaryButton>
          </div>
        </div>
      </PopUpModal>
    </Fragment>
  );
};

export default PreCompRegistrationEntry;