import React from 'react';

import useClubRegistrationLegalEntitiesDetail from './UseClubRegistrationLegalEntitiesDetail';

import ClubRegistrationConstants from '../utils/ClubRegistrationConstants';

import LeftNav from '../../../../common/components/leftNav/LeftNav';
import Headings from '../../../../common/components/headings/Headings';
import Input from '../../../../common/components/inputs/Input';
import PrimaryButton from '../../../../common/components/buttons/PrimaryButton';
import SecondaryButton from '../../../../common/components/buttons/SecondaryButton';
import PopUpModal from '../../../../common/components/dialogs/PopUpModal';
import Constants from '../../../../common/utils/Constants';
import Dropdown from '../../../../common/components/dropdowns/Dropdown';

const ClubRegistrationLegalEntitiesDetail = () => {
  const { 
    clubRegistrationState, 
    clubRegistrationNavState, 
    formState, 
    errorState, 
    legalEntityTypeState,
    onFormValueChanged,
    handleSubmit, 
    onSaveClicked, 
    onCancelClicked,
    onValueTextPairChanged } = useClubRegistrationLegalEntitiesDetail();

  return (
    <LeftNav title={ClubRegistrationConstants.LEFT_NAV_TITLE} state={clubRegistrationNavState} validateBeforeRedirect={false}>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>{formState.usasClubLegalEntityId > 0 ? "Edit" : "Add"} Legal Entity</Headings.H3>
        </div>
      </div>
      <form onSubmit={handleSubmit} noValidate>
        <div className="row usas-extra-top-margin">
          <div className="col-xs-12 col-sm-6 col-md-4">
            {legalEntityTypeState.areItemsLoaded && legalEntityTypeState.areItemsLoaded === true &&
              <Dropdown
                label="Legal Entity Type*"
                options={legalEntityTypeState.items}
                name="legalEntityTypeId"
                value={formState.legalEntityTypeId}
                error={errorState.legalEntityTypeId}
                message={errorState.legalEntityTypeId}
                areValuesIntegers={true}
                isLoading={legalEntityTypeState.isArrayLoading}
                onChange={(newValue, newValueLabel) => { onValueTextPairChanged(newValue, 'legalEntityTypeId', newValueLabel, 'typeName'); }}
              />
            }
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4">
            <Input
              label="Legal Entity Name*"
              name="legalEntityName"
              value={formState.legalEntityName}
              error={errorState.legalEntityName}
              message={errorState.legalEntityName}
              onChange={(value) => { onFormValueChanged('legalEntityName', value) }} />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4">
            <Input
              label="Legal Entity Tax Id*"
              name="legalEntityTaxId"
              value={formState.legalEntityTaxId}
              error={errorState.legalEntityTaxId}
              message={errorState.legalEntityTaxId}
              onChange={(value) => { onFormValueChanged('legalEntityTaxId', value) }} />
          </div>
        </div>
        <div className="row usas-extra-top-margin usas-extra-bottom-margin">
          <div className="col-xs-12 usas-extra-top-margin">
            <PrimaryButton type="button" onClick={onSaveClicked}>Save</PrimaryButton>&nbsp;
            <SecondaryButton type="button" onClick={onCancelClicked}>Back</SecondaryButton>
          </div>
        </div>
      </form>
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={clubRegistrationState.isObjLoading} />
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.SAVING_MSG}
        displayPopUp={clubRegistrationState.isSaving} />
    </LeftNav>
  )
};

export default ClubRegistrationLegalEntitiesDetail;