import { Fragment } from "react";

import useProviderInfoForm from "./UseProviderInfoForm";

import { getUploadedFileName } from "../../utils/LearnToSwimUtils";

import LearnToSwimCurriculumDropdown from "../../../../components/dropdowns/learnToSwimCurriculumDropdown/LearnToSwimCurriculumDropdown";
import LearnToSwimClubsCombobox from "../../../../components/comboboxes/learnToSwimClubsCombobox/LearnToSwimClubsCombobox";

import Input from "../../../../../common/components/inputs/Input";
import YesNoSwitch from "../../../../../common/components/yesNoSwitch/YesNoSwitch";
import PrimaryButton from "../../../../../common/components/buttons/PrimaryButton";
import SecondaryButton from "../../../../../common/components/buttons/SecondaryButton";
import PopUpModal from "../../../../../common/components/dialogs/PopUpModal";
import ReadOnly from "../../../../../common/components/readOnly/ReadOnly";
import Textarea from "../../../../../common/components/inputs/Textarea";

import Constants from "../../../../../common/utils/Constants";

import global from '../../../../../common/components/GlobalStyle.module.css';
import Headings from "../../../../../common/components/headings/Headings";

const ProviderInfoForm = ({ submitButtonText = 'Save', providerObj, onSubmitFormCallback }) => {
  const {
    isLoading,
    isSaving,
    formState,
    errorState,
    handleSubmit,
    updateFormState,
    onValueTextPairChanged,
    resetFormFunction,
    onLogoFileLoad,
    onLogoFileUnload,
    onLogoFileDownload,
    onCurriculumFileLoad,
    onCurriculumFileUnload,
    onCurriculumFileDownload,
    onProofOfInsuranceFileLoad,
    onProofOfInsuranceFileUnload,
    onProofOfInsuranceFileDownload,
    onFormW9FileLoad,
    onFormW9FileUnload,
    onFormW9FileDownload
  } = useProviderInfoForm(onSubmitFormCallback, providerObj);

  return (
    <Fragment>
      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-xs-12 col-sm-6 col-md-4">
            <Input
              label='Business Legal Name*'
              name="entityName"
              value={formState.entityName}
              error={errorState.entityName}
              message={errorState.entityName}
              onChange={(value) => { updateFormState('entityName', value) }} />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4">
            <Input
              label='Trade Name/DBA*'
              name="tradeName"
              value={formState.tradeName}
              error={errorState.tradeName}
              message={errorState.tradeName}
              onChange={(value) => { updateFormState('tradeName', value) }} />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4">
            <Input
              label='Tax Id*'
              name="taxId"
              value={formState.taxId}
              error={errorState.taxId}
              message={errorState.taxId}
              onChange={(value) => { updateFormState('taxId', value) }} />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4">
            <LearnToSwimCurriculumDropdown
              label='Learn To Swim Curriculum*'
              name="learnToSwimCurriculumId"
              value={formState.learnToSwimCurriculumId}
              error={errorState.learnToSwimCurriculumId}
              message={errorState.learnToSwimCurriculumId}
              onChange={(value, valueLabel) => { onValueTextPairChanged(value, 'learnToSwimCurriculumId', valueLabel, 'learnToSwimCurriculumName') }} />
          </div>
          <div className="col-xs-12 col-md-8">
            <Input
              label='Website*'
              name="website"
              value={formState.website}
              error={errorState.website}
              message={errorState.website}
              onChange={(value) => { updateFormState('website', value) }} />
          </div>
          <div className="col-xs-12 col-sm-6">
            <YesNoSwitch
              label='Offers Financial Assistance?*'
              name="offersFinancialAssistance"
              checked={formState.offersFinancialAssistance}
              error={errorState.offersFinancialAssistance}
              message={errorState.offersFinancialAssistance}
              onChange={(value) => { updateFormState('offersFinancialAssistance', value) }} />
          </div>
          <div className="col-xs-12 col-sm-6">
            <YesNoSwitch
              label='Is A Current Year Learn To Swim Grant Recipient?*'
              name="currentLTSGrantRecipient"
              checked={formState.currentLTSGrantRecipient}
              error={errorState.currentLTSGrantRecipient}
              message={errorState.currentLTSGrantRecipient}
              onChange={(value) => { updateFormState('currentLTSGrantRecipient', value) }} />
          </div>
          <div className="col-xs-12 col-sm-6">
            <YesNoSwitch
              label='Is this Program operated by a USA Swimming Club?*'
              name="affiliatedWithUsasClub"
              checked={formState.affiliatedWithUsasClub}
              error={errorState.affiliatedWithUsasClub}
              message={errorState.affiliatedWithUsasClub}
              onChange={(value) => { updateFormState('affiliatedWithUsasClub', value) }} />
          </div>
          <div className={formState.affiliatedWithUsasClub === true ? global.DisplayComponent : global.HideComponent}>
            <div className="col-xs-12 col-sm-6">
              <LearnToSwimClubsCombobox
                label='Affiliated USA Swimming Club*'
                name="usasClubId"
                valueToMatch={formState.usasClubName}
                error={errorState.usasClubId}
                message={errorState.usasClubId}
                onChange={(value, valueLabel) => { onValueTextPairChanged(value, 'usasClubId', valueLabel, 'usasClubName') }} />
            </div>
          </div>
          <div className="col-xs-12">
            <YesNoSwitch
              label='Do you conduct pre-employment screenings (background checks) and reference checks on all employees?*'
              name="backgroundCheckAcknowledgement"
              checked={formState.backgroundCheckAcknowledgement}
              error={errorState.backgroundCheckAcknowledgement}
              message={errorState.backgroundCheckAcknowledgement}
              onChange={(value) => {
                updateFormState('backgroundCheckAcknowledgement', value);
                if (value === true) { updateFormState('backgroundCheckNotUsedExplanation', ''); }
              }} />
          </div>
          <div className={formState.backgroundCheckAcknowledgement === false ? global.DisplayComponent : global.HideComponent}>
            <div className="col-xs-12">
              <Textarea
                className={global.Textarea}
                label="If 'No', please explain using the space provided*"
                name="backgroundCheckNotUsedExplanation"
                value={formState.backgroundCheckNotUsedExplanation}
                error={errorState.backgroundCheckNotUsedExplanation}
                message={errorState.backgroundCheckNotUsedExplanation}
                onChange={(value) => { updateFormState('backgroundCheckNotUsedExplanation', value); }} />
            </div>
          </div>
          <div className="col-xs-12">
            <YesNoSwitch
              label='Does your program provide Child Protection Training for staff?*'
              name="childProtectionTraining"
              checked={formState.childProtectionTraining}
              error={errorState.childProtectionTraining}
              message={errorState.childProtectionTraining}
              onChange={(value) => { updateFormState('childProtectionTraining', value) }} />
          </div>
          <div className="col-xs-12 col-sm-6">
            <YesNoSwitch
              label='Do you offer Adult Learn to Swim Lessons?*'
              name="offerAdultLearnToSwim"
              checked={formState.offerAdultLearnToSwim}
              error={errorState.offerAdultLearnToSwim}
              message={errorState.offerAdultLearnToSwim}
              onChange={(value) => { updateFormState('offerAdultLearnToSwim', value) }} />
          </div>
          <div className="col-xs-12 col-sm-6">
            <YesNoSwitch
              label='Do you offer Adaptive or Special Needs Lessons?*'
              name="offerSpecialNeeds"
              checked={formState.offerSpecialNeeds}
              error={errorState.offerSpecialNeeds}
              message={errorState.offerSpecialNeeds}
              onChange={(value) => { updateFormState('offerSpecialNeeds', value) }} />
          </div>
          <div className="col-xs-12">
            <YesNoSwitch
              label='Agrees to all of the Terms and Conditions in the Indemnification Agreement?*'
              name="indemnificationAgreement"
              checked={formState.indemnificationAgreement}
              error={errorState.indemnificationAgreement}
              message={errorState.indemnificationAgreement}
              onChange={(value) => { updateFormState('indemnificationAgreement', value) }} />
          </div>
          <div className="col-xs-12 col-sm-6">
            <Input
              label="Signature*"
              name="indemnificationAgreementSignature"
              value={formState.indemnificationAgreementSignature}
              error={errorState.indemnificationAgreementSignature}
              message={errorState.indemnificationAgreementSignature}
              onChange={(value) => { updateFormState('indemnificationAgreementSignature', value); }} />
          </div>
          <div className="col-xs-12 col-sm-6">
            <ReadOnly
              label="Signature Date*"
              name="indemnificationAgreementSignatureDate"
              value={formState.indemnificationAgreementSignatureDate} />
          </div>
        </div>
        <div className="row usas-extra-top-margin">
          <div className="col-xs-12 usas-extra-top-margin usas-extra-bottom-margin">
            <Headings.H6>Logo (Optional)</Headings.H6>
          </div>
          <div className="col-xs-12">
            {formState.logoFile?.size > 0 || formState.logoUrl !== ''
              ? (
                <Fragment>
                  <SecondaryButton onClick={(e) => onLogoFileUnload(e)}>Remove Uploaded File</SecondaryButton>&nbsp;
                  {formState.logoUrl !== '' && <PrimaryButton type="button" onClick={(e) => onLogoFileDownload(e)}>Download Uploaded File</PrimaryButton>}
                  <p><b>Uploaded Logo File:</b> {formState.logoFile.name || getUploadedFileName(formState.logoUrl)}</p>
                </Fragment>
              ) : (
                <Fragment>
                  <label htmlFor="logo-file-upload" className={global.FileUpload}>Upload Logo</label>
                  <input hidden id="logo-file-upload" type="file" onChange={(e) => onLogoFileLoad(e)} />&nbsp;(*.jpg)
                  {errorState.logoFile && <p className={global.ErrorMessage}>{errorState.logoFile}</p>}
                </Fragment>
              )
            }
          </div>
          <div className="col-xs-12 usas-extra-top-margin usas-extra-bottom-margin">
            <Headings.H6>Curriculum (Required)</Headings.H6>
          </div>
          <div className="col-xs-12">
            {formState.curriculumFile?.size > 0 || formState.curriculumUrl !== ''
              ? (
                <Fragment>
                  <SecondaryButton onClick={(e) => onCurriculumFileUnload(e)}>Remove Uploaded File</SecondaryButton>&nbsp;
                  {formState.curriculumUrl !== '' && <PrimaryButton type="button" onClick={(e) => onCurriculumFileDownload(e)}>Download Uploaded File</PrimaryButton>}
                  <p><b>Uploaded Curriculum File:</b> {formState.curriculumFile.name || getUploadedFileName(formState.curriculumUrl)}</p>
                </Fragment>
              ) : (
                <Fragment>
                  <label htmlFor="curriculum-file-upload" className={global.FileUpload}>Upload Curriculum</label>
                  <input hidden id="curriculum-file-upload" type="file" onChange={(e) => onCurriculumFileLoad(e)} />&nbsp;(*.pdf), (*.doc), or (*.docx)
                  {errorState.curriculumFile && <p className={global.ErrorMessage}>{errorState.curriculumFile}</p>}
                </Fragment>
              )
            }
          </div>
          <div className="col-xs-12 usas-extra-top-margin usas-extra-bottom-margin">
            <Headings.H6>Proof of Insurance (Required)</Headings.H6>
          </div>
          <div className="col-xs-12">
            {formState.proofOfInsuranceFile?.size > 0 || formState.proofOfInsuranceUrl !== ''
              ? (
                <Fragment>
                  <SecondaryButton onClick={(e) => onProofOfInsuranceFileUnload(e)}>Remove Uploaded File</SecondaryButton>&nbsp;
                  {formState.proofOfInsuranceUrl !== '' && <PrimaryButton type="button" onClick={(e) => onProofOfInsuranceFileDownload(e)}>Download Uploaded File</PrimaryButton>}
                  <p><b>Uploaded Proof Of Insurance File:</b> {formState.proofOfInsuranceFile.name || getUploadedFileName(formState.proofOfInsuranceUrl)}</p>
                </Fragment>
              ) : (
                <Fragment>
                  <label htmlFor="proof-of-insurance-file-upload" className={global.FileUpload}>Upload Proof of Insurance</label>
                  <input hidden id="proof-of-insurance-file-upload" type="file" onChange={(e) => onProofOfInsuranceFileLoad(e)} />&nbsp;(*.pdf), (*.doc), or (*.docx)
                  {errorState.proofOfInsuranceFile && <p className={global.ErrorMessage}>{errorState.proofOfInsuranceFile}</p>}
                </Fragment>
              )
            }
          </div>
          <div className="col-xs-12 usas-extra-top-margin usas-extra-bottom-margin">
            <Headings.H6>W9 Form (Required)</Headings.H6>
          </div>
          <div className="col-xs-12">
            {formState.formW9File?.size > 0 || formState.formW9Url !== ''
              ? (
                <Fragment>
                  <SecondaryButton onClick={(e) => onFormW9FileUnload(e)}>Remove Uploaded File</SecondaryButton>&nbsp;
                  {formState.formW9Url !== '' && <PrimaryButton type="button" onClick={(e) => onFormW9FileDownload(e)}>Download Uploaded File</PrimaryButton>}
                  <p><b>Uploaded W9 Form File:</b> {formState.formW9File.name || getUploadedFileName(formState.formW9Url)}</p>
                </Fragment>
              ) : (
                <Fragment>
                  <label htmlFor="w9-form-file-upload" className={global.FileUpload}>Upload W9 Form</label>
                  <input hidden id="w9-form-file-upload" type="file" onChange={(e) => onFormW9FileLoad(e)} />&nbsp;(*.pdf), (*.doc), or (*.docx)
                  {errorState.formW9File && <p className={global.ErrorMessage}>{errorState.formW9File}</p>}
                </Fragment>
              )
            }
          </div>
        </div>
        <div className="row usas-extra-top-margin">
          <div className="col-xs-12 usas-extra-top-margin usas-extra-bottom-margin">
            <PrimaryButton type="submit">{submitButtonText}</PrimaryButton>&nbsp;
            <SecondaryButton type="button" onClick={resetFormFunction}>Cancel</SecondaryButton>
          </div>
        </div>
      </form>
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={isLoading} />
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.UPLOADING_MSG}
        displayPopUp={isSaving} />
    </Fragment >
  );
};

export default ProviderInfoForm;