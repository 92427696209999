import { CommonHttpHelper, NO_DATA_MESSAGE } from '../../../common/utils/HttpHelper';

const PostClubVendorForOrgUnitIdData = (clubVendorObj, state, setState) => {
  if (state.isSaving !== true && !state.message) {
    const newState = {
      ...state,
      isSaving: true,
      isSaved: false,
      message: 'Saving...'
    };
    setState(newState);

    const url = `/OrgUnitVendor`;
    CommonHttpHelper(url, 'POST', clubVendorObj)
      .then((objData) => {
        if (!objData) {
          throw new Error(NO_DATA_MESSAGE);
        } else {
          setState({
            ...newState,
            isObjLoaded: true,
            isSaving: false,
            isSaved: true,
            objData,
            message: ''
          });
        }
      })
      .catch((e) => {
        const message = e && e.message ? e.message : NO_DATA_MESSAGE;
        setState({
          ...newState,
          isObjLoaded: false,
          isSaving: false,
          isSaved: false,
          message: message
        });
      });
  }
};

export default PostClubVendorForOrgUnitIdData;