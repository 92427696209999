import React, { Fragment } from 'react';

import usePreCompLocationFacilities from './UsePreCompLocationFacilities';

import PreCompLocationFacilityGrid from '../components/grids/PreCompLocationFacilityGrid';

import Headings from '../../../../common/components/headings/Headings';
import PrimaryButton from '../../../../common/components/buttons/PrimaryButton';
import PopUpModal from '../../../../common/components/dialogs/PopUpModal';
import SecondaryButton from '../../../../common/components/buttons/SecondaryButton';
import Constants from '../../../../common/utils/Constants';
import ActionIntraPageButton from '../../../../common/components/buttons/ActionIntraPageButton';

const PreCompLocationFacilities = () => {
  const {
    teamFacilityLinksState,
    modalState,
    poolModalState,
    onAddFacility,
    onEditFacility,
    onAddPool,
    onPoolModalCanceled,
    onEditPool,
    onDeletePool,
    onClickDeletePool,
    onDeleteFacility,
    onModalCancelClicked,
    onModalDeleteClicked
  } = usePreCompLocationFacilities();

  return (
    <Fragment>
      <Headings.H3>Location Facilities</Headings.H3>
      <div className="row">
        <div className="col-xs-12 usas-extra-top-margin">
          <ActionIntraPageButton onClick={onAddFacility}>Add Facility</ActionIntraPageButton>
        </div>
      </div>
      <div className="row usas-extra-top-margin usas-extra-bottom-margin">
        <div className="col-xs-12">
          <PreCompLocationFacilityGrid
            data={teamFacilityLinksState.arrayData}
            isLoading={teamFacilityLinksState.isArrayLoading}
            onEdit={onEditFacility}
            onDelete={onDeleteFacility}
            onAddPool={onAddPool}
            onEditPool={onEditPool}
            onDeletePool={onDeletePool} />
        </div>
      </div>
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.SAVING_MSG}
        displayPopUp={teamFacilityLinksState.isSaving} />
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={teamFacilityLinksState.isArrayLoading} />
      <PopUpModal
        widthPct={90}
        maxWidth={720}
        title={"Unlink Facility?"}
        displayPopUp={modalState.displayPopUp}
        onModalCanceled={onModalCancelClicked}>
        <div className="row">
          <div className="col-xs-12">
            <p><b>Facility Name:</b> {modalState.facilityName}</p>
            <p><b>Facility Location:</b> {modalState.facilityCity}, {modalState.facilityStateCode} {modalState.facilityPostalCode}</p>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12 usas-extra-top-margin">
            <PrimaryButton type="button" onClick={() => onModalDeleteClicked(modalState.targetIndex)}>
              Unlink
            </PrimaryButton>&nbsp;
            <SecondaryButton type="button" onClick={onModalCancelClicked}>Cancel</SecondaryButton>
          </div>
        </div>
      </PopUpModal>
      <PopUpModal
        title={poolModalState.modalTitle}
        displayPopUp={poolModalState.displayPopup}
        widthPct={90}
        maxWidth={720}
        onModalCanceled={onPoolModalCanceled}>
        <div className="row">
          <div className="col-xs-12">
            <p><b>Pool Number / Name:</b> {poolModalState.poolName}</p>
            <p><b>Pool Dimensions:</b> {poolModalState.poolDimensions}</p>
          </div>
        </div>
        <div className="row usas-extra-top-margin">
          <div className="col-xs-12">
            <PrimaryButton type="button" onClick={(e) => { onClickDeletePool(e, poolModalState.poolId) }}>Yes, delete</PrimaryButton>&nbsp;
            <SecondaryButton type="button" onClick={onPoolModalCanceled}>Cancel</SecondaryButton>
          </div>
        </div>
      </PopUpModal>
    </Fragment >
  );
};

export default PreCompLocationFacilities;