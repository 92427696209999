import { isValidMemberId } from "../../../../common/utils/validation";

export const localValidate = (formState) => {
  let errors = {};

  if (formState.memberId.trim() === '') {
    errors.memberId = 'Member Id is required';
  } else if (!isValidMemberId(formState.memberId.trim())) {
    errors.memberId = 'Must be a valid Member Id';
  }

  return errors;
};

const MemberIdViewValidation = async (formState) => {
  let errors = localValidate(formState);

  return errors ? errors : {};
};

export default MemberIdViewValidation;