import React, { Fragment } from 'react';

import EditIcon from '../../../../../common/components/icons/EditIcon';
import DeleteIcon from '../../../../../common/components/icons/DeleteIcon';
import AddRemoveMembersIcon from '../../../../../common/components/icons/AddRemoveMembersIcon';

import global from '../../../../../common/components/GlobalStyle.module.css';

const TableRow = ({ clubGroup, onEditClubGroup, onDeleteClubGroup, onAddRemoveMember }) => (
  <tr>
    <td>{clubGroup?.groupName || ''}</td>
    <td>{clubGroup?.groupTypeName || ''}</td>
    <td>{clubGroup?.groupCode || ''}</td>
    <td>{clubGroup?.dateRange || ''}</td>
    <td>{clubGroup?.numberOfMembers ?? ''}</td>
    <td>
      <div >
        <button
          className={global.IconButtonMargin}
          type="button"
          onClick={(e) => onEditClubGroup(e, clubGroup.orgGroupId, clubGroup.groupTypeName)}>
          <EditIcon />
        </button>
        <button
          className={
            (clubGroup?.numberOfMembers > 0)
              ? global.HideComponent
              : global.IconButtonMargin
          }
          type="button"
          onClick={(e) => onDeleteClubGroup(e, clubGroup.orgGroupId, clubGroup.groupName)}>
          <DeleteIcon />
        </button>
        <button
          className={global.IconButtonMargin}
          type="button"
          onClick={(e) => onAddRemoveMember(e, clubGroup.orgGroupId)}>
          <AddRemoveMembersIcon />
        </button>
      </div>
    </td>
  </tr>
);

const ClubGroupsGridLarge = ({ state, onEditClubGroup, onDeleteClubGroup, onAddRemoveMember }) => (
  <Fragment>
    <table className={[global.UsasTable, 'visible-md visible-lg'].join(' ')}>
      <thead>
        <tr>
          <th>Group Name</th>
          <th>Group Type</th>
          <th>Group Code</th>
          <th>Date Range</th>
          <th>Members</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {state.isArrayLoaded !== true
          ? (
            <tr>
              {state.isArrayLoading === true ?
                <td colSpan="6">Loading...</td> :
                <td colSpan="6">No Groups</td>}
            </tr>)
          : Array.isArray(state.arrayData) && state.arrayData.length > 0
            ? state.arrayData.map((clubGroup, i) => <TableRow key={i} clubGroup={clubGroup} onEditClubGroup={onEditClubGroup} onDeleteClubGroup={onDeleteClubGroup} onAddRemoveMember={onAddRemoveMember} />)
            : (
              <tr>
                <td colSpan="6">No Groups</td>
              </tr>
            )}
      </tbody>
    </table>
  </Fragment>
);

export default ClubGroupsGridLarge;