import { useEffect, useState } from 'react';
import { useNavigate } from '../../../../../common/wrappers/ReactRouterDom';

import NavLinks from '../../NavLinks';

import useOrgGroupTypesData from '../../../../../common/state/orgGroupTypes/UseOrgGroupTypesData';
import useGroupFieldNamesData from '../../../../../common/state/groupFieldNames/UseGroupFieldNamesData';

import useForm from '../../../../../common/utils/UseForm';

const useClubGroupsSelectGroupType = () => {
  const navigate = useNavigate();
  const { GROUP_CONSTANTS } = useGroupFieldNamesData();
  const { orgGroupTypesState, getOrgGroupTypes } = useOrgGroupTypesData();
  const { formState, errorState, setFormState, setIsDirty
  } = useForm(getInitialFormState);
  const [state, setState] = useState(getInitialState);

  const onContinueClicked = () => {
    const targetGroup = state.orgGroupTypes.find(x => x.value === formState.selectedGroupType);

    if (targetGroup?.value) {
      switch (targetGroup.value) {
        case GROUP_CONSTANTS.GROUP_TYPE_NAME_LIST:
          navigate(NavLinks.CLUB_GROUPS_LIST_ADD, { state: { groupTypeId: targetGroup.id, groupTypeName: targetGroup.value } });
          break;
        case GROUP_CONSTANTS.GROUP_TYPE_NAME_TRAVEL_TRIP:
          navigate(NavLinks.CLUB_GROUPS_TRAVEL_ADD, { state: { groupTypeId: targetGroup.id, groupTypeName: targetGroup.value } });
          break;
        case GROUP_CONSTANTS.GROUP_TYPE_NAME_PRACTICE_GROUP:
          navigate(NavLinks.CLUB_GROUPS_PRACTICE_ADD, { state: { groupTypeId: targetGroup.id, groupTypeName: targetGroup.value } });
          break;
        case GROUP_CONSTANTS.GROUP_TYPE_NAME_CLUB_SITE:
          navigate(NavLinks.CLUB_GROUPS_CLUB_SITE_ADD, { state: { groupTypeId: targetGroup.id, groupTypeName: targetGroup.value } });
          break;
        default:
          setState({
            ...state,
            continueErrorMessage: 'An error occurred'
          });
      }
    } else {
      setState({
        ...state,
        continueErrorMessage: 'You must select a group type to continue'
      });
    }
  };

  const onBackClicked = () => {
    navigate(NavLinks.CLUB_GROUPS);
  };

  const onSelectedGroupTypeChange = (value) => {
    const newDescription = state.orgGroupTypes.find(x => x.value === value)?.description;
    setIsDirty(true);
    setFormState({
      ...formState,
      selectedGroupType: value,
      selectedGroupTypeDescription: newDescription || ''
    });
  };

  useEffect(() => {
    if (orgGroupTypesState.isArrayLoaded === false) {
      getOrgGroupTypes();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (orgGroupTypesState.isArrayLoaded === true) {
      setState({
        ...state,
        orgGroupTypes: filterGroupTypes(orgGroupTypesState.arrayData)
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orgGroupTypesState]);

  function filterGroupTypes(groupTypes) {
    const filteredGroupTypes = [];

    for (let i = 0; i < groupTypes.length; i++) {
      if (groupTypes[i].typeName === GROUP_CONSTANTS.GROUP_TYPE_NAME_LIST || groupTypes[i].typeName === GROUP_CONSTANTS.GROUP_TYPE_NAME_TRAVEL_TRIP
        || groupTypes[i].typeName === GROUP_CONSTANTS.GROUP_TYPE_NAME_PRACTICE_GROUP || groupTypes[i].typeName === GROUP_CONSTANTS.GROUP_TYPE_NAME_CLUB_SITE) {
        filteredGroupTypes.push({
          id: groupTypes[i].groupTypeId,
          value: groupTypes[i].typeName,
          label: groupTypes[i].typeName,
          description: groupTypes[i].typeDescription
        });
      }
    }

    return filteredGroupTypes;
  };

  function getInitialFormState() {
    return {
      selectedGroupType: '',
      selectedGroupTypeDescription: 'Please select a Group Type'
    };
  };

  function getInitialState() {
    return {
      orgGroupTypes: [],
      continueErrorMessage: ''
    };
  };

  return {
    orgGroupTypesState,
    state,
    formState,
    errorState,
    onSelectedGroupTypeChange,
    onContinueClicked,
    onBackClicked
  };
};

export default useClubGroupsSelectGroupType;