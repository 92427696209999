import Constants from "../../../../common/utils/Constants";

const createUniqueId = (name) => {
  return `!${name}${Math.round(Math.random() * 1000000)}`;
};

const getFivePercentOfTotal = (totalAmountCollected) => {
  return Math.round((totalAmountCollected * .05) * 100) / 100;
};

const createSATContractObj = (formState, orgUnitId, contractStatusId, satContract = undefined) => {
  const satContactId = satContract?.satContact[0]?.satContactId || undefined;
  const satAddressId = satContract?.satAddress[0]?.satAddressId || undefined;
  const satContractId = satContract?.satContractId || undefined;

  

  const today = new Date();

  const contactArray = [{
    satContactId,
    satContractId,
    contactName: formState.contactName,
    email: formState.contactEmail,
    phoneNumber: formState.contactPhoneNumber
  }];

  const addressArray = [{
    satAddressId,
    satContractId,
    address1: formState.address1,
    address2: formState.address2,
    city: formState.city,
    state: formState.stateCode,
    zip: formState.postalCode
  }];

  if (satContract) {
    return {
      ...satContract,
      orgUnitId,
      satDate: formState.satDate,
      fundraisingGoal: formState.fundraisingGoal,
      specialInstructions: formState.specialInstructions,
      contractStatusId,
      statusDate: today,
      signature: formState.signature,
      signatureDate: formState.signatureDate,
      fulfillmentDate: formState.fulfillmentDate !== Constants.BLANK_DATE_STRING ? formState.fulfillmentDate : '',
      numberOfAthletes: formState.numberOfAthletes,
      satAddress: addressArray,
      satContact: contactArray,
      contractStatus: undefined
    }
  } else {
    return {
      orgUnitId,
      satDate: formState.satDate,
      fundraisingGoal: formState.fundraisingGoal,
      specialInstructions: formState.specialInstructions,
      contractStatusId,
      statusDate: today,
      signature: formState.signature,
      signatureDate: formState.signatureDate,
      fulfillmentDate: formState.fulfillmentDate !== Constants.BLANK_DATE_STRING ? formState.fulfillmentDate : '',
      numberOfAthletes: formState.numberOfAthletes,
      satAddress: addressArray,
      satContact: contactArray,
      satResults: []
    };
  }
};

const createSATResultObj = (formState, resultStatusId, satContractId, satResult = undefined) => {
  return {
    ...satResult,
    satContractId,
    totalAmountCollected: formState.totalAmountCollected,
    donationToFoundation: getFivePercentOfTotal(formState.totalAmountCollected),
    bagTags100Length: formState.bagTags100Length,
    bagTags200Length: formState.bagTags200Length,
    resultStatusId: resultStatusId,
    resultStatus: undefined
  };
};

const createSATParticipants = (formState, satResultsId, satParticipants, satParticipantId = undefined) => {
  const newSatParticipants = satParticipants;

  if (satParticipantId) {
    const participantArrayIndex = satParticipants.findIndex(p => p.satParticipantId === satParticipantId);

    const participantObj = {
      satParticipantId,
      satResultsId,
      amountRaised: formState.amountRaised,
      participantName: formState.participantName,
      satPrizeId: formState.satPrizeId,
      satPrize: {
        description: formState.satPrizeName
      }
    };

    newSatParticipants[participantArrayIndex] = participantObj;
  } else {
    const participantObj = {
      satParticipantId: createUniqueId(formState.participantName),
      satResultsId,
      amountRaised: formState.amountRaised,
      participantName: formState.participantName,
      satPrizeId: formState.satPrizeId,
      satPrize: {
        description: formState.satPrizeName
      }
    };

    newSatParticipants.push(participantObj);
  }

  return newSatParticipants;
};

const createSATContractResultObj = (satContract, newResultObj, resultStatusId) => {
  newResultObj.resultStatusId = resultStatusId;
  newResultObj.resultStatus = undefined;

  const oldSatParticipant = Array.isArray(newResultObj.satParticipant) ? newResultObj.satParticipant : [];
  const newSatParticipant = [];

  for (const participant of oldSatParticipant) {
    const satParticipantId = isNaN(participant.satParticipantId) ? undefined : participant.satParticipantId;

    newSatParticipant.push({
      ...participant,
      satParticipantId,
      satPrize: undefined
    });
  };

  newResultObj.satParticipant = newSatParticipant;

  satContract.satResult = [newResultObj];

  return satContract;
};

export {
  createSATContractObj,
  createSATResultObj,
  createSATParticipants,
  createSATContractResultObj,
  getFivePercentOfTotal
};