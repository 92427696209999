import React from 'react';

import usePreCompRegistrationSubmit from './UsePreCompRegistrationSubmit';

import PreCompRegistrationConstants from '../utils/PreCompRegistrationConstants';

import Headings from '../../../../common/components/headings/Headings';
import PrimaryButton from '../../../../common/components/buttons/PrimaryButton';
import LeftNav from '../../../../common/components/leftNav/LeftNav';
import Input from '../../../../common/components/inputs/Input';
import ReadOnly from '../../../../common/components/readOnly/ReadOnly';
import SecondaryButton from '../../../../common/components/buttons/SecondaryButton';
import PopUpModal from '../../../../common/components/dialogs/PopUpModal';

import Constants from '../../../../common/utils/Constants';

const PreCompRegistrationSubmit = () => {
  const {
    isSaving,
    isLoading,
    displaySavedModal,
    locationRegistrationNavState,
    formState,
    errorState,
    handleSubmit,
    onFormValueChanged,
    onExitRegistrationClicked,
    onBackClicked
  } = usePreCompRegistrationSubmit();

  return (
    <LeftNav title={PreCompRegistrationConstants.LEFT_NAV_TITLE} state={locationRegistrationNavState} validateBeforeRedirect={false}>
      <Headings.H3>Pre-Competitive Registration Submit</Headings.H3>
      <form onSubmit={handleSubmit} noValidate>
        <div className="row">
          <div className="col-xs-12 col-sm-12">
            <p>By signing below, I certify that the information given on this form is truthful, accurate, and complete.</p>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12 col-sm-6 col-md-4">
            {onFormValueChanged === undefined
              ? <ReadOnly
                label="First and Last Name"
                name="signature"
                value={formState.signature} />
              : (
                <Input
                  label="First and Last Name*"
                  name="signature"
                  value={formState.signature}
                  error={errorState.signature}
                  message={errorState.signature}
                  onChange={(value) => { onFormValueChanged("signature", value); }} />
              )
            }
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4">
            <ReadOnly
              label="Date"
              name="submitDate"
              value={formState.submitDate} />
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12 col-sm-12">
            <p>USA Swimming will review your Pre-Competitive Application within the next 14 business days.
              At that time, you will receive an email approving/denying your application.</p>
          </div>
        </div>
        <div className="row usas-extra-top-margin usas-extra-bottom-margin">
          <div className="col-xs-12 usas-extra-top-margin">
            <PrimaryButton type="submit">Submit</PrimaryButton>&nbsp;
            <SecondaryButton type="button" onClick={onBackClicked}>Back</SecondaryButton>
          </div>
        </div>
      </form>
      <PopUpModal
        widthPct={90}
        maxWidth={720}
        title={"Pre-Competitive Application Sent"}
        displayPopUp={displaySavedModal}
        onModalCanceled={onExitRegistrationClicked}>
        <div className="row">
          <div className="col-xs-12">
            <p>USA Swimming will review your Pre-Competitive Application within the next 14 business days.
              At that time, you will receive an email verifying/rejecting your application.</p>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12 usas-extra-top-margin">
            <PrimaryButton type="button" onClick={onExitRegistrationClicked}>Exit Registration</PrimaryButton>
            {/* <SecondaryButton onClick={() => { }}>Download Application Copy</SecondaryButton> */}
          </div>
        </div>
      </PopUpModal>
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.SAVING_MSG}
        displayPopUp={isSaving} />
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={isLoading} />
    </LeftNav>
  );
};

export default PreCompRegistrationSubmit;