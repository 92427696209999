import useClubInactiveReasonData from '../../state/clubInactiveReason/UseClubInactiveReasonData';

const useClubInactiveReasonDropdown = () => {
  const { clubInactiveReasonState } = useClubInactiveReasonData();

  return {
    clubInactiveReasonState
  };
};

export default useClubInactiveReasonDropdown;