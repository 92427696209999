import { useState } from 'react';

import MemberRegistrationStatusData from './MemberRegistrationStatusData';

import { BASIC_INITIAL_STATE } from '../../../common/utils/HttpHelper';

const INITIAL_STATE = {
  ...BASIC_INITIAL_STATE
};

const useMemberRegistrationStatusData = () => {
  const [memberRegistrationStatusState, setMemberRegistrationStatusState] = useState(INITIAL_STATE);

  const getMemberRegistrationStatus = (memberId, roleGroupId) => {
    MemberRegistrationStatusData.getMemberRegistrationStatus(memberId, roleGroupId, memberRegistrationStatusState, setMemberRegistrationStatusState);
  }

  return {
    memberRegistrationStatusState,
    getMemberRegistrationStatus
  };
};

export default useMemberRegistrationStatusData;