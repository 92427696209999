import React, { Fragment } from 'react';

import usePreCompRegistrationOrganizationInfo from './UsePreCompRegistrationOrganizationInfo';

import PreCompRegistrationConstants from '../utils/PreCompRegistrationConstants';

import PreCompOrganizationBasicInfoForm from '../../preComp/components/forms/PreCompOrganizationBasicInfoForm';

import PrimaryButton from '../../../../common/components/buttons/PrimaryButton';
import SecondaryButton from '../../../../common/components/buttons/SecondaryButton';
import Headings from '../../../../common/components/headings/Headings';
import PopUpModal from '../../../../common/components/dialogs/PopUpModal';
import LeftNav from '../../../../common/components/leftNav/LeftNav';
import Constants from '../../../../common/utils/Constants';
import AddressValidation from '../../../../common/components/addressValidation/AddressValidation';

import global from '../../../../common/components/GlobalStyle.module.css';

const PreCompRegistrationOrganizationInfo = () => {
  const {
    isLoading,
    isSaving,
    formState,
    errorState,
    locationRegistrationNavState,
    displayExitModalState,
    onFormValueChanged,
    onValueTextPairChanged,
    onDownloadTemplateFile,
    onUploadWNineForm,
    onRemoveWNineForm,
    onDownloadUploadedFile,
    onBackClicked,
    onModalCancelClicked,
    onModalExitClicked,
    handleSubmitProxy,
    setFormData,
    validateState
  } = usePreCompRegistrationOrganizationInfo();

  return (
    <LeftNav title={PreCompRegistrationConstants.LEFT_NAV_TITLE} state={locationRegistrationNavState} validateBeforeRedirect={false}>
      <Headings.H3>Organization Info</Headings.H3>
      <div className="row usas-extra-top-margin">
        <div className="col-xs-12 usas-extra-top-margin">
          <Headings.H6>W-9 Form</Headings.H6>
        </div>
      </div>
      <form onSubmit={handleSubmitProxy} noValidate>
        <div className="row usas-extra-top-margin usas-extra-bottom-margin">
          <div className="col-xs-12">
            <PrimaryButton type="button" onClick={(event) => onDownloadTemplateFile(event)}>Download Template</PrimaryButton>&nbsp;
            {(formState.w9Form.size > 0 || formState.formW9Url !== '') ?
              <Fragment>
                <SecondaryButton onClick={onRemoveWNineForm}>Remove Uploaded File</SecondaryButton>&nbsp;
                {formState.formW9Name !== '' && <PrimaryButton type="button" onClick={(event) => onDownloadUploadedFile(event)}>Download Uploaded File</PrimaryButton>}
                <p><b>Uploaded File Name:</b> {formState.w9Form.name ? formState.w9Form.name : formState.formW9Name}</p>
              </Fragment> :
              <Fragment>
                <label htmlFor="wNine-form-file-upload" className={global.FileUpload}>
                  Upload File
                </label>
                <input hidden id="wNine-form-file-upload" type="file" onChange={onUploadWNineForm} />
                &nbsp;(*.pdf), (*.doc), or (*.docx)
                {errorState.w9Form && <p className={global.ErrorMessage}>{errorState.w9Form}</p>}
              </Fragment>}
          </div>
        </div>
        <PreCompOrganizationBasicInfoForm
          formState={formState}
          errorState={errorState}
          onFormValueChanged={onFormValueChanged}
          onValueTextPairChanged={onValueTextPairChanged} />
        {errorState.errorOnSubmitAction &&
          <div className="row">
            <div className="col-xs-12">
              <p className={global.ErrorMessage}>{errorState.errorOnSubmitAction || ''}</p>
            </div>
          </div>}
        <AddressValidation formState={formState} setFormData={setFormData} />
        <div className="row usas-extra-top-margin usas-extra-bottom-margin">
          <div className="col-xs-12 usas-extra-top-margin">
            <PrimaryButton type="submit">Continue</PrimaryButton>&nbsp;
            <SecondaryButton type="button" onClick={onBackClicked}>Exit</SecondaryButton>
          </div>
        </div>
      </form>
      {validateState.isValidating === true && (
        <PopUpModal
          displayPopUp={true}
          maxWidth={250}
          title="Validating..."
          widthPct={50} />
      )}
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={isLoading} />
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.SAVING_MSG}
        displayPopUp={isSaving} />
      <PopUpModal
        widthPct={90}
        maxWidth={720}
        title={PreCompRegistrationConstants.EXIT_REGISTRATION}
        displayPopUp={displayExitModalState}
        onModalCanceled={onModalCancelClicked}>
        <div className="row">
          <div className="col-xs-12">
            <p>Are you sure you want to exit Pre-Competitive Registration?</p>
            <p><b>All progress will be lost.</b></p>
          </div>
        </div>
        <div className="row usas-extra-top-margin">
          <div className="col-xs-12">
            <PrimaryButton type="button" onClick={onModalExitClicked}>Yes, Exit Registration</PrimaryButton>&nbsp;
            <SecondaryButton type="button" onClick={onModalCancelClicked}>Cancel</SecondaryButton>
          </div>
        </div>
      </PopUpModal>
    </LeftNav>
  );
};

export default PreCompRegistrationOrganizationInfo;