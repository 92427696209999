import { useState } from 'react';

import ClubNonAthleteRosterData from './ClubNonAthleteRosterData';

const useClubNonAthleteRosterData = () => {
  const [clubNonAthleteRosterState, setClubNonAthleteRosterState] = useState(ClubNonAthleteRosterData.INITIAL_STATE);

  const searchClubNonAthleteRoster = (usasClubId, filterObj) => ClubNonAthleteRosterData.searchClubNonAthleteRosterData(usasClubId, JSON.stringify(filterObj), clubNonAthleteRosterState, setClubNonAthleteRosterState);

  return {
    clubNonAthleteRosterState,
    searchClubNonAthleteRoster
  };
};

export default useClubNonAthleteRosterData;