import useClubAffiliationTypeDropdown from './UseClubAffiliationTypeDropdown';

import Dropdown from '../Dropdown';

import global from '../../GlobalStyle.module.css';

const ClubAffiliationTypeDropdown = ({ label, name, value, error, message, onChange }) => {
  const { clubAffiliationTypeState } = useClubAffiliationTypeDropdown();

  return clubAffiliationTypeState.message
    ? <span className={global.LoadingMsg}>{clubAffiliationTypeState.message}</span>
    : (
      <Dropdown
        label={label}
        name={name}
        value={value}
        error={error}
        message={message}
        onChange={onChange}
        options={clubAffiliationTypeState.options}
        isLoading={clubAffiliationTypeState.isArrayLoading}
      />
    );
};

export default ClubAffiliationTypeDropdown;