import { Fragment } from "react";

import ClubBulkRenewFiltersForm from "./forms/ClubBulkRenewFiltersForm";

import ClubBulkRenewSelectionGrid from "./grids/ClubBulkRenewSelectionGrid";

import PrimaryButton from "../../../../common/components/buttons/PrimaryButton";
import SecondaryButton from "../../../../common/components/buttons/SecondaryButton";
import PopUpModal from "../../../../common/components/dialogs/PopUpModal";
import Headings from "../../../../common/components/headings/Headings";

const ClubBulkRenewRenewalConfirmation = ({ competitionGenderOptions, formState, errorState, onValueTextPairChanged,
    onFormValueChanged, onFilterClicked, onClearFilterClicked, confirmationGridState, memberRegistrationRenewalSelectionState,
    onSaveClicked, onUnselectAll, onCheckboxChange, onModalCanceled, isAthleteRole, renewalConfirmationModalState,
    onRenewalConfirmationContinueClicked, onRenewalConfirmationModalCanceled, memberTypeSelectionFormState }) => {

    return (
        <Fragment>
            <ClubBulkRenewFiltersForm
                title={'Memberships to Renew'}
                competitionGenderOptions={competitionGenderOptions}
                formState={formState}
                errorState={errorState}
                onValueTextPairChanged={onValueTextPairChanged}
                onFormValueChanged={onFormValueChanged}
                onFilterClicked={onFilterClicked}
                onClearFilterClicked={onClearFilterClicked}
                isAthleteRole={isAthleteRole}
            />
            {confirmationGridState.gridData?.filter(x => x.isSelected === true)?.length > 5 && memberRegistrationRenewalSelectionState.isArrayLoading === false &&
                < div className='visible-md visible-lg' >
                    <div className="row usas-extra-top-margin usas-extra-bottom-margin">
                        <div className="col-xs-12 usas-extra-top-margin">
                            <SecondaryButton type="button" onClick={onModalCanceled}>Exit Bulk Renew</SecondaryButton>
                        </div>
                    </div>
                </div >}
            {confirmationGridState.gridData?.filter(x => x.isSelected === true)?.length > 0 && memberRegistrationRenewalSelectionState.isArrayLoading === false &&
                < div className='hidden-md hidden-lg' >
                    <div className="row usas-extra-top-margin usas-extra-bottom-margin">
                        <div className="col-xs-12 usas-extra-top-margin">
                            <SecondaryButton type="button" onClick={onModalCanceled}>Exit Bulk Renew</SecondaryButton>
                        </div>
                    </div>
                </div >}
            {isAthleteRole === true &&
                <div className="row usas-extra-top-margin">
                    <div className="col-xs-12 usas-extra-top-margin">
                        <Headings.H4>Currently Bulk Renewing: Athlete Memberships</Headings.H4>
                    </div>
                </div>}
            {isAthleteRole === false &&
                <div className="row usas-extra-top-margin">
                    <div className="col-xs-12 usas-extra-top-margin">
                        <Headings.H4>Currently Bulk Renewing: {memberTypeSelectionFormState?.renewalOfferingName || ''} Memberships</Headings.H4>
                    </div>
                </div>}
            <div className='row usas-extra-top-margin'>
                <div className="col-xs-12">
                    <ClubBulkRenewSelectionGrid
                        gridData={confirmationGridState.gridData?.filter(x => x.isSelected === true)}
                        isLoading={memberRegistrationRenewalSelectionState.isArrayLoading}
                        onCheckboxChange={onCheckboxChange}
                        selectionView={false}
                        onUnselectAll={onUnselectAll}
                        memberRegistrationRenewalSelectionState={memberRegistrationRenewalSelectionState}
                        onSaveClicked={onSaveClicked}
                        confirmationGridState={confirmationGridState}
                        errorState={errorState}
                    />
                </div>
            </div>
            <div className="row usas-extra-top-margin usas-extra-bottom-margin">
                <div className="col-xs-12 usas-extra-top-margin">
                    <SecondaryButton type="button" onClick={onModalCanceled}>Exit Bulk Renew</SecondaryButton>
                </div>
            </div>
            {isAthleteRole === true &&
                <div className="row usas-extra-top-margin">
                    <div className="col-xs-12 usas-extra-top-margin">
                        <Headings.H4>Currently Bulk Renewing: Athlete Memberships</Headings.H4>
                    </div>
                </div>}
            {isAthleteRole === false &&
                <div className="row usas-extra-top-margin">
                    <div className="col-xs-12 usas-extra-top-margin">
                        <Headings.H4>Currently Bulk Renewing: {memberTypeSelectionFormState?.renewalOfferingName || ''} Memberships</Headings.H4>
                    </div>
                </div>}
            <PopUpModal
                widthPct={90}
                maxWidth={720}
                title={renewalConfirmationModalState.modalTitle}
                displayPopUp={renewalConfirmationModalState.displayPopUp && !memberRegistrationRenewalSelectionState.isSaving}
                onModalCanceled={onRenewalConfirmationModalCanceled}>
                <form>
                    <div className="row">
                        <div className="col-xs-12">
                            <b>Your selected renewal memberships have been added to the shopping cart!</b>
                        </div>
                        <div className="col-xs-12 usas-extra-top-margin usas-extra-bottom-margin">
                            <span>Please choose between continuing to the shopping cart to complete your renewal purchases, or staying on this page and starting the process to renew more memberships.</span>
                        </div>
                        <div className="col-xs-12 usas-extra-top-margin">
                            <PrimaryButton type="button" onClick={onRenewalConfirmationContinueClicked}>Continue to Shopping Cart</PrimaryButton>&nbsp;
                            <SecondaryButton type="button" onClick={onRenewalConfirmationModalCanceled}>Renew More Memberships</SecondaryButton>
                        </div>
                    </div>
                </form>
            </PopUpModal>
        </Fragment>
    )
};

export default ClubBulkRenewRenewalConfirmation;