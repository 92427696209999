import React, { createContext, useState } from 'react';

import RecognitionProgramData from './RecognitionProgramData';

export const ProgramFocusStateContext = createContext();

const ProgramFocusContextProvider = ({ children }) => {
  const stateHook = useState(RecognitionProgramData.INITIAL_PROGRAM_INSTANCE_STATE);

  return (
    <ProgramFocusStateContext.Provider value={stateHook}>
      {children}
    </ProgramFocusStateContext.Provider>
  );
};

export default ProgramFocusContextProvider;