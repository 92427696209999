import { Fragment } from "react";

import useClubSwimAThonResult from './UseClubSwimAThonResult';

import SATResultsGrid from "../components/grids/SATResultsGrid";

import Headings from "../../../../common/components/headings/Headings";
import PopUpModal from "../../../../common/components/dialogs/PopUpModal";
import ActionIntraPageButton from "../../../../common/components/buttons/ActionIntraPageButton";

import Constants from "../../../../common/utils/Constants";

const ClubSwimAThonResult = () => {
  const {
    satState,
    onEditResult,
    onPaymentsClicked
  } = useClubSwimAThonResult();

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>Swim-a-Thon Results</Headings.H3>
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12 usas-extra-top-margin">
          <ActionIntraPageButton onClick={onPaymentsClicked}>Swim-a-Thon Payments</ActionIntraPageButton>
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12 usas-extra-top-margin usas-extra-bottom-margin">
          <SATResultsGrid
            data={satState.objData?.satContracts || []}
            isLoading={satState.isObjLoading}
            onEdit={onEditResult} />
        </div>
      </div>
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={satState.isObjLoading} />
    </Fragment>
  );
};

export default ClubSwimAThonResult;
