import { isValidLink } from "../../utils/LearnToSwimUtils";

import Constants from "../../../../../common/utils/Constants";
import { isValidEIN, isValidITIN } from "../../../../../common/utils/validation";

export const localValidate = (formState) => {
  let errors = {};

  if (formState.entityName.trim() === '') {
    errors.entityName = 'Business Legal Name is required';
  }

  if (formState.tradeName.trim() === '') {
    errors.tradeName = 'Trade Name/DBA is required';
  }

  if (formState.taxId.trim() === '') {
    errors.taxId = 'Tax Id is required';
  } else if (!isValidITIN(formState.taxId.trim()) && !isValidEIN(formState.taxId.trim())) {
    errors.taxId = 'Must be valid Tax Id format XXX-XX-XXXX or XX-XXXXXXX';
  }

  if (!(formState.learnToSwimCurriculumId > 0)) {
    errors.learnToSwimCurriculumId = 'Learn To Swim Curriculum is required';
  }

  if (formState.website.trim() === '') {
    errors.website = 'Website is required';
  } else if (!isValidLink(formState.website)) {
    errors.website = `Website must begin with 'http://' or 'https://'`;
  }

  if (formState.offersFinancialAssistance !== true && formState.offersFinancialAssistance !== false) {
    errors.offersFinancialAssistance = 'Must select yes or no for this field';
  }

  if (formState.currentLTSGrantRecipient !== true && formState.currentLTSGrantRecipient !== false) {
    errors.currentLTSGrantRecipient = 'Must select yes or no for this field';
  }

  if (formState.affiliatedWithUsasClub !== true && formState.affiliatedWithUsasClub !== false) {
    errors.affiliatedWithUsasClub = 'Must select yes or no for this field';
  }

  if (formState.affiliatedWithUsasClub === true) {
    if (formState.usasClubId === '' || formState.usasClubId === Constants.DEFAULT_ID) {
      if (formState.usasClubName !== '') {
        errors.usasClubId = 'Must select a valid USAS Club';
      } else {
        errors.usasClubId = 'Affiliated USAS Club is required';
      }
    }
  }


  if (formState.childProtectionTraining !== true && formState.childProtectionTraining !== false) {
    errors.childProtectionTraining = 'Must select yes or no for this field';
  }

  if (formState.offerAdultLearnToSwim !== true && formState.offerAdultLearnToSwim !== false) {
    errors.offerAdultLearnToSwim = 'Must select yes or no for this field';
  }

  if (formState.offerSpecialNeeds !== true && formState.offerSpecialNeeds !== false) {
    errors.offerSpecialNeeds = 'Must select yes or no for this field';
  }

  if (formState.backgroundCheckAcknowledgement !== true && formState.backgroundCheckAcknowledgement !== false) {
    errors.backgroundCheckAcknowledgement = 'Must select yes or no for this field';
  }

  if (formState.backgroundCheckAcknowledgement === false && formState.backgroundCheckNotUsedExplanation.trim() === '') {
    errors.backgroundCheckNotUsedExplanation = 'An explanation is required';
  }

  if (formState.indemnificationAgreement !== true) {
    errors.indemnificationAgreement = 'Must select yes for this field';
  }

  if (formState.indemnificationAgreementSignature.trim() === '') {
    errors.indemnificationAgreementSignature = 'A Signature is required';
  }

  if (formState.logoFile?.size) {
    if (formState.logoFile.type !== Constants.FILE_TYPE_JPG) {
      errors.logoFile = 'File type not permitted. Please provide a (*.jpg) file.';
    }
  }

  if (formState.curriculumFile?.size) {
    if (formState.curriculumFile.type !== Constants.FILE_TYPE_PDF && formState.curriculumFile.type !== Constants.FILE_TYPE_DOC &&
      formState.curriculumFile.type !== Constants.FILE_TYPE_DOCX) {
      errors.curriculumFile = 'File type not permitted. Please provide a (*.pdf), (*.doc), or (*.docx) file.';
    }
  }

  if (formState.proofOfInsuranceFile?.size) {
    if (formState.proofOfInsuranceFile.type !== Constants.FILE_TYPE_PDF && formState.proofOfInsuranceFile.type !== Constants.FILE_TYPE_DOC &&
      formState.proofOfInsuranceFile.type !== Constants.FILE_TYPE_DOCX) {
      errors.proofOfInsuranceFile = 'File type not permitted. Please provide a (*.pdf), (*.doc), or (*.docx) file.';
    }
  }

  if (formState.formW9File?.size) {
    if (formState.formW9File.type !== Constants.FILE_TYPE_PDF && formState.formW9File.type !== Constants.FILE_TYPE_DOC &&
      formState.formW9File.type !== Constants.FILE_TYPE_DOCX) {
      errors.formW9File = 'File type not permitted. Please provide a (*.pdf), (*.doc), or (*.docx) file.';
    }
  }

  return errors;
};

const ProviderInfoFormValidation = async (formState) => {
  let errors = localValidate(formState);

  return errors ? errors : {};
};

export default ProviderInfoFormValidation;