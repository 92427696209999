import { useEffect } from 'react';

import useLearnToSwimCurriculumData from '../../../state/learnToSwimCurriculum/UseLearnToSwimCurriculumData';

import Constants from '../../../../common/utils/Constants';

const useLearnToSwimCurriculumDropdown = () => {
  const { ltsCurriculumState, setLTSCurriculumState, getLTSCurriculum } = useLearnToSwimCurriculumData();

  useEffect(() => {
    if (ltsCurriculumState.isArrayLoading !== true && ltsCurriculumState.isArrayLoaded !== true) {
      const getLTSCurriculumPromise = getLTSCurriculum();

      if (getLTSCurriculumPromise !== null) {
        getLTSCurriculumPromise.then((newState) => {
          if (newState !== null) {
            const items = newState.arrayData.map(x => { return { id: x.learnToSwimCurriculumId, name: x.curriculumName } });
            items.unshift({ learnToSwimCurriculumId: Constants.DEFAULT_ID, curriculumName: '--' })

            setLTSCurriculumState({
              ...newState,
              items,
              areItemsLoaded: true
            });
          }
        }).catch((e) => {
          //TODO context error
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ltsCurriculumState]);

  return {
    ltsCurriculumState
  };
};

export default useLearnToSwimCurriculumDropdown;