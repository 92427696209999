import { useEffect, useState } from 'react';
import { useNavigate } from '../../../../common/wrappers/ReactRouterDom';

import validate from './ClubRegistrationScreeningPoliciesValidation';

import NavLinks from '../NavLinks';

import ClubRegistrationConstants from '../utils/ClubRegistrationConstants';

import useClubRegistrationNavData from '../../../state/clubRegistration/leftNavigation/UseClubRegistrationNavData';
import useClubRegistrationNewData from '../../../state/clubRegistrationNew/UseClubRegistrationNewData';

import useForm from '../../../../common/utils/UseForm';
import Constants from '../../../../common/utils/Constants';
import { formatDate } from '../../../../common/utils/DateFunctions';
import ToBoolIfBool from '../../../../common/utils/ToBoolIfBool';

const useClubRegistrationScreeningPolicies = () => {
  const navigate = useNavigate();
  const [state, setState] = useState({ submitButton: '', tryRedirect: false });
  const [submitPrequisitesState, setSubmitPrequisitesState] = useState({ areSubmitPrerequisitesDone: false });
  const [modalState, setModalState] = useState(getInitialModalState());
  const { clubRegistrationNavState, setClubRegistrationNavState } = useClubRegistrationNavData();
  const { clubRegistrationState, putUsasClub } = useClubRegistrationNewData();
  const { handleSubmit, updateFormState, formState, errorState, setFormData, isSubmitting, isDirty, setIsDirty
  } = useForm(getFormInitialState(), submitFormCallback, validate);

  const editClubObj = () => {
    let clubCopy = JSON.parse(JSON.stringify(clubRegistrationState.objData));
    //Renewing club


    if (clubCopy.usasClubAcknowledgement.length === 0) {
      clubCopy.usasClubAcknowledgement = [{
        usasClubId: clubCopy.usasClubId,
        preEmploymentScreeningRequired: formState.preEmploymentScreeningRequired,
        racingStartCertified: formState.racingStartCertified,
        stateConcussionsLaws: formState.stateConcussionsLaws,
        minorAthleteAbusePreventionPolicy: formState.minorAthleteAbusePreventionPolicy,
        acknowledgementsSignature: formState.acknowledgementsSignature.trim().length > 0 ? 
          formState.acknowledgementsSignature.trim() : null,
        acknowledgementsSignatureDate: formState.acknowledgementsSignatureDate !== Constants.BLANK_DATE_STRING ? 
          formState.acknowledgementsSignatureDate : null
      }]
    } else {
      clubCopy.usasClubAcknowledgement = [{
        ...clubCopy.usasClubAcknowledgement[0],
        preEmploymentScreeningRequired: formState.preEmploymentScreeningRequired,
        racingStartCertified: formState.racingStartCertified,
        stateConcussionsLaws: formState.stateConcussionsLaws,
        minorAthleteAbusePreventionPolicy: formState.minorAthleteAbusePreventionPolicy,
        acknowledgementsSignature: formState.acknowledgementsSignature.trim().length > 0 ? 
          formState.acknowledgementsSignature.trim() : null,
        acknowledgementsSignatureDate: formState.acknowledgementsSignatureDate !== Constants.BLANK_DATE_STRING ? 
          formState.acknowledgementsSignatureDate : null
      }]
    }



    //New club

    return clubCopy;
  }

  function submitFormCallback() {
    putUsasClub(clubRegistrationState.objData.usasClubId, editClubObj());
    if (state.submitButton === ClubRegistrationConstants.EXIT) {
      setState({ ...state, tryRedirect: false });
    }
    else {
      setState({ ...state, tryRedirect: true });
    }
  };

  const onContinueClicked = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    handleSubmit();
    if (isDirty === false) {
      setState({ ...state, tryRedirect: true, submitButton: ClubRegistrationConstants.CONTINUE });
    }
    else {
      setState({ ...state, submitButton: ClubRegistrationConstants.CONTINUE });
    }
  }

  const onSaveAndExitClicked = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    handleSubmit();
    setState({ ...state, submitButton: ClubRegistrationConstants.EXIT });
  }

  const onBackClicked = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    handleSubmit();
    if (isDirty === false) {
      setState({ ...state, tryRedirect: true, submitButton: ClubRegistrationConstants.BACK });
    }
    else {
      setState({ ...state, submitButton: ClubRegistrationConstants.BACK });
    }
  }

  const onOpenModal = () => {
    if (Object.keys(errorState).length > 0) {
      setModalState({ ...modalState, displayPopUp: true });
    }
    else {
      setModalState({ ...modalState, modalTitle: ClubRegistrationConstants.EXIT_CONFIRMATION, displayPopUp: true });
    }
  }

  const onModalContinue = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    setState({ ...state, tryRedirect: true });
    setModalState({ ...getInitialModalState() });
  }

  const onModalCanceled = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    setModalState({ ...getInitialModalState() });
  }

  const updateNavState = () => {
    for (let i = 0; i < clubRegistrationNavState.length; i++) {
      clubRegistrationNavState[i].isCurrent = false
    }
    const navIndex = clubRegistrationNavState.findIndex(x => x.name === ClubRegistrationConstants.SCREENING_POLICIES)

    if (clubRegistrationState.objData?.usasClubAcknowledgement?.length > 0) {
      //New club
      if (clubRegistrationState.objData.usasClubAcknowledgement?.length === 1) {  

        if (clubRegistrationState.objData?.usasClubAcknowledgement[0].preEmploymentScreeningRequired === true &&
          clubRegistrationState.objData?.usasClubAcknowledgement[0].racingStartCertified === true &&
          clubRegistrationState.objData?.usasClubAcknowledgement[0].stateConcussionsLaws === true &&
          clubRegistrationState.objData?.usasClubAcknowledgement[0].minorAthleteAbusePreventionPolicy === true) {
          clubRegistrationNavState[navIndex] =
          {
            ...clubRegistrationNavState[navIndex],
            isCurrent: true,
            isComplete: true,
            isVisited: true,
            arePrerequisitesDone: true
          }
        }
        else {
          clubRegistrationNavState[navIndex] =
          {
            ...clubRegistrationNavState[navIndex],
            isCurrent: true,
            isComplete: false,
            isVisited: true,
            arePrerequisitesDone: true
          }
        }
      }
      //Renewing club
    }
    else {
      clubRegistrationNavState[navIndex] =
      {
        ...clubRegistrationNavState[navIndex],
        isCurrent: true,
        isComplete: false,
        isVisited: true,
        arePrerequisitesDone: true
      }
    }

    //Submit
    let areSubmitPrerequisitesDone = true;
    let isSubmitComplete = false;
    for (let i = 0; i < clubRegistrationNavState.length; i++) {
      if (clubRegistrationNavState[i].name !== ClubRegistrationConstants.SUBMIT
        && clubRegistrationNavState[i].isComplete === false) {
        areSubmitPrerequisitesDone = false;
      }
      if (clubRegistrationNavState[i].name === ClubRegistrationConstants.SUBMIT) {
        if (clubRegistrationState.objData.status === ClubRegistrationConstants.PENDING_STATUS ||
          clubRegistrationState.objData.status === ClubRegistrationConstants.APPROVED_STATUS) {
          isSubmitComplete = true
        }
      }
    }
    const submitNavIndex = clubRegistrationNavState.findIndex(x => x.name === ClubRegistrationConstants.SUBMIT)
    clubRegistrationNavState[submitNavIndex] =
    {
      ...clubRegistrationNavState[submitNavIndex],
      isCurrent: false,
      isComplete: isSubmitComplete,
      arePrerequisitesDone: areSubmitPrerequisitesDone
    }
    setSubmitPrequisitesState({ ...state, areSubmitPrerequisitesDone: areSubmitPrerequisitesDone });
    setClubRegistrationNavState(clubRegistrationNavState);
  }

  useEffect(() => {
    if (clubRegistrationState.isObjLoaded === false) {
      navigate(NavLinks.CLUB_REGISTRATION_ENTRY_NEW);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const navIndex = clubRegistrationNavState.findIndex(x => x.name === ClubRegistrationConstants.SCREENING_POLICIES)
    if (clubRegistrationNavState[navIndex].isCurrent === false) {
      updateNavState();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clubRegistrationNavState])

  useEffect(() => {
    const submitNavIndex = clubRegistrationNavState.findIndex(x => x.name === ClubRegistrationConstants.SUBMIT)
    if (clubRegistrationNavState[submitNavIndex].arePrerequisitesDone === false && submitPrequisitesState.areSubmitPrerequisitesDone === true) {
      updateNavState();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clubRegistrationNavState, submitPrequisitesState.areSubmitPrerequisitesDone])

  useEffect(() => {
    if (Object.keys(clubRegistrationState.objData).length > 0) {
      let today = formatDate(new Date());
      updateNavState();
      //New club
      if (clubRegistrationState.objData.usasClubAcknowledgement.length === 0) {
        setFormData({
          ...formState,
          isNew: true,
          today: today,
          acknowledgementsSignatureDate: today,
        });
      }
      else if (clubRegistrationState.objData.usasClubAcknowledgement.length === 1) {
        setFormData({
          ...formState,
          isNew: true,
          today: today,
          preEmploymentScreeningRequired: ToBoolIfBool(clubRegistrationState.objData.usasClubAcknowledgement[0].preEmploymentScreeningRequired) !== null ?
            ToBoolIfBool(clubRegistrationState.objData.usasClubAcknowledgement[0].preEmploymentScreeningRequired) : false,
          racingStartCertified: ToBoolIfBool(clubRegistrationState.objData.usasClubAcknowledgement[0].racingStartCertified) !== null ?
            ToBoolIfBool(clubRegistrationState.objData.usasClubAcknowledgement[0].racingStartCertified) : false,
          stateConcussionsLaws: ToBoolIfBool(clubRegistrationState.objData.usasClubAcknowledgement[0].stateConcussionsLaws) !== null ?
            ToBoolIfBool(clubRegistrationState.objData.usasClubAcknowledgement[0].stateConcussionsLaws) : false,
          minorAthleteAbusePreventionPolicy: ToBoolIfBool(clubRegistrationState.objData.usasClubAcknowledgement[0].minorAthleteAbusePreventionPolicy) !== null ?
            ToBoolIfBool(clubRegistrationState.objData.usasClubAcknowledgement[0].minorAthleteAbusePreventionPolicy) : false,
          acknowledgementsSignature:  clubRegistrationState.objData.usasClubAcknowledgement[0].acknowledgementsSignature,
          acknowledgementsSignatureDate: clubRegistrationState.objData.usasClubAcknowledgement[0].acknowledgementsSignatureDate ?
             formatDate(clubRegistrationState.objData.usasClubAcknowledgement[0].acknowledgementsSignatureDate) : today,

          clubRegistrationsLength: 1
        });
      }


      if (clubRegistrationState.objData.status === ClubRegistrationConstants.IN_PROGRESS_STATUS
        || clubRegistrationState.objData.status === ClubRegistrationConstants.RENEWING_STATUS) {
        setIsDirty(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clubRegistrationState.objData])

  useEffect(() => {
    //Handle validation modal and navigation
    if (state.submitButton !== '' && clubRegistrationState.isSaving === false &&
      clubRegistrationState.isObjLoaded === true && isSubmitting === false) {
      if (state.tryRedirect === false) {
        if (Object.keys(errorState).length > 0 || state.submitButton === ClubRegistrationConstants.EXIT) {
          onOpenModal();
        }
      }
      if (state.tryRedirect === true) {
        if (Object.keys(errorState).length === 0) {
          const navIndex = clubRegistrationNavState.findIndex(x => x.name === ClubRegistrationConstants.SCREENING_POLICIES)
          clubRegistrationNavState[navIndex] =
          {
            ...clubRegistrationNavState[navIndex],
            isCurrent: false,
            isComplete: true,
            isVisited: true,
            arePrerequisitesDone: true
          }
          //Submit
          let areSubmitPrerequisitesDone = true;
          let isSubmitComplete = false;
          for (let i = 0; i < clubRegistrationNavState.length; i++) {
            if (clubRegistrationNavState[i].name !== ClubRegistrationConstants.SUBMIT
              && clubRegistrationNavState[i].isComplete === false) {
              areSubmitPrerequisitesDone = false;
            }
            if (clubRegistrationNavState[i].name === ClubRegistrationConstants.SUBMIT) {
              if (clubRegistrationState.objData.status === ClubRegistrationConstants.PENDING_STATUS ||
                clubRegistrationState.objData.status === ClubRegistrationConstants.APPROVED_STATUS) {
                isSubmitComplete = true
              }
            }
          }
          const submitNavIndex = clubRegistrationNavState.findIndex(x => x.name === ClubRegistrationConstants.SUBMIT)
          clubRegistrationNavState[submitNavIndex] =
          {
            ...clubRegistrationNavState[submitNavIndex],
            isCurrent: false,
            isComplete: isSubmitComplete,
            arePrerequisitesDone: areSubmitPrerequisitesDone
          }
          setClubRegistrationNavState(clubRegistrationNavState);
        }
        if (state.submitButton === ClubRegistrationConstants.CONTINUE) {
          navigate(NavLinks.CLUB_REGISTRATION_FACILITY);
        }
        else if (state.submitButton === ClubRegistrationConstants.EXIT) {
          navigate(NavLinks.CLUB_REGISTRATION_ENTRY_NEW);
        }
        else if (state.submitButton === ClubRegistrationConstants.BACK) {
          navigate(NavLinks.CLUB_REGISTRATION_AFFILIATION);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state, errorState, isSubmitting, clubRegistrationState.isObjLoaded, clubRegistrationState.isSaving])

  return {
    clubRegistrationNavState,
    clubRegistrationState,
    state,
    modalState,
    onModalContinue,
    onModalCanceled,
    formState,
    errorState,
    handleSubmit,
    onFormValueChanged: updateFormState,
    onContinueClicked,
    onSaveAndExitClicked,
    onBackClicked
  }
}
export default useClubRegistrationScreeningPolicies;

function getFormInitialState() {
  return {
    isNew: false,
    today: Constants.BLANK_DATE_STRING,
    preEmploymentScreeningRequired: false,
    racingStartCertified: false,
    stateConcussionsLaws: false,
    minorAthleteAbusePreventionPolicy: false,
    acknowledgementsSignature: '',
    acknowledgementsSignatureDate: Constants.BLANK_DATE_STRING,
    clubRegistrationsLength: 0
  }
};

function getInitialModalState() {
  return {
    displayPopUp: false,
    modalTitle: ClubRegistrationConstants.MISSING_REQUIRED_INFORMATION
  }
};