import { useEffect, useState } from "react";
import { useNavigate } from "../../../../common/wrappers/ReactRouterDom";
import { navigateToCrossUiRoute } from '../../../../common/utils/FormatCrossUiRoute';

import NavLinks from "../NavLinks";

import useBaseUsasClubData from "../../../../common/state/clubBase/UseBaseUsasClubData";
import useEmbeddedReportData from '../../../../common/state/embeddedReport/UseEmbeddedReportData';
import UseForm from "../../../../common/utils/UseForm";
import Constants from "../../../../common/utils/Constants";
import useGlobalRoutesData from '../../../../common/state/security/UseGlobalRoutesData';
import useReportPeriodSeasonData from "../../../../common/state/reportPeriodSeason/UseReportPeriodSeasonData";
import { formatDate } from "../../../../common/utils/DateFunctions";

import useClubBlockPartyMeetsData from "../../../state/clubBlockPartyMeets/UseClubBlockPartyMeetsData";

const INITIAL_VIEW_STATE = {
  reportParameters: { MeetId: '' },
  MeetId: '',
  routeName: '',
  isLoading: false,
  reportViewerType: '',
  reportName: ''
};

const UseClubBlockPartyMeets = () => {
  const navigate = useNavigate();
  const { baseUsasClubState } = useBaseUsasClubData();
  const { clubBlockPartyMeetsState, getClubBlockPartyMeets, deleteClubBlockPartyMeet, confirmSave } = useClubBlockPartyMeetsData();
  const { formState, errorState, onValueTextPairChanged, setFormState } = UseForm(getInitialFormState, () => { }, () => { });
  const { reportListState, getEmbeddedReportListForRoute } = useEmbeddedReportData();
  const { getGlobalRoute } = useGlobalRoutesData();
  const { reportPeriodSeasonState } = useReportPeriodSeasonData();

  const [gridState, setGridState] = useState([]);
  const [uiState, setUiState] = useState({ hasBeenDeleted: false });
  const [viewState, setViewState] = useState(INITIAL_VIEW_STATE);

  const onDelete = (meet) => {
    if (clubBlockPartyMeetsState.isArrayLoaded === true) {
      deleteClubBlockPartyMeet(meet.meetId);
      setUiState({ hasBeenDeleted: true });
    }
  };

  const onViewReport = (meet) => {
    setViewState({
      ...viewState,
      reportParameters: { MeetId: meet.meetId },
      routeName: 'CLUB_BLOCKPARTY',
      isLoading: true,
      reportViewerType: 'ActiveReports',
      reportName: 'Block Party Summary'
    });
  }

  const onViewMeetAnnouncementReport = (meet) => {
    setViewState({
      ...viewState,
      reportParameters: { MeetId: meet.meetId },
      routeName: 'CLUB_BLOCKPARTY',
      isLoading: true,
      reportViewerType: 'ActiveReports',
      reportName: 'Meet Announcement'
    });
  }

  const onEdit = (meet) => {
    navigate(NavLinks.CLUB_BLOCKPARTY_ADD_ROLE, { state: { meet } });
  }

  const onGoToCart = (meet) => {
    const paymentsRoute = getGlobalRoute('PAYMENTS_SHOPPING_CART');

    if (paymentsRoute && paymentsRoute.route) {
      navigateToCrossUiRoute(paymentsRoute.uIProjectName, paymentsRoute.route, navigate);
    }
  }

  const onUploadClicked = (meet) => {
    navigate(NavLinks.CLUB_MEETS_UPLOAD, { state: { meet } });
  };

  const onMeetReconClicked = (meet) => {
    navigate(NavLinks.CLUB_MEETS_RECON, { state: { meet } });
  }

  const onReportPeriodChanged = (value, valueLabel) => {
    onValueTextPairChanged(value, 'reportPeriodId', valueLabel, 'reportPeriodLabel');
  };

  const onAddBlockPartyMeetClicked = () => {
    navigate(NavLinks.CLUB_BLOCKPARTY_ADD);
  };

  useEffect(() => {
    if (viewState.routeName) {
      const reportListPromise = getEmbeddedReportListForRoute(viewState.routeName);

      if (reportListPromise) {
        reportListPromise.then((stateObj) => {
          if (Array.isArray(stateObj.arrayData)) {
            const reportIdx = stateObj.arrayData.findIndex(x => x.reportName === viewState.reportName);

            setViewState({
              ...viewState,
              isLoading: false,
              showReport: stateObj.arrayData.length > 0,
              reportInfoId: stateObj.arrayData[reportIdx].reportInfoId,
            });
          } else {
            setViewState({
              ...viewState,
              isLoading: false,
              showReport: false,
              reportInfoId: -1
            });
          }
        }).catch((err) => {
          setViewState({
            ...viewState,
            isLoading: false,
            showReport: false,
            reportInfoId: -1
          });
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewState.routeName, viewState.reportViewerType]);

  useEffect(() => {
    if (uiState.hasBeenDeleted === true && clubBlockPartyMeetsState.isSaving === false && clubBlockPartyMeetsState.isArrayLoading === false) {
      setUiState({ hasBeenDeleted: false });
      getClubBlockPartyMeets(baseUsasClubState.objData.orgUnitId, formState.reportPeriodId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uiState, clubBlockPartyMeetsState]);

  useEffect(() => {
    if (clubBlockPartyMeetsState.isArrayLoading === false && clubBlockPartyMeetsState.isArrayLoaded === true) {
      const today = new Date();
      const newMeetArray = [];

      for (const meet of clubBlockPartyMeetsState.arrayData) {
        const meetStartDate = new Date(meet.startDate);

        newMeetArray.push({
          ...meet,
          isPastMeet: today >= meetStartDate
        });
      }

      setGridState(newMeetArray);
    }
  }, [clubBlockPartyMeetsState])

  useEffect(() => {
    if (
      clubBlockPartyMeetsState.isSaved === false &&
      baseUsasClubState.objData.orgUnitId &&
      formState.reportPeriodId > 0) {
      getClubBlockPartyMeets(baseUsasClubState.objData.orgUnitId, formState.reportPeriodId);
    }
    else if (clubBlockPartyMeetsState.isSaved === true) {
      confirmSave();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clubBlockPartyMeetsState.isSaved, formState.reportPeriodId]);

  useEffect(() => {
    if (reportPeriodSeasonState.isArrayLoading === false && reportPeriodSeasonState.isArrayLoaded === true) {
      let currentDate = new Date(formatDate(new Date()));
      let currentSeason = reportPeriodSeasonState.arrayData.find
        (x => new Date(formatDate(x.startDate)) <= currentDate && new Date(formatDate(x.endDate)) >= currentDate);

      setFormState({...formState, reportPeriodId: currentSeason.reportPeriodId}) //41
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportPeriodSeasonState]);

  function getInitialFormState() {
    return {
      reportPeriodId: Constants.DEFAULT_ID,
      reportPeriodLabel: ''
    };
  };

  return {
    clubBlockPartyMeetsState,
    gridState,
    formState,
    errorState,
    viewState,
    setViewState,
    organizationId: baseUsasClubState.objData?.orgUnit?.organizationId || Constants.DEFAULT_ID,
    onDelete,
    onEdit,
    onUploadClicked,
    onReportPeriodChanged,
    onAddBlockPartyMeetClicked,
    onMeetReconClicked,
    onViewReport,
    onViewMeetAnnouncementReport,
    onGoToCart,
    INITIAL_VIEW_STATE
  }
}

export default UseClubBlockPartyMeets