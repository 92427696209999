import useBpMeetClassificationTreeView from './UseBpMeetClassificationTreeView';

import TreeView from '../TreeView';

import global from '../../GlobalStyle.module.css';

const BpMeetClassificationTreeView = ({ label, name, value, error, message, selectableLeavesOnly, singleSelect,
  onChange, orgUnitId, filterId, filterMode }) => {
  const { bpMeetClassificationState } = useBpMeetClassificationTreeView(orgUnitId, filterId, filterMode);

  return bpMeetClassificationState.message
    ? <span className={global.LoadingMsg}>{bpMeetClassificationState.message}</span>
    : <TreeView
      label={label}
      name={name}
      value={value}
      error={error}
      message={message}
      isLoading={bpMeetClassificationState.isArrayLoading}
      options={orgUnitId ? bpMeetClassificationState.treeData : []}
      selectableLeavesOnly={selectableLeavesOnly}
      singleSelect={singleSelect}
      onChange={onChange} />
};

export default BpMeetClassificationTreeView;