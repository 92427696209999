import useUsasClubFacilityLinksData from '../../../state/clubFacilityLinks/UseUsasClubFacilityLinksData';

import useBaseUsasClubData from '../../../../common/state/clubBase/UseBaseUsasClubData';
import useFacilityData from '../../../../common/state/facility/UseFacilityData';

const useClubFacilitiesAdd = () => {
  const { usasClubFacilityLinksState } = useUsasClubFacilityLinksData();
  const { baseUsasClubState } = useBaseUsasClubData();
  const { facilityState } = useFacilityData();

  return {
    usasClubFacilityLinksState,
    baseUsasClubState,
    facilityState
  };
};

export default useClubFacilitiesAdd;