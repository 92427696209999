import React, { Fragment } from 'react';

import useExpandedRow from '../../../../../common/components/grids/UseExpandedRow';

import PreCompLocationStaffGridLarge from './PreCompLocationStaffGridLarge';
import PreCompLocationStaffGridSmall from './PreCompLocationStaffGridSmall';

const PreCompLocationStaffGrid = ({ data, isLoading, onEdit, onDelete, canDeletePrimaryContact = true, PRIMARY_CONTACT_ORG_ROLE_ID }) => {
  const { expandedId, onClick } = useExpandedRow();
  return (
    <Fragment>
      <PreCompLocationStaffGridLarge data={data} isLoading={isLoading} onEdit={onEdit} onDelete={onDelete}
        canDeletePrimaryContact={canDeletePrimaryContact} expandedId={expandedId} onClick={onClick} PRIMARY_CONTACT_ORG_ROLE_ID={PRIMARY_CONTACT_ORG_ROLE_ID} />
      <PreCompLocationStaffGridSmall data={data} isLoading={isLoading} onEdit={onEdit} onDelete={onDelete}
        canDeletePrimaryContact={canDeletePrimaryContact} expandedId={expandedId} onClick={onClick} PRIMARY_CONTACT_ORG_ROLE_ID={PRIMARY_CONTACT_ORG_ROLE_ID} />
    </Fragment>
  );
};

export default PreCompLocationStaffGrid;