import React, { Fragment } from 'react';

import ProviderFacilitiesGridLarge from './ProviderFacilitiesGridLarge';
import ProviderFacilitiesGridSmall from './ProviderFacilitiesGridSmall';

import useExpandedRow from '../../../../../common/components/grids/UseExpandedRow';

const ProviderFacilitiesGrid = ({ isLoading, data, onEdit, onDelete, onAddPool, onEditPool, onDeletePool }) => {
  const { expandedId, onClick } = useExpandedRow();

  return (
    <Fragment>
      <ProviderFacilitiesGridLarge
        isLoading={isLoading}
        expandedId={expandedId}
        data={data}
        onExpand={onClick}
        onEdit={onEdit}
        onDelete={onDelete}
        onAddPool={onAddPool}
        onEditPool={onEditPool}
        onDeletePool={onDeletePool} />
      <ProviderFacilitiesGridSmall
        isLoading={isLoading}
        expandedId={expandedId}
        data={data}
        onExpand={onClick}
        onEdit={onEdit}
        onDelete={onDelete}
        onAddPool={onAddPool}
        onEditPool={onEditPool}
        onDeletePool={onDeletePool} />
    </Fragment>
  );
}

export default ProviderFacilitiesGrid;