import { useContext } from 'react';

import { PreCompLocationStaffStateContext } from './PreCompLocationStaffContextProvider';

import PreCompLocationStaffData from './PreCompLocationStaffData';

const usePreCompLocationStaffData = () => {
  const [preCompLocationStaffState, setPreCompLocationStaffState] = useContext(PreCompLocationStaffStateContext);

  const getPreCompLocationStaff = (orgUnitId, roleGroupId, isCurrentOnly) => { PreCompLocationStaffData.getPreCompLocationStaffData(orgUnitId, roleGroupId, isCurrentOnly, preCompLocationStaffState, setPreCompLocationStaffState); };
  const postPreCompLocationStaff = (orgUnitId, roleGroupId, staffObj) => { PreCompLocationStaffData.postPreCompLocationStaffData(orgUnitId, roleGroupId, staffObj, preCompLocationStaffState, setPreCompLocationStaffState); };
  const putPreCompLocationStaff = (orgUnitId, personId, roleGroupId, staffObj) => { PreCompLocationStaffData.putPreCompLocationStaffData(orgUnitId, personId, roleGroupId, staffObj, preCompLocationStaffState, setPreCompLocationStaffState); };
  const deletePreCompLocationStaff = (personOrgRoleDurationId) => { PreCompLocationStaffData.deletePreCompLocationStaffData(personOrgRoleDurationId, preCompLocationStaffState, setPreCompLocationStaffState); };

  const savePreCompLocationStaff = (orgUnitId, personId, roleGroupId, personOrgRole) => {
    if (personId && orgUnitId && roleGroupId > 0) {
      let matchFound = false;
      // Edit
      for (const staff of preCompLocationStaffState.arrayData) {
        if (staff.personId === personId) {
          putPreCompLocationStaff(orgUnitId, personId, roleGroupId, { personId: personId, personOrgRole: personOrgRole });

          matchFound = true;
          break;
        }
      }

      // Add
      if (matchFound === false) {
        postPreCompLocationStaff(orgUnitId, roleGroupId, { personId: personId, personOrgRole: personOrgRole });
      }
    }
  };

  const resetPreCompLocationStaffState = () => {
    setPreCompLocationStaffState(PreCompLocationStaffData.INITIAL_STATE);
  };

  const confirmPreCompLocationStaffStateSaved = () => {
    setPreCompLocationStaffState({
      ...preCompLocationStaffState,
      isSaved: false
    });
  };

  return {
    preCompLocationStaffState,
    getPreCompLocationStaff,
    postPreCompLocationStaff,
    putPreCompLocationStaff,
    deletePreCompLocationStaff,
    savePreCompLocationStaff,
    resetPreCompLocationStaffState,
    confirmPreCompLocationStaffStateSaved
  };
};

export default usePreCompLocationStaffData;