import { Fragment } from 'react';

import useClubAffiliationDetail from './UseClubAffiliationDetail';

import Headings from '../../../../common/components/headings/Headings';
import ClubAffiliationTypeMultiselect from '../../../../common/components/multiSelect/clubAffTypeMultiSelect/ClubAffiliationTypeMultiselect';
import PrimaryButton from '../../../../common/components/buttons/PrimaryButton';
import SecondaryButton from '../../../../common/components/buttons/SecondaryButton';
import PopUpModal from '../../../../common/components/dialogs/PopUpModal';
import Constants from '../../../../common/utils/Constants';

const ClubAffiliationDetail = () => {
  const {
    baseUsasClubState,
    formState,
    errorState,
    clubAffiliationTypeState,
    onFormValueChanged,
    handleSubmit,
    onSaveClicked,
    onCancelClicked
  } = useClubAffiliationDetail();

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>Add Club Affiliation</Headings.H3>
        </div>
      </div>
      <form onSubmit={handleSubmit} noValidate>
        <div className="row">
          <div className="col-xs-12 col-sm-6 usas-extra-bottom-margin">
            <ClubAffiliationTypeMultiselect
              label={"Club Affiliation(s)"}
              name={"clubAffiliationTypes"}
              value={formState.clubAffiliationTypes}
              error={errorState.clubAffiliationTypes}
              message={errorState.clubAffiliationTypes}
              onChange={(value) => { onFormValueChanged('clubAffiliationTypes', value); }}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12 usas-extra-top-margin usas-extra-bottom-margin">
            <PrimaryButton type="button" onClick={onSaveClicked}>Save</PrimaryButton>&nbsp;
            <SecondaryButton type="button" onClick={onCancelClicked}>Back</SecondaryButton>
          </div>
        </div>
      </form>
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={baseUsasClubState.isObjLoading || clubAffiliationTypeState.isArrayLoading} />
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.SAVING_MSG}
        displayPopUp={baseUsasClubState.isSaving} />
    </Fragment>
  );
};

export default ClubAffiliationDetail;