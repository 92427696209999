import React, { Fragment } from 'react';

import usePreCompRegistrationLocationEmergencyPlan from './UsePreCompRegistrationLocationEmergencyPlan';

import PreCompRegistrationConstants from '../utils/PreCompRegistrationConstants';

import LeftNav from '../../../../common/components/leftNav/LeftNav';
import Headings from '../../../../common/components/headings/Headings';
import PrimaryButton from '../../../../common/components/buttons/PrimaryButton';
import SecondaryButton from '../../../../common/components/buttons/SecondaryButton';
import PopUpModal from '../../../../common/components/dialogs/PopUpModal';
import Constants from '../../../../common/utils/Constants';

import global from '../../../../common/components/GlobalStyle.module.css';

const PreCompRegistrationLocationEmergencyPlan = () => {
  const {
    isSaving,
    displayModalState,
    fileState,
    locationRegistrationNavState,
    pageErrorMessageState,
    onDownloadUploadedFile,
    onRemoveFile,
    onUploadEmergencyFile,
    onContinueClicked,
    onBackClicked,
    onModalRemoveClicked,
    onModalBackClicked
  } = usePreCompRegistrationLocationEmergencyPlan();

  return (
    <LeftNav title={PreCompRegistrationConstants.LEFT_NAV_TITLE} state={locationRegistrationNavState} validateBeforeRedirect={false}>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>Emergency Plans</Headings.H3>
        </div>
      </div>
      <div className="row usas-extra-top-margin">
        <div className="col-xs-12">
          <Headings.H6>Emergency Plan</Headings.H6>
        </div>
      </div>
      <div className="row usas-extra-top-margin">
        <div className="col-xs-12">
          {fileState.fileUrl === '' ?
            <Fragment>
              <label htmlFor="emergency-plan-file-upload" className={global.FileUpload}>
                Upload File
              </label>
              <input hidden id="emergency-plan-file-upload" type="file" onChange={onUploadEmergencyFile} />
              &nbsp;(*.pdf), (*.doc), or (*.docx)
            </Fragment>
            :
            <Fragment>
              <SecondaryButton onClick={onRemoveFile}>Remove Uploaded File</SecondaryButton>&nbsp;
              <PrimaryButton type="button" onClick={onDownloadUploadedFile}>Download Uploaded File</PrimaryButton>
            </Fragment>
          }
        </div>
      </div>
      {fileState.fileName !== '' &&
        <Fragment>
          <div className="row usas-extra-bottom-margin">
            <div className="col-xs-12">
              <p><b>Uploaded File Name:</b> {fileState.fileName}</p>
            </div>
          </div>
        </Fragment>}
      {(pageErrorMessageState || fileState.fileError === true) &&
        <div className="row">
          <div className="col-xs-12">
            <p className={global.ErrorMessage}>{fileState.errorMessage}</p>
            <p className={global.ErrorMessage}>{pageErrorMessageState}</p>
          </div>
        </div>}
      <div className="row usas-extra-top-margin usas-extra-bottom-margin">
        <div className="col-xs-12 usas-extra-top-margin">
          <PrimaryButton type="button" onClick={onContinueClicked}>Continue</PrimaryButton>&nbsp;
          <SecondaryButton type="button" onClick={onBackClicked}>Back</SecondaryButton>
        </div>
      </div>
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.SAVING_MSG}
        displayPopUp={isSaving} />
      <PopUpModal
        widthPct={90}
        maxWidth={720}
        title="Remove Uploaded File?"
        displayPopUp={displayModalState}
        onModalCanceled={onModalBackClicked}>
        <div className="row">
          <div className="col-xs-12">
            <p><b>File Type:</b> Emergency Plan</p>
            <p><b>Uploaded File Name:</b> {fileState.fileName}</p>
          </div>
        </div>
        <div className="row usas-extra-top-margin">
          <div className="col-xs-12">
            <PrimaryButton type="button" onClick={onModalRemoveClicked}>Yes, Remove</PrimaryButton>&nbsp;
            <SecondaryButton type="button" onClick={onModalBackClicked}>Cancel</SecondaryButton>
          </div>
        </div>
      </PopUpModal>
    </LeftNav >
  );
};

export default PreCompRegistrationLocationEmergencyPlan;