import { useState, useEffect } from 'react';
import { useNavigate } from '../../../../common/wrappers/ReactRouterDom';
import NavLinks from '../NavLinks';

import useClubVendorData from '../../../state/clubVendor/UseClubVendorData';

import useBaseUsasClubData from '../../../../common/state/clubBase/UseBaseUsasClubData';
import useVendorData from '../../../../common/state/vendor/UseVendorData';
import Constants from '../../../../common/utils/Constants';

const INITIAL_STATE = {
  modalState: {
    modalTitle: 'Delete Club Vendor?',
    displayPopUp: false,
    orgUnitVendorId: Constants.DEFAULT_ID,
    vendorName: '',
    effectiveDate: Constants.BLANK_DATE_STRING,
    expirationDate: Constants.BLANK_DATE_STRING
  },
  tryGet: false
};

const useClubActivateVendor = () => {
  const navigate = useNavigate();
  const { baseUsasClubState } = useBaseUsasClubData();
  const { getClubVendorsByOrgUnit, deleteClubVendorFromOrgUnit, clubVendorState } = useClubVendorData();
  const { getVendors, vendorState } = useVendorData(true);
  const [state, setState] = useState(INITIAL_STATE);

  const onModalCanceled = (e) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }

    setState({
      ...state,
      modalState: {
        ...state.modalState,
        displayPopUp: false,
      }
    });
  };

  const onOpenDeleteVendorModalClicked = (e, vendor) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }

    setState({
      ...state,
      modalState: {
        ...state.modalState,
        displayPopUp: true,
        orgUnitVendorId: vendor.orgUnitVendorId,
        vendorName: vendor.vendor.vendorName,
        effectiveDate: vendor.effectiveDate,
        expirationDate: vendor.expirationDate
      }
    });

  };

  const onDeleteClubVendor = (e) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }

    const orgUnitVendorId = state?.modalState?.orgUnitVendorId;

    deleteClubVendorFromOrgUnit(orgUnitVendorId);
  }

  const onAddVendor = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    navigate(NavLinks.CLUB_ACTIVATE_VENDOR_ADD_VENDOR, { state: { clubVendorState } });
  };

  useEffect(() => {
    if (clubVendorState.isSaved === true) {

      setState({
        ...state,
        tryGet: true,
        modalState: {
          ...state.modalState,
          displayPopUp: false,
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clubVendorState.isSaved]);

  useEffect(() => {
    if ((Array.isArray(clubVendorState?.arrayData) === true && clubVendorState?.arrayData?.length > 0) &&
      (Array.isArray(vendorState?.arrayData) === true && vendorState?.arrayData?.length > 0)) {

      const nonActiveVendorsArray = clubVendorState.arrayData.filter(x => x.expirationDate === null);
      nonActiveVendorsArray.forEach(vendor => {
        const nonActiveIndex = vendorState.dataAsOptionsWithNames.findIndex(x => x.id === vendor.vendorId);

        if (nonActiveIndex > Constants.DEFAULT_ID) {
          vendorState.dataAsOptionsWithNames.splice(nonActiveIndex, 1);
        }
      });
    }
  }, [clubVendorState, vendorState]);

  useEffect(() => {
    if (baseUsasClubState?.objData?.orgUnitId !== undefined || state.tryGet === true) {
      const orgUnitId = baseUsasClubState.objData.orgUnitId;

      getClubVendorsByOrgUnit(orgUnitId);
      getVendors();
      setState({ ...state, tryGet: false });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [baseUsasClubState, state.tryGet]);

  return {
    modalState: state.modalState,
    onModalCanceled,
    onDeleteClubVendor,
    onOpenDeleteVendorModalClicked,
    clubVendorState,
    onAddVendor,
    clubThirdPartyActivateVendorId: baseUsasClubState?.objData?.orgUnit?.ouid
  };
};

export default useClubActivateVendor;