import { useEffect, useState } from 'react';
import { useNavigate } from '../../../../common/wrappers/ReactRouterDom';

import validate from './ClubRegistrationInfoValidation';

import NavLinks from '../NavLinks';

import ClubRegistrationConstants from '../utils/ClubRegistrationConstants';

import useClubRegistrationNavData from '../../../state/clubRegistration/leftNavigation/UseClubRegistrationNavData';
import useClubRegistrationNewData from '../../../state/clubRegistrationNew/UseClubRegistrationNewData';
import useUsasClubStaffData from '../../../state/clubStaff/UseUsasClubStaffData';
import useUsasClubFacilityLinksData from '../../../state/clubFacilityLinks/UseUsasClubFacilityLinksData';
import useRequiredRolesData from '../../../state/clubRegistration/requiredRoles/UseRequiredRolesData';

import useEnvironmentVariableData from '../../../../common/state/environmentVariable/UseEnvironmentVariableData';
import useSecurityData from '../../../../common/state/security/UseSecurityData';
import useLscData from '../../../../common/state/lsc/UseLscData';
import useOfferingTypeData from '../../../../common/state/offeringTypes/UseOfferingTypeData';

import useStatesCombobox from '../../../../common/components/comboboxes/statesCombobox/UseStatesCombobox';

import useForm from '../../../../common/utils/UseForm';
import Constants from '../../../../common/utils/Constants';
import { formatDate } from '../../../../common/utils/DateFunctions';

const TAXONOMIES = ['ClubRegistration'];
const SCOPE = 'ClubRegistration';

const settingOptions = [
  { id: 'Rural', name: 'Rural', value: 'Rural' },
  { id: 'Urban', name: 'Urban', value: 'Urban' },
  { id: 'Suburban', name: 'Suburban', value: 'Suburban' }
];

const INITIAL_VALIDATE_STATE = {
  isValidating: false,
  validatedAddress: null
};

const useClubRegistrationInfo = () => {
  const navigate = useNavigate();
  const { environmentVariableState, CLUB_STAFF_ROLE_GROUP_ID } = useEnvironmentVariableData();
  const { lscState, getLscs } = useLscData();
  const [state, setState] = useState({ submitButton: '', tryRedirect: false, validStaff: false, showPage: false });
  const [modalState, setModalState] = useState(getInitialModalState());
  const [validateState, setValidateState] = useState(INITIAL_VALIDATE_STATE);
  const { clubRegistrationNavState, setClubRegistrationNavState } = useClubRegistrationNavData();
  const { clubRegistrationState, postUsasClub, putUsasClub } = useClubRegistrationNewData();
  const { usasClubFacilityLinksState, getUsasClubFacilityLinks } = useUsasClubFacilityLinksData();
  const { usasClubStaffState, getUsasClubStaff } = useUsasClubStaffData();
  const { requiredRolesState, getRequiredRolesByOrgLevelTypeId } = useRequiredRolesData();

  const { resetOfferingTypeState } = useOfferingTypeData();
  const { getContextSecurity } = useSecurityData();
  const { getStateNameById } = useStatesCombobox(false, true, false, false);
  const { handleSubmit, updateFormState, formState, errorState, isDirty, setIsDirty, setFormData, resetForm, isSubmitting, onValueTextPairChanged
  } = useForm(getFormInitialState(), submitFormCallback, validate);

  function submitFormCallback() {
    let clubCopy = JSON.parse(JSON.stringify(clubRegistrationState.objData));
    //Add (on initial visit to page)
    if (Object.keys(clubCopy).length === 2) {
      postUsasClub(createClubObj())
      if (state.submitButton === ClubRegistrationConstants.EXIT) {
        setState({ ...state, tryRedirect: false });
      }
      else {
        setState({ ...state, tryRedirect: true });
      }
    }
    //Edit (on returning to page)
    else if (Object.keys(clubCopy).length > 2) {
      putUsasClub(clubCopy.usasClubId, editClubObj(clubCopy));
      if (state.submitButton === ClubRegistrationConstants.EXIT) {
        setState({ ...state, tryRedirect: false });
      }
      else {
        setState({ ...state, tryRedirect: true });
      }
    }
  };

  const handleSubmitProxy = async (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    setValidateState({
      isValidating: true
    });
    await handleSubmit();
    setValidateState({
      isValidating: false,
      validatedAddress: formState.validatedAddress
    });
    updateFormState('showedAddressSuggestion', true);
  };

  const onContinueClicked = async (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    if (isDirty === false && clubRegistrationState.objData.usasClubId) {
      setState({ ...state, tryRedirect: true, submitButton: ClubRegistrationConstants.CONTINUE });
    }
    else {
      setState({ ...state, submitButton: ClubRegistrationConstants.CONTINUE });
      await handleSubmitProxy();
    }
  }

  const onSaveAndExitClicked = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    setState({ ...state, tryRedirect: false, submitButton: ClubRegistrationConstants.EXIT });
    handleSubmitProxy();
  }

  const onBackClicked = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    if (isDirty === false && clubRegistrationState.objData.usasClubId) {
      setState({ ...state, tryRedirect: true, submitButton: ClubRegistrationConstants.BACK });
    }
    else {
      setState({ ...state, submitButton: ClubRegistrationConstants.BACK });
      handleSubmitProxy();
    }
  }

  const onOpenModal = () => {
    if (Object.keys(errorState).length > 0) {
      setModalState({ ...modalState, displayPopUp: true });
    }
    else {
      setModalState({ ...modalState, modalTitle: ClubRegistrationConstants.EXIT_CONFIRMATION, displayPopUp: true });
    }
  }

  const onModalContinue = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    setState({ ...state, tryRedirect: true });
    setModalState({ ...getInitialModalState() });
  }

  const onModalCanceled = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    setState({ ...state, submitButton: '' });
    setModalState({ ...getInitialModalState() });
  }

  const createClubObj = () => {
    const clubObj = {
      clubName: formState.clubName.trim(),
      clubCode: formState.clubCode.trim(),
      yearFounded: formState.yearFounded.length > 0 ? parseInt(formState.yearFounded) : null,
      lscOrgUnitId: formState.lscId,
      clubOwnerTypeId: formState.clubOwnerTypeId,
      businessEntityTypeId: formState.businessEntityTypeId,
      address1: formState.address1.trim(),
      address2: formState.address2.trim(),
      city: formState.city.trim(),
      stateCode: formState.stateCode,
      postalCode: formState.postalCode.trim(),
      clubSetting: formState.clubSetting !== '' ? formState.clubSetting : null,
      websiteAddress: formState.websiteAddress.trim().length > 0 ? formState.websiteAddress.trim() : null,
      includeOnFindAteam: formState.includeOnFindAteam
    }
    return clubObj;
  }

  const editClubObj = (clubCopy) => {
    //reset club type if the lsc changes (club type is dependent on lsc)
    let resetClubType = false;

    let currentOrgUnitParentChildIndex = clubRegistrationState.objData.orgUnit?.orgUnitParentChild.findIndex(x => formatDate(x.expirationDate) === Constants.DEFAULT_MAX_DATE);
    if (currentOrgUnitParentChildIndex > -1) {
      if (clubRegistrationState.objData.orgUnit.orgUnitParentChild[currentOrgUnitParentChildIndex].parentOrgUnitId !== formState.lscId) {
        resetClubType = true;
        updateClubTypeNavState();
        resetOfferingTypeState();

        const today = new Date();
        const todayFormatted = formatDate(today);
        const yesterday = new Date();
        yesterday.setDate(yesterday.getDate() - 1);
        const yesterdayFormatted = formatDate(yesterday);

        //if the current orgUnitParentChild object has an effective date of today, just update the object
        if (clubRegistrationState.objData.orgUnit.orgUnitParentChild[currentOrgUnitParentChildIndex].effectiveDate &&
          formatDate(clubRegistrationState.objData.orgUnit.orgUnitParentChild[currentOrgUnitParentChildIndex].effectiveDate) === todayFormatted) {
          clubRegistrationState.objData.orgUnit.orgUnitParentChild[currentOrgUnitParentChildIndex].parentOrgUnitId = formState.lscId;
        }
        else {
          //Otherwise, set the expiration date to yesterday and add an new orgUnitParentChild object
          clubRegistrationState.objData.orgUnit.orgUnitParentChild[currentOrgUnitParentChildIndex].expirationDate = yesterdayFormatted;
          clubRegistrationState.objData.orgUnit.orgUnitParentChild.push({
            childOrgUnitId: clubRegistrationState.objData.orgUnit.orgUnitId,
            parentOrgUnitId: formState.lscId,
            effectiveDate: todayFormatted,
            expirationDate: Constants.DEFAULT_MAX_DATE
          })
        }
      }
    }

    clubCopy = {
      ...clubRegistrationState.objData,
      clubSetting: formState.clubSetting !== '' ? formState.clubSetting : null,
      yearFounded: formState.yearFounded.length > 0 ? parseInt(formState.yearFounded) : null,
      websiteAddress: formState.websiteAddress.trim().length > 0 ? formState.websiteAddress.trim() : null,
      includeOnFindAteam: formState.includeOnFindAteam,
      orgUnit: {
        ...clubRegistrationState.objData.orgUnit,
        orgUnitName: formState.clubName.trim(),
        orgUnitCode: formState.clubCode.trim()
      },
      usasClubAddress:
        clubRegistrationState.objData.usasClubAddress.length > 0 ?
          [{
            ...clubRegistrationState.objData.usasClubAddress[0],
            address: {
              ...clubRegistrationState.objData.usasClubAddress[0]?.address,
              address1: formState.address1.trim(),
              address2: formState.address2.trim(),
              city: formState.city.trim(),
              stateCode: formState.stateCode,
              postalCode: formState.postalCode.trim()
            }
          }]
          :
          [{
            address: {
              address1: formState.address1.trim(),
              address2: formState.address2.trim(),
              city: formState.city.trim(),
              stateCode: formState.stateCode,
              postalCode: formState.postalCode.trim(),
              countryCode: 'USA'
            },
            addressName: "Primary",
            isPrimary: true,
            usasClubId: clubRegistrationState.objData.usasClubId,
          }]
    }

    if (clubCopy.clubRegistrations.length === 1) {
      clubCopy.clubRegistrations = [{
        ...clubCopy.clubRegistrations[0],
        lscname: null,
        clubName: formState.clubName.trim(),
        lsccode: null,
        clubCode: formState.clubCode.trim()
      }]
      if (resetClubType === true) {
        clubCopy.clubRegistrations[0].offeringInstanceId = null;
        clubCopy.clubRegistrations[0].offeringInstance = undefined;
      }
    }


    return clubCopy;
  }

  const validateStaff = () => {
    const staff = usasClubStaffState.arrayData;
    let staffRolesSimple = [];
    let requiredRolesArray = [];

    for (const staffIdx in staff) {
      const staffRole = staff[staffIdx].personOrgRole;
      for (const roleIdx in staffRole) {
        staffRolesSimple.push(staffRole[roleIdx].orgRoleId);
      }
    }

    for (const roleIdx in requiredRolesState.arrayData) {
      //is a staff member assigned with a role in the required list, if so, set met to true
      let found = staffRolesSimple.some(roleId => roleId === requiredRolesState.arrayData[roleIdx].requiredRoleId);
      let foundChildrenRole = false;
      if (found === false && requiredRolesState.arrayData[roleIdx].childrenRole) {
        for (const childRoleIdx in requiredRolesState.arrayData[roleIdx].childrenRole) {
          foundChildrenRole = staffRolesSimple.some(roleId => roleId === requiredRolesState.arrayData[roleIdx].childrenRole[childRoleIdx].orgRoleId);

          if (foundChildrenRole) { break; }
        }
      }

      requiredRolesArray.push({
        orgRoleId: requiredRolesState.arrayData[roleIdx].requiredRoleId,
        orgRoleName: requiredRolesState.arrayData[roleIdx].requiredRoleName,
        met: found || foundChildrenRole
      });
    }

    let roleMet = false;

    for (const roleIdx in requiredRolesArray) {
      roleMet = requiredRolesArray[roleIdx].met;
      if (roleMet === false) { break; }
    }

    return roleMet;
  }

  const updateNavState = (validStaff) => {
    for (let i = 0; i < clubRegistrationNavState.length; i++) {
      clubRegistrationNavState[i].isCurrent = false
    }
    const clubInfoNavIndex = clubRegistrationNavState.findIndex(x =>
      x.name === ClubRegistrationConstants.CLUB_INFO)
    if (clubRegistrationState.objData.usasClubId) {
      for (let i = 0; i < clubRegistrationNavState.length; i++) {
        if (clubRegistrationNavState[i].name !== ClubRegistrationConstants.SUBMIT) {
          clubRegistrationNavState[i].arePrerequisitesDone = true
        }
        if (clubRegistrationNavState[i].name === ClubRegistrationConstants.SUBMIT) {
          clubRegistrationNavState[i].arePrerequisitesDone = false
        }
      }
      clubRegistrationNavState[clubInfoNavIndex] =
      {
        ...clubRegistrationNavState[clubInfoNavIndex],
        isCurrent: true,
        isComplete: true,
        isVisited: true,
        arePrerequisitesDone: true
      }
      const clubStaffNavIndex = clubRegistrationNavState.findIndex(x =>
        x.name === ClubRegistrationConstants.CLUB_STAFF)
      if (!validStaff) {
        clubRegistrationNavState[clubStaffNavIndex] =
        {
          ...clubRegistrationNavState[clubStaffNavIndex],
          isCurrent: false,
          arePrerequisitesDone: true
        }
      }
      else if (validStaff === true) {
        clubRegistrationNavState[clubStaffNavIndex] =
        {
          ...clubRegistrationNavState[clubStaffNavIndex],
          isCurrent: false,
          isComplete: true,
          arePrerequisitesDone: true
        }
      }
      else if (validStaff === false) {
        clubRegistrationNavState[clubStaffNavIndex] =
        {
          ...clubRegistrationNavState[clubStaffNavIndex],
          isCurrent: false,
          isComplete: false,
          arePrerequisitesDone: true
        }
      }

      const clubTypeNavIndex = clubRegistrationNavState.findIndex(x =>
        x.name === ClubRegistrationConstants.CLUB_TYPE)
      //New club
      if (clubRegistrationState.objData.clubRegistrations?.length === 1 &&
        clubRegistrationState.objData.clubRegistrations[0].offeringInstanceId !== null) {
        clubRegistrationNavState[clubTypeNavIndex] =
        {
          ...clubRegistrationNavState[clubTypeNavIndex],
          isCurrent: false,
          isComplete: true,
          arePrerequisitesDone: true
        }
      }

      const legalEntityIndex = clubRegistrationNavState.findIndex(x => x.name ===
        ClubRegistrationConstants.LEGAL_ENTITY)
      if (clubRegistrationState.objData.usasClubLegalEntities?.length > 0) {
        clubRegistrationNavState[legalEntityIndex] =
        {
          ...clubRegistrationNavState[legalEntityIndex],
          isCurrent: false,
          isComplete: true,
          arePrerequisitesDone: true
        }
      }

      const affiliationIndex = clubRegistrationNavState.findIndex(x =>
        x.name === ClubRegistrationConstants.AFFILIATION)
      if (clubRegistrationState.objData.usasClubAffiliations?.length > 0) {
        clubRegistrationNavState[affiliationIndex] =
        {
          ...clubRegistrationNavState[affiliationIndex],
          isCurrent: false,
          isComplete: true,
          arePrerequisitesDone: true
        }
      }

      const screenPoliciesIndex = clubRegistrationNavState.findIndex(x =>
        x.name === ClubRegistrationConstants.SCREENING_POLICIES)
      //New club  
      if (clubRegistrationState.objData.usasClubAcknowledgement?.length === 1) {  
        if (clubRegistrationState.objData.usasClubAcknowledgement[0].preEmploymentScreeningRequired === true &&
          clubRegistrationState.objData.usasClubAcknowledgement[0].racingStartCertified === true &&
          clubRegistrationState.objData.usasClubAcknowledgement[0].stateConcussionsLaws === true &&
          clubRegistrationState.objData.usasClubAcknowledgement[0].minorAthleteAbusePreventionPolicy === true) {
          clubRegistrationNavState[screenPoliciesIndex] =
          {
            ...clubRegistrationNavState[screenPoliciesIndex],
            isCurrent: false,
            isComplete: true,
            arePrerequisitesDone: true
          }
        }
      }

      const facilityIndex = clubRegistrationNavState.findIndex(x =>
        x.name === ClubRegistrationConstants.FACILITY)
      if (usasClubFacilityLinksState.arrayData.length > 0) {
        clubRegistrationNavState[facilityIndex] =
        {
          ...clubRegistrationNavState[facilityIndex],
          isCurrent: false,
          isComplete: true,
          arePrerequisitesDone: true
        }
      }

      const missionStatementBudgetIndex = clubRegistrationNavState.findIndex(x =>
        x.name === ClubRegistrationConstants.MISSION_STATEMENT_BUDGET)

      //New club
      if (clubRegistrationState.objData.usasClubAcknowledgement?.length === 1 &&
        clubRegistrationState.objData.usasClubAcknowledgement[0].missionStatementUrl !== null &&
        clubRegistrationState.objData.usasClubAcknowledgement[0].budgetUrl !== null &&
        clubRegistrationState.objData?.usasClubAcknowledgement[0].missionStatementUrl.includes("Bad Request") === false &&
        clubRegistrationState.objData?.usasClubAcknowledgement[0].budgetUrl.includes("Bad Request") === false
      ) {
        clubRegistrationNavState[missionStatementBudgetIndex] =
        {
          ...clubRegistrationNavState[missionStatementBudgetIndex],
          isCurrent: false,
          isComplete: true,
          arePrerequisitesDone: true
        }
      }

      const emergencyPlanIndex = clubRegistrationNavState.findIndex(x =>
        x.name === ClubRegistrationConstants.EMERGENCY_PLAN)
      //New club
      if (clubRegistrationState.objData.usasClubAcknowledgement?.length === 1 &&
        clubRegistrationState.objData.usasClubAcknowledgement[0].emergencyPlanUrl !== null &&
        clubRegistrationState.objData?.usasClubAcknowledgement[0].emergencyPlanUrl.includes("Bad Request") === false
      ) {
        clubRegistrationNavState[emergencyPlanIndex] =
        {
          ...clubRegistrationNavState[emergencyPlanIndex],
          isCurrent: false,
          isComplete: true,
          arePrerequisitesDone: true
        }
      }
      //Submit
      let areSubmitPrerequisitesDone = true;
      let isSubmitComplete = false;
      for (let i = 0; i < clubRegistrationNavState.length; i++) {
        if (clubRegistrationNavState[i].name !== ClubRegistrationConstants.SUBMIT
          && clubRegistrationNavState[i].isComplete === false) {
          areSubmitPrerequisitesDone = false;
        }
        if (clubRegistrationNavState[i].name === ClubRegistrationConstants.SUBMIT) {
          if (clubRegistrationState.objData.status === ClubRegistrationConstants.PENDING_STATUS ||
            clubRegistrationState.objData.status === ClubRegistrationConstants.APPROVED_STATUS) {
            isSubmitComplete = true
          }
        }
      }
      const submitIndex = clubRegistrationNavState.findIndex(x =>
        x.name === ClubRegistrationConstants.SUBMIT)
      clubRegistrationNavState[submitIndex] =
      {
        ...clubRegistrationNavState[submitIndex],
        isCurrent: false,
        isComplete: isSubmitComplete,
        arePrerequisitesDone: areSubmitPrerequisitesDone
      }

      setClubRegistrationNavState(clubRegistrationNavState);
    }
    else {
      for (let i = 0; i < clubRegistrationNavState.length; i++) {
        clubRegistrationNavState[i].arePrerequisitesDone = false
      }
      clubRegistrationNavState[clubInfoNavIndex] =
      {
        ...clubRegistrationNavState[clubInfoNavIndex],
        isCurrent: true,
        isComplete: false,
        isVisited: true,
        arePrerequisitesDone: true
      }
      setClubRegistrationNavState(clubRegistrationNavState);
    }
  }

  const updateClubTypeNavState = () => {
    const clubTypeNavIndex = clubRegistrationNavState.findIndex(x =>
      x.name === ClubRegistrationConstants.CLUB_TYPE)
    clubRegistrationNavState[clubTypeNavIndex] =
    {
      ...clubRegistrationNavState[clubTypeNavIndex],
      isCurrent: false,
      isComplete: false,
      arePrerequisitesDone: true
    }
    //Submit
    let areSubmitPrerequisitesDone = true;
    let isSubmitComplete = false;
    for (let i = 0; i < clubRegistrationNavState.length; i++) {
      if (clubRegistrationNavState[i].name !== ClubRegistrationConstants.SUBMIT
        && clubRegistrationNavState[i].isComplete === false) {
        areSubmitPrerequisitesDone = false;
      }
      if (clubRegistrationNavState[i].name === ClubRegistrationConstants.SUBMIT) {
        if (clubRegistrationState.objData.status === ClubRegistrationConstants.PENDING_STATUS ||
          clubRegistrationState.objData.status === ClubRegistrationConstants.APPROVED_STATUS) {
          isSubmitComplete = true
        }
      }
    }
    const submitIndex = clubRegistrationNavState.findIndex(x =>
      x.name === ClubRegistrationConstants.SUBMIT)
    clubRegistrationNavState[submitIndex] =
    {
      ...clubRegistrationNavState[submitIndex],
      isCurrent: false,
      isComplete: isSubmitComplete,
      arePrerequisitesDone: areSubmitPrerequisitesDone
    }
    setClubRegistrationNavState(clubRegistrationNavState);
  }

  useEffect(() => {
    if (Object.keys(clubRegistrationState.objData).length === 0) {
      navigate(NavLinks.CLUB_REGISTRATION_ENTRY_NEW);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (lscState.isLoading === false && lscState.isLoaded === false) {
      getLscs();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lscState]);

  useEffect(() => {
    const navIndex = clubRegistrationNavState.findIndex(x => x.name === ClubRegistrationConstants.CLUB_STAFF)
    if (clubRegistrationNavState[navIndex].isComplete === false && state.validStaff === true) {
      updateNavState(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clubRegistrationNavState, state.validStaff])

  useEffect(() => {
    if (Object.keys(clubRegistrationState.objData).length > 0 && environmentVariableState.isLoaded === true) {
      if (clubRegistrationState.objData?.orgUnitId) {
        getContextSecurity(clubRegistrationState.objData.orgUnitId, TAXONOMIES, SCOPE);
      }

      if (clubRegistrationState.objData.usasClubId && usasClubStaffState.isArrayLoading === false && usasClubStaffState.isArrayLoaded === false) {
        getUsasClubStaff(clubRegistrationState.objData.orgUnitId, CLUB_STAFF_ROLE_GROUP_ID);
      }

      if (clubRegistrationState.objData.usasClubId && usasClubFacilityLinksState.isArrayLoading === false && usasClubFacilityLinksState.isArrayLoaded === false) {
        getUsasClubFacilityLinks(clubRegistrationState.objData.usasClubId);
      }

      if (
        (
          (clubRegistrationState.objData.usasClubId && usasClubStaffState.isArrayLoaded === true && usasClubFacilityLinksState.isArrayLoaded === true)
          || Object.keys(clubRegistrationState.objData).length === 2
        ) && lscState.isLoading === false && lscState.isLoaded === true
      ) {
        //Check staff validation requirements & set nav state
        const staffIsValid = validateStaff();
        if (staffIsValid === true) {
          updateNavState(true);
        }
        else if (staffIsValid === false) {
          updateNavState(false);
        }

        let currentOrgUnitParentChildIndex = clubRegistrationState.objData.orgUnit?.orgUnitParentChild.findIndex(x => formatDate(x.expirationDate) === Constants.DEFAULT_MAX_DATE);
        let selectedRegistration = {};

        setFormData({
          ...formState,
          selectedRegistration: selectedRegistration
        });

        if (Object.keys(selectedRegistration).length === 0) {
          setFormData({
            ...formState,
            usasClubId: clubRegistrationState.objData.usasClubId || Constants.DEFAULT_ID,
            clubName: clubRegistrationState.objData.orgUnit?.orgUnitName || '',
            clubCode: clubRegistrationState.objData.orgUnit?.orgUnitCode || '',
            lscId: currentOrgUnitParentChildIndex > -1 ? clubRegistrationState.objData.orgUnit.orgUnitParentChild[currentOrgUnitParentChildIndex].parentOrgUnitId : Constants.DEFAULT_ID,
            lscname: currentOrgUnitParentChildIndex > -1 ? lscState.dataAsOptionsWithNames?.find(x => x.id === clubRegistrationState.objData.orgUnit.orgUnitParentChild[currentOrgUnitParentChildIndex].parentOrgUnitId)?.name || '' : '',
            yearFounded: clubRegistrationState.objData.yearFounded ? clubRegistrationState.objData.yearFounded.toString() : '',
            clubSetting: clubRegistrationState.objData.clubSetting || '',
            clubOwnerTypeId: clubRegistrationState.objData.clubOwnerTypeId,
            ownerTypeName: Object.keys(clubRegistrationState.objData).length === 2 ? clubRegistrationState.helperObj?.ownerTypeName : clubRegistrationState.objData.clubOwnerType.typeName,
            businessEntityTypeId: clubRegistrationState.objData.businessEntityTypeId,
            businessEntityTypeName: Object.keys(clubRegistrationState.objData).length === 2 ? clubRegistrationState.helperObj?.businessEntityTypeName : clubRegistrationState.objData.businessEntityType.typeName,
            address1: clubRegistrationState.objData.usasClubAddress && clubRegistrationState.objData.usasClubAddress[0] ? clubRegistrationState.objData.usasClubAddress[0].address.address1 : '',
            address2: clubRegistrationState.objData.usasClubAddress && clubRegistrationState.objData.usasClubAddress[0] ? clubRegistrationState.objData.usasClubAddress[0].address.address2 : '',
            city: clubRegistrationState.objData.usasClubAddress && clubRegistrationState.objData.usasClubAddress[0] ? clubRegistrationState.objData.usasClubAddress[0].address.city : '',
            stateCode: clubRegistrationState.objData.usasClubAddress && clubRegistrationState.objData.usasClubAddress[0] ? clubRegistrationState.objData.usasClubAddress[0].address.stateCode : '',
            stateName: clubRegistrationState.objData.usasClubAddress && clubRegistrationState.objData.usasClubAddress[0] ? getStateNameById(clubRegistrationState.objData.usasClubAddress[0].address.stateCode) : '',
            postalCode: clubRegistrationState.objData.usasClubAddress && clubRegistrationState.objData.usasClubAddress[0] ? clubRegistrationState.objData.usasClubAddress[0].address.postalCode : '',
            websiteAddress: clubRegistrationState.objData.websiteAddress || '',
            includeOnFindAteam: clubRegistrationState.objData.includeOnFindAteam
          });
          setState({ ...state, showPage: true });
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [environmentVariableState, clubRegistrationState.objData, lscState, usasClubStaffState, usasClubFacilityLinksState, requiredRolesState])

  useEffect(() => {
    if (Object.keys(formState.selectedRegistration).length > 0) {
      let currentOrgUnitParentChildIndex = clubRegistrationState.objData.orgUnit?.orgUnitParentChild.findIndex(x => formatDate(x.expirationDate) === Constants.DEFAULT_MAX_DATE);
      setFormData({
        ...formState,
        usasClubId: clubRegistrationState.objData.usasClubId || Constants.DEFAULT_ID,
        clubName: clubRegistrationState.objData.orgUnit?.orgUnitName || '',
        clubCode: clubRegistrationState.objData.orgUnit?.orgUnitCode || '',
        lscId: currentOrgUnitParentChildIndex > -1 ? clubRegistrationState.objData.orgUnit.orgUnitParentChild[currentOrgUnitParentChildIndex].parentOrgUnitId : Constants.DEFAULT_ID,
        lscname: currentOrgUnitParentChildIndex > -1 ? lscState.dataAsOptionsWithNames?.find(x => x.id === clubRegistrationState.objData.orgUnit.orgUnitParentChild[currentOrgUnitParentChildIndex].parentOrgUnitId)?.name || '' : '',
        yearFounded: clubRegistrationState.objData.yearFounded ? clubRegistrationState.objData.yearFounded.toString() : '',
        clubSetting: clubRegistrationState.objData.clubSetting || '',
        clubOwnerTypeId: clubRegistrationState.objData.clubOwnerTypeId,
        ownerTypeName: Object.keys(clubRegistrationState.objData).length === 2 ? clubRegistrationState.helperObj?.ownerTypeName : clubRegistrationState.objData.ownerType.typeName,
        businessEntityTypeId: clubRegistrationState.objData.businessEntityTypeId,
        businessEntityTypeName: Object.keys(clubRegistrationState.objData).length === 2 ? clubRegistrationState.helperObj?.businessEntityTypeName : clubRegistrationState.objData.businessEntityType.typeName,
        address1: clubRegistrationState.objData.usasClubAddress.length > 0 ? clubRegistrationState.objData.usasClubAddress[0]?.address.address1 : '',
        address2: clubRegistrationState.objData.usasClubAddress.length > 0 ? clubRegistrationState.objData.usasClubAddress[0]?.address.address2 : '',
        city: clubRegistrationState.objData.usasClubAddress.length > 0 ? clubRegistrationState.objData.usasClubAddress[0]?.address.city : '',
        stateCode: clubRegistrationState.objData.usasClubAddress.length > 0 ? clubRegistrationState.objData.usasClubAddress[0]?.address.stateCode : '',
        stateName: clubRegistrationState.objData.usasClubAddress.length > 0 ? getStateNameById(clubRegistrationState.objData.usasClubAddress[0]?.address.stateCode) : '',
        postalCode: clubRegistrationState.objData.usasClubAddress.length > 0 ? clubRegistrationState.objData.usasClubAddress[0]?.address.postalCode : '',
        websiteAddress: clubRegistrationState.objData.websiteAddress || '',
        includeOnFindAteam: clubRegistrationState.objData.includeOnFindAteam
      });
      setIsDirty(true);
      setState({ ...state, showPage: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formState.selectedRegistration])

  useEffect(() => {
    //Handle validation/modals and navigate to the correct page
    if (state.submitButton !== '' && isSubmitting === false) {
      //Exit before saving club
      if (clubRegistrationState.isObjLoaded === false && clubRegistrationState.isObjLoading === false
        && state.submitButton === ClubRegistrationConstants.EXIT) {
        if (state.tryRedirect === false) {
          onOpenModal();
        }
        if (state.tryRedirect === true) {
          navigate(NavLinks.CLUB_REGISTRATION_ENTRY_ENTRY);
        }
      }
      //Exit after saving club
      else if (clubRegistrationState.isObjLoaded === true && clubRegistrationState.isSaving === false) {
        if (state.tryRedirect === false && state.submitButton === ClubRegistrationConstants.EXIT) {
          onOpenModal();
        }
        else if (state.tryRedirect === true) {
          if (Object.keys(errorState).length === 0) {
            for (let i = 0; i < clubRegistrationNavState.length; i++) {
              if (clubRegistrationNavState[i].name !== ClubRegistrationConstants.SUBMIT) {
                clubRegistrationNavState[i].arePrerequisitesDone = true
              }
            }
            const navIndex = clubRegistrationNavState.findIndex(x => x.name === ClubRegistrationConstants.CLUB_INFO)
            clubRegistrationNavState[navIndex] =
            {
              ...clubRegistrationNavState[navIndex],
              isCurrent: false,
              isComplete: true,
              isVisited: true,
              arePrerequisitesDone: true
            }
            setClubRegistrationNavState(clubRegistrationNavState);
          }
          
          if (state.submitButton === ClubRegistrationConstants.CONTINUE) {
            navigate(NavLinks.CLUB_REGISTRATION_STAFF);
          }
          else if (state.submitButton === ClubRegistrationConstants.EXIT) {
            navigate(NavLinks.CLUB_REGISTRATION_ENTRY_NEW);
          }
          else if (state.submitButton === ClubRegistrationConstants.BACK) {
            navigate(NavLinks.CLUB_REGISTRATION_DISCLAIMER);
          }
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state, errorState, clubRegistrationState, isSubmitting, validateState])

  useEffect(() => {
    if (
      clubRegistrationState.isObjLoaded === true &&
      requiredRolesState.isArrayLoaded === false &&
      requiredRolesState.isArrayLoading === false) {
      getRequiredRolesByOrgLevelTypeId(clubRegistrationState.objData.clubOwnerType.orgLevelTypeId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clubRegistrationState, requiredRolesState])

  return {
    clubRegistrationNavState,
    clubRegistrationState,
    usasClubStaffState,
    usasClubFacilityLinksState,
    lscState,
    state,
    modalState,
    onModalContinue,
    onModalCanceled,
    formState,
    errorState,
    onFormValueChanged: updateFormState,
    onValueTextPairChanged,
    onContinueClicked,
    onSaveAndExitClicked,
    clearForm: resetForm,
    onBackClicked,
    settingOptions,
    handleSubmitProxy,
    setFormData,
    validateState
  }
}
export default useClubRegistrationInfo;

function getFormInitialState() {
  return {
    usasClubId: Constants.DEFAULT_ID,
    clubName: '',
    clubCode: '',
    lscId: Constants.DEFAULT_ID,
    lscname: '',
    yearFounded: '',
    clubSetting: '',
    clubOwnerTypeId: Constants.DEFAULT_ID,
    ownerTypeName: '',
    businessEntityTypeId: Constants.DEFAULT_ID,
    businessEntityTypeName: '',
    address1: '',
    address2: '',
    city: '',
    stateCode: '',
    stateName: '',
    postalCode: '',
    websiteAddress: '',
    selectedRegistration: {},
    validatedAddress: null,
    showedAddressSuggestion: false,
    includeOnFindAteam: false
  }
};

function getInitialModalState() {
  return {
    displayPopUp: false,
    modalTitle: ClubRegistrationConstants.MISSING_REQUIRED_INFORMATION
  }
};