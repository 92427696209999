import { OmrHttpHelper, NO_DATA_MESSAGE, BASIC_INITIAL_STATE } from '../../../common/utils/HttpHelper';
import Constants from '../../../common/utils/Constants';

export const INITIAL_STATE = {
  ...BASIC_INITIAL_STATE,
  options: [],
  isAthleteRole: ''
};

const getPreviousSeasonOfferingData = (state, setState, isAthleteRole) => {
  if (state.isArrayLoading !== true && !state.message) {
    let newState = {
      ...state,
      isArrayLoading: true,
      isArrayLoaded: false,
      message: 'Loading...',
      isAthleteRole: isAthleteRole
    };
    setState(newState);
    const isAthleteRoleForUrl = isAthleteRole ? encodeURIComponent(isAthleteRole) : '';
    const url = `/Offering/PreviousSeason?isAthleteRole=${isAthleteRoleForUrl}`;
    OmrHttpHelper(url, 'GET')
      .then((data) => {
        if (!data) {
          throw new Error(NO_DATA_MESSAGE);
        } else {
          const formattedData = data.map((o, index) => { return { ...o, index } });
          const options = data.map((o, index) => { return { id: index, name: o.offeringName }; });
          options.unshift({ id: Constants.DEFAULT_ID, name: '--' })
          newState = {
            ...newState,
            isArrayLoading: false,
            isArrayLoaded: true,
            arrayData: formattedData,
            options,
            message: '',
            isAthleteRole: isAthleteRole
          };
          setState(newState);
        }
      })
      .catch((e) => {
        const message = e && e.message ? e.message : NO_DATA_MESSAGE;
        setState({
          ...newState,
          isArrayLoading: false,
          isArrayLoaded: false,
          message: message
        });
      });
  }
};

const PreviousSeasonOfferingData = { INITIAL_STATE, getPreviousSeasonOfferingData };

export default PreviousSeasonOfferingData;