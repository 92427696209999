import { useEffect, useState } from 'react';
import { useNavigate } from '../../../../common/wrappers/ReactRouterDom';

import NavLinks from '../NavLinks';

import validate from './PreCompRegistrationOrganizationInfoValidation';

import PreCompRegistrationConstants from '../utils/PreCompRegistrationConstants';

import usePreCompAffiliationTypeData from '../../../state/preCompAffiliationType/UsePreCompAffiliationTypeData';
import usePreCompBusinessEntityTypeData from '../../../state/preCompBusinessEntityType/UsePreCompBusinessEntityTypeData';
import usePreCompOrganizationRegistrationData from '../../../state/preCompOrganizationRegistration/UsePreCompOrganizationRegistrationData';
import usePreCompLocationRegistrationNavData from '../../../state/preCompLocationRegistration/preCompLocationRegistrationLeftNavBar/UsePreCompLocationRegistrationNavData';
import usePreCompFilesFormw9Data from '../../../state/preCompFilesFormW9/UsePreCompFilesFormW9Data';
import { createOrgObjFromOrgInfoForm } from '../../preComp/utils/PreCompUtils';

import useForm from '../../../../common/utils/UseForm';
import Constants from '../../../../common/utils/Constants';
import HierarchicalDataUtils from '../../../../common/utils/HierarchicalDataUtils';
import useStatesCombobox from '../../../../common/components/comboboxes/statesCombobox/UseStatesCombobox';

const FILE_TYPE_ERROR_MSG = 'File type not permitted. Please provide a (*.pdf), (*.doc), or (*.docx) file.';
const w9TemplateUrl = ' https://www.irs.gov/pub/irs-pdf/fw9.pdf';

const INITIAL_VALIDATE_STATE = {
  isValidating: false,
  validatedAddress: null
};

const usePreCompOrganizationInfo = () => {
  const navigate = useNavigate();
  const { getStateNameById } = useStatesCombobox();
  const { preCompBusinessEntityTypeState } = usePreCompBusinessEntityTypeData();
  const { preCompAffiliationTypeState } = usePreCompAffiliationTypeData();
  const { preCompOrganizationRegistrationState, saveObjData, confirmSave } = usePreCompOrganizationRegistrationData();
  const { locationRegistrationNavState, updateCurrentNavState } = usePreCompLocationRegistrationNavData();
  const { preCompFilesFormW9State, postPreCompFilesFormW9, getFileNameFromUrl, GET_PRE_COMP_FILES_FORM_W9_PATH
  } = usePreCompFilesFormw9Data();
  const { formState, errorState, handleSubmit, updateFormState, onValueTextPairChanged, setFormData, setIsDirty, setErrors
  } = useForm(getInitialFormState, submitFormCallback, validate);
  const [continueState, setContinueState] = useState(false);
  const [validateState, setValidateState] = useState(INITIAL_VALIDATE_STATE);
  const [displayExitModalState, setDisplayExitModalState] = useState(false);

  const handleSubmitProxy = async (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    setValidateState({
      isValidating: true
    });
    await handleSubmit();
    setValidateState({
      isValidating: false,
      validatedAddress: formState.validatedAddress
    });
    updateFormState('showedAddressSuggestion', true);
  };

  const onModalExitClicked = () => {
    navigate(NavLinks.PRE_COMP_REGISTRATION_ENTRY);
  };

  const onModalCancelClicked = () => {
    setDisplayExitModalState(false);
  };

  const onBackClicked = () => {
    setDisplayExitModalState(true);
  };

  const onDownloadTemplateFile = (event) => {
    event.preventDefault();

    window.open(w9TemplateUrl);
  };

  const onUploadWNineForm = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    setErrors({ ...errorState, w9Form: '' });
    if (e.target.files[0]) {
      const selectedFile = e.target.files[0];
      const fileType = e.target.files[0].type;
      if (fileType === Constants.FILE_TYPE_PDF || fileType === Constants.FILE_TYPE_DOC ||
        fileType === Constants.FILE_TYPE_DOCX) {
        updateFormState('w9Form', selectedFile);
      }
      else {
        setErrors({ ...errorState, w9Form: FILE_TYPE_ERROR_MSG });
      }
    }
  };

  const onRemoveWNineForm = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    updateFormState('w9Form', {});
    updateFormState('formW9Url', '');
    updateFormState('formW9Name', '');
  };

  const onDownloadUploadedFile = (event) => {
    event.preventDefault();
    const fileUrl = formState.formW9Url;
    window.location.href = import.meta.env.VITE_PRECOMP_URL + GET_PRE_COMP_FILES_FORM_W9_PATH + fileUrl;
  }

  useEffect(() => {
    updateCurrentNavState(PreCompRegistrationConstants.ORG_INFO);
    setIsDirty(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (preCompOrganizationRegistrationState.isObjLoaded === true && preCompBusinessEntityTypeState.isArrayLoaded === true
      && preCompAffiliationTypeState.isArrayLoaded === true) {

      const preCompAffiliationIds = Array.isArray(preCompOrganizationRegistrationState.objData.preCompOrganizationAffiliation)
        ? preCompOrganizationRegistrationState.objData.preCompOrganizationAffiliation.map(x => x.preCompetitiveAffiliationTypeId) : [];
      const affiliations = HierarchicalDataUtils.GetNameIdPairs(preCompAffiliationTypeState.options, preCompAffiliationIds);

      setFormData({
        ...formState,
        orgUnitName: preCompOrganizationRegistrationState.objData.orgUnit?.orgUnitName || '',
        federalTaxId: preCompOrganizationRegistrationState.objData.federalTaxId ? preCompOrganizationRegistrationState.objData.federalTaxId + '' : '',
        address1: preCompOrganizationRegistrationState.objData.address?.address1 || '',
        address2: preCompOrganizationRegistrationState.objData.address?.address2 || '',
        tradeName: preCompOrganizationRegistrationState.objData.tradeName || '',
        city: preCompOrganizationRegistrationState.objData.address?.city || '',
        stateCode: preCompOrganizationRegistrationState.objData.address?.stateCode || '',
        stateName: preCompOrganizationRegistrationState.objData.address?.stateCode ? getStateNameById(preCompOrganizationRegistrationState.objData.address.stateCode) : '',
        postalCode: preCompOrganizationRegistrationState.objData.address?.postalCode || '',
        businessEntityTypeId: preCompOrganizationRegistrationState.objData.businessEntityTypeId || Constants.DEFAULT_ID,
        businessEntityTypeName: preCompOrganizationRegistrationState.objData.businessEntityType?.typeName || '',
        preCompOrganizationAffiliation: affiliations || [],
        website: preCompOrganizationRegistrationState.objData.website || '',
        formW9Url: preCompOrganizationRegistrationState.objData.formW9Url || '',
        formW9Name: getFileNameFromUrl(preCompOrganizationRegistrationState.objData.formW9Url) || ''
      });
      setIsDirty(true);
    } else if (preCompOrganizationRegistrationState.isObjLoaded === false) {
      navigate(NavLinks.PRE_COMP_REGISTRATION_ENTRY);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [preCompOrganizationRegistrationState.isObjLoaded, preCompAffiliationTypeState.isArrayLoaded, preCompBusinessEntityTypeState.isArrayLoaded]);

  useEffect(() => {
    if (preCompOrganizationRegistrationState.isSaved === true) {
      confirmSave();

      updateCurrentNavState(PreCompRegistrationConstants.ORG_INFO, true);

      setContinueState(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [preCompOrganizationRegistrationState]);

  useEffect(() => {
    if (preCompFilesFormW9State.isSaving === false && preCompFilesFormW9State.isSaved === true) {
      saveObjData(createOrgObjFromOrgInfoForm(preCompOrganizationRegistrationState.objData, formState, true, false, preCompFilesFormW9State.objData.uploadUrl));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [preCompFilesFormW9State]);

  useEffect(() => {
    if (continueState === true) {
      navigate(NavLinks.PRE_COMP_REGISTRATION_ORGANIZATION_CONTACT);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [continueState]);

  function submitFormCallback(formState) {
    if (formState.w9Form.size > 0) {
      postPreCompFilesFormW9(formState.w9Form);
    } else {
      saveObjData(createOrgObjFromOrgInfoForm(preCompOrganizationRegistrationState.objData, formState, true, false));
    }
  };

  function getInitialFormState() {
    return {
      address1: '',
      address2: '',
      tradeName: '',
      city: '',
      stateCode: '',
      stateName: '',
      postalCode: '',
      businessEntityTypeId: Constants.DEFAULT_ID,
      businessEntityTypeName: '',
      preCompOrganizationAffiliation: [],
      website: '',
      formW9Url: '',
      formW9Name: '',
      w9Form: {},
      validatedAddress: null,
      showedAddressSuggestion: false
    };
  };

  return {
    isLoading: preCompAffiliationTypeState.isArrayLoading || preCompBusinessEntityTypeState.isArrayLoading,
    isSaving: preCompFilesFormW9State.isSaving,
    formState,
    errorState,
    locationRegistrationNavState,
    displayExitModalState,
    onFormValueChanged: updateFormState,
    onValueTextPairChanged,
    onDownloadTemplateFile,
    onUploadWNineForm,
    onRemoveWNineForm,
    onDownloadUploadedFile,
    onBackClicked,
    onModalCancelClicked,
    onModalExitClicked,
    handleSubmitProxy,
    setFormData,
    validateState
  };
};

export default usePreCompOrganizationInfo;