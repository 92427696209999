import React, { Fragment } from 'react';

import useClubRegistrationMissionStatementBudget from './UseClubRegistrationMissionStatementBudget';

import ValidationPopUpModal from '../components/ValidationPopUpModal';

import ClubRegistrationConstants from '../utils/ClubRegistrationConstants';

import LeftNav from '../../../../common/components/leftNav/LeftNav';
import Headings from '../../../../common/components/headings/Headings';
import PrimaryButton from '../../../../common/components/buttons/PrimaryButton';
import SecondaryButton from '../../../../common/components/buttons/SecondaryButton';
import PopUpModal from '../../../../common/components/dialogs/PopUpModal';

import global from '../../../../common/components/GlobalStyle.module.css';
import Constants from '../../../../common/utils/Constants';

const VISION = 'vision';
const MISSION_STATEMENT = 'Mission Statement';
const BUDGET = 'budget';
const BUDGET_OR_BUSINESS_PLAN = 'First Year Budget or Business Plan';

const ClubRegistrationMissionStatementBudget = () => {
  const { GET_DOWNLOAD_TEMPLATE_PATH, GET_USAS_CLUB_FILES_PATH, clubRegistrationNavState, clubRegistrationState, modalState, removeFileModalState,
    state, fileState, usasClubFilesState, onUploadMissionStatementClicked, onRemoveMissionStatementClicked, onUploadBudgetClicked,
    onRemoveBudgetClicked, onOpenRemoveFileModalClicked, onRemoveFileModalCanceled, onModalContinue, onModalCanceled, onContinueClicked,
    onSaveAndExitClicked, onBackClicked } = useClubRegistrationMissionStatementBudget();

  return (
    <LeftNav title={ClubRegistrationConstants.LEFT_NAV_TITLE} state={clubRegistrationNavState} validateBeforeRedirect={false}>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>Mission Statement &amp; Budget</Headings.H3>
        </div>
      </div>
      {/* { clubRegistrationState.objData?.clubRegistrations?.length > 1 ?
      <div className="row">
        <div className="col-xs-12">
          <p>It is not required for renewing clubs to upload a Mission Statement, Budget or Business Plan.  </p>
        </div>
      </div> : <Fragment />
      } */}
      <div className="row usas-extra-top-margin">
        <div className="col-xs-12">
          <Headings.H6>{MISSION_STATEMENT}</Headings.H6>
        </div>
      </div>
      {clubRegistrationState.objData.status === ClubRegistrationConstants.IN_PROGRESS_STATUS ||
        clubRegistrationState.objData.status === ClubRegistrationConstants.RENEWING_STATUS ?
        <div className="row usas-extra-top-margin">
          <div className="col-xs-12">
            <PrimaryButton type="button" onClick={(event) => {
              event.preventDefault();
              window.location.href = import.meta.env.VITE_CLUBAPI_URL + GET_DOWNLOAD_TEMPLATE_PATH + VISION;
            }}>Download Template</PrimaryButton>&nbsp;
            {fileState.missionStatementFileUrl === '' ?
              <Fragment>
                <label htmlFor="mission-statement-file-upload" className={global.FileUpload}>
                  Upload File
                </label>
                <input hidden id="mission-statement-file-upload" type="file" onChange={onUploadMissionStatementClicked} />
                &nbsp;(*.pdf), (*.doc), or (*.docx)
              </Fragment>
              :
              <Fragment>
                <SecondaryButton onClick={(e) => { onOpenRemoveFileModalClicked(e, MISSION_STATEMENT) }}>
                  {fileState.missionStatementFileName.includes("Bad Request") ? "Clear Invalid" : "Remove Uploaded"} File
                </SecondaryButton>&nbsp;
                {fileState.missionStatementFileName.includes("Bad Request") === false &&
                  <PrimaryButton type="button" onClick={(event) => {
                    event.preventDefault();
                    const fileUrl = fileState.missionStatementFileUrl;
                    window.location.href = import.meta.env.VITE_CLUBAPI_URL + GET_USAS_CLUB_FILES_PATH + fileUrl;
                  }}>Download Uploaded File</PrimaryButton>
                }
              </Fragment>}
          </div>
        </div> :
        <Fragment >
          <div className="row usas-extra-bottom-margin" hidden={fileState.missionStatementFileUrl !== ''}>
            <div className="col-xs-12">
              <p><b>No Uploaded File</b> </p>
            </div>
          </div>
        </Fragment>}
      <div className="row usas-extra-bottom-margin" hidden={fileState.missionStatementFileUrl === ''}>
        <div className="col-xs-12">
          {fileState.missionStatementFileName.includes("Bad Request")
            ? <p className={global.ErrorMessage} style={{ fontSize: 16, fontWeight: 'bold' }}>Invalid File Type</p>
            : <p><b>Uploaded File Name:</b> {fileState.missionStatementFileName}</p>
          }
        </div>
      </div>
      <div className="row usas-extra-top-margin">
        <div className="col-xs-12">
          <Headings.H6>{BUDGET_OR_BUSINESS_PLAN}</Headings.H6>
        </div>
      </div>
      {clubRegistrationState.objData.status === ClubRegistrationConstants.IN_PROGRESS_STATUS ||
        clubRegistrationState.objData.status === ClubRegistrationConstants.RENEWING_STATUS ?
        <div className="row usas-extra-top-margin">
          <div className="col-xs-12">
            <PrimaryButton type="button" onClick={(event) => {
              event.preventDefault();
              window.location.href = import.meta.env.VITE_CLUBAPI_URL + GET_DOWNLOAD_TEMPLATE_PATH + BUDGET;
            }}>Download Template</PrimaryButton>&nbsp;
            {fileState.budgetFileUrl === '' ?
              <Fragment>
                <label htmlFor="budget-file-upload" className={global.FileUpload}>
                  Upload File
                </label>
                <input hidden id="budget-file-upload" type="file" onChange={onUploadBudgetClicked} />
                &nbsp;(*.pdf), (*.doc), or (*.docx)
              </Fragment>
              :
              <Fragment>
                <SecondaryButton onClick={(e) => { onOpenRemoveFileModalClicked(e, BUDGET_OR_BUSINESS_PLAN) }}>
                  {fileState.budgetFileName.includes("Bad Request") ? "Clear Invalid" : "Remove Uploaded"} File
                </SecondaryButton>&nbsp;
                {fileState.budgetFileName.includes("Bad Request") === false &&
                  <PrimaryButton
                    type="button"
                    onClick={(event) => {
                      event.preventDefault();
                      const fileUrl = fileState.budgetFileUrl;
                      window.location.href = import.meta.env.VITE_CLUBAPI_URL + GET_USAS_CLUB_FILES_PATH + fileUrl;
                    }}>Download Uploaded File</PrimaryButton>
                }
              </Fragment>}
          </div>
        </div> :
        <Fragment >
          <div className="row usas-extra-bottom-margin" hidden={fileState.budgetFileUrl !== ''}>
            <div className="col-xs-12">
              <p><b>No Uploaded File</b> </p>
            </div>
          </div>
        </Fragment>}
      <div className="row usas-extra-bottom-margin" hidden={fileState.budgetFileUrl === ''}>
        <div className="col-xs-12">
          {fileState.budgetFileName.includes("Bad Request")
            ? <p className={global.ErrorMessage} style={{ fontSize: 16, fontWeight: 'bold' }}>Invalid File Type</p>
            : <p><b>Uploaded File Name:</b> {fileState.budgetFileName}</p>
          }
        </div>
      </div>
      {((clubRegistrationState.objData.status === ClubRegistrationConstants.IN_PROGRESS_STATUS ||
        clubRegistrationState.objData.status === ClubRegistrationConstants.RENEWING_STATUS) && state.error) ?
        <div className="row">
          <div className="col-xs-12">
            <p className={global.ErrorMessage}>{state.error ? state.error : ''}</p>
          </div>
        </div> : <Fragment />}
      <div className="row usas-extra-top-margin usas-extra-bottom-margin">
        <div className="col-xs-12 usas-extra-top-margin">
          <PrimaryButton type="button" onClick={() => onContinueClicked()}>Continue</PrimaryButton>&nbsp;
          <PrimaryButton type="button" onClick={() => onSaveAndExitClicked()}>
            {clubRegistrationState.objData.status === ClubRegistrationConstants.IN_PROGRESS_STATUS
              || clubRegistrationState.objData.status === ClubRegistrationConstants.RENEWING_STATUS ?
              ClubRegistrationConstants.SAVE_AND_EXIT : ClubRegistrationConstants.EXIT}</PrimaryButton>&nbsp;
          <SecondaryButton type="button" onClick={() => onBackClicked()}>Back</SecondaryButton>
        </div>
      </div>
      <ValidationPopUpModal
        clubRegistrationState={clubRegistrationState}
        state={state}
        modalState={modalState}
        onModalContinue={onModalContinue}
        onModalCanceled={onModalCanceled}
      />
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={clubRegistrationState.isObjLoading} />
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.SAVING_MSG}
        displayPopUp={clubRegistrationState.isSaving || usasClubFilesState.isSaving} />
      <PopUpModal
        widthPct={90}
        maxWidth={720}
        title={removeFileModalState.modalTitle}
        displayPopUp={removeFileModalState.displayPopUp}
        onModalCanceled={onRemoveFileModalCanceled}>
        <div className="row">
          <div className="col-xs-12">
            <p><b>File Type:</b> {removeFileModalState.fileType}</p>
            {removeFileModalState.fileName.includes("Bad Request")
              ? <p className={global.ErrorMessage} style={{ fontSize: 16, fontWeight: 'bold' }}>Invalid File Type</p>
              : <p><b>Uploaded File Name:</b> {removeFileModalState.fileName}</p>
            }
          </div>
        </div>
        <div className="row usas-extra-top-margin">
          <div className="col-xs-12">
            {removeFileModalState.fileType === MISSION_STATEMENT ?
              <PrimaryButton type="button" onClick={onRemoveMissionStatementClicked}>Yes, Remove</PrimaryButton> :
              <PrimaryButton type="button" onClick={onRemoveBudgetClicked}>Yes, Remove</PrimaryButton>}&nbsp;
            <SecondaryButton type="button" onClick={onRemoveFileModalCanceled}>Cancel</SecondaryButton>
          </div>
        </div>
      </PopUpModal>
    </LeftNav >
  )
}

export default ClubRegistrationMissionStatementBudget;