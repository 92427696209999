import { useState } from 'react';

import { DEFAULT_ID } from '../../utils/Constants';

import useEmbeddedReportData from '../../state/embeddedReport/UseEmbeddedReportData';

const INITIAL_STATE = {
  showGridPopup: false,
  showReport: false,
  reportInfoId: DEFAULT_ID
};

const useReportListAsButton = () => {
  const { reportListState, getEmbeddedReportListForRoute, getEmbeddedReportListForContext } = useEmbeddedReportData();
  const [state, setState] = useState(INITIAL_STATE);

  const onCloseGridPopup = () => {
    setState(INITIAL_STATE);
  };

  const onViewReportClicked = (reportInfoId) => {
    setState({
      ...state,
      showGridPopup: false,
      showReport: true,
      reportInfoId
    });
  };

  const onCloseViewReportClicked = () => {
    if (reportListState.arrayData.length > 1) {
      setState({
        ...state,
        showGridPopup: true,
        showReport: false,
        reportInfoId: DEFAULT_ID
      });
    } else {
      onCloseGridPopup();
    }
  };

  const onReportListButtonClicked = (routeName, contextId) => {
    if (reportListState.isArrayLoaded === true) {
      const onlyReportInfoId = reportListState.arrayData?.length === 1 ? reportListState.arrayData[0]?.reportInfoId : null;

      if (onlyReportInfoId) {
        onViewReportClicked(onlyReportInfoId);
      } else {
        setState({
          ...state,
          showGridPopup: true,
          showReport: false,
          reportInfoId: DEFAULT_ID
        });
      }
    } else if (routeName) {
      const getReportListPromise = contextId
        ? getEmbeddedReportListForContext(routeName, contextId)
        : getEmbeddedReportListForRoute(routeName);

      if (getReportListPromise ?? false) {
        getReportListPromise.then((newState) => {
          if (newState ?? false) {
            const onlyReportInfoId = newState.arrayData?.length === 1 ? newState.arrayData[0]?.reportInfoId : null;

            if (onlyReportInfoId) {
              onViewReportClicked(onlyReportInfoId);
            } else {
              setState({
                ...state,
                showGridPopup: true,
                showReport: false,
                reportInfoId: DEFAULT_ID
              });
            }
          }
        });
      }
    }
  };

  return {
    ...state,
    isLoading: reportListState.isArrayLoading,
    reportList: reportListState.arrayData || [],
    onCloseGridPopup,
    onViewReportClicked,
    onCloseViewReportClicked,
    onReportListButtonClicked
  };
};

export default useReportListAsButton;