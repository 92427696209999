import React, { Fragment } from 'react';

import ClubStaffReportGridLarge from './ClubStaffReportGridLarge';
import ClubStaffReportGridSmall from './ClubStaffReportGridSmall';

import useExpandedRow from '../UseExpandedRow';

const ClubStaffReportGrid = ({ state }) => {
  const { expandedId, onClick } = useExpandedRow();

  return (
    <Fragment>
      <ClubStaffReportGridLarge state={state} expandedId={expandedId} onClick={onClick} />
      <ClubStaffReportGridSmall state={state} expandedId={expandedId} onClick={onClick} />
    </Fragment>
  );
};

export default ClubStaffReportGrid;