import React from 'react';

import useClubRegistrationFacilityAdd from './UseClubRegistrationFacilityAdd';

import NavLinks from '../NavLinks';

import ClubRegistrationConstants from '../utils/ClubRegistrationConstants';

import AddFacility from '../../../../common/components/searches/searchFacility/addFacility/AddFacility';
import LeftNav from '../../../../common/components/leftNav/LeftNav';

const ClubRegistrationFacilityAdd = () => {
  const {
    clubRegistrationState,
    clubRegistrationNavState
  } = useClubRegistrationFacilityAdd();

  return (
    <LeftNav title={ClubRegistrationConstants.LEFT_NAV_TITLE} state={clubRegistrationNavState} validateBeforeRedirect={false}>
      <AddFacility
        saveUrl={NavLinks.CLUB_REGISTRATION_FACILITY_DETAILS}
        cancelUrl={NavLinks.CLUB_REGISTRATION_FACILITY_SEARCH}
        orgUnitId={clubRegistrationState.objData.orgUnitId} />
    </LeftNav>
  );
};

export default ClubRegistrationFacilityAdd;