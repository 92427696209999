import React, { Fragment } from 'react';

import useClubPreCompetitiveProgramsWrite from './UseClubPreCompetitiveProgramsWrite';

import ClubPreCompGridWrite from './ClubPreCompGridWrite';
import ActionIntraPageButton from '../../../../common/components/buttons/ActionIntraPageButton';
import PopUpModal from '../../../../common/components/dialogs/PopUpModal';
import Headings from '../../../../common/components/headings/Headings';
import Constants from '../../../../common/utils/Constants';

const ClubPreCompetitiveProgramsWrite = () => {
  const { usasClubPreCompLinksState, baseUsasClubState, onAdd, onDelete } = useClubPreCompetitiveProgramsWrite();

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>Club Pre-Competitive Programs</Headings.H3>
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12">
          <p>Disclaimer for what is pre-competitive and the impact it has on club and athletes of club</p>
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12 usas-extra-top-margin">
          <ActionIntraPageButton type="button" onClick={() => onAdd()}>Add Program</ActionIntraPageButton>
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12 usas-extra-top-margin usas-extra-bottom-margin">
          <ClubPreCompGridWrite
            data={usasClubPreCompLinksState.arrayData || []}
            isLoading={usasClubPreCompLinksState.isArrayLoading}
            onDelete={onDelete} />
        </div>
      </div>
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={baseUsasClubState.isObjLoading || usasClubPreCompLinksState.isArrayLoading} />
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.SAVING_MSG}
        displayPopUp={usasClubPreCompLinksState.isSaving} />
    </Fragment >
  );
};

export default ClubPreCompetitiveProgramsWrite;