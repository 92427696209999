import React, { createContext, useState } from 'react';

import UsasClubThirdPartyInvoicesData from './UsasClubThirdPartyInvoicesData';

export const UsasClubThirdPartyInvoicesStateContext = createContext();

const UsasClubThirdPartyInvoicesContextProvider = ({ children }) => {
  const stateHook = useState(UsasClubThirdPartyInvoicesData.INITIAL_STATE);

  return (
    <UsasClubThirdPartyInvoicesStateContext.Provider value={stateHook}>
      {children}
    </UsasClubThirdPartyInvoicesStateContext.Provider>
  );
};

export default UsasClubThirdPartyInvoicesContextProvider;