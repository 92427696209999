import { Fragment } from 'react';

import useClubAddVendor from './UseClubAddVendor';

import Headings from '../../../../../common/components/headings/Headings';
import PrimaryButton from '../../../../../common/components/buttons/PrimaryButton';
import SecondaryButton from '../../../../../common/components/buttons/SecondaryButton';
import VendorDropdown from '../../../../../common/components/dropdowns/vendorDropdown/VendorDropdown';
import PopUpModal from '../../../../../common/components/dialogs/PopUpModal';
import Constants from '../../../../../common/utils/Constants';

const ClubAddVendor = () => {
	const {
		vendorState,
		formState,
		errorState,
		onBackToVendorsButtonClicked,
		onValueTextPairChanged,
		clubVendorState,
		handleSubmit
	} = useClubAddVendor();

	return (
		<Fragment>
			<div className="row">
				<div className="col-xs-12 usas-extra-bottom-margin">
					<Headings.H3>Club Add Vendor</Headings.H3>
				</div>
			</div>
			<form onSubmit={handleSubmit} noValidate>
				<div className='row usas-extra-top-margin usas-extra-bottom-margin'>
					<div className='col-xs-12 col-sm-6'>
						<VendorDropdown
							label="Available Club Vendors"
							name='vendorId'
							value={formState.vendorId}
							error={errorState.vendorId}
							message={errorState.vendorId}
							isTeamManagement={true}
							onChange={(newValue, newValueLabel, e) => {
								onValueTextPairChanged(newValue, 'vendorId', newValueLabel, 'vendorName', e);
							}} />
					</div>
				</div>
				<div className="row">
					<div className="col-xs-12 usas-extra-top-margin usas-extra-bottom-margin">
						<PrimaryButton type="submit">Save</PrimaryButton>&nbsp;
						<SecondaryButton type="button" onClick={onBackToVendorsButtonClicked}>Back</SecondaryButton>
					</div>
				</div>
			</form>
			<PopUpModal
				widthPct={50}
				maxWidth={250}
				title={Constants.LOADING_MSG}
				displayPopUp={vendorState?.isArrayLoading} />
			<PopUpModal
				maxWidth={250}
				title={Constants.SAVING_MSG}
				displayPopUp={clubVendorState?.isSaving} />
		</Fragment>
	);
};

export default ClubAddVendor;