import React from 'react';

import usePreCompOfferingTypeTreeView from './UsePreCompOfferingTypeTreeView';

import TreeView from '../../../../../common/components/tree/TreeView';

import global from '../../../../../common/components/GlobalStyle.module.css';

const PreCompOfferingTypeTreeView = ({ label, name, value, error, message, onChange }) => {
  const { preCompOfferingTypeState } = usePreCompOfferingTypeTreeView();
  
  return preCompOfferingTypeState.message
    ? <span className={global.LoadingMsg}>{preCompOfferingTypeState.message}</span>
    : (
      <TreeView
        label={label}
        name={name}
        value={value}
        options={preCompOfferingTypeState.treeData}
        selectableLeavesOnly={true}
        singleSelect={true}
        error={error}
        message={message}
        isLoading={preCompOfferingTypeState.isArrayLoading}
        onChange={onChange} />
    );
};

export default PreCompOfferingTypeTreeView;