import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from '../../../../common/wrappers/ReactRouterDom';

import NavLinks from '../utils/NavLinks';

import useLearnToSwimStaffData from '../../../state/learnToSwimStaff/UseLearnToSwimStaffData';
import useLearnToSwimData from '../../../state/learnToSwim/UseLearnToSwimData';

import useEnvironmentVariableData from '../../../../common/state/environmentVariable/UseEnvironmentVariableData';

import Constants from '../../../../common/utils/Constants';

const INITIAL_DELETE_MODAL_STATE = {
  personOrgRoleDurationId: Constants.DEFAULT_ID, // for role delete
  personId: '', // for person delete
  personName: '',
  displayPopup: false
};

const useLearnToSwimStaff = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { environmentVariableState, LTS_STAFF_ROLE_GROUP_ID } = useEnvironmentVariableData();
  const { ltsProviderState } = useLearnToSwimData();
  const { ltsStaffState, ltsStaffMemberState, getLTSStaff, putLTSStaffMember, deleteLTSStaffMemberRole
  } = useLearnToSwimStaffData();
  const [deleteModalState, setDeleteModalState] = useState(INITIAL_DELETE_MODAL_STATE);

  const onAddStaffClicked = () => {
    navigate(NavLinks.LEARN_TO_SWIM_STAFF_ADD);
  };

  const onEditStaffClicked = (staffObj) => {
    navigate(NavLinks.LEARN_TO_SWIM_STAFF_EDIT, { state: { staffObj } });
  };

  const onDeleteStaffClicked = (personId) => {
    const targetPerson = ltsStaffState.arrayData.find(x => x.personId === personId);

    if (targetPerson) {
      setDeleteModalState({
        ...deleteModalState,
        personId,
        personName: `${targetPerson.firstName || ''} ${targetPerson.lastName || ''}`,
        displayPopup: true
      });
    }
  };

  const onDeleteStaffRoleClicked = (personId, personOrgRoleDurationId) => {
    const targetPerson = ltsStaffState.arrayData.find(x => x.personId === personId);

    if (targetPerson && Array.isArray(targetPerson.personOrgRole)) {
      if (targetPerson.personOrgRole.length === 1 && targetPerson.personOrgRole[0].personOrgRoleDurationId === personOrgRoleDurationId) {
        setDeleteModalState({
          ...deleteModalState,
          personOrgRoleDurationId,
          personName: `${targetPerson.firstName} ${targetPerson.lastName}`,
          displayPopup: true
        });
      } else {
        const deleteLTSStaffMemberRolePromise = deleteLTSStaffMemberRole(personOrgRoleDurationId);

        if (deleteLTSStaffMemberRolePromise !== null) {
          deleteLTSStaffMemberRolePromise.then((newState) => {
            if (newState !== null) {
              const orgUnitId = ltsProviderState.objData?.orgUnitId;

              if (orgUnitId) {
                const getLTSStaffPromise = getLTSStaff(orgUnitId, LTS_STAFF_ROLE_GROUP_ID);

                if (getLTSStaffPromise !== null) {
                  getLTSStaffPromise.catch((e) => {
                    //TODO context error
                  });
                }
              }
            }
          }).catch((e) => {
            //TODO state error
          })
        }
      }
    }
  };

  const onDeleteModalDeleteClicked = () => {
    if (deleteModalState.personOrgRoleDurationId > 0) {
      const deleteLTSStaffMemberRolePromise = deleteLTSStaffMemberRole(deleteModalState.personOrgRoleDurationId);

      if (deleteLTSStaffMemberRolePromise !== null) {
        deleteLTSStaffMemberRolePromise.then((newState) => {
          if (newState !== null) {
            const orgUnitId = ltsProviderState.objData?.orgUnitId;

            if (orgUnitId) {
              const getLTSStaffPromise = getLTSStaff(orgUnitId, LTS_STAFF_ROLE_GROUP_ID);

              if (getLTSStaffPromise !== null) {
                getLTSStaffPromise.catch((e) => {
                  //TODO context error
                });
              }
            }
          }
        }).catch((e) => {
          //TODO state error
        })
      }
    } else if (deleteModalState.personId) {
      const personId = deleteModalState.personId;
      const orgUnitId = ltsProviderState.objData?.orgUnitId;

      if (orgUnitId) {
        const putLTSStaffMemberPromise = putLTSStaffMember(orgUnitId, personId, LTS_STAFF_ROLE_GROUP_ID, { personId, personOrgRole: [] });

        if (putLTSStaffMemberPromise !== null) {
          putLTSStaffMemberPromise.then((newState) => {
            if (newState !== null) {
              const getLTSStaffPromise = getLTSStaff(orgUnitId, LTS_STAFF_ROLE_GROUP_ID);

              if (getLTSStaffPromise !== null) {
                getLTSStaffPromise.catch((e) => {
                  //TODO context error
                });
              }
            }
          }).catch((e) => {
            //TODO state error
          });
        }
      }
    }

    setDeleteModalState({ ...INITIAL_DELETE_MODAL_STATE });
  };

  const onDeleteModalCancelClicked = () => {
    setDeleteModalState({ ...INITIAL_DELETE_MODAL_STATE });
  };

  useEffect(() => {
    if (environmentVariableState.isLoaded === true) {
      if (ltsStaffState.isArrayLoaded !== true || location.state?.refreshState) {
        const orgUnitId = ltsProviderState.objData?.orgUnitId;

        if (orgUnitId) {
          const getLTSStaffPromise = getLTSStaff(orgUnitId, LTS_STAFF_ROLE_GROUP_ID);

          if (getLTSStaffPromise !== null) {
            getLTSStaffPromise.catch((e) => {
              //TODO context error
            });
          }
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [environmentVariableState.isLoaded]);

  return {
    isSaving: ltsStaffMemberState.isObjLoading,
    isLoading: ltsStaffState.isArrayLoading || environmentVariableState.isLoading,
    deleteModalState,
    ltsStaffState,
    onAddStaffClicked,
    onEditStaffClicked,
    onDeleteStaffClicked,
    onDeleteStaffRoleClicked,
    onDeleteModalDeleteClicked,
    onDeleteModalCancelClicked
  };
};

export default useLearnToSwimStaff;