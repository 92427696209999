import { useEffect, useState } from "react";
import { useNavigate } from "../../../../common/wrappers/ReactRouterDom";

import NavLinks from "../NavLinks";

import ClubConstants from "../utils/ClubConstants";

import useUsasClubStaffData from "../../../state/clubStaff/UseUsasClubStaffData";
import useEnvironmentVariableData from '../../../../common/state/environmentVariable/UseEnvironmentVariableData';
import useBaseUsasClubData from "../../../../common/state/clubBase/UseBaseUsasClubData";

import Constants from "../../../../common/utils/Constants";

const registrationRoles = [
  ClubConstants.COACH_ROLE_NAME,
  ClubConstants.JUNIOR_COACH_ROLE_NAME,
  ClubConstants.PROVISIONAL_COACH_ROLE_NAME
];

const useClubStaff = () => {
  const navigate = useNavigate();
  const { environmentVariableState, CLUB_STAFF_ROLE_GROUP_ID } = useEnvironmentVariableData();
  const { baseUsasClubState } = useBaseUsasClubData();
  const { usasClubStaffState, getUsasClubStaff, deleteUsasClubStaff, confirmUsasClubStaffSave } = useUsasClubStaffData();
  const [deleteModalState, setDeleteModalState] = useState(getInitialDeleteModalState);

  const onAdd = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    navigate(NavLinks.CLUB_STAFF_DETAIL, { state: { personId: '' } });
  };

  const onEdit = (e, personId) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    };

    navigate(NavLinks.CLUB_STAFF_DETAIL, { state: { personId } });
  };

  const onDelete = (e, personId, personOrgRoleDurationId) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    const targetPerson = usasClubStaffState.arrayData.find(x => x.personId === personId);

    if (targetPerson && Array.isArray(targetPerson.personOrgRole)) {
      const isLastRole = targetPerson.personOrgRole.length === 1 && targetPerson.personOrgRole[0].personOrgRoleDurationId === personOrgRoleDurationId;

      setDeleteModalState({
        ...deleteModalState,
        personOrgRoleDurationId,
        personName: `${targetPerson.firstName} ${targetPerson.lastName}`,
        isLastRole,
        displayModal: true
      });
    }
  };

  const onModalDeleteClicked = () => {
    deleteUsasClubStaff(deleteModalState.personOrgRoleDurationId);
    setDeleteModalState(getInitialDeleteModalState);
  };

  const onModalCancelClicked = () => {
    setDeleteModalState(getInitialDeleteModalState);
  };

  useEffect(() => {
    if (baseUsasClubState.isObjLoaded === true && environmentVariableState.isLoaded === true) {
      if (usasClubStaffState.isSaved === false) {
        getUsasClubStaff(baseUsasClubState.objData.orgUnitId, CLUB_STAFF_ROLE_GROUP_ID);
      } else {
        confirmUsasClubStaffSave();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps   
  }, [usasClubStaffState.isSaved, environmentVariableState]);

  function getInitialDeleteModalState() {
    return {
      personOrgRoleDurationId: Constants.DEFAULT_ID,
      personName: '',
      isLastRole: false,
      displayModal: false
    };
  };

  return {
    usasClubStaffState,
    deleteModalState,
    environmentVariableState,
    onDelete,
    onAdd,
    onEdit,
    onModalDeleteClicked,
    onModalCancelClicked,
    registrationRoles
  };
};

export default useClubStaff;