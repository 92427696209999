import { isValidEmail } from '../../../../../common/utils/validation';

export const localValidate = (formState) => {
  let errors = {};

  if (formState.email.trim() === '') {
    errors.email = 'Email is required';
  } else if (!isValidEmail(formState.email.trim())) {
    errors.email = 'Email must be in a valid format';
  } else if (formState.email.trim().length > 512) {
    errors.email = 'Email cannot exceed 512 characters';
  }

  return errors;
};

const EditLSCRoleEmailValidation = async (formState) => {
  let errors = localValidate(formState);

  return errors ? errors : {};
};

export default EditLSCRoleEmailValidation;