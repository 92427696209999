import { useState } from "react";

import CommunityClubMemberLoggedInPersonData from "./CommunityClubMemberLoggedInPersonData";

const useCommunityClubMemberLoggedInPersonData = () => {
  const [communityClubMemberLoggedInPersonState, setCommunityClubMemberLoggedInPersonState] = useState(CommunityClubMemberLoggedInPersonData.INITIAL_STATE);

  const deleteCommunitClubMemberLoggedInPersonById = (communityClubMemberLoggedInPersonId) => CommunityClubMemberLoggedInPersonData.deleteCommunitClubMemberLoggedInPersonByIdData(communityClubMemberLoggedInPersonId, communityClubMemberLoggedInPersonState, setCommunityClubMemberLoggedInPersonState);

  const resetCommunityClubMemberLoggedInPersonState = () => {
    setCommunityClubMemberLoggedInPersonState({ ...CommunityClubMemberLoggedInPersonData.INITIAL_STATE });
  };

  const confirmSave = () => {
    setCommunityClubMemberLoggedInPersonState({
      ...communityClubMemberLoggedInPersonState,
      isSaved: false
    });
  };

  return {
    communityClubMemberLoggedInPersonState,
    deleteCommunitClubMemberLoggedInPersonById,
    resetCommunityClubMemberLoggedInPersonState,
    confirmSave
  };
};

export default useCommunityClubMemberLoggedInPersonData;