import { useEffect, useState } from "react";
import { useNavigate } from "../../../../common/wrappers/ReactRouterDom";

import NavLinks from "../utils/NavLinks";
import { createProviderObjFromEntryForm } from "../utils/LearnToSwimRegistrationUtils";

import useLearnToSwimRegistrationData from "../../../state/learnToSwimRegistration/UseLearnToSwimRegistrationData";

const INITIAL_MODAL_STATE = {
  displayModal: false
};

const useLearnToSwimRegistrationEntry = () => {
  const navigate = useNavigate();
  const { ltsProviderDupCheckState, ltsProviderRegistrationState, getLTSProviderDupCheck, putLTSProviderRegistration,
    resetLTSProviderRegistrationState
  } = useLearnToSwimRegistrationData();
  const [modalState, setModalState] = useState(INITIAL_MODAL_STATE);

  const onSubmitFormCallback = (formState) => {
    const getLTSProviderDupCheckPromise = getLTSProviderDupCheck(formState.taxId);

    if (getLTSProviderDupCheckPromise !== null) {
      getLTSProviderDupCheckPromise.then((newState) => {
        if (newState !== null) {
          if (newState.objData?.learnToSwimProviderId > 0) {
            setModalState({
              ...modalState,
              displayModal: true
            });
          } else {
            const providerObj = createProviderObjFromEntryForm(formState, ltsProviderRegistrationState.objData);
            const putLTSProviderRegistrationPromise = putLTSProviderRegistration(providerObj);

            if (putLTSProviderRegistrationPromise !== null) {
              putLTSProviderRegistrationPromise.then((newState) => {
                if (newState !== null) {
                  navigate(NavLinks.LEARN_TO_SWIM_REGISTRATION_PROVIDER_INFO);
                }
              }).catch((e) => {
                //TODO context error
              });
            }
          }
        }
      }).catch((e) => {
        //TODO state error
      });
    }
  };

  const onModalCancelClicked = () => {
    setModalState({
      ...INITIAL_MODAL_STATE
    });
  };

  useEffect(() => {
    resetLTSProviderRegistrationState();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    isLoading: ltsProviderDupCheckState.isObjLoading || ltsProviderRegistrationState.isObjLoading,
    modalState,
    onSubmitFormCallback,
    onModalCancelClicked
  };
};

export default useLearnToSwimRegistrationEntry;