import { useEffect } from 'react';
import { useNavigate } from '../../../common/wrappers/ReactRouterDom';

import { navLinks } from './UseMemberAdministration';

import useMemberAdministrationHeaderData from '../../state/memberAdministrationHeader/UseMemberAdministrationHeaderData';
import useLSCRegistrationManagementData from '../../state/lscRegistrationManagement/UseLSCRegistrationManagementData';

import useNavRoutes from '../../../common/state/security/UseNavRoutes';
import useSecurityData from '../../../common/state/security/UseSecurityData';
import usePersonOrgRoleData from '../../../common/state/personOrgRole/UsePersonOrgRoleData';
import useSelectOrgUnitData from '../../../common/state/selectOrgUnit/UseSelectOrgUnitData';
import useMemberAdministrationLscStaffData from '../../../common/state/memberAdministration/lscStaff/UseMemberAdministrationLscStaffData';
import useMemberAdministrationLscEmailData from '../../../common/state/memberAdministration/lscEmail/UseMemberAdministrationLscEmailData';
import useMemberAdministrationLscBoardData from '../../../common/state/memberAdministration/lscBoard/UseMemberAdministrationLscBoardData';

const TAXONOMIES = ['LSCMemberAdministration'];
const SCOPE = 'ClubMemberAdmin';

const useMemberAdministrationContextView = () => {
  const navigate = useNavigate();
  const { navRoutes } = useNavRoutes();
  const { getContextSecurity } = useSecurityData();
  const { clearArrayData } = usePersonOrgRoleData();
  const { selectOrgUnitState, resetSelectOrgUnitState } = useSelectOrgUnitData();
  const { clearMemberAdministrationLscStaffArrayData } = useMemberAdministrationLscStaffData();
  const { clearLscRoleEmailsArrayData } = useMemberAdministrationLscEmailData();
  const { clearLSCRegManagementArrayData } = useLSCRegistrationManagementData();
  const { clearLscBoardMembersArrayData } = useMemberAdministrationLscBoardData();
  const { memberAdministrationHeaderState, clearMemberAdministrationHeaderObjData } = useMemberAdministrationHeaderData();

  const onBackToSelectionClicked = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    resetSelectOrgUnitState();
    clearArrayData();
    clearMemberAdministrationLscStaffArrayData();
    clearLscRoleEmailsArrayData();
    clearLSCRegManagementArrayData();
    clearLscBoardMembersArrayData();

    if (memberAdministrationHeaderState.lscManagementHqSelection === false ||
      (!navRoutes.CLUB_MEMBER_ADMIN_HQ_SELECTION && navRoutes.CLUB_MEMBER_ADMIN_SELECTION)) {
      clearMemberAdministrationHeaderObjData();
      navigate(navLinks.LSC_SELECTION);
    }
    else {
      clearMemberAdministrationHeaderObjData();
      navigate(navLinks.LSC_HQ_SELECTION);
    }
  }

  const onHomeClicked = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    navigate(navLinks.LSC_STAFF);
  }

  useEffect(() => {
    if (selectOrgUnitState.orgUnitId) {
      getContextSecurity(selectOrgUnitState.orgUnitId, TAXONOMIES, SCOPE)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectOrgUnitState.orgUnitId])


  return {
    onHomeClicked,
    onBackToSelectionClicked,
    selectOrgUnitState
  };
};

export default useMemberAdministrationContextView;
