import { useContext } from 'react';
import useEnvironmentVariableData from '../../../common/state/environmentVariable/UseEnvironmentVariableData';

import { PreCompLocationRegistrationStateContext } from './PreCompLocationRegistrationContextProvider';

import PreCompLocationRegistrationData from './PreCompLocationRegistrationData';

const usePreCompLocationRegistrationData = () => {
  const [preCompLocationRegistrationState, setPreCompLocationRegistrationState] = useContext(PreCompLocationRegistrationStateContext);
  const { PRIMARY_CONTACT_ORG_ROLE_ID } = useEnvironmentVariableData();

  const saveObjData = (objData) => {
    setPreCompLocationRegistrationState({
      ...preCompLocationRegistrationState,
      isSaved: true,
      objData
    });
  };

  const saveStaffData = (staffObj) => {
    const staff = preCompLocationRegistrationState.objData.preCompetitiveLocationStaff;

    let matchFound = false;
    for (let i = 0; i < staff.length; i++) {
      if (staff[i].personId === staffObj.personId) {
        staff[i] = staffObj;
        matchFound = true;
        break;
      }
    }

    if (matchFound === false) {
      staff.push(staffObj);
    }

    if (Array.isArray(staffObj.personOrgRole) && staffObj.personOrgRole.findIndex(x => x.orgRoleId === PRIMARY_CONTACT_ORG_ROLE_ID) >= 0) {
      for (let i = 0; i < staff.length; i++) {
        if (staff[i].personId !== staffObj.personId) {
          const primaryContactIndex = staff[i].personOrgRole.findIndex(x => x.orgRoleId === PRIMARY_CONTACT_ORG_ROLE_ID);

          if (primaryContactIndex >= 0) {
            if (staff[i].personOrgRole.length === 1) {
              staff[i] = null;
            } else {
              staff[i].personOrgRole.splice(primaryContactIndex, 1);
            }
          }
        }
      }
    }

    setPreCompLocationRegistrationState({
      ...preCompLocationRegistrationState,
      isSaved: true,
      objData: {
        ...preCompLocationRegistrationState.objData,
        preCompetitiveLocationStaff: staff.filter(x => x !== null)
      }
    });
  };

  const deleteStaffRoleData = (personId, orgRoleId) => {
    const staff = preCompLocationRegistrationState.objData.preCompetitiveLocationStaff;
    const targetIndex = staff.findIndex(x => x.personId === personId);

    if (targetIndex >= 0) {
      const newOrgRolesId = staff[targetIndex].personOrgRole.filter(x => x.orgRoleId !== orgRoleId);

      if (newOrgRolesId.length === 0) {
        staff.splice(targetIndex, 1);
      } else {
        staff[targetIndex].personOrgRole = newOrgRolesId;
      }

      setPreCompLocationRegistrationState({
        ...preCompLocationRegistrationState,
        isSaved: true,
        objData: {
          ...preCompLocationRegistrationState.objData,
          preCompetitiveLocationStaff: staff
        }
      });
    }
  };

  const resetPreCompLocationRegistrationState = () => {
    setPreCompLocationRegistrationState({
      ...PreCompLocationRegistrationData.INITIAL_STATE,
      objData: JSON.parse(JSON.stringify(PreCompLocationRegistrationData.PRE_COMP_LOCATION_OBJ))
    });
  };

  const setupPreCompLocationRegistrationState = () => {
    setPreCompLocationRegistrationState({
      ...preCompLocationRegistrationState,
      isObjLoaded: true
    });
  };

  const confirmSave = () => {
    setPreCompLocationRegistrationState({
      ...preCompLocationRegistrationState,
      isSaved: false,
      isObjLoaded: true
    });
  };

  return {
    preCompLocationRegistrationState,
    saveObjData,
    saveStaffData,
    deleteStaffRoleData,
    resetPreCompLocationRegistrationState,
    setupPreCompLocationRegistrationState,
    confirmSave
  };
};

export default usePreCompLocationRegistrationData;