import { useContext, useEffect } from 'react';

import { PreCompAffiliationTypeStateContext } from './PreCompAffiliationTypeContextProvider';
import PreCompAffiliationTypeData from './PreCompAffiliationTypeData';

const usePreCompAffiliationTypeData = () => {
  const [preCompAffiliationTypeState, setPreCompAffiliationTypeState] = useContext(PreCompAffiliationTypeStateContext);

  const getAffiliationTypes = () => { PreCompAffiliationTypeData.getPreCompAffiliationTypes(preCompAffiliationTypeState, setPreCompAffiliationTypeState); };

  useEffect(() => {
    if (preCompAffiliationTypeState.isArrayLoading !== true && preCompAffiliationTypeState.isArrayLoaded !== true) {
      getAffiliationTypes(preCompAffiliationTypeState, setPreCompAffiliationTypeState);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    preCompAffiliationTypeState,
    getAffiliationTypes,
  };
};

export default usePreCompAffiliationTypeData;