import React, { Fragment } from 'react';

import DeleteIcon from '../../../../common/components/icons/DeleteIcon';
import EditIcon from '../../../../common/components/icons/EditIcon';

import global from '../../../../common/components/GlobalStyle.module.css';

const LargeGrid = ({ state, onEdit, onDelete }) => (
  <table className={[global.UsasTable, 'visible-md visible-lg'].join(' ')}>
    <thead>
      <tr>
        <th>Legal Entity Name</th>
        <th>Legal Entity Tax Id</th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      {state.isObjLoaded !== true
        ? (
          <tr>
            {state.isObjLoading === true ?
              <td colSpan="3">Loading...</td>
              : <td colSpan="3">No Legal Entities</td>
            }
          </tr>
        ) : Array.isArray(state.objData.usasClubLegalEntities) && state.objData.usasClubLegalEntities.length > 0
          ? state.objData.usasClubLegalEntities.map(entity => (
            <tr key={entity.usasClubLegalEntityId}>
              <td>{entity.legalEntityName}</td>
              <td>{entity.legalEntityTaxId}</td>
              <td>
                <button className={global.IconButtonMargin} type="button" onClick={(e) => onEdit(e, entity.usasClubLegalEntityId)}>
                  <EditIcon />
                </button>
                <button className={global.IconButtonMargin} type="button" onClick={(e) => onDelete(e, entity.usasClubLegalEntityId)}>
                  <DeleteIcon />
                </button>
              </td>
            </tr>
          )) : (
            <tr>
              <td colSpan="3">No Legal Entities</td>
            </tr>
          )
      }
    </tbody>
  </table>
);

const SmallGrid = ({ state, onEdit, onDelete }) => (
  <div className={[global.SmallTable, 'hidden-md hidden-lg'].join(' ')}>
    {state.isObjLoaded !== true
      ? (
        <div className={global.SmallTableRow}>
          <div className={global.SmallTableRowHead}>
            &nbsp;
          </div>
          <div className={global.SmallTableRowInfo}>
            {state.isObjLoading === true ?
              <div className={global.SmallTableRowLabels}>Loading...</div>
              : <div className={global.SmallTableRowLabels}>No Legal Entities</div>
            }
          </div>
        </div>
      ) : Array.isArray(state.objData.usasClubLegalEntities) && state.objData.usasClubLegalEntities.length > 0
        ? state.objData.usasClubLegalEntities.map(entity => (
          <div className={global.SmallTableRow} key={entity.usasClubLegalEntityId}>
            <div className={global.SmallTableRowHead}><span className='hidden-xs'>{entity.legalEntityName}</span>&nbsp;
              <button className={global.IconButton} type="button" onClick={(e) => onDelete(e, entity.usasClubLegalEntityId)}>
                <DeleteIcon />
              </button>
              <button className={global.IconButtonMobileMargin} type="button" onClick={(e) => onEdit(e, entity.usasClubLegalEntityId)}>
                <EditIcon />
              </button>
            </div>
            <div className={global.SmallTableRowInfo}>
              <div className='row'>
                <div className={[global.SmallTableRowLabels, 'col-xs-6 visible-xs'].join(' ')}>Legal Entity Name</div>
                <div className={[global.SmallTableRowData, 'col-xs-6 visible-xs'].join(' ')}>{entity.legalEntityName}</div>
                <div className={[global.SmallTableRowLabels, 'col-xs-6'].join(' ')}>Legal Entity Tax Id</div>
                <div className={[global.SmallTableRowData, 'col-xs-6'].join(' ')}>{entity.legalEntityTaxId}</div>
              </div>
            </div>
          </div>
        )) : (
          <div className={global.SmallTableRow}>
            <div className={global.SmallTableRowHead}>
              &nbsp;
            </div>
            <div className={global.SmallTableRowInfo}>
              <div className={global.SmallTableRowLabels}>No Legal Entities</div>
            </div>
          </div>
        )
    }
  </div >
);

const ClubRegistrationLegalEntitiesGridWrite = ({ state, onEdit, onDelete }) => (
  <Fragment>
    <LargeGrid state={state} onEdit={onEdit} onDelete={onDelete} />
    <SmallGrid state={state} onEdit={onEdit} onDelete={onDelete} />
  </Fragment>
);

export default ClubRegistrationLegalEntitiesGridWrite;