export const localValidate = (formState) => {
  let errors = {};

  if (formState.clubAffiliationTypes.length === 0) {
    errors.clubAffiliationTypes = 'At least one Club Affiliation is required'
  }

  return errors;
};

const ClubRegistrationAffiliationsDetailValidation = async (formState) => {
  let errors = localValidate(formState);

  return errors ? errors : {};
}

export default ClubRegistrationAffiliationsDetailValidation;