import React, { Fragment } from 'react';

import EditIcon from '../../../../common/components/icons/EditIcon';

import { formatDate } from '../../../../common/utils/DateFunctions';

import global from '../../../../common/components/GlobalStyle.module.css';

const SmallGridRow = ({ invoice, onEditClicked }) => (
  <Fragment>
    <div className={global.SmallTableRow}>
      <div className={global.SmallTableRowHead}><span className='hidden-xs'>
        {invoice.thirdPartyClubRegistrationId >= 0 ? invoice.thirdPartyClubRegistrationId : <span>&nbsp;</span>}</span>&nbsp;
        {onEditClicked &&
          <button
            className={global.IconButton}
            type="button"
            onClick={() => onEditClicked(invoice)}>
            <EditIcon />
          </button>
        }
      </div>
      <div className={global.SmallTableRowInfo}>
        <div className='row'>
          <div className={[global.SmallTableRowLabels, 'col-xs-8 visible-xs'].join(' ')}>Third Party Club Registration Id</div>
          <div className={[global.SmallTableRowData, 'col-xs-4 visible-xs'].join(' ')}>{invoice.thirdPartyClubRegistrationId >= 0 ? invoice.thirdPartyClubRegistrationId : <span>&nbsp;</span>}</div>
          <div className={[global.SmallTableRowLabels, 'col-xs-8'].join(' ')}>Registration Date</div>
          <div className={[global.SmallTableRowData, 'col-xs-4'].join(' ')}>
            {invoice.registrationDate ? formatDate(invoice.registrationDate) : <span>&nbsp;</span>}</div>
            <div className={[global.SmallTableRowLabels, 'col-xs-8'].join(' ')}>Report Period</div>
          <div className={[global.SmallTableRowData, 'col-xs-4'].join(' ')}>
            {invoice.periodName || <span>&nbsp;</span>}</div>
          <div className={[global.SmallTableRowLabels, 'col-xs-8'].join(' ')}>Member Registration Count</div>
          <div className={[global.SmallTableRowData, 'col-xs-4'].join(' ')}>{invoice.memberRegistrationCount >= 0 ? invoice.memberRegistrationCount : <span>&nbsp;</span>}</div>
        </div>
      </div>
    </div>
  </Fragment>
);

const ClubThirdPartyInvoicesGridSmall = ({ gridData, isLoading, onEditClicked }) => (
  <div className={[global.SmallTable, 'hidden-md hidden-lg'].join(' ')}>
    {isLoading === true
      ? (
        <div className={global.SmallTableRow}>
          <div className={global.SmallTableRowHead}>&nbsp;</div>
          <div className={global.SmallTableRowInfo}>
            <div className={global.SmallTableRowLabels}>Loading...</div>
          </div>
        </div>
      )
      : Array.isArray(gridData) && gridData.length > 0
        ? gridData.map((invoice, i) => <SmallGridRow key={i} invoice={invoice} onEditClicked={onEditClicked} />)
        : (
          <div className={global.SmallTableRow}>
            <div className={global.SmallTableRowHead}>&nbsp;</div>
            <div className={global.SmallTableRowInfo}>
              <div className={global.SmallTableRowLabels}>No Invoices</div>
            </div>
          </div>
        )
    }
  </div>
);

export default ClubThirdPartyInvoicesGridSmall;