import { CommonHttpHelper, NO_DATA_MESSAGE } from '../../../common/utils/HttpHelper';

const DeleteClubVendorData = (orgUnitVendorId, state, setState) => {
  if (state.isSaving !== true && !state.message) {
    const newState = {
      ...state,
      isSaving: true,
      isSaved: false,
      message: 'Deleting...'
    };
    setState(newState);

    const vendorIdForUrl = orgUnitVendorId ? encodeURIComponent(orgUnitVendorId) : 'NaN';
    const url = `/OrgUnitVendor/${vendorIdForUrl}`
    CommonHttpHelper(url, 'DELETE')
      .then((objData) => {
        if (!objData) {
          throw new Error(NO_DATA_MESSAGE);
        } else {
          setState({
            ...newState,
            isSaving: false,
            isSaved: true,
            objData,
            message: ''
          })
        }
      })
      .catch((e) => {
        const message = e && e.message ? e.message : NO_DATA_MESSAGE;
        setState({
          ...newState,
          isSaving: false,
          isSaved: false,
          message: message
        });
      });
  }
};

export default DeleteClubVendorData;