import React, { createContext, useState } from 'react';

import CategoryMeasureMultiselectData from './CategoryMeasureMultiselectData';

export const CategoryMeasureMultiselectStateContext = createContext();

const CategoryMeasureMultiselectContextProvider = ({ children }) => {
  const stateHook = useState(CategoryMeasureMultiselectData.INITIAL_STATE);

  return (
    <CategoryMeasureMultiselectStateContext.Provider value={stateHook}>
      {children}
    </CategoryMeasureMultiselectStateContext.Provider>
  );
};

export default CategoryMeasureMultiselectContextProvider;