import type { Dimension, DateDimension, Attribute, DataSourceInfo } from '@sisense/sdk-data';

import { createAttribute, createDateDimension, createDimension } from '@sisense/sdk-data';

export const DataSource: DataSourceInfo = { title: 'Club Roster', type: 'elasticube' };

interface RosterDimension extends Dimension {
    Age: Attribute;
    FirstAndPreferredName: Attribute;
    FullName: Attribute;
    LastName: Attribute;
    MemberId: Attribute;
    OrgUnitId: Attribute;
    PersonKey: Attribute;
}
export const Roster = createDimension({
    name: 'Roster',
    Age: createAttribute({
        name: 'Age',
        type: 'numeric-attribute',
        expression: '[Roster.Age]',
    }),
    FirstAndPreferredName: createAttribute({
        name: 'FirstAndPreferredName',
        type: 'text-attribute',
        expression: '[Roster.FirstAndPreferredName]',
    }),
    FullName: createAttribute({
        name: 'FullName',
        type: 'text-attribute',
        expression: '[Roster.FullName]',
    }),
    LastName: createAttribute({
        name: 'LastName',
        type: 'text-attribute',
        expression: '[Roster.LastName]',
    }),
    MemberId: createAttribute({
        name: 'MemberId',
        type: 'text-attribute',
        expression: '[Roster.MemberId]',
    }),
    OrgUnitId: createAttribute({
        name: 'OrgUnitId',
        type: 'numeric-attribute',
        expression: '[Roster.OrgUnitId]',
    }),
    PersonKey: createAttribute({
        name: 'PersonKey',
        type: 'numeric-attribute',
        expression: '[Roster.PersonKey]',
    }),
}) as RosterDimension;
