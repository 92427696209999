import { useContext } from 'react';

import { PreCompOrganizationStateContext } from './PreCompOrganizationContextProvider';
import { PreCompSearchFilterStateContext } from './PreCompSearchFilterContextProvider';

import PreCompOrganizationData from './PreCompOrganizationData';

const usePreCompOrganizationData = () => {
  const [preCompOrganizationState, setPreCompOrganizationState] = useContext(PreCompOrganizationStateContext);
  const [searchFilterState, setSearchFilterState] = useContext(PreCompSearchFilterStateContext);

  const searchPreCompOrganizationLocations = (filterObject, sortBy) => {
    setSearchFilterState({ ...searchFilterState, filterObject, sortBy });
    PreCompOrganizationData.searchPreCompOrganizationData(filterObject, sortBy, preCompOrganizationState, setPreCompOrganizationState);
  };

  const getPreCompOrganization = (organizationId) => {
    PreCompOrganizationData.getPreCompOrganizationData(organizationId, preCompOrganizationState, setPreCompOrganizationState);
  }

  const getPreCompOrganizationByOrgUnitId = (orgUnitId) => {
    PreCompOrganizationData.getPreCompOrganizationByOrgUnitIdData(orgUnitId, preCompOrganizationState, setPreCompOrganizationState);
  };

  const getPreCompOrganizationByLocationOrgUnitId = (orgUnitId) => {
    PreCompOrganizationData.getPreCompOrganizationByLocationOrgUnitIdData(orgUnitId, preCompOrganizationState, setPreCompOrganizationState);
  };

  const putPreCompOrganization = (organizationId, organizationObj) => {
    PreCompOrganizationData.putPreCompOrganizationData(organizationId, organizationObj, preCompOrganizationState, setPreCompOrganizationState);
  };

  const clearObjData = () => {
    setPreCompOrganizationState({
      ...preCompOrganizationState,
      isObjLoading: false,
      isObjLoaded: false,
      isSaving: false,
      isSaved: false,
      objData: {}
    });
  };

  const clearArrayData = () => {
    setPreCompOrganizationState({
      ...preCompOrganizationState,
      isArrayLoading: false,
      isArrayLoaded: false,
      isSaving: false,
      isSaved: false,
      arrayData: []
    });
  };

  const confirmSave = () => {
    setPreCompOrganizationState({
      ...preCompOrganizationState,
      isSaved: false
    });
  };

  const clearPreCompSearchFilterState = () => {
    const filterObject = {};
    const sortBy = '';
    setSearchFilterState({ ...searchFilterState, filterObject, sortBy });
  };

  return {
    preCompOrganizationState,
    searchFilterState,
    confirmSave,
    clearObjData,
    clearArrayData,
    clearPreCompSearchFilterState,
    searchPreCompOrganizationLocations,
    getPreCompOrganization,
    getPreCompOrganizationByOrgUnitId,
    getPreCompOrganizationByLocationOrgUnitId,
    putPreCompOrganization
  };
};

export default usePreCompOrganizationData;