import React from 'react';

import useGroupCoachesDropdown from './UseGroupCoachesDropdown';

import Dropdown from '../Dropdown';

import global from '../../GlobalStyle.module.css';

const GroupCoachesDropdown = ({ label, name, value, error, message, onChange, orgUnitId, usasClubId }) => {
  const { groupCoachesState } = useGroupCoachesDropdown(orgUnitId, usasClubId);

  return groupCoachesState.message
    ? <span className={global.LoadingMsg}>{groupCoachesState.message}</span>
    : (
      <Dropdown
        error={error}
        label={label}
        message={message}
        options={groupCoachesState.dataAsOptionsWithNames}
        name={name}
        value={value}
        onChange={onChange}
        areValuesIntegers={true}
        isLoading={groupCoachesState.isArrayLoading} />
    );
};

export default GroupCoachesDropdown;