import { Fragment } from 'react';

import Input from '../../../../inputs/Input';
import ReadOnly from '../../../../readOnly/ReadOnly';
import CourseDropdown from '../../../../dropdowns/courseDropdown/CourseDropdown';

const FacilityPoolConfigurationForm = ({ formState, errorState, onFormValueChanged, onValueTextPairChanged }) => (
  <Fragment>
    <div className="row">
      <div className="col-xs-12 col-sm-6 col-md-4">
        <ReadOnly
          label="Pool Number / Name"
          name="facilityName"
          value={formState.poolName} />
      </div>
      <div className="col-xs-12 col-sm-6 col-md-4">
        <ReadOnly
          label="Pool Dimensions"
          name="poolDimensions"
          value={formState.poolDimensions} />
      </div>
      <div className="col-xs-12 col-sm-6 col-md-4">
        <CourseDropdown
          label="Course*"
          name="courseId"
          value={formState.courseId}
          error={errorState.courseId}
          message={errorState.courseId}
          onChange={(value, newValueLabel, e) => {
            e && e.target && e.target.value &&
              onValueTextPairChanged(value, 'courseId', newValueLabel, 'courseName')
          }} />
      </div>
      <div className="col-xs-12 col-sm-6 col-md-4">
        <Input
          label="Number of Lanes*"
          name="numberOfLanes"
          type="number"
          min="1"
          value={formState.numberOfLanes}
          error={errorState.numberOfLanes}
          message={errorState.numberOfLanes}
          onChange={(value) => { onFormValueChanged('numberOfLanes', value) }} />
      </div>
    </div>
  </Fragment>
);

export default FacilityPoolConfigurationForm;