import React from 'react';

import usePrizeDropdown from './UsePrizeDropdown';

import Dropdown from '../../../../common/components/dropdowns/Dropdown';

import global from '../../../../common/components/GlobalStyle.module.css';

const PrizeDropdown = ({ label, name, value, error, message, onChange, amountRaised }) => {
  const { prizeState, filteredPrizeState } = usePrizeDropdown(amountRaised);

  return prizeState.message
    ? <span className={global.LoadingMsg}>{prizeState.message}</span>
    : (
      <Dropdown
        error={error}
        label={label}
        message={message}
        options={filteredPrizeState}
        name={name}
        value={value}
        onChange={onChange}
        isLoading={prizeState.isLoading} />
    );
};

export default PrizeDropdown;