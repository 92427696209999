import { useEffect, useState } from 'react';

import validate from './MemberIdViewValidation';

import { createCategoryMeasureScoreObj } from '../../utils/RecognitionProgramUtils';

import useCategoryMeasureScoreData from '../../../state/categoryMeasureScore/UseCategoryMeasureScoreData';

import usePersonByMemberIdData from '../../../../common/state/personByMemberId/UsePersonByMemberIdData';
import useEnvironmentVariableData from '../../../../common/state/environmentVariable/UseEnvironmentVariableData';

import useForm from "../../../../common/utils/UseForm";

const INITIAL_STATE = {
  tryRedirect: false,
  showClearEntryButton: false,
  personObj: null,
};

const INITIAL_FORM_STATE = {
  memberId: ''
};

const useMemberIdView = (programLevel, categoryMeasure, onRedirect) => {
  const { environmentVariableState, USAS_ORGANIZATION_ID } = useEnvironmentVariableData();
  const { categoryMeasureScoreState, postCategoryMeasureScore, putCategoryMeasureScore
  } = useCategoryMeasureScoreData();
  const { personByMemberIdState, resetState, getPersonByMemberIdIsInGoodStanding } = usePersonByMemberIdData();
  const { handleSubmit, updateFormState, setErrors, setFormData, formState, errorState, isDirty
  } = useForm(INITIAL_FORM_STATE, submitFormCallback, validate);
  const [state, setState] = useState(INITIAL_STATE);

  const onSaveClicked = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    if (isDirty === false && formState.memberId.trim() !== ''
      && formState.memberId.trim().length <= 8000) {
      onRedirect(false);
    } else {
      handleSubmit();
    }
  };

  const onClearEntryClicked = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    if (categoryMeasure.categoryMeasureScoreId > 0) {
      putCategoryMeasureScore(categoryMeasure.categoryMeasureScoreId,
        createCategoryMeasureScoreObj(categoryMeasure, programLevel, '', 0));
    } else {
      postCategoryMeasureScore(createCategoryMeasureScoreObj(categoryMeasure, programLevel, '', 0));
    }
    setState({ ...state, tryRedirect: true });
  };

  const onSearchMemberClicked = () => {
    const validationPromise = validate(formState);

    if (validationPromise ?? false) {
      validationPromise.then((errors) => {
        if (errors.memberId) {
          setErrors({ memberId: errors.memberId || 'An error occurred' });
        } else {
          const getPersonByMemberIdIsInGoodStandingPromise = getPersonByMemberIdIsInGoodStanding(formState.memberId, false, USAS_ORGANIZATION_ID);

          if (getPersonByMemberIdIsInGoodStandingPromise ?? false) {
            getPersonByMemberIdIsInGoodStandingPromise.then((newState) => {
              if (newState) {
                setErrors({});
              }
            }).catch((e) => {
              setErrors({ memberId: 'Unable to find a member with provided Member Id' });
              resetState();
            });
          }
        }
      }).catch((e) => {
        setErrors({ memberId: e?.message || 'An error occurred' });
      });
    }
  };

  const onUpdateMemberId = (value) => {
    updateFormState('memberId', value);

    if (state.personObj) {
      setState({
        ...state,
        personObj: null
      });
    }
  };

  function submitFormCallback() {
    if (categoryMeasure.categoryMeasureScoreId > 0) {
      putCategoryMeasureScore(categoryMeasure.categoryMeasureScoreId,
        createCategoryMeasureScoreObj(categoryMeasure, programLevel, formState.memberId.trim(), categoryMeasure?.maxValue));
    } else {
      postCategoryMeasureScore(createCategoryMeasureScoreObj(categoryMeasure, programLevel, formState.memberId.trim(), categoryMeasure?.maxValue));
    }
    setState({ ...state, tryRedirect: true });
  };

  useEffect(() => {
    if (environmentVariableState.isLoaded === true) {
      if (Object.keys(categoryMeasure).length > 0) {
        setFormData({
          ...formState,
          memberId: categoryMeasure.submittedValue || ''
        });

        if (categoryMeasure.submittedValue) {
          setState({ ...state, showClearEntryButton: true });

          const validationPromise = validate({ memberId: categoryMeasure.submittedValue });

          if (validationPromise ?? false) {
            validationPromise.then((errors) => {
              if (!errors.memberId) {
                const getPersonByMemberIdIsInGoodStandingPromise = getPersonByMemberIdIsInGoodStanding(categoryMeasure.submittedValue, false, USAS_ORGANIZATION_ID);

                if (getPersonByMemberIdIsInGoodStandingPromise ?? false) {
                  getPersonByMemberIdIsInGoodStandingPromise.catch((e) => {
                    setErrors({ memberId: 'Unable to find a member with provided Member Id' });
                    resetState();
                  });
                }
              }
            }).catch((e) => {
              //e
            });
          }
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categoryMeasure, environmentVariableState.isLoaded]);

  useEffect(() => {
    if (personByMemberIdState.isObjLoading !== true && personByMemberIdState.isObjLoaded === true) {
      setState({
        ...state,
        personObj: personByMemberIdState.objData
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [personByMemberIdState.isObjLoaded, personByMemberIdState.isObjLoading]);

  useEffect(() => {
    if (state.tryRedirect === true && categoryMeasureScoreState.isSaved === true) {
      onRedirect(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state, categoryMeasureScoreState]);

  return {
    isLoading: personByMemberIdState.isObjLoading || environmentVariableState.isLoading,
    showClearEntryButton: state.showClearEntryButton === true,
    personObj: state.personObj,
    categoryMeasureScoreState,
    formState,
    errorState,
    handleSubmit,
    onUpdateMemberId,
    onSaveClicked,
    onClearEntryClicked,
    onSearchMemberClicked
  };
};

export default useMemberIdView;