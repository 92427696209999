import NavLinks from "../../../views/clubRegistration/NavLinks";
import ClubRegistrationConstants from "../../../views/clubRegistration/utils/ClubRegistrationConstants";

export const INITIAL_STATE = [{
  name: ClubRegistrationConstants.CLUB_INFO,
  displayName: 'Club Info',
  path: NavLinks.CLUB_REGISTRATION_INFO,
  isCurrent: false,
  isComplete: false,
  isVisited: false,
  arePrerequisitesDone: true
}, {
  name: ClubRegistrationConstants.CLUB_STAFF,
  displayName: 'Club Staff',
  path: NavLinks.CLUB_REGISTRATION_STAFF,
  isCurrent: false,
  isComplete: false,
  isVisited: false,
  arePrerequisitesDone: false
}, {
  name: ClubRegistrationConstants.CLUB_TYPE,
  displayName: 'Club Type',
  path: NavLinks.CLUB_REGISTRATION_TYPE,
  isCurrent: false,
  isComplete: false,
  isVisited: false,
  arePrerequisitesDone: false
}, {
  name: ClubRegistrationConstants.LEGAL_ENTITY,
  displayName: 'Club Legal Entities',
  path: NavLinks.CLUB_REGISTRATION_LEGAL_ENTITY,
  isCurrent: false,
  isComplete: false,
  isVisited: false,
  arePrerequisitesDone: false
}, {
  name: ClubRegistrationConstants.AFFILIATION,
  displayName: 'Club Affiliations',
  path: NavLinks.CLUB_REGISTRATION_AFFILIATION,
  isCurrent: false,
  isComplete: false,
  isVisited: false,
  arePrerequisitesDone: false
}, {
  name: ClubRegistrationConstants.SCREENING_POLICIES,
  displayName: 'Screening & Policies',
  path: NavLinks.CLUB_REGISTRATION_SCREENING_POLICIES,
  isCurrent: false,
  isComplete: false,
  isVisited: false,
  arePrerequisitesDone: false
}, 
// {
//   name: ClubRegistrationConstants.PRE_COMPETITIVE_PROGRAMS,
//   displayName: 'Pre-Competitive Programs',
//   path: NavLinks.CLUB_REGISTRATION_PRECOMPETITIVE_PROGRAMS,
//   isCurrent: false,
//   isComplete: false,
//   isVisited: false,
//   arePrerequisitesDone: false
// }, 
{
  name: ClubRegistrationConstants.FACILITY,
  displayName: 'Club Facilities',
  path: NavLinks.CLUB_REGISTRATION_FACILITY,
  isCurrent: false,
  isComplete: false,
  isVisited: false,
  arePrerequisitesDone: false
}, {
  name: ClubRegistrationConstants.MISSION_STATEMENT_BUDGET,
  displayName: 'Mission Statement & Budget',
  path: NavLinks.CLUB_REGISTRATION_MISSION_STATEMENT_BUDGET,
  isCurrent: false,
  isComplete: false,
  isVisited: false,
  arePrerequisitesDone: false
}, {
  name: ClubRegistrationConstants.EMERGENCY_PLAN,
  displayName: 'Emergency Plans',
  path: NavLinks.CLUB_REGISTRATION_EMERGENCY_PLAN,
  isCurrent: false,
  isComplete: false,
  isVisited: false,
  arePrerequisitesDone: false
}, {
  name: ClubRegistrationConstants.SUBMIT,
  displayName: 'Submit',
  path: NavLinks.CLUB_REGISTRATION_SUBMIT,
  isCurrent: false,
  isComplete: false,
  isVisited: false,
  arePrerequisitesDone: false
}];

const ClubRegistrationNavData = { INITIAL_STATE };

export default ClubRegistrationNavData;