import { Fragment } from "react";
import { SisenseContextProvider } from "@sisense/sdk-ui";

import useClubPerformanceAthleteTimes from "./UseClubPerformanceAthleteTImes";

import ClubRosterDashboard from "./ClubRosterDashboard";

import * as DM from '../../../../club-roster-data';

import Headings from "../../../../common/components/headings/Headings";

const ClubPerformanceAthleteTimes = () => {
  const { environmentVariableState, CLUB_ROSTER_OID } = useClubPerformanceAthleteTimes();

  return (
    <Fragment>
      {environmentVariableState.isLoaded === true &&
        <SisenseContextProvider
          defaultDataSource={DM.DataSource}
          url={import.meta.env.VITE_SISENSE_URL}
          token={import.meta.env.VITE_SISENSE_API_TOKEN}>
          <div className="row usas-extra-bottom-margin">
            <div className="col-xs-12">
              <Headings.H3>Athlete Times Roster</Headings.H3>
              <ClubRosterDashboard dashboardOid={CLUB_ROSTER_OID} />
            </div>
          </div>
        </SisenseContextProvider>
      }
    </Fragment>
  );
};

export default ClubPerformanceAthleteTimes;