import React, { Fragment } from "react";

import DeleteIcon from "../../../../common/components/icons/DeleteIcon";
import EditIcon from "../../../../common/components/icons/EditIcon";
import HideIcon from "../../../../common/components/icons/HideIcon";
import ShowIcon from "../../../../common/components/icons/ShowIcon";

import global from '../../../../common/components/GlobalStyle.module.css';

const DetailTableRow = ({ personId, role, onEdit, onDelete }) => (
  <div className={global.SmallTableRow}>
    <div className={global.SmallTableDetailRowHead}>
      <span className='hidden-xs'>{role.orgRoleName}</span>&nbsp;
      {onDelete !== undefined && role.canDelete === true &&
        <button
          className={global.IconButtonMobileMargin}
          type="button"
          onClick={() => onDelete(personId, role.personOrgRoleDurationId)}>
          <DeleteIcon />
        </button>
      }
      {onEdit !== undefined && role.isCurrent === true &&
        <button
          className={global.IconButtonMobileMargin}
          type="button"
          onClick={() => onEdit(personId, role.orgRoleId, role.personOrgRoleDurationId)}>
          <EditIcon />
        </button>
      }
    </div>
    <div className={global.SmallTableRowInfo}>
      <div className='row'>
        <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3 visible-xs'].join(' ')}>Role</div>
        <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3 visible-xs'].join(' ')}>{role.orgRoleName || <span>&nbsp;</span>}</div>
        <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3'].join(' ')}>Email</div>
        <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3'].join(' ')}>{role.orgRoleEmail || <span>&nbsp;</span>}</div>
        <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3'].join(' ')}>Paid/Volunteer</div>
        <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3'].join(' ')}>{role.paidOrVolunteer || <span>&nbsp;</span>}</div>
        <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3'].join(' ')}>Effective Date</div>
        <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3'].join(' ')}>{role.effectiveDate || <span>&nbsp;</span>}</div>
        <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3'].join(' ')}>Expiration Date</div>
        <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3'].join(' ')}>{role.expirationDate || <span>&nbsp;</span>}</div>
      </div>
    </div>
  </div>
);

const DetailTable = ({ staff, onEdit, onDelete }) => (
  <div className={global.SmallTableDetailTable}>
    {Array.isArray(staff.roleDurations) && staff.roleDurations.map((role, i) =>
      <DetailTableRow key={i} personId={staff.personId} role={role} onEdit={onEdit} onDelete={onDelete} />)}
  </div>
);

const GridRow = ({ staff, onEdit, onDelete, expandedId, onClick }) => {
  return (
    <Fragment>
      <div className={global.SmallTableRow}>
        <div className={global.SmallTableRowHead}><span className="hidden-xs">{staff.firstName || staff.lastName ? `${staff.firstName} ${staff.lastName}` : <span>&nbsp;</span>}</span>&nbsp;
          <button
            className={global.IconButton}
            type="button"
            onClick={(e) => onClick(e, staff.personId)}>
            {expandedId === staff.personId ? <HideIcon /> : <ShowIcon />}
          </button>
        </div>
        <div className={global.SmallTableRowInfo}>
          <div className='row'>
            <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3 visible-xs'].join(' ')}>LSC Staff Name</div>
            <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3 visible-xs'].join(' ')}>{staff.preferredName !== '' ? `${staff.preferredName} ${staff.lastName}` : `${staff.firstName} ${staff.lastName}`}</div>
            <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3'].join(' ')}>Phone Number</div>
            <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3'].join(' ')}>{staff.phoneNumber || <span>&nbsp;</span>}</div>
            <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3'].join(' ')}>Email</div>
            <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3'].join(' ')}>{staff.email || <span>&nbsp;</span>}</div>
          </div>
        </div>
      </div>
      {Array.isArray(staff.roleDurations) && staff.roleDurations.length > 0
        && expandedId === staff.personId &&
        <DetailTable staff={staff} onEdit={onEdit} onDelete={onDelete} />}
    </Fragment>
  );
};

const LSCStaffMembersGridSmall = ({ data, isLoading, onEdit, onDelete, expandedId, onClick }) => (
  <div className={[global.SmallTable, 'hidden-md hidden-lg'].join(' ')}>
    {isLoading === true
      ? (
        <div className={global.SmallTableRow}>
          <div className={global.SmallTableRowHead}>&nbsp;</div>
          <div className={global.SmallTableRowInfo}>
            <div className={global.SmallTableRowLabels}>Loading...</div>
          </div>
        </div>
      )
      : data.length > 0
        ? data.map((staff, i) => <GridRow key={i} staff={staff} onEdit={onEdit} onDelete={onDelete}
          expandedId={expandedId} onClick={onClick} />)
        : (
          <div className={global.SmallTableRow}>
            <div className={global.SmallTableRowHead}>&nbsp;</div>
            <div className={global.SmallTableRowInfo}>
              <div className={global.SmallTableRowLabels}>No LSC Staff</div>
            </div>
          </div>
        )
    }
  </div>
);

export default LSCStaffMembersGridSmall;