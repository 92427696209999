
import { useState, useEffect } from "react";
import { useNavigate, useLocation } from "../../../../common/wrappers/ReactRouterDom";
import { formatDate } from '../../../../common/utils/DateFunctions';
import { navigateToCrossUiRoute } from '../../../../common/utils/FormatCrossUiRoute';

import UseForm from "../../../../common/utils/UseForm";
import Constants from "../../../../common/utils/Constants";
import useBaseUsasClubData from "../../../../common/state/clubBase/UseBaseUsasClubData";
import useCourseData from "../../../../common/state/course/UseCourseData";
import useGlobalRoutesData from '../../../../common/state/security/UseGlobalRoutesData';
import useMeetFilesData from "../../../../common/state/meetFiles/UseMeetFilesData";
import useEnvironmentVariableData from "../../../../common/state/environmentVariable/UseEnvironmentVariableData";
import useMeetSoftwareData from "../../../../common/state/meet/meetSoftware/UseMeetSoftwareData";

import validate from "./ClubBlockPartyMeetAddValidation";
import NavLinks from "../NavLinks";

import useClubBlockPartyMeetsData from "../../../state/clubBlockPartyMeets/UseClubBlockPartyMeetsData";
import useUsasClubFacilityLinksData from '../../../state/clubFacilityLinks/UseUsasClubFacilityLinksData';

const UseClubBlockPartyMeet = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const {
    environmentVariableState,
    BLOCK_PARTY_OTS_APPROVED_MEET_CLASSIFICATION_ID,
    BLOCK_PARTY_NON_OTS_APPROVED_MEET_CLASSIFICATION_ID,
    OFFICIALS_ROLES_ROLE_GROUP_ID } = useEnvironmentVariableData();
  const { baseUsasClubState } = useBaseUsasClubData();
  const { courseState } = useCourseData();
  const { clubBlockPartyMeetsState, postClubBlockPartyMeet, putClubBlockPartyMeet } = useClubBlockPartyMeetsData();
  const { usasClubFacilityLinksState, getUsasClubFacilityLinks } = useUsasClubFacilityLinksData();
  const [facilityOptionsState, setFacilityOptionsState] = useState([{ id: Constants.DEFAULT_ID, name: '--' }]);
  const { getGlobalRoute } = useGlobalRoutesData();
  const { meetFilesState, postMeetFiles, getFileNameFromSavedUploadUrl, GET_MEET_FILES_PATH } = useMeetFilesData();
  const [state, setState] = useState({ error: '' });
  const [removeFileModalState, setRemoveFileModalState] = useState(getInitialRemoveFileModalState());
  const [modalState, setModalState] = useState(getInitialModalState());
  const { meetSoftwareState } = useMeetSoftwareData();

  const { formState, errorState, setIsDirty, handleSubmit, onFormValueChanged, onValueTextPairChanged, setFormData, setFormState } =
    UseForm(getInitialFormState, submitFormCallback, validate);

  //TODO: add a env param
  const BLOCK_PARTY_MEET_CLASSIFICATION_PARENT_ID = 102;
  const FILE_TYPE_ERROR_MSG = 'File type not permitted. Please provide a (*.pdf), (*.doc), or (*.docx) file.';


  function submitFormCallback() {
    const meetCourses = formState.meetCourse;
    let courses = "";

    meetCourses.forEach(course => {
      courses += course.id + ",";
    });

    courses = courses.substring(0, courses.length - 1);

    const meetNameFmt = formState.meetId === Constants.DEFAULT_ID ? `${formState.autogeneratedMeetYear} ${formState.meetName}` : formState.meetName;

    const blockPartyMeetObj = {
      meetClassificationId: formState.meetClassification[0].id,
      meetName: meetNameFmt,
      startDate: formState.startDate,
      endDate: formState.endDate,
      hostOrgUnitId: baseUsasClubState.objData.orgUnitId,
      facilityId: formState.facilityId,
      meetSoftwareId: formState.meetSoftwareId,
      status: formState.hasPaid === true ? "Paid" : "Pending",
      meetCourseIds: courses,
      meetAnnouncementUrl: formState.meetAnnouncementUrl,
      parentOrgUnitCode: baseUsasClubState.objData.orgUnit.parentOrgUnitCode,
      orgUnitCode: baseUsasClubState.objData.orgUnit.orgUnitCode,
      meetStaffMemberId: formState.meetStaffMemberId,
      hasCartItem: formState.hasCartItem,
      depthRequirementMet: formState.depthRequirementMet,
      isInWaterStart: formState.isInWaterStart
    }

    if (clubBlockPartyMeetsState.isSaving === false) {
      if (formState.meetId === Constants.DEFAULT_ID) {
        postClubBlockPartyMeet(blockPartyMeetObj);
      }
      else {
        putClubBlockPartyMeet(formState.meetId, blockPartyMeetObj);
      }
    }
  };

  const handleSubmitProxy = async (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    await handleSubmit();
  };

  function getCourseData(selectedCourses) {
    const courseOptions = [];

    for (const course of courseState.data) {
      if (course.courseId > 0 && selectedCourses.indexOf(course.courseCode) > -1) {
        courseOptions.push({ id: course.courseId, name: course.courseCode });
      }
    }
    return courseOptions;
  }

  useEffect(() => {
    if (location.state?.meet.meetId && facilityOptionsState.length > 1 && courseState.isLoaded === true && meetSoftwareState.isLoaded === true) {
      const targetMeet = location.state.meet;

      const startDateAsDate = new Date(formatDate(targetMeet.startDate));
      const startDateYear = startDateAsDate.getFullYear();
      const facility = facilityOptionsState.find(x => x.id === targetMeet.facilityId);

      const urlComp = targetMeet.meetAnnouncementUrl?.split('.').reverse();
      let fileName = urlComp !== undefined ? urlComp[1] + "." + urlComp[0] : "";

      setFormData({
        ...formState,
        meetId: targetMeet.meetId,
        meetName: targetMeet.meetName,
        startDate: formatDate(targetMeet.startDate),
        endDate: formatDate(targetMeet.endDate),
        meetSoftwareId: targetMeet.meetSoftwareId,
        softwareName: targetMeet.softwareName,
        facilityId: targetMeet.facilityId,
        facilityName: facility.name,
        meetCourse: getCourseData(targetMeet.meetCourse),
        meetCourseForDisplay: targetMeet.meetCourse,
        meetClassificationId: targetMeet.meetClassificationId,
        meetClassificationName: targetMeet.meetClassification,
        meetClassification: [{ id: targetMeet.meetClassificationId, name: targetMeet.meetClassification }],
        autogeneratedMeetYear: startDateYear.toString(),
        meetAnnouncementUrl: targetMeet.meetAnnouncementUrl,
        meetAnnouncementFileName: fileName,
        meetStaffMemberId: targetMeet.meetStaffMemberId,
        meetSanctionNumber: targetMeet.meetSanctionNumber,
        hasPaid: targetMeet.status === "Paid" ? true : false,
        hasCartItem: targetMeet.hasCartItem,
        isInWaterStart: targetMeet.isInWaterStart
      });
      setIsDirty(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [facilityOptionsState, courseState, meetSoftwareState]);

  useEffect(() => {
    if (baseUsasClubState.isObjLoading === false && baseUsasClubState.isObjLoaded === true
      && usasClubFacilityLinksState.isArrayLoaded === false && usasClubFacilityLinksState.isArrayLoading === false) {
      getUsasClubFacilityLinks(baseUsasClubState.objData.usasClubId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [baseUsasClubState, usasClubFacilityLinksState]);

  useEffect(() => {
    if (baseUsasClubState.isObjLoading === false && baseUsasClubState.isObjLoaded === true
      && usasClubFacilityLinksState.isArrayLoading === false && usasClubFacilityLinksState.isArrayLoaded === true) {
      setFacilityOptionsState(getFacilityAsOptions(usasClubFacilityLinksState.arrayData || []));
    }
  }, [baseUsasClubState, usasClubFacilityLinksState]);

  useEffect(() => {
    if (meetFilesState.isSaved === true && Object.keys(meetFilesState.objData).length > 0) {

      var urlFormatted = meetFilesState.objData.uploadUrl.includes("Bad Request") ? "Invalid file type" : meetFilesState.objData.uploadUrl;
      var fileName = meetFilesState.objData.uploadUrl.includes("Bad Request") ? "Invalid file type" : getFileNameFromSavedUploadUrl();

      setFormState({ ...formState, meetAnnouncementUrl: urlFormatted, meetAnnouncementFileName: fileName });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [meetFilesState]);

  useEffect(() => {
    if (environmentVariableState.isLoaded === true) {
      setFormState({
        ...formState,
        OFFICIALS_ROLES_ROLE_GROUP_ID: OFFICIALS_ROLES_ROLE_GROUP_ID,
        BLOCK_PARTY_OTS_APPROVED_MEET_CLASSIFICATION_ID: BLOCK_PARTY_OTS_APPROVED_MEET_CLASSIFICATION_ID,
        BLOCK_PARTY_NON_OTS_APPROVED_MEET_CLASSIFICATION_ID: BLOCK_PARTY_NON_OTS_APPROVED_MEET_CLASSIFICATION_ID
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [environmentVariableState]);

  useEffect(() => {
    if (formState.facilityId > 0 && formState.hasPaid === false) {
      const metRequirement = checkFacilityPoolDepthRequirement(formState.facilityId);

      setModalState({
        ...modalState,
        displayModal: !metRequirement,
        facilityName: usasClubFacilityLinksState.arrayData.find(x => x.facilityId === formState.facilityId).facility.facilityName
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formState.facilityId]);

  function checkFacilityPoolDepthRequirement(facilityId) {
    const facility = usasClubFacilityLinksState.arrayData.find(x => x.facilityId === facilityId);

    return facility.facility.depthRequirementMet || formState.isInWaterStart;
  }

  function getFacilityAsOptions(facilityArray) {
    const facilityOptions = [{ id: Constants.DEFAULT_ID, name: '--' }];
    if (facilityArray) {
      for (const facility of facilityArray) {
        if (facility.facility && facility.facility.hostMeet === true) {
          facilityOptions.push({ id: facility.facility.facilityId, name: facility.facility.facilityName });
        }
      }
    }
    return facilityOptions;
  };

  useEffect(() => {
    if (clubBlockPartyMeetsState.isObjLoaded === true) {
      if (clubBlockPartyMeetsState.objData.status === "Pending" && formState.hasCartItem === false) {
        const paymentsRoute = getGlobalRoute('PAYMENTS_SHOPPING_CART');

        if (paymentsRoute && paymentsRoute.route) {
          navigateToCrossUiRoute(paymentsRoute.uIProjectName, paymentsRoute.route, navigate);
        }
      } else {
        navigate(NavLinks.CLUB_BLOCKPARTY);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clubBlockPartyMeetsState]);

  const onOpenRemoveFileModalClicked = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    setRemoveFileModalState({ ...removeFileModalState, fileName: formState.meetAnnouncementFileName, displayPopUp: true });
  }

  const onUploadTemplateClicked = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    };
    if (meetFilesState.isSaving === true) {
      setState({ ...state, error: SAVING_ERROR_MSG });
    }
    else {
      setState({ ...state, error: '' });
      if (e.target.files[0]) {
        const file = e.target.files[0];

        const fileType = e.target.files[0].type;
        if (fileType === Constants.FILE_TYPE_PDF || fileType === Constants.FILE_TYPE_DOC ||
          fileType === Constants.FILE_TYPE_DOCX) {
          postMeetFiles(file);
          setState({ ...state, error: '' });
        }
        else {
          setState({ ...state, error: FILE_TYPE_ERROR_MSG });
        }
      }
    }
  }

  const onRemoveTemplateClicked = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    };
    setRemoveFileModalState({ ...getInitialRemoveFileModalState() });
    if (meetFilesState.isSaving === true) {
      setState({ ...state, error: SAVING_ERROR_MSG });
    }
    else {
      setState({ ...state, error: '' });
      setFormState({ ...formState, meetAnnouncementUrl: '', meetAnnouncementFileName: '' });
    }
  }

  const onRemoveFileModalCanceled = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    setRemoveFileModalState({ ...getInitialRemoveFileModalState() });
  }

  const onBackClicked = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    navigate(NavLinks.CLUB_BLOCKPARTY);
  }

  const onModalCancelClicked = () => {
    setModalState({
      ...getInitialModalState()
    });

    if (formState.depthRequirementMet === false && formState.isInWaterStart === false) {
      setFormState({
        ...formState, facilityId: Constants.DEFAULT_ID
      })
    }
  };

  function getInitialFormState() {
    return {
      meetId: Constants.DEFAULT_ID,
      meetName: '',
      startDate: Constants.BLANK_DATE_STRING,
      endDate: Constants.BLANK_DATE_STRING,
      meetClassificationId: Constants.DEFAULT_ID,
      meetClassificationName: '',
      meetClassification: [],
      meetCourse: [],
      meetCourseForDisplay: '',
      meetSoftwareId: Constants.DEFAULT_ID,
      softwareName: '',
      facilityId: Constants.DEFAULT_ID,
      facilityName: '',
      autogeneratedMeetYear: '',
      autogeneratedMeetLsc: '',
      meetAnnouncementUrl: '',
      meetAnnouncementFileName: '',
      parentOrgUnitCode: '',
      orgUnitCode: '',
      meetStaffMemberId: '',
      OFFICIALS_ROLES_ROLE_GROUP_ID: OFFICIALS_ROLES_ROLE_GROUP_ID,
      BLOCK_PARTY_OTS_APPROVED_MEET_CLASSIFICATION_ID: BLOCK_PARTY_OTS_APPROVED_MEET_CLASSIFICATION_ID,
      BLOCK_PARTY_NON_OTS_APPROVED_MEET_CLASSIFICATION_ID: BLOCK_PARTY_NON_OTS_APPROVED_MEET_CLASSIFICATION_ID,
      meetStaffTypeLabel: 'Meet Staff',
      meetSanctionNumber: '',
      hasPaid: false,
      hasCartItem: false,
      depthRequirementMet: false,
      isInWaterStart: false
    };
  };

  function getInitialRemoveFileModalState() {
    return {
      displayPopUp: false,
      fileName: '',
      modalTitle: 'Remove Uploaded File?'
    }
  };

  function getInitialModalState() {
    return {
      displayModal: false,
      facilityName: ''
    }
  }

  return {
    clubBlockPartyMeetsState,
    formState,
    errorState,
    facilityOptionsState,
    meetFilesState,
    removeFileModalState,
    modalState,
    state,
    environmentVariableState,
    handleSubmitProxy,
    orgUnitId: baseUsasClubState.objData.orgUnitId || '',
    onBackClicked,
    onFormValueChanged,
    onValueTextPairChanged,
    onUploadTemplateClicked,
    onRemoveTemplateClicked,
    onOpenRemoveFileModalClicked,
    onRemoveFileModalCanceled,
    onModalCancelClicked,
    BLOCK_PARTY_MEET_CLASSIFICATION_PARENT_ID,
    mode: formState.meetId === Constants.DEFAULT_ID ? 'Add' : 'Edit',
    GET_MEET_FILES_PATH,
    setFormState,
    location
  }
}

export default UseClubBlockPartyMeet;