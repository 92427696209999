
import React, { Fragment } from 'react';

import SelectOrgUnit from '../../../../common/components/selectOrgUnit/SelectOrgUnit';

import useOrgUnitSelection from './UseOrgUnitSelection';

const OrgUnitSelection = () => {
    const { navLinks, LSC_MEMBER_ADMIN_ROLE_GROUP_ID } = useOrgUnitSelection();

    return (
        <Fragment>
            <SelectOrgUnit
                pageTitle={'Member Administration'}
                navLink={navLinks.LSC_STAFF}
                roleGroupId={LSC_MEMBER_ADMIN_ROLE_GROUP_ID} />
        </Fragment>
    );
};

export default OrgUnitSelection;