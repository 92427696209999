/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';

import global from '../../../../common/components/GlobalStyle.module.css';

const GridRow = ({ preCompLocation, onEdit }) => (
  <tr>
    <td><a onClick={() => onEdit(preCompLocation.locationId, preCompLocation.organizationId)}>{preCompLocation.locationName}</a></td>
    <td>{preCompLocation.organizationName}</td>
    <td>{preCompLocation.primaryContactName}</td>
    <td>{preCompLocation.primaryEmail}</td>
    <td>{preCompLocation.location}</td>
  </tr>
);

const PreCompSearchGridLarge = ({ data, isLoading, onEdit }) => (
  <table className={[global.UsasTable, 'visible-md visible-lg'].join(' ')}>
    <thead>
      <tr>
        <th>Location Name</th>
        <th>Organization Name</th>
        <th>Contact</th>
        <th>Contact Email</th>
        <th>Address</th>
      </tr>
    </thead>
    <tbody>
      {isLoading === true
        ? <tr><td colSpan="5">Loading...</td></tr>
        : data.length > 0
          ? data.map((preCompLocation, i) => <GridRow key={i} preCompLocation={preCompLocation} onEdit={onEdit} />)
          : <tr><td colSpan="5">No Results</td></tr>
      }
    </tbody>
  </table>
);

export default PreCompSearchGridLarge;