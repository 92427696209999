import { Fragment } from 'react';

import useZoneReportingSelection from './UseZoneReportingSelection';

import PopUpModal from '../../../../common/components/dialogs/PopUpModal';
import SelectOrgUnit from '../../../../common/components/selectOrgUnit/SelectOrgUnit';

import Constants from '../../../../common/utils/Constants';

const ZoneReportingSelection = () => {
  const {
    isLoaded,
    NavLink,
    roleGroupId
  } = useZoneReportingSelection();

  return (
    <Fragment>
      {isLoaded === true
        ? (
          <SelectOrgUnit
            pageTitle={'Zone Reports'}
            navLink={NavLink}
            roleGroupId={roleGroupId} />
        )
        : (
          <PopUpModal
            widthPct={50}
            maxWidth={250}
            title={Constants.LOADING_MSG}
            displayPopUp={true} />
        )
      }
    </Fragment>
  );
};

export default ZoneReportingSelection;