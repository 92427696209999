import useGroupCategoriesTreeView from './UseGroupCategoriesTreeView';

import TreeView from '../TreeView';

import global from '../../GlobalStyle.module.css';

const GroupCategoriesTreeView = ({ label, name, value, error, message, selectableLeavesOnly, singleSelect,
  onChange, orgUnitId }) => {
  const { groupCategoryState } = useGroupCategoriesTreeView(orgUnitId);

  return groupCategoryState.message
    ? <span className={global.LoadingMsg}>{groupCategoryState.message}</span>
    : <TreeView
      label={label}
      name={name}
      value={value}
      error={error}
      message={message}
      isLoading={groupCategoryState.isArrayLoading}
      options={groupCategoryState.treeData}
      selectableLeavesOnly={selectableLeavesOnly}
      singleSelect={singleSelect}
      onChange={onChange} />
};

export default GroupCategoriesTreeView;