import { useEffect, useState } from 'react';
import { useNavigate } from '../../../../common/wrappers/ReactRouterDom';

import PreCompRegistrationConstants from '../utils/PreCompRegistrationConstants';

import NavLinks from '../NavLinks';

import validate from '../../preComp/components/forms/PreCompLocationInstructorTrainingFormValidation';

import usePreCompInstructionHoursData from '../../../state/preCompInstructionHours/UsePreCompInstructionHoursData';
import usePreCompLocationRegistrationData from '../../../state/preCompLocationRegistration/UsePreCompLocationRegistrationData';
import usePreCompLocationRegistrationNavData from '../../../state/preCompLocationRegistration/preCompLocationRegistrationLeftNavBar/UsePreCompLocationRegistrationNavData';
import { createLocObjFromLocInstructorTrainingForm } from '../../preComp/utils/PreCompUtils';
import usePreCompCurriculumData from '../../../state/preCompCurriculum/UsePreCompCurriculumData';

import useForm from '../../../../common/utils/UseForm';
import Constants from '../../../../common/utils/Constants';

const usePreCompRegistrationLocationInstructorTraining = () => {
  const navigate = useNavigate();
  const { preCompLocationRegistrationState, saveObjData, confirmSave } = usePreCompLocationRegistrationData();
  const { locationRegistrationNavState, updateCurrentNavState } = usePreCompLocationRegistrationNavData();
  const { preCompInstructionHoursState } = usePreCompInstructionHoursData();
  const { preCompCurriculumState } = usePreCompCurriculumData();
  const { formState, errorState, isSubmitting, handleSubmit, updateFormState, onValueTextPairChanged, setFormData, setIsDirty
  } = useForm(getInitialFormState, submitFormCallback, validate);
  const [continueState, setContinueState] = useState(getInitialContinueState);

  const onContinueClicked = () => {
    setContinueState({
      ...continueState,
      destination: NavLinks.PRE_COMP_REGISTRATION_LOCATION_CLUB
    });
    handleSubmit();
  };

  const onBackClicked = () => {
    setContinueState({
      ...continueState,
      destination: NavLinks.PRE_COMP_REGISTRATION_LOCATION_STAFF
    });
    handleSubmit();
  };

  useEffect(() => {
    updateCurrentNavState(PreCompRegistrationConstants.LOC_INSTRUCTOR_TRAINING);
    setIsDirty(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (preCompLocationRegistrationState.isObjLoaded === true && preCompInstructionHoursState.isArrayLoaded === true
      && preCompCurriculumState.isArrayLoaded === true) {

      setFormData({
        ...formState,
        preEmploymentScreening: preCompLocationRegistrationState.objData.preEmploymentScreening ?? '',
        backgroundCheckRequired: preCompLocationRegistrationState.objData.backgroundCheckRequired ?? '',
        protectionPolicy: preCompLocationRegistrationState.objData.protectionPolicy ?? '',
        instructorTrainingAgencyId: preCompLocationRegistrationState.objData.instructorTrainingAgencyId || Constants.DEFAULT_ID,
        instructorTrainingAgencyName: preCompLocationRegistrationState.objData.instructorTrainingAgency?.trainingAgencyName || '',
        inServiceInstructionHoursId: preCompLocationRegistrationState.objData.inServiceInstructionHoursId || Constants.DEFAULT_ID,
        inServiceInstructionHoursName: preCompLocationRegistrationState.objData.inServiceInstructionHours?.instructionHoursDescription || '',
        inClassroomInstructionHoursId: preCompLocationRegistrationState.objData.inClassroomInstructionHoursId || Constants.DEFAULT_ID,
        inClassroomInstructionHoursName: preCompLocationRegistrationState.objData.inClassroomInstructionHours?.instructionHoursDescription || '',
        inWaterInstructionHoursId: preCompLocationRegistrationState.objData.inWaterInstructionHoursId || Constants.DEFAULT_ID,
        inWaterInstructionHoursName: preCompLocationRegistrationState.objData.inWaterInstructionHours?.instructionHoursDescription || '',
        inShadowInstructionHoursId: preCompLocationRegistrationState.objData.inShadowInstructionHoursId || Constants.DEFAULT_ID,
        inShadowInstructionHoursName: preCompLocationRegistrationState.objData.inShadowInstructionHours?.instructionHoursDescription || '',
        inPracticeInstructionHoursId: preCompLocationRegistrationState.objData.inPracticeInstructionHoursId || Constants.DEFAULT_ID,
        inPracticeInstructionHoursName: preCompLocationRegistrationState.objData.inPracticeInstructionHours?.instructionHoursDescription || ''
      });
      setIsDirty(true);
    } else if (preCompLocationRegistrationState.isObjLoaded === false) {
      navigate(NavLinks.PRE_COMP_REGISTRATION_ENTRY);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [preCompLocationRegistrationState.isObjLoaded, preCompCurriculumState.isArrayLoaded, preCompInstructionHoursState.isArrayLoaded]);

  useEffect(() => {
    if (preCompLocationRegistrationState.isSaved === true && isSubmitting === false) {
      confirmSave();
      updateCurrentNavState(PreCompRegistrationConstants.LOC_INSTRUCTOR_TRAINING, true);

      setContinueState({
        ...continueState,
        tryRedirect: true
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [preCompLocationRegistrationState, isSubmitting]);

  useEffect(() => {
    if (continueState.tryRedirect === true) {
      if (continueState.destination !== '') {
        navigate(continueState.destination);
      } else {
        setContinueState(getInitialContinueState);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [continueState.tryRedirect]);

  function submitFormCallback(formState) {
    saveObjData(createLocObjFromLocInstructorTrainingForm(preCompLocationRegistrationState.objData, formState));
  };

  function getInitialFormState() {
    return {
      preEmploymentScreening: '',
      backgroundCheckRequired: '',
      protectionPolicy: '',
      instructorTrainingAgencyId: Constants.DEFAULT_ID,
      instructorTrainingAgencyName: '',
      inServiceInstructionHoursId: Constants.DEFAULT_ID,
      inServiceInstructionHoursName: '',
      inClassroomInstructionHoursId: Constants.DEFAULT_ID,
      inClassroomInstructionHoursName: '',
      inWaterInstructionHoursId: Constants.DEFAULT_ID,
      inWaterInstructionHoursName: '',
      inShadowInstructionHoursId: Constants.DEFAULT_ID,
      inShadowInstructionHoursName: '',
      inPracticeInstructionHoursId: Constants.DEFAULT_ID,
      inPracticeInstructionHoursName: ''
    };
  };

  function getInitialContinueState() {
    return {
      tryRedirect: false,
      destination: ''
    };
  };

  return {
    isLoading: preCompCurriculumState.isArrayLoading || preCompInstructionHoursState.isArrayLoading,
    formState,
    errorState,
    locationRegistrationNavState,
    onFormValueChanged: updateFormState,
    onValueTextPairChanged,
    onContinueClicked,
    onBackClicked
  };
};

export default usePreCompRegistrationLocationInstructorTraining;