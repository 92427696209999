import { useEffect, useState } from 'react';
import { useNavigate } from '../../../../common/wrappers/ReactRouterDom';

import NavLinks from '../NavLinks';

import usePreCompLocationData from '../../../state/preCompLocation/UsePreCompLocationData';

import useForm from '../../../../common/utils/UseForm';

const usePreCompLocationClubs = () => {
  const navigate = useNavigate();
  const { preCompLocationState, confirmSave, putPreCompLocation } = usePreCompLocationData();
  const { formState, errorState, updateFormState, setFormData, handleSubmit, isDirty
  } = useForm(getInitialFormState, submitFormCallback, () => { });
  const [gridState, setGridState] = useState([]);
  const [modalState, setModalState] = useState(getInitialModalState);
  const [formModalState, setFormModalState] = useState(getInitialFormModalState);

  const onLinkClub = () => {
    navigate(NavLinks.PRE_COMP_LOCATION_CLUB_SEARCH);
  };

  const onClubDelete = (usasClubId) => {
    if (preCompLocationState.isSaving === false) {
      const targetIndex = preCompLocationState.objData.preCompLocationClub.findIndex(x => x.usasClubId === usasClubId);

      if (targetIndex >= 0) {
        setModalState({
          ...modalState,
          displayPopUp: true,
          clubName: preCompLocationState.objData.preCompLocationClub[targetIndex].clubInfo.name,
          clubLocation: preCompLocationState.objData.preCompLocationClub[targetIndex].clubInfo.location,
          targetIndex,
          showLastClubWarning: preCompLocationState.objData.preCompLocationClub.length === 1
        });
      }
    }
  };

  const onModalCancelClicked = () => {
    setModalState(getInitialModalState);
  };

  const onModalDeleteClicked = (targetIndex) => {
    if (targetIndex >= 0) {
      const locationCopy = JSON.parse(JSON.stringify(preCompLocationState.objData));

      locationCopy.preCompLocationClub.splice(targetIndex, 1);

      if (locationCopy.preCompLocationClub.length === 0) {
        locationCopy.isUsasMemberTeam = false;
        locationCopy.referParticipantsToUsas = false;
      }

      putPreCompLocation(preCompLocationState.objData.preCompetitiveLocationId, locationCopy);

      setModalState(getInitialModalState);
    }
  };

  const onFormModalCancelClicked = () => {
    setFormData({
      ...formState,
      isUsasMemberTeam: preCompLocationState.objData.isUsasMemberTeam,
      referParticipantsToUsas: preCompLocationState.objData.referParticipantsToUsas
    });
    setFormModalState(getInitialFormModalState);
  };

  const onFormModalConfirmClicked = () => {
    const locationCopy = JSON.parse(JSON.stringify(preCompLocationState.objData));

    locationCopy.isUsasMemberTeam = formState.isUsasMemberTeam;
    locationCopy.referParticipantsToUsas = formState.referParticipantsToUsas;
    locationCopy.preCompLocationClub = [];

    putPreCompLocation(preCompLocationState.objData.preCompetitiveLocationId, locationCopy);
    setFormModalState(getInitialFormModalState);
  };

  useEffect(() => {
    if (preCompLocationState.isObjLoaded === true) {
      setFormData({
        ...formState,
        isUsasMemberTeam: preCompLocationState.objData.isUsasMemberTeam ?? '',
        referParticipantsToUsas: preCompLocationState.objData.referParticipantsToUsas ?? ''
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [preCompLocationState.objData]);

  useEffect(() => {
    if (preCompLocationState.isSaving === false && preCompLocationState.isSaved === true) {
      confirmSave();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [preCompLocationState]);

  useEffect(() => {
    if (preCompLocationState.isObjLoaded === true) {
      setGridState(preCompLocationState.objData.preCompLocationClub || []);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [preCompLocationState.objData]);

  useEffect(() => {
    if (isDirty === true) {
      handleSubmit();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formState]);

  function submitFormCallback(formState) {
    if (formState.isUsasMemberTeam !== preCompLocationState.objData.isUsasMemberTeam
      || formState.referParticipantsToUsas !== preCompLocationState.objData.referParticipantsToUsas) {
      if (formState.isUsasMemberTeam === false && formState.referParticipantsToUsas === false
        && preCompLocationState.objData.preCompLocationClub.length > 0) {
        setFormModalState({
          ...formModalState,
          displayPopUp: true
        });
      }
      else {
        const locationCopy = JSON.parse(JSON.stringify(preCompLocationState.objData));

        locationCopy.isUsasMemberTeam = formState.isUsasMemberTeam;
        locationCopy.referParticipantsToUsas = formState.referParticipantsToUsas;

        putPreCompLocation(preCompLocationState.objData.preCompetitiveLocationId, locationCopy);
      }
    }
  };

  function getInitialModalState() {
    return {
      displayPopUp: false,
      clubName: '',
      clubLocation: '',
      targetIndex: undefined,
      showLastClubWarning: false
    };
  };

  function getInitialFormModalState() {
    return {
      displayPopUp: false
    };
  };

  function getInitialFormState() {
    return {
      isUsasMemberTeam: '',
      referParticipantsToUsas: ''
    };
  };

  return {
    isSaving: preCompLocationState.isSaving,
    isLoading: preCompLocationState.isObjLoading,
    preCompLocationState,
    gridState,
    modalState,
    formState,
    errorState,
    formModalState,
    onFormValueChanged: updateFormState,
    onLinkClub,
    onClubDelete,
    onModalCancelClicked,
    onModalDeleteClicked,
    onFormModalCancelClicked,
    onFormModalConfirmClicked
  };
};

export default usePreCompLocationClubs;