import React, { Fragment } from 'react';

import useTextEntryView from './UseTextEntryView';

import Textarea from '../../../../common/components/inputs/Textarea';
import Headings from '../../../../common/components/headings/Headings';
import PopUpModal from '../../../../common/components/dialogs/PopUpModal';
import PrimaryButton from '../../../../common/components/buttons/PrimaryButton';
import SecondaryButton from '../../../../common/components/buttons/SecondaryButton';
import ReadOnly from '../../../../common/components/readOnly/ReadOnly';

import Constants from '../../../../common/utils/Constants';

import global from '../../../../common/components/GlobalStyle.module.css';

const Label = ({ question }) => (
  <div dangerouslySetInnerHTML={{ __html: question }} />
);

const TextEntryView = ({ programLevel, categoryMeasure, isReadonly = false, onRedirect }) => {
  const {
    showClearEntryButton,
    categoryMeasureScoreState,
    formState,
    errorState,
    handleSubmit,
    onFormValueChanged,
    onSaveClicked,
    onClearEntryClicked
  } = useTextEntryView(programLevel, categoryMeasure, onRedirect);

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>{categoryMeasure?.measureName}</Headings.H3>
        </div>
      </div>
      <form onSubmit={handleSubmit} noValidate>
        <div className="row">
          <div className="col-xs-12">
            {isReadonly === true
              ? (
                <ReadOnly
                  label={<Label question={categoryMeasure?.question} />}
                  name="textEntry"
                  value={formState.textEntry} />
              ) : (
                <Textarea
                  className={global.Textarea}
                  label={<Label question={categoryMeasure?.question} />}
                  name="textEntry"
                  value={formState.textEntry}
                  error={errorState.textEntry}
                  message={errorState.textEntry}
                  onChange={(value) => { onFormValueChanged('textEntry', value); }} />
              )}
          </div>
        </div>
        <div className="row usas-extra-top-margin usas-extra-bottom-margin">
          {isReadonly === true
            ? (
              <div className="col-xs-12 usas-extra-top-margin">
                <SecondaryButton type="button" onClick={() => onRedirect(false)}>Back</SecondaryButton>
              </div>
            ) : (
              <div className="col-xs-12 usas-extra-top-margin">
                <PrimaryButton type="button" onClick={onSaveClicked}>Save</PrimaryButton>&nbsp;
                <SecondaryButton type="button" onClick={() => onRedirect(false)}>Back</SecondaryButton>&nbsp;
                {showClearEntryButton &&
                  <PrimaryButton type="button" onClick={onClearEntryClicked}>Clear Entry</PrimaryButton>
                }
              </div>
            )
          }
        </div>
      </form>
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.SAVING_MSG}
        displayPopUp={categoryMeasureScoreState.isSaving} />
    </Fragment>
  );
};

export default TextEntryView;