import { BASIC_INITIAL_STATE } from '../../../common/utils/HttpHelper';

import getUsasClubFacilityLinksData from './GetUsasClubFacilityLinksData';
import postUsasClubFacilityLinksData from './PostUsasClubFacilityLinksData';
import putUsasClubFacilityLinksData from './PutUsasClubFacilityLinksData';
import deleteUsasClubFacilityLinksData from './DeleteUsasClubFacilityLinksData';

const INITIAL_STATE = {
  ...BASIC_INITIAL_STATE
};

const UsasClubFacilityLinksData = {
  INITIAL_STATE, getUsasClubFacilityLinksData, postUsasClubFacilityLinksData,
  putUsasClubFacilityLinksData, deleteUsasClubFacilityLinksData
};

export default UsasClubFacilityLinksData;